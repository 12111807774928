<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#DFDFDF',
    },
    strokeColor: {
        type: String,
        default: '#C90808',
    },
});
const width = computed<number>(() => 16 * props.size);
const height = computed<number>(() => 17 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0 16 17">
        <path
            :fill="color"
            d="M1.59976 4.5002C1.59976 4.05837 1.8863 3.7002 2.23976 3.7002H13.7598C14.1132 3.7002 14.3998 4.05837 14.3998 4.5002C14.3998 4.94202 14.1132 5.3002 13.7598 5.3002H2.23976C1.8863 5.3002 1.59976 4.94202 1.59976 4.5002Z"
        />
        <path
            :fill="color"
            d="M3.19983 8.5002C3.19983 8.05836 3.50684 7.7002 3.88554 7.7002H12.1141C12.4928 7.7002 12.7998 8.05836 12.7998 8.5002C12.7998 8.94204 12.4928 9.3002 12.1141 9.3002H3.88554C3.50684 9.3002 3.19983 8.94204 3.19983 8.5002Z"
        />
        <path
            :fill="strokeColor"
            d="M5.59984 11.7002C5.15801 11.7002 4.79984 12.0584 4.79984 12.5002C4.79984 12.942 5.15801 13.3002 5.59984 13.3002H10.3998C10.8417 13.3002 11.1998 12.942 11.1998 12.5002C11.1998 12.0584 10.8417 11.7002 10.3998 11.7002H5.59984Z"
        />
    </BaseIcon>
</template>
