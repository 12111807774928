import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54442b17"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-address-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_location = _resolveComponent("icon-location")!
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_horizontal_divider = _resolveComponent("horizontal-divider")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: "the-address-btn__content",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($options.openModalAddress && $options.openModalAddress(...args)), ["stop"]))
    }, [
      _createVNode(_component_icon_location, {
        size: 0.8,
        color: "#FF9012"
      }),
      _createElementVNode("p", null, _toDisplayString($options.addressName), 1),
      _createVNode(_component_icon_arrow, {
        class: "icon-arrow",
        size: 0.6,
        color: "#FF9012"
      })
    ]),
    _createVNode(_component_horizontal_divider, { height: "0.1rem" })
  ]))
}