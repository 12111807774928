<template>
    <base-modal :cover-all-screen="isMobile" :has-back-arrow="false" :has-close="false" is-centered>
        <template #default>
            <icon-tipti :size="0.8" />
            <div class="creating-order">
                <h1 class="creating-order__title">{{ $t('alerts.processing_order') }}</h1>
                <img class="creating-order__animation" alt="" src="@/assets/gif/processing_order.gif" />
                <h2 class="creating-order__content">{{ $t('alerts.processing_order__waiting') }}</h2>
                <h2 class="creating-order__content creating-order__content--dont-close">
                    {{ $t('general.dont_close_browser') }}
                </h2>
                <div v-if="finalProductToShow.length" class="creating-order__products-bought">
                    <template v-for="(product, productIndex) in finalProductToShow" :key="product">
                        <img
                            v-if="productIndex <= 6"
                            class="creating-order__img"
                            :alt="product.name"
                            :src="product.image.medium"
                            @error="displayDefaultImg"
                        />
                    </template>
                    <div v-if="showCounterProductsRemaining" class="creating-order__counter">
                        <p class="creating-order__counter--text">
                            {{ `+${counterProductsRemaining}` }}
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </base-modal>
</template>
<script lang="ts">
import IconTipti from '@/components/icons/IconTipti.vue';
import { useCheckOut } from '@/composables/useCheckOut';
import { computed } from 'vue';
import { useImage } from '@/composables/ImageComposable';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import { useResize } from '@/composables/useRezise';

export default {
    name: 'loading-order',
    components: { BaseModal, IconTipti },
    setup() {
        const { productsBought } = useCheckOut();
        const { displayDefaultImg } = useImage();
        const { isMobile } = useResize();

        const minProductsToShow = 5;
        const maxProductsToShow = 6;

        const counterProductsRemaining = computed<number>(() =>
            productsBought.value.length - maxProductsToShow < 0 ? 0 : productsBought.value.length - minProductsToShow,
        );
        const showCounterProductsRemaining = computed<boolean>(() => productsBought.value.length > maxProductsToShow);

        const finalProductToShow = computed(() => {
            let productsToShowCounter = productsBought.value.length;
            if (showCounterProductsRemaining.value) productsToShowCounter = minProductsToShow;
            return productsBought.value.slice(0, productsToShowCounter);
        });
        return {
            isMobile,
            finalProductToShow,
            showCounterProductsRemaining,
            counterProductsRemaining,
            displayDefaultImg,
        };
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/index.scss';
.creating-order {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &__title {
        @include subtitle2;
        font-weight: 600;
        max-width: 25rem;
        margin: $margin-sm $margin-none;
    }
    &__content {
        @include body2;
        &--dont-close {
            font-weight: 600;
            text-transform: uppercase;
        }
    }
    &__animation {
        margin: $margin-sm;
        max-width: 8rem;
    }
    &__products-bought {
        margin: $margin-md $margin-none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $margin-md;
        align-items: center;
        justify-content: center;
    }
    &__img {
        width: 4rem;
        height: 4rem;
    }
    &__counter {
        width: 4rem;
        height: 4rem;
        border: 1px $orange solid;
        border-radius: $radius-md;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-orange;
        &--text {
            @include body1;
            font-size: 20px;
            font-weight: 600;
            color: $orange;
            text-align: center;
        }
    }
}
</style>
