import { getEnumValue } from '@/utils/schemaEnumHelper';
import { Components } from '@/enums/components';
import { useStore } from 'vuex';

import { computed, ref } from 'vue';
import { removeCountryCode } from '@/utils/phoneUtils';
import { useCountry } from '@/composables/useCountry';

export const useFormHelper = (initSchema, initData = {}) => {
    const store = useStore();
    const { countryPhoneFromPhoneNumber, validatePhoneAndCountryPhone } = useCountry();
    const emailReg = /^[a-zA-Z]+.*@[a-zA-Z0-9-]*\..{2,}$/;

    const userLanguage = computed(() => store.state.user.userLanguage);

    const currentSchema = ref(initSchema);
    const formData = ref(initData);
    const invalidFiled = ref([]);
    const requiredFields = ref([]);

    const setSchema = (schema) => {
        currentSchema.value = schema;
    };
    const setFormData = (schema) => {
        formData.value = schema;
    };

    const formatInitialData = () => {
        if (formData.value['phone']) {
            countryPhoneFromPhoneNumber(formData.value['phone']);
            formData.value['phone'] = removeCountryCode(formData.value['phone']);
        } else if (formData.value['phone_number']) {
            countryPhoneFromPhoneNumber(formData.value['phone_number']);
            formData.value['phone_number'] = removeCountryCode(formData.value['phone_number']);
        } else {
            if (currentSchema.value['phone'] || currentSchema.value['phone_number']) {
                countryPhoneFromPhoneNumber();
            }
        }
    };

    const formFields = computed(() => {
        const fields = [];
        if (!currentSchema.value) return;

        const requiredFields = currentSchema.value['required'];
        const propertiesBlock = currentSchema.value['properties'];
        const definitionsBlock = currentSchema.value['definitions'];

        const getDefinition = (reference: string, parentId?: string) => {
            return getEnumValue(definitionsBlock[reference]['anyOf'], parentId, userLanguage.value);
        };
        const propertiesNames = Object.keys(propertiesBlock);
        propertiesNames.forEach((property) => {
            let labels;
            try {
                labels = JSON.parse(propertiesBlock[property]['title']);
            } catch (e) {
                labels = propertiesBlock[property]['title'];
            }

            const label =
                labels[userLanguage.value.toUpperCase()] ??
                labels[userLanguage.value] ??
                propertiesBlock[property]['title'];
            const hasRef = propertiesBlock[property]['$ref'];
            let options;
            if (hasRef) {
                const key = propertiesBlock[property]['$ref'].split('/');
                if (propertiesBlock[property]['depends'] && formData.value[propertiesBlock[property]['depends']]) {
                    options = getDefinition(key[key.length - 1], formData.value[propertiesBlock[property]['depends']]);
                } else if (!propertiesBlock[property]['depends']) {
                    options = getDefinition(key[key.length - 1]);
                }
            }

            const setComponent = (): string => {
                if (property === 'phone' || property === 'phone_number') return Components.inputPhoneWithLabel;
                if (propertiesBlock[property]['type'] === 'boolean') return Components.dynamicToggle;
                if (propertiesBlock[property]['format'] === 'date') return Components.dynamicDate;
                if (hasRef) return Components.dynamicDropdown;
                return Components.dynamicInput;
            };

            fields.push({
                component: setComponent(),
                name: property,
                type: propertiesBlock[property]['type'] ?? 'string',
                title: label,
                label: label,
                pattern: propertiesBlock[property]['pattern'],
                minLength: propertiesBlock[property]['minLength'],
                maxLength: propertiesBlock[property]['maxLength'],
                format: propertiesBlock[property]['format'],
                required: requiredFields.includes(property),
                dependency: propertiesBlock[property]['depends'],
                placeholder: propertiesBlock[property]['placeholder'] ?? '',
                options: options,
            });
        });
        return fields;
    });

    const updateValues = (fieldName: string, newFormData: object, field: object): void => {
        invalidFiled.value = invalidFiled.value.filter((element) => element !== fieldName);
        if (field['format'] === 'email') if (!emailReg.test(newFormData[fieldName])) invalidFiled.value.push(fieldName);

        if (field['pattern']?.length) {
            const reg = new RegExp(field['pattern']);
            if (!reg.test(newFormData[fieldName]?.replaceAll(' ', ''))) invalidFiled.value.push(fieldName);
        }
        if (field['minLength'])
            if (newFormData[fieldName].length < field['minLength']) invalidFiled.value.push(fieldName);

        if (field['maxLength'])
            if (newFormData[fieldName].length > field['maxLength']) invalidFiled.value.push(fieldName);

        formData.value = newFormData;
    };
    const isInvalidForm = (): boolean => {
        const required = formFields.value.filter((element) => element.required);
        const formKeys = Object.keys(formData.value);
        required.forEach((element) => {
            requiredFields.value = requiredFields.value.filter((filed) => filed !== element.name);
            if (!formKeys.includes(element.name)) {
                requiredFields.value.push(element.name);
            }
        });
        if (formKeys.includes('phone')) {
            invalidFiled.value = invalidFiled.value.filter((field) => field !== 'phone');
            const validationResult = validatePhoneAndCountryPhone(formData.value['phone_number']);
            if (!validationResult) invalidFiled.value.push('phone');
        }
        if (formKeys.includes('phone_number')) {
            invalidFiled.value = invalidFiled.value.filter((field) => field !== 'phone_number');
            const validationResult = validatePhoneAndCountryPhone(formData.value['phone_number']);
            if (!validationResult) invalidFiled.value.push('phone_number');
        }
        return !!requiredFields.value.length || !!invalidFiled.value.length;
    };

    return {
        setFormData,
        currentSchema,
        isInvalidForm,
        updateValues,
        formFields,
        formData,
        requiredFields,
        invalidFiled,
        formatInitialData,
    };
};
