<template>
    <div v-if="iconComponent" class="faq-main__phones">
        <component :is="iconComponent" :icon-name:="altIcon" />
        <div class="faq-main__phones-txt">
            <div class="faq-main--card">
                <p class="faq-main--title-card">{{ $t(`txt['${titleCard}']`) }}</p>
                <p class="faq-main--content-card">{{ $t(`txt['${contentCard}']`) }}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineAsyncComponent } from 'vue';

export default {
    name: 'CardFaq',

    components: {
        IconWhatIsTipti: defineAsyncComponent(() => import('@/components/icons/IconWhatIsTipti.vue')),
        IconTiptiExpress: defineAsyncComponent(() => import('@/components/icons/IconTiptiExpress.vue')),
        IconOrderInProcess: defineAsyncComponent(() => import('@/components/icons/IconOrderInProcess.vue')),
        IconMakeAnOrder: defineAsyncComponent(() => import('@/components/icons/IconMakeAnOrder.vue')),
        IconMyTiptiCard: defineAsyncComponent(() => import('@/components/icons/IconMyTiptiCard.vue')),
        IconDeliveryOfYourOrder: defineAsyncComponent(() => import('@/components/icons/IconDeliveryOfYourOrder.vue')),
        IconPromotionsDiscountsRates: defineAsyncComponent(
            () => import('@/components/icons/IconPromotionsDiscountsRates.vue'),
        ),
        IconProfileAndSettings: defineAsyncComponent(() => import('@/components/icons/IconProfileAndSettings.vue')),
        IconContactUs: defineAsyncComponent(() => import('@/components/icons/IconContactUs.vue')),
        IconOlderAdult: defineAsyncComponent(() => import('@/components/icons/IconOlderAdult.vue')),
    },

    props: {
        titleCard: {
            type: String,
            required: true,
        },
        contentCard: {
            type: String,
            required: true,
        },
        iconComponent: {
            type: String,
            required: true,
        },
        altIcon: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../faqs.scss';
</style>
