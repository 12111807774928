<script lang="ts" setup>
import { GiftCardDTO } from '@/views/wallet/WalletModels';
import { useWallet } from '@/views/wallet/useWallet';
import { useRouter } from 'vue-router';
import WalletSectionCards from '@/views/wallet/components/WalletSectionCards.vue';
import GiftCard from '@/components/cards/GiftCard.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import IconTipti from '@/components/icons/IconTipti.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

const $router = useRouter();
const { getMainGiftCards, getExpiredGiftCards, giftCardSelected, expiredGiftCards, mainGiftCards } = useWallet();

await Promise.allSettled([getExpiredGiftCards(), getMainGiftCards()]);

const showPlaceholder = mainGiftCards.value.length == 0;
const hideSection = showPlaceholder && expiredGiftCards.value.length == 0;

if (showPlaceholder) mainGiftCards.value = [{} as GiftCardDTO];

const goToGitftCardDetail = (item: GiftCardDTO) => {
    giftCardSelected.value = item;
    return $router.push({
        name: appRoutesMap.giftCardDetail,
    });
};

const gotToExpiredGiftCards = () => {
    return $router.push({
        name: appRoutesMap.expiredGiftCards,
    });
};
</script>

<template>
    <div v-if="!hideSection">
        <WalletSectionCards :items="mainGiftCards" :title="$t('txt.tipti-wallet-gift-cards-title')">
            <template #default="{ item, style }">
                <GiftCard v-if="!showPlaceholder" :card="item" :style="style" @click.stop="goToGitftCardDetail(item)" />
                <BaseCard v-else class="gift-cards__placeholder" bg-color="#f5f5f5">
                    <IconTipti color="#FFFFFF" />
                    <p class="gift-cards__placeholder__text">{{ $t('txt.gift-card-placeholder-text') }}</p>
                </BaseCard>
            </template>
        </WalletSectionCards>
        <a
            v-if="expiredGiftCards.length"
            class="gift-cards-expired-link gift-cards-expired-link__txt"
            @click="gotToExpiredGiftCards"
        >
            {{ $t('txt.tipti-wallet-gift-cards-view-expired') }}
        </a>
    </div>
</template>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.gift-cards-expired-link {
    margin: 0.8rem 1.5rem;

    &__txt {
        @include body1;
        cursor: pointer;
        color: $orange;
        text-decoration: underline;
    }
}

.gift-cards__placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 22rem;
    height: 11rem;
    @include tablet {
        width: 20rem;
        height: 10rem;
    }

    &__text {
        @include body1;
        width: 60%;
        text-align: center;
    }
}
</style>
