import { useStore } from 'vuex';
import { computed } from 'vue';

export const useCurrency = () => {
    const $store = useStore();
    const countrySelected = computed(() => $store.getters["'external/countrySelected',"]);

    const currency = computed(() => countrySelected.value?.currencySymbol ?? '$');

    const convertToCurrency = (amount: string | number): string => {
        return `${currency.value} ${(+amount)?.toFixed(2)}`;
    };

    return {
        currency,
        convertToCurrency,
    };
};
