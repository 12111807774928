<template>
    <change-password v-if="showChangePassword" @on-select-option="passwordChanged" />
    <base-card v-else class="edit-profile" :class="{ 'edit-profile--box-shadow': isFromCart }" padding="0">
        <section-header
            v-if="!isFromCart"
            :has-back="isMobile"
            :title="$t('txt.edit-profile__edit-my-profile')"
            @on-back="$router.back()"
        />

        <img v-if="!isFromCart" class="edit-profile__avatar" alt="Tipti avatar" src="@/assets/svg/ic_avatar.svg" />
        <TabMultiOption v-if="isFromCart" v-model="documentType" class="profile-type-from" :options="documentTypes" />

        <h5 v-if="isFromCart" class="form_header-profile">{{ $t('txt.billing__required_data') }}</h5>
        <form id="editProfileForm" class="edit-profile-form" @submit.prevent>
            <TiptiInputText
                v-if="!isFromCart"
                v-model="name"
                :label="$t('general.name')"
                is-required
                placeholder="Eliza"
            />
            <TiptiInputText
                v-if="!isFromCart"
                v-model="lastname"
                :label="$t('general.last-name')"
                is-required
                placeholder="Mejía"
            />
            <TiptiInputText
                v-model="identityDocument"
                :errors="messageError.identifier"
                :label="documentType.labelInput ?? $t('general.dni')"
                :placeholder="documentType.labelInput ?? $t('general.dni')"
                is-required
            />
            <input-phone-with-label
                v-if="showPhoneInput"
                v-model="phone"
                class="margin-input"
                :errors="messageError.phone"
                :phone-already-exists="isPhoneAlreadyRegistered"
                :show-placeholder-with-out-focus="true"
                is-required
            />
            <TiptiInputText v-if="!isFromCart" v-model="email" :label="$t('general.email')" is-required type="email" />
            <TiptiInputPassword v-if="needsPassword" v-model="password" :label="$t('general.password')" is-required />

            <TiptiInputText
                v-model="birthday"
                :label="$t('general.birthday')"
                :max="maxDate"
                :min="minDate"
                is-required
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                type="date"
            />
            <label class="edit-profile-form__label">
                {{ $t('general.genre') }}
            </label>
            <div class="edit-profile-form__radio">
                <gender-selected
                    v-for="option in [
                        { value: 'MASCULINO', name: $t('general.male') },
                        { value: 'FEMENINO', name: $t('general.female') },
                        { value: 'OTHER', name: $t('general.other') },
                    ]"
                    :key="option.value"
                    v-model="genre"
                    :option="option"
                />
            </div>
            <p v-if="!isFromCart" class="edit-profile-change-password" @click="showChangePassword = true">
                ¿{{ $t('cta.change-password') }}?
            </p>
            <button v-if="!isFromCart" class="edit-profile__deactivate-account" @click="switchShowAlert(true)">
                {{ $t('cta.deactivate-account') }}
            </button>
        </form>
        <section-footer :has-border="false" :with-shadow="false">
            <btn-solid
                :is-disabled="btnIsDisable"
                :is-loading="isLoading"
                :txt="$t('cta.save')"
                @click="isLoading ? null : saveChanges()"
            />
        </section-footer>
    </base-card>

    <alert-deactivate-account v-if="showAlertDeactivateAC" @on-close="switchShowAlert(false)" />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { isMobileBrowser } from '@/utils/utils';
import BaseCard from '@/components/cards/BaseCard.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import ChangePassword from '../../components/changePassword/ChangePassword.vue';
import AlertDeactivateAccount from '@/components/alerts/alertDeactivateAccount/AlertDeactivateAccount.vue';
import InputPhoneWithLabel from '@/components/inputs/primary/phone/InputPhoneWithLabel.vue';
import { useCountry } from '@/composables/useCountry';
import dayjs from 'dayjs';
import TabMultiOption from '@/modules/core/tabs/TabMultiOption.vue';
import GenderSelected from '@/views/myAccount/views/profile/components/GenderSlected.vue';
import { useUser } from '@/composables/useUser';
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';
import { removeCountryCode } from '@/utils/phoneUtils';
import TiptiInputPassword from '@/components/inputs/primary/normal/TiptiInputPassword.vue';
import { useApp } from '@/composables/useApp';

export default {
    name: 'EditProfile',
    components: {
        TiptiInputPassword,
        TiptiInputText,
        GenderSelected,
        TabMultiOption,
        AlertDeactivateAccount,
        BaseCard,
        BtnSolid,
        SectionHeader,
        SectionFooter,
        ChangePassword,
        InputPhoneWithLabel,
    },
    props: {
        isFromCart: {
            type: Boolean,
            default: false,
        },
        showNotifierSuccess: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const { profile } = useUser();
        const { countryPhoneFromPhoneNumber, concatenateNumberAndCountryCode } = useCountry();
        const { notifier } = useApp();
        return {
            countryPhoneFromPhoneNumber,
            concatenateNumberAndCountryCode,
            profile,
            notifier,
        };
    },
    data() {
        return {
            isLoading: false,
            name: '',
            lastname: '',
            email: '',
            password: '',
            phone: '',
            identityDocument: '',
            birthday: '',
            genre: '',
            minDate: dayjs().subtract(90, 'year').format('YYYY-MM-DD'),
            maxDate: dayjs().subtract(15, 'year').format('YYYY-MM-DD'),
            modalMessage: '',
            showChangePassword: false,
            isPhoneAlreadyRegistered: false,
            identificationIsAlreadyRegister: false,
            incorrectPhoneNumber: false,
            showAlertDeactivateAC: false,
            phoneDuplicated: this.$t('alerts.profile_number_exist'),
            showEmptyAlert: false,
            messageError: {
                phone: '',
                identifier: '',
            },
            documentType: {
                id: 'IDENTITY_CARD',
                label: this.$t("general['dni']"),
                labelInput: this.$t("general['dni_document']"),
                value: 'IDENTITY_CARD',
            },
            documentTypes: [
                {
                    id: 'IDENTITY_CARD',
                    label: this.$t("general['dni']"),
                    labelInput: this.$t("general['dni_document']"),
                    value: 'IDENTITY_CARD',
                },
                {
                    id: 'PASSPORT',
                    label: this.$t("txt['bill__form-identifier-passport']"),
                    labelInput: this.$t("general['dni_passport']"),
                    value: 'PASSPORT',
                },
                {
                    id: 'RUC',
                    label: this.$t("general['ruc']"),
                    labelInput: this.$t("general['number_ruc']"),
                    value: 'RUC',
                },
            ],
        };
    },
    watch: {
        phone(): void {
            if (this.incorrectPhoneNumber) this.incorrectPhoneNumber = false;
        },
    },
    created() {
        this.name = this.profile.name ?? '';
        this.lastname = this.profile.lastname ?? '';
        this.email = this.profile.email ?? '';
        this.identityDocument = this.profile.document ?? '';
        this.birthday = this.profile.birthday ?? '';
        this.genre = this.profile.genre ?? '';
        this.phone = removeCountryCode(this.profile.phone);
        this.countryPhoneFromPhoneNumber(this.profile.phone);
        this.documentType = this.documentTypes[0].id;
    },
    computed: {
        ...mapGetters({
            countryCodeSelected: 'external/countryCodeSelected',
        }),
        btnIsDisable(): boolean {
            return (
                !!this.isPhoneEmpty ||
                !!this.isIdentityDocumentEmpty ||
                !!this.isBirthdayEmpty ||
                !!this.isGenreSelected ||
                this.isLoading
            );
        },
        needsPassword(): boolean {
            return this.profile.email !== this.email;
        },
        showPhoneInput(): boolean {
            if (this.isFromCart && !this.profile.phone?.length) return true;
            return !this.isFromCart;
        },
        isGenreSelected(): boolean {
            return !this.genre?.length;
        },
        isMobile(): boolean {
            return isMobileBrowser();
        },
        isNameEmpty(): boolean {
            return !this.name?.length ?? true;
        },
        isLastNameEmpty(): boolean {
            return !this.lastname?.length ?? true;
        },
        isPhoneEmpty(): boolean {
            return !this.phone?.length ?? true;
        },
        isEmailEmpty(): boolean {
            return !this.email?.length ?? true;
        },
        isIdentityDocumentEmpty(): boolean {
            return !this.identityDocument?.length ?? true;
        },
        isBirthdayEmpty(): boolean {
            return !this.birthday?.length ?? true;
        },
        isValidPhoneAndCodeCountry(): boolean {
            if (this.isPhoneEmpty) return false;
            if (!this.countryCodeSelected?.regex?.length) return true;
            const reg = new RegExp(this.countryCodeSelected.regex);
            return reg.test(this.concatenateNumberAndCountryCode(this.phone));
        },
    },
    emits: ['on-accept', 'on-close'],
    methods: {
        switchShowAlert(open: boolean) {
            this.showAlertDeactivateAC = open;
        },
        hasInvalidFields(): boolean {
            this.incorrectPhoneNumber = false;
            if (this.isFromCart) {
                return this.isPhoneEmpty || this.isIdentityDocumentEmpty;
            }
            if (!this.isValidPhoneAndCodeCountry) {
                this.incorrectPhoneNumber = true;
                return true;
            }
            return (
                this.isNameEmpty ||
                this.isLastNameEmpty ||
                this.isEmailEmpty ||
                this.isIdentityDocumentEmpty ||
                this.isBirthdayEmpty
            );
        },
        async saveChanges(): Promise<void> {
            this.messageError = {};
            if (this.isLoading) return;
            if (this.hasInvalidFields()) return;
            if (this.profile.email !== this.email && !this.password) {
                this.notifier({
                    type: 'ERROR',
                    body: this.$t("txt['edit-profile__enter-password']"),
                });
                return;
            }
            try {
                this.isLoading = true;
                this.showEmptyAlert = true;
                await this.$store.dispatch('user/editProfile', {
                    firstname: this.name,
                    lastname: this.lastname,
                    email: this.email,
                    phone: this.concatenateNumberAndCountryCode(this.phone),
                    document: this.identityDocument,
                    genre: this.genre == 'OTHER' ? null : this.genre,
                    birthdate: this.birthday,
                    password: this.password,
                    isEditingEmail: this.profile.email !== this.email,
                    documentType: this.documentType.id,
                });
                if (this.showNotifierSuccess) {
                    this.notifier({
                        type: 'SUCCESS',
                        body: this.$t("txt['profile-options__updated-success']"),
                    });
                }
                this.$emit('on-accept');
            } catch (err) {
                const _message = this.getMessageToShow(err);
                this.messageError = { ...(err?.message ?? {}) };
                if (_message.length) return;
                this.notifier({
                    type: 'ERROR',
                    body: this.modalMessage,
                });
            } finally {
                this.isLoading = false;
            }
        },

        getMessageToShow(value): string {
            this.modalMessage = this.$t('alerts.profile_form_error');
            this.isPhoneAlreadyRegistered = false;
            this.identificationIsAlreadyRegister = false;
            if (typeof value !== 'string') {
                if (value['message']) this.modalMessage = value['message']['phone'] ?? value['message']['identifier'];
                return '';
            }
            if (value?.toLowerCase() === 'the phone sent is already used by another client') {
                this.isPhoneAlreadyRegistered = true;
                this.modalMessage = this.phoneDuplicated;
                return this.isFromCart ? this.phoneDuplicated : '';
            }
            return '';
        },
        passwordChanged(value) {
            if (value === 'edit_profile') this.showChangePassword = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './edit-profile.scss';
</style>
