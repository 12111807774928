import type { SubCategories } from '@/models/product/CategoryModel';
import { CategoryModel } from '@/models/product/CategoryModel';
import { ProductModel } from '@/models/product/ProductModel';
import { CustomCategories } from '@/modules/core/errors/CustomCategories';

export default {
    getCategorySelected(state): CategoryModel {
        return state.categorySelected;
    },
    isFromSeeMoreBtn(state): CategoryModel {
        return state.isFromSeeMoreBtn;
    },
    customCategory(state): { name: CustomCategories; label: string } {
        return state.customCategory;
    },
    getSubcategorySelected(state) {
        return state.subcategorySelected;
    },
    subcategoriesList(state): Array<CategoryModel> {
        return state.subcategoriesList;
    },
    categoryProducts(state): Array<ProductModel> {
        return state.categoryProducts;
    },
    isSearchCategoryProducts(state) {
        return state.isSearchCategoryProducts;
    },
    isMobileCategoryMenuOpen(state) {
        return state.isMobileCategoryMenuOpen;
    },
    promotionSubcategories(state) {
        return state.promotionSubcategories;
    },
    categories(state) {
        return state.categories;
    },
    allSubCategories(state): SubCategories[] {
        return state.allSubCategories;
    },
    lastRetailIdFetched(state): number {
        return state.lastRetailIdFetched;
    },
};
