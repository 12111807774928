import { useStore } from 'vuex';

import { computed } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import { ResponsePurchaseModel } from '@/models/checkout/ResponsePurchaseModel';
import { ResponseOrderModel } from '@/models/checkout/ResponseOrderModel';
import { CartModel } from '@/models/cart/CartModel';
import { useCartType } from '@/modules/core/composable/useCartType';
import { OrderResumenModel } from '@/models/order/OrderResumen';

export const useXSelling = () => {
    const $store = useStore();
    const { changeToExtraItemsCart, changeToMainCart } = useCartType();

    const hasResponsePurchase = computed<ResponsePurchaseModel>(() => $store.getters['checkout/responsePurchase']);
    const cart = computed<CartModel>(() => $store.getters['extraItems/cart']);
    const products = computed<Array<ProductModel>>(() => $store.getters['extraItems/xSellingProducts']);
    const order = computed<ResponseOrderModel>(() => $store.getters['extraItems/order']);
    const canContinueToXSellingCheckout = computed<boolean>(() => $store.getters['extraItems/products'].length > 0);

    const canShowXSellingProducts = computed(() => {
        const show = !hasResponsePurchase.value && products.value?.length;
        if (show) changeToExtraItemsCart();
        return show;
    });

    const fetchXSellingProducts = async (payload?: {
        orderId?: number;
    }): Promise<{ order: OrderResumenModel; products: ProductModel[] }> => {
        const [currentOrder, productForXSelling] = await Promise.all([
            $store.dispatch('orders/getOrderResume', payload?.orderId ?? order.value.id),
            $store.dispatch('extraItems/xSellingProducts', payload?.orderId ?? order.value.id),
        ]);
        return {
            order: currentOrder,
            products: productForXSelling,
        };
    };

    const setXSellingProduct = (products: ProductModel[], order: OrderResumenModel) => {
        $store.commit('extraItems/xSellingProducts', products);
        if (!products?.length) return;
        $store.commit('extraItems/order', order);
        $store.commit('payments/paymentIdSelected', null, { root: true });
        $store.commit('payments/defaultPaymentSelected', null, { root: true });
        $store.dispatch('segment/viewXSellingProducts', products, { root: true });
    };

    const closeXSellingProducts = (isSkip: boolean): void => {
        $store.dispatch('segment/xSellingClose', { isSkip, cart: cart.value });
        $store.commit('extraItems/xSellingProducts', []);
        $store.commit('extraItems/order');
        $store.commit('orders/orderDetailSelected');
        changeToMainCart();
    };

    const analyticsCheckout = (): void => {
        if (!cart.value) return;
        $store.dispatch('segment/viewXSellingOrder', {
            products: cart.value.retailers[0].products,
            total_xselling_cost: cart.value.cartExtraItemAmount,
        });
    };

    return {
        fetchXSellingProducts,
        setXSellingProduct,
        closeXSellingProducts,
        canContinueToXSellingCheckout,
        products,
        order,
        canShowXSellingProducts,
        analyticsCheckout,
        hasResponsePurchase,
    };
};
