class ProductImageModel {
    low: string;
    medium: string;
    high: string;

    constructor(low?: string, medium?: string, high?: string) {
        this.low = low;
        this.medium = medium;
        this.high = high;
    }
}
const toProductImageModel = (data: object): ProductImageModel => {
    return data ? new ProductImageModel(data['small'], data['medium'], data['original']) : null;
};

export { ProductImageModel, toProductImageModel };
