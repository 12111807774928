<template>
    <div class="story-container">
        <icon-circular-close class="icon-close cursor-pointer" :size="1" @click="$emit('on-close')" />
        <div ref="container" class="story-container__content">
            <div class="story-container__bar">
                <progress
                    v-for="(element, index) in selectedStory.elements"
                    :key="index"
                    :max="duration"
                    :value="counterByElement?.[index] ?? 0"
                />
            </div>
            <div class="content__story-miniature" :style="fontStyle">
                <img :src="selectedStory?.iconStory" alt="" />
                <p>{{ selectedStory?.title }}</p>
            </div>
            <div class="gradient"></div>
            <interaction v-if="selectedStory" />
            <img
                v-if="elementIsImage"
                class="story-container__file cursor-pointer"
                :src="file"
                alt=""
                @click="goToStory"
            />
            <video
                v-else
                class="story-container__file cursor-pointer"
                :src="file"
                :volume="volume"
                autoplay
                @click="goToStory"
            />
            <icon-mute
                v-if="!elementIsImage()"
                class="icon-mute"
                :color="iconSoundColor"
                :size="0.25"
                @click="muteAction"
            />
        </div>
        <icon-back
            class="story-container__back cursor-pointer"
            :icon-name="$t('icon.backward')"
            :size="0.7"
            background-fill="#FFFFFF90"
            @click="goLastElement"
        />
        <icon-back
            class="story-container__next cursor-pointer"
            :icon-name="$t('icon.forward')"
            :size="0.7"
            background-fill="#FFFFFF90"
            @click="goNextElement"
        />
    </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import IconBack from '@/components/icons/IconBack.vue';
import Interaction from './components/interaction/Interaction.vue';
import IconMute from '@/components/icons/IconMute.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import { useStory } from '@/composables/useStory';

export default {
    name: 'StoryContainer',
    components: {
        IconBack,
        Interaction,
        IconMute,
        IconCircularClose,
    },
    emits: ['on-close'],
    setup() {
        const { goToStoryScreen } = useStory();

        return {
            goToStoryScreen,
        };
    },
    data() {
        return {
            index: 0,
            idInterval: 0,
            idTimer: 0,
            counter: 0,
            counterByElement: [],
            isPaused: false,
            soundStatus: 'this.muted = true',
            iconSoundColor: '#FFF',
            volume: 0,
            differenceTimeClicking: 0,
        };
    },
    computed: {
        ...mapGetters({
            selectedStory: 'stories/selectedStory',
            stories: 'stories/stories',
            selectedIndex: 'stories/selectedIndex',
            informativeStory: 'stories/informativeStory',
        }),
        file(): string {
            return this.selectedStory?.elements[this.index]?.file;
        },
        duration(): number {
            return this.selectedStory?.elements[this.index]?.durationTime * 1000;
        },
        fontStyle() {
            return {
                '--font-color': this.selectedStory?.titleColor ?? '#FFF',
            };
        },
    },
    watch: {
        selectedStory() {
            clearInterval(this.idTimer);
            clearInterval(this.idInterval);
            if (!this.selectedStory) return;
            this.registerTracking('VIEW');
            this.index = 0;
            this.counterByElement.length = 0;
            this.startShowing();
            this.time();
            this.fireStoryEvent();
        },
    },
    mounted() {
        this.counterByElement.length = 0;
        this.registerTracking('VIEW');
        this.events();
        this.startShowing();
        this.time();
        this.fireStoryEvent();
    },
    unmounted() {
        clearInterval(this.idTimer);
        clearInterval(this.idInterval);
    },
    methods: {
        fireStoryEvent(): void {
            this.$store.dispatch('segment/storiesOpened', {
                stories: {
                    story_id: this.selectedStory.id,
                    story_type: this.selectedStory.type,
                    story: this.selectedStory.title,
                },
            });
        },
        muteAction(): void {
            this.volume === 0 ? (this.volume = 1) : (this.volume = 0);
            this.iconSoundColor === '#FFF' ? (this.iconSoundColor = '#303030') : (this.iconSoundColor = '#FFF');
        },
        async registerTracking(type: string) {
            if (type === 'VIEW') this.$store.dispatch('stories/setAsViewed', this.selectedStory?.elements[0]?.id);
        },
        goToStory(): void {
            if (this.differenceTimeClicking >= 0.2) return;
            if (this.selectedStory?.type === 'INFORMATIVE') {
                this.$store.commit('stories/informativeStory', this.selectedStory);
                this.isPaused = true;
                return;
            }
            this.registerTracking('ENTER');
            this.$store.commit('deeplink/showStory', false);
            this.goToStoryScreen(this.selectedStory);
        },
        async events(): Promise<void> {
            const slider = this.$refs.container;
            let onMouseUpTime;
            let onMouseDownTime;
            slider.onmousedown = async (): Promise<void> => {
                onMouseUpTime = new Date();
                this.isPaused = true;
            };
            slider.onmouseup = async (): Promise<void> => {
                onMouseDownTime = new Date();
                this.differenceTimeClicking = (onMouseDownTime.getTime() - onMouseUpTime.getTime()) / 1000;
                this.isPaused = false;
            };
        },
        elementIsImage(): boolean {
            const url = this.selectedStory?.elements[this.index]?.file;
            return (
                url.endsWith('.jpg') ||
                url.endsWith('.jpeg') ||
                url.endsWith('.png') ||
                url.endsWith('.bmp') ||
                url.endsWith('.gif')
            );
        },
        time(): void {
            this.counter = 0;
            this.counterByElement.push(this.counter);
            this.idTimer = setInterval(() => {
                if (!this.isPaused) {
                    if (this.counterByElement[this.index] >= this.duration) {
                        this.counterByElement[this.index] = this.duration;
                    } else this.counterByElement[this.index] = this.counterByElement[this.index] + 10;
                }
            }, 10);
        },
        startShowing: function () {
            this.idInterval = setInterval(() => {
                if (!this.isPaused) {
                    this.index += 1;
                    const size = +this.selectedStory?.elements.length;
                    if (this.index >= size) this.goNextStory();
                    else {
                        this.counterByElement[this.index - 1] = this.duration;
                        clearInterval(this.idTimer);
                        this.time();
                        this.$store.dispatch('stories/setAsViewed', this.selectedStory?.elements[this.index]?.id);
                    }
                }
            }, this.duration);
        },
        goNextElement(): void {
            this.counterByElement[this.index] = this.duration;
            this.index += 1;
            const size = +this.selectedStory?.elements.length;
            if (this.index >= size) this.goNextStory();
            else {
                this.$store.dispatch('stories/setAsViewed', this.selectedStory?.elements[this.index]?.id);
                clearInterval(this.idTimer);
                this.time();
            }
        },
        goLastElement(): void {
            if (this.index === 0) this.goLastStory();
            else {
                this.index -= 1;
                this.counterByElement.splice(this.index, this.counterByElement.length);
                clearInterval(this.idTimer);
                this.time();
            }
        },
        goNextStory(): void {
            if (this.selectedIndex === this.stories.length - 1) this.$emit('on-close');
            const nextStory =
                this.selectedIndex + 1 < this.stories.length ? this.stories[this.selectedIndex + 1] : this.stories[0];
            const nextIndex = this.selectedIndex + 1 < this.stories.length ? this.selectedIndex + 1 : 0;
            this.$store.commit('stories/selectedStory', nextStory);
            this.$store.commit('stories/selectedIndex', nextIndex);
        },
        goLastStory(): void {
            if (this.selectedIndex === 0) this.$emit('on-close');
            const lastStory =
                this.selectedIndex - 1 < 0
                    ? this.stories[this.stories.length - 1]
                    : this.stories[this.selectedIndex - 1];
            const lastIndex = this.selectedIndex - 1 < 0 ? this.stories.length - 1 : this.selectedIndex - 1;
            this.$store.commit('stories/selectedStory', lastStory);
            this.$store.commit('stories/selectedIndex', lastIndex);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../the-stories-carousel.scss';
@import './story-container.scss';
</style>
