<template>
    <section>
        <base-card class="addresses-checkout" padding="0">
            <section-header
                class="addresses-checkout__header"
                :title="$t('txt.address-user__saved-addresses')"
                bg-color="#FF9012"
                btn-back-color="#fff"
                color-title="#FFF"
                has-back
                @on-back="onBack"
            />
            <div class="addresses-checkout__addresses">
                <addresses show-check @on-check="address"></addresses>
            </div>
        </base-card>
        <teleport to="body">
            <section-footer class="addresses-checkout__footer">
                <btn-out-line :txt="$t('cta.continue')" txt-color="#FFF" @click="saveAddress" />
            </section-footer>
        </teleport>
    </section>
    <loading-modal v-if="isLoading" :title="$t('txt.change_address')" has-header />
</template>
<script lang="ts">
import { AddressModel } from '@/models/address/AddressModel';
import { mapGetters } from 'vuex';
import { logger } from '@/utils/logger';
import BaseCard from '@/components/cards/BaseCard.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import Addresses from '@/views/addresses/Addresses.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import LoadingModal from '@/components/modal/loadingModal/LoadingModal.vue';
import { useCheckoutEvents } from '@/views/checkout/composables/useCheckoutEvents';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export default {
    name: 'addresses-checkout',
    components: {
        BaseCard,
        Addresses,
        SectionHeader,
        BtnOutLine,
        SectionFooter,
        LoadingModal,
    },
    setup() {
        const { openCheckoutAddress } = useCheckoutEvents();
        const { fetchRetailersSchedules } = useRetailer();
        openCheckoutAddress();

        return {
            fetchRetailersSchedules,
        };
    },
    data() {
        return {
            isLoading: false,
            tmpAddress: null,
            disableButtonPayment: true,
        };
    },
    computed: {
        ...mapGetters({
            addressDefault: 'location/addressDefault',
            locationSelected: 'user/userLocation',
        }),
    },
    methods: {
        address(address: AddressModel): void {
            this.tmpAddress = address;
        },
        async saveAddress(): Promise<void> {
            try {
                if (this.isLoading || this.locationSelected === this.tmpAddress) return;
                this.isLoading = true;
                this.$store.commit('checkout/cleanTimeDelivery');
                this.$store.commit('checkout/resetStockItemTimeDelivery');
                const _previousCountry = { ...this.$store.getters['external/countrySelected'] };
                this.$store.commit('external/countrySelected', this.tmpAddress.city?.country);
                await this.$store.dispatch('user/baseConfiguration');
                if (_previousCountry.id !== this.tmpAddress.city?.country?.id) {
                    await this.$store.dispatch('cart/fetchQuickCart');
                    this.$store.dispatch('external/updateCareTeamChatByCountry');
                }
                this.$store.commit('user/userLocation', this.tmpAddress ?? this.addressDefault);
                await this.$store.dispatch('location/updateCurrentSector', {
                    address: this.tmpAddress ?? this.addressDefault,
                    sector: this.tmpAddress?.sector ?? this.addressDefault.sector,
                    screen: this.$route.path,
                    section: 'CHECKOUT',
                });
                this.$store.commit('lists/disableButtonContinuetoCheckout', true);
                await this.$store.dispatch('location/getUnavailableProductsByLocation');
                await this.$store.dispatch('cart/fetchCart');
                await this.fetchRetailersSchedules().catch((err) => logger('FETCH_RETAILERS_SCHEDULES', err));
                this.$router.go(-1);
            } catch (err) {
                logger('SAVE_ADDRESS', err);
            } finally {
                this.isLoading = false;
            }
        },
        async onBack(): Promise<void> {
            await this.fetchRetailersSchedules().catch((err) => logger('FETCH_RETAILERS_SCHEDULES', err));
            this.$router.go(-1);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.addresses-checkout {
    display: flex;
    flex-direction: column;
    @include tablet {
        border-radius: $radius-none !important;
    }
}

.addresses-checkout__header {
    @include tablet {
        display: none;
    }
}

.addresses-checkout__addresses {
    overflow: auto;
    @include tablet {
        max-height: fit-content;
    }
}

.addresses-checkout__footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
