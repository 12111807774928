<template>
    <label class="custom-file-upload" for="file-upload">
        <input id="file-upload" ref="fileRef" accept=".jpg,.jpeg,.png,.pdf" type="file" @change="onFile" />
        <btn-circular>
            <icon-gallery :size="0.5" />
        </btn-circular>
    </label>
</template>

<script lang="ts" setup>
import { compressFile } from '@/utils/picturesUtils';
import { logger } from '@/utils/logger';

import IconGallery from '../icons/IconGallery.vue';
import BtnCircular from './BtnCircular.vue';
import { ref, watch } from 'vue';

defineOptions({
    name: 'TheBtnPicture',
});

const $emit = defineEmits<{
    (e: 'on-file', value: File);
}>();

const fileRef = ref<HTMLInputElement>();

watch(
    () => fileRef.value?.files,
    (newFile, oldValue) => {
        if (!oldValue?.length) return;
        if (!newFile?.length) return $emit('on-file', null);
    },
);
const onFile = async (value: Event): Promise<void> => {
    let file: File | void = (value.target as HTMLInputElement).files[0];
    const type: string = file?.type?.split('/')?.pop();
    if (file && ['png', 'jpg', 'jpeg'].includes(type)) file = await compressFile(file).catch((_) => logger('ON_FILE'));
    $emit('on-file', file as File);
};
</script>

<style lang="scss" scoped>
input[type='file'] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 2.5rem;
    cursor: pointer;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

.custom-file-upload {
    transition: all 200ms ease-in-out;
    cursor: pointer !important;
    position: relative;
    display: inline-block;
}
</style>
