import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-035d0b5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-global-ref", "data-ref"]
const _hoisted_2 = {
  key: 0,
  class: "card-product__sovi"
}
const _hoisted_3 = {
  class: "card-product__name",
  itemprop: "name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_sovi = _resolveComponent("icon-sovi")!
  const _component_base_image = _resolveComponent("base-image")!
  const _component_card_product_price = _resolveComponent("card-product-price")!
  const _component_PercentageDiscount = _resolveComponent("PercentageDiscount")!
  const _component_the_product_action_button = _resolveComponent("the-product-action-button")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(["card-product", { 'cursor-pointer': $props.isForReplacement }]),
    "data-global-ref": $props.productData.productId,
    "data-ref": $props.productData.stockItemId,
    itemscope: "",
    itemtype: "http://schema.org/Product",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args)))
  }, [
    ($props.productData?.isSovi)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_icon_sovi, { size: 0.7 }),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('general.featured')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_base_image, {
        class: _normalizeClass(["card-product__img cursor-pointer", { 'card-product__img--mini': $props.isMiniature }]),
        "alternative-text": $props.productData?.name,
        source: $options.imageSource,
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.openCard ? _ctx.openDetailProduct() : null))
      }, null, 8, ["class", "alternative-text", "source"]),
      _createElementVNode("h3", _hoisted_3, _toDisplayString($props.productData?.name), 1)
    ]),
    _createVNode(_component_card_product_price, { "product-data": $props.productData }, null, 8, ["product-data"]),
    _createVNode(_component_PercentageDiscount, { product: $props.productData }, null, 8, ["product"]),
    _renderSlot(_ctx.$slots, "action", {}, () => [
      ($props.showCounter)
        ? (_openBlock(), _createBlock(_component_the_product_action_button, {
            key: 0,
            class: "product-actions",
            "fetch-cart": $props.fetchCart,
            "is-for-replacement": $props.isForReplacement,
            "is-miniature": $props.isMiniature,
            "is-search-by-stock-item": $props.isSearchByStockItem,
            "product-data": $props.productData,
            section: $props.section,
            onOnGmItems: _cache[1] || (_cache[1] = (id) => _ctx.$emit('on-gm-items', id))
          }, null, 8, ["fetch-cart", "is-for-replacement", "is-miniature", "is-search-by-stock-item", "product-data", "section"]))
        : _createCommentVNode("", true)
    ], true)
  ], 10, _hoisted_1))
}