<template>
    <section>
        <section-header :has-back="true" :title="programSelected.name" :with-borders="false" @on-back="onBack" />
        <header-program />
        <div class="registration">
            <div class="registration__header">
                <p class="registration__text">
                    {{ pageLabel.description }}
                </p>
                <img
                    v-if="pageLabel.image?.length"
                    class="card-program__img"
                    :alt="programSelected.name"
                    :src="pageLabel.image"
                />
            </div>
            <div class="registration-form-inputs">
                <dynamic-form
                    :invalid="invalidFiled"
                    :required="requiredFields"
                    :schema="formFields"
                    :value="formData"
                    @form-values="updateValues"
                />
            </div>
            <btn-text :text="locale.t('general.terms-and-conditions')" @click="showTermsAndConditions = true" />
            <btn-solid :txt="pageLabel.callToActionSuccess" @click="generateSubscription" />
        </div>
    </section>
    <modal-loading-manager
        v-if="showModal"
        :body="loadingModalBody"
        :cta-text="locale.t('general.keep_shopping')"
        :has-close="!isLoading"
        :has-header="true"
        :is-failure="isFailure"
        :is-loading="isLoading"
        :is-success="isSuccess"
        :show-action="isFailure"
        :title="modalTitle"
        @on-action="onAction"
        @on-close="handleCloseModal"
    />
    <alert-pdf
        v-if="showTermsAndConditions"
        :accent-header="true"
        :title="locale.t('general.terms-and-conditions')"
        :url="programSelected?.termsAndConditions"
        @close="showTermsAndConditions = false"
    />
</template>
<script lang="ts">
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnText from '@/components/buttons/BtnText.vue';
import DynamicForm from '@/components/forms/DynamicForm.vue';
import ModalLoadingManager from '@/components/alerts/modalLoadingManager/ModalLoadingManager.vue';
import AlertPdf from '@/components/alerts/alertPdf/AlertPdf.vue';
import { useStore } from 'vuex';
import { logger } from '@/utils/logger';
import HeaderProgram from '@/views/myAccount/views/profile/views/loyaltyPrograms/HeaderProgram.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { ResponseContent } from '@/models/loyaltyPrograms/ResponseContent';
import dayjs from 'dayjs';
import { useCountry } from '@/composables/useCountry';
import { useFormHelper } from '@/composables/useFormHelper';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'RegistrationScreen',
    components: { SectionHeader, HeaderProgram, AlertPdf, ModalLoadingManager, BtnSolid, BtnText, DynamicForm },
    setup() {
        const store = useStore();
        const router = useRouter();
        const loyaltyStore = store.state.loyaltyAndRewards;
        const programSelected = loyaltyStore.program;
        const locale = useI18n();
        const { concatenateNumberAndCountryCode } = useCountry();
        const showModal = ref(false);
        const isLoading = ref(false);
        const isFailure = ref(false);
        const isSuccess = ref(false);
        const showTermsAndConditions = ref(false);
        const loadingModalBody = ref('');

        const modalTitle = computed(() => {
            return isFailure.value ? locale.t('general.ups') : locale.t('txt.loyalty_program');
        });
        const pageLabel = computed(() => {
            return programSelected.customLabelStructure.find((labels) => labels.pageName === 'registration_form');
        });

        const formHelper = useFormHelper(programSelected.registerSchema, loyaltyStore.registrationTempData);
        formHelper.formatInitialData();

        const onAction = (): void => {
            router.replace({
                name: appRoutesMap.home,
            });
        };
        const onBack = (): void => {
            router.go(-1);
        };
        const handleCloseModal = (): void => {
            if (isLoading.value) return;
            if (isSuccess.value)
                router.push({
                    name: appRoutesMap.reward.benefitScreen,
                    params: {
                        id: programSelected.id,
                    },
                });
            showModal.value = false;
        };
        const formatDate = (data): object => {
            //*** use this variable to hide numeric phone conversion
            const dataToSend = Object.assign({}, data);
            const properties = programSelected.registerSchema.properties;
            if (
                !!properties['birth_date'] &&
                properties['birth_date']['format'] === 'date' &&
                !!properties['birth_date']['str_format']
            )
                dataToSend['birth_date'] = dayjs(dataToSend['birth_date']).format(
                    properties['birth_date']['str_format'].toUpperCase(),
                );
            if (dataToSend['phone_number'])
                dataToSend['phone_number'] = concatenateNumberAndCountryCode(dataToSend['phone_number']);
            return dataToSend;
        };
        const generateSubscription = async (): Promise<void> => {
            try {
                if (formHelper.isInvalidForm()) {
                    window.scrollTo(0, 0);
                    return;
                }
                isFailure.value = false;
                isSuccess.value = false;
                isLoading.value = true;
                loadingModalBody.value = locale.t('general.validating_data');
                showModal.value = true;
                const registrationResult: ResponseContent = await store.dispatch(
                    'loyaltyAndRewards/registerSubscription',
                    {
                        programId: programSelected.id,
                        body: formatDate(formHelper.formData.value),
                    },
                );
                if (!registrationResult.is_subscribed) {
                    isFailure.value = true;
                    loadingModalBody.value = registrationResult.message?.description?.length
                        ? registrationResult.message?.description
                        : locale.t('general.error_occurred');
                    return;
                }
                await store.dispatch('loyaltyAndRewards/programs');
                store.commit('loyaltyAndRewards/reloadProgram', programSelected.id);
                isSuccess.value = true;
                loadingModalBody.value = registrationResult.message?.description?.length
                    ? registrationResult.message?.description
                    : locale.t('txt.loyalty_program__applied', {
                          program: programSelected.name,
                      });
            } catch (err) {
                logger('generateSubscription', err);
                loadingModalBody.value = err.message?.description?.length
                    ? err.message?.description
                    : locale.t('general.error_occurred');
                isFailure.value = true;
            } finally {
                isLoading.value = false;
            }
        };

        const updateValues = (fieldName: string, newFormData: object, field: object) => {
            formHelper.updateValues(fieldName, newFormData, field);
        };

        return {
            onAction,
            handleCloseModal,
            onBack,
            pageLabel,
            modalTitle,
            programSelected,
            formData: formHelper.formData,
            showModal,
            isLoading,
            isFailure,
            isSuccess,
            locale,
            showTermsAndConditions,
            invalidFiled: formHelper.invalidFiled,
            requiredFields: formHelper.requiredFields,
            loadingModalBody,
            formFields: formHelper.formFields,
            updateValues,
            generateSubscription,
        };
    },
};
</script>
<style lang="scss" scoped>
@import 'registration-screen';
</style>
