<template>
    <div class="card-menu" :class="{ 'card-menu__no-data': !billUsed }">
        <icon-bill v-if="billUsed" :size="0.7" color="#FF9012" />
        <div v-if="billUsed" class="card-menu__content">
            <h2>{{ $t("general['billing-data']") }}</h2>
            <p>{{ billUsed.identifier }}</p>
        </div>
        <div v-else class="card-menu__content">
            <p class="text-no-selected">{{ $t("general['billing-data']") }}</p>
            <p class="text-no-selected">{{ $t("txt['checkout__enter-billing']") }}</p>
        </div>

        <button :class="{ 'text-no-selected': !billUsed }" @click="showModalBill = true">
            {{ $t(!billUsed ? "general['select']" : "cta['change']") }}
        </button>
    </div>
    <BillsCheckout />
</template>

<script lang="ts" setup>
import IconBill from '@/components/icons/IconBill.vue';
import { storeToRefs } from 'pinia';
import { useBillStore } from '@/store/useBillStore';
import { useUser } from '@/composables/useUser';
import BillsCheckout from '@/views/checkout/views/billsCheckout/BillsCheckout.vue';
import { useBill } from '@/views/bills/composableBill';

const $billStore = useBillStore();
const { showModalBill } = useBill();

const { alphaCode } = useUser();
const { billUsed } = storeToRefs($billStore);
defineEmits<(e: 'on-change-selected') => void>();

if (!billUsed.value?.id) $billStore.getAll(alphaCode.value);
</script>

<style lang="scss" scoped>
@import '../../menu-checkout.scss';
</style>
