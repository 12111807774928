import { BannersGoalModel } from '@/models/promotions/banners/BannerGoalModel';
import { BannerModel } from '@/models/promotions/banners/BannerModel';

export default {
    informativeBanner(state): BannerModel {
        return state.informativeBanner;
    },
    bannerSelected(state): BannerModel {
        return state.bannerSelected;
    },
    bannerGoal(state): BannersGoalModel {
        return state.bannerGoal;
    },
    isLastPage(state): boolean {
        return state.isLastPage;
    },
};
