class NutritionalColorModel {
    readonly sugar: string;
    readonly fat: string;
    readonly salt: string;

    constructor(sugar: string, fat: string, salt: string) {
        this.sugar = sugar;
        this.fat = fat;
        this.salt = salt;
    }
}
const toNutritionalColorModel = (data: object) => {
    return data && Object.prototype.hasOwnProperty.call(data, 'sugar')
        ? new NutritionalColorModel(data['sugar'], data['fat'], data['salt'])
        : null;
};

export { NutritionalColorModel, toNutritionalColorModel };
