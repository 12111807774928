import { ListSubgroupModel } from '@/models/list/ListSubgroupModel';
import { AdModel } from '@/models/ads/AdModel';
import { RecipeDetailModel } from '@/models/recipes/RecipeDetailModel';
import { ListModel } from '@/models/list/ListModel';
import { ListDetailItemModel } from '@/models/list/ListDetailModel';
import { ListCartModel } from '@/models/list/ListCartModel';

export default {
    disableButtonContinuetoCheckout(state, value: boolean) {
        state.disableButtonContinuetoCheckout = value;
    },

    list(state, list: ListModel) {
        state.list = list;
    },
    setSubgroups(state, subgroups: Array<ListSubgroupModel>) {
        state.subgroups = subgroups;
    },
    listDetail(state, listDetail: Array<ListDetailItemModel>) {
        state.listDetail = listDetail;
    },
    recipeAds(state, recipeAds: Array<AdModel>) {
        state.recipeAds = recipeAds;
    },
    recipeDetail(state, recipeDetail: Array<RecipeDetailModel>) {
        state.recipeDetail = recipeDetail;
    },
    isCategoryFromList(state, isCategoryFromList: boolean) {
        state.isCategoryFromList = isCategoryFromList;
    },
    listIdToAddFromCategories(state, listId: number) {
        state.listIdToAddFromCategories = listId;
    },
    isFromDeeplink(state, fromDeeplink: boolean) {
        state.isFromDeeplink = fromDeeplink;
    },
    activeOption(state, option: string) {
        state.activeOption = option;
    },
    localCart(state, payload) {
        if (!payload) {
            state.localCart = [];
            return;
        }
        let listCartUpdated = [];
        const productIsAlreadyAdded = state.localCart.some(item => item.product.id === payload.product.id);
        if (productIsAlreadyAdded) {
            listCartUpdated = state.localCart.map(item => {
                if (item.product.id === payload.product.id) item.product = payload.product;
                return item;
            });
            state.localCart = listCartUpdated;
        } else {
            listCartUpdated.push(new ListCartModel(payload.listIds, payload.product));
            state.localCart = listCartUpdated.concat(state.localCart);
        }
    },
    removeProductList(state, product) {
        state.localCart = state.localCart.filter(item => item.product.id !== product.id);
    },
};
