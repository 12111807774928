import { useStore } from 'vuex';
import { computed } from 'vue';
import { CartModel } from '@/models/cart/CartModel';
import { useXSelling } from '@/modules/extraItems/composables/useXSelling';
import { OrderModel } from '@/models/order/OrderModel';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';

export const useExtraItems = () => {
    const $store = useStore();

    const order = computed<OrderModel>(() => $store.getters['extraItems/order']);
    const cart = computed<CartModel>(() => $store.getters['extraItems/cart']);
    const paymentsToShow = computed<Array<PaymentToShowModel>>(() => $store.getters['payments/paymentsToShow']);

    const canContinue = computed<boolean>(
        () =>
            cart.value?.retailers[0].products.length > 0 &&
            cart.value?.retailers[0].products.length <= order.value?.numberOfExtraItemsAllowed &&
            paymentsToShow.value?.length > 0,
    );

    const buyProducts = async (isXSelling = true): Promise<void> => {
        if (!order.value || !cart.value) return;
        if (!cart.value?.retailers?.length || !cart.value.cartExtraItemAmount) return;
        await $store.dispatch('extraItems/addExtraItems', {
            orderId: order.value.id,
            isXSelling,
        });
    };

    return {
        order,
        cart,
        canContinue,
        buyProducts,
        ...useXSelling(),
        paymentsToShow,
    };
};
