import { baseOrderState } from '@/utils/orderUtils';
import { BaseOrderState } from './BaseOrderState';
import { ShopperModel, toShopperModel } from './ShopperModel';

class HistoryModel {
    readonly date: Date;
    readonly state: string;
    readonly base: BaseOrderState;
    readonly shopper: ShopperModel;

    constructor(date: Date, state: string, base: BaseOrderState, shopper: ShopperModel) {
        this.date = date;
        this.state = state;
        this.base = base;
        this.shopper = shopper;
    }
}

const toHistoryModel = (data: object): HistoryModel => {
    return data
        ? new HistoryModel(
              new Date(data['date']),
              data['state'],
              baseOrderState(data['state']),
              toShopperModel(data['shopper']),
          )
        : null;
};
export { HistoryModel, toHistoryModel };
