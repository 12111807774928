import { ProductModel, toProductModel } from '@/models/product/ProductModel';

class PromotionsCategoriesModel {
    id: number;
    name: string;
    icon: string;
    stockitems: Array<ProductModel>;
    categoryId: number;

    constructor(id: number, name: string, icon: string, stockitems: Array<ProductModel>, categoryId: number) {
        this.id = id;
        this.name = name;
        this.icon = icon;
        this.stockitems = stockitems;
        this.categoryId = categoryId;
    }
}

const toPromotionsCategoriesModel = (data: object) => {
    return data
        ? new PromotionsCategoriesModel(
              data['id'],
              data['name'] ?? '',
              data['icon'] ?? '',
              data['stockitems']?.map((product: object) => toProductModel(product)) ?? [],
              data['category_id'],
          )
        : null;
};

export { PromotionsCategoriesModel, toPromotionsCategoriesModel };
