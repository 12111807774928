class TaxModel {
    percent: number;
    value: number;
    name: string;
    isServiceTax: boolean;

    constructor(percent: number, value: number, name: string, isServiceTax: boolean) {
        this.percent = percent;
        this.value = value;
        this.name = name;
        this.isServiceTax = isServiceTax;
    }
}

const toTaxModel = (data: object) =>
    data
        ? new TaxModel(
              data['percent'] ?? 0.0,
              data['value'] ?? 0.0,
              data['name'] ?? '',
              data['shipping_service'] ?? false,
          )
        : null;

export { toTaxModel, TaxModel };
