<template>
    <base-icon :icon-name="$t('icon.featured')" :width="width" :height="height" viewBox="0 0 16 11" :iconColor="color">
        <path
            d="M0.827615 11C0.936317 11.0001 1.04397 10.9789 1.1444 10.9374C1.24483 10.8959 1.33605 10.8351 1.41282 10.7584L6.06898 6.11672L7.96653 8.00838C8.04338 8.08499 8.13461 8.14576 8.23503 8.18723C8.33543 8.22869 8.44305 8.25003 8.55174 8.25003C8.66042 8.25003 8.76804 8.22869 8.86845 8.18723C8.96886 8.14576 9.06009 8.08499 9.13694 8.00838L14.3445 2.81707L14.3442 4.675C14.3442 4.8938 14.4314 5.10365 14.5866 5.25836C14.7418 5.41308 14.9523 5.5 15.1718 5.5C15.3913 5.5 15.6018 5.41308 15.757 5.25836C15.9122 5.10365 15.9994 4.8938 15.9994 4.675L16 0.825C16 0.606196 15.9128 0.396354 15.7576 0.241637C15.6024 0.0869194 15.3919 0 15.1724 0H11.3104C11.0909 0 10.8804 0.0869194 10.7252 0.241637C10.57 0.396354 10.4828 0.606196 10.4828 0.825C10.4828 1.0438 10.57 1.25365 10.7252 1.40836C10.8804 1.56308 11.0909 1.65 11.3104 1.65H13.1745L8.55174 6.25828L6.65419 4.36662C6.57734 4.29001 6.48611 4.22924 6.3857 4.18777C6.28529 4.14631 6.17767 4.12497 6.06898 4.12497C5.9603 4.12497 5.85268 4.14631 5.75227 4.18777C5.65186 4.22924 5.56063 4.29001 5.48378 4.36662L0.242409 9.59162C0.126663 9.707 0.0478389 9.854 0.0159038 10.014C-0.0160312 10.1741 0.000357755 10.34 0.0629982 10.4907C0.125639 10.6415 0.231717 10.7703 0.367818 10.861C0.503919 10.9516 0.66393 11 0.827615 11Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-sovi',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C7C7C7',
        },
    },
    computed: {
        width(): number {
            return 16 * this.size;
        },
        height(): number {
            return 11 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
