import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_google = _resolveComponent("icon-google")!

  return (_openBlock(), _createElementBlock("button", {
    class: "btn-google",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.handleCredentialResponse && $options.handleCredentialResponse(...args)))
  }, [
    _createVNode(_component_icon_google, { size: 1.4 })
  ]))
}