<template>
    <aside class="prime-options" v-if="primeDeals">
        <base-card
            v-for="deal in primeDeals"
            class="prime-option"
            :key="deal.id"
            padding="1rem"
            @click="selectPrime(deal)"
        >
            <div v-if="isSelected(deal)" class="prime-option--selected"></div>
            <header class="prime-header">
                <div class="prime-header__discount-badge" v-if="isPromotion(deal)">
                    <p>{{ deal.discountRate }}%</p>
                    <p>OFF</p>
                </div>
                <h3 class="prime-header__title">{{ deal.name }}</h3>
                <icon-check class="prime-header__selected" v-if="isSelected(deal)"></icon-check>
            </header>
            <div class="prime-content">
                <p class="prime-content__price" :class="{ 'prime-content__price--discount': isPromotion(deal) }">
                    {{ `${currency} ${deal.priceMonth}` }}
                </p>
                <p v-if="isPromotion(deal)" class="prime-content__old-price">
                    {{ currency }}
                    {{ valueToShowWithoutDiscount(deal) }}
                </p>
                <p class="payment-plan">
                    {{ paymentPlan(deal) }} -
                    <span class="payment-plan__price" :class="{ 'payment-plan__price--discount': isPromotion(deal) }">{{
                        `${currency}${deal.price} `
                    }}</span>
                    <span v-if="isPromotion(deal)" class="payment-plan__old-price"
                        >{{ `${currency}${valueWithoutDiscount(deal)}` }}
                    </span>
                </p>
            </div>
        </base-card>
    </aside>
</template>

<script lang="ts">
import { TiptiCardDealsModel } from '@/models/tiptiCard/TiptiCardDealsModel';
import { mapGetters } from 'vuex';

import BaseCard from '@/components/cards/BaseCard.vue';
import IconCheck from '@/components/icons/IconCheck.vue';

export default {
    name: 'tipti-prime-options',
    data() {
        return {
            frequency: {
                MONTHS: 'MONTHS',
                YEAR: 'YEAR',
            },
        };
    },
    components: {
        BaseCard,
        IconCheck,
    },
    computed: {
        ...mapGetters({
            primeDeals: 'tiptiCard/primeDeals',
            defaultPrime: 'tiptiCard/defaultPrime',
            primeSelected: 'tiptiCard/primeSelected',
            countrySelected: 'external/countrySelected',
        }),
        prime(): TiptiCardDealsModel {
            return this.primeSelected ?? this.defaultPrime;
        },
        currency(): string {
            return this.countrySelected?.currencySymbol ?? '$';
        },
    },
    methods: {
        valueToShowWithoutDiscount(deal: TiptiCardDealsModel): string {
            return deal?.periodTypeDuration === this.frequency.YEAR
                ? (+this.valueWithoutDiscount(deal) / 12).toFixed(2)
                : this.valueWithoutDiscount(deal);
        },
        paymentPlan(deal: TiptiCardDealsModel): string {
            return this.$t(
                deal.periodTypeDuration === this.frequency.YEAR
                    ? 'txt.prime__annual-plan-payment'
                    : 'txt.prime__monthly-plan-payment',
            );
        },
        isPromotion(deal: TiptiCardDealsModel): boolean {
            return deal.discountRate > 0;
        },
        isSelected(deal: TiptiCardDealsModel): boolean {
            return this.prime.id === deal.id;
        },
        selectPrime(deal: TiptiCardDealsModel): void {
            this.$store.commit('tiptiCard/primeSelected', deal);
        },
        valueWithoutDiscount(deal: TiptiCardDealsModel): string {
            return ((deal?.price * 100) / (100 - deal?.discountRate))?.toFixed(2);
        },
    },
};
</script>

<style lang="scss" scoped>
@import './tipti-prime-options.scss';
</style>
