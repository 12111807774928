import { RouteRecordRaw } from 'vue-router';
import { breadcrumbDefinition } from '@/components/breadcrumb/BreadcumbDefinition';
import { appRoutesMap } from '@/router/appRoutesMap';

export const OrderRoutes: RouteRecordRaw = {
    name: appRoutesMap.OrdersPage,
    path: 'my-orders',
    component: () => import('./MyOrders.vue'),
    redirect: { name: appRoutesMap.OrderMap.PlaceHolderPage },
    meta: {
        require_auth: true,
        breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.orders],
    },
    children: [
        {
            name: appRoutesMap.OrderMap.PlaceHolderPage,
            path: appRoutesMap.OrderMap.PlaceHolderPage,
            component: () => import('./views/orderPlaceholder/OrderPlaceholder.vue'),
            meta: {
                require_auth: true,
                breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.orders],
            },
        },
        {
            name: appRoutesMap.OrderMap.OrderPage,
            path: `:orderId`,
            redirect: { name: appRoutesMap.OrderMap.SummaryPage },
            component: () => import('./views/order/Order.vue'),
            meta: {
                require_auth: true,
                isInOrderPage: true,
                breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.orders, breadcrumbDefinition.order],
            },
            children: [
                {
                    name: appRoutesMap.OrderMap.SummaryPage,
                    path: appRoutesMap.OrderMap.SummaryPage,
                    redirect: { name: appRoutesMap.OrderMap.CartTab },
                    component: () => import('./views/order/views/summaryOrder/SummaryOrder.vue'),
                    meta: {
                        require_auth: true,
                        isInOrderPage: true,

                        breadcrumbs: [
                            breadcrumbDefinition.account,
                            breadcrumbDefinition.orders,
                            breadcrumbDefinition.order,
                        ],
                    },
                    children: [
                        {
                            name: appRoutesMap.OrderMap.StateTab,
                            path: appRoutesMap.OrderMap.StateTab,
                            component: () =>
                                import('./views/order/views/summaryOrder/tabs/orderStateTab/OrderStateTab.vue'),
                            meta: {
                                require_auth: true,
                                isInOrderPage: true,

                                breadcrumbs: [
                                    breadcrumbDefinition.account,
                                    breadcrumbDefinition.orders,
                                    breadcrumbDefinition.order,
                                ],
                            },
                        },
                        {
                            name: appRoutesMap.OrderMap.CartTab,
                            path: appRoutesMap.OrderMap.CartTab,
                            component: () => import('./views/order/views/summaryOrder/tabs/myCartTab/MyCartTab.vue'),
                            meta: {
                                require_auth: true,
                                isInOrderPage: true,

                                breadcrumbs: [
                                    breadcrumbDefinition.account,
                                    breadcrumbDefinition.orders,
                                    breadcrumbDefinition.order,
                                ],
                            },
                        },
                        {
                            name: appRoutesMap.OrderMap.ChatTab,
                            path: appRoutesMap.OrderMap.ChatTab,
                            meta: {
                                require_auth: true,
                                isInOrderPage: true,

                                breadcrumbs: [
                                    breadcrumbDefinition.account,
                                    breadcrumbDefinition.orders,
                                    breadcrumbDefinition.order,
                                ],
                            },
                            component: () =>
                                import('./views/order/views/summaryOrder/tabs/chatTab/SuspensedChatTab.vue'),
                        },
                    ],
                },
            ],
        },
    ],
};
