<template>
    <base-icon :icon-name="$t('icon.help')" :width="width" :height="height" viewBox="0 0 38 38" :iconColor="color">
        <path
            d="M18.4113 25.4727C17.8822 25.4727 17.3747 25.6828 17.0006 26.057C16.6265 26.4311 16.4163 26.9385 16.4163 27.4676C16.4163 27.9967 16.6265 28.5042 17.0006 28.8783C17.3747 29.2524 17.8822 29.4627 18.4113 29.4627C18.9404 29.4627 19.4478 29.2524 19.8219 28.8783C20.1961 28.5042 20.4063 27.9967 20.4063 27.4676C20.4063 26.9385 20.1961 26.4311 19.8219 26.057C19.4478 25.6828 18.9404 25.4727 18.4113 25.4727Z"
        />
        <path
            d="M18.7428 10.3867C15.2506 10.3867 13.647 12.4558 13.647 13.8542C13.648 14.0542 13.6895 14.2519 13.7689 14.4354C13.8483 14.6189 13.9639 14.7845 14.1089 14.9222C14.2539 15.0599 14.4252 15.1669 14.6126 15.2368C14.8 15.3066 14.9995 15.3378 15.1993 15.3286C16.5958 15.3286 16.0277 13.3374 18.6668 13.3374C19.9607 13.3374 20.9943 13.9074 20.9943 15.0968C20.9943 16.4933 19.5446 17.2951 18.6915 18.0209C18.1129 18.4933 17.6543 19.0959 17.3531 19.7795C17.0519 20.463 16.9168 21.2081 16.9587 21.9539C16.9587 23.2991 17.3216 23.6867 18.3818 23.6867C19.6491 23.6867 19.9018 23.1167 19.9018 22.6265C19.8052 22.0056 19.8907 21.37 20.148 20.7967C20.4053 20.2234 20.8233 19.7371 21.3515 19.3965C22.1483 18.864 22.8201 18.1649 23.3203 17.3474C23.8206 16.5299 24.1374 15.6136 24.249 14.6617C24.2528 12.2278 22.0621 10.3867 18.7428 10.3867Z"
        />
        <path
            d="M19 2.3776e-06C16.5045 -0.00124629 14.0333 0.489351 11.7275 1.44375C9.42179 2.39814 7.32675 3.79763 5.56219 5.56219C3.79763 7.32675 2.39814 9.42179 1.44375 11.7275C0.489351 14.0333 -0.00124629 16.5045 2.3776e-06 19V36.5161C2.3776e-06 36.9097 0.156341 37.2871 0.434627 37.5654C0.712912 37.8437 1.09035 38 1.4839 38H19C24.0391 38 28.8718 35.9982 32.435 32.435C35.9982 28.8718 38 24.0391 38 19C38 13.9609 35.9982 9.12816 32.435 5.56497C28.8718 2.00178 24.0391 2.3776e-06 19 2.3776e-06ZM19 35.0322H2.9697V19C2.9697 15.8291 3.91002 12.7293 5.67175 10.0928C7.43347 7.45627 9.93747 5.40138 12.8671 4.188C15.7967 2.97462 19.0203 2.65724 22.1303 3.276C25.2403 3.89476 28.097 5.42186 30.339 7.6642C32.5811 9.90653 34.1079 12.7634 34.7263 15.8734C35.3447 18.9835 35.0269 22.2071 33.8132 25.1366C32.5994 28.066 30.5443 30.5698 27.9075 32.3312C25.2708 34.0926 22.1709 35.0326 19 35.0322Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-help',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C7C7C7',
        },
    },
    computed: {
        width(): number {
            return 18 * this.size;
        },
        height(): number {
            return 18 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
