<template>
    <section class="wire-transfer">
        <h2 class="title">{{ $t("txt['wire-transfer__select-your-bank']") }}</h2>
        <base-loading v-if="isLoading" />
        <banks :banks="banks" :first-bank-selected="bankSelected" @on-selected-bank="selectBank" />
        <p class="wire-transfer__note">{{ $t("txt['transfer__once-your-order']") }}</p>
        <base-card-flat v-if="bankSelected?.id" class="flex-column">
            <h3 class="bank-name" :style="{ color: bankSelected.color }">
                {{ bankSelected.name }}
            </h3>
            <horizontal-divider height="0.5rem" />
            <div v-for="label in bankLabels" :key="label.title" class="bank-labels">
                <h4>{{ label.title }}:</h4>
                <p>{{ label.tag }}</p>
            </div>
            <horizontal-divider height="0.5rem" />
            <div class="bank-adjunt-img">
                <div>
                    <p class="bank-adjunt-img__msg">{{ $t("txt['payment-transfer__attach-your-receipt']") }}</p>
                    <p class="bank-adjunt-img__format">{{ $t("txt['payment-transfer__extension_allowed']") }}</p>
                </div>
                <the-btn-picture @on-file="onFileChange" />
            </div>
            <p v-if="nameImg" class="img-selected">{{ nameImg }}</p>
        </base-card-flat>
        <p class="wire-transfer__remember">{{ $t('txt.transfer__once-your-order') }}</p>
    </section>
</template>

<script lang="ts" setup>
import { BanksModel } from '@/models/paymentMethods/BanksModel';
import { CashWirePaymentModel, WireTransferPaymentModel } from '@/models/checkout/CashWirePaymentModel';
import { logger } from '@/utils/logger';

import BaseLoading from '@/components/loading/BaseLoading.vue';
import HorizontalDivider from '@/components/dividers/HorizontalDivider.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import TheBtnPicture from '@/components/buttons/TheBtnPicture.vue';
import Banks from '@/components/banks/Banks.vue';
import { usePayments } from '@/composables/usePayments';
import { useStore } from 'vuex';
import { inject, reactive, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { notifierKey } from '@/modules/notification/useNotifier';

defineOptions({
    name: 'CashWireTransfer',
});
const notifier = inject(notifierKey);
const $store = useStore();
const { t: $t } = useI18n();

const { isMixPaymentTiptiCard, isMixInstitutionalPayment, secondPayment, totalToPay, cleanPaymentMethods } =
    usePayments();
const isLoading = ref(false);
const banks = shallowRef<BanksModel[]>([]);
const bankSelected = ref<BanksModel>();
const nameImg = ref('');

const bankLabels = reactive([
    { title: $t("general['name']"), tag: '' },
    { title: $t("general['ruc']"), tag: '' },
    { title: $t("txt['payment-transfer__number-account']"), tag: '' },
    { title: $t("txt['payment-transfer__type-account']"), tag: '' },
]);
const fetchBanks = async (): Promise<void> => {
    try {
        isLoading.value = true;
        banks.value = await $store.dispatch('payments/banks');
        selectBank(banks.value?.at(0));
    } catch (err) {
        logger('FETCH_BANKS', err);
    } finally {
        isLoading.value = false;
    }
};

const selectBank = (bank: BanksModel): void => {
    bankSelected.value = bank;
    bankLabels[0].tag = bank?.accountName ?? '';
    bankLabels[1].tag = bank?.ruc ?? '';
    bankLabels[2].tag = bank?.accountNumber ?? '';
    bankLabels[3].tag = !bank
        ? ''
        : bank.accountType === 'CURRENT_ACCOUNT'
          ? $t("txt['payment-transfer__wire-transfer-checking']")
          : $t("txt['payment-transfer__wire-transfer-savings']");
};

const onFileChange = (file: File) => {
    try {
        if (!bankSelected.value) return;
        nameImg.value = file?.name;
        cleanPaymentMethods();
        if (!file) return;
        $store.commit(
            'payments/cashWirePayment',
            new CashWirePaymentModel(
                null,
                new WireTransferPaymentModel(bankSelected.value.id, file, bankSelected.value.accountType),
            ),
        );
        $store.commit(
            'payments/paymentsToShow',
            new PaymentToShowModel({
                name: 'payment-wire-transfer',
                amount:
                    isMixPaymentTiptiCard.value || isMixInstitutionalPayment.value
                        ? secondPayment.value
                        : totalToPay.value,
                analyticData: {
                    selected_bank_name: bankSelected.value?.name,
                },
                objectData: [
                    'payment-wire-transfer',
                    new WireTransferPaymentModel(bankSelected.value.id, file, bankSelected.value.accountType),
                ],
            }),
        );
        notifier({
            type: 'SUCCESS',
            title: $t("txt['payment-transfer__voucher-was-successfully']"),
        });
    } catch (err) {
        nameImg.value = '';
        notifier({
            type: 'ERROR',
            title: $t('txt.payment-transfer__voucher-not-be'),
        });
    }
};
fetchBanks();
</script>

<style lang="scss" scoped>
@import './wire-transfer-payment.scss';
</style>
