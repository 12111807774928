import { BannerModel } from '../promotions/banners/BannerModel';
import { RetailerModel } from '../../modules/retailers/models/RetailerModel';
import { ProductImageModel, toProductImageModel } from '@/models/product/ProductImageModel';

class ProductsFlowModel {
    isStory: boolean;
    retailers: RetailerModel;
    productId: number;
    banner: BannerModel;
    productName: string;
    productCover: ProductImageModel;

    constructor(
        isStory: boolean,
        retailers?: RetailerModel,
        productId?: number,
        banner?: BannerModel,
        productName?: string,
        productCover?: object,
    ) {
        this.isStory = isStory;
        this.retailers = retailers;
        this.productId = productId;
        this.banner = banner;
        this.productName = productName;
        this.productCover = toProductImageModel(productCover);
    }
}

export default ProductsFlowModel;
