<template>
    <base-icon
        :icon-name="$t('icon.credit_card')"
        :width="width"
        :height="height"
        viewBox="0 0 33 33"
        :iconColor="color"
    >
        <path
            d="M26.027 8.50052C26.257 8.50052 26.487 8.34644 26.487 8.03827C26.487 7.88418 26.4103 7.73009 26.257 7.65305L9.38799 0.179892C8.39118 -0.282366 7.16435 0.179892 6.70428 1.18145L0.800132 14.4328C0.340068 15.4344 0.800132 16.6671 1.79694 17.1293L5.24741 18.5931C5.55412 18.7472 5.93751 18.5931 6.09086 18.285C6.09086 18.2079 6.16754 18.1309 6.16754 18.0538V12.4297C6.16754 10.2725 7.93112 8.50052 10.0781 8.50052H26.027Z"
        />
        <path
            d="M30.4743 10.3496H10.0781C8.92794 10.3496 8.00781 11.2741 8.00781 12.4298V26.9138C8.00781 28.0695 8.92794 28.994 10.0781 28.994H12.8385C12.7618 28.6088 12.7618 28.1465 12.7618 27.7613C12.7618 27.299 12.8385 26.8368 12.9152 26.3745H10.6148V19.4407H29.9375V26.3745H27.7139C27.7906 26.8368 27.8672 27.299 27.8672 27.7613C27.8672 28.1465 27.8672 28.6088 27.7906 28.994H30.5509C31.7011 28.994 32.6212 28.0695 32.6212 26.9138V12.3527C32.5445 11.2741 31.6244 10.3496 30.4743 10.3496ZM29.9375 15.1263H10.6148V12.9691H29.9375V15.1263Z"
        />
        <path
            d="M20.2771 22.3682C17.3633 22.3682 14.9863 24.7565 14.9863 27.6841C14.9863 30.6118 17.3633 33.0001 20.2771 33.0001C23.1908 33.0001 25.5678 30.6118 25.5678 27.6841C25.5678 24.7565 23.1908 22.3682 20.2771 22.3682ZM21.8106 29.7643C21.5806 30.0725 21.2739 30.2265 20.8905 30.3036C20.7371 30.3036 20.6604 30.4577 20.6604 30.6118C20.6604 30.7658 20.6604 30.9199 20.6604 31.074C20.6604 31.1511 20.5838 31.3051 20.5071 31.3051C20.5071 31.3051 20.5071 31.3051 20.4304 31.3051C20.2771 31.3051 20.1237 31.3051 19.8937 31.3051C19.7403 31.3051 19.6636 31.2281 19.6636 31.074V30.7658C19.6636 30.5347 19.6636 30.5347 19.4336 30.4577C19.1269 30.3806 18.8202 30.3036 18.5135 30.2265C18.2835 30.1495 18.2835 30.0725 18.3601 29.8413C18.4368 29.6872 18.4368 29.4561 18.5135 29.302C18.5902 29.0709 18.5902 29.0709 18.8202 29.1479C19.1269 29.302 19.4336 29.3791 19.817 29.4561C20.047 29.4561 20.2771 29.4561 20.5071 29.3791C20.7371 29.302 20.8905 28.9939 20.8138 28.7627C20.8138 28.6857 20.7371 28.6086 20.6604 28.5316C20.5838 28.4546 20.4304 28.3775 20.2771 28.3005C19.8937 28.1464 19.587 27.9923 19.2803 27.8382C18.7435 27.6071 18.4368 27.0678 18.4368 26.4514C18.4368 25.7581 18.8969 25.2187 19.5103 25.0647C19.817 24.9106 19.817 24.9106 19.817 24.6024V24.2942C19.817 24.0631 19.8937 24.0631 20.047 24.0631H20.2771C20.7371 24.0631 20.7371 24.0631 20.7371 24.5254C20.7371 24.8335 20.7371 24.8335 21.0438 24.9106C21.2739 24.9876 21.5039 24.9876 21.7339 25.1417C21.8106 25.1417 21.8873 25.2958 21.8873 25.3728C21.8106 25.604 21.8106 25.7581 21.7339 25.9892C21.6573 26.2203 21.5806 26.2203 21.4272 26.1433C21.0438 25.9892 20.6604 25.9121 20.2771 25.9121C20.2004 25.9121 20.047 25.9121 19.9704 25.9892C19.7403 26.0662 19.6636 26.2974 19.6636 26.5285C19.6636 26.6055 19.7403 26.6826 19.817 26.7596C19.9704 26.8367 20.1237 26.9907 20.2771 27.0678C20.5838 27.2219 20.8905 27.2989 21.1972 27.453C21.964 27.8382 22.2707 28.8398 21.8873 29.6102C21.8873 29.6102 21.8873 29.6872 21.8106 29.7643Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-credit-card',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 20 * this.size;
        },
        height(): number {
            return 20 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
