<template>
    <subheader-separator
        v-if="recipesAds?.length"
        :name="$t('general.tipti-recipes')"
        has-padding
        @on-pressed="goToSeeMore"
    />
    <carousel-recipe-ads
        :isLastPage="isLastPage"
        :isLoading="isLoading"
        :recipes="recipesAds"
        @reached-end="updateProducts"
    />
</template>
<script lang="ts">
import { logger } from '@/utils/logger';
import { ResultsAdsForRetailer } from '@/store/modules/lists/actions';
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';
import CarouselRecipeAds from '@/components/carousels/CarouselRecipeAds.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'the-recipes',
    data() {
        return {
            recipesAds: [],
            page: 1,
            isLoading: false,
            isLastPage: false,
        };
    },
    components: {
        SubheaderSeparator,
        CarouselRecipeAds,
    },
    methods: {
        updateProducts() {
            if (this.isLoading || this.isLastPage) return;
            this.page += 1;
            this.fecthRecipesAds();
        },
        goToSeeMore() {
            this.$store.commit('lists/activeOption', 'Recetas Tipti');
            this.$router.push({
                name: appRoutesMap.list.recipesPage,
                query: {
                    name: this.$t('general.tipti-recipes'),
                },
            });
        },
        async fecthRecipesAds(): Promise<void> {
            try {
                this.isLoading = true;
                const results: ResultsAdsForRetailer = await this.$store.dispatch(
                    'lists/getRecipeAdsForRetailer',
                    this.page,
                );
                this.isLastPage = results.isLastPage;
                this.recipesAds.push(...results.adRecipesForRetailer);
            } catch (err) {
                logger('FETCH_RECIPE_ADS', err);
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted(): void {
        this.fecthRecipesAds();
        if (!this.isLastPage) this.updateProducts();
    },
};
</script>
