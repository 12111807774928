class ListModel {
    readonly id: number;
    readonly name: string;
    readonly itemsCount: number;
    readonly dateCreated: Date;
    readonly subscriptionList: boolean;
    readonly repetitiveList: boolean;
    readonly nextSubscription: string;
    readonly period: number;
    readonly periodType: string;
    readonly subscriptionPeriod: string;
    readonly subscriptionPeriodType: string;
    readonly subscriptionNextOrder: string;
    readonly selected: boolean;
    readonly listDeepLink: string;
    readonly isForFavorites: boolean;

    constructor(
        id: number,
        name: string,
        itemsCount: number,
        dateCreated: Date,
        subscriptionList: boolean,
        repetitiveList: boolean,
        nextSubscription: string,
        period: number,
        periodType: string,
        subscriptionPeriod: string,
        subscriptionPeriodType: string,
        subscriptionNextOrder: string,
        selected: boolean,
        listDeepLink: string,
        isForFavorites: boolean,
    ) {
        this.id = id;
        this.name = name;
        this.itemsCount = itemsCount;
        this.dateCreated = dateCreated;
        this.subscriptionList = subscriptionList;
        this.repetitiveList = repetitiveList;
        this.nextSubscription = nextSubscription;
        this.period = period;
        this.periodType = periodType;
        this.subscriptionPeriod = subscriptionPeriod;
        this.subscriptionPeriodType = subscriptionPeriodType;
        this.subscriptionNextOrder = subscriptionNextOrder;
        this.selected = selected;
        this.listDeepLink = listDeepLink;
        this.isForFavorites = isForFavorites;
    }
}
const toListModel = (data: object): ListModel => {
    return data
        ? new ListModel(
              data['id'],
              data['name'],
              data['items_count'],
              data['date_created'] ? new Date(data['date_created']) : null,
              data['subscription_list'],
              data['repetitive_list'],
              data['next_subscription'],
              data['period'],
              data['period_type'],
              data['sus_period'],
              data['sus_period_type'],
              data['sus_next_order'],
              data['selected'],
              data['list_deep_link'],
              data['is_for_favorites'] ?? false,
          )
        : null;
};

export { ListModel, toListModel };
