<script lang="ts" setup>
import RetailerDataTile from '@/modules/retailers/components/shared/RetailerDataTile.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import type { PropType } from 'vue';
import { computed } from 'vue';
import { logger } from '@/utils/logger';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { usePromotion } from '@/composables/usePromotion';
import { useCart } from '@/composables/useCart';
import { useApp } from '@/composables/useApp';
import ProductsFlowModel from '@/models/deeplinks/ProductsFlowModel';
import { DeepLink } from '@/enums/deeplinks';
import ImageLoader from '@/modules/core/components/loaders/ImageLoader.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

const props = defineProps({
    retailer: {
        type: Object as PropType<RetailerModel>,
        required: true,
    },
    isLoading: {
        type: Boolean,
        required: true,
    },
});

const $emit = defineEmits<(e: 'update:isLoading', value: boolean) => void>();
const { $store, $route, $router } = useApp();

const productsFlow = computed<ProductsFlowModel | undefined>({
    get: () => $store.getters['deeplink/productsFlow'],
    set: (value) => $store.commit('deeplink/productsFlow', value),
});

const { goToRetailer, retailerSelected } = useRetailer();
const { goToPromoProductsScreen, isBannerInSection, track } = usePromotion();
const { products: cartProducts } = useCart();

const showAdvisorModal = (): void => {
    $store.dispatch('deeplink/showSnackBar', { type: DeepLink.product, isSuccess: false });
};
const openModalProduct = async (): Promise<void> => {
    try {
        if (props.isLoading) return;
        $emit('update:isLoading', true);
        retailerSelected.value = props.retailer;
        $store.dispatch('segment/retailSelected', {
            screen: $route.path,
            section: 'DEEP_LINK',
        });
        if (productsFlow.value.isStory) {
            $store.commit('deeplink/productsFlow', null);
            $store.commit('deeplink/showStory', true);
            return;
        }
        if (productsFlow.value?.banner) {
            goToRetailer({ name: props.retailer.name });
            await $store.dispatch('shareData/getRetailerPromotionsAndBenefits');
            if (!productsFlow.value?.banner) return showAdvisorModal();
            if (isBannerInSection.value) {
                await track({
                    id_object: productsFlow.value?.banner?.id,
                    resource_action: 'VIEW',
                    resource_type: 'BANNER_TRACK',
                });
            }
            await goToPromoProductsScreen(productsFlow.value?.banner);
            $store.commit('deeplink/productsFlow', null);
            return;
        }
        const [_, productResult] = await Promise.allSettled([
            goToRetailer({ name: props.retailer.name }),
            productsFlow.value.productId
                ? $store.dispatch('product/getProductFromProductId', {
                      productId: productsFlow.value.productId,
                      retailerId: props.retailer.id,
                  })
                : Promise.resolve(),
        ]);
        if (!productsFlow.value.productId || productResult.status === 'rejected') return showAdvisorModal();
        const { value: product } = productResult;
        if (!product?.available) return showAdvisorModal();
        $store.dispatch('shareData/getRetailerPromotionsAndBenefits');
        const localProduct = cartProducts.value.find((localProduct) => localProduct?.id == product.id);
        product.productQuantity = localProduct?.productQuantity ?? 0;
        $store.commit('product/productSelected', product);
        $store.commit('deeplink/productsFlow', null);
        $router.push({
            name: appRoutesMap.product,
            params: {
                name: product.name,
            },
        });
    } catch (err) {
        logger('OPEN_MODAL_PRODUCT', err);
        showAdvisorModal();
    } finally {
        $emit('update:isLoading', false);
    }
};

const retailerBanner = computed(() => props.retailer?.franchise?.icon ?? props.retailer?.icon);
</script>

<template>
    <button v-if="retailer" class="modal-retailers-card__retailer" @click="openModalProduct">
        <ImageLoader class="modal-retailers-card__img" :alt-text="retailer.name" :delay="0" :src="retailerBanner" />
        <retailer-data-tile :delivery-range="retailer.deliveryRange" :name="retailer?.name" />
        <icon-arrow :size="0.5" />
    </button>
</template>

<style lang="scss" scoped>
@import './retailers-modal.scss';
</style>
