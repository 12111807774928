import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ae7ebec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "google-maps" }
const _hoisted_2 = { class: "label_note_map" }
const _hoisted_3 = { class: "label_note_map__address" }
const _hoisted_4 = {
  key: 1,
  class: "google-maps__card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Polygon = _resolveComponent("Polygon")!
  const _component_Marker = _resolveComponent("Marker")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!
  const _component_countries_options = _resolveComponent("countries-options")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_card_address = _resolveComponent("card-address")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_GoogleMap, _mergeProps({
      ref: "tiptiMap",
      class: ["google-maps__map", { 'google-maps__map--fit': $props.isFlexible }],
      "api-key": $data.apiKey,
      "disable-default-ui": false,
      "fullscreen-control": false,
      "keyboard-shortcuts": false,
      "map-type-control": false,
      "pan-control": false,
      "street-view-control": false,
      "zoom-control": false,
      "gesture-handling": "greedy",
      "map-type-id": "roadmap"
    }, $data.config), {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.polygons, (polygon, index) => {
          return (_openBlock(), _createBlock(_component_Polygon, {
            key: index,
            options: {
                    ...$data.polygonsOptions,
                    paths: polygon?.paths,
                }
          }, null, 8, ["options"]))
        }), 128)),
        _createVNode(_component_Marker, {
          options: {
                    draggable: true,
                    position: $data.markerCenter,
                },
          onDragend: $options.ondrag
        }, null, 8, ["options", "onDragend"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("general['select_address_note']")), 1)
        ])
      ]),
      _: 1
    }, 16, ["class", "api-key"]),
    _createElementVNode("div", {
      class: _normalizeClass(["google-maps__locator", { 'google-maps__locator--loading': $setup.isRelocating }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.relocate && $options.relocate(...args)))
    }, null, 2),
    ($props.showDropdownCountries)
      ? (_openBlock(), _createBlock(_component_base_card, {
          key: 0,
          class: "google-maps__countries dropdown-country",
          padding: "0.7rem"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_countries_options, { "btn-color": "#FF9012" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($props.hasCreationCard)
      ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
          _createVNode(_component_card_address, {
            "address-marker": $data.addressMarker,
            "is-edit-address": $props.isEditAddress,
            "is-from-register": $props.isFromRegister,
            "polygon-index": $data.polygonIndex,
            section: $props.section,
            onOnSavePosition: $options.getAddress,
            onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close')))
          }, null, 8, ["address-marker", "is-edit-address", "is-from-register", "polygon-index", "section", "onOnSavePosition"])
        ]))
      : _createCommentVNode("", true)
  ]))
}