<template>
    <general-alert
        v-if="!isMobile"
        :is-loading="isLoading"
        :is-disabled="isDisabled"
        :show-buttons="showButtons"
        :onlyConfirmationBtn="onlyConfirmationBtn"
        :accent-header="accentHeader"
        :title="title"
        :acceptButtonTxt="acceptButtonText ?? this.$t('cta.accept')"
        :declineButtonTxt="declineButtonText ?? this.$t('cta.reject')"
        @on-close="$emit('on-close')"
        @on-close-modal="$emit('on-close-modal')"
        @on-accept="$emit('on-accept')"
        @on-decline="$emit('on-decline')"
        :showCloseIcon="showCloseIcon"
        :padding="padding"
    >
        <template v-slot:content>
            <slot name="content" />
        </template>
    </general-alert>
    <base-bottom-sheet
        v-else
        bg-color="#fff"
        @on-dismiss="$emit('on-dismiss')"
        :cta-header-text="ctaHeaderText"
        @on-cta-press="$emit('on-omit')"
    >
        <template v-slot:content>
            <h1 v-if="showBottomSheetTitle" class="bottom-sheet__title">
                {{ title }}
            </h1>
            <slot name="content" />
            <section
                v-if="showButtons"
                class="bottom-sheet__btns"
                :class="{
                    'bottom-sheet__btns--one-btn': bottomSheetOnlyConfirmationBtn || onlyConfirmationBtn,
                }"
                :style="style"
            >
                <btn-out-line
                    v-if="!onlyConfirmationBtn && !bottomSheetOnlyConfirmationBtn"
                    bgColor="#FFF"
                    color="#FF9012"
                    :txt="declineButtonText ?? $t('cta.reject')"
                    @click="$emit('on-decline')"
                />
                <btn-solid
                    :isDisabled="isDisabled"
                    :isLoading="isLoading"
                    :txt="acceptButtonText ?? $t('cta.accept')"
                    @click="$emit('on-accept')"
                />
            </section>
            <slot name="content-bottom" />
        </template>
    </base-bottom-sheet>
</template>

<script lang="ts">
import { isMobileBrowser } from '@/utils/utils';

import GeneralAlert from '../generalAlert/GeneralAlert.vue';
import BaseBottomSheet from '../../bottomSheet/BaseBottomSheet.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';

export default {
    name: 'alert-manager',
    emits: ['on-close', 'on-close-modal', 'on-accept', 'on-decline', 'on-dismiss', 'on-omit'],
    props: {
        isDisabled: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        acceptButtonText: {
            type: String,
        },
        declineButtonText: {
            type: String,
        },
        title: {
            type: String,
            default: '',
        },
        hasPaddingBtn: {
            type: Boolean,
            default: false,
        },
        ctaHeaderText: {
            type: String,
            default: '',
        },
        onlyConfirmationBtn: {
            type: Boolean,
            default: false,
        },
        bottomSheetOnlyConfirmationBtn: {
            type: Boolean,
            default: false,
        },
        showButtons: {
            type: Boolean,
            default: false,
        },
        showCloseIcon: {
            type: Boolean,
            default: true,
        },
        showBottomSheetTitle: {
            type: Boolean,
            default: true,
        },
        padding: {
            type: String,
        },
        btnXPadding: {
            type: Number,
            default: 0,
        },
        btnYPadding: {
            type: Number,
            default: 0,
        },
        accentHeader: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        GeneralAlert,
        BaseBottomSheet,
        BtnOutLine,
        BtnSolid,
    },
    computed: {
        isMobile() {
            return isMobileBrowser();
        },
        style(): object {
            return {
                padding: `${this.btnYPadding}rem ${this.btnXPadding}rem`,
            };
        },
    },
};
</script>
<style lang="scss" scoped>
@import './alert-manager.scss';
</style>
