<template>
    <div v-if="isMobile" class="my-account-container">
        <profile-options v-if="isScreenMyAccount" />
        <router-view v-else />
    </div>
    <div v-else class="my-account-container">
        <profile-options />
        <router-view />
    </div>
</template>

<script lang="ts" setup>
import ProfileOptions from './components/profileOptions/ProfileOptions.vue';
import { useApp } from '@/composables/useApp';
import { useRoutes } from '@/composables/useRoutes';
import { appRoutesMap } from '@/router/appRoutesMap';

const { isMobile, $router } = useApp();
const { isScreenMyAccount } = useRoutes();
if (!isMobile.value && isScreenMyAccount.value) $router.replace({ name: appRoutesMap.account.statistics });
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.my-account-container {
    display: grid;
    grid-template-columns: 1fr 65%;
    box-sizing: border-box;
    margin: $margin-md;
    margin-left: 0;
    grid-column-gap: $margin-md;
    padding-left: 0;
    @include tablet {
        display: flex;
        height: fit-content;
        flex-direction: column;
        margin: $margin-md;
    }
    @include phone {
        margin: unset;
    }
}
</style>
