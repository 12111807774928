<template>
    <div class="progress-bar">
        <p v-currency.noFix="0" />
        <progress-status :bg-color="backgroundColor" :progress="progress" />
        <p v-currency="minimumAmount" />
    </div>
</template>

<script lang="ts">
import ProgressStatus from '@/components/progressBar/ProgressStatus.vue';

export default {
    name: 'progress-bar',
    components: { ProgressStatus },
    props: {
        minimumAmount: {
            default: 0,
            type: Number,
        },
        progress: {
            default: 0,
            type: Number,
        },
        backgroundColor: {
            default: '#ffffff',
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.progress-bar {
    display: flex;
    justify-content: space-between;
    padding: $padding-xsm;
    & p {
        @include body2;
        font-weight: 600;
        width: 3rem;
        text-align: center;
    }
}
</style>
