<template>
    <article
        class="card-product"
        :class="{ 'cursor-pointer': isForReplacement }"
        :data-global-ref="productData.productId"
        :data-ref="productData.stockItemId"
        itemscope
        itemtype="http://schema.org/Product"
        @click="onClick"
    >
        <div v-if="productData?.isSovi" class="card-product__sovi">
            <icon-sovi :size="0.7" />
            <p>{{ $t('general.featured') }}</p>
        </div>
        <div>
            <base-image
                class="card-product__img cursor-pointer"
                :class="{ 'card-product__img--mini': isMiniature }"
                :alternative-text="productData?.name"
                :source="imageSource"
                @click="openCard ? openDetailProduct() : null"
            />
            <h3 class="card-product__name" itemprop="name">{{ productData?.name }}</h3>
        </div>
        <card-product-price :product-data="productData" />
        <PercentageDiscount :product="productData" />
        <slot name="action">
            <the-product-action-button
                v-if="showCounter"
                class="product-actions"
                :fetch-cart="fetchCart"
                :is-for-replacement="isForReplacement"
                :is-miniature="isMiniature"
                :is-search-by-stock-item="isSearchByStockItem"
                :product-data="productData"
                :section="section"
                @on-gm-items="(id: number) => $emit('on-gm-items', id)"
            />
        </slot>
    </article>
</template>

<script lang="ts">
import { ProductModel } from '@/models/product/ProductModel';
import BaseImage from '@/components/logos/Image.vue';
import IconSovi from '@/components/icons/IconSovi.vue';
import productCard from '@/mixings/productCards';
import TheProductActionButton from '@/components/buttons/TheProductActionButton.vue';
import type { PropType } from 'vue';
import { inject } from 'vue';
import {
    deactivateProductKey,
    deactivateProductKeyDefaultVValue,
} from '@/modules/productSuggested/composables/useDeativatedProduct';
import CardProductPrice from '@/components/cards/cardProduct/CardProductPrice.vue';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import PercentageDiscount from '@/components/cards/cardProduct/PercentageDiscount.vue';

export default {
    name: 'CardProduct',
    components: {
        PercentageDiscount,
        CardProductPrice,
        TheProductActionButton,
        IconSovi,
        BaseImage,
    },
    mixins: [productCard],
    props: {
        fetchCart: {
            type: Boolean,
            default: false,
        },
        /// *** [isForReplacement] modify [CardProduct] to show results of replacement search
        isForReplacement: {
            type: Boolean,
            default: false,
        },
        isMiniature: {
            type: Boolean,
            default: false,
        },
        isSearchByStockItem: {
            type: Boolean,
            default: false,
        },
        openCard: {
            type: Boolean,
            default: true,
        },
        productData: {
            type: Object as PropType<ProductModel>,
            required: true,
        },
        section: {
            type: String,
            required: false,
        },
        showCounter: {
            type: Boolean,
            default: true,
        },
        trackItemSection: {
            /// * Used for local tracking
            type: String,
        },
        trackResourceType: {
            /// * Used for local tracking
            type: String,
        },
    },
    emits: ['on-replacement', 'on-gm-items'],
    setup() {
        //*** this property is used in productCard mixin
        const deactivatedProduct = inject<ProductModel>(deactivateProductKey, deactivateProductKeyDefaultVValue);
        const retailer = inject<RetailerModel | undefined>('retailKey', undefined);
        return {
            retailer,
            deactivatedProduct,
        };
    },
    data() {
        return {
            product: this.productData,
            snackBarMessage: '',
            loading: false,
        };
    },
    computed: {
        imageSource(): string {
            return (
                this.productData?.image?.medium ?? this.productData?.media?.find((img) => img.is_cover)?.resource ?? ''
            );
        },
    },
    methods: {
        onClick(): void {
            if (this.isForReplacement && this.productData?.available) return this.$emit('on-replacement');
        },
    },
};
</script>

<style lang="scss" scoped>
@import './card-product.scss';
</style>
