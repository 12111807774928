<template>
    <base-modal
        :auto-size="!isMobile"
        :has-back-arrow="isMobile"
        :has-close="!isMobile"
        :is-full-modal="isMobile"
        :title="modalTitle"
        has-header
        @on-back-arrow="closeModal"
        @on-close="closeModal"
    >
        <base-loading v-if="isLoading" />
        <template v-else>
            <div v-if="productsFlow?.productName" class="modal-retailers__subheader">
                <base-image :has-padding="false" :source="productsFlow.productCover.low" />
                <p class="modal-retailers__subheader-text">
                    {{ $t('txt.retailers-modal__you_may_find') }}
                    <span class="modal-retailers__subheader-text font-weight-600">
                        {{ productsFlow.productName }}
                    </span>
                    {{ $t('txt.retailers-modal__in_the_following') }}
                </p>
            </div>
            <div class="modal-retailers-card">
                <RetailerBannerButton
                    v-for="retailer in productsFlow?.retailers"
                    :key="retailer?.id"
                    v-model:is-loading="isLoading"
                    :retailer="retailer"
                />
            </div>
        </template>
    </base-modal>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BaseImage from '@/components/logos/Image.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { computed, ref } from 'vue';
import { useApp } from '@/composables/useApp';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import ProductsFlowModel from '@/models/deeplinks/ProductsFlowModel';
import RetailerBannerButton from '@/modules/retailers/components/modal/RetailerBannerButton.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'RetailersModal',
});

const $store = useStore();
const $route = useRoute();
const $router = useRouter();
const { goToRetailer } = useRetailer();

const { t: $t } = useI18n();
const { isMobile } = useApp();
const isLoading = ref(false);
const productsFlow = computed<ProductsFlowModel | undefined>({
    get: () => $store.getters['deeplink/productsFlow'],
    set: (value) => $store.commit('deeplink/productsFlow', value),
});

const modalTitle = computed(() => productsFlow.value?.productName ?? $t('txt.retailers-modal__title'));

const closeModal = (): void => {
    if (isLoading.value) return;
    $store.commit('deeplink/productsFlow', null);
    $router.replace({ name: appRoutesMap.home });
};
</script>

<style lang="scss" scoped>
@import './retailers-modal.scss';
</style>
