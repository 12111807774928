import { BaseOrderState } from '@/models/order/BaseOrderState';
import { OrderState } from '@/enums/orderState';

const baseOrderState = (state: string): BaseOrderState => {
    const _green = '#CFDE00';
    const _deepGreen = '#A0AC01';
    const _orange = '#FF9012';
    const _deepOrange = '#D9780B';
    const _yellow = '#F7D93E';
    const _red = '#F03F51';
    const _deepRed = '#C90808';
    const _txtDark = '#707070';
    const _txtBright = '#FFF';

    switch (state.toUpperCase()) {
        case OrderState.PAYMENT_IN_PROCESS:
            return new BaseOrderState(
                'txt.my-order__payment-in-progress',
                _orange,
                _deepOrange,
                'txt.my-order__we-are-processing',
                _txtBright,
            );
        case OrderState.ASSIGNING_SHOPPER:
            return new BaseOrderState(
                'txt.my-order__order-accepted',
                _green,
                _deepGreen,
                'txt.my-order__thank-for-trust',
                _txtDark,
            );
        case OrderState.SHOPPER_ASSIGNED:
            ///TODO: check integrate shopper role here
            return new BaseOrderState(
                'txt.my-order__groceries-advisor-assigned',
                _green,
                _deepGreen,
                'txt.my-order__in-a-moment',
                _txtDark,
            );
        case OrderState.ORDER_WAITING_SHOPPER_IN_STORE:
            return new BaseOrderState(
                'txt.my-order__waiting-shooper',
                _green,
                _deepGreen,
                'txt.my-order__your-order-waiting',
                _txtDark,
            );
        case OrderState.SHOPPER_BUYING:
            return new BaseOrderState(
                'txt.my-order__shopper-buying',
                _green,
                _deepGreen,
                'txt.my-order__your-shopper-picking',
                _txtDark,
            );
        case OrderState.ORDER_WAITING_REPLACED_CONFIRMATION:
            return new BaseOrderState(
                'txt.my-order__waiting-replacement',
                _green,
                _deepGreen,
                'txt.my-order__waiting-confirmation',
                _txtDark,
            );
        case OrderState.SHOPPER_ORDER_CANCELLED:
        case OrderState.USER_ORDER_CANCELLED:
        case OrderState.FINANCIAL_DEPARTMENT_ORDER_CANCELLED:
            return new BaseOrderState('txt.order_cancelled', _red, _deepRed, 'txt.my-order__you-cancelled', _txtBright);
        case OrderState.EXTRA_ITEMS_PAYMENT_IN_PROCESS:
            return new BaseOrderState(
                'txt.my-order__extra-items-payment',
                _orange,
                _deepOrange,
                'txt.my-order__we-are-processing-extra',
                _txtBright,
            );
        case OrderState.VALIDATING_WIRE_TRANSFER:
            return new BaseOrderState(
                'txt.my-order__wire-transfer',
                _green,
                _deepGreen,
                'txt.my-order__validating-wire-transfer',
                _txtDark,
            );
        case OrderState.PAYMENT_REJECTED:
            return new BaseOrderState(
                'txt.my-order__payment-rejected',
                _red,
                _deepRed,
                'txt.my-order__payment-rejected-please',
                _txtBright,
            );
        case OrderState.EXTRA_ITEMS_PAYMENT_REJECTED:
            return new BaseOrderState(
                'txt.my-order__payment-rejected',
                _red,
                _deepRed,
                'txt.my-order__payment-rejected-please',
                _txtBright,
            );
        case OrderState.ORDER_DELIVERED_RATED:
            return new BaseOrderState(
                'txt.my-order__delivered-and-rated',
                _yellow,
                _yellow,
                'txt.my-order__thanks-for-use',
                _txtDark,
            );
        case OrderState.STORE_PURCHASE_COMPLETED:
            return new BaseOrderState(
                'txt.my-order__purchase-finished',
                _green,
                _deepGreen,
                'txt.my-order__your-shopper-finish',
                _txtDark,
            );
        case OrderState.ORDER_DELIVERED:
            return new BaseOrderState(
                'txt.my-order__order-delivered',
                _green,
                _deepGreen,
                'txt.my-order__delivery-will-hand',
                _txtDark,
            );
        case OrderState.ORDER_DELIVERED_UNRATED:
            return new BaseOrderState(
                'txt.my-order__order-delivered',
                _yellow,
                _yellow,
                'txt.my-order__forget-to-comment',
                _txtDark,
            );
        case OrderState.SHOPPER_DELIVERING:
            return new BaseOrderState(
                'txt.my-order__shopper-delivering',
                _green,
                _deepGreen,
                'txt.my-order__your-shopper-will-arrive',
                _txtDark,
            );
        case OrderState.SHOPPER_IN_DESTINY:
            return new BaseOrderState(
                'txt.my-order__in-destiny',
                _green,
                _deepGreen,
                'txt.my-order__shopper-in-destiny',
                _txtDark,
            );
        default:
            return new BaseOrderState(undefined, _green, _deepGreen, undefined, _txtDark);
    }
};

export { baseOrderState };
