import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9640c2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recipes" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header-data" }
const _hoisted_4 = { class: "header-data__title" }
const _hoisted_5 = { class: "recipes-amount" }
const _hoisted_6 = { class: "recipes-amount__quantity" }
const _hoisted_7 = { class: "body" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "recipe__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_tipti = _resolveComponent("icon-tipti")!
  const _component_icon_chef_hat = _resolveComponent("icon-chef-hat")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_triple_loading = _resolveComponent("triple-loading")!
  const _component_auth = _resolveComponent("auth")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("article", _hoisted_1, [
      _createElementVNode("header", _hoisted_2, [
        _createVNode(_component_icon_tipti, {
          class: "header__avatar",
          color: "#fff"
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('general.tipti-recipes')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_icon_chef_hat, { size: 0.7 }),
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('general.recipes', { count: $options.totalRecipes })), 1)
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.recipesInstagram?.recipes, (recipe) => {
          return (_openBlock(), _createBlock(_component_base_card, {
            key: recipe.id,
            class: _normalizeClass(["recipe", { 'recipe--clicked': $options.isClickable(recipe?.deeplink) }]),
            onClick: ($event: any) => ($options.openRecipe(recipe?.deeplink))
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "recipe__img",
                src: recipe.photo,
                alt: "",
                loading: "lazy"
              }, null, 8, _hoisted_8),
              _createElementVNode("h3", _hoisted_9, _toDisplayString(recipe.title), 1)
            ]),
            _: 2
          }, 1032, ["class", "onClick"]))
        }), 128))
      ]),
      ($data.isLoading)
        ? (_openBlock(), _createBlock(_component_triple_loading, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    ($data.showLogin)
      ? (_openBlock(), _createBlock(_component_auth, {
          key: 0,
          onOnClose: _cache[0] || (_cache[0] = ($event: any) => ($data.showLogin = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}