<script lang="ts" setup>
import type { ComponentPublicInstance } from 'vue';
import { onErrorCaptured } from 'vue';
import { useRouter } from 'vue-router';
import { handleImportModuleError, IError, importModuleErrors } from '@/modules/core/composable/handleImportModuleError';

const router = useRouter();

router.onError(handleImportModuleError);
onErrorCaptured(handleError);

function handleError(error: IError, vm: ComponentPublicInstance | null, info: string): boolean {
    if (importModuleErrors.some((err) => error.message.includes(err))) {
        handleImportModuleError(error);
        return false;
    }
    return true;
}
</script>

<template>
    <slot></slot>
</template>
