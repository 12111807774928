export class TiptiError extends Error {
    /**
     * @description property to save the original error object
     */
    public readonly errorObject: any;
    public readonly trackerError: string;
    public readonly code: string | number;

    constructor({
        message = '',
        where = '',
        code,
    }: Partial<{
        message: { [key: string]: string | number } | string;
        where: string;
        code: number | string;
    }>) {
        super(
            typeof message === 'string' ? (!message?.match(/<html/gi)?.length ? message : '') : JSON.stringify(message),
        );
        this.errorObject = typeof message === 'object' ? message : {};
        this.trackerError =
            code !== null && code !== undefined
                ? `Error Code ${code} triggered in ${where}`
                : `Error triggered in ${where} `;
        if (typeof message === 'string' && message?.length && !message.match(/<html/gi)?.length)
            this.trackerError += ` with message ${message}`;
    }
}
export class NotFoundError extends TiptiError {
    constructor(readonly where: string, readonly message = '') {
        super({ where, message, code: 404 });
    }
}
export class ServerError extends TiptiError {
    constructor(readonly where: string, readonly code = 500, readonly message = '') {
        super({ where, code, message });
    }
}
export class NetworkError extends TiptiError {
    constructor(readonly where: string) {
        super({ where });
    }
}
export class RequestError extends TiptiError {
    constructor(readonly where: string, readonly code: number, readonly message = '') {
        super({ where, code, message });
    }
}
export class ClientError extends TiptiError {
    constructor(readonly where: string, readonly code: number, readonly message = '') {
        super({ where, code, message });
    }
}
export class ThrottledError extends TiptiError {
    constructor(readonly where: string, readonly code: number, readonly message = '') {
        super({ where, code, message });
    }
}
export class PaymentErrorOnAdd extends TiptiError {
    constructor({ where, code, message }: { where: string; code: string; message: string }) {
        super({ where, code, message });
    }
}

export class ResentPaymentError extends TiptiError {
    readonly attempts: number;
    constructor(payload?: { message: string; attempts?: number }) {
        super({ message: payload?.message ?? '' });
        this.attempts = payload?.attempts;
    }
}
