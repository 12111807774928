import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b02abaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "default-payment-selector__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_toggle = _resolveComponent("toggle")!

  return ($data.isVisible)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(["default-payment-selector", { 'default-payment-selector__disable': $data.isLoading }])
      }, [
        ($data.isLoading)
          ? (_openBlock(), _createBlock(_component_base_loading, {
              key: 0,
              class: "default-payment--loading",
              heigth: "0.5rem",
              width: "0.5rem"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('txt.default_payment')), 1),
        _createVNode(_component_toggle, {
          modelValue: $data.isActive,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.isActive) = $event)),
          onOnChanged: $options.toggleAction
        }, null, 8, ["modelValue", "onOnChanged"])
      ], 2))
    : _createCommentVNode("", true)
}