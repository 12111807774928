<template>
    <general-alert-rating />
    <section
        v-if="!isFetching"
        class="orders-banner"
        :class="{
            'orders-banner--large-banner': $route.meta.show_order_to_rate && !$route.meta.is_detail_product,
            'orders-banner--large-banner-extra-item': $route.meta.is_detail_product,
        }"
    >
        <div v-if="ordersActive?.total_orders === 1 || isExtraItems" class="one-order__state">
            <the-order-state v-if="!isExtraItems" :order="ordersActive?.order" />
            <the-extra-item-order-state v-if="isExtraItems" />
        </div>
        <banner-orders v-if="ordersActive?.total_orders > 1 && isMainCart" />
    </section>
</template>

<script lang="ts" setup>
import { OrderActiveHomeModel } from '@/models/order/OrderActiveHomeModel';
import BannerOrders from './components/bannerOrders/BannerOrders.vue';
import TheOrderState from '@/modules/main-products/components/TheStatusOrder/components/theOrderState/TheOrderState.vue';
import GeneralAlertRating from '@/views/ratingOrder/generalAlertRating/GeneralAlertRating.vue';
import { useStore } from 'vuex';
import TheExtraItemOrderState from '@/modules/main-products/components/TheStatusOrder/components/theOrderState/TheExtraItemOrderState.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useCart } from '@/composables/useCart';
import { useUser } from '@/composables/useUser';
import { OrderModel } from '@/models/order/OrderModel';
import { useTiptiTAC } from '@/modules/retailers/composables/useTiptiTAC';
import { useHeader } from '@/components/headers/headerMain/useHeader';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'TheStatusBanner',
});

const $store = useStore();
const $route = useRoute();
const { isFetching } = useTiptiTAC();
const { isExtraItemCart, isMainCart } = useCart();
const { userLocation } = useUser();
const ordersActive = ref<OrderActiveHomeModel>();

const orders = computed<OrderModel[]>(() => $store.getters['orders/orders']);
const { height: headerHeight, updateHeight } = useHeader();

onMounted(() => {
    updateHeight();
});

const isExtraItems = computed(() => isExtraItemCart.value && !isRetailerPage.value);

const isRetailerPage = computed(() => $route.name === appRoutesMap.home);

watch(
    () => userLocation.value?.sector?.id,
    async (newId, oldId) => {
        if (!newId || !oldId || newId === oldId) return;
        await fetchActiveOrders();
    },
);
watch(
    () => [...orders.value],
    async (newOrders) => {
        if (!newOrders?.length) return;
        await fetchActiveOrders();
    },
);

const fetchActiveOrders = async (): Promise<void> => {
    if ($route.meta.is_detail_product) return;
    ordersActive.value = await $store.dispatch('orders/getOrdersActiveFromHome', {
        hideOrderToRate: !$route.meta.show_order_to_rate,
        setOrderForExtraItems: !isRetailerPage.value,
    });
};

const onInit = async () => {
    if (isExtraItemCart.value) return;
    fetchActiveOrders();
};

onInit();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.orders-banner {
    display: flex;
    flex-direction: column;
    margin: $margin-none $margin-screen;
    background-color: $white;
    z-index: 3;
    @include tablet {
        margin: $margin-xsm $margin-none $margin-none $margin-none;
    }

    &--large-banner {
        position: sticky;
        top: v-bind(headerHeight);
        margin: $margin-none;

        &-extra-item {
            position: static;
            top: unset;
            margin: $margin-none;
        }
    }
}

.one-order__state {
    @include box-shadow;
    min-height: 4rem;
    background-color: $white;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: $padding-none $padding-lg;
    border-bottom-left-radius: $radius-lg;
    border-bottom-right-radius: $radius-lg;
    @include tablet {
        padding: $padding-none;
    }
}
</style>
