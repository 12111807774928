<template>
    <base-card class="referals">
        <p class="referals__description">
            {{ $t("txt['referred__get-in']") }} <strong>{{ `${currency}${amountGift}` }}</strong>
            {{ $t("txt['referred__with-every-friend']") }}
            <strong>TIPTI</strong>
        </p>
        <p class="referals__label referals--center">{{ $t("general['code']") }}</p>
        <h4 class="referals__code referals--center" v-if="referals?.referredCode" ref="code">
            {{ referals.referredCode }}
        </h4>
        <button class="referals__btn referals--center" @click.once="saveClipBoard(referals?.referredCode)">
            {{ $t("general['copy']") }}
        </button>
        <referred-info :referred="code" :currency="currency" v-for="code in referals?.referredCodes" :key="code.id" />
    </base-card>
    <snack-bar
        :body="snackBarMessage"
        :isSuccess="true"
        :isActive="showSnackBar"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { ReferalModel } from '@/models/referred/ReferalModel';

import BaseCard from '@/components/cards/BaseCard.vue';
import ReferredInfo from '@/views/myAccount/views/referred/components/referals/ReferredInfo.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';

export default {
    name: 'referals',
    data() {
        return {
            showSnackBar: false,
            isSuccess: false,
            isFailure: false,
            snackBarMessage: '',
        };
    },
    props: {
        referals: {
            type: ReferalModel,
        },
    },
    computed: {
        ...mapGetters({ countrySelected: 'external/countrySelected', baseConfiguration: 'user/baseConfiguration' }),
        currency() {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        amountGift() {
            return this.baseConfiguration?.referredReward ?? 0;
        },
        amountMin() {
            return this.baseConfiguration?.referredRewardCondition ?? 0;
        },
    },
    methods: {
        saveClipBoard(code: string): void {
            navigator.clipboard.writeText(code);
            this.snackBarMessage = this.$t('alerts.link_copied');
            this.showSnackBar = true;
        },
    },
    components: {
        ReferredInfo,
        BaseCard,
        SnackBar,
    },
};
</script>
<style lang="scss" scoped>
@import './referals.scss';
</style>
