<template>
    <BaseBone v-if="requestState === 'LOADING'" class="retailers-promotions--skeleton" />
    <div v-else-if="visibleImages?.length" ref="carouselContainer" class="carousel-container">
        <div class="carousel-wrapper">
            <transition-group class="carousel" :style="{ transform: carouselTransform }" tag="div">
                <div
                    v-for="(image, index) in visibleImages"
                    :key="image.id"
                    class="carousel-item"
                    :style="{ zIndex: getZIndex(index) }"
                    @click="selectBanner(image)"
                >
                    <img class="cursor-pointer" :alt="image.title" :src="image.picture" />
                </div>
            </transition-group>
        </div>
        <template v-if="visibleImages.length >= 2">
            <button class="carousel_arrow carousel_arrow--left cursor-pointer" @click="prevSlide">
                <icon-back-no-border
                    :background-fill="backgroundArrow"
                    :color="colorArrow"
                    :icon-name="$t('icon.backward')"
                    :size="0.8"
                />
            </button>
            <button class="carousel_arrow carousel_arrow--right cursor-pointer" @click="nextSlide">
                <icon-back-no-border
                    class="carousel__arrow-next"
                    :background-fill="backgroundArrow"
                    :color="colorArrow"
                    :icon-name="$t('icon.forward')"
                    :size="0.8"
                />
            </button>
        </template>
        <nav v-if="visibleImages.length >= 2" class="carousel-indicators">
            <button
                v-for="(image, index) in images"
                :key="image.id"
                class="carousel-indicators__action cursor-pointer"
                :class="{ 'carousel-indicators__action--active': index === currentIndex }"
                @click="gotoSlide(index)"
            />
        </nav>
    </div>

    <BackgroundWithTransition :is-active="showModalBanner" content-centered @on-bg-click="showModalBanner = false">
        <TheModalContent class="phone-full-page">
            <template #header>
                <BaseHeader :text="bannerSelected.title" with-default-close @on-close="showModalBanner = false" />
            </template>
            <template #default>
                <main class="the-banner">
                    <img class="the-banner__img" :src="bannerSelected.picture" alt="" />
                    <section class="the-banner__ret">
                        <template v-for="retail in bannerSelected.retailerIds" :key="retail">
                            <RetailerCard
                                v-if="getRetail(+retail)"
                                :retailer="getRetail(+retail)"
                                @click.stop="selectRetailer(getRetail(+retail))"
                            />
                        </template>
                    </section>
                </main>
            </template>
        </TheModalContent>
    </BackgroundWithTransition>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import IconBackNoBorder from '@/components/icons/IconBackNoBorder.vue';
import { BannerSectionName } from '@/enums/bannerSectionName';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import { BannerModel } from '@/models/promotions/banners/BannerModel';
import { RequestState } from '@/modules/core/types/WorkingState';
import { useApp } from '@/composables/useApp';
import { useUser } from '@/composables/useUser';
import dayjs from 'dayjs';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';
import TheModalContent from '@/modules/core/components/layouts/TheModalContent.vue';
import BaseHeader from '@/modules/core/components/headers/HeaderWithAction.vue';
import RetailerCard from '@/components/cards/RetailerCard.vue';
import { MinRetailerInfo } from '@/modules/retailers/models/OtherRetailersModel';
import { retailerSections } from '@/modules/retailers/enums/retailerSections';
import { usePromotion } from '@/composables/usePromotion';

defineProps({
    colorArrow: {
        type: String,
        default: '#ffffff',
    },
    backgroundArrow: {
        type: String,
        default: '#ff9012',
    },
});

const { goToRetailer, allRetailers, navigateToRetailer } = useRetailer();
const { goToPromoProductsScreen, isBannerInSection } = usePromotion();
const { $store, $route, $router, screenWidth } = useApp();

const { baseConfiguration } = useUser();
const requestState = ref<RequestState>('LOADING');
const currentIndex = ref(0);
const transitioning = ref(false);
const showModalBanner = ref(false);
const bannerSelected = ref<BannerModel>();
const carouselTransform = ref('translateX(0%)');
let interval: number;

const carouselContainer = ref<HTMLElement>();
const images = ref<BannerModel[]>([]);

const visibleImages = computed(() => {
    const imgArray = images.value;
    const index = currentIndex.value;
    if (imgArray.length === 0) return [];
    return [...imgArray.slice(index - 1), ...imgArray.slice(0, index - 1)];
});

const getRetail = computed(() => (retail: number) => allRetailers.value.find((ret) => ret.id == retail));

const startCarousel = () => {
    interval = setInterval(nextSlide, baseConfiguration.value?.bannerTimeDelay ?? 4000);
};

const resetInterval = () => {
    clearInterval(interval);
    startCarousel();
};

const selectRetailer = (retailer: MinRetailerInfo): void => {
    navigateToRetailer({
        retailer,
        section: retailerSections.BANNER_RETAILERS,
    });
};

const nextSlide = () => {
    if (transitioning.value) return;
    transitioning.value = true;
    currentIndex.value = (currentIndex.value + 1) % images.value.length;
    setTimeout(() => {
        transitioning.value = false;
    }, 600);

    updateCarouselTransform();
    resetInterval();
};

const prevSlide = () => {
    if (transitioning.value) return;

    transitioning.value = true;
    currentIndex.value = (currentIndex.value - 1 + images.value.length) % images.value.length;
    setTimeout(() => {
        transitioning.value = false;
    }, 600);

    updateCarouselTransform();
    resetInterval();
};

const gotoSlide = (value: number) => {
    if (transitioning.value) return;

    transitioning.value = true;
    currentIndex.value = value % images.value.length;
    setTimeout(() => {
        transitioning.value = false;
    }, 600);

    updateCarouselTransform();
    resetInterval();
};

watch(screenWidth, () => {
    updateCarouselTransform();
});

const updateCarouselTransform = () => {
    if (!carouselContainer.value) return;
    const [firstImage, secondImage] = visibleImages.value.slice(0, 2);
    const firstImageElement =
        firstImage && (document.querySelector(`img[src="${firstImage.picture}"]`) as HTMLImageElement);
    const secondImageElement =
        secondImage && (document.querySelector(`img[src="${secondImage.picture}"]`) as HTMLImageElement);
    const containerWidth = carouselContainer.value.clientWidth;

    if (!firstImageElement) {
        carouselTransform.value = 'translateX(0px)';
        return;
    }

    if (visibleImages.value.length < 2) {
        firstImageElement.style.opacity = '1';
        const translateXValue = (containerWidth - firstImageElement.width) / 2;
        carouselTransform.value = `translateX(${translateXValue}px)`;
        return;
    }

    const translateXValue = (containerWidth - secondImageElement.width) / 2 - firstImageElement.width;

    carouselTransform.value = `translateX(${translateXValue}px)`;
};

const selectBanner = (banner: BannerModel) => {
    if (banner.appSection?.name === BannerSectionName.membership_screen) return;
    if (banner.appSection?.name === BannerSectionName.subscriptions_screen)
        return $router.push({ name: appRoutesMap.account.subscriptions });
    if (!banner?.retailerIds?.length) return;
    if (banner?.retailerIds.length === 1) {
        isBannerInSection.value = false;
        openBanner(banner.retailerIds[0]);
        return;
    }
    showModalBanner.value = true;
    bannerSelected.value = banner;
};

const getZIndex = computed(() => (index: number) => {
    if (index == 0) return -1;
    if (index == images.value.length - 1) return 0;
    return 1;
});

const init = async () => {
    const result = (await $store.dispatch('promotions/bannerInSectionRetailer')).filter(
        (ban: BannerModel) => ban.picture?.length,
    );
    if (result.length === 2)
        images.value = [
            ...result,
            ...result.map((ban) => {
                return {
                    ...ban,
                    id: ban.id * dayjs().millisecond(),
                };
            }),
        ];
    else images.value = result;
    requestState.value = 'NONE';
};

onMounted(async () => {
    await init();
    setTimeout(() => {
        updateCarouselTransform();
        startCarousel();
    }, 250);
});

onBeforeUnmount(() => {
    clearInterval(interval);
});

const openBanner = (retailerId: number) => {
    const _retailerSelected = allRetailers.value.find((retailer) => retailer.id === retailerId);
    if (!_retailerSelected) return;
    $store.commit('retailers/retailerSelected', _retailerSelected);
    $store.dispatch('shareData/getRetailerPromotionsAndBenefits');
    goToRetailer();
    $store.dispatch('segment/retailSelected', {
        screen: $route.path,
        section: 'BANNER_RETAILERS',
    });
};
</script>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.retailers-promotions--skeleton {
    height: 12rem;
    @include style.desktop {
        height: 8rem;
    }
    @include style.phone {
        height: 5rem;
    }
}

.carousel-container {
    position: relative;
    margin: style.$margin-lg 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-container__title {
    @include style.body2;
    font-weight: 700;
    margin: style.$margin-md style.$margin-none;
}

.carousel-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.carousel {
    display: flex;
    transition: transform 0.6s ease;
    height: 100%;
    width: 100%;
}

.carousel-item {
    flex: 0 0 auto;
    height: 100%;
    transition:
        opacity 0.6s ease,
        visibility 0s linear 0.6s,
        transform 0.6s ease,
        z-index 0.6s ease;
}

.carousel-item img {
    object-fit: fill;
    height: 12rem;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0.9);
    opacity: 0.6;
    @include style.desktop {
        height: 8rem;
    }
    @include style.phone {
        height: 5rem;
        width: 53svh;
    }
}

.carousel-item:nth-child(2) img {
    transition: transform 0.3s ease;
}

.carousel-item:nth-child(2) img {
    transform: scale(1);
    opacity: 1;
}

.carousel_arrow {
    all: unset;
    border: unset;
    position: absolute;
    top: 50%;
    opacity: 0.7;
    transform: translateY(-50%);
    cursor: pointer;

    &--left {
        left: 1.5rem;
    }

    &--right {
        right: 1.5rem;
    }
}

.carousel__arrow-next {
    transform: rotate(180deg);
}

.carousel__arrow-back {
    @include style.box-glow;
    position: absolute;
    left: style.$margin-screen;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    border-radius: style.$radius-circular;
}

.carousel-indicators {
    margin: style.$margin-md;
    display: flex;
    gap: 0.5rem;
    @include style.tablet {
        display: none;
    }

    &__action {
        all: unset;
        cursor: pointer;
        background-color: #ddd;
        border-radius: 50%;
        padding: 0.5rem;

        &--active {
            background-color: #ff9012;
        }
    }
}

.the-banner {
    padding: 0 1rem 1rem 1rem;
    max-width: 75svw;
    @include style.phone {
        max-width: unset;
    }

    &__img {
        width: 100%;
    }

    &__ret {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(min(100%, 26rem), 0.25fr));
        gap: 2rem 1rem;
        place-content: space-evenly;
        margin: 2rem 0;

        @include style.desktop {
            grid-template-columns: repeat(auto-fill, minmax(min(100%, 20rem), 1fr));
        }
    }
}
</style>
