import { ListSubgroupModel, toListSubgroupModel } from './ListSubgroupModel';

class ListGroupModel {
    readonly id: number;
    readonly name: string;
    readonly description: string;
    readonly pictureForMobileApp: string;
    readonly pictureForMobileWeb: string;
    readonly pictureForWebApp: string;
    readonly showOrder: number;
    readonly subGroups: Array<ListSubgroupModel>;

    constructor(
        id: number,
        name: string,
        description: string,
        pictureForMobileApp: string,
        pictureForMobileWeb: string,
        pictureForWebApp: string,
        showOrder: number,
        subGroups: Array<ListSubgroupModel>,
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.pictureForMobileApp = pictureForMobileApp;
        this.pictureForMobileWeb = pictureForMobileWeb;
        this.pictureForWebApp = pictureForWebApp;
        this.showOrder = showOrder;
        this.subGroups = subGroups;
    }
}
const toListGroupModel = (data: object): ListGroupModel => {
    return data
        ? new ListGroupModel(
              data['id'],
              data['name'],
              data['description'],
              data['picture_for_mobile_app'],
              data['picture_for_mobile_web'],
              data['picture_for_web_app'],
              data['show_order'],
              data['sub_groups'].map((list: object) => {
                  return toListSubgroupModel(list);
              }),
          )
        : null;
};

export { ListGroupModel, toListGroupModel };
