import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c91ca02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cart-prices" }
const _hoisted_2 = {
  key: 0,
  class: "cart-prices__price cart-prices--crossed"
}
const _hoisted_3 = { class: "cart-prices__unitary" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _withDirectives(_createElementVNode("p", {
      class: _normalizeClass([$options.hasPriceWithDiscount ? 'cart-prices__discount' : 'cart-prices__price'])
    }, null, 2), [
      [_directive_currency, $options.finalPriceWithDiscount]
    ]),
    ($options.hasPriceWithDiscount)
      ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_2, null, 512)), [
          [_directive_currency, $options.finalPriceWithoutDiscount]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_3, _toDisplayString($props.product.productQuantity) + " x " + _toDisplayString($options.unitaryPrice), 1),
    (!$setup.isExtraItemCart && $props.product.discountCard)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "cart-card-discount",
          src: $props.product.discountCard?.icon,
          alt: ""
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}