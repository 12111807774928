<template>
    <section class="container-tipti">
        <icon-tipti :color="positiveContrast ? '#ff9012' : '#FF9012'" :size="size"></icon-tipti>
        <div class="container-tipti__slogan" :style="setPositiveContrast">
            <strong class="container-tipti--orange container-tipti--bold" :style="setPositiveContrast">
                {{ $t("txt['slogan__online-and']") }}
            </strong>
            <strong class="container-tipti--orange container-tipti--bold" :style="setPositiveContrast">
                {{ $t("txt['slogan__supermarket-and']") }}
            </strong>
            <p class="container-tipti--orange" :style="setPositiveContrast">
                {{ $t("txt['slogan__home-essenials']") }}
            </p>
        </div>
    </section>
</template>

<script lang="ts">
import IconTipti from '@/components/icons/IconTipti.vue';

export default {
    name: 'TiptiWithSlogan',
    components: {
        IconTipti,
    },
    props: {
        positiveContrast: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            default: 1.2,
        },
    },
    computed: {
        setPositiveContrast(): object {
            return {
                color: this.positiveContrast ? '#FF9012' : '#FF9012',
                'border-color': this.positiveContrast ? '#FFF' : '#FF9012',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.container-tipti {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-tipti__slogan {
    display: inherit;
    margin-left: $margin-sm;
    padding-left: $padding-sm;
    flex-direction: column;
}

.container-tipti__slogan p,
.container-tipti__slogan strong {
    @include body2;
}

.container-tipti--orange {
    color: $orange;
}

.container-tipti--bold {
    font-weight: 600 !important;
}
</style>
