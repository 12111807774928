import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_feeling_bar = _resolveComponent("feeling-bar")!
  const _component_countdown = _resolveComponent("countdown")!
  const _component_question = _resolveComponent("question")!
  const _component_binary = _resolveComponent("binary")!

  return (_ctx.selectedStory?.interactiveElement?.interactionType === 'FEELING_BAR')
    ? (_openBlock(), _createBlock(_component_feeling_bar, { key: 0 }))
    : (_ctx.selectedStory?.interactiveElement?.interactionType === 'COUNTDOWN')
      ? (_openBlock(), _createBlock(_component_countdown, { key: 1 }))
      : (_ctx.selectedStory?.interactiveElement?.interactionType === 'QUESTION')
        ? (_openBlock(), _createBlock(_component_question, { key: 2 }))
        : (_ctx.selectedStory?.interactiveElement?.interactionType === 'BINARY')
          ? (_openBlock(), _createBlock(_component_binary, { key: 3 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1))
}