import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0dbc5640"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "retailer-card__schedule-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "retailer-card__schedule-detail" }
const _hoisted_4 = { class: "retailer-card__schedule-detail" }
const _hoisted_5 = { class: "delivery_time" }
const _hoisted_6 = { class: "hours" }
const _hoisted_7 = { class: "alert-purchase__text-accumulated" }
const _hoisted_8 = { class: "alert-purchase__text-points" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_schedule = _resolveComponent("icon-schedule")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    ($props.orderInfo.franchiseLogo)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "image",
          src: $props.orderInfo.franchiseLogo,
          alt: ""
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('general.delivery-time')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_icon_schedule, {
          size: $options.isMobile ? 0.9 : 1,
          color: "#FF9012"
        }, null, 8, ["size"]),
        _createElementVNode("div", null, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString($options.dayNameAndNumber($props.orderInfo.firstDate)), 1),
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('general.from')) + " " + _toDisplayString($options.hours($props.orderInfo.firstDate)) + " " + _toDisplayString(_ctx.$t("txt['purchase__retailer-schedule-to']")) + " " + _toDisplayString($options.hours($props.orderInfo.secondDate)), 1)
        ])
      ]),
      ($props.orderInfo?.showAccumulatedPoints)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("txt['has-accumulated']")), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("txt['points-text']")), 1)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}