<template>
    <teleport :to="container">
        <Transition name="bounce">
            <div v-if="notificationToView?.length" class="toast-wrapper">
                <TransitionGroup name="fade">
                    <template v-for="(notification, index) in notificationToView" :key="notification">
                        <Component
                            :is="notification.component"
                            v-if="notification.component"
                            v-bind="notification"
                            @click="$emit('clicked', notification)"
                        />
                        <TheNotificationBody
                            v-else
                            :notification="notification"
                            :remaining="remaining"
                            :show-total="index === notificationToView?.length - 1"
                            @on-click="(value) => $emit('clicked', value)"
                            @on-close="(value) => $emit('closed', value)"
                        />
                    </template>
                </TransitionGroup>
            </div>
        </Transition>
    </teleport>
</template>

<script lang="ts" setup>
import TheNotificationBody from './TheNotificationBody.vue';
import type { PropType } from 'vue';
import { computed } from 'vue';
import type { ToastContent } from './Notification';

const props = defineProps({
    notifications: {
        type: Array as PropType<ToastContent[]>,
        default() {
            return [];
        },
    },
    container: {
        type: String,
        default: '.toast-container',
    },
});

const notificationToView = computed(() => {
    const chats = props.notifications.filter((type) => type.type === 'CHAT');
    const chatsGroups = props.notifications.filter((type) => !!type.grouped).length ?? 0;

    const priority1 = props.notifications.filter((type) => type.type === 'ERROR');
    const priority2 = props.notifications.filter((type) => type.type === 'INFO');
    const priority3 = props.notifications.filter((type) => type.type === 'SUCCESS');
    const priority0 = props.notifications.filter((type) => !!type.component);
    return [...priority0, ...chats, ...priority1, ...priority2, ...priority3].slice(0, 3 + chatsGroups);
});

const remaining = computed(() => props.notifications?.length - notificationToView.value?.length);

defineEmits<{
    (e: 'clicked', value: ToastContent): void;
    (e: 'closed', value: ToastContent): void;
}>();
</script>

<style lang="scss" scoped>
@use '@/assets/scss/media-query' as mq;

.toast-wrapper {
    position: fixed;
    z-index: 10;
    top: 8rem;
    right: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
    justify-content: flex-end;
    @include mq.phone {
        top: 4rem;
        right: 0.5rem;
        left: 0.5rem;
    }

    //&--chat {
    //    right: 50%;
    //    left: 50%;
    //    transform: translateX(50%);
    //    @include mq.phone {
    //        top: 2rem;
    //        right: 0.5rem;
    //        left: 0.5rem;
    //    }
    //}
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
    position: absolute;
}
</style>
