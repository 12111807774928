<template>
    <base-card class="flex-column">
        <header class="title-container">
            <h1 class="title-container__title orange">{{ $t("general['select-delivery-sector']") }}</h1>
            <p class="title-container__description">
                {{ $t("txt['address-user__select-delivery-sector']") }}<br />
                {{ $t("txt['address-user__most-nearest']") }}
            </p>
        </header>

        <form>
            <label class="input_label_address"> {{ $t('general.address') }}</label>
            <div class="border-search">
                <GoogleAutocompleteMaps
                    id="inputAddress"
                    class="search-input__input"
                    :options="options"
                    :placeholder="$t('general.map_address_direction')"
                    :value="inputAddress"
                    @place_changed="setPlaceByInput"
                />
                <icon-search :size="0.06" />
            </div>
            <p v-if="!address?.length" class="label-error">* {{ $t('txt.address__select-right-location') }}</p>
            <label class="input_label_address"> {{ $t('txt.address-user__reference') }}</label>
            <input-map
                v-model="reference"
                :placeholder="$t('general.cardAddress__reference')"
                autocomplete="shipping postal-code"
            />
            <label class="input_label_address"> {{ $t('txt.address-user__alias') }}</label>
            <input-map
                v-model="alias"
                :placeholder="$t('general.cardAddress__alias')"
                autocomplete="shipping locality"
            />
        </form>
        <div v-if="!isFromRegister" class="default-container">
            <p>{{ $t("general['set-as-default']") }}</p>
            <toggle v-model="isDefaultAddress" />
        </div>
        <btn-solid
            class="card-address__btn"
            :is-disabled="!isBtnEnabled"
            :is-loading="isLoading"
            :txt="isEditAddress ? $t('cta.update') : $t('txt.address_add-address')"
            border-radius="5px"
            @click="!isBtnEnabled ? null : isEditAddress ? editAddress() : saveAddress()"
        />
    </base-card>
    <loading-modal v-if="isLoading" :has-header="true" :title="$t('txt.change_address')" />
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import { AddressModel } from '@/models/address/AddressModel';
import { ZoneModel } from '@/models/address/ZoneModel';
import { logger } from '@/utils/logger';
import InputMap from '@/components/inputs/primary/map/InputMap.vue';
import BaseCard from '../BaseCard.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import IconSearch from '@/components/icons/IconSearch.vue';
import Toggle from '@/components/toggle/Toggle.vue';
import MapUtils from '@/utils/mapUtils';
import LoadingModal from '@/components/modal/loadingModal/LoadingModal.vue';
import GoogleAutocompleteMaps from '@/components/cards/cardAddress/GoogleAutocompleteMaps.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useApp } from '@/composables/useApp';

export default {
    name: 'CardAddress',

    components: {
        GoogleAutocompleteMaps,
        BaseCard,
        BtnSolid,
        Toggle,
        InputMap,
        IconSearch,
        LoadingModal,
    },
    props: {
        isEditAddress: {
            type: Boolean,
            required: false,
        },
        addressMarker: {
            type: Object,
            required: false,
        },
        isFromRegister: {
            type: Boolean,
            default: false,
        },
        section: {
            type: String,
            required: false,
        },
        polygonIndex: {
            type: Number,
            required: true,
        },
    },
    setup() {
        const { notifier } = useApp();
        const { fetchYourRetailers, fetchOtherRetailers } = useRetailer();
        return {
            fetchYourRetailers,
            fetchOtherRetailers,
            notifier,
        };
    },
    data() {
        return {
            options: {
                fields: ['address_components', 'formatted_address', 'geometry', 'name'],
                componentRestrictions: { country: ['PA', 'EC'] },
            },
            inputPosition: undefined,
            inputAddress: undefined,
            mainStreet: undefined,
            secondStreet: undefined,
            countryCode: undefined,
            address: '',
            reference: '',
            alias: '',
            isSuccess: false,
            isDefaultAddress: false,
            isLoading: false,
        };
    },
    watch: {
        addressMarker: function (newAddress) {
            this.address = newAddress.isOutOfCoverage ? '' : newAddress['formatted_address'];
            this.mainStreet = newAddress['mainStreet']?.length ? newAddress['mainStreet'] : this.address;
            this.secondStreet = newAddress['secondStreet'];
            this.inputAddress = this.address;
            if (newAddress?.center?.lat && newAddress?.center?.lng)
                this.inputPosition = new (window as any).google.maps.LatLng(
                    newAddress.center.lat,
                    newAddress.center.lng,
                );
        },
        '$store.state.external.countrySelected': function () {
            this.initMap();
        },
    },
    computed: {
        ...mapGetters({
            locationSelected: 'user/userLocation',
            polygonsCoordinates: 'location/polygons',
            addressToEdit: 'location/addressToEdit',
            countrySelected: 'external/countrySelected',
            countries: 'external/countries',
            user: 'user/user',
            allAddresses: 'location/allAddresses',
            connectionStatus: 'network/connectionStatus',
        }),
        isBtnEnabled(): boolean {
            return this.address?.length && this.reference?.length && this.alias.length;
        },
        addressFormData(): AddressModel {
            const countryCode = this.countryCode ?? this.addressMarker?.countryCode;
            const position = [this.inputPosition?.lat()?.toString(), this.inputPosition?.lng()?.toString()];
            const addressCountry = this.countries.find((country) => country.alphaCode === countryCode);
            return new AddressModel(
                this.isEditAddress ? this.addressToEdit?.['id'] : undefined,
                this.address,
                position,
                this.isDefaultAddress,
                this.mainStreet,
                this.secondStreet,
                this.reference,
                false,
                new ZoneModel(+this.polygonsCoordinates[this.polygonIndex]?.cityID, undefined, addressCountry),
                new ZoneModel(+this.polygonsCoordinates[this.polygonIndex]?.sectorID, undefined, addressCountry),
                this.alias,
            );
        },
    },
    emits: ['on-save-position', 'on-close'],
    methods: {
        ...mapActions({
            fetchYourRetailers: 'retailers/yourRetailers',
            fetchRetailersTypes: 'retailers/retailersType',
            fetchQuickCart: 'cart/fetchQuickCart',
        }),
        checkConnection(): boolean {
            if (this.connectionStatus) return true;
            this.notifier({
                type: 'ERROR',
                body: this.$t('alerts.connection_error'),
            });
            return false;
        },
        async fetchRetailers(): Promise<void> {
            await Promise.allSettled([this.fetchYourRetailers(), this.fetchRetailersTypes()]);
        },
        initMap(): void {
            this.country = null;
            this.countryCode = null;
            this.mainStreet = null;
            this.secondStreet = null;
            if (this.isEditAddress) {
                this.reference = this.addressToEdit['reference'];
                this.alias = this.addressToEdit['comment'];
                this.isDefaultAddress = this.addressToEdit['isDefaultAddress'];
            }
        },
        setPlaceByInput(place): void {
            if (!place?.address_components) {
                this.address = '';
                return;
            }
            const _map = new MapUtils(place);
            this.address = _map.getAddress();
            this.secondStreet = _map.getSecondStreet();
            this.mainStreet = _map.getMainStreet();
            this.city = _map.getCity();
            this.inputPosition = place['geometry']['location'];
            this.countryCode = _map.getCountryCode();
            this.$emit('on-save-position', this.inputPosition.lat(), this.inputPosition.lng());
        },
        async editAddress(): Promise<void> {
            try {
                if (!this.checkConnection()) return;
                const isDefault = this.isDefaultAddress;
                if (this.addressToEdit['isDefaultAddress'] && !isDefault) {
                    this.isFailure = true;
                    this.notifier({
                        type: 'INFO',
                        body: this.$t("txt['address__failure-one-address-default']"),
                    });
                    return;
                }
                this.isLoading = true;
                await this.$store.dispatch('location/editAddress', {
                    address: this.addressFormData,
                    screen: this.$route.path,
                    section: this.section,
                });
                this.notifier({
                    type: 'SUCCESS',
                    body: this.$t('alerts.address_edit_success'),
                    actions: {
                        close: () => {
                            if (this.isDefaultAddress && this.section === 'RETAILERS')
                                this.$router.push({ name: appRoutesMap.home });
                            this.$emit('on-close');
                        },
                    },
                });
                await Promise.allSettled([
                    this.$store.dispatch('user/getProfile'),
                    this.$store.dispatch('location/countryAddresses'),
                    !this.isDefaultAddress && this.$route.name !== appRoutesMap.home
                        ? Promise.resolve()
                        : this.$router.push({ name: appRoutesMap.home }),
                ]);
                this.$emit('on-close');

                if (this.locationSelected?.id !== this.addressFormData?.id || this.section !== 'RETAILERS') return;
                this.$store.dispatch('location/getUnavailableProductsByLocation').then((hasUnavailable) => {
                    if (hasUnavailable) this.fetchQuickCart().catch((err) => logger('FETCH_CART', err));
                });
            } catch (err) {
                logger(err);
                this.notifier({
                    type: 'ERROR',
                    body: this.$t('alerts.address_edit_error'),
                });
            } finally {
                this.isLoading = false;
            }
        },
        async saveAddress() {
            try {
                if (!this.checkConnection()) return;
                this.isLoading = true;
                if (this.isFromRegister) this.addressFormData.isDefaultAddress = true;
                await this.$store.dispatch('location/addNewAddress', {
                    data: this.addressFormData,
                    screen: this.$route.path,
                    section: this.section,
                });
                this.notifier({
                    type: 'SUCCESS',
                    body: this.$t('alerts.address_add_success'),
                });

                await Promise.allSettled([
                    this.$store.dispatch('user/getProfile'),
                    this.$store.dispatch('location/countryAddresses'),
                    !this.isFromRegister ? Promise.resolve() : this.$store.dispatch('deeplink/handleTrail'),
                    !this.isDefaultAddress && this.$route.name !== appRoutesMap.home
                        ? Promise.resolve()
                        : this.$router.push({ name: appRoutesMap.home }),
                ]);
                this.$emit('on-close');
            } catch (err) {
                this.notifier({
                    type: 'ERROR',
                    body: this.$t('alerts.address_add_error'),
                });
                this.$store.commit('location/showAddressModal', true);
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted() {
        this.initMap();
        document.body.style.overflowY = 'hidden';
    },
    unmounted() {
        document.body.style.overflowY = 'auto';
    },
};
</script>

<style lang="scss" scoped>
@import './card-address.scss';
@import '@/components/inputs/primary/input-primary.scss';
</style>
