class DeliveryInformationModel {
    readonly sector: string;
    readonly addressName: string;
    readonly addressLine: string;
    readonly houseNumber: string;
    readonly reference: string;

    constructor(sector: string, addressName: string, addressLine: string, houseNumber: string, reference: string) {
        this.sector = sector;
        this.addressName = addressName;
        this.addressLine = addressLine;
        this.houseNumber = houseNumber;
        this.reference = reference;
    }
}

const toDeliveryInformationModel = (data: object): DeliveryInformationModel => {
    return data
        ? new DeliveryInformationModel(
              data['sector'],
              data['address_name'],
              data['address_line'],
              data['house_number'],
              data['reference'],
          )
        : null;
};

export { DeliveryInformationModel, toDeliveryInformationModel };
