<template>
    <base-alert @on-close="onDeclineAlert">
        <section class="container--align">
            <p class="alert-container__message">
                {{ $t("txt['login__alert-by-the-present']") }}
                <span @click="showModalPdf = true">{{ $t("general['terms-and-conditions']").toUpperCase() }}</span
                >{{ $t("txt['login__alert-authorize-tipti']") }}<span>TIPTI S.A</span>
                {{ $t("txt['login__alert-to-use-my-data']") }}<span>TIPTI S.A.</span
                >{{ $t("txt['login__alert-may-consider']") }}
            </p>
            <div class="alert-container__check">
                <check-tipti @on-check="acceptTerms = !acceptTerms" :isChecked="acceptTerms" />
                <p>{{ $t("txt['login__accept-terms-conditions']") }}</p>
            </div>
            <section :class="getButtonClass">
                <btn-out-line :txt="$t('cta.cancel')" bgColor="transparent" color="#FF9012" @click="onDeclineAlert" />
                <btn-solid
                    :txt="$t('cta.continue')"
                    @click="acceptTerms ? onAcceptAlert() : null"
                    :isDisabled="!acceptTerms"
                    :isLoading="isLoading"
                />
            </section>
        </section>
    </base-alert>
    <alert-pdf
        v-if="showModalPdf"
        :hasBackground="false"
        :title="$t('general.terms-and-conditions')"
        @on-close="showModalPdf = false"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BaseAlert from '@/components/alerts/baseAlert/BaseAlert.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import CheckTipti from '@/components/checks/CheckTipti.vue';
import AlertPdf from '@/components/alerts/alertPdf/AlertPdf.vue';

export default {
    name: 'modal-acceptation',
    data() {
        return {
            acceptTerms: false,
            showModalPdf: false,
        };
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        registerFields: {
            type: Object,
            required: false,
        },
    },
    components: {
        BtnSolid,
        BaseAlert,
        BtnOutLine,
        CheckTipti,
        AlertPdf,
    },
    emits: ['on-accept', 'on-decline', 'on-unmonted-modal'],
    computed: {
        ...mapGetters({ countrySelected: 'external/countrySelected', user: 'user/user' }),
        getButtonClass(): string {
            return 'alert-container__btn-container';
        },
        termsAndConditions(): string {
            const language = this.user?.defaultLanguage ?? this.countrySelected?.language ?? 'ES';
            return this.countrySelected?.termsAndConditions[language];
        },
        firstName(): string {
            return this.registerFields?.name?.split(' ')?.length
                ? this.registerFields.name.split(' ')[0]
                : this.registerFields.name;
        },
        lastName(): string {
            return this.registerFields?.name?.split(' ')?.length
                ? this.registerFields.name.split(' ')[1]
                : this.registerFields.name;
        },
    },
    methods: {
        async onAcceptAlert(): Promise<void> {
            this.$store.dispatch('segment/tcAccepted', {
                user: {
                    user_id: this.user?.userId ?? '',
                    client_id: this.user?.id ?? '',
                    email: this.registerFields?.email,
                    first_name: this.firstName,
                    last_name: this.lastName,
                },
                terms_and_conditions: {
                    client_accepted: true,
                    terms_type: this.termsAndConditions ?? '',
                },
            });
            this.$emit('on-accept');
        },
        onDeclineAlert(): void {
            this.$store.dispatch('segment/tcAccepted', {
                user: {
                    user_id: this.user?.userId ?? '',
                    client_id: this.user?.id ?? '',
                    email: this.user?.email ?? '',
                    first_name: this.user?.name ?? '',
                    last_name: this.user?.lastname ?? '',
                },
                terms_and_conditions: {
                    client_accepted: false,
                    terms_type: this.termsAndConditions ?? '',
                },
            });
            this.$emit('on-decline');
        },
    },
    unmounted() {
        this.$emit('on-unmonted-modal');
    },
};
</script>

<style lang="scss" scoped>
@import './modal-acceptation.scss';
</style>
