export const i18nInput = {
    EN: {
        input: {
            empty: 'Field {label} ban not be empty',
            invalid_email: 'Invalid email',
            invalid_data: 'Invalid data',
        },
        forms: {
            add_billing_data: 'Add billing data',
        },
    },
    ES: {
        input: {
            empty: 'El campo {label} no puede estar vacio',
            invalid_email: 'Email invalido',
            invalid_data: 'Dato invalido',
        },
        forms: {
            add_billing_data: 'Añadir datos de facturación',
        },
    },
};
