<template>
    <section class="card-membership">
        <base-card-flat
            class="the-membership-card"
            :style="{
                'background-image': `url(${card?.card_picture})`,
            }"
            padding="0"
        >
            <p class="the-membership-card__text">
                {{ card?.name }}
            </p>
            <p class="the-membership-card__text">
                {{ card?.card_number }}
            </p>
        </base-card-flat>
        <AccumulatedPoints v-if="isDetail && benefits?.points_accumulated" :points="benefits?.points_accumulated" />
        <BtnSolid
            v-if="isDetail && benefits?.franchises_list?.length"
            :is-loading="state === 'LOADING'"
            :txt="$t('general.go_to_buy')"
            @click="goToBuy"
        />
    </section>
</template>

<script lang="ts" setup>
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import { computed, PropType, ref } from 'vue';
import { AffiliationMembership } from '@/views/wallet/WalletModels';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import AccumulatedPoints from '@/views/creditCards/components/listCards/AccumulatedPoints.vue';
import { useWallet } from '@/views/wallet/useWallet';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { RequestState } from '@/modules/core/types/WorkingState';

const props = defineProps({
    card: {
        type: Object as PropType<AffiliationMembership>,
        required: true,
    },
    isDetail: {
        type: Boolean,
        default: false,
    },
});

const { benefits } = useWallet();
const { fetchRetailerByFranchiseId, retailerSelected, goToRetailer } = useRetailer();

const state = ref<RequestState>('NONE');
const color = computed(() => props.card.color_text ?? '#707070');

const goToBuy = async () => {
    if (state.value === 'LOADING' || !benefits.value?.franchises_list?.length) return;
    state.value = 'LOADING';
    await fetchRetailerByFranchiseId(+benefits.value.franchises_list[0]);
    if (!retailerSelected.value) return;
    state.value = 'LOAD-ENDED';
    goToRetailer();
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.card-membership {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $margin-md;
}

.the-membership-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: top;
    background-size: cover;
    border-radius: inherit;
    height: 13rem;
    width: 26rem;
    @include tablet {
        width: 20rem;
        height: 10rem;
    }

    &__text {
        @include body1;
        color: v-bind(color);
        margin-left: $margin-md;

        &:last-child {
            margin-bottom: $margin-md;
        }
    }
}

.membership-accumulated {
    display: flex;
    gap: $margin-md;
    align-items: center;

    &__text-points {
        @include body2;
        color: $dark-grey;
        font-weight: 600;
    }

    &__text-total-points {
        @include body1;
        color: $dark-grey;
        font-weight: 700;
    }
}
</style>
