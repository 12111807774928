import { BannersGoalModel } from '@/models/promotions/banners/BannerGoalModel';
import { BannerModel } from '@/models/promotions/banners/BannerModel';

export default {
    informativeBanner(state, informativeBanner: BannerModel) {
        state.informativeBanner = informativeBanner;
    },
    bannerSelected(state, bannerSelected: BannerModel) {
        state.bannerSelected = bannerSelected;
    },
    bannerGoal(state, bannerGoal: BannersGoalModel) {
        state.bannerGoal = bannerGoal;
    },
    isLastPage(state, value: boolean) {
        state.isLastPage = value;
    },
};
