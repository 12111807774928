<template>
    <section v-if="totalSaving > 1" class="purchase">
        <div class="purchase__total-saved-header">
            <h2 class="purchase__total-saved-message">
                {{ $t('general.and') }}
                {{ $t("txt['purchase__total-saved-had']") }}
                <span class="purchase__total-saved-message-highlighted">
                    {{ $t("txt['purchase__total-saved-saved']") }}
                </span>
            </h2>
        </div>
        <h3 class="purchase__total-saved-amount">{{ countrySelected?.currencySymbol ?? '' }} {{ totalSaving }}</h3>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TotalSaving',
    props: {
        totalSaving: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            countrySelected: 'external/countrySelected',
        }),
    },
};
</script>

<style lang="scss" scoped>
@import './total_saving.scss';
</style>
