<template>
    <article class="recipes">
        <header class="header">
            <icon-tipti class="header__avatar" color="#fff" />
            <div class="header-data">
                <h1 class="header-data__title">{{ $t('general.tipti-recipes') }}</h1>
                <div class="recipes-amount">
                    <icon-chef-hat :size="0.7" />
                    <h2 class="recipes-amount__quantity">{{ $t('general.recipes', { count: totalRecipes }) }}</h2>
                </div>
            </div>
        </header>
        <section class="body">
            <base-card
                v-for="recipe in recipesInstagram?.recipes"
                :key="recipe.id"
                class="recipe"
                :class="{ 'recipe--clicked': isClickable(recipe?.deeplink) }"
                @click="openRecipe(recipe?.deeplink)"
            >
                <img class="recipe__img" :src="recipe.photo" alt="" loading="lazy" />
                <h3 class="recipe__label">{{ recipe.title }}</h3>
            </base-card>
        </section>
        <triple-loading v-if="isLoading" />
    </article>
    <auth v-if="showLogin" @on-close="showLogin = false"></auth>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import { logger } from '@/utils/logger';
import { useI18n } from 'vue-i18n';
import { RecipeInstagram } from '@/models/recipes/RecipeInstagram';

import IconChefHat from '@/components/icons/IconChefHat.vue';
import IconTipti from '@/components/icons/IconTipti.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import Auth from '../auth/Auth.vue';
import TripleLoading from '@/components/loading/TripleLoading.vue';
import { useSeoMeta } from '@unhead/vue';

export default {
    name: 'TiptiRecipes',
    components: { IconChefHat, IconTipti, BaseCard, Auth, TripleLoading },
    setup() {
        /// ** set meta data
        const { t } = useI18n();
        useSeoMeta({
            title: t('seo.tipti_recipes'),
            description: t('seo.tipti_recipes__description'),
        });
    },
    data() {
        return {
            recipesInstagram: undefined,
            page: 1,
            showLogin: false,
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({ isAuth: 'user/isAuth' }),
        totalRecipes(): number {
            return this.recipesInstagram?.totalRecipes ?? 0;
        },
    },
    async mounted() {
        this.page = 1;
        await this.fetchRecipes();
        this.scroll();
    },
    unmounted() {
        window.onscroll = () => {
            logger('REGULAR_SCROLL');
        };
    },
    methods: {
        isClickable(link: string): boolean {
            return link?.length > 0;
        },
        async fetchRecipes() {
            try {
                if (this.recipesInstagram && this.recipesInstagram.isLastPage) return;
                this.isLoading = true;
                this.recipesInstagram = await this.$store.dispatch('lists/tiptiRecipes', this.page);
                this.page++;
                this.setJSONLDData();
            } finally {
                this.isLoading = false;
            }
        },
        scroll() {
            window.onscroll = async (): Promise<void> => {
                const bottomOfWindow =
                    document.documentElement.scrollTop + document.documentElement.clientHeight >=
                    document.documentElement.scrollHeight - 300;
                if (bottomOfWindow && !this.isLoading) {
                    await this.fetchRecipes();
                }
            };
        },
        openRecipe(link: string) {
            if (!this.isClickable(link)) return;
            if (this.isAuth) {
                window.open(link, '_self');
                return;
            }
            this.$store.dispatch('segment/openSignIn');
            this.showLogin = true;
        },

        setJSONLDData(): void {
            const script = document.getElementById('jsonLD');
            script.setAttribute('type', 'application/ld+json');
            const _elements =
                this.recipesInstagram?.recipes?.map((recipe: RecipeInstagram, index: number) => {
                    return {
                        '@type': 'ListItem',
                        position: recipe.position === 0 ? index : recipe.position,
                        identifier: recipe.id,
                        name: recipe.title,
                        image: recipe.photo,
                        url: recipe.deeplink,
                    };
                }) ?? [];
            const JSONLD = {
                '@context': 'https://schema.org',
                '@type': 'ItemList',
                name: this.$t('seo.tipti_recipes'),
                description: this.$t('seo.tipti_recipes__description'),
                itemListElement: _elements,
            };
            script.textContent = JSON.stringify(JSONLD);
        },
    },
};
</script>

<style lang="scss" scoped>
@import './tipti-recipes';
</style>
