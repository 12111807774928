<template>
    <tipti-input-text
        v-model="value"
        class="phone-input"
        autocomplete="tel"
        v-bind="{
            ...$props,
            errors: localErrors,
        }"
        @blur="validateBlur"
        @keydown="onInput"
    />
</template>
<script lang="ts" setup>
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';
import { computed, ref, watchEffect } from 'vue';
import { useApp } from '@/composables/useApp';
import { applyRegexMask } from '@/utils/phoneUtils';
import { useCountry } from '@/composables/useCountry';

const value = defineModel({
    type: String,
    default: '',
});
const props = defineProps({
    isEmptyAndRequired: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    isInvalid: {
        type: Boolean,
        default: false,
    },
    phoneAlreadyExists: {
        type: Boolean,
        default: false,
    },
    showPlaceholderWithOutFocus: {
        type: Boolean,
        default: true,
    },
    label: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    errors: {
        type: [String, Array<string>],
        default: undefined,
    },
    pattern: {
        type: String,
        default: '',
    },
});

const { $t } = useApp();
const { validatePhoneAndCountryPhone } = useCountry();
const invalidNumber = ref('');
const onInput = (event): void => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (isNaN(parseInt(event.key)) && event.key !== 'Backspace') {
        return;
    }
    let phoneNumber = value.value;
    if (event.key === 'Backspace') {
        phoneNumber = phoneNumber?.substring(0, phoneNumber.length - 1) ?? '';
    } else phoneNumber = phoneNumber + event.key;
    value.value = applyRegexMask(phoneNumber);
};

const errors12 = computed(() => {
    if (props.isEmptyAndRequired) return $t('general.required-field');
    if (props.phoneAlreadyExists) return $t('alerts.profile_number_exist');
    return '';
});

const localErrors = computed(() => {
    if (Array.isArray(props?.errors)) return [...props.errors, invalidNumber.value];
    return [props.errors ?? '', invalidNumber.value];
});

const validateBlur = () => {
    if (!props.pattern || !value.value?.length) {
        invalidNumber.value = '';
        return;
    }
    invalidNumber.value = validatePhoneAndCountryPhone(value.value) ? '' : $t('general.incorrect_number');
};

const emit = defineEmits<(e: 'on-validate', value: boolean) => void>();

watchEffect(() => {
    emit('on-validate', !!invalidNumber.value?.length);
});
</script>
<style lang="scss" scoped>
.phone-input {
    width: 100%;
    box-sizing: border-box;

    &__text {
        width: 100%;
    }
}
</style>
