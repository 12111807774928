import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07394233"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pickup_map" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pick_up_centers = _resolveComponent("pick-up-centers")!
  const _component_Marker = _resolveComponent("Marker")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return ($setup.showPickUpMap)
    ? (_openBlock(), _createBlock(_component_base_modal, {
        key: 0,
        "has-back-arrow": true,
        "has-close": false,
        "has-header": $options.isMobile,
        title: _ctx.$t('alerts.pickup__title'),
        onOnClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.showPickUpMap = false)),
        onOnBackArrow: _cache[1] || (_cache[1] = ($event: any) => ($setup.showPickUpMap = false))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_pick_up_centers, {
              centers: $data.centers,
              onOnSelected: $options.selectCenter
            }, null, 8, ["centers", "onOnSelected"]),
            _createVNode(_component_GoogleMap, {
              "api-key": $data.apiKey,
              center: $options.center,
              "disable-default-ui": false,
              "fullscreen-control": false,
              "keyboard-shortcuts": false,
              "map-type-control": false,
              options: $data.options,
              "pan-control": false,
              "street-view-control": false,
              zoom: 15,
              "zoom-control": false,
              "gesture-handling": "greedy",
              "map-type-id": "roadmap",
              style: {"width":"100%","height":"90vh"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.markers, (marker, index) => {
                  return (_openBlock(), _createBlock(_component_Marker, {
                    key: index,
                    options: {
                        draggable: false,
                        position: marker.position,
                        icon: marker.icon,
                    },
                    onClick: ($event: any) => ($options.selectCenter(marker.center))
                  }, null, 8, ["options", "onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["api-key", "center", "options"])
          ])
        ]),
        _: 1
      }, 8, ["has-header", "title"]))
    : _createCommentVNode("", true)
}