<template>
    <button class="btn-google" @click="handleCredentialResponse">
        <icon-google :size="1.4" />
    </button>
</template>
<script lang="ts">
import { logger } from '@/utils/logger';
import IconGoogle from '@/components/icons/IconGoogleSmall.vue';

export default {
    name: 'TheGoogle',
    components: {
        IconGoogle,
    },
    emits: ['on-submit'],
    computed: {
        clientId(): string {
            return process.env.VUE_APP_GOOGLE_AUTH;
        },
    },
    methods: {
        handleCredentialResponse(_) {
            try {
                const _window = window as any;
                _window.google.accounts.id.initialize({
                    client_id: this.clientId,
                    cancel_on_tap_outside: false,
                    callback: (value) => {
                        this.$emit('on-submit', value['credential']);
                    },
                });
                _window.google.accounts.id.prompt((notification) => {
                    logger('NOTIFICATION_GOOGLE_AUTH', notification);
                    if (notification.isNotDisplayed()) {
                        this.$gAuth.signIn().then((authCode) => {
                            this.$emit('on-submit', authCode.getAuthResponse().id_token);
                        });
                    }
                });
            } catch (err) {
                logger('HANDLE_CREDENTIAL_RESPONSE', err);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.btn-google {
    padding: $padding-md;
    width: fit-content;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.16));
    border: none;
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer;
    background-color: whitesmoke;
    border-radius: 25% 20%;

    img {
        height: 30px;
        width: 30px;
    }
}
</style>
