<template>
    <alert-manager
        v-if="showModalRate"
        :is-disabled="false"
        :is-loading="false"
        :show-bottom-sheet-title="false"
        :show-close-icon="false"
        padding="0px"
        title=""
        @on-close="showModalRate = false"
        @on-close-modal="showModalRate = false"
    >
        <template #content>
            <div class="rating-order__skip_mobile">
                <button class="rating-order__link-skip" @click="skipRatingOrder">
                    {{ $t('cta.skip-rate-shopper') }}
                </button>
            </div>
            <div class="rating-header">
                {{ complete ? $t('txt.my-order__thanks-for-use') : $t('txt.rate-shopper__rate-your-experience') }}
            </div>
            <section v-if="order && !complete" class="rating-order">
                <div class="rating-order__header">
                    <h2 class="rating-header__title">
                        {{
                            $t('txt.rate-shopper__rate-your-experience-header', {
                                shopper_name: order.shopperWhoBought?.name,
                            })
                        }}
                    </h2>
                    <div class="rating-header__purchase">
                        <div class="rating-header__profile">
                            <figure-profile-rate />
                            <h2 class="rating-header__title_mobile">
                                {{
                                    $t('txt.rate-shopper__rate-your-experience-header', {
                                        shopper_name: order.shopperWhoBought?.name,
                                    })
                                }}
                            </h2>
                            <rating :grade="0" :size="0.6" @stars="changeScore" />
                        </div>
                        <transition name="fade">
                            <div v-if="orderRateForm.purchase_rate >= 4" class="rating-header__options">
                                <button
                                    v-for="(item, index) in features"
                                    :key="index"
                                    class="option-comment"
                                    :class="item.active ? '__active' : ''"
                                    @click="item.active = !item.active"
                                >
                                    {{ item.txt }}
                                </button>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="rating-order__body">
                    <transition name="fade">
                        <div v-if="orderRateForm.purchase_rate >= 4 && tipPaymentAvailable" class="rating-order__tip">
                            <h3 class="rating-order__subtitle">{{ $t('txt.rate-shopper__question-leave-tip') }}</h3>
                            <div class="rating-tip__options">
                                <div
                                    v-for="(item, index) in tipOptions"
                                    :key="index"
                                    class="tip-option"
                                    :class="tipValue === item && !showCustomTip ? '__active' : ''"
                                    @click="tipValue = item"
                                >
                                    {{ currency }} {{ item }}
                                </div>
                                <div v-if="!showCustomTip" class="tip-option" @click="showCustomTip = true">
                                    {{ $t('general.other') }}
                                </div>
                                <div v-else class="tip-option" :class="{ __active: showCustomTip }">
                                    {{ currency }}
                                    <input
                                        v-model.trim="tipValue"
                                        class="tip-option__input"
                                        autofocus
                                        min="0"
                                        step="1"
                                        type="number"
                                        @keydown="onlyNumber"
                                    />
                                </div>
                            </div>
                        </div>
                    </transition>
                    <div class="rating-order__comments">
                        <h3 class="rating-order__subtitle">{{ $t('txt.rate-shopper__leave-a-comment') }}</h3>
                        <text-area v-model="rateMessage" :placeholder="$t('txt.rate-shopper__leave-comment-here')" />
                    </div>
                </div>
                <div class="rating-order__actions">
                    <btn-out-line
                        class="rating-order__button-continue"
                        :is-disabled="!orderRateForm.purchase_rate || loading"
                        :is-loading="loading"
                        :txt="$t('cta.continue')"
                        @click="saveRate"
                    />
                </div>
                <div class="rating-order__footer">
                    <button class="rating-order__link-skip" @click="skipRatingOrder">
                        {{ $t('cta.skip-rate-shopper') }}
                    </button>
                </div>
            </section>
            <section v-if="complete" class="rating-complete">
                <icon-check-outlined :size="5" />
                <p class="rating-complete__message">{{ $t('txt.rate-shopper__message-complete') }}</p>
                <a class="link__care-team" :href="`tel:${countrySelected.mainPhone}`">
                    {{ countrySelected.mainPhone }}</a
                >
            </section>
        </template>
    </alert-manager>
</template>

<script lang="ts" setup>
import AlertManager from '@/components/alerts/alertManager/AlertManager.vue';
import { useOrder } from '@/composables/useOrder';
import { useApp } from '@/composables/useApp';
import { useUser } from '@/composables/useUser';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { computed, ref, watch } from 'vue';
import { PaymentMethodElementModel } from '@/models/order/PaymentMethodElementModel';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import IconCheckOutlined from '@/components/icons/IconCheckOutlined.vue';
import TextArea from '@/components/inputs/textArea/TextArea.vue';
import Rating from '@/components/rating/Rating.vue';
import FigureProfileRate from '@/views/ratingOrder/components/figureProfileRate/FigureProfileRate.vue';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRoutes } from '@/composables/useRoutes';
import { OrderState } from '@/enums/orderState';

const { $t, notifier, $store, $route } = useApp();
const { currency, countrySelected } = useUser();
const {
    rateOrder,
    showModalRate,
    getResume,
    skipRatingOrder: skipRate,
    orderRateForm,
    order: orderSelected,
    features,
    orderToRate: order,
} = useOrder();
const { tiptiCard, fetchTiptiCard } = useTiptiCard();
const rateMessage = ref('');
const tipValue = ref(1);
const showCustomTip = ref(false);
const tipOptions = [1, 2, 3];
const complete = ref(false);
const loading = ref(false);
const { isInOrderPage } = useRoutes();
const $emit = defineEmits<{
    (e: 'on-rate'): void;
}>();

const availableMethods = computed<Array<PaymentMethodElementModel>>(
    () =>
        order.value?.paymentMethods?.filter(
            (paymentMethods: PaymentMethodElementModel) => !['wire_transfer', 'cash'].includes(paymentMethods?.name),
        ),
);

const hasTiptiCard = computed<PaymentMethodElementModel>(() =>
    availableMethods.value.find((payment) => payment.name === 'tipti_card'),
);

const tipPaymentAvailable = computed(() => {
    if (!availableMethods.value?.length) return false;
    if (availableMethods.value?.length !== 1) return true;
    if (!hasTiptiCard.value) return true;
    return tiptiCard.value?.availableAmount >= tipValue.value;
});
watch(
    () => showModalRate.value,
    (value) => {
        rateMessage.value = '';
        if (!value) return;
        complete.value = false;
    },
    {
        immediate: true,
    },
);
watch(
    () => showCustomTip.value,
    (value) => {
        tipValue.value = value ? 0 : 1;
    },
);
watch(
    () => tipPaymentAvailable.value,
    (value) => {
        if (value) return;
        if (availableMethods.value?.length !== 1) return;
        notifier({
            type: 'ERROR',
            body: $t('txt.rate-shopper__balance_tip', {
                currency_symbol: currency.value,
                tipticard_amount: tiptiCard.value?.availableAmount.toFixed(2),
            }),
        });
        tipValue.value = Math.floor(tiptiCard.value?.availableAmount);
    },
);

const onlyNumber = (event: Event) => {
    const { key } = event as KeyboardEvent;
    if (/[0-9]|Backspace/.test(key) || (key?.length !== 1 && isNaN(+key))) return;
    event.preventDefault();
};

const changeScore = (value: number): void => {
    orderRateForm.purchase_rate = value;
};

const saveRate = async () => {
    let tip = null;
    try {
        if (loading.value) return;
        loading.value = true;
        let message = rateMessage.value;
        if (orderRateForm.purchase_rate >= 4) {
            features.forEach((feature) => {
                if (feature.active) message += ` ${feature.txt}`;
            });
            if (availableMethods.value?.length) tip = tipValue.value ? tipValue.value : null;
        }

        orderRateForm.skip = false;
        orderRateForm.tip_amount = tip;
        orderRateForm.comment = message;
        await rateOrder();
        orderSelected.value.state = OrderState.ORDER_DELIVERED_RATED;
        refreshOrdersStatus();
        notifier({
            type: 'SUCCESS',
            body:
                orderRateForm.purchase_rate >= 4 && tipValue.value >= 0
                    ? $t('alerts.shopper_tip_success')
                    : $t('alerts.shopper_rate_success'),
            title: $t('alerts.thanks'),
        });
        complete.value = true;
        if (orderRateForm.tip_amount && availableMethods.value.length === 1 && hasTiptiCard.value) fetchTiptiCard();
    } catch (_) {
        notifier({
            type: 'ERROR',
            body:
                orderRateForm.purchase_rate >= 4 && tipValue.value >= 0
                    ? $t('alerts.shopper_tip_error')
                    : $t('alerts.shopper_rate_error'),
            title: $t('alerts.error'),
        });
    } finally {
        loading.value = false;
        order.value = undefined;
    }
};

const skipRatingOrder = async () => {
    try {
        showModalRate.value = false;
        await skipRate();
        orderSelected.value.state = OrderState.ORDER_DELIVERED_RATED;
        if (!isInOrderPage.value) return;
        orderSelected.value = await getResume(order.value.id);
    } catch (e) {
        notifier({
            type: 'ERROR',
            body: $t('alerts.shopper_rate_error'),
            title: $t('alerts.error'),
        });
    } finally {
        order.value = undefined;
    }
};

const refreshOrdersStatus = async (): Promise<void> => {
    await $store.dispatch('orders/fetchOrders', {
        isActiveOrders: !isInOrderPage.value,
        hideOrderToRate: $route.name === appRoutesMap.home,
    });
    if (!isInOrderPage.value) return;
    orderSelected.value = await getResume(order.value.id);
    $store.commit('orders/isHistoryOrders', true);
};
</script>
<style lang="scss" scoped>
@import './rating-order';
</style>
