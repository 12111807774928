import { logger } from '@/utils/logger';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import { MaxiBalanceModel } from '@/models/maxiDollars/MaxiBalance';
import { MaxiProgramModel } from '@/models/maxiDollars/MaxiProgram';
import { TiptiCardHistoryExchangeModel } from '@/models/historyExchange/TiptiCardHistoryExchange';

export default {
    async getMaxiCards({ rootGetters, commit }): Promise<void> {
        try {
            const url = 'client_profile/payment_method/maxi_dolares_benefit';
            const { data } = await rootGetters['network/axios'].get(url);
            commit('program', MaxiProgramModel.fromJson(data));
        } catch (err) {
            logger('GET_MAXI_CARDS_ERROR', err);
            commit('program', undefined);
        }
    },
    async getHistory({ rootGetters, commit }, { page, size = 10 }: { page: number; size: number }): Promise<boolean> {
        try {
            const cardSelected: PaymentMethodModel = rootGetters['maxiDollars/maxiCardSelected'];
            const program: MaxiProgramModel = rootGetters['maxiDollars/program'];
            const realPage = !page ? page : page * size;
            const url = `loyalty-and-reward/programs/${program.program_id}/payment/${cardSelected.id}/exchange-transactions/?page=${realPage}&size=${size}`;
            const { data } = await rootGetters['network/axios'].get(url);
            const oldHistory = rootGetters['maxiDollars/history'] ?? [];
            const newHistory = TiptiCardHistoryExchangeModel.builderList(data.results);
            const all = [
                ...new Set([...oldHistory, ...newHistory].map(history => JSON.stringify(history))),
            ].map((history: string) => JSON.parse(history));
            commit('history', all);
            return !!data.next;
        } catch (err) {
            logger('GET_MAXI_HISTORY_ERROR', err);
            commit('history', []);
        }
    },
    async getBalance({ rootGetters, commit }): Promise<void> {
        try {
            const cardSelected: PaymentMethodModel = rootGetters['maxiDollars/maxiCardSelected'];
            const program: MaxiProgramModel = rootGetters['maxiDollars/program'];
            const url = `loyalty-and-reward/programs/${program.program_id}/payment/${cardSelected.id}/reward-balance/`;
            const { data } = await rootGetters['network/axios'].get(url);
            commit('balance', MaxiBalanceModel.fromJson(data));
        } catch (err) {
            logger('GET_MAXI_BALANCE_ERROR', err);
            commit(
                'balance',
                MaxiBalanceModel.fromJson({
                    current_balance: '0',
                    status: 'error',
                    reward_type: 'maxiDollars',
                }),
            );
        }
    },
    async sendConversion({ rootGetters }, amount: number): Promise<boolean> {
        try {
            if (!amount || amount <= 0) return;
            const cardSelected: PaymentMethodModel = rootGetters['maxiDollars/maxiCardSelected'];
            const program: MaxiProgramModel = rootGetters['maxiDollars/program'];
            const url = `loyalty-and-reward/programs/${program.program_id}/payment/${cardSelected.id}/exchange/`;
            const payload = {
                total_maxidolars: amount,
            };
            const { data } = await rootGetters['network/axios'].post(url, payload);
            return data.status === 'ok';
        } catch (err) {
            logger('SEND_MAXI_CONVERSION_ERROR', err);
            return false;
        }
    },
};
