<template>
    <svg
        :aria-labelledby="iconName"
        :height="`${height}pt`"
        :viewBox="viewBox"
        :width="`${width}pt`"
        role="presentation"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title :id="iconName" lang="en">{{ iconName }}</title>
        <g :fill="iconColor">
            <slot />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        iconName: {
            type: String,
            default: 'default',
        },
        viewBox: {
            type: String,
            required: true,
        },
        width: {
            type: [Number, String],
            default: 20,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
        iconColor: {
            type: String,
            default: '#000',
        },
    },
};
</script>
