import { defineStore } from 'pinia';
import { SharedCartModel, toSharedCartModel } from '@/models/sharedCart/SharedCartModel';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';

export const useCartShared = defineStore('cart-shared', {
    state: (): {
        show: boolean;
    } => ({
        show: false,
    }),
    actions: {
        async sharedCarts(): Promise<Array<SharedCartModel>> {
            const { data } = await axiosInstance().get('v2/client_profile/get_shared_all/');
            return data?.map((cart) => toSharedCartModel(cart));
        },
        async setAsDefault(cart_id: number): Promise<void> {
            await axiosInstance().post('v2/client_profile/cart_default/', {
                cart_id,
            });
        },
        async updateSharedCart({ cart_shared_id, name }: { cart_shared_id: number; name: string }): Promise<void> {
            await axiosInstance().put('v2/client_profile/cart_shared_edit/', { cart_shared_id, name });
        },
        async deleteCart(cart_shared_id: number): Promise<void> {
            await axiosInstance().delete('v2/client_profile/delete_shared/', {
                data: { cart_shared_id },
            });
        },
        async createSharedCart({ name, clients }: { name: string; clients: string[] }): Promise<void> {
            await axiosInstance().post('v2/client_profile/create_shared/', {
                name,
                clients: clients.join(','),
            });
        },
        async addParticipant(data: { cart_shared_id: number; clients: string }): Promise<void> {
            await axiosInstance().put('v2/client_profile/add_participant_shared/', data);
        },
        async deleteParticipant(data: { cart_shared_id: number; client_id: string }): Promise<void> {
            await axiosInstance().delete('v2/client_profile/delete_participant_shared/', {
                data,
            });
        },
    },
});
