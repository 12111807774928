<template>
    <base-icon :icon-name="$t('icon.document')" :width="width" :height="height" viewBox="0 0 109 74" :iconColor="color">
        <path
            d="M103.75 0.369385H5.24992C2.3551 0.369385 0 2.72449 0 5.6193V62.4411C0 65.1962 2.1339 67.4607 4.83578 67.6728V68.279C4.83578 71.1738 7.19088 73.5281 10.0857 73.5281H99.1655C102.059 73.5281 104.415 71.1738 104.415 68.279V67.647C106.997 67.3193 109 65.1106 109 62.4411V5.6193C109 2.72449 106.645 0.369385 103.75 0.369385V0.369385ZM105.704 62.4411C105.704 63.5189 104.828 64.3946 103.75 64.3946H21.5128C20.6022 64.3946 19.8645 65.133 19.8645 66.0428C19.8645 66.9534 20.6022 67.6911 21.5128 67.6911H101.119V68.279C101.119 69.3568 100.242 70.2324 99.1655 70.2324H10.0857C9.00793 70.2324 8.13142 69.3568 8.13142 68.279V67.6911H13.9685C14.8791 67.6911 15.6167 66.9534 15.6167 66.0428C15.6167 65.133 14.8791 64.3946 13.9685 64.3946H5.24992C4.17216 64.3946 3.29565 63.5189 3.29565 62.4411V5.6193C3.29565 4.54154 4.17216 3.66503 5.24992 3.66503H103.75C104.828 3.66503 105.704 4.54154 105.704 5.6193V62.4411Z"
        />
        <path
            d="M37.9553 26.0518H12.3735C10.1098 26.0518 8.26782 27.8938 8.26782 30.1574V55.7392C8.26782 58.0028 10.1098 59.8448 12.3735 59.8448H37.9553C40.2189 59.8448 42.0609 58.0028 42.0609 55.7392V30.1574C42.0609 27.8929 40.2189 26.0518 37.9553 26.0518ZM18.8783 56.5492V52.7038C18.8783 51.8589 19.566 51.1712 20.4109 51.1712H29.4272C30.2721 51.1712 30.9598 51.8581 30.9598 52.7038V56.5492H18.8783ZM38.7652 55.7392C38.7652 56.1858 38.4018 56.5492 37.9553 56.5492H34.2554V52.7038C34.2554 50.041 32.09 47.8755 29.4272 47.8755H20.4109C17.7489 47.8755 15.5826 50.041 15.5826 52.7038V56.5492H12.3735C11.9269 56.5492 11.5635 56.1858 11.5635 55.7392V30.1574C11.5635 29.7108 11.9269 29.3474 12.3735 29.3474H37.9553C38.4018 29.3474 38.7652 29.7108 38.7652 30.1574V55.7392Z"
        />
        <path
            d="M24.919 32.2073C21.5036 32.2073 18.7244 34.9857 18.7244 38.4019C18.7244 41.8173 21.5036 44.5965 24.919 44.5965C28.3352 44.5965 31.1136 41.8173 31.1136 38.4019C31.1136 34.9857 28.3344 32.2073 24.919 32.2073ZM24.919 41.3C23.3206 41.3 22.0208 39.9994 22.0208 38.4011C22.0208 36.8027 23.3206 35.5029 24.919 35.5029C26.5173 35.5029 27.818 36.8027 27.818 38.4011C27.818 39.9994 26.5173 41.3 24.919 41.3Z"
        />
        <path
            d="M99.0232 27.3499H48.722C47.8114 27.3499 47.0737 28.0875 47.0737 28.9973C47.0737 29.9079 47.8114 30.6455 48.722 30.6455H99.024C99.9338 30.6455 100.671 29.9079 100.671 28.9973C100.671 28.0875 99.9338 27.3499 99.0232 27.3499Z"
        />
        <path
            d="M48.722 38.0802H81.3009C82.2107 38.0802 82.9491 37.3425 82.9491 36.4319C82.9491 35.5213 82.2107 34.7837 81.3009 34.7837H48.722C47.8114 34.7837 47.0737 35.5213 47.0737 36.4319C47.0737 37.3425 47.8114 38.0802 48.722 38.0802Z"
        />
        <path
            d="M61.7856 42.1018H48.722C47.8114 42.1018 47.0737 42.8394 47.0737 43.7492C47.0737 44.6598 47.8114 45.3975 48.722 45.3975H61.7856C62.6954 45.3975 63.4339 44.6598 63.4339 43.7492C63.4339 42.8394 62.6954 42.1018 61.7856 42.1018Z"
        />
        <path
            d="M96.1426 8.55908H90.5434C89.6328 8.55908 88.8952 9.29671 88.8952 10.2073C88.8952 11.1179 89.6328 11.8556 90.5434 11.8556H96.1426C96.8229 11.8556 97.3759 12.4086 97.3759 13.088V13.4489C97.3759 14.1283 96.8229 14.6814 96.1426 14.6814H11.7032C11.0238 14.6814 10.4708 14.1283 10.4708 13.4489V13.088C10.4708 12.4086 11.0238 11.8556 11.7032 11.8556H82.4494C83.3601 11.8556 84.0977 11.1179 84.0977 10.2073C84.0977 9.29671 83.3601 8.55908 82.4494 8.55908H11.7032C9.20593 8.55908 7.17432 10.5907 7.17432 13.088V13.4489C7.17432 15.9454 9.20593 17.9778 11.7032 17.9778H96.1426C98.6399 17.9778 100.672 15.9454 100.672 13.4489V13.088C100.672 10.5907 98.6399 8.55908 96.1426 8.55908Z"
        />
        <path
            d="M98.1915 50.1484C96.584 51.3625 94.4443 53.0798 92.3113 52.3122C91.2535 51.9313 90.298 51.6361 89.226 52.2232C88.9624 52.3687 88.7071 52.5417 88.4576 52.7297C88.283 51.538 87.1512 50.7563 85.9902 51.3816C85.1861 51.8149 84.3586 52.1692 83.4963 52.4419C84.0568 50.9001 84.2539 49.0423 83.611 47.5064C82.8193 45.6153 80.6979 44.9508 78.8867 45.8523C77.2908 46.6448 75.9461 48.5899 75.3108 50.2124C74.6422 51.9213 74.989 53.7625 76.3836 55.0041C76.5349 55.1388 76.6929 55.2586 76.8542 55.3717C75.7174 56.0935 74.4684 56.6598 73.2509 57.1929C72.4293 57.5521 72.2563 58.7388 72.6596 59.4474C73.142 60.2939 74.1041 60.3937 74.9149 60.0386C77.0638 59.0981 79.1977 57.9563 80.8734 56.3613C82.4152 56.2814 84.0168 55.8307 85.4472 55.2594C85.478 55.5338 85.5079 55.8091 85.5387 56.0835C85.6983 57.5147 87.2169 58.2415 88.352 57.2486C88.8468 56.8162 89.3416 56.3837 89.8364 55.9513C90.056 55.7592 90.2738 55.5638 90.4959 55.3742C90.7686 55.1413 90.4235 55.1164 90.8468 55.2469C91.8847 55.5696 92.7836 55.8981 93.9005 55.7891C96.1508 55.5696 98.0926 54.3247 99.8556 52.9941C100.571 52.4536 100.923 51.5779 100.446 50.7396C100.046 50.0361 98.9009 49.6136 98.1915 50.1484ZM78.485 51.1496C78.8459 50.4012 79.6077 49.0798 80.431 48.7654C80.4775 48.7471 80.5349 48.7371 80.5906 48.738C80.5083 48.7621 80.6455 48.975 80.6705 49.1272C80.752 49.6303 80.7062 50.1592 80.599 50.654C80.4177 51.4881 80.0326 52.2823 79.527 52.975C79.4073 52.9492 79.2908 52.9184 79.1769 52.8777C78.3046 52.5675 78.08 51.9904 78.485 51.1496Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-document',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
        },
    },
    computed: {
        width(): number {
            return 109 * this.size;
        },
        height(): number {
            return 74 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
