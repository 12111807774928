import { TiptiAlertModel, BaseTiptiAlertModelInterface } from '@/models/alerts/AlertOpenRetailer';

interface ShippingLimitInterface extends BaseTiptiAlertModelInterface {
    num_items: number;
}

export class ShippingLimitModel extends TiptiAlertModel implements ShippingLimitInterface {
    num_items: number;

    constructor(data?: ShippingLimitInterface) {
        super(data);
        this.setNumItems();
    }

    private setNumItems(): void {
        this.num_items = +this.attributes.find(attr => attr.name === 'num_items')?.value ?? 0;
    }

    static fromJson(data: object) {
        if (!data) return null;
        if (data['title'] == null) return null;
        return new ShippingLimitModel(data as ShippingLimitInterface);
    }
}
