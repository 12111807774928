<template>
    <base-icon :height="height" :icon-name="$t('icon.orders')" :iconColor="color" :width="width" viewBox="0 0 25 24">
        <path
            :fill="color"
            d="M24.8854 17.6106C24.7264 18.2522 24.6409 18.9242 24.3948 19.5298C23.388 22.0076 21.5471 23.5242 18.8983 23.9189C15.7006 24.3951 12.801 22.7419 11.465 19.7943C10.2752 17.1687 10.984 13.8328 13.127 11.8928C14.6627 10.5033 16.4613 9.89522 18.5164 10.0438C21.595 10.2668 24.3173 12.7933 24.7767 15.8384C24.8063 16.0325 24.8494 16.2243 24.8854 16.4169C24.8854 16.8156 24.8854 17.2135 24.8854 17.6106ZM16.6099 18.6701C16.1265 18.1827 15.6766 17.7209 15.2164 17.2694C14.896 16.9546 14.5372 16.9378 14.264 17.2039C14.0003 17.4612 14.0099 17.8495 14.3119 18.1571C14.9032 18.7604 15.5009 19.3581 16.1041 19.9493C16.4325 20.2713 16.7825 20.2825 17.1037 19.9637C18.5643 18.5143 20.0201 17.0601 21.4624 15.5931C21.5918 15.462 21.6597 15.2295 21.6749 15.0369C21.6941 14.7892 21.5207 14.6087 21.2922 14.5216C21.0253 14.4193 20.7824 14.496 20.5826 14.6934C20.1935 15.0769 19.8076 15.4644 19.4217 15.8511C18.4948 16.7804 17.5687 17.7089 16.6099 18.6701Z"
        />
        <path
            :fill="color"
            d="M12.773 4.60241C14.2983 4.60241 15.8237 4.60241 17.3642 4.60241C17.3642 6.13254 17.3642 7.65148 17.3642 9.15764C13.8269 9.40534 11.2581 11.0665 10.125 14.4552C9.00001 17.8199 10.0635 20.6636 12.7267 22.9807C12.6316 22.9807 12.5477 22.9807 12.4646 22.9807C9.54734 22.9807 6.63011 22.9807 3.71287 22.9807C1.81839 22.9807 0.5 21.6663 0.5 19.7783C0.5 14.8155 0.5 9.85359 0.5 4.89085C0.5 4.80376 0.5 4.71747 0.5 4.60241C2.04531 4.60241 3.57624 4.60241 5.10717 4.60241L5.10078 4.59521C5.10078 5.35668 5.10078 6.11815 5.10078 6.87962C5.63133 6.87962 6.12912 6.87962 6.64209 6.87962C6.64209 6.10697 6.64209 5.35109 6.64209 4.59521L6.6357 4.60161C8.17222 4.60161 9.70954 4.60161 11.2461 4.60161L11.2397 4.59521C11.2397 5.35668 11.2397 6.11815 11.2397 6.87962C11.7702 6.87962 12.268 6.87962 12.781 6.87962C12.781 6.10697 12.781 5.35109 12.781 4.59521L12.773 4.60241Z"
        />
        <path
            :fill="color"
            d="M5.10875 4.60253C5.10236 3.96571 5.07759 3.32888 5.27335 2.70724C5.8103 0.998134 7.45469 -0.126092 9.22932 0.0113396C11.0447 0.151968 12.5029 1.52469 12.7354 3.33847C12.789 3.75476 12.7642 4.18064 12.7754 4.60253L12.7818 4.59534C12.268 4.59534 11.7542 4.59534 11.2405 4.59534L11.2469 4.60173C11.2445 4.31488 11.2492 4.02723 11.2397 3.74038C11.2005 2.58739 10.2601 1.61818 9.10946 1.53987C7.9333 1.45997 6.8674 2.27897 6.68762 3.43356C6.6277 3.81629 6.65167 4.21181 6.63649 4.60173L6.64288 4.59534C6.12911 4.59534 5.61533 4.59534 5.10156 4.59534L5.10875 4.60253Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-orders',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 25 * this.size;
        },
        height(): number {
            return 24 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
