<template>
    <p class="badge-chat">{{ messagesUnread }}</p>
</template>

<script>
export default {
    name: 'BadgeChat',
    props: {
        messagesUnread: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.badge-chat {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: calc($padding-xsm/2);
    @include overline;
    font-weight: 600;
    color: $white;
    background-color: $error;
    border-radius: $radius-circular;
    min-width: 1rem;
    min-height: 1rem;
    max-height: 1rem;
}
</style>
