import { TiptiCardDealsModel, toTiptiCardDealsModel } from '@/models/tiptiCard/TiptiCardDealsModel';
import { TiptiCardGiftCardsModel, toTiptiCardGiftCardsModel } from '@/models/tiptiCard/TiptiCardGiftCardsModel';
import { TiptiCardModel, toTiptiCardModel } from '@/models/tiptiCard/TiptiCardModel';
import { TiptiCardsBeneficiary, toTiptiCardsBeneficiary } from '@/models/tiptiCard/TiptiCardsBeneficiary';
import { logger } from '@/utils/logger';

export default {
    async tiptiCardsBeneficiary({ rootGetters }, tiptiCardId: number): Promise<TiptiCardsBeneficiary> {
        try {
            const url = `clients/tipti-cards/?card-number=${tiptiCardId}`;
            const { data } = await rootGetters['network/axios'].get(url);
            return toTiptiCardsBeneficiary(data);
        } catch (err) {
            logger('TIPTI_CARDS_BENEFICIARY', err);
        }
    },
    async cancelPrimeSubscription({ rootGetters }, primeId: number): Promise<void> {
        try {
            const url = `v2/client_profile/tipti_card_prime/cancelled_subscription/${primeId}/`;
            await rootGetters['network/axios'].delete(url);
        } catch (err) {
            throw Error(err);
        }
    },
    async transferTiptiCard({ rootGetters }, payload: { cardId: string; amount: string }): Promise<void> {
        try {
            const url = 'v2/client_profile/tipti_card/send_v2/';
            const body = {
                card_id: payload.cardId,
                amount: payload.amount,
            };
            await rootGetters['network/axios'].post(url, body);
        } catch (err) {
            throw err.response.data;
        }
    },
    async buyTiptiCard({ rootGetters, getters }, isGift: boolean): Promise<void> {
        try {
            const url = 'client_profile/buy_tipti_card/';
            const body = {
                payment_method_id: rootGetters['payments/paymentIdSelected'],
                amount: getters['totalToPay'],
                as_gift: isGift,
                response_flag: true,
            };
            await rootGetters['network/axios'].post(url, body);
        } catch (err) {
            throw err.response;
        }
    },
    async tiptiPrimeDeals({ rootGetters, commit, getters }): Promise<void> {
        try {
            const url = 'v2/client_profile/tipti_card_prime_all';
            const { data } = await rootGetters['network/axios'].get(url);
            commit('primeDeals', data?.map((deals: object) => toTiptiCardDealsModel(deals)) ?? []);
            commit(
                'defaultPrime',
                getters['primeDeals'].find((prime: TiptiCardDealsModel) => prime.periodTypeDuration === 'YEAR'),
            );
        } catch (_) {
            throw Error('TIPTI_PRIME_DEALS');
        }
    },
    async buyTiptiPrime({ rootGetters }, primeBase: number): Promise<void> {
        try {
            const url = `v2/client_profile/tipti_card_prime/create`;
            const data = {
                payment_method: rootGetters['payments/paymentIdSelected'],
                prime_base: primeBase,
                response_flag: true,
            };
            await rootGetters['network/axios'].post(url, data);
        } catch (err) {
            throw err.response;
        }
    },
    async tiptiCard({ rootGetters, commit }): Promise<void> {
        try {
            const url = 'v2/client_profile/tipti_card/';
            const { data } = await rootGetters['network/axios'].get(url);
            commit('tiptiCard', toTiptiCardModel(data));
        } catch (_) {
            throw Error('TIPTI_CARD');
        }
    },
    async codeRefillTiptiCard({ rootGetters }, code: string): Promise<TiptiCardModel> {
        try {
            const url = 'v2/client_profile/claim_tipti_card/';
            const body = { code: code };
            const { data } = await rootGetters['network/axios'].put(url, body);
            return toTiptiCardModel(data);
        } catch (err) {
            logger('CODE_REFILL_TIPTI_CARD', err);
            throw err.response?.data;
        }
    },
    async getGifCards({ rootGetters }): Promise<Array<TiptiCardGiftCardsModel>> {
        try {
            const url = 'v2/client_profile/tipti_card_buys/';
            const { data } = await rootGetters['network/axios'].get(url);
            return data.map((giftCard: object) => toTiptiCardGiftCardsModel(giftCard));
        } catch (err) {
            throw Error(err);
        }
    },
};
