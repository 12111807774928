<template>
    <base-icon
        :icon-name="$t('icon.close')"
        :width="width"
        :height="height"
        viewBox="0 0 12 12"
        iconColor="transparent"
        fill="none"
    >
        <path
            d="M10.433 1.00317L5.43457 6.00158L10.433 11"
            :stroke="color"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M0.999637 10.9968L5.99805 5.99842L0.999637 1.00001"
            :stroke="color"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-search-close',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 12 * this.size;
        },
        height(): number {
            return 12 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
