<template>
    <section class="tipti-card-payment">
        <coupon :full-width="true" :is-tipti-card="true" section="TIPTI_CARD"></coupon>
        <div class="separator"></div>
        <card-tipti-card></card-tipti-card>
        <div class="tipti-card-balance">
            <p>{{ $t("general['available-balance']") }}</p>
            <p v-currency="availableAmount" />
        </div>
        <base-card-flat class="tipti-card-usage">
            <div></div>
            <p>{{ $t(canUsetiptiCard ? "general['use-balance']" : "txt['tipti-card__you-need-recharge']") }}</p>
            <check-tipti v-if="canUsetiptiCard" :is-checked="isSelected" @on-check="selectTiptiCard"></check-tipti>
            <div v-else></div>
        </base-card-flat>
    </section>
    <general-alert
        v-if="showAlertMixPayment"
        :message="
            $t('txt.mix-payment__are-you-sure', {
                amount: availableAmount,
                difference: difference,
                method: 'Tipti Card',
                currency: currency,
            })
        "
        @on-accept="acceptMixPayment"
        @on-decline="showAlertMixPayment = false"
        @on-close="showAlertMixPayment = false"
    >
        <template #icon>
            <icon-tipti-card :size="4" color="#FF9012"></icon-tipti-card>
        </template>
    </general-alert>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import CardTiptiCard from '@/components/cards/cardTiptiCard/CardTiptiCard.vue';
import CheckTipti from '@/components/checks/CheckTipti.vue';
import IconTiptiCard from '@/components/icons/IconTiptiCard.vue';
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import Coupon from '@/components/inputs/redeem/Coupon.vue';
import { useCheckoutEvents } from '@/views/checkout/composables/useCheckoutEvents';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { useUser } from '@/composables/useUser';
import { useApp } from '@/composables/useApp';
import { appRoutesMap } from '@/router/appRoutesMap';
///////
export default {
    name: 'TiptiCardPayment',
    components: {
        CardTiptiCard,
        CheckTipti,
        IconTiptiCard,
        GeneralAlert,
        BaseCardFlat,
        Coupon,
    },
    setup() {
        const { isMobile } = useApp();
        const { selectPaymentsMethod } = useCheckoutEvents();
        const { availableAmount } = useTiptiCard();
        const { currency } = useUser();
        return {
            selectPaymentsMethod,
            availableAmount,
            currency,
            isMobile,
        };
    },
    data() {
        return {
            showAlertMixPayment: false,
            isSelected: false,
        };
    },
    computed: {
        ...mapGetters({
            totalToPay: 'payments/totalToPay',
            isMixInstitutionalPayment: 'payments/isMixInstitutionalPayment',
            secondPayment: 'payments/secondPayment',
        }),
        canUsetiptiCard(): boolean {
            /// * Verify that in mix payment with insitutional the tipti card has the difference
            return (
                (this.isMixInstitutionalPayment && +this.availableAmount >= this.secondPayment) ||
                (!this.isMixInstitutionalPayment && this.availableAmount > 0)
            );
        },
        difference(): string {
            return this.isMixInstitutionalPayment
                ? (+this.secondPayment - +this.availableAmount).toFixed(2)
                : (+this.totalToPay - +this.availableAmount).toFixed(2);
        },
    },
    methods: {
        selectTiptiCard(isTiptiCard: boolean): void {
            this.isSelected = isTiptiCard;
            this.$store.commit('payments/cleanPaymentMethods');
            this.$store.commit('payments/isTiptiCardPayment', isTiptiCard);
            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-tipti-card',
                    amount:
                        +this.difference > 0
                            ? +this.availableAmount
                            : this.isMixInstitutionalPayment
                              ? this.secondPayment
                              : this.totalToPay,
                    data: this.tiptiCard.id,
                    analyticData: {
                        tipticard_current_available_amount: this.availableAmount,
                    },
                    objectData: ['payment-tipti-card', this.tiptiCard?.id],
                }),
            );
            if (this.isMixInstitutionalPayment) return;
            if (isTiptiCard && +this.totalToPay?.toFixed(2) > +this.availableAmount?.toFixed(2))
                this.showAlertMixPayment = true;
        },
        acceptMixPayment() {
            this.showAlertMixPayment = false;
            this.$store.commit('payments/isMixPaymentTiptiCard', true);
            this.$store.commit('payments/secondPayment', +this.difference);
            if (this.isMobile) this.$router.replace({ name: appRoutesMap.checkout.payments.index });
        },
    },
};
</script>

<style lang="scss" scoped>
@import './tipti-card-payment.scss';
</style>
