class StoryElementModel {
    id: number;
    viewed: boolean;
    file: string;
    showOrder: number;
    durationTime: number;

    constructor(id: number, viewed: boolean, file: string, showOrder: number, durationTime: number) {
        this.id = id;
        this.viewed = viewed;
        this.file = file;
        this.showOrder = showOrder;
        this.durationTime = durationTime;
    }
}

const toStoryElementModel = (data: object) => {
    return data
        ? new StoryElementModel(
              data['id'],
              data['viewed'] ?? false,
              data['file'] ?? '',
              data['show_order'],
              data['duration_time'],
          )
        : null;
};

export { StoryElementModel, toStoryElementModel };
