import { OrderModel } from '@/models/order/OrderModel';
import { OrderResumenModel } from '@/models/order/OrderResumen';
import { ProductModel } from '@/models/product/ProductModel';

export default {
    orderDetailSelected(state): OrderResumenModel {
        return state.orderDetailSelected;
    },
    productsToCart(state): Array<ProductModel> {
        return state.productsToCart;
    },
    orders(state): Array<OrderModel> {
        return state.orders;
    },
    orderRateSelected(state): OrderModel {
        return state.orderRateSelected;
    },
    selectedRetailer(state): string {
        return state.selectedRetailer;
    },
    isHistoryOrders(state): boolean {
        return state.isHistoryOrders;
    },
    productsForSegment(state): Array<object> {
        return state.productsForSegment;
    },
    isSelectedOrder(state) {
        return state.isSelectedOrder;
    },
};
