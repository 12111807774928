import ProductsFlowModel from '@/models/deeplinks/ProductsFlowModel';

export default {
    productsFlow(state): ProductsFlowModel {
        return state.productsFlow;
    },
    showModal(state) {
        return state.showModal;
    },
    showRetailerNotFoundModal(state) {
        return state.showRetailerNotFoundModal;
    },
    successModal(state) {
        return state.successModal;
    },
    messageModal(state) {
        return state.messageModal;
    },
    tiptiCardBalance(state) {
        return state.tiptiCardBalance;
    },
    showStory(state) {
        return state.showStory;
    },
    deeplinkNoLoggedTrail(state): Map<string, string> {
        return state.deeplinkNoLoggedTrail;
    },
    deeplinkFullPath(state: { deeplinkFullPath: string }): string {
        return state.deeplinkFullPath;
    },
    productNotFound(state) {
        return state.productNotFound;
    },
    isFromDeeplink(state, isFromDeeplink: boolean) {
        return state.isFromDeeplink;
    },
};
