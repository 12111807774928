<template>
    <div
        class="input-wrapper"
        :class="{ 'input-red': isInvalidFieldByErrors, 'input-orange': !isInvalidFieldByErrors }"
        @focusin="whitFocus = true"
        @focusout="whitFocus = false"
    >
        <template v-if="errors?.length">
            <p v-if="!Array.isArray(errors)" class="input-error">
                {{ errors }}
            </p>
            <template v-for="error in errors" v-else :key="error">
                <p v-if="error.length" class="input-error">
                    {{ error }}
                </p>
            </template>
        </template>
        <div v-else class="input-error--helper"></div>
        <slot />
        <label class="control-label" :for="label" :style="{ color: labelColor }">
            {{ label }}
            <span v-if="isRequired">*</span>
        </label>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, useSlots } from 'vue';

const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    hasFocus: {
        type: Boolean,
        default: false,
    },
    errors: {
        type: [String, Array<string>],
        default: undefined,
    },
});

const slots = useSlots();
const whitFocus = ref(false);

const isInvalidFieldByErrors = computed(() => {
    if (Array.isArray(props.errors)) {
        return props.errors.some((err) => !!err.length);
    }
    return !!props.errors.length;
});

const setFocus = () => {
    if (!slots.default().length) return;
    //@ts-ignore
    const { ctx } = slots.default()[0];
    if (!ctx) return;
    const { refs } = ctx;
    if (!refs || !Object.keys(refs).length || !props.hasFocus) return;
    const [first] = Object.keys(refs);
    const ref = refs[first];
    ref.focus();
};

const labelColor = computed(() => {
    if (whitFocus.value) return '#ff9012';
    return '#707070';
});

onMounted(() => {
    setFocus();
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    box-sizing: border-box;
}

.input-orange {
    :slotted(input) {
        @include body1;
        position: relative;
        border: 2px solid $border;
        border-radius: $radius-md;
        margin-top: 10px;
        padding: 0.75rem $padding-md;
        background-color: $bright-canvas;

        &:focus {
            outline-color: $orange;
            outline-width: 2px;
            border: 2px solid $orange;
        }

        &:not(:focus) {
            outline-color: $white;
            outline-width: 2px;
            border: 2px solid $white;
        }
    }

    :slotted(.password-filed) {
        position: relative;
        margin-top: 10px;
        padding: 0.75rem $padding-md;
        border-radius: $radius-md;
        background-color: $bright-canvas;

        & > input {
            position: static;
            border: 1px solid transparent;
            border-radius: inherit;
            margin-top: unset;
            padding: unset;

            &:focus {
                outline-color: $orange;
                outline-width: 0;
            }
        }
    }
}

:slotted(.password-filed):focus-within {
    border: 1px solid $orange;
}

.input-red {
    :slotted(input) {
        @include body1;
        position: relative;
        background-color: $bright-canvas;
        border: 1px solid $border;
        border-radius: $radius-md;
        margin-top: 10px;
        padding: 0.75rem $padding-md;

        &:not(:focus) {
            border: 1px solid lighten($error, 20%);
        }
    }

    :slotted(input:focus) {
        outline-color: $error;
        outline-width: 2px;
        border: 2px solid $error;
    }
}

label {
    @include caption;
    text-transform: capitalize;
    position: absolute;
    top: -5%;
    z-index: 0;
    left: 0.5em;
    padding: 0 $padding-xsm;
    overflow: hidden;
    background-color: $white;
    text-overflow: ellipsis;
    white-space: normal;
    box-sizing: border-box;
    display: -webkit-box;
    transition: all 0.05s ease-out;
}

:slotted(input:not(:focus)) + label {
    color: $txt;
}

:slotted(input:focus) + label {
    color: $orange;
    font-weight: 600;
}

.input-red + :slotted(input:focus) {
    outline-color: $error;
}

:slotted(::-webkit-input-placeholder) {
    @include body2;
    color: $disable;
}

:slotted(::-moz-placeholder) {
    @include body2;
    color: $disable;
}

.full-width {
    width: 100%;
}

.input-error {
    @include caption;
    color: $error;
    margin: $margin-sm;

    &:not(:last-child) {
        margin: 0 $margin-sm;
    }

    &--helper {
        height: 5px;
    }
}
</style>
