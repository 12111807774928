
import { PropType } from 'vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import IconBack from '@/components/icons/IconBack.vue';

enum Colors {
    white = '#fff',
    orange = '#ff9012',
    magenta = '#f03f51',
    text = '#707070',
}

type ColorAvailable = keyof typeof Colors;
type SizeAvailable = 14 | 16 | 20 | 24 | 28;
type WeightAvailable = 200 | 400 | 600;
const __default__ = {
    name: 'BaseHeader',
    components: { IconBack, IconCircularClose },
    props: {
        text: {
            type: String,
            default: '',
        },
        textColor: {
            type: String as PropType<ColorAvailable>,
            default: 'text',
        },
        /**
         * @description This property will reduce in 2px in tablet size or less
         */
        textSize: {
            type: Number as PropType<SizeAvailable>,
            default: 28,
        },
        textWeight: {
            type: Number as PropType<WeightAvailable>,
            default: 600,
        },
        withDefaultClose: {
            type: Boolean,
            default: false,
        },
        withDefaultIconBack: {
            type: Boolean,
            default: false,
        },
        sizeIcon: {
            type: Number,
            default: 1,
        },
    },
    emits: ['on-close', 'on-back'],
    computed: {
        txtColor() {
            return Colors[this.textColor] ?? Colors.text;
        },
        txtSize(): string {
            return (this.textSize ?? 28) + 'px';
        },
    },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "ba501524": (_ctx.txtColor),
  "3c4f5440": (_ctx.textWeight),
  "601eac96": (_ctx.txtSize),
  "bd8cab16": (_ctx.txtSize - 2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__