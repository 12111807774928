<template>
    <base-icon
        :icon-name="$t('icon.notifications')"
        :width="width"
        :height="height"
        viewBox="0 0 30 38"
        :iconColor="color"
    >
        <path
            d="M10.2327 33.4648C10.2327 33.4749 10.2327 33.4849 10.2327 33.4949C10.2327 34.6898 10.7073 35.8358 11.5523 36.6807C12.3972 37.5256 13.5432 38.0003 14.7381 38.0003C15.933 38.0003 17.0789 37.5256 17.9239 36.6807C18.7688 35.8358 19.2434 34.6898 19.2434 33.4949C19.2434 33.4849 19.2434 33.4749 19.2434 33.4648H10.2327Z"
        />
        <path
            d="M29.1229 27.8349L25.0682 21.8784V14.4485C25.068 12.0604 24.2405 9.74615 22.7266 7.89931C21.2126 6.05247 19.1057 4.78708 16.7641 4.31835V2.02516C16.7641 1.48806 16.5507 0.972949 16.1709 0.593157C15.7911 0.213365 15.276 0 14.7389 0C14.2018 0 13.6867 0.213365 13.3069 0.593157C12.9271 0.972949 12.7137 1.48806 12.7137 2.02516V4.31835C10.3721 4.78708 8.26517 6.05247 6.75122 7.89931C5.23727 9.74615 4.4098 12.0604 4.40955 14.4485V21.8798L0.354923 27.8363C0.145443 28.1441 0.0238261 28.5032 0.00315842 28.875C-0.0175093 29.2467 0.0635514 29.6171 0.237621 29.9462C0.411691 30.2753 0.672189 30.5508 0.991095 30.7429C1.31 30.9351 1.67525 31.0367 2.04758 31.0368H27.4288C27.8011 31.0367 28.1664 30.9351 28.4853 30.7429C28.8042 30.5508 29.0647 30.2753 29.2387 29.9462C29.4128 29.6171 29.4939 29.2467 29.4732 28.875C29.4525 28.5032 29.3309 28.1441 29.1214 27.8363L29.1229 27.8349Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-bell',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C7C7C7',
        },
    },
    computed: {
        width(): number {
            return 30 * this.size;
        },
        height(): number {
            return 38 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
