import { TiptiCardDealsModel } from '@/models/tiptiCard/TiptiCardDealsModel';
import { TiptiCardModel } from '@/models/tiptiCard/TiptiCardModel';

export default {
    tiptiCard(state): TiptiCardModel {
        return state.tiptiCard;
    },
    primeSelected(state): TiptiCardDealsModel {
        return state.primeSelected;
    },
    totalToPay(state): number {
        return state.totalToPay;
    },
    isTiptiCardCheckout(state): boolean {
        return state.isTiptiCardCheckout;
    },
    primeDeals(state): Array<TiptiCardDealsModel> {
        return state.primeDeals;
    },
    defaultPrime(state): TiptiCardDealsModel {
        return state.defaultPrime;
    },
};
