<template>
    <section class="tipti-card">
        <aside v-if="!selectedOption" class="tipti-card-options">
            <the-prime-btn-with-banner class="bottom-margin" />
            <card-tipti-card class="tipti-card-options__card bottom-margin" />
            <share-button-tipti-card class="bottom-margin" />
            <card-coupon class="bottom-margin" is-tipti-card section="TIPTI_CARD" />
            <amount-description />
            <TiptiCardOptions />
            <cancel-prime />
            <tipti-card-history v-if="isMobile" />
        </aside>
        <aside v-if="!isMobile || selectedOption" class="tipti-card-elements">
            <router-view />
        </aside>
    </section>
</template>

<script lang="ts" setup>
import { isMobileBrowser } from '@/utils/utils';
import AmountDescription from './components/amountDescription/AmountDescription.vue';
import TiptiCardOptions from '../tiptiCard/components/tiptiCardOptions/TiptiCardOptions.vue';
import CardTiptiCard from '@/components/cards/cardTiptiCard/CardTiptiCard.vue';
import CardCoupon from '@/components/cards/cardCoupon/CardCoupon.vue';
import TiptiCardHistory from './views/tiptiCardHistory/TiptiCardHistory.vue';
import CancelPrime from './components/cancelPrime/CancelPrime.vue';
import ShareButtonTiptiCard from './components/shareButtonTiptiCard/ShareButtonTiptiCard.vue';
import ThePrimeBtnWithBanner from '@/components/buttons/ThePrimeBtnWithBanner.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'TiptiCard',
});

const isMobile = computed(() => isMobileBrowser());
const $route = useRoute();

const selectedOption = computed(() => {
    if (!isMobile.value) return false;
    return $route.meta.show_router_view && $route.name !== appRoutesMap.tiptiCardRoutes.history;
});
</script>

<style lang="scss" scoped>
@import './tipti-card.scss';
</style>
