class DeliveryDateModel {
    readonly retailerId: number;
    readonly retailerName: string;
    readonly firstDate: string;
    readonly secondDate: string;
    constructor(retailerId: number, retailerName: string, firstDate: string, secondDate: string) {
        this.retailerId = retailerId;
        this.retailerName = retailerName;
        this.firstDate = firstDate;
        this.secondDate = secondDate;
    }
}
const toDeliveryDateModel = (data: object): DeliveryDateModel => {
    return data
        ? new DeliveryDateModel(data['retailer_id'], data['retailer_name'], data['first_date'], data['second_date'])
        : null;
};
export { DeliveryDateModel, toDeliveryDateModel };
