<template>
    <transition name="scale-fade">
        <slot />
    </transition>
</template>

<script>
export default {
    name: 'transition-scale-fade',
};
</script>

<style scoped lang="scss">
.scale-fade-enter-active,
.scale-fade-leave-active {
    transition: all 0.5s ease;
}

.scale-fade-enter-from,
.scale-fade-leave-to {
    opacity: 0;
    transform: scale(1.1);
}
</style>
