class PageStructure {
    readonly pageName: string;
    readonly title: string;
    readonly image: string;
    readonly description: string;
    readonly callToActionSuccess: string;
    readonly callToActionFailure: string;
    constructor(
        pageName: string,
        title: string,
        image: string,
        description: string,
        callToActionSuccess: string,
        callToActionFailure: string,
    ) {
        this.pageName = pageName;
        this.title = title ?? '';
        this.image = image ?? '';
        this.description = description ?? '';
        this.callToActionSuccess = callToActionSuccess ?? '';
        this.callToActionFailure = callToActionFailure ?? '';
    }
}
const buildScreenLabels = data => {
    if (!data) return null;
    const pagesNames = Object.keys(data);
    return pagesNames.map(
        page =>
            new PageStructure(
                page,
                data[page]['title'],
                data[page]['image'],
                data[page]['description'],
                data[page]['call_to_action_success'],
                data[page]['call_to_action_failure'],
            ),
    );
};

export { PageStructure, buildScreenLabels };
