import { AddressModel } from '@/models/address/AddressModel';
import { BaseConfigurationModel } from '@/models/baseConfiguration/BaseConfigurationModel';
import { RetailerPermissionModel } from '@/models/cart/RetailerPermissionModel';
import { ClientProfileModel } from '@/models/user/ClientProfileModel';

export default {
    token(state: { token: string }, token: string): void {
        state.token = `JWT ${token}`;
    },
    user(state: { user: ClientProfileModel }, profile: ClientProfileModel): void {
        state.user = profile;
    },
    userLocation(state: { user: ClientProfileModel }, currentLocation: AddressModel): void {
        state.user['currentAddress'] = currentLocation;
    },
    baseConfiguration(state: { baseConfiguration: BaseConfigurationModel }, baseConfiguration: BaseConfigurationModel) {
        state.baseConfiguration = baseConfiguration;
    },
    grantedPermissions(
        state: { grantedPermissions: Array<RetailerPermissionModel> },
        permissions: Array<RetailerPermissionModel>,
    ): void {
        state.grantedPermissions = permissions;
    },
    selectedDocumentType(state: { selectedDocumentType: string }, type: string): void {
        state.selectedDocumentType = type;
    },
    requireAuth(state: { requireAuth: boolean }, requireAuth: boolean): void {
        state.requireAuth = requireAuth;
    },
};
