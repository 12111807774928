<template>
    <base-card class="surplus-card" trailColor="#FF9012" v-if="pendingPayment">
        <header class="surplus-card-header">
            <icon-warning size="0.8"></icon-warning>
            <h2>{{ $t("txt['notifications__have-pending-payments']") }}</h2>
        </header>
        <aside class="surplus-card-body">
            <div class="surplus-card-body__data">
                <h3>{{ $t("general['order']") }}# {{ pendingPayment?.order }}</h3>
                <p>{{ formatDate(pendingPayment.creationDate) }}</p>
            </div>
            <div class="surplus-card-price">
                <h3>+{{ currency }} {{ pendingPayment?.amountReceivable?.toFixed(2) }}</h3>
                <p>{{ paymentMethod }}</p>
            </div>
        </aside>
        <footer class="surplus-card-fotter">
            <btn-out-line
                :txt="$t('cta.reject')"
                :isLoading="isLoadingReject"
                bgColor="transparent"
                color="#FF9012"
                :isDisabled="isLoadingReject || isLoadingAccept"
                @click="isLoadingReject || isLoadingAccept ? null : setPayment('REJECTED_BY_CLIENT')"
            />
            <btn-solid
                :txt="$t('cta.accept')"
                :isLoading="isLoadingAccept"
                :isDisabled="isLoadingReject || isLoadingAccept"
                @click="isLoadingAccept || isLoadingReject ? null : setPayment('APPROVED')"
            />
        </footer>
    </base-card>
    <snack-bar
        @on-snackbar-close="showSnackBar = false"
        :isSuccess="isSuccess"
        :isFailure="!isSuccess"
        :body="snackBarMessage"
        :isActive="showSnackBar"
    ></snack-bar>
</template>

<script lang="ts">
import { logger } from '@/utils/logger';
import { mapGetters } from 'vuex';

import BaseCard from '@/components/cards/BaseCard.vue';
import IconWarning from '@/components/icons/IconWarning.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/en';

export default {
    name: 'surplus-card',
    data() {
        return {
            isLoadingAccept: false,
            isLoadingReject: false,
            showSnackBar: false,
            isSuccess: false,
            snackBarMessage: this.$t("txt['notifications__pending-processed-failure']"),
        };
    },
    components: {
        BtnSolid,
        BaseCard,
        IconWarning,
        BtnOutLine,
        SnackBar,
    },
    computed: {
        ...mapGetters({
            pendingPayment: 'notifications/pendingPayment',
            countrySelected: 'external/countrySelected',
            user: 'user/user',
        }),
        currency() {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        paymentMethod(): string {
            return this.pendingPayment?.cashPayment
                ? this.$t("general['payment-cash']")
                : this.pendingPayment?.wireTransferPayment
                ? this.$t("general['payment-wire-transfer']")
                : this.pendingPayment?.payphonePayment
                ? this.$t("general['payment-payphone']")
                : this.pendingPayment?.tiptiCardPayment
                ? this.$t("general['payment-tipti-card']")
                : this.pendingPayment?.paymentMethodToUse
                ? this.$t("general['payment-cards']")
                : '';
        },
    },
    methods: {
        async setPayment(status: string): Promise<void> {
            try {
                status == 'APPROVED' ? (this.isLoadingAccept = true) : (this.isLoadingReject = true);
                const paymentDone = await this.$store.dispatch('payments/setPendingPayment', {
                    status: status,
                    payment: this.pendingPayment,
                });
                this.isSuccess = paymentDone;
                this.snackBarMessage = paymentDone
                    ? this.$t("txt['notifications__pending-processed-success']")
                    : this.$t("txt['purchase__payment-rejected']");
                this.$store.commit('notifications/pendingPayment', null);
            } catch (err) {
                this.snackBarMessage = this.$t("txt['notifications__pending-processed-failure']");
                this.isSuccess = false;
                logger('SET_PAYMENT', err);
            } finally {
                this.showSnackBar = true;
                this.isLoadingAccept = false;
                this.isLoadingReject = false;
            }
        },
        formatDate(date: string) {
            return dayjs(date)
                .locale(this.user?.['defaultLanguage']?.toLowerCase())
                .format('MMM D YYYY');
        },
    },
};
</script>

<style lang="scss" scoped>
@import './surplus-card.scss';
</style>
