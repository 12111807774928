import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { BaseConfigurationModel } from '@/models/baseConfiguration/BaseConfigurationModel';
import { AddCreditCardModel } from '@/models/paymentMethods/AddCreditCard';
import { NetworkError, PaymentErrorOnAdd } from '@/modules/core/errors/AppErrors';
import { useCheckoutEvents } from '@/views/checkout/composables/useCheckoutEvents';
import { paymentMethodService } from '@/modules/payment-methods/infraestructure/PaymentMethodService';
import { WorkingState } from '@/modules/core/types/WorkingState';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import { appRoutesMap } from '@/router/appRoutesMap';

export const useAddCreditCard = () => {
    const $store = useStore();
    const { t } = useI18n();
    const $route = useRoute();
    const cardNumberLengthToSearchBrand = 6;
    const minPropertyLength = 3;
    const { creditCardEvent } = useCheckoutEvents();
    const addingState = ref<WorkingState>('NONE');

    const debitAmount = computed<number>(() => {
        const storeDefined: BaseConfigurationModel = $store.getters['user/baseConfiguration'];
        return storeDefined?.maxDebitAmount ?? 0;
    });

    const owner = ref('');
    const cardBrandData = reactive({
        image: '',
        brand: '',
    });

    /// reference number card
    const number = ref('');
    const finalNumberCard = ref('');

    watch(number, (newValue, oldValue) => {
        if (newValue === oldValue) return;
        const pattern = /[0-9]{1,4}/g;
        number.value = `${newValue}`?.match(pattern)?.join(' - ') ?? '';
    });

    const setBrandCardData = (payload?: { image: string; brand: string }) => {
        if (!payload) {
            cardBrandData.brand = '';
            cardBrandData.image = '';
            return;
        }
        cardBrandData.brand = payload.brand;
        cardBrandData.image = payload.image;
    };

    watch(
        number,
        (newValue, oldValue) => {
            finalNumberCard.value = `${newValue ?? ''}`?.match(/[0-9]/g)?.join('') ?? '';
            if (finalNumberCard.value?.length < cardNumberLengthToSearchBrand) {
                if (cardBrandData.image?.length) setBrandCardData();
                return;
            }
            const oldNumbers = `${oldValue ?? ''}`?.match(/[0-9]/g)?.join('') ?? '';
            if (
                finalNumberCard.value.substr(0, cardNumberLengthToSearchBrand) ===
                oldNumbers.substr(0, cardNumberLengthToSearchBrand)
            )
                return;
            paymentMethodService
                .findCardBrandByBin(finalNumberCard.value.substr(0, cardNumberLengthToSearchBrand))
                .then((result) => {
                    setBrandCardData(result);
                })
                .catch(() => {
                    setBrandCardData();
                });
        },
        {
            immediate: true,
        },
    );
    /// end reference number card

    /// reference date
    const date = ref<string>('');
    const dateToShowInFakeCard = ref<string>('');
    watch(date, (newValue: string) => {
        const pattern = /[0-9]{1,2}/g;
        let value = newValue;
        if (+newValue[0] > 3 && newValue?.length === 1) {
            value = `0${newValue}`;
        }
        date.value = value.match(pattern)?.join('/') ?? '';
    });

    watch(date, (newValue) => {
        let value = newValue.length ? newValue?.match(/\d/g)?.join('') : '0';
        if (value.length < 4) value = value.padStart(4, '0');
        dateToShowInFakeCard.value = value?.match(/\d{1,2}/g)?.join('/');
    });
    /// end reference date

    const alias = ref('');
    const isDefault = ref(false);
    const code = ref('');

    const phone = ref('');

    const isFormCompleted = computed(
        () =>
            number.value?.length > cardNumberLengthToSearchBrand &&
            alias.value?.length >= minPropertyLength &&
            code.value?.length >= minPropertyLength &&
            phone.value?.length >= minPropertyLength &&
            owner.value?.length >= minPropertyLength &&
            date.value?.length === 5,
    );

    const addCreditCard = async (): Promise<{ id: number; message: string }> => {
        try {
            if (!isFormCompleted.value) return;
            addingState.value = 'LOADING';
            const cardData = new AddCreditCardModel({
                date: date.value,
                name: owner.value,
                phone: phone.value,
                number: finalNumberCard.value,
                isDefault: isDefault.value,
                alias: alias.value,
                code: code.value,
            });
            const result = await paymentMethodService.addPaymentMethod(cardData);
            addingState.value = 'SUCCESS';

            creditCardEvent({
                newCreditCardAdded: true,
                bin: cardData.number.substring(0, 6),
            });
            if (($route.name as string).includes(appRoutesMap.paymentResent.bodyCredit)) {
                $store.commit(
                    'payments/paymentsToShow',
                    new PaymentToShowModel({
                        name: 'payment-cards',
                        icon: result.creditCardBrandData?.coverPicture,
                        data: result.data?.cardDigits,
                        id: result.id,
                        objectData: ['payment-cards', result.id],
                        alias: alias.value,
                    }),
                );
            }
            return { id: result.id, message: t("txt['card__save-success']") };
        } catch (err) {
            let errorMessage: string;
            if (!(err instanceof PaymentErrorOnAdd)) errorMessage = t("txt['card__save-error']");
            if (err instanceof NetworkError) errorMessage = t('alerts.connection_error');
            if (err instanceof PaymentErrorOnAdd) errorMessage = err.message ?? t("txt['card__save-error']");
            addingState.value = 'ERROR';
            creditCardEvent({
                newCreditCardAdded: false,
                bin: number.value.replace(/ /g, '').substring(0, 6),
            });
            return { id: null, message: errorMessage };
        }
    };
    return {
        owner,
        number,
        date,
        alias,
        isDefault,
        code,
        phone,
        debitAmount,
        finalNumberCard,
        cardBrandData,
        dateToShowInFakeCard,
        isFormCompleted,
        addCreditCard,
        addingState,
    };
};
