import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-755f2dde"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pick-up-centers" }
const _hoisted_2 = { class: "pick-up-centers__title" }
const _hoisted_3 = { class: "centers" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "center-data" }
const _hoisted_7 = { class: "center-data__name" }
const _hoisted_8 = { class: "center-data__address" }
const _hoisted_9 = { class: "center-data__sector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow = _resolveComponent("icon-arrow")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('alerts.pickup__footer')), 1),
    _createElementVNode("nav", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.centers, (center) => {
        return (_openBlock(), _createElementBlock("button", {
          key: center.id,
          class: "center",
          onClick: ($event: any) => (_ctx.$emit('on-selected', center))
        }, [
          _createElementVNode("img", {
            class: "center__img",
            src: center.logo,
            alt: ""
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h2", _hoisted_7, _toDisplayString(center.name), 1),
            _createElementVNode("h3", _hoisted_8, _toDisplayString(center.address), 1),
            _createElementVNode("p", _hoisted_9, _toDisplayString(center?.city?.name ?? '') + ", " + _toDisplayString(center?.sector.name ?? ''), 1)
          ]),
          _createVNode(_component_icon_arrow, { size: 0.5 })
        ], 8, _hoisted_4))
      }), 128))
    ])
  ]))
}