class TiptiCardDealsModel {
    readonly id: number;
    readonly name: string;
    readonly isTrial: boolean;
    readonly toBuy: boolean;
    readonly price: number;
    readonly priceMonth: number;
    readonly period: number;
    readonly periodTypeDuration: string;
    readonly termsAndConditionsFile: string;
    readonly termsAndConditionsHtml: string;
    readonly discountRate: number;
    readonly logo: string;
    readonly benefits: Array<string>;

    constructor(
        id: number,
        name: string,
        isTrial: boolean,
        toBuy: boolean,
        period: number,
        price: number,
        priceMonth: number,
        periodTypeDuration: string,
        termsAndConditionsFile: string,
        termsAndConditionsHtml: string,
        discountRate: number,
        logo: string,
        benefits: Array<string>,
    ) {
        this.id = id;
        this.name = name;
        this.isTrial = isTrial;
        this.toBuy = toBuy;
        this.period = period;
        this.price = price;
        this.priceMonth = priceMonth;
        this.periodTypeDuration = periodTypeDuration;
        this.termsAndConditionsFile = termsAndConditionsFile;
        this.termsAndConditionsHtml = termsAndConditionsHtml;
        this.discountRate = discountRate;
        this.logo = logo;
        this.benefits = benefits;
    }
}

const toTiptiCardDealsModel = (data: object): TiptiCardDealsModel => {
    return data
        ? new TiptiCardDealsModel(
              data['id'],
              data['name'],
              data['is_trial'],
              data['to_buy'],
              data['period'],
              data['price'] ? +data['price'] : 0,
              data['price']
                  ? data['period_type_duration'] === 'YEAR'
                      ? +(+data['price'] / 12).toFixed(2)
                      : +data['price']
                  : 0,
              data['period_type_duration'],
              data['terms_and_conditions_file'],
              data['terms_and_conditions_html'],
              data['discount_rate'] ? +data['discount_rate'] : 0,
              data['logo'] ?? '',
              data['benefits'] ?? [],
          )
        : null;
};

export { TiptiCardDealsModel, toTiptiCardDealsModel };
