import { CartRetailerModel } from './CartRetailerModel';
import { TotalDetailsModel, toTotalDetailModel } from './TotalDetailsModel';
import { RetailerBasicInfoModel } from '@/models/cart/RetailerBasicInfoModel';

interface CartBaseInterface {
    id: number;
    name: string;
    retailers?: Array<CartRetailerModel>;
    retailerIds?: Array<number>;
}

interface CartInterface extends CartBaseInterface {
    id: number;
    isDefault: boolean;
    totalDetails: TotalDetailsModel;
    discountApplied: Array<string>;
}

export interface CartInputInterface extends CartBaseInterface {
    id: number;
    name: string;
    default: boolean;
    total_details: TotalDetailsModel;
    discounts_applied: Array<string>;
    gift_cards_client_use: any;
    shipping_cost?: number;
    retailersBasicInfo: Array<RetailerBasicInfoModel>;
}

export class CartModel implements Readonly<CartInterface> {
    private static instance: CartModel;
    id: number;
    retailers: Array<CartRetailerModel>;
    name: string;
    isDefault: boolean;
    totalDetails: TotalDetailsModel;
    discountApplied: Array<string>;
    retailerIds: Array<number>;
    retailersBasicInfo: Array<RetailerBasicInfoModel>;

    constructor(data?: CartInputInterface) {
        if (!data) return;
        this.id = data.id;
        this.retailers = CartRetailerModel.builderList(data.retailers);
        this.name = data.name ?? '';
        this.isDefault = data.default ?? false;
        this.totalDetails = data.total_details
            ? toTotalDetailModel({
                  ...data.total_details,
                  gift_card_amount:
                      data.gift_cards_client_use?.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.covered_amount;
                      }, 0) ?? 0,
                  shipping_cost: data.shipping_cost ?? 0,
              })
            : ({} as TotalDetailsModel);
        this.discountApplied = data.discounts_applied?.length
            ? data.discounts_applied.map((discount) => discount['discount_name'])
            : [];
        this.retailerIds = data.retailerIds?.length
            ? data.retailerIds.map((retailer) => retailer['id'])
            : this.retailers?.map((theRetailer: CartRetailerModel) => theRetailer.id);
        this.retailersBasicInfo =
            data.retailersBasicInfo ??
            this.retailers.map((ret) => {
                return {
                    id: ret.id,
                    cart_items: ret.products.length,
                };
            });
    }

    //*** used only for extra-items, normal purchase use totalDetails from server response
    get cartExtraItemAmount(): number {
        return (
            +this.retailers
                ?.reduce((accumulator, { extraItemsProductPrice }) => accumulator + extraItemsProductPrice, 0)
                .toFixed(2) ?? 0
        );
    }

    public static getInstance(): CartModel {
        if (!CartModel.instance) CartModel.instance = new CartModel();
        return CartModel.instance;
    }

    static fromJSON(data: object | CartInputInterface): CartModel {
        if (!data || !Object.keys(data).length) return;
        return new CartModel(data as CartInputInterface);
    }
}
