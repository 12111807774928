<template>
    <base-icon icon-name="watch-outlined" :width="width" :height="height" viewBox="0 0 12 13" :iconColor="color">
        <path
            d="M6 0.5C2.6934 0.5 0 3.1934 0 6.5C0 9.8066 2.6934 12.5 6 12.5C9.3066 12.5 12 9.8066 12 6.5C12 3.1934 9.3066 0.5 6 0.5ZM6 1.7C8.65807 1.7 10.8 3.84193 10.8 6.5C10.8 9.15807 8.65807 11.3 6 11.3C3.34193 11.3 1.2 9.15807 1.2 6.5C1.2 3.84193 3.34193 1.7 6 1.7Z"
            fill="#606060"
        />
        <path
            d="M6.00039 2.89941C5.84126 2.89941 5.68865 2.96264 5.57613 3.07516C5.4636 3.18768 5.40039 3.34028 5.40039 3.49941V6.48066C5.40041 6.55973 5.41605 6.63801 5.44641 6.71101C5.47678 6.78401 5.52128 6.8503 5.57734 6.90605L7.37734 8.69434C7.49018 8.80651 7.64295 8.86927 7.80206 8.86882C7.96116 8.86837 8.11358 8.80475 8.22578 8.69194C8.33796 8.5791 8.40072 8.42633 8.40027 8.26722C8.39982 8.10811 8.33619 7.9557 8.22338 7.8435L6.60039 6.23105V3.49941C6.60039 3.34028 6.53718 3.18768 6.42465 3.07516C6.31213 2.96264 6.15952 2.89941 6.00039 2.89941Z"
            fill="#606060"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-watch-outlined',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 12 * this.size;
        },
        height(): number {
            return 13 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
