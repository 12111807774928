<script lang="ts" setup>
import CheckSquare from '@/components/checks/CheckSquare.vue';
import { GiftCardsAlertCheckoutModel } from '@/models/giftCardsAlert/giftCardsAlertCheckoutModel';
import { computed, PropType } from 'vue';
import { useGiftCards } from '@/composables/useGiftCards';

const props = defineProps({
    giftCard: {
        type: Object as PropType<GiftCardsAlertCheckoutModel>,
        required: true,
    },
});

const { useGiftCard, idGiftCards } = useGiftCards();

const isSelected = computed(() => idGiftCards.value.some((card) => card === props.giftCard.id));
</script>

<template>
    <img class="gift-card-alert-checkout__img" :alt="giftCard?.image_gift_card" :src="giftCard?.image_gift_card" />
    <aside class="gift-card-alert-checkout__card-description">
        <p class="gift-card-alert-checkout__card-description__top">{{ giftCard?.name_gift_card }}</p>
        <p class="gift-card-alert-checkout__card-description__bottom">
            {{ $t('txt.gift-card-valid-until') }}{{ giftCard?.expiration_date }}
        </p>
    </aside>

    <p v-currency="giftCard?.available_amount" class="gift-card-alert-checkout__label" />
    <check-square :is-checked="isSelected" @on-check="(value) => useGiftCard(giftCard.id, value)" />
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index';

.gift-card-alert-checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $padding-md;

    &__img {
        display: block;
        width: 5rem;
        height: 3rem;
        border-radius: $radius-md;
        object-fit: fill;
    }

    &__tipti-card {
        display: flex;
        align-items: center;

        &__text {
            @include caption;
            color: $white;
            font-size: 12px;
        }
    }

    &__description {
        @include body2;
        margin-bottom: $margin-lg;
        max-width: 22rem;
        text-align: center;
        @include phone {
            font-size: 14px;
        }
    }

    &__cards-list {
        display: flex;
        align-items: center;
        gap: 1rem;
        min-width: 26rem;
        width: 100%;
        margin-bottom: $margin-lg;
    }

    &__card-description {
        @include body1;
        flex-grow: 1;

        &__top {
            @include body1;
        }

        &__bottom {
            @include body2;
        }
    }

    &__label {
        @include overline;
        color: $orange;
    }
}

.general-alert-btns-checkout {
    display: grid;
    gap: $margin-md;
    margin: $margin-sm 0;
    width: 90%;
    @include phone {
        grid-template-columns: 1fr;
    }
}

.general-alert-btns--one-btn-checkout {
    grid-template-columns: 1fr;
}
</style>
