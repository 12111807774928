import { useStore } from 'vuex';
import { computed, reactive } from 'vue';
import { BaseConfigurationModel } from '@/models/baseConfiguration/BaseConfigurationModel';
import { CountriesModel } from '@/models/external/CountriesModel';
import { ClientProfileModel } from '@/models/user/ClientProfileModel';
import { AddressModel } from '@/models/address/AddressModel';

const modalAddressShowConfig = reactive({
    show: false,
    section: '',
});

export const useUser = () => {
    const $store = useStore();
    const currentRoutePath = computed({
        get: () => $store.getters['app/currentRoutePath'],
        set: (value) => $store.commit('app/currentRoutePath', value),
    });
    const addressDefault = computed<AddressModel>(() => $store.getters['location/addressDefault']);

    const countrySelected = computed<CountriesModel>({
        get: () => $store.getters['external/countrySelected'],
        set: (value) => $store.commit('external/countrySelected', value),
    });
    const baseConfiguration = computed<BaseConfigurationModel>(() => $store.getters['user/baseConfiguration']);
    const profile = computed<ClientProfileModel>(() => $store.getters['user/user']);
    const userLocation = computed<AddressModel>({
        get: () => $store.getters['user/userLocation'],
        set: (value) => $store.commit('user/userLocation', value),
    });
    const userLanguage = computed<string>(() => $store.getters['user/userLanguage']);

    const currency = computed<string>(() => countrySelected.value?.currencySymbol ?? '$');
    const currencyName = computed<string>(() => countrySelected.value?.currencyName ?? 'USD');
    const alphaCode = computed<string>(() => countrySelected.value?.alphaCode ?? 'EC');
    const isAuth = computed<boolean>(() => $store.getters['user/isAuth']);
    const checkCompletedProfile = computed<boolean>(() => !!profile.value?.document && !!profile.value?.phone);
    const isPrime = computed<boolean>(() => Boolean(profile.value?.isPrime));
    const fetchBaseConfiguration = () => $store.dispatch('user/baseConfiguration');

    const requireAuth = computed<boolean>({
        get: () => $store.getters['user/requireAuth'],
        set: (value) => $store.commit('user/requireAuth', value),
    });

    return {
        profile,
        currency,
        baseConfiguration,
        alphaCode,
        currencyName,
        isAuth,
        userLocation,
        userLanguage,
        checkCompletedProfile,
        fetchBaseConfiguration,
        countrySelected,
        getProfile: () => $store.dispatch('user/getProfile'),
        getAddresses: () => $store.dispatch('location/countryAddresses'),
        requireAuth,
        addressDefault,
        currentRoutePath,
        isPrime,
        modalAddressShowConfig,
    };
};
