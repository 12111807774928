<template>
    <section v-if="showCancelPrime" class="cancel-prime">
        <p>{{ $t('txt.tipti-card__remember-that') }}</p>
        <btn-out-line
            :txt="$t('cta.cancel-subscription')"
            bg-color="#FFF"
            color="#C7C7C7"
            @click="showPreActionAlert = true"
        />
    </section>
    <general-alert
        v-if="showPreActionAlert"
        :accept-button-txt="$t('cta.yes-cancel')"
        :decline-button-txt="$t('cta.no-return')"
        :is-disabled="isLoading"
        :is-loading="isLoading"
        :message="$t('txt.tipti-card__are-you-sure')"
        @on-accept="isLoading ? null : cancelSubscription()"
        @on-decline="showPreActionAlert = false"
        @on-close="showPreActionAlert = false"
    />
    <snack-bar
        :body="alertMessage"
        :is-active="showAlert"
        :is-failure="!isSuccess"
        :is-success="isSuccess"
        @on-snackbar-close="showAlert = false"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { logger } from '@/utils/logger';

import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import dayjs from 'dayjs';

export default {
    name: 'CancelPrime',
    components: { BtnOutLine, SnackBar, GeneralAlert },
    data() {
        return {
            isSuccess: false,
            isLoading: false,
            showPreActionAlert: false,
            showAlert: false,
        };
    },
    computed: {
        ...mapGetters({ user: 'user/user' }),
        showCancelPrime(): boolean {
            return (
                this.user?.isPrime &&
                !this.user?.isCancelled &&
                !this.user?.primeInformation?.isExclusive &&
                this.user?.primeInformation?.tiptiCardPrimeId
            );
        },
        nextDatePayment(): string {
            return this.user?.primeInformation?.dateNextPayment
                ? dayjs(this.user.primeInformation.dateNextPayment).format('DD MMM')
                : '';
        },
        alertMessage(): string {
            return this.isSuccess
                ? this.$t('txt.tipti-card__we-are-sorry', { date: this.nextDatePayment })
                : this.$t('general.your-message-not-sent');
        },
    },
    methods: {
        async cancelSubscription(): Promise<void> {
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    'tiptiCard/cancelPrimeSubscription',
                    this.user.primeInformation.tiptiCardPrimeId,
                );
                await this.$store.dispatch('user/getProfile');
                this.isSuccess = true;
                this.showPreActionAlert = false;
            } catch (err) {
                logger('CANCEL_SUBSCRIPTION', err);
                this.isSuccess = false;
            } finally {
                this.isLoading = false;
                this.showAlert = true;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.cancel-prime {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $margin-md;
    margin: $margin-md 0;
    @include desktop {
        grid-template-columns: 1fr;
    }
}

.cancel-prime p {
    @include caption;
}
</style>
