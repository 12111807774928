import ProductsFlowModel from '@/models/deeplinks/ProductsFlowModel';

export default {
    productsFlow(state, data: ProductsFlowModel) {
        state.productsFlow = data;
    },
    showModal(state, showModal: boolean) {
        state.showModal = showModal;
    },
    showRetailerNotFoundModal(state, showRetailerNotFoundModal: boolean) {
        state.showRetailerNotFoundModal = showRetailerNotFoundModal;
    },
    successModal(state, successModal: boolean) {
        state.successModal = successModal;
    },
    messageModal(state, messageModal: string) {
        state.messageModal = messageModal;
    },

    tiptiCardBalance(state, value: number) {
        state.tiptiCardBalance = value;
    },
    showStory(state, value: boolean) {
        state.showStory = value;
    },
    deeplinkNoLoggedTrail(state, queries: Map<string, string>): void {
        state.deeplinkNoLoggedTrail = queries;
    },
    showProductNotFound(state, data) {
        state.productNotFound = data;
    },
    isFromDeeplink(state, isFromDeeplink: boolean) {
        state.isFromDeeplink = isFromDeeplink;
    },
};
