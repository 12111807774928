
import { PropType } from 'vue';
import { AlignmentContent, JustifyContent } from '@/modules/core/components/enums/Aigments';

const __default__ = {
    name: 'BaseColumn',
    props: {
        alignItems: {
            type: String as PropType<AlignmentContent>,
            default: 'center',
        },
        justifyContent: {
            type: String as PropType<JustifyContent>,
            default: 'center',
        },
    },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "763c183a": (_ctx.alignItems),
  "efd9b670": (_ctx.justifyContent)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__