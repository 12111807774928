import { computed, ref, shallowRef } from 'vue';
import { useRoute } from 'vue-router';
import { RequestState } from '@/modules/core/types/WorkingState';
import { useIntersectionObserver } from '@vueuse/core';

export const usePagination = <T>(
    {
        onIntersect,
    }: {
        onIntersect?: Function;
    } = { onIntersect: undefined },
) => {
    const $route = useRoute();

    const elementRef = ref<HTMLElement>();
    const isIntersecting = ref(false);

    const localData = shallowRef<T[]>([]);

    const localRequestState = ref<RequestState>('NONE');

    const requestState = computed({
        get: () => localRequestState.value,
        set: (value) => {
            if (localRequestState.value === 'LOAD-ENDED') return;
            localRequestState.value = value;
        },
    });
    const canFetch = computed(() => requestState.value === 'NONE');

    const searchOn = computed(() => ($route.query.searchOn || $route.params.searchOn) as string);
    const page = ref(+(($route.query.page ?? 1) as string));
    const totalPages = ref(0);

    const paginatorKey = computed(() => `${page.value}${searchOn.value}`);

    const data = computed({
        get: () => localData.value,
        set: (value: T[]) => {
            localData.value = [...localData.value, ...value];
        },
    });

    const { stop, resume } = useIntersectionObserver(elementRef, async ([{ isIntersecting: intersected }]) => {
        if (!onIntersect) return;
        if (isIntersecting.value === intersected) return;
        isIntersecting.value = intersected;
        await onIntersect();
    });

    const $reset = () => {
        localData.value = [];
        page.value = 1;
        requestState.value = 'NONE';
        if (!onIntersect) return;
        resume();
    };

    return {
        $stopIntersection: stop,
        elementRef,
        isIntersecting,
        data,
        canFetch,
        requestState,
        page,
        totalPages,
        searchOn,
        paginatorKey,
        $reset,
    };
};
