import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69e3a9bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_back = _resolveComponent("icon-back")!
  const _component_icon_circular_close = _resolveComponent("icon-circular-close")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass({ 'only-desktop': $props.onlyDesktop })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({ 'modal-bg': $props.hasBackground }),
        style: _normalizeStyle($options.style),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close')))
      }, null, 6),
      _createElementVNode("section", {
        class: _normalizeClass(["base-modal", {
                    'auto-size': $props.autoSize,
                    'auto-size--full-screen': $props.coverAllScreen,
                    'full-modal': $props.isFullModal,
                    'full-modal--full-screen': $props.coverAllScreen,
                    'base-modal--full-screen': $props.coverAllScreen,
                }]),
        style: _normalizeStyle($options.style)
      }, [
        ($props.hasHeader)
          ? (_openBlock(), _createElementBlock("header", {
              key: 0,
              class: _normalizeClass(["modal-header", { 'modal-header--orange': $props.orangeHeader }])
            }, [
              ($props.hasBackArrow)
                ? (_openBlock(), _createBlock(_component_icon_back, {
                    key: 0,
                    class: "cursor-pointer",
                    "background-fill": "#fff",
                    color: "#606060",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-back-arrow')))
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_1)),
              _createElementVNode("h1", {
                class: "modal-header__title",
                style: _normalizeStyle({ color: $props.headerTextColor })
              }, _toDisplayString($props.title), 5),
              ($props.hasClose)
                ? (_openBlock(), _createBlock(_component_icon_circular_close, {
                    key: 2,
                    class: "cursor-pointer modal-header__close",
                    alt: _ctx.$t('cta.close'),
                    color: $props.colorIconclose,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close')))
                  }, null, 8, ["alt", "color"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2))
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["base-modal__body", {
                        'no-scroll': $props.noScroll,
                        'base-modal__body--center': $props.isCentered,
                        'base-modal__body--full-screen': $props.coverAllScreen,
                    }])
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2)
      ], 6)
    ], 2)
  ]))
}