import { PrimeInformationModel, toPrimeInformationModel } from './PrimeInformationModel';
import { toCurrentSectorModel, CurrentSectorModel } from './CurrentSectorModel';
import { getFacebookImage } from '@/utils/utils';
import { AddressModel, toAddressModel } from '../address/AddressModel';
class ClientProfileModel {
    id: number;
    currentSector: CurrentSectorModel;
    currentAddress: AddressModel;
    phone: string;
    name: string;
    genre: string;
    document: string;
    userId: number;
    facebookUserId: string;
    lastname: string;
    email: string;
    birthday: string;
    profilePicture: string;
    isPrime: boolean;
    isPrimeCancelled: boolean;
    defaultLanguage: string;
    tiptiCardBalance: number;
    isExclusive: boolean;
    qrCodeReferred: string;
    primeInformation: PrimeInformationModel;
    totalOrders: number;
    isInsitutionalPayment: boolean;
    userType: string;
    registerDate: string;

    constructor(
        id: number,
        currentSector: CurrentSectorModel,
        currentAddress: AddressModel,
        phone: string,
        name: string,
        genre: string,
        document: string,
        userId: number,
        facebookUserId: string,
        lastname: string,
        email: string,
        birthday: string,
        profilePicture: string,
        isPrime: boolean,
        isPrimeCancelled: boolean,
        tiptiCardBalance: number,
        isExclusive: boolean,
        qrCodeReferred: string,
        primeInformation: PrimeInformationModel,
        defaultLanguage: string,
        totalOrders: number,
        isInsitutionalPayment: boolean,
        userType: string,
        registerDate: string,
    ) {
        this.id = id;
        this.currentSector = currentSector;
        this.currentAddress = currentAddress;
        this.phone = phone;
        this.name = name;
        this.genre = genre;
        this.document = document;
        this.userId = userId;
        this.facebookUserId = facebookUserId;
        this.lastname = lastname;
        this.email = email;
        this.birthday = birthday;
        this.profilePicture = profilePicture;
        this.isPrime = isPrime;
        this.isPrimeCancelled = isPrimeCancelled;
        this.tiptiCardBalance = tiptiCardBalance;
        this.isExclusive = isExclusive;
        this.qrCodeReferred = qrCodeReferred;
        this.primeInformation = primeInformation;
        this.defaultLanguage = defaultLanguage;
        this.totalOrders = totalOrders;
        this.isInsitutionalPayment = isInsitutionalPayment;
        this.userType = userType;
        this.registerDate = registerDate;
    }
}

const toClientProfileModel = (information: object, primeInformation?: PrimeInformationModel): ClientProfileModel => {
    return information
        ? new ClientProfileModel(
              information['id'],
              toCurrentSectorModel(information['current_sector']),
              toAddressModel(information['current_address']),
              information['phone'],
              information['user']['first_name'],
              information['genre'],
              information['document'],
              information['user']['id'],
              information['facebook_user_id'] ?? '',
              information['user']['last_name'],
              information['user']['email'],
              information['birth_date'],
              information['facebook_user_id']?.length
                  ? getFacebookImage(information['facebook_user_id'])
                  : information['profile_picture'],
              information['is_prime'] ?? false,
              information['is_prime_cancelled'] ?? false,
              +information['tipti_card_balance'],
              primeInformation?.isExclusive ?? false,
              information['qrcode_picture'],
              primeInformation,
              information['default_language'],
              +information['total_orders'],
              information['institutional_payment_enabled'],
              information['prime_name'] ?? 'REGULAR',
              information['user']['date_joined'],
          )
        : null;
};

export { ClientProfileModel, toClientProfileModel };
