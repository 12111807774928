<script lang="ts" setup>
import type { PropType } from 'vue';
import { useRouter } from 'vue-router';
import { useImage } from '@/composables/ImageComposable';
import type { PressReleases } from '@/models/external/PressReleases';
import { appRoutesMap } from '@/router/appRoutesMap';

const $router = useRouter();
const { displayDefaultImg } = useImage();

const props = defineProps({
    news: {
        type: Object as PropType<PressReleases>,
        required: true,
    },
});

const goToNews = () => {
    if (!props.news) return;
    $router.push({
        name: appRoutesMap.news.detail,
        params: {
            id: props.news.id,
            title: props.news.title,
        },
    });
};
</script>

<template>
    <article class="card-blog cursor-pointer" @click.stop="goToNews">
        <img class="card-blog__img" :alt="news.title" :src="news?.picture ?? ''" @error="displayDefaultImg" />
        <h2 class="card-blog__title text-line-2">{{ news.title }}</h2>
        <p class="card-blog__summary text-line-4" v-html="news.summary"></p>
        <time v-if="news.date_from" v-date="{ date: news.date_from, format: 'DD MMM YYYY' }" class="card-blog__date" />
    </article>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.card-blog {
    display: flex;
    flex-direction: column;
    margin: style.$margin-md;
    width: 19rem;
    gap: style.$margin-md;
    position: relative;

    &__img {
        height: 9rem;
        object-fit: cover;
        border-radius: 1rem;
        max-width: 18rem;
    }

    &__title {
        @include style.subtitle1;
        @include style.text-lines(2);
        color: style.$orange;
        font-size: 19px;
        font-weight: 600;
    }

    &__summary {
        @include style.subtitle1;
        @include style.text-lines(2);
        font-size: 14px;
        color: style.$dark-grey;
        white-space: normal;
        word-wrap: break-word;
        text-overflow: ellipsis;
    }

    &__date {
        @include style.subtitle1;
        font-size: 12px;
        color: style.$dark-grey;
        margin-top: auto;
    }
}
</style>
