<template>
    <div class="input-normal">
        <div class="field">
            <input
                :name="label"
                :id="label"
                type="date"
                :value="formatInputDate(modelValue)"
                @input="$emit('update:modelValue', $event.target.valueAsDate)"
            />
            <label class="input-label" :for="label"
                >{{ `${labelName}` }}<span class="input__required"> {{ `${requiredField}` }}</span></label
            >
        </div>
        <p class="input-normal__required" v-if="isEmptyAndRequired">* {{ $t("general['required-fields']") }}</p>
        <p class="input-normal__required" v-else-if="isInvalid">* {{ $t("general['invalid-fields']") }}</p>
    </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';

export default {
    name: 'dynamic-date',
    props: {
        labelName: {
            type: String,
        },
        label: {
            type: String,
        },
        maxlength: { type: [String, Number] },
        minLength: { type: [String, Number] },
        pattern: { type: String },
        modelValue: {
            type: String,
        },
        autocomplete: {
            type: [String, Boolean],
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        isEmptyAndRequired: {
            type: Boolean,
            default: false,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        requiredField(): string {
            return this.isRequired ? '*' : '';
        },
    },
    methods: {
        formatInputDate(date) {
            return date && dayjs(this.modelValue).format('YYYY-MM-DD');
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'dynamic-date';
</style>
