<template>
    <section class="sidebar sidebar__bar">
        <transition-group name="block">
            <div
                v-for="(option, index) in options"
                :key="index"
                class="sidebar-option cursor-pointer"
                :class="{
                    'bar-option--active': optionSelected === option.code,
                }"
                @click="$router.push({ name: option.path })"
            >
                <component
                    :is="option.component"
                    :class="{ 'bar-option__active-logo': optionSelected === option.code }"
                    :color="optionSelected === option.code ? '#FFF' : '#C7C7C7'"
                    :size="option.size"
                />
                <p class="sidebar-option__name">{{ option.title }}</p>
            </div>
        </transition-group>
    </section>
</template>

<script lang="ts">
import IconProfile from '@/components/icons/IconProfile.vue';
import IconTiptiCard from '@/components/icons/IconNewTiptiCard.vue';
import IconOrders from '@/components/icons/IconOrders.vue';
import IconBell from '@/components/icons/IconBell.vue';
import IconReferred from '@/components/icons/IconReferred.vue';
import IconConfig from '@/components/icons/IconConfig.vue';
import IconHelp from '@/components/icons/IconHelp.vue';
import IconSubscriptions from '@/components/icons/IconSubscription.vue';
import { useUser } from '@/composables/useUser';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRoutes } from '@/composables/useRoutes';

export default {
    name: 'SidebarProfile',
    components: {
        IconProfile,
        IconTiptiCard,
        IconOrders,
        IconBell,
        IconReferred,
        IconConfig,
        IconHelp,
        IconSubscriptions,
    },
    setup() {
        const { alphaCode } = useUser();

        return { alphaCode, ...useRoutes() };
    },
    data() {
        return {
            options: [
                {
                    title: this.$t("cta['my-account']"),
                    code: appRoutesMap.account.profile,
                    path: appRoutesMap.account.editProfile,
                    component: 'IconProfile',
                    size: 1.3,
                },
                {
                    title: 'Tipti Wallet',
                    code: appRoutesMap.Wallet,
                    path: appRoutesMap.Wallet,
                    component: 'IconTiptiCard',
                    size: 1,
                },
                {
                    title: this.$t("cta['orders']"),
                    code: appRoutesMap.OrdersPage,
                    path: appRoutesMap.OrdersPage,
                    component: 'IconOrders',
                    size: 1.0,
                },
                {
                    title: this.$t("cta['notifications']"),
                    code: appRoutesMap.account.notifications,
                    path: appRoutesMap.account.notifications,
                    component: 'IconBell',
                    size: 0.6,
                },
                {
                    title: this.$t("cta['referred']"),
                    code: appRoutesMap.account.referred,
                    path: appRoutesMap.account.referred,
                    component: 'IconReferred',
                    size: 1.3,
                },
                {
                    title: this.$t("cta['settings']"),
                    code: appRoutesMap.account.settings,
                    path: appRoutesMap.account.settings,
                    component: 'IconConfig',
                    size: 0.6,
                },
                {
                    title: this.$t("cta['help-and-support']"),
                    code: appRoutesMap.account.faqs,
                    path: appRoutesMap.account.faqs,
                    component: 'IconHelp',
                    size: 1.3,
                },
            ],
        };
    },
    computed: {
        optionSelected(): string {
            if (this.isScreenTiptiWallet) return appRoutesMap.Wallet;
            if (this.isScreenNotifications) return appRoutesMap.account.notifications;
            if (this.isScreenReferred) return appRoutesMap.account.referred;
            if (this.isScreenSettings) return appRoutesMap.account.settings;
            if (this.isScreenFaqs) return appRoutesMap.account.faqs;
            if (this.isScreenSubscription) return appRoutesMap.account.subscriptions;
            if (this.isScreenOrders) return appRoutesMap.OrdersPage;
            return appRoutesMap.account.profile;
        },
    },
    created() {
        if (this.alphaCode === 'EC') return;
        this.options.splice(3, 0, {
            title: this.$t('cta.subscriptions'),
            code: 'subscription',
            path: appRoutesMap.account.subscriptions,
            component: 'IconSubscriptions',
            size: 1.0,
        });
    },
};
</script>

<style lang="scss" scoped>
@import './sidebar.scss';
</style>
