<template>
    <p
        v-if="type === 'DISCOUNTED'"
        v-currency.sup.noSpaced="value"
        :class="{
            'product-price__value--header-price': headerPriceSmall,
            'product-price-discount': showDeferredOption,
            'product-price-discount__bold': !showDeferredOption,
        }"
    />
    <p
        v-if="type === 'REGULAR'"
        v-currency.sup.noSpaced="value"
        :class="{
            'product-price__value--header-price': headerPriceSmall,
            'product-price__value': !showDeferredOption,
            'product-price__value--regular': showDeferredOption,
        }"
    />
    <p
        v-if="type === 'OLD'"
        v-currency.noSpaced="value"
        class="product-price__value product-price__value--old"
        :class="{ 'product-price__value--header-price': headerPriceSmall }"
    />
    <p
        v-if="type === 'INLINE'"
        v-currency.noSpaced="value"
        class="product-price__value"
        :class="{ 'product-price__value--header-price': headerPriceSmall }"
    />
    <p
        v-if="type === 'SMALL'"
        v-currency.sup.noSpaced="value"
        class="product-price__value--small"
        :class="{ 'product-price__value--header-price': headerPriceSmall }"
    />
</template>
<script lang="ts" setup>
import type { PropType } from 'vue';
import { useProductTags } from '@/composables/useProductTags';

export type TypeValue = 'DISCOUNTED' | 'REGULAR' | 'OLD' | 'INLINE' | 'SMALL';
const props = defineProps({
    value: {
        type: [Number, String],
        required: true,
    },
    type: {
        type: String as PropType<TypeValue>,
        default: 'REGULAR',
    },
    headerPriceSmall: {
        type: Boolean,
        default: false,
    },
});

const { showDeferredOption } = useProductTags();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.product-price__value {
    @include headline1;
    font-weight: 800;
    width: fit-content;

    &--special {
        background-color: $error;
        color: $white;
        padding: 0.2rem;
        border-radius: $radius-lg;
    }

    &--old {
        @include headline6;
        width: fit-content;
        font-weight: 200;
        text-decoration: line-through;
    }

    &--small {
        @include body1;
    }

    &--header-price {
        font-size: 1.5rem;
    }

    &--regular {
        @include headline1;
        font-weight: 300;
        width: fit-content;
        font-size: 40px;
    }
}

.product-price-base {
    @include headline1;
    width: fit-content;
    background-color: $error;
    color: $white;
    padding: 0.2rem;
    border-radius: $radius-lg;
}

.product-price-discount {
    @extend .product-price-base;
    font-weight: 200;

    &__bold {
        @extend .product-price-base;
        font-weight: 800;
    }
}
</style>
