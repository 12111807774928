import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleteon_carousel = _resolveComponent("skeleteon-carousel")!
  const _component_card_recipe_ads = _resolveComponent("card-recipe-ads")!
  const _component_carousel_tipti = _resolveComponent("carousel-tipti")!

  return (!$props.recipes)
    ? (_openBlock(), _createBlock(_component_skeleteon_carousel, { key: 0 }))
    : ($props.recipes?.length)
      ? (_openBlock(), _createBlock(_component_carousel_tipti, {
          key: 1,
          isLastPage: $props.isLastPage,
          isLoading: $props.isLoading,
          length: $props.recipes.length,
          onReachedEnd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reached-end')))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.recipes, (recipe, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createVNode(_component_card_recipe_ads, {
                  cardSelected: $data.cardSelected,
                  isLoading: $data.isLoadingRecipe,
                  recipe: recipe,
                  onOnSelectedCard: ($event: any) => ($options.clickCard(recipe))
                }, null, 8, ["cardSelected", "isLoading", "recipe", "onOnSelectedCard"])
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["isLastPage", "isLoading", "length"]))
      : _createCommentVNode("", true)
}