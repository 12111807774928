<template>
    <base-icon :icon-name="$t('icon.language')" :width="width" :height="height" viewBox="0 0 42 41" :iconColor="color">
        <path
            d="M0.870678 13.0577C0.870678 15.647 1.67001 18.4829 3.04692 20.4569C3.48068 21.0788 2.41448 22.7662 2.61168 23.0684C2.79094 23.3432 4.22211 22.2395 4.78792 22.6332C6.7597 24.0051 10.4735 25.2447 13.0577 25.2447C19.7883 25.2447 25.2446 19.7884 25.2446 13.0577C25.2446 6.32702 19.7883 0.870728 13.0577 0.870728C6.32697 0.870728 0.870678 6.32702 0.870678 13.0577Z"
            fill="white"
        />
        <path
            d="M13.155 0C5.95516 0 0.0977337 5.85776 0.0977337 13.0576C0.0977337 15.5508 0.802997 17.9695 2.13905 20.0644L0.0239389 25.621C-0.0286273 25.7588 0.00709065 25.9141 0.114245 26.0152C0.183322 26.0806 0.273291 26.1149 0.364271 26.1149C0.414479 26.1149 0.46536 26.1048 0.513209 26.0833L5.68929 23.7667C7.88764 25.3039 10.4647 26.1149 13.155 26.1149C20.3553 26.1149 26.2127 20.2572 26.2127 13.0576C26.2127 5.85776 20.3556 0 13.155 0ZM13.155 24.1885C10.8024 24.1885 8.55112 23.4607 6.64526 22.0832C6.37703 21.8898 6.06063 21.7873 5.7304 21.7873C5.50868 21.7873 5.29404 21.8332 5.09253 21.9235L3.16477 22.7864L4.00515 20.5773C4.18408 20.1093 4.12612 19.5809 3.85082 19.1634C2.65663 17.3475 2.02516 15.2364 2.02516 13.0576C2.02516 10.0897 3.18398 7.29592 5.28898 5.19091C7.39365 3.0859 10.1877 1.92675 13.1554 1.92675C19.2932 1.92675 24.2863 6.9202 24.2863 13.0576C24.2863 19.1954 19.2928 24.1885 13.155 24.1885Z"
            fill="#606060"
        />
        <path
            d="M8.40488 13.529H11.9389V12.2654H8.40488V10.7204H12.255V9.43224H6.91921V16.7079H12.4326V15.3999H8.40488V13.529Z"
            fill="#606060"
        />
        <path
            d="M18.6432 13.0947C18.3635 12.8744 17.9443 12.6981 17.3849 12.5667L16.1082 12.2655C15.6152 12.1502 15.2934 12.0498 15.1414 11.9642C14.9056 11.8358 14.7873 11.6417 14.7873 11.3816C14.7873 11.0986 14.9069 10.8782 15.1465 10.7202C15.3861 10.5621 15.7065 10.4833 16.1082 10.4833C16.4687 10.4833 16.771 10.5443 17.0139 10.6659C17.3779 10.8502 17.574 11.1612 17.6009 11.599H19.062C19.035 10.8256 18.748 10.2359 18.1997 9.82956C17.6515 9.42318 16.9893 9.22 16.2133 9.22C15.284 9.22 14.5797 9.43565 14.1002 9.86663C13.6207 10.2976 13.3805 10.8391 13.3805 11.4904C13.3805 12.2045 13.6258 12.7295 14.1161 13.0651C14.4055 13.2659 14.9376 13.4516 15.7112 13.6227L16.4991 13.7956C16.9604 13.8943 17.2967 14.0079 17.5093 14.1363C17.7212 14.268 17.8277 14.4537 17.8277 14.694C17.8277 15.1054 17.6104 15.3868 17.1774 15.538C16.9489 15.6169 16.6514 15.6567 16.2851 15.6567C15.6738 15.6567 15.2422 15.507 14.9905 15.2075C14.8527 15.043 14.76 14.7944 14.7132 14.4621H13.2619C13.2619 15.2189 13.5358 15.8137 14.0847 16.2467C14.6336 16.679 15.3871 16.8957 16.3447 16.8957C17.2825 16.8957 18.0087 16.676 18.5225 16.2369C19.0364 15.7972 19.2932 15.2456 19.2932 14.5807C19.2935 13.9324 19.0772 13.4371 18.6432 13.0947Z"
            fill="#606060"
        />
        <path
            d="M40.0429 26.9854C40.0429 29.5747 39.2436 32.4107 37.8666 34.3847C37.4329 35.0065 38.4991 36.6939 38.3019 36.9962C38.1226 37.2709 36.6915 36.1672 36.1257 36.5609C34.1539 37.9329 30.4401 39.1724 27.8559 39.1724C21.1252 39.1724 15.6689 33.7161 15.6689 26.9854C15.6689 20.2548 21.1252 14.7985 27.8559 14.7985C34.5866 14.7985 40.0429 20.2548 40.0429 26.9854Z"
            fill="white"
        />
        <path
            d="M27.8562 13.9277C35.0561 13.9277 40.9135 19.7855 40.9135 26.9854C40.9135 29.4786 40.2082 31.8973 38.8722 33.9922L40.9873 39.5487C41.0399 39.6865 41.0041 39.8419 40.897 39.9429C40.8279 40.0083 40.7379 40.0427 40.647 40.0427C40.5968 40.0427 40.5459 40.0326 40.498 40.011L35.3219 37.6944C33.1236 39.2316 30.5465 40.0427 27.8562 40.0427C20.656 40.0427 14.7985 34.1849 14.7985 26.9854C14.7985 19.7855 20.6556 13.9277 27.8562 13.9277ZM27.8562 38.1163C30.2089 38.1163 32.4601 37.3884 34.366 36.0109C34.6342 35.8175 34.9506 35.7151 35.2808 35.7151C35.5025 35.7151 35.7172 35.7609 35.9187 35.8512L37.8465 36.7142L37.0061 34.505C36.8272 34.037 36.8851 33.5086 37.1604 33.0911C38.3546 31.2752 38.9861 29.1642 38.9861 26.9854C38.9861 24.0174 37.8273 21.2237 35.7222 19.1186C33.6176 17.0136 30.8235 15.8545 27.8559 15.8545C21.7181 15.8545 16.725 20.8479 16.725 26.9854C16.725 33.1232 21.7184 38.1163 27.8562 38.1163Z"
            fill="#606060"
        />
        <path
            d="M26.3765 25.1505H22.3981V26.7469H26.0501V28.0527H22.3981V29.9858H26.5601V31.3375H20.8628V23.8192H26.3765V25.1505Z"
            fill="#606060"
        />
        <path
            d="M27.7588 23.8192H29.4063L32.4003 29.0677V23.8192H33.8642V31.3375H32.2932L29.2226 25.9972V31.3375H27.7588V23.8192Z"
            fill="#606060"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-language',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C7C7C7',
        },
    },
    computed: {
        width(): number {
            return 42 * this.size;
        },
        height(): number {
            return 41 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
