import { GlobalSearchRetailerModel } from '@/models/search/GlobalSearchRetailerModel';
import { logger } from '@/utils/logger';

export default {
    async retailersGlobalSearch({ rootGetters }, query: string): Promise<Array<GlobalSearchRetailerModel>> {
        try {
            if (!query?.length) return;
            const url = `v2/retailers_search?query=${query}`;
            const { data } = await rootGetters['network/axios'].get(url);
            return data?.map((result) => new GlobalSearchRetailerModel(result)) ?? [];
        } catch (err) {
            logger('RETAILERS_GLOBAL_SEARCH', err);
        }
    },
};
