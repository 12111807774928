<template>
    <base-icon :icon-name="$t('icon.gallery')" :width="width" :height="height" viewBox="0 0 150 132" :iconColor="color">
        <path
            d="M75.9783 46.4414C67.4374 46.4414 60.6758 53.3809 60.6758 61.7439C60.6758 70.2848 67.6153 77.0464 75.9783 77.0464C84.5192 77.0464 91.2808 70.1069 91.2808 61.7439C91.2808 53.3809 84.5192 46.4414 75.9783 46.4414ZM75.9783 69.751C71.5299 69.751 67.9712 66.1923 67.9712 61.7439C67.9712 57.2955 71.5299 53.7368 75.9783 53.7368C80.4267 53.7368 83.9854 57.2955 83.9854 61.7439C83.9854 66.1923 80.4267 69.751 75.9783 69.751Z"
            fill="white"
        />
        <path
            d="M137.011 11.3882L37.5444 0.178233C33.6298 -0.355575 29.7152 0.71204 26.8682 3.20314C23.8433 5.51631 22.064 8.89709 21.5302 12.6337L19.7508 27.5804H14.0569C6.04974 27.5804 -7.97403e-05 34.6978 -7.97403e-05 42.7049V117.26C-0.178016 124.911 5.87181 131.317 13.5231 131.495C13.701 131.495 13.8789 131.495 14.0569 131.495H114.057C122.064 131.495 129.359 125.267 129.359 117.26V114.413C131.85 113.879 134.164 112.99 136.299 111.566C139.324 109.075 141.103 105.516 141.637 101.78L150 27.7583C150.89 19.5733 145.018 12.4558 137.011 11.3882ZM122.064 73.3099L82.3843 96.4416C79.0035 98.3989 74.733 98.043 71.7081 95.5519L51.6013 77.9362C45.9074 72.954 37.5444 72.7761 31.3166 77.2245L7.29529 94.6622V42.7049C7.29529 38.6124 9.96433 34.8757 14.0569 34.8757H114.057C118.327 35.0537 121.886 38.4345 122.064 42.7049V73.3099ZM142.705 26.6907L134.164 100.712C134.164 102.669 133.274 104.449 131.85 105.694C131.139 106.406 129.537 106.762 129.537 107.118V42.7049C129.181 34.3419 122.598 27.7583 114.235 27.5804H27.0462L28.6476 13.3455C29.0035 11.5661 29.8932 9.78677 31.3166 8.54122C32.9181 7.47361 34.8754 6.9398 36.8327 7.11773L136.121 18.6836C140.213 19.0394 143.06 22.5982 142.705 26.6907Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-gallery',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 20 * this.size;
        },
        height(): number {
            return 20 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
