<template>
    <iframe
        :scrolling="getFileSrcSettings.scrolling"
        :src="getFileSrcSettings.url_file"
        :title="title"
        frameborder="0"
        height="100%"
        width="100%"
    ></iframe>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

defineOptions({
    name: 'PdfFrame',
});

const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        default: '',
    },
});

const isPdfExtension = computed(() => /pdf$/i.test(props?.src));

const getFileSrcSettings = computed(() => {
    if (!props.src) return { url_file: '', scrolling: 'no' };
    if (isPdfExtension.value)
        return {
            url_file: `https://docs.google.com/viewer?url=${props.src}&embedded=true`,
            scrolling: 'no',
        };

    return {
        url_file: props.src,
        scrolling: 'yes',
    };
});
</script>