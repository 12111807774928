<template>
    <base-icon :icon-name="$t('icon.logout')" :width="width" :height="height" viewBox="0 0 20 20" :iconColor="color">
        <g clip-path="url(#clip0)">
            <path
                d="M12.4999 10.8332C12.0391 10.8332 11.6666 11.2065 11.6666 11.6664V14.9998C11.6666 15.459 11.2932 15.8331 10.8332 15.8331H8.33319V3.33325C8.33319 2.62159 7.87986 1.98576 7.19826 1.7491L6.95153 1.66655H10.8332C11.2932 1.66655 11.6666 2.04069 11.6666 2.49997V4.99995C11.6666 5.45984 12.0391 5.83322 12.4999 5.83322C12.9607 5.83322 13.3331 5.45984 13.3331 4.99995V2.49997C13.3331 1.12166 12.2115 0 10.8332 0H1.87498C1.84324 0 1.81669 0.0141905 1.78587 0.0183103C1.74574 0.0149535 1.70744 0 1.6667 0C0.74752 0 0 0.747368 0 1.66655V16.6664C0 17.3781 0.453334 18.0139 1.13494 18.2505L6.14999 19.9223C6.31997 19.9748 6.48904 19.9998 6.66665 19.9998C7.58583 19.9998 8.33319 19.2523 8.33319 18.3331V17.4998H10.8332C12.2115 17.4998 13.3331 16.3782 13.3331 14.9998V11.6664C13.3331 11.2065 12.9607 10.8332 12.4999 10.8332Z"
                :fill="color"
            />
            <path
                d="M19.7557 7.74561L16.4223 4.41236C16.1842 4.17402 15.8257 4.1023 15.5141 4.23139C15.2033 4.36063 14.9999 4.66474 14.9999 5.0015V7.50147H11.6667C11.2066 7.50147 10.8333 7.8747 10.8333 8.33474C10.8333 8.79479 11.2066 9.16802 11.6667 9.16802H14.9999V11.668C14.9999 12.0047 15.2033 12.3089 15.5141 12.4381C15.8257 12.5672 16.1842 12.4955 16.4223 12.2573L19.7557 8.92388C20.0815 8.59811 20.0815 8.07138 19.7557 7.74561Z"
                :fill="color"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-logout',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 20 * this.size;
        },
        height(): number {
            return 20 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
