import { RemoteLocaleDto, RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';
import { getProductBlock } from '@/utils/ProductBuilderUtil';

interface AttributeInProductInterface {
    id: number;
    name: RemoteLocaleDto;
    type_of_attribute_name: RemoteLocaleDto;
    use_image: boolean;
    show_in_app: boolean;
}

export class AttributeInProductModel implements AttributeInProductInterface {
    id: number;
    name: RemoteLocaleModel;
    type_of_attribute_name: RemoteLocaleModel;
    use_image: boolean;
    show_in_app: boolean;

    constructor(data: AttributeInProductInterface) {
        this.id = data.id;
        this.name = RemoteLocaleModel.fromJson(data.name);
        this.type_of_attribute_name = RemoteLocaleModel.fromJson(data.type_of_attribute_name);
        this.use_image = data.use_image ?? false;
        this.show_in_app = data.show_in_app ?? false;
    }

    static fromJson(data: object): AttributeInProductModel {
        if (!data) return null;
        return new AttributeInProductModel(data as AttributeInProductInterface);
    }

    static builderList(data: object): AttributeInProductModel[] {
        if (!data) return [];
        const block = getProductBlock({
            data: data,
            block: 'attributes',
        });
        if (!block) return [];
        return (block as []).map(AttributeInProductModel.fromJson).filter(item => !!item);
    }
}
