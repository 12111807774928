<template>
    <section class="referred">
        <referals :referals="referals" />
        <sharable-information :referals="referals" />
    </section>
</template>

<script lang="ts">
import { toReferalModel } from '@/models/referred/ReferalModel';
import Referals from './components/referals/Referals.vue';
import SharableInformation from './components/sharableInformation/SharableInformation.vue';
import { logger } from '@/utils/logger';

export default {
    name: 'referred',
    components: {
        Referals,
        SharableInformation,
    },
    data() {
        return {
            referals: null,
        };
    },
    mounted() {
        this.fetchReferals();
    },
    methods: {
        async fetchReferals(): Promise<void> {
            try {
                const url = 'v2/client_profile/tipti_card/referred/';
                const response = await this.$store.getters['network/axios'].post(url);
                this.referals = toReferalModel(response.data);
            } catch (err) {
                logger('FETCH_REFERALS', err);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import './referred.scss';
</style>
