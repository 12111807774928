<template>
    <h2 class="title-text-promotions">{{ $t('txt.ongoing-offers') }}</h2>
    <CarouselTipti
        class="banners-promotions"
        :is-loading="false"
        :items-shown="bannerPromotion?.length"
        autoplay
        is-last-page
        show-bullet-points
    >
        <div v-for="(promotion, index) in bannerPromotion" :key="index" class="banners-promotions">
            <img
                class="banners-promotions cursor-pointer"
                :alt="promotion.imagen"
                :src="promotion.imagen"
                loading="lazy"
                @click="openRecipeDeepLink(promotion.deeplink)"
            />
            <section class="banners-promotions__content">
                <h2 class="title-promotions">{{ promotion.title.toUpperCase() }}</h2>
                <p class="sub-title-promotions">
                    {{ promotion.text }}
                </p>
                <BtnSolid class="banners-promotions__button" :txt="$t('txt.buy-now')" @click="handleLogin" />
                <BaseLink
                    v-if="termsAndConditionsFile(promotion)"
                    :txt-link="$t('txt.resume__purchase-instructions-see-terms')"
                    @click="showModalTC(promotion)"
                />
            </section>
        </div>
    </CarouselTipti>
    <Auth v-if="showLogin" @on-close="showLogin = false" />

    <BaseModal
        v-if="showPdfFrame"
        :auto-size="false"
        :has-background="true"
        :is-full-modal="true"
        :title="$t('txt.setting__terms_conditions')"
        header-text-color="#ff9012"
        @on-close="
            showPdfFrame = false;
            srcTermsConditions = null;
        "
    >
        <PdfFrame class="pdf-frame" :src="srcTermsConditions" />
    </BaseModal>
</template>

<script lang="ts" setup>
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';
import { logger } from '@/utils/logger';
import Auth from '@/views/auth/Auth.vue';
import BaseLink from '@/components/links/BaseLink.vue';
import { useStore } from 'vuex';
import { ref } from 'vue';
import { useUser } from '@/composables/useUser';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { useRouter } from 'vue-router';
import PdfFrame from '@/modules/core/components/pdf/PdfFrame.vue';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import { useTranslate } from '@/composables/UseTranslate';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'BannersPromotionsInTipti',
});

const $state = useStore();
const $router = useRouter();
const { isAuth, profile } = useUser();
const { exec } = useTranslate();
const bannerPromotion = ref([]);
const showLogin = ref<boolean>(false);
const showPdfFrame = ref<boolean>(false);
const srcTermsConditions = ref<string>('');

const termsAndConditionsFile = (promotion) => {
    return exec({
        ES: promotion?.terms_conditions_es,
        EN: promotion?.terms_conditions_en,
    });
};

const getBannerHome = async (): Promise<void> => {
    try {
        bannerPromotion.value = await $state.dispatch('external/getBannerspromotionsInTipti');
    } catch (err) {
        logger('GET_LIST_BANNER_PROMOTION', err);
    }
};

getBannerHome();

const openRecipeDeepLink = (deeplink: string): void => {
    if (isAuth.value) return;
    window.open(deeplink, '_self');
};

const handleLogin = () => {
    if (isAuth.value) {
        $router.push({ name: appRoutesMap.home });
        return;
    }
    $state.dispatch('segment/openSignIn');
    showLogin.value = true;
};

const showModalTC = (promotion) => {
    srcTermsConditions.value = termsAndConditionsFile(promotion);
    if (!srcTermsConditions.value) return;
    showPdfFrame.value = true;
};
</script>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as color;
@use '@/assets/scss/type-system.scss' as font;
@use '@/assets/scss/margin.scss' as mg;
@use '@/assets/scss/media-query.scss' as mq;
@use '@/assets/scss/padding.scss' as pd;

.title-text-promotions {
    @include font.headline4;
    color: color.$orange;
    font-weight: 600;
    font-size: 2rem;
    margin: mg.$margin-screen;
}

.banners-promotions {
    border-radius: 1rem;

    &__content {
        display: flex;
        flex-direction: column;
        gap: mg.$margin-md;
    }

    &__button {
        margin-top: 2rem;
        width: 12rem;
        @include mq.phone {
            width: unset;
        }
    }
}

.title-promotions {
    @include font.headline4;
    color: color.$orange;
    font-weight: 600;
    padding-top: pd.$padding-md;
}

.sub-title-promotions {
    @include font.body2;
}

.link-action {
    @include font.body1;
    cursor: pointer;
    color: color.$orange;
    text-decoration: underline;
}

.pdf-frame {
    height: 75vh;
    @include mq.tablet {
        height: 90vh;
    }
}
</style>
