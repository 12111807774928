import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f8e9fec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "validate-error" }
const _hoisted_2 = { class: "validate-error__mobile-title" }
const _hoisted_3 = {
  key: 0,
  class: "validate-error__general"
}
const _hoisted_4 = {
  key: 1,
  class: "validate-card__error"
}
const _hoisted_5 = { class: "validate-error__txt--highlight" }
const _hoisted_6 = { class: "validate-error__txt" }
const _hoisted_7 = { class: "validate-error__txt--highlight" }
const _hoisted_8 = {
  key: 2,
  class: "validate-card__error"
}
const _hoisted_9 = { class: "validate-error__txt--highlight" }
const _hoisted_10 = { class: "validate-error__txt--highlight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_circular_close = _resolveComponent("icon-circular-close")!
  const _component_alert_manager = _resolveComponent("alert-manager")!

  return (_openBlock(), _createBlock(_component_alert_manager, {
    "is-loading": false,
    "is-disabled": false,
    "show-buttons": true,
    "only-confirmation-btn": true,
    "show-bottom-sheet-title": false,
    "has-padding-btn": true,
    title: $options.title,
    "btn-x-padding": 1,
    "btn-y-padding": 1,
    "accept-button-text": $options.btnTxt,
    onOnAccept: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-accept'))),
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close'))),
    onOnDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon_circular_close, {
          iconName: _ctx.$t('icon.error'),
          size: 1,
          color: "#F03F51"
        }, null, 8, ["iconName"]),
        _createElementVNode("h2", _hoisted_2, _toDisplayString($options.title), 1),
        ($props.isRequestError)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.$t('txt.credit-card__verification-error')), 1))
          : ($props.isLastAttempt)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('txt.credit-card__last-attempt')), 1),
                _createElementVNode("p", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.$t('txt.credit-card__additionally')) + " ", 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('general.tipti_team')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('txt.credit-card__contact-you')), 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('general.value_incorrect')), 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('general.attempts_remaining')) + " " + _toDisplayString($props.attempts), 1)
              ]))
      ])
    ]),
    _: 1
  }, 8, ["title", "accept-button-text"]))
}