import { CityModel, toCityModel } from '../location/CityModel';

class CurrentSectorModel {
    id: number;
    name: string;
    position: string;
    city: CityModel;

    constructor(id: number, name: string, position: string, city: CityModel) {
        this.id = id;
        this.name = name;
        this.position = position;
        this.city = city;
    }
}
const toCurrentSectorModel = (data: object): CurrentSectorModel => {
    return data
        ? new CurrentSectorModel(data['id'], data['name'] ?? '', data['position'] ?? '', toCityModel(data['city']))
        : null;
};

export { CurrentSectorModel, toCurrentSectorModel };
