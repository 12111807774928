<script lang="ts" setup>
import type { PropType } from 'vue';
import type { BrandArtwork } from './brandArtwork';
import { useI18n } from 'vue-i18n';
import { logger } from '@/utils/logger';
import { useStore } from 'vuex';
import axios from 'axios';

const typeFiles = {
    SVG: 'svg',
    PNG: 'png',
};

const { t: $t } = useI18n();
const $store = useStore();

const props = defineProps({
    itemBrand: {
        type: Object as PropType<BrandArtwork>,
        required: true,
    },
});

const downloadImg = async (typeFile: string) => {
    try {
        const response = await axios.get(
            typeFile === typeFiles.SVG ? props.itemBrand.linkSvg : props.itemBrand.linkPng,
            {
                responseType: 'blob',
            },
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `logotipo-tipti.${typeFile}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
        logger('DOWNLOAD-IMG', err);
    }
};
</script>

<template>
    <div class="brand-artwork">
        <i class="brand-artwork__icon">
            <component :is="itemBrand.icon" />
        </i>
        <h4 class="brand-artwork__title">{{ itemBrand.title }}</h4>
        <p class="brand-artwork__description">{{ itemBrand.description }}</p>

        <button class="brand-artwork__button cursor-pointer" @click="downloadImg(typeFiles.SVG)">
            <span class="brand-artwork__button-text"> {{ $t("general['download-svg']") }} </span>
        </button>

        <button class="brand-artwork__button cursor-pointer" @click="downloadImg(typeFiles.PNG)">
            <span class="brand-artwork__button-text"> {{ $t("txt['news__download-png']") }} </span>
        </button>
    </div>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.brand-artwork {
    box-shadow: 0 1px 4px 0 style.$dark-black;
    display: flex;
    flex-direction: column;
    gap: style.$margin-md;
    padding: style.$margin-md + style.$margin-md style.$margin-md;
    width: 25rem;
    border-radius: 16px;
    position: relative;

    &__icon {
        align-self: center;
    }

    &__button {
        width: 12rem;
        background-color: style.$orange;
        color: style.$white;
        border: none;
        border-radius: 5px;
        padding: 0.5rem 1.5rem;
    }

    &__button-text {
        @include style.body1;
        color: style.$white;
        font-size: 16px;
    }

    &__title {
        @include style.body1;
        color: style.$orange;
        font-weight: 600;
    }

    &__description {
        @include style.body1;
        font-size: 16px;
        margin-bottom: auto;
    }
}
</style>
