
import { PropType } from 'vue';

enum Colors {
    orange = '#ff9012',
    magenta = '#f03f51',
    text = '#707070',
    border = '#c7c7c7',
}

type ColorAvailable = keyof typeof Colors;
const __default__ = {
    name: 'CreditCardIdentifier',
    props: {
        name: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        number: {
            type: String,
            default: '',
        },
        dotColor: {
            type: String as PropType<ColorAvailable>,
            default: 'border',
        },
        textColor: {
            type: String as PropType<ColorAvailable>,
            default: 'text',
        },
    },
    computed: {
        localDotColor(): Colors {
            return Colors[this.dotColor] ?? Colors.border;
        },
        localTextColor(): Colors {
            return Colors[this.textColor] ?? Colors.text;
        },
        numberInformation(): string {
            return `${this.number}`.padStart(16, 'x');
        },
    },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0b7f581c": (_ctx.localTextColor),
  "37be5b44": (_ctx.localDotColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__