import { toBenefitsAndPromotionsModel } from '@/models/shareData/BenefitsAndPromotionsModel';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';

export default {
    async getRetailerPromotionsAndBenefits({ rootGetters, commit }): Promise<void> {
        const retailer: RetailerModel = rootGetters['retailers/retailerSelected'];
        if (!retailer.franchise?.id) return;
        const url = `v2/customer/franchises/${retailer.franchise.id}/share-information`;
        const { data } = await rootGetters['network/axios'].get(url);
        const benefitsAndPromotions = toBenefitsAndPromotionsModel(data);
        commit('benefitsAndPromotions', benefitsAndPromotions);
    },
    async sendPermissionToShareData(
        { rootGetters, commit },
        payload: {
            approved: boolean;
            retailerId: number;
        },
    ): Promise<void> {
        const url = `v2/customer/permission/${payload.retailerId}`;
        const body = {
            status: payload.approved ? 'APPROVED' : 'DENIED',
        };
        await rootGetters['network/axios'].post(url, body);
    },
};
