import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_modal = _resolveComponent("loading-modal")!
  const _component_success_modal = _resolveComponent("success-modal")!
  const _component_error_modal = _resolveComponent("error-modal")!

  return ($props.state === 'LOADING' || $props.isLoading)
    ? (_openBlock(), _createBlock(_component_loading_modal, {
        key: 0,
        body: $props.body,
        "dont-close": $props.dontClose,
        "has-header": $props.hasHeader,
        title: $props.title
      }, null, 8, ["body", "dont-close", "has-header", "title"]))
    : ($props.state === 'SUCCESS' || $props.isSuccess)
      ? (_openBlock(), _createBlock(_component_success_modal, {
          key: 1,
          body: $props.body,
          "cta-text": $props.ctaText,
          "has-close": $props.hasClose,
          "has-header": $props.hasHeader,
          "show-action": $props.showAction,
          title: $props.title,
          onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close'))),
          onOnAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-action')))
        }, null, 8, ["body", "cta-text", "has-close", "has-header", "show-action", "title"]))
      : ($props.state === 'ERROR' || $props.isFailure)
        ? (_openBlock(), _createBlock(_component_error_modal, {
            key: 2,
            body: $props.body,
            "cta-text": $props.ctaText,
            "has-close": $props.hasClose,
            "has-header": $props.hasHeader,
            "link-text": $props.linkText,
            "show-action": $props.showAction,
            "show-oops": $props.showOops,
            title: $props.title,
            onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close'))),
            onOnAction: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-action'))),
            onOnTextAction: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-text-action')))
          }, null, 8, ["body", "cta-text", "has-close", "has-header", "link-text", "show-action", "show-oops", "title"]))
        : _createCommentVNode("", true)
}