<template>
    <div class="rating" :style="style">
        <div
            v-for="star in maxStars"
            :key="star"
            class="star"
            :class="{ 'cursor-pointer': showCursor }"
            @click="isDissable ? '' : rate(star)"
        >
            <icon-star-rate
                :color="star <= stars ? colorSelect : colorNotSelect"
                :size="size"
                :stroke="star <= stars ? strokeSelect : stroke"
            />
        </div>
    </div>
</template>
<script lang="ts">
import IconStarRate from '../icons/IconStarRate.vue';

export default {
    name: 'Rating',
    components: { IconStarRate },
    props: {
        grade: {
            type: Number,
            default: 5,
        },
        maxStars: {
            type: Number,
            default: 5,
        },
        colorSelect: {
            type: String,
            default: '#F7D93E',
        },
        colorNotSelect: {
            type: String,
            default: '#FFF',
        },
        stroke: {
            type: String,
            default: '#606060',
        },
        strokeSelect: {
            type: String,
            default: '#F7D93E',
        },
        size: {
            type: Number,
            default: 1,
        },
        isDissable: {
            type: Boolean,
            default: false,
        },
        showCursor: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['stars'],
    data() {
        return {
            stars: this.grade,
        };
    },
    computed: {
        style() {
            return {
                gap: `${this.size / 2}rem`,
                padding: `${this.size / 2}rem`,
            };
        },
    },
    watch: {
        grade: function (newVal) {
            this.rate(newVal);
        },
    },
    methods: {
        rate(star: number): void {
            this.stars = star;
            this.$emit('stars', this.stars);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.rating {
    display: grid;
    gap: $margin-sm;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    width: fit-content;
}

.star {
    display: inline-block;
    transition: all 0.2s ease-in-out;

    svg {
        display: block;
    }
}
</style>
