<template>
    <TiptiInputBase
        v-bind="{
            ...$props,
            errors: localErrors,
        }"
    >
        <input
            :id="label"
            ref="inputNumber"
            v-model.trim="inputValue"
            :aria-label="label"
            :aria-required="isRequired"
            :autocomplete="autocomplete"
            :maxlength="maxlength"
            :minlength="minlength"
            :name="label"
            :placeholder="placeholder"
            :required="isRequired"
            :type="type !== 'phone' ? type : 'text'"
            inputmode="numeric"
            @blur="validateOnBlur"
            @keydown="onInput"
        />
    </TiptiInputBase>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import TiptiInputBase from '@/components/inputs/primary/normal/TiptiInputBase.vue';
import { useInput } from '@/components/inputs/primary/normal/useInput';

const inputValue = defineModel<string | number>({
    required: true,
});
const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    type: {
        type: String as PropType<'text' | 'number' | 'phone'>,
        default: 'text',
    },
    autocomplete: {
        type: String,
        default: undefined,
    },
    placeholder: {
        type: String,
        default: '',
    },
    isInvalid: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    errors: {
        type: [String, Array<string>],
        default: '',
    },
    maxlength: {
        type: Number,
        default: undefined,
    },
    minlength: {
        type: Number,
        default: undefined,
    },
    max: {
        type: Number,
        default: undefined,
    },
    min: {
        type: Number,
        default: undefined,
    },
    pattern: {
        type: String,
        default: undefined,
    },
    hasFocus: {
        type: Boolean,
        default: false,
    },
});

const $emits = defineEmits<{
    (e: 'blur'): void;
    (e: 'input', value: string): void;
}>();

const { validateOnBlur, localErrors, onInput } = useInput<string | number>(props, $emits, inputValue);
</script>
