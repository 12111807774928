import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a887b6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payment-methods" }
const _hoisted_2 = { class: "title-otp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_payphone = _resolveComponent("icon-payphone")!
  const _component_icon_cash = _resolveComponent("icon-cash")!
  const _component_icon_wire_transfer = _resolveComponent("icon-wire-transfer")!
  const _component_icon_institutional = _resolveComponent("icon-institutional")!
  const _component_icon_online_bank_services = _resolveComponent("icon-online-bank-services")!
  const _component_icon_nequi = _resolveComponent("icon-nequi")!
  const _component_icon_yappy = _resolveComponent("icon-yappy")!
  const _component_icon_tipti_card = _resolveComponent("icon-tipti-card")!
  const _component_icon_credit_card = _resolveComponent("icon-credit-card")!
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!
  const _component_snack_bar = _resolveComponent("snack-bar")!
  const _component_general_alert = _resolveComponent("general-alert")!
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.payments, (option) => {
      return (_openBlock(), _createBlock(_component_base_card_flat, {
        key: option.code,
        class: _normalizeClass(["payment-method cursor-pointer", { 'payment-method__selected': $options.isTileSelected(option) }]),
        "border-color": $options.isTileSelected(option) ? '#FF9012' : '#C7C7C7',
        onClick: ($event: any) => ($options.isSetMixedPayment ? $options.onShowRestartPaymentAlert(option) : $options.onOptionClick(option))
      }, {
        default: _withCtx(() => [
          (option.code === 'payphone')
            ? (_openBlock(), _createBlock(_component_icon_payphone, {
                key: 0,
                color: $options.getTileColor(option.code),
                size: 1.1
              }, null, 8, ["color"]))
            : (option.code === 'cash')
              ? (_openBlock(), _createBlock(_component_icon_cash, {
                  key: 1,
                  color: $options.getTileColor(option.code),
                  size: 0.8
                }, null, 8, ["color"]))
              : (option.code === 'wire_transfer')
                ? (_openBlock(), _createBlock(_component_icon_wire_transfer, {
                    key: 2,
                    color: $options.getTileColor(option.code),
                    size: 0.6
                  }, null, 8, ["color"]))
                : (option.code === 'institutional')
                  ? (_openBlock(), _createBlock(_component_icon_institutional, {
                      key: 3,
                      color: $options.getTileColor(option.code),
                      size: 0.75
                    }, null, 8, ["color"]))
                  : (option.code === 'online-bank-services')
                    ? (_openBlock(), _createBlock(_component_icon_online_bank_services, {
                        key: 4,
                        color: $options.getTileColor(option.code),
                        size: 0.75
                      }, null, 8, ["color"]))
                    : (option.code === 'nequi')
                      ? (_openBlock(), _createBlock(_component_icon_nequi, {
                          key: 5,
                          color: $options.getTileColor(option.code),
                          size: 1.1
                        }, null, 8, ["color"]))
                      : (option.code === 'yappy')
                        ? (_openBlock(), _createBlock(_component_icon_yappy, {
                            key: 6,
                            color: $options.getTileColor(option.code),
                            size: 0.7
                          }, null, 8, ["color"]))
                        : (option.code === 'tipti_card')
                          ? (_openBlock(), _createBlock(_component_icon_tipti_card, {
                              key: 7,
                              color: option.code === $data.paymentSelected || _ctx.isMixPaymentTiptiCard ? '#FF9012' : '#606060',
                              size: 1.1
                            }, null, 8, ["color"]))
                          : (_openBlock(), _createBlock(_component_icon_credit_card, {
                              key: 8,
                              color: $options.getTileColor(option.code),
                              size: 1
                            }, null, 8, ["color"])),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", {
              class: _normalizeClass(["payment__label", {
                        'payment__label--orange': $options.isTileSelected(option),
                        'payment__label--disable': $options.showDisableColor(option.code),
                    }])
            }, _toDisplayString(option.title), 3),
            (option.code === 'tipti_card')
              ? _withDirectives((_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: _normalizeClass(["tipticard-amount", {
                        'tipticard-amount--orange': $options.isTileSelected(option),
                    }])
                }, null, 2)), [
                  [_directive_currency, $setup.availableAmount]
                ])
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_icon_arrow, {
            class: "content-icon-arrow",
            color: $options.showDisableColor(option.code) ? '#C1C1C1' : $options.isTileSelected(option) ? '#FF9012' : '#606060',
            "icon-name": _ctx.$t('icon.forward'),
            size: 0.75
          }, null, 8, ["color", "icon-name"])
        ]),
        _: 2
      }, 1032, ["class", "border-color", "onClick"]))
    }), 128)),
    _createVNode(_component_snack_bar, {
      body: $data.alertMessage,
      "is-active": $data.showAlert,
      title: $data.alertTitle,
      "is-failure": "",
      onOnSnackbarClose: _cache[0] || (_cache[0] = ($event: any) => ($data.showAlert = false))
    }, null, 8, ["body", "is-active", "title"]),
    ($data.showRestartPaymentsAlert)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 0,
          message: _ctx.$t('txt.payment-methods__change-payment-description'),
          title: _ctx.$t('txt.payment-methods__change-payment'),
          "accent-header": "",
          "show-close-icon": "",
          onOnDecline: _cache[1] || (_cache[1] = ($event: any) => ($data.showRestartPaymentsAlert = false)),
          onOnAccept: $options.onAcceptRestartPayment,
          onOnClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showRestartPaymentsAlert = false))
        }, null, 8, ["message", "title", "onOnAccept"]))
      : _createCommentVNode("", true)
  ]))
}