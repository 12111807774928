<template>
    <section class="benefits">
        <div>
            <section-header :has-back="true" :title="programSelected.name" :with-borders="false" @on-back="onBack" />
            <header-program />
            <div id="benefits" class="benefits__container">
                <div class="subscription">
                    <div class="beneficiary">
                        <h4 class="beneficiary__label">
                            {{ $t('general.name') }}
                        </h4>
                        <p class="subscription__identifier">
                            {{ programSelected.subscription?.subscriberName }}
                        </p>
                    </div>
                    <h6 class="subscription__identifier">
                        {{ programSelected.subscription?.subscriberIdentifier }}
                    </h6>
                </div>

                <base-btn
                    class="btn-see-card"
                    :outline="true"
                    bg-color="#fff"
                    border-color="#ff9012"
                    border-radius="5px"
                    @click="onShowCard"
                >
                    <icon-card :size="1.2" color="#ff9012" />
                    <p class="btn-see-card__txt">
                        {{ $t('cta.show_card') }}
                    </p>
                </base-btn>
                <h4 class="label-section">
                    {{ $t('general.your_benefits') }}
                </h4>
                <section class="benefits__list">
                    <div v-for="benefit in benefits" :key="benefit" class="benefit">
                        <img v-if="benefit?.icon" class="benefit__image" :src="benefit.icon" alt="" />
                        <div v-else class="benefit__image" />
                        <p class="benefit__description">{{ benefit?.description }}</p>
                    </div>
                </section>
                <h4
                    v-if="programSelected.benefits?.length > 3"
                    class="label-section label-section--centered cursor-pointer"
                    @click="minBenefits = !minBenefits"
                >
                    {{ handlerShowBenefits }}
                </h4>
                <h3 v-if="canShowHistory" class="label-section">
                    {{
                        $t('txt.redemption_history', {
                            program: programSelected.name,
                        })
                    }}
                </h3>

                <div v-if="canShowHistory" class="benefits__history">
                    <div v-if="programSelected.isForExchange" class="actions_container">
                        <div class="action__reload-balance">
                            <div class="points">
                                <p class="points__label">
                                    {{ $t('txt.available_points') }}
                                </p>
                                <p class="points__amount">{{ availableAmount }}</p>
                            </div>
                            <base-loading v-if="isLoadingBalance" :is-margin-auto="false" heigth="1rem" width="1rem" />
                            <icon-circular-arrow
                                v-else
                                class="cursor-pointer"
                                :size="1"
                                color="#ff9012"
                                @click="reloadBalance"
                            />
                        </div>
                        <base-btn
                            class="btn-transfer"
                            bg-color="#ff9012"
                            border-color="#ff9012"
                            border-radius="5px"
                            @click="canTransfer"
                        >
                            <p class="transfer__text">
                                {{ $t('txt.transfer_to_tipti') }}
                            </p>
                        </base-btn>
                    </div>
                    <div v-else />
                    <div class="transaction-container">
                        <div class="history__header">
                            <p class="history-text">{{ $t('general.date') }}</p>
                            <p class="history-text">{{ $t('txt.redeemed_points') }}</p>
                            <p class="history-text">{{ $t('general.accredited') }}</p>
                        </div>
                        <div v-for="transaction in transactions" :key="transaction?.id">
                            <div v-if="transaction['transaction_type'] === 'DEBIT'" class="history__detail">
                                <p class="history-text history-text--detail">
                                    {{ transactionDate(transaction?.date) }}
                                </p>
                                <p class="history-text history-text--detail">
                                    {{ getPoints(transaction?.taken_reward) }}
                                </p>
                                <p class="history-text history-text--detail">
                                    {{ getAccreditation(transaction?.amount_credited_to_tipticard) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <scrollable
                :options="{
                    root: $el,
                    rootMargin: '24px',
                }"
                @intersect="intersected"
            />
        </div>
        <section-footer class="benefits__footer" :with-shadow="false" vertical-padding="0">
            <btn-text
                :text="$t('general.un_link') + ' ' + $t('general.affiliation').toLowerCase()"
                @click="currentModal = 'DEACTIVATE'"
            />
        </section-footer>
    </section>
    <modal-manager
        v-if="currentModal === 'DEACTIVATE'"
        :accept-button-text="$t('general.un_link')"
        :has-close="true"
        :has-header="true"
        :is-disabled="false"
        :is-loading="false"
        :only-confirmation-btn="true"
        :show-buttons="true"
        :title="$t('general.un_link')"
        @on-close="closeDeactivateModal"
        @on-dismiss="closeDeactivateModal"
        @on-accept="deactivateFlow"
    >
        <template #content>
            <div class="deactivate">
                <h5 class="deactivate__text">
                    {{ pageLabel.description }}
                </h5>
            </div>
        </template>
    </modal-manager>
    <converter-to-transfer
        v-if="currentModal === 'TRANSFER'"
        :balance="balance.current_balance"
        :equivalence="programSelected?.exchangeEquivalence"
        :title="$t('txt.transfer_balance')"
        @converter="initTransfer"
        @on-close="closeTransferModal"
    >
        <template #header>
            <h5 class="converter__text--orange">{{ $t('general.converter') }}</h5>
            <div class="transfer__content">
                <h5 class="converter__text">
                    {{ programSelected.name }}
                </h5>
                <div></div>
                <h5 class="converter__text">{{ $t('general.tipti_card') }}</h5>
            </div>
        </template>
        <template #error>
            <span class="converter__text converter__text--error">
                {{
                    $t('txt.available_points_error', {
                        amount: balance.current_balance,
                    })
                }}
            </span>
        </template>
    </converter-to-transfer>
    <modal-loading
        v-if="currentModal === 'LOADING'"
        :body="loadingModalBody"
        :has-close="!isLoading"
        :has-header="true"
        :is-failure="isFailure"
        :is-loading="isLoading"
        :is-success="isSuccess"
        :title="modalLoadingTitle"
        @on-close="handleModalLoading"
    />
</template>
<script lang="ts">
import ModalManager from '@/components/alerts/ModalManager/ModalManager.vue';
import { mapActions, mapGetters } from 'vuex';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import ModalLoading from '@/components/alerts/modalLoadingManager/ModalLoadingManager.vue';
import IconCard from '@/components/icons/IconCard.vue';
import BaseBtn from '@/components/buttons/BaseBtn.vue';
import IconCircularArrow from '@/components/icons/IconCircularArrow.vue';
import dayjs from 'dayjs';
import { logger } from '@/utils/logger';
import Scrollable from '@/views/myAccount/views/profile/views/loyaltyPrograms/benefits/ScrollableObserver.vue';
import BtnText from '@/components/buttons/BtnText.vue';
import HeaderProgram from '@/views/myAccount/views/profile/views/loyaltyPrograms/HeaderProgram.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import { ResponseContent } from '@/models/loyaltyPrograms/ResponseContent';
import { LoyaltyProgramsTypes } from '@/enums/loyaltyProgramsTypes';
import ConverterToTransfer from '@/components/modal/converter/ConverterToTiptiCard.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

enum BenefitScreenModals {
    LOADING = 'LOADING',
    DEACTIVATE = 'DEACTIVATE',
    TRANSFER = 'TRANSFER',
}

export default {
    name: 'benefit-screen',
    components: {
        ConverterToTransfer,
        BaseLoading,
        SectionHeader,
        HeaderProgram,
        BtnText,
        Scrollable,
        IconCircularArrow,
        BaseBtn,
        IconCard,
        ModalLoading,
        SectionFooter,
        ModalManager,
    },
    data() {
        return {
            isLoading: false,
            isSuccess: false,
            isFailure: false,
            isDeactivateSuccess: false,
            isLoadingTransactions: false,
            isLoadingBalance: false,
            hasMoreTransactions: true,
            minBenefits: true,
            transactions: [],
            balance: undefined,
            currentModal: '',
            modalLoadingTitle: '',
            loadingModalBody: '',
            nextPge: '',
            amount: 0,
            currentPage: 0,
            pageSize: 10,
            observer: undefined,
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            countrySelected: 'external/countrySelected',
            programSelected: 'loyaltyAndRewards/program',
        }),
        availableAmount(): number {
            return this.balance?.current_balance ?? 0;
        },
        benefits() {
            return this.minBenefits ? this.programSelected.benefits.slice(0, 3) : this.programSelected.benefits;
        },
        currencySymbol(): string {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        handlerShowBenefits(): string {
            return this.minBenefits ? this.$t('txt.see_more_benefits') : this.$t('txt.see_less_benefits');
        },
        pageLabel() {
            return this.programSelected.customLabelStructure.find(
                (labels) => labels.pageName === 'deactivate_affilation',
            );
        },
        canShowHistory(): boolean {
            return (
                this.programSelected.isForExchange ||
                this.programSelected.isForDiscount ||
                this.programSelected.programType !== LoyaltyProgramsTypes.AFFILIATION
            );
        },
    },
    mounted() {
        this.rewardBalance();
        this.updateTransactions();
    },
    methods: {
        ...mapActions({
            getRewards: 'loyaltyAndRewards/getRewards',
            getTransactions: 'loyaltyAndRewards/getTransactions',
            fetchPrograms: 'loyaltyAndRewards/programs',
            deactivateSubscription: 'loyaltyAndRewards/deactivateSubscription',
            exchangeTiptiCard: 'loyaltyAndRewards/exchangeTiptiCard',
            fetchTiptiCard: 'tiptiCard/tiptiCard',
        }),
        canTransfer(): void {
            if (this.balance?.current_balance <= 0) return;
            this.currentModal = BenefitScreenModals.TRANSFER;
        },
        closeDeactivateModal(): void {
            if (this.isLoading) return;
            if (this.isSuccess) {
                this.$router.push({
                    name: appRoutesMap.reward.loyaltyAndReward,
                });
            }
            this.currentModal = '';
        },
        closeTransferModal(): void {
            if (this.isLoading) return;
            this.currentModal = '';
        },
        async deactivateFlow(): Promise<void> {
            try {
                this.currentModal = BenefitScreenModals.LOADING;
                this.isLoading = true;
                this.modalLoadingTitle = this.$t('txt.loyalty_program');
                this.loadingModalBody = this.$t('general.validating_data');
                const deactivateResult: ResponseContent = await this.deactivateSubscription({
                    programId: this.programSelected.id,
                    subscriptionId: this.programSelected.subscription.id,
                });
                if (deactivateResult.status === 'ok') {
                    this.fetchPrograms();
                    await this.$router.push({
                        name: appRoutesMap.reward.programs,
                    });
                    this.isSuccess = true;
                    this.isDeactivateSuccess = true;
                    this.loadingModalBody = deactivateResult.message.description;
                } else {
                    this.isFailure = true;
                    this.loadingModalBody = deactivateResult.message.description;
                }
            } catch (err) {
                this.isFailure = true;
                this.loadingModalBody = err.data?.message?.description?.length
                    ? err.data?.message?.description
                    : this.$t('general.error_occurred');
            } finally {
                this.isLoading = false;
            }
        },
        getAccreditation(value: string): string {
            const newVal = parseFloat(value);
            return this.currencySymbol + ' ' + newVal.toFixed(2);
        },
        getPoints(value: string): string {
            return parseFloat(value).toFixed(2) + ' pts';
        },
        handleModalLoading(): void {
            if (this.isDeactivateSuccess) this.$emit('on-deactivate');
            if (this.isLoading) return;
            this.currentModal = '';
            this.isFailure = false;
            this.isSuccess = false;
        },
        async initTransfer(amount: number): Promise<void> {
            try {
                this.amount = amount;
                this.modalLoadingTitle = this.$t('txt.transfer_balance');
                this.currentModal = BenefitScreenModals.LOADING;
                this.loadingModalBody = '';
                this.isLoading = true;
                const exchangeResult: ResponseContent = await this.exchangeTiptiCard({
                    programId: +this.programSelected.id,
                    subscriptionId: +this.programSelected.subscription.id,
                    body: {
                        total_points: +this.amount,
                    },
                });
                if (exchangeResult.status == 'ok') {
                    this.amount = '';
                    try {
                        this.hasMoreTransactions = true;
                        this.rewardBalance();
                        this.updateTransactions();
                        this.fetchTiptiCard();
                    } catch (e) {
                        logger('UPDATE_VALUES', e);
                    }
                    this.isSuccess = true;
                    this.loadingModalBody = this.$t('txt.transfer_applied', {
                        program: this.programSelected.name,
                    });
                } else {
                    this.isFailure = true;
                    this.modalLoadingTitle = this.$t('general.ups');
                    this.loadingModalBody = exchangeResult.message?.description?.length
                        ? exchangeResult.message?.description
                        : this.$t('general.error_occurred');
                }
            } catch (err) {
                this.isFailure = true;
                this.modalLoadingTitle = this.$t('general.ups');
                this.loadingModalBody = err.message?.description?.length
                    ? err.message?.description
                    : this.$t('general.error_occurred');
            } finally {
                this.isLoading = false;
            }
        },
        intersected(): void {
            this.updateTransactions();
        },
        onBack(): void {
            this.$router.replace({
                name: appRoutesMap.reward.programs,
            });
        },
        onShowCard(): void {
            this.$router.push({
                name: appRoutesMap.reward.showCard,
                params: {
                    id: this.programSelected.id,
                },
            });
        },
        reloadBalance(): void {
            this.rewardBalance();
        },
        async rewardBalance(): Promise<void> {
            try {
                if (this.isLoadingBalance) return;
                this.isLoadingBalance = true;
                this.balance = await this.getRewards({
                    programId: this.programSelected.id,
                    subscriptionId: this.programSelected.subscription.id,
                });
            } catch (err) {
                logger('rewardBalance', err);
            } finally {
                this.isLoadingBalance = false;
            }
        },
        transactionDate(date: string): string {
            if (!date?.length) return;
            return dayjs(date)
                .locale(this.user?.['defaultLanguage']?.toLowerCase())
                .format('DD/MM/YYYY');
        },
        updateTransactions(): void {
            if (!this.hasMoreTransactions || this.isLoadingTransactions) return;
            this.isLoadingTransactions = true;
            this.getTransactions({
                programId: this.programSelected.id,
                subscriptionId: this.programSelected.subscription.id,
                page: this.currentPage,
                size: this.pageSize,
                newUrl: this.nextPge?.length ? this.nextPge : undefined,
            })
                .then((result) => {
                    this.hasMoreTransactions = !!result.next;
                    this.nextPge = result.next;
                    const all = this.transactions?.concat(result.results).filter((element) => !!element);
                    this.transactions = [...new Set(all.map((a) => JSON.stringify(a)))].map((a: string) =>
                        JSON.parse(a),
                    );
                })
                .catch((err) => {
                    logger('updateTransactions', err);
                    this.hasMoreTransactions = false;
                })
                .finally(() => {
                    this.isLoadingTransactions = false;
                });
        },
    },
    emits: ['on-deactivate', 'on-show-card'],
};
</script>
<style lang="scss" scoped>
@import 'benefit-screen';
</style>
