<script lang="ts" setup>
import ButtonsEditAndDelete from '@/components/generic/ButtonsEditAndDelete.vue';
import TiptiCheckboxRounded from '@/modules/payment-methods/components/TiptiCheckboxRounded.vue';

defineOptions({
    name: 'CardBase',
});

defineProps({
    isRemoving: {
        type: Boolean,
        default: false,
    },
    showCheckSelected: {
        type: Boolean,
        default: false,
    },
});
defineEmits<{
    (e: 'on-edit'): void;
    (e: 'on-delete'): void;
}>();
const isDefault = defineModel<boolean>('isDefault', {
    default: false,
});
const isCheck = defineModel<boolean>('isCheck', {
    default: false,
});
</script>

<template>
    <article class="card-generic">
        <slot />
        <section class="card-generic__actions">
            <p v-if="isDefault" class="default-card">{{ $t('general.default') }}</p>
            <ButtonsEditAndDelete
                v-if="isRemoving"
                class="button-card-base"
                @on-delete="$emit('on-delete')"
                @on-edit="$emit('on-edit')"
            />
            <tipti-checkbox-rounded
                v-else-if="showCheckSelected"
                v-model="isCheck"
                class="check-icon"
                bg-round="#F5F5F5"
                label=""
            />
        </section>
    </article>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/index' as style;

.card-generic {
    display: flex;
    justify-content: space-between;
    @include style.box-shadow;
    box-sizing: content-box;
    border-radius: 8px;

    &__actions {
        display: flex;
        gap: style.$margin-sm;
    }
}

.button-card-base {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.default-card {
    @include style.caption;
    color: style.$orange;
    padding: 7px;
    @include style.text-lines(1);
}

.check-icon {
    align-self: center;
    margin-right: style.$margin-md;
}
</style>
