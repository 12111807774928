<script lang="ts" setup>
import TheModalContent from '@/modules/core/components/layouts/TheModalContent.vue';
import BaseHeader from '@/modules/core/components/headers/HeaderWithAction.vue';
import { computed, PropType, ref } from 'vue';
import { RetailerAvailabilityModel } from '@/modules/retailers/models/RetailerAvailabilityModel';
import DeliverySchedules from '@/views/checkout/components/schedules/DeliverySchedules.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { ProductModel } from '@/models/product/ProductModel';
import { logger } from '@/utils/logger';
import { useApp } from '@/composables/useApp';
import { useCart } from '@/composables/useCart';
import { useCheckoutEvents } from '@/views/checkout/composables/useCheckoutEvents';
import { useDays } from '@/composables/useDays';
import BaseBone from '@/components/skeletons/BaseBone.vue';

const show = defineModel({
    type: Boolean,
    required: true,
});

const props = defineProps({
    retail: {
        type: Object as PropType<RetailerAvailabilityModel>,
        required: true,
    },
    stockItem: {
        type: Object as PropType<ProductModel>,
        default: undefined,
    },
    isFromAlert: {
        type: Boolean,
        default: false,
    },
});

const $emits = defineEmits<(e: 'selected') => void>();
const { fetchCart, findRetail } = useCart();
const { openCheckoutSchedule, selectNewScheduleOnCheckout } = useCheckoutEvents();

const { $store, notifier, $t } = useApp();
const { setTimeZone } = useDays();

const timeDelivery = ref<TimeDeliveryModel>();
const isLoading = ref(false);

const stockItemTimeDelivery = computed<TimeDeliveryModel[]>(() => $store.getters['checkout/stockItemTimeDelivery']);
const storeTimeDelivery = computed<TimeDeliveryModel[]>(() => $store.getters['checkout/timeDelivery']);
const retailerIdsWithtimeDelivery = computed<string>(() => $store.getters['checkout/retailerIdsWithtimeDelivery']);

openCheckoutSchedule(findRetail(props.retail.retailer_id));

const saveTimeDelivery = async () => {
    try {
        if (isLoading.value || !timeDelivery.value?.date) return;
        isLoading.value = true;
        const oldSchedule: TimeDeliveryModel = props.stockItem
            ? stockItemTimeDelivery.value.find(
                  (ret: TimeDeliveryModel) =>
                      ret.retailerId === props.retail.retailer_id && props.stockItem?.id === ret.stockItemId,
              )
            : storeTimeDelivery.value.find((ret: TimeDeliveryModel) => ret.retailerId === props.stockItem?.id);
        const allDeliveryTimeLength = storeTimeDelivery.value.length;
        props.stockItem
            ? $store.commit('checkout/stockItemTimeDelivery', timeDelivery.value)
            : $store.commit('checkout/timeDelivery', timeDelivery.value);
        const newDeliveryTimeLength = storeTimeDelivery.value.length;
        if (newDeliveryTimeLength != allDeliveryTimeLength)
            await fetchCart(retailerIdsWithtimeDelivery.value).catch((err) => logger('FETCH_CART', err));
        selectNewScheduleOnCheckout(
            findRetail(props.retail?.retailer_id).toAnalytics,
            {
                initial: oldSchedule ? setTimeZone(`${oldSchedule.date} ${oldSchedule.deliveryFirstDate}`) : null,
                new: setTimeZone(`${timeDelivery.value.date} ${timeDelivery.value.deliveryFirstDate}`),
            },
            props.stockItem
                ? {
                      productName: props.stockItem.name,
                      stockItemId: props.stockItem.id,
                  }
                : null,
        );
        $emits('selected');
        notifier({
            type: 'SUCCESS',
            body: $t('txt.schedule_updated'),
        });
        show.value = false;
    } catch (err) {
        logger('SET_SCHEDULE_SELECTED', err);
        notifier({
            type: 'ERROR',
            body: $t('cta.try_again'),
        });
    } finally {
        isLoading.value = false;
    }
};

const loaded = ref(false);
</script>

<template>
    <TheModalContent class="phone-full-page modal-schedule">
        <template #header>
            <BaseHeader
                :text="`${$t('general.delivery-time')} ${retail.retailer_name}`"
                :text-size="24"
                with-default-close
                @on-close="show = false"
            />
        </template>
        <template #default>
            <Suspense>
                <DeliverySchedules
                    v-model="timeDelivery"
                    :retail="retail"
                    :stock-item="stockItem"
                    @on-load="loaded = true"
                >
                    <div v-if="isFromAlert" class="change-schedule">
                        <img class="retailer-img" :alt="retail.retailer_name" :src="retail.retailer_icon ?? ''" />
                        <p class="retailer-legend">
                            {{ $t('txt.schedule__change-schedule-header') }}
                        </p>
                    </div>
                </DeliverySchedules>
                <template #fallback>
                    <section class="skeleton-deliver-schedule">
                        <div class="skeleton-days">
                            <base-bone v-for="i in 5" :key="i" class="skeleton-days__bone" />
                        </div>
                        <div class="skeleton-schedule">
                            <base-bone v-for="i in 6" :key="i" class="skeleton-schedule__bone" />
                        </div>
                    </section>
                </template>
            </Suspense>
        </template>
        <template v-if="loaded" #footer>
            <footer class="schedule-footer">
                <BtnSolid
                    :is-disabled="!timeDelivery?.date"
                    :is-loading="isLoading"
                    :txt="$t('cta.save')"
                    @click="saveTimeDelivery"
                />
            </footer>
        </template>
    </TheModalContent>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/media-query' as mq;
@use '@/assets/scss/padding' as pd;
@use '@/assets/scss/margin' as md;
@use '@/assets/scss/type-system' as ts;

.modal-schedule {
    min-height: 60svh;
}

.schedule-footer {
    display: flex;
    align-content: center;
    justify-content: center;
    padding-bottom: 0.25rem;
    margin-top: auto;
}

.skeleton-schedule {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__bone {
        width: 100%;
        height: 3rem;
    }
}

.skeleton-deliver-schedule {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

.skeleton-days {
    display: flex;
    gap: 0.5rem;

    &__bone {
        height: 4rem;
        width: 4rem;
    }
}

.change-schedule {
    display: flex;
    gap: 0.5rem;
    width: 20rem;
    margin: md.$margin-md auto;
    align-items: center;
}

.retailer-img {
    height: 3rem;
    width: 3rem;
    border-radius: 100%;
    object-fit: contain;
    border: 1px solid #c7c7c7;
    justify-self: center;
}

.retailer-legend {
    @include ts.caption;
}
</style>
