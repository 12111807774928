<template>
    <section class="the-address-btn">
        <div class="the-address-btn__content" @click.stop="openModalAddress">
            <icon-location :size="0.8" color="#FF9012" />
            <p>{{ addressName }}</p>
            <icon-arrow class="icon-arrow" :size="0.6" color="#FF9012" />
        </div>
        <horizontal-divider height="0.1rem" />
    </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import IconLocation from '@/components/icons/IconLocation.vue';
import HorizontalDivider from '@/components/dividers/HorizontalDivider.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import { AddressModel } from '@/models/address/AddressModel';
import { useUser } from '@/composables/useUser';
import { useApp } from '@/composables/useApp';
import { ref } from 'vue';

export default {
    name: 'TheAddressBtn',
    components: {
        IconLocation,
        HorizontalDivider,
        IconArrow,
    },
    setup() {
        const { isMobile } = useApp();
        const { isAuth, requireAuth, modalAddressShowConfig } = useUser();
        const isLoading = ref(false);

        return {
            isAuth,
            isMobile,
            requireAuth,
            isLoading,
            modalAddressShowConfig,
        };
    },

    computed: {
        ...mapGetters({
            locationSelected: 'user/userLocation',
            addressDefault: 'location/addressDefault',
        }),
        addressName(): string {
            if (this.getAddress?.comment?.length) return this.getAddress.comment;
            else if (this.getAddress?.name?.length) return this.getAddress.name;
            return this.$t('txt.address_add-address');
        },
        getAddress(): AddressModel {
            return this.locationSelected?.id ? this.locationSelected : this.addressDefault;
        },
    },
    methods: {
        openModalAddress() {
            if (!this.isAuth) {
                this.requireAuth = true;
                return;
            }
            this.modalAddressShowConfig.show = true;
            this.modalAddressShowConfig.section = 'HEADER';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-address-btn {
    display: none;
    @include tablet {
        display: flex;
        flex-direction: column;
        padding: 0 $padding-sm;
        width: 1fr;
        background-color: $white;
    }
}

.the-address-btn__content {
    @include caption;
    background-color: $white;
    display: grid;
    align-items: center;
    column-gap: $margin-md;
    grid-template-columns: 5% 1fr 15%;
    @include tablet {
        grid-template-columns: 5% fit-content(100%) 15%;
        align-content: center;
        justify-content: center;
    }
}

.the-address-btn__content p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include tablet {
        padding-left: $padding-sm;
    }
}

.the-address-btn__content span {
    color: $orange;
    font-weight: 600;
    cursor: pointer;
    justify-self: flex-end;
}

.icon-arrow {
    transform: rotate(90deg);
}
</style>
