<template>
    <div class="deferred" :class="{ 'deferred--margin': hasTopMargin }">
        <p v-if="!isModal && !isMixPaymentTiptiCard" class="deferred__title">
            {{ $t('alerts.deferred__title') }}
        </p>
        <p class="deferred__text" :class="{ 'deferred__text--no-modal': !isModal }">
            {{ textAlertDeferredAndMixedPaymentAlert }}
        </p>
        <section class="deferred__options" :class="{ 'deferred__options--no-modal': !isModal }">
            <radio
                v-if="!isMixPaymentTiptiCard"
                v-model="deferred"
                :inputs="options"
                :value-select="deferred"
                radio-group="deferredPayments"
            />
        </section>
    </div>
</template>
<script lang="ts" setup>
import Radio from '@/components/radio/Radio.vue';
import { usePayments } from '@/composables/usePayments';
import { computed } from 'vue';

defineProps({
    isModal: {
        type: Boolean,
        default: true,
    },
    hasTopMargin: {
        type: Boolean,
        default: false,
    },
});

const {
    deferredSelection: deferred,
    deferredOptions,
    textAlertDeferredAndMixedPaymentAlert,
    isMixPaymentTiptiCard,
} = usePayments();

const options = computed(
    () =>
        deferredOptions.value?.map((option) => ({
            value: option?.code,
            name: option?.translatedName,
        })),
);
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.deferred {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &--margin {
        margin: $margin-sm;
        margin-bottom: unset;
    }

    &__title {
        @include caption;
        font-weight: 600;
        align-self: flex-start;
        color: $orange;
    }

    &__text {
        @include overline;
        margin-bottom: $margin-md;

        &--no-modal {
            align-self: flex-start;
            margin-bottom: $margin-xsm;
        }
    }

    &__options {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(3, 1fr);

        &--no-modal {
            grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
        }

        @include phone {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
</style>
