<template>
    <section class="banks">
        <base-card
            padding="0"
            :bgColor="backgroundColor(bank)"
            class="card-bank cursor-pointer"
            :class="{ 'card-bank--disable': !bank?.enabled }"
            v-for="bank in banks"
            :key="bank"
            @click="onSelect(bank)"
        >
            <img :src="bank.icon" alt="" />
        </base-card>
    </section>
</template>

<script lang="ts">
import { BanksModel } from '@/models/paymentMethods/BanksModel';
import BaseCard from '../cards/BaseCard.vue';

export default {
    name: 'banks',
    data() {
        return {
            bankSelected: undefined,
        };
    },
    components: { BaseCard },
    methods: {
        backgroundColor(bank) {
            return bank?.id == this.bankSelected?.id ? bank?.color : bank?.enabled ? '#C1C1C1' : '#f1f1f1';
        },
        onSelect(bank) {
            this.bankSelected = this.bankSelected === bank ? null : bank;
            if (bank && bank?.enabled) this.$emit('on-selected-bank', this.bankSelected);
        },
    },
    props: {
        banks: { type: Array, required: true },
        firstBankSelected: { type: BanksModel, default: undefined },
    },
    watch: {
        firstBankSelected(value) {
            this.bankSelected = value;
        },
    },
    emits: ['on-selected-bank'],
};
</script>

<style lang="scss" scoped>
@import './banks.scss';
</style>
