import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60862b8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "affiliation-card" }
const _hoisted_2 = { class: "title-virtual-affiliation" }
const _hoisted_3 = { class: "container-card" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "name-card" }
const _hoisted_6 = { class: "identify-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_header_program = _resolveComponent("header-program")!
  const _component_vue_barcode = _resolveComponent("vue-barcode")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_section_header, {
      "with-borders": false,
      "has-back": true,
      onOnBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
      title: _ctx.programSelected.name
    }, null, 8, ["title"]),
    _createVNode(_component_header_program),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('txt.show_card__affiliates')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "affiliation-card__img",
          src: _ctx.programSelected.cardImage,
          alt: _ctx.programSelected.name
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.programSelected.subscription.subscriberName), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.programSelected.subscription.subscriberIdentifier), 1)
      ]),
      _createVNode(_component_vue_barcode, {
        value: _ctx.programSelected.subscription.subscriberIdentifier,
        options: { displayValue: true, format: $options.formatCode }
      }, null, 8, ["value", "options"])
    ])
  ], 64))
}