import { BannerModel } from '@/models/promotions/banners/BannerModel';
import { RecipeDetailModel } from '@/models/recipes/RecipeDetailModel';
import { sanitizeTitle } from '@/utils/utils';
import { computed } from 'vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import PromotionsRouting from '@/router/routing';
import { useDays } from '@/composables/useDays';
import { getLocation } from '@/utils/locationUtils';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { TrackingBannerStoryModel } from '@/models/promotions/banners/TrackingBannerStoryModel';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useApp } from '@/composables/useApp';
import { StoriesResultModel } from '@/models/stories/StoriesResultModel';

export const usePromotion = () => {
    const { $store, $router } = useApp();
    const { doFormat } = useDays();
    const { retailerSelected } = useRetailer();

    const isBannerInSection = computed<boolean>({
        get: () => $store.getters['promotions/isBannerInSection'],
        set: (value) => $store.commit('promotions/isBannerInSection', value),
    });

    const informativeBanner = computed<BannerModel>({
        get: () => $store.getters['promotions/informativeBanner'],
        set: (value) => $store.commit('promotions/informativeBanner', value),
    });

    const informativeStory = computed<StoriesResultModel>({
        get: () => $store.getters['stories/informativeStory'],
        set: (value) => $store.commit('stories/informativeStory', value),
    });

    const goToPromoProductsScreen = async (banner: BannerModel, subroute?: string): Promise<void> => {
        let routePath: string;
        let recipeDetail: RecipeDetailModel;
        switch (banner.type) {
            case 'CATEGORY':
                $store.commit('promotions/bannerSelected', banner);
                $router.push({
                    path: `${subroute ?? ''}/banner-categories/${banner.id}/${banner.title}`,
                    query: {
                        titleColor: banner.titleColor?.replace('#', '') ?? '000',
                        picture: banner.picture,
                    },
                });
                return;
            case 'CATEGORIES':
                // TODO: Handle banner in section
                $router.push({
                    name: PromotionsRouting.Promotions.Categories.name,
                    params: { title: sanitizeTitle(banner.title), banner_id: banner.id },
                    query: {
                        titleColor: banner.titleColor?.replace('#', '') ?? '000',
                        picture: banner.picture,
                        title: banner.title,
                    },
                });
                break;
            case 'INFORMATIVE':
                $store.commit('promotions/informativeBanner', banner);
                break;
            case 'MULTIPLE_PRODUCTS':
                $store.commit('promotions/bannerSelected', banner);
                $router.push({
                    path: `${subroute ?? ''}/banner-products/${banner.id}/${banner.title}/${
                        banner.showDiscountActivationProgressBar
                    }`,
                });
                break;
            case 'DISCOUNT':
                $store.commit('promotions/bannerSelected', banner);
                $router.push({
                    path: `${subroute ?? ''}/banner-discount/${banner.id}/${banner.title}/${
                        banner.showDiscountActivationProgressBar
                    }`,
                    query: {
                        titleColor: banner.titleColor?.replace('#', '') ?? '000',
                        picture: banner.picture,
                    },
                });
                break;
            case 'PROMOTION':
                $router.push({
                    path: `${subroute ?? ''}/banner-promotion/${banner.id}/${banner.title}/${
                        banner.showDiscountActivationProgressBar
                    }`,
                    query: {
                        titleColor: banner.titleColor?.replace('#', '') ?? '000',
                        picture: banner.picture,
                    },
                });
                break;
            case 'ONE_PRODUCT':
                const { results } = await $store.dispatch('promotions/bannersProducts', {
                    page: 1,
                    isBannerInSection: isBannerInSection.value,
                    bannerType: banner.type,
                    id: banner.id,
                    retailerId: retailerSelected.value.id,
                });
                if (!results?.length) return;
                await $store.commit('product/productSelected', results[0]);
                $router.push({
                    name: appRoutesMap.product,
                    params: {
                        retailer: retailerSelected.value?.name,
                        name: results[0].name,
                    },
                });
                break;
            case 'RECIPE':
            case 'LIST':
                $router.push({ name: appRoutesMap.list.personalLists });
                recipeDetail = await $store.dispatch('promotions/recipesBanner', {
                    id: banner.id,
                    page: 0,
                    isBannerInSection: isBannerInSection.value,
                });
                $router.push({
                    name: appRoutesMap.list.recipeDetail,
                    query: { group: '', name: recipeDetail['name'], id: recipeDetail['recipeId'] },
                });
                break;

            case 'LINK':
                if (banner.link?.length) {
                    window.location.href = banner.link;
                    break;
                }
                if (banner.deeplink?.length) {
                    window.location.href = banner.deeplink;
                    break;
                }
                break;
            case 'APP_SECTION':
                if (!banner.appSection?.webSection) break;
                switch (banner.appSection?.name) {
                    case 'TiptiCard':
                        routePath = appRoutesMap.tiptiCardRoutes.history;
                        break;
                    case 'Profile':
                        routePath = appRoutesMap.account.editProfile;
                        break;
                    case 'Recipes':
                        routePath = appRoutesMap.list.personalLists;
                        break;
                    case 'Retailers':
                        routePath = appRoutesMap.home;
                        break;
                    case 'Referrals':
                        routePath = appRoutesMap.account.referred;
                        break;
                    case 'Categories':
                        routePath = appRoutesMap.home;
                        break;
                    default:
                        routePath = appRoutesMap.home;
                        break;
                }
                $router.push({ name: routePath });
                break;
            default:
                $router.push({ name: appRoutesMap.home });
        }
    };

    const formatDatePromotion = (start: Date, end: Date) => {
        return `${doFormat({ date: start, format: 'MMMM DD' })} - ${doFormat({
            date: end,
            format: 'MMMM DD',
        })}`;
    };

    const track = async (input: {
        id_object: number;
        resource_type: string;
        resource_action: string;
        interactive_id?: number;
        stock_item_id?: number;
        feeling_bar_select?: string;
        trackItemSection?: string;
    }) => {
        const location = await getLocation();
        const { data } = await axiosInstance().post<TrackingBannerStoryModel>('v2/api/tracking_generic/', {
            origin: 'app_web',
            geo_position: location.geolocation,
            client_device_os: navigator.userAgent,
            ...input,
        });
        return data;
    };

    return {
        goToPromoProductsScreen,
        formatDatePromotion,
        isBannerInSection,
        track,
        informativeBanner,
        informativeStory,
    };
};
