<template>
    <section class="attribute">
        <p class="attribute__name">
            {{ nameTypeAttribute }}:
            <span class="attribute__name--value">{{ nameAttributeSelected }}</span>
        </p>
        <carousel-attributes :has-margin="false" show-arrows>
            <div class="attribute__options">
                <TheProductAttribute
                    v-for="attribute in attributeValues"
                    :key="attribute.id"
                    :attribute="attribute"
                    @update-attribute="setMainAttribute"
                />
            </div>
        </carousel-attributes>
    </section>
    <template v-if="attributeOptions">
        <product-attributes :attribute-order="2" :attribute-values="attributeOptions" />
    </template>
</template>
<script lang="ts">
import { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { ProductAttributeModel } from '@/models/product/ProductAttributeModel';
import { ProductWithAttributeModel } from '@/models/product/ProductWithAttributeModel';
import { AttributeInProductModel } from '@/models/product/AttributeInProduct';
import CarouselAttributes from '@/components/carousels/components/carouselBasic/CarouselBasic.vue';
import TheProductAttribute from '@/components/modal/productModal/components/productAttributes/TheProductAttribute.vue';

export default {
    name: 'ProductAttributes',
    components: { TheProductAttribute, CarouselAttributes },
    props: {
        attributeValues: {
            type: Array as PropType<Array<ProductWithAttributeModel>>,
            required: true,
        },
        attributeOrder: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            attributeOptions: null,
        };
    },
    computed: {
        ...mapGetters({
            userLanguage: 'user/userLanguage',
            product: 'product/currentProduct',
            itemDefault: 'product/itemDefault',
            typeAttributes: 'product/typeAttributes',
        }),
        currentTypeAttribute(): ProductAttributeModel {
            return [...this.typeAttributes]?.sort((a, b) => a.order - b.order)[this.attributeOrder - 1];
        },
        nameTypeAttribute(): string {
            return this.userLanguage === 'en'
                ? this.currentTypeAttribute?.name?.EN
                : this.currentTypeAttribute?.name?.ES;
        },
        nameAttributeSelected(): string {
            const attr = this.product.attributes?.find(
                (attribute: AttributeInProductModel) =>
                    Object.entries(attribute.type_of_attribute_name).toString() ===
                    Object.entries(this.currentTypeAttribute.name).toString(),
            );
            return this.userLanguage === 'en' ? attr?.name?.EN : attr?.name?.ES;
        },
    },
    created() {
        if (this.attributeOrder === 1)
            this.setMainAttribute(
                this.attributeValues?.find((attr: ProductWithAttributeModel) => attr.id == this.itemDefault?.id),
            );

        if (this.attributeOrder === 2)
            this.setMainAttribute(
                this.attributeValues?.find(
                    (attr: ProductWithAttributeModel) => attr.id === this.itemDefault?.attribute[0].id,
                ),
            );
    },
    methods: {
        setMainAttribute(attribute: ProductWithAttributeModel): void {
            if (!attribute) return;
            this.attributeOptions = attribute.attribute?.length ? attribute.attribute : null;
            this.$store.dispatch('product/setCurrentAttributeProduct', { attribute });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.attribute {
    max-width: 25vw;

    @include tablet {
        max-width: 35vw;
    }

    @include phone {
        max-width: unset;
    }

    &__name {
        @include subtitle2;
        font-size: 14px;
        font-weight: 400;

        &--value {
            font-weight: 600;
        }
    }

    &__options {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.attribute-btn {
    margin: $margin-sm;
    background-color: $white;
    min-width: 3rem;
    min-height: 3rem;
    border: $border 1px solid;
    border-radius: $radius-md;

    &--selected {
        background-color: $orange;
        border: $orange 1px solid;
    }

    &--rounded {
        border-radius: $radius-circular;
        background-color: $white;
        min-width: 4rem;
        min-height: 4rem;
    }

    &__image {
        width: 100%;
        border-radius: $radius-circular;
    }

    &__text {
        @include body2;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.attribute-btn--selected > .attribute-btn__text {
    color: $white;
}
</style>
