import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80337246"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "bottom-sheet__title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_base_bottom_sheet = _resolveComponent("base-bottom-sheet")!

  return (!$options.isMobile)
    ? (_openBlock(), _createBlock(_component_base_modal, {
        key: 0,
        hasClose: $props.hasClose,
        title: $props.title,
        hasHeader: $props.hasHeader,
        "header-text-color": $props.headerTextColor,
        onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close')))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true),
          ($props.showButtons)
            ? (_openBlock(), _createElementBlock("section", {
                key: 0,
                class: _normalizeClass(["modal_manager__btns", {
                'modal_manager__btns--one-btn': $props.onlyConfirmationBtn,
            }]),
                style: _normalizeStyle($options.style)
              }, [
                (!$props.onlyConfirmationBtn)
                  ? (_openBlock(), _createBlock(_component_btn_out_line, {
                      key: 0,
                      bgColor: "#FFF",
                      color: "#FF9012",
                      txt: $props.declineButtonText ?? _ctx.$t('cta.reject'),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-decline')))
                    }, null, 8, ["txt"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_btn_solid, {
                  isDisabled: $props.isDisabled,
                  isLoading: $props.isLoading,
                  txt: $props.acceptButtonText ?? _ctx.$t('cta.accept'),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-accept')))
                }, null, 8, ["isDisabled", "isLoading", "txt"])
              ], 6))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["hasClose", "title", "hasHeader", "header-text-color"]))
    : (_openBlock(), _createBlock(_component_base_bottom_sheet, {
        key: 1,
        "bg-color": "#fff",
        onOnDismiss: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('on-dismiss'))),
        "cta-header-text": $props.ctaHeaderText
      }, {
        content: _withCtx(() => [
          ($props.showBottomSheetTitle)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString($props.title), 1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "content", {}, undefined, true),
          ($props.showButtons)
            ? (_openBlock(), _createElementBlock("section", {
                key: 1,
                class: _normalizeClass(["bottom-sheet__btns", {
                    'bottom-sheet__btns--one-btn': $props.onlyConfirmationBtn,
                }]),
                style: _normalizeStyle($options.style)
              }, [
                (!$props.onlyConfirmationBtn)
                  ? (_openBlock(), _createBlock(_component_btn_out_line, {
                      key: 0,
                      bgColor: "#FFF",
                      color: "#FF9012",
                      txt: $props.declineButtonText ?? _ctx.$t('cta.reject'),
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-decline')))
                    }, null, 8, ["txt"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_btn_solid, {
                  isDisabled: $props.isDisabled,
                  isLoading: $props.isLoading,
                  txt: $props.acceptButtonText ?? _ctx.$t('cta.accept'),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-accept')))
                }, null, 8, ["isDisabled", "isLoading", "txt"])
              ], 6))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["cta-header-text"]))
}