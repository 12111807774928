<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#DFDFDF',
    },
});
const width = computed<number>(() => 16 * props.size);
const height = computed<number>(() => 17 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0 16 17">
        <path
            :fill="color"
            d="M1.59992 4.5002C1.59992 4.05837 1.88646 3.7002 2.23992 3.7002H13.7599C14.1134 3.7002 14.3999 4.05837 14.3999 4.5002C14.3999 4.94202 14.1134 5.3002 13.7599 5.3002H2.23992C1.88646 5.3002 1.59992 4.94202 1.59992 4.5002Z"
        />
        <path
            :fill="color"
            d="M3.19998 8.5002C3.19998 8.05836 3.50699 7.7002 3.8857 7.7002H12.1143C12.493 7.7002 12.8 8.05836 12.8 8.5002C12.8 8.94204 12.493 9.3002 12.1143 9.3002H3.8857C3.50699 9.3002 3.19998 8.94204 3.19998 8.5002Z"
        />
        <path
            :fill="color"
            d="M5.6 11.7002C5.15817 11.7002 4.8 12.0584 4.8 12.5002C4.8 12.942 5.15817 13.3002 5.6 13.3002H10.4C10.8418 13.3002 11.2 12.942 11.2 12.5002C11.2 12.0584 10.8418 11.7002 10.4 11.7002H5.6Z"
        />
    </BaseIcon>
</template>
