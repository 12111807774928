<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
});
const width = computed<number>(() => 62 * props.size);
const height = computed<number>(() => 63 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0 62 63">
        <g filter="url(#filter0_d_4_6015)">
            <circle cx="31.0007" cy="30.5002" fill="white" r="26.6667" />
        </g>
        <path
            d="M45.167 39.5656C45.167 42.8373 42.5062 45.5 39.2363 45.5C35.9571 45.5 33.2965 42.8375 33.2965 39.5656C33.2965 39.1438 33.3434 38.7218 33.4276 38.328L25.1829 33.9874C24.1615 35.2906 22.5688 36.1251 20.7887 36.1251C17.6874 36.1251 15.1672 33.6032 15.1672 30.5C15.1672 27.4063 17.6875 24.875 20.7887 24.875C22.5688 24.875 24.1522 25.7094 25.1827 27.0031L34.0554 22.3343C33.9712 21.9408 33.9243 21.5377 33.9243 21.125C33.9243 18.0313 36.4445 15.5 39.5458 15.5C42.6471 15.5 45.1672 18.0312 45.1672 21.125C45.1672 24.2283 42.647 26.7501 39.5458 26.7501C37.8688 26.7501 36.3603 26.0094 35.3391 24.8374L26.3259 29.5811C26.3821 29.8811 26.4101 30.1906 26.4101 30.4999C26.4101 30.8092 26.3821 31.1186 26.3352 31.4186L34.655 35.8061C35.7324 34.4749 37.3907 33.6311 39.2366 33.6311C42.5062 33.6313 45.1672 36.2939 45.1672 39.5656H45.167Z"
            fill="#FF9012"
        />
        <defs>
            <filter
                id="filter0_d_4_6015"
                color-interpolation-filters="sRGB"
                filterUnits="userSpaceOnUse"
                height="61.3335"
                width="61.3333"
                x="0.333984"
                y="0.833496"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_4_6015" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_4_6015" mode="normal" result="shape" />
            </filter>
        </defs>
    </BaseIcon>
</template>
