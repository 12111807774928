<template>
    <base-modal
        :auto-size="!isMobile"
        :has-back-arrow="changeCreditCard"
        :header-text-color="changeCreditCard ? '#ff9012' : null"
        :title="changeCreditCard ? $t('txt.credit-card__cards-choosed') : membership.name"
        :z-index="5"
        @on-close="isSubscribing ? null : $emit('on-close')"
        @on-back-arrow="changeCreditCard = false"
    >
        <div class="cambrella-subscription">
            <select-credit-card
                v-if="changeCreditCard"
                class="cambrella-subscription__credit_cards"
                :amount="membership.price"
                @on-close="changeCreditCard = false"
            />
            <div v-else class="cambrella-subscription__content">
                <img class="cambrella-subscription__img" :src="membership.icon" alt="" @error="displayDefaultImg" />
                <cambrella-tile
                    class="cursor-pointer"
                    :membership="membership"
                    @click="isSubscribing ? null : $emit('on-close')"
                />
                <payment-method-only-credit-card-selection-tile
                    @on-change-payment="isSubscribing ? null : (changeCreditCard = true)"
                />
            </div>
        </div>
        <section-footer v-if="!changeCreditCard" :has-border="false" :with-shadow="false">
            <btn-out-line
                class="prime-payment__footer-btn"
                :is-disabled="!paymentIdSelected || isSubscribing"
                :is-loading="isSubscribing"
                :txt="$t('cta.subscribe')"
                @click="subscribe"
            />
        </section-footer>
    </base-modal>
    <snack-bar
        :body="msg"
        :is-active="showSnackBar"
        :is-failure="error"
        :is-success="success"
        @on-snackbar-close="closeSnackBar"
    />
</template>
<script lang="ts">
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import { useImage } from '@/composables/ImageComposable';
import SelectCreditCard from '@/views/myAccount/views/prime/components/selectCreditCard/SelectCreditCard.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import { usePayments } from '@/composables/usePayments';
import { logger } from '@/utils/logger';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import { isMobileBrowser } from '@/utils/utils';
import { mapActions, mapGetters } from 'vuex';
import { MembershipType } from '@/modules/cambrella/models/MembershipType';
import CambrellaTile from '@/modules/cambrella/components/CambrelaTile.vue';
import PaymentMethodOnlyCreditCardSelectionTile from '@/modules/payment-methods/components/PaymentMethodOnlyCreditCardSelectionTile.vue';
import { CambrellaMembershipModel } from '@/modules/cambrella/models/CambrellaMembership';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import { PropType } from 'vue';

export default {
    name: 'CambrellaGetSubscription',
    components: {
        PaymentMethodOnlyCreditCardSelectionTile,
        CambrellaTile,
        SectionFooter,
        BaseModal,
        SelectCreditCard,
        BtnOutLine,
        SnackBar,
    },
    props: {
        membership: {
            type: Object as PropType<MembershipType>,
            required: true,
        },
        useSnackBarOnSuccess: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const { displayDefaultImg } = useImage();
        const { paymentIdSelected } = usePayments();
        return {
            displayDefaultImg,
            paymentIdSelected,
        };
    },
    data: () => ({
        changeCreditCard: false,
        isSubscribing: false,
        showSnackBar: false,
        success: false,
        error: false,
        msg: '',
    }),
    computed: {
        ...mapGetters({
            countrySelected: 'external/countrySelected',
            defaultPrime: 'tiptiCard/defaultPrime',
            primeSelected: 'tiptiCard/primeSelected',
            creditCardDefault: 'payments/creditCardDefault',
            creditCardSelected: 'payments/creditCardSelected',
            paymentsToShow: 'payments/paymentsToShow',
            paymentIdSelected: 'payments/paymentIdSelected',
            baseConfiguration: 'user/baseConfiguration',
            retailerIdsWithtimeDelivery: 'checkout/retailerIdsWithtimeDelivery',
        }),
        isMobile(): boolean {
            return isMobileBrowser();
        },
    },
    async mounted() {
        await this.verifyPaymentMethod();
    },
    methods: {
        ...mapActions({
            fetchCreditCardList: 'payments/creditCardList',
            fetchProfileData: 'user/getProfile',
            fetchCart: 'cart/fetchCart',
        }),
        async verifyPaymentMethod() {
            try {
                this.isLoading = true;
                const creditCardPayment = this.paymentsToShow?.find(
                    (payment: PaymentToShowModel) =>
                        payment?.id == this.paymentIdSelected && payment?.name === 'payment-cards',
                );
                if (creditCardPayment) return;
                this.$store.commit('payments/cleanPaymentMethods');
                this.$store.commit('payments/paymentsToShow', []);

                await this.fetchCreditCardList(false);
                if (!this.creditCardDefault) return;

                if (this.baseConfiguration?.cardValidationOldRegister && !this.creditCardDefault.isValidate) return;
                this.$store.commit('payments/paymentIdSelected', this.creditCardDefault.id);
                this.$store.commit(
                    'payments/paymentsToShow',
                    new PaymentToShowModel({
                        name: 'payment-cards',
                        id: this.creditCardDefault.id,
                        idExpired: this.creditCardDefault.isExpired,
                        data: this.creditCardDefault.data?.cardDigits?.slice(1, 5),
                        analyticData: {
                            franchise: this.creditCardDefault?.franchise,
                            bin: this.creditCardDefault?.creditCardBrandData?.bin,
                        },
                        objectData: ['payment-cards', this.creditCardDefault.id],
                    }),
                );
            } catch (e) {
                logger('FETCH_CREDIT_CARD_LIST', e);
            } finally {
                this.isLoading = false;
            }
        },
        handleResult(success: boolean): void {
            this.success = success;
            this.error = !success;
            this.msg = success
                ? this.$t('txt.cambrellas__subscribe_success')
                : this.$t('txt.cambrellas__subscribe_error');
        },
        async subscribe(): Promise<void> {
            try {
                if (this.isSubscribing) return;
                this.isSubscribing = true;
                const result: CambrellaMembershipModel = await this.$store.dispatch('cambrellas/subscribe', {
                    payment_method: this.paymentIdSelected,
                    membership_id: this.membership.id,
                });
                if (!result?.id) return this.handleResult(false);
                this.$store.dispatch('segment/cambrellasSubscription', { cambrellas: this.membership });
                if (this.useSnackBarOnSuccess) this.handleResult(true);
                /// Save and autocomplete info cambrellas
                this.$store.commit('cambrellas/cambrellaMembership', result);
                if (!this.useSnackBarOnSuccess) return this.$emit('on-success');
                /// When client subscribe need fetch cart to return balance of new cart
                await this.fetchCart({ retailerIds: this.retailerIdsWithtimeDelivery }).catch((err) =>
                    logger('FETCH_CART', err),
                );
            } catch (err) {
                logger('CAMBRELLA_SUBSCRIPTION', err);
                this.handleResult(false);
            } finally {
                this.isSubscribing = false;
                if (this.useSnackBarOnSuccess) this.showSnackBar = true;
                else this.showSnackBar = this.error;
            }
        },
        closeSnackBar(): void {
            this.showSnackBar = false;
            if (this.success) this.$emit('on-close');
        },
    },
    emits: ['on-close'],
};
</script>
<style lang="scss" scoped>
@import 'cambrella-get-subscription.scss';
</style>
