<template>
    <!-- TODO: ADD: CLOSE OUTSIDE -->
    <teleport to="body">
        <div>
            <div class="alert-bg" @click="$emit('on-close-modal')"></div>
            <div class="alert-container">
                <base-card
                    class="alert-container__content"
                    :padding="showCloseIcon ? (accentHeader ? '0' : '10px') : '0'"
                >
                    <header class="alert-header" :class="{ 'alert-header__title': title.length && accentHeader }">
                        <div v-if="title.length && accentHeader" class="alert-header__spacer"></div>
                        <h1 v-if="title.length && accentHeader" class="alert-header__title-text">{{ title }}</h1>
                        <icon-circular-close
                            v-if="showCloseIcon"
                            class="cursor-pointer"
                            color="#606060"
                            @click="$emit('on-close')"
                        />
                        <div v-else class="alert-header__spacer"></div>
                    </header>
                    <slot></slot>
                </base-card>
            </div>
        </div>
    </teleport>
</template>

<script lang="ts">
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import BaseCard from '@/components/cards/BaseCard.vue';

export default {
    name: 'BaseAlert',
    components: {
        BaseCard,
        IconCircularClose,
    },
    props: {
        accentHeader: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        showCloseIcon: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['on-close', 'on-close-modal'],
    mounted() {
        document.body.style.overflowY = 'hidden';
    },
    unmounted() {
        document.body.style.overflowY = 'auto';
    },
};
</script>

<style lang="scss" scoped>
@import './base-alert.scss';
</style>
