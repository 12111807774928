<template>
    <section class="profile-main-container">
        <sidebar />
        <Suspense>
            <MyAccountRouted />
            <template #fallback>
                <BaseBone />
            </template>
        </Suspense>
    </section>
</template>

<script lang="ts" setup>
import BaseBone from '@/components/skeletons/BaseBone.vue';
import Sidebar from '@/components/sidebar/Sidebar.vue';
import MyAccountRouted from '@/views/myAccount/MyAccountRouted.vue';
import { useApp } from '@/composables/useApp';

const { isMobile, $router } = useApp();
</script>

<style lang="scss" scoped>
@import 'my-account';
</style>
