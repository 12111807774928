<template>
    <benefits-validate-card v-if="showBenefits" @on-close="showBenefits = false" />
    <generate-validation
        v-if="initFlow"
        :payment-method-id="paymentMethodId"
        :force-validate="forceValidate"
        @on-show-benefits="showBenefits = true"
        @on-success="showVerifyValidation = true"
        @on-error="generateError"
        @on-close="onClose"
    />
    <verify-validation
        v-if="showVerifyValidation"
        :payment-method-id="paymentMethodId"
        @on-success="onValidationSuccessful"
        @on-close="$emit('on-close')"
        @on-error="onVerifyError"
        :attempts-pending="remainingAttempts"
    />
    <validation-success v-if="validationSuccessful" @on-close="$emit('on-close')" />
    <validation-error
        v-if="showError"
        @on-accept="onErrorClose"
        @on-close="onErrorClose"
        :is-last-attempt="isLastAttempt"
        :is-request-error="isRequestError"
        :attempts="remainingAttempts"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import GenerateValidation from '@/views/creditCards/components/ValidateCard/generateValidation/GenerateValidation.vue';
import ValidationError from '@/views/creditCards/components/ValidateCard/validationError/ValidationError.vue';
import VerifyValidation from '@/views/creditCards/components/ValidateCard/verifyValidation/VerifyValidation.vue';
import ValidationSuccess from '@/views/creditCards/components/ValidateCard/validationSuccess/ValidationSuccess.vue';
import BenefitsValidateCard from '@/views/creditCards/components/ValidateCard/validationBenefits/BenefitsValidateCard.vue';

export default {
    name: 'validate-card',
    emits: ['on-close'],
    components: {
        ValidationSuccess,
        VerifyValidation,
        ValidationError,
        GenerateValidation,
        BenefitsValidateCard,
    },
    data() {
        return {
            showBenefits: false,
            showError: false,
            isRequestError: false,
            validationSuccessful: false,
            showVerifyValidation: false,
            isLastAttempt: false,
            remainingAttempts: 0,
            isFromNewCard: false,
        };
    },
    props: {
        paymentMethodId: {
            type: Number,
            required: true,
        },
        forceValidate: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onValidationSuccessful(): void {
            this.showVerifyValidation = false;
            this.validationSuccessful = true;
        },
        generateError(): void {
            this.showError = true;
            this.isRequestError = true;
        },
        onErrorClose(): void {
            if (this.isRequestError) {
                this.$emit('on-close');
                return;
            }
            this.showError = false;
            this.showVerifyValidation = true;
        },
        onVerifyError(payload: { isLastAttempt; remainingAttempts; requestError }): void {
            this.showVerifyValidation = false;
            if (payload.remainingAttempts === 0) {
                this.$emit('on-close');
                return;
            }
            this.remainingAttempts = payload.remainingAttempts;
            this.isLastAttempt = payload.isLastAttempt;
            this.requestError = payload.requestError;
            this.showError = true;
        },
        onClose(): void {
            this.$emit('on-close');
        },
    },
    computed: {
        ...mapGetters({ baseConfiguration: 'user/baseConfiguration' }),
        initFlow(): boolean {
            return !this.showBenefits && !this.showError && !this.showVerifyValidation && !this.validationSuccessful;
        },
    },
    mounted() {
        this.remainingAttempts = this.baseConfiguration?.cardValidationAttempts;
    },
};
</script>
