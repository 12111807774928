import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71783940"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "referred" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_referals = _resolveComponent("referals")!
  const _component_sharable_information = _resolveComponent("sharable-information")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_referals, { referals: $data.referals }, null, 8, ["referals"]),
    _createVNode(_component_sharable_information, { referals: $data.referals }, null, 8, ["referals"])
  ]))
}