class InteractiveModel {
    id: number;
    choice: boolean;
    available: boolean;
    text: string;
    textColor: string;
    textBackgroundColor: string;
    isCorrect: boolean;
    totalChoices: number;
    totalGlobalChoices: number;
    percentageChoices: string;
    endDate: Date;
    selectChoice: number;
    feelingBar: number;

    constructor(
        id: number,
        choice: boolean,
        available: boolean,
        text: string,
        textColor: string,
        textBackgroundColor: string,
        isCorrect: boolean,
        totalChoices: number,
        totalGlobalChoices: number,
        percentageChoices: string,
        endDate: Date,
        selectChoice: number,
        feelingBar: number,
    ) {
        this.id = id;
        this.choice = choice;
        this.available = available;
        this.text = text;
        this.textColor = textColor;
        this.textBackgroundColor = textBackgroundColor;
        this.isCorrect = isCorrect;
        this.totalChoices = totalChoices;
        this.totalGlobalChoices = totalGlobalChoices;
        this.percentageChoices = percentageChoices;
        this.endDate = endDate;
        this.selectChoice = selectChoice;
        this.feelingBar = feelingBar;
    }
}

const toInteractiveModel = (data: object) => {
    return data
        ? new InteractiveModel(
              data['id'],
              data['choice'] ?? false,
              data['available'] ?? false,
              data['text'] ?? '',
              data['text_color'] ?? '',
              data['text_background_color'] ?? '',
              data['is_correct'] ?? false,
              data['total_choices'],
              data['total_global_choices'],
              data['percentage_choices'] ?? '',
              new Date(data['end_date']),
              data['select_choice'],
              data['feeling_bar'],
          )
        : null;
};

export { InteractiveModel, toInteractiveModel };
