<script lang="ts" setup>
import { CategoryModel, NewCategoryModel } from '@/models/product/CategoryModel';
import { useProductFetcher } from '@/views/seeMore/useProductFetcher';
import { PropType, ref } from 'vue';
import { Sections } from '@/enums/sectionsOrigin';
import { logger } from '@/utils/logger';
import { ProductModel } from '@/models/product/ProductModel';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { useProductStore } from '@/store/useProductStore';
import { useApp } from '@/composables/useApp';
import CarouselForProducts from '@/components/carousels/CarouselForProducts.vue';
import { RequestState } from '@/modules/core/types/WorkingState';

const props = defineProps({
    section: {
        type: String as PropType<keyof typeof Sections>,
        default: undefined,
    },
    showPromotionalBanner: {
        type: Boolean,
        default: true,
    },
    subcategory: {
        type: Object as PropType<CategoryModel>,
        required: true,
    },
    category: {
        type: Object as PropType<CategoryModel>,
        required: true,
    },
});
const { $store, $route } = useApp();
const { promotionPersonalized } = useProductStore();

const $emit = defineEmits<(e: 'go-to-subcategory') => void>();
const { retailerSelected } = useRetailer();
const { categorySelected, isDiscount: discounted } = useProductFetcher();
const products = ref<ProductModel[]>([]);
const stateRequest = ref<RequestState>('FIRST_LOAD');
const openSubcategory = (): void => {
    $store.commit('categories/setSubcategorySelected', {
        subcategory: new NewCategoryModel({
            id: props.subcategory.id,
            name: props.subcategory.name,
            request_subcategories: true,
            stockitems: products.value as ProductModel[],
        }),
    });
    $emit('go-to-subcategory');
    $store.dispatch('segment/subcategoryViewed', {
        category: {
            category: categorySelected.value.name,
            category_id: categorySelected.value.id,
        },
        subcategory: {
            subcategory: props.subcategory.id,
            subcategory_id: props.subcategory.name,
        },
        origin: {
            screen: $route.path,
            section: props.section,
        },
    });
};

const fetchSubcategoriesById = async (): Promise<void> => {
    try {
        stateRequest.value = 'FIRST_LOAD';
        if (!discounted.value) {
            products.value = await $store.dispatch('categories/subcategoriesProducts', {
                retailerId: retailerSelected.value.id,
                categoryId: props.category.id,
                subcategoryId: props.subcategory.id,
                size: 15,
            });
            return;
        }
        const { results } = await promotionPersonalized({
            retailerId: retailerSelected.value.id,
            page: 1,
            category: props.subcategory.id,
        });
        products.value = results;
    } catch (err) {
        logger('FETCH_SUBCATEGORIES_BY_ID', err);
        stateRequest.value = 'ERROR';
    } finally {
        stateRequest.value = 'LOAD-ENDED';
    }
};

await fetchSubcategoriesById();
</script>

<template>
    <CarouselForProducts
        :items="products"
        :section="`${section}_${category?.name?.toUpperCase()}`"
        :show-load-more-products-card="products?.length >= 15"
        :state="stateRequest"
        :subheader-name="subcategory.name"
        has-subheader-padding
        show-subheader-btn
        @on-click-subheader="openSubcategory"
    />
</template>
