import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.menu'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 150 119",
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M150 106.822C150 113.152 145.962 118.691 141.346 118.691H8.65385C4.03846 118.691 0 113.152 0 106.822C0 100.491 4.03846 94.9521 8.65385 94.9521H141.346C145.962 94.9521 150 100.491 150 106.822Z",
        fill: $props.color
      }, null, 8, _hoisted_1),
      _createElementVNode("path", {
        d: "M126.923 59.3451C126.923 65.6753 122.885 71.2142 118.269 71.2142H8.65385C4.03846 71.2142 0 65.6753 0 59.3451C0 53.015 4.03846 47.4761 8.65385 47.4761H118.269C122.885 47.4761 126.923 53.015 126.923 59.3451Z",
        fill: $props.color
      }, null, 8, _hoisted_2),
      _createElementVNode("path", {
        d: "M150 11.8691C150 18.1992 145.962 23.7381 141.346 23.7381H8.65385C4.03846 23.7381 0 18.1992 0 11.8691C0 5.5389 4.03846 0 8.65385 0H141.346C145.962 0 150 5.5389 150 11.8691Z",
        fill: $props.color
      }, null, 8, _hoisted_3)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "iconColor"]))
}