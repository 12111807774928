<template>
    <base-icon
        :icon-name="$t('icon.default_card')"
        :width="width"
        :height="height"
        viewBox="0 0 14 14"
        :iconColor="color"
    >
        <circle cx="7" cy="7" r="6.5" stroke="#FF9012" />
        <path
            d="M7.56153 3.65234H4.86914V10.348H6.08445V8.41582H7.56153C9.28167 8.41582 10.3474 7.50713 10.3474 6.03408C10.3474 4.55147 9.28167 3.65234 7.56153 3.65234ZM7.50544 7.36365H6.08445V4.70452H7.50544C8.57117 4.70452 9.12274 5.19234 9.12274 6.03408C9.12274 6.87582 8.57117 7.36365 7.50544 7.36365Z"
            fill="#FF9012"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-as-default',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 17 * this.size;
        },
        height(): number {
            return 17 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
