import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b932f2da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "online-bank-services__alert-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_subheader_price = _resolveComponent("subheader-price")!
  const _component_payment_methods = _resolveComponent("payment-methods")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_section_footer = _resolveComponent("section-footer")!
  const _component_general_alert = _resolveComponent("general-alert")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_base_card, {
      class: "payments flex-column",
      padding: "0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_section_header, {
          class: "payments__header",
          title: _ctx.$t('general.payment-methods'),
          "with-borders": !$options.isMobile,
          "bg-color": "#FFF",
          "btn-back-color": "#FF9012",
          "color-title": "#FF9012",
          "has-back": "",
          onOnBack: $options.onBack
        }, null, 8, ["title", "with-borders", "onOnBack"]),
        _createElementVNode("section", {
          class: _normalizeClass(["payments-container", { 'show-one-payment': $options.showPaymentOnMobile }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["payments-container__options", { 'no-show-options': $options.showPaymentOnMobile }])
          }, [
            _createVNode(_component_subheader_price, {
              class: "checkout-payments__price",
              "total-to-pay": _ctx.cart?.totalDetails?.totalCost
            }, null, 8, ["total-to-pay"]),
            _createVNode(_component_payment_methods, {
              "option-selected": $data.optionSelected,
              payments: $data.options,
              "total-to-pay": _ctx.cart?.totalDetails?.totalCost
            }, null, 8, ["option-selected", "payments", "total-to-pay"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["payments-container__divider", { 'payments-container__divider--hidden': $options.showPaymentOnMobile }])
          }, null, 2),
          _createVNode(_component_router_view)
        ], 2)
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_section_footer, { class: "payments__footer" }, {
        default: _withCtx(() => [
          _createVNode(_component_btn_out_line, {
            "is-disabled": !$options.canContinue,
            txt: _ctx.$t('cta.confirm'),
            onClick: $options.checkPaymentAlert
          }, null, 8, ["is-disabled", "txt", "onClick"])
        ]),
        _: 1
      })
    ])),
    ($data.showWireTransferAlert)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 0,
          message: _ctx.$t('txt.transfer__once-your-order'),
          title: _ctx.$t('txt.my-order__wire-transfer'),
          "assets-u-r-l": "svg/order_state/ic_validating_wire_transfer.svg",
          "only-confirmation-btn": "",
          onOnAccept: $options.onBack,
          onOnClose: _cache[0] || (_cache[0] = ($event: any) => ($data.showWireTransferAlert = false))
        }, null, 8, ["message", "title", "onOnAccept"]))
      : _createCommentVNode("", true),
    ($data.showOnlineBankServiceAlert)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 1,
          title: _ctx.$t('general.payment-online-bank-services'),
          "accent-header": "",
          "only-confirmation-btn": "",
          onOnAccept: $options.onBack,
          onOnClose: _cache[1] || (_cache[1] = ($event: any) => ($data.showOnlineBankServiceAlert = false))
        }, {
          content: _withCtx(() => [
            _createElementVNode("section", _hoisted_1, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('txt.online-bank-sevices__proccess-pyment-advisor')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('txt.online-bank-sevices__modify-schedule-msj')), 1)
            ])
          ]),
          _: 1
        }, 8, ["title", "onOnAccept"]))
      : _createCommentVNode("", true)
  ]))
}