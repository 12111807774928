<template>
    <base-card class="add-payphone">
        <div class="payphone-header">
            <icon-back :size="0.7" class="cursor-pointer" @click="$emit('on-back')" />
            <h2 class="payphone-header__title">Payphone</h2>
            <div></div>
        </div>
        <p class="add-payphone__subtitle">
            {{ `${$t("txt['add-payphone__the-total-of']")} ${amount} ${$t("txt['add-payphone__enter-your-number']")}` }}
        </p>
        <input-phone v-model="phone" class="payphone-input" />
        <p v-if="isWrongNumber" class="not-valid-input">{{ $t("txt['add-payphone__not-valid-number']") }}</p>
        <btn-solid :isDisabled="isDisabled" :isLoading="isLoading" :txt="$t('cta.add-number')" @click="addPayphone" />
    </base-card>
    <snack-bar
        :body="snackBarMessage"
        :isActive="showSnackBar"
        :isFailure="isFailure"
        :isSuccess="isSuccess"
        @on-snackbar-close="closeSnackBar"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { logger } from '@/utils/logger';

import BaseCard from '@/components/cards/BaseCard.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import IconBack from '@/components/icons/IconBack.vue';
import InputPhone from '@/components/inputs/primary/phone/InputPhone.vue';
import payphoneMixin from '@/mixings/payphoneMixin';

export default {
    name: 'add-payphone',
    mixins: [payphoneMixin],
    components: {
        BaseCard,
        BtnSolid,
        SnackBar,
        IconBack,
        InputPhone,
    },
    data() {
        return {
            phone: '',
            isLoading: false,
            keyPressed: false,
            showSnackBar: false,
            isSuccess: false,
            isFailure: false,
            snackBarMessage: '',
            isWrongNumber: false,
        };
    },
    props: {
        totalToPay: {
            type: [String, Number],
            required: true,
        },
    },
    emits: ['on-back'],
    methods: {
        async addPayphone(): Promise<void> {
            const validateNumber = /^[0-9+\\(\\)#\\.\s\\/ext-]+$/;

            if (!validateNumber.test(this.phone)) {
                this.isWrongNumber = true;
                return;
            }
            const exist = this.payphoneList.find(phone => phone.phone.trim() === this.phone.trim());
            if (exist) {
                this.snackBarInfo({
                    msg: this.$t('alerts.phone_number_exists'),
                    success: false,
                    failed: true,
                });
                return;
            }
            this.isWrongNumber = false;
            this.isLoading = true;
            try {
                await this.$store.dispatch('payments/addPayphone', this.phone);
                this.snackBarInfo({
                    msg: this.$t("txt['add-payphone__add-number-success']"),
                    success: true,
                    failed: false,
                });
                try {
                    await this.fetchPayphones();
                } catch (err) {
                    logger('ADD_PAYPHONE--GET_LIST', err);
                }
            } catch (err) {
                this.snackBarInfo({
                    msg: err.status == 400 ? err.data : this.$t('alerts.connection_error'),
                    success: false,
                    failed: true,
                });
                logger('ADD_PAYPHONE', err);
            } finally {
                this.isLoading = false;
            }
        },
        snackBarInfo(data: object): void {
            this.showSnackBar = true;
            this.isSuccess = data['success'];
            this.isFailure = data['failed'];
            this.snackBarMessage = data['msg'];
        },
        closeSnackBar(): void {
            this.showSnackBar = false;
            if (this.isSuccess) {
                this.$emit('on-back');
            }
        },
    },
    computed: {
        ///TODO: CHECK HOW TO SEND THIS VALUE DYNAMICALLY
        ...mapGetters({
            cart: 'cart/cart',
            countrySelected: 'external/countrySelected',
            isTiptiCardCheckout: 'tiptiCard/isTiptiCardCheckout',
            payphoneList: 'payments/payphoneList',
        }),
        currency(): string {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        amount(): string {
            return `${this.currency} ${Number(this.totalToPay)?.toFixed(2)}`;
        },
        isDisabled(): boolean {
            return this.phone.length === 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './add-payphone';
</style>
