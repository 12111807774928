import { RetailerPercentageModel, toRetailerPercentageModel } from './RetailerPercentageModel';
import { TaxModel, toTaxModel } from './TaxModel';

class TotalDetailsModel {
    noTaxableSubtotal: number;
    totalDiscount: number;
    taxableSubtotal: number;
    totalCost: number;
    retailersPercentage: Array<RetailerPercentageModel>;
    retailersPercentageTotal: number;
    subtotalTaxes: Array<TaxModel>;
    subtotalTaxesTotal: number;
    taxes: Array<TaxModel>;
    taxesTotal: number;
    serviceTax: number;
    orderCost: number;
    shippingCost: number;
    taxName: string;

    constructor(
        noTaxableSubtotal?: number,
        totalDiscount?: number,
        taxableSubtotal?: number,
        totalCost?: number,
        retailersPercentage?: Array<RetailerPercentageModel>,
        retailersPercentageTotal?: number,
        taxName?: string,
        taxes?: Array<TaxModel>,
        taxesTotal?: number,
        subtotalTaxes?: Array<TaxModel>,
        subtotalTaxesTotal?: number,
        serviceTax?: number,
        giftCardAmount?: number,
        shippingCost?: number,
    ) {
        this.noTaxableSubtotal = noTaxableSubtotal;
        this.totalDiscount = totalDiscount;
        this.taxes = taxes;
        this.taxesTotal = taxesTotal;
        this.subtotalTaxes = subtotalTaxes;
        this.subtotalTaxesTotal = subtotalTaxesTotal;
        this.taxableSubtotal = taxableSubtotal;
        this.retailersPercentage = retailersPercentage;
        this.retailersPercentageTotal = retailersPercentageTotal;
        this.serviceTax = serviceTax;
        this.taxName = taxName;
        // Toshow order value whidt hout gift card
        this.orderCost = totalCost + (shippingCost ?? 0);
        this.shippingCost = shippingCost;

        const totalCostOperationResult = +(totalCost + (shippingCost ?? 0) - (giftCardAmount ?? 0)).toFixed(2);
        this.totalCost = totalCostOperationResult < 0 ? 0 : totalCostOperationResult;
    }
}

const serviceTax = (data: object): number => {
    const serviceTax = data['taxes']['taxes_values']?.find((tax) => tax['shipping_service']);
    return serviceTax ? +serviceTax['percent'] / 100 : 0;
};

const toTotalDetailModel = (data: object): TotalDetailsModel => {
    return data
        ? new TotalDetailsModel(
              data['not_taxable_subtotal'] ?? 0,
              data['total_discount'] ?? 0,
              data['taxable_subtotal'] ?? 0,
              data['total_cost'] ?? 0,
              data['retailers_percentage'].map((retailer: RetailerPercentageModel) =>
                  toRetailerPercentageModel(retailer),
              ),
              data['retailers_percentage_total'] ?? 0,
              data['taxes']['taxes_values']?.length ? data['taxes']['taxes_values'][0]['name'].split(' ')[0] : '',
              data['taxes']['taxes_values']
                  ?.map((tax) => toTaxModel(tax))
                  ?.filter((tax: TaxModel) => tax.percent > 0 && tax.value > 0) ?? [],
              data['taxes']['taxes_values']?.map((tax) => tax['value'])?.reduce((acc, actual) => actual + acc),
              data['taxes']['sub_total_values']
                  ?.map((tax) => toTaxModel(tax))
                  ?.filter((tax: TaxModel) => tax.value > 0) ?? [],
              data['taxes']['sub_total_values']?.map((tax) => tax['value'])?.reduce((acc, actual) => actual + acc),
              serviceTax(data),
              data['gift_card_amount'] ?? 0,
              data['shipping_cost'] ?? 0,
          )
        : null;
};

export { TotalDetailsModel, toTotalDetailModel, serviceTax };
