<template>
    <section
        class="header-main-mobile header-container-mobile"
        :class="{
            'header-main-mobile--in-retailer': isHomePage,
            'header-main-mobile--in-retailer-search': isHomePage && openSearch,
            'header-main-mobile--search': !isHomePage && openSearch,
        }"
    >
        <div class="header-icon">
            <icon-menu-hamburger :size="0.15" @click="$emit('show-menu')" />
        </div>
        <div v-if="isHomePage && !openSearch"></div>
        <the-address-btn v-if="isHomePage && !openSearch" />
        <div v-if="!isHomePage && !openSearch"></div>
        <div>
            <search-products
                v-if="openSearch"
                :is-loading="false"
                :origin-section="originSection"
                @on-search="onSearch"
                @on-blur="openSearch = false"
            />
            <div v-else class="header-icon">
                <icon-search :size="0.12" @click="openSearch = true" />
                <p>{{ $t("cta['search']").toUpperCase() }}</p>
            </div>
        </div>
        <div v-if="!isHomePage" class="header-icon">
            <icon-coupons-mobile
                :size="1.35"
                color="#707070"
                @click="$router.push({ name: appRoutesMap.coupons.index })"
            />
            <p>{{ $t("cta['coupons']").toUpperCase() }}</p>
        </div>
        <div class="header-icon">
            <div v-if="products.length > 0" class="the-cart-batch">
                <p class="the-cart-btn__amount">{{ products.length }}</p>
            </div>
            <icon-cart v-if="isMainCart" :size="0.9" color="#707070" @click="activateCart" />
            <icon-add-cart v-else :size="0.9" color="#707070" @click="activateCart" />
        </div>
    </section>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import IconCart from '@/components/icons/IconCart.vue';
import IconMenuHamburger from '@/components/icons/IconMenuHamburger.vue';
import IconSearch from '@/components/icons/IconSearch.vue';
import SearchProducts from '@/components/inputs/searchProducts/SearchProducts.vue';
import IconCouponsMobile from '@/components/icons/IconCouponsMobile.vue';
import TheAddressBtn from '@/components/buttons/TheAddressBtn.vue';
import { Sections } from '@/enums/sectionsOrigin';
import IconAddCart from '@/components/icons/IconAddCart.vue';
import { useUser } from '@/composables/useUser';
import { useCart } from '@/composables/useCart';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { appRoutesMap } from '@/router/appRoutesMap';

const $store = useStore();
const $router = useRouter();
const $route = useRoute();
const { isAuth, requireAuth } = useUser();
const { isMainCart: cartMain, products, showCart } = useCart();
const $emit = defineEmits<(e: 'show-menu') => void>();
const openSearch = ref(false);

const isHomePage = computed(() => $route.name === appRoutesMap.home);
const originSection = computed(() => (isHomePage.value ? Sections.RETAILERS : Sections.HOMES_RETAILER));

const activateCart = () => {
    if (!isAuth.value) {
        requireAuth.value = true;
        return;
    }
    $store.commit('checkout/cleanTimeDelivery');
    $store.commit('checkout/resetStockItemTimeDelivery');
    showCart.value = !showCart.value;
};

const onSearch = (query: string): void => {
    $emit('show-menu');
    $router.replace({
        name: appRoutesMap.retail.search,
        params: {
            searchOn: query,
        },
    });
};
const isMainCart = computed(() => cartMain.value || isHomePage.value);
</script>
<style lang="scss" scoped>
@import './header-main-mobile.scss';
</style>
