<template>
    <section class="notifications">
        <section-header
            class="notifications-header"
            colorTitle="#707070"
            :title="$t('general.notifications')"
        ></section-header>
        <section class="notifications__body">
            <notifications-section></notifications-section>
            <tipti-news></tipti-news>
        </section>
    </section>
</template>

<script lang="ts">
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import NotificationsSection from './components/notificationsSection/NotificationsSection.vue';
import TiptiNews from './components/tiptiNews/TiptiNews.vue';

export default {
    name: 'notifications',
    components: {
        SectionHeader,
        NotificationsSection,
        TiptiNews,
    },
};
</script>
<style lang="scss" scoped>
@import './notifications.scss';
</style>
