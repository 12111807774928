export class CreditCardBrandModel {
    readonly coverPicture: string;
    readonly textColor: string;
    readonly franchiseLogo: string;
    readonly backgroundColor: string;
    readonly bin: number;

    constructor(data: CreditCardBrandDto) {
        this.coverPicture = data?.cover_picture;
        this.textColor = data?.text_color;
        this.franchiseLogo = data?.franchise_logo;
        this.backgroundColor = data?.background_color;
        this.bin = data?.bin;
    }
}

interface CreditCardBrandDto {
    cover_picture: string;
    text_color: string;
    franchise_logo: string;
    background_color: string;
    bin: number;
}
