import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from '@/store';
import { logger } from '@/utils/logger';
import { WalletMetaTypes } from '@/views/wallet/WalletRoutes';

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition;
        return { left: 0, top: 0, behavior: 'smooth' };
    },
});

declare module 'vue-router' {
    interface RouteMeta {
        require_retailer?: boolean;
        fetch_tipti_tc?: boolean;
        show_notification_rate?: boolean;
        show_order_to_rate?: boolean;
        use_custom_header?: boolean;
        use_custom_footer?: boolean;
        require_auth?: boolean;
        show_router_view?: boolean;
        send_gift?: boolean;
        wallet_type?: WalletMetaTypes;
        breadcrumbs?: string[];
        is_detail_product?: boolean;
        preserve_banner?: boolean;
        banner_type?: 'products' | 'discount' | 'category' | 'promotions';
        story_type?: 'products' | 'discount' | 'category';
        subsection?: 'list' | 'address' | 'payment';
        isCategoryList?: boolean;
        isInOrderPage?: boolean;
        show_recommended_products?: boolean;
    }
}

router.beforeResolve(async (to, from, next) => {
    store.commit('app/currentRoutePath', to.fullPath);
    if (to.meta.require_auth && !store.getters['user/isAuth']) {
        store.commit('user/requireAuth', true);
        return next({ path: '/', replace: true });
    }
    if (to.meta.story_type) store.commit('promotions/bannerSelected');
    if (to.meta.banner_type) store.commit('stories/selectedStory');
    if (!to.meta.banner_type && !to.meta.story_type && !to.meta.is_detail_product && !to.meta.preserve_banner) {
        store.commit('promotions/bannerSelected');
        store.commit('stories/selectedStory');
    }
    next();
});

router.afterEach((to, from, failure) => {
    if (failure) logger('navigation failure', { to, from, failure });
});

export default router;
