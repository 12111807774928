export const appRoutesMap = Object.freeze({
    home: 'HomePage',
    product: 'ProductPage',
    faq: 'FaqPage',
    deeplink: 'DeeplinkPage',
    news: {
        home: 'NewsPage',
        detail: 'NewsDetailPage',
    },
    retail: {
        home: 'RetailPage',
        category: 'RetailCategoryPage',
        subcategory: 'RetailSubcategoryPage',
        seeMore: 'RetailSeeMorePage',
        search: 'RetailSearchPage',
        allCategories: 'AllCategories',
        baseCategoryView: 'BaseCategoryViewPage',
        categorySwitcher: 'CategorySwitcherPage',
        indexCategoryFeaturedProducts: 'MainCategoryFeaturedProducts',
        indexCategorySeeMore: 'MainCategorySeeMore',
        indexCategoryProducts: 'MainCategoryProducts',
        storyProducts: 'story-products',
        storyDiscounts: 'story-discounts',
        storyCategories: 'story-categories',
        seeMoreSuggestions: 'SeeMoreSuggestionsPage',
    },
    becomeRetail: 'BecameRetailPage',
    account: {
        profile: 'ProfilePage',
        faqs: 'FaqsPage',
        statistics: 'StatisticsPage',
        editProfile: 'EditProfilePage',
        addressesProfile: 'AddressesProfile',
        billsProfile: 'BillsProfile',
        creditCardsProfile: 'CreditCardsProfile',
        shareData: 'ShareData',
        billsAndDocuments: 'BillsAndDocuments',
        notifications: 'Notifications',
        referred: 'Referred',
        subscriptions: 'Subscriptions',
        settings: 'Settings',
        tiptiPrime: 'TiptiPrime',
    },
    promotions: {
        home: 'PromotionsPage',
        categories: 'PromotionsCategoriesPage',
        products: 'PromotionsProductsPage',
    },
    retailersInTipti: 'RetailersInTiptiPage',
    about: 'AboutTiptiPage',
    privacyData: {
        policies: 'PoliciesDataProtectionPage',
        index: 'PrivacyPolicyPag',
        personalData: 'PersonalDataProtectionPage',
    },
    list: {
        myLists: 'MyListsPage',
        listCategories: 'ListCategoriesPage',
        listCategoryWithSubcategory: 'ListCategoryWithSubcategoryPage',
        listCategoryProducts: 'ListCategoryProductsPage',
        listCategorySeeMore: 'ListCategorySeeMorePage',
        listCategoryFeaturedProducts: 'ListCategoryFeaturedProductsPage',
        personalLists: 'PersonalLists',
        recipeDetail: 'recipeDetail',
        listPage: 'listPage',
        schedulePage: 'schedulePage',
        recipesPage: 'recipesPage',
        recipesSubGroupPage: 'recipesSubGroupPage',
    },
    categoryProductsInBanner: 'CategoryProductsInBanner',
    privacyPolicyShopper: 'PrivacyPolicyShopperPage',
    tiptiRecipes: 'TiptiRecipes',
    pageNotFound: 'PageNotFound',
    productNotFound: 'ProductNotFound',
    reward: {
        programs: 'programs',
        loyaltyAndReward: 'LoyaltyAndReward',
        benefitScreen: 'BenefitScreen',
        placeholderScreen: 'PlaceholderScreen',
        validationScreen: 'ValidationScreen',
        registrationScreen: 'RegistrationScreen',
        showCard: 'showCard',
    },
    coupons: {
        couponsAndPromotions: 'CouponsAndPromotionsPage',
        index: 'CouponsPage',
        promotions: 'CouponsPromotionsPage',
    },
    checkout: {
        index: 'checkout',
        summary: 'SummaryCheckout',
        addresses: 'AddressesCheckout',
        schedules: 'Schedules',
        hours: 'Hours',
        bills: 'BillsCheckout',
        payments: {
            index: 'PaymentsCheckout',
            tiptiCard: 'TiptiCardPayment',
            cash: 'CashPayment',
            bodyCredit: 'BodyCreditCards',
            payphone: 'Payphone',
            wireTransfer: 'WireTransferPayment',
            institutional: 'InstitutionalPayment',
            onlineBankServices: 'OnlineBankServicesPayment',
            nequi: 'NequiPayment',
            yappy: 'YappyPayment',
            extraItemsPayment: 'extra-items-payment',
        },
    },
    paymentResent: {
        resend: 'resend-payment',
        tiptiCard: 'TiptiCardPayment-resent',
        bodyCredit: 'BodyCreditCards-resent',
        payphone: 'Payphone-resent',
        cash: 'CashPayment-resent',
        wireTransfer: 'CashWireTransfer-resent',
        institutional: 'InstitutionalPayment-resent',
    },
    Wallet: 'Wallet',
    WalletCard: 'wallet.card',
    tiptiCard: 'wallet.tipti-card',
    creditCard: 'wallet.credit-card',
    membership: 'wallet.membership',
    walletInstitution: 'wallet.institution',
    giftCardDetail: 'wallet.gift-card',
    giftCardSelectedRetailer: 'wallet.gift-card-selected-retailer',
    giftCardSelectedRetailerCategory: 'wallet.gift-card-selected-retailer-category',
    expiredGiftCards: 'wallet.expired-gift-cards',
    expiredGiftCardDetail: 'wallet.expired-gift-card-detail',
    tiptiCardRoutes: {
        history: 'tipti-card.history',
        recharge: 'tipti-card.recharge',
        gift: 'tipti-card.gift',
        myGifts: 'tipti-card.myGifts',
        transfer: 'tipti-card.transfer',
        maxiCard: 'tipti-card.maxiCard',
        payments: 'tipti-card.payments',
        paymentsRoutes: {
            creditCard: 'paymentsRoutes.creditCard',
            payphone: 'paymentsRoutes.payphone',
        },
        maxiCardRoutes: {
            maxiDollars: 'maxiCardRoutes.maxi-dollars',
            maxiCard: 'maxiCardRoutes.maxi-card',
        },
    },
    OrdersPage: 'my-orders',
    OrderMap: {
        OrderPage: 'order',
        PlaceHolderPage: 'order-placeholder',
        SummaryPage: 'order-summary',
        CartTab: 'order-cart',
        StateTab: 'order-state',
        ChatTab: 'order-chat',
    },
    ficoaPage: 'catalog-ficoa',
});
