import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11af2df4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "credit-cards-profile__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_body_credit_card = _resolveComponent("body-credit-card")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    class: "flex-column",
    padding: "0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_section_header, {
        "has-back": $options.isMobile || $data.showBack,
        title: _ctx.$t('txt.profile-options__card-register'),
        onOnBack: $options.onBack
      }, null, 8, ["has-back", "title", "onOnBack"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_body_credit_card, {
          "need-to-back": $data.showBack,
          onShowCardAdded: $options.handleShowAddForm
        }, null, 8, ["need-to-back", "onShowCardAdded"])
      ])
    ]),
    _: 1
  }))
}