import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ec38056"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cancel-prime"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_general_alert = _resolveComponent("general-alert")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($options.showCancelPrime)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('txt.tipti-card__remember-that')), 1),
          _createVNode(_component_btn_out_line, {
            txt: _ctx.$t('cta.cancel-subscription'),
            "bg-color": "#FFF",
            color: "#C7C7C7",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.showPreActionAlert = true))
          }, null, 8, ["txt"])
        ]))
      : _createCommentVNode("", true),
    ($data.showPreActionAlert)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 1,
          "accept-button-txt": _ctx.$t('cta.yes-cancel'),
          "decline-button-txt": _ctx.$t('cta.no-return'),
          "is-disabled": $data.isLoading,
          "is-loading": $data.isLoading,
          message: _ctx.$t('txt.tipti-card__are-you-sure'),
          onOnAccept: _cache[1] || (_cache[1] = ($event: any) => ($data.isLoading ? null : $options.cancelSubscription())),
          onOnDecline: _cache[2] || (_cache[2] = ($event: any) => ($data.showPreActionAlert = false)),
          onOnClose: _cache[3] || (_cache[3] = ($event: any) => ($data.showPreActionAlert = false))
        }, null, 8, ["accept-button-txt", "decline-button-txt", "is-disabled", "is-loading", "message"]))
      : _createCommentVNode("", true),
    _createVNode(_component_snack_bar, {
      body: $options.alertMessage,
      "is-active": $data.showAlert,
      "is-failure": !$data.isSuccess,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[4] || (_cache[4] = ($event: any) => ($data.showAlert = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}