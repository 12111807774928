import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_bone = _resolveComponent("base-bone")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!

  return (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (counter) => {
    return _createElementVNode("section", {
      class: "programs-skeleton",
      key: counter
    }, [
      _createVNode(_component_base_bone, { class: "title-skeleton" }),
      _createVNode(_component_base_card_flat, {
        class: "container-skeleton",
        "border-color": "#fff",
        padding: "0"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (index) => {
            return _createVNode(_component_base_bone, {
              key: index,
              class: "program-skeleton",
              "is-show": true
            })
          }), 64))
        ]),
        _: 1
      })
    ])
  }), 64))
}