import { BannerModel, toBannerModel } from '@/models/promotions/banners/BannerModel';
import { RetailerModel, toRetailerModel } from '@/modules/retailers/models/RetailerModel';
import { toStoriesResultModel } from '@/models/stories/StoriesResultModel';
import { RecipeDetailModel } from '@/models/recipes/RecipeDetailModel';
import { logger } from '@/utils/logger';
import { ProductModel } from '@/models/product/ProductModel';
import { DeepLink } from '@/enums/deeplinks';
import { Sections } from '@/enums/sectionsOrigin';
import ProductsFlowModel from '@/models/deeplinks/ProductsFlowModel';
import router from '@/router';
import { assertUnreachable } from '@/modules/core/utils/assertioUnreachable';
import { NewCategoryModel } from '@/models/product/CategoryModel';
import { TiptiCardModel } from '@/models/tiptiCard/TiptiCardModel';
import { useCoupons } from '@/composables/useCoupons';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    async deeplinkLogic({ dispatch }, payload): Promise<void> {
        const type: DeepLink = payload.type;
        switch (type) {
            case DeepLink.retailers:
                return dispatch('retailerDeepLink', payload);
            case DeepLink.franchises:
                return dispatch('franchiseDeepLink', payload);
            case DeepLink.product:
                return dispatch('productDeepLink', payload);
            case DeepLink.onlyProduct:
                return dispatch('onlyProductDeepLink', payload);
            case DeepLink.promotions:
                return dispatch('promotionsDeepLink', payload);
            case DeepLink.tiptiCard:
                return dispatch('openCodigoTC', payload);
            case DeepLink.category:
                return dispatch('categoryDeepLink', payload);
            case DeepLink.screens:
                return dispatch('screensDeeplink', payload);
            case DeepLink.recipe:
                return dispatch('consultRecipes', payload);
            case DeepLink.onlyRecipe:
                return dispatch('consultRecipe', payload);
            case DeepLink.multipleRecipe:
                return dispatch('consultMultipleRecipe', payload);
            case DeepLink.codes:
                return dispatch('addCodeDeeplink', payload);
            case DeepLink.referredCode:
                return dispatch('addCodeDeeplink', payload);
            case DeepLink.productList:
                return dispatch('openList', payload);
            case DeepLink.banners:
                return dispatch('openBanner', payload);
            case DeepLink.bannersInSections:
                return dispatch('openBannersInSections', payload);
            case DeepLink.stories:
                return dispatch('stories', payload);
            case DeepLink.press_release:
                return router
                    .push({ name: appRoutesMap.news.detail, params: { id: payload.id, title: '' } })
                    .then(() => {})
                    .catch(() => {});
            default:
                return assertUnreachable(type);
        }
    },
    async handleTrail({ dispatch, getters, rootGetters, commit }): Promise<void> {
        /// ** before continue verify that there is a trail and user has already added an address
        if (!getters['deeplinkNoLoggedTrail']) return;
        if (!rootGetters['user/userLocation']) return;
        await dispatch('deeplinkLogic', getters['deeplinkNoLoggedTrail']);
        commit('deeplinkNoLoggedTrail', null);
    },
    showSnackBar(
        { commit },
        payload: {
            type: DeepLink;
            showModal?: boolean;
            isSuccess?: boolean;
        },
    ) {
        /// ** handle by default a sucessful snackbar
        if (!payload.type) return logger('TYPE IS REQUIRED TO SHOW SNACKBAR');
        commit('showModal', payload.showModal ?? true);
        commit('messageModal', payload.type);
        commit('successModal', payload.isSuccess ?? true);
    },
    async retailerDeepLink({ commit, rootGetters, dispatch }, payload) {
        try {
            await router.push({ name: appRoutesMap.home });
            const yourRetailers = rootGetters['retailers/yourRetailers'].retailers;
            const otherRetailers = rootGetters['retailers/otherRetailers'].retailers;
            const retailers = yourRetailers.concat(otherRetailers);
            const retailer = retailers.find((retail) => retail.id === +payload.retailerId);
            if (retailer) {
                commit('retailers/retailerSelected', retailer, { root: true });
                dispatch(
                    'segment/retailSelected',
                    {
                        screen: '/main-products',
                        section: 'DEEP_LINK',
                    },
                    { root: true },
                );
                return router.push({
                    name: appRoutesMap.retail.home,
                    params: {
                        retailer: retailer.name,
                    },
                });
            }
            commit('showModal', true);
            commit('messageModal', `${payload.type}-not-found`);
            commit('successModal', false);
        } catch (_) {
            router.push({ name: appRoutesMap.home });
            commit('showModal', true);
            commit('messageModal', payload.type);
            commit('successModal', false);
        }
    },
    async franchiseDeepLink({ commit, rootGetters, dispatch }, payload) {
        try {
            await router.push({ name: appRoutesMap.home });

            const url = `v2/deep-links/franchise-retailer-check?franchise_id=${payload.franchiseId}`;

            const { data } = await rootGetters['network/axios'].get(url);
            const responseRetailerId = data.retailer_id;

            const yourRetailers = rootGetters['retailers/yourRetailers'].retailers;
            const otherRetailers = rootGetters['retailers/otherRetailers'].retailers;
            const retailers = yourRetailers.concat(otherRetailers);
            const retailerFound = retailers.find((retailer) => retailer.id == responseRetailerId);

            if (!retailerFound) throw 'retailer not found, force exception';

            commit('retailers/retailerSelected', retailerFound, { root: true });
            commit('isFromDeeplink', true);
            dispatch(
                'segment/retailSelected',
                {
                    screen: '/main-products',
                    section: 'DEEP_LINK',
                },
                { root: true },
            );
            router.push({
                name: appRoutesMap.retail.home,
                params: {
                    retailer: retailerFound.name,
                },
            });
        } catch (_) {
            router.push({ name: appRoutesMap.home });
            commit('showRetailerNotFoundModal', true);
            commit('successModal', false);
        }
    },
    async productDeepLink({ rootGetters, commit, dispatch }, payload: { idDeepLink: string; id: number }) {
        try {
            const { data } = await rootGetters['network/axios'].get(
                `v2/deep-link/${payload.idDeepLink}/product/${payload.id}`,
            );
            if (!data?.retailers?.length) return dispatch('showSnackBar', { type: DeepLink.product, isSuccess: false });
            /// *** handle two scenarios
            /// * -> with multiple retailers to select
            /// * -> only one retailer option
            /// on the later one, the user should visualize directly the product from that retailer

            /// ** Multiple retailers
            if (data.retailers.length > 1)
                return commit(
                    'productsFlow',
                    new ProductsFlowModel(
                        false,
                        data?.retailers?.map((retailer) => toRetailerModel(retailer) ?? []),
                        payload.id,
                        undefined,
                    ),
                );

            /// ** Only one retailer
            const _retailer = toRetailerModel(data.retailers[0]);
            commit('retailers/retailerSelected', _retailer, { root: true });
            commit('isFromDeeplink', true);
            dispatch(
                'segment/retailSelected',
                {
                    screen: window.location.pathname,
                    section: 'DEEP_LINK',
                },
                { root: true },
            );
            if (!payload.id) {
                router.push({
                    name: appRoutesMap.retail.home,
                    params: {
                        retailer: _retailer.name,
                    },
                });
                return dispatch('showSnackBar', { type: DeepLink.product, isSuccess: false });
            }

            const [_, responsesProduct] = await Promise.allSettled([
                router.push({
                    name: appRoutesMap.retail.home,
                    params: {
                        retailer: _retailer.name,
                    },
                }),
                await dispatch(
                    'product/getProductFromProductId',
                    {
                        productId: payload.id,
                        retailerId: _retailer.id,
                    },
                    { root: true },
                ),
            ]);

            if (responsesProduct.status !== 'fulfilled' || !responsesProduct.value)
                return dispatch('showSnackBar', { type: DeepLink.product, isSuccess: false });

            const _product = responsesProduct.value;

            if (!_product.available) return dispatch('showSnackBar', { type: DeepLink.product, isSuccess: false });
            const localProduct = rootGetters['cart/products'].find(
                (product: ProductModel) => product?.id === payload.id,
            );
            _product.productQuantity = localProduct?.productQuantity ?? 0;
            await commit('product/productSelected', _product, { root: true });
            await router.push({
                name: appRoutesMap.product,
                params: {
                    name: _product.name,
                },
            });
        } catch (_) {
            dispatch('showSnackBar', { type: DeepLink.product, isSuccess: false });
        }
    },
    async onlyProductDeepLink(
        { rootGetters, commit, dispatch },
        payload: {
            idDeepLink: string;
            id: number;
        },
    ) {
        try {
            const { data } = await rootGetters['network/axios'].get(`v2/products/${payload.id}/retailers`);
            if (!data?.retailers?.length) return commit('showProductNotFound', data?.product);
            /// *** handle two scenarios
            /// * -> with multiple retailers to select
            /// * -> only one retailer option
            /// on the later one, the user should visualize directly the product from that retailer

            /// ** Multiple retailers
            if (data.retailers.length > 1)
                return commit(
                    'productsFlow',
                    new ProductsFlowModel(
                        false,
                        data?.retailers?.map((retailer) => toRetailerModel(retailer) ?? []),
                        payload.id,
                        undefined,
                        data?.product?.name,
                        data?.product?.cover[0],
                    ),
                );

            /// ** Only one retailer
            const _retailer = toRetailerModel(data.retailers[0]);
            commit('retailers/retailerSelected', _retailer, { root: true });
            dispatch(
                'segment/retailSelected',
                {
                    screen: window.location.pathname,
                    section: 'DEEP_LINK',
                },
                { root: true },
            );
            if (!payload.id) {
                router.push({
                    name: appRoutesMap.retail.home,
                    params: {
                        retailer: _retailer.name,
                    },
                });
                return dispatch('showSnackBar', { type: DeepLink.product, isSuccess: false });
            }

            const [_, responsesProduct] = await Promise.allSettled([
                router.push({
                    name: appRoutesMap.retail.home,
                    params: {
                        retailer: _retailer.name,
                    },
                }),
                await dispatch(
                    'product/getProductFromProductId',
                    {
                        productId: payload.id,
                        retailerId: _retailer.id,
                    },
                    { root: true },
                ),
            ]);

            if (responsesProduct.status !== 'fulfilled' || !responsesProduct.value)
                return dispatch('showSnackBar', { type: DeepLink.product, isSuccess: false });

            const _product = responsesProduct.value;
            if (!_product.available) return dispatch('showSnackBar', { type: DeepLink.product, isSuccess: false });
            const localProduct = rootGetters['cart/products'].find(
                (product: ProductModel) => product?.id === payload.id,
            );
            _product.productQuantity = localProduct?.productQuantity ?? 0;
            await commit('product/productSelected', _product, { root: true });
            await router.push({
                name: appRoutesMap.product,
                params: {
                    name: _product.name,
                },
            });
        } catch (_) {
            dispatch('showSnackBar', { type: DeepLink.product, isSuccess: false });
        }
    },
    async promotionsDeepLink({ commit, rootGetters }, payload) {
        ///* PAYLOAD OPTIONS:
        // promo: string -> promotionId
        router.push({ name: appRoutesMap.home });
        try {
            const url = `v2/deep-link/discount/${payload.promo}`;
            const { data } = await rootGetters['network/axios'].get(url);
            if (data?.discount_available ?? true)
                return commit(
                    'productsFlow',
                    new ProductsFlowModel(
                        false,
                        data?.retailers?.map((retailer) => toRetailerModel(retailer) ?? []),
                        undefined,
                        new BannerModel(
                            payload.promo,
                            '',
                            undefined,
                            '',
                            undefined,
                            undefined,
                            [],
                            'PROMOTION',
                            data.discount_name,
                            undefined,
                            data.discount_banner,
                            '',
                            '',
                            undefined,
                            undefined,
                            undefined,
                            false,
                            false,
                            undefined,
                            undefined,
                            undefined,
                            false,
                            '',
                            '',
                        ),
                    ),
                );

            commit('showModal', true);
            commit('messageModal', 'NO_AVAILABLE');
            commit('successModal', false);
        } catch (_) {
            commit('showModal', true);
            commit('messageModal', payload.type);
            commit('successModal', false);
        }
    },

    async categoryDeepLink(
        { rootGetters, commit, dispatch },
        payload: {
            type: string;
            retailerId: string;
            categoryId: string;
        },
    ) {
        router.push({ name: appRoutesMap.home });
        let retailerFound = false;
        const retailerSelected: RetailerModel = rootGetters['retailers/retailers'].find(
            (retailer: RetailerModel) => retailer.id === +payload.retailerId,
        );
        if (!retailerSelected) {
            commit('showModal', true);
            commit('messageModal', payload.type);
            commit('successModal', false);
        }
        retailerFound = true;
        commit('retailers/retailerSelected', retailerSelected, { root: true });
        dispatch('shareData/getRetailerPromotionsAndBenefits', null, { root: true });
        const { sub_categories } = await dispatch(
            'categories/subcategoriesCategoriesList',
            {
                retailerId: payload.retailerId,
                categoryId: payload.categoryId,
            },
            { root: true },
        );

        commit(
            'categories/setCategorySelected',
            new NewCategoryModel({
                id: +payload?.categoryId,
                name: 'deeplink',
                request_subcategories: sub_categories?.length > 0,
                isDeeplink: true,
            }),
            { root: true },
        );
        router.push({
            name: appRoutesMap.retail.home,
            params: {
                retailer: retailerSelected?.name ?? '',
            },
        });
    },
    async addCodeDeeplink({ commit, dispatch }, payload) {
        try {
            const { applyCode } = useCoupons();
            await applyCode(payload.code);
            commit('successModal', true);
        } catch (_) {
            commit('successModal', false);
        } finally {
            commit('showModal', true);
            commit('messageModal', payload.type);
            router.push({ name: appRoutesMap.home });
        }
    },

    async openCodigoTC({ commit, dispatch }, payload) {
        try {
            const [tiptiCardResponse] = await Promise.allSettled<[TiptiCardModel, null]>([
                dispatch('tiptiCard/codeRefillTiptiCard', payload.code, { root: true }),
                router.push({
                    name: appRoutesMap.tiptiCardRoutes.history,
                }),
            ]);
            if (tiptiCardResponse.status === 'rejected') throw new Error();
            commit('tiptiCard', tiptiCardResponse.value);
            commit('successModal', true);
        } catch (_) {
            commit('successModal', false);
        } finally {
            commit('showModal', true);
            commit('messageModal', payload.type);
        }
    },

    async consultRecipe({ commit, dispatch }, payload) {
        try {
            router.push({ name: appRoutesMap.list.personalLists });
            const recipeDetail: RecipeDetailModel = await dispatch('lists/getRecipeDetail', +payload.id, {
                root: true,
            });
            if (!recipeDetail?.products?.[0]?.retailer) throw 'NO_PRODUCTS_FOR_THAT_SECTOR';
            commit('retailers/retailerSelected', recipeDetail?.products?.[0]?.retailer, { root: true });
            commit('lists/recipeDetail', recipeDetail, { root: true });
            router.push({
                name: appRoutesMap.list.recipeDetail,
                query: {
                    section: Sections.DEEPLINKS,
                },
            });
        } catch (err) {
            commit('showModal', true);
            commit('messageModal', err === 'NO_PRODUCTS_FOR_THAT_SECTOR' ? 'recipe_wrong_sector' : payload.type);
            commit('successModal', false);
        }
    },
    async consultMultipleRecipe({ commit, dispatch }, payload): Promise<void> {
        try {
            await router.push({ name: appRoutesMap.list.personalLists });
            const { ids } = payload;
            const retailerIds: [string] = ids?.split(',');
            //*** Ensure only set one recipe
            let hasResult = false;
            //*** if error has the same length that retailersIds,
            // means the recipe is not available
            const errors = [];
            const promise = new Promise<RecipeDetailModel>((response, rej) => {
                retailerIds.forEach((id) => {
                    dispatch('lists/getRecipeDetail', Number(id), {
                        root: true,
                    })
                        .then((recipe: RecipeDetailModel) => {
                            if (!hasResult && recipe.products?.length) {
                                hasResult = Boolean(recipe.products?.length);
                                response(recipe);
                            }
                            if (!recipe.products?.length) {
                                errors.push('Empty');
                                if (errors.length === retailerIds.length) rej('Empty');
                            }
                        })
                        .catch((err) => {
                            errors.push(err);
                            if (errors.length === retailerIds.length) rej(err);
                        });
                });
            });
            const recipeDetail = await promise;
            if (!recipeDetail?.products?.[0]?.retailer) throw Error('NO_PRODUCTS_FOR_THIS_RECIPE');
            commit('retailers/retailerSelected', recipeDetail?.products?.[0]?.retailer, { root: true });
            commit('lists/recipeDetail', recipeDetail, { root: true });
            await router.push({
                name: appRoutesMap.list.recipeDetail,
                query: {
                    section: Sections.DEEPLINKS,
                },
            });
        } catch (err) {
            commit('showModal', true);
            commit('messageModal', err === 'NO_PRODUCTS_FOR_THAT_SECTOR' ? 'recipe_wrong_sector' : payload.type);
            commit('successModal', false);
        }
    },

    async consultRecipes({ commit, dispatch }, payload) {
        try {
            router.push({
                name: appRoutesMap.list.personalLists,
                query: {
                    section: payload.section ?? Sections.DEEPLINKS,
                },
            });
            const adRecipe = await dispatch('lists/getRecipeFromAd', parseInt(payload.id), { root: true });
            const recipeDetail: RecipeDetailModel = await dispatch('lists/getRecipeDetail', adRecipe['recipeId'], {
                root: true,
            });
            if (!recipeDetail?.products?.[0]?.retailer) throw 'NO_PRODUCTS_FOR_THAT_SECTOR';
            commit('retailers/retailerSelected', recipeDetail?.products?.[0]?.retailer, { root: true });
            commit('lists/recipeAds', adRecipe, { root: true });
            commit('lists/recipeDetail', recipeDetail, { root: true });

            router.push({
                name: appRoutesMap.list.recipeDetail,
                query: router.currentRoute.value.query,
            });
        } catch (err) {
            commit('showModal', true);
            commit('messageModal', err === 'NO_PRODUCTS_FOR_THAT_SECTOR' ? 'recipe_wrong_sector' : payload.type);
            commit('successModal', false);
        }
    },
    screensDeeplink(_, payload) {
        switch (payload.screen) {
            case 'tipti_prime':
                return router.push({ name: appRoutesMap.account.tiptiPrime });
            case 'subscription':
                return router.push({ name: appRoutesMap.account.subscriptions });
            default:
                return router.push({ name: appRoutesMap.home });
        }
    },
    async openList({ commit, dispatch }, payload) {
        try {
            router.push({ name: appRoutesMap.list.personalLists });
            const list = await dispatch('lists/getList', payload.id, { root: true });
            commit('lists/isFromDeeplink', true, { root: true });
            router.push({ name: appRoutesMap.list.listPage, query: { name: list.name, id: payload.id } });
        } catch (_) {
            commit('messageModal', payload.type);
            commit('showModal', true);
            commit('messageModal', payload.type);
            commit('successModal', false);
        }
    },
    async openBanner({ rootGetters, commit }, payload) {
        try {
            router.push({ name: appRoutesMap.home });
            const url = `v2/deep-link/${payload.idDeepLink}/banner/${payload.id}`;
            const { data } = await rootGetters['network/axios'].get(url);
            if (data?.discount_available ?? true) {
                commit('promotions/isBannerInSection', false, { root: true });
                return commit(
                    'productsFlow',
                    new ProductsFlowModel(
                        false,
                        data?.retailers?.map((retailer) => toRetailerModel(retailer) ?? []),
                        payload.id,
                        toBannerModel(data?.banner),
                    ),
                );
            }
            commit('showModal', true);
            commit('messageModal', 'NO_AVAILABLE');
            commit('successModal', false);
        } catch (_) {
            commit('showModal', true);
            commit('messageModal', payload.type);
            commit('successModal', false);
        }
    },
    async openBannersInSections({ rootGetters, commit }, payload) {
        try {
            router.push({ name: appRoutesMap.home });
            const url = `v2/deep-link/${payload.idDeepLink}/banner-in-sections/${payload.id}`;
            const { data } = await rootGetters['network/axios'].get(url);
            if (data?.discount_available ?? true) {
                commit('promotions/isBannerInSection', true, { root: true });
                return commit(
                    'productsFlow',
                    new ProductsFlowModel(
                        false,
                        data?.retailers?.map((retailer) => toRetailerModel(retailer) ?? []),
                        payload.id,
                        toBannerModel(data?.banner_in_sections),
                    ),
                );
            }
            commit('showModal', true);
            commit('messageModal', 'NO_AVAILABLE');
            commit('successModal', false);
        } catch (_) {
            commit('showModal', true);
            commit('messageModal', payload.type);
            commit('successModal', false);
        }
    },
    async stories({ rootGetters, commit }, payload) {
        try {
            router.push({ name: appRoutesMap.home });
            const url = `v2/deep-link/${payload.idDeepLink}/story/${payload.id}`;
            const { data } = await rootGetters['network/axios'].get(url);
            if (data?.discount_available ?? true) {
                commit('stories/stories', [toStoriesResultModel(data.story)], { root: true });
                commit('stories/selectedStory', toStoriesResultModel(data.story), { root: true });
                commit('stories/selectedIndex', 0, { root: true });
                return commit(
                    'productsFlow',
                    new ProductsFlowModel(
                        true,
                        data?.retailers?.map((retailer) => toRetailerModel(retailer) ?? []),
                        payload.id,
                        undefined,
                    ),
                );
            }
            commit('showModal', true);
            commit('messageModal', 'NO_AVAILABLE');
            commit('successModal', false);
        } catch (_) {
            commit('showModal', true);
            commit('messageModal', payload.type);
            commit('successModal', false);
        }
    },
};
