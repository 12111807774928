<template>
    <skeleteon-carousel v-if="!recipes" />
    <carousel-tipti
        v-else-if="recipes?.length"
        :isLastPage="isLastPage"
        :isLoading="isLoading"
        :length="recipes.length"
        @reached-end="$emit('reached-end')"
    >
        <div v-for="(recipe, index) in recipes" :key="index">
            <card-recipe-ads
                :cardSelected="cardSelected"
                :isLoading="isLoadingRecipe"
                :recipe="recipe"
                @on-selected-card="clickCard(recipe)"
            />
        </div>
    </carousel-tipti>
</template>

<script lang="ts">
import { AdModel } from '@/models/ads/AdModel';
import { Sections } from '@/enums/sectionsOrigin';
import CarouselTipti from './components/carouselTipti/CarouselTipti.vue';
import SkeleteonCarousel from '@/components/skeletons/skeletonCarousel/SkeletonCarousel.vue';
import CardRecipeAds from '../cards/cardRecipeAds/CardRecipeAds.vue';
import { mapGetters } from 'vuex';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'carousel-recipe-ads',
    emits: ['reached-end'],
    components: {
        CarouselTipti,
        SkeleteonCarousel,
        CardRecipeAds,
    },
    data() {
        return {
            isLoadingRecipe: false,
            cardSelected: undefined,
        };
    },
    props: {
        recipes: {
            required: true,
        },
        isLastPage: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            isAuth: 'user/isAuth',
        }),
    },
    methods: {
        async clickCard(adRecipe: AdModel): Promise<void> {
            if (!this.isAuth) return this.$store.commit('user/requireAuth', true);
            this.isLoadingRecipe = true;
            this.cardSelected = adRecipe.id;
            const _recipe = await this.$store.dispatch('lists/getRecipeDetail', adRecipe.recipeId);
            this.$store.commit('lists/activeOption', '');
            this.$store.commit('lists/recipeAds', adRecipe);
            this.$store.commit('lists/recipeDetail', _recipe);
            this.$router.push({
                name: appRoutesMap.list.recipeDetail,
                query: {
                    section: Sections.CAROUSELS,
                },
            });
            this.isLoadingRecipe = false;
        },
    },
};
</script>