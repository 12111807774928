import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_general_alert = _resolveComponent("general-alert")!
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createBlock(_component_general_alert, {
    "accent-header": true,
    "accept-button-txt": _ctx.$t('txt.tipti-card__use-balance'),
    "decline-button-txt": _ctx.$t('txt.tipti-card__accumulate-balance'),
    message: _ctx.$t('txt.tipti-card__available-balance'),
    "show-close-icon": true,
    title: _ctx.$t('txt.tipti-card__alert-title'),
    onOnAccept: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-use-balance'))),
    onOnDecline: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-accumulate'))),
    onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close')))
  }, {
    content: _withCtx(() => [
      _withDirectives(_createElementVNode("h1", null, null, 512), [
        [_directive_currency, _ctx.tiptiCard?.availableAmount?.toFixed(2)]
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('txt.tipti-card__do-you-wish')), 1)
    ]),
    _: 1
  }, 8, ["accept-button-txt", "decline-button-txt", "message", "title"]))
}