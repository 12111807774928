<template>
    <base-icon icon-name="facebook" :width="width" :height="height" viewBox="0 0 17 32" :iconColor="color">
        <path
            d="M10.8465 17.203H16.1195L16.9477 11.9099H10.8465V9.01674C10.8465 6.81817 11.5739 4.86825 13.6552 4.86825H16.9997V0.249654C16.4119 0.171134 15.169 0 12.8208 0C7.91661 0 5.04174 2.55895 5.04174 8.38958V11.9109H0V17.204H5.04072V31.7524C6.03908 31.8993 7.05069 32 8.08878 32C9.02704 32 9.94288 31.9154 10.8465 31.7946V17.203Z"
            fill="white"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-facebook-small',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 32 * this.size;
        },
        height(): number {
            return 32 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
