<template>
    <settings-tile :title="$t('txt.setting__language_change')">
        <template #title-icon>
            <icon-language :size="0.75" class="settings-tile-icon" />
        </template>
        <template #content>
            <p class="settings-parragraph">{{ $t('txt.setting__language_message') }}</p>
            <base-loading v-if="isLanguageLoading" />
            <section v-else class="settings-language">
                <div class="settings-language__options" v-for="language in availableLanguages" :key="language.code">
                    <div>
                        <p class="settings-language__options__option settings-parragraph">{{ language.txt }}</p>
                    </div>
                    <radio-normal
                        class="settings-language__options__radio"
                        radioGroup="language"
                        :value="language"
                        @on-check="updateSelectedLanguage"
                        :isSelected="language.code == languageSelected"
                    ></radio-normal>
                </div>
            </section>
        </template>
    </settings-tile>
    <snack-bar
        :isSuccess="isSuccess"
        :isFailure="isFailure"
        :body="snackBarMessage"
        :isActive="showSnackBar"
        @on-snackbar-close="showSnackBar = false"
    ></snack-bar>
</template>

<script lang="ts">
import { logger } from '@/utils/logger';
import { mapGetters } from 'vuex';

import RadioNormal from '@/components/radio/RadioNormal.vue';
import SettingsTile from '@/views/myAccount/views/settings/common/SettingsTile.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import IconLanguage from '@/components/icons/IconLanguage.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';

export default {
    name: 'settings-language',
    data() {
        return {
            isLanguageLoading: false,
            availableLanguages: [
                { txt: this.$t("general['spanish']"), code: 'ES' },
                { txt: this.$t("general['english']"), code: 'EN' },
            ],
            showSnackBar: false,
            isSuccess: false,
            isFailure: false,
            snackBarMessage: '',
        };
    },

    components: {
        SnackBar,
        BaseLoading,
        RadioNormal,
        SettingsTile,
        IconLanguage,
    },
    computed: {
        ...mapGetters({ profile: 'user/user' }),
        languageSelected(): string {
            return this.profile?.defaultLanguage;
        },
    },
    methods: {
        async updateSelectedLanguage(language: object): Promise<void> {
            try {
                this.isLanguageLoading = true;
                await this.$store.dispatch('user/setDefaultLanguage', language['code']);
                await this.$store.dispatch('user/getProfile');
                this.$router.go();
                this.showSnackBarToUser(true);
            } catch (err) {
                logger('UPDATE_SELECTED_VALUE', err);
                this.showSnackBarToUser(false);
            } finally {
                this.isLanguageLoading = false;
            }
        },
        showSnackBarToUser(success: boolean): void {
            this.isSuccess = success;
            this.isFailure = !success;
            this.snackBarMessage = success
                ? this.$t("txt['setting__update-language-success']")
                : this.$t("general['error-to']", { message: this.$t("txt['setting__update-language']") });
            this.showSnackBar = true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.settings-parragraph {
    @include body2;
}
.settings-language {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: $margin-lg;
    @include phone {
        grid-template-columns: 1fr;
        row-gap: $margin-sm;
    }
    &__options {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: $margin-sm;
        @include phone {
            margin-top: $margin-lg;
            justify-content: space-around;
        }
        &__option {
            font-size: 20px;
        }
        &__radio {
            margin-left: $margin-lg * 3;
        }
    }
}

.settings-tile-icon {
    width: 7rem;
    @include tablet {
        width: 4rem;
    }
}
</style>
