<template>
    <div id="productsBody" class="products-body">
        <section
            v-if="showPromotionalBanner && !searchOn?.length"
            class="products-body__banners"
            :class="{ 'products-body__banners--full': bannersLength === 0 }"
        >
            <the-promotions-banner is-banner-in-section-prop @on-banners-fetch="onBannersFetch" />
        </section>
        <no-content-category v-if="!connectionStatus" />
        <div v-else>
            <featured-products
                v-if="canShowFeaturedProducts"
                :section-name="originSection"
                @go-to-featured-products="$emit('go-to-featured-products')"
            />
            <ThePaginator v-model="page" :total-pages="totalPages">
                <h2 class="category-products--title">{{ title }}</h2>
                <Suspense :key="paginatorKey">
                    <ProductGridFetcher
                        :page="page"
                        :section="originSection"
                        fetcher="CATEGORIES"
                        @pages="(value) => (totalPages = value)"
                    />
                    <template #fallback>
                        <skeleton-grid />
                    </template>
                </Suspense>
            </ThePaginator>
        </div>
    </div>
</template>

<script lang="ts" setup>
import FeaturedProducts from '../featuredProducts/FeaturedProducts.vue';
import NoContentCategory from '../noContentCategory/NoContentCategory.vue';
import { Sections } from '@/enums/sectionsOrigin';
import { computed, PropType, ref } from 'vue';
import SkeletonGrid from '@/components/skeletons/skeletonGrid/SkeletonGrid.vue';
import ThePaginator from '@/modules/core/components/paginatinos/ThePaginator.vue';
import ProductGridFetcher from '@/components/product/productsGrid/ProductGridFetcher.vue';
import { usePagination } from '@/modules/core/components/paginatinos/usePagination';
import ThePromotionsBanner from '@/components/banners/thePromotionsBanner/ThePromotionsBanner.vue';
import { useApp } from '@/composables/useApp';

defineOptions({
    name: 'CategoryProducts',
});
defineProps({
    originSection: {
        type: String as PropType<keyof typeof Sections>,
        default: undefined,
    },
    showFeaturedProducts: {
        type: Boolean,
        default: true,
    },
    showPromotionalBanner: {
        type: Boolean,
        default: true,
    },
});
const { $t, $store } = useApp();
const { page, totalPages, searchOn, paginatorKey } = usePagination();
const bannersLength = ref(0);

const categorySelected = computed(() => $store.getters['categories/getCategorySelected']);
const subcategorySelected = computed(() => $store.getters['categories/getSubcategorySelected']);
const connectionStatus = computed(() => $store.getters['network/connectionStatus']);

const title = computed(() =>
    categorySelected.value?.hasSubcategories
        ? subcategorySelected.value?.name ?? $t('txt.subcategories__all_products')
        : $t('txt.subcategories__all_products'),
);
const canShowFeaturedProducts = computed(
    () => categorySelected.value?.id && !searchOn.value?.length && !subcategorySelected.value?.id,
);

const onBannersFetch = (length: number) => (bannersLength.value = length);
defineEmits<(e: 'go-to-featured-products') => void>();
</script>
<style lang="scss" scoped>
@import './category-products.scss';
</style>
