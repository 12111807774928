import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
    productsFlow: undefined,
    tiptiCardBalance: null, /// * To show message after success tipti card refill
    showModal: false,
    showRetailerNotFoundModal: false,
    successModal: false,
    errorModal: false,
    showStory: false,
    deeplinkNoLoggedTrail: undefined,
    deeplinkFullPath: undefined,
    productNotFound: null,
    isFromDeeplink: false,
    messageModal: '',
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
