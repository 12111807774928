import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/gif/success.gif'


const _withScopeId = n => (_pushScopeId("data-v-bc4ebc16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "model-success" }
const _hoisted_2 = ["alt"]
const _hoisted_3 = {
  key: 0,
  class: "model-success__title"
}
const _hoisted_4 = {
  key: 1,
  class: "model-success__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    hasClose: $props.hasClose,
    title: $props.title,
    hasHeader: $props.hasHeader,
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "model-success__img",
          src: _imports_0,
          alt: _ctx.$t('icon.success')
        }, null, 8, _hoisted_2),
        ($props.title.length && !$props.hasHeader)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString($props.title), 1))
          : _createCommentVNode("", true),
        ($props.body.length)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString($props.body), 1))
          : _createCommentVNode("", true),
        ($props.showAction && $props.ctaText.length)
          ? (_openBlock(), _createBlock(_component_btn_solid, {
              key: 2,
              class: "model-success__action",
              txt: $props.ctaText,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-action')))
            }, null, 8, ["txt"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["hasClose", "title", "hasHeader"]))
}