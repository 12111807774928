<template>
    <section class="binary">
        <header
            class="binary__title"
            :style="{
                color: selectedStory?.interactiveElement?.textColor ?? '#FFF',
                backgroundColor: selectedStory?.interactiveElement?.textBackgroundColor ?? '#606060',
            }"
        >
            <h2>{{ selectedStory?.interactiveElement.text.toUpperCase() }}</h2>
        </header>

        <div
            class="binary__options"
            :style="{
                'grid-template-columns': `${percentageOptionA}% ${percentageOptionB}%`,
                display: !hasAnswered
                    ? percentageOptionA === 100 || percentageOptionB === 100
                        ? 'flex'
                        : 'grid'
                    : 'grid',
            }"
        >
            <div
                v-for="(element, index) in selectedStory?.interactiveElement?.elements"
                :key="index"
                :style="{
                    backgroundColor: element.choice ? '#f7d93e' : element.textBackgroundColor ?? '#606060',
                    display: hasAnswered
                        ? selectedStory?.interactiveElement?.elements[index]?.percentageChoices === 0
                            ? 'none'
                            : 'flex'
                        : 'grid',
                    color: element.textColor ?? '#FFF',
                }"
                class="option cursor-pointer"
                @click="onSelectOption(element.id, index)"
            >
                <div class="option__answer">
                    <p>{{ element.text.toUpperCase() }}</p>
                    <p v-if="hasAnswered">
                        {{ index === 0 ? `${percentageOptionA}%` : `${percentageOptionB}%` }}
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { logger } from '@/utils/logger';
import { computed, ref, watch } from 'vue';
import { StoriesResultModel } from '@/models/stories/StoriesResultModel';
import { usePromotion } from '@/composables/usePromotion';

const $store = useStore();
const percentageOptionA = ref(50);
const percentageOptionB = ref(50);
const localHasAnswered = ref(false);
const { track } = usePromotion();
const selectedStory = computed<StoriesResultModel>(() => $store.getters['stories/selectedStory']);

const hasAnswered = computed(
    () => localHasAnswered.value || selectedStory.value.interactiveElement?.elements?.some((element) => element.choice),
);

const loadPercentage = (): void => {
    percentageOptionA.value = hasAnswered.value
        ? parseInt(selectedStory.value?.interactiveElement?.elements[0]?.percentageChoices)
        : 50;
    percentageOptionB.value = hasAnswered.value
        ? parseInt(selectedStory.value?.interactiveElement?.elements[1]?.percentageChoices)
        : 50;
};
const onSelectOption = async (elementId: number, selectedIndex: number): Promise<void> => {
    try {
        if (hasAnswered.value) return;
        const percentages = [];
        const result = await track({
            id_object: selectedStory.value.id,
            resource_type: 'BINARY_TRACK',
            resource_action: 'BINARY_ANSWER',
            interactive_id: elementId,
        });
        result?.results.forEach((element) => {
            if (element.id === elementId) {
                percentages.push({
                    index: selectedIndex,
                    total: +element.total,
                    display: 'flex',
                });
                percentages.push({
                    index: selectedIndex == 0 ? 1 : 0,
                    total: 100 - +element.total,
                    display: 'none',
                });
            }
        });
        selectedStory.value?.interactiveElement?.elements?.forEach((element, index) => {
            if (index == selectedIndex) element.choice = true;
            element.percentageChoices = percentages?.find((response) => response.index === index).total;
        });
        localHasAnswered.value = true;
        percentageOptionA.value = percentages?.find((response) => +response.index === 0)?.total;
        percentageOptionB.value = percentages?.find((response) => +response.index === 1)?.total;
    } catch (err) {
        logger('BINARY_TRACKING', err);
    }
};

watch(
    () => selectedStory.value?.id,
    () => {
        loadPercentage();
    },
    {
        immediate: true,
    },
);
</script>
<style lang="scss" scoped>
@import '../../../../story-container.scss';
@import './binary.scss';
</style>
