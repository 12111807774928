import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_general_alert = _resolveComponent("general-alert")!

  return (_openBlock(), _createBlock(_component_general_alert, {
    message: _ctx.$t('txt.product-note__save-alert-content'),
    acceptButtonTxt: _ctx.$t('cta.btn-yes-save'),
    declineButtonTxt: _ctx.$t('cta.btn-not-discard'),
    onOnAccept: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-accept'))),
    onOnDecline: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-discard'))),
    onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close'))),
    accentHeader: true,
    title: _ctx.$t('txt.product-note__save-alert-title')
  }, null, 8, ["message", "acceptButtonTxt", "declineButtonTxt", "title"]))
}