import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e606ec6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "primes"
}
const _hoisted_2 = { class: "primes__content" }
const _hoisted_3 = { class: "primes__terms-and-conditions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_tipti_prime_detail = _resolveComponent("tipti-prime-detail")!
  const _component_tipti_prime_options = _resolveComponent("tipti-prime-options")!
  const _component_icon_afflicted_shop_bag = _resolveComponent("icon-afflicted-shop-bag")!
  const _component_no_content = _resolveComponent("no-content")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_section_footer = _resolveComponent("section-footer")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_view_terms_and_contions = _resolveComponent("view-terms-and-contions")!
  const _component_tipti_prime_payment = _resolveComponent("tipti-prime-payment")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_card, {
      class: "become-prime",
      padding: "0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_section_header, {
          "color-title": "#000",
          "has-back": "",
          title: "Tipti Prime",
          onOnBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
        }),
        ($data.isLoading)
          ? (_openBlock(), _createBlock(_component_base_loading, { key: 0 }))
          : (_ctx.primeDeals)
            ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_tipti_prime_detail),
                  _createVNode(_component_tipti_prime_options)
                ])
              ]))
            : (_openBlock(), _createBlock(_component_no_content, {
                key: 2,
                class: "cart__no-content",
                title: _ctx.$t('txt.prime__no_content')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_afflicted_shop_bag, {
                    size: 0.5,
                    color: "#C1C1C1"
                  })
                ]),
                _: 1
              }, 8, ["title"])),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.$t("txt['prime__accept-terms']")) + " ", 1),
            _createElementVNode("span", {
              class: "primes__terms-and-conditions--highlight cursor-pointer",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.view && $options.view(...args)))
            }, _toDisplayString(_ctx.$t("general['terms-and-conditions']")), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("txt['prime__accept-terms-final']")), 1)
          ]),
          _createVNode(_component_section_footer, { class: "prime-footer" }, {
            default: _withCtx(() => [
              _createVNode(_component_btn_solid, {
                "is-disabled": $data.isLoading,
                "is-loading": $data.isLoading,
                txt: _ctx.$t('cta.subscribe'),
                onClick: $options.showPayment
              }, null, 8, ["is-disabled", "is-loading", "txt", "onClick"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_view_terms_and_contions, {
      "only-view": $data.onlyView,
      "show-terms-and-conditions": $data.showTermsAndConditions,
      onSnackBarData: $options.snackData,
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showTermsAndConditions = false))
    }, null, 8, ["only-view", "show-terms-and-conditions", "onSnackBarData"]),
    ($data.showTiptiPayment)
      ? (_openBlock(), _createBlock(_component_tipti_prime_payment, {
          key: 0,
          "is-from-error": $data.isError,
          "snack-data": $data.snackInfo,
          onShowPdfTerms: $options.accept,
          onOnClose: _cache[3] || (_cache[3] = ($event: any) => ($data.showTiptiPayment = false))
        }, null, 8, ["is-from-error", "snack-data", "onShowPdfTerms"]))
      : _createCommentVNode("", true)
  ], 64))
}