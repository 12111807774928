<template>
    <base-icon :icon-name="$t('icon.unfold')" :width="width" :height="height" viewBox="0 0 13 9" :iconColor="color">
        <path d="M6.5 9L0.00480915 0.75L12.9952 0.750001L6.5 9Z" :fill="color ? color : white" />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-dropdown',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#fff',
        },
    },
    computed: {
        width(): number {
            return 13 * this.size;
        },
        height(): number {
            return 9 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
