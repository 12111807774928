<template>
    <template v-if="showOrders && !isLoading">
        <div class="orders-list-container--mobile">
            <BaseTopSheet v-model="showOrders" :height="height" @on-dismiss="onDismiss">
                <ListOrders :orders="orders" @on-hide-orders="showOrders = false" @on-height-orders="setHeight" />
            </BaseTopSheet>
        </div>
        <div class="orders-list-container">
            <ListOrders v-if="showOrders && !isLoading" :orders="orders" @on-hide-orders="showOrders = false" />
        </div>
    </template>
    <div v-if="!showOrders || isLoading" class="order-status cursor-pointer" @click="fetchActiveOrders(true)">
        <div class="icon-and-txt">
            <div class="icon-bags">
                <IconTwoBags :size="0.8" color="#ff9012" />
            </div>
            <div class="txt-status">
                <IconCircle class="order-state__circle" color="#CFDE00" size="25px" />
                <p class="txt-status__title">{{ $t("txt['order__status-some-orders']") }}</p>
                <div class="order-status__icon--mobile">
                    <BaseLoading v-if="isLoading && showOrders" :is-margin-auto="false" heigth=".5em" width=".5em" />
                    <IconArrow v-else class="icon-arrow" :size="0.6" color="#FF9012" />
                </div>
            </div>
            <BtnChat v-if="showIconMessage" :messages-unread="messagesUnreadCount" />
            <div v-else></div>
        </div>
        <div class="order-status__icon">
            <BaseLoading v-if="isLoading && showOrders" heigth="1em" width="1em" />
            <IconArrow v-else class="icon-arrow" :icon-name="$t('icon.show')" :size="0.75" color="#FF9012" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { logger } from '@/utils/logger';
import IconArrow from '@/components/icons/IconArrow.vue';
import IconTwoBags from '@/components/icons/IconTwoBags.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import ListOrders from '../listOrders/ListOrders.vue';
import BaseTopSheet from '@/components/topSheet/BaseTopSheet.vue';
import BtnChat from '@/components/buttons/BtnChat.vue';
import IconCircle from '@/components/icons/IconCircle.vue';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useChat } from '@/composables/useChat';
import { useOrder } from '@/composables/useOrder';
import { useApp } from '@/composables/useApp';
import { computed, ref, watchEffect } from 'vue';
import { useRoutes } from '@/composables/useRoutes';

const showOrders = ref(false);
const isLoading = ref(false);
const height = ref(0);
const { isMobile, $store, $route } = useApp();
const { isHomePage } = useRoutes();
const { isExtraItemCart } = useCartType();
const { loadChannelList, messagesUnreadCount, isConnected } = useChat();
const { orderRejected, searchOrderRejected, orders } = useOrder();

watchEffect(() => {
    if (!isConnected.value) return;
    if (!orders.value.length) return;
    const listOrders = orders.value?.map((order) => order.id.toString());
    loadChannelList(listOrders);
});
const showIconMessage = computed(() => messagesUnreadCount.value != 0);
const setHeight = (value: number) => {
    height.value = value;
};

const onDismiss = () => {
    if (!isMobile.value) return;
    showOrders.value = false;
};

const fetchActiveOrders = async (show = true): Promise<void> => {
    try {
        if (isExtraItemCart.value) return;
        isLoading.value = true;
        showOrders.value = show;
        const { results } = await $store.dispatch('orders/fetchOrders', {
            isActiveOrders: true,
            hideOrderToRate: !$route.meta.show_order_to_rate,
            findPaymentRejected: !showOrders.value,
            searchActiveOrder: true,
            setOrderForExtraItems: !isHomePage.value,
        });
        if (!results) return;
        if (!show) orderRejected.value = searchOrderRejected(results);
    } catch (err) {
        logger('FETCH_CURRENT_ORDERS', err);
    } finally {
        isLoading.value = false;
    }
};
fetchActiveOrders(false);
</script>

<style lang="scss" scoped>
@import './banner-orders.scss';
</style>
