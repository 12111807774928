import { useStore } from 'vuex';
import { CheckoutEventTypes } from '@/views/checkout/enums/CheckoutEventTypes';
import { CartRetailAnalyticInterface, CartRetailerModel } from '@/models/cart/CartRetailerModel';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useExtraItems } from '@/modules/extraItems/composables/useExtraItems';
import { useCart } from '@/composables/useCart';
import { computed } from 'vue';
import PaymentToShowModel, { PaymentToShowName } from '@/models/checkout/PaymentToShowModel';
import { assertUnreachable } from '@/modules/core/utils/assertioUnreachable';
import { OrderResumenModel } from '@/models/order/OrderResumen';

export const useCheckoutEvents = () => {
    const $store = useStore();
    const { isExtraItemCart } = useCartType();
    const mainCart = useCart();
    const extraItems = useExtraItems();

    const cart = computed(() => (isExtraItemCart.value ? extraItems.cart.value : mainCart.cart.value));
    const orderSelected = computed<OrderResumenModel>(() => $store.getters['orders/orderDetailSelected']);

    const getScreenEventType = (selection: PaymentToShowName): CheckoutEventTypes => {
        switch (selection) {
            case 'payment-cards':
                return CheckoutEventTypes.SelectCreditCardScreen;
            case 'payment-cash':
                return CheckoutEventTypes.SelectCashScreen;
            case 'payment-institutional':
                return CheckoutEventTypes.SelectInstitutionalPaymentScreen;
            case 'payment-nequi':
                return CheckoutEventTypes.SelectNequiScreen;
            case 'payment-yappy':
                return CheckoutEventTypes.SelectYappiScreen;
            case 'payment-online-bank-services':
                return CheckoutEventTypes.SelectOnlineScreen;
            case 'payment-payphone':
                return CheckoutEventTypes.SelectPayphoneScreen;
            case 'payment-tipti-card':
                return CheckoutEventTypes.SelectTiptiCardScreen;
            case 'payment-gift-card':
                return CheckoutEventTypes.SelectTiptiCardScreen;
            case 'payment-wire-transfer':
                return CheckoutEventTypes.SelectWireTransferScreen;
            default:
                assertUnreachable(selection);
        }
    };
    const getOrderInformation = () => {
        return {
            retailer: orderSelected.value
                ? orderSelected.value.retailer.toAnalytics
                : cart.value.retailers.map((ret) => ret.toAnalytics),
            order: {
                price: isExtraItemCart.value
                    ? cart.value.cartExtraItemAmount
                    : cart.value?.totalDetails?.totalCost ?? orderSelected.value?.totalToPayInResentPayment,
                is_extra_item: isExtraItemCart.value,
                is_resent_payment: Boolean(orderSelected.value),
            },
        };
    };
    const openCheckoutAddress = () => {
        $store.dispatch('segment/checkoutEvents', {
            event: CheckoutEventTypes.OpenCheckoutAddress,
        });
    };

    const openCheckoutSchedule = (retail: CartRetailerModel) => {
        $store.dispatch('segment/checkoutEvents', {
            event: CheckoutEventTypes.OpenCheckoutSchedule,
            parameters: {
                retailer: retail.toAnalytics,
            },
        });
    };

    const creditCardEvent = ({
        isForm,
        newCreditCardAdded,
        bin,
    }: {
        isForm?: boolean;
        newCreditCardAdded?: boolean;
        bin?: string;
    }) => {
        if (isForm && newCreditCardAdded) return;
        let event;
        if (isForm) event = CheckoutEventTypes.OpenNewCardForm;
        else
            event = newCreditCardAdded
                ? CheckoutEventTypes.SaveNewCardSuccessfully
                : CheckoutEventTypes.SaveNewCardFailed;
        $store.dispatch('segment/checkoutEvents', {
            event,
            parameters: {
                ...getOrderInformation(),
                bin,
            },
        });
    };

    const selectNewScheduleOnCheckout = (
        retailer: CartRetailAnalyticInterface,
        schedule?: {
            initial: string;
            new: string;
        },
        product?: {
            productName: string;
            stockItemId: number;
        },
    ) => {
        const event = schedule
            ? CheckoutEventTypes.SaveNewScheduleOnCheckout
            : CheckoutEventTypes.SelectNewScheduleOnCheckout;
        $store.dispatch('segment/checkoutEvents', {
            event,
            parameters: {
                retailer,
                schedule,
                ...product,
            },
        });
    };

    const viewPaymentsMethodScreen = () => {
        $store.dispatch('segment/checkoutEvents', {
            event: CheckoutEventTypes.ViewPaymentsMethodScreen,
            parameters: {
                ...getOrderInformation(),
            },
        });
    };

    const selectPaymentsMethod = ({
        selection,
        screen,
    }: {
        selection?: PaymentToShowModel;
        screen?: PaymentToShowName;
    }) => {
        if (!selection && !screen) return;
        const event = screen ? getScreenEventType(screen) : selection.analyticCheckoutEvent;
        $store.dispatch('segment/checkoutEvents', {
            event: event,
            parameters: {
                ...getOrderInformation(),
                ...selection?.analyticData,
            },
        });
    };

    return {
        selectPaymentsMethod,
        viewPaymentsMethodScreen,
        openCheckoutSchedule,
        openCheckoutAddress,
        selectNewScheduleOnCheckout,
        creditCardEvent,
    };
};
