import { ProductModel } from '@/models/product/ProductModel';
import { useCart } from '@/composables/useCart';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import { computed, ref } from 'vue';
import { MinRetailerInfo, RestRetailersInfo, YourRetailersInfo } from '@/modules/retailers/models/OtherRetailersModel';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import { retailerSections } from '@/modules/retailers/enums/retailerSections';
import { RetailerAvailabilityModel } from '@/modules/retailers/models/RetailerAvailabilityModel';
import { RetailerFilter } from '@/modules/retailers/models/RetailerFilterModel';
import { useApp } from '@/composables/useApp';
import { appRoutesMap } from '@/router/appRoutesMap';

const filterApplied = ref<RetailerFilter>();

export const useRetailer = (retailerId?: number) => {
    const { $store, $router, $route } = useApp();
    const { cart } = useCart();

    const retailerInCart = computed<CartRetailerModel>(() => {
        return cart.value?.retailers?.find((ret: CartRetailerModel) => ret.id == retailerId);
    });

    const yourRetailers = computed<YourRetailersInfo>(() => $store.getters['retailers/yourRetailers']);

    const retailersTypeFilters = computed<RetailerFilter[]>(() => {
        const result = $store.getters['retailers/filters'];

        if (!result) return [];

        return result;
    });

    const filteredRetailers = computed<MinRetailerInfo[]>(() => {
        if (!filterApplied.value) return [];

        if (filterApplied?.value?.isAllRetailer) {
            const allRetailers = otherRetailers.value.map((ret) => ret.retailers).flat();

            return [
                ...new Set(
                    [...(yourRetailers?.value?.retailers ?? []), ...allRetailers].map((retailer) =>
                        JSON.stringify(retailer),
                    ),
                ),
            ].map((string) => JSON.parse(string));
        }

        const updatedRetailers = otherRetailers.value.find((ret) => ret.id === filterApplied.value.id);

        return updatedRetailers?.retailers ?? [];
    });

    const retailers = computed<RetailerModel[]>(() => $store.getters['retailers/retailers']);
    const cartRetailerItems = computed<number>(() => $store.getters['retailers/cartRetailerItems']);
    const retailersShowed = computed<RetailerModel[]>(() => $store.getters['retailers/retailersAlertLimitShowed']);
    const bannerRetailers = computed<RetailerModel[]>(() => $store.getters['retailers/bannerRetailers']);
    const bannerRetailersImg = computed(() => $store.getters['retailers/bannerRetailersImg']);

    const originalRetailersAvailability = computed<RetailerAvailabilityModel[]>(
        () => $store.getters['retailers/originalRetailersAvailability'],
    );
    const retailersAvailability = computed<RetailerAvailabilityModel[]>(
        () => $store.getters['retailers/retailersAvailability'],
    );

    const retailerSelected = computed<RetailerModel>({
        get: () => $store.getters['retailers/retailerSelected'],
        set: (value) => $store.commit('retailers/retailerSelected', value),
    });
    const otherRetailers = computed<RestRetailersInfo[]>({
        get: () => $store.getters['retailers/otherRetailers'],
        set: (value) => $store.commit('retailers/otherRetailers', value),
    });
    const fetchYourRetailers = async () => $store.dispatch('retailers/yourRetailers');
    const fetchAlerts = async () => $store.dispatch('alerts/fetchAlerts');
    const deferredOptionByRetailer = async () => $store.dispatch('retailers/deferredOptionByRetailer');

    const fetchRetailerSelected = async () => $store.dispatch('retailers/retailerDetail');

    const fetchRetailerByFranchiseId = async (franchise_id: number) =>
        $store.dispatch('retailers/retailerByFranchiseId', franchise_id);

    const allRetailers = computed<MinRetailerInfo[]>(() => {
        return [
            ...(yourRetailers.value?.retailers ?? []),
            ...(otherRetailers.value?.map((ret) => ret.retailers)?.flat() ?? []),
        ];
    });

    const retailerWithNormalProducts = computed<boolean>(() => {
        return retailerInCart.value?.products?.some((product: ProductModel) => !product.uniqueDelivery);
    });

    const goToRetailer = (payload?: Partial<{ name: string; replace: boolean }>) => {
        $store.commit('categories/setCategorySelected');
        return $router.push({
            name: appRoutesMap.retail.home,
            params: {
                retailer: retailerSelected.value?.name ?? payload?.name,
            },
            replace: Boolean(payload?.replace),
        });
    };
    const minRetailerInfoToRetailerModel = (retailer: MinRetailerInfo) => {
        return new RetailerModel(
            retailer.id,
            retailer.pickup_available,
            null,
            '',
            retailer.delivery_range,
            retailer.name,
            retailer.image,
            retailer.icon,
        );
    };
    const navigateToRetailer = ({ retailer, section }: { retailer: MinRetailerInfo; section: retailerSections }) => {
        $store.commit('location/setLocationSelectedAsDelivery');
        retailerSelected.value = minRetailerInfoToRetailerModel(retailer);
        goToRetailer({
            name: retailer?.name,
        });
        $store.dispatch('segment/retailSelected', {
            screen: $route.path,
            section: section,
        });
    };

    const fetchOtherRetailers = async () => $store.dispatch('retailers/otherRetailers');
    const fetchRetailersSchedules = async () => $store.dispatch('retailers/retailersAvailability');
    const fetchFirstSchedulePerRetailer = async () => $store.dispatch('retailers/firstSchedulePerRetailer');
    const fetchFirstSchedulePerRetailerPickUp = async () => $store.dispatch('retailers/firstSchedulePerRetailerPickUp');
    const getRetailerHoursPickUp = async () => $store.dispatch('retailers/getRetailerHoursPickUp');
    const getRetailerHours = async () => $store.dispatch('retailers/getRetailerHours', retailerId);
    const fetchDescriptionRetailer = async () => $store.dispatch('retailers/description');

    return {
        goToRetailer,
        retailerInCart,
        retailerWithNormalProducts,
        yourRetailers,
        allRetailers,
        otherRetailers,
        fetchAlerts,
        navigateToRetailer,
        retailerSelected,
        retailers,
        fetchYourRetailers,
        fetchOtherRetailers,
        cartRetailerItems,
        retailersShowed,
        fetchRetailersSchedules,
        fetchFirstSchedulePerRetailer,
        fetchFirstSchedulePerRetailerPickUp,
        retailersAvailability,
        originalRetailersAvailability,
        bannerRetailers,
        bannerRetailersImg,
        getRetailerHoursPickUp,
        getRetailerHours,
        fetchDescriptionRetailer,
        retailersTypeFilters,
        fetchRetailerSelected,
        minRetailerInfoToRetailerModel,
        deferredOptionByRetailer,
        fetchRetailerByFranchiseId,
        filterApplied,
        filteredRetailers,
    };
};
