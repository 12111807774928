<template>
    <modal-state-feedback
        v-if="!isMobile"
        :body="body"
        :has-close="hasClose"
        :has-header="hasHeader"
        :is-failure="isFailure"
        :is-loading="isLoading"
        :is-success="isSuccess"
        :title="title"
        :cta-text="ctaText"
        :link-text="linkText"
        :show-action="showAction"
        @on-close="$emit('on-close')"
        @on-action="$emit('on-action')"
        @on-text-action="$emit('on-text-action')"
    />
    <bottom-sheet-feedback
        v-else
        :is-active="true"
        @on-close="$emit('on-close')"
        @on-action="$emit('on-action')"
        @on-text-action="$emit('on-text-action')"
        :is-success="isSuccess"
        :is-loading="isLoading"
        :is-failure="isFailure"
        :cta-text="ctaText"
        :link-text="linkText"
        :show-action="showAction"
        :title="title"
        :body="body"
    />
</template>

<script lang="ts">
import { isMobileBrowser } from '@/utils/utils';
import BottomSheetFeedback from '@/components/bottomSheet/bottomSheetFeedback/BottomSheetFeedback.vue';
import ModalStateFeedback from '@/components/modal/modalStateFeedback/ModalStateFeddback.vue';

export default {
    name: 'modal-loading-manager',
    emits: ['on-close', 'on-action', 'on-text-action'],
    props: {
        isSuccess: {
            type: Boolean,
            default: false,
        },
        isFailure: {
            type: Boolean,
            default: false,
        },
        hasClose: {
            type: Boolean,
            default: false,
        },
        hasHeader: {
            type: Boolean,
            default: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        ctaText: {
            type: String,
            default: '',
        },
        linkText: {
            type: String,
            default: '',
        },
        showAction: {
            type: Boolean,
            default: false,
        },
        body: {
            type: String,
            default: '',
        },
        padding: {
            type: String,
        },
    },
    components: {
        ModalStateFeedback,
        BottomSheetFeedback,
    },
    computed: {
        isMobile() {
            return isMobileBrowser();
        },
        style(): object {
            return {
                padding: `${this.btnYPadding}rem ${this.btnXPadding}rem`,
            };
        },
    },
};
</script>
