import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e164270"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "faq-main__phones"
}
const _hoisted_2 = { class: "faq-main__phones-txt" }
const _hoisted_3 = { class: "faq-main--card" }
const _hoisted_4 = { class: "faq-main--title-card" }
const _hoisted_5 = { class: "faq-main--content-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.iconComponent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_resolveDynamicComponent($props.iconComponent), { "icon-name:": $props.altIcon }, null, 8, ["icon-name:"])),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(`txt['${$props.titleCard}']`)), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t(`txt['${$props.contentCard}']`)), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}