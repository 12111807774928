<template>
    <aside class="retailer-logos">
        <h2 class="retailer-logos__title">{{ $t("general['become-part-of']") }}</h2>
        <div class="logos">
            <img
                class="logos__img"
                v-for="image in retailLogos"
                :key="image.imgId"
                :alt="image.imgInfo"
                :src="image.imgSrc"
            />
        </div>
    </aside>
</template>

<script>
export default {
    data() {
        return {
            retailLogos: [
                {
                    imgId: '1',
                    imgInfo: 'Logo Supermaxi',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-supermaxi.svg'),
                },
                {
                    imgId: '2',
                    imgInfo: 'Logo Nestle',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-nestle.svg'),
                },
                {
                    imgId: '3',
                    imgInfo: 'Logo Cocacola',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-cocacola.svg'),
                },
                {
                    imgId: '4',
                    imgInfo: 'Logo Coral',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-coral.svg'),
                },
                {
                    imgId: '5',
                    imgInfo: 'Logo Baguetteria',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-baguetteria.svg'),
                },
                {
                    imgId: '6',
                    imgInfo: 'Logo Lattemicorazon',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-lattemicorazon.svg'),
                },
                {
                    imgId: '7',
                    imgInfo: 'Logo Dolce Gusto',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-nestledolcegusto.svg'),
                },
                {
                    imgId: '8',
                    imgInfo: 'Logo Superfoods',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-superfoods.svg'),
                },
                {
                    imgId: '9',
                    imgInfo: 'Logo Mascotas',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-mmdlmascotas.svg'),
                },
                {
                    imgId: '10',
                    imgInfo: 'Logo Dilipa',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-dilipa.svg'),
                },
                {
                    imgId: '11',
                    imgInfo: 'Logo Bellazucar',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-bellazucar.svg'),
                },
                {
                    imgId: '12',
                    imgInfo: 'Logo Lilarie',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-lilarie.svg'),
                },
                {
                    imgId: '13',
                    imgInfo: 'Logo Fanlos',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-fanlos.svg'),
                },
                {
                    imgId: '14',
                    imgInfo: 'Logo Frunchies',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-frunchies.svg'),
                },
                {
                    imgId: '15',
                    imgInfo: 'Logo Roman',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-roman.svg'),
                },
                {
                    imgId: '16',
                    imgInfo: 'Logo Superpaco',
                    imgSrc: require('@/assets/img/svg-retailer/retailer-superpaco.svg'),
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import './retailer-logos.scss';
</style>
