import { ProgramSubscription, toProgramSubscription } from '@/models/loyaltyPrograms/ProgramSubscription';
import { buildScreenLabels, PageStructure } from '@/models/loyaltyPrograms/PageStructure';

class LoyaltyOrRewardModel {
    readonly programType: string;
    readonly id: number;
    readonly name: string;
    readonly description: string;
    readonly logo: string;
    readonly cardImage: string;
    readonly mainColor: string;
    readonly isForExchange: boolean;
    readonly isForDiscount: boolean;
    readonly discount: string;
    readonly exchangeEquivalence: string;
    readonly termsAndConditions: string;
    subscription: ProgramSubscription;
    readonly benefits: Array<string>;
    readonly querySchema: object;
    readonly registerSchema: object;
    readonly customLabelStructure: Array<PageStructure>;

    constructor(
        id: number,
        name: string,
        description: string,
        logo: string,
        cardImage: string,
        mainColor: string,
        isForExchange: boolean,
        isForDiscount: boolean,
        discount: string,
        exchangeEquivalence: string,
        termsAndConditions: string,
        subscription: ProgramSubscription,
        benefits: Array<string>,
        querySchema: object,
        registerSchema: object,
        customLabelStructure: Array<PageStructure>,
        programType: string,
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.logo = logo;
        this.cardImage = cardImage;
        this.mainColor = mainColor;
        this.isForExchange = isForExchange;
        this.isForDiscount = isForDiscount;
        this.discount = discount;
        this.exchangeEquivalence = exchangeEquivalence;
        this.termsAndConditions = termsAndConditions;
        this.subscription = subscription;
        this.benefits = benefits;
        this.querySchema = querySchema;
        this.registerSchema = registerSchema;
        this.customLabelStructure = customLabelStructure;
        this.programType = programType;
    }
}

const toLoyaltyOrRewardModel = (data: object, programType: string) => {
    return data
        ? new LoyaltyOrRewardModel(
              data['id'],
              data['name'] ?? '',
              data['description'] ?? '',
              data['logo'] ?? '',
              data['card_image'] ?? '',
              data['main_color'] ?? '',
              data['is_enabled_for_exchange'] ?? false,
              data['is_enabled_for_discount'] ?? false,
              data['discount'] ?? 0,
              data['exchange_reward_equivalence'] ?? 0,
              data['terms_and_conditions'] ?? '',
              toProgramSubscription(data['subscription']),
              data['benefits'] ?? [],
              data['query_schema'],
              data['register_schema'],
              buildScreenLabels(data['custom_label_structure']),
              programType,
          )
        : null;
};
export { toLoyaltyOrRewardModel, LoyaltyOrRewardModel };
