import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e3329ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "share-data__content" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "share-data__doted-section"
}
const _hoisted_5 = { class: "share-data__benefits" }
const _hoisted_6 = {
  key: 2,
  class: "share-data__message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_manager = _resolveComponent("alert-manager")!
  const _component_PdfFrame = _resolveComponent("PdfFrame")!
  const _component_general_alert = _resolveComponent("general-alert")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($options.showAlert && !$data.showTermsAndConditions)
      ? (_openBlock(), _createBlock(_component_alert_manager, {
          key: 0,
          "accept-button-text": _ctx.benefitsAndPromotionsMethod?.acceptButtonText,
          "is-disabled": $data.isLoading,
          "is-loading": $data.isLoading,
          "only-confirmation-btn": true,
          "show-buttons": true,
          title: _ctx.benefitsAndPromotionsMethod?.title ?? '',
          onOnAccept: _cache[1] || (_cache[1] = ($event: any) => ($options.closeAlert(true))),
          onOnDismiss: _cache[2] || (_cache[2] = ($event: any) => ($options.closeAlert(false))),
          onOnClose: _cache[3] || (_cache[3] = ($event: any) => ($options.closeAlert(false)))
        }, {
          content: _withCtx(() => [
            _createElementVNode("section", _hoisted_1, [
              (_ctx.benefitsAndPromotionsMethod?.image?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("img", {
                      class: "share-data__img",
                      src: _ctx.benefitsAndPromotionsMethod?.image,
                      alt: ""
                    }, null, 8, _hoisted_3)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.benefitsAndPromotionsMethod?.discountName?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.benefitsAndPromotionsMethod?.discountName), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.benefitsAndPromotionsMethod?.description?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.benefitsAndPromotionsMethod?.description), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("p", {
                class: "share-data__terms",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.showTermsAndConditions = true))
              }, _toDisplayString(_ctx.$t('general.terms-and-conditions')), 1)
            ])
          ]),
          _: 1
        }, 8, ["accept-button-text", "is-disabled", "is-loading", "title"]))
      : _createCommentVNode("", true),
    ($options.showAlert && $data.showTermsAndConditions)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 1,
          "accent-header": true,
          "only-confirmation-btn": true,
          title: _ctx.benefitsAndPromotionsMethod?.title ?? '',
          message: "",
          onOnClose: _cache[4] || (_cache[4] = ($event: any) => ($data.showTermsAndConditions = false)),
          onOnAccept: _cache[5] || (_cache[5] = ($event: any) => ($data.showTermsAndConditions = false))
        }, {
          content: _withCtx(() => [
            _createVNode(_component_PdfFrame, {
              class: "pdf__content",
              src: _ctx.benefitsAndPromotionsMethod.termsAndConditions ?? _ctx.$t('txt.share-data__terms-and-conditions')
            }, null, 8, ["src"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_snack_bar, {
      body: $data.snackBarMessage,
      "is-active": $data.showSnackBar,
      "is-failure": !$data.isSuccess,
      "is-success": $data.isSuccess,
      title: $data.snackBarTitle,
      onOnSnackbarClose: _cache[6] || (_cache[6] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success", "title"])
  ], 64))
}