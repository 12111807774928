import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-177401a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "addresses-checkout__addresses" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_addresses = _resolveComponent("addresses")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_section_footer = _resolveComponent("section-footer")!
  const _component_loading_modal = _resolveComponent("loading-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      _createVNode(_component_base_card, {
        class: "addresses-checkout",
        padding: "0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_section_header, {
            class: "addresses-checkout__header",
            title: _ctx.$t('txt.address-user__saved-addresses'),
            "bg-color": "#FF9012",
            "btn-back-color": "#fff",
            "color-title": "#FFF",
            "has-back": "",
            onOnBack: $options.onBack
          }, null, 8, ["title", "onOnBack"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_addresses, {
              "show-check": "",
              onOnCheck: $options.address
            }, null, 8, ["onOnCheck"])
          ])
        ]),
        _: 1
      }),
      (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
        _createVNode(_component_section_footer, { class: "addresses-checkout__footer" }, {
          default: _withCtx(() => [
            _createVNode(_component_btn_out_line, {
              txt: _ctx.$t('cta.continue'),
              "txt-color": "#FFF",
              onClick: $options.saveAddress
            }, null, 8, ["txt", "onClick"])
          ]),
          _: 1
        })
      ]))
    ]),
    ($data.isLoading)
      ? (_openBlock(), _createBlock(_component_loading_modal, {
          key: 0,
          title: _ctx.$t('txt.change_address'),
          "has-header": ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}