import { RetailerModel, toRetailerModel } from '@/modules/retailers/models/RetailerModel';
import { baseOrderState } from '@/utils/orderUtils';
import { ShopperModel, toShopperModel } from './ShopperModel';
import { PaymentMethodElementModel, toPaymentMethodElementModel } from './PaymentMethodElementModel';
import { OrderState } from '@/enums/orderState';
import {
    OrderRejectedContextDto,
    PaymentRejectedContext,
    SuggestedPaymentMethod,
} from '@/models/order/OrderRejectedContextDto';

export class OrderModel extends OrderRejectedContextDto {
    readonly id: number;
    readonly state: string;
    readonly stateInLocalLanguage: string;
    readonly shopper: ShopperModel;
    readonly shopperWhoBought: ShopperModel;
    readonly delivery: Date;
    readonly retailer: RetailerModel;
    readonly finalCost: number;
    readonly isExpress: boolean;
    readonly isAddExtraItemEnabled: boolean;
    readonly finalOrderItem: number;
    readonly initialOrderItem: number;
    readonly city: string;
    readonly numberOfExtraItemsAllowed: number;
    readonly color: string;
    readonly paymentMethods: Array<PaymentMethodElementModel>;
    readonly paymentAttempts: number;
    readonly paymentAttemptsMessage: string;
    constructor({
        id,
        retailer,
        final_cost,
        isExpress,
        shopper,
        payment_methods,
        payment_attempts,
        payment_attempt_message,
        order_final_items,
        order_initial_items,
        number_of_items_extra_allowed,
        add_extra_items_enabled,
        state_in_local_language,
        state,
        city,
        shopper_who_bought,
        delivery,
        payment_rejected_context,
        suggested_payment_method,
    }: {
        id: number;
        state: string;
        state_in_local_language?: string;
        shopper?: ShopperModel;
        shopper_who_bought?: ShopperModel;
        delivery?: Date;
        retailer?: RetailerModel;
        final_cost?: number;
        isExpress?: boolean;
        add_extra_items_enabled: boolean;
        order_final_items?: number;
        order_initial_items?: number;
        city?: string;
        number_of_items_extra_allowed?: number;
        payment_attempts?: number;
        payment_attempt_message?: string;
        payment_methods?: Array<PaymentMethodElementModel>;
        payment_rejected_context: PaymentRejectedContext;
        suggested_payment_method: SuggestedPaymentMethod;
    }) {
        super({
            suggested_payment_method,
            payment_rejected_context,
        });
        this.id = id;
        this.state = state;
        this.stateInLocalLanguage = state_in_local_language;
        this.shopper = toShopperModel(shopper);
        this.shopperWhoBought = toShopperModel(shopper_who_bought);
        this.delivery = delivery;
        this.retailer = toRetailerModel(retailer);
        this.finalCost = final_cost;
        this.isExpress = isExpress ?? false;
        this.isAddExtraItemEnabled = add_extra_items_enabled;
        this.finalOrderItem = order_final_items;
        this.initialOrderItem = order_initial_items;
        this.city = city ?? '';
        this.numberOfExtraItemsAllowed = number_of_items_extra_allowed ?? 0;
        this.paymentAttempts = payment_attempts;
        this.paymentAttemptsMessage = payment_attempt_message;
        this.color = baseOrderState(state).primaryColor;
        this.paymentMethods = payment_methods?.map((paymentMethod) => toPaymentMethodElementModel(paymentMethod)) ?? [];
    }

    get canAddExtraItemsFromActiveOrder(): boolean {
        return (
            [
                OrderState.PAYMENT_IN_PROCESS,
                OrderState.EXTRA_ITEMS_PAYMENT_IN_PROCESS,
                OrderState.PAYMENT_REJECTED,
                OrderState.EXTRA_ITEMS_PAYMENT_REJECTED,
                OrderState.ASSIGNING_SHOPPER,
                OrderState.SHOPPER_ASSIGNED,
                OrderState.SHOPPER_BUYING,
            ].includes(this.state as unknown as OrderState) &&
            this.numberOfExtraItemsAllowed > 0 &&
            this.isAddExtraItemEnabled
        );
    }
}
