<template>
    <tipti-input-base
        class="input-pass"
        v-bind="{
            ...$props,
            errors: localErrors,
        }"
    >
        <section class="password-filed">
            <input
                :id="label"
                ref="inputPassword"
                v-model.trim="localValue"
                :aria-label="label"
                :aria-required="isRequired"
                :maxlength="maxlength"
                :name="label"
                :placeholder="placeholder"
                :required="isRequired"
                :type="type"
                @blur="validateOnBlur"
            />
            <icon-eye-open v-if="showPassword" class="input-password__sufix" @click="showPassword = false" />
            <icon-eye-close v-else class="input-password__sufix" @click="showPassword = true" />
        </section>
    </tipti-input-base>
</template>

<script lang="ts" setup>
import TiptiInputBase from '@/components/inputs/primary/normal/TiptiInputBase.vue';
import IconEyeClose from '@/components/icons/IconEyeClose.vue';
import IconEyeOpen from '@/components/icons/IconEyeOpen.vue';
import { computed, ref, watch } from 'vue';
import { useInput } from '@/components/inputs/primary/normal/useInput';

const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    modelValue: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    isInvalid: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    errorMessage: {
        type: String,
        default: undefined,
    },
    maxlength: {
        type: Number,
        default: 50,
    },
    errors: {
        type: [String, Array<string>],
        default: undefined,
    },
});

const emits = defineEmits<{
    (e: 'update:modelValue', value: string): void;
    (e: 'blur'): void;
}>();

const localValue = computed({
    get: () => props.modelValue.replace(' ', ''),
    set: (value) => {
        emits('update:modelValue', value.replace(' ', ''));
    },
});
const showPassword = ref(false);
const type = ref('password');

watch(
    () => showPassword.value,
    (value) => {
        type.value = value ? 'text' : 'password';
    },
);

const { validateOnBlur, localErrors } = useInput(props, emits, localValue);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.password-filed {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 0.25rem;
}
</style>
