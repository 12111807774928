import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-514dade8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-menu" }
const _hoisted_2 = {
  key: 0,
  class: "card-menu__content"
}
const _hoisted_3 = {
  key: 1,
  class: "card-menu__content"
}
const _hoisted_4 = { class: "pickup__center" }
const _hoisted_5 = {
  key: 2,
  class: "card-menu__description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_address = _resolveComponent("icon-address")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createVNode(_component_icon_address, {
      size: 0.5,
      color: "#FF9012"
    }),
    ($options.getAddress && !$options.isPickUp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", null, _toDisplayString($options.aliasShown), 1),
          _createElementVNode("p", null, _toDisplayString($options.addressName), 1)
        ]))
      : ($options.pickUpCenter && $options.isPickUp)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h2", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('general.pick-up')) + " ", 1),
              _createElementVNode("strong", null, _toDisplayString($options.retailerName.toUpperCase()), 1)
            ]),
            _createElementVNode("strong", _hoisted_4, _toDisplayString($options.pickUpCenter?.name), 1),
            _createElementVNode("p", null, _toDisplayString($options.pickUpCenter?.address), 1),
            _createElementVNode("p", null, _toDisplayString($options.pickUpCenter?.city?.name) + ", " + _toDisplayString($options.pickUpCenter?.sector?.name), 1)
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("txt['checkout__enter-address']")), 1)),
    _createElementVNode("button", {
      class: _normalizeClass({ 'text-no-selected': !$options.isChangeAddress }),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-change-selected', $options.isPickUp ? 'pickup' : $data.appRoutesMap.checkout.addresses)))
    }, _toDisplayString($options.btnActionTxt), 3)
  ]))
}