import { useStore } from 'vuex';

import { computed, toRaw } from 'vue';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { useCart } from '@/composables/useCart';
import { ProductModel } from '@/models/product/ProductModel';

export const useCheckOut = () => {
    const $store = useStore();
    const { cart } = useCart();
    const timeDelivery = computed<TimeDeliveryModel[]>(() => $store.getters['checkout/timeDelivery']);
    const stockItemTimeDelivery = computed<TimeDeliveryModel[]>(() => $store.getters['checkout/stockItemTimeDelivery']);

    const instructions = computed<string>({
        get: () => $store.getters['checkout/instructions'],
        set: (value) => $store.commit('checkout/instructions', value),
    });
    const phone = computed<string>({
        get: () => $store.getters['checkout/phone'],
        set: (value) => $store.commit('checkout/phone', value),
    });
    const email = computed<string>({
        get: () => $store.getters['checkout/email'],
        set: (value) => $store.commit('checkout/email', value),
    });

    const retailerIds = computed<number[]>(() => [
        ...(timeDelivery.value ?? []).map((time) => time.retailerId),
        ...(stockItemTimeDelivery.value ?? []).map((time) => time.retailerId),
    ]);

    const productsBought = computed<ProductModel[]>(() => {
        const retailer = cart.value?.retailers?.filter((retailer) => retailerIds.value?.includes(retailer.id));
        return retailer.map((retailer) => toRaw(retailer?.products)).flat();
    });

    return {
        productsBought,
        retailerIds,
        instructions,
        phone,
        email,
    };
};
