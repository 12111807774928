<template>
    <general-alert
        v-if="!isPageNotifications && pendingPayment"
        :decline-button-txt="$t('cta.reject')"
        :is-disabled="isLoading"
        :is-loading="isLoading"
        :title="$t('txt.notifications__have-pending-payments')"
        @on-close="closeAlert"
        @on-accept="isLoading ? null : setPayment('APPROVED')"
        @on-decline="isLoading ? null : setPayment('REJECTED_BY_CLIENT')"
    >
        <template #icon>
            <icon-afflicted-shop-bag :size="0.5"></icon-afflicted-shop-bag>
        </template>
        <template #content>
            <p class="surplus-message">
                {{ $t('txt.surplus__your-shopper') }}
                <span v-currency="pendingPayment?.amountReceivable" class="surplus-message--important" />
                {{ $t('txt.surplus__order-of') }}
                <span class="surplus-message--important">#{{ pendingPayment?.order }}</span>
                {{ $t('txt.surplus__to-charge-from') }}
                <span class="surplus-message--important">{{ paymentMethod }}</span>
            </p>
        </template>
    </general-alert>
    <snack-bar
        :body="snackBarMessage"
        :is-active="showSnackBar"
        :is-failure="!isSuccess"
        :is-success="isSuccess"
        @on-snackbar-close="showSnackBar = false"
    ></snack-bar>
</template>

<script lang="ts">
import { logger } from '@/utils/logger';
import { mapGetters } from 'vuex';
import GeneralAlert from '../generalAlert/GeneralAlert.vue';
import IconAfflictedShopBag from '@/components/icons/IconAfflictedShopBag.vue';
import SnackBar from '../snackBar/SnackBar.vue';
import { useRoutes } from '@/composables/useRoutes';

export default {
    name: 'AlertSurplus',
    components: { GeneralAlert, IconAfflictedShopBag, SnackBar },
    setup() {
        const { isPageNotifications } = useRoutes();
        return {
            isPageNotifications,
        };
    },
    data() {
        return {
            isLoading: false,
            showSnackBar: false,
            isSuccess: false,
            snackBarMessage: this.$t("txt['notifications__pending-processed-failure']"),
        };
    },
    computed: {
        ...mapGetters({
            pendingPayment: 'notifications/pendingPayment',
        }),
        paymentMethod(): string {
            return this.pendingPayment?.cashPayment
                ? this.$t("general['payment-cash']")
                : this.pendingPayment?.wireTransferPayment
                  ? this.$t("general['payment-wire-transfer']")
                  : this.pendingPayment?.payphonePayment
                    ? this.$t("general['payment-payphone']")
                    : this.pendingPayment?.tiptiCardPayment
                      ? this.$t("general['payment-tipti-card']")
                      : this.pendingPayment?.paymentMethodToUse
                        ? this.$t("general['payment-cards']")
                        : '';
        },
    },
    methods: {
        closeAlert(): void {
            this.$store.commit('notifications/pendingPayment', null);
        },
        async setPayment(status: string): Promise<void> {
            try {
                this.isLoading = true;
                const paymentDone = await this.$store.dispatch('payments/setPendingPayment', {
                    status: status,
                    payment: this.pendingPayment,
                });
                this.isSuccess = paymentDone;
                this.snackBarMessage = paymentDone
                    ? this.$t("txt['notifications__pending-processed-success']")
                    : this.$t("txt['purchase__payment-rejected']");
                this.closeAlert();
            } catch (err) {
                this.snackBarMessage = this.$t("txt['notifications__pending-processed-failure']");
                this.isSuccess = false;
                logger('SET_PAYMENT', err);
            } finally {
                this.isLoading = false;
                this.showSnackBar = true;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import './alert-surplus.scss';
</style>
