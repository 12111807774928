export const i18Alerts = {
    ES: {
        alerts: {
            getting_location_error:
                'No pudimos obtener la ubicación de tu dispositivo, por favor, ingresala manualmente en la parte inferior',
            maxi_dollars__information_title: 'Canje MaxiDólares',
            transfer_processing: 'Procesando transferencia',
            maxidollars_process_success: 'MaxiDólares transferidos con éxito',
            order: 'Pedido #{orderId}',
            payment_success: 'Pago realizado con éxito',
            payment_rejected: 'Pago rechazado',
            change_payment: 'Por favor cambia el método de pago para continuar con tu pedido',
            payment_accepted: 'Pago realizado con éxito',
            processing_order: 'Espere un momento, estamos procesando tu pago',
            processing_order__waiting: 'Esto puede tardar unos segundos,',
            resent_payment_with: 'Paga con {method}',
            validating_payment: 'Validando pago',
            orders_in: 'Tienes pedidos en',
            change_order_payment: 'puedes cambiar el método de pago desde la órden',
            thanks: 'Gracias',
            purchase__hours_saved_earn: ' has AHORRADO',
            purchase__hours_saved_info: 'ahorras todo el tiempo que hubieses pasado haciendo compras y',
            purchase__hours_saved_info2: 'GANAS TIEMPO PARA TI,',
            purchase__hours_saved_info3: ' para que disfrutes con tus amigos y familia haciendo lo que te gusta',
            purchase__hours_saved_with: 'Con',
            purchase__hours_saved_message: 'DE TIEMPO PARA TI',
            purchase__hours_saved_hours: '{count} hora | {count} horas',
            error: 'Error',
            link_copied: 'Enlace copiado',
            general_error_title: 'Ha ocurrido un error',
            general_error_message: 'Vuelve a intentarlo más tarde',
            address_selected_success: 'Tu dirección ha sido actualizada correctamente',
            connection_error:
                'Ups.. No se pudo enviar la información, por favor verifica la conexión e intenta nuevamente',
            phone_number_exists: 'Error en formulario, este número ya lo tienes registrado',
            bill_data_add: 'Sus datos de factuación han sido registrados exitosamente',
            bill_data_error:
                'Verifica que los datos están correctos. Una vez ingresado el pedido no se podrá cambiar los datos de la factura emitida.',
            edit_bill_data: 'Sus datos de factuación han sido actualizados exitosamente',
            profile_number_exist:
                'El número de teléfono ya pertenece a un usuario, por favor verifícalo intenta nuevamente.',
            profile_document_exist:
                'El documento de identidad ya pertenece a un usuario, por favor verifícalo e intenta nuevamente.',
            profile_form_error: 'Ocurrio un error al actualizar tu perfil, intentalo nuevamente...',
            out_of_coverage: 'Estás un poco lejos, por el momento no cubrimos tu sector..',
            address_add_error: 'Error al agregar dirección',
            address_add_success: 'Tu dirección ha sido agregada exitosamente',
            address_edit_success: 'Los datos de tu dirección han sido actualizados exitosamente.',
            address_edit_error: 'Error al actualizar dirección, por favor inténtalo nuevamente',
            register_user_exists: 'El usuario ya existe. Por favor verifíca tu información y vuelva a intentarlo.',
            register_user_error: 'Usuario no registrado, por favor verifíca tu correo e intenta nuevamente',
            tiptiCard_code_error: 'Este código ya fue activado anteriormente, comunícate con CARE TEAM.',
            tiptiCard_code_notFound: 'Este código no existe, por favor verifícalo e inténtalo nuevamente.',
            tiptiCard_code_success: 'Se ha recargado {currency}{amount} a tu tipticard',
            referred_code_error: 'Este código ya fue activado anteriormente, comunícate con CARE TEAM.',
            referred_code_himself: 'No puedes ingresar tu propio código, verifícalo e intentalo nuevamente',
            referred_code_orders: 'Error, esta promoción es válida solamente para clientes nuevos.',
            register_social_error: 'Error en el registro, por favor intentalo nuevamente.',
            cart_empty_error: 'No se ha podido vaciar tu carrito, por favor inténtalo nuevamente.',
            list_create_error: 'Error al crear la lista, inténtalo nuevamente',
            list_product_error: 'Hubo un error los productos no pudieron ser agregados a las listas',
            shopper_rate_success: 'Has calificado exitosamente tu orden',
            shopper_tip_success: 'Tu propina ha sido enviada con éxito',
            shopper_tip_error: 'Error al enviar calificación, intentalo nuevamente.',
            shopper_rate_error: 'Hubo un problema al enviar la propina.',
            shopper_skip_success: 'Se ha omitido calificar la orden',
            transfer_balance_self:
                'Este número de tipticard pertenece al mismo usuario, verifícalo e intenta nuevamente.',
            transfer_balance_error: 'Error al transferir saldo, por favor intenta nuevamente',
            transfer_balance_success: 'Se ha transferido {currency}{amount} a la TiptiCard de {user}',
            transfer_balance_incorrect: 'Corregir datos',
            tipticard_buy_error: 'Hubo un error al realizar la compra de la gift card, por favor intentalo nuevamente.',
            tipticard_buy_success: 'Ha adquirido una Gift Card de {currency}{amount}',
            tipticard_recharge_success: 'Se ha recargado {currency}{amount} a tu tipticard',
            tipticard_recharge_error: 'Hubo un error al realizar la recarga, por favor intentalo nuevamente.',
            giftCard_copied: 'Enlace copiado',
            order_not_found: 'Error al cargar la órden, por favor inténtalo nuevamente.',
            tipti_contact_success:
                'Gracias por escribirnos, un agente revisará tu caso y se contactará a la brevedad posile.',
            tipti_contact_error: 'Error al enviar los datos, por favor inténtalo nuevamente',
            product_add_error: 'Sucedió un error al cargar el producto, recarga e inténtalo nuevamente',
            product_remove_error: 'Sucedió un error al cargar el producto, recarga e inténtalo nuevamente',
            retail_out_coverage: 'Retailer fuera de cobertura',
            retail_not_found: 'Promoción o tienda no encontrada en tu sector.',
            profile_birthday_empty: 'Por favor completa el formulario con tu fecha de nacimiento',
            profile_birthday_format: 'Ingrese una fecha de nacimiento en el formato ',
            referred_code_success: 'Codigo activado exitosamente',
            extra_payment_error: 'Error al agregar los extra items, espera unos minutos e inténtalo nuevamente.',
            product_not_found: 'Producto deshabilitado y fuera de cobertura.',
            category_not_found: ' La categoría que intentas abrir pertenece a un retailer fuera de cobertura',
            recipe_not_found: 'Receta no encontrada',
            link_unavailable:
                'Oops! Al parecer el link ya no esta vigente o te encuentras fuera de cobertura, comunícate con care team {number}',
            replacement_not_allowed: 'No podrás realizar reemplazos en este tipo de pedido.',
            meet_pickup: 'Conoce Pickup',
            deferred__title: '¿Cómo deseas realizar tu pago?',
            deferred__text: 'Recuerda que puedes diferir tus pagos con tarjeta de crédito.',
            pickup__title: 'Zonas Pickup',
            pickup__footer: '¿Donde quieres recoger tu pedido?',
            configurable_delivery_title: 'Producto no seleccionable',
            configurable_delivery_body: 'El producto {product} no se puede añadir como extra item a tu pedido actual',
            configurable_delivery_btn: 'Regresar al buscador',
            oops: 'Oops!',
            deeplink__retailer_not_found: 'El retailer que buscas no se encuentra en tu sector',
        },
    },

    EN: {
        alerts: {
            getting_location_error:
                'We were unable to obtain the location of your device, please enter it manually at the bottom',
            maxi_dollars__information_title: 'MaxiDólares Exchange',
            transfer_processing: 'Processing transfer',
            maxidollars_process_success: 'MaxiDólares successfully transferred',
            order: 'Order #{orderId}',
            payment_success: 'Payment made successfully',
            payment_rejected: 'Payment was not processed',
            payment_accepted: 'Payment made successfully',
            processing_order: 'Wait a moment, we are processing your payment',
            processing_order__waiting: 'This may take a few seconds,',
            resent_payment_with: 'Pay with {method}',
            validating_payment: 'Validating payment',
            orders_in: 'You have orders in',
            change_order_payment: 'you can change the payment method from the order.',
            thanks: 'Thank you',
            purchase__hours_saved_earn: ' you have SAVED',
            purchase__hours_saved_info: 'you save all the time you would have spent shopping and',
            purchase__hours_saved_info2: 'TIME FOR YOU, ',
            purchase__hours_saved_info3: 'so you can enjoy with your friends and family doing what you like',
            purchase__hours_saved_with: 'With',
            purchase__hours_saved_message: 'OF TIME FOR YOU',
            purchase__hours_saved_hours: '{count} hour | {count} hours',
            error: 'Error',
            link_copied: 'Link copied',
            general_error_title: 'An error has occurred',
            general_error_message: 'Please try again later',
            address_selected_success: 'Your address details have been successfully updated',
            connection_error: 'Oops .. The information could not be sent, please verify the connection and try again',
            phone_number_exists: 'Form error, you already have this number registered',
            bill_data_add: 'Your billing data has been successfully registered',
            bill_data_error:
                'Verify that the data is correct. Once the order has been entered, the information on the invoice issued cannot be changed.',
            edit_bill_data: 'Your billing information has been successfully updated',
            profile_number_exist: 'The phone number already belongs to a user, please verify it and try again.',
            profile_document_exist: 'The identity document already belongs to a user, please verify it and try again.',
            profile_form_error: 'An error occurred while updating your profile, please try again...',
            out_of_coverage: 'You are a bit far, at the moment we do not cover your sector.',
            address_add_error: 'Failed to add address',
            address_add_success: 'Your address has been added successfully',
            address_edit_success: 'Your address details have been successfully updated',
            address_edit_error: 'Error updating address, please try again.',
            register_user_exists: 'User already exists. Please verify your information and try again.',
            register_user_error: 'User not registered, please verify your email and try again',
            tiptiCard_code_error: 'This code has already been activated before, contact CARE TEAM.',
            tiptiCard_code_notFound: 'This code does not exist, please check it and try again.',
            tiptiCard_code_success: '{currency}{amount} has been charged to your tipticard',
            referred_code_error: 'This code has already been activated before, contact CARE TEAM.',
            referred_code_himself: 'You cannot enter your own code, please verify it and try again',
            referred_code_orders: 'Error, this promotion is valid only for new customers.',
            register_social_error: 'Registration failed, please try again.',
            cart_empty_error: 'Your cart could not be emptied, please try again.',
            list_create_error: 'Error creating list, please try again.',
            list_product_error: 'There was an error the products could not be added to the lists',
            shopper_rate_success: 'You have successfully qualified your order',
            shopper_tip_success: 'Your tip has been sent successfully',
            shopper_rate_error: 'There was a problem grading the order.',
            shopper_tip_error: 'Failed to submit grade, please try again.',
            shopper_skip_success: 'Qualifying the order has been omitted',
            transfer_balance_self: 'This tipticard number belongs to the same user, please verify it and try again.',
            transfer_balance_error: 'Error transferring balance, please try again',
            transfer_balance_success: '{currency}{amount} has been transferred to the TiptiCard of {user}',
            transfer_balance_incorrect: 'Wrong information',
            tipticard_buy_error: 'There was an error when purchasing the gift card, please try again.',
            tipticard_buy_success: 'You have purchased a {currency} {amount} Gift Card',
            tipticard_recharge_success: '{currency}{amount} has been charged to your tipticard',
            tipticard_recharge_error: 'There was an error reloading, please try again.',
            giftCard_copied: 'Copied link',
            order_not_found: 'Error loading order, please try again.',
            tipti_contact_success:
                'Thank you for writing to us, an agent will review your case and will contact you as soon as possible.',
            tipti_contact_error: 'Error sending data, please try again',
            product_add_error: 'There was an error loading the product, recharge and try again',
            product_remove_error: 'There was an error loading the product, recharge and try again',
            retail_out_coverage: 'Retailer out of coverage',
            retail_not_found: 'Promotion or store not found in your sector.',
            profile_birthday_empty: 'Please complete the form with your date of birth',
            profile_birthday_format: 'Enter a date of birth in the format ',
            referred_code_success: 'Code Activated Successfully',
            extra_payment_error: 'Error adding extra items, wait a few minutes and try again.',
            product_not_found: 'Product disabled and out of coverage.',
            category_not_found: 'The category you are trying to open belongs to a retailer out of coverage',
            recipe_not_found: 'Recipe not found',
            link_unavailable:
                'Oops! Apparently the link is no longer valid or you are out of coverage, contact the care team {number}',
            replacement_not_allowed: 'You will not be able to make substitutions in this type of order.',
            meet_pickup: 'Meet Pickup',
            deferred__title: 'How would you like to make your payment?',
            deferred__text: 'Remember that you can defer your credit card payments.',
            pickup__title: 'Pickup points',
            pickup__footer: 'Where do you want to pick up your order?',
            configurable_delivery_title: 'Product not selectable',
            configurable_delivery_body: 'The product {product} cannot be added as an extra item to your order',
            configurable_delivery_btn: 'Back to search',
            oops: 'Oops!',
            deeplink__retailer_not_found: 'The retailer you are looking for is not available in your sector',
        },
    },
};
