<template>
    <Transition mode="out-in" name="slide-fade">
        <BaseBone v-if="loadingImg" class="banner_skeleton" />
        <ImageLoader v-else class="header__img" :src="imgSrc" alt-text="" @click.stop="openRecipeDeepLink">
            <template #loader>
                <BaseBone class="banner_skeleton" />
            </template>
        </ImageLoader>
    </Transition>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { BannerHomePage } from '@/models/homePageBanner/bannerHomePage';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import ImageLoader from '@/modules/core/components/loaders/ImageLoader.vue';
import { useApp } from '@/composables/useApp';

const { $store } = useApp();
const banner = ref<BannerHomePage>();
const emits = defineEmits<(e: 'loaded') => void>();
const loadingImg = ref(true);
const imgSrc = ref('');

const getInfo = async () => {
    try {
        banner.value = await $store.dispatch('external/getBannerHeaderHomePage');
        if (!banner.value?.imagen) return;
        imgSrc.value = banner.value?.imagen;
    } finally {
        loadingImg.value = false;
        setTimeout(() => {
            emits('loaded');
        }, 250);
    }
};

const openRecipeDeepLink = () => {
    window.open(banner.value.deeplink, '_blank');
};

await getInfo();
</script>

<style lang="scss" scoped>
.banner_skeleton {
    height: 3em;
    width: 100%;
}

.header__img {
    height: auto;
    cursor: pointer;
    max-height: 4em;
    width: 100%;
    object-fit: fill;
}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
