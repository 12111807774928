import { logger } from '@/utils/logger';

declare const window: any;

interface Geolocation {
    lat?: number;
    lng?: number;
    geolocation: string;
}

const getLocation = async (): Promise<Geolocation> => {
    if (!navigator.geolocation) return { geolocation: '' };
    const resolve = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 2500,
            maximumAge: Infinity,
        });
    }).catch((err) => {
        logger('DATA_NO_ALLOWED_BY_USER', err);
    });

    if (!resolve) return { geolocation: '' };
    return {
        lat: resolve['coords']?.latitude,
        lng: resolve['coords']?.longitude,
        geolocation: `${resolve['coords']?.latitude}, ${resolve['coords']?.longitude}`,
    };
};

const getCountry = async (): Promise<string> => {
    if (!navigator.geolocation) return '';
    let countryName = '';
    const location: Geolocation = await getLocation();
    const latlng = new (window as any).google.maps.LatLng(location.lat, location.lng);
    const geocoder = new window.google.maps.Geocoder();
    await geocoder.geocode(
        {
            latLng: latlng,
        },
        (results, _) => {
            if (!results) return countryName;
            const arrayAddresses = results[0].address_components;
            countryName = arrayAddresses.find((element) => element['types'].includes('country'))?.long_name;
        },
    );
    return countryName;
};

const getCountryAlphaCode = async (): Promise<string> => {
    if (!navigator.geolocation) return '';
    let alphaCode = '';
    const location: Geolocation = await getLocation();
    const latlng = new (window as any).google.maps.LatLng(location.lat, location.lng);
    const geocoder = new window.google.maps.Geocoder();
    await geocoder.geocode(
        {
            latLng: latlng,
        },
        (results, _) => {
            if (!results) return alphaCode;
            const arrayAddresses = results[0].address_components;
            alphaCode = arrayAddresses.find((element) => element['types'].includes('country'))?.short_name;
        },
    );
    return alphaCode;
};

const getCity = async (): Promise<string> => {
    if (!navigator.geolocation) return '';
    let cityName = '';
    const location: Geolocation = await getLocation();
    const latlng = new (window as any).google.maps.LatLng(location.lat, location.lng);
    const geocoder = new window.google.maps.Geocoder();
    await geocoder.geocode(
        {
            latLng: latlng,
        },
        (results, _) => {
            if (!results) return cityName;
            const arrayAddresses = results[0].address_components;
            cityName = arrayAddresses.find(
                (element) =>
                    element['types'].includes('administrative_area_level_1') ||
                    element['types'].includes('administrative_area_level_2'),
            )?.long_name;
        },
    );
    return cityName;
};
export { getLocation, getCountry, getCity, getCountryAlphaCode, Geolocation };
