<template>
    <template v-if="isMobile">
        <skeleton-grid v-if="state === 'LOADING'" :items="4" show-subheader />
        <template v-else-if="productsToRender?.length">
            <subheader-separator
                :name="$t('txt.subcategories__features_products')"
                :show-btn="showElementsByItems"
                has-padding
                @on-pressed="$emit('go-to-featured-products')"
            />
            <products-grid
                class="margin-grid"
                :products="productsToRender"
                :section="`${sectionName}_${categorySelected?.name?.toUpperCase()}`"
                :show-load-more-products-card="showElementsByItems"
                @on-click-see-more="$emit('go-to-featured-products')"
            />
        </template>
    </template>
    <template v-else>
        <CarouselForProducts
            :items="productsToRender"
            :section="`${sectionName}_${categorySelected?.name?.toUpperCase()}`"
            :show-load-more-products-card="showElementsByItems"
            :show-subheader-btn="showElementsByItems"
            :state="state"
            :subheader-name="$t('txt.subcategories__features_products')"
            has-subheader-padding
            @on-click-subheader="$emit('go-to-featured-products')"
        />
    </template>
</template>

<script lang="ts" setup>
import { logger } from '@/utils/logger';
import SkeletonGrid from '@/components/skeletons/skeletonGrid/SkeletonGrid.vue';
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';
import { Sections } from '@/enums/sectionsOrigin';
import type { PropType } from 'vue';
import { computed, ref, shallowRef } from 'vue';
import { useApp } from '@/composables/useApp';
import { useProductFetcher } from '@/views/seeMore/useProductFetcher';
import { RequestState } from '@/modules/core/types/WorkingState';
import { ProductModel } from '@/models/product/ProductModel';
import ProductsGrid from '@/components/product/productsGrid/ProductsGrid.vue';
import { useProductStore } from '@/store/useProductStore';
import CarouselForProducts from '@/components/carousels/CarouselForProducts.vue';

defineProps({
    sectionName: {
        type: String as PropType<keyof typeof Sections>,
        required: true,
    },
});
defineEmits<(e: 'go-to-featured-products') => void>();

const { isOnlyPhone: isMobile } = useApp();
const { categorySelected, retailerSelected } = useProductFetcher();
const { featuredProducts } = useProductStore();
const state = ref<RequestState>('LOADING');
const products = shallowRef<ProductModel[]>([]);
const numberOfItemsPlatform = computed(() => (isMobile.value ? 6 : 15));
const productsToRender = computed(() => products.value.slice(0, numberOfItemsPlatform.value));
const showElementsByItems = computed(() => productsToRender.value?.length >= numberOfItemsPlatform.value);
const getProducts = async () => {
    try {
        state.value = 'LOADING';
        products.value = await featuredProducts({
            retailer_id: retailerSelected.value?.id,
            category_id: categorySelected.value?.id,
        });
    } catch (err) {
        logger('FETCH_PRODUCTS_SUBCATEGORIES', err);
    } finally {
        state.value = 'NONE';
    }
};

getProducts();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.margin-grid {
    margin: 0 $margin-md;
}
</style>
