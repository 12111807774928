import { logger } from '@/utils/logger';
export default {
    methods: {
        async fetchPayphones(): Promise<void> {
            try {
                this.isLoading = true;
                await this.$store.dispatch('payments/payphoneList');
            } catch (err) {
                logger('FETCH_PAYPHONES', err);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
