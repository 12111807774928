<template>
    <figure class="figure">
        <div class="figure-inner" :class="{ '__has-padding': hasPadding }">
            <transition-fade :time="150">
                <img
                    v-if="isLoading || !!error"
                    class="figure-img"
                    :alt="alternativeText"
                    loading="lazy"
                    src="@/assets/svg/tipti_logo/ic_tipti_orange.svg"
                />
                <img
                    v-else
                    class="figure-img"
                    :alt="alternativeText"
                    :src="source"
                    loading="lazy"
                    @error="displayDefaultImg"
                />
            </transition-fade>
        </div>
    </figure>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useImage } from '@/composables/ImageComposable';
import { useImage as vImage } from '@vueuse/core';
import TransitionFade from '@/modules/core/components/transition/TransitionVerticalFade.vue';

const props = defineProps({
    aspectRatio: {
        type: Number,
        default: 1 / 1,
    },
    hasPadding: {
        type: Boolean,
        default: true,
    },
    source: {
        type: String,
        required: true,
    },
    alternativeText: {
        type: String,
    },
});

const { displayDefaultImg } = useImage();

const { isLoading, error } = vImage({ src: props.source });

const getPadding = computed(() => `${props.aspectRatio * 100}%`);
const getAspectRatio = computed(() => (isLoading || !!error ? 1 : props.aspectRatio));
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_padding';

.figure {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 100%;
    padding-top: v-bind(getPadding);
}

.figure-inner {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;

    &.__has-padding {
        padding: $padding-sm;
    }
}

.figure-img {
    width: 100%;
    height: auto;
    aspect-ratio: v-bind(getAspectRatio);
    object-fit: cover;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
