<template>
    <base-icon :icon-name="label" :width="width" :height="height" viewBox="0 0 21 21" :iconColor="color">
        <path
            d="M10.5 21C4.74841 21 0 16.3185 0 10.5C0 4.68153 4.68153 0 10.5 0C16.3185 0 21 4.68153 21 10.5C20.9331 16.3185 16.2516 21 10.5 21ZM10.5 1.87261C5.68471 1.87261 1.80573 5.75159 1.80573 10.5669C1.80573 15.3822 5.68471 19.2611 10.5 19.2611C15.3153 19.2611 19.1943 15.3822 19.1943 10.5669C19.1274 5.75159 15.2484 1.87261 10.5 1.87261Z"
        />
        <path
            d="M14.5116 14.5812C14.1772 14.9156 13.5753 14.9156 13.2409 14.5812L10.432 11.7723L7.62306 14.5812C7.28866 14.9156 6.68675 14.9156 6.35236 14.5812C6.01796 14.2468 6.01796 13.6449 6.35236 13.3105L9.16127 10.5016L6.35236 7.6927C6.01796 7.3583 6.01796 6.75639 6.35236 6.422C6.68675 6.0876 7.28866 6.0876 7.62306 6.422L10.432 9.23092L13.2409 6.422C13.5753 6.0876 14.1772 6.0876 14.5116 6.422C14.846 6.75639 14.846 7.3583 14.5116 7.6927L11.7027 10.5016L14.5116 13.3105C14.846 13.6449 14.846 14.2468 14.5116 14.5812Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-circular-close',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
        iconName: {
            type: String,
        },
    },
    computed: {
        width(): number {
            return 25 * this.size;
        },
        height(): number {
            return 25 * this.size;
        },
        label(): string {
            return this.iconName ?? this.$t('icon.close');
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
