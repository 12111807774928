import { CountriesModel, toCountriesModel } from '@/models/external/CountriesModel';
import { PromotionInTipti, toPromotionInTipti } from '@/models/promotions/PromotionInTipti';
import { logger } from '@/utils/logger';
import { CountryCodeModel } from '@/models/country/CountryCodeModel';
import { BannerHomePage, toBannerHomePage } from '@/models/homePageBanner/bannerHomePage';
import { isFicoaDomain } from '@/utils/utils';
import type { PressReleases, TypePressReleases } from '@/models/external/PressReleases';
import type { ResponsePaged } from '@/modules/core/models/ResponseModels';

export default {
    updateCareTeamChatByCountry({ rootGetters }): void {
        try {
            if (process.env.VUE_APP_API_ENV !== 'PROD' || isFicoaDomain()) return;

            const head = document.getElementsByTagName('head')[0];
            const chatFrame = document.getElementById('genesys-messenger');

            if (rootGetters['cart/showCart']) {
                if (chatFrame) chatFrame.style.display = 'none';
                return;
            }
            if (chatFrame) {
                chatFrame.style.display = 'block';
                return;
            }

            const scriptContent = `
  (function(g, e, n, es, ys) {
    g['_genesysJs'] = e;
    g[e] = g[e] || function() {
      (g[e].q = g[e].q || []).push(arguments);
    };
    g[e].t = 1 * new Date();
    g[e].c = es;
    ys = document.createElement('script');
    ys.async = 1;
    ys.src = n;
    ys.charset = 'utf-8';
    document.head.appendChild(ys);
  })(window, 'Genesys', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js', {
    environment: 'prod',
    deploymentId: '${process.env.VUE_APP_CHATBOT}'
  });
`;

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = scriptContent;

            head.appendChild(script);
        } catch (err) {
            logger('UPDATE_CARE_TEAM_CHAT_BY_COUNTRY', err);
        }
    },
    async sendMailRetailer({ rootGetters }, payload): Promise<void> {
        try {
            const url = `send_mail_from_form/retailer/?country=${rootGetters[
                'external/countrySelected'
            ]?.alphaCode?.toUpperCase()}`;
            const data = {
                'Nombre del local': payload.retailerName,
                'Tipo retailer': payload.retailerType,
                Ciudad: payload.retailerCity,
                Nombre: payload.retailerContactName,
                Cargo: payload.retailerBusinessType,
                Teléfono: payload.retailerPhoneNumber,
                Email: payload.retailerEmail,
                Mensaje: payload.retailerMessage,
            };
            const response = await rootGetters['network/axios'].post(url, data);
        } catch (err) {
            throw Error(`SEND_MAIL_RETAILER ${err}`);
        }
    },
    async sendContact({ rootGetters }, contact: object): Promise<object> {
        try {
            const url = `send_mail_from_form/cantactanos/?country=${rootGetters[
                'external/countrySelected'
            ]?.alphaCode?.toUpperCase()}`;
            return await rootGetters['network/axios'].post(url, contact);
        } catch (err) {
            logger('SEND_CONTACT', err);
        }
    },

    async getPressReleases(
        { rootGetters },
        {
            page,
            type,
        }: {
            page: number;
            type?: TypePressReleases;
        },
    ): Promise<ResponsePaged<PressReleases[]>> {
        try {
            const { data } = await rootGetters['network/axios'].get(`v2/api/press-releases`, {
                params: {
                    page_size: 6,
                    page,
                    type,
                },
            });
            return data;
        } catch (err) {
            logger('GET_PRESS_RELEASES', err);
        }
    },
    async getPressDetailReleases({ rootGetters }, idNews: number): Promise<PressReleases> {
        try {
            const { data } = await rootGetters['network/axios'].get(`v2/api/press-releases-detail/${idNews}`);
            return data;
        } catch (err) {
            logger('GET_PRESS_DETAIL_RELEASES', err);
        }
    },
    ///TODO: IMPLEMENT IN STATE AN ADD MODEL
    async retailersShowRoom({ rootGetters }) {
        try {
            const url = `v2/api/retailer-show-room?country=${
                rootGetters['external/countrySelected']?.alphaCode ?? 'EC'
            }`;
            const response = await rootGetters['network/axios'].get(url);
            return response.data.results;
        } catch (err) {
            logger('GET_RETAILERS_SHOW_ROOM', err);
        }
    },
    async getPromotionsShowRoom({ rootGetters }): Promise<Array<PromotionInTipti>> {
        try {
            let page = 1;
            let isLastPage = false;
            const promotions: Array<PromotionInTipti> = [];
            const url = `v2/api/discount-show-room?country=${
                rootGetters['external/countrySelected']?.alphaCode ?? 'EC'
            }&page=${page}`;
            while (!isLastPage) {
                const { data } = await rootGetters['network/axios'].get(url);
                promotions.push(...data['results'].map((promotion: PromotionInTipti) => toPromotionInTipti(promotion)));
                data['next'] ? (isLastPage = false) : (isLastPage = true);
                page++;
            }
            return promotions;
        } catch (err) {
            throw Error(err);
        }
    },
    //Todo Pasar a servicio
    async getBannerHeaderHomePage({ rootGetters }): Promise<Array<BannerHomePage>> {
        try {
            const url = `v2/main-screen-banners/header?country=${
                rootGetters['external/countrySelected']?.alphaCode ?? 'EC'
            }`;
            const { data } = await rootGetters['network/axios'].get(url);
            return data;
        } catch (error) {
            return null;
        }
    },
    //Todo evitar await en loops
    async getBannersHomePage({ rootGetters }): Promise<Array<BannerHomePage>> {
        let page = 1;
        let isLastPage = false;
        const bannerHomeLanding: Array<BannerHomePage> = [];
        const url = `v2/main-screen-banners/main?country=${rootGetters['external/countrySelected']?.alphaCode ?? 'EC'}`;
        while (!isLastPage) {
            const { data } = await rootGetters['network/axios'].get(url);
            bannerHomeLanding.push(
                ...data['results'].map((bannerLanding: BannerHomePage) => toBannerHomePage(bannerLanding)),
            );
            data['next'] ? (isLastPage = false) : (isLastPage = true);
            page++;
        }
        return bannerHomeLanding;
    },
    //Todo evitar await en loops
    async getBannerspromotionsInTipti({ rootGetters }) {
        const url = `v2/main-screen-banner-promotions`;
        const {
            data: { results },
        } = await rootGetters['network/axios'].get(url);
        return results;
    },
    async getBannersPromotionsStories({ rootGetters }) {
        try {
            const url = `v2/main-screen-histories?country=${
                rootGetters['external/countrySelected']?.alphaCode ?? 'EC'
            }`;
            const response = await rootGetters['network/axios'].get(url);
            return response.data.results;
        } catch (err) {
            logger('GET_RETAILERS_SHOW_ROOM', err);
        }
    },
    async getBannersStores(
        { rootGetters },
        payload: {
            page: number;
        },
    ) {
        try {
            const url = `v2/main-screen-order-shops?&page=${payload.page ?? 1}&country=${
                rootGetters['external/countrySelected']?.alphaCode ?? 'EC'
            }`;
            const response = await rootGetters['network/axios'].get(url);
            return response.data.results;
        } catch (err) {
            logger('GET_RETAILERS_SHOW_ROOM', err);
        }
    },
    async getCountries({ rootGetters, commit }): Promise<void> {
        try {
            const _url = 'countries/';
            const { data } = await rootGetters['network/axios'].get(_url);
            commit(
                'countries',
                data.map((countries: CountriesModel) => toCountriesModel(countries)),
            );
        } catch (err) {
            logger('GET_COUNTRIES', err);
        }
    },
    async getCountryPhones({ rootGetters, commit }): Promise<void> {
        try {
            const _url = 'countries/phones';
            const response = await rootGetters['network/axios'].get(_url);
            const countries: Array<CountryCodeModel> = response.data
                ? response.data.map((coupon: CountryCodeModel) => CountryCodeModel.fromJson(coupon))
                : [];
            commit('countryCodes', countries);
        } catch (err) {
            logger('GET_COUNTRY_PHONE', err);
            throw err.response;
        }
    },
};
