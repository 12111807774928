import { defineStore } from 'pinia';
import { CatalogProduct } from '@/modules/catalogs/models/CatalogProduct';
import { ProductModel } from '@/models/product/ProductModel';

export const useCatalogFicoaStore = defineStore('ficoa', {
    state: (): {
        products: CatalogProduct[];
    } => ({
        products: [],
    }),
    actions: {
        add(product: ProductModel) {
            const localProductIndex: number = this.products.findIndex(
                (localProduct: ProductModel) => localProduct?.id == product.id,
            );
            localProductIndex === -1 ? this.products.push(product) : (this.products[localProductIndex] = product);
        },
        remove(product: ProductModel) {
            this.products = this.products.filter((actualProduct: ProductModel) => actualProduct.id !== product.id);
        },
    },
});
