import { CountriesModel, toCountriesModel } from '../external/CountriesModel';

class ZoneModel {
    readonly id: number;
    readonly name: string;
    readonly country: CountriesModel;

    constructor(id: number, name?: string, country?: CountriesModel) {
        this.id = id;
        this.name = name;
        this.country = country;
    }
}
const toZoneModel = (data: object): ZoneModel => {
    return data ? new ZoneModel(data['id'], data['name'] ?? '', toCountriesModel(data['country'])) : null;
};

export { ZoneModel, toZoneModel };
