<template>
    <modal-manager
        :has-header="false"
        :has-padding-btn="true"
        :is-disabled="false"
        :is-loading="false"
        :is-modal="true"
        :show-bottom-sheet-title="false"
        :show-buttons="false"
        padding=".5rem"
        @on-close="onClose"
        @on-dismiss="onClose"
    >
        <template v-slot:content>
            <div class="benefit">
                <header class="benefit-header">
                    <div class="benefit-header__helper" :style="style"></div>
                    <h2 class="benefit-header__name">{{ goal.giveProduct.name }}</h2>
                    <icon-delete
                        class="benefit-header__icon cursor-pointer"
                        ref="iconDelete"
                        :icon-name="$t('icon.close')"
                        :size="0.6"
                        color="red"
                        @click="onClose"
                    />
                </header>
                <div class="product-benefit">
                    <base-image
                        class="product-benefit__img"
                        :aspect-ratio="isMobile ? 0.7 : 1"
                        :has-padding="false"
                        :source="goal.giveProduct.image"
                        alternativeText=""
                    />
                    <div class="benefit-detail">
                        <h3 class="benefit-detail__special">
                            {{ isMobile ? $t('general.special-price') : $t('general.special-price').toUpperCase() }}
                        </h3>
                        <h4 class="benefit-detail__new-price" v-currency="goal.giveProduct?.current_price" />
                        <h3 class="benefit-detail__before">{{ $t('general.before') }}:</h3>
                        <h4 class="benefit-detail__old-price" v-currency="goal.giveProduct?.original_price" />
                    </div>
                </div>
                <footer v-if="isBrandingProduct" class="benefit-footer">
                    <span class="benefit-footer__free-product">{{ $t('txt.goal__gift_product') }}</span>
                </footer>
                <footer
                    v-else
                    class="benefit-footer benefit-footer__actions"
                    :class="{ 'benefit-footer--mobile': isMobile }"
                >
                    <base-btn
                        class="benefit-footer__btn"
                        :is-disabled="isLoading"
                        :is-loading="isLoading"
                        bgColor="orange"
                        color="#FF9012"
                        @click="onAccept"
                    >
                        <icon-cart :size="0.6" color="white" />
                        <span class="benefit-footer__btn-text">{{ $t('cta.add_product') }}</span>
                    </base-btn>
                    <span class="benefit-footer__text cursor-pointer" @click="onClose">
                        {{ $t('general.no-thanks') }}
                    </span>
                </footer>
            </div>
        </template>
    </modal-manager>
</template>

<script lang="ts">
import BaseImage from '@/components/logos/Image.vue';
import { isMobileBrowser } from '@/utils/utils';
import BaseBtn from '@/components/buttons/BaseBtn.vue';
import IconCart from '@/components/icons/IconCart.vue';
import { mapGetters } from 'vuex';
import { ProductModel } from '@/models/product/ProductModel';
import { logger } from '@/utils/logger';
import IconDelete from '@/components/icons/IconDelete.vue';
import ModalManager from '@/components/alerts/ModalManager/ModalManager.vue';
import { CartBarGoals } from '@/models/cart/CartBarGoals';

export default {
    name: 'product-benefits',
    emits: ['on-close'],
    components: {
        IconDelete,
        BaseImage,
        ModalManager,
        BaseBtn,
        IconCart,
    },
    data() {
        return {
            isLoading: false,
            actualProduct: undefined,
            origin: undefined,
            benefit: undefined,
            widthIcon: undefined,
        };
    },
    props: {
        goal: {
            type: [CartBarGoals, Object],
            required: true,
        },
        retailerId: {
            type: Number,
            required: true,
        },
        retailerName: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            countrySelected: 'external/countrySelected',
            products: 'cart/products',
        }),
        isMobile(): boolean {
            return isMobileBrowser();
        },
        currency(): string {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        isBrandingProduct(): boolean {
            return this.goal.discountTriggerType.toUpperCase() === 'BRANDING';
        },
        style(): object {
            return {
                width: this.widthIcon,
            };
        },
    },
    methods: {
        setProduct(): void {
            const productInCart: ProductModel = this.products.find(
                product => product.id === this.goal.giveProduct.stockitem_id,
            );
            this.actualProduct = productInCart ?? {
                id: this.goal.giveProduct.stockitem_id,
                name: this.goal.giveProduct.name,
                finalPrice: this.goal.giveProduct.current_price,
                productQuantity: 0,
            };
            this.actualProduct.productQuantity += 1;
        },
        prepareAnalytics(isAccepted: boolean): void {
            if (!isAccepted || this.isBrandingProduct) this.setProduct();
            const eventName = this.isBrandingProduct ? 'Product As Benefit Impression' : 'Product As Benefit';
            const retail = {
                retail: this.retailerName,
                retail_id: this.retailerId,
            };
            const origin = {
                screen: this.$route.path,
                section: this.isMobile ? 'BOTTOM SHEET' : 'MODAL',
            };
            const benefit = {
                productBenefit: this.actualProduct,
                eventName,
                retail,
            };
            if (!this.isBrandingProduct) benefit['isAccepted'] = isAccepted;
            this.benefit = benefit;
            this.origin = origin;
        },
        sendAnalytics(): void {
            this.$store.dispatch('segment/giveBenefit', {
                benefit: this.benefit,
                origin: this.origin,
            });
            this.$emit('on-close');
        },
        async onAccept(): Promise<void> {
            try {
                if (this.isLoading) return;
                this.isLoading = true;
                this.setProduct();
                await this.$store.dispatch('cart/addEditProduct', {
                    product: this.actualProduct,
                    screen: this.$route.path,
                    section: this.section,
                });
                await this.$store.dispatch('cart/fetchCart', { needFetchGoals: true });
                this.prepareAnalytics(true);
                this.sendAnalytics();
            } catch (err) {
                logger('ADD_GIFT_PRODUCT', err);
            } finally {
                this.isLoading = false;
                this.$emit('on-close');
            }
        },
        onClose(): void {
            if (this.isLoading) return;
            this.prepareAnalytics(false);
            this.sendAnalytics();
        },
        matchHeight() {
            this.widthIcon = this.$refs.iconDelete.width + 'px';
        },
    },
    mounted() {
        this.$nextTick(function() {
            this.matchHeight();
        });
    },
};
</script>

<style lang="scss" scoped>
@import 'product-benefits.scss';
</style>
