<template>
    <base-icon
        :height="height"
        :icon-name="$t('icon.tipti_card')"
        :icon-color="color"
        :width="width"
        viewBox="0 0 40 27"
    >
        <path
            d="M4.73545 26.5237H34.9078C37.5213 26.5237 39.6432 24.4018 39.6432 21.7882V4.73545C39.6518 2.1219 37.5213 0 34.9078 0H4.73545C2.1219 0 0 2.1219 0 4.73545V21.7796C0 24.3932 2.1219 26.5237 4.73545 26.5237ZM2.62218 4.73545C2.62218 3.57099 3.57099 2.62218 4.73545 2.62218H34.9078C36.0722 2.62218 37.021 3.57099 37.021 4.73545V17.5186H2.62218V4.73545ZM2.62218 21.2707H37.0297V21.7882C37.0297 22.9527 36.0808 23.9015 34.9164 23.9015H4.73545C3.57099 23.9015 2.62218 22.9527 2.62218 21.7882V19.9079V21.2707Z"
        />
        <path
            d="M17.9245 8.27186C17.9935 7.99584 17.683 7.62494 17.3035 7.69394C16.9153 7.76295 16.5875 7.80608 16.5013 8.1856C16.4236 8.54788 17.7692 8.93603 17.9245 8.27186Z"
        />
        <path
            d="M21.4324 8.1855C21.3461 8.13374 20.613 8.13374 20.3024 8.41839C20.0609 8.64265 19.6383 9.44483 19.3019 10.1953C19.5434 9.3672 19.7418 8.76341 19.7763 8.59952C19.8453 8.29763 19.4485 8.36663 19.1639 8.41839C18.9655 8.45289 18.6118 9.28957 18.3013 10.3505C18.2927 10.3678 18.2754 10.385 18.2668 10.4109C18.1719 10.6093 17.318 11.9721 16.9643 12.1878C16.8953 11.929 16.8522 11.4632 17.0333 10.6783C17.111 10.3591 17.2576 9.91061 17.318 9.64322C17.4042 9.27232 16.4985 9.16019 16.4209 9.48796C16.3778 9.6691 16.2225 9.99687 16.0241 10.8508C15.8689 11.5322 15.6705 12.8864 16.6711 12.7053C17.1368 12.6277 17.9045 11.3252 18.146 10.9198C17.663 12.7916 17.4129 14.9997 18.3444 14.8358C18.7326 14.7668 18.2409 14.4649 18.6808 12.5673C18.7671 12.1791 18.8706 11.7824 18.9741 11.4115C19.3019 10.6869 20.2334 8.68578 20.7251 8.59952C20.9839 8.5564 20.3542 11.8514 19.3967 12.0153C18.9655 12.0929 19.3364 12.2913 19.7849 12.205C21.4669 11.9118 22.2691 8.64265 21.4324 8.1855Z"
        />
        <path
            d="M24.1685 12.7485C23.2456 12.9038 23.3405 11.7566 23.582 10.8509C23.8063 9.98833 23.9788 9.66918 24.0219 9.48805C24.1082 9.16028 24.9966 9.30691 24.9017 9.67781C24.8327 9.9452 24.6688 10.3937 24.5826 10.7129C24.3756 11.5064 24.4014 11.9808 24.4618 12.2396C24.4532 12.231 24.6084 12.6709 24.1685 12.7485Z"
        />
        <path
            d="M25.4207 8.6338C25.4983 8.34915 25.2051 7.96963 24.8342 8.02138C24.4546 8.08176 24.1269 8.10764 24.0234 8.48716C23.9198 8.85806 25.2396 9.29797 25.4207 8.6338Z"
        />
        <path
            d="M19.123 12.7571C19.123 12.7571 23.7464 15.155 25.9286 11.7307C25.9286 11.7307 26.1357 11.308 26.4979 11.0148C27.0068 10.6007 27.6193 11.1614 27.2397 11.7911C26.2995 13.3178 23.2288 15.4914 19.123 12.7571Z"
        />
        <path
            d="M25.6614 6.16711C25.3337 6.16711 24.8506 6.21886 24.3158 6.31374C24.3935 6.0981 24.4452 5.94284 24.4625 5.84796C24.5832 5.29592 23.7465 5.01128 23.5913 5.7272C23.5654 5.84796 23.4447 6.14985 23.2808 6.55526C23.0651 6.61564 23.1255 6.59838 22.4441 6.8399C22.4441 6.8399 21.6074 7.20218 21.4349 7.56445C21.4349 7.56445 21.34 7.90085 21.7627 7.71971C22.4441 7.43507 22.5045 7.42644 22.9789 7.29706C22.5993 8.26312 22.1077 9.50521 21.8834 10.5144C21.5298 12.1274 22.0387 12.8519 22.4958 12.7657C22.9616 12.6794 22.3233 12.6449 22.7546 10.6783C22.9702 9.69497 23.7724 7.75421 24.0484 7.02104C24.7557 6.8744 25.4199 6.79677 25.8339 6.79677C26.1013 6.79677 26.3083 6.85715 26.2997 6.75365C26.2997 6.57251 25.9892 6.16711 25.6614 6.16711Z"
        />
        <path
            d="M19.2951 5.59794C19.1226 5.4168 18.907 5.36504 18.6655 5.34779C18.631 5.34779 18.5878 5.34779 18.5533 5.33917C17.9927 5.32192 17.4234 5.43405 16.8713 5.54618C16.0778 5.71007 14.4217 6.23623 13.1451 7.15917C12.6793 7.49556 12.4033 7.96135 12.3256 8.28912C12.2825 8.47026 12.2739 8.64277 12.3084 8.82391C12.3343 9.01367 12.4292 9.16893 12.5758 9.28969C12.6534 9.35007 12.8346 9.38457 12.8777 9.26381C12.9208 9.1603 12.7914 9.09992 12.7397 9.03092C12.6707 8.94466 12.6707 8.80665 12.7224 8.71177C12.8087 8.53926 12.9984 8.39263 13.1537 8.28049C13.6195 7.93547 14.1198 7.61632 14.6373 7.35756C14.3182 8.70315 13.861 10.5835 13.7834 11.748C13.7661 12.0499 13.7575 12.3345 13.9214 12.5502C13.9904 12.645 14.0853 12.7054 14.1974 12.7313C14.3613 12.7658 14.6459 12.7313 14.7322 12.576C14.8184 12.4121 14.7581 12.2224 14.8098 11.7566C14.8357 11.3426 14.8875 10.9458 14.9478 10.5318C15.1203 9.30694 15.5344 7.83196 15.7414 7.05566L15.819 6.84865C16.5349 6.58988 17.2767 6.34836 18.0358 6.24485C18.3032 6.21035 18.5706 6.20173 18.838 6.21898C18.976 6.2276 19.114 6.25348 19.2434 6.28798C19.3296 6.30523 19.459 6.33111 19.4935 6.21898C19.5539 6.01196 19.459 5.76182 19.2951 5.59794Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconTiptiCard',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C3C3C3',
        },
    },
    computed: {
        width(): number {
            return 20 * this.size;
        },
        height(): number {
            return 15 * this.size;
        },
    },
};
</script>
