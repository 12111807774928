<template>
    <base-modal
        v-if="showTermsAndConditions"
        :has-back-arrow="false"
        header-text-color="#ff9012"
        :title="$t('txt.prime__alert-terms')"
        @on-close="$emit('on-close')"
    >
        <alert-terms-and-conditions
            @snack-bar-data="snackData"
            @on-close="$emit('on-close')"
            v-if="showTermsAndConditions"
            :onlyView="onlyView"
        />
    </base-modal>
</template>

<script lang="ts">
import AlertTermsAndConditions from '@/components/alerts/alertPrimeTermsAndCondition/AlertTerminsAndConditions.vue';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';

export default {
    name: 'view-terms-and-condition',
    components: { AlertTermsAndConditions, BaseModal },
    props: {
        showTermsAndConditions: {
            type: Boolean,
            required: true,
        },
        onlyView: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        snackData(data) {
            this.$emit('snack-bar-data', data);
        },
    },
    emits: ['on-close', 'snack-bar-data'],
};
</script>
