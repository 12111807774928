<template>
    <alert-manager
        v-if="showAlert && !showTermsAndConditions"
        :accept-button-text="benefitsAndPromotionsMethod?.acceptButtonText"
        :is-disabled="isLoading"
        :is-loading="isLoading"
        :only-confirmation-btn="true"
        :show-buttons="true"
        :title="benefitsAndPromotionsMethod?.title ?? ''"
        @on-accept="closeAlert(true)"
        @on-dismiss="closeAlert(false)"
        @on-close="closeAlert(false)"
    >
        <template #content>
            <section class="share-data__content">
                <div v-if="benefitsAndPromotionsMethod?.image?.length">
                    <img class="share-data__img" :src="benefitsAndPromotionsMethod?.image" alt="" />
                </div>
                <div v-if="benefitsAndPromotionsMethod?.discountName?.length" class="share-data__doted-section">
                    <h2 class="share-data__benefits">
                        {{ benefitsAndPromotionsMethod?.discountName }}
                    </h2>
                </div>
                <div v-if="benefitsAndPromotionsMethod?.description?.length" class="share-data__message">
                    <p>
                        {{ benefitsAndPromotionsMethod?.description }}
                    </p>
                </div>
                <p class="share-data__terms" @click="showTermsAndConditions = true">
                    {{ $t('general.terms-and-conditions') }}
                </p>
            </section>
        </template>
    </alert-manager>
    <general-alert
        v-if="showAlert && showTermsAndConditions"
        :accent-header="true"
        :only-confirmation-btn="true"
        :title="benefitsAndPromotionsMethod?.title ?? ''"
        message=""
        @on-close="showTermsAndConditions = false"
        @on-accept="showTermsAndConditions = false"
    >
        <template #content>
            <PdfFrame
                class="pdf__content"
                :src="benefitsAndPromotionsMethod.termsAndConditions ?? $t('txt.share-data__terms-and-conditions')"
            />
        </template>
    </general-alert>
    <snack-bar
        :body="snackBarMessage"
        :is-active="showSnackBar"
        :is-failure="!isSuccess"
        :is-success="isSuccess"
        :title="snackBarTitle"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import SnackBar from '../snackBar/SnackBar.vue';
import AlertManager from '../alertManager/AlertManager.vue';
import { logger } from '@/utils/logger';
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import PdfFrame from '@/modules/core/components/pdf/PdfFrame.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'AlertShareData',
    components: {
        PdfFrame,
        GeneralAlert,
        SnackBar,
        AlertManager,
    },
    setup() {
        const { retailerSelected } = useRetailer();

        return {
            retailerSelected,
        };
    },
    data() {
        return {
            showTermsAndConditions: false,
            canShowAlert: true,
            isLoading: false,
            showSnackBar: false,
            isSuccess: false,
            snackBarMessage: '',
            snackBarTitle: '',
        };
    },
    computed: {
        ...mapGetters({
            cart: 'cart/cart',
            benefitsAndPromotionsMethod: 'shareData/benefitsAndPromotions',
        }),
        showAlert() {
            if (!this.benefitsAndPromotionsMethod) return false;
            if (!this.retailerSelected?.id) return false;
            if (this.benefitsAndPromotionsMethod?.askPermission === false) return false;
            if (this.$route.name === appRoutesMap.home) return false;
            //*** this condition was put here for control when page is reloaded
            const retailerFromReloadPage = this.cart?.retailerIds?.filter(
                (retailer: Array<number>) => retailer === this.retailerSelected?.id,
            );
            if (retailerFromReloadPage.length) return true;
            return true;
        },
    },
    methods: {
        closeAlert(accepted: boolean): void {
            try {
                this.isLoading = true;
                this.$store.dispatch('shareData/sendPermissionToShareData', {
                    retailerId: this.retailerSelected.id,
                    approved: accepted,
                });
                if (accepted) {
                    this.snackBarTitle = this.$t('txt.profile-options__share-data');
                    this.snackBarMessage = this.$t('txt.profile-options__share-data-activated');
                    this.showSnackBar = true;
                    this.isSuccess = true;
                }
                this.benefitsAndPromotionsMethod.askPermission = false;
                this.showAlert = false;
            } catch (err) {
                logger('ERROR', err);
                this.showSnackBar = true;
                this.isSuccess = false;
                this.snackBarMessage = this.$t('general.try-again');
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'alert-share-data';
</style>
