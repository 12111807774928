<template>
    <section class="grid-credit-card">
        <form class="credit-card-form" @submit.prevent>
            <tipti-input-number
                v-model="number"
                :label="$t('txt.credit-card__card-number')"
                :maxlength="25"
                :placeholder="cardPlaceholder"
                autocomplete="cc-number"
                has-focus
                is-required
            />
            <div class="min-gap row-inputs">
                <tipti-input-number
                    v-model="date"
                    class="input-field-card"
                    :label="$t('txt.credit-card__expired-date')"
                    :maxlength="5"
                    :placeholder="$t('general.month-year')"
                    autocomplete="cc-exp"
                    is-required
                    type="text"
                />
                <tipti-input-number
                    v-model.trim="code"
                    class="input-field-card"
                    :label="$t('txt.credit-card__code')"
                    :maxlength="4"
                    :placeholder="'&#9679;'.repeat(3)"
                    autocomplete="cc-csc"
                    is-required
                />
                <i class="icon-credit-card">
                    <icon-search-card />
                </i>
            </div>
            <tipti-input-text
                v-model.trim="owner"
                :label="$t('txt.credit-card__owner')"
                :placeholder="$t('txt.credit-card__as-on-card')"
                autocomplete="cc-name"
                is-required
                label-id="credit-card"
                @blur="$forceUpdate()"
            />
            <tipti-input-text
                v-model.trim="alias"
                :label="$t('txt.credit-card__alias')"
                :placeholder="$t('txt.credit-card__alias_placeholder')"
                autocomplete="cc-type"
                is-required
                @blur="$forceUpdate()"
            />
            <tipti-input-number
                v-model.trim="phone"
                :label="$t('txt.credit-card__phone-number')"
                :label-id="$t('txt.credit-card__phone-number')"
                is-required
                placeholder="0999999999"
                type="tel"
            />
            <div class="default-card">
                <p class="default-card__description">
                    {{ $t("general['set-as-default']") }}
                </p>
                <toggle v-model="isDefault" />
            </div>
        </form>
        <div class="credit-card-icon">
            <div class="credit-card-replica">
                <div class="credit-card-replica__front">
                    <div class="card_front_content">
                        <div class="card_front_content__icons">
                            <transition-fade :time="500">
                                <img
                                    v-if="cardBrandData?.image?.length"
                                    class="card-brand"
                                    :alt="cardBrandData.brand"
                                    :src="cardBrandData?.image"
                                />
                                <div v-else class="card-brand" />
                            </transition-fade>
                            <i>
                                <icon-chip-card />
                            </i>
                        </div>
                        <TransitionGroup class="card_front_content__group" name="list" tag="ul">
                            <li
                                v-for="(key, index) in finalNumberCard"
                                :key="index"
                                class="card_front_content__digits"
                                :class="{ 'card_front_content__digits--margin': (index + 1) % 4 === 0 }"
                            >
                                {{ key }}
                            </li>
                        </TransitionGroup>
                        <ul class="card_front_content__group card_front_content__group--left">
                            <li
                                v-for="(key, index) in dateToShowInFakeCard"
                                :key="index + '-' + key"
                                class="card_front_content__date date-value"
                            >
                                {{ key }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="credit-card-replica__back">
                    <div class="card_back_content">
                        <div class="card_back_content__brand" />
                        <div class="card_back_content__codes">
                            <div class="card_back_content__space" />
                            <div class="card_back_content__code">
                                {{ $t('txt.credit-card__code') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <p class="txt-description">
        {{
            $t("txt['credit-card__once-you-pay']", {
                amount: `${currency}${debitAmount}`,
            })
        }}
    </p>
    <btn-solid
        class="add-credit-card__btn"
        :is-disabled="!isFormCompleted"
        :is-loading="addingState === 'LOADING'"
        :txt="$t('txt.credit-card__add-card')"
        type="submit"
        @click="excAddCard"
    />
    <snack-bar
        :body="alertMessage"
        :is-active="addingState === 'SUCCESS' || addingState === 'ERROR'"
        :is-failure="addingState === 'ERROR'"
        :is-success="addingState === 'SUCCESS'"
        @on-snackbar-close="addingState = 'NONE'"
    />
</template>

<script lang="ts" setup>
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import { useCheckoutEvents } from '@/views/checkout/composables/useCheckoutEvents';
import { useAddCreditCard } from '@/views/creditCards/useCreditCard';
import TiptiInputNumber from '@/components/inputs/primary/normal/TiptiInputNumbers.vue';
import IconSearchCard from '@/components/icons/IconSearchCard.vue';
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';
import Toggle from '@/components/toggle/Toggle.vue';
import IconChipCard from '@/components/icons/IconChipCard.vue';
import { useUser } from '@/composables/useUser';
import { ref } from 'vue';
import TransitionFade from '@/modules/core/components/transition/TransitionVerticalFade.vue';

const { currency } = useUser();

const emits = defineEmits(['on-return', 'on-new-credit-card']);

const cardPlaceholder = 'X'
    .repeat(16)
    .match(/\w{1,4}/g)
    .join(' - ');

const {
    number,
    isDefault,
    date,
    addCreditCard,
    isFormCompleted,
    finalNumberCard,
    dateToShowInFakeCard,
    alias,
    phone,
    code,
    owner,
    debitAmount,
    addingState,
    cardBrandData,
} = useAddCreditCard();

const alertMessage = ref<string>('');

const excAddCard = async () => {
    const { id, message } = await addCreditCard();
    alertMessage.value = message;
    if (id) emits('on-return', id);
};
const { creditCardEvent } = useCheckoutEvents();

emits('on-new-credit-card');
creditCardEvent({ isForm: true });
</script>

<style lang="scss" scoped>
@import './body-add-credit-cards.scss';
</style>
