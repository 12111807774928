<template>
    <base-icon
        :height="height"
        :icon-name="$t('icon.information')"
        :width="width"
        icon-color="none"
        viewBox="0 0 25 24"
    >
        <path
            d="M7.30956 11.44H6.70533C6.23369 11.44 5.86527 11.0274 5.90959 10.5557C5.95379 10.1431 6.3369 9.83361 6.74964 9.83361H7.45698C7.72219 9.83361 7.9433 10.0252 8.00222 10.2757C8.01692 10.3494 8.07592 10.3936 8.14963 10.3936H8.96018C9.04859 10.3936 9.12229 10.3199 9.12229 10.2315C9.03389 9.42099 8.38545 8.78736 7.56021 8.72836V7.93262C7.56021 7.84421 7.48651 7.77051 7.3981 7.77051H6.60237C6.51396 7.77051 6.44026 7.84421 6.44026 7.93262V8.74317C5.4382 8.87578 4.68655 9.77472 4.78976 10.8358C4.89296 11.8379 5.77713 12.56 6.77928 12.56H7.29513C7.76676 12.56 8.13518 12.9726 8.09086 13.4443C8.04666 13.8569 7.66355 14.1664 7.25081 14.1664H6.54347C6.27826 14.1664 6.05715 13.9748 5.99823 13.7243C5.98353 13.6506 5.92453 13.6064 5.85082 13.6064H5.04027C4.95186 13.6064 4.86346 13.6801 4.87816 13.7685C4.96656 14.579 5.615 15.2127 6.44024 15.2717V16.0674C6.44024 16.1558 6.51394 16.2295 6.60235 16.2295H7.39808C7.48649 16.2295 7.56019 16.1558 7.56019 16.0674V15.2422C8.41493 15.0948 9.12227 14.4316 9.21069 13.5475C9.3285 12.4127 8.42954 11.4401 7.30959 11.4401L7.30956 11.44Z"
            fill="#606060"
        />
        <path
            clip-rule="evenodd"
            d="M17.384 6.48939C18.1233 7.35042 19.2312 7.89758 20.4696 7.89758C22.6955 7.89758 24.5 6.12965 24.5 3.94879C24.5 1.76793 22.6955 0 20.4696 0C18.2436 0 16.4392 1.76793 16.4392 3.94879C16.4392 4.37634 16.5085 4.78802 16.6368 5.17361L16.6409 5.18579L16.6045 5.20785L12.5124 7.68926C11.2302 6.05293 9.23622 5 7 5C3.13895 5 0 8.13895 0 12C0 15.8611 3.13895 19 7 19C9.23622 19 11.2302 17.9471 12.5124 16.3107L16.6046 18.7921L16.6409 18.8141L16.6369 18.8263C16.5086 19.2119 16.4392 19.6236 16.4392 20.0511C16.4392 22.232 18.2437 23.9999 20.4696 23.9999C22.6956 23.9999 24.5 22.232 24.5 20.0511C24.5 17.8703 22.6956 16.1023 20.4696 16.1023C19.2313 16.1023 18.1234 16.6495 17.3841 17.5105L17.3758 17.5202L14.0172 15.4836L13.3008 15.0493C13.7487 14.1271 14 13.0925 14 12C14 10.9075 13.7487 9.87284 13.3008 8.95073L14.0172 8.51636L17.3757 6.47976L17.384 6.48939ZM18.0626 4.70754L18.2104 5.14696L18.5169 5.50619C18.9794 6.0483 19.6781 6.39758 20.4696 6.39758C21.8961 6.39758 23 5.27244 23 3.94879C23 2.62514 21.8961 1.5 20.4696 1.5C19.043 1.5 17.9392 2.62514 17.9392 3.94879C17.9392 4.21588 17.9828 4.47031 18.0626 4.70754ZM7 17.6147C3.90526 17.6147 1.38526 15.0948 1.38526 12C1.38526 8.90521 3.90521 6.38526 7 6.38526C10.0948 6.38526 12.6147 8.90521 12.6147 12C12.6147 15.0948 10.0948 17.6147 7 17.6147ZM18.0627 19.2924L18.2105 18.853L18.517 18.4937C18.9795 17.9516 19.6782 17.6023 20.4696 17.6023C21.8962 17.6023 23 18.7275 23 20.0511C23 21.3748 21.8962 22.4999 20.4696 22.4999C19.0431 22.4999 17.9392 21.3748 17.9392 20.0511C17.9392 19.784 17.9829 19.5296 18.0627 19.2924Z"
            fill="#606060"
            fill-rule="evenodd"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconPaymentPlanes',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 25 * this.size;
        },
        height(): number {
            return 24 * this.size;
        },
    },
};
</script>
