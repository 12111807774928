<template>
    <base-icon
        :icon-name="$t('icon.arrow_shaft')"
        :width="width"
        :height="height"
        viewBox="0 0 25 26"
        :iconColor="color"
    >
        <path
            d="M24.4035 15.6111L23.0628 2.72135C23.0153 1.97808 22.6516 1.31758 22.0601 0.814957C21.4686 0.312335 20.7649 0.0658054 20.0169 0.13314L7.05257 0.86754C5.51478 0.954966 4.39329 2.27479 4.55523 3.80653C4.71717 5.33827 6.09056 6.50527 7.62835 6.41785L13.8356 6.05724L1.29109 20.8202C0.314121 21.97 0.494387 23.7281 1.6978 24.7507C2.90121 25.7733 4.66544 25.6674 5.64241 24.5177L18.1869 9.75468L18.8272 15.9456C18.9892 17.4773 20.3625 18.6443 21.9003 18.5569C23.4439 18.4627 24.5596 17.1496 24.4035 15.6111Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-arrow-shaft',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 25 * this.size;
        },
        height(): number {
            return 26 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
