import { WrapperResponse } from '@/modules/core/decorators/wrapperResponse';
import { CategoryModel, toCategoryModel } from '@/models/product/CategoryModel';
import { ProductModel, toProductModel } from '@/models/product/ProductModel';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';

class BannerService {
    @WrapperResponse({ rethrowError: false })
    async getCategoriesInBanner({
        retailerId,
        bannerId,
    }: {
        retailerId: number;
        bannerId: number;
    }): Promise<CategoryModel[]> {
        const url = `v2/retailer/${retailerId}/banners/${bannerId}/product_categories`;
        const { data } = await axiosInstance().get(url);
        return data?.map((categoryInModel) => toCategoryModel(categoryInModel)) ?? [];
    }

    @WrapperResponse({ rethrowError: false })
    async getProductsInBanner({
        retailerId,
        bannerId,
        categoryId,
        page,
    }: {
        retailerId: number;
        bannerId: number;
        categoryId: number;
        page: number;
    }): Promise<{ categoryId: number; products: ProductModel[] }> {
        try {
            const url = `v2/retailer/${retailerId}/banners/${bannerId}/detail?page=${page ?? 1}&category=${categoryId}`;
            const { data } = await axiosInstance().get(url);
            return {
                categoryId,
                products: data?.['results'].map((productInModel) => toProductModel(productInModel)) ?? [],
            };
        } catch (error) {
            return {
                categoryId,
                products: [],
            };
        }
    }
}

export const bannerService = new BannerService();
