<template>
    <carousel-base
        class="retailer-filter"
        :background-arrow="'#ff9012'"
        :color-arrow="'#fff'"
        :items="retailersTypeFilters"
        :skeletons="6"
        :state="state"
        carousel-gap="1.25rem"
    >
        <template #item="{ item }">
            <the-retailer-category :filter="item" />
        </template>
        <template #skeleton>
            <div class="skeleton-filter__body">
                <base-bone />
            </div>
        </template>
    </carousel-base>
</template>

<script lang="ts" setup>
import TheRetailerCategory from '@/modules/retailers/components/retailerFilter/TheRetailerCategory.vue';
import CarouselBase from '@/components/carousels/components/CarouselBase.vue';
import { useApp } from '@/composables/useApp';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { ref } from 'vue';
import { RequestState } from '@/modules/core/types/WorkingState';
import BaseBone from '@/components/skeletons/BaseBone.vue';

const { $store } = useApp();
const { retailersTypeFilters } = useRetailer();
const state = ref<RequestState>('LOADING');

const emit = defineEmits<{
    (e: 'loaded'): void;
    (e: 'error'): void;
}>();
const init = async () => {
    try {
        await $store.dispatch('retailers/retailersType');
        emit('loaded');
        state.value = 'LOAD-ENDED';
    } catch (e) {
        emit('error');
    }
};
init();
</script>

<style lang="scss" scoped>
@import '@/assets/scss';

.retailer-filter {
    &__content {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: min-content;
        column-gap: $margin-sm;
    }
}

.skeleton-filter__title {
    height: 1.5rem;
    width: 10rem;
}

.skeleton-filter__body {
    width: 5rem;
    height: 5rem;
}

.retailers__help {
    @include body2;
    font-weight: 600;
    margin: $margin-md $margin-none;

    &--skeleton {
        width: 5rem;
        height: 2rem;
        margin: $margin-md $margin-none;
    }
}
</style>
