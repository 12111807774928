<template>
    <section class="hours-container">
        <no-content
            v-if="!daySchedules.length"
            :description="$t('txt.schelude__no-schedule-subtitle')"
            :title="$t('txt.schelude__no-schedule-title')"
        >
            <icon-afflicted-shop-bag color="#c1c1c1" />
        </no-content>
        <template v-else>
            <p class="hours-container__section">{{ $t('general.hour') }}:</p>
            <TheDeliveryDayOption
                v-for="(schedule, index) in daySchedules"
                :key="index"
                :is-selected="schedule.first_hour === selection.first_hour"
                :schedule="schedule"
                @select="updateSelection"
            />
        </template>
    </section>
</template>

<script lang="ts" setup>
import NoContent from '@/components/noContent/NoContent.vue';
import IconAfflictedShopBag from '@/components/icons/IconAfflictedShopBag.vue';
import { ScheduleModel } from '@/models/retailers/HourModel';
import { PropType } from 'vue';
import TheDeliveryDayOption from '@/views/checkout/components/schedules/TheDeliveryDayOption.vue';

const selection = defineModel({
    type: Object as PropType<ScheduleModel>,
    required: true,
});

defineProps({
    daySchedules: {
        type: Array<ScheduleModel>,
        required: true,
    },
});

const $emit = defineEmits<(e: 'select', value: ScheduleModel) => void>();

const updateSelection = (value: ScheduleModel) => {
    selection.value = value;
};
</script>

<style lang="scss" scoped>
@import 'hour-selector';
</style>
