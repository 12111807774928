import { computed } from 'vue';
import { CustomCategories } from '@/modules/core/errors/CustomCategories';
import { CategoryModel } from '@/models/product/CategoryModel';
import { useApp } from '@/composables/useApp';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export const useProductFetcher = () => {
    const { $route, $store } = useApp();
    const { retailerSelected } = useRetailer();

    const searchOn = computed(() => $route.params.searchOn as string);
    const categorySelected = computed<CategoryModel>(() => $store.getters['categories/getCategorySelected']);
    const subcategorySelected = computed<CategoryModel>(() => $store.getters['categories/getSubcategorySelected']);
    const subcategoriesList = computed<CategoryModel[]>(() => $store.getters['categories/subcategoriesList']);

    const isDiscount = computed(() => categorySelected.value?.isDiscount);

    const seeMoreSection = computed(() => {
        const customCategory: { name: CustomCategories; label: string } = $store.getters['categories/customCategory'];
        return {
            isYourProducts: customCategory?.name === CustomCategories.YOUR_PRODUCTS,
            isPrediction: customCategory?.name === CustomCategories.PREDICTION_PRODUCTS,
            isNewProduct: customCategory?.name === CustomCategories.NEW_PRODUCTS,
            isRecommendSovi: customCategory?.name === CustomCategories.RECOMMENDED_PRODUCTS,
            categoryId: $route.query?.categoryId ?? categorySelected.value?.id,
            subCategoryId: $route.query?.subCategoryId ?? subcategorySelected.value?.id,
            isPromotion: !!$route.query.isPromotion,
            isDiscount: isDiscount.value,
        };
    });

    return {
        seeMoreSection,
        searchOn,
        categorySelected,
        subcategorySelected,
        isDiscount,
        subcategoriesList,
        retailerSelected,
    };
};
