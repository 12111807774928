import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-591ac747"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 1,
  class: "general-alert__title"
}
const _hoisted_3 = {
  key: 2,
  class: "general-alert__message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_base_alert = _resolveComponent("base-alert")!

  return (_openBlock(), _createBlock(_component_base_alert, {
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close'))),
    onOnCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close-modal'))),
    showCloseIcon: $props.showCloseIcon,
    accentHeader: $props.accentHeader,
    title: $props.title
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true),
      _createElementVNode("section", {
        class: "general-alert",
        style: _normalizeStyle(`padding: ${$props.padding}`)
      }, [
        ($props.assetsURL.length)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require(`@/assets/${$props.assetsURL}`),
              alt: $props.title
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true),
        ($props.title.length && !$props.accentHeader)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString($props.title), 1))
          : _createCommentVNode("", true),
        ($props.message)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.message), 1))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "content", {}, undefined, true),
        ($props.showButtons)
          ? (_openBlock(), _createElementBlock("section", {
              key: 3,
              class: _normalizeClass(["general-alert-btns", { 'general-alert-btns--one-btn': $props.onlyConfirmationBtn }])
            }, [
              (!$props.onlyConfirmationBtn)
                ? (_openBlock(), _createBlock(_component_btn_out_line, {
                    key: 0,
                    txt: $props.declineButtonTxt ?? _ctx.$t('cta.reject'),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-decline'))),
                    bgColor: "#FFF",
                    color: "#FF9012"
                  }, null, 8, ["txt"]))
                : _createCommentVNode("", true),
              _createVNode(_component_btn_solid, {
                txt: $props.acceptButtonTxt ?? _ctx.$t('cta.accept'),
                onClick: $options.onAccept,
                isDisabled: $props.isDisabled,
                isLoading: $props.isLoading
              }, null, 8, ["txt", "onClick", "isDisabled", "isLoading"])
            ], 2))
          : _createCommentVNode("", true)
      ], 4)
    ]),
    _: 3
  }, 8, ["showCloseIcon", "accentHeader", "title"]))
}