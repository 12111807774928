import { AddressModel, toAddressModel } from './AddressModel';

class CountryAddressesModel {
    id: number;
    name: string;
    addresses: Array<AddressModel>;
    constructor(id: number, name: string, addresses: Array<AddressModel>) {
        this.id = id;
        this.name = name;
        this.addresses = addresses;
    }
}
const toCountryAddresses = (data: object) => {
    return data
        ? new CountryAddressesModel(
              data['id'],
              data['name'] ?? '',
              data['registred_address']?.map((address: object) => toAddressModel(address)) ?? [],
          )
        : undefined;
};

export { toCountryAddresses, CountryAddressesModel };
