<template>
    <general-alert
        v-if="showPickUpAlert"
        :accent-header="true"
        :accept-button-txt="$t('cta.change-to-pickup')"
        :decline-button-txt="$t('cta.continue-in-delivery')"
        :title="$t('alerts.meet_pickup')"
        @on-close="closePickUpAlert"
        @on-decline="closePickUpAlert"
        @on-accept="choosePickUp"
    >
        <template #content>
            <lottie-animation :src="animation" />
        </template>
    </general-alert>
    <pick-up-map-modal v-if="showPickUpMap" />
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import GeneralAlert from '../generalAlert/GeneralAlert.vue';
import PickUpMapModal from '@/components/modal/pickupMapModal/PickUpMapModal.vue';
import LottieAnimation from '@/components/animations/LottieAnimation.vue';
import { AnimationEnum } from '@/enums/animationEnum';
import { storeToRefs } from 'pinia';
import { usePickupStore } from '@/store/usePickupStore';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

const animation = AnimationEnum.PICKUP;
const { showPickUpAlert, showPickUpMap } = storeToRefs(usePickupStore());
const { fetchFirstSchedulePerRetailer } = useRetailer();
const $store = useStore();
const closePickUpAlert = (): void => {
    showPickUpAlert.value = false;
    $store.commit('location/setLocationSelectedAsDelivery');
    fetchFirstSchedulePerRetailer();
};
const choosePickUp = (): void => {
    showPickUpAlert.value = false;
    showPickUpMap.value = true;
};
</script>
