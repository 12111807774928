<script lang="ts" setup>
import { onMounted, ref } from 'vue';

const autoCompleteMap = ref<HTMLInputElement | undefined>();

const maxRetry = ref(3);

const emits = defineEmits<(e: 'place_changed', value: any) => void>();

const loadAutoComplete = () => {
    if (!autoCompleteMap.value) return;
    if (!maxRetry.value)
        throw new Error(
            "google.maps.places.Autocomplete is undefined. Did you add 'places' to libraries when loading Google Maps?",
        );
    maxRetry.value--;

    const google = (window as any).google;

    if (typeof google?.maps?.places?.Autocomplete !== 'function') {
        setTimeout(() => {
            loadAutoComplete();
        }, 250);
        return;
    }

    const $autocomplete = new google.maps.places.Autocomplete(autoCompleteMap.value, {
        fields: ['address_components', 'formatted_address', 'geometry', 'name'],
        componentRestrictions: { country: ['PA', 'EC'] },
    });

    $autocomplete.addListener('place_changed', () => {
        emits('place_changed', $autocomplete?.getPlace());
    });
};

onMounted(() => {
    if (!autoCompleteMap.value) return;
    setTimeout(() => {
        loadAutoComplete();
    }, 500);
});
</script>

<template>
    <input ref="autoCompleteMap" v-bind="$attrs" v-on="$attrs" />
</template>
