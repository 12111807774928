<script lang="ts" setup>
import { computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import BaseCard from '@/components/cards/BaseCard.vue';
import { useWallet } from '@/views/wallet/useWallet';
import IconBack from '@/components/icons/IconBack.vue';
import { appRoutesMap } from '@/router/appRoutesMap';
import InstitutionalWalletCard from '@/views/wallet/components/InstitutionalWalletCard.vue';
import { useTranslate } from '@/composables/UseTranslate';
import DefaultPaymentSelector from '@/components/defaultPaymentSelector/DefaultPaymentSelector.vue';
import { PaymentsChannels } from '@/models/paymentMethods/PaymentMethod';

const $router = useRouter();
const { institutionalCardSelected, creditCardSelected, membershipSelected } = useWallet();

const resetCards = () => {
    $router.replace({
        name: appRoutesMap.Wallet,
    });
    creditCardSelected.value = undefined;
    membershipSelected.value = undefined;
    institutionalCardSelected.value = undefined;
};

onBeforeMount(() => {
    if (creditCardSelected.value || membershipSelected.value || institutionalCardSelected.value) return;
    $router.replace({
        name: appRoutesMap.Wallet,
    });
});

const { exec } = useTranslate();

const isDefault = computed({
    get: () => institutionalCardSelected.value?.default ?? false,
    set: (value) => {
        institutionalCardSelected.value.default = value;
    },
});
</script>

<template>
    <button class="wallet-card-detail__btn" @click="resetCards">
        <icon-back :size="0.8" color="#000000" />
        <span class="wallet-card-detail__btn-text">{{ $t('txt.go-back') }}</span>
    </button>
    <section class="wallet-card-detail">
        <BaseCard class="wallet-card">
            <div>
                <InstitutionalWalletCard :institution="institutionalCardSelected" />
                <DefaultPaymentSelector
                    :client-institution-id="institutionalCardSelected.id"
                    :is-default="isDefault"
                    :payment-channel-selected="PaymentsChannels.INSTITUTIONAL"
                    :payment-type="institutionalCardSelected.payment_type_id"
                    @on-set-default="(value) => (isDefault = value)"
                />
            </div>
        </BaseCard>
        <BaseCard class="membership-detail">
            <h4 class="label-section">{{ $t('general.your_benefits') }}</h4>
            <div v-html="exec(institutionalCardSelected.benefits)"></div>
        </BaseCard>
    </section>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as colors;
@use '@/assets/scss/type-system' as type;
@use '@/assets/scss/margin' as mg;

.label-section {
    @include type.caption;
    color: colors.$orange;
    font-weight: 600;
}

.wallet-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wallet-card-detail {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    @include type.caption;

    &__btn {
        all: unset;
        display: flex;
        gap: 0.5rem;

        &-txt {
            @include type.button;
            font-size: 2rem;
        }
    }

    @include type.phone {
        grid-template-columns: 1fr;
    }

    &__skeleton {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &--header {
            height: 4rem;
        }

        &--body {
            height: 6rem;
        }
    }
}

.membership-detail {
    flex-direction: column;
    gap: 1rem;
    height: fit-content;

    &__transactions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &_date {
            @include type.body1;
            color: colors.$orange;
            font-weight: 600;
        }

        &_number {
            @include type.body2;
            color: colors.$grey;
        }
    }

    &__arrow {
        display: flex;
        margin: auto 0;
        padding: 1rem;

        &--left {
            transform: rotate(180deg);
        }
    }

    &--cc-header {
        justify-content: space-between;
        align-items: center;
    }

    &__date {
        flex-direction: column;
        border: 1px solid colors.$orange !important;
    }
}

.wallet-card__actions {
    display: flex;
    justify-content: space-evenly;
    gap: 0.5rem;
    align-items: center;
    width: 100%;

    &__delete {
        all: unset;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        cursor: pointer;
    }

    &__text {
        @include type.button;
        color: colors.$magenta;
        font-weight: 400;
    }

    &__default {
        margin-top: unset !important;
    }
}

.benefit-detail__skeleton {
    height: 12rem;
}

.wallet-card-detail__btn {
    all: unset;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    &-text {
        @include type.button;
        font-size: 1.8rem;
        font-weight: 600;
        text-transform: capitalize;
    }
}
</style>
