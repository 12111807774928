<template>
    <feeling-bar v-if="selectedStory?.interactiveElement?.interactionType === 'FEELING_BAR'"></feeling-bar>
    <countdown v-else-if="selectedStory?.interactiveElement?.interactionType === 'COUNTDOWN'"></countdown>
    <question v-else-if="selectedStory?.interactiveElement?.interactionType === 'QUESTION'"></question>
    <binary v-else-if="selectedStory?.interactiveElement?.interactionType === 'BINARY'"></binary>
    <div v-else></div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import Binary from './components/binary/Binary.vue';
import Countdown from './components/countdown/Countdown.vue';
import Question from './components/question/Question.vue';
import FeelingBar from './components/feelingBar/FeelingBar.vue';

export default {
    name: 'interaction',
    components: {
        Binary,
        Countdown,
        Question,
        FeelingBar,
    },
    computed: {
        ...mapGetters({
            selectedStory: 'stories/selectedStory',
        }),
    },
};
</script>
