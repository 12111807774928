import { CouponModel, toCouponModel } from './CouponModel';

class MyCouponsModel {
    code: CouponModel;
    codeExtryDate: string;
    codeActivationDate: string;
    activated: boolean;

    constructor(code: CouponModel, codeExtryDate: string, codeActivationDate: string, activated: boolean) {
        this.code = code;
        this.codeExtryDate = codeExtryDate;
        this.codeExtryDate = codeActivationDate;
        this.activated = activated;
    }
}

const toMyCouponsModel = (data: object): MyCouponsModel => {
    return data
        ? new MyCouponsModel(
              toCouponModel(data['code']),
              data['code_entry_date'] ?? '',
              data['code_activation_date'] ?? '',
              data['activated'] ?? false,
          )
        : null;
};
export { MyCouponsModel, toMyCouponsModel };
