import { ProductModel } from '@/models/product/ProductModel';
import { useCatalogFicoaStore } from '@/store/useCatalogFicoaStore';

export const useFicoaProducts = (product: ProductModel) => {
    const $store = useCatalogFicoaStore();

    const updateFicoaProduct = (quantity: number) => {
        if (!quantity || quantity <= 0) return $store.remove(product);
        const copyProduct = { ...product };
        copyProduct.productQuantity = quantity;
        return $store.add(copyProduct as ProductModel);
    };

    return {
        updateFicoaProduct,
    };
};
