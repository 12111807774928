<template>
    <base-modal :hasClose="hasClose" :title="title" :hasHeader="hasHeader" @on-close="$emit('on-close')">
        <div class="model-success">
            <img class="model-success__img" src="@/assets/gif/success.gif" :alt="$t('icon.success')" />
            <h1 class="model-success__title" v-if="title.length && !hasHeader">{{ title }}</h1>
            <h2 class="model-success__text" v-if="body.length">{{ body }}</h2>
            <btn-solid
                class="model-success__action"
                :txt="ctaText"
                v-if="showAction && ctaText.length"
                @click="$emit('on-action')"
            />
        </div>
    </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';

export default {
    name: 'success-modal',
    components: { BtnSolid, BaseModal },
    props: {
        title: {
            type: String,
            default: '',
        },
        body: {
            type: String,
            default: '',
        },
        ctaText: {
            type: String,
            default: '',
        },
        hasClose: {
            type: Boolean,
            default: false,
        },
        hasHeader: {
            type: Boolean,
            default: false,
        },
        showAction: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-close', 'on-action'],
};
</script>
<style lang="scss" scoped>
@import 'success-modal';
</style>
