class CouponModel {
    code: string;
    validUntil: string;
    description: string;
    icon: string;
    country: string;

    constructor(code: string, validUntil: string, description: string, icon: string, country?: string) {
        this.code = code;
        this.validUntil = validUntil;
        this.description = description;
        this.icon = icon;
        this.country = country;
    }
}

const toCouponModel = (data: object): CouponModel => {
    return data
        ? new CouponModel(
              data['code'],
              data['valid_until'] ?? '',
              data['description'] ?? '',
              data['icon'],
              data['country'] ? data['country']['name'] : '',
          )
        : null;
};

export { CouponModel, toCouponModel };
