<template>
    <div class="verify-email">
        <icon-mail-recovery class="icon-mail" :size="0.85"></icon-mail-recovery>
        <p class="subtitle">
            {{ $t("txt['recovery__password-insert-the-mail-one']") }}
            <span>
                <strong>{{ $t("txt['recovery__password-insert-the-mail-two']") }}</strong>
            </span>
            {{ $t("txt['recovery__password-insert-the-mail-three']") }}
        </p>
        <div class="form-container">
            <label v-if="inputMail" class="label-input" for="email"> {{ $t("txt['login__enter-your-email']") }} </label>
            <TiptiInputText
                v-model="email"
                :label="$t('general.email')"
                autocomplete="email"
                is-required
                placeholder="email@tipti.com"
                type="email"
            />
        </div>
        <btn-solid :is-loading="isLoading" :txt="$t('general.send')" @click="verifyEmail"></btn-solid>
    </div>
    <snack-bar
        :body="snackBarMessage"
        :is-active="showSnackBar"
        :is-failure="isFailure"
        :is-success="isSuccess"
        @on-snackbar-close="showSnackBar = false"
    ></snack-bar>
</template>

<script lang="ts">
import { logger } from '@/utils/logger';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import IconMailRecovery from '@/components/icons/IconMailRecovery.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';

export default {
    name: 'VerifyEmail',
    components: { TiptiInputText, IconMailRecovery, BtnSolid, SnackBar },
    emits: ['on-mail-sent'],
    data() {
        return {
            email: '',
            showChangePassword: false,
            showSnackBar: false,
            snackBarMessage: '',
            isSuccess: false,
            isFailure: false,
            isLoading: false,
            inputMail: false,
        };
    },
    methods: {
        verifyEmail(): void {
            this.email.trim() === '' ? (this.inputMail = true) : this.sendEmail();
        },
        async sendEmail(): Promise<void> {
            try {
                this.inputMail = false;
                this.isLoading = true;
                await this.$store.dispatch('user/passwordRecovery', this.email);
                this.snackBarInfo({
                    msg: this.$t("txt['recovery__password-send-mail-succes']"),
                    success: true,
                    failed: false,
                });
                this.$emit('on-mail-sent', this.email);
            } catch (err) {
                this.snackBarInfo({
                    msg: this.$t("txt['recovery__password-send-mail-fail']"),
                    success: false,
                    failed: true,
                });
                logger('SEND_EMAIL', err);
            } finally {
                this.isLoading = false;
            }
        },
        snackBarInfo(data): void {
            this.showSnackBar = true;
            this.isSuccess = data.success;
            this.isFailure = data.failed;
            this.snackBarMessage = data.msg;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.verify-email {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;
    margin: $margin-md auto;
}

.icon-mail {
    margin: $margin-md;
}

.subtitle {
    @include body1;
    width: 80%;
    text-align: center;
    margin: $margin-md auto;
}

.subtitle span {
    color: $orange;
    text-transform: uppercase;
}

.form-container {
    margin: $margin-md auto;
}

.form-container label {
    @include body2;
    color: $error;
}
</style>
