<template>
    <button :class="selected ? 'btn-container btn--background-orange' : 'btn-container'">
        <img class="btn-container__image" :src="require(`@/assets/svg/${linkImg}`)" />
        <vertical-divider colorDivider="#ff9012" height="100%" width="1px" />
        <section class="btn-container__label">
            <span>{{ txt }}</span>
        </section>
    </button>
</template>

<script>
import VerticalDivider from '@/components/dividers/VerticalDivider.vue';

export default {
    components: {
        VerticalDivider,
    },
    props: {
        txt: {
            type: String,
            required: true,
        },
        selected: {
            type: Boolean,
            required: true,
        },
        linkImg: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.btn-container {
    display: flex;
    border-radius: $radius-md;
    cursor: pointer;
    border: none;
    margin-bottom: $margin-sm;
    transition: all 250ms ease-in-out;
    background: $white;
    flex-direction: row;
    padding: $padding-md;
    @include box-shadow();
    outline-color: $orange;
}
.btn--background-orange {
    background-color: $orange;
    outline-color: $green;
}
.btn--background-orange > .btn-container__label > span {
    color: $white;
}
.btn--background-orange .btn-container__image {
    filter: brightness(2);
}
.btn-container:hover {
    background-color: $orange;
}
.btn-container:hover .btn-container__image {
    filter: brightness(2);
}
.btn-container:hover > .btn-container__label > span {
    color: $white;
}
.btn-container__image {
    width: 20px;
    align-self: center;
}
.btn-container__label {
    @include body1;
    margin-left: $margin-md;
    align-self: center;
}
</style>
