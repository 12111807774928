import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d5a22a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]
const _hoisted_2 = {
  key: 1,
  class: "coupon--no-margin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["coupon", { 'coupon--full-width': $props.fullWidth }])
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.code) = $event)),
      class: "coupon__input",
      type: "text",
      placeholder: $options.placeholder
    }, null, 8, _hoisted_1), [
      [_vModelText, $data.code]
    ]),
    _createElementVNode("button", {
      class: "coupon__btn",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.redeemCode && $options.redeemCode(...args)))
    }, [
      ($data.isLoading)
        ? (_openBlock(), _createBlock(_component_base_loading, {
            key: 0,
            width: ".5rem",
            heigth: ".5rem",
            "is-margin-auto": false
          }))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($options.btnName), 1))
    ]),
    _createVNode(_component_snack_bar, {
      "is-active": $data.showSnackBar,
      "is-success": $data.issuccess,
      "is-failure": !$data.issuccess,
      body: $data.alertMsg,
      onOnSnackbarClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showSnackBar = _ctx.fa))
    }, null, 8, ["is-active", "is-success", "is-failure", "body"])
  ], 2))
}