class ClientInformationModel {
    readonly name: string;
    readonly email: string;
    readonly joinDate: Date;
    readonly clientPhone: string;
    readonly billPhone: string;

    constructor(name: string, email: string, joinDate: Date, clientPhone: string, billPhone: string) {
        this.name = name;
        this.email = email;
        this.joinDate = joinDate;
        this.clientPhone = clientPhone;
        this.billPhone = billPhone;
    }
}

const toClientInformationModel = (data: object): ClientInformationModel => {
    return data
        ? new ClientInformationModel(
              data['client_name'],
              data['email'],
              new Date(data['join_date']),
              data['client_phone_number'],
              data['bill_phone_number'],
          )
        : null;
};

export { ClientInformationModel, toClientInformationModel };
