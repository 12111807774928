<template>
    <base-card class="list-categories" :padding="0">
        <section-header :title="$t('cta.categories')" has-back @on-back="onBack" />
        <aside class="list-categories-body">
            <search-category
                class="list-categories-body__search"
                :origin-section="'MY_LISTS'"
                @redirect-on-search="redirectFromSearch"
            />
            <categories-grid />
        </aside>
    </base-card>
</template>

<script lang="ts">
import BaseCard from '@/components/cards/BaseCard.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import CategoriesGrid from '@/components/category/categories/CategoriesGrid.vue';
import SearchCategory from '@/components/category/searchCategory/SearchCategory.vue';
import { RoutesOrigin } from '@/enums/routesOrigin';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'ListCategories',
    components: {
        BaseCard,
        SectionHeader,
        CategoriesGrid,
        SearchCategory,
    },
    created() {
        this.$store.commit('lists/isCategoryFromList', true);
    },
    methods: {
        onBack(): void {
            this.$router.replace({ name: appRoutesMap.list.personalLists});
            this.$store.commit('lists/localCart');
            this.$store.commit('categories/setCategorySelected');
        },
        redirectFromSearch(query: string): void {
            this.$router.push({
                path: RoutesOrigin.LISTS_CATEGORY_PRODUCTS,
                query: {
                    name: this.$route.params.name ?? this.$t('cta.categories'),
                    searchOn: query,
                    categoryId: this.categoryId,
                },
                replace: query?.length > 0 && !this.$route.path.includes(RoutesOrigin.LISTS_CATEGORY_PRODUCTS),
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import './list-categories.scss';
</style>
