<template>
    <alert-manager
        :is-loading="false"
        :is-disabled="false"
        :show-bottom-sheet-title="false"
        :show-buttons="false"
        :only-confirmation-btn="false"
        :title="$t('txt.credit-card__validate')"
        @on-close="$emit('on-close')"
        @on-dismiss="$emit('on-close')"
        @on-close-modal="$emit('on-close')"
    >
        <template v-slot:content>
            <div class="validate-success">
                <icon-shield-check class="validate-success__img" :size="2" />
                <h3 class="validate-success__txt">{{ $t('txt.credit-card__verification-success') }}</h3>
            </div>
        </template>
    </alert-manager>
</template>

<script lang="ts">
import AlertManager from '@/components/alerts/alertManager/AlertManager.vue';
import IconShieldCheck from '@/components/icons/IconShieldCheck.vue';
export default {
    name: 'validation-success',
    components: { AlertManager, IconShieldCheck },
    emits: ['on-close'],
};
</script>

<style scoped lang="scss">
@import 'validation-success';
</style>
