<template>
    <button class="user-id cursor-pointer" :class="{ 'user-id--copy': isCopy }" @click="copy">
        <Transition mode="out-in">
            <span :key="text" class="user-id__name">
                {{ text }}
            </span>
        </Transition>
        <Transition mode="out-in">
            <IconVerifiedCheck v-if="isCopy" class="user-id__img" :size="isMobile ? 1.0 : 1.4" />
            <IconCopyOutline v-else class="user-id__img" :size="isMobile ? 0.5 : 0.8" />
        </Transition>
    </button>
</template>
<script lang="ts" setup>
import IconCopyOutline from '@/components/icons/IconCopyOutline.vue';
import IconVerifiedCheck from '@/components/icons/IconVerifiedCheck.vue';
import { useUser } from '@/composables/useUser';
import { computed, ref, watch } from 'vue';
import { useApp } from '@/composables/useApp';

const { $t, isMobile } = useApp();
const { profile } = useUser();
const isCopy = ref(false);
watch(isCopy, (newValue, _) => {
    if (!newValue) return;

    setTimeout(() => {
        isCopy.value = false;
    }, 5000);
});

const text = computed(() => (isCopy.value ? `¡ID ${$t('general.copied_text')}!` : `Tipti ID: ${profile.value?.id} `));

const copy = () => {
    if (isCopy.value) return;
    navigator.clipboard.writeText(profile.value.id.toString()).then(
        () => {
            isCopy.value = true;
        },
        () => {
            isCopy.value = false;
        },
    );
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss';

.user-id {
    align-self: center;
    height: fit-content;
    display: inline-flex;
    align-items: center;
    border-radius: 20px;
    border: none;
    width: fit-content;
    background: white;
    font-weight: 500;
    padding: 0.25rem 1.5rem;

    @include box-shadow--full();
    @include phone {
        padding: 0.25rem 0.5rem;
    }

    &__img {
        margin-left: 15px;
    }

    &__name {
        @include subtitle2;
        font-weight: 500;
        @include phone {
            font-size: 12px;
        }
    }

    &--copy {
        background-color: #fff4e7;
    }
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
