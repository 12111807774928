import { ProductModel } from '@/models/product/ProductModel';
import { CartModel } from '@/models/cart/CartModel';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import { OrderResumenModel } from '@/models/order/OrderResumen';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';

export default {
    products(state): Array<ProductModel> {
        return state.products;
    },
    totalCostExtraItems(state) {
        return +state.products
            .reduce(
                (accumulator, { productQuantity, priceWithDiscount }) =>
                    accumulator + productQuantity * priceWithDiscount,
                0,
            )
            .toFixed(2);
    },
    cart(state): CartModel {
        const cart = CartModel.getInstance();
        const order: OrderResumenModel = state.order;
        const retailerInOrder: RetailerModel = order?.retailer;
        if (!retailerInOrder) {
            state.cart = cart;
            return;
        }
        const retailer = CartRetailerModel.getInstance();
        retailer.products = state.products;
        retailer.logo = retailerInOrder?.logo;
        retailer.icon = retailerInOrder.icon;
        retailer.id = retailerInOrder?.id;
        retailer.name = retailerInOrder?.name;
        retailer.primaryColor = retailerInOrder?.primaryColor;
        retailer.secondaryColor = retailerInOrder?.secondaryColor;
        cart.retailers = [retailer];
        state.cart = cart;
        return state.cart;
    },
    xSellingProducts(state): Array<ProductModel> {
        return state.xSellingProducts;
    },
    order(state): OrderResumenModel {
        return state.order;
    },
    showLimitItemsByOrder(state): boolean {
        return state.showLimitItemsByOrder;
    },
};
