import { WrapperResponse } from '@/modules/core/decorators/wrapperResponse';
import { PaymentObject } from '@/modules/core/models/PaymentObject';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { ResentPaymentError } from '@/modules/core/errors/AppErrors';
import { ResentPaymentResponseDto } from '@/models/paymentMethods/ResentPaymentResponseModel';

class OrderService {
    @WrapperResponse({ rethrowError: true })
    async resentPayment({ orderId, payment }: { orderId: number; payment: PaymentObject }): Promise<void> {
        try {
            const url = `client_profile/order/${orderId}/resend_payment/`;
            const { data } = await axiosInstance().post<ResentPaymentResponseDto>(url, payment.formData, {
                ensureProductionRequest: true,
            });
            if (!data.data?.sent)
                throw new ResentPaymentError({
                    message: data?.payment_attempt_message,
                    attempts: data?.payment_attempts,
                });
        } catch (error) {
            const { show_message_error } = error?.errorObject;
            const { payment_attempts } = error?.errorObject;
            const message = show_message_error
                ? show_message_error
                : typeof error.message === 'string'
                  ? error.message
                  : undefined;
            throw new ResentPaymentError({
                message,
                attempts: payment_attempts,
            });
        }
    }
}

export const orderService = new OrderService();
