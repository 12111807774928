export interface CambrellaMembershInterface {
    enabled: boolean;
    cambrellas_available: number;
    number_of_cambrellas: number;
    date_end_membership: string;
    icon: string;
    membership_type_name: string;
    id: number;
    cambrellas_in_use: number;
    notice_with_availability_of_cambrellas: string;
    notice_without_availability_of_cambrellas: string;
    cambrella_return_notice: string;
    benefits: Array<string>;
}

export class CambrellaMembershipModel implements CambrellaMembershInterface {
    cambrellas_available: number;
    date_end_membership: string;
    enabled: boolean;
    icon: string;
    number_of_cambrellas: number;
    membership_type_name: string;
    id: number;
    cambrellas_in_use: number;
    notice_with_availability_of_cambrellas: string;
    notice_without_availability_of_cambrellas: string;
    cambrella_return_notice: string;
    benefits: Array<string>;

    constructor(data: CambrellaMembershInterface) {
        this.enabled = data.enabled;
        this.cambrellas_available = +data.cambrellas_available;
        this.date_end_membership = data.date_end_membership;
        this.icon = data.icon;
        this.number_of_cambrellas = +data.number_of_cambrellas;
        this.membership_type_name = data.membership_type_name;
        this.id = +data.id;
        this.cambrellas_in_use = +data.cambrellas_in_use;
        this.notice_with_availability_of_cambrellas = data.notice_with_availability_of_cambrellas;
        this.notice_without_availability_of_cambrellas = data.notice_without_availability_of_cambrellas;
        this.cambrella_return_notice = data.cambrella_return_notice;
        this.benefits = data.benefits;
    }

    static toJSON(data: CambrellaMembershipModel): object {
        return {
            id: data.id,
            name: data.membership_type_name,
            total: data.number_of_cambrellas,
            available: data.cambrellas_available,
        };
    }

    static fromJSON(data: object): CambrellaMembershipModel {
        if (!data || !Object.keys(data).length) return;
        return new CambrellaMembershipModel(data as CambrellaMembershInterface);
    }

    static builderList(data: object): Array<CambrellaMembershipModel> {
        if (!data) return [];
        return (data as []).map(this.fromJSON).filter(item => !!item);
    }
}
