<template>
    <section class="secondary-actions">
        <div class="secondary-actions__box" @click="onClick('SET_AS_FAVORITE')">
            <icon-favorite :color="isFavorite ? '#FF9012' : '#707070'" :filled="isFavorite" :size="1.2" />
            <p>{{ $t('txt.product-modal__txt-add-favorite-short') }}</p>
        </div>
        <div
            v-if="!product.uniqueDelivery"
            class="cursor-pointer secondary-actions__box"
            @click="onClick('ADD_TO_LIST')"
        >
            <icon-add-list />
            <p>{{ $t('txt.product-modal__txt-add-list') }}</p>
        </div>
        <div v-if="product?.deepLink" class="secondary-actions__box action-share" @click.stop="onClick('SHARE')">
            <icon-share-link :size="0.3" />
            <p>{{ $t('general.share') }}</p>
            <share-product-deep-links
                v-if="showShareLinks"
                ref="showShareLinksRef"
                @on-close="showShareLinks = false"
            />
        </div>
    </section>
</template>
<script lang="ts" setup>
import { ProductModel } from '@/models/product/ProductModel';
import IconFavorite from '@/components/icons/IconFavorite.vue';
import IconAddList from '@/components/icons/IconAddList.vue';
import IconShareLink from '@/components/icons/IconShareLink.vue';
import ShareProductDeepLinks from '../shareProductDeepLinks/ShareProductDeepLinks.vue';
import { PropType, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useUser } from '@/composables/useUser';

defineProps({
    isFavorite: {
        type: Boolean,
        default: false,
    },
    product: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
});
const { isAuth, requireAuth } = useUser();
const $emit = defineEmits<(e: 'on-click', value: string) => void>();

const showShareLinks = ref(false);
const showShareLinksRef = ref<HTMLElement | undefined>();

onClickOutside(showShareLinksRef, () => {
    showShareLinks.value = false;
});

const onClick = (value: string) => {
    if (!isAuth.value) {
        requireAuth.value = true;
        return;
    }
    if (value === 'SHARE') {
        showShareLinks.value = true;
        return;
    }
    $emit('on-click', value);
};
</script>
<style lang="scss" scoped>
@use '@/assets/scss/index';

.secondary-actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: index.$margin-sm;
    justify-content: center;

    &__box {
        display: flex;
        gap: index.$margin-sm;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include index.button;
    }
}

.action-share {
    position: relative;
}
</style>
