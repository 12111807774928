<template>
    <router-link v-if="isRoute" class="link-action" :style="actualColor" :to="{ name: routeName }">
        {{ txtLink }}
    </router-link>
    <a v-else-if="isVisual" class="link-action" :style="actualColor" target="_blank">{{ txtLink }}</a>
    <a v-else class="link-action" :href="routeName" :style="actualColor" target="_blank">{{ txtLink }}</a>
</template>

<script>
export default {
    name: 'base-link',
    props: {
        txtLink: {
            type: String,
            required: true,
        },
        routeName: {
            type: String,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
        isRoute: {
            type: Boolean,
            default: true,
        },
        isVisual: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        actualColor() {
            return { color: this.color };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.link-action {
    @include body1;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
}
</style>
