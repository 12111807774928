import { RemoteLocaleDto } from '@/models/internationalization/RemoteLocale';
import { ProductModel } from '@/models/product/ProductModel';

export interface AffiliationMembership {
    card_number: string;
    card_picture: string;
    color_text: string;
    default: boolean;
    id: number;
    name: string;
}

export interface AffiliationMembershipBenefit {
    title_screen: RemoteLocaleDto;
    title_benefits: RemoteLocaleDto;
    description: RemoteLocaleDto;
    benefits_list: BenefitsList[];
    title_link: RemoteLocaleDto;
    link_benefits: string;
    points_accumulated: number;
    franchises_list: number[];
}

export interface BenefitsList {
    name: RemoteLocaleDto;
    description: RemoteLocaleDto;
    icon: string;
}

export interface CreditCardExpensiveResume {
    number_orders_monthly: number | undefined;
    number_transactions_monthly: number | undefined;
    oldest_day: string;
    total_amount_general: number | undefined;
    total_amount_monthly: number | undefined;
}

export interface CreditCardExpensive {
    date: string;
    total: number;
    orders: [
        {
            order_number: number;
            number_of_items: number;
            cost: number;
        },
    ];
}

export interface GiftCardDTO {
    card_picture: string;
    color_text: string;
    id: number;
    card_name: string;
    available_amount: string;
    expiration_date?: string;
    description: RemoteLocaleDto;
}

export enum TransactionTypeEnum {
    all = 'all',
    incoming = 'add_credit',
    outgoing = 'substract_credit',
}

export interface GiftCardTransactionDTO {
    id: number;
    orders: string;
    items: number;
    amount: string;
    type: TransactionTypeEnum;
    date: string;
}

export interface GiftCardRetailerWithProductsDTO {
    id: number;
    name: string;
    logo: string;
    has_categories: string;
    delivery_range: string;
    stockitems: Object[];
}

export interface GiftCardRetailerWithProductsViewModel {
    id: number;
    name: string;
    imgUrl: string;
    hasCategories: string;
    deliveryRange: string;
    products: ProductModel[];
}

export interface GiftCardRetailerCategoryDTO {
    id: number;
    translate_information: RemoteLocaleDto;
}
