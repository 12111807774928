<template>
    <div class="country-phone__container">
        <country-with-flag @click.prevent="showCountryCodeSelector = true" />
        <phone-input
            v-model="value"
            class="country-phone__code"
            :errors="errors"
            :is-required="isRequired"
            :label="labelForCellphone"
            :pattern="countryCode?.regex"
            :phone-already-exists="phoneAlreadyExists"
            :placeholder="'+' + countryCode?.country_code"
            @on-validate="(isValid: boolean) => emit('on-validate', isValid)"
        />
        <country-code-options v-if="showCountryCodeSelector" @on-close="showCountryCodeSelector = false" />
    </div>
</template>

<script lang="ts" setup>
import { CountryCodeModel } from '@/models/country/CountryCodeModel';
import CountryWithFlag from '@/components/inputs/primary/phone/CountryWithFlag.vue';
import CountryCodeOptions from '@/components/inputs/primary/phone/CountryCodeOptions.vue';
import PhoneInput from '@/components/inputs/primary/phone/PhoneInput.vue';
import { computed, ref } from 'vue';
import { useApp } from '@/composables/useApp';

const value = defineModel({
    default: '',
    type: String,
});

const props = defineProps({
    isRequired: {
        type: Boolean,
        default: false,
    },
    isEmptyAndRequired: {
        type: Boolean,
        default: false,
    },
    isInvalid: {
        type: Boolean,
        default: false,
    },
    phoneAlreadyExists: {
        type: Boolean,
        default: false,
    },
    showPlaceholderWithOutFocus: {
        type: Boolean,
        default: true,
    },
    errors: {
        type: [String, Array<string>],
        default: undefined,
    },
    showLabel: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits<(e: 'on-validate', value: boolean) => void>();

const { $store, $t } = useApp();

const showCountryCodeSelector = ref(false);
const labelForCellphone = computed(() => (props.showLabel ? $t('general.cellphone') : ''));
const countryCode = computed<CountryCodeModel>(() => $store.getters['external/countryCodeSelected']);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.country-phone {
    &__container {
        display: flex;
        align-items: center;
        position: relative;
    }

    &__code {
        @include body2;
        margin: $margin-none 0 0 $margin-sm;
    }
}
</style>
