export default class DeliveryAddressModel {
    retailerId: number;
    addressId: number;
    pickUpMode: boolean;
    pickUpCenterId: number;

    constructor(retailerId: number, addressId: number, pickUpMode: boolean, pickUpCenterId?: number) {
        this.retailerId = retailerId;
        this.addressId = addressId;
        this.pickUpMode = pickUpMode;
        this.pickUpCenterId = pickUpCenterId;
    }

    toJson = () => {
        return {
            retailer_id: this.retailerId,
            address_id: this.addressId,
            pickup_mode: this.pickUpMode,
            pickup_center_id: this.pickUpCenterId,
        };
    };
}
