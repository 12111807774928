import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfFrame = _resolveComponent("PdfFrame")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "auto-size": false,
    "has-background": $props.hasBackground,
    "header-text-color": $props.accentHeader ? '#ff9012' : null,
    "is-full-modal": true,
    title: $props.title,
    onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PdfFrame, {
        class: "pdf__content",
        src: $options.urlFile
      }, null, 8, ["src"])
    ]),
    _: 1
  }, 8, ["has-background", "header-text-color", "title"]))
}