<template>
    <!-- TODO REVIEW DIMENSIONS OF VIEWBOX, CURRENT DIMENSIONS APPARENTLY NO SHOW THE CORRECT FORM -->
    <base-icon
        :icon-name="$t('icon.share')"
        :width="width"
        :height="height"
        viewBox="0 0 25 20"
        iconColor="transparent"
    >
        <path
            d="M13.5078 6.7558L8.32281 1.5708L3.13781 6.7558"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M8.32324 16.8257L8.32324 1.80469" stroke="white" stroke-width="2" stroke-linecap="round" />
        <path
            d="M5.09399 10.1108H1.41406V21.8108H15.38V10.1108H11.598"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-share-list',
    props: {
        size: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        width(): number {
            return 18 * this.size;
        },
        height(): number {
            return 20 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
