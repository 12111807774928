<template>
    <article class="promotions-in-tipti">
        <h1 class="promotions-in-tipti__title">Encuentra ofertas en las tiendas más cercanas</h1>
        <div class="promotions">
            <base-card v-for="promotion in listPromotions" :key="promotion.id" class="promotion" padding="0">
                <img class="promotion__img" :src="promotion.franchise.header" alt="" />
            </base-card>
        </div>

        <a v-if="showButton" class="button-scroll cursor-pointer" @click="getListPromotions">{{ scrollButton }}</a>
    </article>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { logger } from '@/utils/logger';
import BaseCard from '@/components/cards/BaseCard.vue';
import { useSeoMeta } from '@unhead/vue';

export default {
    name: 'StroresOnSale',
    components: { BaseCard },
    setup() {
        const { t } = useI18n();
        useSeoMeta({
            title: t('seo.new_promotions'),
            description: t('seo.new_promotions__description'),
        });
    },

    data() {
        return {
            listPromotions: [],
            items: [],
            showButton: true,
            termsAndConditions: '',
            page: 0,
            scrollButton: 'Mostrar màs',
        };
    },

    mounted() {
        this.getListPromotions();
    },

    methods: {
        async getListPromotions(): Promise<void> {
            try {
                this.page++;
                const stores = await this.$store.dispatch('external/getBannersStores', {
                    page: this.page,
                });
                const newStores = stores;
                if (newStores.length < 12) {
                    this.showButton = false;
                    this.scrollButton = 'No hay más promociones';
                }
                this.listPromotions = [...this.listPromotions, ...newStores];
            } catch (err) {
                logger('GET_LIST_stores');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'stores-on-sale.scss';
</style>
