<template>
    <base-icon :icon-name="$t('icon.store')" :width="width" :height="height" viewBox="0 0 22 23" :iconColor="color">
        <path
            d="M20.5947 9.39305V8.37157C20.2784 9.23591 19.725 9.9431 18.8554 10.336L18.2229 10.5717C17.9857 10.6503 17.7486 10.6503 17.5114 10.6503C16.2465 10.6503 15.0606 9.9431 14.5862 8.76445C13.7956 10.4146 11.8982 11.1217 10.238 10.336C9.5265 10.0217 8.97309 9.47164 8.65685 8.76445C8.02439 10.1788 6.44323 10.886 4.94113 10.4931L4.30864 10.2574C3.51806 9.86449 2.88561 9.15731 2.64844 8.29297C2.64844 8.60727 2.64844 9.00014 2.64844 9.31445V21.9652C2.64844 22.4367 2.96467 22.751 3.43902 22.751H19.8832C20.3575 22.751 20.6737 22.4367 20.6737 21.9652L20.5947 9.39305Z"
        />
        <path
            d="M18.46 0.908203H5.02009C4.94103 0.908203 0.671875 7.42999 0.671875 7.42999H2.96456C2.96456 8.84436 4.15043 10.023 5.57348 10.023C6.99653 10.023 8.18242 8.84436 8.18242 7.42999V7.27283H8.81488V7.42999C8.81488 8.84436 9.92171 10.023 11.3448 10.1015C12.7678 10.1015 13.9537 9.00152 14.0327 7.58715V7.50855V7.27283H14.7443V7.42999C14.7443 8.84436 15.8511 10.023 17.2741 10.1015C18.6972 10.1015 19.8831 9.00152 19.9621 7.58715V7.50855H21.9386L18.46 0.908203Z"
        />
    </base-icon>
</template>
<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-store',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 22 * this.size;
        },
        height(): number {
            return 23 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
