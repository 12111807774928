<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useWallet } from '@/views/wallet/useWallet';
import { useUser } from '@/composables/useUser';
import { useDays } from '@/composables/useDays';
import { useText } from '@/composables/useText';

import { GiftCardTransactionDTO, TransactionTypeEnum } from '@/views/wallet/WalletModels';
import GiftCardTransactionTile from './GiftCardTransactionTile.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BaseBtn from '@/components/buttons/BaseBtn.vue';
import IconPlusCircle from '@/components/icons/IconPlusCircle.vue';
import IconMinusCircle from '@/components/icons/IconMinusCircle.vue';
import IconBack from '@/components/icons/IconBack.vue';
import IconNoTransactions from '@/components/icons/IconNoTransactions.vue';
import NoContent from '@/components/noContent/NoContent.vue';

defineOptions({
    name: 'GiftCardTransactionsSection',
});
const { userLanguage } = useUser();
const { doFormat } = useDays();
const { giftCardSelected, getGiftCardTransactions, giftCardTransactions } = useWallet();
const { capitalize } = useText();

const showAllTransactions = ref<boolean>(false);
const selectedFilter = ref<TransactionTypeEnum>(TransactionTypeEnum.all);

const toggleTransactionsSections = () => (showAllTransactions.value = !showAllTransactions.value);

const transactionListByDate = (transactions: Array<GiftCardTransactionDTO>) => {
    let tempList: Array<Array<GiftCardTransactionDTO>> = [[transactions[0]]];
    let j = 0;
    for (let i = 1; i < transactions.length; i++) {
        if (transactions[i].date.substring(0, 10) == tempList[j][0].date.substring(0, 10)) {
            tempList[j].push(transactions[i]);
            continue;
        }
        tempList.push([transactions[i]]);
        j++;
    }
    return tempList;
};

const filteredTransactionListByDate = computed(() => {
    if (selectedFilter.value === TransactionTypeEnum.incoming) {
        return giftCardTransactions.value.filter((e) => e.type === TransactionTypeEnum.incoming);
    }
    if (selectedFilter.value === TransactionTypeEnum.outgoing) {
        return giftCardTransactions.value.filter((e) => e.type === TransactionTypeEnum.outgoing);
    }
    return giftCardTransactions.value;
});

const lastestTransactions = computed(() => {
    return giftCardTransactions.value.slice(0, 3);
});

const formattedDate = (date: string) => {
    const day = doFormat({ date, format: 'D' });
    const month = doFormat({ date, format: 'MMMM' });

    if (userLanguage.value == 'es') return day + ' de ' + capitalize(month);
    if (userLanguage.value == 'en') return capitalize(month) + ', ' + day;
};

const selectedButtonBackgroundColor = (filter: TransactionTypeEnum) =>
    filter === selectedFilter.value ? '#ff9012' : '#FFFFFF';
const selectedButtonTextColor = (filter: TransactionTypeEnum) =>
    filter === selectedFilter.value ? '#FFFFFF' : '#606060';
const selectFilter = (filter: TransactionTypeEnum) => {
    selectedFilter.value = filter;
};

await getGiftCardTransactions();
</script>

<template>
    <section v-if="!showAllTransactions" class="gift-card-latest-transactions-section">
        <h3 class="gift-card-latest-transactions-section__title">{{ $t('txt.gift-card-latest-transactions') }}</h3>
        <div class="gift-card-latest-transactions-section__list" v-if="giftCardTransactions.length > 0">
            <GiftCardTransactionTile v-for="item in lastestTransactions" :transaction="item" />
        </div>
        <div class="gift-card-latest-transactions-section__btn-container" v-if="giftCardTransactions.length > 0">
            <BtnSolid :txt="$t('txt.gift-card-transactions-history-view-all')" @click="toggleTransactionsSections" />
        </div>
        <NoContent
            :title="$t('txt.gift-card-transactions-placeholder-title')"
            :description="$t('txt.gift-card-transactions-placeholder-description')"
            color-title="#606060"
            v-if="giftCardTransactions.length == 0"
        >
            <IconNoTransactions />
        </NoContent>
        <div class="gift-card-latest-transactions-section__placeholder"></div>
    </section>
    <section v-else class="gift-card-all-transactions-section">
        <button class="gift-card-all-transactions-section__toggle-btn" @click="toggleTransactionsSections">
            <icon-back :size="0.8" color="#000000" />
            <span class="gift-card-all-transactions-section__toggle-btn__text">{{ $t('txt.go-back') }}</span>
        </button>
        <h3 class="gift-card-all-transactions-section__title">
            {{ $t('txt.gift-card-transactions-history-title', { card_name: giftCardSelected.card_name }) }}
        </h3>
        <div class="gift-card-all-transactions-section__btn-container">
            <BaseBtn
                :bg-color="selectedButtonBackgroundColor(TransactionTypeEnum.all)"
                :color="selectedButtonTextColor(TransactionTypeEnum.all)"
                @click="selectFilter(TransactionTypeEnum.all)"
                >{{ $t('txt.gift-card-transactions-history-filter-all') }}
            </BaseBtn>
            <BaseBtn
                :bg-color="selectedButtonBackgroundColor(TransactionTypeEnum.incoming)"
                :color="selectedButtonTextColor(TransactionTypeEnum.incoming)"
                @click="selectFilter(TransactionTypeEnum.incoming)"
                padding=".5rem 2.5rem"
            >
                <IconPlusCircle color="#CFDE00" :size="0.8" class="transaction-filter-button" />
                {{ $t('txt.gift-card-transactions-history-filter-returns') }}
            </BaseBtn>
            <BaseBtn
                :bg-color="selectedButtonBackgroundColor(TransactionTypeEnum.outgoing)"
                :color="selectedButtonTextColor(TransactionTypeEnum.outgoing)"
                @click="selectFilter(TransactionTypeEnum.outgoing)"
                padding=".5rem 2.5rem"
            >
                <IconMinusCircle color="#C90808" :size="0.8" class="transaction-filter-button" />
                {{ $t('txt.gift-card-transactions-history-filter-expenses') }}
            </BaseBtn>
        </div>
        <section
            v-for="itemGroup in transactionListByDate(filteredTransactionListByDate)"
            :key="itemGroup[0]?.id"
            class="gift-card-all-transactions-section__list"
        >
            <p class="gift-card-all-transactions-section__date">{{ formattedDate(itemGroup[0]?.date) }}</p>
            <GiftCardTransactionTile
                v-for="item in itemGroup"
                :transaction="item"
                :key="item.id"
                class="gift-card-all-transactions-section__tile"
            />
        </section>
    </section>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.gift-card-latest-transactions-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &__title {
        @include subtitle1;
        text-align: center;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }
    &__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    &__btn-container {
        display: flex;
        justify-content: center;
        margin-top: $margin-xlg;
    }
}

.gift-card-all-transactions-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &__toggle-btn {
        all: unset;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        @include phone {
            margin: 0 0 $margin-md $margin-sm;
        }
        &__text {
            @include button;
            font-size: 1.5rem;
            font-weight: 600;
            text-transform: capitalize;
        }
    }
    &__title {
        @include subtitle1;
        text-align: center;
        margin-bottom: 1.5rem;
        color: $orange;
    }
    &__date {
        @include subtitle2;
        font-weight: 500;
    }
    &__btn-container {
        display: flex;
        gap: 0.8rem;
        justify-content: center;
        margin-bottom: $margin-lg;
    }
    &__tile {
        margin: $margin-md 0;
    }
}

.transaction-filter-button {
    margin-right: $margin-sm;
}
</style>
