export class AddCreditCardModel {
    name: string;
    number: string;
    date: string;
    alias: string;
    code: string;
    phone: string;
    isDefault: boolean;

    constructor({
        code,
        name,
        date,
        number,
        phone,
        alias,
        isDefault,
    }: {
        name: string;
        number: string;
        alias: string;
        code: string;
        phone: string;
        date: string;
        isDefault: boolean;
    }) {
        this.name = name;
        this.number = number;
        this.alias = alias;
        this.code = code;
        this.phone = phone;
        this.date = date;
        this.isDefault = isDefault;
    }

    get toAddCreditCardPayload() {
        const date = this.date.split('/');
        const finalMonth = date[0].length == 2 ? date[0] : `0${date[0]}`;
        const finalYear = date[1];
        return {
            payment_type: 3,
            name: this.alias,
            default: this.isDefault,
            data: {
                card: {
                    number: this.number,
                    phone: this.phone,
                    expirationMonth: finalMonth,
                    expirationYear: finalYear,
                    verificationCode: this.code,
                    name_user: this.name,
                },
            },
            response_flag: true,
        };
    }
}
