<template>
    <div class="payment-gift-card">
        <div class="payment-gift-card__icon">
            <icon-payment-methods-gift-card :size="0.8" color="#FF9012" />
        </div>

        <section v-if="paymentsToShowGiftCard.length" class="payment-gift-card__details">
            <div class="payment-gift-card__details__data-container">
                <aside
                    v-for="payment in paymentsToShowGiftCard"
                    :key="payment.id"
                    class="payment-gift-card__details__payment"
                >
                    <div class="payment-gift-card__details__payment__description">
                        <h2 class="payment-gift-card__details__payment__description__name">
                            {{ payment.name_gift_card }}
                        </h2>
                        <p class="payment-gift-card__details__payment__description__id"># {{ payment.id }}</p>
                    </div>
                    <h3 v-currency="payment.covered_amount" class="payment-gift-card__details__payment__amount" />
                </aside>
            </div>
            <button class="gift-card-selected__button" @click="openAlertGiftCard">
                {{ $t("cta['change']") }}
            </button>
        </section>

        <section v-else class="gift-card-selected">
            <h2 class="gift-card-selected__text">{{ $t('txt.gift-card-checkout-select-card') }}</h2>
            <button class="gift-card-selected__button" @click="openAlertGiftCard">
                {{ $t("cta['change']") }}
            </button>
        </section>
    </div>
    <modal-change-gift-card v-if="showModal" :show="showModal" @close="showModal = false" />
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';
import IconPaymentMethodsGiftCard from '@/components/icons/IconPaymentMethodsGiftCard.vue';
import ModalChangeGiftCard from '@/components/modalsGiftCards/modalChangeGiftCard/ModalChangeGiftCard.vue';
import { usePayments } from '@/composables/usePayments';
import { useGiftCards } from '@/composables/useGiftCards';
import { notifierKey } from '@/modules/notification/useNotifier';
import { useI18n } from 'vue-i18n';

const { paymentsToShowGiftCard } = usePayments();
const { timeDelivery } = useGiftCards();
const showModal = ref(false);
const notifier = inject(notifierKey);
const { t: $t } = useI18n();
const openAlertGiftCard = () => {
    if (!timeDelivery.value.length) {
        return notifier({
            type: 'ERROR',
            body: $t('txt.checkout__menu-please-select-schedule'),
            title: $t('txt.checkout__menu-no-select-schedule'),
        });
    }
    showModal.value = true;
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

.gift-card-selected {
    flex-grow: 1;
    display: flex;
    align-items: center;

    &__text {
        flex-grow: 1;
        @include caption;
        color: $orange;
        font-weight: 600;
        text-transform: capitalize;
    }

    &__button {
        all: unset;
        @include overline;
        color: $orange;
        text-decoration: underline;
        cursor: pointer;
        justify-self: flex-end;
    }
}

.payment-gift-card {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 1rem;
    padding: 1rem;
    min-height: 6rem;

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 3rem;
        @include tablet {
            min-width: min-content;
        }
    }

    &__no-data {
        grid-template-columns: 1fr 1fr;
    }

    &__details {
        flex-grow: 1;
        display: grid;
        grid-template-columns: 1fr 15%;
        width: 100%;
        @include tablet {
            grid-template-columns: 1fr 4rem;
        }
        @include phone {
            grid-template-columns: 1fr 3rem;
        }

        &__data-container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        &__payment {
            display: flex;
            align-items: center;

            &__description {
                flex-grow: 1;

                &__name {
                    @include caption;
                    color: $orange;
                    font-weight: 600;
                }

                &__id {
                    @include caption;
                }
            }

            &__amount {
                @include caption;
                text-decoration: underline;
            }
        }
    }
}
</style>
