import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e151ddc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "card-menu__content"
}
const _hoisted_2 = {
  key: 1,
  class: "card-payment__warning"
}
const _hoisted_3 = {
  key: 2,
  class: "card-menu__content"
}
const _hoisted_4 = { class: "text-no-selected" }
const _hoisted_5 = { class: "text-no-selected" }
const _hoisted_6 = {
  key: 0,
  class: "card-menu__content"
}
const _hoisted_7 = { class: "text-no-selected" }
const _hoisted_8 = { class: "text-no-selected" }
const _hoisted_9 = { class: "payment-card-selected__icon" }
const _hoisted_10 = { class: "payment-card-selected__text" }
const _hoisted_11 = {
  key: 1,
  class: "card-menu__content"
}
const _hoisted_12 = {
  key: 1,
  class: "card-payment__warning"
}
const _hoisted_13 = { class: "payment-card-selected__icon" }
const _hoisted_14 = { class: "payment-card-selected__text" }
const _hoisted_15 = { class: "card-menu__content" }
const _hoisted_16 = {
  key: 1,
  class: "card-payment__warning"
}
const _hoisted_17 = {
  key: 1,
  class: "card-menu__content"
}
const _hoisted_18 = { class: "text-no-selected" }
const _hoisted_19 = { class: "text-no-selected" }
const _hoisted_20 = {
  key: 1,
  class: "card-menu__content"
}
const _hoisted_21 = {
  key: 1,
  class: "card-payment__warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_payment_methods = _resolveComponent("icon-payment-methods")!
  const _component_payment_to_show_tile = _resolveComponent("payment-to-show-tile")!
  const _component_payment_method_expired = _resolveComponent("payment-method-expired")!

  return (!_ctx.paymentsToShowGiftCard.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["card-menu card-payment-menu", {
            'card-menu__no-data card-payment-menu__no-data': !_ctx.paymentsToShow?.length,
            'card-payment-menu__expired': _ctx.hasPaymentMethodsExpired,
        }])
      }, [
        (_ctx.paymentsToShow?.length)
          ? (_openBlock(), _createBlock(_component_icon_payment_methods, {
              key: 0,
              class: _normalizeClass({ payment_top_icon: _ctx.deferredOptions?.length }),
              size: 0.4,
              color: "#FF9012"
            }, null, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.paymentsToShow.length)
          ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentsToShow, (payment, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: payment }, [
                  payment
                    ? (_openBlock(), _createBlock(_component_payment_to_show_tile, {
                        key: 0,
                        payment: payment,
                        "show-button": index === 0,
                        onOnChangeSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-change-selected')))
                      }, null, 8, ["payment", "show-button"]))
                    : _createCommentVNode("", true),
                  (_ctx.showMissingPayment)
                    ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.$t("txt['payment-methods__pending']")), 1))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("general['payment-methods']")), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("txt['checkout__enter-payment-method']")), 1)
            ])),
        (!_ctx.paymentsToShow?.length)
          ? (_openBlock(), _createElementBlock("button", {
              key: 3,
              class: "payment__card-button text-no-selected",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-change-selected')))
            }, _toDisplayString(_ctx.$t("general['select']")), 1))
          : _createCommentVNode("", true),
        (_ctx.hasPaymentMethodsExpired)
          ? (_openBlock(), _createBlock(_component_payment_method_expired, {
              key: 4,
              onOnChangeSelected: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-change-selected')))
            }))
          : _createCommentVNode("", true)
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["card-menu", {
            'card-payment-menu': _ctx.paymentsToShow.length,
            'card-menu__no-data card-payment-menu__no-data':
                !!_ctx.cart.totalDetails.totalCost && !_ctx.paymentsToShow.length && !_ctx.defaultPayment,
            'card-payment-menu__expired': _ctx.hasPaymentMethodsExpired,
        }])
      }, [
        (_ctx.idGiftCards.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.cart.totalDetails.totalCost)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!_ctx.paymentsToShow.length)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (!_ctx.defaultPayment)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("general['payment-methods']")), 1),
                                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("txt['checkout__enter-payment-method']")), 1)
                              ]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createElementVNode("div", _hoisted_9, [
                                  _createVNode(_component_icon_payment_methods, {
                                    size: 0.4,
                                    color: "#FF9012"
                                  })
                                ]),
                                _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t("general['payment-methods']")), 1)
                              ], 64))
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (_ctx.paymentsToShow?.length)
                            ? (_openBlock(), _createBlock(_component_icon_payment_methods, {
                                key: 0,
                                size: 0.4,
                                color: "#FF9012"
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.paymentsToShow.length)
                            ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentsToShow, (payment, index) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, { key: payment }, [
                                    payment
                                      ? (_openBlock(), _createBlock(_component_payment_to_show_tile, {
                                          key: 0,
                                          payment: payment,
                                          "show-button": index === 0,
                                          onOnChangeSelected: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-change-selected')))
                                        }, null, 8, ["payment", "show-button"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.showMissingPayment)
                                      ? (_openBlock(), _createElementBlock("h4", _hoisted_12, _toDisplayString(_ctx.$t("txt['payment-methods__pending']")), 1))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (!_ctx.paymentsToShow.length)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_icon_payment_methods, {
                              size: 0.4,
                              color: "#FF9012"
                            })
                          ]),
                          _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t("general['payment-methods']")), 1)
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_icon_payment_methods, {
                            size: 0.4,
                            color: "#FF9012"
                          }),
                          _createElementVNode("section", _hoisted_15, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentsToShow, (payment, index) => {
                              return (_openBlock(), _createElementBlock(_Fragment, { key: payment }, [
                                payment
                                  ? (_openBlock(), _createBlock(_component_payment_to_show_tile, {
                                      key: 0,
                                      payment: payment,
                                      "show-button": index === 0,
                                      onOnChangeSelected: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-change-selected')))
                                    }, null, 8, ["payment", "show-button"]))
                                  : _createCommentVNode("", true),
                                (_ctx.showMissingPayment)
                                  ? (_openBlock(), _createElementBlock("h4", _hoisted_16, _toDisplayString(_ctx.$t("txt['payment-methods__pending']")), 1))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          ])
                        ], 64))
                  ], 64))
            ], 64))
          : (!_ctx.paymentsToShow.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t("general['payment-methods']")), 1),
                _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t("txt['checkout__enter-payment-method']")), 1)
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (_ctx.paymentsToShow?.length)
                  ? (_openBlock(), _createBlock(_component_icon_payment_methods, {
                      key: 0,
                      size: 0.4,
                      color: "#FF9012"
                    }))
                  : _createCommentVNode("", true),
                (_ctx.paymentsToShow.length)
                  ? (_openBlock(), _createElementBlock("section", _hoisted_20, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentsToShow, (payment, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: payment }, [
                          payment
                            ? (_openBlock(), _createBlock(_component_payment_to_show_tile, {
                                key: 0,
                                payment: payment,
                                "show-button": index === 0,
                                onOnChangeSelected: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('on-change-selected')))
                              }, null, 8, ["payment", "show-button"]))
                            : _createCommentVNode("", true),
                          (_ctx.showMissingPayment)
                            ? (_openBlock(), _createElementBlock("h4", _hoisted_21, _toDisplayString(_ctx.$t("txt['payment-methods__pending']")), 1))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 64)),
        (!_ctx.paymentsToShow?.length)
          ? (_openBlock(), _createElementBlock("button", {
              key: 3,
              class: _normalizeClass(["payment__card-button text-no-selected", { 'text-orange': _ctx.cart.totalDetails.totalCost <= 0.01 && _ctx.idGiftCards.length }]),
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.resetPayments && $options.resetPayments(...args)))
            }, _toDisplayString(_ctx.$t("general['select']")), 3))
          : _createCommentVNode("", true),
        (_ctx.hasPaymentMethodsExpired)
          ? (_openBlock(), _createBlock(_component_payment_method_expired, {
              key: 4,
              onOnChangeSelected: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('on-change-selected')))
            }))
          : _createCommentVNode("", true)
      ], 2))
}