import type { InjectionKey, Ref, VNode } from 'vue';
import { defineComponent, h, ref } from 'vue';
import TheNotification from './TheNotification.vue';
import type { NotificationPayload, ToastContent } from './Notification';
import { createUUID } from '@/utils/utils';

export const notifierKey = Symbol() as InjectionKey<
    (
        value?: NotificationPayload,
        options?: {
            lastNotificationId?: string;
        },
    ) => void
>;
export const widthKey = Symbol() as InjectionKey<Ref<number>>;

export function useNotifier<TData extends NotificationPayload>(config?: { container?: string }) {
    const data = ref<ToastContent[]>([]);

    function removeNotify(id: string) {
        if (!id) return;
        data.value = data.value?.filter(({ toast_id }) => toast_id !== id);
    }

    /**
     *
     * @param value if you set value then notify
     * @param lastNotificationId the last notification id used to delete then when you need
     * @returns uuid: the notification id created
     */
    function notify(value?: TData, { lastNotificationId }: { lastNotificationId?: string } = {}): string {
        if (lastNotificationId) removeNotify(lastNotificationId);
        if (!value || !Object.keys(value)?.length) return;
        const notification = {
            toast_id: createUUID(data.value?.length),
            ...value,
        };
        if (value.type === 'CHAT')
            data.value.filter((not) => not.grouped === value.grouped).forEach((dat) => removeNotify(dat.toast_id));

        data.value.push(notification);
        if (notification.withoutTimeout) {
            return notification.toast_id;
        }
        setTimeout(() => {
            removeNotify(notification.toast_id);
        }, value.timeout ?? 4000);
        return notification.toast_id;
    }

    const NotifierComponent = defineComponent({
        inheritAttrs: false,
        setup(_, { slots }) {
            function onClicked(notification: ToastContent) {
                if (data.value.length && notification.actions?.click) notification.actions?.click();
                removeNotify(notification.toast_id);
            }

            function onClosed(notification: ToastContent) {
                if (data.value.length && notification.actions?.close) notification.actions?.close();
                removeNotify(notification.toast_id);
            }

            return () =>
                h(
                    TheNotification,
                    {
                        onClicked,
                        onClosed,
                        notifications: data.value,
                        container: config?.container ?? '.toast-container',
                    },
                    {
                        header: () => slots.header?.(),
                        default: () => slots.default?.({ data: data.value }),
                    },
                );
        },
    });
    return {
        NotifierComponent: NotifierComponent as typeof NotifierComponent & {
            // we augment the wrapper type with a constructor type that overrides/adds
            // the slots type information by adding a `$slots` object with slot functions defined as properties
            new (): {
                $slots: {
                    header: (arg: { data?: TData }) => VNode[];
                    default: (arg: { data: TData }) => VNode[];
                };
            };
        },
        notify,
    };
}
