import { ResponseOrderModel } from './ResponseOrderModel';
import { DeliveryDateModel, toDeliveryDateModel } from './DeliveryDateModel';

class ResponsePurchaseModel {
    readonly ordersData: Array<ResponseOrderModel>;
    readonly deliveryDates: Array<DeliveryDateModel>;
    readonly messagePayment: string;
    readonly message: string;
    readonly ordersCalculated: number;
    readonly hoursSaved: string;
    readonly totalSavings: number;
    readonly sent: boolean;

    constructor(
        ordersData: Array<ResponseOrderModel>,
        deliveryDates: Array<DeliveryDateModel>,
        messagePayment: string,
        message: string,
        ordersCalculated: number,
        hoursSaved: string,
        totalSavings: number,
        sent: boolean,
    ) {
        this.ordersData = ordersData;
        this.deliveryDates = deliveryDates;
        this.messagePayment = messagePayment;
        this.message = message;
        this.ordersCalculated = ordersCalculated;
        this.hoursSaved = hoursSaved;
        this.totalSavings = totalSavings;
        this.sent = sent;
    }
}
const toResponsePurchaseModel = (data: object): ResponsePurchaseModel => {
    return data
        ? new ResponsePurchaseModel(
              data['orders_data'].map((order) => new ResponseOrderModel(order)) ?? [],
              data['delivery_dates'].map((date: DeliveryDateModel) => toDeliveryDateModel(date)) ?? [],
              data['message_payment'] ?? '',
              data['message'] ?? '',
              data['orders_calculated'] ?? 0,
              data['hours_saved'] == '0' ? '' : data['hours_saved'] ?? '',
              data['total_savings'] ?? 0,
              data['data']['sent'] ?? false,
          )
        : null;
};
export { ResponsePurchaseModel, toResponsePurchaseModel };
