<template>
    <div class="loading">
        <svg data-name="loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <path class="line line-first" d="M3,31.68A29.16,29.16,0,1,1,3,32" />
            <path class="line line-second" d="M10,31.76A22.12,22.12,0,1,1,10,32" />
            <path class="line line-third" d="M17,31.83A15.09,15.09,0,1,1,17,32" />
        </svg>
    </div>
</template>
<script>
export default {
    name: 'triple-loading',
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_margin.scss';
.loading {
    width: 4rem;
    height: 4rem;
    justify-content: center;
    margin: $margin-lg auto;
    background-color: white;
    box-shadow: 0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    position: relative;
    svg {
        transform-origin: center;
        transform: scale(0.9, 0.9);
    }
}
.line {
    fill: none;
    stroke-miterlimit: 5;
    stroke-width: 4px;
    stroke-dasharray: 1000;
    stroke-dashoffset: 960;
    transform-origin: center;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    zoom: 0.8;
}
.line-first {
    animation-name: spin_second;
    stroke: $orange;
}
.line-second {
    animation-name: spin_second;
    stroke: $green;
}
.line-third {
    stroke-dashoffset: 980;
    animation-name: spin;
    stroke: $orange;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spin_second {
    0% {
        stroke-dashoffset: 960;
        transform: rotate(0deg);
    }
    50% {
        stroke-dashoffset: 900;
    }
    100% {
        stroke-dashoffset: 960;
        transform: rotate(360deg);
    }
}
</style>
