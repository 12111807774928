<template>
    <h4>{{ $t('general.retailers') }}</h4>
    <aside class="autocomplete-retailers-global">
        <div
            v-for="(result, index) in retailersGlobalSearch"
            :key="index"
            class="autocomplete-retailers-global__tile cursor-pointer"
            @click="$emit('on-select-retailer', result)"
        >
            <img
                v-if="result.retailer_logo?.length"
                :alt="result.retailer_name"
                :src="result.retailer_logo"
                @error="displayDefaultImg"
            />
            <p>{{ result.retailer_name }}</p>
        </div>
    </aside>
</template>

<script lang="ts" setup>
import { useImage } from '@/composables/ImageComposable';
import { GlobalSearchProductModel } from '@/models/product/ProductModelAdapters.ts';

defineProps({
    retailersGlobalSearch: {
        type: Array<GlobalSearchProductModel>,
        required: true,
    },
});

const { displayDefaultImg } = useImage();
defineEmits<(e: 'on-select-retailer', value: GlobalSearchProductModel) => void>();
</script>

<style lang="scss" scoped>
@import './autocomplete-retailers-global-search.scss';
</style>
