export class CreditCardModel {
    readonly phone: string;
    readonly cardToken: string;
    readonly verificationCode: string;

    constructor(data: CreditCardDto) {
        this.phone = data?.phone;
        this.cardToken = data?.cardToken;
        this.verificationCode = data?.verificationCode;
    }
}

interface CreditCardDto {
    phone: string;
    cardToken: string;
    verificationCode: string;
}
