<script lang="ts" setup>
import RelatedContentBlog from '@/views/newsPress/components/RelatedContentBlog.vue';
import HeaderDetailBlog from '@/views/newsPress/components/HeaderDetailBlog.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import type { PressReleasesDetail } from '@/models/external/PressReleases';
import SkeletonDetailNews from '@/views/newsPress/components/SkeletonDetailNews.vue';

const $route = useRoute();
const $store = useStore();
const isLoading = ref(false);
const itemNews = ref<PressReleasesDetail>({} as PressReleasesDetail);

const paramsRoute = computed(() => {
    return {
        title: `${$route.params?.title}`,
        id: $route.params?.id,
    };
});

const getDetailReleases = async () => {
    if (isLoading.value || !paramsRoute.value.id) return;
    isLoading.value = true;
    itemNews.value = await $store.dispatch('external/getPressDetailReleases', paramsRoute.value.id);
    isLoading.value = false;
};

getDetailReleases();
</script>

<template>
    <main :key="$route.fullPath" class="the-news-details">
        <section class="the-news-details__content">
            <HeaderDetailBlog :news="itemNews" :news-title="paramsRoute.title" />
            <SkeletonDetailNews v-if="isLoading" />
            <img
                v-if="itemNews?.picture"
                class="the-news-details__img"
                :alt="itemNews?.title"
                :src="itemNews?.picture ?? ''"
            />
            <div v-html="itemNews?.description" />
            <p v-if="itemNews.type === 'MEDIA_NEWS' && itemNews.link" class="the-news-details__txt_source">
                {{ $t("general['source']") }}:
                <a class="the-news-details__link" :href="itemNews.link" target="_blank">{{ itemNews.link }}</a>
            </p>
        </section>
        <RelatedContentBlog v-if="itemNews?.press_release_related?.length" :news="itemNews.press_release_related" />
    </main>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.the-news-details {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    @include style.phone {
        padding: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: style.$margin-md;
        margin: style.$margin-md;
    }

    &__img {
        width: 100%;
        border-radius: 10px;
    }

    &__link {
        @include style.body1;
        font-size: 16px;
        font-weight: 400;
        color: style.$orange;
    }

    &__txt_source {
        @include style.body1;
        font-size: 16px;
        font-weight: 600;
        color: style.$dark-grey;
        white-space: normal;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
