<template>
    <base-icon :icon-name="$t('icon.phone')" :width="width" :height="height" viewBox="0 0 32 27" :iconColor="color">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.1552 11.1367C12.5543 10.7374 13.1807 10.7106 13.547 11.0769L16.3948 13.9247C16.7612 14.2909 16.7342 14.9172 16.335 15.3165C12.6652 18.9862 7.64587 14.3592 4.96718 11.7101C2.31789 9.03145 -2.30897 4.012 1.36074 0.342294C1.75989 -0.0568536 2.38624 -0.0838198 2.7526 0.282424L5.6004 3.13022C5.96662 3.49658 5.93979 4.1228 5.54053 4.52208C3.24055 6.82206 4.45491 7.73333 6.67835 9.99924C8.94409 12.2225 9.85536 13.4372 12.1555 11.1371L12.1552 11.1367Z"
            fill="url(#paint0_linear_548_2013)"
        />
        <path
            d="M11.4511 7.41904C11.5358 7.64994 11.7916 7.76862 12.0226 7.6839C12.2535 7.59919 12.3721 7.34333 12.2874 7.1124C12.1624 6.77115 11.9978 6.43771 11.7936 6.11837C11.5935 5.80567 11.3611 5.51372 11.0962 5.24886C10.8315 4.98413 10.5395 4.75152 10.2267 4.55142C9.90737 4.34722 9.57391 4.18269 9.23266 4.05764C9.00176 3.97292 8.74574 4.09147 8.66116 4.3225C8.57644 4.5534 8.69499 4.80942 8.92602 4.894C9.21414 4.99947 9.49052 5.13507 9.74928 5.3007C9.99868 5.46022 10.239 5.65305 10.4655 5.87957C10.692 6.10611 10.8849 6.34639 11.0444 6.59565C11.2098 6.85443 11.3456 7.13078 11.4511 7.41892L11.4511 7.41904Z"
            fill="url(#paint1_linear_548_2013)"
        />
        <path
            d="M9.45719 2.17538C9.22141 2.10613 8.97414 2.24107 8.90488 2.47686C8.83562 2.71264 8.97056 2.95991 9.20635 3.02917C9.68481 3.17112 10.1487 3.37266 10.5863 3.63356C11.0091 3.88574 11.4102 4.1962 11.7795 4.56547C12.1488 4.93474 12.4592 5.33589 12.7114 5.75868C12.9723 6.1963 13.1739 6.66021 13.3158 7.13863C13.3851 7.37442 13.6323 7.50936 13.8681 7.44011C14.1039 7.37085 14.2389 7.12357 14.1696 6.88779C14.0069 6.33929 13.7752 5.80626 13.4745 5.30233C13.182 4.81186 12.8272 4.35191 12.4102 3.93493C11.9932 3.51796 11.5333 3.1631 11.0428 2.8706C10.5387 2.57005 10.0057 2.33838 9.45734 2.17553L9.45719 2.17538Z"
            fill="url(#paint2_linear_548_2013)"
        />
        <path
            d="M15.1714 6.87396C15.233 7.11159 15.4757 7.25446 15.7133 7.19288C15.9509 7.13129 16.0938 6.88863 16.0322 6.65098C15.8369 5.89829 15.5428 5.16862 15.1499 4.48306C14.7675 3.81587 14.2922 3.18861 13.7242 2.62056C13.1561 2.05251 12.5289 1.57723 11.8617 1.19487C11.1761 0.802081 10.4465 0.507854 9.69391 0.312662C9.45628 0.251073 9.21363 0.393811 9.15202 0.631581C9.09043 0.869215 9.23317 1.11186 9.47094 1.17348C10.1426 1.3478 10.7984 1.61279 11.4192 1.96859C12.0171 2.3113 12.581 2.73887 13.0935 3.2514C13.606 3.76393 14.0336 4.32775 14.3763 4.92568C14.7321 5.54661 14.9972 6.20226 15.1714 6.87394L15.1714 6.87396Z"
            fill="url(#paint3_linear_548_2013)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_548_2013"
                x1="8.32646"
                y1="0.0244141"
                x2="8.32646"
                y2="16.6772"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FFB561" />
                <stop offset="1" stop-color="#FF9012" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_548_2013"
                x1="10.4743"
                y1="4.03027"
                x2="10.4743"
                y2="7.71125"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FFB561" />
                <stop offset="1" stop-color="#FF9012" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_548_2013"
                x1="11.5372"
                y1="2.15723"
                x2="11.5372"
                y2="7.45825"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FFB561" />
                <stop offset="1" stop-color="#FF9012" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_548_2013"
                x1="12.5921"
                y1="0.29834"
                x2="12.5921"
                y2="7.20718"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FFB561" />
                <stop offset="1" stop-color="#FF9012" />
            </linearGradient>
        </defs>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-phone',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 32 * this.size;
        },
        height(): number {
            return 27 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
