<template>
    <div class="expandable">
        <slot name="header" />
        <div class="expandable__dropdown">
            <div class="expandable__dropdown--right">
                <slot name="header-side" />
            </div>
            <div class="expandable__separator">
                <icon-arrow
                    :icon-name="localExpanded ? $t('icon.hide') : $t('icon.show')"
                    :size="0.5"
                    :color="color"
                    v-if="canExpanded"
                    class="expandable__arrow"
                    :class="{ 'expandable__arrow--up': localExpanded }"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import IconArrow from '@/components/icons/IconArrow.vue';
export default {
    name: 'collapsable-header',
    components: { IconArrow },
    props: {
        canExpanded: {
            type: Boolean,
            required: true,
        },
        isExpanded: {
            type: Boolean,
            default: true,
        },
        errorHeader: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localExpanded: this.isExpanded,
        };
    },
    computed: {
        color(): string {
            return this.errorHeader ? '#c90808' : '#606060';
        },
    },
    watch: {
        isExpanded(isExpanded: boolean, _) {
            this.localExpanded = isExpanded;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/index.scss';
.expandable {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    column-gap: $margin-sm;
    box-sizing: border-box;
    align-items: center;
    min-height: 1.5rem;
    width: 100%;
    cursor: default;
    &:hover {
        background-color: $canvas;
    }
    &__dropdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &--right {
            width: 100%;
            text-align: right;
        }
    }
    &__arrow {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: rotate(90deg);
        transition: all ease-in-out 300ms;
    }
    &__arrow--up {
        transform: rotate(-90deg);
        transition: all ease-in-out 300ms;
    }
    &__separator {
        width: $margin-xlg;
    }
}
</style>
