import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!
  const _component_base_card = _resolveComponent("base-card")!

  return ($options.isMobile)
    ? (_openBlock(), _createBlock(_component_base_card_flat, {
        key: 0,
        "border-radius": "0",
        class: "flex-column",
        padding: "0",
        "border-width": "0",
        "bg-color": "#fff"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_base_card, {
        key: 1,
        class: "flex-column",
        padding: "0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }))
}