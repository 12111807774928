class RetailerPermissionModel {
    readonly retailerId: number;
    readonly retailerName: string;
    permissionStatus: string;

    constructor(retailerId: number, retailerName: string, permissionStatus: string) {
        this.retailerId = retailerId;
        this.retailerName = retailerName;
        this.permissionStatus = permissionStatus;
    }
}
const toRetailerPermissionModel = (data: object): RetailerPermissionModel => {
    return data
        ? new RetailerPermissionModel(data['retailer_id'], data['retailer_name'], data['permission_status'])
        : null;
};

export { toRetailerPermissionModel, RetailerPermissionModel };
