import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa91526a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "note" }
const _hoisted_2 = {
  key: 0,
  class: "note__title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_note = _resolveComponent("icon-note")!
  const _component_TextAreaNote = _resolveComponent("TextAreaNote")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_alert_note_not_save = _resolveComponent("alert-note-not-save")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_modal, {
      title: _ctx.$t('cta.add-note'),
      "z-index": 5,
      "header-text-color": "#ff9012",
      onOnClose: $options.closeModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_icon_note, {
            size: 4,
            color: "#FF9012"
          }),
          ($options.hasTitleAndPlaceholderOverride)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($options.noteTitle), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_TextAreaNote, {
            "is-loading-external": $data.isLoading,
            placeholder: $options.notePlaceholder,
            product: $props.initialProduct,
            "is-from-modal": "",
            onOnSave: $options.saveNote,
            onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close')))
          }, null, 8, ["is-loading-external", "placeholder", "product", "onOnSave"])
        ])
      ]),
      _: 1
    }, 8, ["title", "onOnClose"]),
    ($data.showAlertNotSaveNote)
      ? (_openBlock(), _createBlock(_component_alert_note_not_save, {
          key: 0,
          onOnAccept: $options.saveNote,
          onOnDiscard: _cache[1] || (_cache[1] = ($event: any) => ($options.closeModal(true))),
          onOnClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showAlertNotSaveNote = false))
        }, null, 8, ["onOnAccept"]))
      : _createCommentVNode("", true)
  ], 64))
}