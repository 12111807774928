import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3146364"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deactivate-ac" }
const _hoisted_2 = { class: "deactivate-ac__title" }
const _hoisted_3 = { class: "deactivate-ac__selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_radio = _resolveComponent("radio")!
  const _component_text_area = _resolveComponent("text-area")!
  const _component_general_alert = _resolveComponent("general-alert")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_general_alert, {
      "accent-header": true,
      "accept-button-txt": _ctx.$t($data.showOptions ? 'cta.continue' : 'cta.no_go-back'),
      "decline-button-txt": _ctx.$t('cta.yes_deactivate'),
      "is-disabled": $options.checkForDisableButton,
      "is-loading": $data.isLoading,
      "only-confirmation-btn": $data.showOptions || $data.isLoading,
      "show-buttons": true,
      title: _ctx.$t('txt.deactivate-account__want-deactivate'),
      message: "",
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => ($data.isLoading ? null : _ctx.$emit('on-close'))),
      onOnDecline: $options.acceptAlert,
      onOnAccept: $options.onContinue
    }, _createSlots({ _: 2 }, [
      ($data.showOptions)
        ? {
            name: "content",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('txt.deactivate-account__why-deactivate')), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_radio, {
                    modelValue: $data.reason,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.reason) = $event)),
                    inputs: $data.deactivateReasons,
                    "value-select": $data.reason,
                    label: "",
                    "radio-group": "deactivateOptions"
                  }, null, 8, ["modelValue", "inputs", "value-select"])
                ]),
                _createVNode(_component_text_area, {
                  modelValue: $data.reasonTxt,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.reasonTxt) = $event)),
                  modelModifiers: { trim: true },
                  placeholder: _ctx.$t('general.leave_comment'),
                  rows: 4
                }, null, 8, ["modelValue", "placeholder"])
              ])
            ]),
            key: "0"
          }
        : {
            name: "content",
            fn: _withCtx(() => [
              _createElementVNode("div", { class: "deactivate-ac__text" }, [
                _createElementVNode("p", { class: "deactivate-ac__text-body" }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('txt.deactivate-account__we-sorry')) + " ", 1),
                  _createElementVNode("span", { class: "deactivate-ac__text-body--highlighted" }, _toDisplayString(_ctx.$t('txt.deactivate-account__deactivate-acc')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('txt.deactivate-account__all')) + " ", 1),
                  _createElementVNode("span", { class: "deactivate-ac__text-body--highlighted" }, _toDisplayString(_ctx.$t('txt.deactivate-account__data')), 1),
                  _createTextVNode(_toDisplayString(_ctx.$t('txt.deactivate-account__saved')) + " ", 1),
                  _createElementVNode("span", { class: "deactivate-ac__text-body--highlighted" }, _toDisplayString(_ctx.$t('txt.deactivate-account__lose')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('txt.deactivate-account__not-recovery')) + " ", 1),
                  _createElementVNode("span", { class: "deactivate-ac__text-body--highlighted" }, _toDisplayString(_ctx.$t('txt.deactivate-account__also')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('txt.deactivate-account__create')) + " ", 1),
                  _createElementVNode("span", { class: "deactivate-ac__text-body--highlighted" }, _toDisplayString(_ctx.$t('txt.deactivate-account__new-acc')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('txt.deactivate-account__same-credentials')) + " ", 1),
                  _createElementVNode("span", { class: "deactivate-ac__text-body--highlighted" }, _toDisplayString(_ctx.$t('txt.deactivate-account__sure')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('txt.deactivate-account__you-want')) + " ", 1),
                  _createElementVNode("span", { class: "deactivate-ac__text-body--highlighted" }, _toDisplayString(_ctx.$t('txt.deactivate-account__deactivate')), 1)
                ])
              ])
            ]),
            key: "1"
          }
    ]), 1032, ["accept-button-txt", "decline-button-txt", "is-disabled", "is-loading", "only-confirmation-btn", "title", "onOnDecline", "onOnAccept"]),
    _createVNode(_component_snack_bar, {
      body: $data.snackBarMessage,
      "is-active": $data.showSnackBar,
      "is-failure": !$data.isSuccess,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[3] || (_cache[3] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}