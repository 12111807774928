import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a9f1830"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "prime-payment" }
const _hoisted_2 = {
  key: 0,
  class: "prime-payment__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_coupon = _resolveComponent("coupon")!
  const _component_payment_method_only_credit_card_selection_tile = _resolveComponent("payment-method-only-credit-card-selection-tile")!
  const _component_card_tipti_prime_product = _resolveComponent("card-tipti-prime-product")!
  const _component_balance = _resolveComponent("balance")!
  const _component_select_credit_card = _resolveComponent("select-credit-card")!
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_section_footer = _resolveComponent("section-footer")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_modal, {
      "auto-size": !$options.isMobile,
      "has-back-arrow": $data.changeCreditCard,
      title: $options.modalTitle,
      "z-index": 5,
      "header-text-color": "#ff9012",
      onOnClose: $options.onClose,
      onOnBackArrow: $options.toggleShowModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (!$data.changeCreditCard)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_coupon, {
                  "full-width": true,
                  section: "TIPTI_PRIME"
                }),
                _createVNode(_component_payment_method_only_credit_card_selection_tile, {
                  onOnChangePayment: _cache[0] || (_cache[0] = ($event: any) => ($data.changeCreditCard = true))
                }),
                _createVNode(_component_card_tipti_prime_product, {
                  class: "cursor-pointer",
                  onClick: $options.onClose
                }, null, 8, ["onClick"]),
                _createVNode(_component_balance, {
                  class: "prime-payment__balance",
                  balance: $options.prepareBalance,
                  "origin-screen": 'TIPTI_PRIME'
                }, null, 8, ["balance"])
              ]))
            : (_openBlock(), _createBlock(_component_select_credit_card, {
                key: 1,
                amount: $options.currentPrimeDeal.price,
                onOnClose: _cache[1] || (_cache[1] = ($event: any) => ($data.changeCreditCard = false))
              }, null, 8, ["amount"]))
        ]),
        (!$data.changeCreditCard)
          ? (_openBlock(), _createBlock(_component_section_footer, {
              key: 0,
              class: "prime-payment__footer",
              "has-border": true,
              "with-shadow": true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_btn_out_line, {
                  class: "prime-payment__footer-btn",
                  "is-disabled": !_ctx.paymentIdSelected,
                  "is-loading": $data.isLoading,
                  txt: _ctx.$t('cta.subscribe'),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.paymentIdSelected ? _ctx.$emit('show-pdf-terms') : null))
                }, null, 8, ["is-disabled", "is-loading", "txt"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["auto-size", "has-back-arrow", "title", "onOnClose", "onOnBackArrow"]),
    _createVNode(_component_snack_bar, {
      body: $props.snackData?.msg ?? '',
      "is-active": $data.isActive && $props.isFromError,
      "is-failure": $props.snackData?.failed ?? false,
      "is-success": $props.snackData?.success ?? false,
      onOnSnackbarClose: _cache[3] || (_cache[3] = ($event: any) => ($data.isActive = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}