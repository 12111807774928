<template>
    <base-icon :height="height" :icon-color="color" :icon-name="$t('icon.rate')" :width="width" viewBox="0 0 17 15">
        <path
            :fill="color"
            d="M8.51709 0.516174L10.3132 6.04404H16.1255L11.4233 9.46045L13.2194 14.9883L8.51709 11.5719L3.81481 14.9883L5.61092 9.46045L0.908638 6.04404H6.72098L8.51709 0.516174Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconDecrement',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFB100',
        },
    },
    computed: {
        width(): number {
            return 19 * this.size;
        },
        height(): number {
            return 18 * this.size;
        },
    },
};
</script>
