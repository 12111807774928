<template>
    <base-icon
        :height="dimensions.height"
        :icon-name="$t('icon.replacement')"
        :width="dimensions.width"
        icon-color="transparent"
        viewBox="0 0 27 23"
    >
        <g opacity="0.502">
            <path
                d="M0.972656 0.199219V8.92336C0.97296 10.8553 1.77277 12.7079 3.19618 14.0739C4.61959 15.4399 6.55003 16.2072 8.56288 16.2072H20.3732"
                opacity="0.502"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-width="1.11"
            />
            <path
                d="M20.0078 11.6152V21.2376L25.2933 16.1648L20.0078 11.6152Z"
                fill="#4D4D4D"
                opacity="0.502"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-width="1.11"
            />
        </g>
    </base-icon>
</template>

<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#606060',
    },
});

const { t: $t } = useI18n();

const dimensions = reactive({
    width: 27 * props.size,
    height: 23 * props.size,
});
</script>
