<template>
    <base-icon :icon-name="$t('icon.card')" :width="width" :height="height" viewBox="0 0 26 17" :iconColor="color">
        <g clip-path="url(#clip0_40_192687)">
            <path
                d="M0 14.633V5.94176C0.126181 5.89339 0.253046 5.80308 0.379226 5.80308C8.79127 5.7962 17.2033 5.79426 25.6154 5.79727C25.9301 5.79727 25.9993 5.86178 25.9993 6.16881C25.9993 8.86443 26.0041 11.56 25.9932 14.2557C25.9914 14.5526 25.9429 14.8476 25.8494 15.131C25.4614 16.292 24.3875 16.9984 23.0449 16.9984C16.4419 16.9984 9.84096 16.9984 3.24203 16.9984C3.08125 16.9984 2.9198 17.0048 2.7597 16.9984C1.63696 16.9339 0.800508 16.434 0.295786 15.4754C0.158072 15.211 0.0963315 14.9136 0 14.633ZM5.3023 10.8183C4.35256 10.7486 4.04389 11.0847 4.0466 11.9987C4.0466 12.8256 4.34171 13.1526 5.27246 13.152C6.20321 13.1514 6.51662 12.8585 6.51662 11.9671C6.51595 11.0924 6.18285 10.7473 5.3023 10.8183Z"
                :fill="color"
            />
            <path
                d="M-0.00100755 2.36837C0.117711 2.04585 0.189621 1.69559 0.364646 1.40081C0.912786 0.47776 1.77027 0.0107507 2.88487 0.00430026C4.50147 -0.00537533 6.11808 0.00430026 7.734 0.00430026C12.8445 0.00430026 17.9551 0.00430026 23.0656 0.00430026C24.4794 0.00430026 25.6435 0.824147 25.9027 2.10326C26.0295 2.72572 25.9766 3.38366 25.9807 4.02548C25.9807 4.1003 25.8307 4.18802 25.7351 4.24608C25.6869 4.27575 25.6028 4.25253 25.535 4.25253C17.174 4.25253 8.81287 4.25059 0.451482 4.24672C0.299522 4.24672 0.147565 4.15577 -0.00439453 4.10804L-0.00100755 2.36837Z"
                :fill="color"
            />
        </g>
        <defs>
            <clipPath id="clip0_40_192687">
                <rect width="26" height="17" fill="white" />
            </clipPath>
        </defs>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-card',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 20 * this.size;
        },
        height(): number {
            return 20 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
