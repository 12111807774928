import { loyaltyAndRewardsService } from '@/modules/affiliation/infraestructure/LoyaltyAndRewardsService';
import { ref } from 'vue';
import {
    AffiliationAlertBenefitDto,
    AffiliationDocuments,
    AffiliationExternalURLDto,
    TypeDocument,
} from '@/modules/affiliation/models/AffiliateVerificationDto';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { useCartType } from '@/modules/core/composable/useCartType';
import { WorkingState } from '@/modules/core/types/WorkingState';
import { useUser } from '@/composables/useUser';
import type { BannerModel } from '@/models/promotions/banners/BannerModel';
import { useApp } from '@/composables/useApp';
import { logger } from '@/utils/logger';

const bannerUserAffiliateAlert = ref<BannerModel>();
const bannerSectionName = 'Beneficios Afiliado';
type TypeAffiliation = 'USER-REGISTERED' | 'RENEWAL';
export type RegistrationBy = 'AFFILIATION';
const showAlertCompleteProfile = ref<boolean>(false);
const showAlert = ref<boolean>(false);
const isCheckEnded = ref<boolean>(false);
const userAffiliateAlert = ref<AffiliationAlertBenefitDto>(undefined);
const userRegisteredAlert = ref<AffiliationAlertBenefitDto>(undefined);
const affiliationDocuments = ref<AffiliationDocuments[]>([] as AffiliationDocuments[]);
const documentTcu = ref<AffiliationDocuments>({} as AffiliationDocuments);
const documentCva = ref<AffiliationDocuments>({} as AffiliationDocuments);
const documentPpr = ref<AffiliationDocuments>({} as AffiliationDocuments);
const typeAffiliation = ref<TypeAffiliation>(undefined);

export const useAffiliationAlert = () => {
    const subscribing = ref<WorkingState>('NONE');
    const renewAlert = ref<AffiliationAlertBenefitDto>(undefined);
    const outputExternalAlert = ref<AffiliationExternalURLDto>(undefined);
    const { retailerSelected } = useRetailer();
    const { isMainCart } = useCartType();
    const { isAuth, checkCompletedProfile } = useUser();
    const { $t, notifier } = useApp();

    const checkInformation = async (): Promise<void> => {
        const franchise_id = retailerSelected.value?.franchise?.id;

        if (!franchise_id || !isMainCart.value || !isAuth.value) {
            showAlert.value = false;
            isCheckEnded.value = true;
            return;
        }

        const serviceData = await loyaltyAndRewardsService.checkInformation(franchise_id);
        isCheckEnded.value = true;
        if (!serviceData) return;
        const { user_affiliate_alert, user_registered_alert, user_renewal_alert, franchise_program, user_new_alert } =
            serviceData;

        showAlertCompleteProfile.value = Boolean(!checkCompletedProfile.value && franchise_program && user_new_alert);
        if (!checkCompletedProfile.value) return;

        if (user_affiliate_alert) userAffiliateAlert.value = user_affiliate_alert;
        if (user_registered_alert) {
            userRegisteredAlert.value = user_registered_alert;
            typeAffiliation.value = 'USER-REGISTERED';
        }

        if (user_renewal_alert) {
            renewAlert.value = user_renewal_alert;
            typeAffiliation.value = 'RENEWAL';
        }
    };

    const subscribe = async (): Promise<void> => {
        if (userAffiliateAlert.value) {
            userAffiliateAlert.value = null;
            showAlert.value = false;
            return;
        }

        if (subscribing.value === 'LOADING') return;
        subscribing.value = 'LOADING';
        try {
            const franchise_id = retailerSelected.value?.franchise?.id;
            if (!franchise_id) return;
            const { is_subscribed } = await loyaltyAndRewardsService.subscription({
                franchise_id,
            });
            subscribing.value = is_subscribed ? 'SUCCESS' : 'ERROR';
            if (subscribing.value === 'SUCCESS') return handleNotificationSubscription();
        } catch (err) {
            subscribing.value = 'ERROR';
            notifier({
                type: 'ERROR',
                body: $t('txt.error-try-again-in-a-few-minutes'),
            });
        } finally {
            showAlert.value = false;
            affiliationDocuments.value = null;
        }
    };

    const closeAlert = (): void => {
        if (userAffiliateAlert.value) {
            userAffiliateAlert.value = null;
            showAlert.value = false;
            return;
        }
        if (outputExternalAlert.value) {
            userAffiliateAlert.value = null;
            showAlert.value = false;
            return;
        }
        if (userRegisteredAlert.value) {
            showAlert.value = false;
            notifier({
                type: 'INFO',
                body: $t('txt.pending-affiliation'),
            });
            userRegisteredAlert.value = null;
            return;
        }
        if (renewAlert.value) {
            showAlert.value = false;
            notifier({
                type: 'INFO',
                body: $t('txt.renewal-pending'),
            });
            renewAlert.value = null;
        }
    };

    const handleNotificationSubscription = () => {
        if (typeAffiliation.value === 'USER-REGISTERED') {
            notifier({
                type: 'SUCCESS',
                body: $t('txt.successful-affiliation'),
            });
            return;
        }
        if (typeAffiliation.value === 'RENEWAL')
            notifier({
                type: 'SUCCESS',
                body: $t('txt.successful-renovation'),
            });
    };

    const getTermsAndConditions = async (): Promise<void> => {
        const franchise_id = retailerSelected.value?.franchise?.id;
        if (!franchise_id || !isMainCart.value || !isAuth.value) return;

        try {
            const documents = await loyaltyAndRewardsService.termsAndConditionsDocuments(franchise_id);
            if (!documents?.length) return;
            affiliationDocuments.value = documents;
            documentTcu.value = filterTypeDocument(TypeDocument.TCU);
            documentCva.value = filterTypeDocument(TypeDocument.CVA) ?? filterTypeDocument(TypeDocument.NAK);
            documentPpr.value = filterTypeDocument(TypeDocument.PPR);
            showAlert.value = Boolean(userRegisteredAlert.value || userAffiliateAlert.value);
        } catch (error) {
            logger('GET_TERMS_AND_CONDITIONS', error);
            documentTcu.value = undefined;
            documentCva.value = undefined;
            documentPpr.value = undefined;
            showAlert.value = false;
        }
    };

    const init = async () => {
        await checkInformation();
        if (isCheckEnded.value && userRegisteredAlert.value) getTermsAndConditions();
        else showAlert.value = !!userAffiliateAlert.value;
    };

    const filterTypeDocument = (typeDocument: TypeDocument) => {
        return affiliationDocuments.value.find((doc) => doc.typeDocument === typeDocument);
    };

    return {
        checkInformation,
        subscribing,
        showAlert,
        renewAlert,
        closeAlert,
        userAffiliateAlert,
        userRegisteredAlert,
        subscribe,
        outputExternalAlert,
        isCheckEnded,
        bannerUserAffiliateAlert,
        bannerSectionName,
        showAlertCompleteProfile,
        getTermsAndConditions,
        init,
        affiliationDocuments,
        documentTcu,
        documentCva,
        documentPpr,
    };
};
