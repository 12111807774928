<template>
    <section class="my-lists" :class="{ 'my-lists--wider': isFromCart }">
        <header class="my-lists-header">
            <IconCircularClose v-if="!isFromCart" class="cursor-pointer" :size="0.8" @click="$emit('on-back')" />
            <div v-else></div>
            <h3 class="my-lists-header__title">{{ $t("cta['add-to-list']") }}</h3>
            <div></div>
        </header>
        <icon-add-list class="my-lists__icon" :size="2" color="#FF9012" />
        <TiptiInputText
            v-if="isCreateNewList"
            v-model="newList"
            class="my-lists__add-list"
            :label="$t('txt.new_list')"
            :placeholder="$t('txt.product-modal__name-of-list')"
            is-required
            @keyup.enter="saveNewList"
        />
        <dotted-rectangle
            v-else
            class="my-lists__add-list"
            :button-text="$t('txt.list__add-list')"
            @click="isCreateNewList = true"
        />
        <div class="my-lists-container" :class="{ 'my-list--scroll': isFromCart }">
            <section v-for="list in myLists" :key="list" class="my-lists-container__option">
                <label :for="list.name">{{ list.name }}</label>
                <check-circular :item="list.id" @on-check-selected="addListSelected(list)" />
            </section>
        </div>
        <btn-solid
            :is-disabled="isLoading || !listIds.length"
            :is-loading="isLoading"
            :txt="$t('cta.done')"
            @click="addToLists"
        />
    </section>
    <snack-bar
        :body="bodySnackBar"
        :is-active="showSnackBar"
        :is-failure="true"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import { ListModel } from '@/models/list/ListModel';
import { logger } from '@/utils/logger';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import IconAddList from '@/components/icons/IconAddList.vue';
import DottedRectangle from '@/components/dottedRectangle/DottedRectangle.vue';
import CheckCircular from '@/components/checks/CheckCircular.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'TheMyLists',
    components: {
        TiptiInputText,
        IconCircularClose,
        BtnSolid,
        IconAddList,
        DottedRectangle,
        CheckCircular,
        SnackBar,
    },
    props: {
        isFromCart: {
            type: Boolean,
            default: false,
        },
        section: {
            type: String,
            required: false,
        },
    },
    emits: ['on-back', 'on-success', 'on-failure'],
    data() {
        return {
            listIds: [],
            listNames: [],
            myLists: Array,
            isCreateNewList: false,
            showSnackBar: false,
            newList: '',
            bodySnackBar: '',
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            product: 'product/currentProduct',
            cart: 'cart/cart',
            productsCart: 'cart/products',
        }),
    },
    methods: {
        ...mapActions({ fetchMyLists: 'lists/getMyList' }),
        addListSelected(list: ListModel): void {
            if (this.listIds.some((id) => list.id == id)) {
                this.listIds = this.listIds.filter((id) => id !== list.id);
                this.listNames = this.listNames.filter((id) => id !== list.name);
                return;
            }
            this.listIds.push(list.id);
            this.listNames.push(list.name);
        },
        async addCartToLists(): Promise<void> {
            try {
                this.isLoading = true;
                await this.$store.dispatch('lists/addCartToList', {
                    listNames: this.listNames,
                    listIds: this.listIds,
                    screen: this.$route.path,
                    section: this.section,
                    products: this.productsCart,
                });

                if (this.listNames.some((listName) => listName === 'Favoritos')) {
                    this.$store.dispatch('segment/productFavorite', {
                        products: this.productsCart,
                        screen: this.$route.path,
                        state: true,
                        isfromIcon: false,
                    });
                }
                ///* Use to return to personal-lists in case if user in a list and add products
                if (
                    this.$route.query?.id &&
                    this.$route.meta.isCategoryList &&
                    Object.values(this.listIds).includes(+this.$route.query?.id)
                ) {
                    await this.$router.push({
                        name: appRoutesMap.list.personalLists,
                    });
                }
                this.$emit('on-success');
            } catch (err) {
                this.$emit('on-failure');
                logger('ADD_CART_TO_LISTS', err);
            } finally {
                this.isLoading = false;
            }
        },
        async addToLists(): Promise<void> {
            if (!this.listIds.length || this.isLoading) return;
            if (this.isFromCart) {
                await this.addCartToLists();
                return;
            }
            try {
                this.isLoading = true;
                await this.$store.dispatch('lists/addEditProductToList', {
                    product: this.product,
                    listIds: this.listIds,
                    listsNames: JSON.stringify(this.listNames),
                    screen: this.$route.path,
                    section: this.section,
                });

                this.$emit(
                    'on-success',
                    this.listNames.length === 1 ? this.listNames.at(0) : null,
                    this.listIds.length === 1 ? this.listIds.at(0) : null,
                );
            } catch (err) {
                this.$emit('on-failure');
                logger('ADD_TO_LISTS', err);
            } finally {
                this.isLoading = false;
                this.$emit('on-back');
            }
        },
        async saveNewList(): Promise<void> {
            try {
                this.isLoading = true;
                if (!this.newList?.length) return;
                await this.$store.dispatch('lists/createList', this.newList);
                this.newList = '';
                this.myLists = await this.fetchMyLists();
            } catch (err) {
                logger('SAVE_NEW_LIST', err);
                this.bodySnackBar = this.$t('alerts.list_create_error');
                this.showSnackBar = true;
            } finally {
                this.isLoading = false;
            }
        },
    },
    async mounted() {
        try {
            this.isLoading = true;
            this.myLists = await this.fetchMyLists();
        } finally {
            this.isLoading = false;
        }
    },
};
</script>

<style lang="scss" scoped>
@import './the-my-lists.scss';
</style>
