<template>
    <section class="programs-skeleton" v-for="counter in 2" :key="counter">
        <base-bone class="title-skeleton"></base-bone>
        <base-card-flat class="container-skeleton" border-color="#fff" padding="0">
            <base-bone v-for="index in 3" :key="index" class="program-skeleton" :is-show="true" />
        </base-card-flat>
    </section>
</template>
<script lang="ts">
import BaseBone from '@/components/skeletons/BaseBone.vue';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';

export default {
    name: 'skeleton-program',
    components: { BaseBone, BaseCardFlat },
};
</script>
<style lang="scss" scoped>
@import 'skeleton-program';
</style>
