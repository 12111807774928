<template>
    <base-alert
        @on-close="$emit('on-close')"
        @on-close-modal="$emit('on-close-modal')"
        :showCloseIcon="showCloseIcon"
        :accentHeader="accentHeader"
        :title="title"
    >
        <slot name="header"></slot>
        <section class="general-alert" :style="`padding: ${padding}`">
            <img v-if="assetsURL.length" :src="require(`@/assets/${assetsURL}`)" :alt="title" />
            <slot name="icon"></slot>
            <h1 v-if="title.length && !accentHeader" class="general-alert__title">{{ title }}</h1>
            <p v-if="message" class="general-alert__message">{{ message }}</p>
            <slot name="content"></slot>
            <section
                v-if="showButtons"
                class="general-alert-btns"
                :class="{ 'general-alert-btns--one-btn': onlyConfirmationBtn }"
            >
                <btn-out-line
                    :txt="declineButtonTxt ?? $t('cta.reject')"
                    @click="$emit('on-decline')"
                    v-if="!onlyConfirmationBtn"
                    bgColor="#FFF"
                    color="#FF9012"
                ></btn-out-line>
                <btn-solid
                    :txt="acceptButtonTxt ?? $t('cta.accept')"
                    @click="onAccept"
                    :isDisabled="isDisabled"
                    :isLoading="isLoading"
                />
            </section>
        </section>
    </base-alert>
</template>

<script lang="ts">
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BaseAlert from '../baseAlert/BaseAlert.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';

export default {
    name: 'general-alert',
    components: {
        BtnSolid,
        BaseAlert,
        BtnOutLine,
    },
    props: {
        showButtons: {
            type: Boolean,
            default: true,
        },
        accentHeader: {
            type: Boolean,
            default: false,
        },
        showCloseIcon: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
        },
        acceptButtonTxt: {
            type: String,
        },
        declineButtonTxt: {
            type: String,
        },
        assetsURL: {
            type: String,
            default: '',
        },
        onlyConfirmationBtn: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        padding: {
            type: String,
        },
    },
    emits: ['on-close', 'on-close-modal', 'on-accept', 'on-decline'],
    methods: {
        onAccept() {
            if (!this.isDisabled) this.$emit('on-accept');
        },
    },
};
</script>

<style lang="scss" scoped>
@import './general-alert.scss';
</style>
