<template>
    <base-icon
        :icon-name="$t('icon.termsAndConditions')"
        :width="width"
        :height="height"
        viewBox="0 0 26 30"
        :iconColor="color"
    >
        <path
            d="M25.8153 5.22322L25.735 4.57295L13.3334 0.0643345L13.1478 0H12.7697L0.192391 4.57291L0.112034 5.22317C0.089553 5.40206 -1.96219 23.2219 12.5106 29.9015L12.7237 30H13.2015L13.4158 29.9015C27.8813 23.2225 25.8382 5.40194 25.8155 5.22317L25.8153 5.22322ZM12.9625 27.7434C1.69848 22.2095 1.9495 9.21627 2.17786 6.13179L12.9631 2.21031L23.749 6.13179C23.976 9.21737 24.2214 22.2099 12.9625 27.7434ZM18.3165 12.8573H17.2472V10.7144C17.2472 7.56976 14.6832 6.42872 12.9615 6.42872V6.99983L12.9594 6.42872C11.2365 6.42872 8.67369 7.56976 8.67369 10.7144V12.8573H7.60227C7.01083 12.8573 6.53084 13.3373 6.53084 13.9287V20.3573C6.53084 20.9497 7.01083 21.4287 7.60227 21.4287H18.3165C18.9089 21.4287 19.3879 20.9497 19.3879 20.3573V13.9287C19.3879 13.3373 18.9089 12.8573 18.3165 12.8573H18.3165ZM10.8166 10.7144C10.8166 8.76002 12.4579 8.58021 12.9647 8.57158C13.328 8.57158 15.1044 8.67872 15.1044 10.7144V12.8573H10.8166V10.7144ZM17.2451 19.2858H8.6737V15.0001H17.2451V19.2858Z"
            fill="#606060"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-terms-and-conditions',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C7C7C7',
        },
    },
    computed: {
        width(): number {
            return 42 * this.size;
        },
        height(): number {
            return 30 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
