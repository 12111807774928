import { GiveProductDto } from './GiveProduct';
import { BaseGoal } from '@/models/cart/CartBaseGoal';

export class CartBarGoals extends BaseGoal {
    pushMessage: string;
    retailerId: number;
    actualAmount: number;
    minimumAmount: number;
    discountTriggerType: string;
    giveProduct: GiveProductDto;
    promotionApplied: boolean;

    constructor(data: CartBarGoalsDto) {
        super({
            discount_id: data?.discount_id ?? data.cashback_id,
            type: data?.type,
            message: data?.message,
            discount_name: data?.discount_name,
        });
        this.retailerId = data?.retailer_id;
        this.pushMessage = data?.push_message;
        this.actualAmount = data?.actual_amount;
        this.minimumAmount = data?.minimum_amount;
        this.giveProduct = data?.give_product;
        this.discountTriggerType = data?.discount_trigger_type;
        this.promotionApplied = data?.promotion_applied ?? false;
    }
}

export interface CartBarGoalsDto {
    discount_id?: number;
    cashback_id?: number;
    discount_name: string;
    message: string;
    push_message: string;
    actual_amount: number;
    retailer_id: number;
    minimum_amount: number;
    type: string;
    discount_trigger_type: string;
    give_product: GiveProductDto;
    promotion_applied: boolean;
}
