<template>
    <base-icon :icon-name="$t('icon.bill')" :width="width" :height="height" viewBox="0 0 49 49" :iconColor="color">
        <path
            transform="translate(5, 5)"
            d="M31.4626 0.289062H5.61106H5.77263C2.86433 0.612207 0.602325 3.03579 0.602325 5.78252V14.9921H10.4582V38.4201C10.4582 39.0664 10.9429 39.7127 11.7508 39.7127C11.9124 39.7127 12.0739 39.7126 12.2355 39.5511L17.8905 37.2891L23.5456 39.5511C23.8687 39.7126 24.1918 39.7126 24.515 39.5511L30.17 37.2891L35.825 39.5511C35.9866 39.5511 36.1482 39.7127 36.3097 39.7127C36.956 39.7127 37.6023 39.2279 37.6023 38.4201V6.26722C37.6023 3.03578 34.8556 0.289062 31.4626 0.289062ZM10.4582 7.72138V12.7301H3.02591V5.94409C3.02591 4.32836 4.31848 2.87421 6.09578 2.87421H7.38835C9.00407 2.87421 10.4582 4.16679 10.4582 5.94409V7.72138ZM31.4626 29.8568H21.6067C20.9604 29.8568 20.3141 29.2105 20.4757 28.5642C20.4757 27.9179 20.9604 27.4332 21.6067 27.4332H31.4626C32.1089 27.4332 32.7552 27.9179 32.7552 28.5642C32.7552 29.2105 32.2704 29.8568 31.4626 29.8568ZM31.4626 25.0096H21.6067C20.9604 25.0096 20.3141 24.3633 20.4757 23.717C20.4757 23.0707 20.9604 22.586 21.6067 22.586H31.4626C32.1089 22.586 32.7552 23.0707 32.7552 23.717C32.7552 24.3633 32.2704 25.0096 31.4626 25.0096ZM31.4626 20.0009H16.598C15.9517 20.0009 15.3054 19.5161 15.3054 18.8699C15.3054 18.2236 15.7901 17.5773 16.4364 17.5773C16.4364 17.5773 16.4364 17.5773 16.598 17.5773H31.4626C32.1089 17.5773 32.7552 18.062 32.7552 18.7083C32.7552 19.5161 32.2704 20.0009 31.4626 20.0009ZM31.4626 10.145H16.598C15.9517 10.145 15.3054 9.66024 15.3054 9.01396C15.3054 8.36767 15.7901 7.72138 16.4364 7.72138C16.4364 7.72138 16.4364 7.72138 16.598 7.72138H31.4626C32.1089 7.72138 32.7552 8.2061 32.7552 8.85239C32.7552 9.49868 32.2704 10.145 31.4626 10.145Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-bill',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 40 * this.size;
        },
        height(): number {
            return 40 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
