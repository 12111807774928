import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23d9ae27"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "recipe-label" }
const _hoisted_3 = { class: "recipe-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_icon_cart = _resolveComponent("icon-cart")!
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_scroller_item = _resolveComponent("scroller-item")!
  const _component_container_scroller = _resolveComponent("container-scroller")!

  return (_openBlock(), _createBlock(_component_base_card, {
    class: "flex-column",
    padding: "0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_section_header, {
        hasBack: true,
        title: $options.recipeGroup,
        onOnBack: $options.backList
      }, null, 8, ["title", "onOnBack"]),
      (_ctx.recipeAds)
        ? (_openBlock(), _createBlock(_component_container_scroller, {
            key: 0,
            class: "recipes",
            isScroller: $data.isScroller,
            onOnObserver: $options.onObserver,
            onOnObserverChange: $options.onObserverChange
          }, {
            content: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recipeAds, (subgroup) => {
                return (_openBlock(), _createBlock(_component_scroller_item, {
                  key: subgroup.id,
                  id: subgroup.id,
                  name: subgroup.name,
                  observer: $data.refObserver
                }, {
                  content: _withCtx(() => [
                    _createVNode(_component_base_card, {
                      class: "flex-column cursor-pointer",
                      padding: "0",
                      onClick: ($event: any) => ($data.isLoading ? null : $options.getRecipeDetail(subgroup))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          class: "recipe__img",
                          src: subgroup.picture,
                          alt: "",
                          loading: "lazy"
                        }, null, 8, _hoisted_1),
                        _createElementVNode("aside", _hoisted_2, [
                          _createElementVNode("h3", null, _toDisplayString(subgroup.name), 1),
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_icon_cart, {
                              size: 0.6,
                              color: "#707070"
                            }),
                            _createElementVNode("p", null, _toDisplayString(subgroup.productsNumber) + " " + _toDisplayString(_ctx.$t('general.products-general')), 1)
                          ]),
                          ($data.isLoading && $data.getSubgrubId === subgroup.id)
                            ? (_openBlock(), _createBlock(_component_base_loading, {
                                key: 0,
                                isMarginAuto: false,
                                heigth: ".5rem",
                                width: ".5rem"
                              }))
                            : (_openBlock(), _createBlock(_component_icon_arrow, {
                                key: 1,
                                iconName: _ctx.$t('icon.forward'),
                                size: 0.75
                              }, null, 8, ["iconName"]))
                        ])
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["id", "name", "observer"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["isScroller", "onOnObserver", "onOnObserverChange"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}