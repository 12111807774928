<script lang="ts" setup>
import TransitionFade from '@/modules/core/components/transition/TransitionVerticalFade.vue';
import { useImage } from '@/composables/ImageComposable';
import { useImage as vImage } from '@vueuse/core';
import BaseBone from '@/components/skeletons/BaseBone.vue';

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    altText: {
        type: String,
        default: '',
    },
    delay: {
        type: Number,
        default: 250,
    },
    boneLoader: {
        type: Boolean,
        default: false,
    },
});

const { displayDefaultImg } = useImage();

const { isLoading, error } = vImage({ src: props.src });
</script>

<template>
    <transition-fade :time="delay">
        <slot v-if="isLoading" name="loader">
            <BaseBone v-if="boneLoader" v-bind="$attrs" />
            <img v-else :alt="altText" src="@/assets/svg/tipti_logo/ic_tipti_orange.svg" v-bind="$attrs" />
        </slot>
        <slot v-else-if="!!error" name="error">
            <img :alt="altText" src="@/assets/svg/tipti_logo/ic_tipti_orange.svg" v-bind="$attrs" />
        </slot>
        <img v-else :alt="altText" :src="src" v-bind="$attrs" @error="displayDefaultImg" />
    </transition-fade>
</template>
