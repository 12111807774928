<template>
    <section class="icons-section">
        <div class="icons">
            <btn-circular id="chains" ref="chains" class="btn-icons" @click="copyCode">
                <icon-chains :size="0.7" />
            </btn-circular>
            <btn-circular class="btn-icons" @click="share(twitter)">
                <icon-twitter-two :size="0.7" />
            </btn-circular>
            <btn-circular class="btn-icons" @click="share(whatsapp)">
                <icon-whats-app-two :size="0.7" />
            </btn-circular>
            <btn-circular class="btn-icons" @click="share(facebook)">
                <icon-FB :size="0.7" />
            </btn-circular>
            <btn-circular class="btn-icons" @click="shareMail(mail)">
                <icon-gmail :size="0.8" />
            </btn-circular>
        </div>
    </section>
    <snack-bar
        :body="bodySnackBar"
        :is-active="showSnackBar"
        :is-success="true"
        :no-background="true"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<script lang="ts">
import { ShareActions } from '@/enums/shareActions';

import BtnCircular from '@/components/buttons/BtnCircular.vue';
import IconWhatsAppTwo from '@/components/icons/IconWhatsAppTwo.vue';
import IconTwitterTwo from '@/components/icons/IconTwitterTwo.vue';
import IconFB from '@/components/icons/IconFB.vue';
import IconChains from '@/components/icons/IconChains.vue';
import IconGmail from '@/components/icons/IconGmail.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';

export default {
    name: 'ShareButtons',
    components: {
        BtnCircular,
        IconWhatsAppTwo,
        IconTwitterTwo,
        IconFB,
        IconChains,
        IconGmail,
        SnackBar,
    },
    props: {
        link: {
            type: String,
            required: true,
        },
        messageShare: {
            type: String,
            required: true,
        },
        shareLink: {
            type: Boolean,
            default: true,
        },
        mailSubject: {
            type: String,
            default: 'TIPTI',
        },
        snackBarMessage: {
            type: String,
            default: '',
        },
    },
    emits: ['on-track-event'],
    data() {
        return {
            showSnackBar: false,
            bodySnackBar: '',
        };
    },
    computed: {
        shareableLink(): string {
            if (this.shareLink) return this.link;
            return this.messageShare.concat(` ${this.link}`);
        },
        facebook(): string {
            this.segmentTrack(ShareActions.FACEBOOK);
            const redirectLink = this.shareLink ? this.link : 'tipti.market';
            return `https://www.facebook.com/dialog/share?app_id=321198281554109&display=popup&href=${redirectLink}&redirect_uri=https://www.facebook.com/&quote=${this.messageShare} ${this.link}`;
        },
        twitter(): string {
            this.segmentTrack(ShareActions.TWITTER);
            return `https://twitter.com/intent/tweet/?url=${this.link}&text=${this.messageShare}`;
        },
        whatsapp(): string {
            this.segmentTrack(ShareActions.WHATSAPP);
            return `https://web.whatsapp.com/send?text=${this.messageShare} ${this.link}`;
        },
        mail(): string {
            this.segmentTrack(ShareActions.MAIL);
            return `mailto:?&subject=${this.mailSubject}&body=${this.messageShare} ${this.link}`;
        },
    },
    methods: {
        share(url) {
            window.open(url, 'sharer', 'toolbar=0,status=0,width=650,height=500');
            return true;
        },
        shareMail(url): void {
            window.location.href = url;
        },
        copyCode(): void {
            navigator.clipboard.writeText(this.shareableLink);
            this.segmentTrack(ShareActions.COPY_LINK);
            if (!this.snackBarMessage.length) return;
            this.bodySnackBar = this.snackBarMessage;
            this.showSnackBar = true;
        },
        segmentTrack(shareAction) {
            this.$emit('on-track-event', shareAction);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.icons-section {
    display: flex;
    flex-direction: column;
}
.icons {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: $margin-md auto;
}
.btn-icons {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    margin-left: $margin-xsm;
    margin-right: $margin-xsm;
}
.icons__input--display {
    width: 0.1rem;
    z-index: -1;
}
</style>
