import { StoriesResultModel } from '@/models/stories/StoriesResultModel';

export default {
    stories(state, stories: Array<StoriesResultModel>) {
        state.stories = stories;
    },
    featuredStory(state, featuredStory: StoriesResultModel) {
        state.featuredStory = featuredStory;
    },
    selectedIndex(state, index: number) {
        state.selectedIndex = index;
    },
    selectedStory(state, story: StoriesResultModel) {
        state.selectedStory = story;
    },
    informativeStory(state, informativeStory: StoriesResultModel) {
        state.informativeStory = informativeStory;
    },
};
