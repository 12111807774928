import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    countries: undefined,
    countrySelected: undefined,
    countrySelectedName: '',
    countryCodeSelected: undefined,
    countryCodes: [],
};
export default {
    namespaced: true,
    getters,
    mutations,
    actions,
    state,
};
