<template>
    <base-icon :height="height" :icon-color="color" :width="width" icon-name="icon-verified-badge" viewBox="0 0 17 16">
        <path
            :fill="color"
            clip-rule="evenodd"
            d="M9.18565 0.981346C8.66019 0.33955 7.67868 0.339552 7.15328 0.981346L6.50196 1.77686L5.54015 1.41357C4.7642 1.12049 3.91423 1.61122 3.78007 2.42976L3.61378 3.44435L2.5992 3.61063C1.78066 3.74479 1.28992 4.59476 1.58301 5.37072L1.94629 6.33251L1.15078 6.98381C0.508983 7.50927 0.508985 8.49072 1.15078 9.01617L1.94629 9.66751L1.58301 10.6293C1.28993 11.4052 1.78065 12.2552 2.59919 12.3894L3.61378 12.5556L3.78007 13.5702C3.91423 14.3888 4.76419 14.8795 5.54015 14.5864L6.50196 14.2232L7.15328 15.0186C7.67868 15.6605 8.66019 15.6605 9.18565 15.0186L9.83693 14.2232L10.7987 14.5864C11.5747 14.8795 12.4246 14.3888 12.5588 13.5702L12.7251 12.5556L13.7397 12.3894C14.5582 12.2552 15.049 11.4052 14.7559 10.6293L14.3926 9.66751L15.1881 9.01617C15.8299 8.49072 15.8299 7.50927 15.1881 6.98381L14.3926 6.33251L14.7559 5.37072C15.049 4.59476 14.5582 3.74479 13.7397 3.61063L12.7251 3.44435L12.5588 2.42976C12.4246 1.61122 11.5747 1.12049 10.7987 1.41357L9.83693 1.77686L9.18565 0.981346ZM6.87536 10.6798C6.97018 10.8119 7.20722 10.9 7.39685 10.9C7.63388 10.9 7.82351 10.8119 8.01314 10.6357L11.5213 6.36278C11.7583 6.05442 11.7109 5.65796 11.3791 5.43771C11.0472 5.21745 10.6205 5.2615 10.3835 5.56986L7.39685 9.22611L5.92722 7.64027C5.69018 7.33191 5.21611 7.33191 4.93166 7.55216C4.59981 7.77242 4.59981 8.21293 4.83685 8.47724L6.87536 10.6798Z"
            fill-rule="evenodd"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconVerifiedBadge',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#CFDE00',
        },
    },
    computed: {
        width(): number {
            return 13 * this.size;
        },
        height(): number {
            return 15 * this.size;
        },
    },
};
</script>
