import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    alertNewsSlide: undefined,
    closeNewsAlertInRetailers: false,
    closeNewsAlertInCart: false,
};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state,
};
