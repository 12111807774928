
const __default__ = {
    name: 'BaseBackground',
    props: {
        backgroundColor: {
            type: String,
            default: 'rgba(0, 0, 0, 0.5)',
        },
        center: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        document.body.style.overflowY = 'hidden';
    },
    unmounted() {
        document.body.style.overflowY = 'auto';
    },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "487df7e2": (_ctx.backgroundColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__