import { ProductModel, toProductModel } from '@/models/product/ProductModel';

interface CategoryDto {
    category_name: string;
    category_icon: string;
    category_color: string;
    items: Array<ProductModel>;
}
export class CategoryModel {
    readonly name: string;
    readonly icon: string;
    readonly color: string;
    readonly products: Array<ProductModel>;

    constructor(data: Partial<CategoryDto>) {
        this.name = data?.category_name ?? '';
        this.icon = data?.category_icon ?? '';
        this.color = data?.category_color;
        this.products = data?.items?.map(product => {
            if (product instanceof ProductModel) return product;
            const prod = toProductModel(product);
            prod.stockItemId = product['stockitem']['id'];
            return prod;
        });
    }
}
