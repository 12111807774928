<template>
    <base-icon :icon-name="$t('icon.lists')" :width="width" :height="height" viewBox="0 0 20 25" :iconColor="color">
        <path
            d="M13.6366 1.96277H11.923C11.4945 0.720301 10.1236 0.0576493 8.8384 0.554638C8.15295 0.720301 7.63885 1.30012 7.38181 1.96277H5.66816C5.23975 1.96277 4.89702 2.2941 4.89702 2.70826V5.77302C4.89702 6.18718 5.23975 6.51851 5.66816 6.51851H13.6366C14.065 6.51851 14.4077 6.18718 14.4077 5.77302V2.70826C14.4077 2.2941 14.065 1.96277 13.6366 1.96277Z"
        />
        <path
            d="M17.6505 2.42756H14.9944V4.74683C14.9944 5.9893 14.9944 7.06611 13.7092 7.06611H5.65503C4.36979 7.06611 4.36979 5.9893 4.36979 4.74683V2.42756H1.62796C0.77114 2.42756 0 3.09021 0 4.00135V22.6384C0 23.4667 0.685458 24.2122 1.54228 24.2122H17.5649C18.4217 24.2122 19.1928 23.5495 19.1928 22.7212V4.00135C19.2785 3.17304 18.593 2.42756 17.6505 2.42756ZM9.42505 16.1776L6.2548 19.2423C5.91207 19.5736 5.39798 19.5736 5.14093 19.2423L3.51297 17.6685C3.17024 17.3372 3.25592 16.8402 3.51297 16.5917C3.8557 16.3432 4.28411 16.3432 4.62684 16.5917L5.65503 17.5857L8.31118 15.0179C8.6539 14.6866 9.168 14.7694 9.42505 15.1008C9.68209 15.4321 9.68209 15.8462 9.42505 16.1776ZM9.42505 9.96521L6.2548 13.03C5.91207 13.3613 5.39798 13.3613 5.14093 13.03L3.42729 11.539C3.08456 11.2077 3.08456 10.7935 3.42729 10.4622C3.77002 10.1309 4.19843 10.1309 4.54116 10.4622L5.56934 11.4562L8.22549 8.8884C8.56822 8.55708 9.08232 8.63991 9.33936 8.97123C9.68209 9.21973 9.68209 9.63388 9.42505 9.96521ZM15.2514 17.917H12.0812C11.6528 17.917 11.3101 17.5857 11.3101 17.1715C11.3101 16.7574 11.6528 16.4261 12.0812 16.4261H15.2514C15.6798 16.4261 16.0226 16.7574 16.0226 17.1715C16.0226 17.5857 15.6798 17.917 15.2514 17.917ZM15.2514 11.7047H12.0812C11.6528 11.7047 11.3101 11.3733 11.3101 10.9592C11.3101 10.545 11.6528 10.2137 12.0812 10.2137H15.2514C15.6798 10.2137 16.0226 10.545 16.0226 10.9592C16.0226 11.3733 15.6798 11.7047 15.2514 11.7047Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-my-lists',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#707070',
        },
    },
    computed: {
        width(): number {
            return 20 * this.size;
        },
        height(): number {
            return 25 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
