import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50cc1278"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "surplus-card-header" }
const _hoisted_2 = { class: "surplus-card-body" }
const _hoisted_3 = { class: "surplus-card-body__data" }
const _hoisted_4 = { class: "surplus-card-price" }
const _hoisted_5 = { class: "surplus-card-fotter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_warning = _resolveComponent("icon-warning")!
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.pendingPayment)
      ? (_openBlock(), _createBlock(_component_base_card, {
          key: 0,
          class: "surplus-card",
          trailColor: "#FF9012"
        }, {
          default: _withCtx(() => [
            _createElementVNode("header", _hoisted_1, [
              _createVNode(_component_icon_warning, { size: "0.8" }),
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t("txt['notifications__have-pending-payments']")), 1)
            ]),
            _createElementVNode("aside", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t("general['order']")) + "# " + _toDisplayString(_ctx.pendingPayment?.order), 1),
                _createElementVNode("p", null, _toDisplayString($options.formatDate(_ctx.pendingPayment.creationDate)), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h3", null, "+" + _toDisplayString($options.currency) + " " + _toDisplayString(_ctx.pendingPayment?.amountReceivable?.toFixed(2)), 1),
                _createElementVNode("p", null, _toDisplayString($options.paymentMethod), 1)
              ])
            ]),
            _createElementVNode("footer", _hoisted_5, [
              _createVNode(_component_btn_out_line, {
                txt: _ctx.$t('cta.reject'),
                isLoading: $data.isLoadingReject,
                bgColor: "transparent",
                color: "#FF9012",
                isDisabled: $data.isLoadingReject || $data.isLoadingAccept,
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.isLoadingReject || $data.isLoadingAccept ? null : $options.setPayment('REJECTED_BY_CLIENT')))
              }, null, 8, ["txt", "isLoading", "isDisabled"]),
              _createVNode(_component_btn_solid, {
                txt: _ctx.$t('cta.accept'),
                isLoading: $data.isLoadingAccept,
                isDisabled: $data.isLoadingReject || $data.isLoadingAccept,
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($data.isLoadingAccept || $data.isLoadingReject ? null : $options.setPayment('APPROVED')))
              }, null, 8, ["txt", "isLoading", "isDisabled"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_snack_bar, {
      onOnSnackbarClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showSnackBar = false)),
      isSuccess: $data.isSuccess,
      isFailure: !$data.isSuccess,
      body: $data.snackBarMessage,
      isActive: $data.showSnackBar
    }, null, 8, ["isSuccess", "isFailure", "body", "isActive"])
  ], 64))
}