import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e45d402"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "card-recipe-ads-content" }
const _hoisted_3 = { class: "card-recipe-ads-content--button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-selected-card'))),
    class: "card-recipe-ads",
    padding: "0"
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.displayDefaultImg && $options.displayDefaultImg(...args))),
        loading: "lazy",
        src: $props.recipe?.picture,
        alt: "",
        width: "15",
        height: "15"
      }, null, 40, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", null, _toDisplayString($props.recipe?.name), 1),
        _createElementVNode("footer", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('general.see-recipe')), 1),
          _createVNode(_component_icon_arrow, {
            style: {"padding-left":"0.5rem"},
            size: 0.5,
            color: "#fff",
            iconName: _ctx.$t('icon.forward')
          }, null, 8, ["iconName"])
        ])
      ]),
      ($props.isLoading && $props.cardSelected === $props.recipe.id)
        ? (_openBlock(), _createBlock(_component_base_loading, {
            key: 0,
            class: "card-recipe-ads-loading"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}