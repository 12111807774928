<template>
    <button
        class="the-btn-information"
        @focus="handleViewInformation"
        @focusout="outside"
        @click.stop="isMobile ? handleViewInformation() : null"
    >
        <icon-info class="hours-saved__icon" :size="0.8" color="#707070" />
    </button>
    <div
        v-if="showMoreInformation"
        class="the-btn-information__content"
        v-bind="$attrs"
        @click.stop="handleViewInformation"
    >
        <slot />
    </div>
</template>

<script lang="ts" setup>
import IconInfo from '@/components/icons/IconInfo.vue';
import { ref } from 'vue';
import { useApp } from '@/composables/useApp';

defineOptions({
    inheritAttrs: false,
});

const { isMobile } = useApp();

const showMoreInformation = ref(false);
const $emit = defineEmits<{
    (e: 'on-view'): void;
    (e: 'on-hide');
}>();
const outside = (): void => {
    if (!showMoreInformation.value) return;
    showMoreInformation.value = false;
    $emit('on-hide');
};
const handleViewInformation = () => {
    showMoreInformation.value = !showMoreInformation.value;
    showMoreInformation.value ? $emit('on-view') : $emit('on-hide');
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-btn-information {
    all: unset;
    position: relative;
    display: inline-block;
    @include tablet {
        padding: $padding-xsm $padding-md;
    }
    @include phone {
        padding: $padding-xsm $padding-sm;
    }

    &__content {
        @include box-shadow;
        @include body2;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border-radius: $radius-x-lg;
        padding: $padding-md;
        z-index: 1;
        bottom: 110%;
        @include tablet {
            background-color: $black;
        }
    }
}

.the-btn-information__content__mobile {
    @include phone {
        right: 50%;
    }
}
</style>
