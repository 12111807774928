<template>
    <base-card @click="$emit('on-selected-card')" class="card-recipe-ads" padding="0">
        <img @error="displayDefaultImg" loading="lazy" :src="recipe?.picture" alt="" width="15" height="15" />
        <div class="card-recipe-ads-content">
            <h3>{{ recipe?.name }}</h3>
            <footer class="card-recipe-ads-content--button">
                <p>{{ $t('general.see-recipe') }}</p>
                <icon-arrow style="padding-left: 0.5rem;" :size="0.5" color="#fff" :iconName="$t('icon.forward')" />
            </footer>
        </div>
        <base-loading class="card-recipe-ads-loading" v-if="isLoading && cardSelected === recipe.id"></base-loading>
    </base-card>
</template>

<script lang="ts">
import { AdModel } from '@/models/ads/AdModel';
import BaseCard from '../BaseCard.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
export default {
    components: { BaseCard, IconArrow, BaseLoading },
    name: 'card-recipe-ads',
    props: {
        recipe: {
            type: AdModel,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        cardSelected: {
            type: Number,
        },
    },
    emits: ['on-selected-card'],
    methods: {
        displayDefaultImg(event) {
            event.target.src = require('@/assets/svg/tipti_logo/ic_tipti_orange.svg');
        },
    },
};
</script>

<style lang="scss" scoped>
@import './card-recipe-ads.scss';
</style>
