import { ProductModel, toProductModel } from '@/models/product/ProductModel';
import { RemoteLocaleDto, RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';
import { AttributeInProductModel } from '@/models/product/AttributeInProduct';

export interface ProductWithAttributeInterface {
    item?: ProductModel;
    attribute: Array<ProductWithAttributeInterface>;
    id: number;
    name: RemoteLocaleDto;
    type_of_attribute_name?: RemoteLocaleDto;
    image: string;
    use_image: boolean;
    stock?: object;
}

export class ProductWithAttributeModel implements ProductWithAttributeInterface {
    item: ProductModel;
    readonly attribute: Array<ProductWithAttributeModel>;
    readonly id: number;
    readonly name: RemoteLocaleModel;
    readonly image: string;
    readonly use_image: boolean;

    constructor(
        data: ProductWithAttributeInterface,
        isFromItemSelected: boolean,
        previousAttr?: AttributeInProductModel,
    ) {
        this.id = data.id;
        this.name = RemoteLocaleModel.fromJson(data.name);
        this.image = data.image;
        this.use_image = data.use_image;

        const currentAttributeProductModel: AttributeInProductModel = {
            id: data.id,
            name: data.name,
            use_image: data.use_image,
            show_in_app: false,
            type_of_attribute_name: data.name,
        };
        if (data.stock && Object.keys(data.stock).length) this.item = toProductModel(data.stock);

        if (!isFromItemSelected)
            if (this.item && previousAttr) {
                this.item.attributes.push(
                    new AttributeInProductModel({
                        name: data.name,
                        id: data.id,
                        use_image: data.use_image,
                        show_in_app: false,
                        type_of_attribute_name: data.type_of_attribute_name,
                    }),
                );
                if (this.item.attributes.every((attr) => attr.id != previousAttr.id))
                    this.item.attributes.push(new AttributeInProductModel(previousAttr));

                this.item.attributes = [
                    ...new Set(this.item.attributes.filter((att) => [data.id, previousAttr.id].includes(att.id))),
                ];
            }
        this.attribute = !data.attribute?.length
            ? []
            : ProductWithAttributeModel.builderList(data.attribute, isFromItemSelected, currentAttributeProductModel);
    }

    static fromJson(
        data: object,
        isFromItemSelected: boolean,
        previousAttr?: AttributeInProductModel,
    ): ProductWithAttributeModel {
        if (!data || !Object.keys(data).length) return null;
        return new ProductWithAttributeModel(data as ProductWithAttributeInterface, isFromItemSelected, previousAttr);
    }

    static builderList(
        data: object,
        isFromItemSelected = false,
        previousAttr?: AttributeInProductModel,
    ): Array<ProductWithAttributeModel> {
        try {
            if (!data) return null;
            return (data as [])
                .map((on) => this.fromJson(on, isFromItemSelected, previousAttr))
                .filter((attribute) => !!attribute);
        } catch (e) {
            return null;
        }
    }
}
