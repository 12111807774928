import { ProductModel, toProductModel } from '@/models/product/ProductModel';
import { isMobileBrowser } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { CartModel } from '@/models/cart/CartModel';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import InstitutionalPaymentModel from '@/models/checkout/InstitutionalPaymentModel';

export default {
    async xSellingProducts({ rootGetters, commit, dispatch }, orderId: number): Promise<ProductModel[]> {
        try {
            if (!orderId) return;
            const pageSize = isMobileBrowser() ? 9 : 8;
            const url = `v2/product/dont-forget-to-buy?order=${orderId}&size=${pageSize}`;
            const { data } = await rootGetters['network/axios'].get(url);
            return data['results']?.map((product: object) => toProductModel(product)) ?? [];
        } catch (err) {
            logger('GET_X_SELLING_PRODUCTS', err);
            return [];
        }
    },
    async addExtraItems(
        { rootGetters, getters, dispatch, commit },
        payload: { orderId: number; isXSelling?: boolean },
    ): Promise<void> {
        try {
            const url = `v2/client_profile/order/${payload.orderId}/add_extra_items`;
            const paymentsSelected: PaymentToShowModel[] = rootGetters['payments/paymentsToShow'];
            const payment = {
                payment_method_id: paymentsSelected?.find((currentPayment) => currentPayment.name === 'payment-cards')
                    ?.id,
                cash_amount: paymentsSelected?.find((currentPayment) => currentPayment.name === 'payment-cash')?.amount,
                tipti_card_amount: rootGetters['payments/isTiptiCardPayment'] ? getters['totalCostExtraItems'] : null,
                institution_id: (
                    paymentsSelected?.find((currentPayment) => currentPayment.name === 'payment-institutional')
                        ?.objectData as InstitutionalPaymentModel
                )?.id,
                wire_transfer_data: null,
            };

            const stock_items = [];
            const extraItemsToAnalytic = [];

            getters['products']?.forEach((product: ProductModel) => {
                stock_items.push({
                    note: product.note ?? '',
                    note_image: product.noteToShopperPhoto ? product.noteToShopperPhoto.split(',')[1] : undefined,
                    quantity: product.productQuantity,
                    stock_item_id: product.id,
                    stock_item_replace_id: product.replacement?.id ?? null,
                    stock_item_replace_quantity: product.replacement?.quantity ?? null,
                });
                extraItemsToAnalytic.push({
                    product_id: product.id,
                    ean: product.ean ?? '',
                    category: product.category?.name ?? '',
                    category_id: product.category?.id,
                    name: product.name,
                    price: product.price,
                    price_with_discount: product.priceWithDiscount ?? 0,
                    presentation_quantity: product.unitQuantity ?? '',
                    product_quantity: product.productQuantity ?? 0,
                    subcategory: product.subCategory?.name ?? '',
                    image_url: product.image?.medium ?? '',
                });
            });

            const body = {
                stock_items,
                payment,
            };
            await rootGetters['network/axios'].put(url, body, {
                ensureProductionRequest: true,
            });
            if (payload.isXSelling) {
                const cart: CartModel = getters['cart'];
                dispatch(
                    'segment/xSellingOrderComplete',
                    {
                        retailer: cart.retailers[0].toAnalytics,
                        products: extraItemsToAnalytic,
                        payment_method: payment,
                        total_xselling_cost: cart.cartExtraItemAmount,
                    },
                    { root: true },
                );
            }
            commit('clearProducts');
        } catch (err) {
            throw err.response;
        }
    },
};
