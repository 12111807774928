<template>
    <base-card class="cursor-pointer">
        <base-loading v-if="isLoading"></base-loading>
        <div class="load-more-products" v-else>
            <img
                class="load-more-products__image"
                src="@/assets/svg/ic_see_more.svg"
                :alt="$t('general.see-more-products')"
            />
            <p>{{ $t("cta['see-more-items']") }}</p>
        </div>
    </base-card>
</template>
<script>
import BaseCard from '@/components/cards/BaseCard.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
export default {
    name: 'card-load-more-products',
    components: {
        BaseCard,
        BaseLoading,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.load-more-products {
    background-color: $canvas;
    display: flex;
    flex-direction: column;
    border: 2px dashed $border;
    border-radius: $radius-lg;
    justify-content: space-around;
    &__image {
        max-width: 100%;
        margin: $margin-md;
    }
}
.load-more-products p {
    @include body1;
    text-align: center;
}
</style>
