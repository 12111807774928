<template>
    <aside class="notification-rate__figure">
        <img
            class="notification-rate__figure__img"
            :src="order.shopperWhoBought?.picture ?? order.shopper?.picture"
            alt="Shopper Photo"
            @error="displayDefaultImg"
        />
        <img class="notification-rate__figure__retailer" :src="order.retailer?.logo" alt="Retail photo" />
    </aside>
</template>
<script lang="ts" setup>
import { useImage } from '@/composables/ImageComposable';
import { useOrder } from '@/composables/useOrder';

const { displayDefaultImg } = useImage();

const { orderToRate: order } = useOrder();
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index';

.notification-rate__figure {
    position: relative;

    &__img {
        height: 3.5rem;
        width: 3.5rem;
        border-radius: $radius-circular;
    }

    &__retailer {
        position: absolute;
        bottom: 0;
        right: -1rem;
        width: 2rem;
        height: 2rem;
        border-radius: $radius-circular;
    }
}
</style>
