import PromotionsRouting from '@/router/routing';
import getters from './getters';

const state = {
    routes: {
        ...PromotionsRouting,
    },
};

export default {
    namespaced: true,
    state,
    getters,
};
