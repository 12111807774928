import { PickUpCenter } from '../pickup/PickUpCenter';
import { toZoneModel, ZoneModel } from './ZoneModel';

class AddressModel {
    readonly id: number;
    readonly name: string;
    readonly position: Array<string>;
    isDefaultAddress: boolean;
    readonly addressLine1: string;
    readonly addressLine2: string;
    readonly reference: string;
    readonly city: ZoneModel;
    readonly sector: ZoneModel;
    readonly comment: string;
    isPickUpMode: boolean;
    pickUpCenter: PickUpCenter;

    constructor(
        id: number,
        name: string,
        position: Array<string>,
        isDefaultAddress: boolean,
        addressLine1: string,
        addressLine2: string,
        reference: string,
        isPickUpMode: boolean,
        city?: ZoneModel,
        sector?: ZoneModel,
        comment?: string,
        pickUpCenter?: PickUpCenter,
    ) {
        this.id = id;
        this.name = name;
        this.position = position;
        this.isDefaultAddress = isDefaultAddress;
        this.addressLine1 = addressLine1;
        this.addressLine2 = addressLine2;
        this.reference = reference;
        this.comment = comment;
        this.isPickUpMode = isPickUpMode;
        this.city = city;
        this.sector = sector;
        this.pickUpCenter = pickUpCenter;
    }

    toMap(): object {
        return {
            name: this.name,
            position: this.position,
            default: this.isDefaultAddress,
            address_line1: this.addressLine1,
            address_line2: this.addressLine2,
            reference: this.reference,
            comment: this.comment,
            city_id: this.city.id,
            sector_id: this.sector.id,
        };
    }
}

const toAddressModel = (data: object): AddressModel => {
    return data
        ? new AddressModel(
              data['id'],
              data['name'] ?? '',
              data['position']?.split(','),
              data['default'] ?? false,
              data['address_line1'] ?? '',
              data['address_line2'] ?? '',
              data['reference'] ?? '',
              false,
              toZoneModel(data['city_gps']),
              toZoneModel(data['sector']),
              data['comment'] ?? data['name'] ?? '',
          )
        : null;
};

export { AddressModel, toAddressModel };
