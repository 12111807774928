<template>
    <section class="recommendations-products" :class="{ 'recommendations-products__bg-color': hasBgColor }">
        <CarouselForProducts
            :items="items"
            :section="customCat"
            :show-only-see-more-action="false"
            :track-item-section="customCat"
            background-arrow="#FFF4E7"
            color-arrow="#ff9012"
            state="LOAD-ENDED"
        >
            <template v-if="showHeader" #header>
                <SubheaderSeparator
                    :name="$t('txt.you-have-everything-you-need')"
                    :show-btn="false"
                    has-padding
                    show-btn-close
                    @on-close="emit('on-close')"
                >
                </SubheaderSeparator>
            </template>
        </CarouselForProducts>
    </section>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ProductModel } from '@/models/product/ProductModel';
import CarouselForProducts from '@/components/carousels/CarouselForProducts.vue';
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';

defineProps({
    items: {
        type: Array as PropType<ProductModel[]>,
        required: true,
    },
    showHeader: {
        type: Boolean,
        default: true,
    },
    hasBgColor: {
        type: Boolean,
        default: true,
    },
});
const { t: $t } = useI18n();

const customCat = 'SUGGESTIONS';

const emit = defineEmits<{
    (e: 'on-close'): void;
}>();
</script>

<style lang="scss">
@use '@/assets/scss/index.scss' as style;

.recommendations-products {
    border-radius: 20px;
    padding: style.$padding-sm;
    margin: style.$margin-md;

    &__bg-color {
        background-color: style.$gray;
    }
}
</style>
