<template>
    <section v-if="balance || onlyTotal" class="balance">
        <collapsable
            v-if="balance?.subtotalTaxesTotal"
            :amount="balance?.subtotalTaxesTotal"
            :children="subtotalTaxesChildren"
            :header-text="$t('txt.cart__subtotal')"
        />
        <collapsable v-if="showShippingCost" :amount="valueShoppingCost" :children="shoppingCostChildren">
            <template #header>
                <div class="collapsable-item collapsable-item--prime">
                    <p class="collapsable-text">
                        {{ $t('txt.cart__shipping-cost') }}
                        <span
                            v-if="!profile.isPrime"
                            class="collapsable-text--accent cursor-pointer"
                            @click="becomePrime"
                        >
                            ({{ shippingWant[0] }} <span class="collapsable-text--bold"> {{ shippingWant[1] }} </span>?)
                        </span>
                    </p>
                    <btn-information @on-view="viewInformationServiceCost">
                        <template #default>
                            <p class="collapsable-info">
                                {{ shippingInfo[0] }}
                                <span class="collapsable-info collapsable-info--bold">
                                    {{ shippingInfo[1] }}
                                </span>
                                {{ shippingInfo[2] }}
                            </p>
                        </template>
                    </btn-information>
                </div>
            </template>
        </collapsable>
        <collapsable v-if="showServiceFee" :amount="balance?.retailersPercentageTotal" :children="retailersCommission">
            <template #header>
                <div class="collapsable-item">
                    <p class="collapsable-text">
                        {{ $t('txt.cart__service-fee') }}
                    </p>
                    <btn-information
                        v-if="balance?.retailersPercentageTotal === 0"
                        @on-view="viewInformationServiceFee"
                    >
                        <template #default>
                            <p class="collapsable-info">
                                {{ commissionFeeTextAlert }}
                            </p>
                        </template>
                    </btn-information>
                </div>
            </template>
        </collapsable>
        <collapsable
            v-if="balance?.taxesTotal"
            :amount="amountTotalTaxes"
            :children="taxesChildren"
            :header-text="balance?.taxName"
        />
        <collapsable
            v-if="balance?.totalDiscount"
            :amount="balance?.totalDiscount"
            :children="discountsChildren"
            :header-text="$t('txt.cart__savings-total')"
            bold
            error-header
        />
        <collapsable
            :amount="balance?.orderCost ?? totalCostExtraItems"
            :header-text="$t('general.total')"
            bold
            has-extra-height
        />
    </section>
</template>

<script lang="ts">
import { TotalDetailsModel } from '@/models/cart/TotalDetailsModel';
import Collapsable from '@/components/collapsable/Collapsable.vue';
import BtnInformation from '@/components/buttons/BtnInformation.vue';
import { useUser } from '@/composables/useUser';
import { useTranslate } from '@/composables/UseTranslate';
import { useStockItemSchedule } from '@/composables/useStockItemSchedule';
import { mapGetters } from 'vuex';
import { PropType } from 'vue';
import { useCart } from '@/composables/useCart';
import { RetailerCommissionModel } from '@/models/cart/RetailerCommissionModel';
import { appRoutesMap } from '@/router/appRoutesMap';

type balanceParent = 'CART' | 'CHECKOUT' | 'EXTRA_ITEMS' | 'ORDER_DETAIL' | 'TIPTI_PRIME';

export default {
    name: 'Balance',
    components: {
        BtnInformation,
        Collapsable,
    },
    props: {
        balance: {
            type: Object as PropType<TotalDetailsModel>,
            default: undefined,
        },
        discounts: {
            default: [],
        },
        isPickUp: {
            type: Boolean,
            default: false,
        },
        shippingCost: {
            type: Number,
            default: undefined,
        },
        showShippingCost: {
            type: Boolean,
            default: false,
        },
        originScreen: {
            type: String as PropType<balanceParent>,
            required: true,
        },
        retailerName: {
            type: String,
            default: undefined,
        },
    },
    setup() {
        const { profile, baseConfiguration } = useUser();
        const { translate } = useTranslate();
        const { stockItemTimeDelivery } = useStockItemSchedule();
        const { cart } = useCart();

        return {
            profile,
            translate,
            stockItemTimeDelivery,
            cart,
            baseConfiguration,
        };
    },
    computed: {
        ...mapGetters({
            timeDelivery: 'checkout/timeDelivery',
            productWithSpecialDelivery: 'cart/productWithSpecialDelivery',
            totalCostExtraItems: 'extraItems/totalCostExtraItems',
        }),
        onlyTotal(): boolean {
            return this.originScreen === 'EXTRA_ITEMS';
        },
        commissionFeeTextAlert(): string {
            const serverText = this.translate({ recordable: this.baseConfiguration.alertCommissionFee });
            return serverText.length ? serverText : this.$t('txt.cart__service-fee-description');
        },
        discountsChildren() {
            return this.discounts.map((discount) => {
                return {
                    name: discount,
                };
            });
        },
        retailersCommission() {
            let _allPercentageCommissions: Array<RetailerCommissionModel> = [];
            this.balance?.retailersPercentage?.map((retailer) => {
                if (retailer?.commissions?.length) {
                    _allPercentageCommissions = [..._allPercentageCommissions, ...retailer.commissions];
                }
            });
            return _allPercentageCommissions
                ?.filter((commission: RetailerCommissionModel) => commission.total > 0)
                ?.map((commission: RetailerCommissionModel) => {
                    return {
                        name: `${this.translate({
                            recordable: commission.name,
                        })}`,
                        description: `${this.translate({
                            recordable: commission.description,
                        })}`,
                        type: commission['type'] ?? commission.commissionType,
                        amount: commission?.total,
                    };
                });
        },
        shippingInfo(): Array<string> {
            return this.$t('txt.shipping_cost__info').split('/*');
        },
        shippingWant(): Array<string> {
            return this.$t('txt.shipping_cost__want_free').split('/*');
        },
        subtotalTaxesChildren() {
            return this.balance?.subtotalTaxes?.map((tex) => {
                return {
                    name: `${this.$t('txt.cart__subtotal')} ${tex.name}`,
                    amount: tex.value,
                };
            });
        },
        taxesChildren() {
            return this.balance?.taxes?.map((tex) => {
                if (tex.isServiceTax)
                    return {
                        name: tex.name,
                        amount: tex.value + this.getTax(this.cartValueShoppingCost ?? 0),
                    };
                return {
                    name: tex.name,
                    amount: tex.value,
                };
            });
        },
        cartShippingCostChildren() {
            return this.productWithSpecialDelivery.map((product) => {
                return {
                    name: this.$t('txt.cart__shipping-cost') + ' ' + product.name,
                    amount: product.costOfDelivery,
                };
            });
        },
        orderDetailShippingCostChildren() {
            return [
                {
                    name: this.$t('txt.cart__shipping-cost') + ' ' + this.retailerName,
                    amount: this.shippingCost,
                },
            ];
        },
        shoppingCostChildren() {
            if (!this.showShippingCost || this.onlyTotal) return [];
            if (this.originScreen === 'ORDER_DETAIL') return this.orderDetailShippingCostChildren;
            if (this.originScreen === 'CART') return this.cartShippingCostChildren;
            const productDeliveryCostCost = this.stockItemTimeDelivery?.map((time) => {
                return {
                    name: this.$t('txt.cart__shipping-cost') + ' ' + time.productName,
                    amount: time.shippingCost,
                };
            });
            const retailerDeliveryCost = this.timeDelivery.map((time) => {
                return {
                    name: this.$t('txt.cart__shipping-cost') + ' ' + time.retailerName,
                    amount: time.shippingCost,
                };
            });
            return [...retailerDeliveryCost, ...productDeliveryCostCost].filter((item) => !!item);
        },
        cartValueShoppingCost(): number {
            let finalShippingCost = 0;
            this.stockItemTimeDelivery?.forEach((time) => (finalShippingCost += +time.shippingCost));
            this.timeDelivery?.forEach((time) => (finalShippingCost += +time.shippingCost));
            return finalShippingCost;
        },
        valueShoppingCost() {
            if (this.originScreen !== 'CHECKOUT' && this.originScreen !== 'CART') return this.shippingCost;
            return this.cartValueShoppingCost;
        },
        showServiceFee(): boolean {
            return this.originScreen !== 'EXTRA_ITEMS';
        },
        amountTotalTaxes(): number {
            return this.balance?.taxesTotal + this.getTax(this.cartValueShoppingCost ?? 0);
        },
    },
    methods: {
        becomePrime(): void {
            this.$store.commit('cart/showCart', false);
            this.$router.push({ name: appRoutesMap.account.tiptiPrime });
        },
        viewInformationServiceCost(): void {
            setTimeout(() => {
                this.$store.dispatch('segment/viewInformationServiceCost');
            }, 2000);
        },
        viewInformationServiceFee(): void {
            setTimeout(() => {
                this.$store.dispatch('segment/viewInformationServiceFee');
            }, 2000);
        },
        getTax(price: number): number {
            const tax = this.cart?.totalDetails?.serviceTax ?? 0;
            return price * tax;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.balance {
    flex-direction: column;
    box-sizing: border-box;
    @include tablet {
        margin-bottom: $margin-sm;
        padding-top: $padding-sm;
    }
}

.collapsable-text {
    @include caption;
    line-height: 0;
    margin-right: $margin-xsm;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--accent {
        color: $orange;
    }

    &--bold {
        font-weight: 600;
    }
}

.collapsable-item {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;

    &--prime {
        overflow: auto;
    }
}

.collapsable-info {
    @include overline;
    @include tablet {
        color: $white;
    }
    text-align: center;

    &--bold {
        font-weight: 600;
    }
}
</style>
