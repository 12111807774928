import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6992e78a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "credit-card" }
const _hoisted_4 = { class: "credit-card__title" }
const _hoisted_5 = { class: "credit-card__digits" }
const _hoisted_6 = { class: "credit-card__data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", {
    ref: "CardFooter",
    class: _normalizeClass(["credit-card-footer", {
            'credit-card-footer--no-icons': $options.noIcons,
            'credit-card-footer--one-icon': $options.oneIcon,
        }])
  }, [
    ($props.creditCard?.creditCardBrandData?.franchiseLogo)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "credit-card-footer__img",
          alt: $props.creditCard.data?.cardName,
          src: $props.creditCard?.creditCardBrandData?.franchiseLogo
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h2", _hoisted_4, _toDisplayString($props.creditCard.data?.cardName), 1),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(16, (index) => {
          return _createElementVNode("div", {
            key: index,
            class: _normalizeClass(["credit-card__digits-point", $options.spaceDots(index)])
          }, null, 2)
        }), 64)),
        _createElementVNode("p", _hoisted_6, _toDisplayString($props.creditCard.data.cardDigits.slice(1, 5)), 1)
      ])
    ]),
    ($options.iconComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.iconComponent.type), _normalizeProps(_mergeProps({ key: 2 }, $options.iconComponent)), null, 16))
      : _createCommentVNode("", true)
  ], 2))
}