import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-536fce8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-amount" }
const _hoisted_2 = { class: "input-amount__txt" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "input-amount__txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString($options.currency), 1),
    _createElementVNode("input", {
      dir: "rtl",
      value: $data.moneyValue,
      type: "text",
      placeholder: "00,00",
      onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.valueMoney && $options.valueMoney(...args)))
    }, null, 40, _hoisted_3),
    _createElementVNode("p", _hoisted_4, _toDisplayString($options.currencyName), 1)
  ]))
}