<template>
    <BackgroundWithTransition
        :class="{ 'full-mobile': pdfData }"
        :is-active="showAlert"
        content-centered
        @on-bg-click="closeAlert"
    >
        <TransitionFade>
            <template v-if="pdfData">
                <TheModalContent class="fit">
                    <template #header>
                        <BaseHeader
                            :size-icon="0.6"
                            :text="$t(`general['terms-and-conditions']`)"
                            text-color="orange"
                            with-default-close
                            @on-close="pdfData = null"
                        />
                    </template>
                    <template #default>
                        <div class="affiliate__terms-and-conditions__view" v-html="pdfData"></div>
                    </template>
                </TheModalContent>
            </template>
            <template v-else>
                <TheModalContent v-if="userAffiliateAlert" class="fit affiliation-dialog__content">
                    <template #header>
                        <BaseHeader :size-icon="0.6" with-default-close @on-close="closeAlert" />
                    </template>
                    <template #default>
                        <div class="affiliation-benefit__main-content">
                            <img :alt="exec(userAffiliateAlert.title)" :src="userAffiliateAlert.image" />
                            <h3 class="affiliation-benefit__title">{{ exec(userAffiliateAlert.title) }}</h3>
                            <BannerPromotion
                                v-if="bannerUserAffiliateAlert"
                                class="affiliation-benefit__banner"
                                :banner="bannerUserAffiliateAlert"
                                is-banner-in-section-prop
                            />
                            <template v-if="!bannerUserAffiliateAlert">
                                <p
                                    v-translate="userAffiliateAlert.description"
                                    class="affiliation-benefit__text-description"
                                />
                                <aside class="affiliation-benefit__list">
                                    <p
                                        v-for="benefit in userAffiliateAlert.benefit"
                                        :key="benefit.ES"
                                        v-translate="benefit"
                                        class="affiliation-benefit__item"
                                    />
                                </aside>
                            </template>
                        </div>
                    </template>
                    <template #footer>
                        <section class="affiliation-benefit__content-button">
                            <btn-solid
                                :txt="exec(userAffiliateAlert.button)"
                                padding=".5rem  5rem"
                                @click="subscribe"
                            />
                        </section>
                    </template>
                </TheModalContent>
                <TheModalContent v-if="renewAlert" class="fit affiliation-dialog__content">
                    <template #header>
                        <BaseHeader :size-icon="0.6" with-default-close @on-close="closeAlert" />
                    </template>
                    <template #default>
                        <div class="affiliation-benefit">
                            <img v-if="renewAlert?.image" :alt="exec(renewAlert.title)" :src="renewAlert.image" />
                            <h3 class="affiliation-benefit__title">{{ exec(renewAlert.title) }}</h3>
                            <p v-translate="renewAlert.description" class="affiliation-benefit__description" />
                            <section class="affiliation-user__list-tyc">
                                <div v-if="documentTcu?.contentDocument" class="affiliation-user__tyc">
                                    <aside
                                        aria-labelledby="document-tcu"
                                        @click="pdfData = documentTcu.contentDocument"
                                        v-html="documentTcu.string_document"
                                    ></aside>
                                    <BaseCheck v-model="tcu" />
                                </div>
                                <div v-if="documentCva?.contentDocument" class="affiliation-user__tyc">
                                    <aside
                                        aria-labelledby="document-cva"
                                        @click="pdfData = documentCva.contentDocument"
                                        v-html="documentCva.string_document"
                                    ></aside>
                                    <BaseCheck v-model="cva" />
                                </div>
                                <div v-if="documentPpr?.contentDocument" class="affiliation-user__tyc">
                                    <aside
                                        aria-labelledby="document-ppr"
                                        @click="pdfData = documentPpr.contentDocument"
                                        v-html="documentPpr.string_document"
                                    ></aside>
                                    <BaseCheck v-model="ppr" />
                                </div>
                            </section>
                        </div>
                    </template>
                    <template #footer>
                        <section class="affiliation-benefit__content-button">
                            <BtnSolid
                                :is-disabled="!canSubscribe"
                                :is-loading="subscribing === 'LOADING'"
                                :txt="exec(renewAlert.button)"
                                padding=".5rem  5rem"
                                @click="subscribe"
                            />
                        </section>
                    </template>
                </TheModalContent>
                <TheModalContent v-if="outputExternalAlert" class="fit affiliation-dialog__content">
                    <template #header>
                        <base-header
                            :size-icon="0.8"
                            :text="exec(outputExternalAlert.title)"
                            with-default-close
                            @on-close="closeAlert"
                        />
                    </template>
                    <template #default>
                        <base-column class="min-gap">
                            <div class="affiliate__terms-and-conditions__view">
                                <iframe
                                    :src="outputExternalAlert.tc_url"
                                    :title="exec(outputExternalAlert.title)"
                                    height="100%"
                                    sandbox="allow-same-origin allow-scripts"
                                    width="100%"
                                />
                            </div>
                        </base-column>
                    </template>
                </TheModalContent>
                <TheModalContent v-if="userRegisteredAlert" class="fit affiliation-benefit__register">
                    <template #header>
                        <BaseHeader :size-icon="0.6" with-default-close @on-close="closeAlert" />
                    </template>
                    <template #default>
                        <div class="affiliation-benefit">
                            <section class="affiliation-benefit__content_title-img">
                                <img
                                    v-if="userRegisteredAlert?.image"
                                    :alt="exec(userRegisteredAlert.title)"
                                    :src="userRegisteredAlert.image"
                                />
                                <h3 class="affiliation-benefit__title">{{ exec(userRegisteredAlert.title) }}</h3>
                            </section>
                            <p v-translate="userRegisteredAlert.description" class="affiliation-benefit__description" />
                            <section class="affiliation-user__list-tyc">
                                <div v-if="documentTcu?.contentDocument" class="affiliation-user__tyc">
                                    <aside
                                        aria-labelledby="document-tcu"
                                        @click="pdfData = documentTcu.contentDocument"
                                        v-html="documentTcu.string_document"
                                    ></aside>
                                    <BaseCheck v-model="tcu" />
                                </div>
                                <div v-if="documentCva?.contentDocument" class="affiliation-user__tyc">
                                    <aside
                                        aria-labelledby="document-cva"
                                        @click="pdfData = documentCva.contentDocument"
                                        v-html="documentCva.string_document"
                                    ></aside>
                                    <BaseCheck v-model="cva" />
                                </div>
                                <div v-if="documentPpr?.contentDocument" class="affiliation-user__tyc">
                                    <aside
                                        aria-labelledby="document-ppr"
                                        @click="pdfData = documentPpr.contentDocument"
                                        v-html="documentPpr.string_document"
                                    ></aside>
                                    <BaseCheck v-model="ppr" />
                                </div>
                            </section>
                        </div>
                    </template>
                    <template #footer>
                        <section class="affiliation-benefit__content-button">
                            <BtnSolid
                                :is-disabled="!canSubscribe"
                                :is-loading="subscribing === 'LOADING'"
                                :txt="exec(userRegisteredAlert.button)"
                                padding=".5rem  5rem"
                                @click="subscribe"
                            />
                        </section>
                    </template>
                </TheModalContent>
            </template>
        </TransitionFade>
    </BackgroundWithTransition>
</template>

<script lang="ts" setup>
import { computed, ref, watch, watchEffect } from 'vue';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';
import TheModalContent from '@/modules/core/components/layouts/TheModalContent.vue';
import BaseHeader from '@/modules/core/components/headers/HeaderWithAction.vue';
import BaseColumn from '@/modules/core/components/layouts/BaseColumn.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { useAffiliationAlert } from '@/modules/affiliation/composables/useAffiliationAlert';
import { useTranslate } from '@/composables/UseTranslate';
import TransitionFade from '@/modules/core/components/transition/TransitionVerticalFade.vue';
import { CF_AFFILIATION_ACTION, CF_AFFILIATION_CHANEL, CF_DOMAIN } from '@/modules/core/constants';
import BannerPromotion from '@/components/banners/thePromotionsBanner/BannerPromotion.vue';
import BaseCheck from '@/components/checks/BaseCheck.vue';

const {
    showAlert,
    userAffiliateAlert,
    subscribe,
    closeAlert,
    renewAlert,
    userRegisteredAlert,
    outputExternalAlert,
    subscribing,
    isCheckEnded,
    bannerUserAffiliateAlert,
    init,
    documentTcu,
    documentCva,
    documentPpr,
    affiliationDocuments,
} = useAffiliationAlert();

const { exec } = useTranslate();

const emits = defineEmits(['on-finish']);

init();

watchEffect(() => {
    if (!isCheckEnded.value) return;
    if (showAlert.value) return;
    if (subscribing.value !== 'NONE') return;
    emits('on-finish');
});

const pdfData = ref<string>(null);

const cva = ref<boolean>(false);
const ppr = ref<boolean>(false);
const tcu = ref<boolean>(false);

const canSubscribe = computed<boolean>(() => {
    if (!affiliationDocuments.value?.length) return false;
    const hasCheckCva = !documentCva.value?.contentDocument || cva.value;
    const hasCheckPpr = !documentPpr.value?.contentDocument || ppr.value;
    const hasCheckTcu = !documentTcu.value?.contentDocument || tcu.value;
    return hasCheckCva && hasCheckPpr && hasCheckTcu;
});

const messageHandler = (e) => {
    if (!e || e.origin !== CF_DOMAIN) return;
    const { data } = e;
    if (!data || !data.length) return;
    const { chanel, action } = JSON.parse(data);
    if (chanel !== CF_AFFILIATION_CHANEL || action !== CF_AFFILIATION_ACTION) return;
    subscribing.value = 'NONE';
    outputExternalAlert.value = null;
    showAlert.value = false;
};

watch(outputExternalAlert, (newValue) => {
    if (!newValue) return window.removeEventListener('message', messageHandler);
    window.addEventListener('message', messageHandler);
});
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.affiliation-dialog__benefit {
    @include caption;
    text-align: center;
}

.affiliation-dialog__content {
    max-width: 26rem;
}

.rejected-modal__img {
    max-width: 30rem;
    max-height: 20rem;
    object-fit: contain;
    margin: 2rem;
}

.rejected-modal__text {
    @include body2;
    margin: 2rem 0;
}

.affiliation-benefit {
    display: flex;
    flex-direction: column;
    padding: $padding-none $padding-md $padding-md;
    align-items: center;
    justify-content: center;
    gap: $margin-md;

    &__description {
        @include caption;
        font-weight: 600;
        font-size: 12px;
        right: 1rem;
        position: relative;
    }

    &__register {
        width: 23rem;
    }

    &__content_title-img {
        gap: $margin-md;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__item {
        @include caption;
        color: $dark-grey;
        text-align: center;
        font-weight: 500;

        &--link {
            cursor: pointer;
            text-decoration: underline;
            color: $orange;
        }
    }

    &__content-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: $margin-md $margin-md;
    }

    &__main-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: $margin-md;
        margin: $margin-none $margin-md ($margin-md + $margin-md);
    }

    &__title {
        @include body2;
        color: $dark-grey;
        font-weight: 600;
        font-size: 18px;
        margin: $margin-none;
    }

    &__text-description {
        @include body2;
        color: $dark-grey;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: $margin-xsm;
    }
}

.affiliation-user {
    &__tyc {
        display: flex;
        gap: $margin-lg;
        justify-content: space-between;
        align-items: center;
    }

    &__list-tyc {
        display: flex;
        flex-direction: column;
        gap: $margin-md;
    }

    &__text {
        @include caption;
        color: $dark-grey;
        text-align: center;
        font-weight: 500;
        font-size: 11px;

        &--link {
            cursor: pointer;
            text-decoration: underline;
            color: $orange;
        }
    }
}

.affiliate_img {
    width: 10rem;
}

.affiliate__terms-and-conditions__view {
    min-width: 50vw;
    height: 70vh;
    width: 100%;
    box-sizing: border-box;
    max-width: 70vw;
    scroll-behavior: auto;
    padding: $padding-sm;
    @include phone {
        max-width: unset;
        max-height: unset;
    }
}
</style>
