<template>
    <section class="orders-by-stores__container">
        <h1>{{ $t('txt.statistics__orders-by-stores').toUpperCase() }}</h1>
        <div class="orders-by-stores__retailers">
            <base-card
                v-for="(retailer, index) in overAllStatistics.retailers"
                :key="index"
                class="retailers-card"
                padding="0"
                @click="goToOrders(retailer.retailer_id)"
            >
                <img v-if="retailer?.retailer_logo" :src="retailer.retailer_logo" alt="" />
                <vertical-divider color-divider="#FF9012" height="70%" width="1px" />
                <div class="retailers-card__description">
                    <h2>{{ retailer.retailer_name.toUpperCase() }}</h2>
                    <h3>{{ retailer.type_retailer.toUpperCase() }}</h3>
                </div>
                <vertical-divider color-divider="#FF9012" height="70%" width="1px" />
                <h2>{{ retailer.effective_orders }}</h2>
            </base-card>
        </div>
    </section>
</template>

<script lang="ts" setup>
import VerticalDivider from '@/components/dividers/VerticalDivider.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { useApp } from '@/composables/useApp';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useStatistics } from '@/views/myAccount/views/profile/views/statistics/useStatistics';

const { $store, $router, notifier, $t } = useApp();
const { allRetailers } = useRetailer();
const { overAllStatistics } = useStatistics();

const goToOrders = (retailerId: number): void => {
    const isRetailerAvailable = allRetailers.value?.some((retailer) => retailer.id === retailerId);
    if (!isRetailerAvailable) {
        return notifier({
            type: 'INFO',
            body: $t('txt.retailer-stats__sector-coverage'),
            title: $t('txt.retailer-stats__out-of-coverage'),
        });
    }
    $store.commit('orders/isHistoryOrders', true);
    $store.commit('orders/selectedRetailer', retailerId);
    $router.push({ name: appRoutesMap.OrdersPage });
};
</script>

<style lang="scss" scoped>
@import './store-stats.scss';
</style>
