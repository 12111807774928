<template>
    <div class="product-detail__content">
        <div class="header__close-icon" @click="handleCloseProductPage">
            <IconBack />
            <p class="header__close-icon_text-return pointer">{{ $t('txt.go-back') }}</p>
        </div>
        <the-product-information section="PRODUCT_DETAIL" />
        <the-products-related v-if="!product.groupId" />
        <TheProductForYourHome v-if="!product.groupId" />
    </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import IconBack from '@/components/icons/IconBack.vue';
import TheProductForYourHome from '@/components/modal/productModal/components/TheProductForYourHome/TheProductForYourHome.vue';
import TheProductInformation from '@/components/modal/productModal/components/theProductInformation/TheProductInformation.vue';
import TheProductsRelated from '@/components/modal/productModal/components/theProductsRelated/TheProductsRelated.vue';
import { useClosePageProductDetail } from '@/composables/closeProductDetail';
import { useUser } from '@/composables/useUser';
import { ProductModel } from '@/models/product/ProductModel';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/store/useProductStore';
import { useRoutes } from '@/composables/useRoutes';

const store = useStore();
const { countrySelected, currency } = useUser();
const { showModalProductsReplacements, isHighDemand, isOutOfStock } = storeToRefs(useProductStore());
const { isPageSeeMoreSuggestions } = useRoutes();
const product = computed<ProductModel>({
    get: () => store.getters['product/productSelected'],
    set: (value) => store.commit('product/productSelected', value),
});

const currentProduct = computed<ProductModel>(() => store.getters['product/currentProduct']);
const { closePageProductDetail } = useClosePageProductDetail();

const handleCloseProductPage = () => {
    closePageProductDetail();
    if ((isHighDemand.value || isOutOfStock.value) && !isPageSeeMoreSuggestions.value)
        showModalProductsReplacements.value = true;
};

const setJSONLDData = () => {
    if (!currentProduct.value) return;
    const script = document.getElementById('jsonLD');
    script.setAttribute('type', 'application/ld+json');
    const _availability = currentProduct.value.available
        ? 'https://schema.org/InStock'
        : currentProduct.value.highDemand
          ? 'https://schema.org/LimitedAvailability'
          : 'https://schema.org/OutOfStock';
    const _size = `${currentProduct.value.unitQuantity} ${currentProduct.value.unit}`;
    const JSONLD = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        productID: currentProduct.value.id,
        description: currentProduct.value.descriptionProduct,
        name: currentProduct.value.name,
        image: currentProduct.value.image?.medium,
        logo: currentProduct.value.image?.low,
        size: _size,
        mpn: currentProduct.value.ean,
        countryOfOrigin: {
            '@type': 'Country',
            telephone: countrySelected.value['mainPhone'],
            longitude: countrySelected.value['locationLongitude'],
            latitude: countrySelected.value['locationLatitude'],
            name: countrySelected.value['name'],
            identifier: countrySelected.value['id'],
            alternateName: countrySelected.value['alphaCode'],
        },
        category: currentProduct.value.category?.name,
        offers: {
            '@type': 'Offer',
            availability: _availability,
            price: currentProduct.value.priceWithDiscount,
            priceCurrency: currency.value,
            url: currentProduct.value?.deepLink,
        },
    };
    script.textContent = JSON.stringify(JSONLD);
};

watch(
    () => currentProduct.value?.id,
    (newId, oldId) => {
        if (newId === oldId) return;
        setJSONLDData();
        store.dispatch('segment/productViewed', {
            product: currentProduct.value,
            searchedPhrase: store.getters['search/query'] ?? '',
        });

        window.scroll({
            top: 0,
            behavior: 'smooth',
        });
    },
    {
        immediate: true,
    },
);

onMounted(() => {
    store.commit('product/showAlertNotSaveNote', false);
});
</script>

<style lang="scss" scoped>
@import './product-detail.scss';
</style>
