import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/shopper_how_works_ok.png'


const _withScopeId = n => (_pushScopeId("data-v-166d11da"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt"]
const _hoisted_2 = { class: "banner-covid__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_info = _resolveComponent("icon-info")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["banner-covid", { 'banner-covid--no-img': !$props.showImage }])
  }, [
    ($props.showImage)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "banner-covid__mobile-img",
          src: _imports_0,
          alt: _ctx.$t('txt.banner__covid-tipti-shopper-with')
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_icon_info, { color: "#FFF" }),
      _createElementVNode("p", null, [
        _createTextVNode(_toDisplayString(_ctx.$t("general['covid-msg']")) + " ", 1),
        _createElementVNode("span", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.openTermAndConditions && $options.openTermAndConditions(...args)))
        }, _toDisplayString(_ctx.$t("general['here']")), 1)
      ])
    ])
  ], 2))
}