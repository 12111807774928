<template>
    <base-alert
        v-if="props.showAlert"
        :title="props.alertInformation?.title"
        accent-header
        @on-close-modal="emits('on-click-outside')"
        @on-close="emits('on-close')"
    >
        <div class="generic_alert">
            <img
                v-if="props.alertInformation?.icon"
                class="generic_alert__image"
                :src="props.alertInformation?.icon"
                alt=""
            />
            <slot name="icon" />
            <p
                class="generic_alert__description"
                :class="{ 'generic_alert__description--large': props.alertInformation?.icon && isLargeDescription }"
            >
                {{ props.alertInformation?.description }}
            </p>
            <slot name="moreContent" />
            <btn-outline
                v-if="props.alertInformation?.secondary_button_text?.length"
                class="generic_alert__btn"
                :txt="props.alertInformation?.secondary_button_text"
                bg-color="#fff"
                border-radius="3px"
                color="#FF9012"
                @click="emits('on-secondary-action')"
            />
            <btn-solid
                class="generic_alert__btn"
                :txt="props.alertInformation?.button_text ?? ''"
                border-radius="3px"
                @click="emits('on-main-action')"
            />
            <btn-text
                v-if="props.alertInformation?.link_text?.length"
                class="generic_alert__btn--text"
                :text="props.alertInformation?.link_text"
                @click="emits('on-text-action')"
            />
        </div>
    </base-alert>
</template>

<script lang="ts" setup>
import BtnOutline from '@/components/buttons/BtnOutLine.vue';
import { TiptiAlertModel } from '@/models/alerts/AlertOpenRetailer';
import BaseAlert from '@/components/alerts/baseAlert/BaseAlert.vue';
import { computed } from 'vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnText from '@/components/buttons/BtnText.vue';

interface TiptiAlertInterface {
    alertInformation?: TiptiAlertModel;
    showAlert?: boolean;
}

const emits = defineEmits(['on-close', 'on-click-outside', 'on-secondary-action', 'on-main-action', 'on-text-action']);
const props = defineProps<TiptiAlertInterface>();

const isLargeDescription = computed(() => props.alertInformation?.description?.length > 30);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.generic_alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $padding-md;
    &__image {
        width: 15rem;
        min-height: 5rem;
    }
    &__description {
        @include caption;
        margin: $margin-md $margin-none;
        max-width: 30rem;
        text-align: center;
        @include phone {
            font-size: 14px;
        }
        &--large {
            @include overline;
            margin: $margin-md $margin-none;
            @include phone {
                font-size: 12px;
            }
        }
    }
    &__btn {
        box-shadow: unset;
        margin: $margin-sm;
        margin-bottom: unset;
        width: 100%;
        max-width: 20rem;
    }
}
</style>
