import { CountriesModel } from '@/models/external/CountriesModel';
import { CountryCodeModel } from '@/models/country/CountryCodeModel';

export default {
    countries(state: { countries: Array<CountriesModel> }): Array<CountriesModel> {
        return state.countries;
    },
    countrySelected(state: { countrySelected: CountriesModel }): CountriesModel {
        return state.countrySelected;
    },
    countrySelectedName(state: { countrySelected: CountriesModel }): string {
        return state.countrySelected?.name ?? '';
    },
    urlSelected(state: { countrySelected: CountriesModel }): string {
        const expStr = 'https://api.|misuper|api.';
        const defaultDomain = state.countrySelected?.domain?.replace(RegExp('\\b(' + expStr + ')\\b', 'gi'), '');
        return `https://${defaultDomain}`;
    },
    currency(state): string {
        return state.countrySelected?.currencySymbol ?? '$';
    },
    currencyType(state): string {
        return state.countrySelected?.currencyType ?? 'USD';
    },
    countryCodeSelected(state): CountryCodeModel {
        return state.countryCodeSelected;
    },
    countryCodes(state): Array<CountryCodeModel> {
        return state.countryCodes;
    },
};
