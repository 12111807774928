<template>
    <div>
        <CardAdd
            v-if="!isLoading"
            :button-text="$t('forms.add_billing_data')"
            :show-button-actions="!!countryBills?.length"
            :text-button-action="isRemovingCards ? $t('cta.save') : $t('cta.edit')"
            @on-pressed="isRemovingCards = !isRemovingCards"
            @open-bill-form="$emit('open-bill-form', true)"
        />
        <base-loading v-if="isLoading && !countryBills?.length" />
        <section v-else-if="countryBills?.length" class="bills-as-list">
            <article v-for="country in countryBills" :key="country.id">
                <subheader-separator
                    v-if="countryBills?.length >= 1 && !isFromModal"
                    :name="country?.name"
                    :show-btn="false"
                    has-padding
                />
                <article :class="{ 'bills ': isFromModal, 'bills bills-account': !isFromModal }">
                    <CardBase
                        v-for="(bill, billIndex) in country?.bill_address"
                        :key="billIndex"
                        class="card-bill--content"
                        :class="{
                            'card-bill-base--selected': billUsed?.id === bill.id && isFromModal,
                        }"
                        :is-check="billUsed?.id === bill.id"
                        :is-default="bill.default"
                        :is-removing="isRemovingCards"
                        :show-check-selected="isFromModal"
                        @click="clickBill(bill, country.id)"
                        @on-edit="editBill({ bill, countryId: country.id })"
                        @on-delete="deleteBill(bill.id)"
                    >
                        <section class="bill-card">
                            <p class="card-data card-data--name">
                                {{ bill.name }}
                            </p>
                            <p class="card-data">{{ bill.identifier }}</p>
                            <p class="card-data card-data--email">{{ bill.email }}</p>
                        </section>
                    </CardBase>
                </article>
            </article>
        </section>

        <no-content
            v-else
            class="billings__no-content"
            :description="$t('txt.billings__no-content-description')"
            :title="$t('txt.billings__no-content-title')"
            color-title="#A2A2A2"
        >
            <icon-bill :size="2.5" color="#C1C1C1" />
        </no-content>
        <snack-bar
            :body="modalMessage"
            :is-active="showModal"
            :is-failure="isFailure"
            :is-success="isSuccess"
            @on-snackbar-close="showModal = false"
        />
    </div>
    <button
        v-if="isRemovingCards && isFromModal"
        class="cancel-bills-button"
        @click="isRemovingCards = !isRemovingCards"
    >
        <span class="cancel-bills cursor-pointer">{{ $t('cta.cancel') }}</span>
    </button>
</template>

<script lang="ts">
import { logger } from '@/utils/logger';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import IconBill from '@/components/icons/IconBill.vue';
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import { useBillStore } from '@/store/useBillStore';
import { useUser } from '@/composables/useUser';
import CardBase from '@/components/generic/CardBase.vue';
import { storeToRefs } from 'pinia';
import CardAdd from '@/components/generic/CardAdd.vue';
import { useBill } from '@/views/bills/composableBill';
import { useApp } from '@/composables/useApp';
import { computed } from 'vue';
import { useRoutes } from '@/composables/useRoutes';

export default {
    name: 'BillsAsList',
    components: {
        CardAdd,
        CardBase,
        IconBill,
        SnackBar,
        NoContent,
        SubheaderSeparator,
        BaseLoading,
    },
    props: {
        showCheck: {
            type: Boolean,
            default: false,
        },
        hasSelected: {
            type: Boolean,
            required: false,
        },
        isFromModal: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['on-edit-bill', 'on-check', 'open-bill-form', 'schedule-selected'],
    setup() {
        const $billsStore = useBillStore();
        const { billUsed: actualBill } = storeToRefs(useBillStore());
        const { alphaCode } = useUser();
        const { billSelected, defaultBill, saveSelection, showModalBill } = useBill();
        const { notifier } = useApp();
        const { isScreenCheckout } = useRoutes();
        const countryCode = computed(() => (isScreenCheckout.value ? alphaCode.value : undefined));

        return {
            showModalBill,
            notifier,
            billSelected,
            defaultBill,
            saveSelection,
            billUsed: actualBill,
            fetchBills: () => $billsStore.getAll(countryCode.value),
            deleteBillInfo: (id: number) => $billsStore.delete(id),
        };
    },
    data() {
        return {
            isLoading: false,
            showModal: false,
            modalMessage: '',
            isSuccess: false,
            isFailure: false,
            isRemovingCards: false,
            countryBills: [],
        };
    },
    async mounted() {
        this.isLoading = true;
        this.countryBills = await this.fetchBills();
        this.isLoading = false;
    },

    methods: {
        async deleteBill(billId: number): Promise<void> {
            try {
                const isRequestComplete = await this.deleteBillInfo(billId);
                this.modalMessage = isRequestComplete
                    ? this.$t("txt['billing__delete-data-success']")
                    : this.$t("txt['billing__delete-data-error']");
                this.showModal = true;
                this.isSuccess = isRequestComplete;
                this.isFailure = !isRequestComplete;
                this.countryBills = await this.fetchBills();
            } catch (err) {
                logger('DELETE_BILLING', err);
            }
        },
        clickBill(bill: any, countryId: number): void {
            if (this.showCheck && !this.isRemovingCards) {
                this.$emit('on-check', { bill, countryId });
                this.showModalBill = false;
                this.notifier({
                    type: 'SUCCESS',
                    body: this.$t("txt['billing__change-success']"),
                });
            }
        },

        editBill({ bill, countryId }: { bill: any; countryId: number }): void {
            this.$emit('on-edit-bill', { bill, countryId });
        },
    },
};
</script>

<style lang="scss" scoped>
@import './bills-as-list.scss';
</style>
