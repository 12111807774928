<template>
    <main :class="{ 'main-content': true, 'main-content--empty': !hasRetailers }">
        <TheRetailerScreen @has-retailers="(value) => (hasRetailers = value)" />
    </main>

    <t-c-modal-handler />
    <ModalGiftCardAccreditation />
    <alert-news v-if="showAlertNews" @close-alert="closeAlert" />
    <story-page v-if="showStoryFromDeeplink" @on-close="closeStoryPage" />
    <OrderAcceptedAlert />
    <notification-rate v-if="hasRetailers" />
    <RetailersModal v-if="productsFlow" />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import AlertNews from '@/components/alerts/alertNews/AlertNews.vue';
import StoryPage from '@/components/stories/theStoriesCarousel/components/storyPage/StoryPage.vue';
import NotificationRate from '@/views/ratingOrder/notificationRate/NotificationRate.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import TheRetailerScreen from '@/modules/retailers/pages/TheRetailerScreen.vue';
import { useCartType } from '@/modules/core/composable/useCartType';
import { AlertNewsDto } from '@/models/alertNews/AlertNewsModel';
import TCModalHandler from '@/modules/retailers/components/t&c/T&CModalHandler.vue';
import { computed } from 'vue';
import OrderAcceptedAlert from '@/components/alerts/alertPurchase/OrderAcceptedAlert.vue';
import ModalGiftCardAccreditation from '@/components/modalsGiftCards/giftCardAccreditationModal/ModalGiftCardAccreditation.vue';
import RetailersModal from '@/modules/retailers/components/modal/RetailersModal.vue';
import { useApp } from '@/composables/useApp';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    components: {
        RetailersModal,
        ModalGiftCardAccreditation,
        OrderAcceptedAlert,
        TCModalHandler,
        TheRetailerScreen,
        NotificationRate,
        AlertNews,
        StoryPage,
    },
    setup() {
        const { $store, notifier } = useApp();
        const { changeToMainCart } = useCartType();
        const { otherRetailers, filteredRetailers, retailerSelected } = useRetailer();

        const productsFlow = computed(() => $store.getters['deeplink/productsFlow']);

        return {
            changeToMainCart,
            filteredRetailers,
            otherRetailers,
            productsFlow,
            retailerSelected,
            notifier,
        };
    },
    data() {
        return {
            hasRetailers: false,
        };
    },
    computed: {
        ...mapGetters({
            locationSelected: 'user/userLocation',
            showStoryFromDeeplink: 'deeplink/showStory',
            localNewsAlertSlide: 'alertNews/alertNewsSlide',
            closeNewsAlert: 'alertNews/closeNewsAlertInRetailers',
            informativeBanner: 'promotions/informativeBanner',
            informativeStory: 'stories/informativeStory',
        }),
        showAlertNews(): boolean {
            return (
                this.newsAlertSlide?.show_in_web_app && this.newsAlertSlide?.show_at_home_screen && !this.closeNewsAlert
            );
        },
        newsAlertSlide(): AlertNewsDto {
            return this.localNewsAlertSlide;
        },
    },
    watch: {
        filteredRetailers(): void {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        },
    },
    async created(): Promise<void> {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        if (this.$route.query['account-deactivated']) {
            this.$router.replace({ name: appRoutesMap.retail.home });
            this.notifier({
                type: 'INFO',
                body: this.$t('txt.deactivate-account__account-deactivated'),
            });
        }

        //***  << in order to keep the modals flows
        // 1. show Tipti terns and conditions modal
        // 2. Show Alert purchase with payment rejected
        // The logic begin in the file the-status-banner calling the service then
        // call the orders services
        this.$store.commit('extraItems/order');
        this.$store.commit('retailers/cleanAllRetailersData');
        this.$store.commit('checkout/isAutomaticReplacements', false);
        this.$store.commit('checkout/isElder', false);

        await Promise.allSettled([this.changeToMainCart(), this.$store.dispatch('alertNews/getSlidesNews')]);

        this.$store.dispatch('deeplink/handleTrail');
    },
    methods: {
        closeAlert(): void {
            this.$store.commit('alertNews/closeNewsAlertInRetailers', true);
        },
        closeStoryPage(): void {
            this.$store.commit('deeplink/showStory', false);
            this.$store.commit('stories/selectedStory', undefined);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.main-content {
    max-width: 100svw;
    margin: $padding-lg $padding-screen;
    @include tablet {
        margin: $padding-md;
    }

    &--empty {
        min-height: 50vh;
        display: grid;
        place-content: center;
    }
}
</style>
