import { CategoryModel, SubCategories } from '@/models/product/CategoryModel';
import { useApp } from '@/composables/useApp';
import { computed } from 'vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export const useCategories = () => {
    const { $store } = useApp();
    const { retailerSelected } = useRetailer();

    const allSubCategories = computed<SubCategories[]>(() => $store.getters['categories/allSubCategories']);
    const categories = computed<CategoryModel[]>(() => $store.getters['categories/categories']);
    const categoriesWithOutDiscount = computed(() => categories.value.filter((cat) => cat.id !== 0));
    const category = computed<CategoryModel>(() => $store.getters['categories/getCategorySelected']);
    const subcategory = computed<CategoryModel>(() => $store.getters['categories/getSubcategorySelected']);

    const subcategories = computed(
        () => allSubCategories.value?.find((cat) => cat.categoryId == category.value?.id)?.subcategories ?? [],
    );
    const lastIdRetailerSelected = computed<number>(() => $store.getters['categories/lastRetailIdFetched']);

    const getSubCategoryByCategory = async (): Promise<void> => {
        $store.commit('categories/lastIdRetailerSelected', retailerSelected.value.id);
        $store.commit('categories/allSubCategories', []);

        const filteredCategories = categories.value.filter(
            (cat: CategoryModel) => cat.id !== 0 && cat.hasSubcategories,
        );
        if (!filteredCategories.length) return;

        const results = await Promise.allSettled<{
            sub_categories: CategoryModel[];
            category_id: number;
        }>(
            filteredCategories.map(async (cat) => {
                return $store.dispatch('categories/subcategoriesCategoriesList', {
                    retailerId: retailerSelected.value?.id,
                    categoryId: cat.id,
                });
            }),
        );
        const subCategories = [];

        results.forEach((subCategoryResponse) => {
            if (subCategoryResponse.status !== 'fulfilled') return;
            if (!subCategoryResponse.value?.sub_categories?.length) return;
            subCategories.push({
                subcategories: subCategoryResponse.value.sub_categories,
                categoryId: subCategoryResponse.value.category_id,
            });
        });

        $store.commit('categories/allSubCategories', subCategories);
    };

    const fetchCategoriesData = (): void => {
        if (retailerSelected.value.id === lastIdRetailerSelected.value) return;
        getSubCategoryByCategory();
    };

    return {
        fetchCategoriesData,
        categories,
        categoriesWithOutDiscount,
        subcategory,
        category,
        subcategories,
    };
};
