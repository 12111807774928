<template>
    <base-icon :icon-name="$t('icon.facebook')" :width="width" :height="height" viewBox="0 0 12 26" :iconColor="color">
        <path
            d="M12 8.36538L11.5385 12.9231H7.90385C7.90385 18.6923 7.90385 25.8462 7.90385 25.8462H2.53846C2.53846 25.8462 2.53846 18.8077 2.53846 12.9231H0V8.36538H2.53846V5.42308C2.53846 3.28846 3.57692 0 7.96154 0H11.9423V4.44231C11.9423 4.44231 9.51923 4.44231 9.05769 4.44231C8.59615 4.44231 7.90385 4.67308 7.90385 5.71154V8.36538H12Z"
            fill="white"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-fb',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 12 * this.size;
        },
        height(): number {
            return 26 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
