<script lang="ts" setup>
import { useWallet } from '@/views/wallet/useWallet';
import { useRouter } from 'vue-router';
import { GiftCardDTO } from '@/views/wallet/WalletModels';
import IconBack from '@/components/icons/IconBack.vue';
import GiftCard from '@/components/cards/GiftCard.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'TheExpiredGiftCards',
});

const $router = useRouter();

const { expiredGiftCards, giftCardSelected } = useWallet();

const goToExpiredGitftCardDetail = (item: GiftCardDTO) => {
    giftCardSelected.value = item;
    return $router.push({
        name: appRoutesMap.expiredGiftCardDetail,
    });
};

const goBack = () => {
    $router.replace({
        name: appRoutesMap.Wallet,
    });
    expiredGiftCards.value = [];
};

if (!expiredGiftCards.value) goBack();
</script>

<template>
    <button class="expired-gift-cards__btn" @click="goBack">
        <icon-back :size="0.8" color="#000000" />
        <span class="expired-gift-cards__btn-text">{{ $t('txt.go-back') }}</span>
    </button>
    <section class="expired-gift-cards__list">
        <article
            v-for="item in expiredGiftCards"
            :key="item.id"
            class="expired-gift-cards__card-container"
            @click="goToExpiredGitftCardDetail(item)"
        >
            <GiftCard :card="item" is-expired />
            <div class="expired-gift-cards__card-description">
                <p class="expired-gift-cards__card-description__name">{{ item.card_name }}</p>
                <p class="expired-gift-cards__card-description__date">
                    {{ $t('txt.gift-card-expired-on') + item.expiration_date }}
                </p>
            </div>
        </article>
    </section>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.expired-gift-cards__btn {
    all: unset;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin: 0 $margin-xlg;

    &-text {
        @include button;
        font-size: 1.8rem;
        font-weight: 600;
        text-transform: capitalize;
    }
}

.expired-gift-cards {
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 2rem 3rem;
        padding: 0 $margin-lg;
    }

    &__card-container {
        cursor: pointer;
        height: 17rem;
        position: relative;
        @include tablet {
            height: 14rem;
        }
    }

    &__card-description {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 5rem;
        background-color: $bright-canvas;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        box-sizing: border-box;
        padding: $padding-md;

        &__name {
            @include subtitle2;
        }

        &__date {
            @include caption;
            text-align: end;
        }
    }
}
</style>
