import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]
const _hoisted_6 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.historyExchange'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 43 38",
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M8.46645 7.1C11.7998 3.43331 16.5663 1.33326 21.5332 1.33326C25.0665 1.33326 28.4665 2.36656 31.3001 4.26661L30.1335 6.03332C30.0001 6.23333 30.0001 6.46666 30.1001 6.69999C30.2001 6.9 30.4001 7.06666 30.6335 7.06666L35.9001 7.66667C36.4335 7.73333 36.8335 7.13333 36.5335 6.66666L34.4001 1.39997C34.3001 1.16663 34.1001 0.999967 33.8668 0.999967C33.6335 0.966633 33.4001 1.09997 33.2668 1.29997L32.0334 3.16664C28.9667 1.09997 25.3667 0 21.5336 0C16.1668 0 11.0665 2.26668 7.49997 6.23333C7.26663 6.49999 7.26663 6.93333 7.5333 7.16666C7.79997 7.4 8.23331 7.4 8.46664 7.1H8.46645Z",
        fill: $props.color
      }, null, 8, _hoisted_1),
      _createElementVNode("path", {
        d: "M36.5 31.7666C36.7333 31.4999 36.7333 31.0666 36.4666 30.8333C36.2 30.5999 35.7666 30.5999 35.5333 30.8666C32.1666 34.5666 27.3336 36.6666 22.2997 36.6666C18.7331 36.6666 15.2997 35.6333 12.3995 33.7333L13.5661 31.9666C13.6994 31.7666 13.6994 31.5332 13.5994 31.2999C13.4994 31.0999 13.2994 30.9332 13.0661 30.9332L7.73273 30.3332C7.4994 30.2999 7.26606 30.3999 7.13273 30.5999C6.99939 30.7999 6.96606 31.0332 7.03273 31.2666L9.23274 36.5999C9.33274 36.8333 9.53274 36.9999 9.79941 36.9999H9.86607C10.0994 36.9999 10.2994 36.8999 10.4327 36.6999L11.6661 34.8332C14.7662 36.8999 18.4328 37.9999 22.2993 37.9999C27.6993 37.9999 32.899 35.7332 36.4998 31.7666L36.5 31.7666Z",
        fill: $props.color
      }, null, 8, _hoisted_2),
      _createElementVNode("path", {
        d: "M10.333 8.66748C4.63301 8.66748 0 13.3008 0 19.0005C0 24.7002 4.63332 29.3335 10.333 29.3335C16.0327 29.3335 20.666 24.7002 20.666 19.0005C20.666 13.3008 16.0327 8.66748 10.333 8.66748ZM8.06633 19.6679H12.6664C13.033 19.6679 13.333 19.9679 13.333 20.3346C13.333 20.7013 13.033 21.0013 12.6664 21.0013H8.46633C9.16633 22.4013 10.533 23.3346 12.0997 23.3346C12.2664 23.3346 12.4331 23.3346 12.5997 23.3013C12.9664 23.2679 13.2997 23.5013 13.3331 23.8679C13.3664 24.2346 13.1331 24.5679 12.7664 24.6013C12.5664 24.6346 12.3331 24.6346 12.0997 24.6346C9.76637 24.6346 7.79966 23.1013 7.03303 20.9679H5.99973C5.63306 20.9679 5.33306 20.6679 5.33306 20.3013C5.33306 19.9346 5.63306 19.6346 5.99973 19.6346H6.69973C6.6664 19.4346 6.6664 19.2346 6.6664 19.0012C6.6664 18.7679 6.6664 18.5679 6.69973 18.3346H5.99973C5.63306 18.3346 5.33306 18.0346 5.33306 17.6679C5.33306 17.3012 5.63306 17.0012 5.99973 17.0012H7.03303C7.79966 14.8679 9.79967 13.3346 12.0997 13.3346C12.2997 13.3346 12.5331 13.3346 12.7331 13.3679C13.0997 13.4012 13.3664 13.7346 13.2997 14.1012C13.2664 14.4679 12.9331 14.7346 12.5664 14.6679C12.3997 14.6346 12.2664 14.6346 12.0997 14.6346C10.5331 14.6346 9.16637 15.5679 8.46633 16.9679H12.6664C13.033 16.9679 13.333 17.2679 13.333 17.6346C13.333 18.0012 13.033 18.3012 12.6664 18.3012H8.06633C8.03299 18.5346 7.99966 18.7346 7.99966 18.9679C7.99966 19.2346 8.03299 19.4346 8.06633 19.6679Z",
        fill: $props.color
      }, null, 8, _hoisted_3),
      _createElementVNode("path", {
        d: "M31.704 15.9673C31.6706 15.9673 31.6373 15.9673 31.604 16.0006C30.9706 16.1673 30.3373 16.8673 30.7373 17.534C30.9706 17.9006 31.3373 18.0673 31.7373 18.1673V15.9673H31.704Z",
        fill: $props.color
      }, null, 8, _hoisted_4),
      _createElementVNode("path", {
        d: "M33.4034 20C33.2701 19.9667 33.1367 19.9 33.0367 19.8667V22.4C33.4367 22.3334 33.8034 22.1667 34.0367 21.8667C34.6034 21.2 34.2367 20.3001 33.4034 20Z",
        fill: $props.color
      }, null, 8, _hoisted_5),
      _createElementVNode("path", {
        d: "M32.3697 29.3335C38.0697 29.3335 42.7027 24.7002 42.7027 19.0005C42.7027 13.3008 38.0694 8.66748 32.3697 8.66748C26.67 8.66748 22.0367 13.3008 22.0367 19.0005C22.0367 24.7002 26.67 29.3335 32.3697 29.3335ZM29.103 20.7331C29.303 20.3331 29.8697 20.3997 30.1363 20.6997C30.3697 20.9997 30.4697 21.3664 30.703 21.6664C30.9363 21.9665 31.303 22.1664 31.703 22.2998V19.5331C31.003 19.3665 30.2697 19.1331 29.7364 18.5998C29.2364 18.0665 29.1697 17.2665 29.303 16.5664C29.5364 15.5664 30.4031 14.9998 31.3364 14.7331C31.4364 14.6998 31.5698 14.6664 31.6698 14.6664V13.3331C31.6698 12.9664 31.9698 12.6664 32.3364 12.6664C32.7031 12.6664 33.0031 12.9664 33.0031 13.3331V14.7331C33.4698 14.7998 33.9031 14.9331 34.3031 15.1998C34.6364 15.3998 34.9364 15.6664 35.1697 15.9998C35.5697 16.5331 35.7031 17.0331 35.6364 17.4331C35.6031 17.7664 35.2364 17.9664 34.9364 17.8664C34.6364 17.7998 34.4364 17.4998 34.3031 17.2331C34.1697 16.8664 33.9364 16.5331 33.6364 16.2997C33.4364 16.1664 33.2364 16.0664 33.0031 15.9997V18.4664C33.2697 18.5331 33.5364 18.6331 33.8031 18.6998C35.0697 19.0664 35.8031 20.1664 35.6364 21.4331C35.5364 22.0998 35.103 22.6998 34.5363 23.0331C34.0697 23.3331 33.5363 23.4998 33.003 23.5665V24.6665C33.003 25.0332 32.703 25.3332 32.3363 25.3332C31.9696 25.3332 31.6696 25.0332 31.6696 24.6665V23.5998C30.7363 23.4665 30.003 23.0332 29.5029 22.3331C29.3696 22.1665 29.2696 21.9665 29.1696 21.7665C29.0696 21.4998 28.9696 21.0331 29.1029 20.7332L29.103 20.7331Z",
        fill: $props.color
      }, null, 8, _hoisted_6)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "iconColor"]))
}