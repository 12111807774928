<template>
    <template v-if="items.length || state === 'FIRST_LOAD'">
        <slot name="header">
            <SubheaderSeparator
                v-if="showOnlySeeMoreAction || subheaderName?.length"
                :has-padding="hasSubheaderPadding"
                :name="showOnlySeeMoreAction ? '' : subheaderName"
                :show-btn="showSubheaderBtn"
                @on-pressed="$emit('on-click-subheader')"
            />
        </slot>
        <CarouselBase
            :background-arrow="backgroundArrow"
            :color-arrow="colorArrow"
            :items="items"
            :state="state"
            @reached-end="$emit('reached-end')"
        >
            <template #item="{ item, position }">
                <CardProductSpecial
                    v-if="position === 0 && !isMobile && showSpecialProductCard"
                    :class="{ 'carousel-products__special': showSpecialProductCard }"
                    :product-data="item"
                    :section="section"
                    @on-gm-items="(id: number) => $emit('on-gm-items', id)"
                />
                <CardProduct
                    v-else
                    class="carousel-products__regular"
                    :class="{ 'carousel-products__new': isNewProducts }"
                    :product-data="item"
                    :section="section"
                    :track-item-section="trackItemSection"
                    @on-gm-items="(id: number) => $emit('on-gm-items', id)"
                />
            </template>
            <template #more>
                <CardLoadMoreProducts
                    v-if="showLoadMoreProductsCard"
                    class="carousel-products__load-more-products"
                    :is-loading="false"
                    @click="$emit('on-click-subheader')"
                />
            </template>
            <template v-if="showReplacementSuggestion" #suggestion>
                <section class="carousel-products__icons-sg" @click="$emit('on-redirect-suggestion')">
                    <p class="carousel-products__icons">
                        <IconSuggestion :size="0.2" />
                        <span class="carousel-products__suggestions">{{ $t('cta.see-more-suggestions') }}</span>
                    </p>
                </section>
            </template>
            <template #skeleton>
                <base-bone
                    class="carousel-products__regular carousel-products__regular--skeleton"
                    :class="{ 'carousel-products__new': isNewProducts }"
                />
            </template>
        </CarouselBase>
    </template>
</template>

<script lang="ts" setup>
import CardProductSpecial from '../cards/cardProductSpecial/CardProductSpecial.vue';
import SubheaderSeparator from '../subheaders/subheaderSeparator/SubheaderSeparator.vue';
import CardLoadMoreProducts from '../cards/cardLoadMoreProducts/CardLoadMoreProducts.vue';
import CarouselBase from '@/components/carousels/components/CarouselBase.vue';
import { useApp } from '@/composables/useApp';
import CardProduct from '@/components/cards/cardProduct/CardProduct.vue';
import { PropType } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import { RequestState } from '@/modules/core/types/WorkingState';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import IconSuggestion from '@/components/icons/IconSuggestion.vue';

defineProps({
    items: {
        type: Array as PropType<ProductModel[]>,
        required: true,
    },
    state: {
        type: String as PropType<RequestState | 'FIRST_LOAD'>,
        required: true,
    },
    trackItemSection: {
        type: String,
        default: '',
    },
    section: {
        type: String,
        default: '',
    },
    isNewProducts: {
        type: Boolean,
        default: false,
    },
    subheaderName: {
        type: String,
        default: '',
    },
    showSubheaderBtn: {
        type: Boolean,
        default: true,
    },
    hasSubheaderPadding: {
        type: Boolean,
        default: false,
    },
    showLoadMoreProductsCard: {
        type: Boolean,
        default: false,
    },
    showSpecialProductCard: {
        type: Boolean,
        default: false,
    },
    showOnlySeeMoreAction: {
        type: Boolean,
        default: false,
    },
    backgroundArrow: {
        type: String,
        default: '#dfdfdf',
    },
    colorArrow: {
        type: String,
        default: '#606060',
    },
    showReplacementSuggestion: {
        type: Boolean,
        default: false,
    },
});
const { isMobile } = useApp();

defineEmits<{
    (e: 'on-click-subheader'): void;
    (e: 'reached-end'): void;
    (e: 'on-gm-items', id: number): void;
    (e: 'on-redirect-suggestion'): void;
}>();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.carousel-products__special {
    width: 40rem;
}

.carousel-products__regular {
    width: 12rem;
    height: 100%;

    &--skeleton {
        height: 20rem;
        @include tablet {
            height: 18rem;
        }
        @include phone {
            height: 16rem;
        }
    }

    @include tablet {
        width: 10rem;
    }
    @include phone {
        width: 8rem;
    }
}

.carousel-products__new {
    height: 100%;
    width: 18rem;

    @include phone {
        width: 13rem;
    }
}

.carousel-products__load-more-products {
    width: 10rem;
    margin-right: $margin-xsm;
    @include tablet {
        width: 8rem;
        height: 85%;
    }
    @include phone {
        width: 8rem;
    }
}

.products-grid {
    padding: 0 $padding-md;
}

.carousel-products {
    &__icons-sg {
        all: unset;
        display: flex;
        gap: $margin-md;
        cursor: pointer;
        margin: $margin-none $margin-sm;
    }

    &__icons {
        box-shadow: 0 0.5px 4px 0.5px #00000033;
        padding: $padding-md;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 18px;
        width: 8rem;
        text-align: center;
        margin: $margin-none;
    }

    &__suggestions {
        @include body1;
        color: $dark-grey;
        font-size: 16px;
        cursor: pointer;
    }
}
</style>
