<template>
    <div v-if="modelValue" class="top-sheet__main">
        <div class="top-sheet__content">
            <div ref="btnRef" :style="stylesContent">
                <slot />
            </div>
        </div>
        <footer class="top-sheet__footer" @click="$emit('update:modelValue', false)">
            <icon-arrow class="icon-arrow" :size="0.6" color="#FF9012" />
        </footer>
    </div>
</template>

<script lang="ts" setup>
import IconArrow from '@/components/icons/IconArrow.vue';
import { computed, onUnmounted, ref, watch } from 'vue';
import { onClickOutside, useIntersectionObserver } from '@vueuse/core';
import { useStore } from 'vuex';
import { isMobileBrowser } from '@/utils/utils';

defineOptions({
    name: 'BaseTopSheet',
});

const isMobile = computed(() => isMobileBrowser());

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    bgColor: {
        type: String,
        default: '#f1f1f1',
    },
    height: {
        type: Number,
        default: 0,
    },
});

const $emit = defineEmits<(e: 'update:modelValue', value: boolean) => void>();
const btnRef = ref<HTMLElement | undefined>();
const $store = useStore();
const targetIsVisible = ref(false);

const { stop } = useIntersectionObserver(btnRef, ([{ isIntersecting }]) => {
    targetIsVisible.value = isIntersecting;
});

onClickOutside(btnRef, () => {
    // if (!targetIsVisible.value) return;
    // $emit('update:modelValue', false);
});

const stylesContent = computed(() => {
    return {
        'background-color': props.bgColor,
        height: props.height + 'px',
    };
});

onUnmounted(() => {
    $store.commit('search/showMainSearchBlurPredictions', false);
});

watch(
    () => props.modelValue,
    (newValue) => {
        if (!newValue) return;
        if (!isMobile.value) return;
        $store.commit('search/showMainSearchBlurPredictions', true);
    },
    {
        immediate: true,
    },
);
</script>

<style lang="scss" scoped>
@import 'base-top-sheet';
</style>
