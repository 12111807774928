interface AddressComponent {
    types: string[];
    long_name: string;
    short_name: string;
}
interface Address {
    address_components: AddressComponent[];
    formatted_address: string;
}

class MapUtils {
    address: Address;
    constructor(address: Address) {
        this.address = address;
    }

    private _filterAddress(options: string[], isShortName = false): string {
        const place = this.address.address_components.find((address: { types: string[] }) =>
            address.types.some((type: string) => options.includes(type)),
        );
        if (!place?.long_name || !place?.short_name) return '';
        return place.long_name === 'Unnamed Road' ? '' : isShortName ? place.short_name : place.long_name;
    }

    getMainStreet(): string {
        return this._filterAddress(['street_address', 'colloquial_area', 'premise', 'route', 'plus_code']);
    }
    getSecondStreet(): string {
        return this._filterAddress(['intersection', 'neighborhood', 'subpremise', 'political', 'postal_code']);
    }
    getCity(): string {
        return this._filterAddress(['locality']);
    }

    getCountry(): string {
        return this._filterAddress(['country']);
    }

    getCountryCode(): string {
        return this._filterAddress(['country'], true);
    }

    getReference(): string {
        return this._filterAddress([
            'natural_feature',
            'establishment',
            'landmark',
            'street_number',
            'point_of_interest',
            'park',
            'airport',
            'postal_code',
        ]);
    }
    getAddress(): string {
        const address: string = `${this.getMainStreet()} ${this.getSecondStreet()} ${this.getReference()}`.trim();
        return !address.length ? this.address.formatted_address : address;
    }
}

export default MapUtils;
export { Address };
