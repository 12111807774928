import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { OrderResumenModel } from '@/models/order/OrderResumen';
import { useI18n } from 'vue-i18n';
import { ResponsePaged } from '@/modules/core/models/ResponseModels';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { OrderModel } from '@/models/order/OrderModel';
import { OrderState } from '@/enums/orderState';
import type { OrderRejected } from '@/models/order/OrderRejectedModel';

const orderRejected = ref<OrderRejected>();

const modalRate = ref(false);
const snackRate = ref(false);

const useOrderRate = () => {
    const $store = useStore();
    const { t: $t } = useI18n();

    const orderToRate = computed<OrderModel>({
        get: () => $store.getters['orders/orderRateSelected'],
        set: (value) => $store.commit('orders/orderRateSelected', value),
    });

    const orderRateForm = reactive<{
        purchase_rate: number;
        delivery_rate: number;
        skip: boolean;
        tip_amount: number;
        tip_concept: string;
        comment: string;
    }>({
        purchase_rate: 0,
        delivery_rate: null,
        skip: false,
        tip_amount: null,
        tip_concept: 'ALL',
        comment: '',
    });

    const rateOrder = async (): Promise<void> => {
        await axiosInstance().post('/v2/client/rate-order-and-leave-tip/', {
            order_id: orderToRate.value.id,
            ...orderRateForm,
        });
        orderRateForm.purchase_rate = 0;
        orderRateForm.delivery_rate = 0;
        orderRateForm.skip = false;
        orderRateForm.tip_amount = null;
        orderRateForm.comment = '';

        modalRate.value = false;
        snackRate.value = false;
    };

    const showModalRate = computed({
        get: () => modalRate.value,
        set: (value) => {
            orderRateForm.comment = '';
            orderRateForm.purchase_rate = 0;
            orderRateForm.delivery_rate = 0;
            snackRate.value = !value;
            modalRate.value = value;
        },
    });

    const skipRatingOrder = async () => {
        showModalRate.value = false;
        snackRate.value = false;
        orderRateForm.purchase_rate = null;
        orderRateForm.delivery_rate = null;
        orderRateForm.skip = true;
        orderRateForm.tip_amount = null;
        orderRateForm.comment = '';
        return rateOrder();
    };

    const features = reactive([
        {
            txt: $t('txt.rate-shopper__feature-correct-replacements'),
            active: false,
        },
        {
            txt: $t('txt.rate-shopper__feature-followed-instructions'),
            active: false,
        },
        {
            txt: $t('txt.rate-shopper__feature-complete-order'),
            active: false,
        },
        {
            txt: $t('txt.rate-shopper__feature-quality-products'),
            active: false,
        },
    ]);

    const getOrderToRate = async (): Promise<ResponsePaged<OrderModel>> => $store.dispatch('orders/getOrdersToRate');

    return {
        features,
        getOrderToRate,
        showSnackRate: snackRate,
        skipRatingOrder,
        rateOrder,
        orderRateForm,
        showModalRate,
        orderToRate,
    };
};
export const useOrder = () => {
    const $store = useStore();

    const order = computed<OrderResumenModel>({
        get: () => $store.getters['orders/orderDetailSelected'],
        set: (value) => $store.commit('orders/orderDetailSelected', value),
    });

    const orderDetailAsOrderRejected = () => {
        orderRejected.value = {
            state: order.value.state,
            orderId: order.value.id,
            messageToShow: order.value.payment_attempt_message,
            suggested_payment_method:
                order.value?.suggested_payment_method && Object.keys(order.value.suggested_payment_method)?.length
                    ? order.value.suggested_payment_method
                    : undefined,
            payment_rejected_context: order.value.payment_rejected_context,
            final_cost: order.value.orderInformation?.orderCost?.['final_cost'],
        };
    };

    const extraItemOrder = computed<OrderResumenModel>({
        get: () => $store.getters['extraItems/order'],
        set: (value) => $store.commit('extraItems/order', value),
    });

    const isSelectedOrder = computed<boolean>({
        get: () => $store.getters['orders/isSelectedOrder'],
        set: (value) => $store.commit('orders/isSelectedOrder', value),
    });

    const orders = computed<OrderModel[]>(() => $store.getters['orders/orders']);
    const isPaymentRejected = computed<boolean>(() => order.value?.state === OrderState.PAYMENT_REJECTED);

    const getResume = async (orderId?: number) => {
        return $store.dispatch('orders/getOrderResume', orderId ?? order.value.id);
    };

    // TODO standarize this function to avoid any
    const searchOrderRejected = (orders: OrderModel | OrderModel[]): OrderRejected => {
        const orderRejected: OrderModel | any = Array.isArray(orders)
            ? orders.find((order: OrderModel): boolean => order.state === OrderState.PAYMENT_REJECTED)
            : orders;
        if (!orderRejected) return;
        return {
            state: orderRejected.state,
            orderId: orderRejected.id,
            messageToShow: orderRejected.paymentAttemptsMessage ?? orderRejected?.payment_attempt_message,
            suggested_payment_method:
                orderRejected?.suggested_payment_method && Object.keys(orderRejected.suggested_payment_method)?.length
                    ? orderRejected.suggested_payment_method
                    : undefined,
            payment_rejected_context: orderRejected.payment_rejected_context,
            final_cost: orderRejected.finalCost ?? orderRejected.final_cost,
        };
    };

    const hasOrderRejected = computed<boolean>(() => Boolean(searchOrderRejected(orders?.value)));
    const hasOrderSelected = computed<boolean>(() => Boolean(order.value));

    return {
        order,
        isSelectedOrder,
        getResume,
        ...useOrderRate(),
        extraItemOrder,
        orders,
        orderRejected,
        isPaymentRejected,
        searchOrderRejected,
        hasOrderRejected,
        hasOrderSelected,
        orderDetailAsOrderRejected,
    };
};
