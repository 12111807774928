<template>
    <section class="online-bank-services">
        <h2 class="online-bank-services__title">{{ $t("txt['online-bank-sevices__avalible-platform']") }}</h2>
        <div class="online-bank-services__content">
            <base-loading v-if="isLoading"></base-loading>
            <div v-else-if="!banks.length" class="online-bank-services__no-info">
                <no-content :description="$t('txt.online-bank-services__not-loading')">
                    <icon-bank size="1"></icon-bank>
                </no-content>
                <button @click="fetchBanks">
                    {{ $t("txt['online-bank-services__reloading']") }}
                </button>
            </div>
            <banks :bank-selected="bankSelected" :banks="banks" @on-selected-bank="onSelectBank"></banks>
            <base-card-flat v-if="bankSelected?.id" class="flex-column">
                <h3 class="bank-name" :style="{ color: bankSelected['color'] }">
                    {{ $t('txt.online_bank__service_payments') }} {{ bankSelected['name'] }}
                </h3>
                <horizontal-divider height="0.5rem"></horizontal-divider>
                <p class="bank-content">
                    {{ $t("txt['online_bank__steps']") }}
                </p>
                <lottie-animation :src="animation" height="20rem" />
                <p class="bank-content">{{ $t("txt['online-bank-sevices__modify-schedule-msj']") }}</p>
            </base-card-flat>
        </div>
    </section>
</template>

<script lang="ts">
import { BanksModel } from '@/models/paymentMethods/BanksModel';
import { mapGetters } from 'vuex';
import { logger } from '@/utils/logger';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import HorizontalDivider from '@/components/dividers/HorizontalDivider.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import IconBank from '@/components/icons/IconBank.vue';
import Banks from '@/components/banks/Banks.vue';
import { OnlineBankServiceModel } from '@/models/paymentMethods/OnlineBankServiceModel';
import LottieAnimation from '@/components/animations/LottieAnimation.vue';
import { AnimationEnum } from '@/enums/animationEnum';

export default {
    name: 'OnlineBankServices',
    components: {
        BaseLoading,
        HorizontalDivider,
        BaseCardFlat,
        NoContent,
        IconBank,
        Banks,
        LottieAnimation,
    },
    data() {
        return {
            banks: Array,
            bankSelected: null,
            isLoading: false,
            animation: AnimationEnum.ONLINE_BANK,
        };
    },
    computed: {
        ...mapGetters({
            isMixInstitutionalPayment: 'payments/isMixInstitutionalPayment',
            isMixPaymentTiptiCard: 'payments/isMixPaymentTiptiCard',
            secondPayment: 'payments/secondPayment',
            totalToPay: 'payments/totalToPay',
            baseConfiguration: 'user/baseConfiguration',
        }),
    },
    mounted() {
        this.fetchBanks();
    },
    methods: {
        async fetchBanks(): Promise<void> {
            try {
                this.isLoading = true;
                this.banks = await this.$store.dispatch('payments/banks', true);
            } catch (err) {
                logger('FETCH_BANKS', err);
            } finally {
                this.isLoading = false;
            }
        },
        totalToPayOnlieBankService(): number {
            return this.isMixPaymentTiptiCard ? +this.secondPayment : +this.totalToPay;
        },
        onSelectBank(bank: BanksModel) {
            this.bankSelected = this.bankSelected === bank ? null : bank;
            this.$store.commit('payments/cleanPaymentMethods'); ///*** TODO: delete this line this function when refactor fotter in checkout
            if (!bank) return;
            this.$store.commit(
                'payments/onlineBankServicePayment',
                new OnlineBankServiceModel(this.bankSelected?.id, this.totalToPayOnlieBankService()),
            );
            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-online-bank-services',
                    amount: this.isMixPaymentTiptiCard ? this.secondPayment : this.totalToPay,
                    data: this.bankSelected.name,
                    analyticData: {
                        selected_bank_name: this.bankSelected.name,
                    },
                }),
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import './online-bank-services-payment.scss';
</style>
