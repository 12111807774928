<template>
    <section class="privacy-policy">
        <h1 class="privacy-policy__title">Tipti Shopper</h1>
        <img class="privacy-policy__img" src="@/assets/svg/ic_shopper_shopping.svg" alt="" />
        <h2 class="privacy-policy__title">Política de privacidad</h2>
        <p class="privacy-policy__body">
            <span class="privacy-policy__question">AVISO DE PRIVACIDAD Y POLÍTICAS DE MANEJO DE LA INFORMACIÓN </span>
            (HABEAS DATA) TIPTI S.A. TIPTI S.A. en adelante “TIPTI” de conformidad con la normatividad ecuatoriana, ha
            dispuesto para el trato de los datos personales del usuariode la plataforma TIPTI (Incluye aplicación,
            página web y cualesquiera de los contratos que sean suscritos con TIPTI S.A. o sus filiales), para lo
            anterior seda aviso oportuno al usuario de:<br />
            <span class="privacy-policy__question">Aviso de privacidad: </span> titular de la información acepta de
            manera expresa, voluntaria e informada que los datos consignados en las bases de datos de TIPTI, sean
            recopilados, almacenados para los usos que a continuación se enuncian: (i) Recabar o recolectar los datos
            personales e incorporarlos y almacenarlos en nuestra base de datos, (ii) Ordenar, catalogar,
            clasificar,dividir o separar la información suministrada, (iii) Utilizar los datos suministrados en campañas
            de comunicación, divulgación y promoción u oferta de productos, actividades o servicios desarrollados como
            parte de estrategias internas de la compañía, (iv) Utilizarlos para fines administrativos internos o
            comerciales tales como: estudios de crédito, elaboración y presentación de cotizaciones, referencias
            comerciales de experiencia, investigación de mercados, análisis estadísticos, realización de encuestas sobre
            satisfacción, ofrecimiento o reconocimiento de beneficios propios de nuestro programa de lealtad y servicio
            postventa, (v) Conservar registros históricos de la compañía y mantener contacto con los titulares del dato,
            (vi) Verificar, comprobar o validar los datos suministrados, (vii) Estudiar y analizar la información
            entregada para el seguimiento y mejoramiento de los productos, el servicio y la atención, (vii) Entregar la
            información recolectada a terceros con los que la compañía contrate el almacenamientoy administración de los
            datos personales, bajo los estándares de seguridad y confidencialidad a los cuales TIPTI está obligada según
            lo contenido en las leyes pertinentes, (viii) Transferir los datos personales a cualquier país oservidor en
            otro país, (ix) Comunicar y permitir el acceso a los datos personales suministrados a terceros proveedores
            de servicios de apoyo general y a las personas naturales o jurídicas accionistas de TIPTI, (x) Recabar,
            tener, manejar y utilizar la información recibida por el titular de la información para realizar la
            vinculación como contratista o proveedor, (xi) Recabar, tener, manejar y utilizar la información para
            realizar control y prevención del fraude, control y prevención de lavado de activos yfinanciación del
            terrorismo,(xii) Celebración, ejecución y gestión de las propuestas de negocios y contratos objeto de los
            servicios prestados, (xiii) realizar de conformidad con la ley los reportes a centrales de riesgo por
            incumplimiento de las obligaciones financieras derivadas de la relación comercial. (xiv) Transferir a las
            instituciones del sistema financiero nacional los datos personales suministrados por nuestra parte con el
            fin que sean utilizados para revisar la viabilidad del otorgamiento de una tarjeta de crédito de las
            instituciones, contactarme para tal fin, así como consultar mi información personal y financiera en las
            centrales de riesgo, para determinar 18 mi comportamiento, así como actualizar la información suministrada
            pornuestra parte. Derechos que le asisten al Titular: (i) Conocer, actualizar y rectificar sus datos
            personales recolectados por TIPTI. Este derecho se puede ejercer entre otros frente a datos parciales,
            inexactos, incompletos, fraccionados o que induzcan al error, (ii) Solicitar prueba de la autorización
            otorgada a TIPTI (iii)Ser informado por TIPTI, previa solicitud, respecto del uso que le han dado a los
            datos del titular, (iv) Presentar ante la Superintendencia de Compañías quejas por infracciones a lo
            dispuesto en la ley (v) Revocar en cualquier tiempo la autorización y/o supresión del dato teniendo en
            cuenta los límites legales para ello, (vi) Acceder en forma gratuita a los datos del titular y contenidos en
            la base de datos de TIPTI. Forma de conocer la información del Titular y cómo ejercer los derechos: TIPTI
            presenta a elección del Titular las siguientes formas de ejercer sus derechos de “Habeas Data”
            <br />
            <span class="privacy-policy__question"> a. Atención electrónica:</span> El Titular del dato deberá realizar
            su requerimiento formal a la dirección electrónica care.team@tipti.market previo agotamiento de los
            requisitos de legitimación para el ejercicio deltitular, a saber: (i) Ser titular de la información,
            acreditar la identidad en forma suficiente mediante cualquier medio que TIPTI destine para ello. (ii) Por
            las causas habientes, quienes deberán acreditar tal calidad. (iii) Por el representante/apoderado del
            titular de la información, quien también deberá acreditar tal calidad. (iv) Por estipulación a favor de otro
            o por otro ejercitarán por conjunto de las personas que estén facultadas para representarlos, e igualmente
            se deberá acreditar tal calidad. Los derechos de los menores de edad se ejercitarán por las personas
            encargadas de su representación, e igualmente se deberá acreditar tal calidad. <br />
            <span class="privacy-policy__question">b. Atención escrita:</span> El Titular del dato deberá realizar su
            requerimiento formal a la dirección: Eugenio Espejo S/N vía Tanda, Centro Comercial Plaza del Rancho, bloque
            2, oficina 106, previo agotamiento de los requisitos de legitimación para el ejercicio del titular, según lo
            descrito en el literal a del presente acápite, mediante escrito donde se especifique la dirección donde
            recibirá notificaciones y respuestas. <br />
            <span class="privacy-policy__question"> c. Atención verbal:</span> El Titular del dato deberá realizar su
            requerimiento verbal en la dirección: Eugenio Espejo S/N vía Tanda, Centro Comercial Plaza del Rancho,
            bloque 2, oficina 106, Distrito Metropolitano de Quito, Ecuador, previo agotamiento de los requisitos de
            legitimación para el ejercicio del titular, según lo descrito en el literal a del presente acápite.
            <br />
            <span class="privacy-policy__question"> d. Atención telefónica:</span> El Titular del dato deberá realizar
            su requerimientoa través de la línea telefónica: 1700 227 383 , +593 3826 090 SERVICIO AL CLIENTE - OPCIÓN
            1, ADULTO MAYOR - OPCION 2; vía WhatsApp +593 99-554-0223, previo agotamiento de los requisitos de
            legitimación para el ejercicio del titular, según lo descrito en el literal a del presente acápite. Distrito
            Metropolitano de Quito, 01 de enero de 2018
        </p>
        <h3 class="privacy-policy__question">{{ $t('txt.faqs-shoppers__what_permissions') }}</h3>
        <p class="privacy-policy__body">
            {{ $t('txt.faqs-shoppers__with_the_purpose') }}
        </p>
    </section>
</template>
<script lang="ts">
export default {
    name: 'privacy-policy-shopper',
};
</script>

<style lang="scss" scoped>
@import './privacy-policy-shopper.scss';
</style>
