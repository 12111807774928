<template>
    <div class="subscriptions" v-if="showLeftSide">
        <base-card class="subscriptions__options" :padding="0" :class="{ 'card--no-shadow': isMobile }">
            <section-header color-title="#000" :title="$t('general.subscriptions')" />
            <section class="subscriptions__content">
                <p class="subscriptions__legend">
                    {{ $t('txt.subscription__legend') }}
                </p>
                <base-card class="subscription__skeleton" v-if="isLoading">
                    <base-bone is-show />
                </base-card>
                <template v-for="membership in memberships" :key="membership" v-else>
                    <base-card
                        class="subscription"
                        :padding="0.5"
                        bg-color="#ff9012"
                        @click="currentMembership = membership"
                    >
                        <card-cambrella-membership :membership="membership" />
                        <aside
                            class="subscription__arrow"
                            :class="{ 'subscription__arrow--selected': currentMembership === membership }"
                        >
                            <icon-arrow :size="0.6" />
                        </aside>
                    </base-card>
                </template>
            </section>
        </base-card>
        <template v-if="!isMobile">
            <subscription-cambrellas-benefits v-if="currentMembership" :membership="memberships[0]" />
            <subscription-not-selected v-else />
        </template>
    </div>
    <template v-if="isMobile">
        <subscription-cambrellas-benefits
            v-if="currentMembership"
            :membership="memberships[0]"
            @return="currentMembership = undefined"
        />
    </template>
</template>

<script lang="ts">
import BaseCard from '@/components/cards/BaseCard.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import { useCambrellas } from '@/modules/cambrella/composables/useCambrellas';
import { MembershipType } from '@/modules/cambrella/models/MembershipType';
import { logger } from '@/utils/logger';

import BaseBone from '@/components/skeletons/BaseBone.vue';
import { useImage } from '@/composables/ImageComposable';
import SubscriptionCambrellasBenefits from '@/modules/subscriptions/components/SubscriptionCambrellasBenefits.vue';
import SubscriptionNotSelected from '@/modules/subscriptions/components/SubscriptionNotSelected.vue';
import CardCambrellaMembership from '@/modules/subscriptions/components/CardCambrellaMembership.vue';

class MembershipTypeInterface {}

interface StateInterface {
    showCloseSessionModal: boolean;
    isLoading: boolean;
    currentMembership: MembershipTypeInterface;
    memberships: Array<MembershipTypeInterface>;
}
export default {
    name: 'subscription',
    components: {
        CardCambrellaMembership,
        SubscriptionNotSelected,
        SubscriptionCambrellasBenefits,
        BaseBone,
        IconArrow,
        SectionHeader,
        BaseCard,
    },
    data: (): StateInterface => ({
        showCloseSessionModal: false,
        isLoading: false,
        currentMembership: undefined,
        memberships: [] as MembershipType[],
    }),
    setup() {
        const { cambrellasMembershipTypes, getCurrentMembership } = useCambrellas();
        const { displayDefaultImg, isMobile } = useImage();
        return {
            isMobile,
            cambrellasMembershipTypes,
            getCurrentMembership,
            displayDefaultImg,
        };
    },
    computed: {
        showLeftSide(): boolean {
            if (!this.isMobile) return true;
            return !this.currentMembership;
        },
    },
    methods: {
        async getMembershipTypes(): Promise<void> {
            try {
                this.isLoading = true;
                this.getCurrentMembership();
                this.memberships = await this.cambrellasMembershipTypes();
                if (this.isMobile || !this.memberships.length) return;
                this.currentMembership = this.memberships[0];
            } catch (err) {
                logger('err');
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted() {
        this.getMembershipTypes();
    },
};
</script>
<style scoped lang="scss">
@import 'subscriptions';
</style>
