<script lang="ts" setup>
import BaseModal from '../baseModal/BaseModal.vue';
import BtnOutline from '@/components/buttons/BtnOutLine.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { logger } from '@/utils/logger';
import { ref } from 'vue';
import { useApp } from '@/composables/useApp';
import { useUser } from '@/composables/useUser';

defineEmits<(e: 'on-close') => void>();
const { $store } = useApp();
const { profile: user } = useUser();
const isLoading = ref(false);

const cleanStorage = (): void => {
    localStorage.clear();
    caches.keys().then(function (keyList) {
        return Promise.all(
            keyList.map(function (key) {
                return caches.delete(key);
            }),
        );
    });
    window.location.href = '/';
};

const logout = async (): Promise<void> => {
    try {
        if (isLoading.value) return;
        isLoading.value = true;
        await $store.dispatch('user/deactivateAccount', {
            clientId: user.value.id,
            deactivate: false,
            reason: null,
        });
        cleanStorage();
    } catch (err) {
        logger('CLOSE_SESSION', err);
        /// Use to intercept forbiden error, in case of token is deleted and force to redirect login screen
        if (err?.status === 403) {
            cleanStorage();
            return;
        }
    }
};
</script>
<template>
    <base-modal :title="$t('txt.setting__want-close-session')" @on-close="isLoading ? null : $emit('on-close')">
        <section class="close-session-container close-session-container__buttons">
            <btn-outline
                :is-disabled="isLoading"
                :is-loading="isLoading"
                :txt="$t('cta.close-session')"
                bg-color="#FFF"
                color="#FF9012"
                @click="isLoading ? null : logout()"
            />
            <btn-solid
                :is-disabled="isLoading"
                :txt="$t('cta.no-return')"
                @click="isLoading ? null : $emit('on-close')"
            />
        </section>
    </base-modal>
</template>

<style lang="scss" scoped>
@import './close-session-modal.scss';
</style>
