class ProgramSubscription {
    readonly id: number;
    readonly subscriberIdentifier: string;
    readonly subscriberName: string;

    constructor(id: number, subscriberIdentifier: string, subscriberName: string) {
        this.id = id;
        this.subscriberIdentifier = subscriberIdentifier;
        this.subscriberName = subscriberName;
    }
}
const toProgramSubscription = (data: object) =>
    data ? new ProgramSubscription(data['id'], data['subscriber_identifier'], data['subscriber_name']) : null;

export { ProgramSubscription, toProgramSubscription };
