import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0eb1fe8f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "credit-card-information" }
const _hoisted_2 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    ($props.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "credit-card-information__icon",
          alt: $props.name,
          src: $props.icon
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.numberInformation, (key, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (isNaN(key))
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["credit-card-information__dot", {
                    'credit-card-information__dot--space': index === 3 || index === 7 || index === 11,
                }])
            }, null, 2))
          : (_openBlock(), _createElementBlock("p", {
              key: 1,
              class: _normalizeClass(["credit-card-information__number", {
                    'credit-card-information__dot--space': index === 3 || index === 7 || index === 11,
                }])
            }, _toDisplayString(key), 3))
      ], 64))
    }), 128))
  ]))
}