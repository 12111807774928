<template>
    <BackgroundWithTransition :is-active="showModalBill" content-centered @on-bg-click="saveSelection">
        <TheModalContent class="modal-bills-checkout phone-full-page">
            <template #header>
                <BaseHeader
                    :text="$t('txt.checkout__enter-billing-data')"
                    :text-size="24"
                    with-default-close
                    @on-close="showModalBill = false"
                />
            </template>
            <bills
                :is-form-showing="isAddingBillingInfo"
                show-check
                show-modal
                @on-check="select"
                @on-billing-form-showing="isAddingBillingInfo = true"
            />
        </TheModalContent>
    </BackgroundWithTransition>
</template>

<script lang="ts" setup>
import Bills from '@/views/bills/Bills.vue';
import { useBill } from '@/views/bills/composableBill';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';
import TheModalContent from '@/modules/core/components/layouts/TheModalContent.vue';
import BaseHeader from '@/modules/core/components/headers/HeaderWithAction.vue';

defineOptions({
    name: 'BillsCheckout',
});

const emit = defineEmits<(e: 'close-modal-bill-checkout') => void>();
const { select, isAddingBillingInfo, showModalBill, saveSelection } = useBill();
</script>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.modal-bills-checkout {
    border-radius: style.$radius-x-lg;

    @include style.phone {
        padding: 0;
    }
}
</style>
