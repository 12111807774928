class CashWirePaymentModel {
    cash: CashPaymentModel;
    wireTransfer: WireTransferPaymentModel;
    constructor(cash?: CashPaymentModel, wireTransfer?: WireTransferPaymentModel) {
        this.cash = cash;
        this.wireTransfer = wireTransfer;
    }
}

class CashPaymentModel {
    cashToPay: number; /// *** total cash to pay from actual order;
    cashAmount: number; /// *** cash marked by user. It should be equal or over [cashToPay]
    needChange: boolean; /// *** bool to set if user need change

    constructor(cashToPay: number, cashAmount: number, needChange: boolean) {
        this.cashToPay = cashToPay;
        this.cashAmount = cashAmount;
        this.needChange = needChange;
    }
}

class WireTransferPaymentModel {
    account: number;
    photo: ImageBitmap;
    type: string;

    constructor(account: number, photo: ImageBitmap, type: string) {
        this.account = account;
        this.photo = photo;
        this.type = type;
    }
}

export { WireTransferPaymentModel, CashPaymentModel, CashWirePaymentModel };
