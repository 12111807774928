import mutations from './mutations';
import getters from './getters';
import { CartTypes } from '@/modules/core/types/CartType';

interface StateInterface {
    cartType: CartTypes;
    showCart: boolean;
    currentRoutePath: string;
}

const state: StateInterface = {
    cartType: CartTypes.main,
    showCart: false,
    currentRoutePath: '',
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
