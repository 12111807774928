<template>
    <ThePressRoom />
    <div class="the-news__content-page">
        <ThePressReleases :title="$t('general.press-releases')" type-press-releases="PRESS_RELEASE" />
        <ThePressReleases :title="$t('general.news-in-the-med')" type-press-releases="MEDIA_NEWS" />
        <BrandArtwork />
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useSeoMeta } from '@unhead/vue';
import ThePressRoom from '@/views/newsPress/components/ThePressRoom.vue';
import BrandArtwork from '@/views/newsPress/components/BrandArtwork.vue';
import ThePressReleases from '@/views/newsPress/components/ThePressReleases.vue';

defineOptions({
    name: 'News',
});

const { t } = useI18n();
useSeoMeta({
    title: t('seo.news'),
    description: t('txt.news__we-are-building'),
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.the-news {
    &__content-page {
        max-width: 1500px;
        width: 100%;
        padding: 0 2%;
        margin: 0 auto;
        box-sizing: border-box;

        @include style.phone {
            padding: 0;
        }
    }
}
</style>
