import { ProductModel } from '@/models/product/ProductModel';
import { ListDetailItemModel } from '@/models/list/ListDetailModel';
import { urlToFile } from '@/utils/picturesUtils';
import { logger } from '@/utils/logger';
import { mapGetters } from 'vuex';
import { Sections } from '@/enums/sectionsOrigin';
import { CartTypes } from '@/modules/core/types/CartType';
import { useCatalogFicoaStore } from '@/store/useCatalogFicoaStore';
import { mapActions, mapState, storeToRefs } from 'pinia';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useProductStore } from '@/store/useProductStore';

/// *** To implement this you have to put isSearchByStockItem as prop to avoid vue warnings
export default {
    computed: {
        ...mapState(useCatalogFicoaStore, {
            ficoaProducts: 'products',
        }),
        ...mapGetters({
            products: 'cart/products',
            productIdActiveBlur: 'product/productIdActiveBlur',
            isCategoryFromList: 'lists/isCategoryFromList',
            listIdToAddFromCategories: 'lists/listIdToAddFromCategories',
            productExtraItems: 'extraItems/products',
            productLists: 'lists/listDetail',
            dataOrder: 'orders/orderDetailSelected',
            retailerIdsWithtimeDelivery: 'checkout/retailerIdsWithtimeDelivery',
            isFromBannerInSection: 'promotions/isBannerInSection',
            bannerGoal: 'promotions/bannerGoal',
            listCart: 'lists/localCart',
            countrySelected: 'external/countrySelected',
            cartType: 'app/cartType',
            isAuth: 'user/isAuth',
            retailerSelected: 'retailers/retailerSelected',
        }),
        currency(): string {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        isExtraItemCart(): boolean {
            return this.cartType === CartTypes.extraItem;
        },
        updateAmount(): number {
            const actualProduct: ProductModel = this.isExtraItemCart
                ? this.productExtraItems.find((product: ProductModel) => {
                      return product.id === this.productData.id;
                  })
                : this.products?.find((product: ProductModel) => {
                      return product.id === this.productData.id;
                  });
            const stockItem: ProductModel = this.products.find((product: ProductModel) => {
                return product.id === this.productData.stockItemId;
            });
            const productList: ListDetailItemModel = this.productLists.find((product: ListDetailItemModel) => {
                return product.id === this.productData.id;
            });
            const productAddedToListCart = this.listCart.find((item) => {
                return item.product.id === this.productData.id;
            });
            const quantityList = productAddedToListCart
                ? productAddedToListCart?.product?.productQuantity
                : productList?.quantity;
            if (this.isCatalogFicoa)
                return (
                    this.ficoaProducts?.find((product: ProductModel) => {
                        return product.id === this.productData.id;
                    })?.productQuantity ?? 0
                );
            if (this.isSearchByStockItem) return stockItem?.productQuantity ?? 0;
            return this.isCategoryFromList ? quantityList ?? 0 : actualProduct?.productQuantity ?? 0;
        },
        productFromServer(): boolean {
            return this.isExtraItemCart
                ? this.productExtraItems.find((product: ProductModel) => {
                      return product.id === this.productData.id;
                  })
                : this.products?.find((product: ProductModel) => {
                      return product.id === this.productData.id;
                  });
        },
    },
    methods: {
        ...mapActions(useProductStore, ['suggestionsForUnAvailableProducts', 'setItemHighDemand', 'setItemOutOfStock']),
        cleanProductIdActiveBlur(): void {
            this.$store.commit('product/productIdActiveBlur', null);
        },
        async updateServeNoteImageToFile(): Promise<void> {
            this.product.noteToShopperPhotoFile =
                !this.isExtraItemCart &&
                !this.isCategoryFromList &&
                this.productFromServer?.noteToShopperPhoto &&
                !this.productFromServer?.noteToShopperPhotoFile
                    ? await urlToFile(this.productFromServer?.noteToShopperPhoto)
                    : this.productFromServer?.noteToShopperPhotoFile;
        },
        async setDetailProduct(): Promise<void> {
            const product = { ...this.productData };
            product.productQuantity = this.updateAmount;
            product.replacement = this.productFromServer?.replacement;
            product.note = this.productFromServer?.note ?? '';
            product.noteToShopperPhoto = this.productFromServer?.noteToShopperPhoto;
            product.noteToShopperPhotoFile = this.productFromServer?.noteToShopperPhotoFile;
            if (this.deactivatedProduct?.id) product.deactivateProductIdReference = this.deactivatedProduct;
            this.$store.commit('product/productSelected', product);
        },
        async openDetailProduct() {
            const { showModalProductsReplacements, isHighDemand, isOutOfStock } = storeToRefs(useProductStore());
            if (isHighDemand.value || isOutOfStock.value) showModalProductsReplacements.value = false;
            if (this.isForReplacement) return;
            if (this.isCategoryFromList) return;
            this.cleanProductIdActiveBlur();

            await Promise.allSettled([
                this.setDetailProduct(),
                this.$router.push({
                    name: appRoutesMap.product,
                    params: {
                        retailer: this.retailerSelected.name,
                        name: this.productData?.name,
                    },
                    replace: this.$route.name === appRoutesMap.product,
                }),
            ]);
        },

        handleSearchedPhrase(): string {
            let searchedPhrase: string;
            if (this.section === 'YOUR_PRODUCTS') {
                searchedPhrase = this.$store.getters['search/query'];
                this.$store.commit('search/searchOriginSection', Sections.HOMES_RETAILER);
            }
            //+++ handle when page is reloaded
            if (this.$route.fullPath.includes('searchOn')) {
                searchedPhrase = this.$route.fullPath.split('searchOn=')[1]?.split('&')[0];
                this.$store.commit('search/query', searchedPhrase);
            } else if (this.$route.fullPath.includes('search=')) {
                searchedPhrase = this.$route.fullPath.split('search=')[1]?.split('&')[0];
                this.$store.commit('search/query', searchedPhrase);
            } else if (this.$route.fullPath.includes('search/')) {
                searchedPhrase = this.$route.fullPath.split('search/')[1];
                this.$store.commit('search/query', searchedPhrase);
            } else if (this.$store.getters['search/query'].length) {
                searchedPhrase = this.$store.getters['search/query'];
            }
            return searchedPhrase;
        },
        async callActions(deactivatedProductId?: number): Promise<void> {
            try {
                const searchedPhrase: string = this.handleSearchedPhrase();
                if (this.isSearchByStockItem) {
                    if (!this.isAuth) return this.$store.commit('user/requireAuth', true);
                    this.$store.commit('product/productIdActiveBlur', this.productData.stockItemId);
                    if (this.updateAmount !== 0) return;
                    const productData = this.createProductData(this.productData, 1);
                    await this.$store.dispatch('cart/addEditProduct', {
                        product: productData,
                        screen: this.$route.path,
                        section: searchedPhrase ? this.$store.getters['search/searchOriginSection'] : 'YOUR_PRODUCTS',
                        searchedPhrase,
                    });
                    this.$store.commit('cart/lastProductsAdded', productData);
                    return this.$store.dispatch('cart/fetchCart');
                }
                this.product.replacement = this.productFromServer?.replacement;
                this.product.note = this.productFromServer?.note ?? '';
                this.product.noteToShopperPhoto = this.productFromServer?.noteToShopperPhoto ?? '';
                await this.updateServeNoteImageToFile();

                this.$store.commit('product/productIdActiveBlur', this.product.uuid);
                if (this.updateAmount > 0) return;
                this.product.productQuantity = 1;
                if (this.isCatalogFicoa) return;
                if (!this.isAuth) return this.$store.commit('user/requireAuth', true);
                if (this.isExtraItemCart) {
                    this.$store.commit('extraItems/products', this.product);
                    this.$store.dispatch('segment/productAdded', {
                        screen: this.$route.path,
                        product: this.product,
                        section: searchedPhrase
                            ? this.$store.getters['search/searchOriginSection']
                            : this.section === 'x_selling'
                              ? this.section
                              : 'EXTRA_ITEMS',
                        searchedPhrase,
                    });
                    return;
                }
                if (this.isCategoryFromList) {
                    return this.$store.commit('lists/localCart', {
                        listIds: [this.listIdToAddFromCategories],
                        product: this.product,
                    });
                }

                const {
                    isHighDemand,
                    isOutOfStock,
                    lastItemAdded,
                    showModalProductsReplacements,
                    itemAddedFromSuggestion,
                } = storeToRefs(useProductStore());
                if ((isHighDemand.value || isOutOfStock.value) && itemAddedFromSuggestion.value) {
                    if (lastItemAdded.value) this.$store.commit('cart/removeProductFromProducts', lastItemAdded.value);
                    lastItemAdded.value = this.product;
                    this.$store.commit('cart/products', lastItemAdded.value);
                    showModalProductsReplacements.value = true;
                    return;
                }

                await this.$store.dispatch('cart/addEditProduct', {
                    product: this.product,
                    deactivatedProductId,
                    screen: this.$route.path,
                    section: searchedPhrase ? this.$store.getters['search/searchOriginSection'] : this.section,
                    searchedPhrase,
                });
                const addedToProductsOnDemand = await this.handleSuggestedReplacements(this.product);
                if (!addedToProductsOnDemand) this.$store.commit('cart/lastProductsAdded', this.product);
                if (
                    addedToProductsOnDemand &&
                    (isHighDemand.value || isOutOfStock.value) &&
                    !itemAddedFromSuggestion.value
                ) {
                    if (lastItemAdded.value) this.$store.commit('cart/removeProductFromProducts', lastItemAdded.value);
                    showModalProductsReplacements.value = true;
                }

                if (this.fetchCart)
                    this.$store.dispatch('cart/fetchCart', { retailerIds: this.retailerIdsWithtimeDelivery });
                else this.$store.commit('cart/products', this.product);

                if (this.bannerGoal) await this.fetchDiscountActivationProgressBar();
            } catch (err) {
                if (err === 409) {
                    this.product.available = false;
                    const { results } = await this.suggestionsForUnAvailableProducts({
                        stockItemId: this.product.stockItemId,
                        size: 40,
                    });
                    this.setItemOutOfStock({
                        productAdded: this.product,
                        replacementSuggestion: results ?? [],
                    });
                    return;
                }
                this.snackBarMessage = this.$t('alerts.product_add_error');

                this.showSnackBar = true;
                this.cleanProductIdActiveBlur();
                logger('CALL_ACTIONS', err);
            }
        },
        async handleSuggestedReplacements(product: ProductModel): Promise<boolean> {
            try {
                if (!product.highDemand || product.replacement) return false;
                const { results } = await this.suggestionsForUnAvailableProducts({
                    stockItemId: product.stockItemId,
                    size: 40,
                });
                this.setItemHighDemand({
                    productAdded: product,
                    replacementSuggestion: results ?? [],
                });
                return true;
            } catch (err) {
                logger('HANDLE_SUGGESTED_REPLACEMENTS', err);
            }
        },
        async updateProductAmount(): Promise<void> {
            try {
                this.product.productQuantity = +this.quantityUpdated === 0 ? 1 : Math.abs(this.quantityUpdated);
                try {
                    this.isLoading = true;
                    if (this.isCatalogFicoa) return;
                    if (this.isExtraItemCart) return this.$store.commit('extraItems/products', this.product);

                    if (this.isCategoryFromList)
                        return this.$store.commit('lists/localCart', {
                            listIds: [this.listIdToAddFromCategories],
                            product: this.product,
                        });

                    const { isHighDemand, isOutOfStock, lastItemAdded } = storeToRefs(useProductStore());
                    if (isHighDemand.value || isOutOfStock.value) {
                        if (lastItemAdded.value)
                            this.$store.commit('cart/removeProductFromProducts', lastItemAdded.value);
                        this.$store.commit('cart/products', this.product);
                        lastItemAdded.value = this.product;
                        return;
                    }
                    await this.$store.dispatch('cart/addEditProduct', {
                        product: this.product,
                        screen: this.$route.path,
                        section: this.section,
                    });
                    if (this.mustFetchCart)
                        this.$store.dispatch('cart/fetchCart', { retailerIds: this.retailerIdsWithtimeDelivery });
                    else this.$store.commit('cart/products', this.product);

                    if (this.bannerGoal) await this.fetchDiscountActivationProgressBar();
                } catch (err) {
                    logger('UPDATE_AMOUNT_WITH_BACK', err);
                } finally {
                    this.isLoading = false;
                }
            } catch (err) {
                logger('UPDATE_AMOUNT', err);
            }
        },
    },
};
