<template>
    <base-card-flat
        class="gift-card"
        :style="{
            'background-image': `url(${card?.card_picture})`,
        }"
        border-radius="15px"
        padding="0"
    >
        <p class="gift-card__text">{{ $t('txt.gift-card-available-balance') }}</p>
        <p class="gift-card__balance" v-currency="card?.available_amount"></p>
    </base-card-flat>
</template>

<script lang="ts" setup>
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import type { PropType } from 'vue';
import { computed } from 'vue';
import { GiftCardDTO } from '@/views/wallet/WalletModels';

defineOptions({
    name: 'GiftCard',
});

const props = defineProps({
    card: {
        type: Object as PropType<GiftCardDTO>,
        required: true,
    },
    isExpired: {
        type: Boolean,
        default: false,
    },
});

const color = computed(() => props.card.color_text ?? '#707070');
const showGrayscale = computed(() => (props.isExpired ? 'grayscale(1)' : 'grayscale(0)'));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.gift-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: top;
    background-size: cover;
    border-radius: inherit;
    width: 26rem;
    height: 13rem;
    filter: v-bind(showGrayscale);
    @include tablet {
        width: 20rem;
        height: 10rem;
    }

    &__text {
        @include body1;
        font-size: 0.7rem;
        color: v-bind(color);
        margin-right: $margin-lg;
        margin-top: $margin-md;
    }

    &__balance {
        @include body1;
        font-size: 1.4rem;
        color: v-bind(color);
        font-weight: 600;
        margin-right: $margin-lg;
    }
}
</style>