import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d23cb92"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "stories-bar"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "prime-icon" }
const _hoisted_4 = ["alt", "src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_stories = _resolveComponent("skeleton-stories")!
  const _component_icon_prime_star = _resolveComponent("icon-prime-star")!
  const _component_vertical_divider = _resolveComponent("vertical-divider")!
  const _component_carousel_tipti = _resolveComponent("carousel-tipti")!
  const _component_story_page = _resolveComponent("story-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.stories?.length && !$data.storyIsEmpty)
      ? (_openBlock(), _createBlock(_component_skeleton_stories, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.stories?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.featuredStory)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["story-miniature", { viewed: _ctx.featuredStory.viewed }])
              }, [
                _createElementVNode("img", {
                  src: _ctx.featuredStory.iconStory,
                  alt: "",
                  loading: "lazy",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.goToStory(_ctx.featuredStory)))
                }, null, 8, _hoisted_2),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_icon_prime_star, {
                    "icon-name": _ctx.$t('icon.featured'),
                    size: 0.8,
                    color: "#FF9012"
                  }, null, 8, ["icon-name"])
                ])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.featuredStory)
            ? (_openBlock(), _createBlock(_component_vertical_divider, {
                key: 1,
                height: "74px",
                width: "3px"
              }))
            : _createCommentVNode("", true),
          (_ctx.stories?.length)
            ? (_openBlock(), _createBlock(_component_carousel_tipti, {
                key: 2,
                "has-margin": false,
                "is-last-page": $data.isLastPage,
                "is-loading": $data.isLoading,
                onReachedBeforeEnd: $options.updateStories
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stories, (story, index) => {
                    return (_openBlock(), _createElementBlock("img", {
                      key: index,
                      class: _normalizeClass(["story-miniature cursor-pointer", { viewed: story.viewed }]),
                      alt: story.title,
                      src: story.iconStory,
                      loading: "lazy",
                      onClick: ($event: any) => ($options.goToStory(story, index)),
                      onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.displayDefaultImg && $options.displayDefaultImg(...args)))
                    }, null, 42, _hoisted_4))
                  }), 128))
                ]),
                _: 1
              }, 8, ["is-last-page", "is-loading", "onReachedBeforeEnd"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    ($data.showStory)
      ? (_openBlock(), _createBlock(_component_story_page, {
          key: 2,
          onOnClose: $options.closeStoryPage
        }, null, 8, ["onOnClose"]))
      : _createCommentVNode("", true)
  ], 64))
}