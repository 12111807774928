class TiptiCardGiftCardsModel {
    amount: string;
    date: Date;
    buyer: string;
    code: string;
    activated: boolean;
    isGiftCard: boolean;

    constructor(amount: string, date: Date, buyer: string, activated: boolean, code?: string, isGiftCard?: boolean) {
        this.amount = amount;
        this.date = date;
        this.buyer = buyer;
        this.activated = activated;
        this.code = code;
        this.isGiftCard = isGiftCard;
    }
}
const toTiptiCardGiftCardsModel = (data: object): TiptiCardGiftCardsModel => {
    return data
        ? new TiptiCardGiftCardsModel(
              data['amount'] ?? '',
              data['date'] ?? '',
              `${data['buyer']?.['user']?.['first_name'] ?? ''} ${data['buyer']?.['user']?.['last_name']}`,
              data['activated'] ?? false,
              data['code'] ?? '',
              data['as_gift'] ?? false,
          )
        : null;
};

export { TiptiCardGiftCardsModel, toTiptiCardGiftCardsModel };
