<template>
    <section class="modal-base">
        <slot name="header" />
        <main class="main_content__body">
            <slot />
        </main>
        <slot name="footer" />
    </section>
</template>

<style lang="scss" scoped>
@import 'the-modal-content';
</style>
