import { ResponsePurchaseModel } from '@/models/checkout/ResponsePurchaseModel';
import DeliveryAddressModel from '@/models/checkout/DeliveryAddressModel';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { CartModel } from '@/models/cart/CartModel';
import store from '@/store';

export default {
    timeDelivery(state): Array<TimeDeliveryModel> {
        return state.timeDelivery;
    },
    retailerIdsWithtimeDelivery(state): string {
        const _retailerIdsWithUniqueTimeDelivery =
            state.stockItemTimeDelivery
                ?.map((time: TimeDeliveryModel) => time.retailerId)
                .join(',')
                ?.replace(/,\s*$/, '') ?? undefined;
        const _retailerIdsWithDelivery =
            state.timeDelivery
                ?.map((time: TimeDeliveryModel) => time.retailerId)
                .join(',')
                ?.replace(/,\s*$/, '') ?? undefined;
        if (!_retailerIdsWithUniqueTimeDelivery?.length && !_retailerIdsWithDelivery?.length) return undefined;
        if (!_retailerIdsWithUniqueTimeDelivery?.length) return _retailerIdsWithDelivery ?? undefined;
        if (!_retailerIdsWithDelivery?.length) return _retailerIdsWithUniqueTimeDelivery ?? undefined;
        return (
            [_retailerIdsWithUniqueTimeDelivery, _retailerIdsWithDelivery].join(',')?.replace(/,\s*$/, '') ?? undefined
        );
    },
    retailerNamesWithtimeDelivery(state): string {
        return (
            state.timeDelivery
                ?.map((time: TimeDeliveryModel) => time.retailerName)
                .join(',')
                ?.replace(/,\s*$/, '') ?? undefined
        );
    },
    deliveryAddress(state): DeliveryAddressModel {
        return state.deliveryAddress;
    },
    isElder(state): boolean {
        return state.isElder;
    },
    isAutomaticReplacements(state): boolean {
        return state.isAutomaticReplacements;
    },
    instructions(state): string {
        return state.instructions;
    },
    phone(state): string {
        return state.phone;
    },
    email(state): string {
        return state.email;
    },
    responsePurchase(state): ResponsePurchaseModel {
        return state.responsePurchase;
    },
    stockItemTimeDelivery(state): Array<TimeDeliveryModel> {
        return state.stockItemTimeDelivery;
    },
    isSelectedSchedules(state): boolean {
        const _normalDeliverySchedule = state.timeDelivery;
        const _uniqueDeliverySchedules = state.stockItemTimeDelivery;
        if (!_uniqueDeliverySchedules?.length) return _normalDeliverySchedule?.length;
        const _myActualCart: CartModel = store.getters['cart/cart'];
        if (!_myActualCart.retailers?.length) return false;
        const _allIdInUniqueDelivery = (_uniqueDeliverySchedules as Array<TimeDeliveryModel>).map(
            (element) => element.stockItemId,
        );
        const _allRetailers = _myActualCart.retailers.map((retailer) => {
            if (retailer.products.some((product) => product.uniqueDelivery)) {
                const _isSelectedSchedule = retailer.products.some((product) =>
                    _allIdInUniqueDelivery.includes(product.id),
                );
                /// Case 1 only one product with unique delivery
                if (_isSelectedSchedule && retailer.products.length == 1) {
                    return true;
                }
                /// Case 2 only more products with unique delivery
                if (
                    _isSelectedSchedule &&
                    retailer.products.every((product) => _allIdInUniqueDelivery.includes(product.id))
                ) {
                    return true;
                }
                /// Case 3 mix -> normal and unique delivery products
                const _isSelectedNormalTimeDelivery = (_normalDeliverySchedule as Array<TimeDeliveryModel>).some(
                    (time) => time.retailerId === retailer.id,
                );
                return _isSelectedNormalTimeDelivery;
            }
            return _normalDeliverySchedule?.length > 0;
        });
        return _allRetailers.some((value) => value);
    },
};
