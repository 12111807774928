import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    list: undefined,
    disableButtonContinuetoCheckout: true,
    subgroups: [],
    listDetail: [],
    recipeAds: [],
    recipeDetail: [],
    localCart: [],
    isCategoryFromList: false,
    listIdToAddFromCategories: undefined,
    isFromDeeplink: false,
    activeOption: 'Mis Listas',
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
