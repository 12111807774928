<template>
    <section-header :title="$route.query?.name" has-back @on-back="$router.go(-1)" />
    <featured-products-grid :origin-section="Sections.MY_LISTS" />
</template>

<script lang="ts" setup>
import { Sections } from '@/enums/sectionsOrigin';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import FeaturedProductsGrid from '@/components/category/featuredProducts/FeaturedProductsGrid.vue';
import { useApp } from '@/composables/useApp';

defineOptions({
    name: 'list-category-featured-products',
});

const { $route, $router } = useApp();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
</style>
