<template>
    <base-card class="flex-column" padding="0">
        <section-header :has-back="isMobile || showBillingForm" :title="$t('general.billing-data')" @on-back="onBack" />
        <section class="billing-container">
            <bills :is-form-showing="showBillingForm" @on-billing-form-showing="showBillingForm = true" />
        </section>
    </base-card>
</template>

<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import Bills from '@/views/bills/Bills.vue';
import { ref } from 'vue';
import { useApp } from '@/composables/useApp';

const { isMobile, $router } = useApp();
const showBillingForm = ref(false);
const onBack = () => {
    if (showBillingForm.value) showBillingForm.value = false;
    else $router.back();
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.billing-container {
    display: flex;
    padding: $padding-lg;
    flex-direction: column;
    @include tablet {
        padding-bottom: $padding-lg;
    }
    @include phone {
        padding: 0;
    }
}
</style>
