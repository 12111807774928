import { useStore } from 'vuex';
import { CartTypes } from '@/modules/core/types/CartType';
import { computed } from 'vue';
import { useCart } from '@/composables/useCart';

export const useCartType = () => {
    const $store = useStore();
    const { fetchQuickCart } = useCart();
    const isMainCart = computed(() => $store.state.app.cartType === CartTypes.main);
    const isExtraItemCart = computed(() => $store.state.app.cartType === CartTypes.extraItem);
    const idFicoaCart = computed(() => $store.state.app.cartType === CartTypes.ficoa);
    const showCart = computed(() => $store.getters['cart/showCart']);

    const changeToMainCart = () => {
        if (isMainCart.value) return;
        $store.commit('app/cartType', CartTypes.main);
        return fetchQuickCart();
    };

    const changeToExtraItemsCart = () => {
        if (isExtraItemCart.value) return;
        $store.commit('app/cartType', CartTypes.extraItem);
        $store.commit('extraItems/clearProducts');
    };

    const toggleShowCart = () => {
        $store.commit('cart/toggleShowCart');
    };

    const toogleFicoaCart = () => {
        if (!idFicoaCart.value) $store.commit('app/cartType', CartTypes.ficoa);
        toggleShowCart();
    };

    return {
        showCart,
        isMainCart,
        toggleShowCart,
        isExtraItemCart,
        changeToMainCart,
        toogleFicoaCart,
        changeToExtraItemsCart,
    };
};
