import { ref, shallowRef } from 'vue';
import { useStore } from 'vuex';
import { ProductModel } from '@/models/product/ProductModel';

export const useRecommendationsProducts = () => {
    const productsRecommended = shallowRef<ProductModel[]>([]);
    const lastUsedItemId = ref(0);
    const lastUsedItemIds = ref<number[]>([]);
    const $store = useStore();

    const getProductForHome = async (id_item: number) => {
        if (!id_item || id_item === lastUsedItemId.value || lastUsedItemIds.value.includes(id_item)) return;
        lastUsedItemId.value = id_item;
        const response = await $store.dispatch('product/getProductForYourHome', id_item);
        if (response) productsRecommended.value = response;
    };

    const closeRecommendationsProducts = () => {
        if (lastUsedItemId.value && !lastUsedItemIds.value.includes(lastUsedItemId.value))
            lastUsedItemIds.value.push(lastUsedItemId.value);
        productsRecommended.value = [];
    };

    return {
        productsRecommended,
        getProductForHome,
        closeRecommendationsProducts,
    };
};
