<template>
    <div class="icon-with-text">
        <slot name="icon"></slot>
        <h3>{{ text }}</h3>
    </div>
</template>

<script lang="ts">
export default {
    name: 'icon-and-text',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/index.scss';
.icon-with-text {
    display: flex;
    align-items: center;
    padding-left: $padding-xlg;
    & h3 {
        @include body2;
        margin-left: $margin-sm;
        @include tablet {
            font-size: 12px;
        }
    }
}
</style>
