import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.bank'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 84 84",
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M80.1479 76.3257H3.81762C1.7087 76.3257 0.00012207 78.0343 0.00012207 80.145C0.00012207 82.2522 1.7087 83.9607 3.81762 83.9607H80.1461C82.255 83.9607 83.9636 82.2522 83.9636 80.145C83.9618 78.0343 82.2532 76.3257 80.1479 76.3257Z",
        fill: $props.color ? $props.color : '#C7C7C7'
      }, null, 8, _hoisted_1),
      _createElementVNode("path", {
        d: "M8.5877 66.4013C6.47878 66.4013 4.7702 68.1099 4.7702 70.2188C4.7702 72.3278 6.47878 74.0381 8.5877 74.0381H75.376C77.4849 74.0381 79.1935 72.3278 79.1935 70.2188C79.1935 68.1099 77.4849 66.4013 75.376 66.4013H74.4234V32.051H75.376C76.4322 32.051 77.283 31.1968 77.283 30.1441C77.283 29.0914 76.4287 28.2371 75.376 28.2371H8.5877C7.53324 28.2371 6.68074 29.0914 6.68074 30.1441C6.68074 31.1968 7.53503 32.051 8.5877 32.051H9.54207V66.3996H8.5877V66.4013ZM66.7884 32.051V66.3996H55.3395V32.051H66.7884ZM47.7045 32.051V66.3996H36.2556V32.051H47.7045ZM17.1735 32.051H28.6224V66.3996H17.1735V32.051Z",
        fill: $props.color ? $props.color : '#C7C7C7'
      }, null, 8, _hoisted_2),
      _createElementVNode("path", {
        d: "M3.81757 24.8057H80.146C80.1585 24.8057 80.1728 24.8057 80.1835 24.8057C82.2943 24.8057 84.001 23.0971 84.001 20.9882C84.001 19.3118 82.9216 17.8909 81.4221 17.3762L43.551 0.335103C42.5555 -0.111701 41.417 -0.111701 40.4198 0.335103L2.25197 17.5085C0.609513 18.2484 -0.293031 20.032 0.0858589 21.7906C0.462962 23.5493 2.01784 24.8057 3.81757 24.8057Z",
        fill: $props.color ? $props.color : '#C7C7C7'
      }, null, 8, _hoisted_3)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "iconColor"]))
}