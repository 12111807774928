import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  d: "M18.6309 2.59417C18.1169 2.03403 17.4912 1.58784 16.7941 1.28436C16.0971 0.98087 15.3442 0.826831 14.5839 0.832169C13.4289 0.82713 12.3064 1.21503 11.4009 1.93217C10.9118 2.31532 10.4801 2.76657 10.1189 3.27217C9.7566 2.76625 9.32351 2.31498 8.83292 1.93217C7.92854 1.21582 6.80762 0.827958 5.65392 0.832169C4.89352 0.826753 4.14044 0.980752 3.44321 1.28424C2.74599 1.58772 2.1201 2.03396 1.60592 2.59417C0.546916 3.78083 -0.0261416 5.32291 0.000916297 6.91317C0.0475929 8.746 0.761188 10.4989 2.00792 11.8432C3.55311 13.5476 5.23322 15.1248 7.03192 16.5592C7.73192 17.1522 8.51692 17.8252 9.33192 18.5412C9.54844 18.7305 9.82629 18.8348 10.1139 18.8348C10.4015 18.8348 10.6794 18.7305 10.8959 18.5412C11.7149 17.8252 12.5049 17.1522 13.2009 16.5582C14.9996 15.1242 16.6798 13.5474 18.2249 11.8432C19.4717 10.4989 20.1853 8.74602 20.2319 6.91317C20.2601 5.32345 19.6885 3.78143 18.6309 2.59417Z"
}
const _hoisted_2 = {
  key: 1,
  d: "M75 135.135C71.6216 135.135 68.2432 133.784 65.5405 131.757C60.1351 127.027 54.7297 122.297 50 118.243C37.8378 108.784 26.3513 97.2973 15.5405 85.8108C6.08108 75.6757 0.675676 62.1622 0 47.973C0 35.1351 4.05405 23.6486 12.8378 14.1892C20.9459 5.40541 32.4324 0 44.5946 0C44.5946 0 44.5946 0 45.2703 0C54.0541 0 63.5135 3.37838 70.9459 8.78378C72.2973 10.1351 74.3243 11.4865 75.6757 12.8378C77.027 11.4865 78.3784 10.1351 80.4054 8.78378C87.1622 3.37838 95.9459 0 105.405 0C105.405 0 105.405 0 106.081 0C118.243 0 129.73 5.40541 137.838 14.1892C145.946 23.6486 150.676 35.8108 150.676 47.973C150 62.1622 144.595 75.6757 135.135 85.8108C124.324 97.2973 112.838 108.784 100.676 118.243L99.3243 119.595C95.2703 122.973 90.5405 127.027 85.8108 131.757C81.7568 133.784 78.3784 135.135 75 135.135ZM44.5946 13.5135C36.4865 13.5135 28.3784 16.8919 22.2973 22.973C16.8919 29.7297 13.5135 38.5135 13.5135 47.973C13.5135 58.7838 18.2432 68.9189 25 77.027C35.1351 88.5135 46.6216 98.6487 58.1081 108.108C62.8378 112.162 68.2432 116.892 73.6486 121.622C74.3243 122.297 75 122.297 75 121.622C79.7297 117.568 84.4594 113.514 89.1892 109.459L90.5405 108.108C102.703 98.6487 113.514 87.8378 123.649 77.027C131.081 68.9189 135.135 58.7838 135.135 47.973C135.135 39.1892 131.757 30.4054 126.351 23.6486C121.622 16.8919 114.189 13.5135 105.405 13.5135C99.3243 13.5135 93.2432 15.5405 87.8378 19.5946C85.1351 21.6216 82.4324 24.3243 80.4054 27.027L75 34.4595L69.5946 27.027C67.5676 24.3243 64.8649 21.6216 62.1622 19.5946C57.4324 15.5405 51.3513 13.5135 44.5946 13.5135C45.2703 13.5135 44.5946 13.5135 44.5946 13.5135Z"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.favorite'),
    width: $options.width,
    height: $options.height,
    viewBox: $props.filled ? '0 0 21 19' : '0 0 151 136',
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      ($props.filled)
        ? (_openBlock(), _createElementBlock("path", _hoisted_1))
        : (_openBlock(), _createElementBlock("path", _hoisted_2))
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "viewBox", "iconColor"]))
}