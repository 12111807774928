<template>
    <div class="textarea__instructions">
        <textarea
            id="textarea"
            v-model="text"
            class="textarea__instructions--box"
            :maxlength="maxLength"
            :placeholder="placeholder"
            rows="4"
        />
        <p class="textarea__instructions--length">{{ text?.length }}/{{ maxLength }}</p>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useApp } from '@/composables/useApp';

const text = defineModel({
    type: String,
    required: true,
});

const props = defineProps({
    maxLength: {
        type: Number,
        default: 250,
    },
    placeholder: {
        type: String,
        default: undefined,
    },
});

const { $t } = useApp();
const placeholder = computed(() => props.placeholder ?? $t('txt.resume__purchase-instructions-enter-your'));

onMounted(() => {
    const textarea = document.getElementById('textarea');
    const limit = 500; //height limit
    textarea.oninput = function () {
        textarea.style.height = '';
        textarea.style.height = Math.min(textarea.scrollHeight, limit) + 'px';
    };
});
</script>

<style lang="scss" scoped>
@import './text-area-instructions.scss';
</style>
