import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/gif/success.gif'


const _withScopeId = n => (_pushScopeId("data-v-43883ad4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bottom-sheet-feedback" }
const _hoisted_2 = ["alt"]
const _hoisted_3 = {
  key: 3,
  class: "bottom-sheet-feedback__title"
}
const _hoisted_4 = {
  key: 4,
  class: "bottom-sheet-feedback__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_circular_close = _resolveComponent("icon-circular-close")!
  const _component_triple_loading = _resolveComponent("triple-loading")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_btn_text = _resolveComponent("btn-text")!
  const _component_base_bottom_sheet = _resolveComponent("base-bottom-sheet")!

  return ($props.isActive && $options.isMobile)
    ? (_openBlock(), _createBlock(_component_base_bottom_sheet, {
        key: 0,
        bgColor: "#FFF",
        canDrag: $options.canDrag,
        onOnDismiss: $options.closeBottomSheet
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            ($props.isSuccess)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "bottom-sheet-feedback__success",
                  src: _imports_0,
                  alt: _ctx.$t('icon.success')
                }, null, 8, _hoisted_2))
              : ($props.isFailure)
                ? (_openBlock(), _createBlock(_component_icon_circular_close, {
                    key: 1,
                    iconName: _ctx.$t('icon.error'),
                    size: 1.5,
                    color: "#F03F51"
                  }, null, 8, ["iconName"]))
                : ($props.isLoading)
                  ? (_openBlock(), _createBlock(_component_triple_loading, { key: 2 }))
                  : _createCommentVNode("", true),
            ($props.title.length)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString($props.title), 1))
              : _createCommentVNode("", true),
            ($props.body.length)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString($props.body), 1))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "content", {}, undefined, true),
            ($props.showAction && $props.ctaText.length)
              ? (_openBlock(), _createBlock(_component_btn_solid, {
                  key: 5,
                  txt: $props.ctaText,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-action')))
                }, null, 8, ["txt"]))
              : _createCommentVNode("", true),
            ($props.showAction && $props.linkText.length)
              ? (_openBlock(), _createBlock(_component_btn_text, {
                  key: 6,
                  class: "cta-text--normal",
                  text: $props.linkText,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-text-action')))
                }, null, 8, ["text"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 3
      }, 8, ["canDrag", "onOnDismiss"]))
    : _createCommentVNode("", true)
}