import { toClientProfileModel } from '@/models/user/ClientProfileModel';
import { PrimeInformationModel, toPrimeInformationModel } from '@/models/user/PrimeInformationModel';
import { toBaseConfigurationModel } from '@/models/baseConfiguration/BaseConfigurationModel';
import { firebaseApp } from '@/firebase.config.js';
import { logger } from '@/utils/logger';
import { toRetailerPermissionModel } from '@/models/cart/RetailerPermissionModel';

import appboy from '@braze/web-sdk';
import firebase from 'firebase/app';
import LoginModel from '@/models/user/LoginModel';
import RegisterModel from '@/models/user/RegisterModel';

require('firebase/auth');

interface CodePassword {
    code: string;
    password: string;
}

export default {
    async baseConfiguration({ commit, rootGetters }): Promise<void> {
        try {
            const { data } = await rootGetters['network/axios'].get(`v2/api/application_base_configuration`);
            commit('baseConfiguration', toBaseConfigurationModel(data));
        } catch (err) {
            logger('BASE_CONFIGURATION', err);
        }
    },
    async grantedPermissions({ commit, rootGetters }): Promise<void> {
        try {
            const url = 'v2/customer/permission/';
            const { data } = await rootGetters['network/axios'].get(url);
            const permissions = data.map((permission: object) => toRetailerPermissionModel(permission));
            commit('grantedPermissions', permissions);
        } catch (err) {
            logger('GRANTED_PERMISSIONS', err);
        }
    },
    async setRetailerPermission({ rootGetters }, payload: { retailerId: number; status: boolean }): Promise<void> {
        try {
            const url = `v2/customer/permission/${payload.retailerId}`;
            const body = {
                status: payload.status,
            };
            await rootGetters['network/axios'].post(url, body);
        } catch (err) {
            logger('SET_RETAILER_PERMISSION', err);
        }
    },
    async getProfile({ rootGetters, getters, dispatch, commit }): Promise<void> {
        try {
            const response = await rootGetters['network/axios'].get('client_profile/');
            const clientProfile = toClientProfileModel(response.data);
            /// Get information of cambrella subscription
            dispatch('cambrellas/cambrellaMembership', null, { root: true });
            let primeInformation: PrimeInformationModel;
            if (clientProfile.isPrime) primeInformation = await dispatch('getPrimeData');
            commit('user', toClientProfileModel(response.data, primeInformation));
            if (!rootGetters['external/countrySelected']) {
                commit('external/countrySelected', getters['user']?.currentSector?.city.country, { root: true });
            }
            /// ** Update userId for braze if enviroment is STG or PROD;
            if (getters['user']?.id && process.env.VUE_APP_API_ENV !== 'DEV')
                appboy.changeUser(getters['user'].id.toString());
        } catch (err) {
            throw Error('GET_PROFILE');
        }
    },
    async getPrimeData({ rootGetters }): Promise<PrimeInformationModel> {
        try {
            const url = 'v2/client_profile/tipti_card_prime';
            const response = await rootGetters['network/axios'].get(url);
            return toPrimeInformationModel(response.data);
        } catch (err) {
            logger('GET_PRIME_DATA', err);
        }
    },
    async editProfile(
        { rootGetters, dispatch, commit },
        payload: {
            firstname: string;
            lastname: string;
            email: string;
            phone: string;
            document: string;
            genre: string;
            birthdate: string;
            password: string;
            isEditingEmail: boolean;
            documentType: string;
        },
    ): Promise<void> {
        try {
            const user = payload.isEditingEmail
                ? {
                      first_name: payload.firstname,
                      last_name: payload.lastname,
                      email: payload.email,
                      password: payload.password,
                      old_password: payload.password,
                  }
                : {
                      first_name: payload.firstname,
                      last_name: payload.lastname,
                      email: payload.email,
                  };

            const editprofile = {
                user,
                birth_date: payload.birthdate,
                genre: payload.genre,
                document: payload.document,
                document_type: payload.documentType,
                phone: payload.phone,
            };
            const url = 'client_profile/';
            const { data } = await rootGetters['network/axios'].put(url, editprofile);
            if (data['token']) commit('token', data['token']);
            let primeInformation: PrimeInformationModel;
            if (data?.['is_prime'] ?? data?.['client_data']['is_prime'])
                primeInformation = await dispatch('getPrimeData');
            commit('user', toClientProfileModel(data['client_data'] ?? data, primeInformation));
            dispatch('segment/detailsPopulated', null, { root: true });
        } catch (err) {
            logger('EDIT_PROFILE', err);
            throw err['response']['data'];
        }
    },
    async setPassword({ rootGetters }, payload: { oldPassword: string; newPassword: string }): Promise<boolean> {
        try {
            const data = {
                user: {
                    old_password: payload.oldPassword,
                    password: payload.newPassword,
                },
            };
            const url = `client_profile/`;
            const response = await rootGetters['network/axios'].put(url, data);
            return response.status === 200;
        } catch (err) {
            logger('ERROR TO set PASSWORD', err);
        }
    },
    async changeProfilePicture({ rootGetters }, image: File): Promise<boolean> {
        try {
            const formData = new FormData();
            formData.append('profile_picture', image);
            const url = `client_profile/profile_picture/`;
            const response = await rootGetters['network/axios'].post(url, formData);

            return response.status === 201;
        } catch (err) {
            logger('ERROR TO set PROFILE PICTURE', err);
        }
    },
    async login({ commit, rootGetters }, loginModel: LoginModel): Promise<void> {
        try {
            const { data } = await rootGetters['network/axios'].post(
                'api-token-auth/',
                {
                    username: loginModel.email,
                    password: loginModel.password,
                    returnSecureToken: true,
                },
                {
                    headers: {
                        Authorization: null,
                    },
                },
            );
            commit('token', data['token']);
        } catch (err) {
            logger('LOGIN', err);
            throw err.response;
        }
    },
    async register({ rootGetters, commit, dispatch }, registerModel: RegisterModel): Promise<void> {
        try {
            const url = 'client_register/';
            const fullName = registerModel.name.split(' ');
            const body = {
                last_name: fullName[0],
                first_name: fullName[1],
                email: registerModel.email,
                password: registerModel.password,
                origin: 'app_web',
            };
            const { data } = await rootGetters['network/axios'].post(url, body, {
                headers: {
                    Authorization: null,
                },
            });
            dispatch(
                'segment/createNewUser',
                {
                    name: `${body.first_name} ${body.last_name}`,
                    email: body.email,
                    provider: 'EMAIL',
                },
                { root: true },
            );
            commit('token', data['token']);
        } catch (err) {
            logger('REGULAR_REGISTER', err);
            throw err.response;
        }
    },
    async loginFB({ rootGetters, commit, dispatch }): Promise<void> {
        try {
            const url = 'client_facebook_register/';
            const response = await firebaseApp.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider());
            const body = {
                token: response['credential']['accessToken'],
                first_name: response['additionalUserInfo']['profile']['first_name'],
                last_name: response['additionalUserInfo']['profile']['last_name'],
                email: response['additionalUserInfo']['profile']['email'],
                device: '',
                origin: 'app_web',
            };
            const { data } = await rootGetters['network/axios'].post(url, body, {
                headers: {
                    Authorization: null,
                },
            });
            dispatch(
                'segment/createNewUser',
                {
                    name: `${body.first_name} ${body.last_name}`,
                    email: body.email,
                    provider: 'FACEBOOK',
                },
                { root: true },
            );
            commit('token', data['token']);
        } catch (err) {
            logger('REGISTER_FB', err);
            throw err.response;
        }
    },
    async loginGoogle({ rootGetters, commit }, token: string): Promise<void> {
        try {
            const url = 'client-google-register';
            const body = {
                token: token,
                device: '',
                origin: 'app_web',
            };
            const { data } = await rootGetters['network/axios'].post(url, body, {
                headers: {
                    Authorization: null,
                },
            });
            commit('token', data['token']);
        } catch (err) {
            logger('REGISTER_GOO', err);
            throw err.response;
        }
    },
    async codeRecovery({ rootGetters }, code: string): Promise<object> {
        return rootGetters['network/axios'].post(
            `password_reset_confirm/`,
            {
                user_token: code,
            },
            {
                headers: {
                    Authorization: null,
                },
            },
        );
    },
    async passwordRecovery({ rootGetters }, mail: string): Promise<object> {
        return rootGetters['network/axios'].post(
            'password_reset_email/',
            {
                email: mail,
            },
            {
                headers: {
                    Authorization: null,
                },
            },
        );
    },
    async resetPassword({ rootGetters }, payload: CodePassword): Promise<object> {
        try {
            return rootGetters['network/axios'].post(
                'password_reset/',
                {
                    user_token: payload.code,
                    password: payload.password,
                },
                {
                    headers: {
                        Authorization: null,
                    },
                },
            );
        } catch (err) {
            throw Error(`RESET_PASSWORD ${err}`);
        }
    },
    async setDefaultLanguage({ rootGetters }, languageCode: string): Promise<void> {
        try {
            const url = 'v2/set-client-default-language';
            const data = {
                language_code: languageCode,
            };
            await rootGetters['network/axios'].post(url, data);
        } catch (err) {
            throw Error('SET_DEFAULT_LANGUAGE');
        }
    },
    async deactivateAccount(
        { rootGetters },
        payload: {
            clientId: number;
            deactivate: boolean;
            reason: string;
        },
    ): Promise<void> {
        try {
            const url = 'v2/client-profile/log-out';
            const body = {
                client_id: payload.clientId,
                deactivate: payload.deactivate,
                reason: payload.reason,
            };
            await rootGetters['network/axios'].post(url, body);
        } catch (err) {
            logger('DEACTIVATE_ACCOUNT', err);
            throw err.response;
        }
    },
};
