<template>
    <base-loading v-if="isLoadingSchema" />
    <form v-else class="billing-form" @submit.prevent="submitBill">
        <TabMultiOption v-model="documentType" class="bill-type-from" :options="dropdownDocumentOptions" />
        <h5 class="form_header">{{ $t('txt.billing__required_data') }}</h5>
        <section class="billing-form-inputs" :class="{ 'billing-form-inputs--column': isFromModal }">
            <BaseForm
                :errors="errorMessageBill"
                :required="requiredFields"
                :schema="formFields"
                :value="formData"
                @form-values="updateValues"
            />
        </section>
    </form>

    <button v-if="isFromModal" class="button-go-back" @click="back">
        <span class="button-go-back__text cursor-pointer">{{ $t('txt.go-back') }}</span>
    </button>

    <section-footer class="billing-form__footer" :with-shadow="false">
        <btn-solid
            :is-disabled="btnIsDisable"
            :is-loading="isLoading"
            :txt="$t('cta.save')"
            size-text="18px"
            @click="isLoading ? null : submitBill()"
        />
    </section-footer>
    <snack-bar
        :body="modalMessage"
        :is-active="showModal"
        :is-failure="!snackbarSuccess"
        :is-success="snackbarSuccess"
        @on-snackbar-close="closeSnackBar"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import BaseForm from '@/components/forms/DynamicForm.vue';
import { getEnumValue } from '@/utils/schemaEnumHelper';
import { Components } from '@/enums/components';
import { BillSchemaResponse } from '@/models/BillModels';
import { isMobileBrowser } from '@/utils/utils';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import { useCountry } from '@/composables/useCountry';
import { removeCountryCode } from '@/utils/phoneUtils';
import { useBillStore } from '@/store/useBillStore';
import { computed } from 'vue';
import TabMultiOption from '@/modules/core/tabs/TabMultiOption.vue';
import { useBill } from '@/views/bills/composableBill';
import { ClientError } from '@/modules/core/errors/AppErrors';

export default {
    name: 'BillingForm',
    components: {
        TabMultiOption,
        BaseLoading,
        BaseForm,
        BtnSolid,
        SnackBar,
        SectionFooter,
    },
    props: {
        billToEdit: {
            type: Object,
            default: undefined,
        },
        countryId: {
            type: Number,
            default: undefined,
        },
        isFromModal: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-add-success'],
    setup(props) {
        const {
            countryPhoneFromPhoneNumber,
            countries,
            concatenateNumberAndCountryCode,
            validatePhoneAndCountryPhone,
        } = useCountry();
        const { showModalBill } = useBill();
        const country_code = computed(() => {
            if (!props.countryId) return;
            return countries.value.find((country) => country.id === props.countryId)?.alphaCode ?? 'EC';
        });
        const $billsStore = useBillStore();

        return {
            showModalBill,

            concatenateNumberAndCountryCode,
            countryPhoneFromPhoneNumber,
            validatePhoneAndCountryPhone,
            createBill: (data: object) => $billsStore.create(data),
            updateBill: (data: object) => $billsStore.update(data),
            getSchema: () => $billsStore.getSchema(country_code.value),
        };
    },
    data() {
        return {
            billTypeOptions: [],
            formData: {},
            documentType: {
                id: 0,
                label: '',
            },
            requiredFields: [],
            dropdownDocumentOptions: [],
            documentOption: undefined,
            showVerifyAlert: false,
            showModal: false,
            isLoading: false,
            isLoadingSchema: false,
            modalMessage: '',
            errorMessageBill: {},
            snackbarSuccess: false,
            emailReg: /^[a-zA-Z]+.*@[a-zA-Z0-9-]*\..{2,}$/,
        };
    },
    watch: {
        // +++ reset children values when parent value change
        formData: {
            handler: function (new_val, _) {
                const formDataKeys = Object.keys(this.formData);
                if (!formDataKeys?.length) return;
                const schema = this.schemaTypeSelected?.schema;
                const schemaToJson = JSON.parse(schema);
                const propertiesBlock = schemaToJson['properties'];
                const propertiesNames = Object.keys(propertiesBlock);
                propertiesNames.forEach((property) => {
                    const depends = propertiesBlock[property]['depends'];
                    if (
                        depends &&
                        this.formData[property]?.toString().split('-')[1] !==
                            this.formData[depends]?.toString().split('-')[0]
                    )
                        delete this.formData[property];
                });
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters({
            connectionStatus: 'network/connectionStatus',
            userLanguage: 'user/userLanguage',
        }),
        isMobile() {
            return isMobileBrowser();
        },
        schemaTypeSelected() {
            return this.billTypeOptions.find((element) => element.document_type === this.documentType.id);
        },
        formFields() {
            const fields = [];
            const schemaValues =
                this.schemaTypeSelected?.schema ?? this.billTypeOptions?.find((element) => element.default)?.schema;
            if (!schemaValues) return;
            const schemaToJson = JSON.parse(schemaValues);
            const requiredFields = schemaToJson['required'];
            const propertiesBlock = schemaToJson['properties'];
            const definitionsBlock = schemaToJson['definitions'];

            const getDefinition = (reference: string, parentId?: string) => {
                return getEnumValue(definitionsBlock[reference]['anyOf'], parentId);
            };

            const propertiesNames = Object.keys(propertiesBlock);

            propertiesNames.forEach((property) => {
                const labels = JSON.parse(propertiesBlock[property]['title']);
                const label = labels[this.userLanguage] ?? propertiesBlock[property]['title'];
                const description = JSON.parse(
                    propertiesBlock[property]['description'] ?? propertiesBlock[property]['title'],
                );
                const hasRef = propertiesBlock[property]['$ref'];
                let options;
                if (hasRef) {
                    const key = propertiesBlock[property]['$ref'].split('/');
                    if (propertiesBlock[property]['depends'] && this.formData[propertiesBlock[property]['depends']]) {
                        options = getDefinition(
                            key[key.length - 1],
                            this.formData[propertiesBlock[property]['depends']],
                        );
                    } else if (!propertiesBlock[property]['depends']) {
                        options = getDefinition(key[key.length - 1]);
                    }
                }
                const setComponent = () => {
                    if (property === 'phone') return Components.inputPhoneWithLabel;
                    if (propertiesBlock[property]['type'] === 'boolean') return Components.dynamicToggle;
                    if (hasRef) return Components.dynamicDropdown;
                    return Components.dynamicInput;
                };
                fields.push({
                    component: setComponent(),
                    name: property,
                    type: propertiesBlock[property]['type'] ?? 'string',
                    title: label,
                    label: label,
                    pattern: propertiesBlock[property]['pattern'],
                    minLength: propertiesBlock[property]['minLength'],
                    maxLength: propertiesBlock[property]['maxLength'],
                    format: propertiesBlock[property]['format'],
                    required: requiredFields.includes(property),
                    dependency: propertiesBlock[property]['depends'],
                    placeholder: description[this.userLanguage] ?? '',
                    showPlaceholderWithOutFocus: false,
                    options: options,
                });
            });
            return fields;
        },
        btnIsDisable(): boolean {
            if (this.isLoading) return true;
            const required = this.formFields?.filter((field) => field.required)?.map((field) => field.name);
            if (required?.some((filed) => !this.formData[filed])) return true;
            if (this.formData.phone?.length) return !this.validatePhoneAndCountryPhone(this.formData.phone);
            return Object.keys(this.errorMessageBill).some((field) => this.errorMessageBill[field]?.length);
        },
    },
    async mounted() {
        this.isLoadingSchema = true;

        const schemas: Array<BillSchemaResponse> = await this.getSchema();
        this.isLoadingSchema = false;

        this.billTypeOptions = schemas.filter((schema) => schema.enabled);
        this.dropdownDocumentOptions = this.billTypeOptions.map((document) => {
            try {
                return {
                    id: document.document_type,
                    label: JSON.parse(document.document_description),
                };
            } catch (e) {
                return {
                    id: document.document_type,
                    label: document.document_description,
                };
            }
        });
        const defaultSchema = schemas.find((element) => element.default) ?? schemas[0];
        this.documentType = {
            id: defaultSchema.document_type,
            label: defaultSchema.document_type,
        };
        try {
            this.documentType = {
                id: defaultSchema.document_type,
                label: JSON.parse(defaultSchema.document_description),
            };
        } catch (e) {
            this.documentType = {
                id: defaultSchema.document_type,
                label: defaultSchema.document_description,
            };
        }

        if (!this.billToEdit) {
            await this.countryPhoneFromPhoneNumber();
            return;
        }
        const keys = Object.keys(this.billToEdit);
        const temporalDoc = schemas.find((element) => element.document_type === this.billToEdit.type_identifier);
        this.documentType = {
            id: temporalDoc.document_type,
            label: temporalDoc.document_type,
        };
        try {
            this.documentType = {
                id: temporalDoc.document_type,
                label: JSON.parse(temporalDoc.document_description),
            };
        } catch (e) {
            this.documentType = {
                id: temporalDoc.document_type,
                label: temporalDoc.document_description,
            };
        }
        for (const key of keys) {
            if (key === 'phone') {
                await this.countryPhoneFromPhoneNumber(this.billToEdit[key]);
                this.formData[key] = removeCountryCode(this.billToEdit[key]);
            } else {
                this.formData[key] = this.billToEdit[key];
            }
        }
    },
    methods: {
        updateValues(fieldName: string, newFormData: object, field: object) {
            this.errorMessageBill[fieldName] = '';
            if (field['format'] === 'email' && !this.emailReg.test(newFormData[fieldName]))
                this.errorMessageBill.email = this.$t('input.invalid_email');

            if (
                field['pattern']?.length &&
                !new RegExp(field['pattern']).test(newFormData[fieldName]?.replaceAll(' ', '')) &&
                field['name'] !== 'phone'
            )
                this.errorMessageBill[fieldName] = this.$t('input.invalid_data');

            if (field['minLength'] && newFormData[fieldName].length < field['minLength'])
                this.errorMessageBill[fieldName] = this.$t('input.invalid_data');

            if (field['maxLength'] && newFormData[fieldName].length > field['maxLength'])
                this.errorMessageBill[fieldName] = this.$t('input.invalid_data');

            this.formData = newFormData;
        },

        validateForm(): boolean {
            const required = this.formFields.filter((element) => element.required);
            const formKeys = Object.keys(this.formData);
            for (const element of required) {
                this.requiredFields = this.requiredFields.filter((filed) => filed !== element.name);
                if (!formKeys.includes(element.name)) {
                    this.requiredFields.push(element.name);
                    this.showModal = true;
                    this.modalMessage = this.$t('general.required-fields-alert');
                }
            }
            return !this.requiredFields.length;
        },
        async back() {
            this.isLoading = true;
            this.showModal = true;
            this.$emit('on-add-success');
            this.isLoading = false;
        },
        async submitBill(): Promise<void> {
            try {
                this.errorMessageBill = {};
                this.validateForm();
                if (!this.connectionStatus) {
                    this.snackbarSuccess = false;
                    this.modalMessage = this.$t('alerts.connection_error');
                    this.showModal = true;
                    return;
                }
                this.showVerifyAlert = false;
                this.isLoading = true;

                //+++ remove unnecessary fields (only send the schema fields)
                const schemaValues = this.schemaTypeSelected?.schema;
                const schemaToJson = JSON.parse(schemaValues);
                const propertiesBlock = schemaToJson['properties'];
                const schemaKeys = Object.keys(propertiesBlock);
                const formKeys = Object.keys(this.formData);
                const difference = formKeys.filter((x) => !schemaKeys.includes(x));
                difference.forEach((key) => {
                    if (key !== 'id') delete this.formData[key];
                });

                this.formData['type_identifier'] = this.schemaTypeSelected.document_type;

                //*** use this variable to hide the transformation phone value
                const billDataToSend = Object.assign({}, this.formData);
                if (billDataToSend['phone'])
                    billDataToSend['phone'] = this.concatenateNumberAndCountryCode(this.formData['phone']);
                if (this.billToEdit) await this.updateBill(billDataToSend);
                else await this.createBill(billDataToSend);

                this.modalMessage = this.billToEdit
                    ? this.$t('alerts.bill_data_add')
                    : this.$t('alerts.edit_bill_data');
                this.snackbarSuccess = true;
                this.showModal = true;
            } catch (err) {
                if (err instanceof ClientError) {
                    const { errorObject } = err;
                    this.errorMessageBill = { ...errorObject.message };
                    if (Object.keys(errorObject).length) return;
                }

                this.snackbarSuccess = false;
                this.modalMessage = err.message.includes('Wrong document format')
                    ? this.$t('txt.bills-modal__error-identifier')
                    : this.$t("txt['bill__form-message-failed-form']", {
                          message: this.billToEdit ? this.$t("cta['edit']") : this.$t("cta['add']"),
                      });
                this.showModal = true;
            } finally {
                this.isLoading = false;
            }
        },
        closeSnackBar(): void {
            if (!this.showModal) return;
            if (this.snackbarSuccess) this.$emit('on-add-success');
            this.showModal = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './billing-form.scss';

.button-go-back {
    all: unset;
    @include subtitle2;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    align-self: center;

    &__text {
        @include body2;
        align-self: center;
        color: $orange;
        margin-left: 0.5rem;
        text-transform: capitalize;
    }
}

.bill-type-from {
    margin-bottom: 1rem;
}
</style>
