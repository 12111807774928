<template>
    <skeleton-subheader v-if="showSubheader" />
    <section class="skeleton-grid" ref="skeletonGrid">
        <base-bone v-for="index in items" class="cards" :key="index" />
    </section>
</template>
<script lang="ts">
import SkeletonSubheader from '../skeleton_subheader/SkeletonSubheader.vue';
import BaseBone from '@/components/skeletons/BaseBone.vue';

export default {
    name: 'skeleton-grid',
    components: { BaseBone, SkeletonSubheader },
    props: {
        items: {
            type: Number,
            default: 24,
        },
        showSubheader: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.skeleton-grid {
    @include grid-products;
    @include phone {
        gap: $margin-sm;
    }
}
.cards {
    height: 18rem;
    padding: $padding-none;
    @include phone {
        height: 16rem;
    }
}
</style>
