<template>
    <div class="all-categories">
        <div v-if="retailerSelected?.isPickupAvailable && isMainCart" class="delivery-options-container">
            <the-delivery-options show-button-option />
        </div>
        <mobile-header />
        <div class="all-categories__stories-minibanners">
            <the-stories-carousel />
            <the-promotions-banner is-banner-in-section-prop />
        </div>
        <Suspense>
            <CustomCategoriesCarousel
                :retailer-id="retailerSelected?.id"
                custom-cat="DISCOUNTS"
                show-special-product-card
            />
            <template #fallback>
                <SkeletonCarousel />
            </template>
        </Suspense>
        <Suspense>
            <CustomCategoriesCarousel :retailer-id="retailerSelected?.id" custom-cat="PREDICTION_PRODUCTS" />
            <template #fallback>
                <SkeletonCarousel />
            </template>
        </Suspense>
        <transition-vertical-fade key="promotions_banner">
            <section v-if="isMainCart">
                <the-promotions-banner />
            </section>
        </transition-vertical-fade>

        <template v-for="type in CustomCategories" :key="type">
            <Suspense v-if="type !== 'PREDICTION_PRODUCTS'">
                <CustomCategoriesCarousel :custom-cat="type" :retailer-id="retailerSelected?.id" />
                <template #fallback>
                    <SkeletonCarousel />
                </template>
            </Suspense>
        </template>
        <transition-vertical-fade key="recipes_banner">
            <section v-if="isMainCart">
                <the-recipes />
            </section>
        </transition-vertical-fade>
        <template v-if="!categories?.length">
            <SkeletonCarousel v-for="i in 5" :key="i" :max-slides="12" has-header />
        </template>
        <TheRecommendedCategoryCarousel v-for="category in categories" v-else :key="category.id" :category="category" />
    </div>
</template>

<script lang="ts" setup>
import ThePromotionsBanner from '@/components/banners/thePromotionsBanner/ThePromotionsBanner.vue';
import TheStoriesCarousel from '@/components/stories/theStoriesCarousel/TheStoriesCarousel.vue';
import TheRecipes from '../components/theRecipes/TheRecipes.vue';
import TheDeliveryOptions from '@/components/buttons/TheDeliveryOptions.vue';
import MobileHeader from '../components/AllCategoriesMobileHeader.vue';
import TransitionVerticalFade from '@/modules/core/components/transition/TransitionFade.vue';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useSeoMeta } from '@unhead/vue';
import CustomCategoriesCarousel from '@/modules/main-products/components/CustomCategoriesCarousel.vue';
import SkeletonCarousel from '@/components/skeletons/skeletonCarousel/SkeletonCarousel.vue';
import { CustomCategories } from '@/modules/core/errors/CustomCategories';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { useUser } from '@/composables/useUser';
import { useApp } from '@/composables/useApp';
import { useCategories } from '@/modules/main-products/composables/useCategories';
import TheRecommendedCategoryCarousel from '@/modules/main-products/components/theRecommendedCategoryProducts/TheRecommendedCategoryCarousel.vue';

const { retailerSelected } = useRetailer();
const { userLocation } = useUser();
const { $t } = useApp();
const { isMainCart } = useCartType();
const { categoriesWithOutDiscount: categories } = useCategories();
useSeoMeta({
    title: $t('seo.home_retailer', {
        retailer: retailerSelected.value?.name ?? '',
        city: userLocation.value?.city?.name ?? '',
    }),
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.mobile-header {
    display: none;
    padding-bottom: $padding-md;
    @include box-glow-new;
    @include tablet {
        display: block;
    }

    &__nav {
        display: flex;
        justify-content: space-around;
    }

    &__item {
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        align-items: center;

        h3 {
            @include subtitle2;
            @include tablet {
                font-size: 14px;
            }
        }

        &__icon-container {
            width: 2.5rem;
            display: flex;
            justify-content: center;
        }
    }
}

.all-categories__stories-minibanners {
    display: grid;
    grid-template-columns: 50% minmax(0, 1fr);
    align-items: center;
    gap: $margin-md;
    row-gap: $margin-md;
    margin: $margin-md 0;
    @include tablet {
        display: none;
    }
}
</style>
