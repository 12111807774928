import { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { CartTypes } from '@/modules/core/types/CartType';
import Retailers from '@/modules/retailers/pages/Retailers.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export const RouteRetailers: RouteRecordRaw[] = [
    {
        path: '/',
        name: appRoutesMap.home,
        component: Retailers,
        alias: ['/home', '/retailers', '/inicio', ''],
        meta: {
            fetch_tipti_tc: true,
            show_notification_rate: true,
        },
        beforeEnter: (_, __, next) => {
            store.commit('app/cartType', CartTypes.main);
            next();
        },
    },
];
