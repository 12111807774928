<template>
    <base-alert @on-close="onCloseAlert" class="content-alert">
        <h3 class="content-alert__title">{{ title }}</h3>
        <share-buttons
            :messageShare="messageToShare"
            :snack-bar-message="messageToShowOnCopy"
            :link="link"
            :share-link="shareLink"
            @on-track-event="onTrackEvent"
            :mailSubject="title"
        />
    </base-alert>
</template>

<script lang="ts">
import BaseAlert from '../baseAlert/BaseAlert.vue';
import ShareButtons from '@/components/buttons/ShareButtons.vue';

export default {
    name: 'share',
    props: {
        title: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        messageToShare: {
            type: String,
            required: true,
        },
        shareLink: {
            type: Boolean,
            default: true,
        },
        isGiftCard: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BaseAlert,
        ShareButtons,
    },
    computed: {
        messageToShowOnCopy(): string {
            return this.isGiftCard ? this.$t('alerts.giftCard_copied') : '';
        },
    },
    methods: {
        onCloseAlert(): void {
            this.$emit('on-close');
        },
        onTrackEvent(shareAction: string): void {
            this.$emit('on-track-event', shareAction);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.content-alert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__title {
        @include body1;
        width: 80%;
        text-align: center;
        font-weight: 600;
        margin: $margin-md auto;
    }
}
</style>
