<template>
    <div class="register-container">
        <icon-back @click="onBack" class="container-back"></icon-back>
        <tipti-with-slogan></tipti-with-slogan>
        <verify-email v-if="isVerifyEmail" @on-mail-sent="goToVerifyCode"></verify-email>
        <verify-code v-else-if="isVerifyCode" @on-code-validated="goToUpdatePassword"></verify-code>
        <update-password v-else :code="code" :email="email"></update-password>
    </div>
</template>

<script lang="ts">
import TiptiWithSlogan from '@/components/logos/TiptiWithSlogan.vue';
import VerifyEmail from './components/VerifyEmail.vue';
import VerifyCode from './components/VerifyCode.vue';
import UpdatePassword from './components/UpdatePassword.vue';
import IconBack from '@/components/icons/IconBack.vue';

export default {
    name: 'recovery-password',
    data() {
        return {
            isVerifyCode: false,
            isUpdatePassword: false,
            isVerifyEmail: true,
            code: '',
            email: '',
        };
    },
    components: {
        TiptiWithSlogan,
        VerifyEmail,
        VerifyCode,
        UpdatePassword,
        IconBack,
    },
    emits: ['on-back'],
    methods: {
        onBack() {
            if (this.isVerifyCode) {
                this.isVerifyCode = false;
                this.isVerifyEmail = true;
            } else if (this.isUpdatePassword) {
                this.isUpdatePassword = false;
                this.isVerifyCode = true;
            } else this.$emit('on-back');
        },
        goToUpdatePassword(code: string) {
            this.code = code;
            this.isUpdatePassword = true;
            this.isVerifyCode = false;
        },
        goToVerifyCode(email: string) {
            this.email = email;
            this.isVerifyCode = true;
            this.isVerifyEmail = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.register-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
}
.container-back {
    cursor: pointer;
}
</style>
