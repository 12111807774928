<template>
    <BackgroundWithTransition
        v-if="alertToShow === 'COMPLETE_PROFILE_TIPTI'"
        :is-active="alertToShow === 'COMPLETE_PROFILE_TIPTI'"
        content-centered
        @on-bg-click="$emit('on-close')"
    >
        <TheModalContent class="phone-full-page">
            <template #header>
                <BaseHeader
                    :text="$t('txt.cart__complete-your-register-title')"
                    :text-size="20"
                    with-default-close
                    @on-close="$emit('on-close')"
                />
            </template>
            <p v-if="registrationBy === 'AFFILIATION'" class="profile-form-text-complete">
                {{ $t('txt.by-completing-your-registration') }}
            </p>
            <edit-profile
                :show-notifier-success="registrationBy !== 'AFFILIATION'"
                is-from-cart
                @on-accept="handleOnAccept"
                @on-close="$emit('on-close')"
            />
        </TheModalContent>
    </BackgroundWithTransition>
    <alert-complete-profile :show-alert-profile="showAlertProfile" @on-close="$emit('on-accept')" />
</template>

<script lang="ts" setup>
import EditProfile from '@/views/myAccount/views/profile/views/editProfile/EditProfile.vue';
import AlertCompleteProfile from '@/views/myAccount/views/profile/views/editProfile/components/alertComplete/AlertCompleteProfile.vue';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';
import BaseHeader from '@/modules/core/components/headers/HeaderWithAction.vue';
import TheModalContent from '@/modules/core/components/layouts/TheModalContent.vue';
import type { PropType } from 'vue';
import { computed, ref } from 'vue';
import { RegistrationBy } from '@/modules/affiliation/composables/useAffiliationAlert';

const props = defineProps({
    registrationBy: {
        type: String as PropType<RegistrationBy>,
        default: undefined,
    },
});

const emits = defineEmits<{
    (e: 'on-accept'): void;
    (e: 'on-close'): void;
}>();

const alertToShow = ref<string>('COMPLETE_PROFILE_TIPTI');

const showAlertProfile = computed(() => alertToShow.value === 'PROFILE_COMPLETED' && !props.registrationBy);

const handleOnAccept = () => {
    alertToShow.value = 'PROFILE_COMPLETED';
    if (!showAlertProfile.value) emits('on-accept');
};
</script>

<style lang="scss" scoped>
@import './profile-form.scss';

.profile-form-text-complete {
    @include caption;
    text-align: center;
    width: 80%;
    align-self: center;
}
</style>
