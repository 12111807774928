<template>
    <article class="retailer-card cursor-pointer">
        <span v-if="cartItems" class="retailer-card__badge">
            <IconCart class="retailer-card__badge__cart" :size="0.6" color="white" />
            {{ cartItems }}
        </span>
        <ImageLoader class="retailer-card__img" :alt-text="retailer.name" :delay="400" :src="retailer.image">
            <template #loader>
                <base-bone class="retailer-card__img--skeleton" />
            </template>
        </ImageLoader>
        <h4 class="retailer-card__name">{{ retailer.name }}</h4>
    </article>
</template>

<script lang="ts" setup>
import { MinRetailerInfo } from '@/modules/retailers/models/OtherRetailersModel';
import { computed, PropType } from 'vue';
import ImageLoader from '@/modules/core/components/loaders/ImageLoader.vue';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import IconCart from '@/components/icons/IconCart.vue';
import { useCart } from '@/composables/useCart';

const { cart } = useCart();

const props = defineProps({
    retailer: {
        type: Object as PropType<MinRetailerInfo>,
        required: true,
    },
});

const cartItems = computed(
    () => cart.value?.retailersBasicInfo?.find((rel) => rel.id == props.retailer.id)?.cart_items,
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.retailer-card {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;

    @include phone {
        column-gap: 10px;
    }

    &__badge {
        position: absolute;
        top: -20px;
        right: -15px;
        border-radius: 36px;
        background: #ff9012;
        width: 3.75rem;
        height: 2.5rem;
        border: 1px white solid;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: white;
        font-size: 20px;
        font-family: $main-font;
        z-index: 1;
    }

    &__img {
        border-radius: $radius-lg;
        object-fit: cover;
        width: 100%;
        height: 5rem;
        flex-grow: 0.4;
        @include box-shadow;

        @include phone {
            height: 4rem;
        }

        &--skeleton {
            height: 5rem;
            width: 100%;
            z-index: 0;
            @include phone {
                height: 4rem;
            }
        }
    }

    &__name {
        @include body2;
        font-weight: 600;
        @include text-lines(1);
    }
}
</style>
