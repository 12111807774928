<template>
    <base-icon :icon-name="$t('icon.mute')" :width="width" :height="height" viewBox="0 0 100 100" :iconColor="color">
        <svg
            version="1.1"
            id="fi_727240"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 153.667 153.667"
            style="enable-background: new 0 0 153.667 153.667"
            xml:space="preserve"
        >
            <g>
                <path
                    d="M143.221,141.225L97.919,89.519V7.501c0-2.931-1.707-5.593-4.37-6.815
		c-2.665-1.225-5.796-0.784-8.018,1.127L48.76,33.411L21.728,2.559c-2.73-3.115-7.469-3.428-10.583-0.698
		C8.029,4.59,7.716,9.328,10.446,12.443l26.568,30.323H16.087c-4.142,0-7.5,3.357-7.5,7.5V103.4c0,4.143,3.358,7.5,7.5,7.5h21.787
		l47.658,40.954c1.388,1.192,3.129,1.812,4.89,1.812c1.06,0,2.127-0.225,3.128-0.685c2.663-1.223,4.37-3.885,4.37-6.815v-33.885
		l34.02,38.828c1.483,1.692,3.558,2.558,5.644,2.558c1.754,0,3.517-0.612,4.939-1.859
		C145.638,149.078,145.951,144.34,143.221,141.225z M82.919,23.835v48.564L58.645,44.694L82.919,23.835z M23.587,57.767h9.566V95.9
		h-9.566V57.767z M82.919,129.832L48.153,99.956V55.481l34.766,39.68V129.832z"
                />
            </g>
        </svg>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-mute',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 50 * this.size;
        },
        height(): number {
            return 50 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
