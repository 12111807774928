import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-358f6042"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "retailers-in-tipti" }
const _hoisted_2 = { class: "retailers-in-tipti__title" }
const _hoisted_3 = { class: "retailers" }
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { class: "card-overlay" }
const _hoisted_6 = { class: "card-overlay__txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_auth = _resolveComponent("auth")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("article", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("general['retailers']")), 1),
      _createElementVNode("section", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.listRetailers, (store) => {
          return (_openBlock(), _createBlock(_component_base_card, {
            key: store.id,
            class: "retailer cursor-pointer",
            padding: "0"
          }, {
            default: _withCtx(() => [
              (store?.retailer?.logo)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "retailer__img",
                    alt: store.retailer.name,
                    src: store.retailer.logo
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("general['coverage-sector']")), 1),
                _createVNode(_component_btn_solid, {
                  txt: _ctx.$t('general.see'),
                  onClick: $options.openSectorsOfCoverage
                }, null, 8, ["txt", "onClick"])
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ])
    ]),
    ($data.showLogin)
      ? (_openBlock(), _createBlock(_component_auth, {
          key: 0,
          onOnClose: _cache[0] || (_cache[0] = ($event: any) => ($data.showLogin = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}