import { ref } from 'vue';
import { tiptiTermsAndConditionService } from '@/modules/retailers/infraestructure/TiptiTermsAndConditionService';
import { AffiliationAlertDto } from '@/modules/affiliation/models/AffiliationAlertDto';
import { useUser } from '@/composables/useUser';

const alertInfo = ref<AffiliationAlertDto | undefined>();
const isFetching = ref<boolean>(false);

export const useTiptiTAC = () => {
    const isLoading = ref<boolean>(false);
    const { isAuth } = useUser();

    const fetchData = async (): Promise<void> => {
        if (isAuth.value) return;
        isFetching.value = true;
        alertInfo.value = await tiptiTermsAndConditionService.get();
        isFetching.value = false;
    };

    const closeAlert = async (): Promise<void> => {
        if (isLoading.value) return;
        isLoading.value = true;
        await tiptiTermsAndConditionService.post();
        alertInfo.value = null;
        isLoading.value = false;
    };

    return {
        isFetching,
        isLoading,
        alertInfo,
        fetchData,
        closeAlert,
    };
};
