<script generic="T" lang="ts" setup>
import { computed, ref, watch } from 'vue';
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';
import IconArrow from '@/components/icons/IconArrow.vue';

const props = defineProps({
    items: {
        type: Array<T>,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    isLastPage: {
        type: Boolean,
        default: true,
    },
});

const total = computed(() => props.items.length);

const isExpanded = ref(false);

const gapSize = ref(8);
const key = ref(0);

const position = ref('absolute');
let interval;
const valueGapIncrement = 0.25;
const timer = 500;
const intervalTime = 10;

watch(
    () => isExpanded.value,
    (isExpanded) => {
        clearInterval(interval);
        if (isExpanded) {
            interval = setInterval(() => {
                gapSize.value += valueGapIncrement;
            }, intervalTime);
            setTimeout(() => {
                clearInterval(interval);
                position.value = 'static';
                key.value++;
                gapSize.value = 26;
            }, timer);
            return;
        }
        position.value = 'absolute';
        interval = setInterval(() => {
            gapSize.value -= valueGapIncrement;
        }, intervalTime);
        setTimeout(() => {
            clearInterval(interval);
            gapSize.value = 8;
            key.value++;
        }, timer);
    },
);

const onClickHeader = () => {
    if (props.items?.length <= 1) return;
    isExpanded.value = !isExpanded.value;
};
</script>

<template>
    <section v-if="total" class="wallet-section">
        <header class="wallet-section__header cursor-pointer" @click.stop="onClickHeader">
            <h3 class="wallet-section__header-text">{{ title }}</h3>
            <IconArrow
                class="wallet-section__header-icon"
                :class="{ 'wallet-section__header-icon--left': isExpanded }"
                :size="0.5"
            />
        </header>
        <CarouselTipti
            :key="key"
            class="wallet-cards__carousel"
            :is-last-page="isLastPage"
            max-with="unset"
            show-arrows
        >
            <section class="wallet-cards">
                <slot
                    v-for="(item, index) in items"
                    :is-first="index === 0"
                    :is-last="index === items.length - 1"
                    :is-expanded="isExpanded"
                    :item="item"
                    :position="index"
                    :style="{
                        cursor: 'pointer',
                        position: position,
                        left: index * gapSize + 'rem',
                        zIndex: position === 'absolute' ? 1 : 'unset',
                    }"
                />
            </section>
        </CarouselTipti>
        <slot name="footer"></slot>
    </section>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index';

.wallet-section {
    width: 100%;
    box-sizing: border-box;

    &__header {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-left: 0.5rem;

        &-text {
            @include button;
        }

        &-icon {
            transition: all 0.3s ease-out;

            &--left {
                transform: rotate(180deg);
            }
        }
    }
}

.wallet-cards {
    display: flex;
    position: relative;
    height: 13rem;
    gap: 0.5rem;

    @include tablet {
        width: 20rem;
        height: 10rem;
    }

    &__carousel {
        max-width: calc(100svw - $margin-md - 8rem);
        @include tablet {
            max-width: calc(100svw - $margin-md);
        }
    }
}

.wallet-expanded-enter-active,
.wallet-expanded-leave-active {
    transition: width 0.5s ease;
}

.wallet-expanded-enter-from {
    width: 25%;
}

.wallet-expanded-leave-to {
    width: 100%;
}
</style>
