import { RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';

class BaseConfigurationModel {
    readonly id: number;
    readonly limitCashAmount: string;
    readonly enabled: boolean;
    readonly invoiceSystemEnabled: boolean;
    readonly globalPaymentLimit: number;
    readonly expressAsDefaultSchedule: boolean;
    readonly expressItemsLimit: number;
    readonly deferredMinimumAmount: number;
    readonly bannerTimeDelay: number;
    readonly mapColor: string;
    readonly trialPrimeEnabled: boolean;
    readonly wireTransferEnabled: boolean;
    readonly onlineBankPaymentsEnabled: boolean;
    readonly referredReward: number;
    readonly referredRewardCondition: number;
    readonly chatServiceEnabled: boolean;
    readonly cardValidationNewRegister: boolean;
    readonly cardValidationOldRegister: boolean;
    readonly cardValidationAttempts: number;
    readonly maxDebitAmount: number;
    readonly showLoyaltyMenu: boolean;
    readonly nequiPaymentEnable: boolean;
    readonly alertCommissionFee: RemoteLocaleModel;

    constructor(
        id: number,
        limitCashAmount: string,
        enabled: boolean,
        invoiceSystemEnabled: boolean,
        globalPaymentLimit: number,
        expressAsDefaultSchedule: boolean,
        expressItemsLimit: number,
        deferredMinimumAmount: number,
        bannerTimeDelay: number,
        mapColor: string,
        trialPrimeEnabled: boolean,
        wireTransferEnabled: boolean,
        onlineBankPaymentsEnabled: boolean,
        referredReward: number,
        referredRewardCondition: number,
        chatServiceEnabled: boolean,
        cardValidationNewRegister: boolean,
        cardValidationOldRegister: boolean,
        cardValidationAttempts: number,
        maxDebitAmount: number,
        showLoyaltyMenu: boolean,
        nequiPaymentEnable: boolean,
        alertCommissionFee: RemoteLocaleModel,
    ) {
        this.id = id;
        this.limitCashAmount = limitCashAmount;
        this.enabled = enabled;
        this.invoiceSystemEnabled = invoiceSystemEnabled;
        this.globalPaymentLimit = globalPaymentLimit;
        this.expressAsDefaultSchedule = expressAsDefaultSchedule;
        this.expressItemsLimit = expressItemsLimit;
        this.deferredMinimumAmount = deferredMinimumAmount;
        this.bannerTimeDelay = bannerTimeDelay;
        this.mapColor = mapColor;
        this.trialPrimeEnabled = trialPrimeEnabled;
        this.wireTransferEnabled = wireTransferEnabled;
        this.onlineBankPaymentsEnabled = onlineBankPaymentsEnabled;
        this.referredRewardCondition = referredRewardCondition;
        this.referredReward = referredReward;
        this.chatServiceEnabled = chatServiceEnabled;
        this.cardValidationNewRegister = cardValidationNewRegister;
        this.cardValidationOldRegister = cardValidationOldRegister;
        this.cardValidationAttempts = cardValidationAttempts;
        this.maxDebitAmount = maxDebitAmount;
        this.showLoyaltyMenu = showLoyaltyMenu;
        this.nequiPaymentEnable = nequiPaymentEnable;
        this.alertCommissionFee = alertCommissionFee;
    }
}
const toBaseConfigurationModel = (data: object): BaseConfigurationModel => {
    return data
        ? new BaseConfigurationModel(
              data['id'],
              data['limit_cash_amount'],
              data['enabled'] ?? false,
              data['invoice_system_enabled'] ?? false,
              +data['global_payment_limit'],
              data['express_as_default_schedule'] ?? false,
              data['express_items_limit'] ?? false,
              +data['deferred_minimum_amount'],
              +data['banner_time_delay'],
              data['map_color'] ?? '#B6B6B6',
              data['trial_prime_enabled'] ?? false,
              data['wire_transfer_enabled'] ?? false,
              data['online_bank_payments_enabled'] ?? false,
              data['referred_reward'] ? +data['referred_reward'] : 5,
              data['referred_reward_condition'] ? +data['referred_reward_condition'] : 15,
              data['chat_service_enabled'] ?? false,
              data['card_validation_new_register'] ?? false,
              data['card_validation_old_register'] ?? false,
              data['card_validation_attempts'] ?? 0,
              data['max_debit_amount'] ?? 0,
              data['show_loyalty_in_app_menu'] ?? false,
              data['nequi_payment_enable'] ?? false,
              RemoteLocaleModel.fromJson(data['alert_commission_fee']),
          )
        : null;
};
export { BaseConfigurationModel, toBaseConfigurationModel };
