<template>
    <base-icon :icon-name="$t('icon.bag')" :width="width" :height="height" viewBox="0 0 26 35" :iconColor="color">
        <path
            d="M20.1998 4.90043C19.9956 2.43678 18.1678 0.359375 15.9654 0.359375H10.8111C8.60877 0.359375 6.78093 2.43678 6.57671 4.90043H20.1998ZM8.84724 4.90043V8.30623C8.84724 8.71193 8.63084 9.08671 8.27961 9.28943C7.92839 9.49216 7.49556 9.49216 7.14435 9.28943C6.79313 9.08671 6.57671 8.71192 6.57671 8.30623V4.90043H2.03566C1.73461 4.90043 1.44572 5.02005 1.23286 5.2329C1.02 5.44576 0.900391 5.73465 0.900391 6.0357V33.2821C0.900391 33.5831 1.02 33.872 1.23286 34.0848C1.44572 34.2977 1.73461 34.4173 2.03566 34.4173H24.7409C25.042 34.4173 25.3309 34.2977 25.5437 34.0848C25.7566 33.872 25.8762 33.5831 25.8762 33.2821V6.0357C25.8762 5.73465 25.7566 5.44576 25.5437 5.2329C25.3309 5.02005 25.042 4.90043 24.7409 4.90043H20.1998L20.1999 8.30623C20.1999 8.71193 19.9835 9.08671 19.6323 9.28943C19.281 9.49216 18.8482 9.49216 18.497 9.28943C18.1458 9.08671 17.9294 8.71192 17.9294 8.30623V4.90043H8.84724ZM8.84724 4.90043C8.84192 4.34953 9.03705 3.81532 9.39612 3.39773C9.75546 2.97986 10.2544 2.70697 10.8 2.6299H15.9541C16.5037 2.70187 17.008 2.97251 17.3718 3.39086C17.7357 3.80898 17.9342 4.34597 17.9294 4.90043H8.84724Z"
            fill="white"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-one-bag',
    components: {
        BaseIcon,
    },
    props: {
        color: {
            type: String,
            default: '#FF9012',
        },
        iconName: {
            type: String,
        },
        size: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        height(): number {
            return 35 * this.size;
        },
        width(): number {
            return 26 * this.size;
        },
    },
};
</script>
