import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.search'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 150 155",
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M147.808 141.069L110.73 102.867C133.202 76.4623 129.269 36.5747 102.864 14.6646C76.4599 -7.24555 37.1341 -4.43656 14.6622 21.9679C-7.80973 48.3724 -3.87714 87.6983 22.5273 110.17C33.7633 119.721 48.37 124.777 62.9768 124.777C75.8981 124.777 88.2577 120.844 98.9318 113.541L136.01 152.305C139.381 155.676 144.437 155.676 147.808 152.305C150.617 149.496 150.617 143.878 147.808 141.069ZM62.9768 15.7882C88.8195 15.7882 109.044 36.5747 109.044 61.8556C109.044 87.1365 88.2577 107.923 62.9768 107.923C37.1341 107.923 16.9094 87.1365 16.9094 61.8556C16.3476 36.5747 37.1341 15.7882 62.9768 15.7882Z",
        fill: $props.color
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "iconColor"]))
}