import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_subheader_separator = _resolveComponent("subheader-separator")!
  const _component_carousel_recipe_ads = _resolveComponent("carousel-recipe-ads")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($data.recipesAds?.length)
      ? (_openBlock(), _createBlock(_component_subheader_separator, {
          key: 0,
          name: _ctx.$t('general.tipti-recipes'),
          "has-padding": "",
          onOnPressed: $options.goToSeeMore
        }, null, 8, ["name", "onOnPressed"]))
      : _createCommentVNode("", true),
    _createVNode(_component_carousel_recipe_ads, {
      isLastPage: $data.isLastPage,
      isLoading: $data.isLoading,
      recipes: $data.recipesAds,
      onReachedEnd: $options.updateProducts
    }, null, 8, ["isLastPage", "isLoading", "recipes", "onReachedEnd"])
  ], 64))
}