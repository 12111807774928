class PaymentSurplusModel {
    id: number;
    amountReceivable: number;
    order: number;
    state: string;
    paymentMethodToUse: number;
    paymentMethodTransaction: object;
    creationDate: Date;
    tiptiCardPayment: boolean;
    cashPayment: boolean;
    wireTransferPayment: boolean;
    payphonePayment: number;

    constructor(
        id: number,
        amountReceivable: number,
        order: number,
        state: string,
        paymentMethodToUse: number,
        paymentMethodTransaction: object,
        creationDate: Date,
        tiptiCardPayment: boolean,
        cashPayment: boolean,
        wireTransferPayment: boolean,
        payphonePayment: number,
    ) {
        this.id = id;
        this.amountReceivable = amountReceivable;
        this.order = order;
        this.state = state;
        this.paymentMethodToUse = paymentMethodToUse;
        this.paymentMethodTransaction = paymentMethodTransaction;
        this.creationDate = creationDate;
        this.tiptiCardPayment = tiptiCardPayment;
        this.cashPayment = cashPayment;
        this.wireTransferPayment = wireTransferPayment;
        this.payphonePayment = payphonePayment;
    }
}

const toPaymentSurplusModel = (data: object): PaymentSurplusModel => {
    return data
        ? new PaymentSurplusModel(
              data['id'],
              data['amount_receivable'] ?? 0,
              data['order'] ?? 0,
              data['state'] ?? '',
              data['payment_method_to_use'],
              data['payment_method_transaction'],
              data['creation_date'] ? new Date(data['creation_date']) : null,
              data['tipti_card_payment'] ?? false,
              data['cash_payment'] ?? false,
              data['wire_transfer_payment'] ?? false,
              data['payphone_payment'] ?? 0,
          )
        : null;
};
export { PaymentSurplusModel, toPaymentSurplusModel };
