<template>
    <div class="cursor-pointer state-one-order">
        <div class="order-state" @click.stop="goToOrder">
            <base-loading v-if="isLoading" :class="{ 'order-state__loading': oneOrder }" :is-margin-auto="false" />
            <icon-circle v-else class="order-state__circle" :color="isRedColor ? '#C90808' : '#CFDE00'" size="25px" />
            <div v-if="oneOrder" class="order-info__one-order">
                <base-loading v-if="isLoading" :is-margin-auto="false" />
                <icon-circle
                    v-else
                    class="order-state__circle-one"
                    :color="isRedColor ? '#C90808' : '#CFDE00'"
                    size="20px"
                />
                <div>
                    <h2 class="order-info__number" :class="{ 'order-info__number--one': oneOrder }">
                        {{ $t("general['order']") }} # {{ order.id }}
                    </h2>
                    <h2 class="order-info__status" :class="{ 'order-info__status--one': oneOrder }">
                        {{ order.stateInLocalLanguage }}
                    </h2>
                </div>
            </div>
            <div class="order-info" :class="{ 'order-info__hide-on-one': oneOrder }">
                <h2 class="order-info__number" :class="{ 'order-info__number--red': isRedColor }">
                    {{ $t("general['order']") }} # {{ order.id }}
                </h2>
                <h2 class="order-info__status">{{ order.stateInLocalLanguage }}</h2>
                <div class="city-and-retailer">
                    <icon-and-text :text="order.city">
                        <template #icon>
                            <icon-location :size="0.5" color="#707070" />
                        </template>
                    </icon-and-text>
                    <icon-and-text :text="order.retailer.name">
                        <template #icon>
                            <icon-store :size="0.5" color="#707070" />
                        </template>
                    </icon-and-text>
                </div>
            </div>
        </div>
        <div v-if="canCall && !showDivider" class="order-actions">
            <div></div>
            <btn-chat v-if="canChat" :messages-unread="totalUnreadMessages" @click="goToChat" />
            <div v-else></div>
            <btn-by-state
                :is-btn="false"
                :is-disable="isLoading || isDisable"
                :order="order"
                :shadow="false"
                is-from-retailer-or-home-screen
                use-default-color
                @is-loading="btnState"
            />
        </div>
        <div v-else class="order-actions">
            <btn-chat v-if="canChat" :messages-unread="totalUnreadMessages" @click="goToChat" />
            <div v-else></div>
            <div v-if="showDivider && canChat" class="order-actions__divider"></div>
            <div v-else></div>
            <btn-by-state
                :is-btn="false"
                :is-disable="isLoading || isDisable"
                :order="order"
                :shadow="false"
                is-from-retailer-or-home-screen
                use-default-color
                @is-loading="btnState"
                @on-click="onCLickBtnByState"
            />
        </div>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineAsyncComponent } from 'vue';
import { mapGetters } from 'vuex';
import { OrderModel } from '@/models/order/OrderModel';
import { baseOrderState } from '@/utils/orderUtils';
import IconLocation from '@/components/icons/IconLocation.vue';
import IconCircle from '@/components/icons/IconCircle.vue';
import IconStore from '@/components/icons/IconStore.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import IconAndText from '@/components/iconAndText/IconAndText.vue';
import BtnChat from '@/components/buttons/BtnChat.vue';
import { OrderState } from '@/enums/orderState';
import { useChat } from '@/composables/useChat';
import { useOrder } from '@/composables/useOrder';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'TheOrderState',
    components: {
        IconAndText,
        IconLocation,
        IconCircle,
        IconStore,
        BtnChat,
        BtnByState: defineAsyncComponent(() => import('@/components/buttons/BtnByState.vue')),
        BaseLoading,
    },
    props: {
        oneOrder: {
            type: Boolean,
            default: false,
        },
        order: {
            type: Object as PropType<OrderModel>,
            required: true,
        },
        showCircleIcon: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const { orderUnreadMessageCount, loadChannelList, isConnected } = useChat();
        const { orderRejected } = useOrder();
        const totalUnreadMessages = computed<number>(() => orderUnreadMessageCount(props.order?.id.toString()));

        return {
            isConnectedToSendbird: isConnected,
            totalUnreadMessages,
            loadChannelList,
            orderRejected,
        };
    },
    data() {
        return {
            isLoading: false,
            isDisable: false,
        };
    },
    computed: {
        ...mapGetters({
            baseConfiguration: 'user/baseConfiguration',
        }),
        bgColor(): string {
            return baseOrderState(this.order.state).primaryColor;
        },
        canCall(): boolean {
            return (
                !this.order.shopper?.name.length ||
                ![OrderState.ORDER_DELIVERED_RATED].includes(this.order.state) ||
                (this.baseConfiguration?.chatServiceEnabled ?? false)
            );
        },
        canChat(): boolean {
            if (!this.order.shopper || !this.baseConfiguration.chatServiceEnabled) return false;
            return [
                OrderState.SHOPPER_ASSIGNED,
                OrderState.SHOPPER_BUYING,
                OrderState.SHOPPER_DELIVERING,
                OrderState.SHOPPER_IN_DESTINY,
                OrderState.ORDER_WAITING_REPLACED_CONFIRMATION,
                OrderState.PAYMENT_REJECTED,
                OrderState.EXTRA_ITEMS_PAYMENT_IN_PROCESS,
                OrderState.STORE_PURCHASE_COMPLETED,
            ].includes(this.order.state);
        },
        color(): string {
            return baseOrderState(this.order.state).txtColor;
        },
        isRedColor(): boolean {
            return [OrderState.PAYMENT_REJECTED, OrderState.EXTRA_ITEMS_PAYMENT_REJECTED].includes(this.order.state);
        },
        showDivider(): boolean {
            return ![
                OrderState.SHOPPER_DELIVERING,
                OrderState.SHOPPER_IN_DESTINY,
                OrderState.ORDER_WAITING_REPLACED_CONFIRMATION,
                OrderState.STORE_PURCHASE_COMPLETED,
            ].includes(this.order.state);
        },
    },
    watch: {
        isConnectedToSendbird: async function (_) {
            if (this.oneOrder) await this.loadChannelList([this.order.id]);
        },
    },
    async mounted() {
        if (!this.oneOrder) return;
        await this.loadChannelList([this.order.id]);
        if (this.order.state !== OrderState.PAYMENT_REJECTED) return;
        this.orderRejected = {
            orderId: this.order.id,
            state: this.order.state,
            messageToShow: this.order.paymentAttemptsMessage ?? this.order.payment_attempt_message,
            suggested_payment_method: this.order.suggested_payment_method,
            payment_rejected_context: this.order.payment_rejected_context,
            final_cost: this.order.payment_rejected_context.amount,
        };
    },
    methods: {
        btnState(isLoading: boolean): void {
            this.isDisable = isLoading;
        },
        goToChat(): void {
            this.$store.commit('orders/isSelectedOrder', true);
            this.$router.push({
                name: appRoutesMap.OrderMap.ChatTab,
                params: {
                    orderId: this.order.id,
                },
            });
        },
        async goToOrder(): Promise<void> {
            if (this.isDisable || this.isLoading) return;
            this.isLoading = true;
            this.$store.commit('orders/isSelectedOrder', true);
            await this.$router.push({
                name: appRoutesMap.OrderMap.StateTab,
                params: {
                    orderId: this.order.id,
                },
            });
            this.isLoading = false;
        },
        onCLickBtnByState() {
            this.$store.commit('orders/orderRateSelected', this.order);
        },
    },
};
</script>

<style lang="scss" scoped>
@import './the-order-state.scss';
</style>
