import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import { MaxiBalanceModel } from '@/models/maxiDollars/MaxiBalance';
import { MaxiProgramModel } from '@/models/maxiDollars/MaxiProgram';
import { TiptiCardHistoryExchangeModel } from '@/models/historyExchange/TiptiCardHistoryExchange';

export default {
    program(state, program: MaxiProgramModel) {
        state.program = program;
    },
    maxiCardSelected(state, card: PaymentMethodModel) {
        state.maxiCardSelected = card;
    },
    history(state, history: Array<TiptiCardHistoryExchangeModel>) {
        state.history = history;
    },
    balance(state, balance: MaxiBalanceModel) {
        state.balance = balance;
    },
};
