import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38080778"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-tile" }
const _hoisted_2 = { class: "settings-tile__title__wrapper" }
const _hoisted_3 = { class: "settings-tile__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["settings-tile__title", { 'settings-tile__title--no-border': $props.removeBottomBorder }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "title-icon", {}, undefined, true),
        _createTextVNode(" " + _toDisplayString($props.title), 1)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ])
  ]))
}