<template>
    <base-icon
        :icon-name="$t('icon.payment_methods')"
        :width="width"
        :height="height"
        viewBox="0 0 64 66"
        :iconColor="color"
    >
        <path
            d="M50.732 17.0293C51.1887 17.0293 51.6453 16.7249 51.6453 16.116C51.6453 15.8116 51.4931 15.5072 51.1887 15.3549L17.7007 0.589797C15.7219 -0.323511 13.2864 0.589797 12.3731 2.56863L0.652297 28.7501C-0.261011 30.729 0.652297 33.1645 2.63113 34.0778L9.48094 36.9699C10.0898 37.2744 10.8509 36.9699 11.1553 36.361C11.1553 36.2088 11.3076 36.0566 11.3076 35.9044V24.7925C11.3076 20.5304 14.8086 17.0293 19.0707 17.0293H50.732Z"
        />
        <path
            d="M59.5608 20.6836H19.0708C16.7876 20.6836 14.9609 22.5102 14.9609 24.7935V53.4105C14.9609 55.6937 16.7876 57.5204 19.0708 57.5204H24.5507C24.3985 56.7593 24.3985 55.846 24.3985 55.0849C24.3985 54.1716 24.5507 53.2583 24.7029 52.3449H20.1364V38.6453H58.4953V52.3449H54.081C54.2332 53.2583 54.3854 54.1716 54.3854 55.0849C54.3854 55.846 54.3854 56.7593 54.2332 57.5204H59.713C61.9963 57.5204 63.8229 55.6937 63.8229 53.4105V24.6413C63.6707 22.5102 61.8441 20.6836 59.5608 20.6836ZM58.4953 30.1211H20.1364V25.859H58.4953V30.1211Z"
        />
        <path
            d="M39.3155 44.4277C33.5313 44.4277 28.8125 49.1465 28.8125 54.9308C28.8125 60.7151 33.5313 65.4338 39.3155 65.4338C45.0998 65.4338 49.8186 60.7151 49.8186 54.9308C49.8186 49.1465 45.0998 44.4277 39.3155 44.4277ZM42.3599 59.0407C41.9033 59.6495 41.2944 59.954 40.5333 60.1062C40.2289 60.1062 40.0766 60.4106 40.0766 60.7151C40.0766 61.0195 40.0766 61.3239 40.0766 61.6284C40.0766 61.7806 39.9244 62.085 39.7722 62.085C39.7722 62.085 39.7722 62.085 39.62 62.085C39.3155 62.085 39.0111 62.085 38.5545 62.085C38.25 62.085 38.0978 61.9328 38.0978 61.6284V61.0195C38.0978 60.5628 38.0978 60.5628 37.6411 60.4106C37.0323 60.2584 36.4234 60.1062 35.8145 59.954C35.3579 59.8018 35.3579 59.6495 35.5101 59.1929C35.6623 58.8884 35.6623 58.4318 35.8145 58.1274C35.9667 57.6707 35.9667 57.6707 36.4234 57.8229C37.0323 58.1274 37.6411 58.2796 38.4022 58.4318C38.8589 58.4318 39.3155 58.4318 39.7722 58.2796C40.2289 58.1274 40.5333 57.5185 40.3811 57.0618C40.3811 56.9096 40.2289 56.7574 40.0766 56.6052C39.9244 56.453 39.62 56.3007 39.3155 56.1485C38.5545 55.8441 37.9456 55.5397 37.3367 55.2352C36.2712 54.7786 35.6623 53.713 35.6623 52.4953C35.6623 51.1253 36.5756 50.0598 37.7934 49.7554C38.4022 49.4509 38.4022 49.4509 38.4022 48.8421V48.2332C38.4022 47.7765 38.5545 47.7765 38.8589 47.7765H39.3155C40.2289 47.7765 40.2289 47.7765 40.2289 48.6898C40.2289 49.2987 40.2289 49.2987 40.8377 49.4509C41.2944 49.6032 41.751 49.6031 42.2077 49.9076C42.3599 49.9076 42.5121 50.212 42.5121 50.3642C42.3599 50.8209 42.3599 51.1253 42.2077 51.582C42.0555 52.0386 41.9033 52.0386 41.5988 51.8864C40.8377 51.582 40.0766 51.4298 39.3155 51.4298C39.1633 51.4298 38.8589 51.4298 38.7067 51.582C38.25 51.7342 38.0978 52.1909 38.0978 52.6475C38.0978 52.7997 38.25 52.9519 38.4022 53.1042C38.7067 53.2564 39.0111 53.5608 39.3155 53.713C39.9244 54.0175 40.5333 54.1697 41.1422 54.4741C42.6643 55.2352 43.2732 57.2141 42.5121 58.7362C42.5121 58.7362 42.5121 58.8885 42.3599 59.0407Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-payment-methods',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 64 * this.size;
        },
        height(): number {
            return 66 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
