<template>
    <div class="skeleton-categories" :class="{ 'skeleton-categories--grid-only-categories': gridOnlyCategories }">
        <base-card v-for="index in 18" :key="index" class="skeleton-category animate" padding="0.5rem"> </base-card>
    </div>
</template>

<script>
import BaseCard from '@/components/cards/BaseCard.vue';

export default {
    name: 'skeleton-categories',
    props: {
        gridOnlyCategories: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BaseCard,
    },
};
</script>

<style lang="scss" scoped>
@import './skeleton-categories.scss';
</style>
