import { AlertNames } from '@/enums/alertNames';
import { ShippingLimitModel } from '@/models/alerts/ShippingLimitModel';
import { TiptiAlertModel } from '@/models/alerts/AlertOpenRetailer';

export class AlertBuilderModel {
    readonly alerts: Array<TiptiAlertModel> = [];

    constructor(alerts: Array<TiptiAlertModel>) {
        this.alerts = alerts;
    }

    private static fromJson(data: object): TiptiAlertModel {
        if (!data || !Object.keys(data).length) return null;
        if (data['alert_type'] === AlertNames.shippingLimit) {
            return ShippingLimitModel.fromJson(data);
        }
        return TiptiAlertModel.fromJson(data);
    }

    static build(data: object): Array<TiptiAlertModel> {
        if (!data) return [];
        return (data as []).map(AlertBuilderModel.fromJson).filter(alert => !!alert);
    }
}
