
import BtnSolid from '@/components/buttons/BtnSolid.vue';

const __default__ = {
    name: 'no-content',
    inheritAttrs: true,
    components: { BtnSolid },
    emits: ['on-action'],
    props: {
        title: {
            type: String,
            default: '',
        },
        colorTitle: {
            type: String,
            default: '#FF9012',
        },
        colorBtn: {
            type: String,
            default: '#FF9012',
        },
        description: {
            type: String,
            default: '',
        },
        ctaText: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "9ec3bf0a": (_ctx.colorTitle)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__