import { useStore } from 'vuex';
import { computed } from 'vue';
import { CartModel } from '@/models/cart/CartModel';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import { CartTypes } from '@/modules/core/types/CartType';
import { ProductAnalyticInterface, ProductModel } from '@/models/product/ProductModel';
import { useCartShared } from '@/store/useCartShared';
import { storeToRefs } from 'pinia';

export const useCart = () => {
    const $store = useStore();
    const { show: showSharedCart } = storeToRefs(useCartShared());

    const cart = computed<CartModel>(() => $store.getters['cart/cart']);

    const showCart = computed<boolean>({
        get: () => $store.getters['cart/showCart'],
        set: (value) => $store.commit('cart/showCart', value),
    });
    const productsForAnalytics = computed<CartModel>(() => $store.getters['cart/productsForAnalytics']);
    const productsForSegment = computed<ProductAnalyticInterface[]>(() => $store.getters['cart/productsForSegment']);
    const cartType = computed<CartTypes>(() => $store.getters['app/cartType']);
    const personalCartId = computed<number>(() => $store.getters['cart/personalCartId']);
    const cartAmount = computed<number>(() => $store.getters['cart/cartAmount']);
    const retailerIds = computed<number>(() => $store.getters['cart/retailerIds']);

    const isExtraItemCart = computed<boolean>(() => cartType.value === CartTypes.extraItem);
    const isMainCart = computed<boolean>(() => cartType.value === CartTypes.main);
    const isFicoaCart = computed<boolean>(() => cartType.value === CartTypes.ficoa);
    const fetchCart = async (retailerIds?: string): Promise<void> =>
        await $store.dispatch('cart/fetchCart', {
            retailerIds,
        });

    const fetchQuickCart = async (): Promise<void> => await $store.dispatch('cart/fetchQuickCart');

    const retailersWithNormalProducts = computed<Array<CartRetailerModel>>(() =>
        cart.value.retailers.filter((retailer) => retailer.products.some((product) => !product.uniqueDelivery)),
    );

    const products = computed<ProductModel[]>(() => {
        if (isMainCart.value) return $store.getters['cart/products'];
        return $store.getters['extraItems/products'];
    });

    const findProduct = (id: number) => products.value.find((prod) => prod.stockItemId == id);

    const findRetail = (id: number) => cart.value?.retailers?.find((ret: CartRetailerModel) => ret.id === id);

    const hasDeliveryPacking = computed<boolean>(() => cart.value?.retailers?.some((item) => !!item?.isDelivery));

    return {
        cart,
        showCart,
        isFicoaCart,
        productsForAnalytics,
        productsForSegment,
        personalCartId,
        cartAmount,
        retailerIds,
        showSharedCart,
        isExtraItemCart,
        isMainCart,
        fetchCart,
        fetchQuickCart,
        retailersWithNormalProducts,
        products,
        findProduct,
        findRetail,
        hasDeliveryPacking,
    };
};
