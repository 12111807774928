<template>
    <CarouselForProducts
        :is-new-products="customCat === 'NEW_PRODUCTS'"
        :items="products"
        :section="options[customCat].section"
        :show-only-see-more-action="showOnlySubHeadAction"
        :show-special-product-card="showSpecialProductCard"
        :state="requestState"
        :subheader-name="options[customCat].name"
        :track-item-section="options[customCat].section"
        has-subheader-padding
        @reached-end="fetchProducts"
        @on-click-subheader="goToSeeMore"
        @on-gm-items="getProductForHome"
    >
        <template #header>
            <slot name="header" />
        </template>
    </CarouselForProducts>
    <RecommendationsProducts
        v-if="productsRecommended.length"
        :items="productsRecommended"
        @on-close="closeRecommendationsProducts"
    />
</template>
<script lang="ts" setup>
import { CustomCategories } from '@/modules/core/errors/CustomCategories';
import { usePagination } from '@/modules/core/components/paginatinos/usePagination';
import { PropType } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import { useUser } from '@/composables/useUser';
import CarouselForProducts from '@/components/carousels/CarouselForProducts.vue';
import RecommendationsProducts from '@/modules/main-products/components/RecommendationsProducts.vue';
import { useRecommendationsProducts } from '@/modules/main-products/composables/useRecommendationsProducts';
import { useProductStore } from '@/store/useProductStore';
import { appRoutesMap } from '@/router/appRoutesMap';
import { NewCategoryModel } from '@/models/product/CategoryModel';
import { useApp } from '@/composables/useApp';

const props = defineProps({
    customCat: {
        type: String as PropType<keyof typeof CustomCategories | 'DISCOUNTS'>,
        required: true,
    },
    retailerId: {
        type: Number,
        default: 0,
    },
    showSpecialProductCard: {
        type: Boolean,
        default: false,
    },
    showOnlySubHeadAction: {
        type: Boolean,
        default: false,
    },
});

const { $store, $t, $router } = useApp();
const { profile } = useUser();
const { page, requestState, canFetch, data: products } = usePagination<ProductModel>();
const { promotionPersonalized, recommendationsSovi, predictions, newProducts, yourProducts } = useProductStore();
const { productsRecommended, closeRecommendationsProducts, getProductForHome } = useRecommendationsProducts();

const options = {
    YOUR_PRODUCTS: {
        name: $t("txt['main-products__your-products']"),
        section: 'HOME_RECOMMENDATIONS',
        fetch: yourProducts,
    },
    NEW_PRODUCTS: {
        name: $t('txt.main-products__new-products'),
        section: 'NEW_PRODUCTS',
        fetch: newProducts,
    },
    RECOMMENDED_PRODUCTS: {
        name: $t("txt['main-products__recommended_for_you']"),
        section: 'HOME_SOVI_RECOMMENDATIONS',
        fetch: recommendationsSovi,
    },
    PREDICTION_PRODUCTS: {
        name: $t('txt.main-products__predictions', { user: profile.value.name }),
        section: 'HOME_PREDICTIONS',
        fetch: predictions,
    },
    DISCOUNTS: {
        name: props.showOnlySubHeadAction ? '' : $t('txt.main-products__discount-products'),
        section: 'HOME_DISCOUNT_PRODUCTS',
        fetch: promotionPersonalized,
    },
};

const goToSeeMore = () => {
    if (props.customCat === 'DISCOUNTS') return goToPromotion();
    $store.commit('categories/customCategory', {
        name: props.customCat,
        label: options[props.customCat].name,
    });
    $router.push({ name: appRoutesMap.retail.seeMore });
};

const fetchProducts = async (): Promise<void> => {
    if (!canFetch.value) return;
    requestState.value = 'LOADING';
    const { next, results } = await options[props.customCat].fetch({
        retailerId: props.retailerId,
        page: page.value,
    });
    if (!results?.length) {
        requestState.value = 'LOAD-ENDED';
        return;
    }
    products.value = results;
    requestState.value = next ? 'NONE' : 'LOAD-ENDED';
    if (requestState.value === 'LOAD-ENDED') return;
    page.value++;
};

const goToPromotion = (): void => {
    $store.commit(
        'categories/setCategorySelected',
        new NewCategoryModel({
            id: 0,
            name: $t('txt.main-products__discount-products'),
            request_subcategories: true,
            isDiscount: true,
        }),
    );
    $router.push({
        name: appRoutesMap.retail.baseCategoryView,
        params: {
            category: $t('txt.main-products__discount-products'),
        },
    });
};
await fetchProducts();
</script>
