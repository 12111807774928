import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_bone = _resolveComponent("base-bone")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!

  return (_openBlock(), _createBlock(_component_base_card_flat, {
    style: _normalizeStyle($options.style),
    "border-color": "#fff",
    padding: "0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_bone)
    ]),
    _: 1
  }, 8, ["style"]))
}