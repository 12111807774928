<template>
    <div class="filter" :class="{ 'filter--active': filterApplied?.id == filter.id }" @click.stop="filterByType">
        <span v-if="filter.tag_text" v-translate="filter.tag_text" class="filter__badge" />
        <template v-if="filter.isAllRetailer">
            <img
                v-if="filterApplied?.id == filter.id"
                class="filter__logo"
                alt="All store active"
                src="@/assets/img/filters/all-store-active.svg"
            />
            <img
                v-else
                class="filter__logo"
                alt="All store inactive"
                src="@/assets/img/filters/all-store-inactive.svg"
            />
        </template>
        <ImageLoader
            v-else
            class="filter__logo"
            :delay="100"
            :src="filterApplied?.id == filter.id ? filter.active_icon : filter.inactive_icon"
        />

        <p class="filter__type" :class="{ 'filter__type--active': filterApplied?.id == filter.id }">
            <span v-if="filter.isAllRetailer"> {{ $t(`general.${filter.retailer_type}`) }} </span>
            <span v-else v-translate="filter.name ?? filter.retailer_type"></span>
        </p>
    </div>
</template>
<script lang="ts" setup>
import { RetailerFilter } from '@/modules/retailers/models/RetailerFilterModel';
import { PropType } from 'vue';
import ImageLoader from '@/modules/core/components/loaders/ImageLoader.vue';
import { useApp } from '@/composables/useApp';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

const props = defineProps({
    filter: {
        type: Object as PropType<RetailerFilter>,
        required: true,
    },
});
const { $store, $route } = useApp();
const { filterApplied, filteredRetailers } = useRetailer();

const filterByType = (): void => {
    filterApplied.value = props.filter;
    setTimeout(() => {
        $store.dispatch('segment/selectRetailCategory', {
            screen: $route.path,
            section: 'OTHER_RETAILERS_' + props.filter.retailer_type.toUpperCase(),
            name_category: props.filter.retailer_type,
            name_retailers: filteredRetailers.value.map((retailer) => retailer.name) ?? [],
        });
    }, 100);
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss';

.filter {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    width: 4.5rem;

    @include phone {
        width: 3.5rem;
    }

    &__badge {
        @include caption;
        background-color: $orange;
        border-radius: 20px;
        color: white;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        padding: 0.25rem;
    }

    &--active {
        border-bottom: 2px solid #cfde00;
    }

    &__logo {
        margin-top: auto;
        width: 3.5rem;
        height: 3.5rem;
        object-fit: contain;
        @include phone {
            width: 3rem;
            height: 3rem;
        }
    }

    &__type {
        @include overline;
        text-align: center;
        font-weight: 600;
        margin-top: 7px;
        width: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--active {
            color: #ff9012;
        }
    }
}
</style>
