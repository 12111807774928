<template>
    <modal-manager
        :has-close="true"
        :is-loading="false"
        :title="modalTitle"
        @on-close="$emit('on-close')"
        @on-dismiss="$emit('on-close')"
        :is-disabled="disableBtn"
        :show-bottom-sheet-title="showBottomSheetTitle"
        :show-buttons="true"
        :has-header="true"
        :only-confirmation-btn="true"
        :accept-button-text="$t('txt.transfer_to_tipti')"
        @on-accept="converter"
    >
        <template #content>
            <div class="converter">
                <slot name="header" />
                <div class="converter__content">
                    <slot name="field-names" />
                </div>
                <div class="converter__content">
                    <input
                        v-model.number="amount"
                        type="number"
                        class="input_amount"
                        :class="{ 'input_amount--error': hasError }"
                    />
                    <slot name="equal-symbol" v-if="useIcon" />
                    <span v-else class="converter__text"> = </span>
                    <p class="converter__text converter__text--equivalence">{{ currency }} {{ convertValue }}</p>
                </div>
                <slot name="error" v-if="hasError" />
            </div>
        </template>
    </modal-manager>
</template>
<script lang="ts">
import ModalManager from '@/components/alerts/ModalManager/ModalManager.vue';
import { useUser } from '@/composables/useUser';

export default {
    name: 'converter-to-tipti-card',
    components: { ModalManager },
    props: {
        title: {
            type: String,
            default: '',
        },
        useIcon: {
            type: Boolean,
            default: false,
        },
        equivalence: {
            type: [Number, String],
            validator: function(value: string | number) {
                if (typeof value === 'number' && value >= 0) return true;
                return typeof value === 'string' && !isNaN(+value) && +value >= 0;
            },
        },
        balance: {
            type: [Number, String],
            validator: function(value: string | number) {
                if (typeof value === 'number' && value >= 0) return true;
                return typeof value === 'string' && !isNaN(+value) && +value >= 0;
            },
        },
        showBottomSheetTitle: {
            type: Boolean,
            default: true,
        },
        hasInitialAmount: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-close', 'converter'],
    data() {
        return {
            amount: this.hasInitialAmount ? this.balance.replaceAll(',', '.') : '0.0',
        };
    },
    setup() {
        const { currency } = useUser();
        return { currency };
    },
    watch: {
        amount(newVal, _) {
            newVal = newVal.toString();
            if (newVal.includes('.')) {
                this.amount = newVal.split('.')[0] + '.' + newVal.split('.')[1].slice(0, 2);
            }
        },
    },
    computed: {
        modalTitle(): string {
            return !this.title.length ? this.$t('general.converter') : this.title;
        },
        convertValue(): string {
            return (+this.equivalence * +this.amount)?.toFixed(2) ?? '0.00';
        },
        hasError(): boolean {
            return +this.amount > +this.balance;
        },
        disableBtn(): boolean {
            if (this.hasError) return true;
            if (+this.amount < 1) return true;
            return !this.amount;
        },
    },
    methods: {
        converter(): void {
            if (this.hasError) return;
            this.$emit('converter', +this.amount);
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/scss';
.converter {
    margin: $margin-md;
    margin-left: $margin-md * 2;
    &__content {
        display: grid;
        align-items: center;
        justify-items: center;
        grid-template-columns: 1fr 15% 1fr;
        margin-bottom: $margin-xsm;
    }
}
.converter__text {
    @include overline;
    font-weight: 600;
    &--orange {
        @include button;
        color: $orange;
        margin-bottom: $margin-md;
    }
    &--error {
        width: 100%;
        text-align: center;
        color: $error;
    }
    &--equivalence {
        width: 100%;
        font-weight: 400;
        text-align: center;
    }
}
.input_amount {
    all: unset;
    @include overline;
    background-color: #f5f5f5;
    border: 1px solid $disable;
    border-radius: $radius-md;
    padding: $padding-sm;
    text-align: center;
    &--error {
        color: $error;
    }
}
::-webkit-input-placeholder {
    @include overline;
    color: $disable;
}
::-moz-placeholder {
    @include overline;
    color: $disable;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
</style>
