<template>
    <section class="addresses">
        <dotted-rectangle :button-text="$t('txt.address_add-address')" @click="showAddressModal = true" />
        <list-address :section="section" :show-check="showCheck" @on-check="(value) => $emit('on-check', value)" />
    </section>
    <tipti-map-modal v-if="showAddressModal" :section="section" @on-close="showAddressModal = false" />
</template>

<script lang="ts" setup>
import { AddressModel } from '@/models/address/AddressModel';

import DottedRectangle from '@/components/dottedRectangle/DottedRectangle.vue';
import ListAddress from './components/ListAddress.vue';
import TiptiMapModal from '@/components/modal/tiptiMapModal/TiptiMapModal.vue';
import { onMounted, ref } from 'vue';
import { useApp } from '@/composables/useApp';

const showAddressModal = ref(false);
defineProps({
    showCheck: {
        type: Boolean,
        default: false,
    },
    checkWithConfirmation: {
        type: Boolean,
        default: false,
    },
    section: {
        type: String,
        default: '',
    },
});
defineEmits<(e: 'on-check', value: AddressModel) => void>();
const { $store } = useApp();
onMounted(() => {
    $store.dispatch('segment/registerSegmentPage', { event: 'Address' });
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.addresses {
    padding: $padding-lg;
}
</style>
