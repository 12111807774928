import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-id", "data-name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "scroller-item",
    "data-id": $props.id,
    "data-name": $props.name
  }, [
    _renderSlot(_ctx.$slots, "content")
  ], 8, _hoisted_1))
}