<template>
    <section class="stories-bar">
        <carousel-tipti :itemsAmount="10">
            <div v-for="index in 10" :key="index" class="story-miniature">
                <div class="story-icon animate" />
            </div>
        </carousel-tipti>
    </section>
</template>

<script lang="ts">
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';

export default {
    name: 'skeleton-stories',
    components: {
        CarouselTipti,
    },
};
</script>
<style lang="scss" scoped>
@import './skeleton-stories.scss';
</style>
