import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Teleport as _Teleport, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d68e580"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "summary" }
const _hoisted_2 = { class: "summary-information" }
const _hoisted_3 = { class: "summary--sticky" }
const _hoisted_4 = { class: "summary-footer" }
const _hoisted_5 = { class: "footer-btn" }
const _hoisted_6 = { class: "footer-btn__price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_checkout = _resolveComponent("menu-checkout")!
  const _component_dropdown_retailer_products = _resolveComponent("dropdown-retailer-products")!
  const _component_instructions = _resolveComponent("instructions")!
  const _component_balance = _resolveComponent("balance")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_general_alert = _resolveComponent("general-alert")!
  const _component_creating_order_alert = _resolveComponent("creating-order-alert")!
  const _component_alert_tipti_card = _resolveComponent("alert-tipti-card")!
  const _component_snack_bar = _resolveComponent("snack-bar")!
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("aside", _hoisted_2, [
        _createVNode(_component_menu_checkout),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart?.retailers, (retailer) => {
          return (_openBlock(), _createBlock(_component_dropdown_retailer_products, {
            key: retailer.id,
            "items-prescription": _ctx.itemsPrescription,
            retailer: retailer,
            onOnPrescription: _cache[0] || (_cache[0] = (val) => $data.loadPrescription.push(val)),
            onOnRemovePrescription: _cache[1] || (_cache[1] = (val) => $options.removePrescriptionItem(val))
          }, null, 8, ["items-prescription", "retailer"]))
        }), 128)),
        _createVNode(_component_instructions)
      ]),
      _createElementVNode("aside", _hoisted_3, [
        _createVNode(_component_balance, {
          balance: _ctx.cart?.totalDetails,
          discounts: _ctx.cart?.discountApplied,
          "is-pick-up": $options.isPickUp,
          "origin-screen": 'CHECKOUT',
          "show-shipping-cost": ""
        }, null, 8, ["balance", "discounts", "is-pick-up"])
      ])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("footer", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("p", _hoisted_6, null, 512), [
            [_directive_currency, _ctx.cart?.totalDetails?.orderCost]
          ]),
          _createVNode(_component_btn_solid, {
            "is-disabled": !$options.hasAllData,
            "is-loading": $data.isLoading,
            txt: _ctx.$t('txt.checkout__complete-purchase'),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (
                        $options.hasAllData && !$data.isLoading
                            ? $options.isCreationAvailable()
                                ? $options.checkTiptiCardUsage()
                                    ? null
                                    : $options.createOrder()
                                : ($data.showBlockingAlert = true)
                            : null
                    ))
          }, null, 8, ["is-disabled", "is-loading", "txt"])
        ])
      ])
    ])),
    ($data.showBlockingAlert)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 0,
          "accent-header": true,
          "accept-button-txt": $options.isPendingPayment ? _ctx.$t('cta.go-payments') : _ctx.$t('cta.accept'),
          message: $data.messageBlockingAlert,
          "only-confirmation-btn": true,
          "show-close-icon": true,
          title: $data.titleBlockingAlert,
          onOnClose: _cache[3] || (_cache[3] = ($event: any) => ($data.showBlockingAlert = false)),
          onOnAccept: _cache[4] || (_cache[4] = ($event: any) => ($options.isPendingPayment ? $options.goToPayments() : ($data.showBlockingAlert = false)))
        }, null, 8, ["accept-button-txt", "message", "title"]))
      : _createCommentVNode("", true),
    ($data.showCreatingOrder)
      ? (_openBlock(), _createBlock(_component_creating_order_alert, { key: 1 }))
      : _createCommentVNode("", true),
    ($data.showTiptiCardAlert)
      ? (_openBlock(), _createBlock(_component_alert_tipti_card, {
          key: 2,
          onOnUseBalance: _cache[5] || (_cache[5] = ($event: any) => ($options.handleTiptiCardBalance(true))),
          onOnAccumulate: _cache[6] || (_cache[6] = ($event: any) => ($options.handleTiptiCardBalance(false))),
          onOnClose: $options.onCloseTiptiCardAlert
        }, null, 8, ["onOnClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_snack_bar, {
      body: $data.snackBarBody,
      "is-active": $data.showSnackBar,
      "is-success": true,
      title: _ctx.$t('txt.checkout__order-not_created'),
      onOnSnackbarClose: _cache[7] || (_cache[7] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "title"])
  ], 64))
}