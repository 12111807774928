<script lang="ts" setup>
import { useDays } from '@/composables/useDays';
import BaseCard from '@/components/cards/BaseCard.vue';
import { PropType, ref, shallowRef } from 'vue';
import { CreditCardExpensive, CreditCardExpensiveResume } from '@/views/wallet/WalletModels';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import NoContent from '@/components/noContent/NoContent.vue';
import IconCreditCardInfo from '@/components/icons/IconCreditCardInfo.vue';
import { logger } from '@/utils/logger';
import IconCreditCardDetailWallet from '@/components/icons/IconCreditCardDetailWallet.vue';
import { ResponsePaged } from '@/modules/core/models/ResponseModels';
import { useIntersectionObserver } from '@vueuse/core';
import dayjs from 'dayjs';

const props = defineProps({
    dateToFetch: {
        type: String,
        required: true,
    },
    resumeMonth: {
        type: Object as PropType<CreditCardExpensiveResume>,
        default: undefined,
    },
    cardId: {
        type: Number,
        required: true,
    },
});
const { doFormat } = useDays();
const billsDetail = shallowRef<CreditCardExpensive[]>([]);
const page = ref(1);
const isLoading = ref(false);
const isLastPage = ref(false);
const showObserver = ref(false);
const observer = ref<HTMLElement>();

const { stop } = useIntersectionObserver(observer, async ([{ isIntersecting }]) => {
    if (isIntersecting) await fetchData();
});

const fetchData = async () => {
    try {
        if (isLoading.value || isLastPage.value) return;
        isLoading.value = true;
        showObserver.value = false;
        const { data } = await axiosInstance().get<ResponsePaged<CreditCardExpensive>>(
            `v2/client_profile/payment_method/${props.cardId}/expense_details`,
            {
                params: {
                    date: props.dateToFetch,
                    page_size: 5,
                    page: page.value,
                    // used to disable browser cache
                    helper_param: dayjs(),
                },
            },
        );
        if (data?.next) page.value++;
        else {
            isLastPage.value = true;
            stop();
        }
        billsDetail.value = [...billsDetail.value, ...data.results];
    } catch (e) {
        logger('Init card detail', e);
        isLastPage.value = true;
        stop();
    } finally {
        isLoading.value = false;
        setTimeout(() => {
            showObserver.value = true;
        }, 250);
    }
};

await fetchData();
</script>
<template>
    <section v-if="billsDetail.length" class="membership-detail__monthly-resume">
        <div class="membership-detail__resume-item">
            <strong v-currency="resumeMonth.total_amount_general" class="membership-detail__resume-item-amount" />
            <p class="membership-detail__resume-item-text">{{ $t('txt.total_expense') }}</p>
        </div>
        <div class="membership-detail__resume-item">
            <strong v-currency="resumeMonth.total_amount_monthly" class="membership-detail__resume-item-amount" />
            <p class="membership-detail__resume-item-text">
                {{
                    doFormat({
                        date: dateToFetch,
                        format: 'MMM',
                    })
                }}
                {{ $t('txt.expense') }}
            </p>
        </div>
        <div class="membership-detail__resume-item">
            <strong class="membership-detail__resume-item-amount">
                {{ resumeMonth.number_orders_monthly ?? 0 }}
            </strong>
            <p class="membership-detail__resume-item-text membership-detail__resume-item-text--orders">
                # {{ $t('txt.orders') }}
            </p>
        </div>
    </section>
    <template v-if="resumeMonth.number_orders_monthly && billsDetail.length">
        <BaseCard
            v-for="detail in billsDetail"
            :key="detail.date"
            class="membership_separator membership-detail__date"
            padding="0"
        >
            <div class="membership-detail__date-header">
                <p
                    v-date.upper="{
                        date: detail.date,
                        format: 'dddd, D MMMM',
                    }"
                    class="membership-detail__date-header-text"
                />
                <p v-currency="detail.total" class="membership-detail__date-header-text" />
            </div>

            <article
                v-for="(order, index) in detail.orders"
                :key="order.order_number"
                class="membership-detail__order"
                :class="{ 'membership-detail__order--first': index === 0 }"
            >
                <IconCreditCardDetailWallet />
                <div class="membership-detail__order-item membership-detail__order-items">
                    <p class="membership-detail__order-item">{{ $t('txt.order') }} #{{ order.order_number }}</p>
                    <p class="membership-detail__order-item">
                        <span class="membership-detail__order-item--orange membership-detail__order-item--strong">{{
                            order.number_of_items
                        }}</span>
                        items
                    </p>
                </div>
                <span v-currency="order.cost" class="membership-detail__order-item" />
            </article>
        </BaseCard>
    </template>
    <NoContent v-else :description="$t('txt.credit_card_no_transactions')">
        <IconCreditCardInfo :size="4" color="#C7C7C7" />
    </NoContent>
    <div ref="observer" />
</template>
<style lang="scss" scoped>
@use '@/assets/scss/colors' as colors;
@use '@/assets/scss/type-system' as type;
@use '@/assets/scss/margin' as mg;

.membership_separator {
    margin-bottom: 0.5rem;
}

.membership-detail {
    &__monthly-resume {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: mg.$margin-md 0;
    }

    &__resume-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        &-amount {
            @include type.subtitle1;
            color: colors.$orange;
            font-weight: 600;
        }

        &-text {
            @include type.body2;

            &--orders {
                text-transform: capitalize;
            }
        }
    }

    &__date-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid colors.$border;
        padding: 1rem;

        &-text {
            @include type.body2;
        }
    }

    &__date {
        flex-direction: column;
        border: 1px solid colors.$orange !important;
    }

    &__order {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        gap: 0.5rem;
        margin: 0 1rem;
        border-top: 1px solid colors.$border;

        &--first {
            border-top: unset;
        }

        &-item {
            @include type.body2;

            &--orange {
                color: colors.$orange;
            }

            &--strong {
                font-weight: 800;
            }
        }

        &-items {
            margin-right: auto;
        }
    }
}
</style>
