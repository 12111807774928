<template>
    <button class="button-home-page" :class="buttonClasses">{{ text }}</button>
</template>

<script lang="ts">
export default {
    name: 'BtnHomePage',

    props: {
        text: {
            type: String,
            default: 'Click me!',
        },
        fontSize: {
            type: String,
            default: '16px',
        },
        borderRadius: {
            type: String,
            default: '4px',
        },
    },

    data() {
        return {
            showLogin: false,
            showRegister: false,
        };
    },
    computed: {
        stylesBtn(): object {
            return { 'border-radius': this.borderRadius };
        },
        stylesBtnTxt(): object {
            return {
                color: this.color,
                '--overflow': this.stretch ? 'unset' : 'hidden',
            };
        },

        isIconTextSpaceDistribution(): boolean {
            return this.withIcon && !this.centeredIcon;
        },
        buttonClasses() {
            return ['button', `button--font-${this.fontSize}`, `button--radius-${this.borderRadius}`];
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.button-home-page {
    @include headline4;
    font-weight: 600;
    padding: 8px 16px;
    background-color: $orange;
    color: $white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    @include tablet {
        font-size: 8px;
        cursor: pointer;
    }
    @include phone {
        font-size: 4px;
        border: none;
        cursor: pointer;
    }
}
</style>
