import { RestRetailersInfo, YourRetailersInfo } from '@/modules/retailers/models/OtherRetailersModel';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import { RetailerAvailabilityModel } from '@/modules/retailers/models/RetailerAvailabilityModel';
import { RetailerFilter } from '@/modules/retailers/models/RetailerFilterModel';
import { BannerModel } from '@/models/promotions/banners/BannerModel';
import { DeferredOptionByRetailer } from '@/modules/retailers/store/model';

export default {
    yourRetailers(state, yourRetailers: YourRetailersInfo) {
        state.yourRetailers = yourRetailers;
    },
    otherRetailers(state, otherRetailers: RestRetailersInfo) {
        state.otherRetailers = otherRetailers;
    },
    filters(state, filters: RetailerFilter[]) {
        state.filters = [...(filters ?? [])];
    },
    retailerSelected(state, retailer: RetailerModel) {
        state.retailerSelected = retailer;
    },
    originalRetailersAvailability(state, retailers: Array<RetailerAvailabilityModel>) {
        state.originalRetailersAvailability = retailers;
    },
    retailersAvailability(state, retailers: Array<RetailerAvailabilityModel>) {
        state.retailersAvailability = retailers;
    },
    bannerRetailers(state, banner?: BannerModel) {
        if (!banner?.retailerIds?.length) {
            state.bannerRetailers = [];
            return;
        }

        const _retailers: Array<RetailerModel> = [
            ...new Set(
                [...(state.yourRetailers?.retailers ?? ''), ...(state.otherRetailers?.retailers ?? '')].map((ret) =>
                    JSON.stringify(ret),
                ),
            ),
        ].map((ret) => JSON.parse(ret));

        state.bannerRetailers = _retailers.filter((retailer: RetailerModel) => {
            return banner?.retailerIds.includes(retailer.id);
        });
        state.bannerRetailersImg = banner.images;
    },
    retailersAlertLimitShowed(state) {
        state.retailersAlertLimitShowed.push(state.retailerSelected);
    },
    removeRetailerAlertLimitShowed(state, retailers: Array<number>) {
        if (!retailers || !retailers.length) {
            state.retailersAlertLimitShowed = [];
            state.cartRetailerItems = 0;
            return;
        }
        for (const retailer of retailers) {
            state.retailersAlertLimitShowed = state.retailersAlertLimitShowed.filter(
                (ret: RetailerModel) => ret.id !== retailer,
            );
        }
        state.cartRetailerItems = 0;
    },
    cartRetailerItems(state, retailerCartItem: number) {
        state.cartRetailerItems = retailerCartItem;
    },
    cleanAllRetailersData(state) {
        state.yourRetailers = {} as YourRetailersInfo;
        state.otherRetailers = [];
        state.filters = [];
    },
    deferredOptionByRetailer(state, input: DeferredOptionByRetailer) {
        state.deferredOptionByRetailer = input;
    },
};
