<template>
    <base-icon :height="height" :icon-color="color" :width="width" icon-name="email_footer" viewBox="0 0 32 27">
        <path
            d="M9.08991 8.05397C8.89008 7.94203 8.4908 7.85519 8.13872 7.85519C6.9882 7.85519 6.27027 8.74149 6.27027 9.71597C6.27027 10.3718 6.64444 10.7543 7.16557 10.7543C7.5885 10.7543 7.94813 10.547 8.19543 10.1393H8.21168C8.25279 10.5626 8.52399 10.7543 8.89856 10.7543C9.76294 10.7543 10.4174 10.0193 10.4174 8.85254C10.4174 7.51052 9.41058 6.54346 8.00472 6.54346C6.19879 6.54346 5.01562 7.95842 5.01562 9.5879C5.01562 11.1549 6.1837 12.1058 7.50907 12.1058C8.07658 12.1058 8.49278 12.0417 8.97164 11.8178L8.82877 11.3608C8.5084 11.5211 8.07635 11.6089 7.6702 11.6089C6.47976 11.6089 5.6252 10.8502 5.6252 9.51693C5.6252 7.99068 6.69614 7.03258 7.91764 7.03258C9.15656 7.03258 9.8272 7.83893 9.8272 8.8783C9.8272 9.79765 9.39621 10.2376 9.05232 10.2286C8.82763 10.2198 8.77252 10.0044 8.85116 9.53371L9.08991 8.05397ZM8.14664 9.27666C8.07487 9.73989 7.76376 10.1164 7.46795 10.1164C7.20401 10.1164 7.06866 9.92387 7.06866 9.62015C7.06866 9.00398 7.48382 8.48496 8.01153 8.48496C8.11515 8.48496 8.19551 8.50108 8.25961 8.51694L8.14664 9.27666Z"
            fill="url(#paint0_linear_548_2012)"
        />
        <path
            d="M8.02942 14.4247C7.94166 14.5079 7.82879 14.5506 7.71737 14.5506C7.60595 14.5506 7.49308 14.5087 7.40506 14.4266L0 7.42992V17.0248H15.434V7.33789L8.02942 14.4247Z"
            fill="url(#paint1_linear_548_2012)"
        />
        <path
            d="M2.97999 5.40837H12.4513V8.93597L14.7502 6.73581L7.61991 0L0.583984 6.73581L2.98002 8.99918L2.97999 5.40837Z"
            fill="url(#paint2_linear_548_2012)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_548_2012"
                gradientUnits="userSpaceOnUse"
                x1="7.71653"
                x2="7.71653"
                y1="6.54346"
                y2="12.1058"
            >
                <stop stop-color="#FFB561" />
                <stop offset="1" stop-color="#FF9012" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_548_2012"
                gradientUnits="userSpaceOnUse"
                x1="7.71701"
                x2="7.71701"
                y1="7.33789"
                y2="17.0247"
            >
                <stop stop-color="#FFB561" />
                <stop offset="1" stop-color="#FF9012" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_548_2012"
                gradientUnits="userSpaceOnUse"
                x1="7.66711"
                x2="7.66711"
                y1="0"
                y2="8.99916"
            >
                <stop stop-color="#FFB561" />
                <stop offset="1" stop-color="#FF9012" />
            </linearGradient>
        </defs>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconEmailFooter',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 32 * this.size;
        },
        height(): number {
            return 27 * this.size;
        },
    },
};
</script>
