<script lang="ts" setup>
import { computed, PropType, ref } from 'vue';
import { useDays } from '@/composables/useDays';
import dayjs, { Dayjs } from 'dayjs';
import { useText } from '@/composables/useText';

import BaseCard from '@/components/cards/BaseCard.vue';
import { GiftCardTransactionDTO, TransactionTypeEnum } from '@/views/wallet/WalletModels';
import IconArrowShaft from '@/components/icons/IconArrowShaft.vue';

defineOptions({
    name: 'GiftCardTransactionTile',
});
const props = defineProps({
    transaction: {
        type: Object as PropType<GiftCardTransactionDTO>,
        required: true,
    },
});

const { doFormat } = useDays();
const { capitalize } = useText();

const date = ref<Dayjs>(dayjs(props.transaction.date));
const isIncomingTransaction = props.transaction.type == TransactionTypeEnum.incoming;

const formattedDate = computed(() => doFormat({ date: date.value, format: 'MMMM D, YYYY' }));

const itemsText = 'item' + (props.transaction.items > 1 ? 's' : '');

const balanceSymbol = computed(() => (isIncomingTransaction ? '+' : '-'));
const transactionColor = computed(() => (isIncomingTransaction ? '#CFDE00' : '#DD4451'));
const transactionIconBackgroundColor = computed(() => (isIncomingTransaction ? '#FAFCE6' : '#FAE6E6'));
const arrowDirection = computed(() => (isIncomingTransaction ? '0deg' : '180deg'));
</script>

<template>
    <BaseCard class="gift-card-transaction-tile" padding="1rem">
        <div class="gift-card-transaction-tile__icon">
            <IconArrowShaft :size="0.8" :color="transactionColor" class="gift-card-transaction-tile__icon__svg" />
        </div>
        <div class="gift-card-transaction-tile__order-details">
            <p class="gift-card-transaction-tile__order-details__order-number transaction-text">
                {{ $t('general.order') + ' #' + transaction.orders }}
            </p>
            <p class="transaction-text">
                <span v-if="isIncomingTransaction" class="gift-card-transaction-tile__order-details__number-items">{{
                    $t('txt.gift-card-transaction-return')
                }}</span>
                <span v-else-if="!isIncomingTransaction">
                    <span class="gift-card-transaction-tile__order-details__number-items">
                        {{ transaction.items }}
                    </span>
                    {{ itemsText }}
                </span>
            </p>
        </div>
        <div>
            <p class="gift-card-transaction-tile__balance transaction-text">
                <span>{{ balanceSymbol }}</span>
                <span v-currency="transaction.amount"></span>
            </p>
            <p class="transaction-text">
                {{ capitalize(formattedDate) }}
            </p>
        </div>
    </BaseCard>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.gift-card-transaction-tile {
    @include body2;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 74px;
        height: 60px;
        border-radius: 10px;
        background-color: v-bind(transactionIconBackgroundColor);
        margin-right: 0.5rem;
        &__svg {
            rotate: v-bind(arrowDirection);
        }
    }
    &__order-details {
        flex-grow: 1;
        &__number-items {
            font-weight: 600;
            color: $orange;
        }
    }
    &__balance {
        font-size: 14px;
        color: v-bind(transactionColor);
        text-align: end;
    }
}

.transaction-text {
    margin: $margin-xsm 0;
}
</style>
