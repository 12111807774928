<template>
    <base-icon :icon-name="$t('icon.home')" :width="width" :height="height" viewBox="0 0 24 24" :iconColor="color">
        <path
            d="M23.5807 10.714L19.9867 7.04821V2.73071C19.9867 1.99755 19.3477 1.34585 18.6289 1.34585C17.9101 1.34585 17.2712 1.99755 17.2712 2.73071V4.19703L14.5557 1.42732C13.198 0.0424574 10.9617 0.0424574 9.52412 1.42732L0.419301 10.714C-0.139767 11.2842 -0.139767 12.1803 0.419301 12.6691C0.978369 13.1579 1.8569 13.2393 2.33611 12.6691L11.4409 3.46387C11.7604 3.13802 12.2396 3.13802 12.5591 3.46387L21.6639 12.7506C22.223 13.3208 23.1015 13.3208 23.5807 12.7506C24.1398 12.1803 24.1398 11.2842 23.5807 10.714Z"
        />
        <path
            d="M12.4791 6.15218C12.2395 5.9078 11.7603 5.9078 11.5207 6.15218L3.53399 14.2984C3.37426 14.4613 3.37426 14.6243 3.37426 14.7872V20.7339C3.37426 22.1188 4.49239 23.2593 5.85013 23.2593H9.84348V16.9867H14.316V23.2593H18.3094C19.6671 23.2593 20.7852 22.1188 20.7852 20.7339V14.7872C20.7852 14.6243 20.7054 14.4613 20.6255 14.2984L12.4791 6.15218Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-home',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 24 * this.size;
        },
        height(): number {
            return 24 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
