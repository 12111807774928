import { toZoneModel, ZoneModel } from '../address/ZoneModel';

class PickUpCenter {
    readonly id: number;
    readonly name: string;
    readonly city: ZoneModel;
    readonly sector: ZoneModel;
    readonly address: string;
    readonly logo: string;
    readonly markerLogo: string;
    geoPosition: string;
    latitude: string;
    longitude: string;

    constructor(
        id: number,
        name: string,
        logo: string,
        markerLogo: string,
        city: ZoneModel,
        sector: ZoneModel,
        address: string,
        geoPosition: string,
    ) {
        const _position: Array<string> = geoPosition.split(',');
        this.id = id;
        this.name = name;
        this.logo = logo;
        this.markerLogo = markerLogo;
        this.city = city;
        this.sector = sector;
        this.address = address;
        this.geoPosition = geoPosition;
        this.latitude = _position[0];
        this.longitude = _position[1];
    }
}

const toPickUpCenter = (data: object) => {
    return data
        ? new PickUpCenter(
              data['id'],
              data['name'] ?? '',
              data['franchise']?.['logo'] ?? '',
              data['franchise']?.['map_marker'] ?? '',
              toZoneModel(data['city']),
              toZoneModel(data['sector']),
              `${data['address_line1']} ${data['address_line2'] ?? ''}`,
              data['geo_position'],
          )
        : null;
};

export { PickUpCenter, toPickUpCenter };
