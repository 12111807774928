import { Sections } from '@/enums/sectionsOrigin';
class RecipeInstagram {
    id: number;
    position: number;
    title: string;
    deeplink: string;
    photo: string;

    constructor(id: number, position: number, title: string, deeplink: string, photo: string) {
        this.id = id;
        this.position = position;
        this.title = title;
        this.deeplink = deeplink;
        this.photo = photo;
    }
}

const toDeepLink = (link: string): string => {
    if (!link?.length) return '';
    const id: string = link.substring(link.lastIndexOf('=') + 1);
    //* A query params named section is added to reference the origin of the section from which it is being called.
    return `${window.origin}/deeplink?type=recipe&id=${id}&section=${Sections.TIPTI_RECIPES}`;
};

const toRecipeInstagram = (data: object) => {
    return data
        ? new RecipeInstagram(data['id'], data['position'], data['title'], toDeepLink(data['link']), data['photo'])
        : null;
};

export { toRecipeInstagram, RecipeInstagram };
