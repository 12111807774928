import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_search_category = _resolveComponent("search-category")!
  const _component_category_products = _resolveComponent("category-products")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    class: "flex-column",
    padding: "0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_section_header, {
        title: $options.sectionName,
        "has-back": "",
        onOnBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
      }, null, 8, ["title"]),
      _createVNode(_component_search_category, {
        class: "lists-search",
        "origin-section": $options.originSection,
        onRedirectOnSearch: $options.redirectToSearch
      }, null, 8, ["origin-section", "onRedirectOnSearch"]),
      _createVNode(_component_category_products, {
        class: "category-products",
        "origin-section": $options.originSection,
        onGoToFeaturedProducts: $options.goToFeaturedProduct
      }, null, 8, ["origin-section", "onGoToFeaturedProducts"])
    ]),
    _: 1
  }))
}