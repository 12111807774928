<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
});
const width = computed<number>(() => 42 * props.size);
const height = computed<number>(() => 34 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0 42 34">
        <path
            :fill="color"
            clip-rule="evenodd"
            d="M37.8559 0.767578H4.70534C2.42624 0.767578 0.561523 2.6323 0.561523 4.9114V29.7743C0.561523 32.0534 2.42624 33.9181 4.70534 33.9181H37.8559C40.135 33.9181 41.9997 32.0534 41.9997 29.7743V4.9114C41.9997 2.6323 40.135 0.767578 37.8559 0.767578ZM37.8559 29.7743H33.7121V11.5415L21.2806 19.4148L8.84916 11.5415V29.7743H4.70534V4.9114H7.19163L21.2806 13.6134L35.3696 4.9114H37.8559V29.7743Z"
            fill-rule="evenodd"
        />
    </BaseIcon>
</template>
