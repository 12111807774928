<template>
    <base-icon :icon-name="$t('icon.schedule')" :width="width" :height="height" viewBox="0 0 23 22" :iconColor="color">
        <path
            d="M18.0308 11.7812C15.2701 11.7812 13.0615 14.048 13.0615 16.8814C13.0615 19.7148 15.2701 21.9816 18.0308 21.9816C20.7915 21.9816 23.0001 19.7148 23.0001 16.8814C23.0001 14.048 20.7915 11.7812 18.0308 11.7812ZM18.3621 20.6782V20.2248C18.3621 20.0548 18.2517 19.8848 18.0308 19.8848C17.8652 19.8848 17.6995 19.9982 17.6995 20.2248V20.6782C15.9327 20.5082 14.4971 19.0915 14.3315 17.2214H14.7732C14.9388 17.2214 15.1045 17.0514 15.1045 16.8814C15.1045 16.7114 14.9388 16.5981 14.7732 16.5414H14.3315C14.4971 14.728 15.8775 13.2546 17.6995 13.0846V13.538C17.6995 13.708 17.81 13.878 18.0308 13.878C18.1965 13.878 18.3621 13.7646 18.3621 13.538V13.0846C20.129 13.2546 21.5645 14.6713 21.7302 16.5414H21.2885C21.1228 16.5414 20.9572 16.6547 20.9572 16.8814C20.9572 17.0514 21.0676 17.2214 21.2885 17.2214H21.7302C21.5645 19.0348 20.129 20.5082 18.3621 20.6782Z"
        />
        <path
            d="M19.9051 16.3838H18.7456C18.5799 16.1572 18.3038 15.9872 18.0278 15.9872L16.9787 14.5138C16.813 14.2871 16.537 14.2304 16.3161 14.3438C16.0953 14.5138 16.04 14.8538 16.2057 15.0805L17.2548 16.5538C17.1995 16.6672 17.1995 16.7805 17.1995 16.8939C17.1995 17.3472 17.586 17.7439 18.0278 17.7439C18.3038 17.7439 18.5799 17.6305 18.7456 17.3472H19.9051C20.1811 17.3472 20.402 17.1205 20.402 16.8372C20.3468 16.6105 20.1259 16.3838 19.9051 16.3838Z"
        />
        <path
            d="M4.75596 6.74354C5.63939 6.74354 6.30197 6.00685 6.30197 5.15683V1.64338C6.35718 0.736689 5.63939 0 4.75596 0C3.87253 0 3.20996 0.736689 3.20996 1.64338V5.10016C3.20996 6.00685 3.92775 6.74354 4.75596 6.74354Z"
        />
        <path
            d="M14.3878 6.74354C15.2712 6.74354 15.9338 6.00685 15.9338 5.15683V1.64338C15.989 0.736689 15.2712 0 14.3878 0C13.5044 0 12.8418 0.736689 12.8418 1.64338V5.10016C12.8418 6.00685 13.5596 6.74354 14.3878 6.74354Z"
        />
        <path
            d="M6.92652 11.2289C6.92652 10.7756 6.54002 10.3789 6.0983 10.3789H4.60751C4.1658 10.3789 3.7793 10.7756 3.7793 11.2289V12.759C3.7793 13.2123 4.1658 13.609 4.60751 13.609H6.0983C6.54002 13.609 6.92652 13.2123 6.92652 12.759V11.2289Z"
        />
        <path
            d="M11.1804 11.2299C11.1804 10.7766 10.7939 10.3799 10.3522 10.3799H8.86142C8.4197 10.3799 8.0332 10.7766 8.0332 11.2299V12.76C8.0332 13.2133 8.4197 13.61 8.86142 13.61H10.3522C10.7939 13.61 11.1804 13.2133 11.1804 12.76V11.2299Z"
        />
        <path
            d="M13.1124 10.3789C12.6707 10.3789 12.2842 10.7756 12.2842 11.2289V12.759C12.2842 13.1557 12.5603 13.4957 13.002 13.5523C13.6093 12.589 14.4375 11.8523 15.4314 11.3423V11.2289C15.4314 10.7756 15.0449 10.3789 14.6032 10.3789H13.1124Z"
        />
        <path
            d="M4.60751 14.5703C4.1658 14.5703 3.7793 14.967 3.7793 15.4203V16.9504C3.7793 17.4037 4.1658 17.8004 4.60751 17.8004H6.0983C6.54002 17.8004 6.92652 17.4037 6.92652 16.9504V15.4203C6.92652 14.967 6.54002 14.5703 6.0983 14.5703H4.60751Z"
        />
        <path
            d="M10.3522 14.5703H8.86142C8.4197 14.5703 8.0332 14.967 8.0332 15.4203V16.9504C8.0332 17.4037 8.4197 17.8004 8.86142 17.8004H10.3522C10.7939 17.8004 11.1804 17.4037 11.1804 16.9504V15.4203C11.1804 14.967 10.7939 14.5703 10.3522 14.5703Z"
        />
        <path
            d="M12.5337 19.3932H4.91408C3.58893 19.3932 2.53986 18.3165 2.53986 16.9565V8.73957H16.7299V10.893C17.5582 10.6663 18.3864 10.6663 19.2698 10.8363V3.12939H17.006V5.11279C17.006 6.5295 15.9017 7.71954 14.4662 7.71954C13.0306 7.71954 11.9263 6.58617 11.9263 5.11279V3.12939H7.34351V5.11279C7.34351 6.5295 6.23922 7.71954 4.80365 7.71954C3.36808 7.71954 2.26379 6.5295 2.26379 5.11279V3.12939H0V16.9565C0 19.7332 2.20857 22 4.91408 22H14.3005C14.4109 22 14.5214 22 14.6318 22C13.6932 21.32 12.9754 20.4133 12.5337 19.3932Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-schedule',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 30 * this.size;
        },
        height(): number {
            return 30 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
