<template>
    <PromotionTextSlogan />
    <StoriesPromotions />
    <BannersPromotionsInTipti />
    <StroresOnSale />
    <PromotionalCodeGuide />
</template>

<script lang="ts" setup>
import PromotionTextSlogan from '@/views/promotionsInTipti/PromotionTextSlogan/PromotionTextSlogan.vue';
import StoriesPromotions from '@/views/promotionsInTipti/StoriesPromotions/StoriesPromotions.vue';
import BannersPromotionsInTipti from '@/views/promotionsInTipti/PromotionsBannersInTipti/BannersPromotionsInTipti.vue';
import PromotionalCodeGuide from '@/views/promotionsInTipti/PomotionalCodeGuide/PromotionalCodeGuide.vue';
import StroresOnSale from '@/views/promotionsInTipti/StoresOnSale/StoresOnSale.vue';
import { useSeo } from '@/composables/useSeo';
import { onMounted } from 'vue';

const { metaPromotions } = useSeo();

onMounted(() => {
    metaPromotions();
});
</script>
<style lang="scss" scoped>
@import './promotions-in-tipti.scss';
</style>
