<template>
    <section
        v-for="(retailer, index) in retailers"
        :key="retailer.id"
        class="products"
        :class="{ 'products--no-rounded': !showHeader }"
    >
        <retailer-header-cart
            v-if="showHeader"
            :get-total-for-retailer="getTotalForRetailer(retailer.products)"
            :index="index"
            :retailer="retailer"
            :show-retailer-image="cartType === 'EXTRA_ITEMS'"
        />
        <minimum-amount
            v-if="showHeader && cartType === 'MAIN'"
            :minimum-amount="retailer?.minimumBuyPrice ?? 0"
            :total-to-pay="getTotalForRetailer(retailer.products)"
        />
        <cart-bar :cart-bar-goals="retailer.goals" :retailer-id="retailer.id" :retailer-name="retailer.name" />
        <div v-for="product in retailer.products" :key="product.id" class="product-list">
            <section class="product-cart">
                <div class="product">
                    <img
                        v-if="product?.image?.medium"
                        class="product__img"
                        :src="product.image.medium"
                        alt=""
                        @error="displayDefaultImg"
                    />
                    <icon-tipti v-else />
                    <div class="product-main">
                        <product-cart-detail :product="product" :retailer="retailer" />
                        <div class="product-main__quantity">
                            <div v-if="!product.mandatoryItem" class="quantity-actions">
                                <btn-circular
                                    v-if="remove(product)"
                                    class="actions-quantity--shadow"
                                    bg-color="#F03F51"
                                    @click="deleteProduct(retailer, product)"
                                >
                                    <icon-trash :size="1.25" />
                                </btn-circular>
                                <btn-circular
                                    v-else
                                    :is-disabled="isLoadingDeleteProduct || isDisabledEditing"
                                    @click="
                                        isLoadingEditQuantity || isDisabledEditing
                                            ? null
                                            : editQuantity(retailer, product, false)
                                    "
                                >
                                    <icon-decrement :size="1.25" />
                                </btn-circular>
                                <input
                                    class="actions-quantity__input"
                                    v-model.number="product.productQuantity"
                                    :disabled="isDisabledEditing"
                                    type="number"
                                    @blur="onEnterQuantity(product, product.productQuantity)"
                                    @keyup.enter="onEnterQuantity(product, product.productQuantity)"
                                />
                                <btn-circular
                                    :is-disabled="product.isSampling || isDisabledEditing"
                                    @click="
                                        product.isSampling || isLoadingEditQuantity || isDisabledEditing
                                            ? null
                                            : editQuantity(retailer, product)
                                    "
                                >
                                    <icon-increment :size="1.25" />
                                </btn-circular>
                            </div>
                            <template v-if="showDelete && !product.mandatoryItem">
                                <base-loading
                                    v-if="isLoadingDeleteProduct && productSelectedId === product.id"
                                    :is-margin-auto="false"
                                    heigth=".5rem"
                                    width=".5rem"
                                />
                                <button v-else class="action" @click="deleteProduct(retailer, product)">
                                    <icon-trash :size="1.5" color="#606060" />
                                    <span>{{ $t("cta['delete']") }}</span>
                                </button>
                            </template>
                        </div>
                    </div>
                    <base-loading v-if="isLoadingEditProduct && productSelectedId === product.id" />
                    <product-cart-price v-else :product="product" />
                </div>
                <p v-if="product?.messageCarryPay" class="product__special-message">
                    <img alt="attached-picture" src="@/assets/svg/ic_label.svg" />
                    {{ translate({ recordable: product?.messageCarryPay }) }}
                </p>
                <p v-else-if="product.uniqueDelivery && product.hoursToDelivery" class="product__special-message">
                    {{
                        $t('txt.configurable__delivery', {
                            hours: product.hoursToDelivery,
                        })
                    }}
                </p>
            </section>
            <section v-if="product.replacement" class="product-replacement">
                <icon-arrow-replace style="justify-self: center" />
                <p class="product-replacement__label">{{ $t('general.remplace-for') }}</p>
                <p class="product-replacement__product">{{ product.replacement.name }}</p>
            </section>
        </div>
        <alert-minimum-amount-per-retailer
            v-if="minimumAmountAlert && retailer.id === productSavedForMinimumAmountFlow?.retailer?.id"
            :is-loading="isLoadingMinimumAmount"
            :minimum-amount="minimumAmountAlert"
            @on-close="doNotDeleteProduct(retailer)"
            @on-accept="goToHome(retailer)"
            @on-decline="doNotDeleteProduct(retailer)"
        />
    </section>

    <snack-bar
        :body="snackBarMessage"
        :is-active="showSnackBar"
        :is-failure="!isSuccess"
        :is-success="isSuccess"
        :title="title"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<script lang="ts">
import { ProductModel } from '@/models/product/ProductModel';
import { logger } from '@/utils/logger';
import { mapGetters } from 'vuex';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import { RetailerInProductModel } from '@/models/product/RetailerInProductModel';
import { urlToFile } from '@/utils/picturesUtils';
import IconTrash from '@/components/icons/IconTrash.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import IconIncrement from '@/components/icons/IconIncrement.vue';
import IconDecrement from '@/components/icons/IconDecrement.vue';
import BtnCircular from '@/components/buttons/BtnCircular.vue';
import IconArrowReplace from '@/components/icons/IconArrowReplace.vue';
import IconTipti from '@/components/icons/IconTipti.vue';
import MinimumAmount from '../minimumAmount/MinimumAmount.vue';
import AlertMinimumAmountPerRetailer from '@/components/alerts/alertMinimumAmountPerRetailer/AlertMinimumAmountPerRetailer.vue';
import CartBar from '../cartBar/CartBar.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import RetailerHeaderCart from '@/modules/retailers/components/RetailerHeaderCart.vue';
import ProductCartPrice from '@/views/cart/components/products/ProductCartPrice.vue';
import ProductCartDetail from '@/views/cart/components/products/ProductCartDetail.vue';
import { useImage } from '@/composables/ImageComposable';
import { useTranslate } from '@/composables/UseTranslate';
import { useExtraItemHandleEditProduct } from '@/modules/extraItems/composables/useExtraItemHandleProduct';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { useRoutes } from '@/composables/useRoutes';
import { appRoutesMap } from '@/router/appRoutesMap';

type CartType = 'MAIN' | 'EXTRA_ITEMS';

export default {
    name: 'Products',
    components: {
        ProductCartDetail,
        ProductCartPrice,
        RetailerHeaderCart,
        IconTrash,
        BaseLoading,
        IconDecrement,
        IconIncrement,
        BtnCircular,
        IconArrowReplace,
        IconTipti,
        MinimumAmount,
        AlertMinimumAmountPerRetailer,
        CartBar,
        SnackBar,
    },
    setup() {
        const { deletedProduct, addProduct } = useExtraItemHandleEditProduct();
        const { isExtraItemCart } = useCartType();
        const { translate } = useTranslate();
        const { goToRetailer, yourRetailers, otherRetailers, retailerSelected } = useRetailer();
        const { isScreenCheckout } = useRoutes();
      
        return {
            deletedProductExtraItem: deletedProduct,
            addProductExtraItem: addProduct,
            isExtraItem: isExtraItemCart,
            ...useImage(),
            translate,
            goToRetailer,
            yourRetailers,
            otherRetailers,
            retailerSelected,
            isScreenCheckout,
        };
    },
    data() {
        return {
            timer: undefined,
            productSelectedId: undefined,
            isLoadingDeleteProduct: false,
            isLoadingEditQuantity: false,
            isLoadingEditProduct: false,
            retailersExpanded: [],
            productSelectedForNote: null,
            minimumAmountAlert: undefined,
            isLoadingMinimumAmount: false,
            productSavedForMinimumAmountFlow: undefined,
            goalSavedForAlertsAndAnimation: undefined,
            isSuccess: false,
            showSnackBar: false,
            snackBarMessage: '',
            title: '',
        };
    },
    computed: {
        ...mapGetters({
            retailerIdsWithtimeDelivery: 'checkout/retailerIdsWithtimeDelivery',
            timeDelivery: 'checkout/timeDelivery',
        }),

        cartType(): CartType {
            return this.isExtraItem ? 'EXTRA_ITEMS' : 'MAIN';
        },
    },
    props: {
        retailers: {
            type: Array<CartRetailerModel>,
            default: () => [],
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showDelete: {
            type: Boolean,
            default: true,
        },
        isDisabledEditing: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        remove(product: ProductModel): boolean {
            return this.isDisabledEditing ? false : +product.productQuantity <= 1;
        },

        checkMinimumAmountPerRetailer(retailer: CartRetailerModel, product: ProductModel): boolean {
            let totalToPay = 0;
            retailer?.products?.forEach((actualProduct: ProductModel) => {
                if (product.id == actualProduct.id)
                    totalToPay = totalToPay + product.productQuantity * product.priceWithDiscount;
                else totalToPay = totalToPay + actualProduct.finalPriceWithDiscount;
            });
            const showMinimumAmountAlert: boolean = totalToPay <= retailer.minimumBuyPrice;
            if (showMinimumAmountAlert && this.cartType === 'MAIN') this.minimumAmountAlert = retailer.minimumBuyPrice;

            return showMinimumAmountAlert;
        },
        async goToHome(retailer: CartRetailerModel): Promise<void> {
            try {
                const actualProduct: ProductModel = retailer?.products?.find(
                    (product: ProductModel) => product.id == this.productSavedForMinimumAmountFlow.id,
                );
                if (!actualProduct || this.isLoadingMinimumAmount) return;
                this.isLoadingMinimumAmount = true;
                if (this.productSavedForMinimumAmountFlow['isDelete'])
                    await this.$store.dispatch('cart/deleteProduct', {
                        product: actualProduct,
                        screen: this.$route.path,
                        section: this.showHeader ? 'CART' : 'CHECKOUT',
                    });
                else
                    await this.$store.dispatch('cart/addEditProduct', {
                        product: actualProduct,
                        screen: this.$route.path,
                        section: this.showHeader ? 'CART' : 'CHECKOUT',
                    });
                await this.$store.dispatch('cart/fetchCart');
            } catch (err) {
                logger('GO_TO_HOME', err);
            } finally {
                const retailerFromYourRetailers: RetailerModel = this.yourRetailers?.retailers.find(
                    (actualRetailer: RetailerModel) => actualRetailer.id == retailer.id,
                );
                const retailerFromOtherRetailers: RetailerModel = this.otherRetailers?.retailers?.find(
                    (actualRetailer: RetailerModel) => actualRetailer.id == retailer.id,
                );
                if (retailerFromYourRetailers || retailerFromOtherRetailers) {
                    this.retailerSelected = retailerFromYourRetailers ?? retailerFromOtherRetailers;

                    this.goToRetailer({ replace: true });
                } else this.$router.replace({ name: appRoutesMap.retail.home});
                this.isLoadingMinimumAmount = false;
            }
        },
        doNotDeleteProduct(retailer: CartRetailerModel): void {
            /// * get product's index from array of products
            /// since this object has a memory reference productQuantity is already updated;
            /// Although, we will use the deepCopy [productSavedForMinimumAmountFlow] to update [productQuantity]
            const actualProductIndex: number = retailer?.products?.findIndex(
                (product: ProductModel) => product.id == this.productSavedForMinimumAmountFlow?.id,
            );
            if (actualProductIndex != -1)
                retailer.products[actualProductIndex].productQuantity =
                    this.productSavedForMinimumAmountFlow['productQuantity'];
            this.productSavedForMinimumAmountFlow = undefined;
            this.minimumAmountAlert = undefined;
        },
        async deleteProduct(retailer: CartRetailerModel, product: ProductModel): Promise<void> {
            try {
                if (this.isExtraItem) return this.deletedProductExtraItem(product);
                this.productSelectedId = product.id;
                this.isLoadingDeleteProduct = true;
                /// * [actualProduct] used for minimumAmount flow;
                const actualProduct = { ...product };
                actualProduct.productQuantity = 0;
                if (this.isScreenCheckout && this.checkMinimumAmountPerRetailer(retailer, actualProduct)) {
                    this.productSavedForMinimumAmountFlow = product;
                    this.productSavedForMinimumAmountFlow['retailer'] = new RetailerInProductModel(
                        retailer.id,
                        retailer.name,
                        retailer.primaryColor,
                        retailer.secondaryColor,
                    );
                    this.productSavedForMinimumAmountFlow['isDelete'] = true;
                    return;
                }
                await this.$store.dispatch('cart/deleteProduct', {
                    product: product,
                    screen: this.$route.path,
                    section: this.showHeader ? 'CART' : 'CHECKOUT',
                });
                /// ** remove [timeDelivery] of [retailerId] if it was the last product of that retailer on cart;
                if (this.timeDelivery?.length && retailer?.products?.length == 1)
                    this.$store.commit('checkout/removeTimeDelivery', retailer?.id);
                if (this.isScreenCheckout)
                    await this.$store.dispatch('cart/fetchCart', { retailerIds: this.retailerIdsWithtimeDelivery });
                else await this.$store.dispatch('cart/fetchCart', { needFetchGoals: true });
            } catch (err) {
                this.title = this.$t('alerts.general_error_title');
                this.snackBarMessage = this.$t('alerts.general_error_title');
                this.showSnackBar = true;
                this.isSuccess = false;
                logger('DELETE_PRODUCT', err);
            } finally {
                this.isLoadingDeleteProduct = false;
            }
        },
        async editQuantity(retailer: CartRetailerModel, product: ProductModel, add = true) {
            try {
                add ? (product.productQuantity += 1) : (product.productQuantity -= 1);
                if (this.isExtraItem) return this.addProductExtraItem(product);
                if (this.timer) clearTimeout(this.timer);
                this.isLoadingEditProduct = true;
                this.productSelectedId = product.id;
                /// ** [productSavedForMinimumAmountFlow] deepCopy used on future if minimum amount flow detonates;
                if (this.productSavedForMinimumAmountFlow?.id != product.id) {
                    this.productSavedForMinimumAmountFlow = { ...product };
                    this.productSavedForMinimumAmountFlow['retailer'] = new RetailerInProductModel(
                        retailer.id,
                        retailer.name,
                        retailer.primaryColor,
                        retailer.secondaryColor,
                    );
                }
                this.timer = setTimeout(async () => {
                    try {
                        this.isLoadingEditQuantity = true;
                        if (this.isScreenCheckout && this.checkMinimumAmountPerRetailer(retailer, product)) return;
                        product.noteToShopperPhotoFile = product?.noteToShopperPhoto
                            ? await urlToFile(product?.noteToShopperPhoto)
                            : null;
                        await this.$store.dispatch('cart/addEditProduct', {
                            product: product,
                            screen: this.$route.path,
                            section: this.showHeader ? 'CART' : 'CHECKOUT',
                        });
                        if (this.isScreenCheckout)
                            await this.$store.dispatch('cart/fetchCart', {
                                retailerIds: this.retailerIdsWithtimeDelivery,
                            });
                        else
                            await this.$store.dispatch('cart/fetchCart', {
                                needFetchGoals:
                                    retailer?.minimumBuyPrice <
                                    this.getTotalForRetailer(retailer.products) + product.priceWithDiscount,
                                isAdd: add,
                            });
                        this.productSavedForMinimumAmountFlow = undefined;
                        const _actualGoals =
                            this.cart?.retailers?.find((element) => element?.id == retailer?.id)?.goals ?? [];
                        if (
                            add &&
                            this.goalSavedForAlertsAndAnimation != undefined &&
                            !_actualGoals.some(
                                (element) => element.discountId == this.goalSavedForAlertsAndAnimation.discountId,
                            )
                        ) {
                            this.goalSavedForAlertsAndAnimation.retailerId = retailer.id;
                            this.$store.commit('cart/cartBarGoal', this.goalSavedForAlertsAndAnimation);
                        }
                    } catch (err) {
                        logger('EDIT_QUANTITY_WITH_BACK', err);
                    } finally {
                        this.isLoadingEditProduct = false;
                        this.isLoadingEditQuantity = false;
                    }
                }, 500);
            } catch (err) {
                logger('EDIT_QUANTITY', err);
            }
        },
        getTotalForRetailer(products?: Array<ProductModel>): number {
            return products?.reduce(
                (accumulator, { finalPriceWithDiscount }) => accumulator + +finalPriceWithDiscount?.toFixed(2),
                0,
            );
        },
        async onEnterQuantity(product: ProductModel, quantity) {
            try {
                product.productQuantity = quantity === 0 || !quantity ? 1 : Math.abs(quantity);
                if (this.isExtraItem) return this.addProductExtraItem(product);
                this.productSelectedId = product.id;
                this.isLoadingEditQuantity = true;
                await this.$store.dispatch('cart/addEditProduct', {
                    product: product,
                    screen: this.$route.path,
                    section: this.showHeader ? 'CART' : 'CHECKOUT',
                });
                await this.$store.dispatch('cart/fetchCart', {
                    retailerIds: this.retailerIdsWithtimeDelivery,
                    needFetchGoals: true,
                    isAdd: true,
                });
            } catch (err) {
                logger('ON_ENTER_QUANTITY', err);
            } finally {
                this.isLoadingEditQuantity = false;
                this.productSelectedId = undefined;
            }
        },
        mustBeShowCartBar(retailer): boolean {
            if (this.isLoadingEditQuantity === 'EXTRA_ITEMS') return false;
            return retailer?.minimumBuyPrice < this.getTotalForRetailer(retailer.products) && this.showHeader;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './products.scss';
</style>
