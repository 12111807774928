import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a2fcb1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-menu payment-method-expired" }
const _hoisted_2 = { class: "payment-method-expired__txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_credit_card_info = _resolveComponent("icon-credit-card-info")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_icon_credit_card_info, { size: 0.7 })
    ]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('txt.credit_card__expired_info')), 1),
    _createElementVNode("button", {
      class: "payment-method-expired__btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-change-selected')))
    }, _toDisplayString(_ctx.$t('cta.change')), 1)
  ]))
}