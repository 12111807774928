<template>
    <base-icon
        :icon-name="$t('icon.promotions')"
        :width="width"
        :height="height"
        viewBox="0 0 128 150"
        :iconColor="color"
    >
        <path
            d="M112.658 0H13.9241C6.32911 0 0 6.32911 0 13.9241V150L30.3797 119.62H112.658C120.253 119.62 126.582 113.291 126.582 105.696V13.9241C127.215 6.32911 120.886 0 112.658 0ZM89.2405 94.9367L64.557 77.2152L39.8734 94.9367L49.3671 66.4557L25.3165 48.7342H55.6962L65.1899 20.2532L74.6835 48.7342H105.063L80.3797 66.4557L89.2405 94.9367Z"
            :fill="color"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-promos',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C3C3C3',
        },
    },
    computed: {
        width(): number {
            return 128 * this.size;
        },
        height(): number {
            return 150 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
