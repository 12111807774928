import { computed, ref } from 'vue';
import { updateNoteByBoughtMode } from '@/utils/productUtils';
import { logger } from '@/utils/logger';
import { ProductModel } from '@/models/product/ProductModel';
import { BannersGoalModel } from '@/models/promotions/banners/BannerGoalModel';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useExtraItemHandleEditProduct } from '@/modules/extraItems/composables/useExtraItemHandleProduct';
import { Sections } from '@/enums/sectionsOrigin';
import { useDeactivatedProduct } from '@/modules/productSuggested/composables/useDeativatedProduct';
import { ReplacementEventTypes, ReplacementType } from '@/modules/events/enums/ReplacementEventTypes';
import { useReplacementEvents } from '@/modules/events/useReplacementEvents';
import { useCart } from '@/composables/useCart';
import { useApp } from '@/composables/useApp';
import { useProductStore } from '@/store/useProductStore';
import { useProductReplacements } from '@/modules/highDemandAndOutOfStock/composable/useProductReplacements';
import { storeToRefs } from 'pinia';

export const useProductQuantity = (
    section: string,
    {
        productTarget,
    }: {
        productTarget?: ProductModel;
    } = { productTarget: undefined },
) => {
    const { $store: store, $route: route, $t, notifier, $router } = useApp();
    const { findProduct } = useCart();
    const { addProduct, deletedProduct, isAlreadyInTheCart } = useExtraItemHandleEditProduct();
    const { isExtraItemCart } = useCartType();
    const { removeOne } = useDeactivatedProduct();
    const { sendReplacementAnalytics } = useReplacementEvents();
    const { suggestionsForUnAvailableProducts, setItemHighDemand } = useProductStore();
    const {
        getProductsSuggestions,
        redirectionAndShowNotifier,
        sendAnalyticsAndRemoveItem,
        isProductInReplacementSuggestions,
    } = useProductReplacements();
    const { showModalProductsReplacements, isHighDemand, isOutOfStock, lastItemAdded, itemAddedFromSuggestion } =
        storeToRefs(useProductStore());
    const product = computed<ProductModel>(() => productTarget ?? store.getters['product/currentProduct']);

    const quantity = ref(1);
    const productQuantityInCart = computed<number>(() => findProduct(product.value.stockItemId)?.productQuantity ?? 0);

    const retailerIdsWithTimeDelivery = computed<string>(() => store.state.checkout.retailerIdsWithtimeDelivery);
    const bannerGoal = computed<BannersGoalModel>(() => store.state.promotions.bannerGoal);
    /// ** fetchCart will only work in conjuntion with [retailerIdsWithtimeDelivery]
    const fetchCart = computed<boolean>(() => store.state.product.fetchCart);
    const isBannerInSection = computed<boolean>(() => store.state.promotions.isBannerInSection);

    const isLoading = ref(false);

    const alreadyInCart = computed(() => productQuantityInCart.value > 0);

    const fetchDiscountActivationProgressBar = async (): Promise<void> => {
        try {
            if (!bannerGoal.value) return;
            if (bannerGoal.value.actualAmount === bannerGoal.value.minimumAmount && bannerGoal.value.actualAmount > 0)
                return;
            const newBannerGoal = await store.dispatch('promotions/bannersDiscountGoal', {
                id: route.params.id,
                isBannerInSection: isBannerInSection,
            });
            store.commit('promotions/bannerGoal', newBannerGoal);
        } catch (err) {
            logger('ERROR', err);
            store.commit('promotions/bannerGoal', undefined);
        }
    };

    const onChangedQuantity = (value: number) => {
        quantity.value = value;
    };

    const extraItemsHandle = () => {
        if (+quantity.value === 0) return deletedProduct(product.value);
        const isInCart = isAlreadyInTheCart(product.value);
        addProduct(product.value);
        if (isInCart) return;
        const originSearchKey = 'EXTRA_ITEMS';
        const eventTrack = {
            product: product,
            screen: originSearchKey,
        };
        if (store.getters['search/query']?.length) {
            eventTrack['searchedPhrase'] = store.getters['search/query'];
            store.commit('search/searchOriginSection', Sections.EXTRA_ITEMS);
        }
        store.dispatch('segment/productAdded', eventTrack);
    };

    const handleDeleteProduct = async (): Promise<void> => {
        try {
            await store.dispatch('cart/deleteProduct', {
                product: product.value,
                screen: route.path,
                section,
            });
            if (fetchCart.value)
                store.dispatch('cart/fetchCart', {
                    retailerIds: retailerIdsWithTimeDelivery,
                });
            else store.commit('cart/removeProductFromProducts', product.value);
        } catch (_) {
            notifier({
                type: 'ERROR',
                body: $t('alerts.product_remove_error'),
            });
        }
    };

    const handleSuggestedReplacements = async (product: ProductModel): Promise<boolean> => {
        try {
            if (!product.highDemand || product.replacement) return false;
            const { results } = await suggestionsForUnAvailableProducts({
                stockItemId: product.stockItemId,
                size: 40,
            });
            setItemHighDemand({
                productAdded: product,
                replacementSuggestion: results ?? [],
            });
            if (results?.length) showModalProductsReplacements.value = true;
            return true;
        } catch (err) {
            logger('HANDLE_SUGGESTED_REPLACEMENTS', err);
        }
    };

    const handleAddOrEditProduct = async (): Promise<void> => {
        if (isHighDemand.value || isOutOfStock.value) isProductInReplacementSuggestions(product.value);
        if (itemAddedFromSuggestion.value) {
            lastItemAdded.value = product.value;
            await redirectionAndShowNotifier();
            sendAnalyticsAndRemoveItem();
            $router.back();
            return;
        }

        const eventTrack = {
            product: product.value,
            screen: route.path,
            section,
        };
        if (store.getters['search/query'].length) {
            eventTrack['searchedPhrase'] = store.getters['search/query'];
        }
        await store.dispatch('cart/addEditProduct', eventTrack);
        if (!(await handleSuggestedReplacements(product.value)))
            if (!alreadyInCart.value) store.commit('cart/lastProductsAdded', product.value);
        if (fetchCart.value) store.dispatch('cart/fetchCart', { retailerIds: retailerIdsWithTimeDelivery });
        else store.commit('cart/products', product.value);
    };

    const quantityAction = async (isBoughtByWeight: boolean): Promise<void> => {
        try {
            isLoading.value = true;
            if (!product.value.available) return await getProductsSuggestions(product.value);
            const isForDelete = alreadyInCart.value && +quantity.value <= 0;
            const newProduct = { ...product.value };
            newProduct.productQuantity = Math.abs(+quantity.value);
            newProduct.note = updateNoteByBoughtMode(product.value.note, isBoughtByWeight);
            if (isExtraItemCart.value) extraItemsHandle();

            product.value.productQuantity = Math.abs(+quantity.value);
            product.value.note = updateNoteByBoughtMode(product.value.note, isBoughtByWeight);
            isForDelete ? await handleDeleteProduct() : await handleAddOrEditProduct();
            if (product.value.deactivateProductIdReference) {
                sendReplacementAnalytics({
                    event: ReplacementEventTypes.SaveReplacement,
                    mainProduct: product.value,
                    replacementProduct: product.value.deactivateProductIdReference,
                    replacementType: ReplacementType.OutOfStock,
                });
                removeOne(product.value.deactivateProductIdReference.id);
            }

            fetchDiscountActivationProgressBar();
        } catch (err) {
            if (err === 409) return await getProductsSuggestions(product.value);
            notifier({
                type: 'ERROR',
                body: $t('alerts.product_add_error'),
            });
            logger('QUANTITY_ACTION', err);
        } finally {
            isLoading.value = false;
        }
    };

    const removeReplacement = async (): Promise<void> => {
        product.value.replacement = null;
        if (alreadyInCart.value) return;
        await store.dispatch('cart/addEditProduct', {
            product: product.value,
            screen: route.path,
            section,
        });
        store.commit('cart/products', product.value);
    };

    return {
        isLoading,
        removeReplacement,
        onChangedQuantity,
        quantityAction,
        quantity,
        productQuantityInCart,
        alreadyInCart,
        fetchDiscountActivationProgressBar,
        handleDeleteProduct,
    };
};
