import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
    deliveryAddress: [],
    timeDelivery: [],
    isElder: false,
    isAutomaticReplacements: false,
    instructions: '',
    phone: '',
    email: '',
    responsePurchase: undefined,
    stockItemTimeDelivery: [],
    isSelectedSchedules: false,
};

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations,
};
