export const i18Seo = {
    ES: {
        seo: {
            tipti: `Compras a domicilio de Supermercado y tiendas especializadas.`,
            tipti__description: 'Realizamos tus compras en tus tiendas y supermercados favoritos, y al mismo precio.',
            retailers: 'Tiendas',
            retailers__description:
                'Ingresa a nuestra app y encuentra una gran variedad de tiendas especializadas y supermercados. Dependiendo de tu ubicación podrás visualizar los que se encuentran disponibles para tu sector y empezar con tus compras.',
            categories: 'Categorías en {retailer}',
            category_name: '{category} en {retailer}',
            my_lists: 'Mis listas',
            codes_and_promotions: 'Códigos y Promociones',
            coupons: 'Códigos',
            promotions: 'Promociones',
            new_promotions: 'Nuevas Promociones',
            new_promotions__description:
                'Tenemos promociones especiales para ti. Todos los días disfruta de las mejores promociones en supermercados y tiendas especializadas en nuestra app. ¡Incluso puedes aplicar varias promociones a la vez!',
            latest_promotions: 'Promociones',
            my_orders: 'Mis ordenes',
            become_retailer: 'Conviertete en retailer',
            about_tipti: 'Acerca de Tipti',
            coverage_sector: 'Sectores de cobertura',
            coverage_sector__description:
                'Te tenemos cubierto! Descubre todos los sectores de cobertura que tiene nuestra app. Ingresa tu dirección y mira todas las tiendas y supermercados disponibles para tu área. Cada día seguimos creciendo para ti.',
            contact_us: 'Contáctanos',
            tipti_recipes: 'Recetas Tipti',
            tipti_recipes__description:
                'Descubre recetas rápidas y fáciles de preparar en tu día a día en nuestra app. Solamente con un click podrás modificar y agregar los ingredientes a tu carrito. Mira la preparación mientras esperas tu pedido y así de fácil te conviertes en tu propio chef.',
            home_retailer: '{retailer} en {city}',
            countries: 'Tiempo para ti',
            news: 'Sala de prensa',
            page_home_keywords:
                'supermercado, delivery, domicilio, rappi, glovo, supermaxi, tipti, supermercados abiertos, coral hipermercados, soy rappi, megamaxi, glovo app, despensa familiar, jumbo , glovo delivery, rappi app, jumbo a domicilio, amazon, rappi delivery, tienda jumbo, hipermercados, coral supermercados, rappi shopper, glovo envios, supermercado virtual, compras supermercado, megamaxi 6 de diciembre, supermercado en linea, supermercado el coral, rappi envios, tienda en linea, ecommerce, groceries, supermercado online, online, comprar online, tia, tia online, micromercado, comida, compras, ecommerce de supermercado, supermaxi a domicilio, megamaxi a domicilio, compras online, compras de supermercado a domicilio, promociones domicilio, descuentos online, licor a domicilio, comida a domicilio, domicilio comprar, frutas a domicilio, carnes a domicilio, Aki, supermercados aki, aki a domicilio, aki delivery, comprar en aki, aki delivery, jugueton a domicilio, juguetes a domicilio, comprar juguetes online',
            page_about: 'Tu supermercado a domicilio',
            page_about_description:
                'Somos especialistas en hacer tus compras con Expertos Gastrónomos, tal como tu lo harías',
            page_about_keywords:
                'Vamos Sumando, vamos sumando, shoppers que son como tú, shoppers que son como tu, shoppers, shoppers gastrónomos, Pierangela Sierra, Rafael Luque, e-commerce, ecommerce, tiempo para ti, tiendas y supermercados, tiendas a domicilio, supermercados a domicilio, lo hacemos posible, hacemos posible lo imposible, imposible no comprobarlo, noticias, Tipti, misión, visión, valores, integridad, planificación, agilidad, innovación, determinación, optimismo, sumar, todos, únicos, ganas, esfuerzos, conocimientos, recompensas, pasión, experiencia, Tipti Semilleros de Talento, semilleros de talento, Tipti Tech Academy, tech academy, Tipti University, acerca de nosotros, nosotros',
            page_promotion: 'Códigos y Promociones',
            page_promotion_description:
                'Encuentra las mejores promos y descuentos en tus supermercados y tiendas favoritas',
            page_promotion_keywords:
                'ofertas, alimentos, descuentos,  productos de supermercado, productos, cupones, cupones de compra online, promociones exclusivas, promociones, ofertas especiales, ofertas pedidos a domicilio, descuentos en compras, cupones para productos, ofertas relámpago, tiendas y descuentos, descuentos y promociones diarias, descuentos exclusivos, ofertas especiales, rebajas productos, promociones flash compras, descuentos recurrentes, promociones en marcas.',
        },
    },

    EN: {
        seo: {
            tipti: 'Supermarket and specialty stores home shopping.',
            tipti__description:
                'We make your purchases in your favorite stores and supermarkets, and at the same price.',
            retailers: 'Retailers',
            retailers__description:
                'Enter our app and find a wide variety of specialized stores and supermarkets. Depending on your location, you will be able to view the ones available for your sector and start with your purchases.',
            categories: 'Categories in {retailer}',
            category_name: '{category} in {retailer}',
            my_lists: 'My lists',
            codes_and_promotions: 'Codes and Promotions',
            coupons: 'Codes',
            promotions: 'Promotions',
            new_promotions: 'New Promotions',
            new_promotions__description:
                'We have special promotions for you. Every day enjoy the best promotions in supermarkets and specialized stores in our app. You can even apply several promotions at the same time!',
            my_orders: 'My orders',
            become_retailer: 'Become a retailer',
            about_tipti: 'About Tipti',
            coverage_sector: 'Coverage sectors',
            coverage_sector__description:
                'We have you covered! Discover all the sectors of coverage that our app has. Enter your address and see all the stores and supermarkets available for your area. Every day we continue to grow for you.',
            contact_us: 'Contact us',
            tipti_recipes: "Tipti's recipes",
            tipti_recipes__description:
                'Discover quick and easy recipes to prepare in your day to day in our app. With just one click you can modify and add the ingredients to your cart. Watch the preparation while you wait for your order and that easily you become your own chef.',
            home_retailer: '{retailer} in {city}',
            countries: 'Time for you',
            news: 'Press room',
            page_home_keywords:
                'supermarket, delivery, home delivery, rappi, glovo, supermaxi, tipti, open supermarkets, coral hypermarkets, soy rappi, megamaxi, glovo app, family pantry, jumbo, glovo delivery, rappi app, jumbo home delivery, amazon, rappi delivery, jumbo store, hypermarkets, coral supermarkets, rappi shopper, glovo delivery, virtual supermarket, supermarket shopping, megamaxi december 6, online supermarket, el coral supermarket, rappi delivery, online store, ecommerce, groceries, online supermarket, online, buy online, tia, tia online, micro-market, food, shopping, supermarket ecommerce, supermaxi at home, megamaxi at home, online shopping, supermarket shopping at home, promotions at home, online discounts, liquor at home, food at home, home shopping, fruits at home, meats at home, Aki, aki supermarkets, aki at home, aki delivery, buy at aki, aki delivery, aki toys at home, toys at home, buy toys online',
            page_about: 'Your supermarket at home',
            page_about_description: 'We specialize in shopping with Expert Gourmets, just as you would do it yourself.',
            page_about_keywords:
                'We are adding, we are adding, shoppers who are like you, shoppers who are like you, shoppers, gourmet shoppers, Pierangela Sierra, Rafael Luque, e-commerce, ecommerce, time for you, stores and supermarkets, stores at home, supermarkets at home , we make it possible, we make the impossible possible, impossible not to check it, news, Tipti, mission, vision, values, integrity, planning, agility, innovation, determination, optimism, add, all, unique, desire, efforts, knowledge, rewards, passion, experience, Tipti Semilleros de Talento, seedbeds of talent, Tipti Tech Academy, tech academy, Tipti University, about us, us',
            page_promotion: 'Codes and Promotions',
            page_promotion_description:
                'Find the best promotions and discounts in your favorite supermarkets and stores.',
            page_promotion_keywords:
                'offers, food, discounts, supermarket products, products, coupons, online shopping coupons, exclusive promotions, promotions, special offers, special offers, home delivery offers, shopping discounts, product coupons, lightning deals, stores and discounts, daily discounts and promotions, exclusive discounts, special offers, product rebates, flash shopping promotions, recurring discounts, brand promotions.',
        },
    },
};
