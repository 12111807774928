import { ProductModel } from '@/models/product/ProductModel';

interface ProductSuggestedInput {
    readonly unAvailableProduct: ProductModel;
    readonly suggestions: Array<ProductModel>;
}

export class ProductSuggested {
    readonly unAvailableProduct: ProductModel;
    readonly suggestions: Array<ProductModel>;

    constructor(data: ProductSuggestedInput) {
        this.unAvailableProduct = data.unAvailableProduct;
        this.suggestions = data.suggestions;
    }
}
