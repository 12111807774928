<template>
    <div class="round">
        <input :id="label" :checked="modelValue" type="checkbox" @click.stop="update" />
        <label :for="label" />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const modelValue = defineModel<boolean>({
    default: false,
});

const props = defineProps({
    preventUpdate: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        required: true,
    },
    bgRound: {
        type: String,
        default: '#fff',
    },
});
const cursorType = computed(() => (props.preventUpdate ? 'default' : 'pointer'));
const update = (event: Event) => {
    if (props.preventUpdate) return event.preventDefault();
    modelValue.value = (event.target as HTMLInputElement).checked;
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.round {
    position: relative;
}

.round label {
    background-color: v-bind(bgRound);
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: v-bind(cursorType);
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
}

.round label:after {
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 4px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 10px;
}

.round input[type='checkbox'] {
    visibility: hidden;
}

.round input[type='checkbox']:checked + label {
    background-color: $orange;
    border-color: $orange;
}

.round input[type='checkbox']:not(:checked) + label {
    background-color: $bright-canvas;
    border-color: $bright-canvas;
}

.round input[type='checkbox']:checked + label:after {
    opacity: 1;
}
</style>
