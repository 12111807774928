class PromotionInTipti {
    id: string;
    banner: string;
    name: string;
    termsAndConditions: string;

    constructor(id: string, banner: string, name: string, termsAndConditions: string) {
        this.id = id;
        this.banner = banner;
        this.name = name;
        this.termsAndConditions = termsAndConditions;
    }
}

const toPromotionInTipti = (data: object) => {
    return data
        ? new PromotionInTipti(
              data['id'],
              data['banner'],
              data['display_name'] ?? '',
              data['terms_and_conditions_file'],
          )
        : null;
};
export { toPromotionInTipti, PromotionInTipti };
