<template>
    <settings-tile :title="$t('txt.setting__terms_conditions')" remove-bottom-border>
        <template #title-icon>
            <icon-terms-and-conditions class="settings-tile-icon" :size="0.75" />
        </template>
        <template #content>
            <base-loading v-if="isTermsLoading" />
            <ul v-else>
                <li
                    v-for="item in termsAndConditionsData"
                    :key="item.id"
                    class="settings-terms"
                    @click="selectTermsAndConditions(item)"
                >
                    {{
                        translate({
                            recordable: item.text,
                        })
                    }}
                </li>
            </ul>
            <section v-if="!isMobile" class="settings_btns">
                <btn-solid :txt="$t('cta.close-session')" @click="showCloseSessionModal = true"></btn-solid>
            </section>
        </template>
    </settings-tile>
    <close-session-modal v-if="showCloseSessionModal" @on-close="showCloseSessionModal = false"></close-session-modal>
    <alert-pdf
        v-if="selectedTermsAndConditions"
        :title="translate({ recordable: selectedTermsAndConditions.text })"
        :url="translate({ recordable: selectedTermsAndConditions.document })"
        @on-close="selectedTermsAndConditions = undefined"
    />
</template>

<script lang="ts" setup>
import SettingsTile from '@/views/myAccount/views/settings/common/SettingsTile.vue';
import IconTermsAndConditions from '@/components/icons/IconTermsAndConditions.vue';
import AlertPdf from '@/components/alerts/alertPdf/AlertPdf.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import CloseSessionModal from '@/components/modal/closeSessionModal/CloseSessionModal.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { useTranslate } from '@/composables/UseTranslate';
import { useApp } from '@/composables/useApp';
import { ref } from 'vue';
import { useTermsAndConditions } from '@/composables/useTermsAndConditions';
import { logger } from '@/utils/logger';

const { isMobile } = useApp();
const { translate } = useTranslate();
const { selectTermsAndConditions, fetchTermsAndConditions, termsAndConditionsData, selectedTermsAndConditions } =
    useTermsAndConditions();
const isTermsLoading = ref(true);
const showCloseSessionModal = ref(false);

const init = () => {
    fetchTermsAndConditions()
        .catch((e) => {
            logger('Terms and condition', e);
        })
        .finally(() => (isTermsLoading.value = false));
};

init();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

li {
    @include body2;
}

ul {
    padding: $padding-none $padding-lg;
    margin: $margin-sm $margin-none;
}

.settings-terms {
    margin-bottom: $margin-sm;
    text-decoration: underline;
    cursor: pointer;
}

.settings-tile-icon {
    width: 7rem;
    @include tablet {
        width: 4rem;
    }
}

.settings_btns {
    display: flex;
    margin-top: $margin-lg;
    justify-content: center;
}

.last-tile {
    background-color: $black;
}
</style>
