import { useStore } from 'vuex';
import { computed, toRaw } from 'vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import { orderService } from '@/modules/orders/infraestructure/OrderService';
import { PaymentObject } from '@/modules/core/models/PaymentObject';
import { OrderResumenModel } from '@/models/order/OrderResumen';
import { useOrder } from '@/composables/useOrder';

export const useResentPayment = () => {
    const $store = useStore();
    const { orderRejected } = useOrder();

    const orderSelected = computed<OrderResumenModel>(() => $store.getters['orders/orderDetailSelected']);
    const paymentsToShow = computed<PaymentToShowModel[]>(() => $store.getters['payments/paymentsToShow']);

    const resentPayment = async () => {
        const payments = paymentsToShow.value.map((payment: PaymentToShowModel) => toRaw(payment));
        return orderService.resentPayment({
            payment: new PaymentObject(payments),
            orderId: orderRejected.value?.orderId ?? orderSelected?.value?.id,
        });
    };

    return {
        resentPayment,
    };
};
