class TiptiCardsBeneficiary {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    profilePicture: string;
    constructor(id: number, email: string, firstName: string, lastName: string, profilePicture?: string) {
        this.id = id;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.profilePicture = profilePicture;
    }
}

const toTiptiCardsBeneficiary = (data: object) => {
    return data
        ? new TiptiCardsBeneficiary(
              data['id'],
              data['email'] ?? '',
              data['first_name'] ?? '',
              data['last_name'] ?? '',
              data['profile_picture'],
          )
        : null;
};

export { TiptiCardsBeneficiary, toTiptiCardsBeneficiary };
