<template>
    <div class="scroller-item" :data-id="id" :data-name="name">
        <slot name="content" />
    </div>
</template>
<script lang="ts">
export default {
    name: 'scroller-item',
    props: {
        observer: {
            type: IntersectionObserver,
        },
        id: {
            type: Number,
        },
        name: {
            type: String,
        },
    },
    mounted() {
        this.observer.observe(this.$el);
    },
    unmounted() {
        this.observer.unobserve(this.$el);
    },
};
</script>
