import { CashWirePaymentModel } from '@/models/checkout/CashWirePaymentModel';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import { DeferredModel } from '@/models/paymentMethods/DeferredModel';

import InstitutionalPaymentModel from '@/models/checkout/InstitutionalPaymentModel';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import { OnlineBankServiceModel } from '@/models/paymentMethods/OnlineBankServiceModel';
import { PayphoneModel } from '@/models/paymentMethods/PayphoneModel';
import { DefaultPaymentTypeModel } from '@/models/paymentMethods/DefaultPaymentTypeModel';
import { DefaultPaymentModel } from '@/models/paymentMethods/DefaultPaymentModel';
import NequiPaymentModel from '@/models/checkout/NequiPaymentModel';

export default {
    totalToPay(state): number {
        return state.totalToPay;
    },
    creditCardDefault(state): PaymentMethodModel {
        return state.creditCardDefault;
    },
    creditCardSelected(state): PaymentMethodModel {
        return state.creditCardSelected;
    },
    paymentIdSelected(state): number {
        return state.paymentIdSelected;
    },
    paymentsToShow(state): Array<PaymentToShowModel> {
        return state.paymentsToShow;
    },
    deferred(state): DeferredModel {
        return state.deferred;
    },
    deferredOptions(state): Array<DeferredModel> {
        return state.deferredOptions;
    },
    cashWirePayment(state): CashWirePaymentModel {
        return state.cashWirePayment;
    },
    institutionPayment(state): InstitutionalPaymentModel {
        return state.institutionPayment;
    },
    onlineBankServicePayment(state): OnlineBankServiceModel {
        return state.onlineBankServicePayment;
    },
    nequiPayment(state): NequiPaymentModel {
        return state.nequiPayment;
    },
    isTiptiCardPayment(state): boolean {
        return state.isTiptiCardPayment;
    },
    isMixPaymentTiptiCard(state): boolean {
        return state.isMixPaymentTiptiCard;
    },
    isMixInstitutionalPayment(state): boolean {
        return state.isMixInstitutionalPayment;
    },
    isYappyPayment(state): boolean {
        return state.isYappyPayment;
    },
    secondPayment(state): number {
        return state.secondPayment;
    },
    payphoneList(state): Array<PayphoneModel> {
        return state.payphoneList;
    },
    defaultPaymentsTypesAvailable(state): Array<DefaultPaymentTypeModel> {
        return state.defaultPaymentsTypesAvailable;
    },
    defaultPaymentSelected(state): DefaultPaymentModel {
        return state.defaultPaymentSelected;
    },
};
