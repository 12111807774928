<template>
    <base-icon :height="height" :iconColor="color" :width="width" viewBox="0 0 20 18">
        <path
            :fill="color"
            d="M19.2079 3.43671C18.7459 2.97408 18.0858 2.64363 17.3597 2.64363H15.0495L14.5215 1.25572C14.3894 0.925269 14.1254 0.594816 13.7954 0.396544C13.4653 0.132181 13.1353 0 12.7393 0H7.32673C6.93069 0 6.60066 0.132181 6.27063 0.396544C5.94059 0.594816 5.67657 0.925269 5.54455 1.25572L5.0165 2.64363H2.64026C1.9802 2.64363 1.25413 2.97408 0.792079 3.43671C0.264026 3.96544 0 4.62635 0 5.35334V14.7382C0 15.4652 0.264026 16.1261 0.792079 16.6548C1.25413 17.1836 1.9802 17.4479 2.70627 17.4479H17.3597C18.8119 17.4479 20 16.2583 20 14.8043V5.35334C20 4.62635 19.736 3.96544 19.2079 3.43671ZM13.3333 13.3503C12.4752 14.2756 11.2871 14.7382 10.033 14.7382C8.77888 14.7382 7.59076 14.2756 6.73267 13.3503C4.88449 11.5659 4.88449 8.59178 6.73267 6.74125C7.59076 5.81598 8.77888 5.35334 10.033 5.35334C11.2871 5.35334 12.4752 5.81598 13.3333 6.74125C15.1155 8.52569 15.1155 11.4998 13.3333 13.3503C13.3333 13.3503 13.3333 13.2842 13.3333 13.3503Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconCamera',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C90808',
        },
    },
    computed: {
        width(): number {
            return 20 * this.size;
        },
        height(): number {
            return 18 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>