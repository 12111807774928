<template>
    <skeleton-subheader :show-skeleton-btn="hasHeader" />
    <carousel-tipti :items-shown="maxSlides">
        <base-bone v-for="slide in maxSlides" :key="slide" class="card" />
    </carousel-tipti>
</template>

<script>
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import SkeletonSubheader from '../skeleton_subheader/SkeletonSubheader.vue';

export default {
    name: 'SkeletonCarousel',
    components: { CarouselTipti, BaseBone, SkeletonSubheader },
    props: {
        maxSlides: {
            type: Number,
            default: 8,
        },
        hasHeader: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.card {
    height: 18rem;
    width: 12rem;
    @include phone {
        height: 16rem;
    }
}
</style>
