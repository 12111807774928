import { useRouter } from 'vue-router';
import { cleanNote } from '@/utils/productUtils';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';

export function useClosePageProductDetail() {
    const router = useRouter();
    const store = useStore();
    const product = computed<ProductModel>(() => store.getters['product/currentProduct']);
    const tmpNote = computed<string>(() => store.getters['product/tmpNote']);
    const tmpFile = computed<File>(() => store.getters['product/tmpFile']);
    const closePageProductDetail = async (): Promise<void> => {
        if (
            +product.value.productQuantity > 0 &&
            tmpNote.value?.length &&
            tmpNote.value?.toLowerCase() !== cleanNote(product.value?.note)
        ) {
            store.commit('product/showAlertNotSaveNote', true);
            return;
        }

        if (+product.value.productQuantity > 0 && tmpFile.value?.name !== product.value?.noteToShopperPhotoFile?.name) {
            store.commit('product/showAlertNotSaveNote', true);
            return;
        }
        new Promise(() => {
            router.back();
        }).then(() => {
            store.commit('product/productSelected', null);
            store.commit('product/currentProduct', null);
            store.commit('product/itemSelected', null);
        });
    };

    return {
        closePageProductDetail,
    };
}
