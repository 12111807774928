import dayjs, { Dayjs } from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useUser } from '@/composables/useUser';
import { useText } from '@/composables/useText';

require('dayjs/locale/es');
require('dayjs/locale/en');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);

interface DoFormatInputInterface {
    date: string | number | Date | Dayjs;
    format: string;
    upper?: boolean;
    title?: boolean;
}

export const useDays = () => {
    const { userLanguage } = useUser();
    const { capitalize } = useText();
    const doFormat = ({
        date,
        format = 'DD/MM/YYYY',
        upper = false,
        title = false,
    }: DoFormatInputInterface): string => {
        const value = dayjs(date)
            .locale(userLanguage.value ?? 'es')
            .format(format);
        if (upper) return value.toUpperCase();
        if (title) return capitalize(value);
        return value;
    };

    const setTimeZone = (date: string) => {
        if (!date) return;
        return new Date(date).toLocaleString('en', {
            timeZone: 'America/Guayaquil',
        });
    };

    const toStringCalendar = (date: string | Date): string => {
        return dayjs(date)
            .locale(userLanguage.value.toLowerCase())
            .calendar(date, {
                sameDay: userLanguage.value.toLowerCase() == 'es' ? '[hoy día hasta] HH:mm' : '[Today at] HH:mm',
                nextDay: userLanguage.value.toLowerCase() == 'es' ? '[Mañana hasta] HH:mm' : '[Tomorrow at] HH:mm',
                sameElse: 'DD/MM/YYYY HH:mm',
            });
    };

    const isBefore = (date: string | Date, dateToCompare = dayjs()): boolean => dayjs(date).isBefore(dateToCompare);
    const isAfter = (date: string | Date, dateToCompare = dayjs()): boolean => !dayjs(date).isBefore(dateToCompare);

    const hours = (date: Date) => {
        if (!date) return;
        return dayjs(date).locale(userLanguage.value.toLowerCase()).format('HH:mm');
    };

    return { doFormat, toStringCalendar, setTimeZone, isBefore, isAfter, hours };
};
