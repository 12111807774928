<template>
    <aside class="retailer-bussiness">
        <img :src="imageToShow" alt="Tipti app movil" />
        <div class="information">
            <h1 class="information__title">
                {{ $t("txt['become-retailer__what-does-tipti']") }}<br />
                {{ $t("txt['become-retailer__with-your-bussiness']") }}
            </h1>
            <div class="benefits">
                <ul v-for="(items, index) in listItems" :key="index">
                    <li class="color-tipti-green" v-for="(item, indexI) in items" :key="indexI">
                        {{ item }}
                    </li>
                </ul>
            </div>
        </div>
    </aside>
</template>

<script>
import { isMobileBrowser } from '@/utils/utils';
export default {
    name: 'retailer-bussiness',
    data() {
        return {
            listItems: [
                [
                    this.$t("txt['become-retailer__increase-customer-base']"),
                    this.$t("txt['become-retailer__increase-purchase-loyalty']"),
                    this.$t("txt['become-retailer__increase-purchase-frequency']"),
                    this.$t("txt['become-retailer__average-ticket-increase']"),
                    this.$t("txt['become-retailer__average-ticket-profitability']"),
                    this.$t("txt['become-retailer__home-penetration']"),
                ],
                [
                    this.$t("txt['become-retailer__brand-adoption']"),
                    this.$t("txt['become-retailer__brand-preference']"),
                    this.$t("txt['become-retailer__brand-benefits']"),
                    this.$t("txt['become-retailer__approval-and-contribution']"),
                    this.$t("txt['become-retailer__effective-communication']"),
                ],
            ],
        };
    },
    computed: {
        imageToShow() {
            return isMobileBrowser()
                ? require('@/assets/img/tipti-app-mid.png')
                : require('@/assets/img/tipti-app-full.png');
        },
    },
};
</script>

<style lang="scss" scoped>
@import './retailer-bussiness.scss';
</style>
