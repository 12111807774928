<template>
    <aside class="search-results">
        <h4>{{ $t('general.search-results') }}</h4>
        <div
            v-for="product in products"
            :key="product.stockItemId"
            class="autocomplete-option cursor-pointer"
            @click="$emit('on-click', product.name)"
        >
            <img v-if="product.image.medium" :src="product.image.medium" alt="" />
            <icon-tipti v-else :size="0.5" />
            <h5 v-html="boldQuery(product.name)"></h5>
            <icon-search :size="0.07" />
        </div>
    </aside>
</template>

<script lang="ts" setup>
import IconSearch from '@/components/icons/IconSearch.vue';
import IconTipti from '@/components/icons/IconTipti.vue';
import { ProductModel } from '@/models/product/ProductModel';

const props = defineProps({
    products: {
        type: Array<ProductModel>,
        required: true,
    },
    query: {
        type: String,
        default: '',
    },
});
defineEmits<(e: 'on-click', value: string) => void>();

const boldQuery = (word: string): string => {
    return word
        ?.toLowerCase()
        ?.replaceAll(props.query.toLowerCase(), `<span style="font-weight:600">${props.query.toLowerCase()}</span>`);
};
</script>

<style lang="scss" scoped>
@import './search-results.scss';
</style>
