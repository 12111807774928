import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-704cfd06"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "card-product-special",
  itemscope: "",
  itemtype: "http://schema.org/Product"
}
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = {
  key: 1,
  class: "card-product-special__image cursor-pointer"
}
const _hoisted_4 = { class: "card-product-special__information" }
const _hoisted_5 = { class: "information" }
const _hoisted_6 = { class: "information__special-price" }
const _hoisted_7 = { class: "information__price" }
const _hoisted_8 = { class: "information__regular-price" }
const _hoisted_9 = {
  key: 0,
  class: "information__units"
}
const _hoisted_10 = {
  key: 1,
  class: "information__units"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_tipti = _resolveComponent("icon-tipti")!
  const _component_PercentageDiscount = _resolveComponent("PercentageDiscount")!
  const _component_the_product_action_button = _resolveComponent("the-product-action-button")!
  const _component_product_indicators = _resolveComponent("product-indicators")!
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    ($props.productData.image?.medium)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "card-product-special__image cursor-pointer",
          alt: $props.productData?.name,
          src: $props.productData.image.medium,
          itemprop: "thumbnail",
          loading: "lazy",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDetailProduct && _ctx.openDetailProduct(...args))),
          onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.displayDefaultImg && _ctx.displayDefaultImg(...args)))
        }, null, 40, _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_icon_tipti)
        ])),
    _createVNode(_component_PercentageDiscount, {
      product: $props.productData,
      "where-to-show": "CardSpecial"
    }, null, 8, ["product"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_the_product_action_button, {
        class: "actions",
        "is-for-replacement": false,
        "product-data": $props.productData,
        section: $props.section
      }, null, 8, ["product-data", "section"]),
      _createElementVNode("h3", {
        class: "card-product-special__name cursor-pointer",
        itemprop: "name",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openDetailProduct && _ctx.openDetailProduct(...args)))
      }, _toDisplayString($props.productData.name), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.priceLabel?.toUpperCase()), 1),
        _withDirectives(_createElementVNode("p", _hoisted_7, null, 512), [
          [_directive_currency, $props.productData?.priceWithDiscount]
        ]),
        _withDirectives(_createElementVNode("p", _hoisted_8, null, 512), [
          [_directive_currency, $props.productData?.price]
        ]),
        ($props.productData?.groupId && _ctx.attributeValue.length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.attributeValue), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString($props.productData.unitQuantity) + " " + _toDisplayString($props.productData.unit), 1))
      ]),
      _createVNode(_component_product_indicators, {
        indicators: $props.productData.indicators,
        size: 0.8
      }, null, 8, ["indicators"])
    ])
  ]))
}