<template>
    <article class="card-product-special" itemscope itemtype="http://schema.org/Product">
        <img
            v-if="productData.image?.medium"
            class="card-product-special__image cursor-pointer"
            :alt="productData?.name"
            :src="productData.image.medium"
            itemprop="thumbnail"
            loading="lazy"
            @click="openDetailProduct"
            @error="displayDefaultImg"
        />
        <div v-else class="card-product-special__image cursor-pointer">
            <icon-tipti />
        </div>
        <PercentageDiscount :product="productData" where-to-show="CardSpecial" />
        <div class="card-product-special__information">
            <the-product-action-button
                class="actions"
                :is-for-replacement="false"
                :product-data="productData"
                :section="section"
            />
            <h3 class="card-product-special__name cursor-pointer" itemprop="name" @click="openDetailProduct">
                {{ productData.name }}
            </h3>
            <div class="information">
                <p class="information__special-price">{{ priceLabel?.toUpperCase() }}</p>
                <p v-currency="productData?.priceWithDiscount" class="information__price" />
                <p v-currency="productData?.price" class="information__regular-price" />
                <p v-if="productData?.groupId && attributeValue.length" class="information__units">
                    {{ attributeValue }}
                </p>
                <p v-else class="information__units">{{ productData.unitQuantity }} {{ productData.unit }}</p>
            </div>
            <product-indicators :indicators="productData.indicators" :size="0.8" />
        </div>
    </article>
</template>

<script lang="ts">
import { ProductModel } from '@/models/product/ProductModel';
import IconTipti from '@/components/icons/IconTipti.vue';
import productCard from '@/mixings/productCards';
import { useProductAttribute } from '@/composables/ProductAttributesComposable';
import { useImage } from '@/composables/ImageComposable';
import { useProductTags } from '@/composables/useProductTags';
import type { PropType } from 'vue';
import { defineAsyncComponent } from 'vue';
import ProductIndicators from '@/components/badges/productIndicators/ProductIndicators.vue';
import PercentageDiscount from '@/components/cards/cardProduct/PercentageDiscount.vue';

export default {
    name: 'CardProductSpecial',
    components: {
        PercentageDiscount,
        TheProductActionButton: defineAsyncComponent(() => import('@/components/buttons/TheProductActionButton.vue')),
        IconTipti,
        ProductIndicators,
    },
    mixins: [productCard],
    props: {
        isSearchByStockItem: {
            type: Boolean,
            default: false,
        },
        productData: {
            type: Object as PropType<ProductModel>,
            required: true,
        },
        section: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        return {
            ...useProductAttribute(props.productData),
            ...useImage(),
            ...useProductTags(),
        };
    },
    data() {
        return {
            product: this.productData,
        };
    },
};
</script>

<style lang="scss" scoped>
@import './card-product-special.scss';
</style>
