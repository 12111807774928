import { defineStore } from 'pinia';
import { logger } from '@/utils/logger';
import { BillAddress, BillsByCountry, BillSchemaResponse, InvoiceDocument } from '@/models/BillModels';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { ResponsePaged } from '@/modules/core/models/ResponseModels';

export const useBillStore = defineStore('bill', {
    state: (): {
        defaultBill: BillAddress;
        selectedBill: BillAddress;
    } => ({
        defaultBill: {} as BillAddress,
        selectedBill: {} as BillAddress,
    }),
    getters: {
        billUsed(state): BillAddress {
            return state.selectedBill?.id ? state.selectedBill : state.defaultBill;
        },
    },
    actions: {
        async getAll(country_code?: string): Promise<BillsByCountry[]> {
            try {
                const { data } = await axiosInstance().get<BillsByCountry[]>(`client_profile/billinginfo/all`, {
                    params: {
                        country: country_code,
                        'group-by': !country_code ? 'country' : undefined,
                    },
                });
                if (country_code?.length)
                    this.defaultBill = data
                        .find((country) => country.bill_address?.find((bill) => bill.default))
                        ?.bill_address.find((bill) => bill.default);
                return data;
            } catch (err) {
                logger('GET_BILLING_INFO', err);
            }
        },
        async create(data: object): Promise<boolean> {
            try {
                const response = await axiosInstance().post(
                    `v2/client_profile/billing_info/?schema_validator=true`,
                    data,
                );

                return response.status === 201;
            } catch (err) {
                if (err?.response?.data?.non_field_errors[0]) throw Error(err?.response?.data?.non_field_errors[0]);
                else throw err;
            }
        },
        async update(bill: object): Promise<void> {
            try {
                await axiosInstance().put(`v2/client_profile/billing_info/${bill['id']}?schema_validator=true`, bill);
            } catch (err) {
                if (err?.response?.data?.non_field_errors[0]) throw Error(err?.response?.data?.non_field_errors[0]);
                else throw err;
            }
        },
        async delete(id: number): Promise<boolean> {
            try {
                const response = await axiosInstance().delete(`v2/client_profile/billing_info/${id}`);
                return response.status === 200;
            } catch (err) {
                logger('DELETE_BILLING_INFO', err);
            }
        },
        async getBillingFilesInformation({
            order_id,
            page,
        }: {
            page: number;
            order_id?: string;
        }): Promise<ResponsePaged<InvoiceDocument>> {
            const { data } = await axiosInstance().get<ResponsePaged<InvoiceDocument>>(`v2/billing/documents`, {
                params: {
                    page,
                    order_id,
                },
                cancelPreviousRequests: true,
            });
            return data;
        },
        async getSchema(country_code?: string): Promise<BillSchemaResponse[]> {
            try {
                const { data } = await axiosInstance().get(`clients/billing/document_types`, {
                    params: {
                        country: country_code,
                    },
                });
                return data.document_types as BillSchemaResponse[];
            } catch (err) {
                logger('GET_BILL_SCHEMA', err);
            }
        },
    },
});
