import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d3e4c496"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "verify-email" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "form-container" }
const _hoisted_4 = {
  key: 0,
  class: "label-input",
  for: "email"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_mail_recovery = _resolveComponent("icon-mail-recovery")!
  const _component_TiptiInputText = _resolveComponent("TiptiInputText")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_icon_mail_recovery, {
        class: "icon-mail",
        size: 0.85
      }),
      _createElementVNode("p", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t("txt['recovery__password-insert-the-mail-one']")) + " ", 1),
        _createElementVNode("span", null, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t("txt['recovery__password-insert-the-mail-two']")), 1)
        ]),
        _createTextVNode(" " + _toDisplayString(_ctx.$t("txt['recovery__password-insert-the-mail-three']")), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        ($data.inputMail)
          ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString(_ctx.$t("txt['login__enter-your-email']")), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_TiptiInputText, {
          modelValue: $data.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.email) = $event)),
          label: _ctx.$t('general.email'),
          autocomplete: "email",
          "is-required": "",
          placeholder: "email@tipti.com",
          type: "email"
        }, null, 8, ["modelValue", "label"])
      ]),
      _createVNode(_component_btn_solid, {
        "is-loading": $data.isLoading,
        txt: _ctx.$t('general.send'),
        onClick: $options.verifyEmail
      }, null, 8, ["is-loading", "txt", "onClick"])
    ]),
    _createVNode(_component_snack_bar, {
      body: $data.snackBarMessage,
      "is-active": $data.showSnackBar,
      "is-failure": $data.isFailure,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[1] || (_cache[1] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}