import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-abfeee04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-pdf__file" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfFrame = _resolveComponent("PdfFrame")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_section_footer = _resolveComponent("section-footer")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PdfFrame, {
        class: "pdf__content",
        src: $options.currentPrime?.termsAndConditionsFile
      }, null, 8, ["src"])
    ]),
    (!$props.onlyView)
      ? (_openBlock(), _createBlock(_component_section_footer, {
          key: 0,
          class: "alert-pdf__footer",
          "with-shadow": false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_btn_solid, {
              "is-loading": $data.isLoading,
              txt: _ctx.$t('txt.prime__alert-terms-accepted'),
              onClick: $options.confirm
            }, null, 8, ["is-loading", "txt", "onClick"]),
            _createVNode(_component_btn_out_line, {
              "is-disabled": $data.isLoading,
              txt: _ctx.$t('cta.cancel'),
              "bg-color": "#FFF",
              color: "#FF9012",
              onClick: $options.deny
            }, null, 8, ["is-disabled", "txt", "onClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_snack_bar, {
      body: $data.snackBarMessage,
      "is-active": $data.showSnackBar,
      "is-failure": $data.isFailure,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: $data.appRoutesMap.tiptiCardRoutes.history })))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}