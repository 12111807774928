import type { RouteRecordRaw } from 'vue-router';
import News from '@/views/newsPress/News.vue';
import ThePageNewsDetail from '@/views/newsPress/pages/ThePageNewsDetail.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export const NewsRoute: RouteRecordRaw[] = [
    {
        path: `/news`,
        name: appRoutesMap.news.home,
        component: News,
    },
    {
        name: appRoutesMap.news.detail,
        path: '/news/:id/:title?',
        component: ThePageNewsDetail,
    },
];
