<template>
    <section class="skeleton-checkout">
        <aside class="skeleton-checkout__left">
            <base-card padding="0" class="skeleton-checkout__left-menu animate "> </base-card>
            <base-card padding="0" class="skeleton-checkout__left-retailers animate "> </base-card>
            <base-card padding="0" class="skeleton-checkout__left-retailers animate "> </base-card>
        </aside>
        <aside class="skeleton-checkout__right">
            <base-card padding="0" class="skeleton-checkout__right-balance animate "> </base-card>
        </aside>
    </section>
</template>

<script lang="ts">
import BaseCard from '@/components/cards/BaseCard.vue';

export default {
    name: 'skeleton-checkout',
    components: {
        BaseCard,
    },
};
</script>
<style lang="scss" scoped>
@import './skeleton-checkout.scss';
</style>
