<script lang="ts" setup>
import { PropType, ref, shallowReactive } from 'vue';
import { CategoryModel } from '@/models/product/CategoryModel';
import { useProductStore } from '@/store/useProductStore';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import CarouselForProducts from '@/components/carousels/CarouselForProducts.vue';
import { useApp } from '@/composables/useApp';
import { logger } from '@/utils/logger';
import { RequestState } from '@/modules/core/types/WorkingState';
import { ProductModel } from '@/models/product/ProductModel';
import { useCategories } from '@/modules/main-products/composables/useCategories';
import { appRoutesMap } from '@/router/appRoutesMap';
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';
import SkeletonSubheader from '@/components/skeletons/skeleton_subheader/SkeletonSubheader.vue';

const props = defineProps({
    category: {
        type: Object as PropType<CategoryModel>,
        required: true,
    },
});

const page = ref(1);
const { $store, $router } = useApp();
const { recommendationsByCategory } = useProductStore();
const { retailerSelected } = useRetailer();
const { subcategories } = useCategories();
const products = shallowReactive<ProductModel[]>([]);

const requestState = ref<RequestState>('FIRST_LOAD');

const goToSeeMore = () => {
    $store.commit('categories/setCategorySelected', props.category);
    $store.commit('categories/subcategoriesList', subcategories.value ?? []);
    $router.push({
        name: appRoutesMap.retail.baseCategoryView,
        params: {
            category: props.category.name,
        },
    });
};

const getProducts = async (): Promise<void> => {
    try {
        if (requestState.value === 'LOAD-ENDED' || requestState.value === 'LOADING') return;
        requestState.value = page.value === 1 ? 'FIRST_LOAD' : 'LOADING';
        const { results, next } = await recommendationsByCategory({
            page: page.value,
            category_id: props.category.id,
            retailer_id: retailerSelected.value.id,
        });
        page.value++;
        requestState.value = next?.length ? 'NONE' : 'LOAD-ENDED';
        products.push(...results);
    } catch (err) {
        logger('FETCH_CATEGORY', err);
        requestState.value = 'LOAD-ENDED';
    }
};

const isInter = (value: boolean) => {
    if (!value) return;
    if (products.length) return;
    getProducts();
};
</script>

<template>
    <CarouselForProducts
        :key="category.id"
        :items="products"
        :state="requestState"
        :subheader-name="category.name"
        has-subheader-padding
        @on-click-subheader="goToSeeMore"
        @reached-end="getProducts"
    >
        <template #header>
            <SkeletonSubheader v-if="requestState === 'FIRST_LOAD'" @is-visible="isInter" />
            <SubheaderSeparator
                v-else
                :name="category.name"
                :show-btn="!!products.length"
                has-padding
                @on-pressed="goToSeeMore"
            />
        </template>
    </CarouselForProducts>
</template>
