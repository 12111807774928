<template>
    <alert-manager
        :is-loading="false"
        :show-buttons="!isVerifying"
        :title="title"
        :only-confirmation-btn="true"
        :accept-button-text="$t('cta.verify')"
        :show-bottom-sheet-title="false"
        :has-padding-btn="true"
        :is-disabled="isDisable"
        :btn-x-padding="1"
        :btn-y-padding="1"
        @on-accept="onAccept"
        @on-close="$emit('on-close')"
        @on-dismiss="$emit('on-close')"
    >
        <template v-slot:content>
            <div class="validate-card">
                <icon-shield-check class="validate-card__img" :size="2" />
                <h2 class="validate-card__mobile-title">{{ title }}</h2>
                <h3 class="validate-card__txt">
                    {{
                        $t('txt.credit-card__discount-amount', {
                            currency,
                            amount: debitAmount,
                        })
                    }}
                </h3>
                <h3 class="validate-card__reimbursed validate-card__txt">
                    {{ $t('txt.credit-card__amount') }}
                    <span class="validate-card__reimbursed--highlight">
                        {{ $t('txt.credit-card__reimbursed') }}
                    </span>
                    {{ $t('txt.credit-card__automatically') }}
                </h3>
                <h3 class="validate-card__txt--close validate-card__txt">
                    {{ $t('general.dont_close_app') }}
                </h3>
                <p
                    class="validate-card__txt--benefits validate-card__txt cursor-pointer"
                    @click="$emit('on-show-benefits')"
                    v-if="!isVerifying"
                >
                    {{ $t('txt.credit-card__benefits') }}
                </p>
                <triple-loading v-else />
            </div>
        </template>
    </alert-manager>
</template>

<script lang="ts">
import TripleLoading from '@/components/loading/TripleLoading.vue';
import IconShieldCheck from '@/components/icons/IconShieldCheck.vue';
import AlertManager from '@/components/alerts/alertManager/AlertManager.vue';
import { mapGetters } from 'vuex';
import { logger } from '@/utils/logger';

export default {
    name: 'generate-validation',
    data() {
        return {
            title: this.$t('txt.credit-card__validate'),
            isDisable: false,
            isVerifying: false,
            showButtons: true,
        };
    },
    props: {
        paymentMethodId: {
            type: Number,
            required: true,
        },
        forceValidate: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TripleLoading,
        IconShieldCheck,
        AlertManager,
    },
    emits: ['on-close', 'on-success', 'on-error', 'on-show-benefits'],
    computed: {
        ...mapGetters({ countrySelected: 'external/countrySelected', baseConfiguration: 'user/baseConfiguration' }),
        currency(): string {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        debitAmount(): number {
            return this.baseConfiguration?.maxDebitAmount ?? 0;
        },
    },
    methods: {
        onAccept(): void {
            this.generateValidation();
        },
        async generateValidation(): Promise<void> {
            try {
                this.isVerifying = true;
                await this.$store.dispatch('payments/generateVerification', this.paymentMethodId);
                this.$emit('on-success');
            } catch (err) {
                this.requestError = true;
                logger('GENERATE_VERIFICATION_ATTEMPT', err);
                this.$emit('on-error');
            } finally {
                this.isVerifying = false;
            }
        },
    },
    mounted() {
        if (this.forceValidate) {
            this.generateValidation();
        }
    },
};
</script>

<style scoped lang="scss">
@import 'generate-validation.scss';
</style>
