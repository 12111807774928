<template>
    <div class="profile-breadcrumb">
        <p v-for="(route, i) in breadcrumb" :key="route">
            {{ route === breadcrumbDefinition.order ? `${$t('general.order')} #${orderId}` : $t(route) }}
            <em v-if="i !== breadcrumb.length - 1" class="arrow-right"></em>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { breadcrumbDefinition } from '@/components/breadcrumb/BreadcumbDefinition';
import { useWallet } from '@/views/wallet/useWallet';

const $store = useStore();
const $route = useRoute();
const { creditCardSelected, membershipSelected, giftCardSelected } = useWallet();

const breadcrumb = computed(() => {
    if (creditCardSelected.value?.id) return [...$route.meta.breadcrumbs, breadcrumbDefinition.registeredCard];
    if (membershipSelected.value?.id) return [...$route.meta.breadcrumbs, breadcrumbDefinition.membershipCard];
    if (giftCardSelected.value?.id && $route.meta.breadcrumbs.length > 0)
        return [...$route.meta.breadcrumbs, giftCardSelected.value.card_name];
    return $route.meta.breadcrumbs ?? [];
});

const orderId = computed(() => $route.params.orderId ?? 0);
</script>

<style lang="scss" scoped>
@import '../../assets/scss/index.scss';

.profile-breadcrumb {
    height: min-content;
    display: flex;
    flex-flow: row;
    margin-top: $margin-sm;
    margin-bottom: $margin-md;
    @include tablet {
        display: none;
    }

    & p {
        @include caption;
        color: $txt;
        margin-left: $margin-sm;
    }
}

.arrow-right {
    border: solid $orange;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
</style>
