<template>
    <list-subcategories-body
        class="banner-categories-list"
        :subcategories="categories"
        @on-select-subcategory="onSelectCategory"
    />
    <subheader-products
        class="subheader"
        :display-name="categoryName"
        :has-button="false"
        :name="categoryName"
        has-return
    />
    <div id="productsBody" class="products-body" :class="{ 'products-body--scroll': localScroll }">
        <div ref="categoryProducts">
            <product-not-found v-if="!productsByCategory.length && !isLoading" />
            <template v-else>
                <subheader-separator :name="categoryName" :show-btn="false" has-padding />
                <products-grid class="products-grid" :products="productsByCategory" />
            </template>
            <SkeletonGrid v-if="isLoading" />
        </div>
    </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import ProductNotFound from '@/views/productNotFound/ProductNotFound.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { defineAsyncComponent } from 'vue';
import { useBanners } from '@/modules/banners/composables/useBanners';
import SubheaderProducts from '@/components/subheaders/subheaderProducts/SubheaderProducts.vue';
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';
import ListSubcategoriesBody from '@/components/category/listSubcategories/ListSubcategoriesBody.vue';
import { CategoryModel } from '@/models/product/CategoryModel';
import SkeletonGrid from '@/components/skeletons/skeletonGrid/SkeletonGrid.vue';

export default {
    name: 'CategoryProductsInBanner',
    data() {
        return {
            page: 1,
        };
    },
    components: {
        SkeletonGrid,
        ProductNotFound,
        ProductsGrid: defineAsyncComponent(() => import('@/components/product/productsGrid/ProductsGrid.vue')),
        SubheaderProducts,
        SubheaderSeparator,
        ListSubcategoriesBody,
    },
    props: {
        localScroll: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { retailerSelected } = useRetailer();
        const { isLoading, fetchProductsFromCategoryId, productsByCategory, fetchCategories, categories } =
            useBanners();
        return {
            isLoading,
            retailerSelected,
            fetchCategories,
            categories,
            productsByCategory,
            fetchProductsFromCategoryId,
        };
    },
    computed: {
        ...mapGetters({
            bannerInfo: 'promotions/bannerSelected',
            categoryInfo: 'categories/getCategorySelected',
        }),
        categoryName(): string {
            return this.categoryInfo.name;
        },
    },
    async created() {
        await this.fetchCategories(this.bannerInfo.id, this.page);
        this.fetchProductsFromCategoryId(this.bannerInfo.id, this.categoryInfo.id, this.page);
    },
    methods: {
        onSelectCategory(category: CategoryModel): void {
            this.$store.commit('categories/setCategorySelected', category);
            this.fetchProductsFromCategoryId(this.bannerInfo.id, category.id, this.page);
        },
    },
};
</script>
<style lang="scss" scoped>
@import './category-products-in-banner.scss';
</style>
