<template>
    <base-icon
        :icon-name="$t('icon.remove')"
        :width="width"
        :height="height"
        viewBox="0 -192 469.33333 469"
        :iconColor="color"
    >
        <path
            d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-decrement',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 10 * this.size;
        },
        height(): number {
            return 10 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
