<template>
    <base-icon
        :icon-name="$t('icon.categories')"
        :width="width"
        :height="height"
        viewBox="0 0 24 25"
        :iconColor="color"
    >
        <path
            d="M0 1.18279C0 0.744209 0.358172 0.388672 0.799999 0.388672H6.14214C6.58397 0.388672 6.94214 0.74421 6.94214 1.18279V6.37361C6.94214 6.81219 6.58397 7.16772 6.14214 7.16772H0.799999C0.358172 7.16772 0 6.81219 0 6.37361V1.18279Z"
        />
        <path
            d="M0 9.70502C0 9.26645 0.358172 8.91091 0.799999 8.91091H6.14214C6.58397 8.91091 6.94214 9.26645 6.94214 9.70503V14.8958C6.94214 15.3344 6.58397 15.69 6.14214 15.69H0.799999C0.358172 15.69 0 15.3344 0 14.8958V9.70502Z"
        />
        <path
            d="M0 18.2273C0 17.7887 0.358172 17.4331 0.799999 17.4331H6.14214C6.58397 17.4331 6.94214 17.7887 6.94214 18.2273V23.4181C6.94214 23.8567 6.58397 24.2122 6.14214 24.2122H0.799999C0.358172 24.2122 0 23.8567 0 23.4181V18.2273Z"
        />
        <path
            d="M8.52892 1.18279C8.52892 0.744209 8.88709 0.388672 9.32892 0.388672H14.6711C15.1129 0.388672 15.4711 0.74421 15.4711 1.18279V6.37361C15.4711 6.81219 15.1129 7.16772 14.6711 7.16772H9.32892C8.88709 7.16772 8.52892 6.81219 8.52892 6.37361V1.18279Z"
        />
        <path
            d="M8.5289 9.70502C8.5289 9.26645 8.88707 8.91091 9.3289 8.91091H14.671C15.1129 8.91091 15.471 9.26645 15.471 9.70503V14.8958C15.471 15.3344 15.1129 15.69 14.671 15.69H9.3289C8.88707 15.69 8.5289 15.3344 8.5289 14.8958V9.70502Z"
        />
        <path
            d="M8.5289 18.2273C8.5289 17.7887 8.88707 17.4331 9.3289 17.4331H14.671C15.1129 17.4331 15.471 17.7887 15.471 18.2273V23.4181C15.471 23.8567 15.1129 24.2122 14.671 24.2122H9.3289C8.88707 24.2122 8.5289 23.8567 8.5289 23.4181V18.2273Z"
        />
        <path
            d="M17.0578 1.18279C17.0578 0.744209 17.416 0.388672 17.8578 0.388672H23.2C23.6418 0.388672 24 0.74421 24 1.18279V6.37361C24 6.81219 23.6418 7.16772 23.2 7.16772H17.8578C17.416 7.16772 17.0578 6.81219 17.0578 6.37361V1.18279Z"
        />
        <path
            d="M17.0578 9.70502C17.0578 9.26645 17.416 8.91091 17.8578 8.91091H23.2C23.6418 8.91091 24 9.26645 24 9.70503V14.8958C24 15.3344 23.6418 15.69 23.2 15.69H17.8578C17.416 15.69 17.0578 15.3344 17.0578 14.8958V9.70502Z"
        />
        <path
            d="M17.0578 18.2273C17.0578 17.7887 17.416 17.4331 17.8578 17.4331H23.2C23.6418 17.4331 24 17.7887 24 18.2273V23.4181C24 23.8567 23.6418 24.2122 23.2 24.2122H17.8578C17.416 24.2122 17.0578 23.8567 17.0578 23.4181V18.2273Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-category',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 24 * this.size;
        },
        height(): number {
            return 25 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
