import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36688f29"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "credit-card-tile"
}
const _hoisted_2 = { class: "credit-card-tile__card" }
const _hoisted_3 = { class: "credit-card-tile__owner" }
const _hoisted_4 = { class: "credit-card-data" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "credit-card-data__number" }
const _hoisted_7 = {
  key: 1,
  class: "credit-card-no-selected"
}
const _hoisted_8 = { class: "credit-card-no-selected__title" }
const _hoisted_9 = { class: "credit-card-no-selected__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_payment_methods = _resolveComponent("icon-payment-methods")!
  const _component_payment_method_expired = _resolveComponent("payment-method-expired")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!

  return (_openBlock(), _createBlock(_component_base_card_flat, {
    class: _normalizeClass(["credit-card", { 'credit-card__expired': $setup.hasPaymentMethodsExpired }]),
    "border-color": '#a2a2a2'
  }, {
    default: _withCtx(() => [
      ($options.creditCardToPay)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_icon_payment_methods, {
              size: 0.4,
              color: "#FF9012"
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(`${_ctx.$t('general.payment-cards')}`), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("img", {
                  class: "credit-card-data__logo",
                  src: $options.creditCardToPay.icon,
                  style: _normalizeStyle({ 'background-color': $options.creditCardToPay.backgroundColor ?? '#606060' }),
                  alt: "",
                  onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.displayDefaultImg && $setup.displayDefaultImg(...args)))
                }, null, 44, _hoisted_5),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (index) => {
                  return _createElementVNode("div", { key: index }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["credit-card-data__dot", $options.spaceDots(index)])
                    }, null, 2)
                  ])
                }), 64)),
                _createElementVNode("p", _hoisted_6, _toDisplayString($options.creditCardToPay.data), 1)
              ])
            ]),
            _createElementVNode("p", {
              class: "credit-card-tile__change cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-change-payment')))
            }, _toDisplayString($options.btnTxt), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('icon.payment_methods')), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('txt.checkout__enter-payment-method')), 1)
            ]),
            _createElementVNode("p", {
              class: "credit-card-no-selected__btn cursor-pointer",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-change-payment')))
            }, _toDisplayString($options.btnTxt), 1)
          ])),
      ($setup.hasPaymentMethodsExpired)
        ? (_openBlock(), _createBlock(_component_payment_method_expired, {
            key: 2,
            onOnChangeSelected: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-change-payment')))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}