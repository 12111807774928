<template>
    <div class="carousel" :class="{ __margin: hasMargin }">
        <div ref="slider" class="carousel-items">
            <slot></slot>
            <triple-loading v-if="isLoading" class="carousel-items__loading"></triple-loading>
        </div>
        <!-- <aside v-if="showBulletPoints" class="carousel-bullets">
<button
v-for="index in itemsShown"
:key="index"
class="carousel-bullets__button"
:class="{ 'carousel-bullets__button--active': bulletSelected == index }"
@click="goPosition(index)"
></button>
</aside> -->
        <aside v-if="showArrows">
            <icon-back-no-border
                v-show="showLeftArrow"
                class="carousel__arrow-back cursor-pointer"
                :background-fill="backgroundArrow"
                :color="colorArrow"
                :icon-name="$t('icon.backward')"
                :size="0.8"
                @click="goBack()"
            />
            <icon-back-no-border
                v-if="showRightArrow"
                class="carousel__arrow-next cursor-pointer"
                :background-fill="backgroundArrow"
                :color="colorArrow"
                :icon-name="$t('icon.forward')"
                :size="0.8"
                @click="goFoward()"
            />
        </aside>
    </div>
</template>

<script lang="ts">
import IconBackNoBorder from '@/components/icons/IconBackNoBorder.vue';
import TripleLoading from '@/components/loading/TripleLoading.vue';
import { useWindowSize } from '@vueuse/core/index';

export default {
    name: 'CarouselTipti',
    components: {
        IconBackNoBorder,
        TripleLoading,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        hasMargin: {
            type: Boolean,
            default: true,
        },
        isLastPage: {
            type: Boolean,
            default: false,
        },
        showBulletPoints: {
            type: Boolean,
            default: false,
        },
        itemsShown: {
            type: Number,
            default: 1,
        },
        hasArrowButton: {
            type: Boolean,
            default: true,
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
        transitionTime: {
            type: Number,
            default: 3,
        },
        hasPagination: {
            type: Boolean,
            default: true,
        },
        hasFade: {
            type: Boolean,
            default: false,
        },
        showArrows: {
            type: Boolean,
            default: true,
        },
        backgroundArrow: {
            type: String,
            default: '#dfdfdf',
        },
        colorArrow: {
            type: String,
            default: '#606060',
        },
    },
    emits: ['reached-end', 'reached-before-end'],
    setup() {
        const { width } = useWindowSize();
        return {
            width,
        };
    },
    data() {
        return {
            showLeftArrow: false,
            showRightArrow: this.hasArrowButton,
            bulletSelected: 1,
        };
    },
    watch: {
        width() {
            const slider = this.$refs.slider;
            if (!slider) return;
            this.showRightArrow = slider.scrollLeft + slider.clientWidth < slider.scrollWidth;
        },
    },
    unmounted() {
        window.clearInterval(this.intervalId);
    },
    async mounted() {
        this.scroll();
        const slider = this.$refs.slider;
        this.showRightArrow = slider.scrollLeft + slider.clientWidth < slider.scrollWidth;
        // if (this.intervalId) {
        //     for (let id = 0; id <= this.intervalId; id++) {
        //         window.clearInterval(id);
        //     }
        // }
        if (this.autoplay && this.showBulletPoints) this.startShowing();
    },
    methods: {
        goBack(): void {
            const slider = this.$refs.slider;
            if (!slider) return;
            slider.scrollBy({
                left: -slider.clientWidth,
                behaviour: 'smooth',
            });
            this.bulletSelected = this.bulletSelected - 1;
        },
        async goFoward(): Promise<void> {
            const slider = this.$refs.slider;
            if (!slider) return;
            slider.scrollBy({
                left: slider.clientWidth,
                behaviour: 'smooth',
            });
            this.bulletSelected = this.bulletSelected + 1;
            await this.fetchPage();
        },
        // goPosition(bulletIndex: number): void {
        /// ** DISBABLED UNTIL CORRECT WOR
        // const slider = this.$refs.slider;
        // if (!slider) return;
        // slider.scrollBy({
        //     left: slider.scrollWidth / bulletIndex,
        //     behaviour: 'smooth',
        // });
        // },
        async fetchPage(): Promise<void> {
            const slider = this.$refs.slider;
            if (!slider) return;
            const maxRightSide = slider.scrollLeft + slider.clientWidth + 50;
            if (maxRightSide >= slider.scrollWidth - slider.scrollWidth / 4 && !this.isLoading && !this.isLastPage) {
                await this.$emit('reached-before-end');
            }
            if (maxRightSide >= slider.scrollWidth && !this.isLoading && !this.isLastPage) {
                await this.$emit('reached-end');
            }
        },
        scroll() {
            const slider = this.$refs.slider;
            if (!slider || !this.hasPagination) return;
            slider.onscroll = async (): Promise<void> => {
                // if (this.showBulletPoints) window.clearInterval(this.intervalId);
                const slider = this.$refs.slider;
                if (!slider) return;
                this.showRightArrow = this.isLastPage
                    ? slider?.scrollLeft + slider.clientWidth < slider.scrollWidth
                    : slider?.scrollLeft + slider.clientWidth <= slider.scrollWidth;
                this.showLeftArrow = slider.scrollLeft > 0;
                await this.fetchPage();

                // if (!this.showBulletPoints) return;
                // const bulletPointArray: Array<number> = Array.from(
                //     Array(Math.round(slider.scrollWidth / slider.clientWidth)).keys(),
                //     n => n + 1,
                // ).reverse();
                // const actualBulletIndex = Math.ceil(slider.scrollWidth / slider.scrollLeft - 1);
                // this.bulletSelected = bulletPointArray[actualBulletIndex];
            };
        },
        startShowing: function () {
            const slider = this.$refs.slider;
            if (!slider) return;
            const intervalId = setInterval(async () => {
                if (this.itemsShow == 1) return;
                if (this.bulletSelected > this.itemsShown) {
                    this.bulletSelected = 1;
                    slider.scrollTo(0, 0);
                    return;
                }
                await this.goFoward();
                this.bulletSelected = this.bulletSelected + 1;
            }, this.transitionTime * 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
@import './carousel-tipti.scss';
</style>
