import { computed } from 'vue';
import { useUser } from '@/composables/useUser';
import { useI18n } from 'vue-i18n';
import IconSubscription from '@/components/icons/IconSubscription.vue';
import IconRewards from '@/components/icons/IconRewards.vue';
import IconHelp from '@/components/icons/IconHelp.vue';
import IconConfig from '@/components/icons/IconConfig.vue';
import IconMyLists from '@/components/icons/IconMyLists.vue';
import IconReferred from '@/components/icons/IconReferred.vue';
import IconOrders from '@/components/icons/IconOrders.vue';
import IconTiptiCard from '@/components/icons/IconTiptiCard.vue';
import IconProfile from '@/components/icons/IconProfile.vue';
import IconBell from '@/components/icons/IconBell.vue';
import IconPromos from '@/components/icons/IconPromos.vue';
import IconDataProtection from '@/components/icons/IconDataProtection.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export const useMainMenuOptions = () => {
    const { t } = useI18n();
    const { baseConfiguration, alphaCode } = useUser();

    /// Delete this when back implement endpoint to control.
    const loyaltyName = computed<string>(() => {
        if (alphaCode.value === 'EC') return t('txt.profile-options__loyalty_ec');
        return t('txt.profile-options__loyalty_pa');
    });

    const sideBarOptions = [
        {
            title: t("cta['my-account']"),
            code: appRoutesMap.account.profile,
            component: IconProfile,
            route: appRoutesMap.account.profile,
            size: 0.8,
        },
        {
            title: 'Tipti Wallet',
            code: 'tipti_card',
            component: IconTiptiCard,
            route: appRoutesMap.Wallet,
            size: 0.6,
        },
        {
            title: t("cta['notifications']"),
            code: 'notifications',
            component: IconBell,
            route: appRoutesMap.account.notifications,
            size: 0.4,
        },
        {
            title: t("cta['codes-promotions']"),
            code: 'code_promotions',
            component: IconPromos,
            route: appRoutesMap.coupons.couponsAndPromotions,
            size: 0.1,
        },
        {
            title: t("cta['orders']"),
            code: 'orders',
            component: IconOrders,
            route: appRoutesMap.OrdersPage,
            size: 0.8,
        },
        {
            title: t("cta['referred']"),
            code: 'referred',
            component: IconReferred,
            route: appRoutesMap.account.referred,
            size: 0.8,
        },
        {
            title: t("cta['lists-and-recipes']"),
            code: 'my-lists',
            component: IconMyLists,
            route: appRoutesMap.list.personalLists,
            size: 0.8,
        },
    ];

    const sideBarSecundaryOptions = [
        {
            title: t('cta.protection_of_data'),
            code: 'settings',
            component: IconDataProtection,
            route: appRoutesMap.privacyData.policies,
            size: 0.8,
            target: '_blank',
        },
        {
            title: t("cta['settings']"),
            code: 'settings',
            component: IconConfig,
            route: appRoutesMap.account.settings,
            size: 0.4,
        },
        {
            title: t("cta['help-and-support']"),
            code: 'faqs',
            component: IconHelp,
            route: appRoutesMap.account.faqs,
            size: 0.8,
        },
    ];

    const setBarOption = () => {
        let position = 2;
        if (baseConfiguration.value?.showLoyaltyMenu) {
            sideBarOptions.splice(position, 0, {
                title: loyaltyName.value,
                code: 'rewards',
                component: IconRewards,
                route: appRoutesMap.reward.programs,
                size: 0.5,
            });
            position++;
        }
        if (alphaCode.value != 'EC')
            sideBarOptions.splice(position, 0, {
                title: t('cta.subscriptions'),
                code: 'subscription',
                component: IconSubscription,
                route: appRoutesMap.account.subscriptions,
                size: 0.8,
            });
    };

    return { sideBarOptions, sideBarSecundaryOptions, setBarOption };
};
