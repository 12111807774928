class BanksModel {
    readonly id: number;
    readonly name: string;
    readonly icon: string;
    readonly accountName: string;
    readonly ruc: string;
    readonly accountNumber: string;
    readonly enabled: boolean;
    readonly accountType: string;
    readonly color: string;

    constructor(
        id: number,
        name: string,
        icon: string,
        accountName: string,
        ruc: string,
        accountNumber: string,
        enabled: boolean,
        accountType: string,
        color: string,
    ) {
        this.id = id;
        this.name = name;
        this.icon = icon;
        this.accountName = accountName;
        this.ruc = ruc;
        this.accountNumber = accountNumber;
        this.enabled = enabled;
        this.accountType = accountType;
        this.color = color;
    }
}
const toBanksModel = (data: object): BanksModel => {
    return data
        ? new BanksModel(
              data['id'],
              data['institution']['name'],
              data['institution']['icon'],
              data['name'],
              data['ruc'],
              data['account_number'],
              data['enabled'],
              data['account_type'],
              data['institution']['color'],
          )
        : null;
};

export { BanksModel, toBanksModel };
