<template>
    <base-modal v-if="show" :has-close="true" :has-header="true" :title="'Giftcards'" @on-close="$emit('close')">
        <div class="modal-change-giftcard__list">
            <GiftCardModalSelector
                v-for="gift in gifts"
                :key="gift.id"
                :gift-card="gift"
                :is-selected="localIds.some((local) => local === gift.id)"
                @on-change="changeSelection(gift.id)"
            />
        </div>
        <section class="modal-change-giftcard-btn-container">
            <btn-solid
                v-if="localIds.length === 0"
                class="modal-change-giftcard-btn-container__btn"
                :is-loading="isLoading"
                :txt="$t('txt.gift-card-change-card-btn')"
                border-radius="7px"
                @click="resetPaymentGiftCard"
            />
            <btn-solid
                v-if="localIds.length !== 0"
                class="modal-change-giftcard-btn-container__btn"
                :is-loading="isLoading"
                :txt="$t('general.apply')"
                border-radius="7px"
                @click="useBalanceCards"
            />
        </section>
    </base-modal>
</template>

<script lang="ts" setup>
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { useGiftCards } from '@/composables/useGiftCards';
import { useCart } from '@/composables/useCart';
import GiftCardModalSelector from '@/components/modalsGiftCards/modalChangeGiftCard/GiftCardModalSelector.vue';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { usePayments } from '@/composables/usePayments';
import { useTiptiCard } from '@/composables/useTiptiCard';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import { logger } from '@/utils/logger';

defineProps({
    show: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits<(e: 'close') => void>();
const store = useStore();
const { gifts, isLoading, timeDelivery } = useGiftCards();
const { cart, fetchCart } = useCart();
const { tiptiCard } = useTiptiCard();
const { paymentsToShow, difference, isTiptiCardPayment, isMixInstitutionalPayment, isMixPaymentTiptiCard } =
    usePayments();

const localIds = ref<number[]>([]);

const changeSelection = (id: number) => {
    const exist = localIds.value.some((local) => local === id);
    if (!exist) {
        localIds.value.push(id);
        return;
    }
    localIds.value = localIds.value.filter((local) => local !== id);
};

const resetPaymentGiftCard = async () => {
    isLoading.value = true;
    store.commit('giftCardAlert/paymentsToShowGiftCard', []);
    store.commit('giftCardAlert/idgGiftCardsToUse', []);
    await fetchCart(timeDelivery.value.map((ret) => ret.retailerId).join(','));
    isLoading.value = false;
    emit('close');
};
const useBalanceCards = async () => {
    try {
        isLoading.value = true;
        store.commit('giftCardAlert/idgGiftCardsToUse', [...localIds.value]);
        await fetchCart(timeDelivery.value.map((ret) => ret.retailerId).join(','));

        if (cart.value.totalDetails.totalCost === cart.value.totalDetails.orderCost) {
            isMixPaymentTiptiCard.value = false;
            isMixInstitutionalPayment.value = false;
            store.commit('payments/cleanPaymentMethods');
            emit('close');
            return;
        }
        if (isMixPaymentTiptiCard.value || isTiptiCardPayment.value) {
            isMixPaymentTiptiCard.value = false;
            isMixInstitutionalPayment.value = false;
            store.commit('payments/cleanPaymentMethods');
            isTiptiCardPayment.value = true;
            if (+tiptiCard.value.availableAmount >= +cart.value.totalDetails.totalCost) {
                store.commit(
                    'payments/paymentsToShow',
                    new PaymentToShowModel({
                        name: 'payment-tipti-card',
                        amount: +cart.value.totalDetails?.totalCost,
                        data: `${tiptiCard.value.id}`,
                        objectData: ['payment-tipti-card', +tiptiCard.value?.id],
                    }),
                );
                emit('close');
                return;
            }
            const valuePending: number = difference(
                +cart.value?.totalDetails?.totalCost,
                +tiptiCard.value?.availableAmount,
            );
            isMixPaymentTiptiCard.value = true;
            store.commit('payments/secondPayment', valuePending);
            store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-tipti-card',
                    amount: tiptiCard.value.availableAmount,
                    data: `${tiptiCard.value.id}`,
                    objectData: ['payment-tipti-card', +tiptiCard.value?.id],
                }),
            );
            emit('close');
            return;
        }
        isTiptiCardPayment.value = false;
        if (paymentsToShow.value.length > 1) isMixInstitutionalPayment.value = false;
        store.commit('payments/cleanPaymentMethods');
        emit('close');
    } catch (e) {
        logger('use GC', e);
    } finally {
        isLoading.value = false;
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

.modal-change-giftcard__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding: $padding-lg;
    max-width: 900px;
    justify-content: center;
    overflow-y: scroll;
    @include tablet {
        max-width: 700px;
    }
    @include phone {
        max-width: 400px;
    }

    &__card {
        position: relative;

        &__bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $white;
            border-bottom-left-radius: $radius-x-lg;
            border-bottom-right-radius: $radius-x-lg;
            display: flex;
            padding: $padding-sm $padding-md;

            &__left {
                flex-grow: 1;
                @include body2;

                &__name {
                    margin: $margin-none;
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;
                }

                &__tooltip {
                    @include caption;
                    margin: $margin-none;
                }

                &__date {
                    margin: $margin-none;
                    color: $orange;
                }
            }
        }
    }
}

.modal-change-giftcard-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $padding-lg 0;

    &__btn {
        width: 280px;
    }
}
</style>
