<script generic="T extends { id: number | string; label: string | RemoteLocaleDto }" lang="ts" setup>
import type { PropType } from 'vue';
import { RemoteLocaleDto } from '@/models/internationalization/RemoteLocale';

const value = defineModel({
    type: Object as PropType<T>,
    required: true,
});
defineProps({
    options: {
        type: Array<T>,
        required: true,
    },
});
</script>

<template>
    <section class="tipti-tabs">
        <button
            v-for="option in options"
            :key="option.id"
            class="tipti-tabs__option"
            :class="{ 'tipti-tabs__option--selected': value.id === option.id }"
            type="button"
            @click.stop="value = option"
        >
            <slot :option="option">
                <span
                    v-translate="option.label"
                    class="tipti-tabs__option--text"
                    :class="{ 'tipti-tabs__option--text-selected': value.id === option.id }"
                />
            </slot>
            <div v-if="value.id === option.id" class="tipti-tabs__selector-indicator" />
        </button>
    </section>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as color;
@use '@/assets/scss/box-shadow' as shadown;
@use '@/assets/scss/type-system' as type;
@use '@/assets/scss/media-query' as mq;
@use '@/assets/scss/text-lines' as tl;

.tipti-tabs {
    display: grid;
    grid-auto-columns: minmax(10rem, auto);
    grid-auto-flow: column;
    box-shadow: 0 4px 7px 0 #0000001a;
    border-radius: 12px;
    @include mq.phone {
        grid-auto-columns: minmax(8rem, auto);
    }

    &__option {
        all: unset;
        padding: 0.4rem;
        margin: 0.25rem 0;
        cursor: pointer;
        display: flex;
        align-content: center;
        justify-content: center;

        &:not(:last-child) {
            border-right: 2px solid color.$border;
        }

        &--selected {
            position: relative;
        }

        &--text {
            @include type.button;
            width: 100%;
            text-align: center;
            @include tl.text-lines(1);
        }
    }

    &__selector-indicator {
        position: absolute;
        left: 1rem;
        right: 1rem;
        bottom: -3px;
        border-bottom: 3px solid color.$orange;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
