<template>
    <base-icon :icon-name="$t('icon.reload')" :width="width" :height="height" viewBox="0 0 26 21" :iconColor="color">
        <path
            d="M7.21008 7.46665H5.39524C5.8937 6.12657 6.71723 4.93368 7.79067 3.9969C8.8641 3.06012 10.1532 2.4093 11.5404 2.10384C12.9275 1.79838 14.3685 1.84803 15.7317 2.24824C17.0949 2.64846 18.3369 3.38649 19.3444 4.39496C19.5064 4.54236 19.7183 4.62134 19.9365 4.61554C20.1546 4.60974 20.3622 4.51961 20.5162 4.36381C20.6702 4.20801 20.7589 3.99847 20.7639 3.77858C20.7689 3.55869 20.6898 3.34529 20.543 3.18257C19.2965 1.9325 17.7506 1.02844 16.0553 0.558102C14.36 0.087767 12.5729 0.0671357 10.8674 0.49821C9.16187 0.929284 7.59585 1.79742 6.32131 3.01839C5.04677 4.23935 4.10699 5.77167 3.59316 7.46665H1.67632C1.52718 7.46681 1.3807 7.50651 1.2516 7.58177C1.1225 7.65704 1.01532 7.76521 0.940814 7.89544C0.866311 8.02567 0.82711 8.17336 0.827148 8.32369C0.827187 8.47402 0.866463 8.62169 0.941033 8.75188L3.70791 13.5843C3.78264 13.7142 3.88989 13.8219 4.01893 13.8969C4.14797 13.9718 4.29428 14.0112 4.4432 14.0112C4.59212 14.0112 4.73842 13.9718 4.86746 13.8969C4.99651 13.8219 5.10376 13.7142 5.17848 13.5843L7.94961 8.75188C8.02437 8.62137 8.06365 8.47331 8.0635 8.32262C8.06335 8.17192 8.02378 8.02393 7.94877 7.89358C7.87377 7.76323 7.76598 7.65512 7.63628 7.58016C7.50659 7.50521 7.35958 7.46605 7.21008 7.46665Z"
            fill="#FF9012"
        />
        <path
            d="M23.0291 7.46693C22.9544 7.33709 22.8471 7.22931 22.7181 7.15439C22.589 7.07947 22.4427 7.04004 22.2938 7.04004C22.1449 7.04004 21.9986 7.07947 21.8696 7.15439C21.7405 7.22931 21.6333 7.33709 21.5585 7.46693L18.8597 12.1794C18.7851 12.3096 18.7458 12.4573 18.7458 12.6076C18.7457 12.7579 18.7849 12.9056 18.8594 13.0359C18.9339 13.1661 19.0411 13.2743 19.1702 13.3495C19.2993 13.4248 19.4458 13.4645 19.5949 13.4646H21.3205C20.8221 14.8047 19.9985 15.9976 18.9251 16.9344C17.8517 17.8712 16.5625 18.522 15.1754 18.8275C13.7882 19.1329 12.3473 19.0833 10.9841 18.6831C9.62087 18.2828 8.37884 17.5448 7.37137 16.5363C7.20941 16.3889 6.99742 16.31 6.77929 16.3158C6.56116 16.3216 6.35359 16.4117 6.19957 16.5675C6.04555 16.7233 5.95686 16.9328 5.95188 17.1527C5.9469 17.3726 6.026 17.586 6.17281 17.7487C7.41887 19.004 8.96624 19.9127 10.6642 20.3861C12.3621 20.8595 14.1528 20.8816 15.8617 20.4502C17.5707 20.0188 19.1397 19.1486 20.4158 17.9244C21.692 16.7002 22.6318 15.1638 23.1439 13.4646H25.0097C25.1588 13.4645 25.3053 13.4248 25.4344 13.3495C25.5635 13.2743 25.6707 13.1661 25.7452 13.0359C25.8197 12.9056 25.8589 12.7579 25.8589 12.6076C25.8588 12.4573 25.8196 12.3096 25.745 12.1794L23.0291 7.46693Z"
            fill="#FF9012"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-circular-arrow',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 26 * this.size;
        },
        height(): number {
            return 21 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
