<script lang="ts" setup>
import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import IconShareSocial from '@/components/icons/IconShareSocial.vue';
import IconSocialFacebook from '@/components/icons/IconSocialFacebook.vue';
import IconSocialTwitter from '@/components/icons/IconSocialTwitter.vue';
import IconSocialWhatsapp from '@/components/icons/IconSocialWhatsapp.vue';
import IconSocialEmail from '@/components/icons/IconSocialEmail.vue';
import IconCopy from '@/components/icons/IconCopy.vue';
import IconCheckCopy from '@/components/icons/IconCheckCopy.vue';
import { useStore } from 'vuex';
import { useUser } from '@/composables/useUser';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    link: {
        type: String,
        required: true,
    },
    resourceName: {
        type: String,
        required: true,
    },
});
const $store = useStore();
const { t: $t } = useI18n();
const { profile } = useUser();
const showCopiedMessage = ref(false);
const shareRef = ref();
const showItemShareDialog = ref(false);
const storeName = 'TIPTI';
const userName = computed(() => `${profile.value?.name} ${profile.value?.lastname}`);
const resourceType = $t("general['news-press-room']");
const networks = computed(() => {
    const mailSubject = `${$t("general['share-news']")} ${resourceType}`;
    const messageShare = `${$t("general['hello-news']")}, 😄 ${userName.value} ${$t("general['text-shared-news']")},
    ${props.resourceName}, ${$t("general['text-shared-news-two']")} ${storeName}`;
    return [
        {
            component: IconSocialFacebook,
            size: 0.8,
            shareUrl: `https://www.facebook.com/dialog/share?app_id=${process.env.VUE_APP_API_APP_ID}&display=popup&href=${props.link}&redirect_uri=https://www.facebook.com/&quote=${messageShare} ${props.link}`,
        },
        {
            component: IconSocialTwitter,
            size: 0.8,
            shareUrl: `https://twitter.com/intent/tweet/?url=${props.link}&text=${messageShare}`,
        },
        {
            component: IconSocialWhatsapp,
            size: 0.8,
            shareUrl: `https://web.whatsapp.com/send?text=${messageShare} ${props.link}`,
        },
        {
            component: IconSocialEmail,
            size: 0.8,
            shareUrl: `mailto:?&subject=${mailSubject}&body=${messageShare} ${props.link}`,
        },
    ];
});
const share = (shareUrl: string) => {
    window.open(shareUrl, 'sharer', 'toolbar=0,status=0,width=650,height=500');
};

const copyLink = () => {
    navigator.clipboard.writeText(props.link);
    showCopiedMessage.value = true;
    setTimeout(() => {
        showCopiedMessage.value = false;
    }, 3000);
};

onClickOutside(shareRef, () => {
    if (!showItemShareDialog.value) return;
    showItemShareDialog.value = false;
    if (!showCopiedMessage.value) return;
    showCopiedMessage.value = false;
});
</script>

<template>
    <i class="cursor-pointer">
        <IconShareSocial :size="0.6" @click="showItemShareDialog = true" />
    </i>
    <aside v-if="showItemShareDialog" ref="shareRef" class="share-dialog">
        <div ref="inputLink" class="share-dialog__input-content">
            <input
                ref="input"
                class="share-dialog__input-link"
                :value="link"
                aria-disabled="true"
                readonly
                type="text"
            />
            <div class="share-dialog__icon-copy cursor-pointer" @click="copyLink">
                <span class="share-dialog__copy-text">{{ $t("general['copy']") }}</span>
                <IconCopy />
            </div>
        </div>
        <div v-show="showCopiedMessage" class="share-dialog__copy-message">
            <span class="share-dialog__copied-text">{{ $t("general['copied']") }}</span>
            <i class="share-dialog__copied-icon cursor-pointer">
                <IconCheckCopy :size="0.2" />
            </i>
        </div>
        <section class="share-dialog__icons">
            <i
                v-for="{ component, shareUrl, size } in networks"
                :key="shareUrl"
                class="cursor-pointer"
                @click="share(shareUrl)"
            >
                <component :is="component" :size="size" />
            </i>
        </section>
    </aside>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as color;
@use '@/assets/scss/type-system.scss' as font;
@use '@/assets/scss/media-query' as mq;

.share-dialog {
    top: 100%;
    z-index: 1;
    right: 0.5%;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 0.6rem 3.6rem;
    background-color: color.$white;
    border-radius: 12px;
    box-shadow: 0 4px 4px color.$dark-black;
    overflow: hidden;

    @include mq.phone {
        padding: 0.6rem 2.6rem;
    }

    &__input-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid color.$bright-grey;
        box-sizing: border-box;
        border-radius: 0.7rem;
    }

    &__input-link {
        all: unset;
        padding: 0.6rem;
        color: color.$dark-grey;
    }

    &__icons {
        display: flex;
        justify-content: space-between;
        gap: 0.6rem;
        margin-top: 0.6rem;
    }

    &__icon-copy {
        align-items: center;
        display: flex;
        background-color: color.$bright-canvas;
        gap: 0.6rem;
        padding: 0.6rem;
        border-radius: 0.7rem;
    }

    &__copy-message {
        position: absolute;
        z-index: 2;
        left: 0;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: color.$orange;
    }

    &__copy-text {
        @include font.body1;
        font-size: 16px;
        font-weight: 400;
        color: color.$orange;
    }

    &__copied-text {
        @include font.body1;
        font-size: 16px;
        font-weight: 400;
        color: color.$white;
    }

    &__copied-icon {
        padding: 0.5rem;
    }
}

.input-link {
    box-sizing: border-box;
}
</style>
