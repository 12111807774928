import { isMobileBrowser } from '@/utils/utils';

class ListSubgroupModel {
    readonly id: number;
    readonly name: string;
    readonly description: string;
    readonly background: string;
    readonly showOrder: number;

    constructor(id: number, name: string, description: string, background: string, showOrder: number) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.background = background;
        this.showOrder = showOrder;
    }
}

const background = (data: object) => (isMobileBrowser() ? data['picture_for_mobile_web'] : data['picture_for_web_app']);

const toListSubgroupModel = (data: object): ListSubgroupModel => {
    return data
        ? new ListSubgroupModel(data['id'], data['name'], data['description'], background(data), data['show_order'])
        : null;
};

export { ListSubgroupModel, toListSubgroupModel };
