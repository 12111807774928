<template>
    <button class="rounded-icon" :style="btnStyle">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'btn-icon',
    props: {
        bgColor: {
            type: String,
            default: '#FF9012',
        },
        width: {
            type: String,
            default: '30px',
        },
        height: {
            type: String,
            default: '30px',
        },
    },
    computed: {
        btnStyle() {
            return {
                '--background-color': this.bgColor,
                '--width-icon': this.width,
                '--height-icon': this.height === this.width ? this.height : this.width,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_radius.scss';

.rounded-icon {
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    background-color: var(--background-color);
    border-radius: $radius-circular;
    width: var(--width-icon);
    height: var(--height-icon);
}
</style>
