import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10c47439"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-header" }
const _hoisted_2 = { class: "mobile-header__nav" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "mobile-header__item__icon-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_retailer_information = _resolveComponent("retailer-information")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_retailer_information),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.navItems, (nav, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "mobile-header__item",
          onClick: _withModifiers(($event: any) => ($options.selectNav(nav?.path)), ["stop"])
        }, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(nav.component), {
              size: nav.size,
              color: "#606060"
            }, null, 8, ["size"]))
          ]),
          _createElementVNode("h3", null, _toDisplayString(nav.name), 1)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}