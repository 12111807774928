<template>
    <aside class="card-menu">
        <icon-address :size="0.5" color="#FF9012" />
        <div v-if="getAddress && !isPickUp" class="card-menu__content">
            <h2>{{ aliasShown }}</h2>
            <p>{{ addressName }}</p>
        </div>
        <div v-else-if="pickUpCenter && isPickUp" class="card-menu__content">
            <h2>
                {{ $t('general.pick-up') }} <strong>{{ retailerName.toUpperCase() }}</strong>
            </h2>
            <strong class="pickup__center">{{ pickUpCenter?.name }}</strong>
            <p>{{ pickUpCenter?.address }}</p>
            <p>{{ pickUpCenter?.city?.name }}, {{ pickUpCenter?.sector?.name }}</p>
        </div>
        <p v-else class="card-menu__description">{{ $t("txt['checkout__enter-address']") }}</p>
        <button
            :class="{ 'text-no-selected': !isChangeAddress }"
            @click="$emit('on-change-selected', isPickUp ? 'pickup' : appRoutesMap.checkout.addresses)"
        >
            {{ btnActionTxt }}
        </button>
    </aside>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import { PickUpCenter } from '@/models/pickup/PickUpCenter';
import IconAddress from '@/components/icons/IconAddress.vue';
import { AddressModel } from '@/models/address/AddressModel';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'AddressCard',
    components: {
        IconAddress,
    },
    emits: ['on-change-selected'],
    data() {
        return {
            appRoutesMap,
        };
    },
    computed: {
        ...mapGetters({
            locationSelected: 'user/userLocation',
            addressDefault: 'location/addressDefault',
            cart: 'cart/cart',
        }),
        btnActionTxt(): string {
            return this.isChangeAddress ? this.$t("cta['change']") : this.$t("general['select']");
        },
        isPickUp(): boolean {
            return this.getAddress.isPickUpMode ?? false;
        },
        isChangeAddress(): boolean {
            return (this.isPickUp && this.pickUpCenter) || (!this.isPickUp && this.getAddress.id);
        },
        pickUpCenter(): PickUpCenter {
            return this.getAddress?.pickUpCenter;
        },
        retailerName(): string {
            if (!this.cart?.retailers?.length) return '';
            return this.cart.retailers[0].name;
        },
        aliasShown(): string {
            return this.getAddress?.comment?.trim()?.length ? this.getAddress.comment : this.getAddress?.addressLine1;
        },
        getAddress(): AddressModel {
            return this.locationSelected?.id ? this.locationSelected : this.addressDefault;
        },
        addressName(): string {
            if (this.getAddress.addressLine2.trim()?.length)
                return this.getAddress.addressLine1?.trim() + ', ' + this.getAddress.addressLine2.trim();
            return this.getAddress.addressLine1?.trim();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../menu-checkout.scss';
@import './address-card.scss';
</style>
