import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73e7dd9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "phones cursor-pointer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_icon_payphone = _resolveComponent("icon-payphone")!
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_btn_circular = _resolveComponent("btn-circular")!
  const _component_icon_check = _resolveComponent("icon-check")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!
  const _component_default_payment_selector = _resolveComponent("default-payment-selector")!
  const _component_no_content = _resolveComponent("no-content")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($data.isLoading && !$data.selectPayphoneId)
      ? (_openBlock(), _createBlock(_component_base_loading, { key: 0 }))
      : (_ctx.payphoneList.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payphoneList, (phone) => {
              return (_openBlock(), _createElementBlock("div", { key: phone }, [
                _createVNode(_component_base_card_flat, {
                  class: _normalizeClass(["phone", { 'phone-selected': $options.isSelected(phone) }]),
                  "border-color": $options.isSelected(phone) ? '#FF9012' : '#C7C7C7',
                  onClick: ($event: any) => ($options.selectPhone(phone))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icon_payphone, {
                      color: $options.isSelected(phone) ? '#FF9012' : '#606060'
                    }, null, 8, ["color"]),
                    _createElementVNode("p", {
                      class: _normalizeClass(["phone__number", { 'phone-selected__number': $options.isSelected(phone) }])
                    }, _toDisplayString(phone.phone), 3),
                    ($data.isLoading && $data.selectPayphoneId === phone.id)
                      ? (_openBlock(), _createBlock(_component_base_loading, { key: 0 }))
                      : ($props.deleteNumber)
                        ? (_openBlock(), _createBlock(_component_btn_circular, {
                            key: 1,
                            class: "actions",
                            "bg-color": "#C90808",
                            onClick: ($event: any) => ($options.deletePhone(phone.id))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_icon_close, {
                                "icon-name": _ctx.$t('icon.remove'),
                                size: 0.4,
                                color: "#FFF"
                              }, null, 8, ["icon-name"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true),
                    ($options.isSelected(phone))
                      ? (_openBlock(), _createBlock(_component_icon_check, { key: 2 }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["class", "border-color", "onClick"]),
                ($options.isSelected(phone))
                  ? (_openBlock(), _createBlock(_component_default_payment_selector, {
                      key: 0,
                      "is-default": phone.resultDefault,
                      "payment-channel-selected": phone.paymentChannel,
                      "payment-id": phone.id,
                      "payment-type": phone.paymentType,
                      onOnSetDefault: _ctx.fetchPayphones
                    }, null, 8, ["is-default", "payment-channel-selected", "payment-id", "payment-type", "onOnSetDefault"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(_component_no_content, {
            key: 2,
            title: _ctx.$t('general.payment-payphone')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_payphone, {
                size: 7,
                color: "#C7C7C7"
              })
            ]),
            _: 1
          }, 8, ["title"])),
    _createVNode(_component_snack_bar, {
      body: $data.modalMessage,
      "is-active": $data.showSnackBar,
      "is-failure": $data.isFailure,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[0] || (_cache[0] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}