export default {
    connectionStatus(state, payload: boolean) {
        state.connectionStatus = payload;
    },
    geoLocalizationState(state, payload: boolean) {
        state.geoLocalizationState = payload;
    },
    askForMaintenance(state, payload: boolean) {
        state.askForMaintenance = payload;
    },
};
