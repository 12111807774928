class DefaultPaymentDataModel {
    readonly alias: string;
    readonly identifier: string;
    readonly logo: string;
    readonly balance: number;
    readonly unlimitedBuys: boolean;
    readonly institutionId: number;
    readonly franchise: string;
    readonly branding: boolean;
    readonly isExpired: boolean;

    constructor(
        alias: string,
        identifier: string,
        logo: string,
        balance: number,
        franchise?: string,
        branding?: boolean,
        isExpired?: boolean,
        unlimitedBuys?: boolean,
        institutionId?: number,
    ) {
        this.alias = alias;
        this.identifier = identifier;
        this.logo = logo;
        this.balance = balance;
        this.franchise = franchise;
        this.branding = branding;
        this.unlimitedBuys = unlimitedBuys;
        this.institutionId = institutionId;
        this.isExpired = isExpired;
    }
}
class DefaultPaymentModel {
    readonly paymentTypeId: number;
    readonly paymentTypeName: string;
    readonly paymentId: number;
    readonly institutionalId: number;
    readonly defaultPaymentId: number;
    readonly channelPayment: string;
    readonly data: DefaultPaymentDataModel;

    constructor(
        paymentTypeId: number,
        paymentTypeName: string,
        paymentId: number,
        institutionalId: number,
        defaultPaymentId: number,
        channelPayment: string,
        data: DefaultPaymentDataModel,
    ) {
        this.paymentTypeId = paymentTypeId;
        this.paymentTypeName = paymentTypeName;
        this.paymentId = paymentId;
        this.institutionalId = institutionalId;
        this.defaultPaymentId = defaultPaymentId;
        this.channelPayment = channelPayment;
        this.data = data;
    }
}
const toDefaultPaymentDataModel = (data: object): DefaultPaymentDataModel => {
    return data
        ? new DefaultPaymentDataModel(
              data['alias'] ?? '',
              data['identifier'],
              data['logo'],
              data['balance'],

              ///* Use only to credit card [paymentez] payment default
              data['franchise']?.toUpperCase() ?? '',
              data['branding'] ?? false,
              data['is_expired'] ?? false,

              ///* Use only to institutional payment default
              data['unlimited_buys'] ?? false,
              data['institution_id'],
          )
        : null;
};
const toDefaultPaymentModel = (data: object): DefaultPaymentModel => {
    return data && data['id']
        ? new DefaultPaymentModel(
              data['payment_type_id'],
              data['payment_type_name'],
              data['payment_id'],
              data['institutional_id'],
              data['id'],
              data['channel'],
              toDefaultPaymentDataModel(data['data']),
          )
        : null;
};

export { DefaultPaymentModel, toDefaultPaymentModel };
