import { CartInputInterface, CartModel } from '@/models/cart/CartModel';
import { logger } from '@/utils/logger';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import { ProductModel } from '@/models/product/ProductModel';
import { createUUID } from '@/utils/utils';
import { ReplacementModel } from '@/models/product/ReplacementModel';

import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { CartGoals } from '@/models/cart/CartGoals';
import { serviceTax } from '@/models/cart/TotalDetailsModel';
import { CartMedicalPrescription } from '@/models/cart/CartMedicalPrescription';

interface FetchCartInterface {
    retailerIds?: string;
    needFetchGoals?: boolean;
    isAdd?: boolean;
    overrideDeactivatedProduct?: boolean;
    from_outside?: boolean;
}

export default {
    async fetchQuickCart({ rootGetters, commit }, payload?: { showRetailers: false; showItems: true }): Promise<void> {
        try {
            const url = `v2/shopping_cart/information?retailer_detail=${
                payload?.showRetailers ?? false
            }&cart_items_detail=${payload?.showItems ?? true}`;
            const { data } = await rootGetters['network/axios'].get(url);
            commit(
                'personalCartId',
                data['client_personal_carts'][rootGetters['external/countrySelected']?.alphaCode ?? 'EC'],
            );
            commit(
                'cart',
                new CartModel({
                    id: data['id'],
                    retailers: !data?.['cart_items']?.length
                        ? []
                        : [
                              CartRetailerModel.fromJSON({
                                  products:
                                      data['cart_items'].map(
                                          (product) =>
                                              new ProductModel(
                                                  product['discount_card'],
                                                  product['stock_item_id'],
                                                  product['discount_five_by_five'],
                                                  null,
                                                  createUUID(product['stock_item_id']),
                                                  product['stock_item_quantity'],
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  product['note'],
                                                  undefined,
                                                  product['note_picture'],
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  product['hours_to_delivery'],
                                                  product['unique_delivery'],
                                                  product['cost_of_delivery'],
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  product['stock_item_replacement_product']
                                                      ? new ReplacementModel(
                                                            product['stock_item_replacement_product']['stockitem'],
                                                            product['stock_item_replacement_product']['name'],
                                                            product['stock_item_replacement_product']['quantity'],
                                                            product['stock_item_replacement_product']['unit_quantity'],
                                                            product['stock_item_replacement_product']['unit'],
                                                            product['stock_item_replacement_product']['image'],
                                                        )
                                                      : undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                              ),
                                      ) ?? [],
                              }),
                          ],
                    name: data['name'],
                    default: data['default'],
                    retailerIds: data['retailers'],
                    retailersBasicInfo: data['retailers'],
                } as CartInputInterface),
            );
        } catch (err) {
            logger('FETCH_QUICK_CART', err);
        }
    },
    async fetchCart({ rootGetters, dispatch, commit }, payload: FetchCartInterface): Promise<void> {
        /// * [retailerIds] collected as string and separated by comma
        ///* [version=v2] is used for implement feature of service fee.
        ///* [taxes_by_country = true] is used for use information according to country.
        const idGifts = rootGetters['giftCardAlert/idgGiftCardsToUse']?.join(',');
        const url =
            payload?.retailerIds ?? false
                ? `v2/client/shopping-cart?taxes_by_country=true&retailers_id=${
                      payload.retailerIds
                  }&new_checkout=true&version=v2&gift_card_selected_id=[${idGifts ?? ''}]`
                : `v2/client/shopping-cart?taxes_by_country=true&version=v2&gift_card_selected_id=[${idGifts ?? ''}]`;
        const overrideDeactivatedProduct = payload?.overrideDeactivatedProduct ?? true;
        if (overrideDeactivatedProduct) commit('product/suggestionsForUnAvailableProducts', null, { root: true });
        const [cartResponse, goalsResponse] = await Promise.allSettled([
            rootGetters['network/axios'].get(url),
            payload?.needFetchGoals ? dispatch('getCartBarGoals', payload.from_outside) : Promise.reject(),
        ]);
        if (cartResponse.status === 'rejected') throw Error();
        const data = cartResponse.value.data;
        commit('giftCardAlert/paymentsToShowGiftCard', data.gift_cards_client_use, { root: true });

        /// *** Calculate shippingCost save on [checkout] module
        /// ** Update totalCost plus shippingCost
        let shippingCost = 0;
        let originalShippingCost = 0;

        let shippingCostWithTaxes = 0;

        const _listTimeDelivery = [
            ...rootGetters['checkout/timeDelivery'],
            ...rootGetters['checkout/stockItemTimeDelivery'],
        ];
        _listTimeDelivery.forEach((time: TimeDeliveryModel) => {
            if (time?.shippingCost) {
                shippingCost += +time?.shippingCost;
                shippingCostWithTaxes += +time.shippingCost + serviceTax(data['total_details']) * +time?.shippingCost;
            }

            if (time?.originalShippingCost) originalShippingCost += +time?.originalShippingCost;
        });

        const _actualCart: CartModel = { ...data };

        ///*** Add goals to cart data
        if (goalsResponse.status === 'fulfilled') {
            const goals = goalsResponse.value;
            _actualCart.retailers.forEach((retailer) => {
                const _actualGoalForRetailer = goals?.productGoals?.filter((goal) => goal.retailerId === retailer.id);
                const goalSavedForAlertsAndAnimation = retailer.goals == undefined ? undefined : retailer.goals[0];
                if (
                    payload?.isAdd &&
                    goalSavedForAlertsAndAnimation != undefined &&
                    !_actualGoalForRetailer?.some(
                        (element) => element?.discountId == goalSavedForAlertsAndAnimation?.discountId,
                    )
                ) {
                    goalSavedForAlertsAndAnimation.retailerId = retailer?.id;
                    commit('cartBarGoal', goalSavedForAlertsAndAnimation);
                }

                retailer.goals = goals?.productGoals.filter((goal) => goal.retailerId === retailer?.id);
            });

            commit('cardGoal', goals?.cardGoal);
        }
        const _dataConvert: CartModel = CartModel.fromJSON({
            ...structuredClone(_actualCart),
            shipping_cost: +(shippingCostWithTaxes ?? 0).toFixed(2),
        });

        const productWithSpecialDelivery = _dataConvert.retailers
            .map((retailer) => retailer.products.filter((product) => product.uniqueDelivery))
            .flat();
        if (overrideDeactivatedProduct) {
            const deactivatedProduct = _dataConvert.retailers
                .map((retailer) => retailer.products.filter((product) => !product.available))
                .flat();
            commit('product/deactivatedProducts', deactivatedProduct, { root: true });
            if (deactivatedProduct.length)
                deactivatedProduct?.forEach((product) => {
                    dispatch('product/suggestionsForUnAvailableProducts', product, { root: true });
                });
        }

        commit('productWithSpecialDelivery', productWithSpecialDelivery);
        commit('cart', _dataConvert);
    },
    async emptyCart({ rootGetters, commit }, cartId: number) {
        try {
            const url = `v2/client_profile/shopping_cart_empty/${cartId}/`;
            const { data } = await rootGetters['network/axios'].delete(url);
            commit('cartAmount', data['cart_items']);
        } catch (err) {
            throw err.response;
        }
    },
    async deleteProduct(
        { rootGetters, commit, dispatch },
        payload: {
            product?: ProductModel;
            screen?: string;
            section?: string;
            deactivatedProductId?: number;
        },
    ): Promise<void> {
        try {
            const url = `v2/client_profile/shopping_cart/item/${payload.product?.id ?? payload?.deactivatedProductId}/`;
            const { data } = await rootGetters['network/axios'].delete(url);
            commit('retailers/removeRetailerAlertLimitShowed', [rootGetters['retailers/retailerSelected']?.id], {
                root: true,
            });
            commit('products', []);
            commit('cartAmount', data['cart_items']);
            dispatch('segment/productRemoved', payload, { root: true });
        } catch (err) {
            throw err.response?.data;
        }
    },
    async checkAdult({ rootGetters }): Promise<boolean> {
        try {
            const url = 'v2/client_profile/check_cart_filter/';
            const { data } = await rootGetters['network/axios'].get(url);
            return data['response'];
        } catch (err) {
            throw Error(err);
        }
    },
    async addEditProduct(
        { rootGetters, commit, dispatch, getters },
        payload: {
            screen: string;
            section: string;
            product: ProductModel;
            deactivatedProductId?: number;
            origin?: string;
            searchedPhrase?: string;
        },
    ) {
        try {
            const url = 'v2/client_profile/shopping_cart/';
            const formData = new FormData();
            formData.append('stock_item_id', payload.product.id.toString());
            if (payload.deactivatedProductId)
                formData.append('original_stock_item_id', payload.deactivatedProductId.toString());
            formData.append('quantity', payload.product.productQuantity.toString());
            if (payload.product.note) formData.append('note_to_shopper', payload.product.note);
            formData.append('origin', 'app_web');
            if (payload.product.replacement?.id)
                formData.append('stock_item_id_replace', payload.product.replacement.id.toString());
            if (payload.product.replacement?.quantity)
                formData.append('quantity_replace', payload.product.replacement.quantity.toString());
            if (payload.product.trackId) formData.append('track_id', payload.product.trackId.toString());
            if (payload.product.trackResourceType) formData.append('resource_type', payload.product.trackResourceType);
            if (payload.product.trackItemSection)
                formData.append('tracking_item_section', payload.product.trackItemSection);
            if (payload.product.noteToShopperPhotoFile)
                formData.append('note_to_shopper_photo', payload.product.noteToShopperPhotoFile);

            const { data } = await rootGetters['network/axios'].put(url, formData);
            commit('cartAmount', data['cart_items']);
            const { cart_items } =
                data['retailers']?.find(
                    (retailer) => retailer['id'] === rootGetters['retailers/retailerSelected']?.id,
                ) ?? 0;
            commit('retailers/cartRetailerItems', cart_items, { root: true });
            const isEdit = getters['productsForSegment'].some((product) => product.product_id === payload.product.id);
            if (isEdit) dispatch('segment/productUpdated', payload, { root: true });
            else dispatch('segment/productAdded', payload, { root: true });
        } catch (err) {
            logger('ADD_PRODUCT_ERROR', err);
            throw err.response?.status;
        }
    },
    async getCartBarGoals({ rootGetters }, from_outside = false): Promise<CartGoals> {
        try {
            const url = `v2/client/shopping-cart-goals?from_outside=${from_outside}`;
            const { data } = await rootGetters['network/axios'].get(url);
            if (!data?.length) return;
            return new CartGoals(data);
        } catch (err) {
            throw Error(err);
        }
    },

    async getPrescriptionItems({ rootGetters, commit }): Promise<CartMedicalPrescription> {
        try {
            const { data } = await rootGetters['network/axios'].get(
                `v2/client/shopping-cart/medical-prescription-mandatory`,
            );
            if (!data?.length) return;
            commit('itemsPrescription', data);
            return data;
        } catch (err) {
            logger('GET_PRESCRIPTION_ITEMS', err);
        }
    },
};
