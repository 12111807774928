<template>
    <base-icon :icon-name="$t('icon.close')" :width="width" :height="height" viewBox="2 0 40 40" :iconColor="color">
        <g filter="url(#filter0_d)">
            <path
                d="M22.6341 36.2681C31.8208 36.2681 39.2681 28.8208 39.2681 19.6341C39.2681 10.4473 31.8208 3 22.6341 3C13.4473 3 6 10.4473 6 19.6341C6 28.8208 13.4473 36.2681 22.6341 36.2681Z"
            />
        </g>
        <path
            d="M29.0373 13.2881L22.9573 19.3681L29.0373 25.4482"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17.5618 25.4443L23.6418 19.3643L17.5618 13.2843"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <defs>
            <filter
                id="filter0_d"
                x="0"
                y="0"
                width="45.2681"
                height="45.2681"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="3" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-filled-circular-close',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 45 * this.size;
        },
        height(): number {
            return 45 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
