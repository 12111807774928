const segmentKey =
    process.env.VUE_APP_API_ENV === 'DEV'
        ? process.env.VUE_APP_API_SEGMENT_KEY_DEV
        : process.env.VUE_APP_API_ENV === 'STG'
          ? process.env.VUE_APP_API_SEGMENT_KEY_STG
          : process.env.VUE_APP_API_SEGMENT_KEY_PROD;

export const headScripts = {
    googletagmanager: {
        src: 'https://www.googletagmanager.com/gtag/js?id=G-75GVW39W9Y',
        defer: true,
        async: true,
        condition: process.env.VUE_APP_API_ENV === 'PROD',
    },
    gtagDataLayer: {
        condition: process.env.VUE_APP_API_ENV === 'PROD',
        content: `window.dataLayer = window.dataLayer || [];
                    function gtag() {
                      dataLayer.push(arguments);
                    }
                    gtag("js", new Date());
                    gtag("config", "G-75GVW39W9Y");`,
    },
    segment: {
        condition: true,
        content: `
          !(function() {
      var analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console && console.error && console.error("Segment snippet included twice.");
        else {
          analytics.invoked = !0;
          analytics.methods = [
            "trackSubmit",
            "trackClick",
            "trackLink",
            "trackForm",
            "pageview",
            "identify",
            "reset",
            "group",
            "track",
            "ready",
            "alias",
            "debug",
            "page",
            "once",
            "off",
            "on",
            "addSourceMiddleware",
            "addIntegrationMiddleware",
            "setAnonymousId",
            "addDestinationMiddleware"
          ];
          analytics.factory = function(e) {
            return function() {
              const t = Array.prototype.slice.call(arguments);
              t.unshift(e);
              analytics.push(t);
              return analytics;
            };
          };
          for (const element of analytics.methods) {
            analytics[element] = analytics.factory(element);
          }
          analytics.load = function(key, e) {
            const t = document.createElement("script");
            t.type = "text/javascript";
            t.async = !0;
            t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
            const n = document.getElementsByTagName("script")[0];
            n.parentNode.insertBefore(t, n);
            analytics._loadOptions = e;
          };
          analytics._writeKey = "${segmentKey}";
          analytics.SNIPPET_VERSION = "4.15.2";
          analytics.load("${segmentKey}");
          analytics.page();
        }
    })()
      `,
    },
    metricool: {
        condition: process.env.VUE_APP_API_ENV === 'PROD',
        content: `function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash:"c96d9826555a54a93c3e2d7df2a98f4d"})});`,
    },
};
