import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fa1e2ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "delivery-tabs"
}
const _hoisted_2 = {
  key: 1,
  class: "delivery__options"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_delivery = _resolveComponent("icon-delivery")!
  const _component_icon_pickup = _resolveComponent("icon-pickup")!
  const _component_alert_pick_up = _resolveComponent("alert-pick-up")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($options.showTabs)
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          _createElementVNode("button", {
            class: _normalizeClass(["delivery-tabs__label", { 'delivery-tabs--selected': !$options.isPickUp }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.updateDeliveryMode(false)))
          }, _toDisplayString(_ctx.$t('general.delivery')), 3),
          _createElementVNode("button", {
            class: _normalizeClass(["delivery-tabs__label", { 'delivery-tabs--selected': $options.isPickUp }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.updateDeliveryMode(true)))
          }, _toDisplayString(_ctx.$t('general.pick-up')), 3)
        ]))
      : _createCommentVNode("", true),
    ($options.showButtons)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createElementVNode("button", {
            class: _normalizeClass(["delivery__option", { 'delivery__option--selected': !$options.isPickUp }]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.updateDeliveryMode(false)))
          }, [
            _createVNode(_component_icon_delivery),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('general.delivery')), 1)
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["delivery__option", { 'delivery__option--selected': $options.isPickUp }]),
            onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.updateDeliveryMode(true)))
          }, [
            _createVNode(_component_icon_pickup),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('general.pick-up')), 1)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_alert_pick_up)
  ], 64))
}