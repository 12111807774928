<template>
    <!--
  USAGE OF OUTLINE BUTTON
  Possible options:
  * normal only text (default one)
  * special text and icon (left side)

  TO CONSIDER:
  - text will always appear;
  - To activate special one set [withIcon] to true and implement slot named icon;
  - [color] handle border, text and icon color;
  - [bgColor] handles background color;
  - [isLoading] is optional. Although by default it does not show up;
-->
    <BaseBtn
        :class="{ 'btn-outline--center': withIcon, elevation: elevation }"
        :bg-color="bgColor"
        :border-color="color"
        :border-radius="borderRadius"
        :full-width="fullWidth"
        :is-disabled="isDisabled"
        :is-loading="isLoading"
        outline
    >
        <slot v-if="withIcon" name="icon"></slot>
        <p class="btn-outline" :style="colors">{{ txt }}</p>
        <div v-if="withIcon"></div>
    </BaseBtn>
</template>

<script lang="ts" setup>
import BaseBtn from './BaseBtn.vue';
import { computed } from 'vue';

defineOptions({
    name: 'BtnOutline',
});

const props = defineProps({
    txt: {
        type: String,
        required: true,
    },
    color: {
        type: String,
        default: '#FFF',
    },
    bgColor: {
        type: String,
        default: '#FF9012',
    },
    borderRadius: {
        type: String,
        default: '5px',
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    withIcon: {
        type: Boolean,
        default: false,
    },
    fullWidth: {
        type: Boolean,
        default: false,
    },
    isDisabled: {
        type: Boolean,
        default: false,
    },
    elevation: {
        type: Boolean,
        default: true,
    },
    fontWeight: {
        type: String,
        default: '400',
    },
});

const colors = computed<object>(() => {
    return {
        color: props.color,
    };
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.btn-outline--center {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
}

.btn-outline {
    @include button;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline-style: none;
    text-decoration: none;
    white-space: initial;
    font-weight: v-bind(fontWeight);
}
</style>