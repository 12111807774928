import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c4beca0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }
const _hoisted_2 = { class: "addresses" }
const _hoisted_3 = {
  key: 0,
  class: "address__icons"
}
const _hoisted_4 = { class: "icons icons__delete" }
const _hoisted_5 = { class: "icons icons__edit" }
const _hoisted_6 = { class: "address-header" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "address__data" }
const _hoisted_9 = { class: "address__data" }
const _hoisted_10 = { class: "address__reference" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_subheader_toggle = _resolveComponent("subheader-toggle")!
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_icon_address = _resolveComponent("icon-address")!
  const _component_no_content = _resolveComponent("no-content")!
  const _component_subheader_separator = _resolveComponent("subheader-separator")!
  const _component_icon_trash = _resolveComponent("icon-trash")!
  const _component_icon_edit = _resolveComponent("icon-edit")!
  const _component_icon_check = _resolveComponent("icon-check")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!
  const _component_tipti_map_modal = _resolveComponent("tipti-map-modal")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_subheader_toggle, {
      "is-active": $data.isRemovingCards,
      subtitle: _ctx.$t('txt.address-user__review-address'),
      onOnPressed: _cache[0] || (_cache[0] = ($event: any) => ($data.isRemovingCards = !$data.isRemovingCards))
    }, null, 8, ["is-active", "subtitle"]),
    ($data.isLoading)
      ? (_openBlock(), _createBlock(_component_base_loading, { key: 0 }))
      : (!_ctx.countryAddresses.length)
        ? (_openBlock(), _createBlock(_component_no_content, {
            key: 1,
            description: _ctx.$t('txt.address__no-content-description'),
            title: _ctx.$t('txt.address__no-content-title'),
            "color-title": "#C1C1C1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_address, {
                size: 2,
                color: "#C1C1C1"
              })
            ]),
            _: 1
          }, 8, ["description", "title"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryAddresses, (country) => {
              return (_openBlock(), _createElementBlock("div", {
                key: country?.id
              }, [
                (_ctx.countryAddresses?.length > 1)
                  ? (_openBlock(), _createBlock(_component_subheader_separator, {
                      key: 0,
                      name: country?.name,
                      "show-btn": false
                    }, null, 8, ["name"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(country?.addresses, (address) => {
                    return (_openBlock(), _createBlock(_component_base_card_flat, {
                      key: address.id,
                      class: _normalizeClass(["address cursor-pointer", {
                        'address--delete': $data.isRemovingCards,
                        'address--selected': !$options.isSelected(address) && !$data.isRemovingCards,
                    }]),
                      "border-color": $data.isRemovingCards || $options.isSelected(address) ? '#C7C7C7' : '#FF901230',
                      onClick: ($event: any) => (
                        !$data.isRemovingCards && !$props.showCheck ? $options.openModal(address) : $data.isRemovingCards ? null : $options.onSelect(address)
                    )
                    }, {
                      default: _withCtx(() => [
                        ($data.isRemovingCards)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_icon_trash, {
                                  size: 1.5,
                                  onClick: ($event: any) => ($options.deleteAddress(address.id))
                                }, null, 8, ["onClick"])
                              ]),
                              _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_icon_edit, {
                                  size: 0.8,
                                  onClick: ($event: any) => ($options.openModal(address))
                                }, null, 8, ["onClick"])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("header", _hoisted_6, [
                          _createVNode(_component_icon_address, {
                            color: !$options.isSelected(address) && !$data.isRemovingCards ? '#ff9012' : '#707070',
                            size: 0.4
                          }, null, 8, ["color"]),
                          _createElementVNode("h2", {
                            class: _normalizeClass({
                                'address-header__title-orange': !$options.isSelected(address) && !$data.isRemovingCards,
                            })
                          }, _toDisplayString(address.comment), 3),
                          (address.isDefaultAddress)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t("general['default']")), 1))
                            : _createCommentVNode("", true),
                          (!$options.isSelected(address) && !address.isDefaultAddress && !$data.isRemovingCards)
                            ? (_openBlock(), _createBlock(_component_icon_check, { key: 1 }))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("p", _hoisted_8, _toDisplayString(`${address?.addressLine1 ?? ''}, ${address?.addressLine2 ?? ''}`), 1),
                        _createElementVNode("p", _hoisted_9, _toDisplayString(address?.city?.name ?? '') + ", " + _toDisplayString(address?.sector?.name ?? ''), 1),
                        _createElementVNode("p", _hoisted_10, _toDisplayString(address?.reference ?? ''), 1)
                      ]),
                      _: 2
                    }, 1032, ["class", "border-color", "onClick"]))
                  }), 128))
                ])
              ]))
            }), 128))
          ])),
    ($data.showAddressModal)
      ? (_openBlock(), _createBlock(_component_tipti_map_modal, {
          key: 3,
          section: $props.section,
          "is-edit-address": "",
          onOnClose: _cache[1] || (_cache[1] = ($event: any) => ($data.showAddressModal = false))
        }, null, 8, ["section"]))
      : _createCommentVNode("", true),
    _createVNode(_component_snack_bar, {
      body: $data.snackBarMessage,
      "is-active": $data.showSnackBar,
      "is-failure": $data.isFailure,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}