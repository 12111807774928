import { getProductBlock } from '@/utils/ProductBuilderUtil';

interface ProductMediaInterface {
    alt_text: string;
    is_cover: boolean;
    product: number;
    resource: string;
}

export class ProductMediaModel implements ProductMediaInterface {
    alt_text: string;
    is_cover: boolean;
    product: number;
    resource: string;

    constructor(productMedia: ProductMediaInterface) {
        this.alt_text = productMedia.alt_text ?? '';
        this.is_cover = productMedia.is_cover;
        this.product = productMedia.product;
        this.resource = productMedia.resource;
    }

    static builderList(data: object): ProductMediaModel[] {
        if (!data) return [];
        const block = getProductBlock({
            data: data,
            block: 'media',
        });
        if (!block) return [];
        return (block as []).map(item => new ProductMediaModel(item)).filter(item => !!item);
    }
}
