import { GiftCardsAlertCheckoutModel } from '@/models/giftCardsAlert/giftCardsAlertCheckoutModel';

export default {
    idgGiftCardsToUse(state, id: number | number[]) {
        if (Array.isArray(id)) {
            state.idgGiftCardsToUse = [...id];
            return;
        }
        const exist: number = state.idgGiftCardsToUse.findIndex((local: number) => local == id);
        if (exist === -1) {
            state.idgGiftCardsToUse.push(id);
            return;
        }
        state.idgGiftCardsToUse = state.idgGiftCardsToUse.filter((card) => card !== id);
    },
    paymentsToShowGiftCard(state, paymentsToShowGiftCard: GiftCardsAlertCheckoutModel[]) {
        state.paymentsToShowGiftCard = paymentsToShowGiftCard;
    },
    toShowGiftCards(state, toShowGiftCards: GiftCardsAlertCheckoutModel[]) {
        state.toShowGiftCards = toShowGiftCards;
    },
};
