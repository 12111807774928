import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { AffiliationCheckDto, AffiliationDocuments } from '@/modules/affiliation/models/AffiliateVerificationDto';
import { WrapperResponse } from '@/modules/core/decorators/wrapperResponse';

class LoyaltyAndRewardsService {
    @WrapperResponse({ useLogs: false })
    async checkInformation(franchise_id: number): Promise<AffiliationCheckDto> {
        const url = `loyalty-and-reward/franchise/${franchise_id}/check-information/`;
        const { data } = await axiosInstance().get<AffiliationCheckDto>(url);
        return data;
    }

    @WrapperResponse({ rethrowError: true, useLogs: false })
    async subscription({ franchise_id }: { franchise_id: number }): Promise<{ is_subscribed: boolean }> {
        const url = `loyalty-and-reward/franchise/${franchise_id}/subscription/`;
        const { data } = await axiosInstance().post<{ is_subscribed: boolean }>(url);
        return data;
    }

    async termsAndConditionsDocuments(franchise_id: number): Promise<AffiliationDocuments[]> {
        const url = `loyalty-and-reward/franchise/${franchise_id}/terms-and-conditions-documents/`;
        const { data } = await axiosInstance().get<AffiliationDocuments[]>(url);
        return data;
    }
}

export const loyaltyAndRewardsService = new LoyaltyAndRewardsService();
