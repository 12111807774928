import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05f64692"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "story-page" }
const _hoisted_2 = { class: "story-sidebar" }
const _hoisted_3 = { class: "story-sidebar__header" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "prime-icon" }
const _hoisted_6 = { class: "story-sidebar__information" }
const _hoisted_7 = { class: "overline" }
const _hoisted_8 = {
  class: "story-sidebar__scroll",
  ref: "sidebarStories"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "story-sidebar__information no-featured" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_circular_close = _resolveComponent("icon-circular-close")!
  const _component_icon_prime_star = _resolveComponent("icon-prime-star")!
  const _component_vertical_divider = _resolveComponent("vertical-divider")!
  const _component_story_container = _resolveComponent("story-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createVNode(_component_icon_circular_close, {
          alt: "close",
          size: 1,
          color: "#FFF",
          class: "cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close')))
        }),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("txt['stories']")), 1)
      ]),
      (_ctx.featuredStory)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            class: "story-sidebar__item",
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.goToStory(_ctx.featuredStory)))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["story-miniature", { viewed: _ctx.featuredStory.viewed }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.goToStory(_ctx.featuredStory)))
            }, [
              _createElementVNode("img", {
                src: _ctx.featuredStory.iconStory
              }, null, 8, _hoisted_4),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_icon_prime_star, {
                  iconName: _ctx.$t('icon.featured'),
                  size: 0.8,
                  color: "#FF9012"
                }, null, 8, ["iconName"])
              ])
            ], 2),
            (_ctx.featuredStory)
              ? (_openBlock(), _createBlock(_component_vertical_divider, {
                  key: 0,
                  height: "74px",
                  width: "2px"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(_ctx.featuredStory.title), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("txt['sponsor']")), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("section", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stories, (story, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["story-sidebar__item", { 'selected-item': _ctx.selectedStory == story }]),
            key: index,
            onClick: ($event: any) => ($options.goToStory(story, index))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["story-miniature", { viewed: story.viewed }])
            }, [
              _createElementVNode("img", {
                src: story.iconStory,
                alt: "",
                onError: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.displayDefaultImg && $options.displayDefaultImg(...args)))
              }, null, 40, _hoisted_10)
            ], 2),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", null, _toDisplayString(story.title), 1)
            ])
          ], 10, _hoisted_9))
        }), 128))
      ], 512)
    ]),
    (_ctx.selectedStory)
      ? (_openBlock(), _createBlock(_component_story_container, {
          key: 0,
          onOnClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-close')))
        }))
      : _createCommentVNode("", true)
  ]))
}