import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59e56315"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "program-header",
    style: _normalizeStyle({
            'background-color': _ctx.programSelected.mainColor,
        })
  }, [
    _createElementVNode("img", {
      class: "program-header__img",
      src: _ctx.programSelected.logo,
      alt: _ctx.programSelected.name
    }, null, 8, _hoisted_1)
  ], 4))
}