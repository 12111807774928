<template>
    <template v-if="!wasShowed">
        <base-card v-if="showSuccess" class="success-cart-bar" bg-color="#ff9012" border-radius="7px" padding="0.1rem">
            <p class="success-cart-bar__text">{{ getSuccessMessage }}</p>
            <icon-check-success class="success-cart-bar__icon" />
        </base-card>
        <base-card-flat v-else class="cart-bar" border-color="#ff9012" border-width="2px" padding="0.3rem">
            <p class="cart-bar__legend">
                {{ getMessage(localBenefit.message, 0) }}
                <span class="cart-bar__amount">{{ getMessage(localBenefit.message, 1) }}</span>
                {{ getMessage(localBenefit.message, 2) }}
            </p>
            <progress-bar :minimum-amount="minimumAmount" :progress="progress" background-color="#dfdfdf" />
        </base-card-flat>
    </template>
    <general-alert
        v-if="showCartBarAlert"
        :accent-header="true"
        :message="$t('txt.cart-bar__cashback-content')"
        :only-confirmation-btn="true"
        :show-close-icon="true"
        :title="$t('txt.cart-bar__cashback-title')"
        @on-close="closeAlert"
        @on-accept="closeAlert"
    />
    <product-benefits
        v-if="product && showSuccess"
        :goal="localBenefit"
        :retailer-id="retailerId"
        :retailer-name="retailerName"
        @on-close="
            () => {
                localBenefit = undefined;
                showSuccess = false;
                product = undefined;
                wasShowed = true;
            }
        "
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import ProgressBar from '@/components/progressBar/ProgressBar.vue';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import IconCheckSuccess from '@/components/icons/IconCheckSuccess.vue';
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import ProductBenefits from '@/views/cart/components/productBenefits/ProductBenefits.vue';
import { CartBarGoals } from '@/models/cart/CartBarGoals';

export default {
    name: 'CartBar',
    components: {
        ProductBenefits,
        ProgressBar,
        BaseCardFlat,
        BaseCard,
        IconCheckSuccess,
        GeneralAlert,
    },
    props: {
        cartBarGoals: {
            type: Array<CartBarGoals>,
            default: [],
        },
        retailerId: {
            type: Number,
            required: true,
        },
        retailerName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showSuccess: false,
            wasShowed: false,
            showBenefit: false,
            localBenefit: undefined,
            product: undefined,
            localGoal: undefined,
        };
    },
    computed: {
        ...mapGetters({
            cartGoals: 'cart/cartBarGoal',
            showCartBarAlert: 'cart/showCartBarAlert',
            cart: 'cart/cart',
        }),
        progress(): number {
            return Math.abs((this.actualAmount * 100) / this.minimumAmount);
        },
        minimumAmount(): number {
            return this.localBenefit.minimumAmount;
        },
        actualAmount(): number {
            return this.localBenefit.actualAmount;
        },
        getSuccessMessage(): number {
            return this.localBenefit?.pushMessage;
        },
    },
    watch: {
        cartBarGoals: {
            handler: function (value: CartBarGoals[], oldValue: CartBarGoals[] | undefined) {
                this.wasShowed = false;
                if (value?.length && !oldValue?.length) {
                    this.localBenefit = value?.[0];
                    setTimeout(() => {
                        this.product = this.localBenefit?.giveProduct;
                    }, 2500);
                    this.showSuccess = value.at(0)?.promotionApplied && value.at(0)?.retailerId === this.retailerId;
                    return;
                }
                if (!value?.length && oldValue?.length) {
                    this.localBenefit = oldValue?.[0];
                    setTimeout(() => {
                        this.product = this.localBenefit?.giveProduct;
                    }, 2500);
                    this.showSuccess =
                        !oldValue[0].promotionApplied &&
                        oldValue[0].minimumAmount <=
                            this.cart?.retailers
                                ?.find((ret) => ret.id === this.retailerId)
                                ?.products?.reduce(
                                    (accumulator, { finalPriceWithDiscount }) =>
                                        accumulator + +finalPriceWithDiscount?.toFixed(2),
                                    0,
                                ) &&
                        oldValue.at(0)?.retailerId === this.retailerId;
                    return;
                }
                this.localBenefit = undefined;
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        this.closeAlert();
    },
    methods: {
        getMessage(message: string, position: number): string {
            return message.split(/[{}]/)[position];
        },
        closeAlert(): void {
            this.$store.commit('cart/showCartBarAlert', false);
        },
    },
};
</script>

<style lang="scss" scoped>
@import './cart-bar.scss';
</style>
