import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-026627da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feeling-bar" }
const _hoisted_2 = {
  class: "feeling-bar__container",
  ref: "container"
}
const _hoisted_3 = {
  class: "container-bar",
  ref: "slider"
}
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", {
      class: "feeling-bar__title",
      style: _normalizeStyle({ color: $options.textColor, backgroundColor: $options.bgColor })
    }, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.selectedStory?.interactiveElement.text.toUpperCase()), 1)
    ], 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("progress", {
          value: $data.value,
          max: 100
        }, null, 8, _hoisted_4),
        _createElementVNode("div", {
          ref: "indicator",
          class: "container-bar__indicator",
          style: _normalizeStyle({
                        backgroundColor: _ctx.selectedStory?.interactiveElement?.elements[0]?.textBackgroundColor,
                        left: `${$data.value}%`,
                    })
        }, _toDisplayString(_ctx.selectedStory?.interactiveElement?.elements[0]?.text), 5)
      ], 512)
    ], 512)
  ]))
}