<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed, ref, watch } from 'vue';
import IconCamera from '@/components/icons/IconCamera.vue';
import IconPrescription from '@/components/icons/IconPrescription.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import type { CartMedicalPrescription } from '@/models/cart/CartMedicalPrescription';
import BaseBtn from '@/components/buttons/BaseBtn.vue';
import { useStore } from 'vuex';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import { compressFile } from '@/utils/picturesUtils';
import { logger } from '@/utils/logger';

const $store = useStore();

const props = defineProps({
    itemsPrescription: {
        type: Array as PropType<CartMedicalPrescription[]>,
        required: true,
    },
    retailer: {
        type: Object as PropType<CartRetailerModel>,
        required: true,
    },
});

const emit = defineEmits<{
    (e: 'on-prescription', value: number): void;
    (e: 'on-remove-prescription', value: number): void;
}>();

const loadPrescription = ref(false);
const fileList = ref([]);

const colorsCard = computed(() => {
    if (loadPrescription.value)
        return {
            background: '#FFF2E3',
            labels: '#FF9012',
            icons: '#FF9012',
            border: '#DFDFDF',
        };
    return {
        background: '#FED6D3',
        icons: '#C90808',
        border: '#C90808',
    };
});

watch(
    () => fileList.value.length,
    (newLength) => {
        if (newLength) return (loadPrescription.value = true);
        return (loadPrescription.value = false);
    },
    { immediate: true },
);

watch(
    () => loadPrescription.value,
    (newValue) => {
        if (newValue) return emit('on-prescription', props.retailer?.id);
        return emit('on-remove-prescription', props.retailer?.id);
    },
    { immediate: true },
);

const colorLabels = computed(() => (loadPrescription.value ? '#FF9012' : '#C90808'));
const listFilesPrescriptions = computed(() => $store.getters['cart/listFilesPrescriptions']);
const showSnackBar = ref(false);
const fileUpload = ref<HTMLElement>();
const filesTempPrescriptions = ref<File[]>([]);

const onFileImg = async (event: Event) => {
    if (!event.target) return;
    showSnackBar.value = false;
    const files = (event.target as HTMLInputElement)?.files;
    const filesPrescriptions = Array.from(files).filter((file) => isValidImageFile(file));
    filesTempPrescriptions.value.push(...filesPrescriptions);
    if (!filesTempPrescriptions.value.length) {
        showSnackBar.value = true;
        fileUpload.value = null;
        return;
    }
    const changedFiles = convertFilesWithRetailerNames(filesTempPrescriptions.value);
    const compressedFiles = await compressPrescriptionFiles(changedFiles);
    $store.commit('cart/listFilesPrescriptions', { changedFiles: compressedFiles, retailer_id: props.retailer.id });
    fileList.value = filesTempPrescriptions.value;
    loadPrescription.value = true;
    fileUpload.value = null;
    return;
};

const isValidImageFile = (file: File) => {
    const fileName = file?.name.toLowerCase();
    return ['.jpg', '.jpeg', '.png'].some((ext) => fileName.endsWith(ext));
};

const compressPrescriptionFiles = async (filesPrescriptions: File[]) => {
    const compressedFiles = [];
    for (const file of filesPrescriptions) {
        const compressedFile = await compressFile(file).catch((_) => logger('COMPRESS_FILE'));
        if (compressedFile) {
            compressedFiles.push(compressedFile);
        }
    }
    return compressedFiles;
};

const convertFilesWithRetailerNames = (files: File[]) => {
    return files.map((file) => {
        const fileExtension = file.name.split('.').pop();
        const fileName = `${props.retailer.id}.${fileExtension}`;
        return new File([file], fileName, { type: file.type });
    });
};

const deleteFile = (fileToDelete: File) => {
    removeFromFileList(fileToDelete);
    removeFromRetailerFiles(fileToDelete);
};

const removeFromFileList = (fileToDelete: File) => {
    const index = fileList.value.indexOf(fileToDelete);
    if (index === -1) return;
    fileList.value.splice(index, 1);
};

const removeFromRetailerFiles = (fileToDelete: File) => {
    const retailerFiles = listFilesPrescriptions.value.find((obj) => obj.retailer_id === props.retailer.id);

    if (!retailerFiles) return;
    const fileExtension = fileToDelete.name.split('.').pop();
    const newFileName = `${props.retailer.id}.${fileExtension}`;
    const newFile = new File([fileToDelete], newFileName);

    const fileIndex = retailerFiles.listFiles.findIndex((file) => file.name === newFile.name);

    if (fileIndex === -1) return;
    retailerFiles.listFiles.splice(fileIndex, 1);
};
</script>

<template>
    <BaseCard
        class="load-prescription-card"
        :bg-color="colorsCard.background"
        :border-color="colorsCard.border"
        :padding="0"
    >
        <div class="custom-file-upload">
            <input
                id="file-upload"
                ref="fileUpload"
                accept=".jpg,.jpeg,.png"
                multiple
                type="file"
                @change="onFileImg($event)"
            />
            <div class="load-prescription cursor-pointer">
                <aside class="load-prescription__content" aria-labelledby="">
                    <i>
                        <IconPrescription :color="colorsCard.icons" />
                    </i>
                    <p>
                        {{ $t("txt['prescription-required']") }}
                        {{ retailer.name }}
                    </p>
                </aside>
                <i>
                    <IconCamera :color="colorsCard.icons" />
                </i>
            </div>
        </div>
    </BaseCard>
    <section v-if="fileList.length" class="list-files">
        <u v-for="file in fileList" :key="file.lastModified" class="list-files__names">
            {{ file.name }}
            <BaseBtn style="all: unset; cursor: pointer" @click="deleteFile(file)"> X</BaseBtn>
        </u>
    </section>

    <SnackBar
        :body="$t('txt.format-invalid-file')"
        :is-active="showSnackBar"
        :is-failure="true"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.load-prescription {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $padding-sm $padding-md;
    align-items: center;

    &__content {
        display: flex;
        flex-direction: row;
        gap: $margin-md;
        align-items: center;
    }
}

.custom-file-upload {
    transition: all 200ms ease-in-out;
    cursor: pointer !important;
    position: relative;
    display: inline-block;
}

input[type='file'] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

.load-prescription-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    p {
        @include caption;
        color: v-bind(colorLabels);
    }
}

.list-files {
    display: flex;
    flex-direction: column;
    padding: $padding-sm;

    &__names {
        display: flex;
        flex-direction: row;
        gap: $margin-md;
    }

    u {
        @include caption;
        color: v-bind(colorLabels);
    }
}
</style>
