import { TiptiAlertModel } from '@/models/alerts/AlertOpenRetailer';

export default {
    alerts(state): Array<TiptiAlertModel> {
        return state.alerts;
    },
    alert(state): TiptiAlertModel {
        return state.alert;
    },
};
