import { RemoteLocaleDto, RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';
import { CartBarGoals } from '@/models/cart/CartBarGoals';
import { ProductModel, toProductModel } from '@/models/product/ProductModel';
import { RetailerInProductModel } from '@/models/product/RetailerInProductModel';

interface CartRetailerBaseInterface {
    id: number;
    name: string;
    logo: string;
    icon: string;
    /// * Obtains in getCartGoals
    goals: CartBarGoals[];
}

interface CartRetailerInterface extends CartRetailerBaseInterface {
    primaryColor: string;
    secondaryColor: string;
    products: Array<ProductModel>;
    minimumBuyPrice: number;

    /// *** [retailerNeedsUsersInformation] Retailer need to ask's users information
    retailerNeedsUsersInformation: boolean;
    /// *** [userHasNotAcceptedOrDeniedShareInformation]
    /// `true` User has not response yet. Then, show alert if [retailerNeedsUsersInformation] is `true`
    /// `false` User has already response either approve or denied.
    userHasNotAcceptedOrDeniedShareInformation: boolean;
    isPickupAvailable: boolean;
    shopperNoteTitle: object;
    shopperNotePlaceholder: object;
    storeName: string;
}

interface CartRetailerInputInterface extends CartRetailerBaseInterface {
    primary_color?: string;
    secondary_color?: string;
    //*** use items when need to build the model instead of use products directly
    items: Array<ProductModel>;
    //*** product is used for cart extra items
    products: Array<ProductModel>;
    minimum_buy_price?: number;
    /// *** [retailerNeedsUsersInformation] Retailer need to ask's users information
    request_customer_information?: boolean;
    /// *** [userHasNotAcceptedOrDeniedShareInformation]
    /// `true` User has not response yet. Then, show alert if [retailerNeedsUsersInformation] is `true`
    /// `false` User has already response either approve or denied.
    customer_need_request_permission?: boolean;
    pickup_available?: boolean;
    shopper_note_title?: RemoteLocaleDto;
    shopper_note_placeholder?: RemoteLocaleDto;
    store_name?: string;
    is_delivery: boolean;
}

export interface CartRetailAnalyticInterface {
    retail: string;
    id: number;
    store_name: string;
}

export class CartRetailerModel {
    private static instance: CartRetailerModel;
    id: number;
    name: string;
    primaryColor: string;
    secondaryColor: string;
    products: Array<ProductModel>;
    minimumBuyPrice: number;
    logo: string;
    icon: string;
    /// *** [userHasNotAcceptedOrDeniedShareInformation]
    /// `true` User has not response yet. Then, show alert if [retailerNeedsUsersInformation] is `true`
    /// *** [retailerNeedsUsersInformation] Retailer need to ask's users information
    retailerNeedsUsersInformation: boolean;
    /// `false` User has already response either approve or denied.
    userHasNotAcceptedOrDeniedShareInformation: boolean;
    /// * Obtains in getCartGoals
    goals: Array<CartBarGoals>;
    isPickupAvailable: boolean;
    shopperNoteTitle: object;
    shopperNotePlaceholder: object;
    storeName: string;
    isDelivery: boolean;

    constructor(data?: CartRetailerInputInterface) {
        if (!data || !Object.keys(data).length) return;
        this.id = data.id;
        this.name = data.name;
        this.primaryColor = data.primary_color;
        this.secondaryColor = data.secondary_color;
        this.isPickupAvailable = data.pickup_available ?? false;
        this.products =
            data.products ??
            data.items?.map((product: ProductModel) => {
                const productModel: ProductModel = toProductModel(product);
                if (!productModel.retailer)
                    productModel.retailer = new RetailerInProductModel(
                        data.id,
                        data.name,
                        data.primary_color,
                        data.secondary_color,
                        data.logo ?? '',
                    );
                return productModel;
            }) ??
            [];

        this.minimumBuyPrice = data.minimum_buy_price ?? 0;
        this.logo = data.logo ?? '';
        this.icon = data.icon ?? '';
        this.retailerNeedsUsersInformation = data.request_customer_information ?? false;
        this.userHasNotAcceptedOrDeniedShareInformation = data.customer_need_request_permission ?? false;
        this.goals = data.goals ?? [];
        this.shopperNoteTitle = RemoteLocaleModel.fromJson(data.shopper_note_title);
        this.shopperNotePlaceholder = RemoteLocaleModel.fromJson(data.shopper_note_title);
        this.storeName = data.store_name;
        this.isDelivery = data.is_delivery;
    }

    get toAnalytics(): CartRetailAnalyticInterface {
        return {
            retail: this.name,
            id: this.id,
            store_name: this.storeName,
        };
    }

    //*** used only for extra-items, normal purchase use totalDetails from CartModel
    get extraItemsProductPrice(): number {
        return +this.products
            .reduce(
                (accumulator, { productQuantity, priceWithDiscount }) =>
                    accumulator + productQuantity * priceWithDiscount,
                0,
            )
            .toFixed(2);
    }

    static fromJSON(data: object | CartRetailerInputInterface): CartRetailerModel {
        if (!data || !Object.keys(data).length) return null;
        return new CartRetailerModel(data as CartRetailerInputInterface);
    }

    static builderList(data: object): Array<CartRetailerModel> {
        if (!data || !(data as []).length) return [];
        return (data as []).map(this.fromJSON).filter((retail) => !!retail);
    }

    public static getInstance(): CartRetailerModel {
        if (!CartRetailerModel.instance) CartRetailerModel.instance = new CartRetailerModel();
        return CartRetailerModel.instance;
    }
}
