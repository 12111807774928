<template>
    <base-icon :icon-name="$t('icon.shown')" :width="width" :height="height" viewBox="0 0 25 15" :iconColor="color">
        <path
            d="M12.2406 0C7.56323 0 3.32151 2.4857 0.191553 6.52314C-0.0638511 6.85392 -0.0638511 7.31603 0.191553 7.64681C3.32151 11.6891 7.56323 14.1748 12.2406 14.1748C16.918 14.1748 21.1598 11.6891 24.2897 7.65168C24.5451 7.3209 24.5451 6.85878 24.2897 6.528C21.1598 2.4857 16.918 0 12.2406 0ZM12.5762 12.0783C9.47125 12.268 6.90719 9.78228 7.1025 6.76149C7.26275 4.27093 9.34104 2.25221 11.9051 2.09655C15.01 1.90684 17.5741 4.39254 17.3788 7.41332C17.2135 9.89902 15.1352 11.9177 12.5762 12.0783ZM12.4209 9.77255C10.7483 9.8747 9.36608 8.53699 9.47626 6.91229C9.56139 5.56972 10.6832 4.48496 12.0654 4.3974C13.738 4.29525 15.1202 5.63296 15.01 7.25766C14.9199 8.6051 13.7981 9.68985 12.4209 9.77255Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-eye-open',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 25 * this.size;
        },
        height(): number {
            return 15 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
