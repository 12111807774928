<script lang="ts" setup>
const checked = defineModel({
    type: Boolean,
    required: true,
});
</script>

<template>
    <input v-model="checked" class="base-check" type="checkbox" />
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as color;
@use '@/assets/scss/radius' as radius;

.base-check {
    width: 1.5rem;
    height: 1.3rem;
    border-radius: radius.$radius-md;
    display: inline-block;
    position: relative;
    appearance: none;
    outline: 2px solid color.$bright-grey;
    cursor: pointer;
    transition: all linear 0.3s;

    &:checked {
        outline: none;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: color.$orange;
            border-radius: radius.$radius-md;
            outline: none;
        }

        &::after {
            content: '\2713';
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: color.$white;
            font-size: 1rem;
            font-weight: 700;
        }
    }
}
</style>
