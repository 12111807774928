import type { SubCategories } from '@/models/product/CategoryModel';
import { CategoryModel } from '@/models/product/CategoryModel';
import { ProductModel } from '@/models/product/ProductModel';
import { CustomCategories } from '@/modules/core/errors/CustomCategories';

export default {
    setCategorySelected(state, category: CategoryModel) {
        state.categorySelected = category;

        //*** update only when has values
        if (state.isMobileCategoryMenuOpen) state.isMobileCategoryMenuOpen = false;
        if (state.subcategorySelected) state.subcategorySelected = undefined;
        if (state.subcategoriesList?.length && !category?.isDeeplink) state.subcategoriesList = [];
        if (state.subcategoriesList?.length && category?.isDeeplink && !category?.hasSubcategories)
            state.subcategoriesList = [];

        //*** always reset values because we can have only one category selected
        //** customCategory is a pseudo category create locally to show especial categories
        //** categories like recommendations or new products
        if (state.customCategory) state.customCategory = undefined;
    },
    setSubcategorySelected(
        state,
        payload: {
            subcategory: CategoryModel;
            isFromSeeMoreBtn: boolean;
        },
    ) {
        state.subcategorySelected = payload?.subcategory;
        if (state.isFromSeeMoreBtn !== Boolean(payload?.isFromSeeMoreBtn))
            state.isFromSeeMoreBtn = Boolean(payload?.isFromSeeMoreBtn);

        //*** update only when has values
        if (state.isMobileCategoryMenuOpen) state.isMobileCategoryMenuOpen = false;
    },
    customCategory(
        state,
        input: {
            name: CustomCategories;
            label: string;
        },
    ) {
        state.customCategory = input;

        if (state.isMobileCategoryMenuOpen) state.isMobileCategoryMenuOpen = false;

        //*** always reset values because we can have only one category selected
        //** customCategory is a pseudo category create locally to show especial categories
        //** categories like recommendations or new products
        if (state.categorySelected) state.categorySelected = undefined;
        if (state.subcategorySelected) state.subcategorySelected = undefined;
        if (state.subcategoriesList?.length) state.subcategoriesList = [];
    },
    subcategoriesList(state, value: Array<CategoryModel>) {
        state.subcategoriesList = value;
    },
    promotionSubcategories(state, value: Array<CategoryModel>) {
        state.promotionSubcategories = value;
    },
    categoryProducts(state, value: Array<ProductModel>) {
        state.categoryProducts = value;
    },
    isSearchCategoryProducts(state, value: boolean) {
        state.isSearchCategoryProducts = value;
    },
    isMobileCategoryMenuOpen(state, value: boolean) {
        state.isMobileCategoryMenuOpen = value;
    },
    categories(state, categories: CategoryModel[]) {
        state.categories = categories;
    },
    allSubCategories(state, payload: SubCategories[]) {
        state.allSubCategories = payload;
    },
    lastIdRetailerSelected(state, payload: number) {
        state.lastRetailIdFetched = payload;
    },
};
