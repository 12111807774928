<script lang="ts" setup>
import TiptiCheckboxRounded from '@/modules/payment-methods/components/TiptiCheckboxRounded.vue';
import { ScheduleModel } from '@/models/retailers/HourModel';
import { computed, PropType } from 'vue';
import { useUser } from '@/composables/useUser';

const props = defineProps({
    schedule: {
        type: Object as PropType<ScheduleModel>,
        required: true,
    },
    isSelected: {
        type: Boolean,
        default: false,
    },
});
const $emits = defineEmits<{
    (e: 'select', value: ScheduleModel);
}>();

const { currency } = useUser();

const check = computed({
    get: () => props.isSelected,
    set: () => {
        $emits('select', props.schedule);
    },
});
</script>
<template>
    <article
        class="hour-option cursor-pointer"
        :class="{ 'hour-option__selected': check }"
        @click.stop="isSelected ? null : (check = true)"
    >
        <p>{{ schedule.first_hour }} - {{ schedule.second_hour }}</p>
        <p class="hour-option__text">
            {{
                +schedule.price === 0
                    ? $t('txt["schedule__free-shipping"]')
                    : `${currency}${(+schedule?.price)?.toFixed(2)}`
            }}
        </p>
        <tipti-checkbox-rounded
            v-model="check"
            :label="schedule.first_hour"
            :prevent-update="isSelected"
            bg-round="#F5F5F5"
        />
    </article>
</template>
<style lang="scss" scoped>
@import 'hour-selector';
</style>
