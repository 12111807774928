import { createUUID } from '@/utils/utils';
import dayjs from 'dayjs';
class NotificationModel {
    id: string;
    title: string;
    body: string;
    date: string;
    isSeen: boolean;

    constructor(title: string, body: string, isSeen = false) {
        this.id = createUUID(dayjs().millisecond());
        this.title = title;
        this.body = body;
        this.date = dayjs().format();
        this.isSeen = isSeen;
    }
}

export default NotificationModel;
