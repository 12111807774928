<template>
    <base-modal
        v-if="showPickUpMap"
        :has-back-arrow="true"
        :has-close="false"
        :has-header="isMobile"
        :title="$t('alerts.pickup__title')"
        @on-close="showPickUpMap = false"
        @on-back-arrow="showPickUpMap = false"
    >
        <div class="pickup_map">
            <pick-up-centers :centers="centers" @on-selected="selectCenter" />
            <GoogleMap
                :api-key="apiKey"
                :center="center"
                :disable-default-ui="false"
                :fullscreen-control="false"
                :keyboard-shortcuts="false"
                :map-type-control="false"
                :options="options"
                :pan-control="false"
                :street-view-control="false"
                :zoom="15"
                :zoom-control="false"
                gesture-handling="greedy"
                map-type-id="roadmap"
                style="width: 100%; height: 90vh"
            >
                <Marker
                    v-for="(marker, index) in markers"
                    :key="index"
                    :options="{
                        draggable: false,
                        position: marker.position,
                        icon: marker.icon,
                    }"
                    @click="selectCenter(marker.center)"
                />
            </GoogleMap>
        </div>
    </base-modal>
</template>

<script lang="ts">
import { logger } from '@/utils/logger';
import { mapActions, mapGetters } from 'vuex';
import { PickUpCenter } from '@/models/pickup/PickUpCenter';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import { AddressModel } from '@/models/address/AddressModel';
import { isMobileBrowser } from '@/utils/utils';
import BaseModal from '../baseModal/BaseModal.vue';
import PickUpCenters from './components/pickUpCenters/PickUpCenters.vue';
import { storeToRefs } from 'pinia';
import { usePickupStore } from '@/store/usePickupStore';
import { GoogleMap, Marker, Polygon } from 'vue3-google-map';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export default {
    name: 'PickUpMapModal',
    setup() {
        const $pickupStore = usePickupStore();
        const { showPickUpMap, temporalRetailer } = storeToRefs($pickupStore);
        const { retailerSelected, fetchFirstSchedulePerRetailerPickUp } = useRetailer();
        const getCenters = (retailerId: number) => $pickupStore.pickUpCenters(retailerId);
        return {
            showPickUpMap,
            temporalRetailer,
            getCenters,
            retailerSelected,
            fetchFirstSchedulePerRetailerPickUp,
        };
    },
    data() {
        return {
            apiKey: process.env.VUE_APP_API_GOOGLE_KEY ?? '',
            map: null,
            centers: [],
            userPosition: undefined,
            markers: [],
            options: {
                zoomControl: this.isMobile,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            countrySelected: 'external/countrySelected',
            retailerIdsWithtimeDelivery: 'checkout/retailerIdsWithtimeDelivery',
        }),
        isMobile(): boolean {
            return isMobileBrowser();
        },
        retailer(): RetailerModel {
            return { ...(this.temporalRetailer ?? this.retailerSelected) };
        },
        center(): {
            lat: number;
            lng: number;
        } {
            if (this.userPosition) return this.userPosition;
            if (this.markers?.length) return this.markers[0].position;
            return {
                lat: +this.countrySelected?.locationLatitude,
                lng: +this.countrySelected?.locationLongitude,
            };
        },
    },
    components: {
        BaseModal,
        PickUpCenters,
        GoogleMap,
        Polygon,
        Marker,
    },
    methods: {
        ...mapActions({
            fetchCart: 'cart/fetchCart',
        }),
        async selectCenter(center: PickUpCenter): Promise<void> {
            const _location: AddressModel = { ...this.locationSelected };
            _location.isPickUpMode = true;
            _location.pickUpCenter = center;
            /// ** and update user delivery mode selection;
            this.$store.commit('user/userLocation', _location);
            this.$store.commit('payments/isMixPaymentTiptiCard', false);
            this.$store.commit('payments/isMixInstitutionalPayment', false);
            this.$store.commit('payments/cleanPaymentMethods', true);
            this.$store.dispatch('segment/initialModeDeliveryAndPickup', {
                mode: {
                    initial_mode: 'DELIVERY',
                    new_mode: 'PICKUP',
                },
                origin: {
                    screen: this.$route.path,
                },
            });
            this.fetchFirstSchedulePerRetailerPickUp().catch((err) => logger('FETCH_FIRST_SCHEDULE_PER_RETAILER', err));
            this.fetchCart({ retailerIds: this.retailerIdsWithtimeDelivery }).catch((err) => logger('FETCH_CART', err));
            this.showPickUpMap = false;
        },
        usersCurrentLocation() {
            navigator.geolocation.getCurrentPosition(this.getPositions, this.geoError, {
                enableHighAccuracy: true,
                timeout: 1000,
                maximumAge: Infinity,
            });
        },
        getPositions(position) {
            logger('POSITION', position);
            if (!position) return;
            this.userPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
            this.markers.push({
                position: this.userPosition,
                icon: {
                    /// ** TODO: ask designer for this icon
                    url:
                        'data:image/svg+xml;charset=UTF-8,' +
                        encodeURIComponent(
                            '<svg width="60" height="60" viewBox="0 0 104 150" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M51.8677 84.8448C70.0807 84.8448 84.8453 70.0803 84.8453 51.8672C84.8453 33.6542 70.0807 18.8896 51.8677 18.8896C33.6547 18.8896 18.8901 33.6542 18.8901 51.8672C18.8901 70.0803 33.6547 84.8448 51.8677 84.8448Z" fill="#FF9012"/><path d="M51.8677 0C23.2124 0 0 23.2124 0 51.8677C0 80.5229 51.8677 150 51.8677 150C51.8677 150 103.735 80.5229 103.735 51.8677C103.735 23.2124 80.523 0 51.8677 0ZM51.8677 89.3276C31.2167 89.3276 14.4077 72.5187 14.4077 51.8677C14.4077 31.2167 31.2167 14.4077 51.8677 14.4077C72.5187 14.4077 89.3276 31.2167 89.3276 51.8677C89.3276 72.5187 72.5187 89.3276 51.8677 89.3276Z" fill="#FF9012"/><path d="M55.6826 59.0901C49.1191 60.2106 50.72 50.2854 51.8406 45.4828C53.2814 39.2395 54.402 36.9983 54.7221 35.7176C55.2024 33.3163 60.9655 34.4369 60.3251 37.1584C59.8449 39.0794 58.8843 42.2811 58.244 44.6824C57.2835 48.2042 56.9633 52.0463 57.4436 55.7283C57.4436 55.4081 58.4041 58.6098 55.6826 59.0901Z" fill="white"/><path d="M23.3452 59.0895C23.3452 59.0895 52.961 76.3787 66.8884 51.7256C67.849 49.8046 68.9695 48.0436 70.5704 46.6029C73.7721 43.5612 77.7742 47.7235 75.373 52.2058C69.2897 63.2517 49.5992 78.78 23.3452 59.0895Z" fill="white"/><path d="M48.4788 36.9984C49.1192 34.4371 44.1565 33.3165 43.0359 35.0774C42.2355 37.959 41.5952 40.5203 41.1149 43.0817C38.8737 54.6078 42.0754 59.8907 44.9569 59.2503C47.8385 58.61 43.8364 58.2898 46.7179 44.2023C47.0381 42.2813 47.6784 39.7199 48.4788 36.9984Z" fill="white"/></svg>',
                        ),
                    scaledSize: { width: 50, height: 50 },
                    labelOrigin: { x: 0, y: 0 },
                },
            });
        },
        geoError(error) {
            logger('GEO_ERROR', error);
        },
    },
    async mounted() {
        this.centers = await this.getCenters(this.retailer?.id);
        this.markers = this.centers.map((center: PickUpCenter) => {
            return {
                id: center.id,
                position: {
                    lat: +center.latitude,
                    lng: +center.longitude,
                },
                icon: {
                    url: center.markerLogo,
                    scaledSize: { width: 50, height: 50 },
                    labelOrigin: { x: 0, y: 0 },
                },
                center: center,
            };
        });
        this.usersCurrentLocation();
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss';

.pickup_map {
    display: grid;
    box-sizing: border-box;
    min-height: 90vh;
    grid-template-columns: calc(100% - 65vw) 65vw;
    @include tablet {
        display: flex;
        flex-direction: column-reverse;
        grid-template-columns: 1fr;
        height: 100vh;
        min-width: 100vw;
    }
}

.vue-map-container {
    @include tablet {
        height: 100%;
    }
}

.google-maps__map {
    width: 100%;
    height: 90vh;
    @include tablet {
        height: 100vh;
    }
}
</style>
