import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f237516"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expandable" }
const _hoisted_2 = { class: "expandable__dropdown" }
const _hoisted_3 = { class: "expandable__dropdown--right" }
const _hoisted_4 = { class: "expandable__separator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow = _resolveComponent("icon-arrow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "header", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "header-side", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        ($props.canExpanded)
          ? (_openBlock(), _createBlock(_component_icon_arrow, {
              key: 0,
              "icon-name": $data.localExpanded ? _ctx.$t('icon.hide') : _ctx.$t('icon.show'),
              size: 0.5,
              color: $options.color,
              class: _normalizeClass(["expandable__arrow", { 'expandable__arrow--up': $data.localExpanded }])
            }, null, 8, ["icon-name", "color", "class"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}