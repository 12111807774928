<template>
    <loading-modal
        v-if="state === 'LOADING' || isLoading"
        :body="body"
        :dont-close="dontClose"
        :has-header="hasHeader"
        :title="title"
    />
    <success-modal
        v-else-if="state === 'SUCCESS' || isSuccess"
        :body="body"
        :cta-text="ctaText"
        :has-close="hasClose"
        :has-header="hasHeader"
        :show-action="showAction"
        :title="title"
        @on-close="$emit('on-close')"
        @on-action="$emit('on-action')"
    />
    <error-modal
        v-else-if="state === 'ERROR' || isFailure"
        :body="body"
        :cta-text="ctaText"
        :has-close="hasClose"
        :has-header="hasHeader"
        :link-text="linkText"
        :show-action="showAction"
        :show-oops="showOops"
        :title="title"
        @on-close="$emit('on-close')"
        @on-action="$emit('on-action')"
        @on-text-action="$emit('on-text-action')"
    />
</template>
<script lang="ts">
import LoadingModal from '@/components/modal/loadingModal/LoadingModal.vue';
import SuccessModal from '@/components/modal/successModal/SuccessModal.vue';
import ErrorModal from '@/components/modal/errorModal/ErrorModal.vue';
import { PropType } from 'vue';
import { WorkingState } from '@/modules/core/types/WorkingState';

export default {
    name: 'ModalStateFeedback',
    components: { ErrorModal, SuccessModal, LoadingModal },
    props: {
        isSuccess: {
            type: Boolean,
            default: false,
        },
        isFailure: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        state: {
            type: String as PropType<WorkingState>,
            default: 'NONE',
        },
        title: {
            type: String,
            default: '',
        },
        body: {
            type: String,
            default: '',
        },
        ctaText: {
            type: String,
            default: '',
        },
        linkText: {
            type: String,
            default: '',
        },
        hasClose: {
            type: Boolean,
            default: false,
        },
        hasHeader: {
            type: Boolean,
            default: false,
        },
        showAction: {
            type: Boolean,
            default: false,
        },
        dontClose: {
            type: Boolean,
            default: false,
        },
        showOops: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-close', 'on-action', 'on-text-action'],
};
</script>
