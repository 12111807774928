<template>
    <div class="column">
        <slot />
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { AlignmentContent, JustifyContent } from '@/modules/core/components/enums/Aigments';

export default {
    name: 'BaseColumn',
    props: {
        alignItems: {
            type: String as PropType<AlignmentContent>,
            default: 'center',
        },
        justifyContent: {
            type: String as PropType<JustifyContent>,
            default: 'center',
        },
    },
};
</script>

<style lang="scss" scoped>
.column {
    display: flex;
    flex-direction: column;
    align-items: v-bind(alignItems);
    justify-content: v-bind(justifyContent);
}
.min-gap {
    gap: 0.5rem;
}
</style>
