<template>
    <base-icon :icon-name="$t('icon.single_check')" :width="width" :height="height" viewBox="0 0 23 22">
        <path
            d="M22.2587 10.8286C22.2587 16.4465 17.4417 21.0556 11.4302 21.0556C5.41864 21.0556 0.601587 16.4465 0.601587 10.8286C0.601587 5.2106 5.41864 0.601587 11.4302 0.601587C17.4417 0.601587 22.2587 5.2106 22.2587 10.8286Z"
            fill="#FF9012"
            stroke="#FF9012"
            stroke-width="1.20317"
        />
        <path
            d="M16.6297 9.20092L11.4494 15.2699C11.238 15.5176 11.0266 15.6415 10.7094 15.6415C10.498 15.6415 10.1808 15.5176 9.96939 15.2699L7.53786 12.4212C7.11498 11.9258 7.11498 11.1826 7.53786 10.811C7.96073 10.3156 8.59505 10.3156 8.9122 10.811L10.6037 12.7928L15.0439 7.59081C15.4668 7.09538 16.1011 7.09538 16.4182 7.59081C16.9468 7.96238 16.9468 8.7055 16.6297 9.20092Z"
            fill="white"
        />

        <defs>
            <clipPath id="clip0">
                <rect width="125.658" height="150" fill="white" />
            </clipPath>
        </defs>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-check',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 23 * this.size;
        },
        height(): number {
            return 22 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
