import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { TermsAndConditionsDto } from '@/models/termsAndConditions/TermsAndConditionsDto';
import { reactive, ref } from 'vue';
import { useApp } from '@/composables/useApp';
import { useTranslate } from '@/composables/UseTranslate';

export const useTermsAndConditions = () => {
    const { $store, $route } = useApp();
    const { exec } = useTranslate();

    const termsAndConditionsData = reactive<TermsAndConditionsDto[]>([]);
    const selectedTermsAndConditions = ref<TermsAndConditionsDto>();

    const fetchTermsAndConditions = async () => {
        const { data } = await axiosInstance().get<TermsAndConditionsDto[]>('terms_conditions');
        termsAndConditionsData.push(...data);
    };

    const selectTermsAndConditions = (item: TermsAndConditionsDto): void => {
        selectedTermsAndConditions.value = item;
        $store.dispatch('segment/viewTermsAndConditions', {
            origin: {
                screen: $route.name,
            },
            terms_and_conditions: {
                client_accepted: false,
                terms_type: selectedTermsAndConditions.value.terms_and_conditions_type,
                terms_information: exec(selectedTermsAndConditions.value.document),
            },
        });
    };

    return {
        fetchTermsAndConditions,
        termsAndConditionsData,
        selectedTermsAndConditions,
        selectTermsAndConditions,
    };
};
