import { CashWirePaymentModel } from '@/models/checkout/CashWirePaymentModel';
import { OnlineBankServiceModel } from '@/models/paymentMethods/OnlineBankServiceModel';
import { PayphoneModel } from '@/models/paymentMethods/PayphoneModel';
import { DefaultPaymentTypeModel } from '@/models/paymentMethods/DefaultPaymentTypeModel';
import { DefaultPaymentModel } from '@/models/paymentMethods/DefaultPaymentModel';
import { DeferredModel } from '@/models/paymentMethods/DeferredModel';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';

import InstitutionalPaymentModel from '@/models/checkout/InstitutionalPaymentModel';
import PaymentToShowModel, { PaymentToShowName } from '@/models/checkout/PaymentToShowModel';
import NequiPaymentModel from '@/models/checkout/NequiPaymentModel';

export default {
    totalToPay(state, amount: number) {
        state.totalToPay = amount;
    },
    creditCardDefault(state, creditCard: PaymentMethodModel) {
        state.creditCardDefault = creditCard;
    },
    creditCardSelected(state, creditCard: PaymentMethodModel) {
        state.creditCardSelected = creditCard;
    },
    paymentIdSelected(state, id: number) {
        state.paymentIdSelected = id;
    },
    deferred(state, deferred: DeferredModel) {
        const paymentCard = state.paymentsToShow?.find((payment) => payment.name === 'payment-cards');
        if (paymentCard) paymentCard.deferredInformation = deferred;
        state.deferred = deferred;
    },
    deferredOptions(state, deferredOptions: Array<DeferredModel>) {
        if (!deferredOptions?.length) state.deferred = undefined;
        state.deferredOptions = deferredOptions;
    },
    paymentsToShow(state, newPayment: PaymentToShowModel) {
        if (!newPayment) return (state.paymentsToShow = []);
        const updatedPaymentsToShow: Array<PaymentToShowModel> = state.paymentsToShow.filter(
            (payment: PaymentToShowModel) => {
                if (newPayment.name === 'payment-cards' || newPayment.name === 'payment-payphone')
                    return payment.name !== 'payment-cards' && payment.name !== 'payment-payphone';
                return payment.name != newPayment.name;
            },
        );
        state.paymentsToShow = [...updatedPaymentsToShow, newPayment];
    },
    removePayment(state, { id, name }: { id: number; name: PaymentToShowName }) {
        const updatedPaymentsToShow: Array<PaymentToShowModel> = state.paymentsToShow?.filter(
            (payment: PaymentToShowModel) => payment.name !== name && payment.id !== id,
        );
        state.paymentsToShow = [...updatedPaymentsToShow];
    },
    cashWirePayment(state, cashWirePayment: CashWirePaymentModel) {
        state.cashWirePayment = cashWirePayment;
    },
    institutionPayment(state, institutional: InstitutionalPaymentModel) {
        state.institutionPayment = institutional;
    },
    onlineBankServicePayment(state, onlineBankService: OnlineBankServiceModel) {
        state.onlineBankServicePayment = onlineBankService;
    },
    nequiPayment(state, nequiPayment: NequiPaymentModel) {
        state.nequiPayment = nequiPayment;
    },
    isTiptiCardPayment(state, isTiptiCard: boolean) {
        state.isTiptiCardPayment = isTiptiCard;
    },
    isMixPaymentTiptiCard(state, isMixPayment: boolean) {
        state.isMixPaymentTiptiCard = isMixPayment;
    },
    isMixInstitutionalPayment(state, isMixPayment: boolean) {
        state.isMixInstitutionalPayment = isMixPayment;
    },
    isYappyPayment(state, isYappyPayment: boolean) {
        state.isYappyPayment = isYappyPayment;
    },
    secondPayment(state, secondPayment: number) {
        state.secondPayment = secondPayment;
    },
    cleanPaymentMethods(state) {
        if (state.isMixPaymentTiptiCard && state.paymentsToShow?.length > 2) state.isMixPaymentTiptiCard = false;
        if (state.isMixInstitutionalPayment && state.paymentsToShow?.length > 2)
            state.isMixInstitutionalPayment = false;
        if (state.isMixPaymentTiptiCard || state.isMixInstitutionalPayment) return;
        state.institutionPayment = null;
        state.paymentIdSelected = null;
        state.cashWirePayment = null;
        state.isTiptiCardPayment = false;
        state.onlineBankServicePayment = null;
        state.nequiPayment = null;
        state.isYappyPayment = false;
        state.deferred = [];
        state.paymentsToShow = [];
    },
    payphoneList(state, payphoneList: Array<PayphoneModel>): void {
        state.payphoneList = payphoneList;
    },
    defaultPaymentsTypesAvailable(state, defaultPaymentTypes: Array<DefaultPaymentTypeModel>): void {
        state.defaultPaymentsTypesAvailable = defaultPaymentTypes;
    },
    defaultPaymentSelected(state, defaultPayment: DefaultPaymentModel): void {
        state.defaultPaymentSelected = defaultPayment;
    },
};
