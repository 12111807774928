import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-133087d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "minimum-amount-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_general_alert = _resolveComponent("general-alert")!

  return (_openBlock(), _createBlock(_component_general_alert, {
    acceptButtonTxt: _ctx.$t('cta.delete-and-continue'),
    accentHeader: true,
    showCloseIcon: true,
    declineButtonTxt: _ctx.$t('cta.no-delete'),
    title: _ctx.$t('txt.minimum-amount__are-you-sure', { amount: $props.minimumAmount, currency: $options.currency }),
    onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close'))),
    onOnAccept: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-accept'))),
    onOnDecline: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-decline'))),
    isLoading: $props.isLoading,
    isDisabled: $props.isLoading
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('txt.minimum-amount__there-is', { amount: $props.minimumAmount, currency: $options.currency })), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('txt.minimum-amount__if-deleted')), 1)
      ])
    ]),
    _: 1
  }, 8, ["acceptButtonTxt", "declineButtonTxt", "title", "isLoading", "isDisabled"]))
}