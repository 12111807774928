<template>
    <section v-if="creditCards?.length || isLoading">
        <h6 v-if="!isLoading" class="credit-card-header-list">
            {{ $t('txt.credit-card__cards-choosed') }}
        </h6>
        <credit-card-list
            :cards="creditCards"
            :is-delete="isDelete"
            :show-skeleton="isLoading"
            @fetch-list="fetchPaymentMethods"
            @add-card="$emit('add-card')"
        />
    </section>
    <no-content
        v-if="!creditCards?.length && !isLoading"
        :description="$t('txt.credit-card__dont-have')"
        :title="$t('general.credit-debit-card')"
    >
        <icon-one-credit-card :size="2" color="#C7C7C7" />
    </no-content>
    <snack-bar
        :body="modalMessage"
        :is-active="showSnackBar"
        :is-failure="!isSuccess"
        :is-success="isSuccess"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import { logger } from '@/utils/logger';

import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import NoContent from '@/components/noContent/NoContent.vue';
import CreditCardList from '@/views/creditCards/components/listCards/CreditCardList.vue';
import IconOneCreditCard from '@/components/icons/IconOneCreditCard.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'ListCards',
    components: {
        IconOneCreditCard,
        CreditCardList,
        SnackBar,
        NoContent,
    },
    data() {
        return {
            isLoading: false,
            showSnackBar: false,
            modalMessage: '',
            isSuccess: false,
            creditCards: [] as PaymentMethodModel[],
            isDelete: false,
        };
    },
    computed: {
        ...mapGetters({
            isMixInstitutionalPayment: 'payments/isMixInstitutionalPayment',
            isMixPaymentTiptiCard: 'payments/isMixPaymentTiptiCard',
            secondPayment: 'payments/secondPayment',
            totalToPay: 'payments/totalToPay',
            paymentIdSelected: 'payments/paymentIdSelected',
        }),
    },
    async mounted() {
        await this.fetchPaymentMethods();
    },
    methods: {
        ...mapActions({
            fetchCreditCards: 'payments/creditCardList',
        }),
        async fetchPaymentMethods(loadSilent = false): Promise<void> {
            try {
                this.isLoading = !loadSilent;
                this.showVerificationFlow = false;
                this.creditCards = await this.fetchCreditCards(true);
                if (!this.$route.path.name?.includes(appRoutesMap.paymentResent.resend))
                    this.$store.commit('payments/cleanPaymentMethods');
                if (!this.paymentIdSelected) return;
                const creditCard = this.creditCards.find(
                    (card: PaymentMethodModel) => card.id == this.paymentIdSelected,
                );
                this.$store.commit('payments/paymentIdSelected', this.paymentIdSelected);
                if (!creditCard) return;
                this.$store.commit(
                    'payments/paymentsToShow',
                    new PaymentToShowModel({
                        name: 'payment-cards',
                        id: creditCard.id,
                        idExpired: creditCard.isExpired,
                        amount:
                            this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment
                                ? this.secondPayment
                                : this.totalToPay,
                        data: creditCard?.data?.cardDigits?.slice(1, 5),
                        icon: creditCard?.creditCardBrandData?.franchiseLogo,
                        backgroundColor: creditCard?.creditCardBrandData?.backgroundColor,
                        analyticData: {
                            franchise: creditCard?.franchise,
                            bin: creditCard?.creditCardBrandData?.bin,
                        },
                        objectData: ['payment-cards', creditCard?.id],
                    }),
                );
                this.$store.dispatch('promotions/setCreditCardPromotion');
            } catch (err) {
                this.isSuccess = false;
                this.showSnackBar = true;
                this.modalMessage = this.$t('general.please_reload');
                logger('FETCH_CREDIT_CARDS', err);
            } finally {
                this.isLoading = false;
            }
        },
    },
    emits: ['add-card'],
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.credit-card-header-list {
    @include subtitle2;
    font-size: 18px;
    font-weight: 400;
    margin: $margin-lg 0;
}
</style>
