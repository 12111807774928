import { logger } from '@/utils/logger';

export default {
    async getSlidesNews({ rootGetters, commit }): Promise<void> {
        try {
            if (!rootGetters['user/userLocation']) return;
            const _url = 'v2/api/slide-groups';
            const { data } = await rootGetters['network/axios'].get(_url);
            commit('alertNewsSlide', data);
        } catch (err) {
            commit('alertNewsSlide', null);
            logger('GET_SLIDES_NEWS', err);
        }
    },
    async slideActivity({ rootGetters }, payload: { id: number; accepted: boolean }): Promise<void> {
        try {
            const _url = `v2/api/slide-group/${payload.id}/activity`;
            const _body = {
                origin: 'app_web',
                accepted: payload.accepted,
            };
            await rootGetters['network/axios'].put(_url, _body);
        } catch (err) {
            logger('SLIDE_ACTIVITY', err);
        }
    },
};
