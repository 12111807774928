<template>
    <base-card class="sharable-container">
        <aside class="sharable-information">
            <h5 class="sharable-information__head">
                {{ $t("txt['referred__win-money']").toUpperCase() }} {{ currency }} {{ amountGift }}
            </h5>
            <strong class="sharable-information__middle">{{
                $t("txt['referred__every-referred']").toUpperCase()
            }}</strong>
            <p class="sharable-information__description">
                {{
                    $t("txt['referred__send-this-code']", {
                        currency: currency,
                        amountGift: amountGift,
                        amountMin: amountMin,
                    })
                }}
            </p>
        </aside>
        <aside class="sharable">
            <img v-if="user?.qrCodeReferred" :src="user.qrCodeReferred" alt="QR code" />
            <div class="sharable__info">
                <h4 class="sharable__code" v-if="referals?.referredCode">{{ referals.referredCode }}</h4>
                <p class="sharable__label">{{ $t("txt['referred__share-code']") }}</p>
                <share-buttons
                    class="sharable__buttons"
                    :link="referals?.referredDeepLink"
                    :messageShare="messageShare"
                    :snackBarMessage="$t('alerts.link_copied')"
                />
            </div>
        </aside>
        <aside class="sharable-information">
            <h1 class="sharable-information__head">
                {{ $t("txt['referred__win-money']").toUpperCase() }} {{ currency }} {{ amountGift }}
            </h1>
            <h2 class="sharable-information__middle">{{ $t("txt['referred__first-order']").toUpperCase() }}</h2>
            <h4 class="sharable-information__description">
                {{ $t("txt['referred__code-referred']", { currency: currency, amountGift: amountGift }) }}
            </h4>
            <div class="referred-code">
                <coupon class="referred-code__coupon" section="REFERREDS" />
            </div>
            <br />
        </aside>
        <txt-action-terms-and-conditions />
    </base-card>
</template>

<script lang="ts">
import BaseCard from '@/components/cards/BaseCard.vue';
import TxtActionTermsAndConditions from '@/components/termAndConditions/TxtActionTermsAndConditions.vue';
import Coupon from '@/components/inputs/redeem/Coupon.vue';
import ShareButtons from '@/components/buttons/ShareButtons.vue';

import { mapGetters } from 'vuex';
import { ReferalModel } from '@/models/referred/ReferalModel';

export default {
    name: 'sharable-information',
    components: {
        BaseCard,
        TxtActionTermsAndConditions,
        Coupon,
        ShareButtons,
    },
    props: {
        referals: {
            type: ReferalModel,
        },
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            countrySelected: 'external/countrySelected',
            baseConfiguration: 'user/baseConfiguration',
        }),
        currency() {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        amountGift() {
            return this.baseConfiguration?.referredReward ?? 0;
        },
        amountMin() {
            return this.baseConfiguration?.referredRewardCondition ?? 0;
        },
        messageShare() {
            return this.$t("txt['referred__msg-code-send']", {
                link: this.referals?.referredDeepLink,
                code: this.referals?.referredCode,
                currency: this.currency,
                amount: this.amountGift,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import './sharable-information.scss';
</style>
