<template>
    <div class="subscription-data">
        <p class="subscription-data__text subscription-data__text--question">
            {{ title }}
        </p>
        <p class="subscription-data__text subscription-data__text--exclamation">
            {{ subtitle }}
        </p>
        <p
            v-if="cambrellaMembership"
            v-date="{
                date: cambrellaMembership.date_end_membership,
                format: 'D MMM YYYY',
            }"
            class="subscription-data__text subscription-data__text--price"
        />
        <p v-else class="subscription-data__text subscription-data__text--price">
            {{ $t('general.pay__only') }} <span v-currency="membership.price" />
        </p>
    </div>
</template>
<script lang="ts" setup>
import { MembershipType } from '@/modules/cambrella/models/MembershipType';
import { useCambrellas } from '@/modules/cambrella/composables/useCambrellas';
import { computed, PropType } from 'vue';
import { useApp } from '@/composables/useApp';

const props = defineProps({
    membership: {
        type: Object as PropType<MembershipType>,
        required: true,
    },
});
const { $t } = useApp();
const { cambrellaMembership } = useCambrellas();

const title = computed(() => {
    if (!cambrellaMembership.value) return $t('txt.subscription__change');
    return !cambrellaMembership.value.cambrellas_in_use
        ? $t('txt.cambrellas__not_used')
        : $t('txt.cambrellas__used_count', {
              total: cambrellaMembership.value.number_of_cambrellas,
              used: cambrellaMembership.value.cambrellas_in_use,
          });
});
const subtitle = computed(() =>
    !cambrellaMembership.value ? $t('txt.subscription__get') : $t('txt.membership__active') + ':',
);
</script>
<style lang="scss" scoped>
@import '@/assets/scss';

.subscription-data {
    background-image: url('../../../assets/svg/ic_cambrella_subscription.svg');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    &__text {
        @include overline;
        color: $white;
        text-align: right;
        max-width: 10rem;
        margin-bottom: $margin-xsm;

        &--question {
            font-weight: 600;
        }

        &--price {
            @include body2;
            color: $white;
            font-weight: 600;
            font-size: 18px;
            max-width: unset;
        }
    }
}
</style>
