<template>
    <template v-if="$route.meta.banner_type">
        <template v-if="$route.meta.banner_type === 'products'">
            <banner-with-subcategories v-if="hasSubcategories" />
            <multiple-products
                v-else
                :track-resource-type="trackResourceType"
                fetch-string="promotions/bannersProducts"
            />
        </template>
        <multiple-products
            v-if="$route.meta.banner_type === 'promotions'"
            :track-resource-type="trackResourceType"
            fetch-string="promotions/bannersPromotions"
        />
        <multiple-products
            v-if="$route.meta.banner_type === 'discount'"
            :track-resource-type="trackResourceType"
            fetch-string="promotions/bannersDiscount"
        />
        <promotion-categories v-if="$route.meta.banner_type === 'category'" :fetch-string="fetchType" />
    </template>
    <template v-if="$route.meta.story_type">
        <promotion-categories v-if="$route.meta.story_type === 'category'" :fetch-string="storeFetch" />
        <multiple-products v-else :fetch-string="storeFetch" track-resource-type="HISTORY_ITEM_TRACK" />
    </template>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import BannerWithSubcategories from '@/views/multipleProducts/banners/bannerWithSubcategories/BannerWithSubcategories.vue';
import MultipleProducts from '@/views/multipleProducts/MultipleProducts.vue';
import PromotionCategories from '@/views/promotionCategories/PromotionCategories.vue';
import { useApp } from '@/composables/useApp';

const { $store, $route } = useApp();

const trackResourceType = computed(() =>
    $store.getters['promotions/isBannerInSection'] ? 'BANNER_ITEM_TRACK' : undefined,
);

const storeFetch = computed(() => {
    if ($route.meta.story_type === 'category') return 'stories/storiesSubcategories';
    return $route.meta.story_type === 'products' ? 'stories/productsFromStory' : 'stories/productsWithDiscount';
});

const fetchType = computed(() =>
    $store.getters['promotions/isBannerInSection']
        ? 'promotions/bannersInSectionCategory'
        : 'promotions/bannersCategory',
);

const hasSubcategories = computed(() => $store.getters['promotions/bannerSelected']?.isCategorizeProducts);
</script>
