<template>
    <header class="head">
        <slot name="icon-left">
            <button v-if="withDefaultIconBack" class="header-icon header-icon--left" @click.stop="$emit('on-back')">
                <icon-back />
            </button>
            <div v-else></div>
        </slot>
        <slot name="text">
            <h1 class="title">{{ text }}</h1>
        </slot>
        <slot name="icon-right">
            <button v-if="withDefaultClose" class="header-icon header-icon--right" @click="$emit('on-close')">
                <icon-circular-close :size="sizeIcon" />
            </button>
            <div v-else></div>
        </slot>
    </header>
</template>

<script lang="ts">
import { PropType } from 'vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import IconBack from '@/components/icons/IconBack.vue';

enum Colors {
    white = '#fff',
    orange = '#ff9012',
    magenta = '#f03f51',
    text = '#707070',
}

type ColorAvailable = keyof typeof Colors;
type SizeAvailable = 14 | 16 | 20 | 24 | 28;
type WeightAvailable = 200 | 400 | 600;
export default {
    name: 'BaseHeader',
    components: { IconBack, IconCircularClose },
    props: {
        text: {
            type: String,
            default: '',
        },
        textColor: {
            type: String as PropType<ColorAvailable>,
            default: 'text',
        },
        /**
         * @description This property will reduce in 2px in tablet size or less
         */
        textSize: {
            type: Number as PropType<SizeAvailable>,
            default: 28,
        },
        textWeight: {
            type: Number as PropType<WeightAvailable>,
            default: 600,
        },
        withDefaultClose: {
            type: Boolean,
            default: false,
        },
        withDefaultIconBack: {
            type: Boolean,
            default: false,
        },
        sizeIcon: {
            type: Number,
            default: 1,
        },
    },
    emits: ['on-close', 'on-back'],
    computed: {
        txtColor() {
            return Colors[this.textColor] ?? Colors.text;
        },
        txtSize(): string {
            return (this.textSize ?? 28) + 'px';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.head {
    width: 100%;
    min-width: 0;
    display: grid;
    grid-template-columns: 10% 1fr 10%;
    gap: $margin-sm;
    align-items: center;
    justify-items: center;
    padding: $padding-sm;
    box-sizing: border-box;
    background-color: $white;
    border-radius: inherit;
}

.title {
    @include headline5;
    color: v-bind(txtColor);
    font-weight: v-bind(textWeight);
    text-align: center;
    font-size: v-bind(txtSize);
    @include tablet {
        @include subtitle2;
        color: v-bind(txtColor);
        font-size: v-bind(txtSize - 2);
        font-weight: v-bind(textWeight);
    }
}

.header-icon {
    all: unset;
    cursor: pointer;

    &--left {
        justify-self: flex-start;
    }

    &--right {
        justify-self: flex-end;
    }
}
</style>
