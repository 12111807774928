<template>
    <base-modal :title="title" @on-close="$emit('on-close')" autoSize hasClose noScroll>
        <div class="cambrellas">
            <section class="cambrellas__content">
                <img class="cambrellas__img" :src="membership.icon" alt="" @error="displayDefaultImg" />
                <section class="cambrellas__progress">
                    <p class="cambrellas__progress-description">
                        {{
                            $t('txt.cambrellas__progress', {
                                init: cambrellasUsed,
                                finish: membership.number_of_cambrellas,
                            })
                        }}
                    </p>
                    <div class="progress-indicator">
                        <p class="progress-indicator__value">0</p>
                        <progress-status
                            class="cambrellas__status"
                            :progress="cambrellasRemaining"
                            bg-color="#DFDFDF"
                        />
                        <p class="progress-indicator__value">{{ membership.number_of_cambrellas }}</p>
                    </div>
                </section>
                <cambrella-return :membership="membership" />
                <p class="cambrellas__progress-description cambrellas__progress-description--footer">
                    {{ footerMessage }}
                </p>
            </section>
            <section-footer vertical-padding="0" class="cambrellas__footer" :has-border="false" :with-shadow="false">
                <btn-text
                    class="cambrellas__btn-txt"
                    :text="
                        $t('txt.cambrellas__expire', {
                            date: doFormat({
                                date: membership.date_end_membership,
                            }),
                        })
                    "
                    :in-link="false"
                    :is-underline="false"
                    is-normal-weight
                />
            </section-footer>
        </div>
    </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BtnText from '@/components/buttons/BtnText.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import { useImage } from '@/composables/ImageComposable';
import ProgressStatus from '@/components/progressBar/ProgressStatus.vue';
import { CambrellaMembershipModel } from '@/modules/cambrella/models/CambrellaMembership';
import { useDays } from '@/composables/useDays';
import CambrellaReturn from '@/modules/cambrella/components/CambrellaReturn.vue';

export default {
    name: 'cambrella-with-subscription',
    components: { CambrellaReturn, ProgressStatus, BaseModal, BtnText, SectionFooter },
    props: {
        membership: {
            type: [Object, CambrellaMembershipModel],
            required: true,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const { displayDefaultImg } = useImage();
        const { doFormat } = useDays();
        return {
            displayDefaultImg,
            doFormat,
        };
    },
    computed: {
        title(): string {
            return this.showHeader ? this.membership.membership_type_name : '';
        },
        cambrellasRemaining(): number {
            return (this.cambrellasUsed * 100) / this.membership.number_of_cambrellas;
        },
        cambrellasUsed(): number {
            const min = 0;
            const max = this.membership.number_of_cambrellas;
            const clamp = (num: number): number => Math.min(Math.max(num, min), max);
            return clamp(this.membership.cambrellas_in_use);
        },
        footerMessage(): string {
            return this.membership.cambrellas_available
                ? this.membership.notice_with_availability_of_cambrellas
                : this.membership.notice_without_availability_of_cambrellas;
        },
    },
    emits: ['on-close', 'on-terms-and-conditions', 'on-subscribe'],
};
</script>
<style lang="scss" scoped>
@import 'cambrella.scss';
</style>
