<template>
    <section class="retailer-info">
        <ImageLoader
            class="retailer-info__img"
            :alt-text="retailerName"
            :src="retailerLogo"
            bone-loader
            loading="lazy"
            @click.stop="gotoHome"
        />
        <h2 class="retailer-info__title" @click="gotoHome">{{ retailerName }}</h2>
        <aside v-if="deliveryRange" class="retailer-info__schedule">
            <icon-watch :size="0.7" />
            <h4 class="schedule__date">
                <span class="schedule__date--orange">{{ deliveryMode }}:</span>
                {{ deliveryRange }}
            </h4>
        </aside>
    </section>
</template>

<script lang="ts" setup>
import IconWatch from '@/components/icons/IconWatch.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { computed } from 'vue';
import { useUser } from '@/composables/useUser';
import { useI18n } from 'vue-i18n';
import ImageLoader from '@/modules/core/components/loaders/ImageLoader.vue';

const { goToRetailer, retailerSelected } = useRetailer();
const { userLocation } = useUser();
const { t } = useI18n();

const gotoHome = (): void => {
    goToRetailer({ replace: true });
};

const deliveryMode = computed(() => (userLocation.value?.isPickUpMode ? t('general.pick-up') : t('general.delivery')));

const retailerName = computed(() => retailerSelected.value?.name ?? '');
const retailerLogo = computed(() => retailerSelected.value?.franchise?.icon ?? retailerSelected.value?.icon);
const deliveryRange = computed(() => retailerSelected.value?.deliveryRange);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.retailer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $margin-lg;

    &__img {
        margin-top: $margin-lg;
        width: 100%;
        border-radius: 50%;
        max-width: 120px;
        aspect-ratio: 1/1;
        cursor: pointer;
        border: 1px solid $bright-grey;
    }

    &__title {
        @include subtitle2;
        color: $dark-grey;
        margin: $margin-md 0;
        cursor: pointer;
    }

    &__schedule {
        @include caption;
        display: flex;
        align-items: center;
        margin: $margin-sm $margin-md;
    }
}

.schedule__date {
    @include caption;
    font-weight: 600;
    margin-left: $padding-sm;

    &--orange {
        color: $orange;
    }
}
</style>
