import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19e46e32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-note" }
const _hoisted_2 = { class: "the-note-area" }
const _hoisted_3 = { class: "the-note__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_area = _resolveComponent("text-area")!
  const _component_icon_gallery = _resolveComponent("icon-gallery")!
  const _component_icon_filled_circular_close = _resolveComponent("icon-filled-circular-close")!
  const _component_the_btn_picture = _resolveComponent("the-btn-picture")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_alert_note_not_save = _resolveComponent("alert-note-not-save")!
  const _component_general_alert = _resolveComponent("general-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_text_area, {
          modelValue: $options.updateNote,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.updateNote) = $event)),
          modelModifiers: { trim: true },
          class: "the-note-area__area",
          placeholder: $options.thePlaceholder,
          rows: 4
        }, null, 8, ["modelValue", "placeholder"]),
        _createElementVNode("p", {
          class: _normalizeClass(["the-note-area__counter", { 'the-note-area__counter--limit': _ctx.tmpNote?.length >= 250 }])
        }, _toDisplayString(_ctx.tmpNote?.length) + "/250 ", 3)
      ]),
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("button", {
          class: "the-note-area__add-photo",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($options.isDeleteFile ? $options.onClean() : $options.onAddFile()), ["stop"]))
        }, [
          ($options.isDeleteFile)
            ? (_openBlock(), _createBlock(_component_icon_gallery, {
                key: 0,
                size: 0.6
              }))
            : _createCommentVNode("", true),
          ($options.isDeleteFile)
            ? (_openBlock(), _createBlock(_component_icon_filled_circular_close, {
                key: 1,
                class: "cursor-pointer",
                size: 0.4,
                color: "#f03f51",
                onClick: $options.onClean
              }, null, 8, ["onClick"]))
            : (_openBlock(), _createBlock(_component_the_btn_picture, {
                key: 2,
                onOnFile: $options.onFileChange
              }, null, 8, ["onOnFile"])),
          _createElementVNode("p", null, _toDisplayString($options.isDeleteFile ? _ctx.tmpFile?.name : _ctx.$t('cta.add-image')), 1)
        ]),
        _createVNode(_component_btn_solid, {
          "is-disabled": !$options.isNoteChanged || $props.isLoadingExternal || $data.isLoading || !_ctx.tmpNote?.length,
          "is-loading": $props.isLoadingExternal || $data.isLoading,
          txt: _ctx.$t('cta.modified-note'),
          onClick: _withModifiers($options.addNote, ["stop"])
        }, null, 8, ["is-disabled", "is-loading", "txt", "onClick"])
      ])
    ]),
    (_ctx.showAlertNotSaveNote)
      ? (_openBlock(), _createBlock(_component_alert_note_not_save, {
          key: 0,
          onOnAccept: _cache[2] || (_cache[2] = ($event: any) => ($options.addNote(true))),
          onOnDiscard: $options.cleanAndBack,
          onOnClose: $options.cleanAndBack
        }, null, 8, ["onOnDiscard", "onOnClose"]))
      : _createCommentVNode("", true),
    ($data.showAlertFileRequirements)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 1,
          "accent-header": true,
          message: _ctx.$t('txt.note__add-photo-as-note'),
          "only-confirmation-btn": true,
          title: _ctx.$t('txt.product-modal__send-note'),
          onOnAccept: _cache[3] || (_cache[3] = ($event: any) => ($data.showAlertFileRequirements = false)),
          onOnClose: _cache[4] || (_cache[4] = ($event: any) => ($data.showAlertFileRequirements = false))
        }, null, 8, ["message", "title"]))
      : _createCommentVNode("", true)
  ], 64))
}