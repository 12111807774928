import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
    polygons: [],
    cities: [],
    locationSelected: undefined,
    addressToEdit: undefined,
    countryAddresses: [],
    addressDefault: undefined,
    showAddressModal: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
