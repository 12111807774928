import { ProductModel, toProductModel } from '@/models/product/ProductModel';
import { BannersGoalModel, toBannersGoalModel } from '@/models/promotions/banners/BannerGoalModel';
import { BannerModel, toBannerModel } from '@/models/promotions/banners/BannerModel';
import { PromotionsCategoriesModel, toPromotionsCategoriesModel } from '@/models/promotions/PromotionsCategoriesModel';
import { RecipeDetailModel, toRecipeDetailModel } from '@/models/recipes/RecipeDetailModel';
import { logger } from '@/utils/logger';
import { paymentMethodService } from '@/modules/payment-methods/infraestructure/PaymentMethodService';
import { ResponsePaged } from '@/modules/core/models/ResponseModels';

interface IdPageBannerType {
    id: number;
    page: number;
    isBannerInSection?: boolean;
    retailerId: number;
}

interface RequestBannerDetail {
    id: number;
    page: number;
    itemsSize: number;
}

interface BannersGoal {
    id: number;
    isBannerInSection: boolean;
}

interface RequestBannerProducts {
    bannerId: number;
    categoryId: number;
    page: number;
}

export default {
    async bannerInSectionRetailer({ rootGetters }): Promise<Array<BannerModel>> {
        try {
            let page = 1;
            let fetchAnotherPage = true;
            let banners: Array<BannerModel> = [];
            while (fetchAnotherPage) {
                const url = `v2/banners_in_sections?app_section=Retailers&page=${page}`;
                const { data } = await rootGetters['network/axios'].get(url);
                fetchAnotherPage = Boolean(data['next']);
                banners = [...banners, ...data['results'].map((banner) => toBannerModel(banner))];
                page++;
            }
            return banners;
        } catch (err) {
            logger('BANNER_IN_SECTION_RETAILER', err);
            return [];
        }
    },
    async setCreditCardPromotion({ rootGetters, dispatch }): Promise<void> {
        try {
            if (!rootGetters['payments/paymentIdSelected']) return paymentMethodService.deletedPromotions();
            const url = 'v2/client_profile/coupon/promotion/';
            const body = {
                id_payment: rootGetters['payments/paymentIdSelected'],
                tipti_card_amount: rootGetters['payments/isTiptiCardPayment']
                    ? rootGetters['tiptiCard/tiptiCard']?.availableAmount
                    : null,
            };
            await rootGetters['network/axios'].post(url, body);
            /// ** FETCH CART only when there is not error on request above
            await dispatch('cart/fetchCart', null, { root: true });
        } catch (err) {
            logger('FETCH_CREDIT_CARD_PROMOTION', err);
        }
    },
    async promotionalBanners(
        { rootGetters },
        payload: { isBannerInSection: boolean; page: number; categoryId: number },
    ): Promise<{ banners: Array<BannerModel>; isLastPage: boolean }> {
        try {
            const url = payload?.isBannerInSection
                ? `v2/retailer/${rootGetters['retailers/retailerSelected']?.id}/banners_in_section/?page=${
                      payload?.page ?? 1
                  }&category=${payload.categoryId}`
                : `v2/retailer/${rootGetters['retailers/retailerSelected']?.id}/banners/?page=${payload?.page ?? 1}`;
            const { data } = await rootGetters['network/axios'].get(url);
            const promotionalBanners = data['results']?.map((promotion: BannerModel) => toBannerModel(promotion)) ?? [];
            const isLastPage = !data['next'];
            return { banners: promotionalBanners, isLastPage: isLastPage };
        } catch (err) {
            throw Error(`PROMOTIONAL_BANNERS ${err}`);
        }
    },
    async bannersDiscount({ rootGetters }, payload: IdPageBannerType): Promise<ResponsePaged<ProductModel[]>> {
        try {
            const url = payload.isBannerInSection
                ? `v2/retailer/${payload.retailerId}/banners_in_section/${payload.id}/discount_detail?page=${
                      payload.page ?? 1
                  }`
                : `v2/retailer/${payload.retailerId}/banners/${payload.id}/discount_detail?page=${payload.page ?? 1}`;
            const { data } = await rootGetters['network/axios'].get(url);
            return {
                ...data,
                results: data?.results?.map(toProductModel) ?? [],
            };
        } catch (err) {
            throw Error(`BANNERS_DISCOUNT ${err}`);
        }
    },
    async bannersProducts({ rootGetters }, payload: IdPageBannerType): Promise<ResponsePaged<ProductModel[]>> {
        try {
            const url = payload.isBannerInSection
                ? `v2/retailer/${payload.retailerId}/banners_in_section/${payload.id}/detail?page=${payload.page ?? 1}`
                : `v2/retailer/${payload.retailerId}/banners/${payload.id}/detail?page=${payload.page ?? 1}`;
            const { data } = await rootGetters['network/axios'].get(url);
            return {
                ...data,
                results: data?.results?.map((product: ProductModel) => toProductModel(product)) ?? [],
            };
        } catch (err) {
            throw Error(`BANNERS_PRODUCTS ${err}`);
        }
    },

    async bannersInSectionCategory(
        { rootGetters, commit },
        payload: IdPageBannerType,
    ): Promise<ResponsePaged<PromotionsCategoriesModel[]>> {
        try {
            const { data } = await rootGetters['network/axios'].get(
                `v2/retailer/${payload.retailerId}/banners_in_section/${payload.id}/detail`,
                {
                    params: {
                        page: payload.page,
                    },
                },
            );
            return {
                ...data,
                results: data?.results?.map(toPromotionsCategoriesModel) ?? [],
            };
        } catch (err) {
            throw Error(`PROMOTION_BANNER_IN_SECTION_CATEGORY ${err}`);
        }
    },
    async bannersCategory(
        { rootGetters },
        payload: IdPageBannerType,
    ): Promise<ResponsePaged<PromotionsCategoriesModel[]>> {
        try {
            const { data } = await rootGetters['network/axios'].get(
                `v2/retailer/${payload.retailerId}/banners/${payload.id}/detail`,
                {
                    params: {
                        page: payload.page,
                    },
                },
            );
            return {
                ...data,
                results: data?.results?.map(toPromotionsCategoriesModel) ?? [],
            };
        } catch (err) {
            throw Error(`PROMOTION_BANNERS_CATEGORY ${err}`);
        }
    },

    async bannersCategoriesDetail(
        { rootGetters, commit },
        payload: RequestBannerDetail,
    ): Promise<Array<PromotionsCategoriesModel>> {
        try {
            const url = `v2/retailer/${rootGetters['retailers/retailerSelected'].id}/banners/${
                payload.id
            }/detail?page=${payload.page ?? 1}&items_size=${payload.itemsSize ?? 12}`;
            const { data } = await rootGetters['network/axios'].get(url);
            const subcategories =
                data['results']?.map((category: object) => toPromotionsCategoriesModel(category)) ?? [];
            if (data['next'] === null || data['next'] == undefined) commit('isLastPage', true);
            return subcategories;
        } catch (err) {
            throw Error(`CATEGORIES_BANNER_DETAIL ${err}`);
        }
    },

    async bannersCategoriesProducts({ rootGetters }, payload: RequestBannerProducts): Promise<object> {
        try {
            const url = `v2/retailers/${rootGetters['retailers/retailerSelected'].id}/banners/${
                payload.bannerId
            }/categories/${payload.categoryId}?page=${payload.page ?? 1}`;

            const { data } = await rootGetters['network/axios'].get(url);
            const products = data['results']?.map((product: object) => toProductModel(product)) ?? [];
            return {
                products: products,
                isLastPage: data['next'] == undefined || data['next'] == null,
            };
        } catch (err) {
            throw Error(`CATEGORIES_BANNER_PRODUCTS ${err}`);
        }
    },
    async bannersPromotions({ rootGetters }, payload: IdPageBannerType): Promise<ResponsePaged<ProductModel[]>> {
        try {
            const { data } = await rootGetters['network/axios'].get(`products_discount/${payload.id}/`, {
                params: {
                    page: payload.page,
                    retailer_id: payload.retailerId,
                },
            });
            return {
                ...data,
                results: data?.results?.map(toProductModel) ?? [],
            };
        } catch (err) {
            throw Error(`BANNERS_PROMOTIONS ${err}`);
        }
    },
    async bannersDiscountGoal({ rootGetters }, payload: BannersGoal): Promise<BannersGoalModel> {
        try {
            const _url = payload.isBannerInSection
                ? `v2/banners/${payload.id}/discount_goal?type=banner_in_section`
                : `v2/banners/${payload.id}/discount_goal?type=banner`;
            const { data } = await rootGetters['network/axios'].get(_url);
            return toBannersGoalModel(data);
        } catch (err) {
            throw Error(`BANNNERS_DISCOUNT_GOAL: ${err}`);
        }
    },
    async recipesBanner({ rootGetters, commit }, payload: IdPageBannerType): Promise<RecipeDetailModel> {
        try {
            const _url = payload.isBannerInSection
                ? `v2/retailer/${rootGetters['retailers/retailerSelected'].id}/banners_in_section/${payload.id}/detail`
                : `v2/retailer/${rootGetters['retailers/retailerSelected'].id}/banners/${payload.id}/detail`;
            const { data } = await rootGetters['network/axios'].get(_url);
            const _recipe = data['results'][0];
            commit('lists/recipeAds', _recipe, { root: true });
            commit('lists/recipeDetail', _recipe['recipe'], { root: true });
            return toRecipeDetailModel(_recipe['recipe']);
        } catch (err) {
            throw Error(`RECIPES_BANNER: ${err}`);
        }
    },
    async recipeStory({ rootGetters, commit }, idStory: number) {
        try {
            const _url = `v2/retailer/${rootGetters['retailers/retailerSelected'].id}/histories/${idStory}/detail`;
            const { data } = await rootGetters['network/axios'].get(_url);
            const _recipe = data['results'][0];
            commit('lists/recipeAds', _recipe, { root: true });
            commit('lists/recipeDetail', _recipe['recipe'], { root: true });
            return toRecipeDetailModel(_recipe['recipe']);
        } catch (err) {
            throw Error(`RECIPES_STORY: ${err}`);
        }
    },
};
