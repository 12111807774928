<template>
    <category-products :key="componentKey" class="see-more-body" :show-featured-products="false" />
</template>

<script lang="ts" setup>
import CategoryProducts from '@/components/category/categoryProducts/CategoryProducts.vue';
import { computed } from 'vue';
import { CategoryModel } from '@/models/product/CategoryModel';
import { useApp } from '@/composables/useApp';

const { $store } = useApp();

const categorySelected = computed<CategoryModel>(() => $store.getters['categories/getCategorySelected']);
const subcategorySelected = computed<CategoryModel>(() => $store.getters['categories/getSubcategorySelected']);
const componentKey = computed(() => subcategorySelected.value?.id ?? categorySelected.value?.id);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.see-more-body {
    padding: $padding-sm;
    @include tablet {
        padding: $padding-none $padding-sm;
    }
}
</style>
