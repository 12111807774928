<template>
    <section>
        <base-card class="payments flex-column" padding="0">
            <section-header
                class="payments__header"
                :title="$t('general.payment-methods')"
                :with-borders="!isMobile"
                bg-color="#FFF"
                btn-back-color="#FF9012"
                color-title="#FF9012"
                has-back
                @on-back="onBack"
            />
            <section class="payments-container" :class="{ 'show-one-payment': showPaymentOnMobile }">
                <div class="payments-container__options" :class="{ 'no-show-options': showPaymentOnMobile }">
                    <subheader-price class="checkout-payments__price" :total-to-pay="cart?.totalDetails?.totalCost" />
                    <payment-methods
                        :option-selected="optionSelected"
                        :payments="options"
                        :total-to-pay="cart?.totalDetails?.totalCost"
                    />
                </div>
                <div
                    class="payments-container__divider"
                    :class="{ 'payments-container__divider--hidden': showPaymentOnMobile }"
                ></div>
                <router-view />
            </section>
        </base-card>
        <teleport to="body">
            <section-footer class="payments__footer">
                <btn-out-line :is-disabled="!canContinue" :txt="$t('cta.confirm')" @click="checkPaymentAlert" />
            </section-footer>
        </teleport>
        <general-alert
            v-if="showWireTransferAlert"
            :message="$t('txt.transfer__once-your-order')"
            :title="$t('txt.my-order__wire-transfer')"
            assets-u-r-l="svg/order_state/ic_validating_wire_transfer.svg"
            only-confirmation-btn
            @on-accept="onBack"
            @on-close="showWireTransferAlert = false"
        />
        <general-alert
            v-if="showOnlineBankServiceAlert"
            :title="$t('general.payment-online-bank-services')"
            accent-header
            only-confirmation-btn
            @on-accept="onBack"
            @on-close="showOnlineBankServiceAlert = false"
        >
            <template #content>
                <section class="online-bank-services__alert-content">
                    <p>{{ $t('txt.online-bank-sevices__proccess-pyment-advisor') }}</p>
                    <p>{{ $t('txt.online-bank-sevices__modify-schedule-msj') }}</p>
                </section>
            </template>
        </general-alert>
    </section>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import { isMobileBrowser } from '@/utils/utils';
import BaseCard from '@/components/cards/BaseCard.vue';
import PaymentMethods from '@/components/paymentMethods/PaymentMethods.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import SubheaderPrice from '@/components/subheaders/subheaderPrice/SubheaderPrice.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import { useCheckoutEvents } from '@/views/checkout/composables/useCheckoutEvents';
import { useUser } from '@/composables/useUser';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'PaymentsCheckout',
    components: {
        BaseCard,
        PaymentMethods,
        SectionHeader,
        SectionFooter,
        SubheaderPrice,
        GeneralAlert,
        BtnOutLine,
    },
    emits: ['go-checkout'],
    setup() {
        const { viewPaymentsMethodScreen, selectPaymentsMethod } = useCheckoutEvents();
        viewPaymentsMethodScreen();
        const { alphaCode } = useUser();
        return {
            selectPaymentsMethod,
            alphaCode,
        };
    },
    data() {
        return {
            showWireTransferAlert: false,
            showOnlineBankServiceAlert: false,
            options: [
                {
                    title: 'Tipti Card',
                    code: 'tipti_card',
                    route: appRoutesMap.checkout.payments.tiptiCard,
                },
                {
                    title: this.$t("general['payment-cards']"),
                    code: 'credit_cards',
                    route: appRoutesMap.checkout.payments.bodyCredit,
                },
                { title: 'Payphone', code: 'payphone', route: appRoutesMap.checkout.payments.payphone },
                {
                    title: this.$t("general['payment-cash']"),
                    code: 'cash',
                    route: appRoutesMap.checkout.payments.cash,
                },
                {
                    title: this.$t("general['payment-wire-transfer']"),
                    code: 'wire_transfer',
                    route: appRoutesMap.checkout.payments.wireTransfer,
                },
                {
                    title: this.$t("general['payment-institutional']"),
                    code: 'institutional',
                    route: appRoutesMap.checkout.payments.institutional,
                },
            ],
            optionSelected: '',
        };
    },
    watch: {
        paymentsToShow(paymentsSelected: PaymentToShowModel[]) {
            if (!this.isMobile) return;
            if (!paymentsSelected.length) return;
            if (paymentsSelected[0]?.name === 'payment-yappy') return this.onBack();
            if (
                paymentsSelected.length > 1 &&
                paymentsSelected[0]?.name === 'payment-tipti-card' &&
                paymentsSelected[1]?.name === 'payment-yappy'
            )
                this.onBack();
        },
    },
    computed: {
        ...mapGetters({
            paymentsToShow: 'payments/paymentsToShow',
            cart: 'cart/cart',
            isMixPaymentTiptiCard: 'payments/isMixPaymentTiptiCard',
            isMixInstitutionalPayment: 'payments/isMixInstitutionalPayment',
            secondPayment: 'payments/secondPayment',
            cashWirePayment: 'payments/cashWirePayment',
            onlineBankServicePayment: 'payments/onlineBankServicePayment',
            retailerIdsWithtimeDelivery: 'checkout/retailerIdsWithtimeDelivery',
            baseConfiguration: 'user/baseConfiguration',
            countrySelected: 'external/countrySelected',
            userProfile: 'user/user',
        }),
        isMobile() {
            return isMobileBrowser();
        },
        canContinue(): boolean {
            if (this.paymentsToShow?.some((payment: PaymentToShowModel) => payment.idExpired)) return false;
            return (
                ((this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment) && this.paymentsToShow?.length > 1) ||
                (!this.isMixPaymentTiptiCard && !this.isMixInstitutionalPayment && this.paymentsToShow?.length)
            );
        },
        showPaymentOnMobile(): boolean {
            if (!this.isMobile) return;
            return [
                '/checkout/PaymentsCheckout/credit-cards',
                '/checkout/PaymentsCheckout/tipti-card',
                '/checkout/PaymentsCheckout/payphone',
                '/checkout/PaymentsCheckout/cash',
                '/checkout/PaymentsCheckout/wire-transfer',
                '/checkout/PaymentsCheckout/institutional',
                '/checkout/PaymentsCheckout/online-bank-services',
                '/checkout/PaymentsCheckout/nequi',
            ].includes(this.$route.path);
        },
        isOnlineBankServicesAvailable(): boolean {
            return this.baseConfiguration.onlineBankPaymentsEnabled;
        },
    },
    unmounted() {
        this.showWireTransferAlert = false;
        this.showOnlineBankServiceAlert = false;
    },
    async mounted() {
        if (this.isOnlineBankServicesAvailable)
            this.options.push({
                title: this.$t("general['payment-online-bank-services']"),
                code: 'online_bank_services',
                route: appRoutesMap.checkout.payments.onlineBankServices,
            });

        const isPanamaCountrySelected = this.alphaCode === 'PA';
        if (isPanamaCountrySelected) {
            this.options.push({
                title: 'Nequi',
                code: 'nequi',
                route: appRoutesMap.checkout.payments.nequi,
            });
            this.options.push({
                title: this.$t("general['payment-yappy']"),
                code: 'yappy',
                route: appRoutesMap.checkout.payments.yappy,
            });
        }
        await this.$store.dispatch('cart/fetchCart', { retailerIds: this.retailerIdsWithtimeDelivery });
    },
    methods: {
        onBack(): void {
            if (this.paymentsToShow.length) {
                const events = this.paymentsToShow.map((payment: PaymentToShowModel) => {
                    return this.selectPaymentsMethod({
                        selection: payment,
                    });
                });
                Promise.allSettled(events);
            }
            this.$router.go(-1);
        },
        checkPaymentAlert() {
            if (!this.canContinue) return;
            if (this.cashWirePayment?.wireTransfer) this.showWireTransferAlert = true;
            else if (this.onlineBankServicePayment) this.showOnlineBankServiceAlert = true;
            else this.onBack();
        },
    },
};
</script>

<style lang="scss" scoped>
@import './payments.scss';
</style>
