<template>
    <section
        class="category-body__search-banners"
        :class="{ 'category-body__search-banners--full': bannersLength === 0, 'fade-in': bannersLength !== 0 }"
    >
        <the-promotions-banner
            v-if="showPromotionalBanner"
            is-banner-in-section-prop
            @on-banners-fetch="onBannersFetch"
        />
    </section>
    <no-content-category v-if="hasContent" />
    <template v-else>
        <featured-products
            v-if="!discounted"
            :section-name="section"
            @go-to-featured-products="$emit('go-to-featured-products')"
        />
        <ThePaginator v-model="page" :total-pages="totalPages">
            <div
                v-for="subcategory in subcategoriesToShow[page - 1]"
                :key="subcategory.id"
                class="category-body__carousel"
            >
                <Suspense :key="page">
                    <TheCategoryCarousel
                        :category="categorySelected"
                        :subcategory="subcategory"
                        @go-to-subcategory="$emit('go-to-subcategory')"
                    />
                    <template #fallback>
                        <SkeletonCarousel />
                    </template>
                </Suspense>
            </div>
        </ThePaginator>
    </template>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { CategoryModel } from '@/models/product/CategoryModel';
import { Sections } from '@/enums/sectionsOrigin';
import FeaturedProducts from '../featuredProducts/FeaturedProducts.vue';
import ThePromotionsBanner from '@/components/banners/thePromotionsBanner/ThePromotionsBanner.vue';
import type { PropType } from 'vue';
import { computed, ref } from 'vue';
import { useProductFetcher } from '@/views/seeMore/useProductFetcher';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import ThePaginator from '@/modules/core/components/paginatinos/ThePaginator.vue';
import TheCategoryCarousel from '@/components/category/categoryWithSubcategories/TheCategoryCarousel.vue';
import { usePagination } from '@/modules/core/components/paginatinos/usePagination';
import SkeletonCarousel from '@/components/skeletons/skeletonCarousel/SkeletonCarousel.vue';
import NoContentCategory from '@/components/category/noContentCategory/NoContentCategory.vue';

defineOptions({
    name: 'CategoryWithSubcategories',
});

defineProps({
    section: {
        type: String as PropType<keyof typeof Sections>,
        default: undefined,
    },
    showPromotionalBanner: {
        type: Boolean,
        default: true,
    },
});
const $store = useStore();
const $emit = defineEmits<{
    (e: 'go-to-subcategory'): void;
    (e: 'go-to-featured-products');
    (e: 'on-banners-fetch', value: number);
}>();
const { retailerSelected } = useRetailer();
const { categorySelected, isDiscount: discounted } = useProductFetcher();
const { page, totalPages } = usePagination();

const promotionSubcategories = computed<CategoryModel[]>(() => $store.getters['categories/promotionSubcategories']);
const subcategoriesList = computed<CategoryModel[]>(() => $store.getters['categories/subcategoriesList']);

const chunkSize = 3;

page.value = 1;
const hasContent = ref(false);
const bannersLength = ref(0);
const subcategories = ref<CategoryModel[]>([]);
const subcategoriesToShow = ref<Array<CategoryModel[]>>([]);
const onBannersFetch = (length: number): void => {
    bannersLength.value = length;
    $emit('on-banners-fetch', length);
};

const fetchSubcategoriesCategoriesList = async (): Promise<void> => {
    try {
        if (discounted.value) {
            subcategories.value = promotionSubcategories.value.map((p) => ({
                name: p.name,
                id: p.id,
                hasSubcategories: p.hasSubcategories,
                icon: p.icon,
                internationalizedName: p.internationalizedName,
                isDeeplink: p.isDeeplink,
                isDiscount: p.isDiscount,
                isLastPage: p.isLastPage,
                products: p.products,
            }));
            $store.commit('categories/subcategoriesList', subcategories.value);
        } else
            subcategories.value = await $store.dispatch('categories/subcategoriesCategoriesList', {
                retailerId: retailerSelected.value?.id,
                categoryId: categorySelected.value?.id,
            });
        calculatePagesAndChunks();
    } catch (_) {
        hasContent.value = true;
    }
};

const calculatePagesAndChunks = () => {
    totalPages.value = Math.ceil((subcategories.value?.length ?? 0) / chunkSize);
    for (let i = 0; i < subcategories.value.length; i += chunkSize) {
        subcategoriesToShow.value.push(subcategories.value.slice(i, i + chunkSize));
    }
};

const setSubCategories = () => {
    if (!discounted.value && subcategoriesList.value.length) {
        subcategories.value = subcategoriesList.value;
        calculatePagesAndChunks();
        return;
    }
    fetchSubcategoriesCategoriesList();
};

setSubCategories();
</script>

<style lang="scss" scoped>
@import './category-with-subcategories.scss';
</style>
