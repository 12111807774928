import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e709e56c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "login-container"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header__text" }
const _hoisted_4 = { class: "login-inputs" }
const _hoisted_5 = { class: "login__forgot-password" }
const _hoisted_6 = { class: "login__actions" }
const _hoisted_7 = { class: "login__actions__btn-container" }
const _hoisted_8 = { class: "login__actions__to-register" }
const _hoisted_9 = { class: "social-header" }
const _hoisted_10 = { class: "social-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_circular_close = _resolveComponent("icon-circular-close")!
  const _component_tipti_input_text = _resolveComponent("tipti-input-text")!
  const _component_TiptiInputPassword = _resolveComponent("TiptiInputPassword")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_base_link = _resolveComponent("base-link")!
  const _component_separator_text = _resolveComponent("separator-text")!
  const _component_the_google = _resolveComponent("the-google")!
  const _component_the_facebook = _resolveComponent("the-facebook")!
  const _component_recovery_password = _resolveComponent("recovery-password")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$data.isRecoveryPassword)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('cta.log-in')), 1),
            _createVNode(_component_icon_circular_close, {
              class: "header__close",
              size: 0.8,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close')))
            })
          ]),
          _createElementVNode("form", {
            class: "login",
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => ($options.login && $options.login(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_tipti_input_text, {
                modelValue: $data.email,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.email) = $event)),
                "is-required": "",
                label: "email",
                "label-id": "email",
                placeholder: "Ingresa aquí tu e-mail"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_TiptiInputPassword, {
                modelValue: $data.password,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.password) = $event)),
                class: "password--sepator",
                errors: $data.errorLogin ? `${_ctx.$t('txt.login__user-password-incorrect')}` : '',
                "is-required": "",
                label: "contraseña",
                "label-id": "password",
                placeholder: "Ingresa aquí la contraseña para tu cuenta",
                type: "password"
              }, null, 8, ["modelValue", "errors"]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("a", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($data.isRecoveryPassword = true))
                }, _toDisplayString(_ctx.$t("txt['login__forget-your-password']")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_btn_solid, {
                  "is-loading": $data.isLoading,
                  txt: _ctx.$t('cta.log-in')
                }, null, 8, ["is-loading", "txt"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("txt['login__still-not-account']")), 1),
                _createVNode(_component_base_link, {
                  "txt-link": _ctx.$t('txt.login__enter-here'),
                  onClick: _withModifiers($options.switchToRegiter, ["prevent"])
                }, null, 8, ["txt-link", "onClick"])
              ]),
              _createVNode(_component_separator_text, {
                class: "option-separator",
                text: "o"
              }),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('general.general-login-with')), 1),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_the_google, { onOnSubmit: $options.loginGoogle }, null, 8, ["onOnSubmit"]),
                _createVNode(_component_the_facebook, { onClick: $options.loginFacebook }, null, 8, ["onClick"])
              ])
            ])
          ], 32)
        ]))
      : (_openBlock(), _createBlock(_component_recovery_password, {
          key: 1,
          onOnBack: $options.onBack
        }, null, 8, ["onOnBack"])),
    _createVNode(_component_snack_bar, {
      body: $data.snackBarMessage,
      "is-active": $data.showSnackBar,
      "is-failure": $data.isFailure,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[5] || (_cache[5] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}