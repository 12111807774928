<template>
    <div class="circle" :style="styleCircle"></div>
</template>

<script lang="ts">
export default {
    name: 'icon-circle',
    props: {
        size: {
            type: String,
            default: '25px',
        },
        color: {
            type: String,
            default: '25px',
        },
        margin: {
            type: String,
            default: '0',
        },
    },
    computed: {
        styleCircle(): object {
            return {
                height: this.size,
                width: this.size,
                'background-color': this.color,
                margin: this.margin,
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/index.scss';
.circle {
    border-radius: 50%;
    margin: $padding-none $padding-md;
    display: inline-block;
}
</style>
