import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08675dfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progress_status = _resolveComponent("progress-status")!
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("p", null, null, 512), [
      [
        _directive_currency,
        0,
        void 0,
        { noFix: true }
      ]
    ]),
    _createVNode(_component_progress_status, {
      "bg-color": $props.backgroundColor,
      progress: $props.progress
    }, null, 8, ["bg-color", "progress"]),
    _withDirectives(_createElementVNode("p", null, null, 512), [
      [_directive_currency, $props.minimumAmount]
    ])
  ]))
}