<template>
    <base-icon
        :icon-name="$t('icon.copy_to_clipboard')"
        :width="width"
        :height="height"
        viewBox="0 0 34 33"
        :iconColor="color"
    >
        <path
            d="M32.105 5.64964L27.8185 1.40829C25.9211 -0.459582 22.8403 -0.472196 20.9387 1.38727L16.1823 6.027C14.2777 7.88541 14.2693 10.9127 16.1613 12.7795L18.8796 10.1685C18.6042 9.33703 18.9437 8.3847 19.6164 7.72564L22.6426 4.77509C23.6013 3.84483 25.1391 3.84904 26.0851 4.7856L28.6594 7.32936C29.6054 8.26592 29.6012 9.77431 28.6489 10.7067L25.6226 13.6593C24.9825 14.2837 23.9313 14.6264 23.1157 14.4067L20.4531 17.0198C22.3483 18.8919 25.4271 18.9003 27.3328 17.0408L32.084 12.4053C33.9939 10.5437 34.0002 7.51961 32.105 5.64964Z"
        />
        <path
            d="M14.6901 22.6292C14.9161 23.4418 14.5545 24.4929 13.8996 25.132L11.0731 27.8933C10.104 28.8393 8.53566 28.8341 7.57072 27.8807L4.94497 25.2939C3.98213 24.3415 3.98844 22.7932 4.95759 21.8514L7.7862 19.09C8.47575 18.4205 9.46592 18.0799 10.33 18.3658L13.0566 15.6644C11.1267 13.7608 7.98592 13.7513 6.04762 15.6434L1.4615 20.1212C-0.478903 22.0164 -0.488363 25.0973 1.44048 27.0051L5.80691 31.3221C7.7347 33.2236 10.8734 33.2331 12.8159 31.3411L17.402 26.8632C19.3403 24.9659 19.3487 21.8871 17.423 19.9814L14.6901 22.6292Z"
        />
        <path
            d="M11.7077 21.3996C12.35 22.0482 13.4011 22.0545 14.056 21.408L21.8922 13.6128C22.545 12.9642 22.5492 11.9152 21.9006 11.2646C21.2563 10.6181 20.2072 10.6139 19.5566 11.2582L11.7182 19.0514C11.0665 19.6978 11.0623 20.7469 11.7077 21.3996Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-chains',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 34 * this.size;
        },
        height(): number {
            return 33 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
