import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.digital_documents'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 17 21",
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M15.8549 2.35114H15.2476V17.2712C15.2476 18.2624 14.4407 19.0692 13.4495 19.0692H7.29629H2.4032V19.6222C2.4032 20.2556 2.91723 20.7696 3.55056 20.7696H9.70163H15.8527C16.486 20.7696 17 20.2556 17 19.6222V3.4985C17.0001 2.86517 16.4882 2.35114 15.8549 2.35114Z",
        fill: $props.color ? $props.color : '#FF9012'
      }, null, 8, _hoisted_1),
      _createElementVNode("path", {
        d: "M7.29626 18.4185H13.4473C14.0807 18.4185 14.5947 17.9045 14.5947 17.2712V2.35111V1.14736C14.5947 0.514035 14.0807 0 13.4473 0H7.29626H5.25096V0.379562C5.25528 0.418602 5.25747 0.459812 5.25747 0.501021V3.00179V3.64379C5.25747 4.53522 4.53305 5.25964 3.64162 5.25964H2.99962H0.498852C0.466319 5.25964 0.431616 5.25747 0.399082 5.2553H0V17.2712C0 17.9045 0.514035 18.4185 1.14736 18.4185H2.40534H7.29626ZM7.52833 14.5188H3.51799C3.21868 14.5188 2.97576 14.2758 2.97576 13.9765C2.97576 13.6772 3.21868 13.4343 3.51799 13.4343H7.5305C7.82981 13.4343 8.07273 13.6772 8.07273 13.9765C8.07273 14.2758 7.82764 14.5188 7.52833 14.5188ZM11.5408 11.7512H3.51799C3.21868 11.7512 2.97576 11.5083 2.97576 11.209C2.97576 10.9097 3.21868 10.6668 3.51799 10.6668H11.5408C11.8402 10.6668 12.0831 10.9097 12.0831 11.209C12.0831 11.5083 11.8402 11.7512 11.5408 11.7512ZM3.51799 7.72787H11.5408C11.8402 7.72787 12.0831 7.97079 12.0831 8.2701C12.0831 8.56942 11.8402 8.81234 11.5408 8.81234H3.51799C3.21868 8.81234 2.97576 8.56942 2.97576 8.2701C2.97576 7.97079 3.21868 7.72787 3.51799 7.72787Z",
        fill: $props.color ? $props.color : '#FF9012'
      }, null, 8, _hoisted_2),
      _createElementVNode("path", {
        d: "M0.49888 4.60894H3.64165C3.64382 4.60894 3.64816 4.60894 3.65033 4.60894C4.17521 4.60461 4.60031 4.1795 4.60465 3.65462C4.60465 3.65245 4.60465 3.64811 4.60465 3.64594V0.501004C4.60465 0.210368 4.36607 0.017334 4.11883 0.017334C3.99954 0.017334 3.88025 0.0607125 3.78048 0.160483L0.15619 3.78475C-0.14746 4.0884 0.067264 4.60894 0.49888 4.60894Z",
        fill: $props.color ? $props.color : '#FF9012'
      }, null, 8, _hoisted_3)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "iconColor"]))
}