import { TiptiError } from '@/modules/core/errors/AppErrors';

interface DebugLogInput {
    /**
     * @description property to silent logs for api responses
     * @default true
     */
    logResult: boolean;
    /**
     * @description property to silent logs  for api errors
     * @default true
     */
    logFailure: boolean;
    /**
     * @default false
     */
    rethrowError: boolean;
    /**
     * @description property to silent logs with one key instead specify
     * ```
     * {
     *   logFailure: false,
     *   logResult: false
     * }
     * ```
     * @default true
     */
    useLogs: boolean;
}
export function WrapperResponse(payload?: Partial<DebugLogInput>) {
    return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;

        descriptor.value = async function (...args: unknown[]) {
            try {
                const result = await originalMethod.apply(this, args);
                if (
                    (payload?.useLogs ?? true) &&
                    (payload?.logResult ?? true) &&
                    ['DEV', 'STG'].includes(process.env.VUE_APP_API_ENV)
                ) {
                    // eslint-disable-next-line no-console
                    console.info('this log is showed only no production environments');
                    // eslint-disable-next-line no-console
                    console.table(result);
                }
                return result;
            } catch (error) {
                if (
                    (((payload?.useLogs ?? true) && payload?.logFailure) ?? true) &&
                    ['DEV', 'STG'].includes(process.env.VUE_APP_API_ENV)
                ) {
                    const errorName = error.constructor?.name ?? 'UnknownError';

                    const { name } = target.constructor;

                    const isAppError = error instanceof TiptiError;
                    let errorMessage;
                    if (isAppError) {
                        errorMessage = (error as TiptiError).trackerError;
                    } else {
                        errorMessage = error.message ?? error ?? 'Unknown Error';
                    }

                    // eslint-disable-next-line no-console
                    console.error(
                        `CUSTOM LOGGER => CAUGHT ${errorName}`,
                        `\nWHERE ${name ?? ''} - INVOKING ${propertyKey}\n`,
                        errorMessage,
                    );
                }
                if (!payload?.rethrowError) return;
                throw error;
            }
        };
    };
}
