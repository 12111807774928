import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ce52d44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-normal" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = ["name", "id", "value"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "input__required" }
const _hoisted_6 = {
  key: 0,
  class: "input-normal__required"
}
const _hoisted_7 = {
  key: 1,
  class: "input-normal__required"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        name: $props.label,
        id: $props.label,
        type: "date",
        value: $options.formatInputDate($props.modelValue),
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.valueAsDate)))
      }, null, 40, _hoisted_3),
      _createElementVNode("label", {
        class: "input-label",
        for: $props.label
      }, [
        _createTextVNode(_toDisplayString(`${$props.labelName}`), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(`${$options.requiredField}`), 1)
      ], 8, _hoisted_4)
    ]),
    ($props.isEmptyAndRequired)
      ? (_openBlock(), _createElementBlock("p", _hoisted_6, "* " + _toDisplayString(_ctx.$t("general['required-fields']")), 1))
      : ($props.isInvalid)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, "* " + _toDisplayString(_ctx.$t("general['invalid-fields']")), 1))
        : _createCommentVNode("", true)
  ]))
}