<template>
    <button class="tipti-prime-card" @click="goToPrime">
        <i>
            <IconStarPrime :size="1">
                <template #extra></template>
            </IconStarPrime>
        </i>
        <span class="tipti-prime-card__text">{{ $t('general.tipti_card_prime_benefits') }}</span>
        <IconPrime class="tipti-prime-card__icon-prime" :size="1.8" color="white" />
    </button>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import { appRoutesMap } from '@/router/appRoutesMap';
import IconStarPrime from '@/components/icons/IconStarPrime.vue';
import IconPrime from '@/components/icons/IconPrime.vue';

export default {
    name: 'TiptiPrimeCard',
    components: { IconStarPrime, IconPrime },
    computed: {
        ...mapGetters({
            profile: 'user/user',
            isAuth: 'user/isAuth',
        }),
    },
    methods: {
        goToPrime() {
            if (!this.isAuth) return this.$store.commit('user/requireAuth', true);
            this.$router.push({ name: appRoutesMap.account.tiptiPrime });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.tipti-prime-card {
    all: unset;
    display: flex;
    align-items: center;
    background-color: $black;
    padding: $padding-sm $padding-md;
    border-radius: 8px;
    justify-content: space-between;
    cursor: pointer;
    height: 1.9rem;

    &__text {
        @include body2;
        font-size: 12px;
        color: $white;
        font-weight: 600;
    }
}
</style>
