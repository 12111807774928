<template>
    <div v-if="isAuth" class="cart-button">
        <base-btn
            v-if="isMainCart"
            class="the-cart-btn-base the-cart-btn"
            :class="{ 'the-cart-btn--empty': cartAmount === 0 }"
            bg-color="#FF9012"
            border-radius="7px"
            color="#FFF"
            padding=".5rem 0"
            @click="activateCart"
        >
            <div>
                <icon-cart v-if="personalCartId === cart?.id" :size="0.7" color="white" />
                <icon-shared-cart v-else :size="0.8" color="white" />
            </div>
            <p v-if="cartAmount > 0" class="the-cart-btn__amount">{{ cartAmount }}</p>
        </base-btn>
        <button
            v-if="isExtraItemCart"
            class="extra-item-cart"
            name="extra-items cart"
            type="button"
            @click="activateCart"
        >
            <div class="extra-item-cart__amount">
                <icon-add-cart :size="0.8" color="#fff" />
                <p class="the-cart-btn__amount extra-item-cart__amount_text">
                    {{ extraItemsProductsCount }} / {{ order?.numberOfExtraItemsAllowed ?? 10 }}
                </p>
            </div>
            <span class="extra-item-cart__name">{{ $t('txt.added_cart') }}</span>
        </button>
    </div>
    <div v-else class="cart-button">
        <base-btn
            class="the-cart-btn-base the-cart-register-btn"
            bg-color="#FF9012"
            border-radius="7px"
            color="#FFF"
            padding=".5rem 0"
            @click="goToRegister"
        >
            <div>{{ $t("txt['register__register-register']") }}</div>
            <p v-if="cartAmount > 0" class="the-cart-btn__amount">{{ cartAmount }}</p>
        </base-btn>
    </div>
</template>

<script lang="ts" setup>
import IconCart from '@/components/icons/IconCart.vue';
import IconSharedCart from '@/components/icons/IconSharedCart.vue';
import BaseBtn from '@/components/buttons/BaseBtn.vue';
import IconAddCart from '@/components/icons/IconAddCart.vue';
import { useCart } from '@/composables/useCart';
import { useUser } from '@/composables/useUser';
import { computed, watch } from 'vue';
import { useExtraItems } from '@/modules/extraItems/composables/useExtraItems';
import { useApp } from '@/composables/useApp';
import { useRoutes } from '@/composables/useRoutes';

const { isAuth } = useUser();
const { order } = useExtraItems();
const { isMainCart, isExtraItemCart, showCart, cart, personalCartId, cartAmount } = useCart();
const { $store } = useApp();
const extraItemsProductsCount = computed(() => $store.getters['extraItems/products']?.length ?? 0);
const { isScreenCheckout } = useRoutes();

const activateCart = async () => {
    $store.commit('product/productIdActiveBlur', null);
    if (!showCart.value && !isScreenCheckout.value && isMainCart.value) {
        $store.commit('checkout/cleanTimeDelivery');
        $store.commit('checkout/resetStockItemTimeDelivery');
    }
    $store.commit('cart/showCart', !showCart.value);
};
const goToRegister = async () => $store.commit('user/requireAuth', true);

watch(
    () => showCart.value,
    () => {
        $store.dispatch('external/updateCareTeamChatByCountry');
    },
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-cart-btn-base {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    column-gap: $margin-md;
    text-align: center;
    white-space: nowrap;
}

.the-cart-register-btn {
    height: 2.9rem;
    grid-template-columns: 1fr;
}

.the-cart-btn {
    &--empty {
        grid-template-columns: 1fr;
    }
}

.the-cart-btn__txt {
    @include button;
    width: 100%;
    color: $orange;
    border-radius: 2rem;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
}

.the-cart-btn__amount {
    @include button;
    color: $orange;
    border-radius: $radius-md;
    padding: $padding-xsm $padding-md;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 14px;
}

.cart-button {
    position: relative;
}

.cart-button__tutorial {
    position: absolute;
    top: 3.5rem;
    right: 0;
}

.cart-button--index {
    z-index: 4;
}

.modal-bg {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    cursor: pointer;
    z-index: 3;
    transition: all 1s;
}

.modal-bg--display {
    display: none;
}

.extra-item-cart {
    all: unset;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $orange;
    padding: $padding-xsm $padding-sm;
    margin: 0 auto;

    &__amount {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        justify-content: center;
        align-content: center;
        align-items: center;
        justify-items: center;
    }

    &__amount_text {
        display: flex;
        white-space: nowrap;
        height: 1.2rem;
        font-weight: 600;
    }

    &__name {
        @include button;
        color: $white;
        font-size: 12px;
    }
}
</style>
