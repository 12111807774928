export const enum CheckoutEventTypes {
    OpenCheckoutAddress = 'Open checkout address',
    OpenCheckoutSchedule = 'Open checkout schedule',
    SelectNewScheduleOnCheckout = 'Select new schedule on checkout',
    SaveNewScheduleOnCheckout = 'Save new schedule on checkout',
    ViewPaymentsMethodScreen = 'View payments method screen',
    SelectTiptiCardScreen = 'Select tipticard screen',
    SelectCreditCardScreen = 'Select creditcard screen',
    SelectPayphoneScreen = 'Select payphone screen',
    SelectCashScreen = 'Select cash screen',
    SelectWireTransferScreen = 'Select wire transfer screen',
    SelectInstitutionalPaymentScreen = 'Select institutional payment screen',
    SelectOnlineScreen = 'Select online screen',
    SelectNequiScreen = 'Select neequi screen',
    SelectYappiScreen = 'Select yappi screen',
    SelectTiptiCardAsPaymentMethod = 'Select tipticard as payment method',
    SelectCreditCardAsPaymentMethod = 'Select creditcard as payment method',
    SaveNewCardSuccessfully = 'Save new card successfully',
    SaveNewCardFailed = 'Save new card failed',
    OpenNewCardForm = 'Open new card form',
    SelectPayphoneAsPaymentMethod = 'Select payphone as payment method',
    SelectCashAsPaymentMethod = 'Select cash as payment method',
    SelectWireTransferAsPaymentMethod = 'Select wire transfer as payment method',
    SelectInstitutionalAsPaymentMethod = 'Select institutional as payment method',
    SelectOnlineAsPaymentMethod = 'Select online as payment method',
    SelectNequiAsPaymentMethod = 'Select nequi as payment method',
    SelectYappiAsPaymentMethod = 'Select yappi as payment method',
}
