<template>
    <header class="settings-header">
        <div class="settings-header__user-info">
            <h3 class="settings-header__user-info__name">
                {{ profile.name }}
            </h3>
            <p class="settings-header__user-info__details">{{ profile.email }}</p>
            <p class="settings-header__user-info__details">ID: {{ profile.id }}</p>
        </div>
        <h3 class="settings-header__title">
            {{ $t('txt.setting__title') }}
        </h3>
    </header>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

export default {
    name: 'settings-header',
    computed: {
        ...mapGetters({ profile: 'user/user' }),
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.settings-header {
    display: flex;
    @include tablet {
        display: none;
    }
    &__title {
        @include subtitle2;
        font-size: 18px;
        font-weight: 600;
        margin: auto $margin-none;
        padding: $padding-sm $padding-md * 2;
        border-bottom: 4px solid $orange;
    }
    &__user-info {
        width: 25rem;
        text-align: center;
        background-color: $orange;
        padding: $padding-sm;
        border-top-left-radius: $radius-lg;
        box-sizing: border-box;
        &__name {
            @include subtitle1;
            color: $white;
            font-weight: 600;
            font-size: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__details {
            @include body2;
            color: $white;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
