<template>
    <h4>{{ $t('general.products-general') }}</h4>
    <div
        v-for="result in productsGlobalSearch"
        :key="result.product_name"
        class="autocomplete-products-global cursor-pointer"
        @click="$emit('on-select-product', result?.match_phrase, result?.retailer_id)"
    >
        <img v-if="result?.product_picture" :src="result.product_picture" alt="" @error="displayDefaultImg" />
        <icon-tipti v-else :size="0.4" />
        <p v-html="boldQuery(result?.product_name, result?.match_phrase)"></p>
        <img
            v-if="result?.retailer_logo?.length"
            class="autocomplete-products-global__img-retailer"
            :alt="result.retailer_name"
            :src="result.retailer_logo"
            @error="displayDefaultImg"
        />
    </div>
</template>

<script lang="ts" setup>
import IconTipti from '@/components/icons/IconTipti.vue';
import { useImage } from '@/composables/ImageComposable';
import { GlobalSearchProductModel } from '@/models/product/ProductModelAdapters.ts';

defineProps({
    productsGlobalSearch: {
        type: Array<GlobalSearchProductModel>,
        required: true,
    },
});

const { displayDefaultImg } = useImage();

defineEmits<(e: 'on-select-product', value: string, value2: number) => void>();
const boldQuery = (productName: string, query: string): string => {
    return productName
        ?.toLowerCase()
        ?.replaceAll(query.toLowerCase(), `<span style="font-weight:600">${query.toLowerCase()}</span>`);
};
</script>

<style lang="scss" scoped>
@import './autocomplete-products-global-search.scss';
</style>
