<template>
    <section ref="descriptionWrapper" v-if="product.descriptionProduct?.trim()?.length" v-bind="$attrs">
        <header>
            <icon-info :size="0.8" color="#707070" />
            <h3 class="nutritional-information-header__title">{{ $t("general['description']") }}</h3>
        </header>
        <div
            class="nutrition-description"
            :class="{
                'nutrition-description--btn': !showBtnAll,
            }"
        >
            <aside v-html="product.descriptionProduct"></aside>
        </div>
        <div
            v-for="chip in nutritionalColorOrdered"
            :key="chip.type"
            class="nutrition-chip"
            :class="`nutrition-chip--${chip.color}`"
        >
            <p class="nutrition-chip__level">{{ chip.level }}</p>
            <p class="nutrition-chip__type">Total {{ chip.type }}</p>
        </div>
        <div ref="productDescriptionRef" v-if="showBtnAll"></div>
    </section>
    <BtnText v-if="showBtnAll" class="show-all-btn" :text="textAction" @click.stop="showAllToggle" />
</template>
<script lang="ts" setup>
import { ProductModel } from '@/models/product/ProductModel';
import IconInfo from '@/components/icons/IconInfo.vue';
import { computed, onMounted, PropType, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useIntersectionObserver } from '@vueuse/core';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import BtnText from '@/components/buttons/BtnText.vue';

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    product: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
});
const productDescriptionRef = ref<HTMLImageElement>();
const descriptionWrapper = ref<HTMLImageElement>();
const targetIsVisible = ref(false);
const store = useStore();
const { t: $t } = useI18n();
const route = useRoute();
const showAll = ref(false);
const showBtnAll = ref(false);
const textAction = computed(() => (!showAll.value ? $t('cta.see-more') : $t('cta.see-less')));

onMounted(() => {
    if (!props.product.descriptionProduct) return;
    showAll.value = descriptionWrapper.value?.clientHeight <= 100;
    showBtnAll.value = descriptionWrapper.value?.clientHeight > 100;
    if (descriptionWrapper.value) descriptionWrapper.value.style.maxHeight = '100px';
});

const { stop } = useIntersectionObserver(productDescriptionRef, ([{ isIntersecting }]) => {
    targetIsVisible.value = isIntersecting;
});

const showAllToggle = () => {
    showAll.value = !showAll.value;
    if (!descriptionWrapper.value) return;
    descriptionWrapper.value.style.maxHeight = showAll.value ? 'unset' : '100px';
};

watchEffect(() => {
    if (!targetIsVisible.value) return;
    stop();
    store.dispatch('segment/viewProductDetail', {
        screen: route.path,
    });
});

const nutritionalColorOrdered = computed(() => {
    const colorsOrdered = [];
    ['RED', 'YELLOW', 'GREEN'].forEach((color: string) => {
        for (const type in props.product.nutritionColors) {
            if (props.product.nutritionColors[type] !== color) continue;
            colorsOrdered.push({
                level:
                    'RED' === props.product.nutritionColors[type]
                        ? $t("general['high']")
                        : 'YELLOW' === props.product.nutritionColors[type]
                          ? $t("general['medium']")
                          : $t("general['low']"),
                color: props.product.nutritionColors[type].toLowerCase(),
                type:
                    type.toUpperCase() === 'SALT'
                        ? $t("general['salt']")
                        : type.toUpperCase() === 'SUGAR'
                          ? $t("general['sugar']")
                          : $t("general['fat']"),
            });
        }
    });
    return colorsOrdered;
});
</script>

<style lang="scss">
@import './the-nutritional-information.scss';
</style>
