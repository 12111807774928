import { isMobileBrowser } from '@/utils/utils';

class AdModel {
    readonly id: number;
    readonly name: string;
    readonly picture: string;
    readonly productsNumber: number;
    readonly recipeId: number;
    isFavorite: boolean;
    readonly deeplink: string;
    ///** TODO implement isfavorite by default value will be false
    ///** the value of false by default will be changed to the value returned by the request

    constructor(
        id: number,
        name: string,
        picture: string,
        productsNumber: number,
        recipeId: number,
        isFavorite: boolean,
        deeplink: string,
    ) {
        this.id = id;
        this.name = name;
        this.picture = picture;
        this.productsNumber = productsNumber;
        this.recipeId = recipeId;
        this.isFavorite = isFavorite;
        this.deeplink = deeplink;
    }
}

const picture = (data: object) => (isMobileBrowser() ? data['picture_for_mobile_web'] : data['picture_for_web']);

const toAdModel = (data: object): AdModel => {
    return data
        ? new AdModel(
              data['id'],
              data['name'],
              picture(data),
              data['recipe_items_number'],
              data['recipe_id'] ?? data['recipe']?.['id'] ?? data['recipe'],
              false,
              data['deep_link'],
          )
        : null;
};
export { AdModel, toAdModel };
