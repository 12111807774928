import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, renderList as _renderList, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-743e3810"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-information" }
const _hoisted_2 = { class: "product-information__main" }
const _hoisted_3 = { class: "product-information__name" }
const _hoisted_4 = {
  key: 0,
  class: "indicator-delivery"
}
const _hoisted_5 = {
  key: 1,
  class: "indicator-item"
}
const _hoisted_6 = { class: "product-information__unit-information" }
const _hoisted_7 = { class: "product-information__detail" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "deferred-price__header" }
const _hoisted_10 = { class: "deferred-price__title" }
const _hoisted_11 = { class: "deferred-price" }
const _hoisted_12 = { class: "deferred-price__month" }
const _hoisted_13 = {
  class: "deferred-price__price",
  type: "INLINE"
}
const _hoisted_14 = { class: "deferred-price__reference" }
const _hoisted_15 = { class: "alert_legend" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductImages = _resolveComponent("ProductImages")!
  const _component_the_nutritional_information = _resolveComponent("the-nutritional-information")!
  const _component_product_indicators = _resolveComponent("product-indicators")!
  const _component_ProductPrice = _resolveComponent("ProductPrice")!
  const _component_TheQuantityModifier = _resolveComponent("TheQuantityModifier")!
  const _component_skeleton_attributes = _resolveComponent("skeleton-attributes")!
  const _component_product_attributes = _resolveComponent("product-attributes")!
  const _component_SecondaryProductActions = _resolveComponent("SecondaryProductActions")!
  const _component_IconPaymentPlanes = _resolveComponent("IconPaymentPlanes")!
  const _component_the_note = _resolveComponent("the-note")!
  const _component_the_my_lists = _resolveComponent("the-my-lists")!
  const _component_snack_bar = _resolveComponent("snack-bar")!
  const _component_icon_add_cart = _resolveComponent("icon-add-cart")!
  const _component_tipti_alert = _resolveComponent("tipti-alert")!
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("article", _hoisted_1, [
      _createElementVNode("section", null, [
        (_ctx.product)
          ? (_openBlock(), _createBlock(_component_ProductImages, {
              key: 0,
              product: _ctx.product,
              zoom: _ctx.sectionShowed === 'ZOOM',
              onCloseZoom: _cache[0] || (_cache[0] = (value) => (_ctx.sectionShowed = value ? 'ZOOM' : 'MAIN'))
            }, null, 8, ["product", "zoom"]))
          : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_component_the_nutritional_information, {
          key: _ctx.product.id,
          class: "the-nutritional-information",
          product: _ctx.product
        }, null, 8, ["product"]))
      ]),
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.product.name), 1),
        (_ctx.product.hoursToDelivery && _ctx.product.uniqueDelivery)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('txt.unique_delivery_info_message', {
                        count: _ctx.product.hoursToDelivery,
                    })), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_product_indicators, {
          indicators: _ctx.product.indicators
        }, null, 8, ["indicators"]),
        (_ctx.product.carry && _ctx.product.pay)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.getMultiplierTag(_ctx.product)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.product.unitQuantity) + " " + _toDisplayString(_ctx.product.unit) + " " + _toDisplayString(_ctx.product.conversionGramsAndKilogramsToPounds), 1),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_ProductPrice, { product: _ctx.product }, null, 8, ["product"]),
          (_openBlock(), _createBlock(_component_TheQuantityModifier, {
            key: _ctx.product.id,
            "is-loading": $setup.isLoading,
            product: _ctx.product,
            onOnMainAction: $options.handleActionProduct,
            onOnChangedQuantity: $setup.onChangedQuantity
          }, null, 8, ["is-loading", "product", "onOnMainAction", "onOnChangedQuantity"])),
          (_ctx.productSelected.groupId)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (!$data.attributes?.attribute)
                  ? (_openBlock(), _createBlock(_component_skeleton_attributes, { key: 0 }))
                  : (_openBlock(), _createBlock(_component_product_attributes, {
                      key: 1,
                      "attribute-order": 1,
                      "attribute-values": $data.attributes?.attribute
                    }, null, 8, ["attribute-values"]))
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.sectionShowed !== 'ADD_TO_LIST')
            ? (_openBlock(), _createBlock(_component_SecondaryProductActions, {
                key: 1,
                "is-favorite": _ctx.isFavorite,
                product: _ctx.product,
                onOnClick: _ctx.onActionClick
              }, null, 8, ["is-favorite", "product", "onOnClick"]))
            : _createCommentVNode("", true)
        ]),
        false
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (_ctx.showDeferredOption && _ctx.sectionShowed !== 'ADD_TO_LIST')
                ? (_openBlock(), _createElementBlock("section", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_IconPaymentPlanes),
                      _createElementVNode("p", _hoisted_10, _toDisplayString($setup.t('txt.payment_facilities')), 1)
                    ]),
                    _createElementVNode("section", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deferredValues, (option) => {
                        return (_openBlock(), _createElementBlock("article", {
                          key: option.value,
                          class: "deferred-price__option"
                        }, [
                          _createElementVNode("p", _hoisted_12, _toDisplayString(option.months), 1),
                          _withDirectives(_createElementVNode("p", _hoisted_13, null, 512), [
                            [
                              _directive_currency,
                              option.value,
                              void 0,
                              { noSpaced: true }
                            ]
                          ])
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("p", _hoisted_14, "* " + _toDisplayString($setup.t('txt.reference_fee')), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.sectionShowed === 'ADD_NOTE')
          ? (_openBlock(), _createBlock(_component_the_note, {
              key: _ctx.product.id,
              quantity: $setup.quantity,
              section: $props.section
            }, null, 8, ["quantity", "section"]))
          : _createCommentVNode("", true),
        (_ctx.sectionShowed === 'ADD_TO_LIST')
          ? (_openBlock(), _createBlock(_component_the_my_lists, {
              key: 4,
              section: "PRODUCT_DETAIL",
              onOnFailure: _ctx.onFailure,
              onOnSuccess: _ctx.onSuccess,
              onOnBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sectionShowed = 'ADD_NOTE'))
            }, null, 8, ["onOnFailure", "onOnSuccess"]))
          : _createCommentVNode("", true),
        _createVNode(_component_the_nutritional_information, {
          class: "the-nutritional-information the-nutritional-information--mobile",
          product: _ctx.product
        }, null, 8, ["product"])
      ])
    ]),
    _createVNode(_component_snack_bar, {
      body: $setup.showSnackBarInfo.message,
      "is-active": $setup.showSnackBarInfo.showSnackBar,
      "is-failure": $setup.showSnackBarInfo.isFailure,
      "is-success": $setup.showSnackBarInfo.isSuccess,
      onOnSnackbarClose: $setup.closeSnackBar
    }, null, 8, ["body", "is-active", "is-failure", "is-success", "onOnSnackbarClose"]),
    _createVNode(_component_tipti_alert, {
      "alert-information": $data.alertData,
      "show-alert": $data.showAskForExtraItemsModal,
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showAskForExtraItemsModal = false)),
      onOnMainAction: _cache[3] || (_cache[3] = ($event: any) => ($options.addInOrder(true))),
      onOnSecondaryAction: _cache[4] || (_cache[4] = ($event: any) => ($options.addInOrder(false)))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_icon_add_cart, {
          size: 2,
          color: "#ff9012"
        })
      ]),
      moreContent: _withCtx(() => [
        _createElementVNode("p", _hoisted_15, _toDisplayString($setup.t('txt.extra_item_limit', { limit: _ctx.order.numberOfExtraItemsAllowed })), 1)
      ]),
      _: 1
    }, 8, ["alert-information", "show-alert"])
  ], 64))
}