<template>
    <base-icon :icon-name="$t('icon.whatsapp')" :width="width" :height="height" viewBox="0 0 27 27" :iconColor="color">
        <path
            d="M13.5 0C6.02679 0 0 6.02679 0 13.5C0 16.2121 0.783482 18.683 2.16964 20.7924L0.964286 26.5179L5.96652 24.6496C8.13616 26.096 10.7277 27 13.5603 27C21.0335 27 27.0603 20.9732 27.0603 13.5C27 6.02679 20.9732 0 13.5 0ZM22.1786 18.0804C21.5156 19.346 20.1295 20.3103 18.8036 20.6116C16.3929 21.0335 13.0179 19.4665 10.3058 17.2969C7.53348 15.1272 5.2433 12.1741 5.12277 9.76339C5.12277 8.4375 5.78571 6.81027 6.81027 5.90625C8.73884 4.27902 10.8482 7.35268 11.1496 7.95536C11.8125 8.91964 10.9688 9.70312 10.3058 10.5469C9.28125 11.8125 10.4866 13.3192 12.2946 14.7656C14.1027 16.2121 15.8504 16.9955 16.875 15.7299C17.5379 14.8862 18.0804 13.9219 19.1652 14.3438C19.8281 14.4643 23.3237 15.8504 22.1786 18.0804Z"
            fill="white"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-whatsapp-two',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 27 * this.size;
        },
        height(): number {
            return 27 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
