<script lang="ts" setup>
import { useWallet } from '@/views/wallet/useWallet';
import { useRouter } from 'vue-router';
import { GiftCardRetailerWithProductsViewModel } from '@/views/wallet/WalletModels';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';
import CardProduct from '@/components/cards/cardProduct/CardProduct.vue';
import IconBackNoBorder from '@/components/icons/IconBackNoBorder.vue';
import IconWatchOutlined from '@/components/icons/IconWatchOutlined.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { computed, PropType, provide } from 'vue';
import { appRoutesMap } from '@/router/appRoutesMap';

const props = defineProps({
    retailer: {
        type: Object as PropType<GiftCardRetailerWithProductsViewModel>,
        required: true,
    },
});

const $router = useRouter();

const { giftCardRetailerSelected } = useWallet();
const { retailerSelected, allRetailers } = useRetailer();

const retailOfCard = computed(() => allRetailers.value.find((ret) => ret.id === props.retailer.id));

provide('retailKey', retailOfCard.value);
const goToSelctedRetailer = () => {
    retailerSelected.value = retailOfCard.value;
    giftCardRetailerSelected.value = props.retailer;
    return $router.push({
        name: appRoutesMap.giftCardSelectedRetailer,
    });
};
</script>

<template>
    <BaseCardFlat class="gift-card-detail-retailers" border-color="#DFDFDF" padding="0rem">
        <div class="gift-card-detail-retailers__header">
            <img
                class="gift-card-detail-retailers__header__img"
                :alt="retailer.name"
                :src="retailer.imgUrl"
                loading="lazy"
            />
            <div class="gift-card-detail-retailers__header__info">
                <h3 class="gift-card-detail-retailers__header__title" @click="goToSelctedRetailer">
                    {{ retailer.name }}
                </h3>
                <div class="gift-card-detail-retailers__header__info__delivery-time">
                    <IconWatchOutlined />
                    <p class="gift-card-detail-retailers__header__info__delivery-time__text">
                        {{ retailer.deliveryRange }}
                    </p>
                </div>
            </div>
            <icon-back-no-border
                class="gift-card-detail-retailers__header__arrow-next cursor-pointer"
                :icon-name="$t('icon.forward')"
                :size="0.9"
                background-fill="#FFF4E7"
                color="#FF9012"
                @click="goToSelctedRetailer"
            />
        </div>
        <carousel-tipti
            class="gift-card-detail-retailers__carousel"
            :length="retailer.products.length"
            has-arrow-button
        >
            <card-product
                v-for="product in retailer.products"
                :key="product?.id"
                class="gift-card-detail-retailers__product"
                :product-data="product"
            />
        </carousel-tipti>
    </BaseCardFlat>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.gift-card-detail-retailers__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: $margin-lg 2 * $margin-md;

    &__header {
        &__title {
            @include headline6;
            text-align: center;
            margin-bottom: $margin-md;
            color: $orange;
            font-weight: 500;
        }

        &__description {
            @include body2;
            text-align: center;
        }
    }
}

.gift-card-detail-retailers {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
        padding: $padding-md;
        gap: 1rem;

        &__img {
            object-fit: cover;
            height: 80px;
            width: 150px;
            border-radius: 5px;
        }

        &__info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            &__delivery-time {
                background-color: $bright-canvas;
                width: fit-content;
                display: flex;
                padding: $padding-sm;
                border-radius: 8px;

                &__text {
                    @include caption;
                    margin: 0 0 0 $margin-sm;
                }
            }
        }

        &__title {
            @include subtitle1;
        }

        &__arrow-next {
            transform: rotate(180deg);
            @include box-glow;
        }
    }

    &__carousel {
        max-width: calc(100svw - 2 * $margin-md - 10rem);
        @include tablet {
            max-width: calc(100svw - $margin-md);
        }
    }

    &__product {
        width: 12rem;
        height: 100%;
        @include tablet {
            width: 10rem;
        }
        @include phone {
            width: 8rem;
        }
    }
}
</style>
