import { CartBarGoals } from '@/models/cart/CartBarGoals';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { ProductModel } from '@/models/product/ProductModel';
import type { CartMedicalPrescription } from '@/models/cart/CartMedicalPrescription';

const state = {
    cart: null,
    cartAmount: 0,
    products: [],
    personalCartId: null,
    showCart: false,
    lastProductsAdded: [],
    cartBarGoal: CartBarGoals,
    cardGoal: undefined,
    showCartBarAlert: false,
    productWithSpecialDelivery: [] as Array<ProductModel>,
    listFilesPrescriptions: [],
    itemsPrescription: [] as CartMedicalPrescription[],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
