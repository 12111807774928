<template>
    <div class="loader" :class="{ 'loader__margin-auto': isMarginAuto }" :style="loadingStyle"></div>
</template>

<script>
export default {
    name: 'base-loading',
    props: {
        color: {
            type: String,
            default: '#FF9012',
        },
        width: {
            type: String,
            default: '2em',
        },
        heigth: {
            type: String,
            default: '2em',
        },
        isMarginAuto: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        loadingStyle() {
            return {
                '--loading-color': this.color,
                '--loading-width': this.width,
                '--loading-height': this.heigth === this.width ? this.heigth : this.width,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.loader,
.loader:after {
    border-radius: 50%;
    min-width: var(--loading-width);
    min-height: var(--loading-height);
    max-width: var(--loading-width);
    max-height: var(--loading-height);
}
.loader {
    font-size: 10px;
    position: relative;
    border-top: 0.6em solid rgba(255, 255, 255, 0.2);
    border-right: 0.6em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.6em solid rgba(255, 255, 255, 0.2);
    border-left: 0.6em solid var(--loading-color);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load 1.1s infinite linear;
    animation: load 1.1s infinite linear;
    &__margin-auto {
        margin: $margin-sm auto;
    }
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
