<script lang="ts" setup>
import { useRoute } from 'vue-router';
import TheNewsDetail from '@/views/newsPress/pages/TheNewsDetail.vue';
import SkeletonDetailNews from '@/views/newsPress/components/SkeletonDetailNews.vue';

const $route = useRoute();
</script>

<template>
    <Suspense :key="$route.fullPath">
        <TheNewsDetail />
        <template #fallback>
            <SkeletonDetailNews />
        </template>
    </Suspense>
</template>
