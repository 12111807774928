import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import { ProductModel, toProductModel } from './ProductModel';

class UnavailableProductsByHomologationModel {
    retailer: RetailerModel;
    items: Array<ProductModel>;
    approvedToContinue: boolean;
    numberOfItemsSentToCart: number;

    constructor(
        retailer: RetailerModel,
        items: Array<ProductModel>,
        approvedToContinue: boolean,
        numberOfItemsSentToCart: number,
    ) {
        this.retailer = retailer;
        this.items = items;
        this.approvedToContinue = approvedToContinue;
        this.numberOfItemsSentToCart = numberOfItemsSentToCart;
    }
}

const toUnavailableProductsByHomologationModel = (data: object): UnavailableProductsByHomologationModel => {
    return data
        ? new UnavailableProductsByHomologationModel(
              data['retailer'],
              data['not_available_items']['items']?.map((product: object) => toProductModel(product)),
              data['approved_to_continue'],
              data['number_of_items_sent_to_cart'],
          )
        : null;
};
export { UnavailableProductsByHomologationModel, toUnavailableProductsByHomologationModel };
