<template>
    <no-content
        v-if="stateForOverallStatistics === 'EMPTY'"
        class="stats-container__no-content"
        :description="$t('txt.statistics__no-orders-detail')"
        :title="$t('general.orders', { count: 0 })"
    >
        <icon-afflicted-shop-bag :size="0.5" color="#C1C1C1" />
    </no-content>
    <div v-else class="stats-graphics__container">
        <TransitionHandler :time="250" mode="out-in">
            <BaseBone v-if="stateForMonthlyStatistics === 'LOADING'" class="stats-graphics-donut__skeleton" />
            <TheDoughtChart v-else :key="selectedMonthIndex" />
        </TransitionHandler>
        <main-info v-if="isMobile" class="stats__main-info" />
        <TheLinearChart />
    </div>
</template>

<script lang="ts" setup>
import MainInfo from '../mainInfo/MainInfo.vue';
import { useApp } from '@/composables/useApp';
import TheLinearChart from '@/views/myAccount/views/profile/views/statistics/components/statsGraphics/TheLinearChart.vue';
import TheDoughtChart from '@/views/myAccount/views/profile/views/statistics/components/statsGraphics/TheDoughtChart.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import IconAfflictedShopBag from '@/components/icons/IconAfflictedShopBag.vue';
import { useStatistics } from '@/views/myAccount/views/profile/views/statistics/useStatistics';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import TransitionHandler from '@/modules/core/components/transition/TransitionHandler.vue';

const { isMobile } = useApp();
const { stateForOverallStatistics, stateForMonthlyStatistics, selectedMonthIndex } = useStatistics();
</script>

<style lang="scss" scoped>
@import './stats-graphics.scss';
</style>
