import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e47e52ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "subscriptions"
}
const _hoisted_2 = { class: "subscriptions__content" }
const _hoisted_3 = { class: "subscriptions__legend" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_base_bone = _resolveComponent("base-bone")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_card_cambrella_membership = _resolveComponent("card-cambrella-membership")!
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_subscription_cambrellas_benefits = _resolveComponent("subscription-cambrellas-benefits")!
  const _component_subscription_not_selected = _resolveComponent("subscription-not-selected")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($options.showLeftSide)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_base_card, {
            class: _normalizeClass(["subscriptions__options", { 'card--no-shadow': $setup.isMobile }]),
            padding: 0
          }, {
            default: _withCtx(() => [
              _createVNode(_component_section_header, {
                "color-title": "#000",
                title: _ctx.$t('general.subscriptions')
              }, null, 8, ["title"]),
              _createElementVNode("section", _hoisted_2, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('txt.subscription__legend')), 1),
                (_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_base_card, {
                      key: 0,
                      class: "subscription__skeleton"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_base_bone, { "is-show": "" })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.memberships, (membership) => {
                      return (_openBlock(), _createBlock(_component_base_card, {
                        key: membership,
                        class: "subscription",
                        padding: 0.5,
                        "bg-color": "#ff9012",
                        onClick: ($event: any) => (_ctx.currentMembership = membership)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_card_cambrella_membership, { membership: membership }, null, 8, ["membership"]),
                          _createElementVNode("aside", {
                            class: _normalizeClass(["subscription__arrow", { 'subscription__arrow--selected': _ctx.currentMembership === membership }])
                          }, [
                            _createVNode(_component_icon_arrow, { size: 0.6 })
                          ], 2)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
              ])
            ]),
            _: 1
          }, 8, ["class"]),
          (!$setup.isMobile)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.currentMembership)
                  ? (_openBlock(), _createBlock(_component_subscription_cambrellas_benefits, {
                      key: 0,
                      membership: _ctx.memberships[0]
                    }, null, 8, ["membership"]))
                  : (_openBlock(), _createBlock(_component_subscription_not_selected, { key: 1 }))
              ], 64))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    ($setup.isMobile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.currentMembership)
            ? (_openBlock(), _createBlock(_component_subscription_cambrellas_benefits, {
                key: 0,
                membership: _ctx.memberships[0],
                onReturn: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentMembership = undefined))
              }, null, 8, ["membership"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}