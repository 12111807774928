<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { computed } from 'vue';

const props = defineProps({
    size: {
        type: Number,
        default: 0.6,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
});
const width = computed<number>(() => 118 * props.size);
const height = computed<number>(() => 92 * props.size);
</script>

<template>
    <BaseIcon :height="height" :icon-color="color" :width="width" viewBox="0 0 118 92">
        <g clip-path="url(#clip0_4_5363)">
            <path
                :fill="color"
                d="M44.2248 23.1611C44.6862 20.9464 42.3793 18.0858 39.4264 18.6395C36.4736 19.1932 33.8898 19.47 33.1516 22.4228C32.5057 25.2834 43.0252 28.2363 44.2248 23.1611Z"
            />
            <path
                :fill="color"
                d="M71.5412 22.4227C70.8952 22.0536 65.174 22.0536 62.7749 24.2683C60.9293 26.0215 57.6073 32.2964 54.9313 38.0175C56.8691 31.5581 58.3456 26.852 58.6224 25.6524C59.1761 23.3455 56.1309 23.8069 53.824 24.2683C52.2553 24.5451 49.487 31.0968 47.0878 39.3094C46.9955 39.4939 46.9032 39.5862 46.811 39.7708C46.0727 41.3395 39.4288 51.859 36.6605 53.6123C36.1068 51.5822 35.7377 47.9834 37.2142 41.8931C37.7678 39.4017 38.9674 35.8952 39.4288 33.8651C40.0748 31.0045 33.0617 30.0817 32.5081 32.6655C32.1389 34.1419 30.9393 36.6334 29.3706 43.2773C28.171 48.5371 26.6023 59.0566 34.4459 57.6725C38.0447 57.0265 44.0427 46.9684 45.8882 43.831C42.1971 58.4107 40.2593 75.5742 47.3646 74.2823C50.3175 73.7287 46.5341 71.4217 49.9484 56.6574C50.6866 53.6123 51.4248 50.5672 52.163 47.7066C54.6545 42.0777 61.9444 26.4829 65.7277 25.837C67.7578 25.4679 62.8671 51.1208 55.3927 52.4127C52.0707 52.9664 54.9313 54.5351 58.3456 53.8891C71.7257 51.3977 78.0005 25.9293 71.5412 22.4227Z"
            />
            <path
                :fill="color"
                d="M92.6701 57.857C85.4725 59.0566 86.2107 50.198 88.0562 43.0927C89.8095 36.4487 91.1014 33.865 91.4705 32.4808C92.1164 29.9894 99.0372 31.0967 98.299 33.9573C97.7453 35.9873 96.4534 39.4939 95.8075 41.9853C94.1465 48.1679 94.4233 51.7667 94.8847 53.8891C94.977 53.8891 96.1766 57.3033 92.6701 57.857Z"
            />
            <path
                :fill="color"
                d="M102.454 25.9293C103.008 23.7147 100.793 20.7618 97.8402 21.2232C94.8873 21.6846 92.3036 21.9614 91.5654 24.822C90.8271 27.6826 101.07 31.0968 102.454 25.9293Z"
            />
            <path
                :fill="color"
                d="M53.5488 57.9495C53.5488 57.9495 89.5368 76.5894 106.423 50.0136C107.531 47.8913 109.007 46.0457 110.853 44.477C114.821 41.2473 119.619 45.6766 116.574 50.475C109.284 62.3787 85.3843 79.1731 53.5488 57.9495Z"
            />
            <path
                :fill="color"
                d="M104.392 6.73579C100.885 6.82807 97.3789 7.19718 93.9647 7.84311C94.5183 6.18213 94.9797 4.98253 95.1643 4.24432C96.087 -0.0926929 89.6277 -2.21506 88.3358 3.32155C87.6899 5.53619 86.8594 7.65856 85.9366 9.78093C84.2756 10.2423 84.737 10.15 79.3849 11.9956C79.3849 11.9956 72.9256 14.7639 71.5414 17.6245C71.5414 17.6245 70.8032 20.2082 74.0329 18.8241C77.078 17.4399 80.2154 16.3326 83.5374 15.5021C80.5845 23.0688 76.8012 32.6656 75.0479 40.5091C72.2796 53.0588 76.2475 58.6877 79.8463 58.0417C83.4451 57.3958 78.4622 57.1189 81.8764 41.801C83.5374 34.142 89.8122 19.0086 91.9346 13.3797C96.5484 12.3647 101.162 11.811 105.868 11.6265C107.991 11.6265 109.559 12.0878 109.467 11.2574C109.283 9.96548 106.883 6.73579 104.392 6.73579Z"
            />
            <path
                :fill="color"
                d="M54.8396 2.30647C53.5478 1.01459 51.7945 0.368652 49.949 0.368652C49.6721 0.368652 49.3953 0.368652 49.0262 0.368652C44.6892 0.368652 40.2599 0.922313 36.0152 1.93736C29.8326 3.22923 17.0061 7.38169 7.04025 14.487C3.99511 16.6094 1.78047 19.7468 0.673148 23.2533C0.396317 24.6375 0.30404 26.0216 0.488594 27.4058C0.673148 28.7899 1.41136 30.1741 2.51868 31.0046C3.07235 31.4659 4.54877 31.7428 4.91788 30.82C5.19471 29.9895 4.27194 29.5281 3.81056 29.0667C3.2569 28.3285 3.2569 27.3135 3.71828 26.5753C4.54877 25.1911 5.74837 24.0838 7.04025 23.2533C10.639 20.5773 14.5147 18.1781 18.5749 16.0557C16.0834 26.5753 12.4846 41.155 11.9309 50.1981C11.7464 52.5051 11.7464 54.812 13.0382 56.3807C13.5919 57.1189 14.3301 57.5803 15.2529 57.7648C16.5448 58.0417 18.7594 57.7648 19.4053 56.5652C20.0513 55.2734 19.5899 53.7969 19.959 50.1981C20.1436 46.9685 20.6049 43.9233 21.0663 40.6936C22.3582 31.1891 25.5879 19.6545 27.2489 13.6565L27.8025 12.0878C33.3391 9.87317 39.1526 8.39674 44.966 7.38169C46.9961 7.10486 49.1185 7.01258 51.1486 7.19714C52.2559 7.28941 53.2709 7.47397 54.286 7.7508C54.9319 7.93535 55.947 8.11991 56.2238 7.19714C56.7775 5.53615 56.2238 3.59834 54.8396 2.30647Z"
            />
            <path
                :fill="color"
                d="M10.0895 81.3305H8.70531V79.3927H5.937V87.3285H7.87481V88.6204H2.61503V87.3285H4.55285V79.3927H1.78454V81.3305H0.400391V78.0085H10.0895V81.3305Z"
            />
            <path
                :fill="color"
                d="M15.4391 88.7126H11.3789V87.4208H12.6708V82.7146H11.3789V81.3305H14.1472V87.3285H15.4391V88.7126ZM12.394 78.839C12.3017 78.2854 12.7631 77.824 13.3167 77.824C13.8704 77.824 14.3318 78.1931 14.3318 78.839C14.3318 79.3927 13.9627 79.8541 13.3167 79.8541C12.8553 79.8541 12.394 79.3927 12.394 78.839Z"
            />
            <path
                :fill="color"
                d="M24.1146 85.5753H18.1166C18.578 88.0668 21.6231 88.159 22.4536 86.7749H24.0223C23.4686 88.159 22.0845 89.0818 20.608 88.9895C18.4857 88.9895 16.7324 87.2363 16.7324 85.1139C16.7324 82.9915 18.3934 81.3306 20.5158 81.2383C22.4536 81.2383 24.1146 82.7147 24.1146 84.7448C24.1146 84.8371 24.1146 84.9294 24.1146 85.0216C24.1146 85.1139 24.1146 85.3907 24.1146 85.5753ZM18.1166 84.468H22.7304C22.3613 81.6074 18.6702 81.6074 18.1166 84.468Z"
            />
            <path
                :fill="color"
                d="M40.0802 88.7132H36.02V87.4213H37.3119V84.653C37.4042 83.2688 36.666 82.4384 35.4664 82.4384C34.6359 82.4384 33.8977 82.8997 33.4363 83.638C33.5286 84.1916 33.5286 84.3762 33.5286 87.329H34.8204V88.6209H30.7603V87.329H32.0521V84.5607C32.0521 83.1766 31.3139 82.3461 30.1143 82.3461C29.2838 82.3461 28.6379 82.8075 28.1765 83.5457V87.329H29.4684V88.6209H25.4082V87.329H26.7001V82.7152H25.4082V81.331H28.1765V81.977C28.7302 81.331 29.5607 81.0542 30.3912 81.0542C31.4062 81.0542 32.329 81.5156 32.8826 82.2538C33.6208 81.5156 34.6359 81.0542 35.7432 81.1465C37.4965 81.1465 38.7883 82.4384 38.7883 84.5607V87.4213H40.0802V88.7132Z"
            />
            <path
                :fill="color"
                d="M50.1374 85.0216C50.1374 87.144 48.4764 88.805 46.4463 88.8972C45.5236 88.8972 44.6931 88.6204 44.0471 88.0668V90.7428H45.9849V92.0347H41.3711V90.7428H42.663V82.7147H41.3711V81.3305H44.1394V81.9765C44.7853 81.4228 45.7081 81.146 46.5386 81.146C48.4764 81.2383 50.1374 82.8993 50.1374 85.0216ZM48.661 85.0216C48.661 82.6224 45.8004 81.4228 43.9548 83.4529V86.5903C45.8004 88.6204 48.661 87.3285 48.661 85.0216Z"
            />
            <path
                :fill="color"
                d="M59.2733 85.0216C59.3656 87.2363 57.6123 88.9895 55.3976 89.0818C53.183 89.1741 51.4297 87.4208 51.3375 85.2062C51.2452 82.9915 52.9985 81.2383 55.2131 81.146H55.3054C57.4277 81.146 59.181 82.807 59.2733 85.0216C59.2733 85.0216 59.2733 84.9293 59.2733 85.0216ZM57.7968 85.0216C57.7968 83.6375 56.5972 82.5301 55.2131 82.6224C53.8289 82.6224 52.7216 83.822 52.8139 85.2062C52.8139 86.5903 53.9212 87.6054 55.3054 87.6054C56.6895 87.6054 57.7968 86.4981 57.7968 85.0216C57.7968 85.1139 57.7968 85.0216 57.7968 85.0216Z"
            />
            <path
                :fill="color"
                d="M73.6667 85.0216C73.6667 87.144 72.0057 88.805 69.9756 88.8972C69.0528 88.8972 68.2224 88.6204 67.5764 88.0668V90.7428H69.5142V92.0347H64.9004V90.7428H66.1923V82.7147H64.9004V81.3305H67.6687V81.9765C68.3146 81.4228 69.2374 81.146 70.0679 81.146C72.0057 81.2383 73.6667 82.8993 73.6667 85.0216ZM72.2825 85.0216C72.2825 82.6224 69.422 81.4228 67.5764 83.4529V86.5903C69.422 88.6204 72.2825 87.3285 72.2825 85.0216Z"
            />
            <path
                :fill="color"
                d="M82.5304 87.3283H83.8223V88.6202H81.054V87.9742C79.393 89.2661 76.9938 88.9893 75.7019 87.3283C74.41 85.6673 74.6869 83.2681 76.3479 81.9762C77.732 80.8689 79.7621 80.8689 81.1463 81.9762V81.3303H83.9146V82.6222H82.5304V87.3283ZM81.1463 86.5901V83.4527C79.3007 81.4226 76.4401 82.6222 76.4401 85.0214C76.4401 86.4055 77.5475 87.6051 78.9316 87.6051C79.7621 87.6051 80.5926 87.236 81.1463 86.5901Z"
            />
            <path
                :fill="color"
                d="M91.1088 82.715C90.0015 82.715 88.9865 82.9918 87.9714 83.3609V87.3288H90.0015V88.6207H85.2031V87.3288H86.495V82.715H85.2031V81.3308H87.9714V82.2536C88.8942 81.6076 90.0015 81.2385 91.1088 81.2385V82.715Z"
            />
            <path
                :fill="color"
                d="M99.5988 87.3285H100.891V88.6204H98.1223V87.9745C96.4614 89.2664 94.0622 88.9895 92.7703 87.3285C91.4784 85.6676 91.7552 83.2684 93.4162 81.9765C94.8004 80.8692 96.8305 80.8692 98.2146 81.9765V81.3305H100.983V82.6224H99.691L99.5988 87.3285ZM98.1223 86.5903V83.4529C97.5687 82.807 96.7382 82.4379 95.9077 82.4379C94.5235 82.4379 93.4162 83.6375 93.4162 85.0216C93.4162 87.4208 96.2768 88.6204 98.1223 86.5903Z"
            />
            <path
                :fill="color"
                d="M112.33 88.6202C109.377 89.543 107.901 88.2511 107.901 85.9442V82.7145H105.963V81.3303H107.901V78.9312H109.377V81.3303H112.053V82.6222H109.377V85.8519C109.377 86.9592 109.931 87.5129 110.854 87.5129C111.223 87.5129 111.684 87.4206 112.053 87.3283L112.33 88.6202Z"
            />
            <path
                :fill="color"
                d="M117.595 88.7129H113.535V87.421H114.827V82.7149H113.535V81.3307H116.303V87.3287H117.595V88.7129ZM114.55 78.8393C114.55 78.2856 114.919 77.8242 115.565 77.8242C116.119 77.8242 116.58 78.1933 116.58 78.8393C116.58 79.3929 116.211 79.8543 115.565 79.8543C115.012 79.8543 114.55 79.3929 114.55 78.8393Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_4_5363">
                <rect fill="white" height="92" transform="translate(0.404297)" width="117.192" />
            </clipPath>
        </defs>
    </BaseIcon>
</template>
