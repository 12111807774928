<template>
    <aside class="autocomplete-your-products__options" @click.stop="$emit('on-select-product')">
        <img
            v-if="product.image.medium"
            class="autocomplete-your-products__image"
            :src="product.image.medium"
            alt=""
            @error="displayDefaultImg"
        />
        <icon-tipti v-else :size="0.5" />
        <h5 class="autocomplete-your-products__name">{{ product.name }}</h5>
    </aside>
    <TransitionFade mode="out-in">
        <ActionsQuantity
            v-if="shoActions"
            class="autocomplete-action-quantity"
            :actual-quantity="productQuantityInCart === 0 ? 1 : productQuantityInCart"
            :is-from-autocomplete="true"
            :product-data="product"
            section="YOUR_PRODUCTS"
            @on-close="shoActions = false"
            @on-update="updateQuantity"
        />
        <btn-circular v-else class="actions" @click="tryToShow">
            <template v-if="productQuantityInCart > 0">
                {{ productQuantityInCart }}
            </template>
            <icon-increment v-else :size="1" />
        </btn-circular>
    </TransitionFade>
</template>

<script lang="ts" setup>
import IconTipti from '@/components/icons/IconTipti.vue';
import BtnCircular from '@/components/buttons/BtnCircular.vue';
import IconIncrement from '@/components/icons/IconIncrement.vue';
import { useImage } from '@/composables/ImageComposable';
import { computed, PropType, ref, toRef } from 'vue';
import ActionsQuantity from '@/components/product/actionsQuantity/ActionsQuantity.vue';
import { useProductQuantity } from '@/composables/useProductQuantity';
import { ProductModel } from '@/models/product/ProductModel';
import { useUser } from '@/composables/useUser';
import TransitionFade from '@/modules/core/components/transition/TransitionFade.vue';
import { useCart } from '@/composables/useCart';

const props = defineProps({
    product: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
});

const { products } = useCart();
const { isAuth, requireAuth } = useUser();
const { displayDefaultImg } = useImage();
defineEmits<(e: 'on-select-product') => void>();

const productQuantityInCart = computed<number>(
    () => products.value?.find((product) => product.stockItemId === props.product.stockItemId)?.productQuantity ?? 0,
);

const { quantityAction, quantity } = useProductQuantity('PRODUCT_DETAIL', {
    productTarget: toRef(props, 'product').value,
});
let timer;

const shoActions = ref(false);
const updateQuantity = (value: number | string) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
        quantity.value = +value;
        quantityAction(false);
        shoActions.value = false;
    }, 1500);
};

const tryToShow = () => {
    if (!isAuth.value) {
        requireAuth.value = true;
        return;
    }
    shoActions.value = true;
    if (productQuantityInCart.value > 0) {
        timer = setTimeout(() => {
            shoActions.value = false;
        }, 1500);
        return;
    }
    quantity.value = 1;
    quantityAction(false);
    timer = setTimeout(() => {
        shoActions.value = false;
    }, 2500);
};
</script>

<style lang="scss" scoped>
@import 'your-product-found.scss';
</style>
