import { computed, Ref, ref, watch } from 'vue';
import { useApp } from '@/composables/useApp';
import { applyRegexMask } from '@/utils/phoneUtils';

const InputEvents = <T extends number | boolean | string>(props, $emits, inputValue: Ref<T>) => {
    const emptyMessage = ref('');
    const patternValid = ref('');
    const { $t } = useApp();

    const requireValidation = () => {
        if (!props.isRequired) {
            emptyMessage.value = '';
            return;
        }
        if (typeof inputValue.value === 'number' && `${inputValue.value ?? ''}`?.trim()?.length) {
            emptyMessage.value = '';
            return;
        }
        if (typeof inputValue.value === 'string' && inputValue.value?.trim()?.length) {
            emptyMessage.value = '';
            return;
        }
        emptyMessage.value = $t('input.empty', { label: props.label });
    };

    const emailValidation = () => {
        if (emptyMessage.value?.length) {
            patternValid.value = '';
            return;
        }
        const isValidEmail = /^[a-zA-Z]+.*@[a-zA-Z0-9-]*\..{2,}$/.test(inputValue.value as string);
        patternValid.value = isValidEmail ? '' : $t('input.invalid_email', { label: props.label });
    };

    const validateOnBlur = () => {
        $emits('blur');
        requireValidation();
        if (typeof inputValue.value !== 'string') return;
        if (props.type === 'email') emailValidation();
    };

    const onInput = (e: Event) => {
        const event = e as KeyboardEvent;
        if (typeof inputValue.value === 'string')
            if (props.type === 'phone') {
                event.preventDefault();
                if (isNaN(parseInt(event.key)) && event.key !== 'Backspace') return;

                let phoneNumber: string = inputValue.value;
                if (event.key === 'Backspace') {
                    phoneNumber = phoneNumber?.substring(0, phoneNumber?.length - 1) ?? '';
                } else phoneNumber = phoneNumber + event.key;
                (inputValue.value as string) = applyRegexMask(phoneNumber);
                return;
            }
        const { key } = event;
        if (/[0-9]|Backspace/.test(key) || (key?.length !== 1 && isNaN(+key))) return;
        event.preventDefault();
    };

    watch(props, (value, oldValue) => {
        if (!oldValue) return;
        validateOnBlur();
    });

    return {
        onInput,
        validateOnBlur,
        validationMessages: computed(() => [patternValid.value, emptyMessage.value]),
    };
};

export const useInput = <T extends number | boolean | string>(props, $emits, inputValue: Ref<T>) => {
    const { validateOnBlur, validationMessages, onInput } = InputEvents(props, $emits, inputValue);

    const localErrors = computed<string[]>(() => {
        if (Array.isArray(props.errors))
            return [...(props.errors ?? []), ...validationMessages.value].filter((val) => !!val?.length);
        return [props.errors ?? '', ...validationMessages.value].filter((val) => !!val?.length);
    });

    return {
        validateOnBlur,
        localErrors,
        onInput,
    };
};
