<template>
    <section class="cash-payment">
        <p class="cash-description">
            {{ $t("txt['payment-effective__if-your-payment']") }}
        </p>
        <base-card-flat class="cash-option">
            <div></div>
            <p class="cash-label">{{ $t("txt['payment-effective__have-exact-amount']") }}</p>
            <check-tipti :is-checked="exactCashSelected" @on-check="exactCash" />
        </base-card-flat>
        <base-card-flat class="cash-change">
            <p class="cash-label">{{ $t("txt['payment-effective__need-change-of']") }}</p>
            <input
                v-model="amount"
                class="cash-change__input"
                :class="{ 'cash-change__input--error': showAmountError }"
                :disabled="exactCashSelected"
                :placeholder="$t('general.example', { message: `${currency}20.00` })"
                type="number"
                @keyup="updateCashAmount($event.target.value)"
            />
            <p v-if="showAmountError" class="cash-change__error">
                {{ $t("txt['cash__only-equal']", { amount: valueToPayWithCash, currency: currency }) }}
            </p>
        </base-card-flat>
    </section>
</template>

<script lang="ts">
import { CashPaymentModel, CashWirePaymentModel } from '@/models/checkout/CashWirePaymentModel';
import { mapGetters } from 'vuex';

import CheckTipti from '@/components/checks/CheckTipti.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import { useCheckoutEvents } from '@/views/checkout/composables/useCheckoutEvents';

export default {
    name: 'CashPayment',
    components: { CheckTipti, BaseCardFlat },
    data() {
        return {
            amount: '',
            exactCashSelected: false,
        };
    },
    computed: {
        ...mapGetters({
            totalToPay: 'payments/totalToPay',
            isMixInstitutionalPayment: 'payments/isMixInstitutionalPayment',
            isMixPaymentTiptiCard: 'payments/isMixPaymentTiptiCard',
            secondPayment: 'payments/secondPayment',
            countrySelected: 'external/countrySelected',
        }),
        currency() {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        valueToPayWithCash(): number {
            return this.isMixPaymentTiptiCard || (this.isMixInstitutionalPayment && this.secondPayment)
                ? +this.secondPayment
                : +this.totalToPay;
        },
        showAmountError(): boolean {
            return this.amount.length && +this.amount < this.valueToPayWithCash;
        },
    },
    setup() {
        const { selectPaymentsMethod } = useCheckoutEvents();
        return {
            selectPaymentsMethod,
        };
    },
    methods: {
        totalToPayCash(): number {
            return this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment
                ? +this.secondPayment
                : +this.totalToPay;
        },
        updateCashAmount(cashAmount: string): void {
            if (!this.isMixPaymentTiptiCard && !this.isMixInstitutionalPayment) {
                this.$store.commit('payments/cleanPaymentMethods');
            }
            if (+this.amount < this.valueToPayWithCash) return;
            this.selectPaymentsMethod({
                screen: 'payment-cash',
            });
            this.$store.commit(
                'payments/cashWirePayment',
                new CashWirePaymentModel(
                    new CashPaymentModel(
                        this.totalToPayCash(),
                        +cashAmount < this.totalToPayCash() ? this.totalToPayCash() : +cashAmount,
                        !this.exactCashSelected,
                    ),
                    null,
                ),
            );
            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-cash',
                    amount: this.totalToPayCash(),
                    objectData: [
                        'payment-cash',
                        new CashPaymentModel(
                            this.totalToPayCash(),
                            +cashAmount < this.totalToPayCash() ? this.totalToPayCash() : +cashAmount,
                            !this.exactCashSelected,
                        ),
                    ],
                }),
            );
        },
        exactCash(isExactCash: boolean): void {
            this.exactCashSelected = isExactCash;
            if (!isExactCash) return;
            if (!this.isMixPaymentTiptiCard && !this.isMixInstitutionalPayment) {
                this.$store.commit('payments/cleanPaymentMethods');
            }
            this.$store.commit(
                'payments/cashWirePayment',
                new CashWirePaymentModel(
                    new CashPaymentModel(this.totalToPayCash(), this.totalToPayCash(), !this.exactCashSelected),
                    null,
                ),
            );
            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-cash',
                    amount: this.totalToPayCash(),
                }),
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import './cash-payment.scss';
</style>
