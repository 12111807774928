import { getFacebookImage } from '@/utils/utils';
class ReferredCodesModel {
    userName: string;
    userImage: string;
    claimDate: string;
    reward: string;

    constructor(userName?: string, userImage?: string, claimDate?: string, reward?: string) {
        this.userName = userName;
        this.userImage = userImage;
        this.claimDate = claimDate;
        this.reward = reward;
    }
}

const toReferredCodesModel = (data: object): ReferredCodesModel => {
    return data
        ? new ReferredCodesModel(
              `${data['receiver_client']['user']['first_name'] ?? ''} ${data['receiver_client']['user']['last_name'] ??
                  ''}`,
              data['receiver_client']['facebook_user_id']
                  ? getFacebookImage(data['receiver_client']['facebook_user_id'])
                  : data['receiver_client']['profile_picture'],
              data['claim_date'],
              data['reward'] ?? 0,
          )
        : null;
};
export { ReferredCodesModel, toReferredCodesModel };
