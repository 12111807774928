<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useXSelling } from '@/modules/extraItems/composables/useXSelling';

type WhereToShow = 'CardProduct' | 'DetailProduct' | 'CardSpecial';

const props = defineProps({
    product: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
    whereToShow: {
        type: String as PropType<WhereToShow>,
        default: 'CardProduct',
    },
});

const { isMainCart } = useCartType();
const { products: xSellingProducts } = useXSelling();

const discountPercentage = computed<number>(() => {
    if (!props.product) return 0;
    const discountedPrice = +props.product?.priceWithDiscount?.toFixed(2);
    if (
        props.product?.discountCard &&
        +props.product?.priceWithDiscount > +props.product?.discountCard?.product_price &&
        isMainCart.value &&
        !xSellingProducts.value?.length
    ) {
        const productPrice = +props.product?.discountCard?.product_price.toFixed(2);
        return Math.round(100 - (100 * productPrice) / discountedPrice);
    }

    if (+props.product?.priceWithDiscount && +props.product?.price > +props.product?.priceWithDiscount) {
        const originalPrice = +props.product?.price.toFixed(2);
        return Math.round(100 - (100 * discountedPrice) / originalPrice);
    }

    if (props.product?.discount <= 0) return 0;
    return Math.round(props.product?.discount * 100);
});
</script>

<template>
    <aside v-if="discountPercentage && whereToShow === 'CardProduct'" class="product-discount__card">
        <span class="product-discount__percentage">
            {{ `-${discountPercentage}%` }}
        </span>
    </aside>
    <aside v-if="discountPercentage && whereToShow === 'CardSpecial'" class="product-discount__card-special">
        <span class="product-discount__percentage-special">
            {{ `-${discountPercentage}%` }}
        </span>
    </aside>
    <section v-if="discountPercentage && whereToShow === 'DetailProduct'" class="product-discount__content">
        <aside class="product-discount__detail">
            <span class="product-discount__percentage_detail">
                {{ `-${discountPercentage}%` }}
            </span>
        </aside>
    </section>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as color;
@use '@/assets/scss/type-system' as system;
@use '@/assets/scss/radius' as radius;

.centered-flex {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-discount-base {
    position: absolute;
    transform: rotate(320deg);
    background-color: color.$error;
}

.product-discount-text {
    @include system.subtitle2;
    font-weight: 600;
    color: color.$white;
}

.product-discount {
    &__card {
        @extend .product-discount-base;
        left: -6.5rem;
        width: 13.5rem;
        height: 3rem;
        top: 0;
    }

    &__percentage {
        @extend .centered-flex;
        @extend .product-discount-text;
        top: 1.5rem;
        left: 1rem;
    }

    &__detail {
        @extend .product-discount-base;
        left: -4rem;
        width: 12.5rem;
        height: 4.5rem;
        top: -3rem;
    }

    &__percentage_detail {
        @extend .centered-flex;
        @extend .product-discount-text;
        top: 3rem;
        left: -2rem;
    }

    &__content {
        position: absolute;
        overflow: hidden;
        border-top-left-radius: radius.$radius-md;
        width: 5.5rem;
        height: 4.5rem;
    }

    &__card-special {
        @extend .product-discount-base;
        left: -5.5rem;
        width: 13.5rem;
        height: 4.5rem;
        top: -2rem;
    }

    &__percentage-special {
        @extend .centered-flex;
        @extend .product-discount-text;
        top: 3rem;
        left: -0.5rem;
    }
}
</style>
