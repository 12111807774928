<template>
    <base-card class="special-instructions" :padding="0">
        <div class="special-instructions-section">
            <icon-elder></icon-elder>
            <h2>{{ $t("txt['resume__are-you-elder-people']") }}</h2>
            <icon-info class="icon-info" color="#FF9012" @click="showInformationElder = true"></icon-info>
            <check-tipti
                @on-check="isElder ? setElderCondition(false) : (showTermsElder = true)"
                :isChecked="isElder"
            ></check-tipti>
        </div>
        <div class="special-instructions-section">
            <icon-automatic-replacements></icon-automatic-replacements>
            <div class="instructions-section">
                <h2>{{ $t("txt['resume__automatic-replacements-requested']") }}</h2>
            </div>
            <icon-info
                class="icon-info"
                color="#FF9012"
                :size="0.8"
                @click="showInformationAutomaticReplacements = true"
            ></icon-info>
            <check-tipti
                @on-check="
                    isAutomaticReplacements
                        ? setAutomaticReplacementsCondition(false)
                        : (showTermsAutomaticReplacements = true)
                "
                :isChecked="isAutomaticReplacements"
            ></check-tipti>
        </div>
    </base-card>
    <general-alert
        :accentHeader="true"
        :showCloseIcon="true"
        v-if="showTermsElder || showInformationElder"
        :title="
            showInformationElder ? `${$t('cta.information')} ${$t('general.elder-people')}` : $t('general.elder-people')
        "
        :message="
            showInformationElder ? $t('txt.resume__elder-information') : $t('txt.resume__elder-terms-and-conditions')
        "
        :declineButtonTxt="$t('cta.cancel')"
        :onlyConfirmationBtn="isElder"
        :showButtons="showTermsElder"
        @on-decline="showTermsElder = false"
        @on-close="showInformationElder ? (showInformationElder = false) : (showTermsElder = false)"
        @on-accept="isElder ? (showTermsElder = false) : acceptElderTerms(true)"
    >
    </general-alert>
    <general-alert
        :accentHeader="true"
        :showCloseIcon="true"
        v-if="showTermsAutomaticReplacements || showInformationAutomaticReplacements"
        :title="
            showInformationAutomaticReplacements
                ? `${$t('cta.information')} ${$t('txt.resume__alert-replacements-title')}`
                : $t('txt.resume__alert-replacements-title')
        "
        :message="
            showInformationAutomaticReplacements
                ? $t('txt.resume__alert-replacements-information')
                : $t('txt.resume__alert-replacements-description')
        "
        :declineButtonTxt="$t('cta.cancel')"
        :onlyConfirmationBtn="isAutomaticReplacements"
        :showButtons="showTermsAutomaticReplacements"
        @on-decline="showTermsAutomaticReplacements = false"
        @on-close="
            showInformationAutomaticReplacements
                ? (showInformationAutomaticReplacements = false)
                : (showTermsAutomaticReplacements = false)
        "
        @on-accept="
            isAutomaticReplacements ? (showTermsAutomaticReplacements = false) : acceptAutomaticReplacements(true)
        "
    >
    </general-alert>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import BaseCard from '@/components/cards/BaseCard.vue';
import CheckTipti from '@/components/checks/CheckTipti.vue';
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import IconAutomaticReplacements from '@/components/icons/IconAutomaticReplacements.vue';
import IconElder from '@/components/icons/IconElder.vue';
import IconInfo from '@/components/icons/IconInfo.vue';

export default {
    name: 'instructions',
    data() {
        return {
            isLoading: false,
            retailers: [],
            showTermsElder: false,
            showTermsAutomaticReplacements: false,
            showInformationElder: false,
            showInformationAutomaticReplacements: false,
        };
    },
    components: {
        BaseCard,
        CheckTipti,
        GeneralAlert,
        IconAutomaticReplacements,
        IconElder,
        IconInfo,
    },
    computed: {
        ...mapGetters({
            isElder: 'checkout/isElder',
            isAutomaticReplacements: 'checkout/isAutomaticReplacements',
            profile: 'user/user',
        }),
    },
    methods: {
        setElderCondition(state: boolean): void {
            this.$store.commit('checkout/isElder', state);
        },
        setAutomaticReplacementsCondition(state: boolean): void {
            this.$store.commit('checkout/isAutomaticReplacements', state);
        },
        acceptAutomaticReplacements(): void {
            this.$store.dispatch('segment/tcAccepted', {
                user: {
                    user_id: this.profile.userId,
                    client_id: this.profile.id,
                    email: this.profile.email,
                    first_name: this.profile.name,
                    last_name: this.profile.lastname,
                },
                terms_and_conditions: {
                    client_accepted: true,
                    terms_type: 'ELDER_ADULT_TERMS',
                },
            });
            this.setAutomaticReplacementsCondition(true);
            this.showTermsAutomaticReplacements = false;
        },
        acceptElderTerms(): void {
            this.$store.dispatch('segment/tcAccepted', {
                user: {
                    user_id: this.profile.userId,
                    client_id: this.profile.id,
                    email: this.profile.email,
                    first_name: this.profile.name,
                    last_name: this.profile.lastname,
                },
                terms_and_conditions: {
                    client_accepted: true,
                    terms_type: 'AUTOMATIC_REPLACEMENTS_TERMS',
                },
            });
            this.setElderCondition(true);
            this.showTermsElder = false;
        },
    },
};
</script>
<style lang="scss" scoped>
@import './instructions.scss';
</style>
