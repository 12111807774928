<template>
    <base-icon :icon-name="$t('icon.auto_replace')" :width="width" :height="height" viewBox="0 0 25 37">
        <g clip-path="url(#clip0)">
            <path
                d="M4.15291 4.26929C3.87275 5.7169 3.27124 7.10726 2.37308 8.28498C1.80452 9.03741 1.33485 9.85527 0.96405 10.7222H14.148C13.7772 9.86345 13.3075 9.03741 12.7389 8.28498C11.849 7.09908 11.2393 5.7169 10.9673 4.26929H4.15291Z"
                fill="#FF9012"
            />
            <path
                d="M12.3517 30.7598C11.8408 30.22 11.3876 29.5984 11.025 28.9359C10.6955 28.5924 10.3741 28.2408 10.0939 27.8482C9.83026 27.4802 10.3247 27.1203 10.6625 27.3084C10.7696 27.2512 10.9097 27.2348 11.0333 27.2839C11.0745 27.1203 11.1322 26.9649 11.1981 26.8095C11.2558 26.6214 11.3382 26.4333 11.4288 26.2534C11.5936 25.918 12.1374 26.1143 12.1045 26.4415C12.261 26.4088 12.4423 26.4742 12.5165 26.6132C12.7225 26.5233 13.0191 26.6705 13.0521 26.9404C13.0603 26.9894 13.0603 27.0385 13.0686 27.0876C13.2086 27.1285 13.324 27.243 13.3075 27.4147C13.3075 27.4474 13.2993 27.4802 13.2993 27.5047C13.3405 27.4802 13.3899 27.4638 13.4394 27.4556C13.6536 27.2348 14.0821 27.472 14.0326 27.7909C14.1233 27.7828 14.2139 27.8155 14.2881 27.8645C14.5023 27.5865 15.0214 27.7746 14.9225 28.1753C14.8896 28.298 14.8649 28.4207 14.8319 28.5515C14.8566 28.5924 14.8731 28.6333 14.8731 28.6906C14.972 28.6906 15.0709 28.7315 15.1285 28.8051V15.3676C15.1285 14.5252 15.0379 13.6829 14.8566 12.8568H0.271918C0.0906394 13.6747 0 14.5171 0 15.3676V32.4446C0 34.6528 1.80455 36.4439 4.02933 36.4439H11.0827C13.3075 36.4439 15.1121 34.6528 15.1121 32.4446V32.0356C14.0491 32.2074 13.0521 31.5122 12.3517 30.7598Z"
                fill="#FF9012"
            />
            <path
                d="M10.7119 2.13462H10.7943C11.3546 2.09372 11.7913 1.63572 11.7913 1.0714C11.7913 0.474359 11.3052 0 10.7119 0H4.40011C3.80684 0 3.32068 0.474359 3.32068 1.0714C3.32068 1.63572 3.7574 2.09372 4.31771 2.13462H4.40011H10.7119Z"
                fill="#FF9012"
            />
            <path
                d="M16.1585 19.6859C11.4206 19.6859 7.56427 23.5135 7.56427 28.2162C7.56427 32.9189 11.4206 36.7465 16.1585 36.7465C20.8965 36.7465 24.7528 32.9189 24.7528 28.2162C24.7528 23.5135 20.8965 19.6859 16.1585 19.6859ZM20.9624 25.9753L15.4664 31.3895C15.145 31.7085 14.6259 31.733 14.2798 31.414L11.3711 28.7805C11.025 28.4616 11.0086 27.9218 11.3052 27.5864C11.6266 27.2429 12.1704 27.2266 12.5082 27.5455L14.8154 29.6393L19.7264 24.7648C20.0725 24.4213 20.6081 24.4213 20.9542 24.7648C21.3085 25.1001 21.3085 25.6318 20.9624 25.9753Z"
                fill="#CFDE00"
            />
            <path
                d="M16.1585 37C11.2805 37 7.31708 33.0579 7.31708 28.2244C7.31708 23.3908 11.2887 19.4487 16.1585 19.4487C21.0284 19.4487 25 23.3908 25 28.2244C25 33.0579 21.0284 37 16.1585 37ZM16.1585 19.9313C11.5524 19.9313 7.81147 23.6525 7.81147 28.2162C7.81147 32.7799 11.5607 36.5011 16.1585 36.5011C20.7564 36.5011 24.5056 32.7962 24.5056 28.2244C24.5056 23.6525 20.7564 19.9313 16.1585 19.9313ZM14.8566 31.8884C14.5847 31.8884 14.321 31.7902 14.1068 31.594L11.2063 28.9604C10.7696 28.5515 10.7284 27.8645 11.1239 27.4229L11.1322 27.4147C11.3299 27.202 11.6019 27.0794 11.8985 27.0712C12.1951 27.063 12.4753 27.1612 12.6895 27.3656L14.8154 29.3039L19.5616 24.5931C19.7759 24.3804 20.056 24.2659 20.3527 24.2659C20.6493 24.2659 20.9295 24.3804 21.1437 24.5931C21.5887 25.0347 21.5887 25.7217 21.1437 26.1552L15.6477 31.5694C15.4252 31.7739 15.145 31.8884 14.8566 31.8884ZM11.4865 27.75C11.2723 27.9954 11.297 28.3798 11.5359 28.6006L14.4446 31.2259C14.6918 31.4549 15.0544 31.4467 15.2851 31.2096L20.7812 25.7953C21.0284 25.55 21.0284 25.1656 20.7812 24.9202C20.6576 24.7975 20.501 24.7321 20.3444 24.7321C20.1796 24.7321 20.0231 24.7975 19.9077 24.9202L14.8237 29.9664L12.3434 27.7091C12.1045 27.4965 11.7172 27.5047 11.4865 27.75Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="25" height="37" fill="white" />
            </clipPath>
        </defs>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-automatic-replacements',
    props: {
        size: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        width(): number {
            return 25 * this.size;
        },
        height(): number {
            return 25 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
