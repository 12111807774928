<template>
    <base-icon :icon-name="$t('icon.twitter')" :width="width" :height="height" viewBox="0 0 32 27" :iconColor="color">
        <path
            d="M31.0974 3.0319C30.3923 3.31394 29.6872 3.59598 28.9116 3.737C29.7577 2.96139 30.3217 1.97426 30.6743 0.916622C30.7448 0.634584 30.4628 0.352547 30.1807 0.493566C29.1231 1.12815 27.9949 1.55121 26.8668 1.90375C26.7963 1.90375 26.7258 1.90375 26.6553 1.90375C26.4437 1.90375 26.2322 1.83324 26.0912 1.69223C24.822 0.564075 23.2708 0 21.5786 0C20.8735 0 20.1684 0.141019 19.3928 0.352547C17.207 1.05764 15.5148 2.89088 14.9507 5.07668C14.7392 5.92279 14.6687 6.7689 14.8097 7.61501C14.8097 7.68552 14.8097 7.75603 14.7392 7.82654C14.6687 7.89705 14.5982 7.89705 14.5276 7.89705C9.6625 7.47399 5.36143 5.14718 2.25902 1.33968C2.118 1.12815 1.76545 1.12815 1.62443 1.33968C1.06036 2.39732 0.70781 3.59598 0.70781 4.79464C0.70781 6.62788 1.48341 8.39062 2.75258 9.65979C2.18851 9.51877 1.69494 9.30724 1.20138 9.0252C0.989848 8.88418 0.70781 9.09571 0.70781 9.30724C0.70781 12.0571 2.25902 14.5249 4.65634 15.5826C4.58583 15.5826 4.58583 15.5826 4.51532 15.5826C4.16277 15.5826 3.73971 15.5121 3.38717 15.4416C3.10513 15.371 2.8936 15.6531 2.96411 15.9351C3.73971 18.4029 5.855 20.1657 8.32282 20.5887C6.20754 21.9989 3.81022 22.704 1.27189 22.704H0.496282C0.284754 22.704 0.073226 22.845 0.00271658 23.1271C-0.0677928 23.3386 0.073226 23.6206 0.284754 23.6912C3.10513 25.3129 6.34856 26.2295 9.6625 26.2295C12.5534 26.2295 15.2327 25.6654 17.7006 24.5373C19.9569 23.4796 21.9311 21.9989 23.5529 20.1657C25.1041 18.4029 26.3027 16.3582 27.1488 14.1724C27.9244 12.0571 28.3475 9.73029 28.3475 7.61501V7.5445C28.3475 7.19196 28.4885 6.83941 28.7705 6.62788C29.8282 5.78177 30.7448 4.79464 31.4499 3.66649C31.7319 3.31394 31.3794 2.89088 31.0974 3.0319Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-twitter-two',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 32 * this.size;
        },
        height(): number {
            return 27 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
