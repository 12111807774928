import { getFacebookImage } from '@/utils/utils';
class ParticipantModel {
    id: number;
    name: string;
    photo: string;

    constructor(id: number, name: string, photo: string) {
        this.id = id;
        this.name = name;
        this.photo = photo;
    }
}
const toParticipantModel = (data: object): ParticipantModel => {
    return data
        ? new ParticipantModel(
              data['id'],
              data['name'],
              data['facebook_user_id']?.length ? getFacebookImage(data['facebook_user_id']) : data['profile_picture'],
          )
        : null;
};
export { ParticipantModel, toParticipantModel };
