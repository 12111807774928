import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c439a45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 2,
  class: "programs"
}
const _hoisted_3 = { class: "program-title" }
const _hoisted_4 = { class: "program-container" }
const _hoisted_5 = {
  key: 3,
  class: "programs"
}
const _hoisted_6 = { class: "program-title" }
const _hoisted_7 = { class: "program-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_program_skeleton = _resolveComponent("program-skeleton")!
  const _component_icon_afflicted_shop_bag = _resolveComponent("icon-afflicted-shop-bag")!
  const _component_no_content = _resolveComponent("no-content")!
  const _component_program_type = _resolveComponent("program-type")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_section_header, {
      "has-back": $options.isMobile,
      title: _ctx.$t('txt.affiliates_and_rewards'),
      "with-borders": false,
      onOnBack: $options.onBack
    }, null, 8, ["has-back", "title", "onOnBack"]),
    (_ctx.isLoadingPrograms)
      ? (_openBlock(), _createBlock(_component_program_skeleton, { key: 0 }))
      : ($options.noContent)
        ? (_openBlock(), _createBlock(_component_no_content, {
            key: 1,
            class: "no-content",
            "cta-text": _ctx.$t('cta.reload_page'),
            description: _ctx.$t('general.error_occurred'),
            onOnAction: $options.getPrograms
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_afflicted_shop_bag)
            ]),
            _: 1
          }, 8, ["cta-text", "description", "onOnAction"]))
        : _createCommentVNode("", true),
    ($options.showAfiliation)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('txt.available_programs')), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.allProgram?.afiliations, (program) => {
              return (_openBlock(), _createBlock(_component_program_type, {
                key: program.id,
                program: program,
                onClick: ($event: any) => ($options.onSelectProgram(program))
              }, null, 8, ["program", "onClick"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    ($options.showRewards)
      ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('txt.available_rewards')), 1),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.programs?.rewards, (program) => {
              return (_openBlock(), _createBlock(_component_program_type, {
                key: program.id,
                program: program,
                onClick: ($event: any) => ($options.onSelectProgram(program))
              }, null, 8, ["program", "onClick"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}