<template>
    <cambrella-with-out-subscription
        v-if="currentModal === 'TYPES'"
        :membership="currentMembership"
        @on-subscribe="subscribe"
        @on-close="$emit('on-close')"
    />
    <cambrella-with-subscription
        v-if="currentModal === 'MEMBERSHIP'"
        :membership="cambrellaMembership"
        @on-close="$emit('on-close')"
    />
    <cambrella-get-subscription
        v-if="currentModal === 'SUBSCRIPTION'"
        :membership="currentMembership"
        @on-close="$emit('on-close')"
    />
</template>

<script lang="ts" setup>
import CambrellaWithOutSubscription from '@/modules/cambrella/components/CambrellaWithOutSubcription.vue';
import CambrellaWithSubscription from '@/modules/cambrella/components/CambrellaWithSubcription.vue';
import CambrellaGetSubscription from '@/modules/cambrella/components/CambrellaGetSubcription.vue';
import { logger } from '@/utils/logger';
import { useCambrellas } from '../composables/useCambrellas';
import { useApp } from '@/composables/useApp';
import { ref } from 'vue';
import { MembershipType } from '@/modules/cambrella/models/MembershipType';

const { $store } = useApp();
const { hasCambrellaSubscription, cambrellaMembership } = useCambrellas();
const currentMembership = ref<MembershipType>();
const currentModal = ref<'TYPES' | 'MEMBERSHIP' | 'SUBSCRIPTION'>();
const $emit = defineEmits<(e: 'on-close') => void>();

const subscribe = (): void => {
    currentModal.value = 'SUBSCRIPTION';
    $store.dispatch('segment/initialCambrellasSubscription', { cambrellas: currentMembership.value });
};
const fetchCambrellas = async (): Promise<void> => {
    try {
        if (hasCambrellaSubscription.value) {
            currentModal.value = 'MEMBERSHIP';
            return;
        }
        const membershipTypes: MembershipType[] = await $store.dispatch('cambrellas/cambrellaMembershipTypes');
        if (!membershipTypes.length) return $emit('on-close');
        currentMembership.value = membershipTypes.at(0);
        currentModal.value = 'TYPES';
    } catch (err) {
        logger('FETCH_CAMBRELLAS', err);
        $emit('on-close');
    }
};

fetchCambrellas();
</script>
