<template>
    <textarea
        class="tipti-textarea"
        :placeholder="placeholder"
        :rows="rows"
        :value="modelValue"
        maxlength="250"
        @input="$emit('update:modelValue', $event.target.value.trim())"
        @keyup="$emit('save-on-key', $event.target.value)"
    />
</template>

<script>
export default {
    name: 'RoundedInput',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        },
        rows: {
            type: Number,
            default: 4,
        },
    },
    emits: ['update:modelValue', 'save-on-key'],
    methods: {
        updateValue(value) {
            this.$emit('update:modelValue', value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.tipti-textarea::placeholder {
    color: $disable;
    @include caption;
    color: $placeholder;
}
.tipti-textarea {
    appearance: unset;
    @include body2;
    border: none;
    resize: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: $radius-lg;
    background-color: $canvas;
    padding: $padding-sm;
    padding-bottom: $padding-lg;
    @include box-shadow--custom-inset(0, 1px, 2px, 1px);
    outline-color: $orange;
}
</style>
