import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d5f7a07"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cambrella-subscription" }
const _hoisted_2 = {
  key: 1,
  class: "cambrella-subscription__content"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_credit_card = _resolveComponent("select-credit-card")!
  const _component_cambrella_tile = _resolveComponent("cambrella-tile")!
  const _component_payment_method_only_credit_card_selection_tile = _resolveComponent("payment-method-only-credit-card-selection-tile")!
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_section_footer = _resolveComponent("section-footer")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_modal, {
      "auto-size": !$options.isMobile,
      "has-back-arrow": _ctx.changeCreditCard,
      "header-text-color": _ctx.changeCreditCard ? '#ff9012' : null,
      title: _ctx.changeCreditCard ? _ctx.$t('txt.credit-card__cards-choosed') : $props.membership.name,
      "z-index": 5,
      onOnClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isSubscribing ? null : _ctx.$emit('on-close'))),
      onOnBackArrow: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeCreditCard = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.changeCreditCard)
            ? (_openBlock(), _createBlock(_component_select_credit_card, {
                key: 0,
                class: "cambrella-subscription__credit_cards",
                amount: $props.membership.price,
                onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeCreditCard = false))
              }, null, 8, ["amount"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("img", {
                  class: "cambrella-subscription__img",
                  src: $props.membership.icon,
                  alt: "",
                  onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.displayDefaultImg && $setup.displayDefaultImg(...args)))
                }, null, 40, _hoisted_3),
                _createVNode(_component_cambrella_tile, {
                  class: "cursor-pointer",
                  membership: $props.membership,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isSubscribing ? null : _ctx.$emit('on-close')))
                }, null, 8, ["membership"]),
                _createVNode(_component_payment_method_only_credit_card_selection_tile, {
                  onOnChangePayment: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSubscribing ? null : (_ctx.changeCreditCard = true)))
                })
              ]))
        ]),
        (!_ctx.changeCreditCard)
          ? (_openBlock(), _createBlock(_component_section_footer, {
              key: 0,
              "has-border": false,
              "with-shadow": false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_btn_out_line, {
                  class: "prime-payment__footer-btn",
                  "is-disabled": !$setup.paymentIdSelected || _ctx.isSubscribing,
                  "is-loading": _ctx.isSubscribing,
                  txt: _ctx.$t('cta.subscribe'),
                  onClick: $options.subscribe
                }, null, 8, ["is-disabled", "is-loading", "txt", "onClick"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["auto-size", "has-back-arrow", "header-text-color", "title"]),
    _createVNode(_component_snack_bar, {
      body: _ctx.msg,
      "is-active": _ctx.showSnackBar,
      "is-failure": _ctx.error,
      "is-success": _ctx.success,
      onOnSnackbarClose: $options.closeSnackBar
    }, null, 8, ["body", "is-active", "is-failure", "is-success", "onOnSnackbarClose"])
  ], 64))
}