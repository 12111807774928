import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39c31d8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "program-placeholder" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "program-placeholder__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_header_program = _resolveComponent("header-program")!
  const _component_btn_solid = _resolveComponent("btn-solid")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_section_header, {
      "has-back": true,
      title: _ctx.programSelected.name,
      onOnBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"]),
    _createVNode(_component_header_program),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "program-placeholder__img",
        src: $options.pageLabel.image,
        alt: ""
      }, null, 8, _hoisted_2),
      _createElementVNode("h3", _hoisted_3, _toDisplayString($options.pageLabel.description), 1),
      _createVNode(_component_btn_solid, {
        txt: $options.pageLabel.callToActionSuccess,
        onClick: $options.changeToVerificationScreen
      }, null, 8, ["txt", "onClick"])
    ])
  ]))
}