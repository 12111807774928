<template>
    <section class="coupon" :class="{ 'coupon--full-width': fullWidth }">
        <input v-model="code" class="coupon__input" type="text" :placeholder="placeholder" />
        <button class="coupon__btn" @click="redeemCode">
            <base-loading v-if="isLoading" width=".5rem" heigth=".5rem" :is-margin-auto="false" />
            <span v-else class="coupon--no-margin">{{ btnName }}</span>
        </button>
        <snack-bar
            :is-active="showSnackBar"
            :is-success="issuccess"
            :is-failure="!issuccess"
            :body="alertMsg"
            @on-snackbar-close="showSnackBar = fa"
        />
    </section>
</template>

<script lang="ts">
import BaseLoading from '@/components/loading/BaseLoading.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import { logger } from '@/utils/logger';
import { useUser } from '@/composables/useUser';
import { useCoupons } from '@/composables/useCoupons';

export default {
    name: 'Coupon',
    components: {
        BaseLoading,
        SnackBar,
    },
    props: {
        isTiptiCard: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        section: {
            type: String,
            required: false,
        },
    },
    setup() {
        const { countrySelected } = useUser();
        const { applyCode, getCoupons } = useCoupons();

        return {
            countrySelected,
            applyCode,
            getCoupons,
        };
    },
    data() {
        return {
            code: '',
            isLoading: false,
            showSnackBar: false,
            issuccess: false,
            alertMsg: '',
        };
    },
    computed: {
        btnName(): string {
            return this.isTiptiCard ? this.$t("general['apply']") : this.$t("txt['referred__button-referred']");
        },
        placeholder(): string {
            return this.isTiptiCard ? this.$t('general.recharge-code') : this.$t('general.enter-your-coupon');
        },
        currency(): string {
            return this.countrySelected?.currencySymbol ?? '$';
        },
    },
    methods: {
        async redeemCode() {
            if (!this.code.length) return;
            try {
                this.isLoading = true;
                if (this.isTiptiCard) {
                    await this.$store.dispatch('tiptiCard/codeRefillTiptiCard', this.code);
                    this.alertMsg = this.$t("general['code-successfully']");
                    await this.$store.dispatch('tiptiCard/tiptiCard');
                } else {
                    this.alertMsg = await this.applyCode(this.code);
                    if (this.section === 'REFERREDS') {
                        this.alertMsg = this.$t('alerts.referred_code_success');
                    }
                    await this.getCoupons();
                }
                this.issuccess = true;
            } catch (err) {
                logger('err', err);
                this.handleError(err);
            } finally {
                this.$store.dispatch('segment/addCode', {
                    code: {
                        code: this.code,
                        status: this.issuccess ? 'VALID' : 'NOT_VALID',
                    },
                    origin: {
                        screen: this.$route.path,
                        section: this.section,
                    },
                });
                this.isLoading = false;
                this.showSnackBar = true;
            }
        },
        handleError(err): void {
            this.issuccess = false;
            this.alertMsg = this.isTiptiCard
                ? this.$t('alerts.tiptiCard_code_error')
                : this.$t("general['code-incorrect-or-already']");
            if (this.isTiptiCard && err?.error === 'No card with that code found')
                this.alertMsg = this.$t('alerts.tiptiCard_code_notFound');
            if (this.section === 'REFERREDS') {
                this.alertMsg = this.$t('alerts.referred_code_error');
                if (err?.message === 'The client cannot referred to himself')
                    this.alertMsg = this.$t('alerts.referred_code_himself');
                if (err?.message === 'The client already have orders')
                    this.alertMsg = this.$t('alerts.referred_code_orders');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.coupon {
    width: fit-content;
    display: flex;
    border-radius: $radius-lg;
    justify-content: center;
    margin: auto;
}

.coupon--full-width {
    width: 100%;
    justify-content: space-between;
}

.coupon__input {
    width: 100%;
    @include body1;
    outline-color: $orange;
    padding: $padding-sm $padding-md;
    border: 1px solid #c7c7c7;
    border-right: none;
    border-radius: $radius-lg 0 0 $radius-lg;
}

.coupon__btn {
    border: none;
    @include button;
    padding: $padding-md;
    background-color: $orange;
    color: $white;
    cursor: pointer;
    border-radius: 0 $radius-lg $radius-lg 0;
}

.coupon__btn:hover {
    font-weight: 600;
}

.coupon--no-margin {
    margin: 0;
}
</style>
