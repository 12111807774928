<template>
    <div class="credit-card-overlay">
        <slot />
    </div>
</template>
<script lang="ts">
export default {
    name: 'overlay',
    props: {
        bgColor: {
            type: String,
            default: '#FFFFFF',
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.credit-card-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: $radius-lg;
    background-color: v-bind(bgColor);
    &--no-radius {
        border-radius: inherit;
    }
}
</style>
