import { CambrellaMembershipModel } from '@/modules/cambrella/models/CambrellaMembership';

export default {
    cambrellaMembership(state, membership: CambrellaMembershipModel): void {
        state.cambrellaMembership = membership;
    },
    cambrellasModalWasShowed(state, wasShowed: boolean): void {
        state.cambrellasModalWasShowed = wasShowed;
    },
};
