import { ProductAnalyticInterface, ProductModel } from '@/models/product/ProductModel';
import { CartModel } from '@/models/cart/CartModel';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import { BaseGoal } from '@/models/cart/CartBaseGoal';
import type { CartMedicalPrescription } from '@/models/cart/CartMedicalPrescription';

export default {
    showCart(state) {
        return state.showCart;
    },
    cart(state): CartModel {
        return state.cart;
    },
    cardGoal(state): BaseGoal {
        return state.cardGoal;
    },
    personalCartId(state) {
        return state.personalCartId;
    },
    products(state): Array<ProductModel> {
        return state.products;
    },
    productsForAnalytics(state) {
        return state.products.map((product) => {
            return { id: product.id, name: product.name, quantity: product.productQuantity };
        });
    },
    productsForSegment(state) {
        return state.products.map((product: ProductModel) => {
            return {
                product_id: product.id,
                ean: product.ean ?? '',
                category: product.category?.name ?? '',
                category_id: product.category?.id,
                subcategory: product.subCategory?.name ?? '',
                name: product.name,
                price: product.price,
                price_with_discount: product.priceWithDiscount ?? 0,
                presentation_quantity: product.unitQuantity ?? '',
                product_quantity: product.productQuantity ?? 0,
                image_url: product.image?.medium ?? '',
                indicators: product.indicators,
            } as ProductAnalyticInterface;
        });
    },
    retailerIds(state: { cart: CartModel }): Array<number> {
        return state.cart.retailers.map((retailer: CartRetailerModel) => retailer.id);
    },
    cartAmount(state) {
        return state.cartAmount;
    },
    lastProductsAdded(state): Array<ProductModel> {
        return state.lastProductsAdded;
    },
    cartBarGoal(state) {
        return state.cartBarGoal;
    },
    showCartBarAlert(state) {
        return state.showCartBarAlert;
    },
    productWithSpecialDelivery(state): Array<ProductModel> {
        return state.productWithSpecialDelivery;
    },
    listFilesPrescriptions(state) {
        return state.listFilesPrescriptions;
    },
    itemsPrescription(state): Array<CartMedicalPrescription> {
        return state.itemsPrescription;
    },
};
