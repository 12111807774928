<template>
    <base-icon :icon-name="$t('icon.share')" :width="width" :height="height" viewBox="0 0 64 64" :iconColor="color">
        <path
            d="M21.7288 18.2215L28.6479 11.356V47.6739C28.6479 48.5561 29.0011 49.4021 29.6297 50.0258C30.2584 50.6496 31.111 51 32 51C32.889 51 33.7416 50.6496 34.3703 50.0258C34.9989 49.4021 35.3521 48.5561 35.3521 47.6739V11.356L42.2712 18.2215C42.5822 18.5317 42.9518 18.778 43.3588 18.9463C43.7659 19.1146 44.2024 19.2015 44.6434 19.2021C45.0843 19.2027 45.5211 19.1169 45.9286 18.9498C46.3361 18.7826 46.7064 18.5373 47.0182 18.2279C47.33 17.9185 47.5772 17.5511 47.7457 17.1467C47.9142 16.7424 48.0006 16.309 48 15.8715C47.9994 15.4339 47.9118 15.0008 47.7422 14.5969C47.5726 14.193 47.3244 13.8263 47.0117 13.5177L34.3703 0.974246C34.0591 0.665373 33.6895 0.420361 33.2828 0.253199C32.8761 0.0860372 32.4402 0 32 0C31.5598 0 31.1239 0.0860372 30.7172 0.253199C30.3105 0.420361 29.9409 0.665373 29.6297 0.974246L16.9883 13.5177C16.6756 13.8263 16.4274 14.193 16.2578 14.5969C16.0882 15.0008 16.0006 15.4339 16 15.8715C15.9994 16.309 16.0858 16.7424 16.2543 17.1467C16.4228 17.5511 16.67 17.9185 16.9818 18.2279C17.2936 18.5373 17.6639 18.7826 18.0714 18.9498C18.4789 19.1169 18.9157 19.2027 19.3566 19.2021C19.7976 19.2015 20.2341 19.1146 20.6412 18.9463C21.0482 18.778 21.4178 18.5317 21.7288 18.2215V18.2215Z"
        />
        <path
            d="M60.6897 29C59.8117 29 58.9697 29.3457 58.3489 29.9611C57.7281 30.5764 57.3793 31.411 57.3793 32.2812V57.4375H6.62069V32.2812C6.62069 31.411 6.27192 30.5764 5.65111 29.9611C5.0303 29.3457 4.1883 29 3.31034 29C2.43239 29 1.59039 29.3457 0.969578 29.9611C0.348768 30.5764 0 31.411 0 32.2812V58.5312C0 59.9817 0.581279 61.3727 1.61596 62.3982C2.65065 63.4238 4.05398 64 5.51724 64H58.4828C59.946 64 61.3494 63.4238 62.384 62.3982C63.4187 61.3727 64 59.9817 64 58.5312V32.2812C64 31.411 63.6512 30.5764 63.0304 29.9611C62.4096 29.3457 61.5676 29 60.6897 29Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-share-link',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 50 * this.size;
        },
        height(): number {
            return 50 * this.size;
        },
    },
};
</script>
