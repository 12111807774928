<template>
    <section class="summary">
        <aside class="summary-information">
            <menu-checkout />
            <dropdown-retailer-products
                v-for="retailer in cart?.retailers"
                :key="retailer.id"
                :items-prescription="itemsPrescription"
                :retailer="retailer"
                @on-prescription="(val) => loadPrescription.push(val)"
                @on-remove-prescription="(val) => removePrescriptionItem(val)"
            />
            <instructions />
        </aside>
        <aside class="summary--sticky">
            <balance
                :balance="cart?.totalDetails"
                :discounts="cart?.discountApplied"
                :is-pick-up="isPickUp"
                :origin-screen="'CHECKOUT'"
                show-shipping-cost
            />
        </aside>
    </section>
    <teleport to="body">
        <footer class="summary-footer">
            <div class="footer-btn">
                <p v-currency="cart?.totalDetails?.orderCost" class="footer-btn__price" />
                <btn-solid
                    :is-disabled="!hasAllData"
                    :is-loading="isLoading"
                    :txt="$t('txt.checkout__complete-purchase')"
                    @click="
                        hasAllData && !isLoading
                            ? isCreationAvailable()
                                ? checkTiptiCardUsage()
                                    ? null
                                    : createOrder()
                                : (showBlockingAlert = true)
                            : null
                    "
                />
            </div>
        </footer>
    </teleport>
    <general-alert
        v-if="showBlockingAlert"
        :accent-header="true"
        :accept-button-txt="isPendingPayment ? $t('cta.go-payments') : $t('cta.accept')"
        :message="messageBlockingAlert"
        :only-confirmation-btn="true"
        :show-close-icon="true"
        :title="titleBlockingAlert"
        @on-close="showBlockingAlert = false"
        @on-accept="isPendingPayment ? goToPayments() : (showBlockingAlert = false)"
    />
    <creating-order-alert v-if="showCreatingOrder" />
    <alert-tipti-card
        v-if="showTiptiCardAlert"
        @on-use-balance="handleTiptiCardBalance(true)"
        @on-accumulate="handleTiptiCardBalance(false)"
        @on-close="onCloseTiptiCardAlert"
    />
    <snack-bar
        :body="snackBarBody"
        :is-active="showSnackBar"
        :is-success="true"
        :title="$t('txt.checkout__order-not_created')"
        @on-snackbar-close="showSnackBar = false"
    />
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import { HandlerServerError } from '@/utils/handleServerError';
import { logger } from '@/utils/logger';
import { Geolocation, getLocation } from '@/utils/locationUtils';
import { AddressModel } from '@/models/address/AddressModel';
import MenuCheckout from '../../components/menu/MenuCheckout.vue';
import DropdownRetailerProducts from '../../components/dropdownRetailerProducts/DropdownRetailerProducts.vue';
import Instructions from '../../components/instructions/Instructions.vue';
import Balance from '@/views/cart/components/balance/Balance.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import AlertTiptiCard from '@/components/alerts/alertTiptiCard/AlertTiptiCard.vue';
import tiptiCardMixin from '@/mixings/tiptiCardMixin';
import CreatingOrderAlert from '@/components/loading/LoadingOrder.vue';
import { usePayments } from '@/composables/usePayments';
import { ResponseOrderModel } from '@/models/checkout/ResponseOrderModel';
import { OrderState } from '@/enums/orderState';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useBillStore } from '@/store/useBillStore';
import { useOrder } from '@/composables/useOrder';
import { useCart } from '@/composables/useCart';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export default {
    name: 'SummaryCheckout',
    components: {
        CreatingOrderAlert,
        MenuCheckout,
        DropdownRetailerProducts,
        Instructions,
        Balance,
        BtnSolid,
        SnackBar,
        GeneralAlert,
        AlertTiptiCard,
    },
    mixins: [tiptiCardMixin],
    setup() {
        const { fetchQuickCart, hasDeliveryPacking } = useCart();
        const { orderRejected } = useOrder();
        const { retailersAvailability } = useRetailer();
        const { billUsed } = storeToRefs(useBillStore());
        return {
            fetchQuickCart,
            hasPaymentMethodsExpired: computed(() => usePayments().hasPaymentMethodsExpired),
            orderRejected,
            billUsed,
            retailersAvailability,
            hasDeliveryPacking,
        };
    },
    data() {
        return {
            showSnackBar: false,
            position: 'DATA_NO_ALLOWED_BY_USER',
            snackBarBody: '',
            showBlockingAlert: false,
            titleBlockingAlert: '',
            messageBlockingAlert: '',
            showCreatingOrder: false,
            isLoading: false,
            showTiptiCardAlert: false,
            loadPrescription: [],
        };
    },
    computed: {
        ...mapGetters({
            cart: 'cart/cart',
            countrySelected: 'external/countrySelected',
            locationSelected: 'user/userLocation',
            addressDefault: 'location/addressDefault',
            timeDelivery: 'checkout/timeDelivery',
            stockItemTimeDelivery: 'checkout/stockItemTimeDelivery',
            paymentsToShow: 'payments/paymentsToShow',
            isMixInstitutionalPayment: 'payments/isMixInstitutionalPayment',
            isMixPaymentTiptiCard: 'payments/isMixPaymentTiptiCard',
            baseConfiguration: 'user/baseConfiguration',
            institutionSelected: 'payments/institutionPayment',
            tiptiCard: 'tiptiCard/tiptiCard',
            isSelectedSchedule: 'checkout/isSelectedSchedules',
            itemsPrescription: 'cart/itemsPrescription',
            giftCardsToUse: 'giftCardAlert/toShowGiftCards',
            giftCardsIds: 'giftCardAlert/idgGiftCardsToUse',
            responsePurchase: 'checkout/responsePurchase',
            phoneInstruction: 'checkout/phone',
            emailInstruction: 'checkout/email',
        }),
        getAddress(): AddressModel {
            return this.locationSelected?.id ? this.locationSelected : this.addressDefault;
        },
        isPickUp(): boolean {
            return this.locationSelected?.isPickUpMode ?? false;
        },
        differenceToPay(): number {
            return this.isMixPaymentTiptiCard
                ? this.difference(+this.cart?.totalDetails?.totalCost, +this.tiptiCard?.availableAmount)
                : +this.cart?.totalDetails?.totalCost - +this.institutionSelected?.amount;
        },
        hasAllData(): boolean {
            if (this.itemsPrescription.length && !this.allRetailerIdsInLoadPrescription) return false;
            if (this.hasPaymentMethodsExpired.value) return false;
            if (this.hasDeliveryPacking && (!this.emailInstruction?.length || !this.phoneInstruction?.length))
                return false;

            return (
                !this.isLoading &&
                this.getAddress &&
                this.isSelectedSchedule &&
                this.billUsed &&
                (this.paymentsToShow?.length ||
                    (+this.cart?.totalDetails?.totalCost <= 0.01 && this.giftCardsIds?.length)) &&
                this.cart?.retailers.length
            );
        },

        isPendingPayment(): boolean {
            return (this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment) && this.paymentsToShow?.length === 1;
        },
        isCashAvailable(): boolean {
            return this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment
                ? +this.differenceToPay <= +this.baseConfiguration?.limitCashAmount
                : +this.cart?.totalDetails?.totalCost <= +this.baseConfiguration?.limitCashAmount;
        },
        isCreditCardAvailable(): boolean {
            const isInsideLimit: boolean =
                this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment
                    ? this.differenceToPay <= this.baseConfiguration?.globalPaymentLimit
                    : +this.cart?.totalDetails?.totalCost <= this.baseConfiguration?.globalPaymentLimit;
            return +this.cart?.totalDetails?.totalCost >= 1 && isInsideLimit;
        },
        allRetailerIdsInLoadPrescription() {
            const trueDeliveryIds = this.retailersAvailability
                .filter((item) => item.delivery_data)
                .map((item) => item.id);
            const filteredItems = this.itemsPrescription.filter((item) => trueDeliveryIds.includes(item.retailer_id));
            return filteredItems?.every((item) => this.loadPrescription.includes(item.retailer_id));
        },
    },
    async mounted() {
        getLocation().then((location: Geolocation) => {
            this.position = location.geolocation;
        });
    },
    methods: {
        onCloseTiptiCardAlert(): void {
            this.showTiptiCardAlert = false;
        },
        async handleTiptiCardBalance(useTiptiCardBalance: boolean): Promise<void> {
            if (useTiptiCardBalance) this.setPaymentWithTiptiCard();
            this.showTiptiCardAlert = false;
            logger(this.cart?.retailers.length);
            if (this.cart?.retailers.length > 1) await this.createOrder();
        },
        goToPayments() {
            this.showBlockingAlert = false;
            this.$router.push({ name: appRoutesMap.checkout.payments.index });
        },
        isCreationAvailable(): boolean {
            if (this.isPendingPayment) {
                this.titleBlockingAlert = this.isMixPaymentTiptiCard
                    ? this.$t('txt.tipti-card__alert-title')
                    : this.$t('txt.institutional__alert-title');
                this.messageBlockingAlert = this.$t('txt.tipti-card__alert-description', {
                    currency: this.currency,
                    amount: this.differenceToPay.toFixed(2),
                });
                return false;
            }
            if (!this.isCashAvailable && this.paymentsToShow.some((payment) => payment.name === 'payment-cash')) {
                this.titleBlockingAlert = this.$t('txt.cash__not-allowed');
                this.messageBlockingAlert = this.$t('txt.cash__over-limit');
                return false;
            }
            if (
                !this.isCreditCardAvailable &&
                this.paymentsToShow.some((payment) => payment.name === 'payment-cards')
            ) {
                if (+this.cart?.totalDetails?.totalCost < 1) {
                    this.titleBlockingAlert = this.$t('txt.credit-card__not-allowed');
                    this.messageBlockingAlert = this.$t('txt.credit-card__under-limit', { currency: this.currency });
                }
                if (+this.cart?.totalDetails?.totalCost > this.baseConfiguration?.globalPaymentLimit) {
                    this.titleBlockingAlert = this.$t('txt.credit-card__not-allowed');
                    this.messageBlockingAlert = this.$t('txt.credit-card__over-limit', {
                        currency: this.currency,
                        amount: this.baseConfiguration?.globalPaymentLimit,
                    });
                }
                return false;
            }
            if (
                !this.isCreditCardAvailable &&
                this.paymentsToShow.some((payment) => payment.name === 'payment-payphone')
            ) {
                if (+this.cart?.totalDetails?.totalCost < 1) {
                    this.titleBlockingAlert = this.$t('txt.payphone__not-allowed');
                    this.messageBlockingAlert = this.$t('txt..payphone__under-limit', { currency: this.currency });
                }
                if (+this.cart?.totalDetails?.totalCost > this.baseConfiguration?.globalPaymentLimit) {
                    this.titleBlockingAlert = this.$t('txt.payphone__not-allowed');
                    this.messageBlockingAlert = this.$t('txt.payphone__over-limit', {
                        currency: this.currency,
                        amount: this.baseConfiguration?.globalPaymentLimit,
                    });
                }
                return false;
            }
            if (this.cart.shippingCost && !this.paymentsToShow.length) {
                this.titleBlockingAlert = this.$t('txt.payphone__not-allowed');
                this.messageBlockingAlert = this.$t('txt.tipti-card__alert-description', {
                    currency: this.currency,
                    amount: 0,
                });
                return false;
            }
            if (!this.cart.totalCost && this.giftCardsToUse.length) {
                return true;
            }

            return true;
        },
        checkTiptiCardUsage(): boolean {
            if (this.cart?.retailers.length === 1) return false;
            if (
                !this.isTiptiCardPayment &&
                +this.tiptiCard?.availableAmount > 0 &&
                !this.institutionPayment?.id &&
                //// ** Check if difference can be paid with credit card / payphone
                !(
                    +this.tiptiCard?.availableAmount < +this.cart?.totalDetails?.totalCost &&
                    this.paymentIdSelected &&
                    this.difference(+this.tiptiCard?.availableAmount, +this.cart?.totalDetails?.totalCost) < 1
                ) &&
                this.timeDelivery.length
            ) {
                this.showTiptiCardAlert = true;
                return true;
            }
        },
        async createOrder(): Promise<void> {
            try {
                this.isLoading = true;
                this.showCreatingOrder = true;
                await this.$store.dispatch('checkout/createOrder', {
                    totalToPay: +this.cart?.totalDetails?.totalCost,
                    position: this.position,
                    billing_info_id: this.billUsed.id,
                });

                const ordersRejected = this.responsePurchase?.ordersData?.find(
                    (order: ResponseOrderModel) => order.state === OrderState.PAYMENT_REJECTED,
                );
                this.fetchQuickCart();
                if (!ordersRejected) return this.$router.replace({ name: appRoutesMap.home });

                this.orderRejected = {
                    state: ordersRejected.state,
                    orderId: ordersRejected.id,
                    messageToShow: this.responsePurchase?.messagePayment,
                    suggested_payment_method: ordersRejected?.suggested_payment_method,
                    payment_rejected_context: ordersRejected?.payment_rejected_context,
                    final_cost: ordersRejected?.payment_rejected_context?.amount,
                };
            } catch (err) {
                this.snackBarBody = HandlerServerError(
                    err,
                    {
                        429: this.$t('txt.checkout__order-too_many_request'),
                        400: this.$t('general.try-again'),
                    },
                    this,
                );
                this.showSnackBar = true;
                logger('CREATE_ORDER', err);
            } finally {
                this.showCreatingOrder = false;
                this.isLoading = false;
            }
        },
        removePrescriptionItem(val: number) {
            this.loadPrescription = this.loadPrescription.filter((item) => item !== val);
        },
    },

    beforeRouteEnter(to, from, next) {
        if (!from.path.includes('payments-checkout')) return next();
        next(async (vm) => {
            vm.isLoading = true;
            await vm.$store.dispatch('cart/fetchCart');
            vm.isLoading = false;
        });
    },
};
</script>
<style lang="scss" scoped>
@import './summary-checkout';
</style>
