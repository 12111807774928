import { ReferredCodesModel, toReferredCodesModel } from './ReferredCodesModel';

class ReferalModel {
    referredCode: string;
    referredDeepLink: string;
    referredCodes: Array<ReferredCodesModel>;

    constructor(referredCode: string, referredDeepLink: string, referredCodes: Array<ReferredCodesModel>) {
        this.referredCode = referredCode;
        this.referredDeepLink = referredDeepLink;
        this.referredCodes = referredCodes;
    }
}

const toReferalModel = (data: object): ReferalModel => {
    return data
        ? new ReferalModel(
              data['referred_code'],
              data['referred_code_deep_link'],
              data['referred_codes']
                  ? data['referred_codes'].map((code: ReferredCodesModel) => toReferredCodesModel(code))
                  : null,
          )
        : null;
};
export { ReferalModel, toReferalModel };
