import { computed } from 'vue';
import { useStore } from 'vuex';

/**
 * @field phoneNumber: Phone number to send a message
 * @field text: the template text to send
 */
interface OpenWhatsAppInterface {
    phoneNumber?: string;
    text?: string;
}

const useWhatsApp = () => {
    const store = useStore();
    const countrySelected = computed(() => store.state.external.countrySelected);

    const openWhatsApp = (payload?: OpenWhatsAppInterface): void => {
        const whatsappNumber = countrySelected.value.cellphone?.replaceAll(/[+-\s]/g, '');
        const whatsappUrl = `https://wa.me/${payload?.phoneNumber ?? whatsappNumber}/?text=${payload?.text ?? ''}`;
        window.open(whatsappUrl, '_blank');
    };

    return {
        openWhatsApp,
    };
};

export { useWhatsApp };
