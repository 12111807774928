/// *** Rules before write a section
/// * All sections should be in plural
/// * All names with separations should have underscore, SNAKE_CASE, instead of space ex: MY_LISTS
/// * All sections should be wrote with UPPER_CASE
/// ** Write ONLY sections if that section can be related on different places
/// ** analyse another field to that identification

export const enum Sections {
    MY_LISTS = 'MY_LISTS',
    TIPTI_RECIPES = 'TIPTI_RECIPES',
    CAROUSELS = 'CAROUSELS',
    DEEPLINKS = 'DEEPLINKS',
    BANNERS = 'BANNERS',
    BANNERS_IN_SECTION = 'BANNERS_IN_SECTION',
    STORIES = 'STORIES',
    CATEGORIES = 'CATEGORIES',
    HOMES_RETAILER = 'HOMES_RETAILER',
    SUBCATEGORIES = 'SUBCATEGORIES',
    RETAILERS = 'RETAILERS',
    EXTRA_ITEMS = 'EXTRA_ITEMS',
    SUBSCRIPTIONS = 'SUBSCRIPTIONS',
    REPLACEMENTS = 'REPLACEMENTS',
    MAIN_CATEGORIES = 'MAIN_CATEGORIES',
}
