import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-619cbfaf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "slider",
  class: "carousel-items"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_triple_loading = _resolveComponent("triple-loading")!
  const _component_icon_back_no_border = _resolveComponent("icon-back-no-border")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["carousel", { __margin: $props.hasMargin }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      ($props.isLoading)
        ? (_openBlock(), _createBlock(_component_triple_loading, {
            key: 0,
            class: "carousel-items__loading"
          }))
        : _createCommentVNode("", true)
    ], 512),
    ($props.showArrows)
      ? (_openBlock(), _createElementBlock("aside", _hoisted_2, [
          _withDirectives(_createVNode(_component_icon_back_no_border, {
            class: "carousel__arrow-back cursor-pointer",
            "background-fill": $props.backgroundArrow,
            color: $props.colorArrow,
            "icon-name": _ctx.$t('icon.backward'),
            size: 0.8,
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.goBack()))
          }, null, 8, ["background-fill", "color", "icon-name"]), [
            [_vShow, $data.showLeftArrow]
          ]),
          ($data.showRightArrow)
            ? (_openBlock(), _createBlock(_component_icon_back_no_border, {
                key: 0,
                class: "carousel__arrow-next cursor-pointer",
                "background-fill": $props.backgroundArrow,
                color: $props.colorArrow,
                "icon-name": _ctx.$t('icon.forward'),
                size: 0.8,
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.goFoward()))
              }, null, 8, ["background-fill", "color", "icon-name"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}