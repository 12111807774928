<template>
    <base-icon
        :height="height"
        :icon-color="color"
        :icon-name="$t('icon.data_protection')"
        :width="width"
        viewBox="0 0 18 20"
    >
        <path
            :fill="color"
            d="M8.6 10.3263C6.94737 10.3263 5.60842 11.6653 5.60842 13.3179C5.60842 14.9705 6.94737 16.3116 8.6 16.3116C10.2526 16.3116 11.5916 14.9726 11.5916 13.32C11.5916 11.6674 10.2526 10.3263 8.6 10.3263ZM8.6 15.2526C7.53263 15.2526 6.66737 14.3874 6.66737 13.32C6.66737 12.2526 7.53263 11.3874 8.6 11.3874C9.66737 11.3874 10.5326 12.2526 10.5326 13.32C10.5326 14.3874 9.66737 15.2526 8.6 15.2526ZM16.5474 6.63789H14.2653V2.30947C14.2653 1.03579 13.2295 0 11.9558 0H5.24421C3.97053 0 2.93474 1.03579 2.93474 2.30947V6.63789H0.652632C0.294737 6.63789 0 6.93053 0 7.29053V19.3474C0 19.7053 0.292632 20 0.652632 20H16.5474C16.9053 20 17.2 19.7074 17.2 19.3474V7.29053C17.2 6.93053 16.9074 6.63789 16.5474 6.63789ZM4.25474 2.30947C4.25474 1.76421 4.69895 1.32 5.24421 1.32H11.9558C12.5011 1.32 12.9453 1.76421 12.9453 2.30947V6.63789H4.25474V2.30947ZM8.6 16.88C5.96421 16.88 3.66947 15.4463 2.43789 13.3179C3.66947 11.1916 5.96421 9.75789 8.6 9.75789C11.2358 9.75789 13.5305 11.1916 14.7621 13.32C13.5305 15.4463 11.2358 16.88 8.6 16.88Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconDataProtection',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 18 * this.size;
        },
        height(): number {
            return 20 * this.size;
        },
    },
};
</script>
