<template>
    <base-icon :icon-name="$t('icon.referreds')" :width="width" :height="height" viewBox="0 0 39 39" :iconColor="color">
        <path
            d="M36.0683 22.0888C34.765 20.7769 33.0416 19.9658 31.2001 19.7977C32.1458 18.947 32.8684 17.8774 33.3045 16.6824C33.7406 15.4875 33.8768 14.2039 33.7013 12.944C33.5257 11.6842 33.0438 10.4867 32.2976 9.45647C31.5515 8.42628 30.564 7.59489 29.4217 7.03522C28.2795 6.47554 27.0173 6.20467 25.746 6.24637C24.4747 6.28806 23.233 6.64104 22.1299 7.27436C21.0268 7.90768 20.0959 8.80198 19.4188 9.87885C18.7418 10.9557 18.3393 12.1822 18.2467 13.4509C19.5164 13.997 20.6628 14.7938 21.6171 15.7937C22.5715 16.7935 23.3141 17.9756 23.8006 19.2694C24.2871 20.5631 24.5074 21.9417 24.4483 23.3226C24.3892 24.7035 24.0519 26.0583 23.4566 27.3057C25.1506 28.1905 26.5677 29.5253 27.5521 31.1634C28.5365 32.8015 29.0501 34.6794 29.0362 36.5905V36.6933L28.9682 37.9941H38.2844L38.3611 27.6893C38.3677 26.6506 38.1684 25.6209 37.7749 24.6597C37.3813 23.6984 36.8013 22.8247 36.0683 22.0888Z"
        />
        <path
            d="M19.3776 28.6696C20.5483 27.6178 21.3723 26.2356 21.7409 24.7055C22.1095 23.1755 22.0054 21.5697 21.4421 20.1001C20.8789 18.6305 19.8831 17.3664 18.5864 16.4746C17.2896 15.5829 15.7528 15.1055 14.179 15.1055C12.6052 15.1055 11.0684 15.5829 9.77163 16.4746C8.47485 17.3664 7.47909 18.6305 6.91586 20.1001C6.35263 21.5697 6.24844 23.1755 6.61705 24.7055C6.98565 26.2356 7.80971 27.6178 8.98038 28.6696C7.01541 28.822 5.17909 29.7059 3.83441 31.1468C2.48974 32.5877 1.73457 34.4806 1.7182 36.4514L1.64148 37.9998H26.4584L26.5352 36.5682C26.549 34.594 25.8209 32.6865 24.4949 31.2236C23.169 29.7607 21.342 28.8492 19.3759 28.6696H19.3776Z"
        />
        <path
            d="M5.01901 9.2965V12.1822C5.03503 12.747 5.27067 13.2834 5.67586 13.6773C6.08106 14.0711 6.62387 14.2915 7.18895 14.2915C7.75403 14.2915 8.29685 14.0711 8.70204 13.6773C9.10724 13.2834 9.34288 12.747 9.35889 12.1822V9.2965H12.2446C12.8201 9.2965 13.372 9.06788 13.779 8.66094C14.1859 8.254 14.4145 7.70207 14.4145 7.12656C14.4145 6.55106 14.1859 5.99913 13.779 5.59219C13.372 5.18524 12.8201 4.95663 12.2446 4.95663H9.35889V2.10929C9.34288 1.54444 9.10724 1.00809 8.70204 0.614218C8.29685 0.220347 7.75403 0 7.18895 0C6.62387 0 6.08106 0.220347 5.67586 0.614218C5.27067 1.00809 5.03503 1.54444 5.01901 2.10929V4.95488H2.16994C1.59443 4.95488 1.0425 5.1835 0.63556 5.59044C0.228618 5.99739 0 6.54932 0 7.12482C0 7.70032 0.228618 8.25226 0.63556 8.6592C1.0425 9.06614 1.59443 9.29476 2.16994 9.29476H5.01901V9.2965Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-referred',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C7C7C7',
        },
    },
    computed: {
        width(): number {
            return 18 * this.size;
        },
        height(): number {
            return 18 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
