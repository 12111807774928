import { AddressModel } from '@/models/address/AddressModel';
import { BaseConfigurationModel } from '@/models/baseConfiguration/BaseConfigurationModel';
import { RetailerPermissionModel } from '@/models/cart/RetailerPermissionModel';
import { ClientProfileModel } from '@/models/user/ClientProfileModel';

export default {
    isAuth(state: { token: string }): boolean {
        return Boolean(state.token?.length);
    },
    token(state: { token: string }): string {
        return state.token;
    },
    user(state: { user: ClientProfileModel }): ClientProfileModel {
        return state.user;
    },
    userLocation(state: { user: ClientProfileModel }): AddressModel {
        return state.user?.currentAddress;
    },
    userLanguage(state: { user: ClientProfileModel }): string {
        return state.user?.defaultLanguage?.toLowerCase() ?? 'es';
    },
    baseConfiguration(state: { baseConfiguration: BaseConfigurationModel }): BaseConfigurationModel {
        return state.baseConfiguration;
    },
    grantedPermissions(state: { grantedPermissions: Array<RetailerPermissionModel> }): Array<RetailerPermissionModel> {
        return state.grantedPermissions;
    },
    selectedDocumentType(state: { selectedDocumentType: string }): string {
        return state.selectedDocumentType;
    },
    requireAuth(state: { requireAuth: boolean }): boolean {
        return state.requireAuth;
    },
};
