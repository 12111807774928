import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5096fe52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stories-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "profile" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCircularClose = _resolveComponent("IconCircularClose")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_CarouselTipti = _resolveComponent("CarouselTipti")!

  return (_openBlock(), _createBlock(_component_CarouselTipti, { class: "carousel-stories" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.storiesImages, (promotion, index) => {
          return (_openBlock(), _createElementBlock("button", {
            key: index,
            class: "story",
            onClick: ($event: any) => ($options.openModal(promotion.imagen_big))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: promotion.imagen,
                alt: ""
              }, null, 8, _hoisted_4)
            ]),
            ($data.open)
              ? (_openBlock(), _createBlock(_component_base_modal, {
                  key: 0,
                  "has-close": true,
                  "has-header": false,
                  onOnClose: $options.closeModal
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_IconCircularClose, {
                      class: "close-modal",
                      color: $data.color,
                      size: 0.8,
                      onClick: $options.closeModal
                    }, null, 8, ["color", "onClick"]),
                    _createElementVNode("img", {
                      class: "img-big",
                      src: $data.imgBig,
                      alt: ""
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 1
                }, 8, ["onOnClose"]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_2))
        }), 128))
      ])
    ]),
    _: 1
  }))
}