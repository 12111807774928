<template>
    <base-icon :icon-name="$t('icon.tipti')" :width="width" :height="height" viewBox="0 0 77 49" :iconColor="color">
        <path
            d="M28.8032 15.262C29.1354 13.8265 27.5711 11.9349 25.6368 12.2769C23.6646 12.6274 21.9868 12.8554 21.5453 14.7807C21.108 16.6681 28.0169 18.661 28.8032 15.262Z"
        />
        <path
            d="M46.7415 14.8188C46.3084 14.5696 42.5701 14.5485 40.9806 16.0263C39.7695 17.1832 37.6081 21.2957 35.8588 25.1211C37.1035 20.8819 38.0833 17.7743 38.2767 16.9426C38.6342 15.393 36.6241 15.7519 35.1271 16.0179C34.1179 16.1994 32.3013 20.4977 30.7244 25.9318C30.6614 26.0162 30.5941 26.1218 30.5352 26.2484C30.0306 27.2787 25.7036 34.2412 23.8954 35.3601C23.5548 34.0427 23.3151 31.6614 24.2486 27.6249C24.6229 26.0035 25.384 23.694 25.6994 22.3344C26.1367 20.447 21.5111 19.8601 21.1243 21.5195C20.9056 22.4653 20.1066 24.1246 19.0932 28.5073C18.29 31.9823 17.2892 38.9279 22.4152 38.0159C24.7953 37.5979 28.7312 30.9563 29.9297 28.8747C27.4823 38.4719 26.1998 49.796 30.9221 48.9558C32.8943 48.6053 30.3754 47.06 32.6293 37.3192C33.0919 35.3179 33.5923 33.3123 34.1095 31.408C35.7663 27.6756 40.5559 17.407 43.0411 16.9679C44.3825 16.7314 41.1572 33.6289 36.2667 34.4987C34.0591 34.8914 35.9429 35.8963 38.2305 35.4867C46.8929 33.9414 51.0055 17.1368 46.7415 14.8188Z"
        />
        <path
            d="M60.6728 38.198C55.9505 39.0003 56.4425 33.1271 57.6704 28.4657C58.8268 24.0619 59.6762 22.4026 59.9285 21.4568C60.37 19.7847 64.9073 20.5363 64.4069 22.4321C64.0495 23.8001 63.2127 26.1097 62.7837 27.7437C61.7156 31.8055 61.867 34.2249 62.153 35.5718C62.153 35.5718 62.9351 37.818 60.6728 38.198Z"
        />
        <path
            d="M67.0688 17.1122C67.4472 15.6681 65.9754 13.7006 64.0579 13.9835C62.1026 14.2748 60.4416 14.4479 59.9328 16.3817C59.4324 18.2817 66.1689 20.528 67.0688 17.1122Z"
        />
        <path
            d="M34.9092 38.2485C34.9092 38.2485 58.5374 50.5691 69.6851 33.0003C69.6851 33.0003 70.7616 30.8258 72.6034 29.3184C75.1895 27.1989 78.3517 30.0869 76.3879 33.2916C71.5731 41.1534 55.8714 52.2707 34.9092 38.2485Z"
        />
        <path
            d="M68.3265 4.449C66.6529 4.45322 64.1929 4.69811 61.4512 5.20056C61.8339 4.107 62.103 3.28788 62.2081 2.80654C62.8263 -0.0308227 58.5413 -1.46639 57.7507 2.16898C57.612 2.80654 57.0106 4.34766 56.1738 6.42924C55.0721 6.74591 55.3917 6.6488 51.8889 7.89859C51.8889 7.89859 47.6375 9.73527 46.7503 11.6057C46.7503 11.6057 46.2793 13.32 48.4113 12.4206C51.8889 10.9513 52.2 10.9302 54.6432 10.2335C52.7173 15.2031 50.2026 21.5449 49.0631 26.7552C47.2633 35.0097 49.8368 38.7633 52.1958 38.3116C54.5549 37.8598 51.317 37.6824 53.512 27.6123C54.6138 22.5625 58.7263 12.598 60.1182 8.86971C63.7472 8.13081 67.1322 7.70014 69.2516 7.70436C70.6182 7.70859 71.6695 8.02103 71.6401 7.46792C71.5854 6.54746 69.9791 4.44478 68.3265 4.449Z"
        />
        <path
            d="M35.8001 1.54017C34.9171 0.632387 33.8237 0.332606 32.5875 0.260828C32.394 0.248161 32.1964 0.239716 32.003 0.235494C29.1183 0.159493 26.221 0.716832 23.412 1.29528C19.3541 2.13129 10.9145 4.84621 4.36305 9.57092C1.9998 11.2978 0.578489 13.6665 0.187417 15.3554C-0.0186309 16.2632 -0.0564765 17.1921 0.0822907 18.1041C0.229468 19.0795 0.67941 19.8817 1.42791 20.4939C1.82319 20.8148 2.75671 20.9837 2.98379 20.3546C3.18563 19.8057 2.54646 19.5186 2.26893 19.1766C1.89888 18.7248 1.93252 18.0408 2.19323 17.5172C2.63897 16.6263 3.61455 15.9001 4.39248 15.3174C6.78937 13.5272 9.31241 11.9312 11.9574 10.5758C10.3342 17.4919 7.9752 27.1355 7.59674 33.0974C7.50003 34.6343 7.45798 36.1163 8.3032 37.2014C8.66904 37.6701 9.15262 38.0079 9.73292 38.1345C10.5529 38.3119 12.0289 38.1388 12.4536 37.345C12.9077 36.5005 12.5671 35.5167 12.8405 33.1396C12.9582 31.0031 13.2441 28.9891 13.5385 26.878C14.4174 20.5826 16.5325 13.0078 17.588 9.04314L17.9622 7.99179C21.6164 6.66178 25.4178 5.41621 29.2697 4.90954C30.6195 4.7322 31.9903 4.67309 33.3444 4.79554C34.0466 4.85887 34.7447 4.9771 35.4301 5.14599C35.8758 5.25577 36.5192 5.36554 36.721 4.80821C37.1415 3.65131 36.6201 2.38463 35.8001 1.54017Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-tipti',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 77 * this.size;
        },
        height(): number {
            return 49 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
