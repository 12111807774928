<template>
    <section>
        <base-card class="promotions">
            <h2 class="promotions__title">{{ $t("general['promotions']") }}</h2>
            <div class="promotions-body">
                <p class="promotions-body__description">
                    {{ $t("txt['setting__want-receive-notifications']") }}
                </p>
                <!-- TODO: ADD ACTION NEED IT FOR THIS SECTION -->
                <toggle></toggle>
            </div>
        </base-card>
        <base-card class="notifications" padding="0">
            <surplus-card></surplus-card>
            <div v-if="notifications?.length">
                <div
                    v-for="(notification, index) in notifications"
                    :key="index"
                    class="notification"
                    :class="{ 'notification--unseen': !notification.isSeen, 'cursor-pointer': !notification.isSeen }"
                    @click="notification.isSeen ? null : markAsSeen(notification)"
                >
                    <icon-warning></icon-warning>
                    <div class="notification-data">
                        <time :datetime="notification.date">{{ notificationDate(notification.date) }}</time>
                        <h5>{{ notification?.title ?? '' }}</h5>
                        <p>{{ notification?.body ?? '' }}</p>
                    </div>
                </div>
            </div>
            <base-loading v-if="isLoading"></base-loading>
            <no-content
                v-else-if="!notifications?.length && !pendingPayment"
                colorTitle="#A2A2A2"
                class="notifications__no-content"
                :title="$t('txt.notifications__no-content-title')"
                :description="$t('txt.notifications__no-content-description')"
            >
                <icon-bell color="#C1C1C1" :size="1.5"></icon-bell>
            </no-content>
        </base-card>
    </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import BaseCard from '@/components/cards/BaseCard.vue';
import Toggle from '@/components/toggle/Toggle.vue';
import SurplusCard from './components/surplusCard/SurplusCard.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import IconBell from '@/components/icons/IconBell.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import IconWarning from '@/components/icons/IconWarning.vue';
import NotificationModel from '@/models/notifications/NotificationModel';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/en';

export default {
    name: 'notifications-section',
    data() {
        return {
            isLoading: false,
            isSuccess: false,
            isFailure: false,
            snackBarMessage: '',
            showSnackBar: false,
        };
    },
    components: {
        Toggle,
        BaseCard,
        SurplusCard,
        NoContent,
        IconBell,
        BaseLoading,
        IconWarning,
    },
    computed: {
        ...mapGetters({
            notifications: 'notifications/notifications',
            pendingPayment: 'notifications/pendingPayment',
            user: 'user/user',
        }),
    },
    methods: {
        notificationDate(date: string): string {
            return dayjs(date)
                .locale(this.user?.['defaultLanguage']?.toLowerCase())
                .format('HH:mm - D MMM');
        },
        async fetchSurplusPayments(): Promise<void> {
            try {
                this.isLoading = true;
                await this.$store.dispatch('payments/fetchSurplusPayments');
            } finally {
                this.isLoading = false;
            }
        },
        snackBarInfo(data: object): void {
            this.showSnackBar = true;
            this.isSuccess = data['success'];
            this.isFailure = data['failed'];
            this.snackBarMessage = data['msg'];
        },
        markAsSeen(notification: NotificationModel): void {
            this.$store.commit('notifications/updateNotificationAsSeen', notification);
        },
    },
    async mounted() {
        this.$store.commit('notifications/cleanNotifications');
        await this.fetchSurplusPayments();
    },
};
</script>
<style lang="scss" scoped>
@import './notifications-section.scss';
</style>
