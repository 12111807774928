<template>
    <base-icon
        :height="dimensions.height"
        :icon-color="color"
        :icon-name="$t('icon.address')"
        :width="dimensions.width"
        viewBox="0 0 28 28"
    >
        <path
            d="M20.3053 20.6772C20.107 20.6772 19.8095 20.7764 19.8095 21.0739C19.8095 21.2722 19.9087 21.5697 20.2062 21.5697C24.7679 22.0656 27.1479 23.5531 27.1479 24.3464C27.1479 25.5364 22.5862 27.1231 15.0495 27.1231C7.51279 27.1231 2.9511 25.5364 2.9511 24.3464C2.9511 23.5531 5.33111 22.0656 9.8928 21.5697C10.0911 21.5697 10.2895 21.3714 10.2895 21.0739C10.2895 20.8756 10.0911 20.6772 9.79363 20.6772C5.23194 21.2722 2.05859 22.7598 2.05859 24.3464C2.05859 26.1314 6.52112 28.0156 15.0495 28.0156C23.5779 28.0156 28.0404 26.1314 28.0404 24.3464C27.9412 22.7598 24.7679 21.2722 20.3053 20.6772Z"
        />
        <path
            d="M14.9503 25.8339L21.5945 16.2147C24.0737 12.6447 23.7762 7.7855 20.8012 4.51298C17.5287 1.24046 12.2728 1.24046 9.09945 4.51298C6.2236 7.7855 5.82693 12.5455 8.30611 16.1155L14.9503 25.8339ZM15.0495 7.28966C16.7353 7.28966 18.1237 8.678 18.1237 10.3638C18.1237 12.0497 16.7353 13.438 15.0495 13.438C13.3636 13.438 12.0745 12.0497 12.0745 10.3638C11.9753 8.678 13.3636 7.28966 15.0495 7.28966Z"
        />
    </base-icon>
</template>

<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { reactive } from 'vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#606060',
    },
});

const dimensions = reactive({
    width: 50 * props.size,
    height: 50 * props.size,
});
</script>
