import {
    ProductAttributeValueInterface,
    ProductAttributeValueModel,
} from '@/models/product/ProductAttributeValueModel';
import { RemoteLocaleDto, RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';

export interface ProductAttributeInterface {
    readonly id: number;
    readonly order: number;
    readonly name: RemoteLocaleDto;
    readonly use_image: boolean;
    readonly value: Array<ProductAttributeValueInterface>;
}

export class ProductAttributeModel implements ProductAttributeInterface {
    id: number;
    order: number;
    name: RemoteLocaleModel;
    use_image: boolean;
    value: Array<ProductAttributeValueModel>;

    constructor(data: ProductAttributeInterface) {
        this.id = data.id;
        this.order = data.order;
        this.name = RemoteLocaleModel.fromJson(data.name);
        this.use_image = data.use_image ?? false;
        this.value = ProductAttributeValueModel.builderList(data.value);
    }

    static fromJson(data: object): ProductAttributeModel {
        if (!data || !Object.keys(data).length) return null;
        return new ProductAttributeModel(data as ProductAttributeInterface);
    }

    static builderList(data: object): Array<ProductAttributeModel> {
        if (!data) return null;
        return (data as []).map(this.fromJson).filter(product => !!product);
    }
}
