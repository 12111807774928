<template>
    <CarouselTipti class="carousel-stories">
        <div class="stories-container">
            <button
                v-for="(promotion, index) in storiesImages"
                :key="index"
                class="story"
                @click="openModal(promotion.imagen_big)"
            >
                <div class="profile">
                    <img :src="promotion.imagen" alt="" />
                </div>
                <base-modal v-if="open" :has-close="true" :has-header="false" @on-close="closeModal">
                    <IconCircularClose
                        class="close-modal"
                        :color="color"
                        :size="0.8"
                        @click="closeModal"
                    ></IconCircularClose>
                    <img class="img-big" :src="imgBig" alt="" />
                </base-modal>
            </button>
        </div>
    </CarouselTipti>
</template>

<script lang="ts">
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import { logger } from '@/utils/logger';
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';

export default {
    name: 'StoriesPromotions',

    components: { IconCircularClose, CarouselTipti, BaseModal },
    data() {
        return {
            open: false,
            storiesImages: [],
            imgBig: '',
            color: 'white',
        };
    },
    mounted() {
        this.getStoriesPromotions();
    },
    methods: {
        openModal(imgBig: string): void {
            this.imgBig = imgBig;
            this.open = true;
        },
        closeModal(): void {
            this.open = false;
        },
        async getStoriesPromotions(): Promise<void> {
            try {
                this.storiesImages = await this.$store.dispatch('external/getBannersPromotionsStories');
            } catch (err) {
                logger('GET_LIST_BANNER_PROMOTION', err);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'stories-promotions';
</style>
