import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { CambrellaMembershInterface } from '@/modules/cambrella/models/CambrellaMembership';

interface StateInterface {
    cambrellaMembership: CambrellaMembershInterface;
    cambrellasModalWasShowed: boolean;
}

const state: StateInterface = {
    cambrellaMembership: null,
    cambrellasModalWasShowed: false,
};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state,
};
