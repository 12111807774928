<script lang="ts" setup>
import { useWallet } from '@/views/wallet/useWallet';
import { useRouter } from 'vue-router';
import { useTranslate } from '@/composables/UseTranslate';
import { GiftCardRetailerCategoryDTO } from '@/views/wallet/WalletModels';
import GiftCard from '@/components/cards/GiftCard.vue';
import { appRoutesMap } from '@/router/appRoutesMap';
import CarouselForProducts from '@/components/carousels/CarouselForProducts.vue';

defineOptions({
    name: 'TheGiftCardSelctedRetailerWithCategories',
});

const $router = useRouter();
const { exec } = useTranslate();

const {
    giftCardSelected,
    giftCardRetailerCategories,
    giftCardRetailerPrdoductsByCategory,
    giftCardRetailerCategorySelected,
    getGiftCardRetailerCategories,
    getGiftCardRetailerProductsByCategory,
} = useWallet();

await getGiftCardRetailerCategories();
await getGiftCardRetailerProductsByCategory();

const goToSelectedRetailerCategory = (category: GiftCardRetailerCategoryDTO) => {
    giftCardRetailerCategorySelected.value = category;
    $router.push({
        name: appRoutesMap.giftCardSelectedRetailerCategory,
    });
};
</script>

<template>
    <div class="the-gift-card-selected-retailer-with-categories__first-row">
        <GiftCard :card="giftCardSelected" />
        <div class="carousel-width-patch">
            <CarouselForProducts
                :items="giftCardRetailerPrdoductsByCategory[0]"
                :subheader-name="exec(giftCardRetailerCategories[0].translate_information)"
                state="LOAD-ENDED"
                @on-click-subheader="goToSelectedRetailerCategory(giftCardRetailerCategories[0])"
            />
        </div>
    </div>
    <div class="carousel-width-patch">
        <CarouselForProducts
            v-for="(products, index) in giftCardRetailerPrdoductsByCategory.slice(1)"
            :items="products"
            :subheader-name="exec(giftCardRetailerCategories[index + 1].translate_information)"
            state="LOAD-ENDED"
            @on-click-subheader="goToSelectedRetailerCategory(giftCardRetailerCategories[index + 1])"
        />
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-gift-card-selected-retailer-with-categories__first-row {
    display: grid;
    grid-template-columns: 1fr calc(100svw - $margin-lg - 8rem - 450px);
    align-items: center;
    @include tablet {
        grid-template-columns: 1fr calc(100svw - $margin-lg - 350px);
    }
    @include phone {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.carousel-width-patch {
    max-width: calc(100svw - $margin-lg - 7.5rem);
    @include tablet {
        max-width: calc(100svw - $margin-lg);
    }
    @include phone {
        max-width: calc(100svw - $margin-xlg);
    }
}
</style>
