<template>
    <section class="card-tipti-card" :style="{ 'background-image': `url(${tiptiCard?.image})` }">
        <header class="card-tipti-card__header">
            <img v-if="tiptiCard?.logo" class="card-tipti-card__logo" :src="tiptiCard.logo" alt="" />
            <div class="card-tipti-card__balance">
                <p class="card-tipti-card__balance__label">{{ $t("general['available-balance']") }}</p>
                <p v-currency="tiptiCard?.availableAmount" class="card-tipti-card__balance__amount" />
            </div>
        </header>
        <footer class="card-tipti-card__footer">
            <p class="card-tipti-card__footer__client">{{ profile?.name }} {{ profile?.lastname }}</p>
            <p class="card-tipti-card__footer__number">{{ tiptiCard?.cardNumber ?? '' }}</p>
        </footer>
    </section>
</template>

<script lang="ts" setup>
import { useTiptiCard } from '@/composables/useTiptiCard';
import { useUser } from '@/composables/useUser';

defineOptions({
    name: 'CardTiptiCard',
});

const { tiptiCard } = useTiptiCard();
const { profile } = useUser();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.card-tipti-card {
    @include box-shadow;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: $radius-lg;
    justify-content: space-between;
    padding: $padding-md;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 2em;
        @include tablet {
            margin-bottom: 1em;
        }
    }

    &__logo {
        max-width: 4rem;
        @include tablet {
            max-width: 3rem;
        }
    }

    &__balance {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: auto;

        &__label {
            @include overline;
            color: $white;
        }

        &__amount {
            @include headline1;
            font-size: 3rem;
            color: $white;
            text-align: right;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        white-space: nowrap;

        &__client {
            @include body1;
            font-weight: 600;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            color: $white;
        }

        &__number {
            @include body2;
            color: $white;
        }
    }

    &__pattern {
        border-radius: $radius-lg;
        width: 100%;
    }
}
</style>
