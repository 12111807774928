export interface MaxiBalanceInterface {
    readonly status: string;
    readonly current_balance: string;
    readonly reward_type: string;
}

export class MaxiBalanceModel implements MaxiBalanceInterface {
    current_balance: string;
    reward_type: string;
    status: string;

    constructor(data: MaxiBalanceInterface) {
        this.current_balance = (+data?.current_balance ?? 0).toFixed(2);
        this.status = data.status;
        this.reward_type = data.reward_type;
    }

    static fromJson(json: object): MaxiBalanceModel {
        if (!json || !Object.keys(json).length) return;
        return new MaxiBalanceModel(json as MaxiBalanceInterface);
    }
}
