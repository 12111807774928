interface BrowserPayload {
    browser_name: string;
}

export default {
    browser(_) {
        return {
            browser_name: navigator.userAgent,
        };
    },
};
