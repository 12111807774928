<template>
    <section class="coupons-container">
        <coupon class="coupons--margin" section="CODES" />
        <no-content
            v-if="!myCoupons?.length"
            :description="$t('txt.codes__no-nodes-yet')"
            :title="$t('cta.my-coupons')"
        >
            <icon-coupons :size="10" color="#c1c1c1" />
        </no-content>
        <template v-else>
            <subheader-separator :name="$t('cta.my-coupons')" :show-btn="false" />
            <aside class="coupons">
                <base-card v-for="(coupon, index) in myCoupons" :key="index" class="coupon-card">
                    <img
                        v-if="coupon.code?.icon"
                        class="coupon-card__img"
                        :alt="coupon.code?.code"
                        :src="coupon.code.icon"
                    />
                    <icon-tipti v-else class="coupon-card__img" />
                    <div class="coupon-card__middle">
                        <h3 class="coupon-card__title">{{ coupon.code.code }}</h3>
                        <p class="coupon-card__description">{{ coupon.code.description }}</p>
                        <p class="coupon-card__date">{{ coupon.code.validUntil }}</p>
                    </div>
                    <p
                        class="coupon-card__status"
                        :class="{ 'coupon-card--active': !coupon.activated && isAfter(coupon.code.validUntil) }"
                    >
                        {{
                            !coupon.activated && isAfter(coupon.code.validUntil)
                                ? $t("general['available']")
                                : `${$t("general['redeemed']")} ${
                                      coupon.code?.country?.length ? $t("general['in']") : ''
                                  } ${coupon.code?.country}`
                        }}
                    </p>
                </base-card>
            </aside>
        </template>
    </section>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import Coupon from '@/components/inputs/redeem/Coupon.vue';
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import IconTipti from '@/components/icons/IconTipti.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import IconCoupons from '@/components/icons/IconCoupons.vue';
import { useSeoMeta } from '@unhead/vue';
import { useCoupons } from '@/composables/useCoupons';
import { useDays } from '@/composables/useDays';

defineOptions({
    name: 'Coupons',
});

const { t } = useI18n();
useSeoMeta({
    title: t('seo.coupons'),
});
const { isAfter } = useDays();
const { myCoupons, getCoupons } = useCoupons();

await getCoupons();
</script>

<style lang="scss" scoped>
@import './coupons.scss';
</style>
