import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_billing_form = _resolveComponent("billing-form")!
  const _component_bills_as_list = _resolveComponent("bills-as-list")!

  return ($data.showBillingForm)
    ? (_openBlock(), _createBlock(_component_billing_form, {
        key: 0,
        "bill-to-edit": $data.billToEdit,
        "country-id": $data.countryId,
        "is-from-modal": $props.showModal,
        onOnAddSuccess: $options.onAddNewBillData
      }, null, 8, ["bill-to-edit", "country-id", "is-from-modal", "onOnAddSuccess"]))
    : (_openBlock(), _createBlock(_component_bills_as_list, {
        key: 1,
        "is-from-modal": $props.showModal,
        "show-check": $props.showCheck,
        onOpenBillForm: $options.activateBillingForm,
        onOnEditBill: $options.onEditBill,
        onOnCheck: $options.onCheck
      }, null, 8, ["is-from-modal", "show-check", "onOpenBillForm", "onOnEditBill", "onOnCheck"]))
}