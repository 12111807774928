<template>
    <div class="radio-normal">
        <input type="radio" :id="value" :name="radioGroup" @input="$emit('on-check', value)" :checked="isSelected" />
        <label :for="value"> </label>
    </div>
</template>

<script>
export default {
    name: 'radio-normal',
    props: {
        radioGroup: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-check'],
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.radio-normal {
    position: relative;
}
input {
    margin: 0;
    margin-right: $margin-sm;
}

input[type='radio']:checked,
input[type='radio']:not(:checked) {
    position: relative;
    z-index: 1;
    transform: scale(2);
    opacity: 0;
    cursor: pointer;
}
input[type='radio']:checked + label,
input[type='radio']:not(:checked) + label {
    display: inline-block;
}
input[type='radio']:checked + label:before,
input[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: $white;
}
input[type='radio']:checked + label:before {
    border: 1px solid $orange;
}
input[type='radio']:checked + label:after,
input[type='radio']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $orange;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
input[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
input[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.radio--vertical {
    flex-direction: column;
    margin-right: 0;
    margin-top: $margin-md;
}
</style>
