<template>
    <section v-if="hoursSaved?.length" class="container">
        <div class="hours-saved">
            <div></div>
            <section class="hours-saved__content">
                <h2 class="hours-saved__header">{{ $t("alerts['purchase__hours_saved_with']") }}</h2>
                <icon-tipti class="alert_purchase__tipti_logo" :size="0.5" />
                <h2 class="alert-purchase__hours-saved-header-title">
                    {{ $t("alerts['purchase__hours_saved_earn']") }}
                </h2>
            </section>
            <button
                class="cursor-pointer"
                style="all: unset"
                @click="showMoreInformationMobile"
                @mouseover="!isMobile ? (showMoreInformation = true) : null"
            >
                <icon-info class="hours-saved__icon" :size="0.8" color="#ff9012" />
            </button>
        </div>
        <h2 class="alert-purchase__hours-saved-hours">
            {{ $t('alerts.purchase__hours_saved_hours', +hoursSaved ?? 1, { count: +hoursSaved ?? 1 }) }}
        </h2>
        <h2 class="alert-purchase__hours-saved-footer">
            {{ $t("alerts['purchase__hours_saved_message']") }}
        </h2>
        <div
            v-if="showMoreInformation"
            class="alert-purchase__hours-saved-info-msg"
            @click="handleShowInformation"
            @mouseout="!isMobile ? (showMoreInformation = false) : null"
        >
            <h3 class="alert-purchase__hours-saved-info-msg-detail">
                {{ $t("alerts['purchase__hours_saved_with']") }}
            </h3>
            <icon-tipti class="alert_purchase__tipti_logo" :size="0.3" />
            <h4 class="alert-purchase__hours-saved-info-msg-detail">
                {{ $t("alerts['purchase__hours_saved_info']") }}
                <span class="alert-purchase__hours-saved-info-msg-highlighted">
                    {{ $t("alerts['purchase__hours_saved_info2']") }}
                </span>
                {{ $t("alerts['purchase__hours_saved_info3']") }}
            </h4>
        </div>
    </section>
</template>

<script>
import { isMobileBrowser } from '@/utils/utils';
import IconTipti from '@/components/icons/IconTipti';
import IconInfo from '@/components/icons/IconInfo';

export default {
    name: 'HoursSaved',
    components: {
        IconInfo,
        IconTipti,
    },
    props: {
        hoursSaved: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showMoreInformation: false,
        };
    },
    computed: {
        isMobile() {
            return isMobileBrowser();
        },
    },
    methods: {
        showMoreInformationMobile() {
            if (!this.isMobile) return;
            this.showMoreInformation = !this.showMoreInformation;
        },
        handleShowInformation() {
            if (this.isMobile && this.showMoreInformation) this.showMoreInformation = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './hours_saved.scss';
</style>
