import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.client_profile'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 18 18",
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M8.89706 0C7.13739 0 5.41724 0.521805 3.95412 1.49943C2.49101 2.47705 1.35065 3.86658 0.677251 5.4923C0.00385383 7.11802 -0.172336 8.90693 0.170959 10.6328C0.514254 12.3586 1.36161 13.9439 2.60589 15.1882C3.85017 16.4325 5.43547 17.2799 7.16133 17.6232C8.8872 17.9664 10.6761 17.7903 12.3018 17.1169C13.9275 16.4435 15.3171 15.3031 16.2947 13.84C17.2723 12.3769 17.7941 10.6567 17.7941 8.89706C17.7915 6.5382 16.8534 4.27669 15.1854 2.60873C13.5174 0.94076 11.2559 0.0025691 8.89706 0ZM13.1757 10.5107C12.5745 10.0902 11.9144 9.76093 11.2168 9.5336C11.8512 9.04857 12.3176 8.37687 12.5504 7.61298C12.7832 6.84909 12.7707 6.03144 12.5147 5.27501C12.2587 4.51859 11.772 3.86144 11.1231 3.396C10.4741 2.93056 9.69564 2.68024 8.89706 2.68024C8.09848 2.68024 7.31999 2.93056 6.67107 3.396C6.02215 3.86144 5.53544 4.51859 5.27941 5.27501C5.02338 6.03144 5.0109 6.84909 5.24372 7.61298C5.47654 8.37687 5.94295 9.04857 6.57736 9.5336C4.77785 10.1218 3.26807 11.3701 2.35206 13.0269C1.61246 11.856 1.20054 10.508 1.15925 9.12367C1.11797 7.73932 1.44883 6.36924 2.11734 5.15629C2.78586 3.94335 3.76753 2.93196 4.96001 2.22759C6.15249 1.52322 7.51209 1.15166 8.89706 1.15166C10.282 1.15166 11.6416 1.52322 12.8341 2.22759C14.0266 2.93196 15.0083 3.94335 15.6768 5.15629C16.3453 6.36924 16.6762 7.73932 16.6349 9.12367C16.5936 10.508 16.1817 11.856 15.4421 13.0269C14.8899 12.0262 14.1153 11.1657 13.1782 10.5115L13.1757 10.5107Z",
        fill: $props.color
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "iconColor"]))
}