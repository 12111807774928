<template>
    <div class="content-bagde" v-if="name">
        <p>{{ name }}</p>
    </div>
</template>

<style lang="scss" scoped>
@import './badge-name.scss';
</style>

<script lang="ts">
export default {
    props: {
        name: {
            type: String,
        },
    },
};
</script>
