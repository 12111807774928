<template>
    <base-modal :title="$t('cta.add-note')" :z-index="5" header-text-color="#ff9012" @on-close="closeModal">
        <div class="note">
            <icon-note :size="4" color="#FF9012" />
            <p v-if="hasTitleAndPlaceholderOverride" class="note__title">{{ noteTitle }}</p>
            <TextAreaNote
                :is-loading-external="isLoading"
                :placeholder="notePlaceholder"
                :product="initialProduct"
                is-from-modal
                @on-save="saveNote"
                @on-close="$emit('on-close')"
            />
        </div>
    </base-modal>
    <alert-note-not-save
        v-if="showAlertNotSaveNote"
        @on-accept="saveNote"
        @on-discard="closeModal(true)"
        @on-close="showAlertNotSaveNote = false"
    />
</template>
<script lang="ts">
import { ProductModel } from '@/models/product/ProductModel';
import { mapGetters } from 'vuex';

import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import IconNote from '@/components/icons/IconNote.vue';
import TextAreaNote from '@/components/inputs/textArea/TextAreaNote.vue';
import { useTranslate } from '@/composables/UseTranslate';
import { RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';
import { cleanNote } from '@/utils/productUtils';
import AlertNoteNotSave from '@/components/alerts/alertNoteNotSave/AlertNoteNotSave.vue';
import { logger } from '@/utils/logger';
import { PropType } from 'vue';

export default {
    name: 'NoteModal',
    props: {
        initialProduct: {
            type: Object as PropType<ProductModel>,
            required: true,
        },
        shopperNoteTitle: {
            type: Object as PropType<RemoteLocaleModel>,
            default: undefined,
        },
        shopperNotePlaceholder: {
            type: Object as PropType<RemoteLocaleModel>,
            default: undefined,
        },
    },
    setup() {
        const { translate } = useTranslate();
        return {
            translate,
        };
    },
    data() {
        return {
            isLoading: false,
            showAlertNotSaveNote: false,
        };
    },
    computed: {
        ...mapGetters({
            retailerIdsWithtimeDelivery: 'checkout/retailerIdsWithtimeDelivery',
            tmpNote: 'product/tmpNote',
            tmpFile: 'product/tmpFile',
            userLanguage: 'user/userLanguage',
        }),
        hasTitleAndPlaceholderOverride(): boolean {
            return this.noteTitle?.length && this.notePlaceholder?.length;
        },
        noteTitle(): string {
            return this.translate({ recordable: this.shopperNoteTitle });
        },
        notePlaceholder(): string {
            return this.translate({ recordable: this.shopperNotePlaceholder });
        },
    },
    components: {
        AlertNoteNotSave,
        BaseModal,
        IconNote,
        TextAreaNote,
    },
    emits: ['on-close'],
    methods: {
        async saveNote(): Promise<void> {
            try {
                if (this.isLoading) return;
                this.isLoading = true;
                this.showAlertNotSaveNote = false;
                const product = { ...this.initialProduct };
                product.note = this.tmpNote;
                product.noteToShopperPhotoFile = this.tmpFile;
                await this.$store.dispatch('cart/addEditProduct', { product: product, screen: this.$route.path });
                await this.$store.dispatch('cart/fetchCart', { retailerIds: this.retailerIdsWithtimeDelivery });
                this.$emit('on-close');
            } catch (err) {
                logger('SAVE_NOTE', err);
            } finally {
                this.isLoading = false;
            }
        },
        closeModal(isFromAlertNoteUnsaved = false) {
            if (!isFromAlertNoteUnsaved)
                if (this.tmpNote?.toLowerCase() !== cleanNote(this.initialProduct.note)) {
                    this.showAlertNotSaveNote = true;
                    return;
                }
            this.showAlertNotSaveNote = true;
            this.$store.commit('product/tmpNote', '');
            this.$store.commit('product/tmpFile', null);
            this.$emit('on-close');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.note {
    padding: $padding-lg;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25rem;
    @include phone {
        width: 20rem;
    }

    &__title {
        @include caption;
        margin: $margin-md $margin-none;
        color: $error;
        text-align: center;
    }
}

.note__btn {
    margin-top: $margin-md;
}
</style>
