import { resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-410d6bdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tipti-prime-card__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconStarPrime = _resolveComponent("IconStarPrime")!
  const _component_IconPrime = _resolveComponent("IconPrime")!

  return (_openBlock(), _createElementBlock("button", {
    class: "tipti-prime-card",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.goToPrime && $options.goToPrime(...args)))
  }, [
    _createElementVNode("i", null, [
      _createVNode(_component_IconStarPrime, { size: 1 }, {
        extra: _withCtx(() => []),
        _: 1
      })
    ]),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('general.tipti_card_prime_benefits')), 1),
    _createVNode(_component_IconPrime, {
      class: "tipti-prime-card__icon-prime",
      size: 1.8,
      color: "white"
    })
  ]))
}