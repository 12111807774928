import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_whats_app = _resolveComponent("icon-whats-app")!
  const _component_btn_out_line = _resolveComponent("btn-out-line")!

  return (_openBlock(), _createBlock(_component_btn_out_line, {
    onClick: $options.redirectToWhatsApp,
    bgColor: "#fff",
    color: "#ff9012",
    txt: _ctx.$t('cta.communicate_with_advisor'),
    borderRadius: "7px",
    "with-icon": ""
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_icon_whats_app, { color: "#FF9012" })
    ]),
    _: 1
  }, 8, ["onClick", "txt"]))
}