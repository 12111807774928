import { createStore, ModuleTree } from 'vuex';
import SecureLS from 'secure-ls';

import NetworkModule from './modules/network';
import LocationModule from './modules/location';
import UserModule from './modules/user';
import ProductModule from './modules/product';
import CartModule from './modules/cart';
import ExternalModule from './modules/external';
import ListsModule from './modules/lists';
import CategoriesModule from './modules/categories';
import RetailersModule from '../modules/retailers/store';
import SearchModule from './modules/search';
import createPersistedState from 'vuex-persistedstate';
import OrdersModule from './modules/orders';
import PromotionModule from './modules/promotions';
import PaymentsModule from './modules/payments';
import TiptiCardModule from './modules/tiptiCard';
import GiftCardAlertModule from './modules/giftCardsAlerts';
import CheckoutModule from './modules/checkout';
import DeeplinkModule from './modules/deeplink';
import ExtraItemsModule from '../modules/extraItems/store';
import StoriesModule from './modules/stories';
import AlertNewsModule from './modules/alertNews';
import NotificationsModule from './modules/notifications';
import SegmentModule from './modules/segment';
import ShareDataModule from './modules/shareData';
import NavigationModule from './modules/navigation';
import App from '../modules/core/store';
import loyaltyAndRewards from './modules/loyaltyAndRewards';
import AlertsModule from './modules/alerts';
import MaxiDollars from './modules/maxiDollars';
import Cambrellas from '../modules/cambrella/store';

const ls = new SecureLS({ isCompression: false });
const modules: ModuleTree<unknown> = {
    cart: CartModule,
    lists: ListsModule,
    product: ProductModule,
    location: LocationModule,
    external: ExternalModule,
    user: UserModule,
    network: NetworkModule,
    retailers: RetailersModule,
    orders: OrdersModule,
    categories: CategoriesModule,
    payments: PaymentsModule,
    search: SearchModule,
    promotions: PromotionModule,
    tiptiCard: TiptiCardModule,
    giftCardAlert: GiftCardAlertModule,
    checkout: CheckoutModule,
    deeplink: DeeplinkModule,
    extraItems: ExtraItemsModule,
    stories: StoriesModule,
    alertNews: AlertNewsModule,
    notifications: NotificationsModule,
    segment: SegmentModule,
    shareData: ShareDataModule,
    navigation: NavigationModule,
    app: App,
    loyaltyAndRewards: loyaltyAndRewards,
    alerts: AlertsModule,
    maxiDollars: MaxiDollars,
    cambrellas: Cambrellas,
};

export default createStore({
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],
    modules: modules,
    strict: false,
});
