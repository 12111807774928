<template>
    <skeleton-categories v-if="isLoading" />
    <div v-else class="categories">
        <base-card
            v-for="category in categories"
            :key="category.id"
            class="category"
            padding="0"
            @click="openCategory(category)"
        >
            <h2 class="category__title">{{ category.name }}</h2>
            <img class="category__img" :src="category.icon" alt="" @error="displayDefaultImg" />
        </base-card>
    </div>
</template>

<script lang="ts" setup>
import { CategoryModel, SubCategories } from '@/models/product/CategoryModel';
import { logger } from '@/utils/logger';
import BaseCard from '@/components/cards/BaseCard.vue';
import SkeletonCategories from '@/components/skeletons/skeletonCategories/SkeletonCategories.vue';
import { useImage } from '@/composables/ImageComposable';
import { computed, ref } from 'vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { useApp } from '@/composables/useApp';
import { appRoutesMap } from '@/router/appRoutesMap';

const { $store, $router, $route } = useApp();
const { displayDefaultImg } = useImage();
const { retailerSelected } = useRetailer();

const isLoading = ref<boolean>(true);
const categories = ref<CategoryModel[]>([]);
const allSubCategories = computed<SubCategories[]>(() => $store.getters['categories/allSubCategories']);

const fetchCategories = async (): Promise<void> => {
    try {
        categories.value = await $store.dispatch('categories/categories', retailerSelected.value?.id);
    } catch (err) {
        logger('FETCH_CATEGORIES', err);
    } finally {
        isLoading.value = false;
    }
};

fetchCategories();

const openCategory = (category: CategoryModel) => {
    $store.commit('categories/setCategorySelected', category);

    const setSubCategory = allSubCategories.value.find((cat) => cat.categoryId === category.id);
    $store.commit('categories/subcategoriesList', setSubCategory?.subcategories ?? []);

    $store.dispatch('segment/categoryViewed', {
        category: {
            category: category?.name,
            category_id: category?.id,
        },
        origin: {
            screen: $route.path,
            section: category?.name,
        },
    });
    if (!category.hasSubcategories) {
        $router.push({
            name: appRoutesMap.list.listCategoryProducts,
            query: {
                name: category.name,
                categoryId: category.id,
                hasReturn: true,
            },
        });
        return;
    }
    $router.push({
        name: appRoutesMap.list.listCategoryWithSubcategory,
        params: {
            id: category.id,
            name: category.name,
        },
    });
};
</script>
<style lang="scss" scoped>
@import './categories-grid.scss';
</style>
