import { toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bd7696f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tipti-card__content" }
const _hoisted_2 = {
  key: 0,
  class: "credit-card__amount"
}
const _hoisted_3 = {
  key: 1,
  class: "alert-purchase__divider"
}
const _hoisted_4 = { class: "credit-card__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_horizontal_divider = _resolveComponent("horizontal-divider")!
  const _component_body_credit_card = _resolveComponent("body-credit-card")!
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_section_footer = _resolveComponent("section-footer")!
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showConfirmButton)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('txt.tipti-prime__your-amount')) + " ", 1),
          _withDirectives(_createElementVNode("span", null, null, 512), [
            [_directive_currency, $props.amount]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showConfirmButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_horizontal_divider)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_body_credit_card, { onShowCardAdded: $options.onShowAddNewCard }, null, 8, ["onShowCardAdded"])
    ]),
    _createVNode(_component_section_footer, {
      class: "footer__select-card",
      "with-shadow": false
    }, {
      default: _withCtx(() => [
        (_ctx.showConfirmButton)
          ? (_openBlock(), _createBlock(_component_btn_out_line, {
              key: 0,
              "is-disabled": !$options.canSubscribe,
              txt: _ctx.$t('cta.confirm'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.canSubscribe ? $options.onClose() : null))
            }, null, 8, ["is-disabled", "txt"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}