import { useStore } from 'vuex';
import { computed } from 'vue';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import { useCart } from '@/composables/useCart';
import { useUser } from '@/composables/useUser';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { usePickupStore } from '@/store/usePickupStore';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

interface UsePickUpInterface {
    showButtonOption?: boolean;
}

export const usePickUp = (data?: UsePickUpInterface) => {
    const $store = useStore();
    const { t } = useI18n();
    const { cart } = useCart();
    const { userLocation } = useUser();
    const { retailerSelected } = useRetailer();
    const { temporalRetailer } = storeToRefs(usePickupStore());

    const canShowDeliveryOptions = computed<boolean>(() => {
        if (!data.showButtonOption)
            return (
                cart.value?.retailerIds?.length === 1 &&
                !!cart.value?.retailers.find((retailer) => retailer.isPickupAvailable)
            );
        if (cart.value?.retailerIds?.length > 1) return false;
        if (!cart.value?.retailerIds?.length && retailerSelected.value?.isPickupAvailable) return true;

        return (
            !!cart.value?.retailerIds?.find((id) => id === retailerSelected.value.id) &&
            retailerSelected.value?.isPickupAvailable
        );
    });

    const retailerPickup = computed<RetailerModel>(() => {
        const retailer = data.showButtonOption ? retailerSelected.value : cart.value?.retailers[0];
        return new RetailerModel(
            retailer.id,
            retailer.isPickupAvailable,
            undefined,
            undefined,
            retailer.name,
            retailer.logo,
            undefined,
            undefined,
            undefined,
            retailer.primaryColor,
            retailer.secondaryColor,
        );
    });

    const retailerWasChanged = computed<boolean>(() => {
        return temporalRetailer.value?.id === retailerSelected.value?.id;
    });

    const deliveryModeText = computed<string>(() => {
        return userLocation.value?.isPickUpMode ? t('general.pickup-time') : t('general.delivery-time');
    });

    const isPickup = computed(() => userLocation.value.isPickUpMode);

    return {
        retailerPickup,
        canShowDeliveryOptions,
        retailerWasChanged,
        deliveryModeText,
        isPickup,
    };
};
