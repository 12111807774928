<template>
    <base-icon :icon-name="$t('icon.orders')" :width="width" :height="height" viewBox="0 0 31 31" :iconColor="color">
        <path
            d="M9.43603 25.5393L10.6011 10.8652C10.6572 10.1793 10.9687 9.53948 11.4741 9.07227C11.9795 8.60505 12.6418 8.3445 13.3301 8.34229H14.772C14.8897 7.47847 15.1971 6.65127 15.672 5.92017H15.3721V5.82031C15.3707 4.48447 14.8395 3.20371 13.895 2.25903C12.9505 1.31435 11.6698 0.782838 10.334 0.78125C8.99813 0.782838 7.71756 1.31435 6.77306 2.25903C5.82857 3.20371 5.29733 4.48447 5.29601 5.82031V5.92017H2.99596C2.53714 5.92213 2.09581 6.09611 1.75902 6.40771C1.42224 6.71932 1.21454 7.14599 1.17699 7.60327L0.0130525 22.2742C-0.0309423 22.8572 0.0455571 23.443 0.237906 23.9951C0.430255 24.5472 0.73425 25.054 1.13097 25.4834C1.52882 25.9118 2.01062 26.2536 2.54638 26.4875C3.08213 26.7215 3.66037 26.8425 4.24499 26.8433H9.50402C9.42613 26.4132 9.40329 25.9751 9.43603 25.5393ZM6.82201 5.81738C6.82307 4.88644 7.19325 3.99382 7.85143 3.33545C8.50961 2.67708 9.40206 2.3065 10.333 2.30518C11.2639 2.3065 12.1564 2.67708 12.8146 3.33545C13.4727 3.99382 13.8429 4.88644 13.844 5.81738V5.91724H6.82104L6.82201 5.81738Z"
        />
        <path
            d="M30.988 25.6064L29.824 10.9395C29.7869 10.4818 29.5795 10.0547 29.2427 9.74268C28.9058 9.43066 28.4641 9.2564 28.005 9.25439H25.7051V9.1543C25.7037 7.81845 25.1725 6.5377 24.228 5.59302C23.2835 4.64834 22.0028 4.11682 20.667 4.11523C19.3311 4.11682 18.0504 4.64834 17.1059 5.59302C16.1615 6.5377 15.6303 7.81845 15.629 9.1543V9.25439H13.329C12.8702 9.25636 12.4288 9.43034 12.092 9.74194C11.7552 10.0535 11.5475 10.48 11.51 10.9373L10.3461 25.6084C10.3021 26.1914 10.3786 26.7772 10.5709 27.3293C10.7633 27.8814 11.0673 28.3879 11.464 28.8174C11.8618 29.2456 12.3435 29.5873 12.879 29.8213C13.4146 30.0553 13.9926 30.1763 14.577 30.1772H26.756C27.3406 30.1765 27.9188 30.0555 28.4546 29.8215C28.9903 29.5876 29.4721 29.2457 29.87 28.8174C30.2671 28.3878 30.5713 27.8809 30.7637 27.3284C30.956 26.7759 31.0324 26.1898 30.988 25.6064ZM17.156 9.15039C17.1571 8.21945 17.5272 7.32683 18.1854 6.66846C18.8436 6.01009 19.736 5.63975 20.667 5.63843C21.5979 5.63975 22.4904 6.01009 23.1486 6.66846C23.8067 7.32683 24.1769 8.21945 24.178 9.15039V9.25024H17.155L17.156 9.15039Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'two-bags',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 31 * this.size;
        },
        height(): number {
            return 31 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
