<template>
    <div class="input-amount">
        <p class="input-amount__txt">{{ currency }}</p>
        <input dir="rtl" :value="moneyValue" type="text" placeholder="00,00" @keydown="valueMoney" />
        <p class="input-amount__txt">{{ currencyName }}</p>
    </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

export default {
    name: 'input-amount',
    emits: ['amount-value'],
    data() {
        return {
            moneyValue: '',
            money: '',
        };
    },
    props: {
        totalNumbers: {
            type: Number,
            default: 4,
        },
    },
    computed: {
        ...mapGetters({ countrySelected: 'external/countrySelected' }),
        currency(): string {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        currencyName(): string {
            return this.countrySelected?.currencyName ?? 'USD';
        },
        commaPosition(): number {
            const value = this.totalNumbers - 2;
            return value > 0 ? value : 0;
        },
    },
    methods: {
        valueMoney(event): void {
            event.preventDefault();
            if (isNaN(parseInt(event.key)) && event.key !== 'Backspace') {
                return;
            }
            let auxArray;
            this.money = this.money.replace(',', '');
            //*** handle when a user press delete
            if (event.key === 'Backspace') {
                this.money = this.money.substring(0, this.money.length - 1);
                const fillLeftZeros = this.money.padStart(this.totalNumbers, '0');

                //*** handle when all numbers are zero
                if (fillLeftZeros === '0'.padStart(this.totalNumbers, '0')) {
                    this.money = '';
                    this.moneyValue = '';
                    this.$emit('amount-value', this.money);
                    return;
                }

                auxArray = fillLeftZeros.split('');
                auxArray.splice(this.commaPosition, 0, ',');
                this.moneyValue = auxArray.join('');
                this.money = this.moneyValue;
                this.$emit('amount-value', this.money);
            }

            //*** handle to allow a new value => remove first zero
            if (this.money[0] === '0') {
                auxArray = this.money.split('');
                auxArray.shift();
                this.money = auxArray.join('');
            }

            if (this.money.length < this.totalNumbers && event.key !== 'Backspace') {
                this.money = this.money + '' + event.key;
            } else {
                return;
            }
            this.money = this.money.replace(',', '').padStart(this.totalNumbers, '0');
            auxArray = this.money.split('');
            auxArray.splice(this.commaPosition, 0, ',');
            this.moneyValue = auxArray.join('');
            this.money = this.moneyValue;
            this.$emit('amount-value', this.money);
        },
    },
};
</script>

<style scoped lang="scss">
@import 'input-amount';
</style>
