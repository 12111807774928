<template>
    <div class="scrollable" />
</template>

<script>
export default {
    name: 'scrollable-observer',
    props: ['options'],
    data: () => ({
        observer: null,
    }),
    mounted() {
        const options = this.options || {};
        this.observer = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting) {
                this.$emit('intersect');
            }
        }, options);
        this.observer.observe(this.$el);
    },
    unmounted() {
        this.observer.disconnect();
    },
};
</script>
