<template>
    <base-modal :title="membership.name" auto-size has-close no-scroll @on-close="$emit('on-close')">
        <div class="cambrellas">
            <section class="cambrellas__content">
                <img class="cambrellas__img" :src="membership.icon" alt="" @error="displayDefaultImg" />
                <p v-translate="membership.description" class="cambrellas__description" />
            </section>
            <section-footer class="cambrellas__footer" :has-border="false" :with-shadow="false" vertical-padding="0">
                <btn-solid
                    class="cambrellas__btn"
                    :txt="exec(props.membership.button_text)"
                    @click="$emit('on-subscribe')"
                />
                <btn-text
                    class="cambrellas__btn-txt"
                    :text="exec(props.membership.link_terms_and_conditions_text)"
                    is-normal-weight
                    is-underline
                    @click="showAlertPdf = true"
                />
            </section-footer>
        </div>
    </base-modal>
    <alert-pdf
        v-if="showAlertPdf"
        :title="membership.name"
        :url="membership.terms_and_conditions"
        accent-header
        has-background
        @close="showAlertPdf = false"
    />
</template>
<script lang="ts" setup>
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnText from '@/components/buttons/BtnText.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import { useImage } from '@/composables/ImageComposable';
import { MembershipType } from '@/modules/cambrella/models/MembershipType';
import { useTranslate } from '@/composables/UseTranslate';
import AlertPdf from '@/components/alerts/alertPdf/AlertPdf.vue';
import { PropType, ref } from 'vue';

const props = defineProps({
    membership: {
        type: Object as PropType<MembershipType>,
        required: true,
    },
});
defineEmits<{
    (e: 'on-subscribe'): void;
    (e: 'on-close'): void;
}>();
const showAlertPdf = ref(false);
const { displayDefaultImg } = useImage();
const { exec } = useTranslate();
</script>
<style lang="scss" scoped>
@import 'cambrella.scss';
</style>
