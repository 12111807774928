import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    subcategories: [],
    categorySelected: null,
    subcategorySelected: null,
    isFromSeeMoreBtn: false,
    subcategoriesList: [],
    promotionSubcategories: [],
    categoryProducts: [],
    isSearchCategoryProducts: false,
    isMobileCategoryMenuOpen: false,
    categories: [],
    customCategory: undefined,
    allSubCategories: [],
    lastRetailIdFetched: 0,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
