<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed, ref } from 'vue';
import TheCardNews from '@/views/newsPress/components/TheCardNews.vue';
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';
import { useStore } from 'vuex';
import type { PressReleases, TypePressReleases } from '@/models/external/PressReleases';
import SkeletonNews from '@/views/newsPress/components/SkeletonNews.vue';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    typePressReleases: {
        type: String as PropType<TypePressReleases>,
        required: true,
    },
});

const $store = useStore();
const isLoading = ref(false);
const page = ref(1);
const items = ref<PressReleases[]>([]);
const isLastPage = ref(false);
const getListReleases = async () => {
    if (isLoading.value || isLastPage.value) return;
    isLoading.value = true;
    const { results, next } = await $store.dispatch('external/getPressReleases', {
        page: page.value,
        type: props.typePressReleases,
    });
    isLoading.value = false;
    if (!results) return;
    page.value = page.value + 1;
    items.value = [...items.value, ...results];
    isLastPage.value = !next;
};

getListReleases();

const showSeeMore = computed(() => !isLastPage.value && items.value.length);
</script>

<template>
    <section class="news-media">
        <p v-if="items.length" class="news-media__title">{{ title }}</p>
        <section class="news-media__carousel-mobile">
            <CarouselTipti
                :is-last-page="isLastPage"
                background-arrow="#ffc788"
                color-arrow="white"
                @reached-end="getListReleases"
            >
                <template v-if="items.length">
                    <TheCardNews v-for="item in items" :key="item.id" :news="item" />
                </template>
                <template v-if="isLoading">
                    <SkeletonNews v-for="i in 6" :key="i" />
                </template>
            </CarouselTipti>
        </section>

        <section class="news-media__content">
            <TheCardNews v-for="item in items" :key="item.id" :news="item" />
            <template v-if="isLoading">
                <SkeletonNews v-for="i in 6" :key="i" />
            </template>
        </section>

        <u v-if="showSeeMore" class="news-media__see-more cursor-pointer" @click="getListReleases">
            {{ $t("general['see-more-newsletters']") }}
        </u>
    </section>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.news-media {
    margin: style.$margin-md;

    &__title {
        @include style.headline6;
        color: style.$dark-grey;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(auto-fill, 19rem);
        gap: 0.5rem;
        place-content: center;
        @include style.phone {
            display: none;
        }
    }

    &__see-more {
        display: flex;
        justify-content: center;
        @include style.subtitle1;
        color: style.$orange;
        font-size: 20px;
        font-weight: 600;
        @include style.phone {
            display: none;
        }
    }

    &__carousel-mobile {
        display: none;
        @include style.phone {
            display: unset;
        }
    }
}
</style>
