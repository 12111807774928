<template>
    <div>
        <breadcrumb />
        <router-view v-if="isNotMyAccountOptionRoute" />
        <div v-else>
            <profile />
        </div>
    </div>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import Profile from '@/views/myAccount/views/profile/TheUserProfile.vue';
import { computed } from 'vue';
import { useUser } from '@/composables/useUser';
import { useApp } from '@/composables/useApp';

const { $route, notifier, $t } = useApp();
const { profile, getProfile } = useUser();

const isNotMyAccountOptionRoute = computed(() =>
    //*** Add routers who don´t want to see with my profile section
    $route.path
        .split('/')
        .some(
            (path) =>
                [
                    'tipti-wallet',
                    'tipti_prime',
                    'notifications',
                    'referred',
                    'settings',
                    'faqs',
                    'my-orders',
                    'subscription',
                ].indexOf(path) >= 0,
        ),
);
const init = async () => {
    try {
        await getProfile();
    } catch (e) {
        notifier({
            type: 'ERROR',
            body: $t("general['error-to']", { message: $t("txt['profile__get-the-user']") }),
        });
    }
};

await init();
</script>

<style lang="scss" scoped>
@import 'my-account';
</style>
