import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e416e5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock("header", {
    class: "retailer-header",
    style: _normalizeStyle({ 'background-color': $props.retailer?.primaryColor })
  }, [
    ($props.showRetailerImage)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "retailer-header__img",
          src: $props.retailer.icon,
          alt: $props.retailer.name
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({ 'retailer-header__detail-left': $props.showRetailerImage })
    }, [
      _createElementVNode("h2", {
        class: _normalizeClass(["retailer-header__title", { 'retailer--border': $props.index === 0 }]),
        style: _normalizeStyle({ color: $props.retailer?.secondaryColor })
      }, _toDisplayString($props.retailer.name), 7),
      _createElementVNode("h3", {
        class: "retailer-header__items",
        style: _normalizeStyle({ color: $props.retailer?.secondaryColor })
      }, _toDisplayString(_ctx.$t('general.products', { count: $props.retailer?.products?.length ?? 0 })), 5)
    ], 2),
    ($props.getTotalForRetailer)
      ? _withDirectives((_openBlock(), _createElementBlock("h2", {
          key: 1,
          class: "retailer-header__price",
          style: _normalizeStyle({ color: $props.retailer?.secondaryColor })
        }, null, 4)), [
          [_directive_currency, $props.getTotalForRetailer]
        ])
      : _createCommentVNode("", true)
  ], 4))
}