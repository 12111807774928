<script lang="ts" setup>
import { useWallet } from '@/views/wallet/useWallet';
import GiftCardsRetailCarousel from '@/views/wallet/components/GiftCard/GiftCardsRetailCarousel.vue';

defineOptions({
    name: 'TheGiftCardDetailRetailersSection',
});

const { getGiftCardRetailers, giftCardRetailers } = useWallet();

await getGiftCardRetailers();
</script>

<template>
    <article class="gift-card-detail-retailers__container">
        <div class="gift-card-detail-retailers__container__header">
            <h2 class="gift-card-detail-retailers__container__header__title">
                {{ $t('txt.gift-card-available-stores-title') }}
            </h2>
            <h4 class="gift-card-detail-retailers__container__header__description">
                {{ $t('txt.gift-card-available-stores-description') }}
            </h4>
        </div>
        <GiftCardsRetailCarousel v-for="retailer in giftCardRetailers" :key="retailer.id" :retailer="retailer" />
    </article>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.gift-card-detail-retailers__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: $margin-lg 2 * $margin-md;

    &__header {
        &__title {
            @include headline6;
            text-align: center;
            margin-bottom: $margin-md;
            color: $orange;
            font-weight: 500;
        }

        &__description {
            @include body2;
            text-align: center;
        }
    }
}

.gift-card-detail-retailers {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
        padding: $padding-md;
        gap: 1rem;

        &__img {
            object-fit: cover;
            height: 80px;
            width: 150px;
            border-radius: 5px;
        }

        &__info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            &__delivery-time {
                background-color: $bright-canvas;
                width: fit-content;
                display: flex;
                padding: $padding-sm;
                border-radius: 8px;

                &__text {
                    @include caption;
                    margin: 0 0 0 $margin-sm;
                }
            }
        }

        &__title {
            @include subtitle1;
        }

        &__arrow-next {
            transform: rotate(180deg);
            @include box-glow;
        }
    }

    &__carousel {
        max-width: calc(100svw - 2 * $margin-md - 10rem);
        @include tablet {
            max-width: calc(100svw - $margin-md);
        }
    }

    &__product {
        width: 12rem;
        height: 100%;
        @include tablet {
            width: 10rem;
        }
        @include phone {
            width: 8rem;
        }
    }
}
</style>
