import { RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';
import { RetailerCommissionModel } from './RetailerCommissionModel';

class RetailerPercentageModel {
    name: string;
    percentageCommission: number;
    total: number;
    maxCommissionFee: number;
    commissionName: RemoteLocaleModel;
    commissions: Array<RetailerCommissionModel>;

    constructor(
        name: string,
        percentageCommission: number,
        total: number,
        maxCommissionFee: number,
        commissionName: RemoteLocaleModel,
        commissions: Array<RetailerCommissionModel>,
    ) {
        this.name = name;
        this.percentageCommission = percentageCommission;
        this.total = total;
        this.maxCommissionFee = maxCommissionFee;
        this.commissionName = commissionName;
        this.commissions = commissions;
    }
}
const toRetailerPercentageModel = (data: object): RetailerPercentageModel => {
    return data
        ? new RetailerPercentageModel(
              data['name'] ?? '',
              data['percentage_commission'] ?? 0,
              data['total'] ?? 0,
              0,
              RemoteLocaleModel.fromJson(data['commission_name']),
              data['commissions']?.map((commission: object) => commission) ?? [],
          )
        : null;
};

export { RetailerPercentageModel, toRetailerPercentageModel };
