import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5969c953"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "institutional-title" }
const _hoisted_2 = {
  key: 1,
  class: "institutions"
}
const _hoisted_3 = {
  key: 0,
  class: "institution__not-enough"
}
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { class: "institution-data" }
const _hoisted_6 = {
  key: 0,
  class: "institution-data__balance"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_radio_normal = _resolveComponent("radio-normal")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!
  const _component_default_payment_selector = _resolveComponent("default-payment-selector")!
  const _component_snack_bar = _resolveComponent("snack-bar")!
  const _component_icon_institutional = _resolveComponent("icon-institutional")!
  const _component_general_alert = _resolveComponent("general-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("general['institutional-assigned']")), 1),
      ($data.isLoading)
        ? (_openBlock(), _createBlock(_component_base_loading, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.institutions, (institutional) => {
              return (_openBlock(), _createElementBlock("div", {
                key: institutional?.institution?.id
              }, [
                _createVNode(_component_base_card_flat, { class: "institution" }, {
                  default: _withCtx(() => [
                    (!$options.isSelectionActive(institutional))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t("txt['institutional__not-enough']")), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (institutional?.institution?.logo)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          alt: institutional?.institution?.name,
                          src: institutional.institution.logo
                        }, null, 8, _hoisted_4))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("h2", null, _toDisplayString(institutional?.institution?.name), 1),
                      (!institutional?.unlimitedBuys && +institutional?.amountAvailable > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("general['available-balance']")) + ": " + _toDisplayString($options.currency) + " " + _toDisplayString(institutional?.amountAvailable), 1))
                        : _createCommentVNode("", true)
                    ]),
                    ($options.isSelectionActive(institutional))
                      ? (_openBlock(), _createBlock(_component_radio_normal, {
                          key: 2,
                          "is-selected": $data.institutionalSelected?.institution?.id === institutional?.institution?.id,
                          value: institutional,
                          "radio-group": "institutionalPayment",
                          onOnCheck: $options.selectInstitution
                        }, null, 8, ["is-selected", "value", "onOnCheck"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024),
                ($data.institutionalSelected?.institution?.id === institutional?.institution?.id)
                  ? (_openBlock(), _createBlock(_component_default_payment_selector, {
                      key: 0,
                      "client-institution-id": $data.institutionalSelected.clientInstitutitonId,
                      "is-default": $data.institutionalSelected.resultDefault,
                      "payment-channel-selected": $options.paymentChannel,
                      "payment-type": $data.paymentType,
                      onOnSetDefault: _ctx.fetchInstitutions
                    }, null, 8, ["client-institution-id", "is-default", "payment-channel-selected", "payment-type", "onOnSetDefault"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
    ]),
    _createVNode(_component_snack_bar, {
      body: _ctx.$t('txt.institutional__not-meet'),
      "is-active": $data.showAlertNoMeets,
      onOnSnackbarClose: _cache[0] || (_cache[0] = ($event: any) => ($data.showAlertNoMeets = false)),
      onOnSnackbarTap: _cache[1] || (_cache[1] = ($event: any) => ($data.showAlertNoMeets = false))
    }, null, 8, ["body", "is-active"]),
    ($data.showAlertMixPayment)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 0,
          message: 
            _ctx.$t('txt.mix-payment__are-you-sure', {
                method: `${_ctx.$t('general.payment-institutional')} ${$data.institutionalSelected?.institution?.name}`,
                amount: $data.institutionalSelected?.amountAvailable,
                difference: $options.difference,
                currency: $options.currency,
            })
        ,
          onOnAccept: $options.acceptMixPayment,
          onOnDecline: $options.declineMixPayment,
          onOnClose: $options.declineMixPayment
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_icon_institutional, {
              size: 4,
              color: "#FF9012"
            })
          ]),
          _: 1
        }, 8, ["message", "onOnAccept", "onOnDecline", "onOnClose"]))
      : _createCommentVNode("", true)
  ], 64))
}