import { ProductModel } from '../product/ProductModel';

class ListDetailItemModel {
    readonly id: number;
    readonly price: number;
    readonly available: boolean;
    readonly product: ProductModel;
    readonly onlyDiscountItem: boolean;
    readonly discountApplied: boolean;
    readonly priceWithDiscount: number;
    quantity: number;
    readonly listItemId: number;
    readonly note: string;

    constructor(
        id: number,
        price: number,
        available: boolean,
        product: ProductModel,
        onlyDiscountItem: boolean,
        discountApplied: boolean,
        priceWithDiscount: number,
        quantity: number,
        listItemId: number,
        note: string,
    ) {
        this.id = id;
        this.price = price;
        this.available = available;
        this.product = product;
        this.onlyDiscountItem = onlyDiscountItem;
        this.discountApplied = discountApplied;
        this.priceWithDiscount = priceWithDiscount;
        this.quantity = quantity;
        this.listItemId = listItemId;
        this.note = note;
    }
}
const toListDetailItemModel = (data: object): ListDetailItemModel => {
    return data
        ? new ListDetailItemModel(
              data['id'],
              +data['price'],
              data['available'],
              data['product'],
              data['only_discount_item'],
              data['discount_applied'],
              data['price_with_discount'],
              data['quantity'],
              data['list_item_id'],
              data['note'],
          )
        : null;
};

class ListDetailModel {
    readonly id: number;
    readonly name: number;
    items: ListDetailItemModel;

    constructor(id: number, name: number, items: ListDetailItemModel) {
        this.id = id;
        this.name = name;
        this.items = items;
    }
}
const toListDetailModel = (data: object): ListDetailModel => {
    return data
        ? new ListDetailModel(
              data['id'],
              data['name'],
              data['items'] == null
                  ? []
                  : data['items'].map((item: object) => {
                        return toListDetailItemModel(item);
                    }),
          )
        : null;
};

export { ListDetailModel, toListDetailModel, ListDetailItemModel, toListDetailItemModel };
