import { AppSectionModel, toAppSectionModel } from '@/models/promotions/AppSectionModel';
import { StoryElementModel, toStoryElementModel } from './StoryElementModel';
import { InteractiveElementModel, toInteractiveElementModel } from './InteractiveElementModel';
import { PromoObjectModel } from '@/models/promotions/PromoObjectModel';
import { isMobileBrowser } from '@/utils/utils';
class StoriesResultModel extends PromoObjectModel {
    elements: Array<StoryElementModel>;

    buttonText: string;
    buttonColor: string;
    buttonTextColor: string;
    iconStory: string;
    showOrder: number;
    showInMobile: boolean;
    showInWeb: boolean;
    detailButtonText: string;
    detailButtonPrimaryColor: string;
    detailButtonSecondaryColor: string;
    detailButtonTextColor: string;
    goCheckoutButtonText: string;
    goCheckoutButtonPrimaryColor: string;
    goCheckoutButtonSecondaryColor: string;
    goCheckoutButtonTextColor: string;
    featured: boolean;
    viewed: boolean;
    description: string;
    interactiveElement: InteractiveElementModel;

    constructor(
        id: number,
        retailers: string,
        cities: string,
        appSection: AppSectionModel,
        elements: Array<StoryElementModel>,
        historyType: string,
        title: string,
        buttonText: string,
        titleColor: string,
        buttonColor: string,
        buttonTextColor: string,
        iconStory: string,
        picture: string,
        startDate: Date,
        endDate: Date,
        showOrder: number,
        showInMobile: boolean,
        showInWeb: boolean,
        detailButtonText: string,
        detailButtonPrimaryColor: string,
        detailButtonSecondaryColor: string,
        detailButtonTextColor: string,
        goCheckoutButtonText: string,
        goCheckoutButtonPrimaryColor: string,
        goCheckoutButtonSecondaryColor: string,
        goCheckoutButtonTextColor: string,
        featured: boolean,
        viewed: boolean,
        description: string,
        interactiveElement: InteractiveElementModel,
        file: string,
        showTermsAndConditions: boolean,
        showDiscountActivationProgressBar: boolean,
    ) {
        super(
            id,
            retailers,
            cities,
            appSection,
            historyType,
            title,
            titleColor,
            picture,
            startDate,
            endDate,
            file,
            showTermsAndConditions,
            showDiscountActivationProgressBar,
        );
        this.elements = elements;
        this.buttonText = buttonText;
        this.buttonColor = buttonColor;
        this.buttonTextColor = buttonTextColor;
        this.iconStory = iconStory;
        this.showOrder = showOrder;
        this.showInMobile = showInMobile;
        this.showInWeb = showInWeb;
        this.detailButtonText = detailButtonText;
        this.detailButtonPrimaryColor = detailButtonPrimaryColor;
        this.detailButtonSecondaryColor = detailButtonSecondaryColor;
        this.detailButtonTextColor = detailButtonTextColor;
        this.goCheckoutButtonText = goCheckoutButtonText;
        this.goCheckoutButtonPrimaryColor = goCheckoutButtonPrimaryColor;
        this.goCheckoutButtonSecondaryColor = goCheckoutButtonSecondaryColor;
        this.goCheckoutButtonTextColor = goCheckoutButtonTextColor;
        this.featured = featured;
        this.viewed = viewed;
        this.description = description;
        this.interactiveElement = interactiveElement;
    }
}

const picture = (data: object) => (isMobileBrowser() ? data['picture_for_mobile'] : data['picture_for_web']);

const toStoriesResultModel = (data: object) => {
    return data
        ? new StoriesResultModel(
              data['id'],
              data['retailers'] ?? '',
              data['citys'] ?? '',
              toAppSectionModel(data['app_sections']),
              data['elements']
                  ? data['elements'].map((element: StoryElementModel) => toStoryElementModel(element))
                  : [],
              data['history_type'] ?? '',
              data['title_text'] ?? '',
              data['button_text'] ?? '',
              data['title_color'] ?? '',
              data['button_color'] ?? '',
              data['button_text_color'] ?? '',
              data['icon_history'] ?? '',
              picture(data),
              new Date(data['start_date']),
              new Date(data['end_date']),
              data['show_order'],
              data['show_in_mobile'] ?? false,
              data['show_in_web'] ?? false,
              data['detail_button_text'] ?? '',
              data['detail_button_primary_color'] ?? '',
              data['detail_button_secondary_color'] ?? '',
              data['detail_button_text_color'] ?? '',
              data['go_checkout_button_text'] ?? '',
              data['go_checkout_button_primary_color'] ?? '',
              data['go_checkout_button_secondary_color'] ?? '',
              data['go_checkout_button_text_color'] ?? '',
              data['featured'] ?? false,
              data['viewed'] ?? false,
              data['description'] ?? '',
              toInteractiveElementModel(data['interactive_element']),
              null,
              false,
              data['show_discount_activation_progress_bar'] ?? false,
          )
        : null;
};

export { StoriesResultModel, toStoriesResultModel };
