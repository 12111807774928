<template>
    <img class="center" :alt="$t('general.page-not-found')" src="@/assets/png/404.png" />
</template>

<script>
export default {
    name: 'NotFoundPage',
};
</script>

<style scoped>
.center {
    display: flex;
    justify-content: center;
    margin: 2rem auto;
    width: 75vw;
}
</style>
