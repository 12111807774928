import { GiftCardsAlertCheckoutModel } from '@/models/giftCardsAlert/giftCardsAlertCheckoutModel';

export default {
    idgGiftCardsToUse: (state) => state.idgGiftCardsToUse,

    paymentsToShowGiftCard(state): Array<GiftCardsAlertCheckoutModel> {
        return state.paymentsToShowGiftCard;
    },
    toShowGiftCards(state): Array<GiftCardsAlertCheckoutModel> {
        return state.toShowGiftCards;
    },
};
