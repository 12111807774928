<template>
    <div class="terms-and-conditions" @click="openTermsAndConditions">
        <p class="terms-and-conditions__txt" :style="{ color: color }">{{ $t("general['terms-and-conditions']") }}</p>
        <icon-arrow :color="color" :size="0.5"></icon-arrow>
    </div>
    <alert-pdf v-if="showModal" :title="$t('general.terms-and-conditions')" @on-close="closeTermsAndConditions" />
</template>

<script lang="ts">
import IconArrow from '@/components/icons/IconArrow.vue';
import AlertPdf from '@/components/alerts/alertPdf/AlertPdf.vue';
export default {
    name: 'text-action-terms-and-conditions',
    data() {
        return {
            showModal: false,
        };
    },
    props: {
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    components: {
        IconArrow,
        AlertPdf,
    },
    methods: {
        openTermsAndConditions(): void {
            this.showModal = true;
        },
        closeTermsAndConditions(): void {
            this.showModal = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type-system.scss';
@import '@/assets/scss/_margin.scss';

.terms-and-conditions {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.terms-and-conditions__txt {
    @include button;
    color: $orange;
    margin-right: $margin-sm;
}
</style>
