import { CategoryModel, toCategoryModel } from '@/models/product/CategoryModel';
import { ProductModel } from '@/models/product/ProductModel';
import { logger } from '@/utils/logger';

export interface SubCategoriesData {
    retailerId: number;
    categoryId: number;
    subcategoryId?: number;
    size: number;
    query?: string;
    page?: number;
}

export default {
    async categories({ rootGetters, commit }, retailerId: number): Promise<Array<CategoryModel>> {
        try {
            commit('categories', []);
            const url = `category/${retailerId}/all`;
            const { data } = await rootGetters['network/axios'].get(url);
            const allCategories = data.map((category: object) => toCategoryModel(category));
            commit('categories', allCategories);
            return allCategories;
        } catch (err) {
            logger('FETCH_CATEGORIES', err);
        }
    },
    async subcategoriesCategoriesList(
        { rootGetters, commit },
        payload: SubCategoriesData,
    ): Promise<{
        sub_categories: CategoryModel[];
        category_id: number;
    }> {
        const { data } = await rootGetters['network/axios'].get(
            `retailers/${payload?.retailerId}/categories/${payload.categoryId}/subcategories-information/`,
        );
        const _dataSubcategories = data?.map((category: object) => toCategoryModel(category)) ?? [];
        commit('subcategoriesList', _dataSubcategories);
        return {
            sub_categories: _dataSubcategories,
            category_id: payload.categoryId,
        };
    },
    async subcategoriesProducts({ rootGetters }, payload: SubCategoriesData): Promise<Array<ProductModel>> {
        try {
            const _url = `v3/retailers/${
                payload.retailerId ?? rootGetters['retailers/retailerSelected'].id
            }/categories/${payload?.categoryId}/subcategories/${payload?.subcategoryId}/products/?size=${
                payload?.size ?? 24
            }`;
            const { data } = await rootGetters['network/axios'].get(_url);
            const _dataModel = data.map((category: object) => toCategoryModel(category));
            return _dataModel[0].products;
        } catch (err) {
            throw err.response;
        }
    },
};
