import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6712aa0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expandable-child__text expandable-child__text--name" }
const _hoisted_2 = { class: "expandable-info" }
const _hoisted_3 = {
  key: 1,
  class: "expandable-child__text expandable-child__text--value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_collapsable_header = _resolveComponent("collapsable-header")!
  const _component_btn_information = _resolveComponent("btn-information")!
  const _component_transition_vertical_fade = _resolveComponent("transition-vertical-fade")!
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_collapsable_header, {
      "can-expanded": $props.children?.length > 0,
      "error-header": $props.errorHeader,
      "is-expanded": _ctx.isExpanded,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isExpanded = !_ctx.isExpanded))
    }, {
      header: _withCtx(() => [
        _renderSlot(_ctx.$slots, "header", {}, () => [
          _createElementVNode("p", {
            class: _normalizeClass(["expandable-header-text", {
                        'expandable-header-text--accent': $props.errorHeader,
                        'expandable-header-text--bold': $props.bold,
                        'expandable-header-text--highlight': $props.hasExtraHeight,
                    }])
          }, _toDisplayString($props.headerText), 3)
        ], true)
      ]),
      "header-side": _withCtx(() => [
        _renderSlot(_ctx.$slots, "header-side", {}, () => [
          _withDirectives(_createElementVNode("p", {
            class: _normalizeClass(["expandable-header-text", {
                        'expandable-header-text--error': $props.errorHeader,
                        'expandable-header-text--bold': $props.bold,
                        'expandable-header-text--highlight': $props.hasExtraHeight,
                    }])
          }, null, 2), [
            [_directive_currency, $props.amount]
          ])
        ], true)
      ]),
      _: 3
    }, 8, ["can-expanded", "error-header", "is-expanded"]),
    _createVNode(_component_transition_vertical_fade, null, {
      default: _withCtx(() => [
        (_ctx.isExpanded && $props.children?.length)
          ? (_openBlock(), _createElementBlock("section", {
              key: 0,
              class: _normalizeClass(["expandable-children", { 'expandable-children--expanded': _ctx.isExpanded }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.children, (child) => {
                return (_openBlock(), _createElementBlock("div", { key: child }, [
                  _renderSlot(_ctx.$slots, "child-content", {}, () => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["expandable-child", { 'expandable-child-information': child?.description }])
                    }, [
                      _createElementVNode("p", _hoisted_1, _toDisplayString(child?.name), 1),
                      (child?.description)
                        ? (_openBlock(), _createBlock(_component_btn_information, {
                            key: 0,
                            onOnView: ($event: any) => ($options.viewInformationServiceFee(child?.type))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_2, _toDisplayString(child.description), 1)
                            ]),
                            _: 2
                          }, 1032, ["onOnView"]))
                        : _createCommentVNode("", true),
                      (child.amount)
                        ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_3, null, 512)), [
                            [_directive_currency, child.amount]
                          ])
                        : _createCommentVNode("", true)
                    ], 2)
                  ], true)
                ]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 64))
}