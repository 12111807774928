<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
    stroke: {
        type: String,
        default: '#FF9012',
    },
});
const width = computed<number>(() => 27 * props.size);
const height = computed<number>(() => 27 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0 27 27">
        <rect
            :stroke="stroke"
            fill="white"
            height="25.8333"
            rx="12.9167"
            stroke-linecap="round"
            stroke-width="0.833333"
            width="25.8333"
            x="0.750163"
            y="0.416667"
        />
        <path
            :fill="color"
            clip-rule="evenodd"
            d="M16.9995 7.49984L6.99951 13.3332L16.9995 19.1665C15.4675 15.414 15.4675 11.2353 16.9995 7.49984Z"
            fill-rule="evenodd"
        />
    </BaseIcon>
</template>
