import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    height: $options.height,
    iconColor: $props.color,
    width: $options.width,
    viewBox: "0 0 23 28"
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        fill: $props.color,
        d: "M21.4667 0H17.7333C17.3998 0 17.0917 0.177915 16.925 0.466667C16.7583 0.755419 16.7583 1.11125 16.925 1.4C17.0917 1.68875 17.3998 1.86667 17.7333 1.86667H20.5333V26.1333H1.86667V1.86667H4.66667C5.00021 1.86667 5.30832 1.68875 5.47499 1.4C5.64165 1.11125 5.64165 0.755413 5.47499 0.466667C5.30832 0.17792 5.0002 0 4.66667 0H0.933333C0.685835 0 0.448331 0.098336 0.273333 0.273333C0.098336 0.448331 0 0.685835 0 0.933333V27.0667C0 27.3142 0.098336 27.5517 0.273333 27.7267C0.448331 27.9017 0.685835 28 0.933333 28H21.4667C21.7142 28 21.9517 27.9017 22.1267 27.7267C22.3017 27.5517 22.4 27.3142 22.4 27.0667V0.933333C22.4 0.685835 22.3017 0.448331 22.1267 0.273333C21.9517 0.098336 21.7142 0 21.4667 0Z"
      }, null, 8, _hoisted_1),
      _createElementVNode("path", {
        fill: $props.color,
        d: "M7.46605 5.6H14.9327C15.1802 5.6 15.4177 5.50166 15.5927 5.32667C15.7677 5.15167 15.866 4.91417 15.866 4.66667V0.933333C15.866 0.685835 15.7677 0.448331 15.5927 0.273333C15.4177 0.098336 15.1802 0 14.9327 0H7.46605C7.21855 0 6.98105 0.098336 6.80605 0.273333C6.63105 0.448331 6.53271 0.685835 6.53271 0.933333V4.66667C6.53271 4.91417 6.63105 5.15167 6.80605 5.32667C6.98105 5.50166 7.21855 5.6 7.46605 5.6ZM8.39938 1.86667H13.9994V3.73333H8.39938V1.86667Z"
      }, null, 8, _hoisted_2),
      _createElementVNode("path", {
        fill: $props.color,
        d: "M7.46605 20.533C7.71355 20.533 7.95106 20.4346 8.12605 20.2596C8.30105 20.0847 8.39939 19.8471 8.39939 19.5996V16.249L11.2833 19.133L9.60333 20.8036C9.42667 20.9788 9.32729 21.2173 9.32729 21.4663C9.32729 21.7153 9.42667 21.9538 9.60333 22.129C9.77854 22.3057 10.0171 22.4051 10.2661 22.4051C10.515 22.4051 10.7536 22.3057 10.9288 22.129L12.5994 20.449L14.27 22.129C14.4452 22.3057 14.6837 22.4051 14.9327 22.4051C15.1817 22.4051 15.4202 22.3057 15.5954 22.129C15.7721 21.9538 15.8715 21.7153 15.8715 21.4663C15.8715 21.2173 15.7721 20.9788 15.5954 20.8036L13.9154 19.133L15.5954 17.4624C15.8321 17.2255 15.9246 16.8805 15.8379 16.5571C15.7513 16.2336 15.4988 15.9811 15.1752 15.8944C14.8519 15.8078 14.5069 15.9003 14.27 16.1369L12.5994 17.8169L9.71543 14.933H10.266C11.5998 14.933 12.8323 14.2213 13.4992 13.0663C14.166 11.9113 14.166 10.488 13.4992 9.33298C12.8323 8.17799 11.5998 7.46631 10.266 7.46631H7.46605C7.21855 7.46631 6.98105 7.56464 6.80605 7.73964C6.63105 7.91464 6.53271 8.15214 6.53271 8.39964V19.5996C6.53271 19.8471 6.63105 20.0846 6.80605 20.2596C6.98105 20.4346 7.21855 20.533 7.46605 20.533L7.46605 20.533ZM8.39939 9.33298H10.2661C10.9329 9.33298 11.5492 9.68882 11.8827 10.2663C12.216 10.8438 12.216 11.5555 11.8827 12.133C11.5492 12.7105 10.9329 13.0663 10.2661 13.0663H8.39939V9.33298Z"
      }, null, 8, _hoisted_3)
    ]),
    _: 1
  }, 8, ["height", "iconColor", "width"]))
}