<template>
    <div class="container-faq">
        <div class="icon" @click="$emit('back-faq')">
            <icon-back :size="0.9"></icon-back>
        </div>
        <div class="main-content-card">
            <component :is="componentActiveCopy" />
        </div>
        <div class="content-faq">
            <div class="vertical-bar"></div>

            <div class="faq-title faq-title-content">{{ $t(`txt['faqs__content']`) }}</div>
            <div v-for="th in faqThemes" :key="th.titleSubject">
                <p
                    class="faq-title-content__pointer"
                    :style="th.nameComponent == componentActiveCopy ? 'font-weight: 700;' : ''"
                    @click="displayContent(th.nameComponent)"
                >
                    <template v-if="th.nameComponent == componentActiveCopy">
                        <span class="faq-title-content__item-select"> | </span>
                    </template>
                    {{ $t(`txt['${th.titleSubject}']`) }}
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineAsyncComponent } from 'vue';
import IconBack from '@/components/icons/IconBack.vue';

export default {
    name: 'ContentFaq',

    components: {
        FaqContactUs: defineAsyncComponent(() => import('@/views/faqs/views/FaqContactUs.vue')),
        FaqCoverageSectors: defineAsyncComponent(() => import('@/views/faqs/views/FaqCoverageSectors.vue')),
        FaqDeliveryYourOrder: defineAsyncComponent(() => import('@/views/faqs/views/FaqDeliveryYourOrder.vue')),
        FaqMakeAnOrder: defineAsyncComponent(() => import('@/views/faqs/views/FaqMakeAnOrder.vue')),
        FaqMyTiptiCard: defineAsyncComponent(() => import('@/views/faqs/views/FaqMyTiptiCard.vue')),
        FaqOrderInProcess: defineAsyncComponent(() => import('@/views/faqs/views/FaqOrderInProcess.vue')),
        FaqOrderSeniors: defineAsyncComponent(() => import('@/views/faqs/views/FaqOrderSeniors.vue')),
        FaqProblemsWithDelivery: defineAsyncComponent(() => import('@/views/faqs/views/FaqProblemsWithDelivery.vue')),
        FaqProfileSettings: defineAsyncComponent(() => import('@/views/faqs/views/FaqProfileSettings.vue')),
        FaqPromotionsDiscounts: defineAsyncComponent(() => import('@/views/faqs/views/FaqPromotionsDiscounts.vue')),
        FaqTiptiExpress: defineAsyncComponent(() => import('@/views/faqs/views/FaqTiptiExpress.vue')),
        FaqWhatIsTipti: defineAsyncComponent(() => import('@/views/faqs/views/FaqWhatIsTipti.vue')),
        IconBack,
    },
    props: {
        componentActive: {
            type: String,
            default: '',
            required: true,
        },
    },
    emits: ['back-faq'],
    data() {
        return {
            faqThemes: [
                {
                    titleSubject: 'faqs__what-is-tipti',
                    nameComponent: 'FaqWhatIsTipti',
                },
                {
                    titleSubject: 'faqs__make-an-order',
                    nameComponent: 'FaqMakeAnOrder',
                },
                {
                    titleSubject: 'faqs__tipti-express',
                    nameComponent: 'FaqTiptiExpress',
                },
                {
                    titleSubject: 'faqs__my-tipti-card',
                    nameComponent: 'FaqMyTiptiCard',
                },
                {
                    titleSubject: 'faqs__order-in-process',
                    nameComponent: 'FaqOrderInProcess',
                },
                {
                    titleSubject: 'faqs__promotions-discounts-rates',
                    nameComponent: 'FaqPromotionsDiscounts',
                },
                {
                    titleSubject: 'faqs__problems_with_delivery',
                    nameComponent: 'FaqProblemsWithDelivery',
                },
                {
                    titleSubject: 'faqs__profile-and-settings',
                    nameComponent: 'FaqProfileSettings',
                },
                {
                    titleSubject: 'faqs__delivery-of-your-order',
                    nameComponent: 'FaqDeliveryYourOrder',
                },
                {
                    titleSubject: 'faqs__your-order-is-for-elderly-adult',
                    nameComponent: 'FaqOrderSeniors',
                },
                {
                    titleSubject: 'faqs__contact_us',
                    nameComponent: 'FaqContactUs',
                },
                {
                    titleSubject: 'faqs__coverage_sectors',
                    nameComponent: 'FaqCoverageSectors',
                },
            ],

            componentActiveCopy: null,
        };
    },
    created() {
        this.componentActiveCopy = this.componentActive;
    },
    methods: {
        displayContent(nameComponent: string): void {
            this.componentActiveCopy = nameComponent;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../faqs.scss';
</style>
