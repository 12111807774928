import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f71cfa54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-solid-right-arrow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_right_arrow = _resolveComponent("icon-right-arrow")!
  const _component_base_btn = _resolveComponent("base-btn")!

  return (_openBlock(), _createBlock(_component_base_btn, {
    isLoading: $props.isLoading,
    bgColor: $props.bgColor,
    isDisabled: $props.isDisabled,
    fullWidth: $props.fullWidth,
    loadingSize: $props.loadingSize
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", {
          style: _normalizeStyle($options.stylesBtn)
        }, _toDisplayString($props.txt), 5),
        _createVNode(_component_icon_right_arrow, { color: "#fff" })
      ])
    ]),
    _: 1
  }, 8, ["isLoading", "bgColor", "isDisabled", "fullWidth", "loadingSize"]))
}