<template>
    <base-icon :icon-name="$t('icon.email_recovery')" :width="width" :height="height" viewBox="0 0 112 106">
        <path
            d="M24.0195 48.8897L43.7963 62.6628L54.0379 54.5402L64.6326 62.6628L85.2923 48.8897V7.57031H24.0195V48.8897Z"
            fill="#CFDE00"
        />
        <path
            d="M7.76953 56.1316V100.1C7.76953 102.925 10.0651 105.397 13.0669 105.397H105.771C109.126 105.397 111.774 102.748 111.774 99.3933V47.6558C111.774 45.89 110.185 44.3008 108.419 44.3008C107.713 44.3008 107.007 44.4774 106.477 44.8305L64.8044 74.3191L54.916 66.1965L43.9681 74.3191L13.0669 53.3063C11.4777 52.2468 9.35873 52.6 8.29926 54.1892C7.9461 54.7189 7.76953 55.4252 7.76953 56.1316Z"
            fill="#FF9012"
        />
        <path
            d="M108.642 39.4355C108.642 39.2589 108.642 39.0824 108.465 38.9058C108.289 38.1995 107.936 37.6697 107.406 37.3166L91.8669 26.5453V14.8911C91.8669 13.3019 90.6308 12.0658 89.0416 12.0658H71.2072L56.0214 1.47114C55.1386 0.764828 53.7259 0.764828 52.843 1.47114L37.4807 12.0658H19.8229C18.2337 12.0658 16.9976 13.3019 16.9976 14.8911V26.3687L1.45871 37.14C0.752392 37.6697 0.222656 38.5526 0.222656 39.4355V99.6488C0.222656 101.238 1.45871 102.474 3.04791 102.474L105.817 102.297C107.406 102.297 108.642 101.061 108.642 99.4722V39.4355ZM91.8669 33.2553L100.696 39.4355L91.8669 45.6158V33.2553ZM54.4322 7.29823L61.4954 12.2424H47.3691L54.4322 7.29823ZM22.6481 17.7164H86.3929V49.5005L65.0269 64.3331L56.198 57.6231C55.1386 56.9168 53.9025 56.9168 52.843 57.6231L44.0141 64.5096L22.6481 49.6771V17.7164ZM16.9976 33.0787V45.7923L7.81553 39.4355L16.9976 33.0787ZM5.87317 44.7329L39.4231 68.0412L5.87317 93.9982V44.7329ZM11.1705 97.0001L54.4322 63.4502L97.5174 97.0001H11.1705ZM69.618 68.0412L103.168 44.7329V94.1748L69.618 68.0412Z"
            fill="#606060"
        />
        <path
            d="M73.2755 28.8867H35.6643C34.0751 28.8867 33.0156 30.2993 33.0156 31.8886C33.0156 33.3012 34.2517 34.3607 35.6643 34.5372H73.2755C74.8647 34.5372 75.9242 33.1246 75.9242 31.5354C75.9242 30.1228 74.6881 28.8867 73.2755 28.8867Z"
            fill="#606161"
        />
        <path
            d="M73.2705 43.2969H35.6593C34.0701 43.2969 32.834 44.3563 32.6574 45.9456C32.4809 47.5348 33.7169 48.7708 35.3061 48.9474C35.4827 48.9474 35.4827 48.9474 35.6593 48.9474H73.2705C74.8597 48.9474 76.0957 47.8879 76.2723 46.2987C76.4489 44.7095 75.2129 43.4735 73.6236 43.2969C73.4471 43.2969 73.4471 43.2969 73.2705 43.2969Z"
            fill="#606161"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-email-recovery',
    props: {
        size: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        width(): number {
            return 112 * this.size;
        },
        height(): number {
            return 106 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
