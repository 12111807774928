<template>
    <base-card class="dropdown__share-links">
        <icon-circular-close class="btn-close" :size="0.75" @click.stop="$emit('on-close')" />
        <p class="share-links__message">{{ $t('txt.share-product__message') }}</p>
        <share-buttons
            :link="linksData.deepLink"
            :mail-subject="$t('txt.share-product__subject')"
            :message-share="linksData.message"
            :share-link="false"
            @on-track-event="productShareEvent"
        />
    </base-card>
</template>
<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import ShareButtons from '@/components/buttons/ShareButtons.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import { computed, defineOptions } from 'vue';
import { useUser } from '@/composables/useUser';
import { ProductModel } from '@/models/product/ProductModel';
import { ShareActions } from '@/enums/shareActions';
import { useApp } from '@/composables/useApp';

defineOptions({
    name: 'ShareProductDeepLinks',
});
defineEmits<(e: 'on-close') => void>();

const { profile } = useUser();
const { $store, $route, $t } = useApp();

const linksData = computed(() => {
    const product: ProductModel = $store.getters['product/productSelected'];
    return {
        message: $t('txt.share-product__message-share', {
            user_name: profile.value?.name,
            product_name: product?.name,
        }),
        deepLink: product?.deepLink,
    };
});

const productShareEvent = (media: ShareActions) => {
    $store.dispatch('segment/productShare', {
        screen: $route.path,
        media,
    });
};
</script>
<style lang="scss" scoped>
@import './share-product-deep-links';
</style>
