import { computed } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import { useTranslate } from '@/composables/UseTranslate';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { DeferredOptionByRetailer } from '@/modules/retailers/store/model';

/***
 * Affiliate price when margin = 0 instead of regular price
 * Discount price when rate = 0 instead of regular price
 */
export const useProductTags = () => {
    const store = useStore();
    const { t } = useI18n();
    const { translate } = useTranslate();

    const product = computed<ProductModel>(() => store.state.product.currentProduct);
    const retailerSelected = computed<RetailerModel>(() => {
        return store.getters['retailers/retailerSelected'];
    });

    const deferredOptionByRetailer = computed<DeferredOptionByRetailer>(() => {
        return store.getters['retailers/deferredOptionByRetailer'];
    });

    const priceTagFromFranchise = computed<string>(() => {
        if (!retailerSelected.value?.franchise?.priceText) return t("general['regular-price']");
        const text = translate({
            recordable: retailerSelected.value?.franchise?.priceText,
        });
        return text.length ? text : t("general['regular-price']");
    });

    const priceLabel = computed<string>(() => {
        if (!product.value) return;
        if (product.value.hasDiscount) return t("general['special-price']");
        if (!product.value.affiliatedPrice) return t("general['regular-price']");
        return priceTagFromFranchise.value;
    });

    const priceTag = computed<string>(() => {
        if (!product.value) return;
        if (!product.value.affiliatedPrice) return t("general['regular-price']");
        return priceTagFromFranchise.value;
    });

    const colorTag = computed<string>(() => {
        if (!retailerSelected.value?.franchise?.priceText) return '#606060';
        if (product.value.hasDiscount) return '#C90808';
        return retailerSelected.value?.franchise?.franchisePriceTextColor ?? '#606060';
    });

    const getMultiplierTag = (product: ProductModel): string =>
        t('txt.product_carry', {
            take: product.carry,
            pay: product.pay,
        });

    const showDeferredOption = computed(() => {
        if (!deferredOptionByRetailer.value.franchise_fee_list?.length) return false;
        if (product.value.hasDiscount)
            return deferredOptionByRetailer.value?.limit_price_to_show <= product.value.priceWithDiscount;
        return deferredOptionByRetailer.value?.limit_price_to_show <= product.value.price;
    });

    const deferredValues = computed(() => {
        if (!deferredOptionByRetailer.value.franchise_fee_list?.length) return [];
        const price = product.value.discountCard?.product_price
            ? product.value.discountCard?.product_price
            : product.value.priceWithDiscount;
        return deferredOptionByRetailer.value.franchise_fee_list
            .sort((a, b) => b - a)
            .map((option) => {
                return {
                    option,
                    value: (+price.toFixed(2) / option).toFixed(2),
                    months: `${option} ${t('txt.months')}`,
                    payments: `${option} ${t('txt.payments')}`,
                };
            });
    });

    return {
        priceTag,
        priceLabel,
        getMultiplierTag,
        showDeferredOption,
        deferredValues,
        colorTag,
    };
};
