<template>
    <base-card-flat
        class="the-credit-card"
        :class="{ 'the-credit-card--uno': !isFullOverlay }"
        :style="{
            'background-image': `url(${card?.creditCardBrandData?.coverPicture})`,
        }"
        border-radius="inherit"
        padding="0"
        v-bind="$attrs"
    >
        <slot v-if="isFullOverlay" name="overlay" />
        <section v-else class="the-credit-card--expired">
            <slot name="overlay" />
        </section>
        <credit-card-footer :credit-card="card" :is-default="isDefault" @on-height="calHeight" />
    </base-card-flat>
</template>
<script lang="ts" setup>
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import CreditCardFooter from '@/views/creditCards/components/listCards/CreditCardFooter.vue';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import { PropType, ref } from 'vue';

defineOptions({
    name: 'TheCreditCard',
});

const props = defineProps({
    card: {
        type: Object as PropType<PaymentMethodModel>,
        required: true,
    },
    isDefault: {
        type: Boolean,
        default: false,
    },
    isFullOverlay: {
        type: Boolean,
        default: true,
    },
});

const height = ref(0);

const calHeight = (value: number) => {
    if (height.value < value) height.value = value;
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-credit-card {
    position: relative;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: top;
    background-size: cover;
    border-radius: inherit;
    height: 13rem;
    width: 26rem;
    overflow: hidden;
    @include tablet {
        width: 20rem;
        height: 10rem;
    }

    &--uno {
        flex-direction: column;
    }

    &__validate-content {
        display: flex;
        flex-direction: column;
        justify-items: center;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__validate-txt {
        @include body1;
        padding: $padding-md;
        font-weight: 600;
        color: $white;
        text-align: center;
    }

    &--expired {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-items: center;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}
</style>
