import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schema, (field) => {
    return (_openBlock(), _createBlock(_resolveDynamicComponent(field.component), _mergeProps({
      key: field.name,
      modelValue: _ctx.formData[field.name],
      "onUpdate:modelValue": ($event: any) => ((_ctx.formData[field.name]) = $event),
      errors: _ctx.errors[field.name],
      "input-phone-disabled": false,
      "is-disabled": field.disabled,
      "is-invalid": _ctx.localInvalid?.includes(field.name) || !!_ctx.errors[field.name]?.length,
      "is-required": field.required,
      label: field.name,
      "label-name": field.title,
      placeholder: field.placeholder
    }, {
            ...field,
            ..._ctx.$attrs,
        }, {
      onInput: ($event: any) => (_ctx.updateForm(field.name, $event.target.value, field)),
      "onUpdate:modelValue": (value) => _ctx.updateForm(field.name, value, field)
    }), null, 16, ["modelValue", "onUpdate:modelValue", "errors", "is-disabled", "is-invalid", "is-required", "label", "label-name", "placeholder", "onInput"]))
  }), 128))
}