import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4962702b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("hr", {
    class: "separator",
    "data-text": $props.text
  }, null, 8, _hoisted_1))
}