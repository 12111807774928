<template>
    <div class="shell" v-bind="$attrs" :style="{ 'background-color': bgColor }">
        <div class="shell__progress" :style="{ width: `${progress}%` }"></div>
    </div>
</template>
<script lang="ts">
export default {
    name: 'progress-status',
    inheritAttrs: false,
    props: {
        progress: {
            type: Number,
            default: 0,
        },
        bgColor: {
            type: String,
            default: '#ffffff',
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.shell {
    width: 100%;
    box-sizing: border-box;
    margin: 4px $margin-sm;
    border-radius: $radius-toggle;
    max-height: 1rem;
    &__progress {
        background-color: $orange;
        border-radius: $radius-toggle;
        height: 100%;
        transition: all ease-in-out 200ms;
    }
}
</style>
