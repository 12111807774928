import { RouteRecordRaw } from 'vue-router';
import RetailersInTipti from '@/views/retailersInTipti/RetailersInTipti.vue';
import PromotionsInTipti from '@/views/promotionsInTipti/PromotionsInTipti.vue';
import MyAccount from '@/views/myAccount/MyAccount.vue';
import Faqs from '@/views/faqs/Faqs.vue';
import Retailers from '@/modules/retailers/pages/Retailers.vue';
import BecomeRetailer from '@/views/becomeRetailer/BecomeRetailer.vue';
import MainProducts from '@/modules/main-products/views/MainProducts.vue';
import MainCategorySeeMore from '@/modules/main-products/views/mainCategoriesSeeMore/MainCategorySeeMore.vue';
import Promotions from '@/views/couponsAndPromotions/components/promotions/Promotions.vue';
import Coupons from '@/views/couponsAndPromotions/components/coupons/Coupons.vue';
import store from '@/store';
import ResendPayment from '@/views/resendPayment/ResendPayment.vue';
import Payments from '@/views/checkout/views/payments/Payments.vue';
import TiptiCardPayment from '@/views/tiptiCardPayment/TiptiCardPayment.vue';
import Payphone from '@/views/payphone/Payphone.vue';
import BodyCreditCards from '@/views/creditCards/bodyCreditCards/BodyCreditCards.vue';
import WireTransferPayment from '@/views/wireTransferPayment/WireTransferPayment.vue';
import InstitutionalPayment from '@/views/institutionalPayment/InstitutionalPayment.vue';
import OnlineBankServicesPayment from '@/views/onlineBankServicesPayment/OnlineBankServicesPayment.vue';
import NequiPayment from '@/views/nequiPayment/NequiPayment.vue';
import YappyPayment from '@/views/yappyPayment/YappyPayment.vue';
import Notifications from '@/views/myAccount/views/notifications/Notifications.vue';
import Referred from '@/views/myAccount/views/referred/Referred.vue';
import Settings from '@/views/myAccount/views/settings/Settings.vue';
import TiptiPrime from '@/views/myAccount/views/prime/Prime.vue';
import EditProfile from '@/views/myAccount/views/profile/views/editProfile/EditProfile.vue';
import CreditCardsProfile from '@/views/myAccount/views/profile/views/creditCardsProfile/CreditCardsProfile.vue';
import AddressesProfile from '@/views/myAccount/views/profile/views/addressesProfile/AddressesProfile.vue';
import AddressesCheckout from '@/views/checkout/views/addressesCheckout/AddressesCheckout.vue';
import BillsProfile from '@/views/myAccount/views/profile/views/billsProfile/BillsProfile.vue';
import Statistics from '@/views/myAccount/views/profile/views/statistics/TheStatistics.vue';
import ShareData from '@/views/myAccount/views/profile/views/shareData/ShareData.vue';
import BillsAndDocuments from '@/views/myAccount/views/profile/views/billsAndDocuments/BillsAndDocuments.vue';
import CashPayment from '@/views/cashPayment/CashPayment.vue';
import TiptiRecipes from '@/views/tiptiRecipes/TiptiRecipes.vue';
import PrivacyPolicyShopper from '@/views/privacyPolicyShopper/PrivacyPolicyShopper.vue';

//// ** LISTS FLOW
import RecipesSubGroup from '@/views/myLists/views/recipesSubGroup/RecipesSubGroup.vue';
import RecipeDetail from '@/views/myLists/views/recipeDetail/RecipeDetail.vue';
import ListCategories from '@/views/myLists/views/personalLists/views/listCategories/ListCategories.vue';
import ListCategoryWithSubcategory from '@/views/myLists/views/personalLists/views/listCategories/views/listCategoryWithSubcategory/ListCategoryWithSubcategory.vue';
import ListCategoryProducts from '@/views/myLists/views/personalLists/views/listCategories/views/listCategoryProducts/ListCategoryProducts.vue';
import ListCategoryFeaturedProducts from '@/views/myLists/views/personalLists/views/listCategories/views/listCategoryFeaturedProducts/ListCategoryFeaturedProducts.vue';
import ListCategorySeeMore from '@/views/myLists/views/personalLists/views/listCategories/views/listCategorySeeMore/ListCategorySeeMore.vue';
//// ** EXTRA ITEMS FLOW
/// ** BANNER IN SECTION VIEWS
import BannerAndStorySwitcher from '@/views/multipleProducts/BannerAndStorySwitcher.vue';
import CategoryProductsInBanner from '@/views/multipleProducts/banners/categoryProductsInBanner/CategoryProductsInBanner.vue';
/// ** STORIES VIEWS
import { isMobileBrowser } from '@/utils/utils';

import PromotionRoutes from './modules/promotion';

////// *** CHECK
import SummaryCheckout from '@/views/checkout/views/summaryCheckout/SummaryCheckout.vue';
import LoyaltyAndReward from '@/views/myAccount/views/profile/views/loyaltyPrograms/loyaltyAndRewards/LoyaltyAndRewards.vue';
import BenefitScreen from '@/views/myAccount/views/profile/views/loyaltyPrograms/benefits/BenefitScreen.vue';
import PlaceholderScreen from '@/views/myAccount/views/profile/views/loyaltyPrograms/placeholder/PlaceholderScreen.vue';
import ValidationScreen from '@/views/myAccount/views/profile/views/loyaltyPrograms/validation/ValidationScreen.vue';
import RegistratinoScreen from '@/views/myAccount/views/profile/views/loyaltyPrograms/register/RegistrationScreen.vue';
import Programs from '@/views/myAccount/views/profile/views/loyaltyPrograms/Programs.vue';
import ShowCard from '@/views/myAccount/views/profile/views/loyaltyPrograms/showCard/ShowCard.vue';
import { catalogRoutes } from '@/modules/catalogs/routes';
import Subscriptions from '@/modules/subscriptions/pages/Subscriptions.vue';

import AllCategories from '@/modules/main-products/views/AllCategories.vue';
import ProductNotFound from '@/views/productNotFound/ProductNotFound.vue';
import SeeMore from '@/views/seeMore/SeeMore.vue';
import CategorySwitcher from '@/modules/main-products/CategorySwitcher.vue';
import MainCategoryProducts from '@/modules/main-products/views/mainCategoryProducts/MainCategoryProducts.vue';
import Search from '@/views/search/Search.vue';
import BaseCategoryView from '@/modules/main-products/BaseCategoryView.vue';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import PageNotFound from '@/views/404/PageNotFound.vue';
import { OrderRoutes } from '@/views/myAccount/views/myOrders/OrderRouteMap';
import { WalletRoutes } from '@/views/wallet/WalletRoutes';
import { breadcrumbDefinition } from '@/components/breadcrumb/BreadcumbDefinition';
import ProductDetail from '@/views/productDetail/ProductDetail.vue';
import { NewsRoute } from '@/views/newsPress/route';
import { RouteRetailers } from '@/modules/retailers/routes';
import Checkout from '@/views/checkout/Checkout.vue';
import { OrderState } from '@/enums/orderState';
import { OrderModel } from '@/models/order/OrderModel';
import { appRoutesMap } from '@/router/appRoutesMap';
import FeaturedProductsGrid from '@/components/category/featuredProducts/FeaturedProductsGrid.vue';
import SeeMoreSuggestions from '@/modules/highDemandAndOutOfStock/PageSeeMoreSuggestions.vue';

const authLoyaltyPrograms = (_, __, next) => {
    if (store.getters['user/baseConfiguration']?.showLoyaltyMenu) next();
    else next({ name: appRoutesMap.account.profile });
};

const subscriptionGuard = (_, __, next) => {
    if (store.getters['external/countrySelected']?.alphaCode === 'PA') next();
    else next({ name: appRoutesMap.account.profile });
};
const checkoutGuard = (_, __, next) => {
    if (
        store.getters['user/isAuth'] &&
        (store.getters['user/userLocation'] || store.getters['location/addressDefault']) &&
        store.getters['cart/cart']?.retailers?.length &&
        store.getters['cart/cart'].retailers[0].id
    )
        return next();
    next({ name: appRoutesMap.home });
};

const paymentsCheckoutGuard = (_, __, next) => {
    if (store.getters['checkout/isSelectedSchedules']) next();
    else next({ path: '/checkout' });
};

const billsAndDocumentsGuard = (_, __, next) => {
    if (isMobileBrowser()) return next({ name: appRoutesMap.account.profile });
    return next();
};

const retailerSelected = (): RetailerModel => store.getters['retailers/retailerSelected'];
const isCategorySelected = () => Boolean(store.getters['categories/getCategorySelected']);
const isSubcategorySelected = () => Boolean(store.getters['categories/getSubcategorySelected']);
const isCustomCategory = () => Boolean(store.getters['categories/customCategory']);

const viewCategoriesGuard = (to, from, next) => {
    const { meta, params } = to;

    if (!retailerSelected()) return next({ name: appRoutesMap.home });
    const isValidRetailer = retailerSelected()?.name?.trim()?.toUpperCase() === params.retailer?.trim()?.toUpperCase();
    if (!isValidRetailer) return next({ name: appRoutesMap.home });

    if (!Object.keys(meta).length) return next();
    const { require_category, require_subcategory } = meta;
    const hasValidCategorySelection = isCategorySelected() || isCustomCategory();
    if (require_category && !hasValidCategorySelection) return next({ name: appRoutesMap.retail.home });

    if (require_subcategory && (!hasValidCategorySelection || !isSubcategorySelected()))
        return next({ name: appRoutesMap.retail.home });
    next();
};

const orderRejected = Array.isArray(store.getters['orders/orders'])
    ? store.getters['orders/orders'].find((order: OrderModel): boolean => order.state === OrderState.PAYMENT_REJECTED)
    : store.getters['orders/orders'];

const hasOrderRejected = () => Boolean(orderRejected);
const hasOrderSelected = () => Boolean(store.getters['orders/orderDetailSelected']);
const isUserAuthenticated = () => store.getters['user/isAuth'];
const resendPaymentGuard = (to, from, next) => {
    if (!isUserAuthenticated() || (!hasOrderRejected() && !hasOrderSelected()))
        return next({ name: appRoutesMap.home });
    next();
};

const routes: Array<RouteRecordRaw> = [
    ...RouteRetailers,
    ...PromotionRoutes,
    ...catalogRoutes,
    {
        path: '/faqs',
        name: appRoutesMap.faq,
        component: Faqs,
    },
    {
        path: `/coupons-and-promotions`,
        name: appRoutesMap.coupons.couponsAndPromotions,
        component: () => import('@/views/couponsAndPromotions/CouponsAndPromotions.vue'),
        meta: {
            require_auth: true,
        },
        beforeEnter: (_, __, next) => {
            if (store.getters['retailers/retailerSelected']) return next();
            next({ name: appRoutesMap.home });
        },
        redirect: { name: appRoutesMap.coupons.index },
        children: [
            {
                path: 'coupons',
                name: appRoutesMap.coupons.index,
                component: Coupons,
                meta: {
                    require_auth: true,
                },
            },
            {
                path: 'promotions',
                name: appRoutesMap.coupons.promotions,
                component: Promotions,
                meta: {
                    require_auth: true,
                },
                children: [
                    {
                        path: `banner-products/:id/:title/:activationDiscountProgressBar`,
                        component: BannerAndStorySwitcher,
                        meta: {
                            banner_type: 'products',
                        },
                    },
                    {
                        path: `banner-discount/:id/:title/:activationDiscountProgressBar`,
                        component: BannerAndStorySwitcher,
                        meta: {
                            banner_type: 'discount',
                        },
                    },
                    {
                        path: `banner-promotion/:id/:title/:activationDiscountProgressBar`,
                        component: BannerAndStorySwitcher,
                        meta: {
                            banner_type: 'promotions',
                        },
                    },
                    {
                        path: `banner-categories/:id/:title`,
                        component: BannerAndStorySwitcher,
                        meta: {
                            banner_type: 'category',
                        },
                    },
                ],
            },
        ],
    },
    {
        path: `/category-products-in-banner/:name`,
        name: appRoutesMap.categoryProductsInBanner,
        component: CategoryProductsInBanner,
        meta: {
            preserve_banner: true,
        },
    },
    {
        path: `/about-tipti`,
        name: appRoutesMap.about,
        component: () => import('@/views/aboutTipti/AboutTipti.vue'),
    },
    {
        path: '/my-lists',
        name: appRoutesMap.list.myLists,
        component: () => import('@/views/myLists/MyLists.vue'),
        meta: {
            require_auth: true,
            subsection: 'list',
        },
        children: [
            {
                name: appRoutesMap.list.personalLists,
                path: 'personal-lists',
                meta: {
                    require_auth: true,
                    subsection: 'list',
                },
                component: () => import('@/views/myLists/views/personalLists/PersonalLists.vue'),
            },
            {
                name: appRoutesMap.list.listPage,
                path: 'list',
                meta: {
                    require_auth: true,
                    subsection: 'list',
                },
                component: () => import('@/views/myLists/views/personalLists/views/list/List.vue'),
            },
            {
                name: appRoutesMap.list.schedulePage,
                path: 'schedule',
                meta: {
                    require_auth: true,
                    subsection: 'list',
                },
                component: () => import('@/views/myLists/views/personalLists/views/listSchedule/ListSchedule.vue'),
            },
            {
                name: appRoutesMap.list.recipesPage,
                path: 'recipes',
                meta: {
                    subsection: 'list',
                },
                component: () => import('@/views/myLists/views/recipes/Recipes.vue'),
            },
            {
                name: appRoutesMap.list.recipesSubGroupPage,
                path: 'recipes-sub-group',
                component: RecipesSubGroup,
                meta: {
                    subsection: 'list',
                },
            },
            {
                name: appRoutesMap.list.recipeDetail,
                path: 'recipe-detail',
                component: RecipeDetail,
                meta: {
                    subsection: 'list',
                },
            },
            {
                name: appRoutesMap.list.listCategories,
                path: 'categories',
                component: ListCategories,
                meta: {
                    subsection: 'list',
                },
            },
            {
                name: appRoutesMap.list.listCategoryWithSubcategory,
                path: `list-category-with-subcategory/:id/:name/`,
                component: ListCategoryWithSubcategory,
                meta: {
                    subsection: 'list',
                    isCategoryList: true,
                },
            },
            {
                path: 'list-category-products',
                name: appRoutesMap.list.listCategoryProducts,
                component: ListCategoryProducts,
                meta: {
                    subsection: 'list',
                    isCategoryList: true,
                },
            },
            {
                name: appRoutesMap.list.listCategorySeeMore,
                path: `list-category-category-see-more`,
                component: ListCategorySeeMore,
                meta: {
                    subsection: 'list',
                    isCategoryList: true,
                },
            },
            {
                name: appRoutesMap.list.listCategoryFeaturedProducts,
                path: `list-category-featured-products`,
                component: ListCategoryFeaturedProducts,
                meta: {
                    subsection: 'list',
                    isCategoryList: true,
                },
            },
        ],
    },
    {
        path: '/become-retailer',
        name: 'BecomeRetailer',
        component: BecomeRetailer,
    },
    {
        path: `/retailers-in-tipti`,
        name: appRoutesMap.retailersInTipti,
        component: RetailersInTipti,
    },
    {
        path: `/promotions-in-tipti`,
        name: appRoutesMap.promotions.home,
        component: PromotionsInTipti,
    },

    {
        path: `/policies-data-protection`,
        name: appRoutesMap.privacyData.policies,
        component: () => import('@/views/policiesDataProtection/PoliciesDataProtectionPage.vue'),
        redirect: { name: appRoutesMap.privacyData.index },
        meta: {
            use_custom_header: true,
            use_custom_footer: true,
        },
        children: [
            {
                path: ``,
                name: appRoutesMap.privacyData.index,
                component: () => import('@/views/policiesDataProtection/views/PrivacyPolicy.vue'),
                meta: {
                    use_custom_header: true,
                    use_custom_footer: true,
                },
            },
            {
                path: `/personal-data-protection`,
                name: appRoutesMap.privacyData.personalData,
                component: () => import('@/views/policiesDataProtection/views/PersonalDataProtection.vue'),
                meta: {
                    use_custom_header: true,
                    use_custom_footer: true,
                },
            },
        ],
    },
    ...NewsRoute,
    {
        path: `/my-account`,
        name: appRoutesMap.account.profile,
        component: MyAccount,
        meta: {
            pageTitle: 'page-title__my-account',
            require_auth: true,
        },
        children: [
            {
                name: appRoutesMap.account.faqs,
                path: 'faqs',
                component: Faqs,
            },
            {
                path: 'statistics',
                name: appRoutesMap.account.statistics,
                component: Statistics,
                meta: {
                    pageTitle: 'page-title__my-account-statistics',
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.statistics],
                },
            },
            {
                path: 'profile',
                name: appRoutesMap.account.editProfile,
                component: EditProfile,
                meta: {
                    pageTitle: 'page-title__my-account-edit-profile',
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.profile],
                },
            },
            {
                path: 'programs',
                name: appRoutesMap.reward.programs,
                component: Programs,
                beforeEnter: authLoyaltyPrograms,
                redirect: { name: appRoutesMap.reward.loyaltyAndReward },
                children: [
                    {
                        name: appRoutesMap.reward.loyaltyAndReward,
                        path: `loyalty-and-rewards`,
                        component: LoyaltyAndReward,
                        meta: {
                            breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.programs],
                        },
                    },
                    {
                        name: appRoutesMap.reward.benefitScreen,
                        path: `benefit-screen/:id`,
                        component: BenefitScreen,
                        meta: {
                            breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.programs],
                        },
                    },
                    {
                        name: appRoutesMap.reward.placeholderScreen,
                        path: `placeholder-screen/:id`,
                        component: PlaceholderScreen,
                        meta: {
                            breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.programs],
                        },
                    },
                    {
                        name: appRoutesMap.reward.validationScreen,
                        path: `ValidationScreen/:id`,
                        component: ValidationScreen,
                        meta: {
                            breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.programs],
                        },
                    },
                    {
                        name: appRoutesMap.reward.registrationScreen,
                        path: `RegistrationScreen/:id`,
                        component: RegistratinoScreen,
                        meta: {
                            breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.programs],
                        },
                    },
                    {
                        name: appRoutesMap.reward.showCard,
                        path: `show-card/:id`,
                        component: ShowCard,
                        meta: {
                            breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.programs],
                        },
                    },
                ],
            },
            {
                path: 'addresses-profile',
                name: appRoutesMap.account.addressesProfile,
                component: AddressesProfile,
                meta: {
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.address],
                },
            },
            {
                path: 'bills-profile',
                name: appRoutesMap.account.billsProfile,
                component: BillsProfile,
                meta: {
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.address],
                },
            },
            {
                path: 'credit-cards',
                name: appRoutesMap.account.creditCardsProfile,
                component: CreditCardsProfile,
                meta: {
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.creditCards],
                },
            },
            {
                path: 'share-data',
                name: appRoutesMap.account.shareData,
                component: ShareData,
                meta: {
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.shareData],
                },
            },
            {
                path: 'bills-and-document',
                name: appRoutesMap.account.billsAndDocuments,
                component: BillsAndDocuments,
                beforeEnter: billsAndDocumentsGuard,
                meta: {
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.bills],
                },
            },
            OrderRoutes,
            WalletRoutes,
            {
                name: appRoutesMap.account.tiptiPrime,
                path: 'tipti_prime',
                component: TiptiPrime,
                meta: {
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.prime],
                },
            },
            {
                name: appRoutesMap.account.notifications,
                alias: ['/FLUTTER_NOTIFICATION_CLICK'],
                path: 'notifications',
                component: Notifications,
                meta: {
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.notifications],
                },
            },
            {
                name: appRoutesMap.account.referred,
                path: 'referred',
                component: Referred,
                meta: {
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.referred],
                },
            },
            {
                name: appRoutesMap.account.subscriptions,
                path: 'subscription',
                component: Subscriptions,
                beforeEnter: subscriptionGuard,
                meta: {
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.subscriptions],
                },
            },
            {
                name: appRoutesMap.account.settings,
                path: 'settings',
                component: Settings,
                meta: {
                    breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.settings],
                },
            },
        ],
    },
    {
        path: '/resend-payment',
        component: ResendPayment,
        name: appRoutesMap.paymentResent.resend,
        beforeEnter: resendPaymentGuard,
        children: [
            {
                path: 'tipti-card',
                component: TiptiCardPayment,
                name: appRoutesMap.paymentResent.tiptiCard,
                beforeEnter: resendPaymentGuard,
            },
            {
                path: 'credit-cards',
                name: appRoutesMap.paymentResent.bodyCredit,
                component: BodyCreditCards,
                beforeEnter: resendPaymentGuard,
            },
            {
                path: 'payphone',
                name: appRoutesMap.paymentResent.payphone,
                component: Payphone,
                beforeEnter: resendPaymentGuard,
            },
            {
                path: 'cash',
                name: appRoutesMap.paymentResent.cash,
                component: CashPayment,
                beforeEnter: resendPaymentGuard,
            },
            {
                path: 'wire-transfer',
                name: appRoutesMap.paymentResent.wireTransfer,
                component: WireTransferPayment,
                beforeEnter: resendPaymentGuard,
            },
            {
                path: 'institutional',
                name: appRoutesMap.paymentResent.institutional,
                component: InstitutionalPayment,
                beforeEnter: resendPaymentGuard,
            },
        ],
    },
    {
        path: '/deeplink',
        name: appRoutesMap.deeplink,
        component: Retailers,
        beforeEnter: (to, _, next) => {
            /// ** all deeplink struture with tipti.market domain
            /// should be saved and reconstruct after country selection to throw the deeplink again
            /// with the selected country's domain
            store.dispatch('deeplink/deeplinkLogic', to.query);
            return next();
        },
    },
    {
        path: '/checkout',
        name: appRoutesMap.checkout.index,
        component: Checkout,
        beforeEnter: checkoutGuard,
        meta: {
            require_auth: true,
        },
        redirect: { name: appRoutesMap.checkout.summary },
        children: [
            {
                path: '',
                name: appRoutesMap.checkout.summary,
                component: SummaryCheckout,
            },
            {
                path: 'addresses',
                name: appRoutesMap.checkout.addresses,
                component: AddressesCheckout,
                meta: {
                    subsection: 'address',
                },
            },
            {
                path: 'payments',
                name: appRoutesMap.checkout.payments.index,
                beforeEnter: paymentsCheckoutGuard,
                component: Payments,
                meta: {
                    subsection: 'payment',
                },
                children: [
                    {
                        path: 'tipti-card',
                        component: TiptiCardPayment,
                        name: appRoutesMap.checkout.payments.tiptiCard,
                    },
                    {
                        path: 'cash',
                        component: CashPayment,
                        name: appRoutesMap.checkout.payments.cash,
                    },
                    {
                        path: 'credit-cards',
                        component: BodyCreditCards,
                        name: appRoutesMap.checkout.payments.bodyCredit,
                    },
                    {
                        path: 'payphone',
                        component: Payphone,
                        name: appRoutesMap.checkout.payments.payphone,
                    },
                    {
                        path: 'wire-transfer',
                        component: WireTransferPayment,
                        name: appRoutesMap.checkout.payments.wireTransfer,
                    },
                    {
                        path: 'institutional',
                        component: InstitutionalPayment,
                        name: appRoutesMap.checkout.payments.institutional,
                    },
                    {
                        path: 'online-bank-services',
                        component: OnlineBankServicesPayment,
                        name: appRoutesMap.checkout.payments.onlineBankServices,
                    },
                    {
                        path: 'nequi',
                        component: NequiPayment,
                        name: appRoutesMap.checkout.payments.nequi,
                    },
                    {
                        path: 'yappy',
                        component: YappyPayment,
                        name: appRoutesMap.checkout.payments.yappy,
                    },
                ],
            },
        ],
    },
    {
        path: `/banner-products/:id/:title/:activationDiscountProgressBar`,
        component: BannerAndStorySwitcher,
        meta: {
            banner_type: 'products',
        },
    },
    {
        path: `/banner-discount/:id/:title/:activationDiscountProgressBar`,
        component: BannerAndStorySwitcher,
        meta: {
            banner_type: 'discount',
        },
    },
    {
        path: `/banner-promotion/:id/:title/:activationDiscountProgressBar`,
        component: BannerAndStorySwitcher,
        meta: {
            banner_type: 'promotions',
        },
    },
    {
        path: `/banner-categories/:id/:title`,
        component: BannerAndStorySwitcher,
        meta: {
            banner_type: 'category',
        },
    },
    {
        path: `/tipti-recipes`,
        name: appRoutesMap.tiptiRecipes,
        component: TiptiRecipes,
    },
    {
        path: `/privacy-policy`,
        name: appRoutesMap.privacyPolicyShopper,
        component: PrivacyPolicyShopper,
    },
    {
        path: `/:retailer`,
        name: appRoutesMap.retail.home,
        component: MainProducts,
        beforeEnter: [
            (_, from, next) => {
                if (from.name !== appRoutesMap.product) return next();
                store.commit('promotions/bannerSelected');
                next();
            },
            viewCategoriesGuard,
        ],
        redirect: { name: appRoutesMap.retail.allCategories },
        meta: {
            require_retailer: true,
            show_order_to_rate: true,
        },
        children: [
            {
                path: appRoutesMap.retail.allCategories,
                name: appRoutesMap.retail.allCategories,
                component: AllCategories,
                beforeEnter: [
                    viewCategoriesGuard,
                    (_, __, next) => {
                        store.commit('categories/setCategorySelected');
                        store.commit('categories/setSubcategorySelected');
                        next();
                    },
                ],
                meta: {
                    require_retailer: true,
                    show_recommended_products: true,
                },
            },
            {
                path: ':category',
                name: appRoutesMap.retail.baseCategoryView,
                component: BaseCategoryView,
                beforeEnter: viewCategoriesGuard,
                redirect: { name: appRoutesMap.retail.categorySwitcher },
                meta: {
                    require_retailer: true,
                    require_category: true,
                },
                children: [
                    {
                        path: '',
                        name: appRoutesMap.retail.categorySwitcher,
                        component: CategorySwitcher,
                        meta: {
                            require_retailer: true,
                            show_recommended_products: true,
                        },
                    },
                    {
                        path: ':subcategory',
                        name: appRoutesMap.retail.indexCategorySeeMore,
                        component: MainCategorySeeMore,
                        beforeEnter: viewCategoriesGuard,
                        meta: {
                            require_retailer: true,
                            require_category: true,
                            require_subcategory: true,
                        },
                    },
                    {
                        name: appRoutesMap.retail.indexCategoryFeaturedProducts,
                        path: 'featured-products',
                        component: FeaturedProductsGrid,
                        beforeEnter: viewCategoriesGuard,
                        props: { originSection: 'featured-products' },
                        meta: {
                            require_retailer: true,
                            require_category: true,
                        },
                    },
                    {
                        path: 'MainCategoryProducts',
                        name: appRoutesMap.retail.indexCategoryProducts,
                        component: MainCategoryProducts,
                        beforeEnter: viewCategoriesGuard,
                        meta: {
                            require_retailer: true,
                        },
                    },
                ],
            },
            {
                path: 'see-more',
                name: appRoutesMap.retail.seeMore,
                component: SeeMore,
                meta: {
                    require_retailer: true,
                },
                beforeEnter: [
                    viewCategoriesGuard,
                    (to, __, next) => {
                        const target = { ...to };
                        if (target.query.page) return next();
                        target.query.page = '1';
                        next({ ...to });
                    },
                ],
            },
            {
                path: `Search/:searchOn`,
                name: appRoutesMap.retail.search,
                component: Search,
                meta: {
                    require_retailer: true,
                    show_recommended_products: true,
                },
                beforeEnter: (to, __, next) => {
                    if (!retailerSelected()) return next({ name: appRoutesMap.home });
                    const target = { ...to };
                    if (target.query.page) return next();
                    target.query.page = '1';
                    next({ ...to });
                },
            },
            {
                path: 'product/:name',
                name: appRoutesMap.product,
                component: ProductDetail,
                meta: {
                    require_retailer: true,
                    is_detail_product: true,
                },
                beforeEnter: (to, from, next) => {
                    if (!store.getters['product/productSelected']) return next({ name: appRoutesMap.home });
                    next();
                },
            },
            {
                path: `story-products/:id/:title`,
                name: appRoutesMap.retail.storyProducts,
                component: BannerAndStorySwitcher,
                meta: {
                    require_retailer: true,
                    story_type: 'products',
                },
            },
            {
                path: `story-discounts/:id/:title`,
                name: appRoutesMap.retail.storyDiscounts,
                component: BannerAndStorySwitcher,
                meta: {
                    require_retailer: true,
                    story_type: 'discount',
                },
            },
            {
                path: `story-categories/:id/:title`,
                name: appRoutesMap.retail.storyCategories,
                component: BannerAndStorySwitcher,
                meta: {
                    require_retailer: true,
                    story_type: 'category',
                },
            },
            {
                path: 'products-suggestions/:stockItemId',
                name: appRoutesMap.retail.seeMoreSuggestions,
                component: SeeMoreSuggestions,
                meta: {
                    require_retailer: true,
                },
            },

            {
                path: 'not-found',
                name: appRoutesMap.productNotFound,
                component: ProductNotFound,
                meta: {
                    require_retailer: true,
                },
            },
        ],
    },
    {
        path: '/:catchAll(.*)',
        name: appRoutesMap.pageNotFound,
        component: PageNotFound,
    },
];

export default routes;
