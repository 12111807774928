import { ProductModel } from '@/models/product/ProductModel';
import { ProductWithAttributeModel } from '@/models/product/ProductWithAttributeModel';
import { ProductAttributeModel } from '@/models/product/ProductAttributeModel';
import { UnavailableProductsByHomologationModel } from '@/models/product/UnavailableProductsByHomologationModel';
import { ProductSuggested } from '@/models/product/ProductSuggestied';

export default {
    isLastPage(state, isLastPage: boolean) {
        state.isLastPage = isLastPage;
    },
    productSelected(state, product: ProductModel) {
        state.productSelected = product;
        state.currentProduct = product;
        if (product) return;
        state.tmpNote = '';
        state.tmpFile = null;
    },
    currentProduct(state, product: ProductModel) {
        state.currentProduct = product;
        if (product) return;
        state.tmpNote = '';
        state.tmpFile = null;
    },
    productIdActiveBlur(state, uuid: string) {
        state.productIdActiveBlur = uuid;
    },
    fetchCart(state, fetch: boolean) {
        state.fetchCart = fetch;
    },
    tmpNote(state, tmpNote: string) {
        state.tmpNote = tmpNote;
    },
    tmpFile(state, tmpFile: File) {
        state.tmpFile = tmpFile;
    },
    showAlertNotSaveNote(state, status: boolean) {
        state.showAlertNotSaveNote = status;
    },
    itemSelected(state, product: ProductWithAttributeModel): void {
        state.itemSelected = product;
    },
    itemDefault(state, product: ProductWithAttributeModel): void {
        state.itemDefault = product;
    },
    typeAttributes(state, attributes: Array<ProductAttributeModel>): void {
        state.type_attributes = attributes;
    },
    unavailableProductsByHomologation(
        state,
        unavailableProductsByHomologation: Array<UnavailableProductsByHomologationModel>,
    ) {
        state.unavailableProductsByHomologation = unavailableProductsByHomologation;
    },
    suggestionsForUnAvailableProducts(state, suggestions: ProductSuggested): void {
        if (!suggestions?.unAvailableProduct) {
            state.suggestionsForUnAvailableProducts = [];
            return;
        }
        const tempState = state.suggestionsForUnAvailableProducts.filter(
            (iSuggestion: ProductSuggested) => iSuggestion.unAvailableProduct.id !== suggestions.unAvailableProduct.id,
        );
        state.suggestionsForUnAvailableProducts = [...tempState, suggestions];
    },
    deactivatedProducts(state, products: Array<ProductModel>): void {
        state.deactivatedProducts = products ?? [];
    },
    removeOneDeactivatedProduct(state, productId: number): void {
        state.suggestionsForUnAvailableProducts = state.suggestionsForUnAvailableProducts.filter(
            (iSuggestion: ProductSuggested) => iSuggestion.unAvailableProduct.id !== productId,
        );
    },
};
