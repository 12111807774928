import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { YourRetailersInfo } from '@/modules/retailers/models/OtherRetailersModel';

const state = {
    yourRetailers: {} as YourRetailersInfo,
    otherRetailers: [],
    retailers: [],
    filters: [],
    retailerSelected: null,
    cartRetailerItems: 0,
    retailersAvailability: [],
    originalRetailersAvailability: [],
    bannerRetailers: [],
    bannerRetailersImg: undefined,
    retailersAlertLimitShowed: [],
    deferredOptionByRetailer: undefined,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
