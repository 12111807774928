import { StoriesResultModel } from '@/models/stories/StoriesResultModel';

export default {
    stories(state): Array<StoriesResultModel> {
        return state.stories;
    },
    featuredStory(state): StoriesResultModel {
        return state.featuredStory;
    },
    selectedIndex(state): number {
        return state.selectedIndex;
    },
    selectedStory(state): StoriesResultModel {
        return state.selectedStory;
    },
    informativeStory(state): StoriesResultModel {
        return state.informativeStory;
    },
};
