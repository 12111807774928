export const enum OrderState {
    PAYMENT_REJECTED = 'PAYMENT_REJECTED',
    EXTRA_ITEMS_PAYMENT_REJECTED = 'EXTRA_ITEMS_PAYMENT_REJECTED',
    ORDER_DELIVERED_RATED = 'ORDER_DELIVERED_RATED',
    ORDER_DELIVERED = 'ORDER_DELIVERED',
    ORDER_DELIVERED_UNRATED = 'ORDER_DELIVERED_UNRATED',
    ASSIGNING_SHOPPER = 'ASSIGNING_SHOPPER',
    SHOPPER_ASSIGNED = 'SHOPPER_ASSIGNED',
    SHOPPER_BUYING = 'SHOPPER_BUYING',
    SHOPPER_DELIVERING = 'SHOPPER_DELIVERING',
    SHOPPER_IN_DESTINY = 'SHOPPER_IN_DESTINY',
    PAYMENT_IN_PROCESS = 'PAYMENT_IN_PROCESS',
    STORE_PURCHASE_COMPLETED = 'STORE_PURCHASE_COMPLETED',
    VALIDATING_WIRE_TRANSFER = 'VALIDATING_WIRE_TRANSFER',
    EXTRA_ITEMS_PAYMENT_IN_PROCESS = 'EXTRA_ITEMS_PAYMENT_IN_PROCESS',
    USER_ORDER_CANCELLED = 'USER_ORDER_CANCELLED',
    FINANCIAL_DEPARTMENT_ORDER_CANCELLED = 'FINANCIAL_DEPARTMENT_ORDER_CANCELLED',
    SHOPPER_ORDER_CANCELLED = 'SHOPPER_ORDER_CANCELLED',
    ORDER_WAITING_REPLACED_CONFIRMATION = 'ORDER_WAITING_REPLACED_CONFIRMATION',
    ORDER_WAITING_SHOPPER_IN_STORE = 'ORDER_WAITING_SHOPPER_IN_STORE',
}
