<script lang="ts" setup>
import TheCardMembership from '@/views/creditCards/components/listCards/TheCardMembership.vue';
import WalletSectionCards from '@/views/wallet/components/WalletSectionCards.vue';
import { useWallet } from '@/views/wallet/useWallet';
import { useRouter } from 'vue-router';
import { AffiliationMembership } from '@/views/wallet/WalletModels';
import { appRoutesMap } from '@/router/appRoutesMap';

const $router = useRouter();
const { memberships, getMemberships, membershipSelected } = useWallet();

await getMemberships();

const goToRoute = (item: AffiliationMembership) => {
    membershipSelected.value = item;
    return $router.push({
        name: appRoutesMap.WalletCard,
    });
};
</script>

<template>
    <WalletSectionCards :items="memberships" :title="$t('txt.membership_cards')">
        <template #default="{ item, style }">
            <TheCardMembership :card="item" :style="style" @click.stop="goToRoute(item)" />
        </template>
    </WalletSectionCards>
</template>
