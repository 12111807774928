<template>
    <base-bottom-sheet bgColor="#FFF" v-if="isActive && isMobile" :canDrag="canDrag" @on-dismiss="closeBottomSheet">
        <template v-slot:content>
            <div class="bottom-sheet-feedback">
                <img
                    class="bottom-sheet-feedback__success"
                    v-if="isSuccess"
                    src="@/assets/gif/success.gif"
                    :alt="$t('icon.success')"
                />
                <icon-circular-close :iconName="$t('icon.error')" v-else-if="isFailure" :size="1.5" color="#F03F51" />
                <triple-loading v-else-if="isLoading" />
                <h1 class="bottom-sheet-feedback__title" v-if="title.length">{{ title }}</h1>
                <h2 class="bottom-sheet-feedback__body" v-if="body.length">{{ body }}</h2>
                <slot name="content" />
                <btn-solid :txt="ctaText" v-if="showAction && ctaText.length" @click="$emit('on-action')" />
                <btn-text
                    class="cta-text--normal"
                    :text="linkText"
                    v-if="showAction && linkText.length"
                    @click="$emit('on-text-action')"
                />
            </div>
        </template>
    </base-bottom-sheet>
</template>

<script lang="ts">
import { isMobileBrowser } from '@/utils/utils';
import BaseBottomSheet from '@/components/bottomSheet/BaseBottomSheet.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import TripleLoading from '@/components/loading/TripleLoading.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnText from '@/components/buttons/BtnText.vue';

// TODO: refactor: maintain the logic with SOLID principle
export default {
    name: 'bottom-sheet-feedback',
    data() {
        return {
            showSnackBar: false,
        };
    },
    components: {
        BtnText,
        BtnSolid,
        BaseBottomSheet,
        IconCircularClose,
        TripleLoading,
    },
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isSuccess: {
            type: Boolean,
            default: false,
        },
        isFailure: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        body: {
            type: String,
            default: '',
        },
        ctaText: {
            type: String,
            default: '',
        },
        linkText: {
            type: String,
            default: '',
        },
        showAction: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        isLoading() {
            if (!this.isLoading) this.setTimeOut();
        },
    },
    emits: ['on-close', 'on-action', 'on-text-action'],
    computed: {
        isMobile(): boolean {
            return isMobileBrowser();
        },
        canDrag(): boolean {
            return !this.isLoading;
        },
    },
    methods: {
        setTimeOut() {
            if (this.isActive) {
                window.clearTimeout(5000);
                window.setTimeout(() => {
                    this.closeBottomSheet();
                }, Number(5000));
            }
        },
        closeBottomSheet(): void {
            if (this.isLoading) return;
            this.$emit('on-close');
        },
    },
};
</script>
<style lang="scss" scoped>
@import './bottom-sheet-feedback.scss';
</style>
