<template>
    <base-icon icon-name="watch" :width="width" :height="height" viewBox="0 0 13 13" :iconColor="color">
        <path
            d="M6.5 0C2.91576 0 0 2.91576 0 6.5C0 10.0842 2.91576 13 6.5 13C10.0842 13 13 10.0842 13 6.5C13 2.91576 10.0842 0 6.5 0ZM9.59131 9.86208C9.48568 9.9677 9.34702 10.0209 9.20837 10.0209C9.06971 10.0209 8.93095 9.9677 8.82542 9.86208L6.11706 7.15381C6.0152 7.05254 5.95837 6.91488 5.95837 6.77087V3.25C5.95837 2.95047 6.20097 2.70837 6.5 2.70837C6.79903 2.70837 7.04163 2.95047 7.04163 3.25V6.54662L9.59131 9.09619C9.80306 9.30804 9.80306 9.65032 9.59131 9.86208Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-watch',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 15 * this.size;
        },
        height(): number {
            return 15 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
