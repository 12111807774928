import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6df7615c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "skeleton-checkout" }
const _hoisted_2 = { class: "skeleton-checkout__left" }
const _hoisted_3 = { class: "skeleton-checkout__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("aside", _hoisted_2, [
      _createVNode(_component_base_card, {
        padding: "0",
        class: "skeleton-checkout__left-menu animate"
      }),
      _createVNode(_component_base_card, {
        padding: "0",
        class: "skeleton-checkout__left-retailers animate"
      }),
      _createVNode(_component_base_card, {
        padding: "0",
        class: "skeleton-checkout__left-retailers animate"
      })
    ]),
    _createElementVNode("aside", _hoisted_3, [
      _createVNode(_component_base_card, {
        padding: "0",
        class: "skeleton-checkout__right-balance animate"
      })
    ])
  ]))
}