<template>
    <section class="list-cards" :class="{ 'list-cards--centered': isScreenOrders }">
        <template v-if="showSkeleton">
            <skeleton-credit-card v-for="index in 3" :key="index" />
        </template>
        <credit-card-with-actions
            v-for="card in cards"
            v-else
            :key="card.id"
            :card="card"
            :is-delete="isDelete"
            :is-maxi-cards="isMaxiCards"
            @add-card="$emit('add-card')"
            @fetch-list="$emit('fetch-list')"
        />
    </section>
</template>

<script lang="ts" setup>
import CreditCardWithActions from '@/views/creditCards/components/listCards/CreaditCardWithActions.vue';
import SkeletonCreditCard from '@/components/skeletons/skeletonCreditCards/SkeletonCreditCard.vue';
import { useRoutes } from '@/composables/useRoutes';

defineProps({
    cards: {
        required: true,
    },
    isDelete: {
        type: Boolean,
        default: false,
    },
    isMaxiCards: {
        type: Boolean,
        default: false,
    },
    showSkeleton: {
        type: Boolean,
        default: false,
    },
});

const { isScreenOrders } = useRoutes();

defineEmits<{
    (e: 'fetch-list'): void;
    (e: 'add-card'): void;
}>();
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.list-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, 26rem);
    gap: $margin-md;
    @include tablet {
        gap: $margin-sm;
        justify-items: center;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 20rem);
    }

    &--centered {
        justify-items: center;
        justify-content: center;
    }
}
</style>
