<template>
    <aside class="pick-up-centers">
        <h1 class="pick-up-centers__title">{{ $t('alerts.pickup__footer') }}</h1>
        <nav class="centers">
            <button v-for="center in centers" :key="center.id" class="center" @click="$emit('on-selected', center)">
                <img class="center__img" :src="center.logo" alt="" />
                <div class="center-data">
                    <h2 class="center-data__name">{{ center.name }}</h2>
                    <h3 class="center-data__address">{{ center.address }}</h3>
                    <p class="center-data__sector">{{ center?.city?.name ?? '' }}, {{ center?.sector.name ?? '' }}</p>
                </div>
                <icon-arrow :size="0.5" />
            </button>
        </nav>
    </aside>
</template>
<script lang="ts">
import IconArrow from '@/components/icons/IconArrow.vue';

export default {
    name: 'pick-up-center',
    emits: ['on-selected'],
    props: {
        centers: {
            type: Array,
        },
    },
    components: { IconArrow },
};
</script>

<style lang="scss" scoped>
@import './pick-up-centers';
</style>
