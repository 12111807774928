<template>
    <slot />
    <base-card class="hours-retailers" padding="0">
        <no-content
            v-if="!deliveryDays?.length"
            :description="$t('txt.schelude__no-schedule-subtitle')"
            :title="$t('txt.schelude__no-schedule-title')"
        >
            <icon-afflicted-shop-bag color="#c1c1c1" />
        </no-content>
        <div v-else class="schedules-container">
            <p class="schedules-container__section">{{ $t('general.date') }}:</p>
            <div class="schedules-container__dates schedules-container--static">
                <button
                    v-for="hourItem in deliveryDays"
                    :key="hourItem.date.toString()"
                    class="schedules-container__dates-cards cursor-pointer"
                    :class="{ 'schedules--bg-orange': daySelected.date === hourItem.date }"
                    type="button"
                    @click="selectDate(hourItem)"
                >
                    <span
                        v-date.upper="{ date: hourItem.date, format: 'DD' }"
                        class="schedules-container__date schedules-container__date--number"
                        :class="{ 'schedules--white': daySelected.date === hourItem.date }"
                    />
                    <span
                        v-date="{ date: hourItem.date, format: 'ddd' }"
                        class="schedules-container__date"
                        :class="{ 'schedules--white': daySelected.date === hourItem.date }"
                    />
                </button>
            </div>
            <section v-if="stockItem" class="hours-product">
                <img class="hours-product__img" alt="" src="../../../../assets/svg/stok-item-delivery.svg" />
                <p class="hours-product__info">
                    {{
                        $t('txt.product__delivery_scheduled', {
                            name: stockItem?.name,
                            hours: stockItem?.hoursToDelivery,
                        })
                    }}
                </p>
                <p class="hours-product__info">
                    {{ $t('txt.product__delivery_scheduled_2') }}
                </p>
            </section>
            <template v-else>
                <hours-grid v-model="hourSelected" :day-schedules="daySelected?.schedules" />
            </template>
        </div>
    </base-card>
</template>

<script lang="ts" setup>
import { HourModel, ScheduleModel } from '@/models/retailers/HourModel';
import { logger } from '@/utils/logger';

import BaseCard from '@/components/cards/BaseCard.vue';
import HoursGrid from './HourSelector.vue';
import IconAfflictedShopBag from '@/components/icons/IconAfflictedShopBag.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import { useStockItemSchedule } from '@/composables/useStockItemSchedule';
import { useCheckoutEvents } from '@/views/checkout/composables/useCheckoutEvents';
import { PropType, ref, watch } from 'vue';
import { useCart } from '@/composables/useCart';
import { usePickUp } from '@/composables/usePickUp';
import { useApp } from '@/composables/useApp';
import { ProductModel } from '@/models/product/ProductModel';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { RetailerAvailabilityModel } from '@/modules/retailers/models/RetailerAvailabilityModel';

const timeDelivery = defineModel({
    type: Object as PropType<TimeDeliveryModel>,
    default: undefined,
});
const props = defineProps({
    retail: {
        type: Object as PropType<RetailerAvailabilityModel>,
        required: true,
    },
    stockItem: {
        type: Object as PropType<ProductModel>,
        default: undefined,
    },
});

const $emits = defineEmits<(e: 'on-load') => void>();
const { $store } = useApp();
const { isPickup } = usePickUp();
const { findRetail } = useCart();
const { fetchProductSchedules } = useStockItemSchedule();
const { selectNewScheduleOnCheckout } = useCheckoutEvents();
const deliveryDays = ref<HourModel[]>([]);

const daySelected = ref<HourModel>();
const hourSelected = ref<ScheduleModel>();

const getRetailer = findRetail(props.retail.retailer_id);

watch(hourSelected, (value, oldValue) => {
    const shippingCost = props.stockItem ? daySelected.value?.unique_delivery_shipping_cost : value?.price;
    const originalShippingCost = props.stockItem
        ? daySelected.value?.original_unique_delivery_shipping_cost
        : value?.original_price;
    timeDelivery.value = new TimeDeliveryModel({
        retailerId: props.retail.retailer_id,
        shippingCost: `${shippingCost}`,
        originalShippingCost: `${originalShippingCost}`,
        stockItemId: props.stockItem?.id,
        retailerName: props.retail.retailer_name,
        productName: props.stockItem?.name,
        retailerLogo: props.retail.retailer_icon,
        express: value?.express,
        date: `${daySelected.value?.date}`,
        deliveryFirstDate: value.first_hour,
        deliverySecondDate: value.second_hour,
        deliveryType: daySelected.value?.type,
    });
    if (oldValue) selectNewScheduleOnCheckout(getRetailer?.toAnalytics);
});

const getHours = async (): Promise<HourModel[]> => {
    if (props.stockItem)
        return fetchProductSchedules({
            retailer_id: props.retail.retailer_id,
            stock_id: props.stockItem.id,
        });
    if (isPickup.value) return $store.dispatch('retailers/getRetailerHoursPickUp');
    return $store.dispatch('retailers/getRetailerHours', props.retail.retailer_id);
};

const fetchHours = async (): Promise<void> => {
    try {
        const result = await getHours();
        deliveryDays.value = result.filter((hour) => !!hour?.schedules?.length);
        if (!deliveryDays.value?.length) return;
        $emits('on-load');
        daySelected.value = deliveryDays.value?.at(0);
        hourSelected.value = daySelected.value?.schedules?.at(0);
    } catch (err) {
        logger('FETCH_HOURS', err);
    }
};

const selectDate = (hourItem: HourModel) => {
    selectNewScheduleOnCheckout(getRetailer?.toAnalytics);
    daySelected.value = hourItem;
    hourSelected.value = hourItem.schedules.at(props.stockItem ? -1 : 0);
};

await fetchHours();
</script>
<style lang="scss" scoped>
@import 'delivery-schedules';
</style>
