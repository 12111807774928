<template>
    <skeleton-stories v-if="!stories?.length && !storyIsEmpty"></skeleton-stories>
    <div v-if="stories?.length" class="stories-bar">
        <div v-if="featuredStory" class="story-miniature" :class="{ viewed: featuredStory.viewed }">
            <img :src="featuredStory.iconStory" alt="" loading="lazy" @click="goToStory(featuredStory)" />
            <div class="prime-icon">
                <icon-prime-star :icon-name="$t('icon.featured')" :size="0.8" color="#FF9012"></icon-prime-star>
            </div>
        </div>
        <vertical-divider v-if="featuredStory" height="74px" width="3px"></vertical-divider>
        <carousel-tipti
            v-if="stories?.length"
            :has-margin="false"
            :is-last-page="isLastPage"
            :is-loading="isLoading"
            @reached-before-end="updateStories"
        >
            <img
                v-for="(story, index) in stories"
                :key="index"
                class="story-miniature cursor-pointer"
                :class="{ viewed: story.viewed }"
                :alt="story.title"
                :src="story.iconStory"
                loading="lazy"
                @click="goToStory(story, index)"
                @error="displayDefaultImg"
            />
        </carousel-tipti>
    </div>
    <story-page v-if="showStory" @on-close="closeStoryPage"></story-page>
</template>

<script lang="ts">
import { isMobileBrowser } from '@/utils/utils';
import { mapGetters } from 'vuex';
import { StoriesResultModel } from '@/models/stories/StoriesResultModel';
import { logger } from '@/utils/logger';

import VerticalDivider from '@/components/dividers/VerticalDivider.vue';
import IconPrimeStar from '@/components/icons/IconPrimeStar.vue';
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';
import SkeletonStories from '@/components/skeletons/skeletonStories/SkeletonStories.vue';
import { defineAsyncComponent } from 'vue';

export default {
    name: 'TheStoriesCarousel',
    components: {
        VerticalDivider,
        IconPrimeStar,
        StoryPage: defineAsyncComponent(() => import('./components/storyPage/StoryPage.vue')),
        CarouselTipti,
        SkeletonStories,
    },
    data() {
        return {
            showStory: false,
            isLoading: false,
            isLastPage: false,
            storyIsEmpty: false,
            page: 1,
        };
    },
    computed: {
        ...mapGetters({
            featuredStory: 'stories/featuredStory',
            stories: 'stories/stories',
        }),
        isMobile() {
            return isMobileBrowser();
        },
    },
    created() {
        this.$store.commit('stories/stories', []);
    },
    async mounted() {
        await this.fetchTheStories();
    },
    methods: {
        displayDefaultImg(event) {
            event.target.src = require('@/assets/svg/tipti_logo/ic_tipti_orange.svg');
        },
        closeStoryPage(): void {
            this.showStory = false;
            this.page = 1;
            this.fetchTheStories();
        },

        async updateStories(): Promise<void> {
            if (this.isLoading || this.isLastPage) return;
            this.page += 1;
            await this.fetchTheStories();
        },
        async goToStory(selectedStory: StoriesResultModel, index: number): Promise<void> {
            this.$store.commit('stories/selectedStory', selectedStory);
            this.$store.commit('stories/selectedIndex', index);
            this.showStory = true;
        },
        async fetchTheStories(): Promise<void> {
            try {
                this.isLoading = true;
                this.isLastPage = await this.$store.dispatch('stories/stories', this.page);
                if (this.stories.length === 0) this.storyIsEmpty = true;

                const storiesSegment = this.stories.map((element) => {
                    return {
                        story_id: element.id,
                        story_type: element.type,
                        story: element.title,
                    };
                });
                this.$store.dispatch('segment/storiesImpressions', {
                    stories: storiesSegment,
                });
            } catch (err) {
                logger('FETCH_STORIES', err);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import './the-stories-carousel.scss';
</style>
