<template>
    <div class="horizontal-divider" :style="styles"></div>
</template>

<script lang="ts">
export default {
    name: 'horizontal-divider',
    props: {
        color: {
            type: String,
            default: '#C7C7C7',
        },
        height: {
            type: String,
            default: '0',
        },
        width: {
            type: String,
            default: '100%',
        },
        lineHeight: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        styles(): object {
            return {
                'background-color': this.color,
                margin: `${this.height} 0`,
                width: `${this.width}`,
                height: `${this.lineHeight}px`,
            };
        },
    },
};
</script>
