<template>
    <btn-circular v-if="isMobile" class="btn-actions" bg-color="#fff">
        <icon-increment :size="1" color="#707070" />
    </btn-circular>
    <base-btn
        v-else
        class="base-btn--no-shadow"
        :outline="false"
        bg-color="#fff"
        color="#707070"
        padding="0 0.5rem"
        @mouseout="mouseout"
        @mouseover="mouseover"
    >
        <icon-increment :size="1" color="#707070" />
        <p class="add-text">{{ btnText }}</p>
    </base-btn>
</template>

<script lang="ts" setup>
import IconIncrement from '@/components/icons/IconIncrement.vue';
import BtnCircular from '@/components/buttons/BtnCircular.vue';
import BaseBtn from '@/components/buttons/BaseBtn.vue';
import { useApp } from '@/composables/useApp';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

const { t: $t } = useI18n();
const { isMobile } = useApp();
const btnText = ref($t('cta.add'));

const mouseover = (): void => {
    btnText.value = $t('cta.add_to_cart');
};
const mouseout = (): void => {
    btnText.value = $t('cta.add');
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.add-text {
    @include button;
    border-radius: 2rem;
    text-align: center;
    transition: all 250ms ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: var(--overflow);
    margin: $margin-sm;
    margin-left: $margin-xsm;
}
</style>
