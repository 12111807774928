import { ref, shallowRef } from 'vue';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import { usePayments } from '@/composables/usePayments';
import {
    AffiliationMembership,
    AffiliationMembershipBenefit,
    GiftCardDTO,
    GiftCardRetailerCategoryDTO,
    GiftCardRetailerWithProductsDTO,
    GiftCardRetailerWithProductsViewModel,
    GiftCardTransactionDTO,
} from '@/views/wallet/WalletModels';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { ProductModel, toProductModel } from '@/models/product/ProductModel';
import { ResponsePaged } from '@/modules/core/models/ResponseModels';
import { WalletInstitution } from '@/views/wallet/InstitutionalWallets';
import { logger } from '@/utils/logger';

const creditCards = shallowRef<PaymentMethodModel[]>([]);
const memberships = ref<AffiliationMembership[]>([]);
const mainGiftCards = ref<GiftCardDTO[]>([]);
const expiredGiftCards = ref<GiftCardDTO[]>([]);
const giftCardRetailers = ref<GiftCardRetailerWithProductsViewModel[]>();
const giftCardTransactions = ref<GiftCardTransactionDTO[]>([]);
const giftCardCategoryProducts = ref<ProductModel[]>([]);
const giftCardRetailerCategories = ref<GiftCardRetailerCategoryDTO[]>([]);
const giftCardRetailerPrdoductsByCategory = ref<ProductModel[][]>([]);
const creditCardSelected = ref<PaymentMethodModel>();
const institutionalCardSelected = ref<WalletInstitution>();
const membershipSelected = ref<AffiliationMembership>();
const giftCardSelected = ref<GiftCardDTO>();
const giftCardRetailerSelected = ref<GiftCardRetailerWithProductsViewModel>();
const giftCardRetailerCategorySelected = ref<GiftCardRetailerCategoryDTO>();
const benefits = ref<AffiliationMembershipBenefit>();

export const useWallet = () => {
    const { fetCreditCard: getTCs } = usePayments();

    const getCreditCard = async () => {
        creditCards.value = await getTCs();
    };

    const getMemberships = async () => {
        const { data } = await axiosInstance().get<AffiliationMembership[]>('v3/client_cards/memberships/affiliation');
        memberships.value = data;
    };

    const getGiftCards = async (isExpired: boolean = false) => {
        const { data } = await axiosInstance().get<GiftCardDTO[]>('v3/gift_cards/clients', {
            params: {
                ...(isExpired ? { expired: isExpired } : {}),
            },
        });
        return data;
    };

    const getMainGiftCards = async () => {
        mainGiftCards.value = await getGiftCards();
    };

    const getExpiredGiftCards = async () => {
        expiredGiftCards.value = await getGiftCards(true);
    };

    const getGiftCardTransactions = async () => {
        const { data } = await axiosInstance().get<GiftCardTransactionDTO[]>(
            `v3/gift_cards/clients/${giftCardSelected.value.id}/transaction_history`,
            {
                params: {
                    all: true,
                },
            },
        );
        giftCardTransactions.value = data;
    };

    const getGiftCardRetailers = async () => {
        const { data } = await axiosInstance().get<ResponsePaged<GiftCardRetailerWithProductsDTO>>(
            'v3/gift_card/client/retailers_items',
            {
                params: {
                    gift_card_id: giftCardSelected.value.id,
                    page_size: 10,
                },
            },
        );
        giftCardRetailers.value = data.results.map((item) => {
            return {
                id: item.id,
                name: item.name,
                imgUrl: item.logo,
                hasCategories: item.has_categories,
                deliveryRange: item.delivery_range,
                products: item.stockitems.map((product) => toProductModel(product)),
            };
        });
    };

    const getGiftCardProducts = async (categoryId?: number) => {
        const { data } = await axiosInstance().get<ResponsePaged<Object[]>>('v3/gift_card/client/all_products', {
            params: {
                gift_card_id: giftCardSelected.value.id,
                retailer_id: giftCardRetailerSelected.value.id,
                category_id: categoryId,
            },
        });
        return data.results.map((item) => toProductModel(item));
    };

    const getGiftCardCategoryProducts = async () => {
        giftCardCategoryProducts.value = await getGiftCardProducts(giftCardRetailerCategorySelected?.value?.id);
    };

    const getGiftCardRetailerCategories = async () => {
        const { data } = await axiosInstance().get<GiftCardRetailerCategoryDTO[]>('v3/gift_card/client/categories', {
            params: {
                gift_card_id: giftCardSelected.value.id,
                retailer_id: giftCardRetailerSelected.value.id,
            },
        });
        giftCardRetailerCategories.value = data;
    };

    const getGiftCardRetailerProductsByCategory = async () => {
        giftCardRetailerPrdoductsByCategory.value = await Promise.all(
            giftCardRetailerCategories.value.map((e) => getGiftCardProducts(e.id)),
        );
    };

    const $clean = () => {
        giftCardSelected.value = undefined;
        creditCardSelected.value = undefined;
        membershipSelected.value = undefined;
        institutionalCardSelected.value = undefined;
    };

    const getMembershipCardById = async () => {
        try {
            const { data } = await axiosInstance().get<AffiliationMembershipBenefit>(
                `/v3/client_membership/card/affiliation/${membershipSelected.value.id}/benefits`,
                {
                    params: {
                        version: '2',
                    },
                },
            );
            benefits.value = data;
        } catch (e) {
            logger('MembershipCardById', e);
        }
    };

    return {
        getCreditCard,
        getMemberships,
        getMainGiftCards,
        getExpiredGiftCards,
        getGiftCardTransactions,
        getGiftCardRetailers,
        getGiftCardCategoryProducts,
        getGiftCardRetailerCategories,
        getGiftCardRetailerProductsByCategory,
        creditCards,
        memberships,
        mainGiftCards,
        expiredGiftCards,
        membershipSelected,
        creditCardSelected,
        giftCardSelected,
        giftCardTransactions,
        giftCardRetailers,
        giftCardRetailerSelected,
        giftCardCategoryProducts,
        giftCardRetailerCategories,
        giftCardRetailerPrdoductsByCategory,
        giftCardRetailerCategorySelected,
        $clean,
        institutionalCardSelected,
        benefits,
        getMembershipCardById,
    };
};
