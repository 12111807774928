import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26f9fce4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "model-error" }
const _hoisted_2 = {
  key: 0,
  class: "model-error__oops"
}
const _hoisted_3 = {
  key: 1,
  class: "model-error__title"
}
const _hoisted_4 = {
  key: 2,
  class: "model-error__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_circular_close = _resolveComponent("icon-circular-close")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_btn_text = _resolveComponent("btn-text")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    hasClose: $props.hasClose,
    hasHeader: $props.hasHeader,
    title: $props.title,
    onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon_circular_close, {
          iconName: _ctx.$t('icon.error'),
          size: 1.5,
          color: "#F03F51"
        }, null, 8, ["iconName"]),
        ($props.showOops)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_2, "Oops!"))
          : _createCommentVNode("", true),
        ($props.title.length && !$props.hasHeader)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString($props.title), 1))
          : _createCommentVNode("", true),
        ($props.body.length)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString($props.body), 1))
          : _createCommentVNode("", true),
        ($props.showAction && $props.ctaText.length)
          ? (_openBlock(), _createBlock(_component_btn_solid, {
              key: 3,
              txt: $props.ctaText,
              class: "model-loading__action",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-action')))
            }, null, 8, ["txt"]))
          : _createCommentVNode("", true),
        ($props.showAction && $props.linkText.length)
          ? (_openBlock(), _createBlock(_component_btn_text, {
              key: 4,
              text: $props.linkText,
              class: "cta-text--normal",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-text-action')))
            }, null, 8, ["text"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["hasClose", "hasHeader", "title"]))
}