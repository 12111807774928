<template>
    <section class="credit-card-information">
        <img v-if="icon" class="credit-card-information__icon" :alt="name" :src="icon" />
        <template v-for="(key, index) in numberInformation" :key="index">
            <span
                v-if="isNaN(key)"
                class="credit-card-information__dot"
                :class="{
                    'credit-card-information__dot--space': index === 3 || index === 7 || index === 11,
                }"
            />
            <p
                v-else
                class="credit-card-information__number"
                :class="{
                    'credit-card-information__dot--space': index === 3 || index === 7 || index === 11,
                }"
            >
                {{ key }}
            </p>
        </template>
    </section>
</template>
<script lang="ts">
import { PropType } from 'vue';

enum Colors {
    orange = '#ff9012',
    magenta = '#f03f51',
    text = '#707070',
    border = '#c7c7c7',
}

type ColorAvailable = keyof typeof Colors;
export default {
    name: 'CreditCardIdentifier',
    props: {
        name: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        number: {
            type: String,
            default: '',
        },
        dotColor: {
            type: String as PropType<ColorAvailable>,
            default: 'border',
        },
        textColor: {
            type: String as PropType<ColorAvailable>,
            default: 'text',
        },
    },
    computed: {
        localDotColor(): Colors {
            return Colors[this.dotColor] ?? Colors.border;
        },
        localTextColor(): Colors {
            return Colors[this.textColor] ?? Colors.text;
        },
        numberInformation(): string {
            return `${this.number}`.padStart(16, 'x');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.credit-card-information {
    display: flex;
    align-items: center;
    flex-direction: row;
    &__number {
        @include body2;
        color: v-bind(localTextColor);
    }
    &__dot {
        min-width: 0.4rem;
        min-height: 0.4rem;
        border-radius: $radius-circular;
        margin-left: $margin-xsm;
        background-color: v-bind(localDotColor);
        @include phone {
            min-width: 0.3rem;
            min-height: 0.3rem;
            margin-left: calc($margin-xsm/2);
        }
        &--space {
            margin-right: $margin-sm;
        }
    }
    &__icon {
        height: $margin-lg;
    }
}
</style>
