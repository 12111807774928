<template>
    <section class="search-products">
        <search
            :is-alternative-style="searchCategories"
            :is-autocomplete="hasAutocomplete && showPredictions"
            :is-loading="isLoading || isLoadingResults"
            :placeholder="searchPlaceholder"
            @on-keyup="autocomplete"
            @on-keyup-esc="cleanPredictions"
            @on-blur="cleanPredictions"
            @on-search="(value: string) => onSearch(value, null, false)"
            @on-change-value="(value: string) => (query = value)"
            @on-focus="focusedInput = true"
            @on-clean-query="$emit('on-clean')"
        />
        <aside
            v-if="isAutocompleteActive"
            ref="slide"
            v-click-outside="cleanYourProducts"
            class="autocomplete"
            @keydown.esc="cleanYourProducts"
        >
            <autocomplete-your-products
                v-if="yourProducts?.length && !isHomePage"
                :products="yourProducts"
                :products-prediction-quantity="results.length"
                @on-select-product="onSearch"
            />
            <search-results v-if="!isHomePage" :products="results" :query="query" @on-click="onSearch" />
            <template v-else>
                <autocomplete-retailers-global-search
                    v-if="retailers.length && retailersSearched.length"
                    :retailers-global-search="retailersSearched"
                    @on-select-retailer="onSelectRetailer"
                />
                <autocomplete-products-global-search :products-global-search="results" @on-select-product="onSearch" />
            </template>
        </aside>
    </section>
</template>

<script lang="ts">
import { mapActions } from 'vuex';
import { logger } from '@/utils/logger';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import { SearchBar } from '@/enums/searchBar';
import Search from '../search/Search.vue';
import AutocompleteYourProducts from '@/components/autocomplete/autocompleteYourProducts/AutocompleteYourProducts.vue';
import vClickOutside from '@/directives/click_outside';
import AutocompleteProductsGlobalSearch from '@/components/autocomplete/autocompleteProductsGlobalSearch/AutocompleteProductsGlobalSearch.vue';
import AutocompleteRetailersGlobalSearch from '@/components/autocomplete/autocompleteRetailersGlobalSearch/AutocompleteRetailersGlobalSearch.vue';
import SearchResults from '@/components/autocomplete/autocomplete/SearchResults.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { Sections } from '@/enums/sectionsOrigin';
import { PropType, ref } from 'vue';
import { useProductStore } from '@/store/useProductStore';
import { useUser } from '@/composables/useUser';
import { useRoutes } from '@/composables/useRoutes';
import { ProductModel } from '@/models/product/ProductModel';
import { GlobalSearchProductModel } from '@/models/product/ProductModelAdapters.ts';

// TODO refactor this to call endpoint only when the previous value change with the current value
export default {
    name: 'SearchProducts',
    components: {
        Search,
        AutocompleteYourProducts,
        AutocompleteProductsGlobalSearch,
        AutocompleteRetailersGlobalSearch,
        SearchResults,
    },
    directives: {
        clickOutside: vClickOutside,
    },
    props: {
        hasAutocomplete: {
            type: Boolean,
            default: true,
        },
        searchCategories: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        originSection: {
            type: String as PropType<keyof typeof Sections>,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
    },
    emits: ['on-search', 'on-empty-search', 'on-clean', 'on-blur'],
    setup() {
        const results = ref<Array<ProductModel | GlobalSearchProductModel>>([]);
        const { profile } = useUser();
        const { recommendationsAutocomplete, autocompleteSearch, autocompleteGlobalSearch } = useProductStore();
        const { goToRetailer, retailers, retailerSelected } = useRetailer();
        const { isHomePage } = useRoutes();
        return {
            results,
            isHomePage,
            profile,
            goToRetailer,
            retailers,
            retailerSelected,
            recommendationsAutocomplete,
            autocompleteSearch,
            autocompleteGlobalSearch,
        };
    },
    data() {
        return {
            query: '',
            retailersSearched: [],
            showPredictions: false,
            yourProducts: [],
            focusedInput: false,
            isLoadingResults: false,
            delayTime: 250,
        };
    },
    computed: {
        isAutocompleteActive(): boolean {
            return this.hasAutocomplete && this.showPredictions;
        },
        options(): Array<string> {
            return [...this.yourProducts, ...this.results]?.map((option) =>
                'product_name' in option ? option.product_name : option.name,
            );
        },
        searchBar(): string {
            return this.isHomePage ? SearchBar.GLOBAL : SearchBar.GLOBAL_BY_RETAILER;
        },
        searchPlaceholder(): string {
            return this.isHomePage
                ? this.$t('general.search-on-stores')
                : this.placeholder ?? `${this.$t('general.search-on')} ${this.retailerSelected?.name ?? ''}`;
        },
    },
    watch: {
        $route(to, _) {
            /// ** clean query everytime the user move to a route that does not have the query searchOn
            if (to.params['searchOn']) return;
            this.query = '';
            this.$store.commit('search/query', '');
            this.setShowPredictions(false);
        },
    },
    created() {
        this.setShowPredictions(false);
    },
    methods: {
        ...mapActions({
            fetchRetailersGlobalSearch: 'search/retailersGlobalSearch',
        }),
        async autocomplete(event: KeyboardEvent): Promise<void> {
            if (event.key?.length > 2) return;

            if (!this.hasAutocomplete) return;
            try {
                this.setShowPredictions(this.query.length >= 3);
                if (this.query.length < 3 || this.isLoading) {
                    this.results = [];
                    this.yourProducts = [];
                    return;
                }

                if (['Backspace', 'Enter', 'Escape'].includes(event.key)) return;

                setTimeout(async () => {
                    try {
                        if (this.isLoadingResults) return;
                        this.isLoadingResults = true;
                        this.yourProducts = !this.isHomePage
                            ? await this.recommendationsAutocomplete({
                                  query: this.query,
                                  userId: this.profile.id,
                                  retailerId: this.retailerSelected?.id,
                              })
                            : [];
                        this.retailersSearched = this.isHomePage
                            ? await this.fetchRetailersGlobalSearch(this.query)
                            : [];
                        this.results = this.isHomePage
                            ? await this.autocompleteGlobalSearch(this.query)
                            : await this.autocompleteSearch({
                                  query: this.query,
                                  retailerId: this.retailerSelected?.id,
                              });
                    } finally {
                        this.isLoadingResults = false;
                    }
                }, this.delayTime);
            } catch (err) {
                logger('AUTOCOMPLETE', err);
            }
        },
        cleanPredictions(event?) {
            if (!this.hasAutocomplete || event?.relatedTarget) return;

            setTimeout(() => {
                this.setShowPredictions(false);
            }, this.delayTime);
            this.focusedInput = false;
            this.$emit('on-blur');
        },
        cleanYourProducts() {
            if (!this.focusedInput) {
                this.yourProducts = [];
                this.cleanPredictions();
            }
            this.focusedInput = false;
        },
        async onSearch(query: string, retailerId: number, sendAutocompleteAnalytic = true): Promise<void> {
            this.$store.commit('search/searchOriginSection', this.originSection);
            if (sendAutocompleteAnalytic)
                this.$store.dispatch('segment/searchAutocompleteSelected', {
                    keyword: this.query,
                    search_bar: {
                        search_bar: this.searchBar,
                        search_bar_id: this.searchBar,
                    },
                    origin: {
                        screen: this.$route.path,
                        section: this.originSection,
                    },
                    options: this.options,
                    optionSelected: query,
                });
            this.setShowPredictions(false);
            this.query = query;
            this.$store.commit('search/query', query);
            if (!query.length) this.$emit('on-empty-search');
            if (!query.length || this.isLoading) return;
            if (this.isHomePage) {
                const retailer: RetailerModel = this.retailers.find((retailer) => retailer.id === retailerId);
                if (retailer) this.retailerSelected = retailer;
            }
            //** first navigate to retailer if exists
            //** It only runs in the global home browser
            if (!this.searchCategories) {
                await this.goToRetailer();
            }
            await this.$emit('on-search', query);
        },
        onSelectRetailer(payload: { id: number; name: string }): void {
            const retailer: RetailerModel = this.retailers.find((element) => element.id === payload?.id);
            if (retailer) this.retailerSelected = retailer;
            this.$store.dispatch('segment/retailSelected', {
                origin: {
                    screen: this.$route.path,
                    section: this.originSection,
                },
            });
            this.$store.dispatch('segment/searchAutocompleteSelected', {
                keyword: this.query,
                search_bar: {
                    search_bar: this.searchBar,
                    search_bar_id: this.searchBar,
                },
                origin: {
                    screen: this.$route.path,
                    section: this.originSection,
                },
                options: this.options,
                optionSelected: payload?.name,
            });
            this.query = '';
            this.results = [];
            this.goToRetailer();
        },
        setShowPredictions(show: boolean): void {
            this.showPredictions = show;
            this.yourProducts = [];
            this.retailersSearched = [];
        },
    },
};
</script>

<style lang="scss" scoped>
@import './search-products.scss';
</style>
