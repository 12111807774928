<template>
    <base-icon
        :height="height"
        :icon-color="color"
        :icon-name="$t('icon.payment_methods_gift_card')"
        :width="width"
        viewBox="0 0 31 21"
    >
        <path
            d="M30.5 3.70135V3.703V17.0378C30.5 18.8054 29.0646 20.2408 27.297 20.2408H3.703C1.93659 20.2408 0.5 18.7999 0.5 17.0311V3.703C0.5 1.93541 1.93541 0.5 3.703 0.5H27.297C29.0661 0.5 30.5058 1.93655 30.5 3.70135ZM28.9495 14.1991H29.4495V13.6991V3.703C29.4495 2.51628 28.4837 1.55048 27.297 1.55048H3.703C2.51628 1.55048 1.55048 2.51628 1.55048 3.703V13.6991V14.1991H2.05048H28.9495ZM1.55048 17.0378V17.1331H1.55256C1.60261 18.2758 2.54822 19.1904 3.703 19.1904H27.3037C28.4904 19.1904 29.4562 18.2246 29.4562 17.0378V16.6331V16.1331H28.9562H2.55048V15.5674H2.05048H1.55048V16.6331V17.0378Z"
            fill="#FF9012"
            stroke="#FF9012"
        />
        <path d="M19.4056 4.17773L11.9736 11.6097" stroke="#FF9012" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M13.0057 6.65487C13.8038 6.65487 14.4508 6.00787 14.4508 5.20976C14.4508 4.41165 13.8038 3.76465 13.0057 3.76465C12.2075 3.76465 11.5605 4.41165 11.5605 5.20976C11.5605 6.00787 12.2075 6.65487 13.0057 6.65487Z"
            stroke="#FF9012"
            stroke-miterlimit="10"
        />
        <path
            d="M18.3719 12.023C19.17 12.023 19.817 11.376 19.817 10.5779C19.817 9.77981 19.17 9.13281 18.3719 9.13281C17.5738 9.13281 16.9268 9.77981 16.9268 10.5779C16.9268 11.376 17.5738 12.023 18.3719 12.023Z"
            stroke="#FF9012"
            stroke-miterlimit="10"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconPaymentMethodsGiftCard',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 31 * this.size;
        },
        height(): number {
            return 21 * this.size;
        },
    },
};
</script>
