import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0524c44d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "credit-card-header-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_credit_card_list = _resolveComponent("credit-card-list")!
  const _component_icon_one_credit_card = _resolveComponent("icon-one-credit-card")!
  const _component_no_content = _resolveComponent("no-content")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($data.creditCards?.length || $data.isLoading)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          (!$data.isLoading)
            ? (_openBlock(), _createElementBlock("h6", _hoisted_2, _toDisplayString(_ctx.$t('txt.credit-card__cards-choosed')), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_credit_card_list, {
            cards: $data.creditCards,
            "is-delete": $data.isDelete,
            "show-skeleton": $data.isLoading,
            onFetchList: $options.fetchPaymentMethods,
            onAddCard: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-card')))
          }, null, 8, ["cards", "is-delete", "show-skeleton", "onFetchList"])
        ]))
      : _createCommentVNode("", true),
    (!$data.creditCards?.length && !$data.isLoading)
      ? (_openBlock(), _createBlock(_component_no_content, {
          key: 1,
          description: _ctx.$t('txt.credit-card__dont-have'),
          title: _ctx.$t('general.credit-debit-card')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_one_credit_card, {
              size: 2,
              color: "#C7C7C7"
            })
          ]),
          _: 1
        }, 8, ["description", "title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_snack_bar, {
      body: $data.modalMessage,
      "is-active": $data.showSnackBar,
      "is-failure": !$data.isSuccess,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[1] || (_cache[1] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}