<template>
    <article class="pressroom">
        <div class="pressroom__content">
            <div class="pressroom__content-text">
                <h1 class="pressroom__title">{{ $t("general['news']") }}</h1>
                <p class="pressroom__description">
                    {{ $t("general['news-text-one']") }}
                </p>
                <p class="pressroom__description">
                    {{ $t("general['news-text-two']") }}
                </p>
                <p class="pressroom__description">
                    {{ $t("general['news-text-three']") }}
                </p>
            </div>
            <img
                class="pressroom__content pressroom__img"
                alt="press-room"
                src="@/assets/img/press_room/press_room_image.png"
            />
        </div>
    </article>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.pressroom {
    padding-top: $padding-xlg;
    padding-bottom: $padding-xlg;
    padding-left: $padding-xlg;

    @include phone {
        padding-top: $padding-md;
        padding-bottom: $padding-md;
        padding-left: $padding-md;
    }

    &__content {
        display: grid;
        grid-template-columns: 35% 60%;
        gap: $margin-md;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include phone {
            display: block;
        }
    }

    &__content-text {
        grid-column: 1;
    }

    &__img {
        grid-column: 2;
        border-top-left-radius: 50% 70%;
        border-bottom-left-radius: 50% 70%;
        overflow: hidden;
    }

    &__title {
        @include headline2;
        color: $dark-grey;
        @include phone {
            width: 100%;
        }
    }

    &__description {
        @include body1;
        margin: $margin-lg $margin-none;
        color: $txt;
        font-weight: 400;
    }
}
</style>
