import { AdModel, toAdModel } from '../ads/AdModel';
import { ProductModel, toProductModel } from '../product/ProductModel';

class RecipeDetailModel {
    readonly id: number;
    readonly products: Array<ProductModel>;
    readonly name: string;
    readonly peopleNumber: number;
    readonly description: string;
    readonly ingredients: string;
    readonly preparation: string;
    readonly calories: number;
    readonly blogUrl: string;
    readonly sendEmailWithInstructions: boolean;
    readonly showIngredientsInGroup: boolean;
    readonly difficulty: number;
    readonly recipe: AdModel;

    constructor(
        id: number,
        products: Array<ProductModel>,
        name: string,
        peopleNumber: number,
        description: string,
        ingredients: string,
        preparation: string,
        calories: number,
        blogUrl: string,
        sendEmailWithInstructions: boolean,
        showIngredientsInGroup: boolean,
        difficulty: number,
        recipe: AdModel,
    ) {
        this.id = id;
        this.products = products;
        this.name = name;
        this.peopleNumber = peopleNumber;
        this.description = description;
        this.ingredients = ingredients;
        this.preparation = preparation;
        this.calories = calories;
        this.blogUrl = blogUrl;
        this.sendEmailWithInstructions = sendEmailWithInstructions;
        this.showIngredientsInGroup = showIngredientsInGroup;
        this.difficulty = difficulty;
        this.recipe = recipe;
    }
}

const toRecipeDetailModel = (data: object): RecipeDetailModel => {
    return data
        ? new RecipeDetailModel(
              data['id'],
              data['stock_items']?.map(item => toProductModel(item)),
              data['name'],
              data['people_number'] ? +data['people_number'] : 0,
              data['description'],
              data['ingredients_description'],
              data['preparation'],
              data['calories'] ? +data['calories'] : 0,
              data['blog_url'],
              data['send_email_with_instructions'],
              data['show_ingredients_in_group'],
              data['difficulty'],
              toAdModel(data['ad']),
          )
        : null;
};
export { RecipeDetailModel, toRecipeDetailModel };
