import { ProductModel } from '@/models/product/ProductModel';
import { AttributeInProductModel } from '@/models/product/AttributeInProduct';
import { useTranslate } from '@/composables/UseTranslate';
import { computed } from 'vue';

export const useProductAttribute = (product: ProductModel) => {
    const { translate } = useTranslate();

    const attributeValue = computed<string>(() => {
        const attr = product?.attributes?.find((attribute: AttributeInProductModel) => attribute.show_in_app);
        return !attr ? '' : translate({ recordable: attr?.name });
    });

    const attributeName = computed<string>(() => {
        const attr = product?.attributes?.find((attribute: AttributeInProductModel) => attribute.show_in_app);
        return !attr ? '' : translate({ recordable: attr?.type_of_attribute_name });
    });

    return {
        attributeValue,
        attributeName,
    };
};
