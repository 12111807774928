<script lang="ts" setup>
import { useTiptiCard } from '@/composables/useTiptiCard';
import WalletSectionCards from '@/views/wallet/components/WalletSectionCards.vue';
import CardTiptiCard from '@/components/cards/cardTiptiCard/CardTiptiCard.vue';
import { appRoutesMap } from '@/router/appRoutesMap';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { WalletInstitution, WalletInstitutionResponse } from '@/views/wallet/InstitutionalWallets';
import { shallowRef } from 'vue';
import InstitutionalWalletCard from '@/views/wallet/components/InstitutionalWalletCard.vue';
import { useApp } from '@/composables/useApp';
import { useWallet } from '@/views/wallet/useWallet';

defineOptions({
    name: 'CardsWithAmount',
});

const { $router } = useApp();
const { fetchTiptiCard } = useTiptiCard();
const institutions = shallowRef<WalletInstitution[]>([]);
const { institutionalCardSelected } = useWallet();
const getInstitutionalAndTiptiCard = async () => {
    const [_, institutionsResponse] = await Promise.allSettled([
        fetchTiptiCard(),
        axiosInstance().get<WalletInstitutionResponse[]>('v2/client_profile/institutional-card-payments'),
    ]);
    if (institutionsResponse?.status === 'rejected' || !institutionsResponse?.value?.data?.length) {
        institutions.value = [{ id: 0 } as WalletInstitution];
        return;
    }
    institutions.value = [
        ...institutionsResponse.value.data.map((ins) => {
            return {
                ...ins,
                benefits: {
                    ES: ins.es_benefits,
                    EN: ins.es_benefits,
                },
            };
        }),
        {} as WalletInstitution,
    ];
};

await getInstitutionalAndTiptiCard();

const setInstitution = (institution: WalletInstitution) => {
    institutionalCardSelected.value = institution;
    $router.push({
        name: appRoutesMap.walletInstitution,
    });
};
</script>

<template>
    <WalletSectionCards :items="institutions" :title="$t('txt.cards_with_balance')">
        <template #default="{ style, isLast, item, isExpanded }">
            <CardTiptiCard
                v-if="isLast"
                class="wallet-tipti-card cursor-pointer"
                :style="style"
                @click.stop="
                    $router.push({
                        name: appRoutesMap.tiptiCard,
                    })
                "
            />
            <InstitutionalWalletCard
                v-else
                :institution="item"
                :show-all-data="isExpanded"
                :style="style"
                @click.stop="setInstitution(item)"
            />
        </template>
    </WalletSectionCards>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/media-query' as mq;
@use '@/assets/scss/type-system' as ts;
@use '@/assets/scss/padding' as pd;
@use '@/assets/scss/radius' as br;
@use '@/assets/scss/colors' as cl;

.wallet-tipti-card {
    height: 13rem;
    width: 26rem;
    box-sizing: border-box;
    border-radius: br.$radius-lg;

    @include mq.tablet {
        width: 20rem;
        height: 10rem;
    }
}

.institutional-card {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    padding: pd.$padding-md;
    box-sizing: border-box;

    &__amount {
        align-self: flex-start;
        margin-top: auto;
    }

    &__amount-text {
        @include ts.headline1;

        font-size: 2.5rem;
        text-align: left;

        &--expanded {
            text-align: right;
        }

        &--label {
            @include ts.overline;
            text-align: left;

            &--expanded {
                text-align: right;
            }
        }
    }
}

.institutional-card-expanded {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__amount {
        text-align: right !important;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        white-space: nowrap;

        &__client {
            @include ts.body1;
            font-weight: 600;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
        }

        &__number {
            @include ts.body2;
        }
    }
}
</style>
