import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/svg/ic_avatar.svg'


const _withScopeId = n => (_pushScopeId("data-v-f259091e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "edit-profile__avatar",
  alt: "Tipti avatar",
  src: _imports_0
}
const _hoisted_2 = {
  key: 3,
  class: "form_header-profile"
}
const _hoisted_3 = { class: "edit-profile-form__label" }
const _hoisted_4 = { class: "edit-profile-form__radio" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_change_password = _resolveComponent("change-password")!
  const _component_section_header = _resolveComponent("section-header")!
  const _component_TabMultiOption = _resolveComponent("TabMultiOption")!
  const _component_TiptiInputText = _resolveComponent("TiptiInputText")!
  const _component_input_phone_with_label = _resolveComponent("input-phone-with-label")!
  const _component_TiptiInputPassword = _resolveComponent("TiptiInputPassword")!
  const _component_gender_selected = _resolveComponent("gender-selected")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_section_footer = _resolveComponent("section-footer")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_alert_deactivate_account = _resolveComponent("alert-deactivate-account")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($data.showChangePassword)
      ? (_openBlock(), _createBlock(_component_change_password, {
          key: 0,
          onOnSelectOption: $options.passwordChanged
        }, null, 8, ["onOnSelectOption"]))
      : (_openBlock(), _createBlock(_component_base_card, {
          key: 1,
          class: _normalizeClass(["edit-profile", { 'edit-profile--box-shadow': $props.isFromCart }]),
          padding: "0"
        }, {
          default: _withCtx(() => [
            (!$props.isFromCart)
              ? (_openBlock(), _createBlock(_component_section_header, {
                  key: 0,
                  "has-back": $options.isMobile,
                  title: _ctx.$t('txt.edit-profile__edit-my-profile'),
                  onOnBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
                }, null, 8, ["has-back", "title"]))
              : _createCommentVNode("", true),
            (!$props.isFromCart)
              ? (_openBlock(), _createElementBlock("img", _hoisted_1))
              : _createCommentVNode("", true),
            ($props.isFromCart)
              ? (_openBlock(), _createBlock(_component_TabMultiOption, {
                  key: 2,
                  modelValue: $data.documentType,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.documentType) = $event)),
                  class: "profile-type-from",
                  options: $data.documentTypes
                }, null, 8, ["modelValue", "options"]))
              : _createCommentVNode("", true),
            ($props.isFromCart)
              ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('txt.billing__required_data')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("form", {
              id: "editProfileForm",
              class: "edit-profile-form",
              onSubmit: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["prevent"]))
            }, [
              (!$props.isFromCart)
                ? (_openBlock(), _createBlock(_component_TiptiInputText, {
                    key: 0,
                    modelValue: $data.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.name) = $event)),
                    label: _ctx.$t('general.name'),
                    "is-required": "",
                    placeholder: "Eliza"
                  }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true),
              (!$props.isFromCart)
                ? (_openBlock(), _createBlock(_component_TiptiInputText, {
                    key: 1,
                    modelValue: $data.lastname,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.lastname) = $event)),
                    label: _ctx.$t('general.last-name'),
                    "is-required": "",
                    placeholder: "Mejía"
                  }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_TiptiInputText, {
                modelValue: $data.identityDocument,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.identityDocument) = $event)),
                errors: $data.messageError.identifier,
                label: $data.documentType.labelInput ?? _ctx.$t('general.dni'),
                placeholder: $data.documentType.labelInput ?? _ctx.$t('general.dni'),
                "is-required": ""
              }, null, 8, ["modelValue", "errors", "label", "placeholder"]),
              ($options.showPhoneInput)
                ? (_openBlock(), _createBlock(_component_input_phone_with_label, {
                    key: 2,
                    modelValue: $data.phone,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.phone) = $event)),
                    class: "margin-input",
                    errors: $data.messageError.phone,
                    "phone-already-exists": $data.isPhoneAlreadyRegistered,
                    "show-placeholder-with-out-focus": true,
                    "is-required": ""
                  }, null, 8, ["modelValue", "errors", "phone-already-exists"]))
                : _createCommentVNode("", true),
              (!$props.isFromCart)
                ? (_openBlock(), _createBlock(_component_TiptiInputText, {
                    key: 3,
                    modelValue: $data.email,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.email) = $event)),
                    label: _ctx.$t('general.email'),
                    "is-required": "",
                    type: "email"
                  }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true),
              ($options.needsPassword)
                ? (_openBlock(), _createBlock(_component_TiptiInputPassword, {
                    key: 4,
                    modelValue: $data.password,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.password) = $event)),
                    label: _ctx.$t('general.password'),
                    "is-required": ""
                  }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_TiptiInputText, {
                modelValue: $data.birthday,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.birthday) = $event)),
                label: _ctx.$t('general.birthday'),
                max: $data.maxDate,
                min: $data.minDate,
                "is-required": "",
                pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}",
                type: "date"
              }, null, 8, ["modelValue", "label", "max", "min"]),
              _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('general.genre')), 1),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
                        { value: 'MASCULINO', name: _ctx.$t('general.male') },
                        { value: 'FEMENINO', name: _ctx.$t('general.female') },
                        { value: 'OTHER', name: _ctx.$t('general.other') },
                    ], (option) => {
                  return (_openBlock(), _createBlock(_component_gender_selected, {
                    key: option.value,
                    modelValue: $data.genre,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.genre) = $event)),
                    option: option
                  }, null, 8, ["modelValue", "option"]))
                }), 128))
              ]),
              (!$props.isFromCart)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 5,
                    class: "edit-profile-change-password",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => ($data.showChangePassword = true))
                  }, " ¿" + _toDisplayString(_ctx.$t('cta.change-password')) + "? ", 1))
                : _createCommentVNode("", true),
              (!$props.isFromCart)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 6,
                    class: "edit-profile__deactivate-account",
                    onClick: _cache[11] || (_cache[11] = ($event: any) => ($options.switchShowAlert(true)))
                  }, _toDisplayString(_ctx.$t('cta.deactivate-account')), 1))
                : _createCommentVNode("", true)
            ], 32),
            _createVNode(_component_section_footer, {
              "has-border": false,
              "with-shadow": false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_btn_solid, {
                  "is-disabled": $options.btnIsDisable,
                  "is-loading": $data.isLoading,
                  txt: _ctx.$t('cta.save'),
                  onClick: _cache[13] || (_cache[13] = ($event: any) => ($data.isLoading ? null : $options.saveChanges()))
                }, null, 8, ["is-disabled", "is-loading", "txt"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"])),
    ($data.showAlertDeactivateAC)
      ? (_openBlock(), _createBlock(_component_alert_deactivate_account, {
          key: 2,
          onOnClose: _cache[14] || (_cache[14] = ($event: any) => ($options.switchShowAlert(false)))
        }))
      : _createCommentVNode("", true)
  ], 64))
}