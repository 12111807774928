export interface GlobalSearchRetailerDto {
    id: number;
    name: string;
    logo: string;
}
export class GlobalSearchRetailerModel {
    id: number;
    name: string;
    logo: string;

    constructor(data: GlobalSearchRetailerDto) {
        this.id = data.id;
        this.name = data.name;
        this.logo = data.logo;
    }
}
