<template>
    <base-icon :height="dimensions.height" :icon-color="color" :width="dimensions.width" viewBox="0 0 23 24">
        <path
            :fill="color"
            d="M16.7749 8.71572H14.0464V5.10318C14.0464 4.67373 13.6928 4.32007 13.2633 4.32007C12.8339 4.32007 12.4802 4.67374 12.4802 5.10318V9.52423C12.4802 9.95368 12.8339 10.3073 13.2633 10.3073H16.7749C17.2044 10.3073 17.558 9.95367 17.558 9.52423C17.5832 9.06941 17.2295 8.71572 16.7749 8.71572H16.7749Z"
        />
        <path
            :fill="color"
            d="M22.7875 9.52421C22.7875 4.26947 18.518 0 13.2633 0C8.00861 0 3.73914 4.26952 3.73914 9.52421C3.73914 10.8632 4.01704 12.1263 4.52225 13.2631L0.353923 20.2357C-0.100724 21.0189 -0.126122 21.9535 0.328723 22.7368C0.78337 23.52 1.59188 24 2.47618 24H14.0467C14.9562 24 15.7645 23.52 16.1942 22.7368C16.6488 21.9537 16.6236 21.019 16.169 20.2357L15.3101 18.7957C19.5792 17.8611 22.7875 14.0462 22.7875 9.52408L22.7875 9.52421ZM14.8549 21.9537C14.7791 22.08 14.5518 22.4083 14.0718 22.4083H2.50129C2.02124 22.4083 1.79392 22.0799 1.71818 21.9537C1.6424 21.8273 1.49085 21.4485 1.71818 21.0442L7.50343 11.3684C7.73076 10.9642 8.13501 10.939 8.28655 10.939C8.4381 10.939 8.81716 10.9895 9.06966 11.3684L14.8549 21.0442C15.0826 21.4737 14.9309 21.8273 14.8551 21.9537H14.8549ZM14.4508 17.3558L10.4087 10.56C9.95401 9.80206 9.17071 9.34741 8.28659 9.34741C7.40229 9.34741 6.61918 9.80206 6.16453 10.56L5.58335 11.4949C5.40642 10.8633 5.33064 10.2065 5.33064 9.52435C5.33082 5.12851 8.89279 1.56645 13.2633 1.56645C17.6337 1.56645 21.1959 5.1286 21.1959 9.49908C21.1959 13.4907 18.2653 16.7749 14.4506 17.3559L14.4508 17.3558Z"
        />
        <path
            :fill="color"
            d="M9.14547 20.4126C9.14547 20.887 8.76096 21.2715 8.28657 21.2715C7.81218 21.2715 7.42749 20.887 7.42749 20.4126C7.42749 19.9382 7.81218 19.5537 8.28657 19.5537C8.76096 19.5537 9.14547 19.9382 9.14547 20.4126Z"
        />
        <path
            :fill="color"
            d="M8.18542 13.6925C7.65481 13.7431 7.25073 14.2231 7.30112 14.7536L7.6296 18.2903C7.6548 18.5934 7.9075 18.8461 8.2106 18.8713C8.56428 18.8965 8.8674 18.644 8.91797 18.2903L9.24645 14.7536V14.5766C9.19605 14.0462 8.71601 13.6419 8.18539 13.6925L8.18542 13.6925Z"
        />
    </base-icon>
</template>

<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { reactive } from 'vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#D43939',
    },
});

const dimensions = reactive({
    width: 23 * props.size,
    height: 24 * props.size,
});
</script>
