import { RemoteLocaleDto } from '@/models/internationalization/RemoteLocale';

export interface AffiliateVerificationDto {
    affiliate: boolean;
    state_documents: boolean;
    tc_url: string;
    accept_tc_alert: AffiliationAlertBaseDto;
    output_external_alert: AffiliationAlertBaseDto;
}

export interface AffiliationCheckDto {
    acceptance_tyc_alert: AffiliationAlertWithTermsDto;
    user_new_alert: boolean;
    franchise_program: boolean;
    user_registered_alert: AffiliationAlertBenefitDto;
    user_renewal_alert: AffiliationAlertBenefitDto;
    user_affiliate_alert: AffiliationAlertBenefitDto;
    user_reject_alert: AffiliationAlertBaseDto;
    output_external_alert: AffiliationExternalURLDto;
    message: null;
}

export interface AffiliationAlertBaseDto {
    title: RemoteLocaleDto;
    description: RemoteLocaleDto;
    image?: string;
    button: RemoteLocaleDto;
}

export interface AffiliationAlertBenefitDto extends AffiliationAlertBaseDto {
    benefit: RemoteLocaleDto[];
}

export interface AffiliationAlertVerificationDto extends AffiliationAlertBenefitDto {
    verification: RemoteLocaleDto;
}

export interface AffiliationAlertWithTermsDto extends AffiliationAlertBaseDto {
    terms: ExternalDocumentsDto;
}

export interface AffiliationExternalURLDto extends AffiliationAlertBaseDto {
    tc_url: string;
}

export interface ExternalDocumentsDto {
    CVA: string;
    PPR: string;
}

export interface AffiliationDocuments {
    typeDocument: keyof typeof TypeDocument;
    contentDocument: string;
    string_document: string;
}

export enum TypeDocument {
    PPR = 'PPR',
    NAK = 'NAK',
    CVA = 'CVA',
    TCU = 'TCU',
}
