import { vModelDynamic as _vModelDynamic, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b29e7e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_icon_search = _resolveComponent("icon-search")!
  const _component_icon_search_close = _resolveComponent("icon-search-close")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["search", {
            'search--min-radius': $props.minRadius,
            'search--autocomplete': $props.isAutocomplete,
            'search--secondary': $props.isAlternativeStyle,
        }])
  }, [
    _withDirectives(_createElementVNode("input", {
      ref: "generalSearch",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.query) = $event)),
      placeholder: $props.placeholder ?? '',
      type: $props.searchType,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-blur', $event))),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-focus', $event))),
      onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-change-value', $data.query))),
      onKeyup: [
        _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-keyup', $event))),
        _cache[5] || (_cache[5] = _withKeys(($event: any) => ($options.onSearch($data.query)), ["enter"])),
        _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.$emit('on-keyup-esc', $event)), ["esc"]))
      ]
    }, null, 40, _hoisted_1), [
      [
        _vModelDynamic,
        $data.query,
        void 0,
        { trim: true }
      ]
    ]),
    ($props.isLoading)
      ? (_openBlock(), _createBlock(_component_base_loading, {
          key: 0,
          "is-margin-auto": false,
          heigth: ".4em",
          width: ".4em"
        }))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["cursor-pointer", { 'icon-section': $props.hasIconAccentBackground }]),
          onClick: _cache[7] || (_cache[7] = ($event: any) => (!$data.query?.length ? $options.onSearch($data.query) : $options.onClean()))
        }, [
          (!$data.query?.length)
            ? (_openBlock(), _createBlock(_component_icon_search, {
                key: 0,
                class: "cursor-pointer",
                color: $props.hasIconAccentBackground ? '#fff' : '#606060',
                size: 0.1
              }, null, 8, ["color"]))
            : (_openBlock(), _createBlock(_component_icon_search_close, {
                key: 1,
                class: "cursor-pointer",
                color: $props.hasIconAccentBackground ? '#fff' : '#606060',
                size: 0.9
              }, null, 8, ["color"]))
        ], 2))
  ], 2))
}