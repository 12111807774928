import { PaymentMethodModel, toPaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';

export class MaxiProgramInstance {
    program_id: number;
    debit_reward_equivalence: number;
    payment_methods: Array<PaymentMethodModel>;
}
export class MaxiProgramModel implements MaxiProgramInstance {
    payment_methods: Array<PaymentMethodModel>;
    debit_reward_equivalence: number;
    program_id: number;

    constructor(data: MaxiProgramInstance) {
        this.program_id = data.program_id;
        this.debit_reward_equivalence = data.debit_reward_equivalence;
        if (!data.payment_methods?.length) this.payment_methods = [];
        else this.payment_methods = (data.payment_methods as []).map(card => toPaymentMethodModel(card));
    }

    static fromJson(json: object): MaxiProgramModel {
        if (!json || !Object.keys(json).length) return;
        return new MaxiProgramModel(json as MaxiProgramInstance);
    }
}
