import { ref } from 'vue';

const height = ref('0px');
const headerRef = ref<HTMLElement>();
export const useHeader = () => {
    const updateHeight = () => {
        const heightElement = headerRef.value?.offsetHeight ?? 0;
        height.value = `${heightElement}px`;
    };
    return {
        updateHeight,
        height,
        headerRef,
    };
};
