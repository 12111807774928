<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
});
const width = computed<number>(() => 34 * props.size);
const height = computed<number>(() => 34 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0 34 34">
        <path
            :fill="color"
            d="M30.8119 7.3562C30.0814 7.56492 29.5248 7.53013 29.5248 7.53013L29.5596 7.391C30.2205 6.86922 31.925 5.19951 32.1337 4.43422C32.2381 4.08637 32.0989 3.98202 32.0989 3.98202L29.9423 4.88643L28.1334 5.6865C26.8811 4.36464 25.0027 3.52979 22.8808 3.52979C19.124 3.52979 16.0628 6.1735 16.0628 9.47814C16.0628 10.0695 16.1672 11.1826 16.3411 11.7044C16.3411 11.5305 16.3411 11.8783 16.3411 11.7044C13.9061 11.6348 10.8102 10.6608 8.06218 9.23463C2.60086 6.48657 2.2878 4.57335 2.2878 4.57335C1.41817 5.47778 1.00074 8.50413 1.87037 10.8348C2.42694 12.3305 4.34013 13.6872 4.34013 13.6872C4.34013 13.6872 3.4705 13.6872 2.60086 13.4089C1.69644 13.0958 1.38337 12.8175 1.38337 12.8175C0.861585 14.1046 1.90515 16.4353 3.74878 18.105C4.82713 19.079 6.77512 19.6703 6.77512 19.6703L3.714 19.7399C3.54007 22.7315 9.97538 24.5055 9.97538 24.5055C8.09697 25.9665 5.90548 26.8361 3.57485 26.8361C2.35736 26.8361 1.17466 26.6622 0.0615234 26.3144C3.15743 28.8537 7.12298 30.6278 11.4364 30.3843C22.9156 29.7929 29.3857 19.9138 29.664 10.2434V10.2782C29.664 10.2782 30.1858 9.99992 31.3337 8.852C32.4816 7.70407 33.2121 6.34743 33.2121 6.34743C33.2121 6.34743 31.5424 7.14749 30.8119 7.3562Z"
        />
    </BaseIcon>
</template>
