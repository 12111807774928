import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f1b1224"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_btn = _resolveComponent("base-btn")!

  return (_openBlock(), _createBlock(_component_base_btn, {
    isLoading: $props.isLoading,
    class: _normalizeClass({ 'btn-solid--center': $options.isIconTextSpaceDistribution }),
    bgColor: $props.bgColor,
    isDisabled: $props.isDisabled,
    fullWidth: $props.fullWidth,
    loadingSize: $props.loadingSize,
    style: _normalizeStyle($options.stylesBtn),
    padding: $props.padding
  }, {
    default: _withCtx(() => [
      ($props.withIcon)
        ? _renderSlot(_ctx.$slots, "icon", { key: 0 }, undefined, true)
        : _createCommentVNode("", true),
      _createElementVNode("p", {
        class: "btn-solid",
        style: _normalizeStyle($options.stylesBtnTxt)
      }, _toDisplayString($props.txt), 5),
      ($options.isIconTextSpaceDistribution)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["isLoading", "class", "bgColor", "isDisabled", "fullWidth", "loadingSize", "style", "padding"]))
}