import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.promotions'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 128 150",
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M112.658 0H13.9241C6.32911 0 0 6.32911 0 13.9241V150L30.3797 119.62H112.658C120.253 119.62 126.582 113.291 126.582 105.696V13.9241C127.215 6.32911 120.886 0 112.658 0ZM89.2405 94.9367L64.557 77.2152L39.8734 94.9367L49.3671 66.4557L25.3165 48.7342H55.6962L65.1899 20.2532L74.6835 48.7342H105.063L80.3797 66.4557L89.2405 94.9367Z",
        fill: $props.color
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "iconColor"]))
}