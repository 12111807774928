<template>
    <section class="base-background" :class="{ 'content-centered': center }">
        <slot />
    </section>
</template>
<script lang="ts">
export default {
    name: 'BaseBackground',
    props: {
        backgroundColor: {
            type: String,
            default: 'rgba(0, 0, 0, 0.5)',
        },
        center: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        document.body.style.overflowY = 'hidden';
    },
    unmounted() {
        document.body.style.overflowY = 'auto';
    },
};
</script>
<style lang="scss" scoped>
.base-background {
    background-color: v-bind(backgroundColor);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    transition: all 1s;
    overscroll-behavior: none;
    touch-action: none;
}
.content-centered {
    display: grid;
    place-items: center;
}
</style>
