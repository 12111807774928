<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed } from 'vue';
import { isMobileBrowser } from '@/utils/utils';
import ShareResource from '@/views/newsPress/components/ShareResource.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import IconBackArrow from '@/components/icons/IconBackArrow.vue';
import type { PressReleasesDetail } from '@/models/external/PressReleases';

const router = useRouter();
const $store = useStore();

const isMobile = computed(() => isMobileBrowser());

const props = defineProps({
    news: {
        type: Object as PropType<PressReleasesDetail>,
        required: true,
    },
    newsTitle: {
        type: String,
        required: true,
    },
});
</script>
<template>
    <header class="header-detail-news header-detail-news__shadow">
        <aside class="header-detail-news__content" aria-labelledby="">
            <IconBackArrow class="cursor-pointer" @click="router.back" />
            <h3 class="header-detail-news__title">{{ news?.title ?? newsTitle }}</h3>
        </aside>
        <i>
            <ShareResource v-if="news?.deep_link" :link="news?.deep_link" :resource-name="news.title" />
        </i>
    </header>
    <h3 class="header-detail-news__title-mobile">{{ news.title }}</h3>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.header-detail-news {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: content-box;
    width: 100%;
    padding: 5px;
    position: relative;

    &__content {
        display: flex;
        align-items: center;
        gap: style.$margin-md;
    }

    &__title {
        @include style.subtitle1;
        color: style.$dark-grey;
        font-size: 30px;
        font-weight: 600;
        @include style.phone {
            display: none;
        }
    }

    &__title-mobile {
        display: none;
        @include style.phone {
            display: unset;
            @include style.subtitle1;
            color: style.$dark-grey;
            font-size: 30px;
            font-weight: 600;
        }
    }

    &__shadow {
        box-shadow: 0 2.5px style.$dark-light;
        @include style.phone {
            box-shadow: unset;
        }
    }
}
</style>
