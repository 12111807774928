import { TiptiAlertModel } from '@/models/alerts/AlertOpenRetailer';
import { AlertNames } from '@/enums/alertNames';

export default {
    alerts(state, alerts: Array<TiptiAlertModel>) {
        state.alerts = alerts;
    },
    removeAlert(state, alertName: AlertNames) {
        state.alerts = state.alerts.filter((alert: TiptiAlertModel) => alert.name !== alertName);
    },
};
