<template>
    <base-icon icon-name="google" :width="width" :height="height" viewBox="0 0 19 19" :iconColor="color">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.62 9.71589C18.62 9.04225 18.5595 8.39452 18.4473 7.77271H9.5V11.4475H14.6127C14.3925 12.635 13.7232 13.6411 12.717 14.3147V16.6984H15.7873C17.5836 15.0445 18.62 12.6091 18.62 9.71589Z"
            fill="#4285F4"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.49998 19.0001C12.065 19.0001 14.2154 18.1494 15.7873 16.6985L12.717 14.3148C11.8663 14.8848 10.7782 15.2216 9.49998 15.2216C7.02566 15.2216 4.93135 13.5505 4.1843 11.3051H1.01044V13.7664C2.57362 16.8712 5.78635 19.0001 9.49998 19.0001Z"
            fill="#34A853"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.18432 11.305C3.99432 10.735 3.88636 10.1261 3.88636 9.50001C3.88636 8.87387 3.99432 8.26501 4.18432 7.69501V5.23364H1.01045C0.367045 6.51614 0 7.96705 0 9.50001C0 11.033 0.367045 12.4839 1.01045 13.7664L4.18432 11.305Z"
            fill="#FBBC05"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.49998 3.77841C10.8948 3.77841 12.147 4.25773 13.1316 5.19909L15.8563 2.47432C14.2111 0.941364 12.0607 0 9.49998 0C5.78635 0 2.57362 2.12886 1.01044 5.23364L4.1843 7.695C4.93135 5.44955 7.02566 3.77841 9.49998 3.77841Z"
            fill="#EA4335"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-google-small',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 19 * this.size;
        },
        height(): number {
            return 19 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
