<template>
    <teleport v-if="isActive" to="body">
        <div class="modal-mask" :class="{ 'no-background': noBackground }">
            <div class="modal-wrapper">
                <base-card
                    class="alert"
                    :class="{
                        'alert--success': isSuccess,
                        'alert--failure': isFailure,
                        'cursor-pointer': cursorPointer,
                    }"
                    :padding="padding"
                    border-radius="1em 0 0 1em"
                >
                    <img
                        v-if="profileImage.length"
                        :alt="altImage"
                        :src="profileImage"
                        @click="onSnackBarTap"
                        @error="displayDefaultImg"
                    />
                    <div class="alert-body" :style="{ textAlign: textAlign }" @click="onSnackBarTap">
                        <h2
                            class="alert-body__title"
                            :style="{ color: titleColor, fontSize: titleSize, textAlign: textAlign }"
                        >
                            {{ title }}
                        </h2>
                        <p class="alert-body__msg">{{ body }}</p>
                    </div>
                    <icon-circular-close
                        class="alert__close cursor-pointer"
                        :size="0.8"
                        @click="closeModal"
                    ></icon-circular-close>
                </base-card>
            </div>
        </div>
    </teleport>
</template>

<script>
import BaseCard from '@/components/cards/BaseCard.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';

export default {
    name: 'SnackBar',
    components: {
        BaseCard,
        IconCircularClose,
    },
    props: {
        padding: {
            type: String,
            default: '1rem',
        },
        title: {
            type: String,
            default: '',
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        isSuccess: {
            type: Boolean,
            default: false,
        },
        isFailure: {
            type: Boolean,
            default: false,
        },
        body: {
            type: String,
            default: '',
        },
        noBackground: {
            type: Boolean,
            default: false,
        },
        profileImage: {
            type: String,
            default: '',
        },
        altImage: {
            type: String,
            default: '',
        },
        titleColor: {
            type: String,
            default: '#FF9012',
        },
        titleSize: {
            type: String,
            default: '1.5rem',
        },
        textAlign: {
            type: String,
            default: 'center',
        },
        cursorPointer: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-snackbar-close', 'on-snackbar-tap'],
    watch: {
        isActive() {
            this.setTimeOut();
        },
    },
    methods: {
        displayDefaultImg(event) {
            event.target.src = require('@/assets/svg/ic_avatar_tipti.svg');
        },
        // TODO: FIND A BETTER WAY TO IMPROVE THIS PART
        setTimeOut() {
            if (this.isActive) {
                window.clearTimeout(5000);
                window.setTimeout(() => {
                    this.closeModal();
                }, Number(5000));
            }
        },
        onSnackBarTap() {
            this.$emit('on-snackbar-tap');
            this.closeModal();
        },
        closeModal() {
            this.$emit('on-snackbar-close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import './snack-bar.scss';
</style>
