<template>
    <h1 class="title-text-promotions">Promociones y descuentos Tipti</h1>
    <h2 class="title-text-subtitle-promotions">Son expertos gastrónomos, que compran los</h2>
    <h2 class="title-text-subtitle-promotions">productos que tú y tu familia necesitan</h2>
    <div class="container-button">
        <btn-home-page
            class="button-promotions"
            :text="textPrommotion"
            bg-color="#FFF"
            color="#FF9012"
            @click="switchToLogin"
        ></btn-home-page>
    </div>
    <auth v-if="showLogin" :is-register="showRegister" @on-close="showLogin = false"></auth>
</template>

<script lang="ts">
import BtnHomePage from '@/views/home/components/BtnHomePage/Btn-home-page.vue';
import Auth from '@/views/auth/Auth.vue';

export default {
    name: 'PromotionTextSlogan',
    components: { Auth, BtnHomePage },
    data() {
        return {
            textPrommotion: 'Ahorra ahora',
            showLogin: false,
            showRegister: false,
        };
    },
    methods: {
        switchToLogin() {
            this.$store.dispatch('segment/openSignIn');
            this.showLogin = true;
            this.showRegister = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'promotion-text-slogan.scss';
</style>
