<template>
    <base-modal
        :auto-size="false"
        :has-background="hasBackground"
        :header-text-color="accentHeader ? '#ff9012' : null"
        :is-full-modal="true"
        :title="title"
        @on-close="$emit('close')"
    >
        <PdfFrame class="pdf__content" :src="urlFile" />
    </base-modal>
</template>

<script lang="ts">
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import { mapGetters } from 'vuex';
import PdfFrame from '@/modules/core/components/pdf/PdfFrame.vue';

export default {
    name: 'AlertPdf',
    components: {
        PdfFrame,
        BaseModal,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        hasBackground: {
            type: Boolean,
            default: true,
        },
        accentHeader: {
            type: Boolean,
            default: true,
        },
        url: {
            type: String,
        },
    },
    emits: ['close'],
    computed: {
        ...mapGetters({ user: 'user/user', countrySelected: 'external/countrySelected' }),
        termsAndConditions(): string {
            const language = this.user?.defaultLanguage ?? this.countrySelected?.language ?? 'ES';
            return this.countrySelected?.termsAndConditions[language];
        },
        urlFile(): string {
            return this.url ?? this.termsAndConditions;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.pdf__content {
    height: 75vh;
    @include tablet {
        height: 90vh;
    }
}
</style>
