<template>
    <section class="yappy-payment">
        <div class="yappy-payment__container">
            <img class="yappy-payment__image" alt="yappi icon" src="@/assets/svg/ic_bell.svg" />
            <p class="yappy-payment__description">
                {{ $t('txt.payment-yappy__description') }}
            </p>
        </div>
    </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';

export default {
    name: 'yappy',

    computed: {
        ...mapGetters({
            totalToPay: 'payments/totalToPay',
        }),
    },

    mounted() {
        this.$store.commit('payments/cleanPaymentMethods');
        this.$store.commit('payments/isYappyPayment', true);
        this.$store.commit(
            'payments/paymentsToShow',
            new PaymentToShowModel({
                name: 'payment-yappy',
                amount: this.totalToPay,
            }),
        );
    },
};
</script>

<style lang="scss" scoped>
@import './yappy-payment.scss';
</style>
