<template>
    <div class="retailer-form-backdrop-white">
        <h3 class="retailer-form-title">{{ $t("txt['retailer__form-we-want-your']") }}</h3>
        <form class="retailer-form" @submit.prevent="onSubmit">
            <p class="retailer-form-alert" v-if="showAlert">{{ $t("txt['retailer__form-fill-requerid-fields']") }}</p>
            <input
                type="text"
                :placeholder="`${$t('general.retail-name')} *`"
                v-model.trim="retailerName"
                :class="{ 'retailer-form--missing-field': showAlert && !retailerName?.length }"
            />
            <input
                type="text"
                :placeholder="`${$t('general.retail-type')} *`"
                :class="{ 'retailer-form--missing-field': showAlert && !retailerType?.length }"
                v-model.trim="retailerType"
            />
            <input
                type="text"
                :placeholder="`${$t('general.enterprise-type')} *`"
                :class="{ 'retailer-form--missing-field': showAlert && !retailerBusinessType?.length }"
                v-model.trim="retailerBusinessType"
            />
            <input
                type="text"
                :placeholder="`${$t('general.contact-name')} *`"
                :class="{ 'retailer-form--missing-field': showAlert && !retailerContactName?.length }"
                v-model.trim="retailerContactName"
                autocomplete="name"
            />
            <input
                type="text"
                :placeholder="`${$t('general.city')} *`"
                :class="{ 'retailer-form--missing-field': showAlert && !retailerCity?.length }"
                v-model.trim="retailerCity"
                autocomplete="shipping locality"
            />
            <input
                type="tel"
                :placeholder="`${$t('general.phone')} *`"
                :class="{ 'retailer-form--missing-field': showAlert && !retailerPhoneNumber?.length }"
                v-model.trim="retailerPhoneNumber"
                autocomplete="tel"
            />
            <input
                type="email"
                :placeholder="`${$t('general.email')} *`"
                :class="{ 'retailer-form--missing-field': showAlert && !retailerEmail?.length }"
                v-model.trim="retailerEmail"
                autocomplete="email"
            />
            <textarea :placeholder="$t('general.message')" rows="4" v-model.trim="retailerMessage" />

            <div class="container-msg-from">
                <p>
                    {{ $t("txt['retailer__form-procedure-aceept-that']") }}
                </p>
                <p>{{ $t("txt['retailer__form-please-is-important']") }}</p>
            </div>
            <div class="retailer-form__btn">
                <button-solid :txt="$t('general.send')" />
            </div>
        </form>
    </div>
    <snack-bar
        :isSuccess="isSuccess"
        :isFailure="!isSuccess"
        :body="snackBarMessage"
        :isActive="showSnackBar"
        @on-snackbar-close="showSnackBar = false"
    ></snack-bar>
</template>

<script>
import ButtonSolid from '@/components/buttons/BtnSolid.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';

export default {
    name: 'retailer-form',
    components: {
        ButtonSolid,
        SnackBar,
    },
    data() {
        return {
            retailerName: '',
            retailerType: '',
            retailerBusinessType: '',
            retailerContactName: '',
            retailerCity: '',
            retailerPhoneNumber: '',
            retailerEmail: '',
            retailerMessage: '',
            showAlert: false,
            showSnackBar: false,
            snackBarMessage: '',
            isSuccess: false,
        };
    },
    methods: {
        async onSubmit() {
            this.showAlert = false;
            if (
                !this.retailerName.length ||
                !this.retailerType.length ||
                !this.retailerBusinessType.length ||
                !this.retailerContactName.length ||
                !this.retailerCity.length ||
                !this.retailerPhoneNumber.length ||
                !this.retailerEmail.length
            ) {
                this.showAlert = true;
                return;
            }
            try {
                const payload = {
                    retailerName: this.retailerName,
                    retailerType: this.retailerType,
                    retailerBusinessType: this.retailerBusinessType,
                    retailerContactName: this.retailerContactName,
                    retailerCity: this.retailerCity,
                    retailerPhoneNumber: this.retailerPhoneNumber,
                    retailerEmail: this.retailerEmail,
                    retailerMessage: this.retailerMessage,
                };

                await this.$store.dispatch('external/sendMailRetailer', payload);
                this.retailerName = '';
                this.retailerType = '';
                this.retailerBusinessType = '';
                this.retailerContactName = '';
                this.retailerCity = '';
                this.retailerPhoneNumber = '';
                this.retailerEmail = '';
                this.retailerMessage = '';
                this.snackBarMessage = this.$t("txt['retailer__form-success']");
                this.isSuccess = true;
            } catch (_) {
                this.snackBarMessage = this.$t("txt['retailer__form-failure']");
                this.isSuccess = false;
            } finally {
                this.showSnackBar = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import './retailer-form.scss';
</style>
