import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a484439"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tipti-prime__product-owner" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_tipti = _resolveComponent("icon-tipti")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!

  return (_openBlock(), _createBlock(_component_base_card_flat, {
    class: "tipti-prime__product",
    borderColor: '#a2a2a2'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_icon_tipti, {
        size: 0.5,
        color: "#FF9012"
      }),
      _createElementVNode("p", _hoisted_1, _toDisplayString(`${$options.currentPrimeDeal.name}`), 1),
      ($options.currentPrimeDeal?.logo)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "tipti-prime__product-logo",
            src: $options.currentPrimeDeal.logo,
            alt: ""
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}