<script lang="ts" setup>
import { WalletInstitution } from '@/views/wallet/InstitutionalWallets';
import { PropType } from 'vue';
import TransitionFade from '@/modules/core/components/transition/TransitionFade.vue';
import { useUser } from '@/composables/useUser';

defineProps({
    institution: {
        type: Object as PropType<WalletInstitution>,
        required: true,
    },
    style: {
        type: Object,
        default: undefined,
    },
    showAllData: {
        type: Boolean,
        default: true,
    },
});
const { profile } = useUser();
</script>

<template>
    <article
        class="wallet-tipti-card institutional-card"
        :style="{
            'background-image': `url(${institution?.image})`,
            ...style,
        }"
    >
        <TransitionFade>
            <section v-if="showAllData" class="institutional-card-expanded">
                <header class="institutional-card-expanded__amount">
                    <p
                        class="institutional-card__amount-text institutional-card__amount-text--label institutional-card__amount-text--label--expanded"
                        :style="{
                            color: institution.color ?? '#fffffF',
                        }"
                    >
                        {{ $t("general['available-balance']") }}
                    </p>
                    <p
                        v-currency="institution?.amount_allocated_available"
                        class="institutional-card__amount-text institutional-card__amount-text--expanded"
                        :style="{
                            color: institution.color ?? '#fffffF',
                        }"
                    />
                </header>
                <footer class="institutional-card-expanded__footer">
                    <p
                        class="institutional-card-expanded__footer__client"
                        :style="{
                            color: institution.color ?? '#fffffF',
                        }"
                    >
                        {{ profile?.name }} {{ profile?.lastname }}
                    </p>
                    <p
                        class="institutional-card-expanded__footer__number"
                        :style="{
                            color: institution.color ?? '#fffffF',
                        }"
                    >
                        {{ institution?.card_number ?? '' }}
                    </p>
                </footer>
            </section>
            <section v-else class="institutional-card__amount">
                <p
                    class="institutional-card__amount-text institutional-card__amount-text--label"
                    :style="{
                        color: institution.color ?? '#fffffF',
                    }"
                >
                    {{ $t('txt.gift-card-available-balance') }}
                </p>
                <p
                    v-currency="institution?.amount_allocated_available"
                    class="institutional-card__amount-text"
                    :style="{
                        color: institution.color ?? '#fffffF',
                    }"
                ></p>
            </section>
        </TransitionFade>
    </article>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/media-query' as mq;
@use '@/assets/scss/type-system' as ts;
@use '@/assets/scss/padding' as pd;
@use '@/assets/scss/radius' as br;
@use '@/assets/scss/colors' as cl;

.wallet-tipti-card {
    height: 13rem;
    width: 26rem;
    box-sizing: border-box;
    border-radius: br.$radius-lg;

    @include mq.tablet {
        width: 20rem;
        height: 10rem;
    }
}

.institutional-card {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    padding: pd.$padding-md;
    box-sizing: border-box;

    &__amount {
        align-self: flex-start;
        margin-top: auto;
    }

    &__amount-text {
        @include ts.headline1;

        font-size: 2.5rem;
        text-align: left;

        &--expanded {
            text-align: right;
        }

        &--label {
            @include ts.overline;
            text-align: left;

            &--expanded {
                text-align: right;
            }
        }
    }
}

.institutional-card-expanded {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__amount {
        text-align: right !important;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-institutions: flex-start;
        white-space: nowrap;

        &__client {
            @include ts.body1;
            font-weight: 600;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
        }

        &__number {
            @include ts.body2;
        }
    }
}
</style>
