import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e2594eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-categories-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_search_category = _resolveComponent("search-category")!
  const _component_categories_grid = _resolveComponent("categories-grid")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    class: "list-categories",
    padding: 0
  }, {
    default: _withCtx(() => [
      _createVNode(_component_section_header, {
        title: _ctx.$t('cta.categories'),
        "has-back": "",
        onOnBack: $options.onBack
      }, null, 8, ["title", "onOnBack"]),
      _createElementVNode("aside", _hoisted_1, [
        _createVNode(_component_search_category, {
          class: "list-categories-body__search",
          "origin-section": 'MY_LISTS',
          onRedirectOnSearch: $options.redirectFromSearch
        }, null, 8, ["onRedirectOnSearch"]),
        _createVNode(_component_categories_grid)
      ])
    ]),
    _: 1
  }))
}