import { computed } from 'vue';
import { useCart } from '@/composables/useCart';
import { DeferredModel } from '@/models/paymentMethods/DeferredModel';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import InstitutionalPaymentModel from '@/models/checkout/InstitutionalPaymentModel';
import { CashWirePaymentModel } from '@/models/checkout/CashWirePaymentModel';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { BaseConfigurationModel } from '@/models/baseConfiguration/BaseConfigurationModel';
import { OrderResumenModel } from '@/models/order/OrderResumen';
import { GiftCardsAlertCheckoutModel } from '@/models/giftCardsAlert/giftCardsAlertCheckoutModel';
import { useGiftCards } from '@/composables/useGiftCards';
import { DefaultPaymentModel } from '@/models/paymentMethods/DefaultPaymentModel';
import { useOrder } from '@/composables/useOrder';
import { useApp } from '@/composables/useApp';

export const usePayments = () => {
    const { getInfoAlertGiftCard, getting, activeModal } = useGiftCards();
    const { $store, $t } = useApp();
    const { cart } = useCart();
    const { tiptiCard } = useTiptiCard();
    const { orderRejected } = useOrder();
    const paymentsToShow = computed<PaymentToShowModel[]>(() => $store.getters['payments/paymentsToShow']);
    const paymentsToShowGiftCard = computed<GiftCardsAlertCheckoutModel[]>(
        () => $store.getters['giftCardAlert/paymentsToShowGiftCard'],
    );
    const orderSelected = computed<OrderResumenModel>(() => $store.getters['orders/orderDetailSelected']);

    const cleanPaymentMethods = () => $store.commit('payments/cleanPaymentMethods');

    const isMixInstitutionalPayment = computed<boolean>({
        get: () => $store.getters['payments/isMixInstitutionalPayment'],
        set: (value) => $store.commit('payments/isMixInstitutionalPayment', value),
    });

    const isMixPaymentTiptiCard = computed<boolean>({
        get: () => $store.getters['payments/isMixPaymentTiptiCard'],
        set: (value) => $store.commit('payments/isMixPaymentTiptiCard', value),
    });

    const secondPayment = computed<number>({
        get: () => $store.getters['payments/secondPayment'],
        set: (value) => $store.commit('payments/secondPayment', value),
    });

    const totalToPay = computed<number>({
        get: () => $store.getters['payments/totalToPay'],
        set: (value) => $store.commit('payments/totalToPay', value),
    });

    const isTiptiCardPayment = computed<boolean>({
        get: () => $store.getters['payments/isTiptiCardPayment'],
        set: (value) => $store.commit('payments/isTiptiCardPayment', value),
    });

    const defaultPayment = computed<DefaultPaymentModel>(() => $store.getters['payments/defaultPaymentSelected']);

    const institutionSelected = computed<InstitutionalPaymentModel>({
        get: () => $store.getters['payments/institutionPayment'],
        set: (value) => $store.commit('payments/institutionPayment', value),
    });
    const paymentIdSelected = computed<number>(() => $store.getters['payments/paymentIdSelected']);
    const cashWirePayment = computed<CashWirePaymentModel>(() => $store.getters['payments/cashWirePayment']);
    const deferredOptions = computed<Array<DeferredModel>>(() => $store.getters['payments/deferredOptions']);
    const deferred = computed<DeferredModel>({
        get: () => $store.getters['payments/deferred'],
        set: (value) => {
            $store.commit('payments/deferred', value);
        },
    });

    const deferredSelection = computed<string>({
        get: () => deferred.value?.code,
        set: (value) => {
            deferred.value = deferredOptions.value.find((opt) => opt?.code === value);
        },
    });

    const baseConfiguration = computed<BaseConfigurationModel>(() => $store.getters['user/baseConfiguration']);

    const difference = (value: number, secondValue: number): number => +(value - secondValue)?.toFixed(2);

    const institutionalPaymentToShow = computed<number>(() => {
        if (paymentsToShow.value.find((payment) => payment.name === 'payment-tipti-card')) {
            return difference(+cart.value?.totalDetails?.totalCost?.toFixed(2), tiptiCard.value?.availableAmount);
        }
        return +(institutionSelected.value?.amount ?? 0).toFixed(2);
    });

    const differenceToPay = computed((): number => {
        return isMixPaymentTiptiCard.value
            ? difference(+cart.value?.totalDetails?.totalCost?.toFixed(2), +tiptiCard.value?.availableAmount)
            : difference(+cart.value?.totalDetails?.totalCost?.toFixed(2), +institutionSelected.value?.amount);
    });

    const showMissingPayment = computed<boolean>(() => {
        return (isMixPaymentTiptiCard.value || isMixInstitutionalPayment.value) && paymentsToShow.value?.length === 1;
    });

    /// * Deferred section * ///

    const isDeferAvailable = computed<boolean>(() => {
        if (
            paymentsToShow.value?.some((payment) => payment.name === 'payment-cards') &&
            (orderSelected.value || orderRejected.value)
        ) {
            return (
                (orderRejected.value?.final_cost ?? orderSelected.value?.totalToPayInResentPayment) >=
                baseConfiguration.value?.deferredMinimumAmount
            );
        }

        /// ** UPDATE [totalPaymentWithCreditCard] in case tipti card or institutional payment covers some value;
        const firstPayment: number =
            isMixPaymentTiptiCard.value || isMixInstitutionalPayment.value
                ? difference(+differenceToPay.value, +cart.value?.totalDetails?.totalCost.toFixed(2))
                : +cart.value?.totalDetails?.totalCost.toFixed(2);
        const totalPaymentWithCreditCard: number =
            firstPayment < +cart.value?.totalDetails?.totalCost.toFixed(2)
                ? difference(+cart.value?.totalDetails?.totalCost.toFixed(2), firstPayment)
                : +cart.value?.totalDetails?.totalCost.toFixed(2);
        return paymentIdSelected.value && totalPaymentWithCreditCard >= baseConfiguration.value?.deferredMinimumAmount;
    });

    const fetchDeferredOptions = (): void => {
        $store.dispatch('payments/deferredOptions', orderRejected.value);
    };

    const checkDeferAvailability = (): void => {
        $store.commit('payments/deferredOptions', []);
        $store.commit('payments/deferred', null);
        if (
            !isDeferAvailable.value ||
            paymentsToShowGiftCard.value?.length ||
            !paymentsToShow.value?.length ||
            paymentsToShow.value?.some((payment) => payment.name === 'payment-cards' && payment.idExpired) ||
            paymentsToShow.value?.every((payment) => payment.name != 'payment-cards')
        )
            return;
        fetchDeferredOptions();
    };

    const hasPaymentMethodsExpired = computed<boolean>(() => paymentsToShow.value?.some((method) => method?.idExpired));

    const textAlertDeferredAndMixedPayment = computed<string>(() =>
        isMixPaymentTiptiCard.value
            ? $t('txt.mixed-payment-messages-not-deferred__title')
            : $t('alerts.deferred__title'),
    );
    const textAlertDeferredAndMixedPaymentAlert = computed<string>(() =>
        isMixPaymentTiptiCard.value ? $t('txt.mixed-payment-messages-not__deferred') : $t('alerts.deferred__title'),
    );
    const resetPaymentMethodsAndGiftCards = async (): Promise<boolean> => {
        getting.value = false;
        activeModal.value = false;
        $store.commit('giftCardAlert/paymentsToShowGiftCard', []);
        $store.commit('payments/isMixPaymentTiptiCard', false);
        $store.commit('payments/isMixInstitutionalPayment', false);
        $store.commit('payments/cleanPaymentMethods');
        $store.commit('giftCardAlert/idgGiftCardsToUse', []);
        $store.commit('payments/deferredOptions', []);
        isTiptiCardPayment.value = false;
        return getInfoAlertGiftCard();
    };

    const fetCreditCard = () => $store.dispatch('payments/creditCardList', true);
    const fetchDefaultPayment = () => $store.dispatch('payments/defaultPayment');
    return {
        hasPaymentMethodsExpired,
        isDeferAvailable,
        institutionalPaymentToShow,
        paymentsToShow,
        paymentsToShowGiftCard,
        tiptiCard,
        isMixPaymentTiptiCard,
        textAlertDeferredAndMixedPayment,
        textAlertDeferredAndMixedPaymentAlert,
        cashWirePayment,
        differenceToPay,
        showMissingPayment,
        cart,
        deferredOptions,
        deferred,
        paymentIdSelected,
        resetPaymentMethodsAndGiftCards,
        checkDeferAvailability,
        difference,
        fetCreditCard,
        cleanPaymentMethods,
        isMixInstitutionalPayment,
        totalToPay,
        secondPayment,
        defaultPayment,
        isTiptiCardPayment,
        institutionSelected,
        fetchDefaultPayment,
        deferredSelection,
        getSurplusPayments: () => $store.dispatch('payments/fetchSurplusPayments'),
        getDefaultPaymentTypes: () => $store.dispatch('payments/defaultPaymentsType'),
    };
};
