class OnlineBankServiceModel {
    accountId: number;
    amount: number;
    constructor(accountId: number, amount: number) {
        this.accountId = accountId;
        this.amount = amount;
    }
}

export { OnlineBankServiceModel };
