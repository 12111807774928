import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_benefits_validate_card = _resolveComponent("benefits-validate-card")!
  const _component_generate_validation = _resolveComponent("generate-validation")!
  const _component_verify_validation = _resolveComponent("verify-validation")!
  const _component_validation_success = _resolveComponent("validation-success")!
  const _component_validation_error = _resolveComponent("validation-error")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($data.showBenefits)
      ? (_openBlock(), _createBlock(_component_benefits_validate_card, {
          key: 0,
          onOnClose: _cache[0] || (_cache[0] = ($event: any) => ($data.showBenefits = false))
        }))
      : _createCommentVNode("", true),
    ($options.initFlow)
      ? (_openBlock(), _createBlock(_component_generate_validation, {
          key: 1,
          "payment-method-id": $props.paymentMethodId,
          "force-validate": $props.forceValidate,
          onOnShowBenefits: _cache[1] || (_cache[1] = ($event: any) => ($data.showBenefits = true)),
          onOnSuccess: _cache[2] || (_cache[2] = ($event: any) => ($data.showVerifyValidation = true)),
          onOnError: $options.generateError,
          onOnClose: $options.onClose
        }, null, 8, ["payment-method-id", "force-validate", "onOnError", "onOnClose"]))
      : _createCommentVNode("", true),
    ($data.showVerifyValidation)
      ? (_openBlock(), _createBlock(_component_verify_validation, {
          key: 2,
          "payment-method-id": $props.paymentMethodId,
          onOnSuccess: $options.onValidationSuccessful,
          onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-close'))),
          onOnError: $options.onVerifyError,
          "attempts-pending": $data.remainingAttempts
        }, null, 8, ["payment-method-id", "onOnSuccess", "onOnError", "attempts-pending"]))
      : _createCommentVNode("", true),
    ($data.validationSuccessful)
      ? (_openBlock(), _createBlock(_component_validation_success, {
          key: 3,
          onOnClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-close')))
        }))
      : _createCommentVNode("", true),
    ($data.showError)
      ? (_openBlock(), _createBlock(_component_validation_error, {
          key: 4,
          onOnAccept: $options.onErrorClose,
          onOnClose: $options.onErrorClose,
          "is-last-attempt": $data.isLastAttempt,
          "is-request-error": $data.isRequestError,
          attempts: $data.remainingAttempts
        }, null, 8, ["onOnAccept", "onOnClose", "is-last-attempt", "is-request-error", "attempts"]))
      : _createCommentVNode("", true)
  ], 64))
}