<template>
    <div class="story-page">
        <div class="story-sidebar">
            <section class="story-sidebar__header">
                <icon-circular-close
                    alt="close"
                    :size="1"
                    color="#FFF"
                    class="cursor-pointer"
                    @click="$emit('on-close')"
                ></icon-circular-close>
                <p>{{ $t("txt['stories']") }}</p>
            </section>
            <section v-if="featuredStory" class="story-sidebar__item" @click="goToStory(featuredStory)">
                <div
                    class="story-miniature"
                    @click="goToStory(featuredStory)"
                    :class="{ viewed: featuredStory.viewed }"
                >
                    <img :src="featuredStory.iconStory" />
                    <div class="prime-icon">
                        <icon-prime-star :iconName="$t('icon.featured')" :size="0.8" color="#FF9012"></icon-prime-star>
                    </div>
                </div>
                <vertical-divider height="74px" width="2px" v-if="featuredStory"></vertical-divider>
                <div class="story-sidebar__information">
                    <p>{{ featuredStory.title }}</p>
                    <p class="overline">{{ $t("txt['sponsor']") }}</p>
                </div>
            </section>
            <section class="story-sidebar__scroll" ref="sidebarStories">
                <div
                    class="story-sidebar__item"
                    v-for="(story, index) in stories"
                    :key="index"
                    @click="goToStory(story, index)"
                    :class="{ 'selected-item': selectedStory == story }"
                >
                    <div class="story-miniature" :class="{ viewed: story.viewed }">
                        <img :src="story.iconStory" alt="" @error="displayDefaultImg" />
                    </div>
                    <div class="story-sidebar__information no-featured">
                        <p>{{ story.title }}</p>
                    </div>
                </div>
            </section>
        </div>
        <story-container v-if="selectedStory" @on-close="$emit('on-close')"></story-container>
    </div>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex';
import { StoriesResultModel } from '@/models/stories/StoriesResultModel';

import VerticalDivider from '@/components/dividers/VerticalDivider.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import IconPrimeStar from '@/components/icons/IconPrimeStar.vue';
import StoryContainer from '../storyContainer/StoryContainer.vue';
export default {
    name: 'story-page',
    components: {
        VerticalDivider,
        IconCircularClose,
        IconPrimeStar,
        StoryContainer,
    },
    computed: {
        ...mapGetters({
            featuredStory: 'stories/featuredStory',
            stories: 'stories/stories',
            selectedStory: 'stories/selectedStory',
        }),
    },
    emits: ['on-close'],
    methods: {
        ...mapActions({ fetchStories: 'stories/stories' }),
        displayDefaultImg(event) {
            event.target.src = require('@/assets/svg/tipti_logo/ic_tipti_orange.svg');
        },
        goToStory(selectedStory: StoriesResultModel, index: number): void {
            this.$store.commit('stories/selectedStory', selectedStory);
            this.$store.commit('stories/selectedIndex', index);
        },
        onEscapeListener() {
            document.onkeydown = async (e): Promise<void> => {
                if (e.key == 'Escape') {
                    this.$emit('on-close');
                }
            };
        },
        scroll() {
            const slider = this.$refs.sidebarStories;
            let isDown = false;
            let startY;
            let scrollTop;
            slider.onmousedown = async (e): Promise<void> => {
                isDown = true;
                slider.classList.add('active');
                startY = e.pageY - slider.offsetTop;
                scrollTop = slider.scrollTop;
            };
            slider.onmouseleave = async (): Promise<void> => {
                isDown = false;
                slider.classList.remove('active');
            };
            slider.onmouseup = async (): Promise<void> => {
                isDown = false;
                slider.classList.remove('active');
            };
            slider.onmousemove = async (e): Promise<void> => {
                if (!isDown) return;
                e.preventDefault();
                const y = e.pageY - slider.offsetTop;
                const walk = (y - startY) * 3;
                slider.scrollTop = scrollTop - walk;
            };
        },
    },
    mounted() {
        this.scroll();
        this.onEscapeListener();
    },
};
</script>
<style lang="scss" scoped>
@import '../../the-stories-carousel.scss';
@import './story-page.scss';
</style>
