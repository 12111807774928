import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31b25d74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icons-section" }
const _hoisted_2 = { class: "icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_chains = _resolveComponent("icon-chains")!
  const _component_btn_circular = _resolveComponent("btn-circular")!
  const _component_icon_twitter_two = _resolveComponent("icon-twitter-two")!
  const _component_icon_whats_app_two = _resolveComponent("icon-whats-app-two")!
  const _component_icon_FB = _resolveComponent("icon-FB")!
  const _component_icon_gmail = _resolveComponent("icon-gmail")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_btn_circular, {
          id: "chains",
          ref: "chains",
          class: "btn-icons",
          onClick: $options.copyCode
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_chains, { size: 0.7 })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_btn_circular, {
          class: "btn-icons",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.share($options.twitter)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_twitter_two, { size: 0.7 })
          ]),
          _: 1
        }),
        _createVNode(_component_btn_circular, {
          class: "btn-icons",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.share($options.whatsapp)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_whats_app_two, { size: 0.7 })
          ]),
          _: 1
        }),
        _createVNode(_component_btn_circular, {
          class: "btn-icons",
          onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.share($options.facebook)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_FB, { size: 0.7 })
          ]),
          _: 1
        }),
        _createVNode(_component_btn_circular, {
          class: "btn-icons",
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.shareMail($options.mail)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_gmail, { size: 0.8 })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_snack_bar, {
      body: $data.bodySnackBar,
      "is-active": $data.showSnackBar,
      "is-success": true,
      "no-background": true,
      onOnSnackbarClose: _cache[4] || (_cache[4] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active"])
  ], 64))
}