<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
});
const width = computed<number>(() => 13 * props.size);
const height = computed<number>(() => 15 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0 13 15">
        <path
            :fill="color"
            d="M8.62885 14.9982H1.63295C1.20004 14.9972 0.785169 14.8247 0.479229 14.5184C0.17329 14.2121 0.00122605 13.797 0.000732422 13.3641V4.13994C0.00122726 3.70671 0.173548 3.29136 0.479891 2.98502C0.786234 2.67868 1.20158 2.50635 1.63482 2.50586H6.22297C6.37162 2.50589 6.51419 2.56493 6.61934 2.67002L10.0969 6.15095C10.1491 6.20303 10.1904 6.26488 10.2187 6.33295C10.2469 6.40103 10.2614 6.474 10.2614 6.54769V13.3641C10.2609 13.7971 10.0888 14.2122 9.78281 14.5185C9.47679 14.8248 9.06182 14.9973 8.62885 14.9982ZM1.63295 3.62766C1.49744 3.62825 1.36767 3.68247 1.27202 3.77847C1.17638 3.87447 1.12263 4.00443 1.12253 4.13994V13.3641C1.12273 13.4996 1.17651 13.6295 1.27213 13.7255C1.36776 13.8215 1.49747 13.8757 1.63295 13.8764H8.62885C8.76466 13.8762 8.89484 13.8222 8.99087 13.7261C9.0869 13.6301 9.14094 13.4999 9.14114 13.3641V6.77991L5.98889 3.62766H1.63295Z"
        />
        <path
            :fill="color"
            d="M11.1337 12.4923H9.70157C9.55281 12.4923 9.41015 12.4332 9.30496 12.3281C9.19977 12.2229 9.14067 12.0802 9.14067 11.9314C9.14067 11.7827 9.19977 11.64 9.30496 11.5348C9.41015 11.4296 9.55281 11.3705 9.70157 11.3705H11.1337C11.2695 11.3703 11.3997 11.3163 11.4958 11.2203C11.5918 11.1242 11.6458 10.9941 11.646 10.8582V1.63408C11.6458 1.49828 11.5918 1.36809 11.4958 1.27206C11.3997 1.17603 11.2695 1.122 11.1337 1.1218H4.13783C4.00235 1.12249 3.87264 1.17674 3.77701 1.27272C3.68139 1.3687 3.62761 1.4986 3.62741 1.63408V3.06625C3.62741 3.21501 3.56832 3.35767 3.46313 3.46286C3.35794 3.56805 3.21527 3.62714 3.06651 3.62714C2.91775 3.62714 2.77509 3.56805 2.6699 3.46286C2.56471 3.35767 2.50562 3.21501 2.50562 3.06625V1.63408C2.50601 1.20082 2.6783 0.785414 2.98466 0.47905C3.29103 0.172685 3.70644 0.000396037 4.1397 0H11.1337C11.567 0.000396037 11.9824 0.172685 12.2888 0.47905C12.5951 0.785414 12.7674 1.20082 12.7678 1.63408V10.8582C12.7674 11.2915 12.5951 11.7069 12.2888 12.0133C11.9824 12.3196 11.567 12.4919 11.1337 12.4923Z"
        />
        <path
            :fill="color"
            d="M9.70175 7.10822H7.294C6.86076 7.10772 6.44541 6.9354 6.13907 6.62906C5.83273 6.32272 5.66041 5.90737 5.65991 5.47414V3.06676C5.65991 2.918 5.71901 2.77533 5.8242 2.67014C5.92938 2.56495 6.07205 2.50586 6.22081 2.50586C6.36957 2.50586 6.51224 2.56495 6.61743 2.67014C6.72261 2.77533 6.78171 2.918 6.78171 3.06676V5.47451C6.78181 5.61035 6.83581 5.74059 6.93186 5.83664C7.02791 5.93269 7.15816 5.9867 7.294 5.9868H9.70175C9.85051 5.9868 9.99317 6.04589 10.0984 6.15108C10.2036 6.25627 10.2626 6.39894 10.2626 6.54769C10.2626 6.69645 10.2036 6.83912 10.0984 6.94431C9.99317 7.0495 9.85051 7.10859 9.70175 7.10859V7.10822Z"
        />
    </BaseIcon>
</template>
