import { isEmptyObject } from '@/utils/utils';
class AppSectionModel {
    id: number;
    name: string;
    mobileSection: boolean;
    webSection: boolean;
    enabled: boolean;

    constructor(id: number, name: string, mobileSection: boolean, webSection: boolean, enabled: boolean) {
        this.id = id;
        this.name = name;
        this.mobileSection = mobileSection;
        this.webSection = webSection;
        this.enabled = enabled;
    }
}

const toAppSectionModel = (data: object): AppSectionModel => {
    return !data || isEmptyObject(data)
        ? null
        : new AppSectionModel(
              data['id'],
              data['name'] ?? '',
              data['mobile_section'] ?? false,
              data['web_section'] ?? false,
              data['enabled'] ?? false,
          );
};

export { AppSectionModel, toAppSectionModel };
