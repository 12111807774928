import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23bdd30c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question" }
const _hoisted_2 = { class: "question__options" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "answer-item" }
const _hoisted_5 = { class: "answer-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", {
      class: "question__title",
      style: _normalizeStyle({ color: $options.textColor, backgroundColor: $options.bgColor })
    }, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.selectedStory?.interactiveElement.text.toUpperCase()), 1)
    ], 4),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedStory?.interactiveElement?.elements, (element, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "question__options-answer",
          style: _normalizeStyle({
                    color: !$options.hasAnswered
                        ? element.textColor ?? '#FFF'
                        : element.isCorrect || $data.selectedIndex === index
                        ? '#FFF'
                        : element.textColor,
                    backgroundColor: !$options.hasAnswered
                        ? element.textBackgroundColor ?? '#606060'
                        : $data.selectedIndex === index
                        ? $data.isCorrectAnswer
                            ? '#1CA757'
                            : '#f01728'
                        : element.isCorrect
                        ? '#1CA75780'
                        : element.textBackgroundColor,
                }),
          onClick: ($event: any) => ($options.hasAnswered ? null : $options.onSelectOption(element.id, index))
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString($data.items[index]), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(element.text), 1)
        ], 12, _hoisted_3))
      }), 128))
    ])
  ]))
}