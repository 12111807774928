<template>
    <base-icon :icon-name="$t('icon.no_profile_picture')" :width="width" :height="height" viewBox="0 0 100 100">
        <g>
            <path
                fill="#E63F51"
                d="M70.279,49.806c0,10.977-8.895,24.248-19.865,24.248c-10.97,0-19.863-13.271-19.863-24.248
		c0-10.969,8.894-17.984,19.863-17.984C61.385,31.822,70.279,38.837,70.279,49.806z"
            />
            <path
                fill="#333333"
                d="M50.414,75.597c-5.59,0-11.014-3.059-15.271-8.611c-3.842-5.013-6.137-11.436-6.137-17.179
		c0-11.315,9.004-19.528,21.408-19.528c12.405,0,21.408,8.213,21.408,19.528c0,5.744-2.294,12.167-6.137,17.179
		C61.428,72.538,56.006,75.597,50.414,75.597z M50.414,33.366c-10.615,0-18.32,6.914-18.32,16.44c0,5.088,2.057,10.808,5.5,15.3
		c3.659,4.774,8.213,7.403,12.82,7.403c4.608,0,9.162-2.629,12.82-7.403c3.445-4.492,5.5-10.212,5.5-15.3
		C68.734,40.28,61.029,33.366,50.414,33.366z"
            />
        </g>
        <g>
            <circle fill="#333333" cx="45.895" cy="55.128" r="1.639" />
            <circle fill="#333333" cx="55.632" cy="55.128" r="1.638" />
        </g>
        <circle fill="#C63449" cx="43.485" cy="60.75" r="1.448" />
        <circle fill="#C63449" cx="56.913" cy="61.31" r="1.448" />
        <g>
            <g>
                <path
                    fill="#333333"
                    d="M50.754,61.854c-1.264,0-2.045-0.805-2.322-2.393c-0.064-0.373,0.184-0.728,0.557-0.793
			c0.373-0.064,0.729,0.185,0.793,0.557c0.221,1.26,0.674,1.26,0.973,1.26c0.3,0,0.754,0,0.974-1.26
			c0.065-0.372,0.421-0.621,0.793-0.557c0.372,0.065,0.622,0.42,0.557,0.793C52.799,61.049,52.018,61.854,50.754,61.854z"
                />
            </g>
        </g>
        <path
            fill="#A3C125"
            d="M49.969,26.74c-2.652-0.734-9.271-2.845-12.475-6.14c-0.374-0.369-0.715-0.77-1.018-1.198
	c0.869,0.162,1.75,0.267,2.635,0.315l1.617,0.12c5.315,0.479,6.903,0.584,8.312,1.977C49.758,22.532,50.117,24.404,49.969,26.74z"
        />
        <path
            fill="#CDDB28"
            d="M50.625,25.961c-2.594-0.915-9.051-3.479-12.02-6.988c-0.348-0.394-0.66-0.817-0.934-1.266
	c0.857,0.222,1.729,0.388,2.607,0.496l1.605,0.231c5.27,0.846,6.846,1.061,8.154,2.547C50.705,21.749,50.936,23.641,50.625,25.961z"
        />
        <g>
            <g>
                <path
                    fill="#333333"
                    d="M52.385,30.492c1.105-1.976,2.698-3.636,4.627-4.823c0.811-0.498,1.676-0.904,2.576-1.212
			c0.674-0.268,1.059-0.981,0.912-1.693v-0.09c-0.006-0.027-0.015-0.054-0.023-0.082c-0.261-0.785-1.107-1.211-1.893-0.951
			c-2.052,0.701-3.934,1.823-5.526,3.294c0.069-1.958-0.587-3.872-1.842-5.376c-2.261-2.216-4.747-2.411-10.198-2.831l-1.646-0.165
			c-0.84-0.047-1.676-0.152-2.502-0.314l-0.479-0.075c-0.988-0.188-2,0.18-2.636,0.958c-0.579,0.78-0.688,1.813-0.284,2.696
			c0.471,1.01,1.133,1.919,1.946,2.681c3.744,3.878,10.901,6.155,14.092,7.023c-0.09,0.187-0.136,0.392-0.135,0.599L52.385,30.492z
			 M50.033,26.613c-2.65-0.734-9.27-2.845-12.475-6.14c-0.373-0.369-0.715-0.77-1.018-1.197c0.871,0.162,1.75,0.266,2.635,0.314
			l1.617,0.12c5.316,0.479,6.904,0.584,8.312,1.977C49.823,22.405,50.182,24.277,50.033,26.613z"
                />
            </g>
        </g>
        <ellipse fill="#F6F5F3" cx="51.753" cy="82.788" rx="14.5" ry="2.943" />
        <g>
            <path
                fill="#C63449"
                d="M47.842,68.853c-1.789,0-7.611,0-9.075-2.355c3.17,3.519,7.247,5.891,11.696,5.891
		c10.094,0,18.279-12.212,18.279-22.313c0-0.084-0.006-0.166-0.007-0.25C65.749,59.716,59.654,68.853,47.842,68.853z"
            />
        </g>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-avatar',
    props: {
        size: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        width(): number {
            return 50 * this.size;
        },
        height(): number {
            return 50 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
