import Axios, { AxiosRequestConfig } from 'axios';
import { getTokenFromOrigin } from '@/modules/core/infraestructure/countryTokens';

const baseURL =
    process.env.VUE_APP_API_ENV === 'DEV'
        ? process.env.VUE_APP_API_DEV_URL
        : process.env.VUE_APP_API_ENV === 'STG'
          ? process.env.VUE_APP_API_STG_URL
          : process.env.VUE_APP_API_PROD_URL;

const authRequest = (token: string, baseURL: string): AxiosRequestConfig => {
    if (!token) {
        const { origin } = window.location;
        token = getTokenFromOrigin(origin);
    }
    return {
        baseURL,
        headers: {
            Authorization: token,
        },
    };
};

export default {
    axios(state, __, ___, rootGetters) {
        const customization = authRequest(rootGetters['user/token'], baseURL);
        const instance = Axios.create(customization);

        instance.interceptors.request.use(
            (config) => {
                if (config.baseURL !== process.env.VUE_APP_API_PROD_URL) return config;
                if (
                    ['localhost', 'develop', 'staging'].every(
                        (path) => !RegExp(path).exec(window.location.hostname)?.length,
                    )
                )
                    return config;
                if (config.method === 'get' || !config.ensureProductionRequest) return config;
                throw new Error('Operation canceled by production validation');
            },
            (error) => {
                return Promise.reject(error);
            },
        );

        instance.interceptors.response.use(
            (config) => {
                return config;
            },
            (error) => {
                state.askForMaintenance = true;
                return Promise.reject(error);
            },
        );

        return instance;
    },
    connectionStatus(state) {
        return state.connectionStatus;
    },
    geoLocalizationState(state) {
        return state.geoLocalizationState;
    },
    askForMaintenance(state) {
        return state.askForMaintenance;
    },
};
