const tokenEC =
    'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo0OCwiZW1haWwiOiJpbnZpdGFkb0B0aXB0aS5tYXJrZXQiLCJ0eXBlIjoxLCJ1c2VybmFtZSI6Imludml0YWRvQHRpcHRpLm1hcmtldCIsImV4cCI6ODgwODkwMDQzMjR9.GZJXL3HTvI6GNDsPTyvpimmAkWn2ZELeSrJGnBKbP-o';
const tokenPA =
    'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozNSwiZW1haWwiOiJpbnZpdGFkbzNAdGlwdGkubWFya2V0IiwidHlwZSI6MSwidXNlcm5hbWUiOiJpbnZpdGFkbzNAdGlwdGkubWFya2V0IiwiZXhwIjo4ODA5NTIyNTQxM30.91vORVHgGnZq2UGZ_eO5Nyaw1RYPcO7oXYiqHmUFT_s';

const countryTokenMap = {
    EC: tokenEC,
    PA: tokenPA,
};

const getCountryAbbreviationFromUrl = (url: string): string => {
    const domainRegex = /\/\/(www\.)?([a-zA-Z0-9.-]+)\/?/;
    const match = url.match(domainRegex);
    if (match) {
        const domain = match[2];
        if (domain.includes('tipti.market')) return 'EC';
        if (domain.includes('tipti.com.pa')) return 'PA';
    }
};

export const getTokenFromOrigin = (origin: string) => {
    const countryAbbreviation = getCountryAbbreviationFromUrl(origin);

    return Object.prototype.hasOwnProperty.call(countryTokenMap, countryAbbreviation)
        ? countryTokenMap[countryAbbreviation]
        : tokenEC;
};
