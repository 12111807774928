<template>
    <section class="product-no-found">
        <img src="@/assets/img/shopper-cartoon.png" alt="shopper" />
        <p class="search_legend">{{ $t('txt.search_result_empty') }}</p>
        <div class="options">
            <base-card class="option-card" padding="2rem">
                <h3 class="option-card__title option-card--orange">{{ $t('txt.product-no-found__we-will-find') }}</h3>
                <p class="option-card__paragraph">
                    {{ $t('txt.product-no-found__select') }}
                </p>
                <router-link :to="{ name: appRoutesMap.home }">
                    <btn-solid :txt="$t('cta.continue-shopping')" />
                </router-link>
            </base-card>
            <base-card class="option-card" padding="2rem">
                <p class="option-card__paragraph">
                    {{ $t('txt.product-no-found__report-a-product') }}
                </p>
                <input
                    v-model="product"
                    class="option-card__missing-product"
                    :placeholder="$t('general.product-name')"
                />
                <btn-solid
                    :is-disabled="!product?.length"
                    :is-loading="isLoading"
                    :txt="$t('cta.report-product')"
                    @click="reportProduct"
                />
            </base-card>
        </div>
    </section>
</template>
<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { logger } from '@/utils/logger';
import { useUser } from '@/composables/useUser';
import { useStore } from 'vuex';
import { inject, ref } from 'vue';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { notifierKey } from '@/modules/notification/useNotifier';
import { useI18n } from 'vue-i18n';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'ProductNotFound',
});

const $store = useStore();
const { t: $t } = useI18n();
const isLoading = ref(false);
const product = ref('');
const { isAuth, requireAuth } = useUser();
const notifier = inject(notifierKey);

const reportProduct = async (): Promise<void> => {
    if (isLoading.value) return;
    if (!isAuth) {
        requireAuth.value = true;
        return;
    }
    try {
        isLoading.value = true;
        await axiosInstance().post('client_profile/feedback/', {
            message: product.value,
            feedback_type: 'MISSING_PRODUCT',
            context: product.value,
        });
        product.value = '';
        if (!notifier) return;
        notifier({
            type: 'SUCCESS',
            body: $t("general['your-message-sent']"),
        });
    } catch (err) {
        logger('REPORT_PRODUCT', err);
        notifier({
            type: 'ERROR',
            body: $t("general['your-message-not-sent']"),
        });
    } finally {
        isLoading.value = false;
    }
};
</script>
<style lang="scss" scoped>
@import './product-not-found.scss';
</style>
