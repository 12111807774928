const applyRegexMask = (phoneNumber: string, counter = 3): string => {
    if (!phoneNumber.length) return '';
    phoneNumber = phoneNumber?.replaceAll(' ', '');
    const regex = new RegExp(/.{3}/g);
    if (phoneNumber.length <= counter) return phoneNumber;
    const mod = phoneNumber.length % counter;
    const initialValues = phoneNumber.substring(0, mod);
    const valueFormatted = phoneNumber.substring(mod, phoneNumber.length).matchAll(regex);
    const match = Array.from(valueFormatted)
        .map(group => group['0'])
        .join(' ');
    if (!initialValues.length) return match;
    return `${initialValues} ${match}`;
};

const removeCountryCode = (phoneNumber: string): string => {
    return applyRegexMask(phoneNumber?.trim()?.replace(/(^[+0-9]*(?=\s))/, '') ?? '')
        ?.replace('+', '')
        ?.trim();
};

export { applyRegexMask, removeCountryCode };
