import { ProductModel } from '@/models/product/ProductModel';

const updateNoteByBoughtMode = (note: string, isBoughtByWeigth?: boolean): string => {
    if (!isBoughtByWeigth) return note?.trim()?.replaceAll('este producto se compro por unidades', '');

    return note?.trim()?.length
        ? `este producto se compro por unidades
            ${note.replaceAll('este producto se compro por unidades', '')}`
        : 'este producto se compro por unidades';
};

const cleanNote = (note: string): string => {
    return note?.toLowerCase()?.trim()?.replaceAll('este producto se compro por unidades', '') ?? '';
};

const isBoughtByWeigth = (product?: ProductModel): boolean => {
    if (!product) return false;
    return (
        product?.note?.toLowerCase()?.trim()?.includes('este producto se compro por unidades') ||
        (!product.unitStock && product.bulk)
    );
};

export { updateNoteByBoughtMode, cleanNote, isBoughtByWeigth };
