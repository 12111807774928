<template>
    <base-icon :icon-name="$t('icon.location')" :width="width" :height="height" viewBox="0 0 18 23" :iconColor="color">
        <path
            d="M8.58937 0.908203C4.2196 0.908203 0.578125 4.47945 0.578125 8.76494C0.578125 14.2409 7.86108 22.3357 8.10384 22.5738C8.34661 22.8118 8.83214 22.8118 9.0749 22.5738C9.56043 22.3357 16.8434 14.2409 16.8434 8.76494C16.8434 4.47945 13.2019 0.908203 8.58937 0.908203ZM8.58937 12.8124C6.40449 12.8124 4.70513 11.1458 4.70513 8.76494C4.70513 6.38411 6.64725 4.71753 8.83214 4.71753C11.017 4.95562 12.7164 6.6222 12.7164 8.76494C12.7164 11.1458 11.017 12.8124 8.58937 12.8124Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-location',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 22 * this.size;
        },
        height(): number {
            return 23 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
