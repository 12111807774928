import { AddressModel } from '@/models/address/AddressModel';
import { toResponsePurchaseModel } from '@/models/checkout/ResponsePurchaseModel';
import DeliveryAddressModel from '@/models/checkout/DeliveryAddressModel';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';

export default {
    async createOrder(
        { rootGetters, getters, commit },
        payload: { totalToPay: number; position: string; billing_info_id: string },
    ): Promise<void> {
        try {
            ///* Use [version=v2] for service fee feature
            const url = 'v2/client/create-new-order?version=v2';
            const formData = new FormData();
            formData.append('origin', 'app_web');
            formData.append('position', payload.position?.length ? payload.position : 'DATA_NO_ALLOWED_BY_USER');
            formData.append('billing_info_id', payload.billing_info_id);
            if (getters['timeDelivery'].length)
                formData.append(
                    'time_delivery_information',
                    JSON.stringify(
                        getters['timeDelivery']?.map((time: TimeDeliveryModel) => {
                            return {
                                retailer_id: time.retailerId,
                                delivery_first_date: `${time.date}T${time.deliveryFirstDate}:00-05:00`,
                                delivery_second_date: `${time.date}T${time.deliverySecondDate}:00-05:00`,
                                shipping_cost: time.shippingCost,
                                original_shipping_cost: time.originalShippingCost,
                                express: time.express,
                                delivery_type: time.deliveryType,
                            };
                        }),
                    ),
                );
            if (getters['stockItemTimeDelivery'].length)
                formData.append(
                    'item_time_delivery_information',
                    JSON.stringify(
                        getters['stockItemTimeDelivery']?.map((time: TimeDeliveryModel) => {
                            return {
                                retailer_id: time.retailerId,
                                stock_item_id: time.stockItemId,
                                delivery_first_date: `${time.date}T${time.deliveryFirstDate}:00-05:00`,
                                shipping_cost: time.shippingCost,
                                original_shipping_cost: time.originalShippingCost,
                            };
                        }),
                    ),
                );
            const _location: AddressModel = rootGetters['user/userLocation'].id
                ? rootGetters['user/userLocation']
                : rootGetters['location/addressDefault'];
            const timeDelivery = getters['timeDelivery']?.map((time: TimeDeliveryModel) =>
                new DeliveryAddressModel(
                    +time.retailerId,
                    _location?.id,
                    _location?.isPickUpMode ?? false,
                    _location?.pickUpCenter?.id,
                ).toJson(),
            );
            const stockItemTimeDelivery = getters['stockItemTimeDelivery']?.map((time: TimeDeliveryModel) =>
                new DeliveryAddressModel(
                    +time.retailerId,
                    _location?.id,
                    _location?.isPickUpMode ?? false,
                    _location?.pickUpCenter?.id,
                ).toJson(),
            );
            const allDeliveryInformation = [...timeDelivery, ...stockItemTimeDelivery];
            formData.append('delivery_address_information', JSON.stringify(allDeliveryInformation));
            if (getters['isAutomaticReplacements'])
                formData.append('accept_all_replacements', getters['isAutomaticReplacements']);
            if (getters['instructions']) formData.append('client_instructions', getters['instructions']);
            if (getters['phone']) formData.append('client_instructions_phone', getters['phone']);
            if (getters['email']) formData.append('client_instructions_email', getters['email']);
            if (getters['isElder']) formData.append('generated_by_older_adult', getters['isElder']);

            /// * Tipti Card payment
            if (
                !rootGetters['payments/institutionPayment']?.unlimitedBuys &&
                rootGetters['payments/isTiptiCardPayment'] &&
                +rootGetters['tiptiCard/tiptiCard']?.availableAmount > 0
            )
                formData.append(
                    'tipti_card_amount',
                    payload.totalToPay >= +rootGetters['tiptiCard/tiptiCard'].availableAmount
                        ? rootGetters['tiptiCard/tiptiCard'].availableAmount
                        : rootGetters['payments/isMixInstitutionalPayment'] &&
                            rootGetters['payments/institutionPayment']?.amount
                          ? Math.abs(+payload.totalToPay - +rootGetters['payments/institutionPayment']?.amount).toFixed(
                                2,
                            )
                          : payload.totalToPay.toFixed(2),
                );

            /// * Cash payments
            if (rootGetters['payments/cashWirePayment']?.cash) {
                formData.append('cash_amount', rootGetters['payments/cashWirePayment'].cash?.cashAmount);
                formData.append('cash_to_pay', rootGetters['payments/cashWirePayment'].cash?.cashToPay);
            }
            const idGifts = rootGetters['giftCardAlert/idgGiftCardsToUse'];
            formData.append('gift_card_payments_selected', `[${idGifts}]`);
            /// * Wire transfer payments
            if (rootGetters['payments/cashWirePayment']?.wireTransfer) {
                formData.append('account_id', rootGetters['payments/cashWirePayment'].wireTransfer?.account);
                formData.append('photo', rootGetters['payments/cashWirePayment'].wireTransfer?.photo);
                formData.append('type', rootGetters['payments/cashWirePayment'].wireTransfer?.type);
            }
            /// * Credit/ Debit cards and Payphone
            if (rootGetters['payments/paymentIdSelected'])
                formData.append('payment_id', rootGetters['payments/paymentIdSelected']);

            /// * Deferred of Credit/ Debit cards (optional)
            if (Boolean(rootGetters['payments/deferred']?.code) && rootGetters['payments/paymentIdSelected'])
                formData.append('deferred_information', JSON.stringify(rootGetters['payments/deferred']));

            /// * Institutional payments
            if (rootGetters['payments/institutionPayment']?.id)
                formData.append('institution_id', rootGetters['payments/institutionPayment'].id);
            if (
                rootGetters['payments/institutionPayment']?.id &&
                !rootGetters['payments/institutionPayment']?.unlimitedBuys
            ) {
                if (
                    rootGetters['payments/isTiptiCardPayment'] &&
                    +rootGetters['tiptiCard/tiptiCard']?.availableAmount > 0
                ) {
                    formData.append(
                        'institutional_amount',
                        Math.abs(payload.totalToPay - rootGetters['tiptiCard/tiptiCard']?.availableAmount).toFixed(2),
                    );
                } else formData.append('institutional_amount', rootGetters['payments/institutionPayment']?.amount);
            }
            /// * Online Bank Services payments
            if (rootGetters['payments/onlineBankServicePayment']?.accountId)
                formData.append('account_id', rootGetters['payments/onlineBankServicePayment']?.accountId);
            if (rootGetters['payments/onlineBankServicePayment']?.amount)
                formData.append('amount_online_banking', rootGetters['payments/onlineBankServicePayment']?.amount);
            /// * Nequi payments
            if (rootGetters['payments/nequiPayment']?.selectedPhoneNumber)
                formData.append('nequi_number', rootGetters['payments/nequiPayment'].selectedPhoneNumber);
            /// * Yappyy payments
            if (rootGetters['payments/isYappyPayment'])
                formData.append('yappy_payment', rootGetters['payments/isYappyPayment']);
            /// *  prescription load  on items
            if (rootGetters['cart/listFilesPrescriptions'].length)
                rootGetters['cart/listFilesPrescriptions']?.forEach((item) => {
                    item.listFiles?.forEach((file: File) => {
                        formData.append('img_med_pres', file);
                    });
                });

            const { data } = await rootGetters['network/axios'].post(url, formData, {
                ensureProductionRequest: true,
            });
            const creationResponse = toResponsePurchaseModel(data);
            if (rootGetters['payments/isYappyPayment']) window.open(data.data.link_to_pay_yappy);
            _location.isPickUpMode = false;
            commit('user/userLocation', _location, { root: true });
            commit(
                'retailers/removeRetailerAlertLimitShowed',
                getters['timeDelivery']?.map((time: TimeDeliveryModel) => time.retailerId),
                { root: true },
            );
            commit('extraItems/xSellingProducts', [], { root: true });
            commit('responsePurchase', creationResponse);
            commit('payments/isMixInstitutionalPayment', false, { root: true });
            commit('payments/isMixPaymentTiptiCard', false, { root: true });
            commit('payments/cleanPaymentMethods', null, { root: true });
            commit('cart/listFilesPrescriptions', null);
            commit('cart/itemsPrescription', []);
        } catch (err) {
            throw err.response;
        }
    },
};
