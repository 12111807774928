import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63b154dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "attribute" }
const _hoisted_2 = { class: "attribute__name" }
const _hoisted_3 = { class: "attribute__name--value" }
const _hoisted_4 = { class: "attribute__options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheProductAttribute = _resolveComponent("TheProductAttribute")!
  const _component_carousel_attributes = _resolveComponent("carousel-attributes")!
  const _component_product_attributes = _resolveComponent("product-attributes", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, [
        _createTextVNode(_toDisplayString($options.nameTypeAttribute) + ": ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString($options.nameAttributeSelected), 1)
      ]),
      _createVNode(_component_carousel_attributes, {
        "has-margin": false,
        "show-arrows": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.attributeValues, (attribute) => {
              return (_openBlock(), _createBlock(_component_TheProductAttribute, {
                key: attribute.id,
                attribute: attribute,
                onUpdateAttribute: $options.setMainAttribute
              }, null, 8, ["attribute", "onUpdateAttribute"]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    ($data.attributeOptions)
      ? (_openBlock(), _createBlock(_component_product_attributes, {
          key: 0,
          "attribute-order": 2,
          "attribute-values": $data.attributeOptions
        }, null, 8, ["attribute-values"]))
      : _createCommentVNode("", true)
  ], 64))
}