export interface TiptiCardHistoryExchangeInterface {
    readonly id: number;
    readonly date: Date;
    readonly amount_credited_to_tipticard: string;
    readonly taken_reward: string;
}

export class TiptiCardHistoryExchangeModel implements TiptiCardHistoryExchangeInterface {
    amount_credited_to_tipticard: string;
    date: Date;
    id: number;
    taken_reward: string;

    constructor(data: TiptiCardHistoryExchangeInterface) {
        this.id = data.id;
        this.date = data.date;
        this.taken_reward = (+data.taken_reward ?? 0).toFixed(2);
        this.amount_credited_to_tipticard = (+data.amount_credited_to_tipticard ?? 0).toFixed(2);
    }

    static fromJson(data: object): TiptiCardHistoryExchangeModel {
        if (!data || !Object.keys(data).length) return;
        return new TiptiCardHistoryExchangeModel(data as TiptiCardHistoryExchangeInterface);
    }

    static builderList(data: object): Array<TiptiCardHistoryExchangeModel> {
        if (!data || !(data as []).length) return [];
        return (data as []).map(this.fromJson).filter(exchange => !!exchange);
    }
}
