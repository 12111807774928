<template>
    <base-icon
        :height="height"
        :icon-name="$t('icon.information')"
        :icon-color="color"
        :width="width"
        viewBox="0 0 18 18"
    >
        <path
            d="M9 2C5.1 2 2 5.1 2 9C2 12.9 5.1 16 9 16C12.9 16 16 12.9 16 9C16 5.1 12.9 2 9 2ZM9 0C14 0 18 4 18 9C18 14 14 18 9 18C4 18 0 14 0 9C0 4 4 0 9 0Z"
        />
        <path
            d="M9.67969 5.37891C9.67969 5.97891 9.27969 6.37891 8.67969 6.37891C8.07969 6.37891 7.67969 5.97891 7.67969 5.37891C7.67969 4.77891 8.07969 4.37891 8.67969 4.37891C9.27969 4.37891 9.67969 4.77891 9.67969 5.37891ZM7.77966 12.9789V7.07892H9.67969V12.9789H7.77966Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconInfo',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 18 * this.size;
        },
        height(): number {
            return 18 * this.size;
        },
    },
};
</script>
