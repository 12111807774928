<template>
    <label>
        <input @input="onInput($event)" type="checkbox" v-model="value" :checked="isChecked" />
        <span></span>
    </label>
</template>
<script>
export default {
    name: 'check-tipti',
    emits: ['on-check'],
    data() {
        return { value: this.isChecked };
    },
    props: {
        isChecked: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onInput(event) {
            this.$emit('on-check', !this.value);
            this.value = event.target.value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';
label {
    position: relative;
}
span {
    cursor: pointer;
    width: 1.25em;
    height: 1.25em;
    border: 1px solid #ccc;
    display: flex;
    border-radius: 50%;
    transition: all linear 0.3s;
    background-color: $white;
    justify-content: center;
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        width: 0.75em;
        border-radius: $radius-circular;
        background-color: $orange;
        height: 0.75em;
        visibility: hidden;
    }
}
input {
    display: none;
    &:checked ~ span {
        background: $white;
        &:after {
            visibility: visible;
        }
    }
}
</style>
