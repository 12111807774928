import { RetailerAvailabilityModel } from '@/modules/retailers/models/RetailerAvailabilityModel';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';

import { MinRetailerInfo, RestRetailersInfo, YourRetailersInfo } from '@/modules/retailers/models/OtherRetailersModel';
import { RetailerFilter } from '@/modules/retailers/models/RetailerFilterModel';
import { DeferredOptionByRetailer } from '@/modules/retailers/store/model';

export default {
    filters(state): RetailerFilter[] {
        return state.filters ?? [];
    },
    yourRetailers(state): YourRetailersInfo {
        return state.yourRetailers;
    },
    otherRetailers(state): RestRetailersInfo[] {
        return state.otherRetailers;
    },
    retailers(state): MinRetailerInfo[] {
        return [
            ...(state.yourRetailers?.retailers ?? []),
            ...(state.otherRetailers.map((ret) => ret.retailers)?.flat() ?? []),
        ];
    },
    retailerSelected(state): RetailerModel {
        return state.retailerSelected;
    },
    cartRetailerItems(state): number {
        return state.cartRetailerItems;
    },
    retailersAvailability(state): RetailerAvailabilityModel[] {
        return state.retailersAvailability;
    },
    originalRetailersAvailability(state): RetailerAvailabilityModel[] {
        return state.originalRetailersAvailability;
    },
    bannerRetailers(state): Array<RetailerModel> {
        return state.bannerRetailers;
    },
    retailersAlertLimitShowed(state): Array<RetailerModel> {
        return state.retailersAlertLimitShowed;
    },
    bannerRetailersImg(state): {
        picture_for_web: string;
        picture_for_mobile_web: string;
    } {
        return state.bannerRetailersImg;
    },
    deferredOptionByRetailer(state): DeferredOptionByRetailer {
        return state.deferredOptionByRetailer ?? {};
    },
};
