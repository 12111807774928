import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-882c8798"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-news" }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = { class: "alert-news__description" }
const _hoisted_4 = { class: "alert-slide-dot" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    title: $options.newsAlertSlide?.slides[$data.currentSlidePosition]?.title,
    "header-text-color": "#ff9012",
    onOnClose: $options.closeAlert
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("img", {
          class: "alert-news__img",
          alt: $options.newsAlertSlide?.slides[$data.currentSlidePosition]?.title,
          src: $options.newsAlertSlide?.slides[$data.currentSlidePosition]?.web_picture,
          height: "500",
          loading: "lazy",
          width: "500"
        }, null, 8, _hoisted_2),
        _createElementVNode("p", _hoisted_3, _toDisplayString($options.newsAlertSlide?.slides[$data.currentSlidePosition]?.body), 1),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.newsAlertSlide?.slides, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "slide-dot",
              key: index,
              style: _normalizeStyle(index === $data.currentSlidePosition ? 'background-color: #cfde00;' : ''),
              onClick: ($event: any) => ($data.currentSlidePosition = index)
            }, null, 12, _hoisted_5))
          }), 128))
        ]),
        _createVNode(_component_btn_solid, {
          class: "alert-news__button",
          stretch: true,
          txt: $options.newsAlertSlide?.slides[$data.currentSlidePosition]?.button_text,
          onClick: $options.nextPage
        }, null, 8, ["txt", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title", "onOnClose"]))
}