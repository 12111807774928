import { useStore } from 'vuex';
import { computed } from 'vue';
import { CambrellaMembershipModel } from '@/modules/cambrella/models/CambrellaMembership';
import { MembershipType } from '@/modules/cambrella/models/MembershipType';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export const useCambrellas = () => {
    const $store = useStore();
    const { yourRetailers } = useRetailer();

    const cambrellaMembership = computed<CambrellaMembershipModel>(
        () => $store.getters['cambrellas/cambrellaMembership'],
    );
    const cambrellasWasShowedOnce = computed<boolean>(() => $store.getters['cambrellas/cambrellasModalWasShowed']);

    const hasCambrellaSubscription = computed<boolean>(() => !!cambrellaMembership.value?.date_end_membership);

    const showCambrellaModalFromTracking = computed<boolean>(
        () =>
            yourRetailers.value?.tracking?.cambrellas?.show_notification &&
            !hasCambrellaSubscription.value &&
            !cambrellasWasShowedOnce.value,
    );

    const cambrellasMembershipTypes = (): Promise<Array<MembershipType>> =>
        $store.dispatch('cambrellas/cambrellaMembershipTypes');

    const getCurrentMembership = (): Promise<void> => $store.dispatch('cambrellas/cambrellaMembership');

    const toggleCambrellasModalWasShowed = (wasShowed: boolean) => {
        $store.commit('cambrellas/cambrellasModalWasShowed', wasShowed);
    };

    return {
        cambrellaMembership,
        hasCambrellaSubscription,
        cambrellasMembershipTypes,
        getCurrentMembership,
        showCambrellaModalFromTracking,
        toggleCambrellasModalWasShowed,
    };
};
