import { logger } from '@/utils/logger';
import { CountryCodeModel } from '@/models/country/CountryCodeModel';

import { computed } from 'vue';
import axios from 'axios';
import MapUtils from '@/utils/mapUtils';
import { CountriesModel } from '@/models/external/CountriesModel';
import { useApp } from '@/composables/useApp';

interface Coordinates {
    lat: number;
    lng: number;
}

interface AddressResult {
    formatted_address: string;
    mainStreet: string;
    secondStreet: string;
    city: string;
    country: string;
    countryCode: string;
    center: Coordinates;
}

export const useCountry = () => {
    const { $store } = useApp();

    const countryCodes = computed<Array<CountryCodeModel>>(() => $store.getters['external/countryCodes']);
    const countries = computed<CountriesModel[]>(() => $store.getters['external/countries']);
    const countryCodeSelected = computed<CountryCodeModel>({
        get: () => $store.getters['external/countryCodeSelected'],
        set: (value) => $store.commit('external/countryCodeSelected', value),
    });

    const getAddressFromCoords = async (cords: Coordinates): Promise<AddressResult> => {
        try {
            logger('LAT_LNG', `${cords.lat} || ${cords.lng}`);
            if (!cords.lat || !cords.lng) return;

            const { data } = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${cords.lat},${cords.lng}&key=${process.env.VUE_APP_API_GOOGLE_KEY}`,
            );

            const place = data.results[0];
            if (!place) return;
            const _map = new MapUtils(place);
            return {
                formatted_address: _map.getAddress(),
                mainStreet: _map.getMainStreet(),
                secondStreet: _map.getSecondStreet(),
                city: _map.getCity(),
                country: _map.getCountry(),
                countryCode: _map.getCountryCode(),
                center: { lat: cords.lat, lng: cords.lng },
            };
        } catch (err) {
            logger('GET_ADDRESS_FROM_COORDS_COMPOSABLE', err);
        }
    };

    const onSuccessPosition = async (result: any): Promise<void> => {
        const cordsInformation = await getAddressFromCoords({
            lng: result.coords.longitude,
            lat: result.coords.latitude,
        });
        const countryPhone = countryCodes?.value?.find(
            (country: CountryCodeModel) => country.alpha_code === cordsInformation?.countryCode,
        );

        if (countryPhone) {
            $store.commit('external/countryCodeSelected', countryPhone);
            return;
        }
        $store.commit(
            'external/countryCodeSelected',
            countryCodes?.value?.find((country: CountryCodeModel) => country.alpha_code === 'EC'),
        );
    };

    const setDefaultCountryPhone = async (): Promise<void> => {
        const permissionsResult = await navigator.permissions.query({ name: 'geolocation' });
        if (permissionsResult.state === 'granted')
            return navigator.geolocation.getCurrentPosition(onSuccessPosition, () => {
                $store.commit(
                    'external/countryCodeSelected',
                    countryCodes?.value?.find((country: CountryCodeModel) => country.alpha_code === 'EC'),
                );
            });
        const countryPhone = countryCodes?.value?.find((country: CountryCodeModel) => country.alpha_code === 'EC');
        $store.commit('external/countryCodeSelected', countryPhone);
    };

    const countryPhoneFromPhoneNumber = async (phoneNumber?: string): Promise<string> => {
        try {
            if (!phoneNumber?.length) {
                await setDefaultCountryPhone();
                return;
            }
            const number = phoneNumber.split(' ')[0].replace('+', '');
            const countryPhone = countryCodes?.value?.find(
                (country: CountryCodeModel) => country.country_code === number,
            );
            if (countryPhone) countryCodeSelected.value = countryPhone;
            else await setDefaultCountryPhone();
        } catch (e) {
            logger('SET_COUNTRY_PHONE', e);
            await setDefaultCountryPhone();
        }
    };

    const concatenateNumberAndCountryCode = (phoneNumber: string): string => {
        if (!phoneNumber?.length) return '';
        return `+${$store.state.external?.countryCodeSelected?.country_code ?? ''} ${
            phoneNumber?.replaceAll(' ', '') ?? ''
        }`;
    };

    const validatePhoneAndCountryPhone = (phoneNumber: string): boolean => {
        if (!phoneNumber?.length) return false;
        if (!$store.state.external.countryCodeSelected?.regex?.length) return true;
        const regex = new RegExp($store.state.external.countryCodeSelected.regex);
        return regex.test(concatenateNumberAndCountryCode(phoneNumber));
    };

    return {
        countries,
        getAddressFromCoords,
        countryPhoneFromPhoneNumber,
        concatenateNumberAndCountryCode,
        validatePhoneAndCountryPhone,
        countryCodeSelected,
        getCountries: () => $store.dispatch('external/getCountries'),
    };
};
