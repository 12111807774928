<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { reactive } from 'vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
});

const dimensions = reactive({
    width: 44 * props.size,
    height: 40 * props.size,
});
</script>

<template>
    <BaseIcon :height="dimensions.height" :icon-color="color" :width="dimensions.width" viewBox="0 0 44 40">
        <g clip-path="url(#clip0_5_2523)">
            <path
                :fill="color"
                d="M43.9999 28.1546C43.8324 29.036 43.7329 29.9366 43.4833 30.7936C42.4884 34.2233 40.373 36.7716 37.2347 38.4542C35.046 39.6271 32.6827 40.0792 30.2217 39.8209C26.5319 39.4334 23.5472 37.7334 21.3219 34.7557C19.7039 32.5914 18.8678 30.1339 18.8765 27.4372C18.8905 23.3041 20.5329 19.9127 23.8003 17.3714C26.3818 15.3642 29.3472 14.5002 32.6059 14.8284C36.6081 15.2316 39.7254 17.162 41.9596 20.4957C43.1115 22.2132 43.7486 24.1279 43.9354 26.1892C43.9423 26.273 43.9755 26.3533 43.9965 26.4353V28.1528L43.9999 28.1546Z"
            />
            <path
                :fill="color"
                d="M14.5654 12.6606C11.1357 12.6222 7.68503 12.1404 4.41588 10.7197C3.26915 10.2222 2.19572 9.60087 1.2724 8.74387C-0.368282 7.22187 -0.370028 5.53057 1.27066 4.01556C2.48022 2.8985 3.91844 2.17241 5.44567 1.62086C9.22274 0.261186 13.1255 -0.0913871 17.1067 0.205332C19.8191 0.4078 22.4529 0.955858 24.9558 2.04499C26.1026 2.54418 27.1725 3.16729 28.0941 4.02952C29.7191 5.54977 29.7208 7.21489 28.0958 8.72816C26.8793 9.86093 25.4236 10.5783 23.8842 11.1438C20.9082 12.2382 17.8154 12.6361 14.5637 12.6606H14.5654Z"
            />
            <path
                :fill="color"
                d="M0.0157376 13.1318C5.75639 16.5773 11.9857 17.1794 18.4647 16.6698C18.3984 16.7762 18.3478 16.8757 18.2814 16.9595C17.3808 18.0975 16.6408 19.3315 16.0665 20.665C15.9827 20.8605 15.9164 21.0246 15.6232 21.0316C12.1201 21.0979 8.68693 20.7139 5.37938 19.4886C3.91149 18.944 2.52214 18.2529 1.34574 17.1882C0.45209 16.3783 -0.0872415 15.4236 0.0122468 14.1721C0.0384279 13.8457 0.0157376 13.5141 0.0157376 13.1318Z"
            />
            <path
                :fill="color"
                d="M18.0493 37.5989C15.7419 37.8642 13.3716 37.8555 11.0049 37.571C8.73759 37.2987 6.5279 36.7838 4.42992 35.8605C3.11388 35.281 1.87639 34.5742 0.898956 33.4833C0.288064 32.8008 -0.0330917 32.0241 0.012289 31.099C0.0314884 30.7133 0.0157798 30.3276 0.0157798 29.9034C0.0995594 29.9296 0.165885 29.9366 0.216502 29.968C2.9149 31.6925 5.90479 32.6053 9.03081 33.1394C11.1672 33.5042 13.321 33.6386 15.4871 33.5758C15.7943 33.5671 15.9217 33.677 16.0596 33.9388C16.7019 35.1606 17.3756 36.3667 18.0476 37.5972L18.0493 37.5989Z"
            />
            <path
                :fill="color"
                d="M14.7346 25.2415V29.3554C14.108 29.3554 13.4518 29.3903 12.8007 29.3502C9.54729 29.1442 6.39159 28.5246 3.46454 27.0183C2.44173 26.4912 1.49572 25.8506 0.759158 24.943C0.273935 24.3443 -0.00533065 23.6759 0.0138688 22.8939C0.0243413 22.4506 0.0156143 22.0072 0.0156143 21.4906C4.55891 24.3199 9.56823 25.156 14.7346 25.2432V25.2415Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_5_2523">
                <rect fill="white" height="39.7848" transform="translate(0 0.107544)" width="44" />
            </clipPath>
        </defs>
    </BaseIcon>
</template>
