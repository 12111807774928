import { CountriesModel, toCountriesModel } from '../external/CountriesModel';
import { SectorModel, toSectorModel } from './SectorModel';

class CityModel {
    id: number;
    name: string;
    referenceCoordinate: object;
    sectors: SectorModel[];
    position: string;
    country: CountriesModel;

    constructor(
        id: number,
        name: string,
        sectors?: SectorModel[],
        referenceCoordinate?: object,
        position?: string,
        country?: CountriesModel,
    ) {
        this.id = id;
        this.name = name;
        this.sectors = sectors;
        this.referenceCoordinate = referenceCoordinate;
        this.position = position;
        this.country = country;
    }

    haveReference(): boolean {
        return typeof this.referenceCoordinate !== 'undefined';
    }

    addSector(sector: SectorModel): void {
        this.sectors.push(sector);
    }
}
const toCityModel = (data: object) =>
    data
        ? new CityModel(
              data['id'],
              data['name'] ?? '',
              data['sectors']
                  ?.map(sector => toSectorModel(sector))
                  ?.filter((sector: SectorModel) => sector.name?.trim()?.length > 2) ?? [],
              undefined,
              data['position'],
              toCountriesModel(data['country']),
          )
        : null;

export { CityModel, toCityModel };
