<template>
    <section class="feeling-bar">
        <header class="feeling-bar__title" :style="{ color: textColor, backgroundColor: bgColor }">
            <h2>{{ selectedStory?.interactiveElement.text.toUpperCase() }}</h2>
        </header>

        <div class="feeling-bar__container" ref="container">
            <div class="container-bar" ref="slider">
                <progress :value="value" :max="100" />
                <div
                    ref="indicator"
                    class="container-bar__indicator"
                    :style="{
                        backgroundColor: selectedStory?.interactiveElement?.elements[0]?.textBackgroundColor,
                        left: `${value}%`,
                    }"
                >
                    {{ selectedStory?.interactiveElement?.elements[0]?.text }}
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

export default {
    name: 'feeling-bar',
    data() {
        return {
            value: 20,
        };
    },
    computed: {
        ...mapGetters({
            selectedStory: 'stories/selectedStory',
        }),
        hasAnswered() {
            return this.selectedStory?.interactiveElement?.elements?.some(element => element.choice);
        },
        textColor() {
            return this.selectedStory?.interactiveElement?.textColor ?? '#FFF';
        },
        bgColor() {
            return this.selectedStory?.interactiveElement?.textBackgroundColor ?? '#606060';
        },
    },
    watch: {
        selectedStory() {
            this.loadPercentage();
            this.hasAnswered = false;
        },
    },
    mounted() {
        this.drag();
        this.loadPercentage();
    },
    methods: {
        drag() {
            const indicator = this.$refs.indicator;
            const container = this.$refs.container;
            const slider = this.$refs.slider;
            const widthSlider = slider.clientWidth;
            let mouseDown = false;
            let posFinal;
            let posInitial;

            // MOUSE EVENTS
            indicator.onmousedown = async (e): Promise<void> => {
                mouseDown = true;
                e = e || window.event;
                e.preventDefault();
                posInitial = e.clientX;
            };
            container.onmouseleave = async (): Promise<void> => {
                mouseDown = false;
                if (!this.hasAnswered) this.registerAnswer();
            };
            indicator.onmouseup = async (): Promise<void> => {
                mouseDown = false;
                if (!this.hasAnswered) this.registerAnswer();
            };

            indicator.onmousemove = async (e): Promise<void> => {
                e = e || window.event;
                e.preventDefault();
                if (indicator.offsetLeft <= 0 || indicator.offsetLeft >= widthSlider || this.hasAnswered) {
                    mouseDown = false;
                }
                if (!mouseDown) {
                    return;
                }
                posFinal = posInitial - e.clientX;
                posInitial = e.clientX;
                indicator.style.left = indicator.offsetLeft - posFinal + 'px';
                const pixelsPercentage = (-posFinal * 100) / widthSlider;
                this.value = this.value + pixelsPercentage;
            };

            // TOUCH EVENTS
            indicator.ontouchstart = async (e): Promise<void> => {
                mouseDown = true;
                e = e || window.event;
                e.preventDefault();
                const clientX = e.touches[0].clientX;
                posInitial = clientX;
            };
            indicator.ontouchend = async (): Promise<void> => {
                mouseDown = false;
                if (!this.hasAnswered) this.registerAnswer();
            };

            indicator.ontouchmove = async (e): Promise<void> => {
                e = e || window.event;
                e.preventDefault();
                if (indicator.offsetLeft <= 0 || indicator.offsetLeft >= widthSlider || this.hasAnswered) {
                    mouseDown = false;
                }
                if (!mouseDown) {
                    return;
                }
                const clientX = e.touches[0].clientX;
                posFinal = posInitial - clientX;
                posInitial = clientX;
                indicator.style.left = indicator.offsetLeft - posFinal + 'px';
                const pixelsPercentage = (-posFinal * 100) / widthSlider;
                this.value = this.value + pixelsPercentage;
            };
        },

        loadPercentage(): void {
            this.value =
                this.selectedStory?.interactiveElement?.elements[0]?.selectChoice === 0
                    ? 20
                    : (this.selectedStory?.interactiveElement?.elements[0]?.selectChoice * 100) /
                      this.selectedStory?.interactiveElement?.elements[0]?.feelingBar;
        },
        async registerAnswer(): Promise<void> {
            const valueToSend = (this.value * this.selectedStory?.interactiveElement?.elements[0]?.feelingBar) / 100;
            this.hasAnswered = true;
            this.selectedStory?.interactiveElement?.elements?.forEach(element => {
                element.choice = true;
                element.selectChoice = valueToSend;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../../story-container.scss';
@import './feeling-bar.scss';
</style>
