<script lang="ts" setup>
import IconBack from '@/components/icons/IconBack.vue';
import { useApp } from '@/composables/useApp';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { storeToRefs } from 'pinia';
import { useProductReplacements } from '@/modules/highDemandAndOutOfStock/composable/useProductReplacements';
import { useImage } from '@/composables/ImageComposable';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { computed, onBeforeUnmount, ref } from 'vue';
import ProductsGrid from '@/components/product/productsGrid/ProductsGrid.vue';
import { useProductStore } from '@/store/useProductStore';

const { $route, $router, $t, notifier } = useApp();
const { displayDefaultImg } = useImage();
const { lastItemAdded, isHighDemand, isOutOfStock } = storeToRefs(useProductStore());
const {
    sendAnalyticsAndRemoveItem,
    productItem,
    imageSource,
    replacementSuggestionPageItems,
    redirectionAndShowNotifier,
    sendConfirmReplacementAnalytics,
} = useProductReplacements();
const { retailerSelected } = useRetailer();
const canSendEvents = ref(false);
const textSubHeaderPage = computed(() => {
    if (isHighDemand.value) return $t('txt.select-a-replacement');
    if (isOutOfStock.value) return $t('txt.product-out-of-stock-in-store');
    return '';
});

const init = () => {
    if (!$route.params?.stockItemId || !replacementSuggestionPageItems.value?.length)
        $router.replace({
            name: appRoutesMap.retail.home,
            params: {
                retailer: retailerSelected.value?.name,
            },
        });
};

init();

const handleConfirmReplace = async () => {
    await redirectionAndShowNotifier();
    sendConfirmReplacementAnalytics();
    sendAnalyticsAndRemoveItem();
    $router.replace({
        name: appRoutesMap.retail.home,
        params: {
            retailer: retailerSelected.value?.name,
        },
    });
};

const goBack = () => {
    canSendEvents.value = true;
    $router.back();
};

onBeforeUnmount(() => {
    if (!canSendEvents.value) return;
    if (isHighDemand.value) {
        sendAnalyticsAndRemoveItem();
        return;
    }
    notifier({
        type: 'INFO',
        title: $t('txt.product-was-not-added'),
        body: $t('txt.availability-of-our-products'),
    });
    sendAnalyticsAndRemoveItem();
});

const priceAndQuantity = computed<{ unitQuantity: number; costTotal: number }>(() => {
    if (isHighDemand.value)
        return {
            unitQuantity: productItem.value.productQuantity,
            costTotal: productItem.value.productQuantity * productItem.value.priceWithDiscount,
        };
    return {
        unitQuantity: 1,
        costTotal: productItem.value.priceWithDiscount,
    };
});
</script>

<template>
    <div class="page-suggestion">
        <section class="page-suggestion__header">
            <button class="page-suggestion__icon-back" @click="goBack">
                <IconBack :size="0.8" />
            </button>
            <p class="page-suggestion__replacement">{{ $t('txt.replacement-suggestions') }}</p>
        </section>

        <section class="page-suggestion__detail-item">
            <img class="page-suggestion__img" :alt="productItem.name" :src="imageSource" @error="displayDefaultImg" />
            <div class="page-suggestion__content-name-price">
                <aside>
                    <p class="page-suggestion__product_name">{{ productItem.name }}</p>
                    <span class="page-suggestion__product_indicator">
                        {{ productItem.unitQuantity }} {{ productItem.unit }}
                        {{ productItem.conversionGramsAndKilogramsToPounds }}
                    </span>
                </aside>

                <section>
                    <p v-currency="productItem.priceWithDiscount" class="page-suggestion__price-discount"></p>
                    <span class="page-suggestion__price-counter">
                        {{ priceAndQuantity.unitQuantity }} x
                        <p v-currency="priceAndQuantity.costTotal" class="page-suggestion__price"></p
                    ></span>
                </section>
            </div>
        </section>
        <p class="page-suggestion__header--sub">{{ textSubHeaderPage }}</p>
        <ProductsGrid
            v-if="replacementSuggestionPageItems?.length"
            :products="replacementSuggestionPageItems"
            section="REPLACEMENT"
        />
        <footer class="page-suggestion__footer-btn">
            <BtnSolid :is-disabled="!lastItemAdded" :txt="$t('cta.confirm-replace')" @click="handleConfirmReplace" />
        </footer>
    </div>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/index' as style;

.page-suggestion {
    margin: style.$margin-lg + style.$margin-lg style.$margin-md style.$margin-md;
    display: flex;
    flex-direction: column;
    gap: style.$margin-lg;

    @include style.phone {
        margin: style.$margin-md;
    }

    &__header {
        display: flex;
        gap: style.$margin-md;
        align-items: center;

        &--sub {
            @include style.body1;
            color: style.$dark-grey;
            font-weight: 400;
            font-size: 14px;
        }
    }

    &__detail-item {
        display: flex;
        gap: style.$margin-md;
        @include style.phone {
            gap: style.$margin-sm;
        }
    }

    &__content-name-price {
        display: flex;
        gap: style.$padding-xlg;
        @include style.phone {
            gap: style.$padding-sm;
        }
    }

    &__replacement {
        @include style.body1;
        color: style.$dark-grey;
        font-weight: 600;
        font-size: 20px;
    }

    &__icon-back {
        all: unset;
        cursor: pointer;
    }

    &__footer-btn {
        justify-content: center;
        display: flex;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        background: white;
        height: 5rem;
        align-items: center;
    }

    &__price-counter {
        display: flex;
        align-items: center;
        @include style.body1;
        color: style.$dark-grey;
        font-weight: 400;
        font-size: 12px;
        width: max-content;
    }

    &__img {
        height: 5rem;
        @include style.phone {
            height: 4rem;
        }
    }

    &__product_name {
        @include style.body1;
        color: style.$dark-grey;
        font-weight: 500;
    }

    &__product_indicator {
        @include style.body1;
        color: style.$dark-grey;
        font-size: 12px;
    }

    &__price-discount {
        @include style.body1;
        color: style.$dark-grey;
        width: max-content;
    }

    &__price {
        @include style.body1;
        color: style.$dark-grey;
        font-weight: 400;
        font-size: 12px;
    }
}
</style>
