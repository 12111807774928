<script lang="ts" setup>
import { useWallet } from '@/views/wallet/useWallet';
import { useRouter } from 'vue-router';
import IconBack from '@/components/icons/IconBack.vue';
import TheGiftCardSelectedRetailerWithoutCategories from './TheGiftCardSelectedRetailerWithoutCategories.vue';
import SkeletonGrid from '@/components/skeletons/skeletonGrid/SkeletonGrid.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'TheGiftCardSelctedRetailerCategory',
});

const $router = useRouter();

const { giftCardRetailerCategorySelected } = useWallet();

const resetCards = () => {
    $router
        .replace({
            name: appRoutesMap.giftCardSelectedRetailer,
        })
        .then(() => {
            giftCardRetailerCategorySelected.value = undefined;
        });
};

if (!giftCardRetailerCategorySelected.value)
    $router.replace({
        name: appRoutesMap.Wallet,
    });
</script>

<template>
    <section class="the-gift-card-selected-retailer-category__container">
        <button class="the-gift-card-selected-retailer-category__btn" @click="resetCards">
            <icon-back :size="0.8" color="#000000" />
            <span class="the-gift-card-selected-retailer-category__btn-text">{{ $t('txt.go-back') }}</span>
        </button>
        <Suspense>
            <TheGiftCardSelectedRetailerWithoutCategories />
            <template #fallback>
                <SkeletonGrid />
            </template>
        </Suspense>
    </section>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-gift-card-selected-retailer-category__container {
    margin: 0 $margin-lg;
}

.the-gift-card-selected-retailer-category__btn {
    all: unset;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin: 0 $margin-xlg;
    margin-bottom: $margin-lg;

    &-text {
        @include button;
        font-size: 1.8rem;
        font-weight: 600;
        text-transform: capitalize;
    }
}
</style>
