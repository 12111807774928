<template>
    <base-modal
        :auto-size="false"
        :has-close="hasClose"
        :title="$t('general.select-delivery-sector')"
        @on-close="onClose"
    >
        <tipti-map
            :is-edit-address="isEditAddress"
            :is-from-register="isFromRegister"
            :section="section"
            @on-close="onClose"
        />
    </base-modal>
</template>

<script lang="ts" setup>
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import TiptiMap from '@/components/tiptiMap/TiptiMap.vue';
import { useUser } from '@/composables/useUser';
import { useApp } from '@/composables/useApp';
import { computed } from 'vue';

defineProps({
    hasClose: { type: Boolean, default: true },
    isEditAddress: { type: Boolean, default: false },
    isFromRegister: { type: Boolean, default: false },
    section: {
        type: String,
        default: '',
    },
});
const $emit = defineEmits<(e: 'on-close') => void>();
const { $store } = useApp();
const { userLocation, addressDefault } = useUser();

const address = computed(() => userLocation.value ?? addressDefault.value);
const onClose = (): void => {
    $emit('on-close');
    $store.commit('external/countrySelected', address.value?.city?.country);
};
</script>
