import { CambrellaMembershipModel } from '@/modules/cambrella/models/CambrellaMembership';

export default {
    cambrellaMembership(state): CambrellaMembershipModel {
        return state.cambrellaMembership;
    },
    cambrellasModalWasShowed(state): boolean {
        return state.cambrellasModalWasShowed;
    },
};
