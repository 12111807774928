export default class InstitutionalPaymentModel {
    id: number;
    amount: number;
    unlimitedBuys: boolean;

    constructor(id: number, unlimitedBuys?: boolean, amount?: number) {
        this.id = id;
        this.unlimitedBuys = unlimitedBuys;
        this.amount = amount;
    }
}
