import type { AxiosRequestConfig } from 'axios';
import Axios, { AxiosInstance } from 'axios';
import store from '@/store';
import {
    ClientError,
    NetworkError,
    NotFoundError,
    RequestError,
    ServerError,
    ThrottledError,
} from '@/modules/core/errors/AppErrors';
import { getTokenFromOrigin } from '@/modules/core/infraestructure/countryTokens';

declare module 'axios' {
    interface AxiosRequestConfig {
        cancelPreviousRequests?: boolean;
        ensureProductionRequest?: boolean;
    }
}

const baseURL =
    process.env.VUE_APP_API_ENV === 'DEV'
        ? process.env.VUE_APP_API_DEV_URL
        : process.env.VUE_APP_API_ENV === 'STG'
          ? process.env.VUE_APP_API_STG_URL
          : process.env.VUE_APP_API_PROD_URL;

const pendingRequests: { [key: string]: AbortController } = {};

const removePendingRequest = (url: string | undefined, abort = false): void => {
    // check if pendingRequests contains our request URL
    if (!url || !pendingRequests[url]) return;

    // if we want to abort ongoing call, abort it
    if (abort) pendingRequests[url].abort();

    // remove the request URL from pending requests
    delete pendingRequests[url];
};
export const axiosInstance = (useAuth = true): AxiosInstance => {
    const config: AxiosRequestConfig = {};

    if (useAuth && store.getters['user/token']) {
        config.headers = {
            Authorization: store.getters['user/token'],
        };
    }

    if (useAuth && !store.getters['user/isAuth']) {
        const { origin } = window.location;
        config.headers = {
            Authorization: getTokenFromOrigin(origin),
        };
    }

    config.baseURL = baseURL;

    const instance = Axios.create(config);

    instance.interceptors.request.use((config) => {
        // we only want to cancel requests if config.cancelPreviousRequests is true
        // if the config.url exists
        // if the config doesn't contain AbortController.signal already, in this case we let the developer handle it himself
        if (config?.cancelPreviousRequests && config?.url && !config.signal) {
            // remove request URL from pending requests and also abort ongoing call with same URL
            removePendingRequest(config.url, true);

            const abortController = new AbortController(); //create new AbortController
            config.signal = abortController.signal; // assign it's signal into request config
            pendingRequests[config.url] = abortController; // store AbortController in the pending requests map
        }
        if (config.baseURL !== process.env.VUE_APP_API_PROD_URL) return config;
        if (['localhost', 'develop', 'staging'].every((path) => !RegExp(path).exec(window.location.hostname)?.length))
            return config;
        if (!config.ensureProductionRequest) return config;
        throw new Error('Operation canceled by production validation');
    });

    instance.interceptors.response.use(
        (response) => {
            removePendingRequest(response.request.responseURL);
            return response;
        },
        (error) => {
            removePendingRequest(error.config?.url);
            const { response } = error;
            const { config } = error;
            const { request } = error;
            const { message } = error;

            store.commit('network/askForMaintenance', true);

            if (!response) {
                if (message.match(/Network Error/gi)) return Promise.reject(new NetworkError(config?.url));
                return Promise.reject(new RequestError(config?.url, request?.status ?? 0, message ?? 'Unknown error'));
            }
            const { data } = response;
            if (response.status === 404)
                return Promise.reject(new NotFoundError(config?.url ?? response.config?.url, data ?? message));
            if (response.status === 429)
                return Promise.reject(new ThrottledError(config?.url ?? response.config?.url, data ?? message));
            if (response.status < 500)
                return Promise.reject(
                    new ClientError(config?.url ?? response.config?.url, response.status, data ?? message),
                );

            return Promise.reject(new ServerError(config?.url ?? response.config?.url, response.status));
        },
    );

    return instance;
};
