<template>
    <base-card class="flex-column" padding="0">
        <section-header :title="sectionName" has-back @on-back="$router.go(-1)" />
        <search-category class="lists-search" :origin-section="originSection" @redirect-on-search="redirectToSearch" />
        <category-products
            class="category-products"
            :origin-section="originSection"
            @go-to-featured-products="goToFeaturedProduct"
        />
    </base-card>
</template>

<script lang="ts">
import { Sections } from '@/enums/sectionsOrigin';
import CategoryProducts from '@/components/category/categoryProducts/CategoryProducts.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import SearchCategory from '@/components/category/searchCategory/SearchCategory.vue';
import { RoutesOrigin } from '@/enums/routesOrigin';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export default {
    name: 'list-category-products',
    components: { CategoryProducts, BaseCard, SectionHeader, SearchCategory },
    setup() {
        const { retailerSelected } = useRetailer();

        return {
            retailerSelected,
        };
    },

    computed: {
        sectionName(): string {
            return this.$route.query.name;
        },
        subheaderName(): string {
            return `${this.$t("txt['search__products-find-in']")} ${this.retailerSelected.name}`;
        },
        originSection(): Sections {
            return Sections.MY_LISTS;
        },
        categoryId(): string {
            return this.$route.query?.categoryId;
        },
    },
    methods: {
        redirectToSearch(query: string): void {
            this.$router.push({
                path: RoutesOrigin.LISTS_CATEGORY_PRODUCTS,
                query: {
                    name: this.$route.params.name ?? this.$t('cta.categories'),
                    searchOn: query,
                    categoryId: this.categoryId,
                },
                replace: this.query?.length > 0 && !this.$route.path.includes(RoutesOrigin.LISTS_CATEGORY_PRODUCTS),
            });
        },
        goToFeaturedProduct(): void {
            this.$router.push({
                name: appRoutesMap.list.listCategoryFeaturedProducts,
                query: {
                    name: this.sectionName,
                    categoryId: this.categoryId,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import './list-category-products';
</style>
