import { computed, ref } from 'vue';

import SendbirdChat, { BaseChannel, ConnectionState, SendbirdError } from '@sendbird/chat';
import { useUser } from '@/composables/useUser';
import { generateRandomColor, getFacebookImage } from '@/utils/utils';
import { logger } from '@/utils/logger';
import {
    GroupChannel,
    GroupChannelHandler,
    GroupChannelListOrder,
    GroupChannelModule,
} from '@sendbird/chat/groupChannel';
import {
    AdminMessage,
    BaseMessage,
    FileMessage,
    FileMessageCreateParams,
    MessageListParams,
    ReplyType,
    UserMessage,
    UserMessageCreateParams,
} from '@sendbird/chat/message';
import { OpenChannelModule } from '@sendbird/chat/openChannel';
import { SendbirdError as SendbirdErrorCodes } from '@/enums/chatCodes';
import { ModuleNamespaces, SendableMessage } from '@sendbird/chat/lib/__definition';
import { useOrder } from '@/composables/useOrder';
import { NotificationPayload } from '@/modules/notification/Notification';
import { useApp } from '@/composables/useApp';
import { appRoutesMap } from '@/router/appRoutesMap';

let sendBirdInstance: SendbirdChat & ModuleNamespaces<[OpenChannelModule, GroupChannelModule]>;

const messagesUnreadCount = ref(0);
const messageSent = ref<SendableMessage | UserMessage | FileMessage | AdminMessage>();
const messageReceived = ref<BaseMessage>();
const inChat = ref(false);
const isChatClosed = ref(false);
const isSendingMessage = ref(false);
const isLastPage = ref(false);
const isLoadingMessages = ref(false);
const groupChannels = ref<GroupChannel[]>([]);
const currentChannel = ref<GroupChannel>();

const sendbirdApiKey =
    process.env.VUE_APP_API_ENV === 'DEV'
        ? process.env.VUE_APP_API_SENDBIRD_KEY_DEV
        : process.env.VUE_APP_API_ENV === 'STG'
          ? process.env.VUE_APP_API_SENDBIRD_KEY_STG
          : process.env.VUE_APP_API_SENDBIRD_KEY_PROD;

const connectionState = ref<ConnectionState>(ConnectionState.CLOSED);

export const useChat = () => {
    const { $store, $t, notifier, $router } = useApp();
    const retrieve_messages_size = 30;
    const { order } = useOrder();

    const { baseConfiguration, profile, isAuth } = useUser();
    const shopper = computed(
        () => currentChannel.value?.members?.find((member) => member.metaData['type'] === 'shopper'),
    );

    const isConnected = computed(
        () =>
            isAuth.value &&
            baseConfiguration.value?.chatServiceEnabled &&
            connectionState.value === ConnectionState.OPEN,
    );

    const initChat = () => {
        try {
            if (sendBirdInstance) return;

            sendBirdInstance = SendbirdChat?.init({
                appId: sendbirdApiKey,
                modules: [new OpenChannelModule(), new GroupChannelModule()],
            });
        } catch (e) {
            logger('SEND_BIRD_INIT', e);
        }
    };

    const connectUser = async () => {
        await connectUserToSendBird();
        return setMessageListener();
    };

    const orderUnreadMessageCount = (orderId: string): number => {
        if (!isConnected.value) return 0;
        return groupChannels.value?.find((channel) => channel?.url == orderId)?.unreadMessageCount ?? 0;
    };

    const connectUserToSendBird = async (): Promise<void> => {
        try {
            if (!sendBirdInstance) initChat();
            if (!sendBirdInstance) return;
            try {
                await sendBirdInstance?.connect(profile.value?.email);
                connectionState.value = sendBirdInstance.connectionState;
            } catch (e) {
                logger('CHAT_CONNECT', e);
            }

            const facebookUserId = profile.value?.facebookUserId ?? '';
            const _profilePicture = profile.value?.profilePicture ?? '';

            await sendBirdInstance?.updateCurrentUserInfo({
                profileUrl: facebookUserId.length ? getFacebookImage(facebookUserId) : _profilePicture,
                nickname: `${profile.value?.name ?? ''} ${profile.value?.lastname ?? ''}`,
            });

            if (
                !sendBirdInstance?.currentUser?.metaData ||
                Object.keys(sendBirdInstance?.currentUser?.metaData).length === 0 ||
                Object.getPrototypeOf(sendBirdInstance?.currentUser?.metaData) !== Object.prototype
            ) {
                await configMetadataUser();
            } else if (sendBirdInstance?.currentUser?.metaData['phone'] != profile.value?.phone) {
                await updateMetadataUser();
            }
        } catch (err) {
            logger('CONNECT_USER_TO_SENDBIRD', err);
        }
    };

    ///* This function allows a user to enter a chat, as long as they are invited or create a new channel
    const enterToChat = async (shopperId: string, orderId: string): Promise<boolean> => {
        if (!isConnected.value) await connectUserToSendBird();

        const userExistInServer = await checkIfUserExistsInServer(shopperId);
        if (!userExistInServer) return false;

        await createGroupChannel({
            userId: profile.value?.email ?? '',
            shopperId: shopperId,
            orderId: orderId,
        });
        prepareChat();
        return true;
    };

    const checkIfUserExistsInServer = async (shopperId: string): Promise<boolean> => {
        try {
            const query = sendBirdInstance?.createApplicationUserListQuery({
                userIdsFilter: [shopperId],
            });
            const result = await query.next();
            return !!result.length;
        } catch (err) {
            logger('CHECK_IF_USER_EXISTS_IN_SERVER', err);
        }
    };

    const configMetadataUser = async (): Promise<void> => {
        ///*** All users need a metadata to send a specific color and identify if this is
        /// client or shopper
        try {
            await sendBirdInstance?.currentUser?.createMetaData({
                color: `0xff${generateRandomColor()}`,
                type: 'client',
                phone: profile.value?.phone ?? '',
            });
        } catch (err) {
            logger('CONFIG_METADATA_USER', err);
        }
    };

    const updateMetadataUser = async (): Promise<void> => {
        ///*** All users need a metadata to send a specific color and identify if this is
        /// client or shopper
        try {
            await sendBirdInstance?.currentUser?.updateMetaData({
                color: `0xff${generateRandomColor()}`,
                type: 'client',
                phone: profile.value?.phone,
            });
        } catch (err) {
            logger('UPDATE_METADATA_USER', err);
        }
    };

    const loadChannelList = async (activeOrders: Array<string>): Promise<void> => {
        try {
            if (!isConnected.value) return;
            const query = sendBirdInstance?.groupChannel?.createMyGroupChannelListQuery({
                order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
                channelUrlsFilter: activeOrders,
            });
            groupChannels.value = await query?.next();
        } catch (err) {
            logger('LOAD_CHANNEL_LIST:', err);
        }
    };

    const messagesUnread = async (): Promise<void> => {
        try {
            if (!isAuth.value) return;
            if (!isConnected.value) await connectUserToSendBird();
            if (sendBirdInstance?.connectionState !== ConnectionState.OPEN) {
                messagesUnreadCount.value = 0;
                return;
            }

            messagesUnreadCount.value = await sendBirdInstance?.groupChannel?.getTotalUnreadMessageCount();
        } catch (err) {
            logger('MESSAGES_UNREAD', err);
        }
    };

    const createGroupChannel = async (payload: {
        userId: string;
        shopperId: string;
        orderId: string;
    }): Promise<void> => {
        try {
            const _channelVerifier = await verifyIfChannelExists(payload.orderId);
            if (_channelVerifier) {
                currentChannel.value = _channelVerifier;
                const newGroupChannels: GroupChannel[] = groupChannels.value ?? [];
                if (!newGroupChannels.includes(_channelVerifier)) newGroupChannels?.push(_channelVerifier);
                groupChannels.value = newGroupChannels;
                return;
            }
            currentChannel.value = await sendBirdInstance?.groupChannel?.createChannel({
                operatorUserIds: [payload.userId],
                channelUrl: payload.orderId,
                name: `Orden ${payload.orderId}`,
                invitedUserIds: [payload.userId, payload.shopperId],
            });
            const newGroupChannels: GroupChannel[] = [...groupChannels.value];
            if (!newGroupChannels.includes(currentChannel.value)) newGroupChannels.push(currentChannel.value);
            groupChannels.value = newGroupChannels;
        } catch (err) {
            logger('CREATE_GROUP_CHANNEL', err);
        }
    };

    const verifyIfChannelExists = async (orderId: string): Promise<GroupChannel> => {
        try {
            return await sendBirdInstance?.groupChannel?.getChannel(orderId);
        } catch (err) {
            logger('VERIFY_IF_CHANNEL_EXISTS', err);
        }
    };

    const reconnectToServer = async (): Promise<void> => {
        initChat();
        if (connectionState.value === ConnectionState.CLOSED) await connectUserToSendBird();
    };

    const verifyMembers = async () => {
        if (!isConnected.value) return;
        if (currentChannel.value.members?.some((member) => member.userId === order.value?.actualShopperAssigned.email))
            return;
        await inviteUsers({
            idUsers: [order.value.actualShopperAssigned.email],
        });
    };

    /// ** Invite shopper in case he is not involved into the chat
    /// this can happen if the user starts the conversation
    const inviteUsers = async (payload: { idUsers: Array<string> }): Promise<void> => {
        try {
            if (!isConnected.value) return;
            await currentChannel.value?.inviteWithUserIds(payload.idUsers);
        } catch (err) {
            logger('INVITE_USERS', err);
        }
    };

    const loadMessages = async (timestamp: number): Promise<Array<UserMessage | FileMessage | BaseMessage>> => {
        try {
            if (isLoadingMessages.value) return;
            if (!isConnected.value) await connectUserToSendBird();

            isLoadingMessages.value = true;
            const params: MessageListParams = {
                prevResultSize: retrieve_messages_size,
                nextResultSize: 0,
                reverse: true,
                includeParentMessageInfo: true,
                includeReactions: true,
                includeThreadInfo: true,
                isInclusive: true,
                replyType: ReplyType.ALL,
            };

            const messages: BaseMessage[] = await currentChannel.value?.getMessagesByTimestamp(timestamp, params);
            if (messages?.length !== retrieve_messages_size) isLastPage.value = true;
            return messages ?? [];
        } catch (err) {
            logger('LOAD_MESSAGES', err);
            isLastPage.value = true;
        } finally {
            isLoadingMessages.value = false;
        }
    };

    const sendTxtMessage = async ({
        customType,
        message,
        parentMessageId,
    }: {
        message: string;
        customType: string;
        parentMessageId: number;
    }): Promise<void> => {
        try {
            isSendingMessage.value = true;
            const params: UserMessageCreateParams = {
                message,
                customType,
                parentMessageId,
            };

            const channel: GroupChannel = currentChannel.value;
            channel
                .sendUserMessage(params)
                .onSucceeded((result) => {
                    messageSent.value = result;
                    isSendingMessage.value = false;
                })
                .onFailed((err, message) => {
                    if (!(err instanceof SendbirdError)) return;
                    if (
                        ![SendbirdErrorCodes.CONNECTION_REQUIRED, SendbirdErrorCodes.CONNECTION_CANCELED].includes(
                            err?.code,
                        )
                    ) {
                        logger('SEND_USER_MESSAGE', err);
                        isSendingMessage.value = false;

                        return;
                    }
                    reconnectToServer();
                });
        } catch (err) {
            logger('SEND_TXT_MESAGE');
        }
    };

    const sendFileMessage = async (payload: {
        file: File;
        fileUrl: string;
        message: string;
        customType: string;
        parentMessageId: number;
    }): Promise<void> => {
        try {
            isSendingMessage.value = true;
            const params: FileMessageCreateParams = {
                file: payload.file,
                fileUrl: payload.fileUrl,
                data: payload.message,
                parentMessageId: payload.parentMessageId,
                customType: payload.customType,
            };
            const channel: GroupChannel = currentChannel.value;
            channel
                .sendFileMessage(params)
                .onSucceeded((result) => {
                    messageSent.value = result;
                    isSendingMessage.value = false;
                })
                .onFailed((err, message) => {
                    if (!(err instanceof SendbirdError)) return;
                    if (
                        ![SendbirdErrorCodes.CONNECTION_REQUIRED, SendbirdErrorCodes.CONNECTION_CANCELED].includes(
                            err.code,
                        )
                    ) {
                        logger('SEND_FILE_MESSAGE', err);
                        isSendingMessage.value = false;
                        return;
                    }
                    reconnectToServer();
                });
        } catch (err) {
            logger('SEND_FILE_MESSAGE');
        }
    };

    const getStaticMap = async (latitude: string, longitude: string): Promise<File> => {
        try {
            const _baseUrl = 'https://maps.googleapis.com/maps/api/staticmap?';
            const _center = `${longitude},${latitude}`;
            const _zoom = 16;
            const _size = '300x150';
            const _marker = `color:0xff9012%7C${_center}`;
            const _styleRoad = 'feature:road|visibility:on';
            const _styleLocalRoad = 'feature:road.local|visibility:on';
            const _stylePoi = 'feature:poi|visibility:off';
            const _url = `${_baseUrl}center=${_center}&zoom=${_zoom}&size=${_size}&markers=${_marker}&style=${_styleRoad}&style=${_styleLocalRoad}&style=${_stylePoi}&key=${process.env.VUE_APP_API_GOOGLE_KEY}`;
            const response = await fetch(_url);
            const data = await response.blob();
            const metadata = {
                type: 'image/jpeg',
            };
            return new File([data], 'location.jpg', metadata);
        } catch (err) {
            logger('GET_STATIC_MAP', err);
        }
    };

    const prepareChat = async (): Promise<void> => {
        inChat.value = true;
        return currentChannel.value?.markAsRead();
    };

    const setMessageListener = async () => {
        if (!isConnected.value) await connectUserToSendBird();
        const channelHandler: GroupChannelHandler = new GroupChannelHandler({
            onMessageReceived: (channel: BaseChannel, message: BaseMessage) => {
                if (!inChat.value || currentChannel.value?.url != channel.url) {
                    messagesUnreadCount.value++;
                    if (notifier && (message instanceof UserMessage || message instanceof FileMessage)) {
                        const data: NotificationPayload = {
                            type: 'CHAT',
                            canTouch: true,
                            timeout: 5000,
                            grouped: message.channelUrl,
                            actions: {
                                click: () => {
                                    $store.commit('orders/isSelectedOrder', true);
                                    $router.push({
                                        name: appRoutesMap.OrderMap.ChatTab,
                                        params: {
                                            orderId: message.channelUrl,
                                        },
                                    });
                                },
                                close: () => {},
                            },
                            title: $t('txt.chat__notification-title'),
                            body: '',
                            image: {
                                src: message.sender.plainProfileUrl,
                                alt: message.sender.nickname,
                            },
                        };
                        if (message instanceof UserMessage) {
                            data.body = message?.message ?? message.data;
                        }
                        if (message instanceof FileMessage) {
                            if (message.customType === 'location') data.body = 'Te ha enviado una ubicacion';
                            else data.body = message.data ? message.data : 'Ha enviado un archivo';
                        }
                        notifier(data);
                    }
                } else if (inChat.value && currentChannel.value?.url == channel.url) {
                    currentChannel.value.markAsRead();
                    messageReceived.value = message;
                }
                const index = groupChannels.value.findIndex((element) => element.url == channel.url);
                if (index !== -1) groupChannels.value[index] = channel as GroupChannel;
                else groupChannels.value.push(channel as GroupChannel);
            },
            onChannelFrozen: (channel) => {
                const index = groupChannels.value.findIndex((element) => element.url == channel.url);
                if (index !== -1) groupChannels.value[index] = channel as GroupChannel;
                if (currentChannel.value?.url !== channel.url || !inChat.value) return;
                currentChannel.value = channel as GroupChannel;
                isChatClosed.value = true;
            },
        });

        sendBirdInstance?.groupChannel?.addGroupChannelHandler('channel_list_view', channelHandler);
    };

    return {
        inChat,
        isConnected,
        orderUnreadMessageCount,
        connectUserToSendBird,
        messagesUnread,
        loadChannelList,
        enterToChat,
        initChat,
        connectUser,
        isChatClosed,
        verifyMembers,
        loadMessages,
        isSendingMessage,
        sendTxtMessage,
        sendFileMessage,
        getStaticMap,
        shopper,
        currentChannel,
        isLastPage,
        messageSent,
        messageReceived,
        isLoadingMessages,
        prepareChat,
        messagesUnreadCount,
        groupChannels,
    };
};
