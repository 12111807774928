class PaymentMethodElementModel {
    readonly amount: number;
    readonly name: string;

    constructor(amount: number, name?: string) {
        this.amount = amount;
        this.name = name;
    }
}

const toPaymentMethodElementModel = (data: object): PaymentMethodElementModel => {
    return data ? new PaymentMethodElementModel(+data['amount'], data['name'] ?? '') : null;
};
export { PaymentMethodElementModel, toPaymentMethodElementModel };
