import { mapGetters } from 'vuex';
import { logger } from './logger';

export default {
    computed: {
        ...mapGetters({ isBannerInSection: 'promotions/isBannerInSection', banersGoal: 'promotions/bannerGoal' }),
    },
    methods: {
        async fetchDiscountActivationProgressBar(): Promise<void> {
            try {
                if (
                    this.bannerGoal?.actualAmount === this.bannerGoal?.minimumAmount &&
                    this.bannerGoal?.actualAmount > 0
                )
                    return;
                const bannerGoal = await this.$store.dispatch('promotions/bannersDiscountGoal', {
                    id: this.$route.params.id,
                    isBannerInSection: this.isBannerInSection,
                });
                this.$store.commit('promotions/bannerGoal', bannerGoal);
            } catch (err) {
                logger('ERROR', err);
                this.$store.commit('promotions/bannerGoal', undefined);
            }
        },
    },
};
