<template>
    <section class="flex-column">
        <search
            v-if="data?.length || query?.length"
            class="search-bills-and-documents"
            :is-for-search-bills="true"
            :placeholder="$t('txt.bills-documents__search-bills')"
            @on-change-value="searchByOrder"
            @on-clean-query="onClean"
        />
        <section-header
            class="bills-section-header"
            :has-back="true"
            :title="$t('general.bill')"
            @on-back="$router.back()"
        />
        <base-card id="bills-and-documents-base" class="bills-base-card">
            <bills-table v-if="data?.length" :bills-file="data" is-from-user-menu />
            <no-content v-else :title="$t('txt.profile-bills__no-content')" color-title="#C1C1C1">
                <icon-bills-generated :size="5" color="#C1C1C1" />
            </no-content>
            <base-loading v-if="requestState === 'LOADING'" />
            <div v-if="requestState === 'NONE'" ref="containerBills"></div>
        </base-card>
    </section>
</template>

<script lang="ts" setup>
import { logger } from '@/utils/logger';

import BaseCard from '@/components/cards/BaseCard.vue';
import BillsTable from '@/components/tables/BillsTable.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import IconBillsGenerated from '@/components/icons/IconBillsGenerated.vue';
import Search from '@/components/inputs/search/Search.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import { useBillStore } from '@/store/useBillStore';
import { ref } from 'vue';
import { usePagination } from '@/modules/core/components/paginatinos/usePagination';
import { InvoiceDocument } from '@/models/BillModels';

defineOptions({
    name: 'BillsAndDocument',
});

const $billStore = useBillStore();
const query = ref<string>();

const documentsAndBills = async () => {
    try {
        if (requestState.value == 'LOAD-ENDED') return;
        requestState.value = 'LOADING';
        const { next, results } = await $billStore.getBillingFilesInformation({
            page: page.value,
            order_id: query.value?.length ? query.value : undefined,
        });
        data.value = results;
        if (!next) {
            requestState.value = 'LOAD-ENDED';
            $stopIntersection();
            return;
        }
        page.value++;
        requestState.value = 'NONE';
    } catch (e) {
        logger('DOCS_AND_BILLS', e);
        requestState.value = 'LOAD-ENDED';
    }
};

const {
    page,
    requestState,
    data,
    $reset,
    elementRef: containerBills,
    $stopIntersection,
} = usePagination<InvoiceDocument>({ onIntersect: documentsAndBills });

const searchByOrder = async (key: string): Promise<void> => {
    $reset();
    query.value = key?.trim();
    await documentsAndBills();
};

const onClean = async () => {
    $reset();
    query.value = '';
    await documentsAndBills();
};

documentsAndBills();
</script>

<style lang="scss" scoped>
@import './bills-and-documents';
</style>
