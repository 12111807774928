<script lang="ts" setup>
import { BannerModel } from '@/models/promotions/banners/BannerModel';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { usePromotion } from '@/composables/usePromotion';
import type { PropType } from 'vue';
import { computed, ref } from 'vue';
import { useApp } from '@/composables/useApp';
import { RequestState } from '@/modules/core/types/WorkingState';

const props = defineProps({
    isBannerInSectionProp: {
        type: Boolean,
        default: false,
    },
    banner: {
        type: Object as PropType<BannerModel>,
        required: true,
    },
});

const { goToPromoProductsScreen } = usePromotion();
const { $store } = useApp();
const state = ref<RequestState>('NONE');
const bannerImg = computed(() => props.banner.picture ?? '');

const goToBanner = async (banner: BannerModel): Promise<void> => {
    if (state.value === 'LOADING') return;
    state.value = 'LOADING';
    $store.commit('promotions/isBannerInSection', props.isBannerInSectionProp);
    await goToPromoProductsScreen(banner);
    state.value = 'LOAD-ENDED';
};
</script>

<template>
    <div class="promotion" :class="{ promotion__mini: isBannerInSectionProp }">
        <img
            v-if="bannerImg"
            class="promotion__img"
            :class="{ 'promotion__img-mini': isBannerInSectionProp }"
            :alt="banner?.title"
            :src="bannerImg"
            loading="lazy"
        />
        <div class="card-overlay">
            <p class="card-overlay__txt" :class="{ 'card-overlay__txt-minibanners': isBannerInSectionProp }">
                {{ banner?.title }}
            </p>
            <btn-solid
                class="card-overlay__btn cursor-pointer"
                :is-loading="state === 'LOADING'"
                :txt="$t('cta.see-more')"
                @click="goToBanner(banner)"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import './the-promotions-banner.scss';
</style>
