<template>
    <base-icon
        :icon-name="$t('icon.online_bank')"
        :width="width"
        :height="height"
        viewBox="0 0 25 28"
        :iconColor="color"
    >
        <path
            d="M25 13.5714C25 17.7068 21.7155 21.0714 17.6786 21.0714C13.6417 21.0714 10.3572 17.7068 10.3572 13.5714C10.3572 9.43606 13.6417 6.07144 17.6786 6.07144C21.7155 6.07144 25 9.43606 25 13.5714ZM17.1154 12.2318C17.1154 11.9526 17.2889 11.7587 17.4443 11.7587H19.0866C19.5529 11.7587 19.9314 11.3711 19.9314 10.8934C19.9314 10.4157 19.5529 10.028 19.0866 10.028H18.5234V9.82144C18.5234 9.34375 18.1449 8.95606 17.6786 8.95606C17.2123 8.95606 16.8338 9.34375 16.8338 9.82144V10.1422C16.0206 10.4261 15.4259 11.2499 15.4259 12.2318C15.4259 13.448 16.3315 14.4368 17.4443 14.4368H17.914C18.0695 14.4368 18.2429 14.6318 18.2429 14.9111C18.2429 15.1903 18.0695 15.3841 17.914 15.3841H16.2706C15.8043 15.3841 15.4259 15.7718 15.4259 16.2495C15.4259 16.7272 15.8043 17.1149 16.2706 17.1149H16.8338V17.3214C16.8338 17.7991 17.2123 18.1868 17.6786 18.1868C18.1449 18.1868 18.5234 17.7991 18.5234 17.3214V17.0007C19.3366 16.7168 19.9314 15.893 19.9314 14.9111C19.9314 13.6949 19.0257 12.7061 17.9129 12.7061H17.4432C17.2889 12.7061 17.1154 12.5111 17.1154 12.2318Z"
            :fill="color ? color : '#FF9012'"
        />
        <path
            d="M15.9375 4.11667V3.39286C15.9375 2.77083 15.4252 2.2619 14.7991 2.2619H14.2299C13.6038 2.2619 13.0915 2.77083 13.0915 3.39286C13.0915 4.01488 12.5792 4.52381 11.9531 4.52381H6.26116C5.63504 4.52381 5.12277 4.01488 5.12277 3.39286C5.12277 2.77083 4.61049 2.2619 3.98437 2.2619H3.41518C2.78906 2.2619 2.27679 2.77083 2.27679 3.39286V23.75C2.27679 24.372 2.78906 24.881 3.41518 24.881H14.7991C15.4252 24.881 15.9375 24.372 15.9375 23.75V23.0375C16.4953 23.128 17.0645 23.1845 17.6451 23.1845C17.8386 23.1845 18.0208 23.1845 18.2143 23.1732V23.75C18.2143 25.6161 16.6775 27.1429 14.7991 27.1429H3.41518C1.53683 27.1429 0 25.6161 0 23.75V3.39286C0 1.52679 1.53683 0 3.41518 0H14.7991C16.6775 0 18.2143 1.52679 18.2143 3.39286V3.96964C18.0208 3.95833 17.8386 3.95833 17.6451 3.95833C17.0645 3.95833 16.4953 4.01488 15.9375 4.11667Z"
            :fill="color ? color : '#FF9012'"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-online-bank-services',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 25 * this.size;
        },
        height(): number {
            return 28 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
