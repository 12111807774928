import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6746b6a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nequi-payment" }
const _hoisted_2 = { class: "nequi-payment__title" }
const _hoisted_3 = { class: "nequi-payment__description" }
const _hoisted_4 = { class: "nequi-payment__content" }
const _hoisted_5 = {
  class: "number-box",
  type: "text"
}
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "nequi-payment__phone-numbers-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_nequi = _resolveComponent("icon-nequi")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('txt.payment-nequi__title')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('txt.payment-nequi__description')), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString($data.phoneCode), 1),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.inputNumber) = $event)),
        class: "nequi-payment__input number-box",
        placeholder: $options.inputPlaceholder,
        type: "text",
        onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onKeyPress && $options.onKeyPress(...args)))
      }, null, 40, _hoisted_6), [
        [_vModelText, $data.inputNumber]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('txt.payment-nequi__phone-numbers-list')), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.phoneNumbersArray, (phoneNumber) => {
        return (_openBlock(), _createBlock(_component_base_card_flat, {
          key: phoneNumber,
          class: "nequi-payment__list-item",
          "border-color": phoneNumber == $data.selectedNumber ? '#FF9012' : '#C7C7C7',
          onClick: ($event: any) => ($options.onOptionSelected(phoneNumber))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_nequi, {
              class: "nequi-payment__icon",
              color: phoneNumber == $data.selectedNumber ? '#FF9012' : '#606060',
              size: 1.1
            }, null, 8, ["color"]),
            _createTextVNode(" " + _toDisplayString(phoneNumber), 1)
          ]),
          _: 2
        }, 1032, ["border-color", "onClick"]))
      }), 128))
    ], 512), [
      [_vShow, $options.isPhoneNumberListNotEmpty]
    ])
  ]))
}