<template>
    <div class="alert-pdf__file">
        <PdfFrame class="pdf__content" :src="currentPrime?.termsAndConditionsFile" />
    </div>
    <section-footer v-if="!onlyView" class="alert-pdf__footer" :with-shadow="false">
        <btn-solid :is-loading="isLoading" :txt="$t('txt.prime__alert-terms-accepted')" @click="confirm" />
        <btn-out-line :is-disabled="isLoading" :txt="$t('cta.cancel')" bg-color="#FFF" color="#FF9012" @click="deny" />
    </section-footer>
    <snack-bar
        :body="snackBarMessage"
        :is-active="showSnackBar"
        :is-failure="isFailure"
        :is-success="isSuccess"
        @on-snackbar-close="$router.push({ name: appRoutesMap.tiptiCardRoutes.history })"
    />
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import { logger } from '@/utils/logger';
import { TiptiCardDealsModel } from '@/models/tiptiCard/TiptiCardDealsModel';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import { HandlerServerError } from '@/utils/handleServerError';
import PdfFrame from '@/modules/core/components/pdf/PdfFrame.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'AlertTermsAndConditions',
    components: {
        PdfFrame,
        SnackBar,
        BtnOutLine,
        BtnSolid,
        SectionFooter,
    },

    props: {
        hasBackground: {
            type: Boolean,
            default: true,
        },
        url: {
            type: String,
        },
        isTrial: {
            type: Boolean,
            default: false,
        },
        onlyView: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            acceptTerms: false,
            isLoading: false,
            isSuccess: false,
            isFailure: false,
            snackBarMessage: '',
            showSnackBar: false,
            appRoutesMap,
        };
    },
    computed: {
        ...mapGetters({
            defaultPrime: 'tiptiCard/defaultPrime',
            profile: 'user/user',
            primeSelected: 'tiptiCard/primeSelected',
        }),
        currentPrime(): TiptiCardDealsModel {
            return this.primeSelected ?? this.defaultPrime;
        },
    },
    methods: {
        ...mapActions({
            fetchProfileData: 'user/getProfile',
        }),
        deny(): void {
            this.$store.dispatch('segment/tcAccepted', {
                user: {
                    user_id: this.profile.userId,
                    client_id: this.profile.id,
                    email: this.profile.email,
                    first_name: this.profile.name,
                    last_name: this.profile.lastname,
                },
                terms_and_conditions: {
                    client_accepted: false,
                    terms_type: this.currentPrime?.termsAndConditionsFile,
                },
            });
            this.onClose();
        },
        onClose() {
            this.$emit('on-close');
        },
        async confirm(): Promise<void> {
            try {
                this.$store.dispatch('segment/tcAccepted', {
                    user: {
                        user_id: this.profile.userId,
                        client_id: this.profile.id,
                        email: this.profile.email,
                        first_name: this.profile.name,
                        last_name: this.profile.lastname,
                    },
                    terms_and_conditions: {
                        client_accepted: true,
                        terms_type: this.currentPrime?.termsAndConditionsFile,
                    },
                });
                this.isLoading = true;
                await this.$store.dispatch('tiptiCard/buyTiptiPrime', this.currentPrime?.id);
                try {
                    await this.fetchProfileData();
                } catch (error) {
                    logger(error);
                }
                this.isSuccess = true;
                this.snackBarMessage = this.$t('txt.tipti-prime__buy-success');
                this.showSnackBar = true;
            } catch (err) {
                this.$emit('snack-bar-data', {
                    msg: HandlerServerError(
                        err,
                        {
                            429: this.$t('txt.checkout__order-too_many_purchases'),
                            400: this.$t('general.transaction-error'),
                        },
                        this,
                    ),
                    success: false,
                    failed: true,
                });
                this.onClose();
                logger(err);
            } finally {
                this.isLoading = false;
            }
        },
    },
    emits: ['snack-bar-data', 'on-close', 'on-confirm', 'close'],
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.alert-pdf {
    &__file {
        padding: $padding-sm;
        border: solid 1px $txt;
        overflow: auto;
        @include tablet {
            height: 60vh;
        }
    }

    &__fooqwter {
        display: grid;
        grid-template-columns: auto;
        grid-gap: $padding-sm;
        flex-direction: column;
        margin: $margin-sm;
        justify-content: center;
    }

    &__footer {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        display: grid;
        grid-template-columns: auto;
        grid-gap: $padding-sm;
        flex-direction: column;
        margin: $margin-sm;
        justify-content: center;
        @include tablet {
            margin: $margin-none;
        }
    }
}

.pdf__content {
    height: 60vh;
    @include tablet {
        height: 90vh;
    }
}
</style>
