<template>
    <section class="dotted-rectangle">
        <div></div>
        <p class="dotted-rectangle__txt">{{ buttonText }}</p>
        <btn-icon height="1.5rem" width="1.5rem"><p class="text--white">+</p></btn-icon>
    </section>
</template>
///
<script>
import BtnIcon from '@/components/buttons/BtnIcon.vue';

export default {
    components: {
        BtnIcon,
    },
    props: {
        buttonText: {
            type: String,
            required: true,
        },
    },
    emits: ['on-pressed'],
};
</script>

<style lang="scss" scoped>
@import './dotted-rectangle';
</style>
