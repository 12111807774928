<template>
    <aside class="actions-quantity">
        <btn-circular
            v-if="remove"
            :class="{
                'actions-quantity--small': isFromAutocomplete,
            }"
            :is-loading="isLoadingDelete"
            bg-color="#FFF"
            loading-size=".5rem"
            padding="0 .5rem"
            @click="deleteProduct"
        >
            <icon-trash class="actions_svg" :color="isMiniature ? '#FFF' : '#707070'" :size="1.5" />
        </btn-circular>
        <btn-circular
            v-else
            class="actions-quantity--shadow"
            :class="{
                'actions-quantity--small': isFromAutocomplete,
            }"
            bg-color="#FFF"
            padding="0 .5rem"
            @click="decrement"
        >
            <icon-decrement class="actions_svg" :size="1" color="#707070" />
        </btn-circular>
        <div class="input__container">
            <input
                v-model.number="quantity"
                class="actions-quantity__input"
                type="number"
                @blur="onEnter"
                @keyup.enter="onEnter"
            />
        </div>
        <btn-circular
            class="actions-quantity--shadow"
            :class="{
                'actions-quantity--small': isFromAutocomplete,
            }"
            bg-color="#FFF"
            padding="0 .5rem"
            @click="increment"
        >
            <icon-increment class="actions_svg" :size="1" color="#707070" />
        </btn-circular>
    </aside>
</template>

<script lang="ts">
import { ProductModel } from '@/models/product/ProductModel';
import { mapGetters } from 'vuex';

import BtnCircular from '@/components/buttons/BtnCircular.vue';
import IconIncrement from '@/components/icons/IconIncrement.vue';
import IconDecrement from '@/components/icons/IconDecrement.vue';
import IconTrash from '@/components/icons/IconTrash.vue';
import fetchDiscountActivationProgressBar from '@/utils/discountActivationProgressBarUtils';
import { isMobileBrowser } from '@/utils/utils';
import { ListDetailItemModel } from '@/models/list/ListDetailModel';
import { useExtraItemHandleEditProduct } from '@/modules/extraItems/composables/useExtraItemHandleProduct';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useFicoaProducts } from '@/modules/catalogs/composables/useFicoaProducts';
import { PropType } from 'vue';
import { notifierKey } from '@/modules/notification/useNotifier';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/store/useProductStore';

export default {
    name: 'ActionsQuantity',
    components: {
        BtnCircular,
        IconIncrement,
        IconDecrement,
        IconTrash,
    },
    mixins: [fetchDiscountActivationProgressBar],
    inject: {
        notifier: {
            from: notifierKey,
        },
    },
    props: {
        productData: {
            type: Object as PropType<ProductModel>,
            required: true,
        },
        /// ** fetchCart will only work in conjuntion with [retailerIdsWithtimeDelivery]
        mustFetchCart: {
            type: Boolean,
            default: false,
        },
        actualQuantity: {
            type: [String, Number],
        },
        isFromAutocomplete: {
            type: Boolean,
            default: false,
        },
        section: {
            type: String,
            default: undefined,
        },
        isMiniature: {
            type: Boolean,
            default: false,
        },
        isCatalogFicoa: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'on-delete', 'on-update', 'on-close'],
    data() {
        return {
            timer: undefined,
            quantity: this.actualQuantity ?? 1,
            product: this.productData,
            isLoading: false,
            isLoadingDelete: false,
        };
    },
    computed: {
        ...mapGetters({
            products: 'cart/products',
            dataOrder: 'orders/orderDetailSelected',
            isFromBannerInSection: 'promotions/isBannerInSection',
            retailerIdsWithtimeDelivery: 'checkout/retailerIdsWithtimeDelivery',
            isCategoryFromList: 'lists/isCategoryFromList',
            listIdToAddFromCategories: 'lists/listIdToAddFromCategories',
            list: 'lists/list',
            listDetail: 'lists/listDetail',
            bannerGoal: 'promotions/bannerGoal',
            listCart: 'lists/localCart',
            isAuth: 'user/isAuth',
        }),
        remove() {
            return +this.quantity <= 1;
        },
        isMobile() {
            return isMobileBrowser();
        },
    },
    watch: {
        quantity(newVal, _): void {
            if (!newVal) return this.deleteProduct();
            this.$emit('on-update', newVal);
        },
    },
    setup(props) {
        const { isExtraItemCart } = useCartType();
        const { deletedProduct } = useExtraItemHandleEditProduct();
        const { updateFicoaProduct } = useFicoaProducts(props.productData);
        const { isHighDemand, isOutOfStock, lastItemAdded } = storeToRefs(useProductStore());
        return {
            isExtraItemCart,
            deletedProduct,
            updateFicoaProduct,
            isHighDemand,
            isOutOfStock,
            lastItemAdded,
        };
    },
    methods: {
        async deleteProduct(): Promise<void> {
            try {
                if (this.isLoadingDelete) return;
                this.isLoadingDelete = true;
                if (this.isCatalogFicoa) return this.updateFicoaProduct(0);
                if (this.isExtraItemCart) return this.deletedProduct(this.product);
                if (this.isCategoryFromList) {
                    this.$store.commit('lists/removeProductList', this.product);
                    const productsFromServer: Array<ListDetailItemModel> = this.listDetail;
                    const productInList = productsFromServer?.find((element) => element?.id === this.product.id);
                    if (!productInList) return;
                    this.$store.dispatch('lists/deleteProductList', productInList.listItemId);
                    return this.$store.commit(
                        'lists/listDetail',
                        productsFromServer?.filter((element) => element.listItemId !== productInList.listItemId),
                    );
                }
                if ((this.isHighDemand || this.isOutOfStock) && this.lastItemAdded) {
                    this.$store.commit('cart/removeProductFromProducts', this.lastItemAdded);
                    const resultProduct = this.products.find((item) => item.id === this.lastItemAdded.id);
                    if (!resultProduct) this.lastItemAdded = undefined;
                    return;
                }
                this.$store.commit('cart/removeProductFromProducts', this.product);
                await this.$store.dispatch('cart/deleteProduct', {
                    product: this.productData,
                    screen: this.$route.path,
                    section: this.section,
                });
                if (this.fetchCart)
                    this.$store.dispatch('cart/fetchCart', { retailerIds: this.retailerIdsWithtimeDelivery });
                else this.$store.commit('cart/removeProductFromProducts', this.product);
                if (this.bannerGoal) await this.fetchDiscountActivationProgressBar();
            } catch (err) {
                this.notifier({
                    type: 'ERROR',
                    body: this.$t('alerts.product_remove_error'),
                });
            } finally {
                this.isLoadingDelete = false;
                this.$emit('on-delete');
            }
        },
        async increment(): Promise<void> {
            if (this.isLoading || this.isLoadingDelete) return;
            if (!this.isAuth && !this.isCatalogFicoa) return this.$store.commit('user/requireAuth', true);

            this.quantity++;
            this.$emit('on-update', this.quantity);
        },
        async decrement(): Promise<void> {
            if (this.isLoading) return;
            this.quantity--;
            this.$emit('on-update', this.quantity);
        },
        async onEnter(): Promise<void> {
            this.$emit('on-update', this.quantity);
            this.$emit('on-close');
        },
    },
};
</script>
<style lang="scss" scoped>
@import './actions-quantity.scss';
</style>
