import { OnlineBankServiceModel } from '@/models/paymentMethods/OnlineBankServiceModel';
import { CashPaymentModel, CashWirePaymentModel } from '@/models/checkout/CashWirePaymentModel';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            cart: 'cart/cart',
            paymentsToShow: 'payments/paymentsToShow',
            isMixPaymentTiptiCard: 'payments/isMixPaymentTiptiCard',
            baseConfiguration: 'user/baseConfiguration',
            institutionPayment: 'payments/institutionPayment',
            tiptiCard: 'tiptiCard/tiptiCard',
            paymentId: 'payments/paymentIdSelected',
            isTiptiCardPayment: 'payments/isTiptiCardPayment',
            onlineBankServices: 'payments/onlineBankServicePayment',
            cashPayment: 'payments/cashWirePayment',
        }),
    },
    methods: {
        checkTiptiCardUsage(): boolean {
            return !!(
                !this.isTiptiCardPayment &&
                +this.tiptiCard?.availableAmount > 0 &&
                !this.institutionPayment?.id &&
                //// ** Check if difference can be paid with credit card / payphone
                !(
                    +this.tiptiCard?.availableAmount < +this.cart?.totalDetails?.totalCost &&
                    this.paymentIdSelected &&
                    this.difference(+this.tiptiCard?.availableAmount, +this.cart?.totalDetails?.totalCost) < 1
                ) &&
                this.timeDelivery.length
            );
        },
        difference(value: number, secondValue: number): number {
            return +Math.abs(value - secondValue).toFixed(2);
        },
        setPaymentWithTiptiCard() {
            const difference: number = this.difference(
                +this.cart?.totalDetails?.totalCost,
                +this.tiptiCard?.availableAmount,
            );
            if (difference < this.baseConfiguration?.deferredMinimumAmount && this.deferred) {
                this.titleBlockingAlert = this.$t('txt.credit-card-deferred__not-allowed');
                this.messageBlockingAlert = this.$t('txt.credit-card-deferred__under-limit', {
                    currency: this.currency,
                    amount: this.baseConfiguration?.deferredMinimumAmount,
                });
                this.showBlockingAlert = true;
                return;
            }
            const paymentDefault = this.paymentsToShow[0];
            const paymentId = this.paymentId;
            if (+this.tiptiCard?.availableAmount < +this.cart?.totalDetails?.totalCost) {
                this.$store.commit('payments/isMixPaymentTiptiCard', true);
                this.$store.commit('payments/secondPayment', difference);
            } else {
                this.$store.commit('payments/cleanPaymentMethods');
            }
            this.$store.commit('payments/isTiptiCardPayment', true);
            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-tipti-card',
                    amount: difference > 0 ? +this.tiptiCard?.availableAmount : +this.cart?.totalDetails?.totalCost,
                    data: this.tiptiCard.id,
                    analyticData: {
                        tipticard_current_available_amount: this.tiptiCard?.availableAmount,
                    },
                    objectData: ['payment-tipti-card', this.tiptiCard.id],
                }),
            );
            if (
                paymentDefault &&
                +this.tiptiCard?.availableAmount < +this.cart?.totalDetails?.totalCost &&
                difference > 1
            ) {
                this.$store.commit('payments/paymentsToShow', paymentDefault);
                this.$store.commit('payments/paymentIdSelected', paymentId);
                this.checkPaymentOnlineBankService(paymentDefault);
                this.checkPaymentCash(paymentDefault);
            }
        },
        checkPaymentOnlineBankService(paymentDefault: PaymentToShowModel): void {
            if (paymentDefault.name !== 'payment-online-bank-services') return;
            const _amount = +this.cart?.totalDetails?.totalCost - +this.tiptiCard?.availableAmount;
            this.$store.commit(
                'payments/onlineBankServicePayment',
                new OnlineBankServiceModel(this.onlineBankServices?.accountId, _amount),
            );
        },
        checkPaymentCash(paymentDefault: PaymentToShowModel): void {
            if (paymentDefault.name !== 'payment-cash') return;
            const _amount = +this.cart?.totalDetails?.totalCost - +this.tiptiCard?.availableAmount;
            this.$store.commit(
                'payments/cashWirePayment',
                new CashWirePaymentModel(
                    new CashPaymentModel(
                        _amount,
                        this.cashPayment?.cash?.cashAmount,
                        this.cashPayment?.cash?.needChange,
                    ),
                    null,
                ),
            );
        },
    },
};
