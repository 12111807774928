<template>
    <section class="payment__card">
        <aside class="card-payment__content" :class="{ 'card-payment__content--columns': paymentsToShow?.length > 1 }">
            <div>
                <h2>{{ $t(`general['${payment.name}']`) }}</h2>
                <div v-if="payment.name === 'payment-cards'" class="credit-card-tile">
                    <img
                        v-if="payment?.icon?.length"
                        class="credit-card-tile__logo"
                        :src="payment.icon"
                        :style="{ 'background-color': payment.backgroundColor ?? '#606060' }"
                        alt=""
                        @error="displayDefaultImg"
                    />
                    <aside class="credit-card-tile__data">
                        <div class="credit-card-tile__data-row">
                            <div v-for="index in 12" :key="index" class="credit-card-hidden">
                                <div class="credit-card-tile__hidden-dot" :class="spaceDots(index)"></div>
                            </div>
                            <p>{{ payment.data }}</p>
                        </div>
                        <div class="credit-card__deferred">
                            <p v-if="!deferred">{{ $t("general['payment-current']") }}</p>
                        </div>
                    </aside>
                </div>
                <p v-else-if="payment.name === 'payment-tipti-card'">
                    {{ tiptiCard?.cardNumber ?? '' }}
                </p>
                <p v-else class="payment-name">{{ payment.data }}</p>
            </div>
            <h3 v-if="paymentsToShow?.length > 1" v-currency="paymentAmount" />
            <h3
                v-if="paymentsToShowGiftCard?.length && paymentsToShow?.length === 1"
                v-currency="paymentsToShow.at(0).amount"
            />
        </aside>
        <button v-if="showButton" class="payment__card-button" @click="$emit('on-change-selected')">
            {{ $t("cta['change']") }}
        </button>
    </section>
    <deferred-option v-if="showDeferredSelector" :is-modal="false" />
</template>
<script lang="ts" setup>
import { useImage } from '@/composables/ImageComposable';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import DeferredOption from '@/components/alerts/alertDeferred/DeferredOption.vue';
import type { PropType } from 'vue';
import { computed } from 'vue';
import { usePayments } from '@/composables/usePayments';
import { useOrder } from '@/composables/useOrder';

const props = defineProps({
    payment: {
        type: Object as PropType<PaymentToShowModel>,
        required: true,
    },
    showButton: {
        type: Boolean,
        default: false,
    },
});
const { displayDefaultImg } = useImage();
const {
    paymentsToShow,
    deferred,
    tiptiCard,
    differenceToPay,
    deferredOptions,
    paymentsToShowGiftCard,
    institutionalPaymentToShow,
} = usePayments();
const { orderRejected } = useOrder();

const $emit = defineEmits<{ (e: 'show-deferred-selector', value: boolean): void; (e: 'on-change-selected'): void }>();
const spaceDots = (index: number): string => {
    return index === 3 || index === 7 || index == 11 ? 'credit-card-tile--space' : '';
};

const paymentAmount = computed(() => {
    if (props.payment.name === 'payment-tipti-card') return +tiptiCard.value?.availableAmount;
    if (props.payment.name === 'payment-institutional') return institutionalPaymentToShow.value;
    return differenceToPay.value;
});

const showDeferredSelector = computed(() => {
    const canShow =
        props.payment.name === 'payment-cards' && deferredOptions.value?.length && !orderRejected.value?.orderId;
    $emit('show-deferred-selector', canShow);
    return canShow;
});
</script>
<style lang="scss" scoped>
@import '../../menu-checkout.scss';
@import 'payment-to-show-tile.scss';
</style>
