<template>
    <base-icon :icon-name="$t('icon.success')" :width="width" :height="height" viewBox="0 0 55 55" :iconColor="color">
        <path
            d="M51.1285 14.1238L44.8887 10.5212L41.287 4.28235H34.0828L27.843 0.679688L21.6033 4.28235H14.3991L10.7964 10.5221L4.55774 14.1238V21.3281L0.955078 27.5678L4.55774 33.8075V41.0118L10.7975 44.6144L14.4001 50.8542H21.6044L27.8432 54.4559L34.0829 50.8532H41.2872L44.8898 44.6135L51.1296 41.0108V33.8066L54.7322 27.5669L51.1296 21.3271V14.1238H51.1285ZM25.4163 38.5728C24.9149 39.0742 24.2544 39.3179 23.5968 39.3087C22.9391 39.318 22.2788 39.0742 21.7774 38.5728L13.9136 30.709C12.9285 29.7239 12.9285 28.1259 13.9136 27.1408L15.9954 25.059C16.9805 24.0739 18.5785 24.0739 19.5636 25.059L23.5968 29.0923L36.1218 16.5663C37.1069 15.5813 38.705 15.5813 39.6901 16.5663L41.7718 18.6481C42.7569 19.6332 42.7569 21.2313 41.7718 22.2163L25.4163 38.5728Z"
            fill="white"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-check-success',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 55 * this.size;
        },
        height(): number {
            return 55 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
