<template>
    <div class="card" :style="styles">
        <slot></slot>
    </div>
</template>

<script lang="ts">
export default {
    name: 'BaseCard',
    props: {
        padding: {
            type: [String, Number],
            default: '1em',
        },
        borderRadius: {
            type: String,
            default: '11px',
        },
        bgColor: {
            type: String,
            default: '#FFF',
        },
        trailColor: {
            type: String,
        },
        borderColor: {
            type: String,
        },
    },
    computed: {
        styles(): object {
            return {
                padding: this.padding,
                'border-radius': this.borderRadius,
                'background-color': this.bgColor,
                border: this.borderColor ? `1px solid ${this.borderColor}` : 'none',
                'border-left': this.borderColor
                    ? `1px solid ${this.borderColor}`
                    : this.trailColor
                    ? `10px solid ${this.trailColor}`
                    : 'none',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_box-shadow.scss';

.card {
    display: flex;
    @include box-shadow;

    &--no-shadow {
        box-shadow: unset;
    }
}
</style>
