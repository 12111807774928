<template>
    <base-icon :height="height" :icon-name="label" :width="width" viewBox="0 0 30 30">
        <g fill="#FFF" filter="url(#filter0_d_3404_263753)">
            <circle :fill="color" cx="15" cy="14.5" r="12" />
            <circle cx="15" cy="14.5" r="11.7" stroke="#FF9012" stroke-width="0.6" />
        </g>
        <path
            d="M15.5421 13.6429V13.9579H15.8571H20.1429C20.442 13.9579 20.685 14.2008 20.685 14.5C20.685 14.7992 20.442 15.0421 20.1429 15.0421H15.8571H15.5421V15.3571V19.6429C15.5421 19.942 15.2992 20.185 15 20.185C14.7008 20.185 14.4579 19.942 14.4579 19.6429V15.3571V15.0421H14.1429H9.85714C9.55797 15.0421 9.315 14.7992 9.315 14.5C9.315 14.2008 9.55797 13.9579 9.85714 13.9579H14.1429H14.4579V13.6429V9.35714C14.4579 9.05797 14.7008 8.815 15 8.815C15.2992 8.815 15.5421 9.05797 15.5421 9.35714V13.6429Z"
            fill="#FF9012"
            stroke="#FF9012"
            stroke-width="0.63"
        />
        <defs>
            <filter
                id="filter0_d_3404_263753"
                color-interpolation-filters="sRGB"
                filterUnits="userSpaceOnUse"
                height="28.8"
                width="28.8"
                x="0.6"
                y="0.7"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="0.6" />
                <feGaussianBlur stdDeviation="1.2" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_3404_263753" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_3404_263753" mode="normal" result="shape" />
            </filter>
        </defs>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconAdd',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#ff8b07',
        },
        backgroundFill: {
            type: String,
            default: '#FFF',
        },
        iconName: {
            type: String,
            default: '',
        },
    },
    computed: {
        width(): number {
            return 30 * this.size;
        },
        height(): number {
            return 30 * this.size;
        },
        label(): string {
            return this.iconName ?? this.$t('cta.add');
        },
    },
};
</script>
