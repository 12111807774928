import { RemoteLocaleDto, RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';
import { CartRetailAnalyticInterface } from '@/models/cart/CartRetailerModel';

interface RetailerInterface {
    priceText?: RemoteLocaleDto;
    //*** this note title and placeholder being inside franchise
    shopperNoteTitle: RemoteLocaleDto;
    shopperNotePlaceholder: RemoteLocaleDto;
    store_name: string;
    franchisePriceTextColor?: string;
}

class RetailerModel implements Readonly<RetailerInterface> {
    id: number;
    readonly isPickupAvailable: boolean;
    retailerType: string;
    deliveryRange?: string;
    name: string;
    logo: string;
    icon: string;
    header: string;
    headerTablet: string;
    primaryColor: string;
    secondaryColor: string;
    franchise: RetailerModel;
    retailerTypes: Array<string>;
    readonly showAlertFirstTime: boolean;
    shopperNoteTitle: RemoteLocaleModel;
    shopperNotePlaceholder: RemoteLocaleModel;
    closeTime: string;
    enabledTimeAlertClose: boolean;
    timeAlertClose: number;
    priceText: RemoteLocaleModel;
    store_name: string;
    franchisePriceTextColor: string;

    // TODO: use RetailerInterface instead of direct properties
    //  try to adopt this approach
    constructor(
        id: number,
        isPickupAvailable: boolean,
        data: RetailerInterface,
        retailerType?: string,
        deliveryRange?: string,
        name?: string,
        logo?: string,
        icon?: string,
        header?: string,
        headerTablet?: string,
        primaryColor?: string,
        secondaryColor?: string,
        franchise?: RetailerModel,
        retailerTypes?: Array<string>,
        showAlertFirstTime?: boolean,
        closeTime?: string,
        enabledTimeAlertClose?: boolean,
        timeAlertClose?: number,
        franchisePriceTextColor?: string,
    ) {
        this.id = id;
        this.isPickupAvailable = isPickupAvailable;
        this.retailerType = retailerType;
        this.deliveryRange = deliveryRange;
        this.name = name;
        this.logo = logo;
        this.icon = icon;
        this.header = header;
        this.headerTablet = headerTablet;
        this.primaryColor = primaryColor;
        this.secondaryColor = secondaryColor;
        this.franchise = franchise;
        this.retailerTypes = retailerTypes;
        this.showAlertFirstTime = showAlertFirstTime;
        this.shopperNoteTitle = RemoteLocaleModel.fromJson(data?.shopperNoteTitle);
        this.shopperNotePlaceholder = RemoteLocaleModel.fromJson(data?.shopperNotePlaceholder);
        this.closeTime = closeTime;
        this.enabledTimeAlertClose = enabledTimeAlertClose;
        this.timeAlertClose = timeAlertClose;
        this.priceText = RemoteLocaleModel.fromJson(data?.priceText);
        this.store_name = data?.store_name ?? '';
        this.franchisePriceTextColor = data?.franchisePriceTextColor ?? '';
    }

    get toAnalytics(): CartRetailAnalyticInterface {
        return {
            retail: this.name,
            id: this.id,
            store_name: this.store_name,
        };
    }
}

const toRetailerModel = (data: object, retailerMinInfo?: object): RetailerModel => {
    if (!data) return null;
    return new RetailerModel(
        data['id'],
        data['pickup_available'] ?? false,
        {
            priceText: data['franchise_price_text'],
            shopperNoteTitle: data['shopper_note_title'],
            shopperNotePlaceholder: data['shopper_note_placeholder'],
            store_name: data['store_name'],
            franchisePriceTextColor: data['franchise_price_text_color'],
        },
        data['retailer_type'],
        data['delivery_range'],
        data['name'],
        retailerMinInfo ? retailerMinInfo['logo'] : data['logo'],
        retailerMinInfo ? retailerMinInfo['icon'] : data['icon'],
        data['header'],
        data['header_tablet'],
        retailerMinInfo ? retailerMinInfo['primary_color'] : data['primary_color'],
        retailerMinInfo ? retailerMinInfo['secondary_color'] : data['secondary_color'],
        toRetailerModel(data['franchise']),
        data['retailer_types'] ?? [],
        data['show_alert_first_time'] ?? false,
        data['close_time'],
        data['enabled_time_alert_close'] ?? false,
        data['time_alert_close'] ?? 0,
    );
};

export { RetailerModel, toRetailerModel };
