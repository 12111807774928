<template>
    <div
        ref="headerRef"
        class="subheader-separator"
        :class="{ 'subheader-separator--padding': hasPadding, 'subheader-separator--no-text': !name.length }"
    >
        <h2 class="subheader-separator__subtitle">{{ name }}</h2>
        <btn-text v-if="showBtn" :is-underline="false" :text="buttonTxt" @click="openSeeMore">
            <icon-right-arrow class="btn-icon" :size="0.75" color="#ff9012" />
        </btn-text>

        <IconCircularClose
            v-if="showBtnClose"
            class="cursor-pointer"
            :size="0.8"
            color="#606060"
            role="button"
            @click="$emit('on-close')"
        />
    </div>
</template>

<script lang="ts" setup>
import BtnText from '@/components/buttons/BtnText.vue';
import IconRightArrow from '@/components/icons/IconRightArrow.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import { computed, ref, watch } from 'vue';
import { useApp } from '@/composables/useApp';
import { useElementVisibility } from '@vueuse/core';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    showBtn: {
        type: Boolean,
        default: true,
    },
    btnName: {
        type: String,
        default: '',
    },
    hasPadding: {
        type: Boolean,
        default: false,
    },
    isDisabled: {
        type: Boolean,
        default: false,
    },
    showBtnClose: {
        type: Boolean,
        default: false,
    },
});

const { $t } = useApp();
const $emit = defineEmits<{
    (e: 'on-pressed'): void;
    (e: 'on-close'): void;
    (e: 'is-visible', value: boolean): void;
}>();
const headerRef = ref<HTMLElement>();

const isViable = useElementVisibility(headerRef);

watch(isViable, (value) => {
    setTimeout(() => {
        $emit('is-visible', value);
    }, 300);
});
const buttonTxt = computed(() => (props.btnName ? props.btnName : $t('cta.see-more')));
const openSeeMore = () => {
    if (props.isDisabled) return;
    $emit('on-pressed');
};
</script>

<style lang="scss" scoped>
@import './subheader-separator.scss';
</style>
