<template>
    <base-card
        class="dropdown-retailer-products __outlined"
        :bg-color="bgColor"
        :border-color="borderColor"
        :padding="0"
    >
        <header
            class="retailer-products__header"
            :class="{ 'retailer-products__header--cursor-default': !isRetailerAvailable }"
            :style="{ 'background-color': headerBackground }"
            @click="onHeaderPressed"
        >
            <aside class="header__retailer-column">
                <img v-if="retailer.logo?.length" :src="retailer.logo" alt="" @error="displayDefaultImg" />
                <div v-if="isScheduleSelected" class="retailer-column__data">
                    <h1 :style="{ color: headerFontColor }">{{ retailer.name }}</h1>
                </div>
                <p v-else-if="!isRetailerAvailable">{{ $t("txt['schedules__this-retailer-dont']") }}</p>
                <p v-else>{{ $t("txt['schedule__select-delivery-placeholder']") }}</p>
            </aside>
            <icon-arrow
                v-if="showProducts"
                class="header__arrow"
                :color="headerFontColor"
                :icon-name="$t('icon.hide')"
                :size="0.75"
            />
            <aside v-else class="header__products-resume-column" :class="{ 'half-opacity': !isScheduleSelected }">
                <div v-for="product in peekProducts" :key="product.id">
                    <img v-if="product.image?.medium" :src="product.image.medium" alt="" @error="displayDefaultImg" />
                </div>
                <h3 v-if="retailer.products.length > peekProducts.length" class="products-resume-column__indicator">
                    {{ `+${retailer.products.length - peekProducts.length}` }}
                </h3>
            </aside>
        </header>
        <products
            v-if="showProducts"
            :is-disabled-editing="true"
            :retailers="[retailer]"
            :show-delete="false"
            :show-header="false"
        />
    </base-card>
    <LoadPrescriptionItem
        v-if="hasItemsPrescription && isRetailerAvailable"
        :items-prescription="itemsPrescription"
        :retailer="retailer"
        @on-prescription="(val) => $emit('on-prescription', val)"
        @on-remove-prescription="(val) => $emit('on-remove-prescription', val)"
    />
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import { RetailerAvailabilityModel } from '@/modules/retailers/models/RetailerAvailabilityModel';
import BaseCard from '@/components/cards/BaseCard.vue';
import Products from '@/views/cart/components/products/Products.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { useImage } from '@/composables/ImageComposable';
import { useUser } from '@/composables/useUser';
import LoadPrescriptionItem from '@/views/checkout/components/itemPrescription/LoadPrescriptionItem.vue';
import { CartMedicalPrescription } from '@/models/cart/CartMedicalPrescription';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export default {
    name: 'DropdownRetailerProducts',
    components: {
        BaseCard,
        Products,
        IconArrow,
        LoadPrescriptionItem,
    },
    props: {
        retailer: {
            type: Object as PropType<CartRetailerModel>,
            required: true,
        },
        itemsPrescription: {
            type: Array as PropType<CartMedicalPrescription[]>,
            required: true,
        },
    },
    emits: ['on-prescription', 'on-remove-prescription'],
    setup() {
        const { displayDefaultImg } = useImage();
        const { currency } = useUser();
        const { retailersAvailability, originalRetailersAvailability } = useRetailer();
        return {
            displayDefaultImg,
            currency,
            retailersAvailability,
            originalRetailersAvailability,
        };
    },
    data() {
        return {
            showProducts: false,
        };
    },
    computed: {
        bgColor(): string {
            return this.isScheduleSelected ? '#FFF' : '#FED6D3';
        },
        ...mapGetters({
            timeDelivery: 'checkout/timeDelivery',
            stockItemTimeDelivery: 'checkout/stockItemTimeDelivery',
        }),
        borderColor(): string {
            return this.isScheduleSelected ? '#C7C7C7' : '#c90808';
        },
        headerBackground(): string {
            if (!this.showProducts) return 'transparent';
            return this.retailer.primaryColor ?? '#FFF';
        },
        headerFontColor(): string {
            if (!this.showProducts) return '#707070';
            return this.retailer.secondaryColor ?? '#707070';
        },
        isRetailerAvailable(): boolean {
            if (this.isOnlyProductTime && this.productWithTimeDelivery) return true;
            return (
                this.retailersAvailability.find(
                    (retailer: RetailerAvailabilityModel) => retailer.retailer_id == this.retailer.id,
                )?.delivery_data ?? false
            );
        },
        isRetailerCoverValue(): boolean {
            return this.getFinalPriceRetailer > this.retailer.minimumBuyPrice;
        },
        isScheduleSelected(): TimeDeliveryModel {
            if (this.isOnlyProductTime) return this.productWithTimeDelivery;
            return this.timeDelivery.find((time: TimeDeliveryModel) => time.retailerId == this.retailer.id);
        },
        peekProducts() {
            return this.retailer?.products.slice(0, 4) ?? [];
        },
        getFinalPriceRetailer(): number {
            return this.retailer?.products?.reduce(
                (accumulator, { finalPriceWithDiscount }) => accumulator + finalPriceWithDiscount,
                0,
            );
        },
        productWithTimeDelivery(): TimeDeliveryModel {
            return this.stockItemTimeDelivery.find((time: TimeDeliveryModel) => time.retailerId === this.retailer.id);
        },
        isOnlyProductTime(): boolean {
            return !this.originalRetailersAvailability.some((retailer) =>
                this.retailersAvailability.find(
                    (ret) => ret.retailer_id == retailer.retailer_id && this.retailer.id === ret.retailer_id,
                ),
            );
        },
        hasItemsPrescription(): CartMedicalPrescription | null {
            if (!this.itemsPrescription.length) return null;
            return this.itemsPrescription.find((item) => item.retailer_id === this.retailer.id);
        },
    },
    methods: {
        onHeaderPressed() {
            if (!this.isRetailerAvailable || !this.isRetailerCoverValue) return;
            if (!this.isScheduleSelected) return;
            this.showProducts = !this.showProducts;

            // todo temporally silent until new retail screen
            // if (this.isOnlyProductTime) return;
            // this.$router.push({
            //     path: '/checkout/hours',
            //     query: {
            //         retailerName: this.retailer.name,
            //         retailerId: this.retailer.id,
            //         retailerLogo: this.retailer.icon,
            //     },
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
@import './dropdown-retailer-products.scss';
</style>
