import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    height: $options.height,
    "icon-color": $props.color,
    "icon-name": _ctx.$t('icon.rate'),
    width: $options.width,
    viewBox: "0 0 17 15"
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        fill: $props.color,
        d: "M8.51709 0.516174L10.3132 6.04404H16.1255L11.4233 9.46045L13.2194 14.9883L8.51709 11.5719L3.81481 14.9883L5.61092 9.46045L0.908638 6.04404H6.72098L8.51709 0.516174Z"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["height", "icon-color", "icon-name", "width"]))
}