import { TaxModel, toTaxModel } from '../cart/TaxModel';

class CountriesModel {
    readonly id: number;
    readonly code: string;
    readonly alphaCode: string;
    readonly name: string;
    readonly currencySymbol: string;
    readonly currencyName: string;
    readonly language: string;
    readonly icon: string;
    readonly enable: boolean;
    readonly taxRate: string;
    readonly currencyType: string;
    readonly domain: string;
    readonly email: string;
    readonly address: string;
    readonly mainPhone: string;
    readonly secondPhone: string;
    readonly cellphone: string;
    readonly termsAndConditions: object;
    readonly taxes: Array<TaxModel>;
    readonly taxName: string;
    readonly serviceTaxPercent: number;
    readonly facebook: string;
    readonly instagram: string;
    readonly twitter: string;
    readonly linkedin: string;
    readonly location: string;
    readonly locationLatitude: string;
    readonly locationLongitude: string;

    constructor(
        id: number,
        code: string,
        alphaCode: string,
        name: string,
        currencySymbol: string,
        currencyName: string,
        language: string,
        icon: string,
        enable: boolean,
        taxRate: string,
        currencyType: string,
        domain: string,
        email: string,
        address: string,
        mainPhone: string,
        secondPhone: string,
        cellphone: string,
        termsAndConditions: object,
        taxes: Array<TaxModel>,
        taxName: string,
        serviceTaxPercent: number,
        facebook: string,
        instagram: string,
        twitter: string,
        linkedin: string,
        location: string,
        locationLatitude: string,
        locationLongitude: string,
    ) {
        this.id = id;
        this.code = code;
        this.alphaCode = alphaCode;
        this.name = name;
        this.currencySymbol = currencySymbol;
        this.currencyName = currencyName;
        this.language = language;
        this.icon = icon;
        this.enable = enable;
        this.taxRate = taxRate;
        this.currencyType = currencyType;
        this.domain = domain;
        this.email = email;
        this.address = address;
        this.mainPhone = mainPhone;
        this.secondPhone = secondPhone;
        this.cellphone = cellphone;
        this.termsAndConditions = termsAndConditions;
        this.taxes = taxes;
        this.taxName = taxName;
        this.serviceTaxPercent = serviceTaxPercent;
        this.facebook = facebook;
        this.instagram = instagram;
        this.twitter = twitter;
        this.linkedin = linkedin;
        this.location = location;
        this.locationLatitude = locationLatitude;
        this.locationLongitude = locationLongitude;
    }
}
const baseURL = (domain?: string) => {
    return process.env.VUE_APP_API_ENV === 'DEV'
        ? domain ?? process.env.VUE_APP_API_DEV_URL
        : process.env.VUE_APP_API_ENV === 'STG'
        ? domain ?? process.env.VUE_APP_API_STG_URL
        : domain ?? process.env.VUE_APP_API_PROD_URL;
};
const serviceTax = (taxes: Array<object>): number => {
    const serviceTax = taxes?.find(tax => tax['shipping_service']);
    return serviceTax ? +serviceTax['percent'] : 0;
};

const defaultInstagram = 'https://www.instagram.com/tipti.market';
const defaultTwitter = 'https://www.twitter.com/tiptimarket';
const defaultLinkedin = 'https://www.linkedin.com/company/tipti-s.a./';
const defaultFacebook = 'https://www.linkedin.com/company/tipti-s.a./';
const defaultLocation = '-0.1807545,-78.4448735';

const toCountriesModel = (data: object): CountriesModel => {
    return data
        ? new CountriesModel(
              data['id'],
              data['code'] ?? '593',
              data['alpha_code']?.toUpperCase() ?? 'EC',
              data['name'] ?? 'Ecuador',
              data['currency_symbol'] ?? '$',
              data['currency_name'] ?? 'USD',
              data['language'] ?? 'Español',
              data['icon'],
              data['enabled'] ?? false,
              data['tax_rate'],
              data['currency_type'] ?? 'USD',
              baseURL(data['domain']),
              data['care_team_email'] ?? '',
              data['main_address'] ?? '',
              data['main_phone_number'] ?? '',
              data['second_phone_number'] ?? '',
              data['cellphone_number'] ?? '',
              data['terms_and_conditions'],
              data['taxes']?.map(tax => toTaxModel(tax)) ?? [],
              data['taxes']?.length ? data['taxes'][0]['name'].split(' ')[0] : '',
              serviceTax(data['taxes']),
              data['facebook'] ?? defaultFacebook,
              data['instagram'] ?? defaultInstagram,
              data['twitter'] ?? defaultTwitter,
              data['linkedin'] ?? defaultLinkedin,
              data['position'] ?? defaultLocation,
              (data['position'] ?? defaultLocation).split(',')[0],
              (data['position'] ?? defaultLocation).split(',')[1],
          )
        : null;
};

export { CountriesModel, toCountriesModel };
