class BannersGoalModel {
    actualAmount: number;
    minimumAmount: number;
    benefit: string;
    bannerMessage: string;
    pushMessage: string;

    constructor(
        actualAmount: number,
        minimumAmount: number,
        benefit: string,
        bannerMessage: string,
        pushMessage: string,
    ) {
        this.actualAmount = actualAmount;
        this.minimumAmount = minimumAmount;
        this.benefit = benefit;
        this.bannerMessage = bannerMessage;
        this.pushMessage = pushMessage;
    }
}

const toBannersGoalModel = (data: object) => {
    return data
        ? new BannersGoalModel(
              data['actual_amount'] ?? 0,
              data['minimum_amount'] ?? 0,
              data['benefit'] ?? '',
              data['banner_message'] ?? '',
              data['push_message'] ?? '',
          )
        : null;
};
export { BannersGoalModel, toBannersGoalModel };
