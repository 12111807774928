import { computed } from 'vue';
import { ProductSuggestion } from '@/models/product/ProductOnDemandModel';
import { ProductModel } from '@/models/product/ProductModel';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/store/useProductStore';
import { ReplacementEventTypes, ReplacementType } from '@/modules/events/enums/ReplacementEventTypes';
import { useReplacementEvents } from '@/modules/events/useReplacementEvents';
import { useApp } from '@/composables/useApp';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { toReplacementModel } from '@/models/product/ReplacementModel';
import { logger } from '@/utils/logger';

export const useProductReplacements = () => {
    const {
        itemHighDemand,
        itemOutOfStock,
        lastItemAdded,
        isHighDemand,
        isOutOfStock,
        itemAddedFromSuggestion,
        showModalProductsReplacements,
    } = storeToRefs(useProductStore());
    const { suggestionsForUnAvailableProducts, setItemOutOfStock, setItemHighDemand } = useProductStore();
    const { $store, $route, $t } = useApp();
    const { retailerSelected } = useRetailer();
    const { sendReplacementAnalytics } = useReplacementEvents();
    const { notifier } = useApp();

    const productsOnDemand = computed<ProductSuggestion>(() => itemHighDemand.value?.[0] as ProductSuggestion);
    const productsOutOfStock = computed<ProductSuggestion>(() => itemOutOfStock.value?.[0] as ProductSuggestion);

    const productItem = computed(() => {
        if (isHighDemand.value) return productsOnDemand.value?.productAdded;
        return productsOutOfStock.value?.productAdded;
    });

    const replacementSuggestion = computed<ProductModel[]>(() => {
        if (isHighDemand.value) return productsOnDemand.value?.replacementSuggestion.slice(0, 4) ?? [];
        return productsOutOfStock.value?.replacementSuggestion.slice(0, 4) ?? [];
    });

    const replacementSuggestionPageItems = computed<ProductModel[]>(() => {
        if (isHighDemand.value) return productsOnDemand.value?.replacementSuggestion?.slice(0, 15) ?? [];
        return productsOutOfStock.value?.replacementSuggestion?.slice(0, 15) ?? [];
    });

    const imageSource = computed<string>(
        () => productItem.value?.image?.medium ?? productItem.value?.media?.find((img) => img.is_cover)?.resource ?? '',
    );

    const getProductsSuggestions = async (product: ProductModel) => {
        const { results } = await suggestionsForUnAvailableProducts({
            stockItemId: product.stockItemId,
            size: 40,
        });
        if (!results?.length) {
            notifier({
                type: 'INFO',
                title: $t('txt.product-was-not-added'),
                body: $t('txt.availability-of-our-products'),
            });
            return;
        }
        setItemOutOfStock({
            productAdded: product,
            replacementSuggestion: results ?? [],
        });
        showModalProductsReplacements.value = true;
    };

    const sendAnalyticsAndRemoveItem = (): void => {
        const mainProduct = isHighDemand.value
            ? productsOnDemand.value?.productAdded
            : productsOutOfStock.value?.productAdded;
        if (isHighDemand.value) {
            sendReplacementAnalytics({
                replacementType: ReplacementType.HighDemand,
                event: ReplacementEventTypes.DataReplacementTracking,
                mainProduct,
                replacementProduct: lastItemAdded.value as ProductModel,
            });

            setItemHighDemand({
                productAdded: mainProduct,
                replacementSuggestion: [],
            });

            resetPropertiesStore();
            return;
        }

        if (isOutOfStock.value) {
            sendReplacementAnalytics({
                replacementType: ReplacementType.OutOfStock,
                event: ReplacementEventTypes.DataReplacementTracking,
                mainProduct,
                replacementProduct: lastItemAdded.value as ProductModel,
            });

            setItemOutOfStock({
                productAdded: mainProduct,
                replacementSuggestion: [],
            });

            resetPropertiesStore();
        }
    };

    const resetPropertiesStore = () => {
        lastItemAdded.value = undefined;
        if (!itemHighDemand.value.length && !itemOutOfStock.value.length) showModalProductsReplacements.value = false;
        itemAddedFromSuggestion.value = false;
    };

    const sendConfirmReplacementAnalytics = () => {
        if (isHighDemand.value) {
            sendReplacementAnalytics({
                mainProduct: productItem.value,
                replacementProduct: lastItemAdded.value as ProductModel,
                replacementType: ReplacementType.HighDemand,
                event: ReplacementEventTypes.SaveReplacement,
            });
            return;
        }

        if (isOutOfStock.value) {
            sendReplacementAnalytics({
                mainProduct: productItem.value,
                replacementProduct: lastItemAdded.value as ProductModel,
                replacementType: ReplacementType.OutOfStock,
                event: ReplacementEventTypes.SaveReplacement,
            });

            sendReplacementAnalytics({
                replacementType: ReplacementType.OutOfStock,
                event: ReplacementEventTypes.ReplacementSelected,
                mainProduct: productItem.value,
                replacementProduct: lastItemAdded.value as ProductModel,
            });
        }
    };

    const redirectionAndShowNotifier = async () => {
        if (!retailerSelected.value?.name) return;
        if (isHighDemand.value) {
            await addReplacement();
            notifier({
                type: 'SUCCESS',
                title: $t('txt.product-selected-successfully'),
                body: $t('txt.product-is-not-available'),
            });
            return;
        }

        if (isOutOfStock.value) {
            await $store.dispatch('cart/addEditProduct', {
                product: lastItemAdded.value,
                screen: $route.path,
            });
            notifier({
                type: 'SUCCESS',
                title: $t('txt.product-selected-successfully'),
                body: $t('txt.item-was-added-to-replace'),
            });
        }
    };

    const addReplacement = async (): Promise<void> => {
        try {
            if (!productItem.value || !lastItemAdded.value) return;
            const productEdited = { ...productItem.value };
            productEdited.replacement = toReplacementModel({
                id: lastItemAdded.value.id,
                quantity: lastItemAdded.value.productQuantity,
                name: lastItemAdded.value.name,
                unit_quantity: lastItemAdded.value.unitQuantity,
                unit: lastItemAdded.value.unit,
                image: lastItemAdded.value.image,
            });
            await $store.dispatch('cart/addEditProduct', {
                product: productEdited,
                screen: $route.path,
            });
            $store.commit('cart/products', productEdited);
        } catch (err) {
            logger('ADD_REPLACEMENT', err);
        }
    };

    const isProductInReplacementSuggestions = (product: ProductModel) => {
        if (!replacementSuggestionPageItems.value?.length) return (itemAddedFromSuggestion.value = false);
        itemAddedFromSuggestion.value = replacementSuggestionPageItems.value.some(
            (item) => item.stockItemId === product.stockItemId,
        );
    };

    return {
        productsOnDemand,
        isHighDemand,
        productItem,
        imageSource,
        replacementSuggestion,
        isOutOfStock,
        getProductsSuggestions,
        productsOutOfStock,
        sendAnalyticsAndRemoveItem,
        replacementSuggestionPageItems,
        redirectionAndShowNotifier,
        addReplacement,
        sendConfirmReplacementAnalytics,
        isProductInReplacementSuggestions,
    };
};
