import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
    productSelected: null,
    currentProduct: null,
    productIdActiveBlur: null,
    fetchCart: false, /// * activate fetchCart on add products
    isLastPage: false,
    tmpNote: '',
    tmpFile: undefined,
    showAlertNotSaveNote: false,
    itemSelected: null,
    itemDefault: null,
    type_attributes: [],
    unavailableProductsByHomologation: [],
    suggestionsForUnAvailableProducts: [],
    deactivatedProducts: [],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
