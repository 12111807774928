import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26cd53e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-container" }
const _hoisted_2 = { class: "settings-container__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_settings_header = _resolveComponent("settings-header")!
  const _component_settings_language_tile = _resolveComponent("settings-language-tile")!
  const _component_settings_terms_and_conditions_tile = _resolveComponent("settings-terms-and-conditions-tile")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_settings_header),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_settings_language_tile),
      _createVNode(_component_settings_terms_and_conditions_tile)
    ])
  ]))
}