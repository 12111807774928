<template>
    <section>
        <section-header :has-back="true" :title="programSelected.name" :with-borders="false" @on-back="onBack" />
        <header-program />
        <div class="verification">
            <img class="card-program__img" :src="pageLabel.image" alt="" />
            <div class="registration-content">
                <p class="registration-content__text">
                    {{ pageLabel.description }}
                </p>
                <div class="validation-form-inputs">
                    <dynamic-form
                        :invalid="invalidFiled"
                        :required="requiredFields"
                        :schema="formFields"
                        :value="formData"
                        @form-values="updateValues"
                    />
                </div>
                <btn-text
                    class="cta-text--normal"
                    :text="$t('general.terms-and-conditions')"
                    @click="showTermsAndConditions = true"
                />
                <btn-solid :txt="pageLabel.callToActionSuccess" @click="verifySubscription" />
                <btn-text :text="pageLabel.callToActionFailure" @click="onBack" />
            </div>
        </div>
    </section>
    <modal-loading-manager
        v-if="showModal"
        :body="loadingModalBody"
        :has-close="!isLoading"
        :has-header="true"
        :is-failure="isFailure"
        :is-loading="isLoading"
        :is-success="isSuccess"
        :title="modalTitle"
        @on-action="onAction"
        @on-close="handleCloseModal"
    />
    <alert-pdf
        v-if="showTermsAndConditions"
        :accent-header="true"
        :title="$t('general.terms-and-conditions')"
        :url="programSelected?.termsAndConditions"
        @close="showTermsAndConditions = false"
    />
</template>
<script lang="ts">
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnText from '@/components/buttons/BtnText.vue';
import DynamicForm from '@/components/forms/DynamicForm.vue';
import { useStore } from 'vuex';
import AlertPdf from '@/components/alerts/alertPdf/AlertPdf.vue';
import ModalLoadingManager from '@/components/alerts/modalLoadingManager/ModalLoadingManager.vue';
import HeaderProgram from '@/views/myAccount/views/profile/views/loyaltyPrograms/HeaderProgram.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import { useFormHelper } from '@/composables/useFormHelper';
import { useI18n } from 'vue-i18n';
import { logger } from '@/utils/logger';
import { useRouter } from 'vue-router';
import { computed, defineComponent, ref } from 'vue';
import { ResponseContent } from '@/models/loyaltyPrograms/ResponseContent';
import { appRoutesMap } from '@/router/appRoutesMap';

export default defineComponent({
    name: 'ValidationScreen',
    components: { SectionHeader, HeaderProgram, ModalLoadingManager, AlertPdf, BtnSolid, BtnText, DynamicForm },
    setup() {
        const store = useStore();
        const router = useRouter();
        const loyaltyStore = useStore().state.loyaltyAndRewards;
        const programSelected = loyaltyStore.program;
        const locale = useI18n();
        const showModal = ref(false);
        const isLoading = ref(false);
        const isFailure = ref(false);
        const isSuccess = ref(false);
        const showTermsAndConditions = ref(false);
        const loadingModalBody = ref('');

        const modalTitle = computed(() => {
            return isFailure.value ? locale.t('general.ups') : locale.t('txt.loyalty_program');
        });
        const pageLabel = computed(() => {
            return programSelected.customLabelStructure.find((labels) => labels.pageName === 'verification_form');
        });

        const formBuilder = useFormHelper(programSelected.querySchema);

        const updateValues = (fieldName: string, newFormData: object, field: object) => {
            formBuilder.updateValues(fieldName, newFormData, field);
        };
        const verifySubscription = async () => {
            try {
                if (formBuilder.isInvalidForm()) {
                    window.scrollTo(0, 0);
                    return;
                }
                isFailure.value = false;
                isSuccess.value = false;
                isLoading.value = true;
                loadingModalBody.value = locale.t('general.validating_data');
                showModal.value = true;
                const validationResult: ResponseContent = await store.dispatch('loyaltyAndRewards/checkSubscription', {
                    programId: programSelected.id,
                    body: formBuilder.formData.value,
                });
                if (validationResult.has_external_subscription) {
                    await store.dispatch('loyaltyAndRewards/programs');
                    store.commit('loyaltyAndRewards/reloadProgram', programSelected.id);
                    router.replace({
                        name: appRoutesMap.reward.benefitScreen,
                        params: {
                            id: programSelected.id,
                        },
                    });
                    showModal.value = false;
                    isSuccess.value = true;
                    return;
                }
                router.push({
                    name: appRoutesMap.reward.registrationScreen,
                    params: { id: programSelected.id },
                });
                showModal.value = false;
                store.commit('loyaltyAndRewards/registrationTempData', validationResult.data);
            } catch (err) {
                logger('validation', err);
                loadingModalBody.value = err.message?.description?.length
                    ? err.message?.description
                    : locale.t('general.error_occurred');
                isFailure.value = true;
            } finally {
                isLoading.value = false;
            }
        };

        const onAction = (): void => {
            router.replace({
                name: appRoutesMap.home,
            });
        };
        const handleCloseModal = (): void => {
            if (isLoading.value) return;
            showModal.value = false;
        };
        const onBack = (): void => {
            router.go(-1);
        };
        return {
            onAction,
            handleCloseModal,
            onBack,
            pageLabel,
            modalTitle,
            programSelected,
            formData: formBuilder.formData,
            showModal,
            isLoading,
            isFailure,
            isSuccess,
            showTermsAndConditions,
            invalidFiled: formBuilder.invalidFiled,
            requiredFields: formBuilder.requiredFields,
            loadingModalBody,
            formFields: formBuilder.formFields,
            updateValues,
            verifySubscription,
        };
    },
});
</script>
<style lang="scss" scoped>
@import 'validation-screen';
</style>
