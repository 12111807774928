<template>
    <aside class="information-quantity__bulk-options">
        <p class="information-quantity__instruction">
            {{ $t("txt['product-modal__how-would-you']") }}
        </p>
        <div class="information-quantity__options">
            <button
                :style="{
                    backgroundColor: showByWeight ? '#F5F5F57F' : '#FFF4E7',
                    color: showByWeight ? '#707070' : '#FF9012',
                }"
                @click.stop="showByWeight = false"
            >
                {{ $t('general.in-units') }}
            </button>
            <button
                :style="{
                    backgroundColor: !showByWeight ? '#F5F5F57F' : '#FFF4E7',
                    color: !showByWeight ? '#707070' : '#FF9012',
                }"
                @click.stop="showByWeight = true"
            >
                {{ $t('general.by-weight') }}
            </button>
        </div>
        <span class="information-quantity__instruction--legend">
            *{{
                $t('txt.product_modal__buying_legend', {
                    type: showByWeight ? $t('general.by-weight') : $t('general.in-units'),
                })
            }}
        </span>
    </aside>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
});
const emits = defineEmits<(e: 'update:modelValue', value: boolean) => void>();
const showByWeight = computed({
    get: () => props.modelValue,
    set: (value) => emits('update:modelValue', value),
});
</script>
<style lang="scss" scoped>
@import './the-quantity-modifier.scss';
</style>
