import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import { WireTransferPaymentModel } from '@/models/checkout/CashWirePaymentModel';
import InstitutionalPaymentModel from '@/models/checkout/InstitutionalPaymentModel';

export class PaymentObject {
    readonly paymentMethods: PaymentToShowModel[];

    constructor(paymentMethods: PaymentToShowModel[]) {
        this.paymentMethods = paymentMethods;
    }
    get formData(): FormData {
        const formData = new FormData();

        for (const payment of this.paymentMethods) {
            if (payment.name === 'payment-cash') formData.append('cash_amount', `${payment.amount}`);
            if (payment.name === 'payment-institutional')
                formData.append('institution_id', `${(payment.objectData as InstitutionalPaymentModel)?.id}`);
            if (payment.name === 'payment-wire-transfer') {
                const transferData = payment.objectData as WireTransferPaymentModel;
                formData.append('account_id', `${transferData.account}`);
                //@ts-ignore
                formData.append('photo', transferData.photo);
                formData.append('type', transferData.type);
            }
            if (payment.name === 'payment-cards' || payment.name === 'payment-payphone') {
                if (payment.deferredInformation)
                    formData.append('deferred_information', JSON.stringify(payment.deferredInformation));
                formData.append('payment_method_id', `${payment.id}`);
            }
            if (payment.name === 'payment-tipti-card') formData.append('tipti_card_id', `${payment.objectData}`);
        }
        return formData;
    }
}
