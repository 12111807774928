<template>
    <Transition mode="out-in" name="slide-fade">
        <section
            v-if="requestState === 'NONE'"
            class="section-retailers"
            :aria-label="$t('txt.retailers__popular-stores')"
        >
            <RetailerColumCard
                v-for="retailer in retailers"
                :key="retailer.id"
                :retailer="retailer"
                @click.stop="selectRetailer(retailer)"
            />
            <aside v-if="showViewAll" class="section-retailers__view-all cursor-pointer" @click="setFilterByType">
                <section class="section-retailers__view-all__img">
                    <IconNextRounded :size="3" />
                </section>
                <h4 class="section-retailers__view-all__name">{{ $t('txt.retailers__popular-view-all') }}</h4>
            </aside>
        </section>
        <TheYourRetailerSkeleton v-else-if="requestState === 'LOADING'" />
    </Transition>
</template>
<script lang="ts" setup>
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { retailerSections } from '@/modules/retailers/enums/retailerSections';
import { MinRetailerInfo } from '@/modules/retailers/models/OtherRetailersModel';
import { ref, watch } from 'vue';
import { RequestState } from '@/modules/core/types/WorkingState';
import TheYourRetailerSkeleton from '@/modules/retailers/components/yourRetailers/TheYourRetailerSkeleton.vue';
import RetailerColumCard from '@/components/cards/RetailerColumCard.vue';
import IconNextRounded from '@/components/icons/IconNextRounded.vue';
import { useStore } from 'vuex';
import { RETAILER_FILTER } from '@/modules/core/constants';

const $store = useStore();
const {
    navigateToRetailer,
    filterApplied,
    fetchYourRetailers,
    minRetailerInfoToRetailerModel,
    yourRetailers,
    retailerSelected,
} = useRetailer();

const emit = defineEmits<(e: 'loaded') => void>();
const requestState = ref<RequestState>('NONE');
const retailers = ref<MinRetailerInfo[]>([]);
const showViewAll = ref(false);

const init = async () => {
    try {
        requestState.value = 'LOADING';
        await fetchYourRetailers();
        requestState.value = 'NONE';
        if (!yourRetailers.value?.retailers?.length) return;
        if (retailerSelected.value) return;
        retailerSelected.value = minRetailerInfoToRetailerModel(yourRetailers.value?.retailers?.at(0));
    } catch (e) {
        requestState.value = 'ERROR';
    } finally {
        emit('loaded');
    }
};
const selectRetailer = (retailer: MinRetailerInfo): void => {
    navigateToRetailer({
        retailer,
        section: retailerSections.YOUR_RETAILERS,
    });
};
const setFilterByType = (): void => {
    filterApplied.value = RETAILER_FILTER;
};

watch(yourRetailers, (newValue, _) => {
    if (!newValue) return;
    if (!newValue?.retailers) return;
    showViewAll.value = newValue.retailers.length > 11;
    retailers.value = showViewAll.value ? newValue.retailers.slice(0, 11) : newValue.retailers;
});

init();
</script>
<style lang="scss" scoped>
@import 'your-retailers';
</style>
