import { isMobileBrowser } from '@/utils/utils';
import { AdModel, toAdModel } from './AdModel';

class AdRecipeModel {
    readonly id: number;
    readonly name: string;
    readonly description: string;
    readonly background: string;
    readonly showOrder: number;
    readonly ads: AdModel;

    constructor(id: number, name: string, description: string, background: string, showOrder: number, ads: AdModel) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.background = background;
        this.showOrder = showOrder;
        this.ads = ads;
    }
}
const background = (data: object) => (isMobileBrowser() ? data['picture_for_mobile_web'] : data['picture_for_web']);

const toAdRecipeModel = (data: object): AdRecipeModel => {
    return data
        ? new AdRecipeModel(
              data['id'],
              data['name'],
              data['description'],
              background(data),
              data['show_order'] ?? 0,
              data['ads'].map((ad: object) => {
                  return toAdModel(ad);
              }),
          )
        : null;
};
export { AdRecipeModel, toAdRecipeModel };
