<template>
    <base-modal
        v-if="canShowThisAlert"
        class="alert-purchase__header"
        :title="paymentAcceptedTitle"
        is-centered
        @on-close="closeAndClean"
    >
        <img class="alert-purchase__animation" alt="" loading="lazy" src="@/assets/gif/success.gif" />
        <div class="alert-purchase__retailers">
            <base-card-flat
                v-for="(order, index) in responsePurchase?.ordersData"
                :key="order.retailerId"
                :class="{ 'card-margin': index !== responsePurchase?.ordersData.length - 1 }"
                border-color="white"
                padding="0"
            >
                <card-retailer-purchase-schedule :order-info="order" />
            </base-card-flat>
            <div v-if="showStats" class="alert-purchase">
                <hours-saved :hours-saved="responsePurchase.hoursSaved" />
                <total-saving :total-saving="responsePurchase.totalSavings" />
            </div>
        </div>
    </base-modal>
</template>
<script lang="ts">
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import CardRetailerPurchaseSchedule from '@/components/cards/cardRetailerPurchaseSchedule/CardRetailerPurchaseSchedule.vue';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import { mapGetters } from 'vuex';
import { OrderState } from '@/enums/orderState';
import HoursSaved from '@/components/alerts/alertPurchase/components/hoursSaved/HoursSaved.vue';
import TotalSaving from '@/components/alerts/alertPurchase/components/totalSaving/TotalSaving.vue';
import { useXSelling } from '@/modules/extraItems/composables/useXSelling';
import { useTiptiTAC } from '@/modules/retailers/composables/useTiptiTAC';
import { ResponseOrderModel } from '@/models/checkout/ResponseOrderModel';

export default {
    name: 'OrderAcceptedAlert',
    components: {
        TotalSaving,
        HoursSaved,
        CardRetailerPurchaseSchedule,
        BaseCardFlat,
        BaseModal,
    },
    setup() {
        const { fetchXSellingProducts, setXSellingProduct } = useXSelling();
        return {
            alertInfo: useTiptiTAC().alertInfo,
            setXSellingProduct,
            fetchXSellingProducts,
        };
    },
    data() {
        return {
            xSellingProducts: [],
            xSellingOrder: undefined,
        };
    },
    computed: {
        ...mapGetters({
            responsePurchase: 'checkout/responsePurchase',
        }),
        canShowThisAlert(): boolean {
            return !this.alertInfo && this.isOrderAccepted && !!this.responsePurchase;
        },
        paymentAcceptedTitle(): string {
            if (this.isPaymentInProcess) return this.$t('txt.my-order__payment-in-progress');
            return this.$t('txt.purchase__order-accepted');
        },
        showStats(): boolean {
            return this.responsePurchase?.hoursSaved?.length || this.responsePurchase?.totalSavings > 1;
        },
        isPaymentInProcess(): boolean {
            return this.responsePurchase?.ordersData?.some(
                (order: ResponseOrderModel) => order.state === OrderState.PAYMENT_IN_PROCESS,
            );
        },
        rejectedPaymentOrders(): boolean {
            return this.responsePurchase?.ordersData?.filter(
                (order: ResponseOrderModel) => order.state === OrderState.PAYMENT_REJECTED,
            );
        },
        isOrderAccepted(): boolean {
            return !!this.rejectedPaymentOrders;
        },
    },
    async created() {
        if (!this.responsePurchase) return;
        if (this.responsePurchase.ordersData.length !== 1) return;
        if (
            [OrderState.PAYMENT_REJECTED, OrderState.VALIDATING_WIRE_TRANSFER, OrderState.PAYMENT_IN_PROCESS].includes(
                this.responsePurchase.ordersData[0].state as unknown as OrderState,
            )
        )
            return;

        this.$store.dispatch('segment/viewOrderCompleteModal', {
            amount_saved: this.responsePurchase.totalSavings,
            time_saved: this.responsePurchase.hoursSaved,
        });
        const { order, products } = await this.fetchXSellingProducts({
            orderId: this.responsePurchase.ordersData[0].id,
        });
        this.xSellingProducts = products;
        this.xSellingOrder = order;
    },
    methods: {
        closeAndClean(): void {
            this.$store.commit('checkout/cleanTimeDelivery');
            this.$store.commit('checkout/resetStockItemTimeDelivery');
            this.$store.commit('checkout/responsePurchase', undefined);
            this.setXSellingProduct(this.xSellingProducts, this.xSellingOrder);
            this.$store.dispatch('cart/fetchCart');
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'alert-purchase';
</style>
