export const enum DeepLink {
    retailers = 'retailers',
    franchises = 'franchises',
    product = 'product',
    onlyProduct = 'only_product',
    promotions = 'promotions',
    tiptiCard = 'tiptiCard',
    category = 'category',
    screens = 'screens',
    recipe = 'recipe',
    onlyRecipe = 'only_recipe',
    codes = 'codes',
    referredCode = 'referredCode',
    productList = 'productList',
    banners = 'banners',
    bannersInSections = 'bannersInSections',
    stories = 'stories',
    multipleRecipe = 'multiple_recipe',
    press_release = 'only_press_release',
}
