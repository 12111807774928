<template>
    <div class="verify-code">
        <icon-enter-code :size="0.85" />
        <p class="subtitle">
            {{ $t("txt['recovery__password-insert-the-code-one']") }}
            <span>
                <strong>{{ $t("general['code']") }}</strong>
            </span>
            {{ $t("txt['recovery__password-insert-the-code-two']") }}
        </p>
        <div class="form-container">
            <label v-if="invalidCode" class="label-input" for="code">
                {{ $t("txt['recovery__password-dont-forget-code']") }}
            </label>
            <TiptiInputText
                v-model="code"
                :label="$t('general.code')"
                :placeholder="$t('general.example', { message: '123ALG' })"
            />
        </div>
        <btn-solid :is-loading="isLoading" :txt="$t('general.send')" @click="sendCode" />
    </div>
</template>

<script lang="ts" setup>
import IconEnterCode from '@/components/icons/IconEnterCode.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { logger } from '@/utils/logger';
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';
import { inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { notifierKey } from '@/modules/notification/useNotifier';

const $emit = defineEmits<(e: 'on-code-validated', value: string) => void>();
const $store = useStore();
const notifier = inject(notifierKey);
const { t } = useI18n();
const code = ref('');
const invalidCode = ref(false);
const isLoading = ref(false);
const sendCode = async (): Promise<void> => {
    try {
        if (!code.value.trim().length) {
            invalidCode.value = true;
            return;
        }
        invalidCode.value = false;
        isLoading.value = true;
        await $store.dispatch('user/codeRecovery', code.value);
        notifier({
            type: 'SUCCESS',
            body: t("txt['recovery__password-send-code-succes']"),
        });

        $emit('on-code-validated', code.value);
    } catch (err) {
        notifier({
            type: 'ERROR',
            body: t("txt['recovery__password-send-code-fail']"),
        });

        logger('SEND_CODE', err);
    } finally {
        isLoading.value = false;
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.verify-code {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 80%;
    margin: $margin-md auto;
}

.icon-mail {
    margin: $margin-md;
}

.subtitle {
    @include body1;
    width: 80%;
    text-align: center;
    margin: $margin-md auto;
}

.subtitle span {
    color: $orange;
    text-transform: uppercase;
}

.form-container {
    margin: $margin-md auto;
}

.form-container label {
    @include body2;
    color: $error;
}
</style>
