<template>
    <alert-manager
        :accept-button-text="$t('cta.verify')"
        :is-disabled="isDisable || isVerifying"
        :is-loading="isVerifying"
        :only-confirmation-btn="true"
        :show-bottom-sheet-title="false"
        :show-buttons="!isVerifying"
        :title="title"
        @on-accept="onAccept"
        @on-close="$emit('on-close')"
        @on-dismiss="$emit('on-close')"
        @on-close-modal="$emit('on-close')"
    >
        <template #content>
            <div v-if="isVerifying" class="verify-card">
                <icon-shield-check class="verify-card__img--verifying" :size="2" />
                <h2 class="verify-card__title-mobile">{{ title }}</h2>
                <triple-loading />
            </div>
            <div v-else class="verify-card">
                <icon-shield-check class="verify-card__img" :size="2" />
                <h3 class="verify-txt verify-txt--highlight">
                    {{ $t('txt.credit-card__enter-amount') }}
                </h3>
                <h3 class="verify-txt">
                    {{ $t('txt.credit-card__random-value') }}
                    <span class="verify-txt--tipti">TIPTI</span>
                </h3>
                <p class="verify-txt verify-txt--date">
                    {{
                        $t('txt.credit-card__collection-date', {
                            date,
                        })
                    }}
                </p>
                <h3 v-if="isLastAttempt" class="verify-txt--error verify-txt">
                    {{ $t('txt.credit-card__last-attempt') }}
                </h3>
                <h3 v-else class="verify-txt">
                    {{
                        $t('txt.credit-card__verification-attempts', {
                            times: remainingAttempts,
                            attempts: $t('txt.credit-card__verification-attempt', remainingAttempts),
                        })
                    }}
                </h3>
                <input-amount @amount-value="onAmountValue" />
            </div>
        </template>
    </alert-manager>
</template>

<script lang="ts">
import AlertManager from '@/components/alerts/alertManager/AlertManager.vue';
import IconShieldCheck from '@/components/icons/IconShieldCheck.vue';
import dayjs from 'dayjs';
import { logger } from '@/utils/logger';
import TripleLoading from '@/components/loading/TripleLoading.vue';
import InputAmount from '@/components/inputs/inputAmount/InputAmount.vue';

export default {
    name: 'VerifyValidation',
    components: {
        AlertManager,
        IconShieldCheck,
        TripleLoading,
        InputAmount,
    },
    props: {
        paymentMethodId: {
            type: Number,
            required: true,
        },
        attemptsPending: {
            type: Number,
        },
    },
    emits: ['on-success', 'on-error', 'on-close'],
    data() {
        return {
            isLastAttempt: false,
            isVerifying: false,
            remainingAttempts: 0,
            amountEntered: '',
        };
    },
    computed: {
        date(): string {
            return dayjs(dayjs()).format('DD/MM/YYYY');
        },
        isDisable(): boolean {
            return !this.amountEntered.length || this.amountEntered === '00,00';
        },
        title(): string {
            return this.isVerifying ? this.$t('general.verifying') : this.$t('txt.credit-card__validate');
        },
    },
    mounted() {
        this.remainingAttempts = this.attemptsPending;
        this.isLastAttempt = this.attemptsPending === 1;
    },
    methods: {
        onAmountValue(value: string): void {
            this.amountEntered = value;
        },
        async onAccept(): Promise<void> {
            try {
                this.isVerifying = true;
                await this.$store.dispatch('payments/validatePayment', {
                    paymentMethodId: this.paymentMethodId,
                    amount: this.amountEntered.replace(',', '.'),
                });
                this.$emit('on-success');
            } catch (err) {
                const attempt = +err?.data?.available_attempts;
                this.$emit('on-error', {
                    isLastAttempt: attempt === 1,
                    remainingAttempts: attempt,
                    requestError: attempt === undefined,
                });
                logger('VALIDATION_ATTEMPT', err);
            } finally {
                this.isVerifying = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'verify-validation.scss';
</style>
