import { StoriesResultModel } from '@/models/stories/StoriesResultModel';
import { RecipeDetailModel } from '@/models/recipes/RecipeDetailModel';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export const useStory = () => {
    const $router = useRouter();
    const $store = useStore();
    const { retailerSelected } = useRetailer();
    const goToStoryScreen = async (story: StoriesResultModel) => {
        let recipeDetail: RecipeDetailModel;

        switch (story.type) {
            case 'CATEGORY':
                return $router.push({
                    name: appRoutesMap.retail.storyCategories,
                    params: {
                        id: story.id,
                        title: story.title,
                    },
                    query: {
                        titleColor: story.titleColor?.replace('#', '') ?? '000',
                        picture: story.picture,
                    },
                });
            case 'INFORMATIVE':
                return $store.commit('stories/informativeStory', story);
            case 'MULTIPLE_PRODUCTS':
                return $router.push({
                    name: appRoutesMap.retail.storyProducts,
                    params: {
                        id: story.id,
                        title: story.title,
                    },
                    query: {
                        titleColor: story.titleColor?.replace('#', '') ?? '000',
                        picture: story.picture,
                    },
                });
            case 'DISCOUNT':
                return $router.push({
                    name: appRoutesMap.retail.storyDiscounts,
                    params: {
                        id: story.id,
                        title: story.title,
                    },
                    query: {
                        titleColor: story.titleColor?.replace('#', '') ?? '000',
                        picture: story.picture,
                    },
                });
            case 'ONE_PRODUCT':
                const { results } = await $store.dispatch('stories/productsFromStory', {
                    page: 1,
                    id: story.id,
                    retailerId: retailerSelected.value.id,
                });
                if (!results?.length) return;
                $store.commit('product/productSelected', results[0]);
                return $router.push({
                    name: appRoutesMap.product,
                    params: {
                        name: results[0].name,
                    },
                });
            case 'RECIPE':
            case 'LIST':
                $router.push({ name: appRoutesMap.list.personalLists });
                recipeDetail = await $store.dispatch('promotions/recipeStory', story.id);
                return $router.push({
                    name: appRoutesMap.list.recipeDetail,
                    query: { group: '', name: recipeDetail['name'], id: recipeDetail['recipeId'] },
                });
            case 'APP_SECTION':
                if (!story.appSection?.webSection) return;
                switch (story.appSection?.name) {
                    case 'TiptiCard':
                        return $router.push({ name: appRoutesMap.tiptiCard });
                    case 'Profile':
                        return $router.push({ name: appRoutesMap.account.editProfile });
                    case 'Recipes':
                        return $router.push({ name: appRoutesMap.list.personalLists });
                    case 'Referrals':
                        return $router.push({ name: appRoutesMap.account.referred });
                    case 'Categories':
                        return $router.push({ name: appRoutesMap.home });
                    case 'Retailers':
                    default:
                        return $router.push({ name: appRoutesMap.home });
                }
            default:
                return $router.push({ name: appRoutesMap.home });
        }
    };

    return {
        goToStoryScreen,
    };
};
