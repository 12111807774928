import { PaymentSurplusModel } from '@/models/notifications/PaymentSurplusModel';

import NotificationModel from '@/models/notifications/NotificationModel';
import dayjs from 'dayjs';

export default {
    notifications(state, notification: NotificationModel) {
        state.notifications.push(notification);
    },
    updateNotificationAsSeen(state, notification: NotificationModel) {
        state.notifications = state.notifications.map((actualNotification: NotificationModel) => {
            if (notification.id == actualNotification.id) {
                actualNotification.isSeen = true;
            }
            return actualNotification;
        });
    },
    pendingPayment(state, pendingPayment: PaymentSurplusModel) {
        state.pendingPayment = pendingPayment;
    },
    cleanNotifications(state) {
        state.notifications = state.notifications.filter((notification: NotificationModel) => {
            const todayDate = dayjs();
            /// * 604800000 = 1 week on milliseconds
            return todayDate.diff(notification.date) <= 604800000;
        });
    },
    tokenFirebase(state, value) {
        state.tokenFirebase = value;
    },
    message(state, value) {
        state.message = value;
        setTimeout(function() {
            if (state.message) state.message = null;
        }, 5000);
    },
};
