<template>
    <base-card class="flex-column" padding="0">
        <div ref="infoBox" v-click-outside="dismissBottomSheet" class="orders-container">
            <div class="order-section">
                <div class="order-section--desktop"></div>
                <div>
                    <div v-for="order in orders" :key="order.id" class="order-list">
                        <the-order-state :order="order" />
                    </div>
                </div>
                <div class="order-section--desktop"></div>
            </div>
            <div class="order-status__open cursor-pointer" @click.stop="$emit('on-hide-orders')">
                <icon-arrow class="icon-arrow__open" :icon-name="$t('icon.hide')" :size="0.75" color="#FF9012" />
            </div>
        </div>
    </base-card>
</template>

<script lang="ts">
import BaseCard from '@/components/cards/BaseCard.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import TheOrderState from '@/modules/main-products/components/TheStatusOrder/components/theOrderState/TheOrderState.vue';
import vClickOutside from '@/directives/click_outside';
import { isMobileBrowser } from '@/utils/utils';
import { OrderModel } from '@/models/order/OrderModel';
import { PropType } from 'vue';

export default {
    name: 'ListOrders',
    components: {
        TheOrderState,
        BaseCard,
        IconArrow,
    },
    directives: {
        clickOutside: vClickOutside,
    },
    props: {
        orders: {
            type: Array as PropType<Array<OrderModel>>,
            required: true,
        },
    },
    emits: ['on-height-orders', 'on-hide-orders'],
    computed: {
        isMobile(): boolean {
            return isMobileBrowser();
        },
    },
    mounted() {
        this.matchHeight();
    },
    methods: {
        matchHeight() {
            const height = this.$refs.infoBox?.clientHeight;
            this.$emit('on-height-orders', height);
        },
        dismissBottomSheet(): void {
            this.$emit('on-hide-orders');
        },
    },
};
</script>

<style lang="scss" scoped>
@import './list-orders.scss';
</style>
