<template>
    <header class="retailer-header" :style="{ 'background-color': retailer?.primaryColor }">
        <img v-if="showRetailerImage" class="retailer-header__img" :src="retailer.icon" :alt="retailer.name" />
        <div :class="{ 'retailer-header__detail-left': showRetailerImage }">
            <h2
                class="retailer-header__title"
                :class="{ 'retailer--border': index === 0 }"
                :style="{ color: retailer?.secondaryColor }"
            >
                {{ retailer.name }}
            </h2>
            <h3 class="retailer-header__items" :style="{ color: retailer?.secondaryColor }">
                {{ $t('general.products', { count: retailer?.products?.length ?? 0 }) }}
            </h3>
        </div>
        <h2
            v-if="getTotalForRetailer"
            class="retailer-header__price"
            :style="{ color: retailer?.secondaryColor }"
            v-currency="getTotalForRetailer"
        />
    </header>
</template>
<script lang="ts">
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';

export default {
    name: 'retailer-header-cart',
    props: {
        getTotalForRetailer: {
            type: Number,
            default: 0,
        },
        showRetailerImage: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        retailer: {
            type: [RetailerModel, Object],
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss';
.retailer--border {
    border-radius: $radius-x-lg $radius-x-lg 0 0;
}
.retailer-header {
    display: flex;
    flex-wrap: wrap;
    border-radius: $radius-lg $radius-lg 0 0;
    padding: $padding-sm $padding-md;
    justify-content: space-between;
    align-items: center;
    &__img {
        border-radius: $radius-circular;
        height: 3rem;
        width: 3rem;
    }
    &__detail-left {
        margin-right: auto;
        margin-left: $margin-sm;
    }
    &__title {
        @include subtitle1;
    }
    &__price {
        @include body1;
    }
    &__items {
        @include caption;
    }
}
</style>
