<template>
    <general-alert
        :accent-header="true"
        :accept-button-txt="$t(showOptions ? 'cta.continue' : 'cta.no_go-back')"
        :decline-button-txt="$t('cta.yes_deactivate')"
        :is-disabled="checkForDisableButton"
        :is-loading="isLoading"
        :only-confirmation-btn="showOptions || isLoading"
        :show-buttons="true"
        :title="$t('txt.deactivate-account__want-deactivate')"
        message=""
        @on-close="isLoading ? null : $emit('on-close')"
        @on-decline="acceptAlert"
        @on-accept="onContinue"
    >
        <template v-if="showOptions" #content>
            <div class="deactivate-ac">
                <p class="deactivate-ac__title">{{ $t('txt.deactivate-account__why-deactivate') }}</p>
                <div class="deactivate-ac__selector">
                    <radio
                        v-model="reason"
                        :inputs="deactivateReasons"
                        :value-select="reason"
                        label=""
                        radio-group="deactivateOptions"
                    />
                </div>
                <text-area v-model.trim="reasonTxt" :placeholder="$t('general.leave_comment')" :rows="4" />
            </div>
        </template>
        <template v-else #content>
            <div class="deactivate-ac__text">
                <p class="deactivate-ac__text-body">
                    {{ $t('txt.deactivate-account__we-sorry') }}
                    <span class="deactivate-ac__text-body--highlighted">
                        {{ $t('txt.deactivate-account__deactivate-acc') }}
                    </span>
                    {{ $t('txt.deactivate-account__all') }}
                    <span class="deactivate-ac__text-body--highlighted"> {{ $t('txt.deactivate-account__data') }} </span
                    >{{ $t('txt.deactivate-account__saved') }}
                    <span class="deactivate-ac__text-body--highlighted">
                        {{ $t('txt.deactivate-account__lose') }}
                    </span>
                    {{ $t('txt.deactivate-account__not-recovery') }}
                    <span class="deactivate-ac__text-body--highlighted">
                        {{ $t('txt.deactivate-account__also') }}
                    </span>
                    {{ $t('txt.deactivate-account__create') }}
                    <span class="deactivate-ac__text-body--highlighted">
                        {{ $t('txt.deactivate-account__new-acc') }}
                    </span>
                    {{ $t('txt.deactivate-account__same-credentials') }}
                    <span class="deactivate-ac__text-body--highlighted">
                        {{ $t('txt.deactivate-account__sure') }}
                    </span>
                    {{ $t('txt.deactivate-account__you-want') }}
                    <span class="deactivate-ac__text-body--highlighted">
                        {{ $t('txt.deactivate-account__deactivate') }}
                    </span>
                </p>
            </div>
        </template>
    </general-alert>
    <snack-bar
        :body="snackBarMessage"
        :is-active="showSnackBar"
        :is-failure="!isSuccess"
        :is-success="isSuccess"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<script lang="ts">
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import Radio from '@/components/radio/Radio.vue';
import TextArea from '@/components/inputs/textArea/TextArea.vue';
import { mapGetters } from 'vuex';
import SnackBar from '../snackBar/SnackBar.vue';

export default {
    name: 'AlertDeactivateAccount',
    components: { GeneralAlert, Radio, TextArea, SnackBar },
    emits: ['on-close'],
    data() {
        return {
            reason: '',
            reasonTxt: '',
            showOptions: true,
            snackBarMessage: '',
            showSnackBar: false,
            isSuccess: false,
            isLoading: false,
            deactivateReasons: [
                {
                    value: this.$t('txt.deactivate-account__expectations'),
                    name: this.$t('txt.deactivate-account__expectations'),
                },
                {
                    value: this.$t('txt.deactivate-account__shopping-problems'),
                    name: this.$t('txt.deactivate-account__shopping-problems'),
                },
                { value: 'CUSTOM', name: this.$t('general.other') },
            ],
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
        }),
        checkForDisableButton() {
            if (this.isLoading) return true;
            if (this.showOptions && !this.reason.length) return true;
            return this.reason === 'CUSTOM' && !this.reasonTxt.length;
        },
    },
    watch: {
        reason: function (currentValue, _) {
            if (currentValue !== 'CUSTOM' && this.reasonTxt.length) {
                this.reasonTxt = '';
            }
        },
    },
    mounted() {
        this.showOptions = true;
    },
    methods: {
        onContinue() {
            return this.isLoading ? null : this.showOptions ? (this.showOptions = false) : this.$emit('on-close');
        },
        acceptAlert() {
            return this.isLoading ? null : this.onAccept();
        },
        async onAccept(): Promise<void> {
            try {
                const _window = window as any;
                this.isLoading = true;
                await this.$store.dispatch('user/deactivateAccount', {
                    clientId: this.user.id,
                    deactivate: true,
                    reason: this.reason !== 'CUSTOM' ? this.reason : this.reasonTxt,
                });
                this.$store.commit('user/token', undefined);
                this.$store.commit('user/user', undefined);
                localStorage.clear();
                caches.keys().then(function (keyList) {
                    return Promise.all(
                        keyList.map(function (key) {
                            return caches.delete(key);
                        }),
                    );
                });
                _window.google.accounts.id.disableAutoSelect();
                window.location.href = '/?account-deactivated=true';
            } catch (err) {
                this.isLoading = false;
                this.snackBarMessage = this.$t('general.try-again');
                this.isSuccess = false;
                this.showSnackBar = true;
            }
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/scss/index.scss';

.alert-bg .deactivate-ac {
    &__selector {
        & .radio {
            margin: $margin-sm $margin-none;
        }
    }
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.deactivate-ac {
    &__title {
        @include body2;
        text-align: center;
    }

    &__selector {
        padding: $padding-sm $padding-none;
    }
}

.deactivate-ac__text {
    padding: $padding-sm;
    max-width: 30rem;

    &-body {
        @include body2;
        text-align: justify;
        line-height: 1.5rem;

        &--highlighted {
            font-weight: 800;
        }
    }
}
</style>
