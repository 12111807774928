import { ProductModel } from '@/models/product/ProductModel';
import { OrderResumenModel } from '@/models/order/OrderResumen';

export default {
    products(state, product: ProductModel) {
        const oldProductLength = state.products.length;
        const localProductIndex: number = state.products.findIndex(
            (localProduct: ProductModel) => localProduct?.id == product.id,
        );
        localProductIndex === -1 ? state.products.push(product) : (state.products[localProductIndex] = product);
        state.showLimitItemsByOrder =
            oldProductLength < state.products.length &&
            (state.order.numberOfExtraItemsAllowed ?? 10) + 1 === state.products.length &&
            !state.xSellingProducts.length;
    },
    removeProduct(state, product: ProductModel) {
        state.products = state.products.filter((actualProduct: ProductModel) => actualProduct.id !== product.id);
    },
    clearProducts(state) {
        state.products = [];
    },
    xSellingProducts(state, products: Array<ProductModel>): void {
        state.xSellingProducts = products;
    },
    order(state, order: OrderResumenModel): void {
        if (state.xSellingProducts?.length && !order) return;
        state.order = order;
    },
    closeSnackBarLimit(state): void {
        state.showLimitItemsByOrder = false;
    },
};
