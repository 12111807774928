import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c69746be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "validate-success" }
const _hoisted_2 = { class: "validate-success__txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_shield_check = _resolveComponent("icon-shield-check")!
  const _component_alert_manager = _resolveComponent("alert-manager")!

  return (_openBlock(), _createBlock(_component_alert_manager, {
    "is-loading": false,
    "is-disabled": false,
    "show-bottom-sheet-title": false,
    "show-buttons": false,
    "only-confirmation-btn": false,
    title: _ctx.$t('txt.credit-card__validate'),
    onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close'))),
    onOnDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close'))),
    onOnCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon_shield_check, {
          class: "validate-success__img",
          size: 2
        }),
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('txt.credit-card__verification-success')), 1)
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}