<template>
    <btn-out-line
        @click="redirectToWhatsApp"
        bgColor="#fff"
        color="#ff9012"
        :txt="$t('cta.communicate_with_advisor')"
        borderRadius="7px"
        with-icon
    >
        <template #icon>
            <icon-whats-app color="#FF9012" />
        </template>
    </btn-out-line>
</template>
<script lang="ts">
import BtnOutLine from './BtnOutLine.vue';
import IconWhatsApp from '../icons/IconWhatsApp.vue';
import { useWhatsApp } from '@/composables/WhastAppComposable';

export default {
    name: 'btn-contact-advisor',
    components: { BtnOutLine, IconWhatsApp },
    props: {
        orderId: {
            type: Number,
        },
        text: {
            type: String,
        },
    },
    setup() {
        const { openWhatsApp } = useWhatsApp();
        return {
            openWhatsApp,
        };
    },
    methods: {
        redirectToWhatsApp() {
            this.openWhatsApp({ text: this.text });
        },
    },
};
</script>
