import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8ac79ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "bills-as-list"
}
const _hoisted_2 = { class: "bill-card" }
const _hoisted_3 = { class: "card-data card-data--name" }
const _hoisted_4 = { class: "card-data" }
const _hoisted_5 = { class: "card-data card-data--email" }
const _hoisted_6 = { class: "cancel-bills cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardAdd = _resolveComponent("CardAdd")!
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_subheader_separator = _resolveComponent("subheader-separator")!
  const _component_CardBase = _resolveComponent("CardBase")!
  const _component_icon_bill = _resolveComponent("icon-bill")!
  const _component_no_content = _resolveComponent("no-content")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (!$data.isLoading)
        ? (_openBlock(), _createBlock(_component_CardAdd, {
            key: 0,
            "button-text": _ctx.$t('forms.add_billing_data'),
            "show-button-actions": !!$data.countryBills?.length,
            "text-button-action": $data.isRemovingCards ? _ctx.$t('cta.save') : _ctx.$t('cta.edit'),
            onOnPressed: _cache[0] || (_cache[0] = ($event: any) => ($data.isRemovingCards = !$data.isRemovingCards)),
            onOpenBillForm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open-bill-form', true)))
          }, null, 8, ["button-text", "show-button-actions", "text-button-action"]))
        : _createCommentVNode("", true),
      ($data.isLoading && !$data.countryBills?.length)
        ? (_openBlock(), _createBlock(_component_base_loading, { key: 1 }))
        : ($data.countryBills?.length)
          ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.countryBills, (country) => {
                return (_openBlock(), _createElementBlock("article", {
                  key: country.id
                }, [
                  ($data.countryBills?.length >= 1 && !$props.isFromModal)
                    ? (_openBlock(), _createBlock(_component_subheader_separator, {
                        key: 0,
                        name: country?.name,
                        "show-btn": false,
                        "has-padding": ""
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("article", {
                    class: _normalizeClass({ 'bills ': $props.isFromModal, 'bills bills-account': !$props.isFromModal })
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(country?.bill_address, (bill, billIndex) => {
                      return (_openBlock(), _createBlock(_component_CardBase, {
                        key: billIndex,
                        class: _normalizeClass(["card-bill--content", {
                            'card-bill-base--selected': $setup.billUsed?.id === bill.id && $props.isFromModal,
                        }]),
                        "is-check": $setup.billUsed?.id === bill.id,
                        "is-default": bill.default,
                        "is-removing": $data.isRemovingCards,
                        "show-check-selected": $props.isFromModal,
                        onClick: ($event: any) => ($options.clickBill(bill, country.id)),
                        onOnEdit: ($event: any) => ($options.editBill({ bill, countryId: country.id })),
                        onOnDelete: ($event: any) => ($options.deleteBill(bill.id))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("section", _hoisted_2, [
                            _createElementVNode("p", _hoisted_3, _toDisplayString(bill.name), 1),
                            _createElementVNode("p", _hoisted_4, _toDisplayString(bill.identifier), 1),
                            _createElementVNode("p", _hoisted_5, _toDisplayString(bill.email), 1)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["class", "is-check", "is-default", "is-removing", "show-check-selected", "onClick", "onOnEdit", "onOnDelete"]))
                    }), 128))
                  ], 2)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createBlock(_component_no_content, {
              key: 3,
              class: "billings__no-content",
              description: _ctx.$t('txt.billings__no-content-description'),
              title: _ctx.$t('txt.billings__no-content-title'),
              "color-title": "#A2A2A2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon_bill, {
                  size: 2.5,
                  color: "#C1C1C1"
                })
              ]),
              _: 1
            }, 8, ["description", "title"])),
      _createVNode(_component_snack_bar, {
        body: $data.modalMessage,
        "is-active": $data.showModal,
        "is-failure": $data.isFailure,
        "is-success": $data.isSuccess,
        onOnSnackbarClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showModal = false))
      }, null, 8, ["body", "is-active", "is-failure", "is-success"])
    ]),
    ($data.isRemovingCards && $props.isFromModal)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "cancel-bills-button",
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($data.isRemovingCards = !$data.isRemovingCards))
        }, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('cta.cancel')), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}