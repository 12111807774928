import { CartTypes } from '@/modules/core/types/CartType';

export default {
    cartType(state, value: CartTypes) {
        if (state.cartType !== value) state.cartType = value;
    },
    currentRoutePath(state, value: string) {
        state.currentRoutePath = value;
    },
};
