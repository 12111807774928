<template>
    <section>
        <section-header v-if="!isMobile" title="Tipti Card" />
        <base-loading v-if="tiptiCard?.details === null" />
        <div
            v-for="(detail, index) in tiptiCard?.details"
            v-else-if="tiptiCard?.details != null && tiptiCard?.details"
            :key="index"
            class="tipti-card-default-balance"
        >
            <p
                v-date="{
                    date: detail.date,
                    format: 'MMM DD',
                }"
            />
            <p>{{ detail.detail }}</p>
            <p
                v-currency="detail.amount"
                class="tipti-card-default-balance__amount"
                :style="detail.action === 'substract_credit' ? 'color: red' : ''"
            />
        </div>
        <tipti-card-out-moves v-if="tiptiCard?.details != null && tiptiCard?.details.length === 0" />
    </section>
</template>

<script lang="ts" setup>
import { isMobileBrowser } from '@/utils/utils';

import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import TiptiCardOutMoves from '../../components/tiptiCardOutMoves/TiptiCardOutMoves.vue';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { computed } from 'vue';

const { tiptiCard } = useTiptiCard();

const isMobile = computed(() => isMobileBrowser());
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.tipti-card-default-balance {
    @include body2;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid $disable;
    margin: 0 $margin-xlg;
    align-items: center;
    justify-items: center;
    @include phone {
        margin: 0 $margin-sm;
    }
}

.tipti-card-default-balance__amount {
    color: $green;
}
</style>
