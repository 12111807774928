<template>
    <div class="download__stores">
        <button v-for="store in stores" :key="store" class="cursor-pointer store-app" @click="clickStore(store)">
            <component :is="store.icon" v-bind="store" />
        </button>
    </div>
</template>
<script lang="ts">
import IconAppGallery from '@/components/icons/IconAppGallery.vue';
import IconAppStore from '@/components/icons/IconAppStore.vue';
import IconGooglePlay from '@/components/icons/IconGooglePlay.vue';

export default {
    name: 'MobileAppStores',
    components: { IconAppGallery, IconAppStore, IconGooglePlay },
    props: {
        canRedirect: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            default: 1.2,
        },
    },
    data() {
        return {
            stores: [
                {
                    icon: 'IconGooglePlay',
                    url: 'https://play.google.com/store/apps/details?id=ec.tipti&hl=es_EC',
                    alt_text: 'Google play Android',
                    size: this.size,
                },
                {
                    icon: 'IconAppStore',
                    url: 'https://apps.apple.com/ec/app/tipti/id1193596576',
                    alt_text: 'App Store Apple',
                    size: this.size,
                },
                {
                    icon: 'IconAppGallery',
                    url: 'https://appgallery.huawei.com/#/app/C101418537',
                    alt_text: 'App gallery Huawei',
                    size: this.size,
                },
            ],
        };
    },
    methods: {
        clickStore(store): void {
            if (!this.canRedirect) return;
            window.open(store.url, '_blank');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.download__stores {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);

    @include phone {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: $margin-sm;
        margin-bottom: $margin-md;
        justify-items: center;
    }
}

.store-app {
    all: unset;
}
</style>
