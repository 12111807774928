<template>
    <base-icon
        :height="height"
        :icon-color="color"
        :icon-name="$t('icon.credit_card')"
        :width="width"
        viewBox="0 0 44 44"
    >
        <circle cx="22" cy="22" fill="#FFF4E7" r="22" />
        <path
            clip-rule="evenodd"
            d="M12.3124 32.6483C11.0029 32.6483 9.94141 31.5362 9.94141 30.1644V15.2612C9.94141 13.8894 11.0029 12.7773 12.3124 12.7773H32.4091C33.7186 12.7773 34.7801 13.8894 34.7801 15.2612V30.1644C34.7801 31.5362 33.7186 32.6483 32.4091 32.6483H12.3124Z"
            fill-rule="evenodd"
            stroke="#FF9012"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        />
        <path
            d="M9.94141 18.9844H34.7801"
            stroke="#FF9012"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        />
        <path
            d="M14.9102 25.8164H22.3618"
            stroke="#FF9012"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: 'none',
        },
    },
    computed: {
        width(): number {
            return 44 * this.size;
        },
        height(): number {
            return 44 * this.size;
        },
    },
};
</script>
