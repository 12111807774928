import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    stories: [],
    featuredStory: null,
    selectedIndex: 0,
    selectedStory: undefined,
    informativeStory: undefined,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
