import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b16296c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notifications" }
const _hoisted_2 = { class: "notifications__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_notifications_section = _resolveComponent("notifications-section")!
  const _component_tipti_news = _resolveComponent("tipti-news")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_section_header, {
      class: "notifications-header",
      colorTitle: "#707070",
      title: _ctx.$t('general.notifications')
    }, null, 8, ["title"]),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_notifications_section),
      _createVNode(_component_tipti_news)
    ])
  ]))
}