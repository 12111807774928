<template
    ><button class="btn-chat">
        <icon-message :size="1.2" />
        <div class="btn-chat__badge">
            <badge-chat v-if="showBadge" :messagesUnread="messagesUnread" />
        </div>
    </button>
</template>

<script>
import IconMessage from '@/components/icons/IconChat.vue';
import BadgeChat from '@/components/badges/BadgeChat.vue';
export default {
    name: 'btn-chat',
    components: {
        IconMessage,
        BadgeChat,
    },
    props: {
        messagesUnread: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        showBadge() {
            return this.messagesUnread !== 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.btn-chat {
    all: unset;
    display: flex;
    align-items: center;
    max-width: 2rem;
    @include overline;
    font-weight: 600;
    position: relative;
    &__badge {
        position: absolute;
        right: -10%;
        top: 20%;
    }
}
</style>
