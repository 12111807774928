import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76e347bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-contact" }
const _hoisted_2 = { class: "faq-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardFaq = _resolveComponent("CardFaq")!
  const _component_ContentFaq = _resolveComponent("ContentFaq")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$data.hideMainFaq)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          class: _normalizeClass(["contain-faqs", { 'contain-faqs--profile': $setup.isPageAccountFaqs }])
        }, [
          _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("txt['faqs__how-can-we-help-you']")), 1),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.homeFaqCards, (item) => {
              return (_openBlock(), _createBlock(_component_CardFaq, {
                key: item.titleCard,
                "alt-icon": item.titleCard,
                "content-card": item.contentCard,
                "icon-component": item.iconComponent,
                "title-card": item.titleCard,
                onClick: ($event: any) => ($options.openCardFaq(item.nameComponent))
              }, null, 8, ["alt-icon", "content-card", "icon-component", "title-card", "onClick"]))
            }), 128))
          ])
        ], 2))
      : _createCommentVNode("", true),
    ($data.hideMainFaq)
      ? (_openBlock(), _createBlock(_component_ContentFaq, {
          key: 1,
          "component-active": $data.selectedCard,
          onBackFaq: $options.backFaq
        }, null, 8, ["component-active", "onBackFaq"]))
      : _createCommentVNode("", true),
    _createVNode(_component_snack_bar, {
      body: $data.snackBarMessage,
      "is-active": $data.showSnackBar,
      "is-failure": !$data.isSuccess,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[0] || (_cache[0] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}