import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    tiptiCard: undefined,
    totalToPay: 0,
    primeSelected: undefined,
    isTiptiCardCheckout: false,
    primeDeals: [],
    defaultPrime: undefined,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
