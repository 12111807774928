<template>
    <button
        class="container-btn-circular"
        :class="{ 'btn-disabled': isDisabled, 'cursor-pointer': !isDisabled }"
        :disabled="isDisabled"
        :style="{ 'background-color': bgColor, padding: padding }"
    >
        <base-loading :heigth="loadingSize" :width="loadingSize" v-if="isLoading" />
        <slot v-else />
    </button>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.container-btn-circular {
    all: unset;
    border-radius: $radius-circular;
    display: grid;
    justify-content: center;
    align-content: center;
}
.btn-disabled {
    background-color: $disable !important;
}
.cursor-pointer {
    cursor: pointer;
}
</style>

<script>
import BaseLoading from '../loading/BaseLoading.vue';
export default {
    name: 'btn-circular',
    components: { BaseLoading },
    props: {
        bgColor: {
            type: String,
            default: '#FF9012',
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        loadingSize: {
            type: String,
        },
        padding: {
            type: String,
            default: '0.5rem',
        },
    },
};
</script>
