import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "shell" }, _ctx.$attrs, {
    style: { 'background-color': $props.bgColor }
  }), [
    _createElementVNode("div", {
      class: "shell__progress",
      style: _normalizeStyle({ width: `${$props.progress}%` })
    }, null, 4)
  ], 16))
}