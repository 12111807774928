<template>
    <div
        class="the-product-actions"
        :class="{ 'the-product-actions--rounded': updateAmount > 0 && !isLargeQuantity && !canShowActions }"
        @mouseout="onMouseOut"
        @mouseover="onMouseOver"
    >
        <div v-if="isForReplacement"></div>
        <actions-quantity
            v-else-if="canShowActions"
            class="card-product-blur__actions"
            :actual-quantity="updateAmount === 0 ? 1 : updateAmount"
            :is-catalog-ficoa="isCatalogFicoa"
            :is-miniature="isMiniature"
            :must-fetch-cart="fetchCart"
            :product-data="productData"
            :section="section"
            @on-close="onClose"
            @on-update="onUpdate"
            @on-delete="onDelete"
        />
        <the-add-buttons v-else-if="updateAmount === 0" :key="buttonRemountKey" @click.once="onBtnClicked(true)" />
        <btn-circular
            v-else
            class="btn-actions"
            :class="{ 'actions--mini': isMiniature }"
            bg-color="#ffffff"
            @click="onBtnClicked(false)"
        >
            <span class="card-product__quantity" v-text="updateAmount" />
        </btn-circular>
        <alert-configurable-delivery-products
            v-if="showConfigurableDeliveryAlert"
            :product-name="productData.name"
            @on-close="closeConfigurableDeliveryAlert"
        />
        <tipti-alert
            :alert-information="alertData"
            :show-alert="showAskForExtraItemsModal"
            @on-close="onBtnClicked(true, true)"
            @on-main-action="addInOrder"
            @on-secondary-action="onBtnClicked(true, true)"
        >
            <template #icon>
                <icon-add-cart :size="2" color="#ff9012" />
            </template>
            <template #moreContent>
                <p class="alert_legend">{{ $t('txt.extra_item_limit', { limit: order.numberOfExtraItemsAllowed }) }}</p>
            </template>
        </tipti-alert>
        <snack-bar
            :body="message5x5"
            :is-active="show5x5Message && !wasShowed5x5Message"
            :is-success="isDiscountApplied"
            no-background
            @on-snackbar-close="wasShowed5x5Message = true"
            @on-snackbar-tap="wasShowed5x5Message = true"
        />
    </div>
</template>

<script lang="ts">
import ActionsQuantity from '@/components/product/actionsQuantity/ActionsQuantity.vue';
import AlertConfigurableDeliveryProducts from '../alerts/alertConfigurableDeliveryProducts/AlertConfigurableDeliveryProducts.vue';
import BtnCircular from '@/components/buttons/BtnCircular.vue';
import fetchDiscountActivationProgressBar from '@/utils/discountActivationProgressBarUtils';
import productCard from '@/mixings/productCards';
import { ProductModel } from '@/models/product/ProductModel';
import TheAddButtons from '@/components/buttons/TheAddButtons.vue';
import { isMobileBrowser } from '@/utils/utils';
import { useStockItemSchedule } from '@/composables/useStockItemSchedule';
import {
    deactivateProductKey,
    deactivateProductKeyDefaultVValue,
} from '@/modules/productSuggested/composables/useDeativatedProduct';
import type { PropType } from 'vue';
import { inject } from 'vue';
import { mapGetters } from 'vuex';
import { BaseTiptiAlertModelInterface } from '@/models/alerts/AlertOpenRetailer';
import TiptiAlert from '@/components/alerts/alertTiptiAlert/TiptiAlert.vue';
import { useExtraItemHandleEditProduct } from '@/modules/extraItems/composables/useExtraItemHandleProduct';
import { useCartType } from '@/modules/core/composable/useCartType';
import IconAddCart from '@/components/icons/IconAddCart.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import { ReplacementEventTypes, ReplacementType } from '@/modules/events/enums/ReplacementEventTypes';
import { useReplacementEvents } from '@/modules/events/useReplacementEvents';
import { useFicoaProducts } from '@/modules/catalogs/composables/useFicoaProducts';
import { useRoutes } from '@/composables/useRoutes';
import { useProductReplacements } from '@/modules/highDemandAndOutOfStock/composable/useProductReplacements';
import { useProductStore } from '@/store/useProductStore';

export default {
    name: 'TheProductActionButton',
    components: {
        SnackBar,
        IconAddCart,
        TiptiAlert,
        TheAddButtons,
        ActionsQuantity,
        BtnCircular,
        AlertConfigurableDeliveryProducts,
    },
    mixins: [fetchDiscountActivationProgressBar, productCard],
    inheritAttrs: true,
    props: {
        fetchCart: {
            type: Boolean,
            default: false,
        },
        isForReplacement: {
            type: Boolean,
            default: false,
        },
        isMiniature: {
            type: Boolean,
            default: false,
        },
        productData: {
            type: Object as PropType<ProductModel>,
            required: true,
        },
        section: {
            type: String,
            required: false,
        },
        isSearchByStockItem: {
            type: Boolean,
            required: false,
        },
        isCatalogFicoa: {
            type: Boolean,
            default: false,
        },
        alwaysShowCounter: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-close', 'on-gm-items'],
    setup(props) {
        const { canAddProduct } = useStockItemSchedule();
        const { isMainCart, isExtraItemCart } = useCartType();
        const { addProduct } = useExtraItemHandleEditProduct();
        const { sendReplacementAnalytics } = useReplacementEvents();
        const { updateFicoaProduct } = useFicoaProducts(props.productData);
        const deactivatedProduct = inject<ProductModel>(deactivateProductKey, deactivateProductKeyDefaultVValue);
        const { isScreenMyAccount } = useRoutes();
        const { getProductsSuggestions, isProductInReplacementSuggestions } = useProductReplacements();
        const { isHighDemand, isOutOfStock } = useProductStore();
        return {
            updateFicoaProduct,
            sendReplacementAnalytics,
            isMainCart,
            isExtraItemCart,
            deactivatedProduct,
            canAddProduct,
            addOrEditProductExtraItem: addProduct,
            isScreenMyAccount,
            getProductsSuggestions,
            isHighDemand,
            isOutOfStock,
            isProductInReplacementSuggestions,
        };
    },
    data() {
        return {
            product: this.productData,
            showBtnActions: false,
            timerForActions: undefined,
            quantityUpdated: undefined,
            showConfigurableDeliveryAlert: false,
            showAskForExtraItemsModal: false,
            wasShowed5x5Message: false,
            show5x5Message: false,
            message5x5: '',
            isDiscountApplied: false,
            alertData: undefined,
            buttonRemountKey: 1,
        };
    },
    computed: {
        ...mapGetters({
            order: 'extraItems/order',
            isAuth: 'user/isAuth',
        }),
        isLargeQuantity(): boolean {
            return this.updateAmount?.toString()?.length >= 3;
        },
        isMobile(): boolean {
            return isMobileBrowser();
        },
        validateForShow(): boolean {
            return this.order && this.isMainCart && !this.isScreenMyAccount;
        },
        canShowActions(): boolean {
            if (this.alwaysShowCounter) return true;
            return this.showBtnActions;
        },
        shouldShowRecommendedProducts() {
            return (
                this.$route.meta?.show_recommended_products &&
                this.productData?.gm_recommendation &&
                !this.productData.highDemand
            );
        },
    },
    watch: {
        updateAmount(newValue: number, oldValue: number) {
            if (
                !this.isMainCart ||
                !this.product.discountFiveByFive ||
                Boolean(this.product.carry) ||
                Boolean(this.product.pay)
            )
                return;
            if (oldValue < 5 && newValue === 5) {
                this.message5x5 = this.$t('txt.discount_5x5_applied');
                this.isDiscountApplied = true;
                this.show5x5Message = true;
                this.wasShowed5x5Message = false;
            }
            if (newValue !== 0 && oldValue >= 5 && newValue < 5) {
                this.message5x5 = this.$t('txt.discount_5x5_lost');
                this.isDiscountApplied = false;
                this.show5x5Message = true;
                this.wasShowed5x5Message = false;
            }
        },
    },
    mounted() {
        this.alertData = {
            title: this.$t('txt.extra_items__active_order'),
            description: this.$t('txt.active_order__message'),
            button_text: this.$t('txt.add_in_order'),
            secondary_button_text: this.$t('txt.create_new_cart'),
        } as BaseTiptiAlertModelInterface;
    },
    methods: {
        addInOrder(): void {
            const product = this.product;
            if (product) product.productQuantity = 1;
            this.addOrEditProductExtraItem(product);
            this.showAskForExtraItemsModal = false;
        },
        setTimer(): void {
            this.timerForActions = setTimeout(() => {
                this.showBtnActions = false;
                this.$emit('on-close');
                if (!this.quantityUpdated || this.quantityUpdated === this.product.productQuantity) return;
                if (!this.isCatalogFicoa) this.updateProductAmount();
                else this.updateFicoaProduct(this.quantityUpdated);
            }, 2000);
        },
        onDelete(): void {
            clearTimeout(this.timerForActions);
            this.showBtnActions = false;
        },
        onBtnClicked(isProductAdd: boolean, isAfterAskForExtraItemsModal = false): void {
            if (this.showBtnActions) return;
            if (this.isCatalogFicoa) {
                this.showBtnActions = true;
                if (isProductAdd) this.quantityUpdated = 1;
                this.updateFicoaProduct(this.quantityUpdated);
                this.setTimer();
                return;
            }
            if (!this.isAuth) {
                this.buttonRemountKey++;
                return this.$store.commit('user/requireAuth', true);
            }

            if (this.productData.uniqueDelivery && !this.canAddProduct) {
                this.buttonRemountKey++;
                this.showConfigurableDeliveryAlert = true;
                return;
            }
            if (this.validateForShow && !isAfterAskForExtraItemsModal) {
                this.showAskForExtraItemsModal = true;
                return;
            }
            if (isAfterAskForExtraItemsModal) this.$store.commit('extraItems/order', undefined);
            if (this.isHighDemand || this.isOutOfStock) this.isProductInReplacementSuggestions(this.productData);
            if (!this.productData.available) {
                this.getProductsSuggestions(this.productData);
                this.buttonRemountKey++;
                return;
            }
            this.showAskForExtraItemsModal = false;
            this.showBtnActions = true;
            if (isProductAdd) {
                this.quantityUpdated = 1;
                this.callActions(this.deactivatedProduct?.stockItemId);
                if (this.deactivatedProduct?.id || this.isForReplacement) {
                    this.sendReplacementAnalytics({
                        mainProduct: this.deactivatedProduct,
                        replacementProduct: this.productData,
                        event: ReplacementEventTypes.SaveReplacement,
                        replacementType: this.isForReplacement
                            ? ReplacementType.HighDemand
                            : ReplacementType.OutOfStock,
                    });
                    this.sendReplacementAnalytics({
                        mainProduct: this.deactivatedProduct,
                        replacementProduct: this.productData,
                        event: ReplacementEventTypes.DataReplacementTracking,
                        replacementType: this.isForReplacement
                            ? ReplacementType.HighDemand
                            : ReplacementType.OutOfStock,
                    });
                }

                if (this.shouldShowRecommendedProducts) this.$emit('on-gm-items', this.productData.id);
            }
            this.setTimer();
        },
        onUpdate(quantity: number): void {
            this.quantityUpdated = quantity;
            if (!this.isMobile) return;
            this.onMouseOver();
            this.setTimer();
        },
        onMouseOver(): void {
            clearTimeout(this.timerForActions);
        },
        onMouseOut(): void {
            this.setTimer();
        },
        onClose(): void {
            this.cleanProductIdActiveBlur();
            this.updateProductAmount();
            this.showBtnActions = false;
            this.$emit('on-close');
        },
        closeConfigurableDeliveryAlert() {
            this.showConfigurableDeliveryAlert = false;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-product-actions {
    @include box-shadow--full;
    min-height: 2rem;
    min-width: 2rem;
    background-color: $white;
    border-radius: $radius-toggle;
    transition: all 0.9s ease;

    &--rounded {
        border-radius: $radius-circular;
    }
}

.card-product__quantity {
    @include button;
}

.product-actions {
    position: absolute;
    top: 0.1rem;
    right: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-quantity {
    @include button;
    text-align: center;
    transition: all 250ms ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: var(--overflow);
    margin: $margin-sm;
    margin-left: $margin-xsm;
}

.alert_legend {
    @include caption;
    margin: $margin-sm;
}
</style>
