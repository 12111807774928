<template>
    <base-icon
        :icon-name="$t('icon.wire_transfer')"
        :width="width"
        :height="height"
        viewBox="0 0 45 35"
        :iconColor="color"
    >
        <path
            d="M29.4175 29.9474C29.0927 29.6226 28.5659 29.6226 28.241 29.9474C27.9162 30.2722 27.9162 30.799 28.241 31.1239L28.5742 31.457H14.0081C13.5487 31.457 13.1763 31.8296 13.1763 32.2889C13.1763 32.7483 13.5487 33.1208 14.0081 33.1208H28.5742L28.241 33.4539C27.9162 33.7788 27.9162 34.3055 28.241 34.6305C28.4035 34.7928 28.6164 34.8741 28.8292 34.8741C29.0421 34.8741 29.2551 34.7928 29.4174 34.6305L31.1707 32.8772C31.4955 32.5524 31.4955 32.0256 31.1707 31.7007L29.4175 29.9474Z"
        />
        <path
            d="M15.1732 5.56339C15.3357 5.72577 15.5486 5.80704 15.7615 5.80704C15.9743 5.80704 16.1873 5.72577 16.3497 5.56339C16.6745 5.23854 16.6745 4.7118 16.3497 4.38687L16.0165 4.05371H30.5825C31.042 4.05371 31.4144 3.6812 31.4144 3.22184C31.4144 2.76248 31.042 2.38997 30.5825 2.38997H16.0165L16.3497 2.0568C16.6745 1.73196 16.6745 1.20522 16.3497 0.88029C16.0248 0.555528 15.4981 0.555528 15.1732 0.88029L13.4199 2.63354C13.0951 2.95839 13.0951 3.48513 13.4199 3.81005L15.1732 5.56339Z"
        />
        <path
            d="M35 8C29.4928 8 25 12.4928 25 18C25 23.5072 29.4928 28 35 28C40.5072 28 45 23.5072 45 18C45 12.4928 40.5072 8 35 8ZM37.8986 21.913C37.4638 22.4928 36.8841 22.7826 36.1594 22.9275C35.8696 22.9275 35.7246 23.2174 35.7246 23.5072C35.7246 23.7971 35.7246 24.087 35.7246 24.3768C35.7246 24.5217 35.5797 24.8116 35.4348 24.8116C35.4348 24.8116 35.4348 24.8116 35.2899 24.8116C35 24.8116 34.7101 24.8116 34.2754 24.8116C33.9855 24.8116 33.8406 24.6667 33.8406 24.3768V23.7971C33.8406 23.3623 33.8406 23.3623 33.4058 23.2174C32.8261 23.0725 32.2464 22.9275 31.6667 22.7826C31.2319 22.6377 31.2319 22.4928 31.3768 22.058C31.5217 21.7681 31.5217 21.3333 31.6667 21.0435C31.8116 20.6087 31.8116 20.6087 32.2464 20.7536C32.8261 21.0435 33.4058 21.1884 34.1304 21.3333C34.5652 21.3333 35 21.3333 35.4348 21.1884C35.8696 21.0435 36.1594 20.4638 36.0145 20.029C36.0145 19.8841 35.8696 19.7391 35.7246 19.5942C35.5797 19.4493 35.2899 19.3043 35 19.1594C34.2754 18.8696 33.6957 18.5797 33.1159 18.2899C32.1014 17.8551 31.5217 16.8406 31.5217 15.6812C31.5217 14.3768 32.3913 13.3623 33.5507 13.0725C34.1304 12.7826 34.1304 12.7826 34.1304 12.2029V11.6232C34.1304 11.1884 34.2754 11.1884 34.5652 11.1884H35C35.8696 11.1884 35.8696 11.1884 35.8696 12.058C35.8696 12.6377 35.8696 12.6377 36.4493 12.7826C36.8841 12.9275 37.3188 12.9275 37.7536 13.2174C37.8986 13.2174 38.0435 13.5072 38.0435 13.6522C37.8986 14.087 37.8986 14.3768 37.7536 14.8116C37.6087 15.2464 37.4638 15.2464 37.1739 15.1014C36.4493 14.8116 35.7246 14.6667 35 14.6667C34.8551 14.6667 34.5652 14.6667 34.4203 14.8116C33.9855 14.9565 33.8406 15.3913 33.8406 15.8261C33.8406 15.971 33.9855 16.1159 34.1304 16.2609C34.4203 16.4058 34.7101 16.6957 35 16.8406C35.5797 17.1304 36.1594 17.2754 36.7391 17.5652C38.1884 18.2899 38.7681 20.1739 38.0435 21.6232C38.0435 21.6232 38.0435 21.7681 37.8986 21.913Z"
        />
        <path
            d="M10 8C4.49275 8 0 12.4928 0 18C0 23.5072 4.49275 28 10 28C15.5072 28 20 23.5072 20 18C20 12.4928 15.5072 8 10 8ZM12.8986 21.913C12.4638 22.4928 11.8841 22.7826 11.1594 22.9275C10.8696 22.9275 10.7246 23.2174 10.7246 23.5072C10.7246 23.7971 10.7246 24.087 10.7246 24.3768C10.7246 24.5217 10.5797 24.8116 10.4348 24.8116C10.4348 24.8116 10.4348 24.8116 10.2899 24.8116C10 24.8116 9.71014 24.8116 9.27536 24.8116C8.98551 24.8116 8.84058 24.6667 8.84058 24.3768V23.7971C8.84058 23.3623 8.84058 23.3623 8.4058 23.2174C7.82609 23.0725 7.24638 22.9275 6.66667 22.7826C6.23188 22.6377 6.23188 22.4928 6.37681 22.058C6.52174 21.7681 6.52174 21.3333 6.66667 21.0435C6.81159 20.6087 6.81159 20.6087 7.24638 20.7536C7.82609 21.0435 8.4058 21.1884 9.13043 21.3333C9.56522 21.3333 10 21.3333 10.4348 21.1884C10.8696 21.0435 11.1594 20.4638 11.0145 20.029C11.0145 19.8841 10.8696 19.7391 10.7246 19.5942C10.5797 19.4493 10.2899 19.3043 10 19.1594C9.27536 18.8696 8.69565 18.5797 8.11594 18.2899C7.10145 17.8551 6.52174 16.8406 6.52174 15.6812C6.52174 14.3768 7.3913 13.3623 8.55072 13.0725C9.13043 12.7826 9.13043 12.7826 9.13043 12.2029V11.6232C9.13043 11.1884 9.27536 11.1884 9.56522 11.1884H10C10.8696 11.1884 10.8696 11.1884 10.8696 12.058C10.8696 12.6377 10.8696 12.6377 11.4493 12.7826C11.8841 12.9275 12.3188 12.9275 12.7536 13.2174C12.8986 13.2174 13.0435 13.5072 13.0435 13.6522C12.8986 14.087 12.8986 14.3768 12.7536 14.8116C12.6087 15.2464 12.4638 15.2464 12.1739 15.1014C11.4493 14.8116 10.7246 14.6667 10 14.6667C9.85507 14.6667 9.56522 14.6667 9.42029 14.8116C8.98551 14.9565 8.84058 15.3913 8.84058 15.8261C8.84058 15.971 8.98551 16.1159 9.13043 16.2609C9.42029 16.4058 9.71014 16.6957 10 16.8406C10.5797 17.1304 11.1594 17.2754 11.7391 17.5652C13.1884 18.2899 13.7681 20.1739 13.0435 21.6232C13.0435 21.6232 13.0435 21.7681 12.8986 21.913Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-wire-transfer',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 40 * this.size;
        },
        height(): number {
            return 40 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
