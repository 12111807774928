<template>
    <base-icon :height="height" :icon-color="color" :width="width" icon-name="icon-verified-badge" viewBox="0 0 81 81">
        <rect
            fill="#FFF4E7"
            height="78.4924"
            rx="39.2462"
            stroke="#FF9012"
            stroke-width="1.5076"
            width="78.4924"
            x="1.42885"
            y="1.0992"
        />
        <path
            :fill="color"
            d="M60.0736 37.4403L47.1219 24.3367C46.8305 24.0264 46.4806 23.7782 46.0929 23.6069C45.7052 23.4355 45.2875 23.3444 44.8644 23.3388C44.4413 23.3333 44.0215 23.4135 43.6295 23.5746C43.2375 23.7358 42.8814 23.9747 42.5821 24.2772C42.2828 24.5798 42.0464 24.9399 41.8867 25.3363C41.7271 25.7327 41.6475 26.1575 41.6526 26.5855C41.6577 27.0135 41.7474 27.4362 41.9164 27.8286C42.0855 28.221 42.3304 28.5752 42.6369 28.8704L50.293 36.6044H24.8304C23.9893 36.6044 23.1828 36.9424 22.5881 37.544C21.9934 38.1457 21.6593 38.9617 21.6593 39.8126C21.6593 40.6635 21.9934 41.4795 22.5881 42.0812C23.1828 42.6828 23.9893 43.0208 24.8304 43.0208H50.2221L42.6369 50.7071C42.067 51.3138 41.754 52.1218 41.7647 52.9587C41.7754 53.7956 42.1089 54.5952 42.6941 55.1868C43.2794 55.7784 44.07 56.1151 44.8972 56.1252C45.7244 56.1353 46.5228 55.8179 47.1219 55.2408L60.0736 42.1372C60.369 41.84 60.6033 41.4864 60.7627 41.097C60.922 40.7075 61.0034 40.2899 61.002 39.8683C61.0041 39.8418 61.0041 39.8152 61.002 39.7887C61.0039 39.7622 61.0039 39.7356 61.002 39.7091C61.0018 38.8583 60.6678 38.0423 60.0736 37.4403Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconVerifiedBadge',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 13 * this.size;
        },
        height(): number {
            return 15 * this.size;
        },
    },
};
</script>
