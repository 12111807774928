<template>
    <collapsable-header
        :can-expanded="children?.length > 0"
        :error-header="errorHeader"
        :is-expanded="isExpanded"
        @click="isExpanded = !isExpanded"
    >
        <template #header>
            <slot name="header">
                <p
                    class="expandable-header-text"
                    :class="{
                        'expandable-header-text--accent': errorHeader,
                        'expandable-header-text--bold': bold,
                        'expandable-header-text--highlight': hasExtraHeight,
                    }"
                >
                    {{ headerText }}
                </p>
            </slot>
        </template>
        <template #header-side>
            <slot name="header-side">
                <p
                    v-currency="amount"
                    class="expandable-header-text"
                    :class="{
                        'expandable-header-text--error': errorHeader,
                        'expandable-header-text--bold': bold,
                        'expandable-header-text--highlight': hasExtraHeight,
                    }"
                />
            </slot>
        </template>
    </collapsable-header>
    <transition-vertical-fade>
        <section
            v-if="isExpanded && children?.length"
            class="expandable-children"
            :class="{ 'expandable-children--expanded': isExpanded }"
        >
            <div v-for="child in children" :key="child">
                <slot name="child-content">
                    <div class="expandable-child" :class="{ 'expandable-child-information': child?.description }">
                        <p class="expandable-child__text expandable-child__text--name">{{ child?.name }}</p>
                        <btn-information v-if="child?.description" @on-view="viewInformationServiceFee(child?.type)">
                            <template #default>
                                <p class="expandable-info">
                                    {{ child.description }}
                                </p>
                            </template>
                        </btn-information>
                        <p
                            v-if="child.amount"
                            v-currency="child.amount"
                            class="expandable-child__text expandable-child__text--value"
                        />
                    </div>
                </slot>
            </div>
        </section>
    </transition-vertical-fade>
</template>

<script lang="ts">
import CollapsableHeader from '@/components/collapsable/CollapsableHeader.vue';
import TransitionVerticalFade from '@/modules/core/components/transition/TransitionVerticalFade.vue';
import BtnInformation from '../buttons/BtnInformation.vue';

export default {
    name: 'Collapsable',
    components: { TransitionVerticalFade, CollapsableHeader, BtnInformation },
    props: {
        amount: {
            type: [String, Number],
            default: 0,
        },
        headerText: {
            type: String,
            default: '',
        },
        children: {
            type: Array,
        },
        errorHeader: {
            type: Boolean,
            default: false,
        },
        bold: {
            type: Boolean,
            default: false,
        },
        hasExtraHeight: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isExpanded: false,
    }),
    methods: {
        viewInformationServiceFee(type: string): void {
            setTimeout(() => {
                this.$store.dispatch('segment/viewInformationServiceFee', {
                    code_type: type,
                });
            }, 2000);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.expandable-header-text {
    @include caption;
    line-height: 0;

    &--right {
        text-align: right;
    }

    &--highlight {
        @include body2;
    }

    &--error {
        color: $error;
    }

    &--bold {
        font-weight: 600;
    }
}

.expandable-children {
    margin: $margin-none 2rem;

    &--expanded {
        margin-bottom: $margin-sm;
    }
}

.expandable-child {
    width: 100%;
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    align-items: center;
    justify-items: center;
    cursor: default;

    &:hover {
        background-color: $canvas;
    }

    &__text {
        @include overline;

        &--name {
            width: 100%;
        }

        &--value {
            align-self: center;
            justify-self: right;
        }
    }
}

.expandable-child-information {
    width: 100%;
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    cursor: default;

    &:hover {
        background-color: $canvas;
    }

    &__text {
        @include overline;

        &--name {
            width: 100%;
        }

        &--value {
            align-self: center;
            justify-self: right;
        }
    }
}

.expandable-info {
    @include overline;
    @include tablet {
        color: $white;
    }
    text-align: center;

    &--bold {
        font-weight: 600;
    }
}
</style>
