import { ProductModel, toProductModel } from './ProductModel';
import { RemoteLocaleDto, RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';

class CategoryModel {
    id: number;
    name: string;
    icon: string;
    products: Array<ProductModel>;
    isLastPage: boolean;
    hasSubcategories: boolean;
    internationalizedName: RemoteLocaleModel;
    isDiscount: boolean;
    isDeeplink: boolean;

    constructor(
        id: number,
        name: string,
        isLastPage: boolean,
        icon?: string,
        products?: Array<ProductModel>,
        hasSubcategories?: boolean,
        internationalizedName?: RemoteLocaleModel,
        isDiscount?: boolean,
        isDeeplink?: boolean,
    ) {
        this.id = id;
        this.name = name;
        this.isLastPage = isLastPage;
        this.icon = icon;
        this.products = products;
        this.hasSubcategories = hasSubcategories;
        this.internationalizedName = internationalizedName;
        this.isDiscount = isDiscount;
        this.isDeeplink = isDeeplink;
    }
}

interface CategoryDto {
    id: number;
    name: string;
    icon?: string;
    stockitems?: Array<ProductModel>;
    more_pages?: boolean;
    request_subcategories?: boolean;
    isDiscount?: boolean;
    translate_information?: RemoteLocaleDto;
    isDeeplink?: boolean;
}

export class NewCategoryModel extends CategoryModel {
    constructor(data: CategoryDto) {
        super(
            data.id,
            data.name,
            data.more_pages,
            data.icon,
            data.stockitems,
            data.request_subcategories,
            data.translate_information,
            data.isDiscount,
            data.isDeeplink,
        );
    }
}

/**
 * @deprecated use new NewCategoryModel
 */
const toCategoryModel = (data: object): CategoryModel => {
    return data
        ? new CategoryModel(
              data['id'],
              data['name'] ?? '',
              data['more_pages'] ? !data['more_pages'] : true,
              data['icon'],
              data['stockitems']?.map((product: object) => toProductModel(product)),
              data['request_subcategories'] ?? false,
              data['translate_information'],
          )
        : null;
};

export interface SubCategories {
    subcategories: CategoryDto[];
    categoryId: number;
}

export { CategoryModel, toCategoryModel };
