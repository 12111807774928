<template>
    <div
        class="card-menu"
        :class="{
            'card-menu__single-retailer': isSingle,
            'card-menu__no-data': !scheduleToRender,
        }"
    >
        <IconSchedule v-if="scheduleToRender" :size="0.8" color="#FF9012" />
        <aside class="card-menu__content">
            <header class="retailer-schedule__header">
                <section class="retailer-schedule-card" :class="{ 'text-no-selected': !scheduleToRender }">
                    {{ deliveryModeText }}
                    <span class="retailer-schedule-card retailer-schedule-card--name">
                        {{ product?.retailer?.name.toUpperCase() }}
                        {{ product.name.toUpperCase() }}
                    </span>
                </section>
            </header>
            <p
                v-if="scheduleToRender"
                v-date="{ date: scheduleToRender?.date, format: 'dddd D' }"
                class="retailer-schedule--date"
            />
            <p v-else class="text-no-selected">{{ $t("txt['schedule__select-delivery']") }}</p>
        </aside>
        <button :class="{ 'text-no-selected': !scheduleToRender }" @click="showModalSchedule = true">
            {{ $t(!scheduleToRender ? "general['select']" : "cta['change']") }}
        </button>
    </div>
    <BackgroundWithTransition :is-active="showModalSchedule" content-centered @on-bg-click="showModalSchedule = false">
        <CheckoutScheduleModal
            v-if="showModalSchedule"
            v-model="showModalSchedule"
            :retail="retailer"
            :stock-item="product"
        />
    </BackgroundWithTransition>
</template>

<script lang="ts" setup>
import { computed, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import IconSchedule from '@/components/icons/IconSchedule.vue';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { ProductModel } from '@/models/product/ProductModel';
import { usePickUp } from '@/composables/usePickUp';
import CheckoutScheduleModal from '@/views/checkout/components/menu/components/scheduleCard/CheckoutScheduleModal.vue';
import { RetailerAvailabilityModel } from '@/modules/retailers/models/RetailerAvailabilityModel';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';

const { deliveryModeText } = usePickUp();

const $state = useStore();
const props = defineProps({
    product: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
    isSingle: {
        required: true,
        type: Boolean,
    },
});

const showModalSchedule = ref(false);

const retailers = computed<RetailerAvailabilityModel[]>(
    () => $state.getters['retailers/originalRetailersAvailability'],
);

const retailer = computed(() => retailers.value.find((ret) => ret.retailer_id === props.product.retailer.id));
const timeDelivery = computed(() => $state.getters['checkout/stockItemTimeDelivery']);

const scheduleToRender = computed<TimeDeliveryModel>(
    () => timeDelivery.value?.find((time: TimeDeliveryModel) => time.stockItemId === props.product?.id),
);
</script>

<style lang="scss" scoped>
@import '../../menu-checkout.scss';
@import './schedule-card.scss';

.retailer-schedule-card {
    @include caption;
    color: $orange;
    font-weight: 600;
    text-transform: capitalize;

    &--name {
        font-weight: 900;
    }
}
</style>
