<template>
    <h1 class="title-text-promotions">¿Cómo aplicar códigos promocionales?</h1>
    <carousel-tipti :autoplay="true" :is-last-page="true" :is-loading="false" :show-bullet-points="true">
        <div class="container">
            <div class="img-container">
                <p class="promotion-text-guide">Abre la aplicación Tipti y toca <br />para mostrar el menú lateral</p>
                <br />
                <img class="img" :src="require('@/assets/svg/promotions-guide/1promociones.svg')" alt="img" />
            </div>
            <div class="img-container">
                <br />
                <p class="promotion-text-guide">
                    Dirígete a la sección de códigos <br />
                    y promociones y toca para mostrar la pantalla.
                </p>
                <img class="img" :src="require('@/assets/svg/promotions-guide/2promociones.svg')" alt="img" />
            </div>
            <div class="img-container">
                <p class="promotion-text-guide">
                    Una vez en la pantalla, introduce el <br />
                    código y pulsa “Aplicar” para <br />
                    aplicarlo a su cuenta.
                </p>
                <br />
                <img class="img" :src="require('@/assets/svg/promotions-guide/3promociones.svg')" alt="img" />
            </div>
        </div>
    </carousel-tipti>
</template>

<script lang="ts">
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';

export default {
    name: 'PromotionalCodeGuide',
    components: { CarouselTipti },
};
</script>

<style lang="scss" scoped>
@import 'promotional-code-guide.scss';
</style>
