<template>
    <base-modal
        :title="newsAlertSlide?.slides[currentSlidePosition]?.title"
        header-text-color="#ff9012"
        @on-close="closeAlert"
    >
        <section class="alert-news">
            <img
                class="alert-news__img"
                :alt="newsAlertSlide?.slides[currentSlidePosition]?.title"
                :src="newsAlertSlide?.slides[currentSlidePosition]?.web_picture"
                height="500"
                loading="lazy"
                width="500"
            />
            <p class="alert-news__description">
                {{ newsAlertSlide?.slides[currentSlidePosition]?.body }}
            </p>
            <div class="alert-slide-dot">
                <div
                    v-for="(item, index) in newsAlertSlide?.slides"
                    class="slide-dot"
                    :key="index"
                    :style="index === currentSlidePosition ? 'background-color: #cfde00;' : ''"
                    @click="currentSlidePosition = index"
                ></div>
            </div>
            <btn-solid
                class="alert-news__button"
                :stretch="true"
                :txt="newsAlertSlide?.slides[currentSlidePosition]?.button_text"
                @click="nextPage"
            />
        </section>
    </base-modal>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { AlertNewsDto } from '@/models/alertNews/AlertNewsModel';

export default {
    name: 'alert-news',
    data() {
        return {
            currentSlidePosition: 0,
        };
    },
    computed: {
        ...mapGetters({
            localNewsAlertSlide: 'alertNews/alertNewsSlide',
        }),
        newsAlertSlide(): AlertNewsDto {
            return this.localNewsAlertSlide;
        },
    },
    emits: ['close-alert'],
    components: {
        BaseModal,
        BtnSolid,
    },
    methods: {
        nextPage() {
            if (this.currentSlidePosition < this.newsAlertSlide.slideNumber - 1) {
                this.currentSlidePosition = this.currentSlidePosition + 1;
                return;
            }
            this.currentSlidePosition = 0;
            this.$store.dispatch('alertNews/slideActivity', { id: this.newsAlertSlide.id, accepted: true });
            this.$emit('close-alert');
        },
        closeAlert(): void {
            this.$store.dispatch('alertNews/slideActivity', { id: this.newsAlertSlide.id, accepted: false });
            this.$emit('close-alert');
        },
    },
};
</script>

<style lang="scss" scoped>
@import './alert-news.scss';
</style>
