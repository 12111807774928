import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-702b2f1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-parragraph" }
const _hoisted_2 = {
  key: 1,
  class: "settings-language"
}
const _hoisted_3 = { class: "settings-language__options__option settings-parragraph" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_language = _resolveComponent("icon-language")!
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_radio_normal = _resolveComponent("radio-normal")!
  const _component_settings_tile = _resolveComponent("settings-tile")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_settings_tile, {
      title: _ctx.$t('txt.setting__language_change')
    }, {
      "title-icon": _withCtx(() => [
        _createVNode(_component_icon_language, {
          size: 0.75,
          class: "settings-tile-icon"
        })
      ]),
      content: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('txt.setting__language_message')), 1),
        ($data.isLanguageLoading)
          ? (_openBlock(), _createBlock(_component_base_loading, { key: 0 }))
          : (_openBlock(), _createElementBlock("section", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.availableLanguages, (language) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "settings-language__options",
                  key: language.code
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(language.txt), 1)
                  ]),
                  _createVNode(_component_radio_normal, {
                    class: "settings-language__options__radio",
                    radioGroup: "language",
                    value: language,
                    onOnCheck: $options.updateSelectedLanguage,
                    isSelected: language.code == $options.languageSelected
                  }, null, 8, ["value", "onOnCheck", "isSelected"])
                ]))
              }), 128))
            ]))
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_snack_bar, {
      isSuccess: $data.isSuccess,
      isFailure: $data.isFailure,
      body: $data.snackBarMessage,
      isActive: $data.showSnackBar,
      onOnSnackbarClose: _cache[0] || (_cache[0] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["isSuccess", "isFailure", "body", "isActive"])
  ], 64))
}