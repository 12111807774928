<template>
    <base-card-flat
        class="credit-card"
        :class="{ 'credit-card__expired': hasPaymentMethodsExpired }"
        :border-color="'#a2a2a2'"
    >
        <div v-if="creditCardToPay" class="credit-card-tile">
            <icon-payment-methods :size="0.4" color="#FF9012" />
            <div class="credit-card-tile__card">
                <p class="credit-card-tile__owner">{{ `${$t('general.payment-cards')}` }}</p>
                <div class="credit-card-data">
                    <img
                        class="credit-card-data__logo"
                        :src="creditCardToPay.icon"
                        :style="{ 'background-color': creditCardToPay.backgroundColor ?? '#606060' }"
                        alt=""
                        @error="displayDefaultImg"
                    />
                    <div v-for="index in 12" :key="index">
                        <div class="credit-card-data__dot" :class="spaceDots(index)"></div>
                    </div>
                    <p class="credit-card-data__number">{{ creditCardToPay.data }}</p>
                </div>
            </div>
            <p class="credit-card-tile__change cursor-pointer" @click="$emit('on-change-payment')">
                {{ btnTxt }}
            </p>
        </div>
        <div v-else class="credit-card-no-selected">
            <div>
                <p class="credit-card-no-selected__title">
                    {{ $t('icon.payment_methods') }}
                </p>
                <p class="credit-card-no-selected__content">
                    {{ $t('txt.checkout__enter-payment-method') }}
                </p>
            </div>
            <p class="credit-card-no-selected__btn cursor-pointer" @click="$emit('on-change-payment')">
                {{ btnTxt }}
            </p>
        </div>
        <payment-method-expired v-if="hasPaymentMethodsExpired" @on-change-selected="$emit('on-change-payment')" />
    </base-card-flat>
</template>

<script lang="ts">
import IconPaymentMethods from '@/components/icons/IconPaymentMethods.vue';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import { usePayments } from '@/composables/usePayments';
import { useImage } from '@/composables/ImageComposable';
import { computed } from 'vue';
import PaymentMethodExpired from '@/views/checkout/components/menu/components/expired-card/PaymentMethodExpired.vue';

export default {
    name: 'PaymentMethodOnlyCreditCardSelectionTile',
    components: { PaymentMethodExpired, IconPaymentMethods, BaseCardFlat },
    emits: ['on-change-payment'],
    setup() {
        const { paymentsToShow, hasPaymentMethodsExpired } = usePayments();
        const { displayDefaultImg } = useImage();
        return {
            paymentsToShow,
            hasPaymentMethodsExpired: computed(() => hasPaymentMethodsExpired.value),
            displayDefaultImg,
        };
    },
    computed: {
        creditCardToPay(): PaymentToShowModel {
            return this.paymentsToShow?.find((paymentMethod: PaymentToShowModel) => {
                return paymentMethod?.name === 'payment-cards';
            });
        },
        btnTxt(): string {
            return this.creditCardToPay ? this.$t('cta.change') : this.$t('cta.add');
        },
    },
    methods: {
        spaceDots(index: number): string {
            return index === 3 || index === 7 || index == 11 ? 'credit-card-data__dot--spacer' : '';
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'payment-method-only-credit-card-selection-tile';
</style>
