<template>
    <footer
        ref="CardFooter"
        class="credit-card-footer"
        :class="{
            'credit-card-footer--no-icons': noIcons,
            'credit-card-footer--one-icon': oneIcon,
        }"
    >
        <img
            v-if="creditCard?.creditCardBrandData?.franchiseLogo"
            class="credit-card-footer__img"
            :alt="creditCard.data?.cardName"
            :src="creditCard?.creditCardBrandData?.franchiseLogo"
        />
        <div v-else></div>
        <div class="credit-card">
            <h2 class="credit-card__title">{{ creditCard.data?.cardName }}</h2>
            <div class="credit-card__digits">
                <div v-for="index in 16" :key="index" class="credit-card__digits-point" :class="spaceDots(index)"></div>
                <p class="credit-card__data">{{ creditCard.data.cardDigits.slice(1, 5) }}</p>
            </div>
        </div>
        <Component :is="iconComponent.type" v-if="iconComponent" v-bind="iconComponent" />
    </footer>
</template>

<script lang="ts">
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import IconShieldCheck from '@/components/icons/IconShieldCheck.vue';
import IconAsDefault from '@/components/icons/IconAsDefault.vue';
import IconAlertClock from '@/components/icons/IconAlertClock.vue';
import { PropType } from 'vue';

export default {
    name: 'CreditCardFooter',
    components: { IconAsDefault, IconShieldCheck, IconAlertClock },
    props: {
        creditCard: {
            required: true,
            type: Object as PropType<PaymentMethodModel>,
        },
        isDefault: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            iconComponentList: [
                {
                    type: 'IconAsDefault',
                    size: 1.5,
                },
                {
                    type: 'IconShieldCheck',
                    size: 1.5,
                },
                {
                    type: 'IconAlertClock',
                },
            ],
        };
    },
    computed: {
        iconComponent() {
            if (this.creditCard?.isExpired)
                return this.iconComponentList.find((name) => name.type === 'IconAlertClock');
            if (this.creditCard?.isValidate && !this.creditCard.isRequiredValidation)
                return this.iconComponentList.find((name) => name.type === 'IconShieldCheck');
            if (this.isDefault) return this.iconComponentList.find((name) => name.type === 'IconAsDefault');
            return null;
        },
        style(): object {
            return {
                'background-color': this.creditCard.creditCardBrandData?.textColor,
            };
        },
        oneIcon(): boolean {
            return (!this.creditCard.isValidate && this.isDefault) || (this.creditCard.isValidate && !this.isDefault);
        },
        noIcons(): boolean {
            return !this.creditCard.isValidate && !this.isDefault;
        },
    },
    mounted() {
        this.getHeight();
    },
    methods: {
        spaceDots(index: number): string {
            return [3, 7, 11, 16].includes(index) ? 'spaceBetweenPoints' : '';
        },
        getHeight() {
            this.$emit('on-height', this.$refs.CardFooter.clientHeight);
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'credit-card-footer';
</style>
