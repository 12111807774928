import { CountryAddressesModel } from '@/models/address/CountryAddressesModel';
import { AddressModel } from '@/models/address/AddressModel';
import { CityModel } from '@/models/location/CityModel';
import { logger } from '@/utils/logger';

const polygons = state => state.polygons;
const cities = (state): Array<CityModel> => state.cities;
const addressToEdit = state => state.addressToEdit;

export default {
    polygons,
    cities,
    addressToEdit,
    locationSelected(state) {
        return state.locationSelected ?? state.addressDefault;
    },
    countryAddresses(state: { countryAddresses: Array<CountryAddressesModel> }): Array<CountryAddressesModel> {
        return state.countryAddresses;
    },
    allAddresses(state): Array<AddressModel> {
        const addresses = [];
        state.countryAddresses.forEach(element => {
            addresses.push(...element.addresses);
        });
        return addresses;
    },
    addressDefault(state: { countryAddresses: Array<CountryAddressesModel> }): AddressModel {
        let _defaultAddress: AddressModel;
        for (const country of state.countryAddresses) {
            if (_defaultAddress) continue;
            _defaultAddress = country.addresses?.find((address: AddressModel) => address['isDefaultAddress']);
        }
        return _defaultAddress;
    },
    showAddressModal(state) {
        return state.showAddressModal;
    },
};
