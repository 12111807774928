<script lang="ts" setup>
import { useCheckOut } from '@/composables/useCheckOut';
import { useCountry } from '@/composables/useCountry';
import { computed, reactive, ref } from 'vue';
import { removeCountryCode } from '@/utils/phoneUtils';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';
import TheModalContent from '@/modules/core/components/layouts/TheModalContent.vue';
import BaseHeader from '@/modules/core/components/headers/HeaderWithAction.vue';
import TextAreaInstructions from '@/components/inputs/textArea/textAreaInstructions/TextAreaInstructions.vue';
import InputPhoneWithLabel from '@/components/inputs/primary/phone/InputPhoneWithLabel.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import IconNoteWithPencil from '@/components/icons/IconNoteWithPencil.vue';
import { useCart } from '@/composables/useCart';
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';

const { countryPhoneFromPhoneNumber, concatenateNumberAndCountryCode, countryCodeSelected } = useCountry();
const { instructions, phone, email } = useCheckOut();
const { hasDeliveryPacking } = useCart();

const showInstructions = ref(false);
const localInstructions = reactive({
    text: '',
    phone: '',
    email: '',
});

const saveInstruction = () => {
    phone.value = concatenateNumberAndCountryCode(localInstructions.phone);
    instructions.value = localInstructions.text;
    email.value = localInstructions.email;
    showInstructions.value = false;
};

const showModal = () => {
    countryPhoneFromPhoneNumber(phone.value);
    localInstructions.phone = removeCountryCode(phone.value);
    localInstructions.text = instructions.value;
    showInstructions.value = true;
};

const visiblePhone = computed(() => removeCountryCode(phone.value));
const isValidNumber = ref(false);

const btnSaveInstructionsIsDisabled = computed(() => {
    if ((!localInstructions.email?.length || !localInstructions.phone?.length) && hasDeliveryPacking.value) return true;
    return isValidNumber.value;
});
</script>
<template>
    <section class="card-menu" :class="{ 'no-instruction': hasDeliveryPacking && (!phone?.length || !email?.length) }">
        <IconNoteWithPencil color="#FF9012" />
        <div class="card-checkout-section">
            <aside class="card-checkout-section__instructions">
                <h2 class="card-checkout-section__text_instructions">{{ $t('txt.resume__instructions-delivery') }}</h2>
                <p
                    v-if="
                        (!instructions?.length && !phone?.length && !hasDeliveryPacking) ||
                        (!email.length && hasDeliveryPacking)
                    "
                    class="card-checkout-section__text_contact"
                    :class="{ 'card-checkout-section__text_contact--red': hasDeliveryPacking }"
                >
                    {{ $t('txt.resume__contact-delivery') }}
                </p>
            </aside>
            <template v-if="instructions.length || phone.length || (email.length && hasDeliveryPacking)">
                <aside class="card-checkout-section__instructions">
                    <p class="card-checkout-section__text-info-instructions">{{ instructions }}</p>
                    <template v-if="phone.length || email.length">
                        <p class="card-checkout-section__text-info-instructions">
                            {{ $t('txt.resume__delivery_contact_additional') }}
                        </p>
                        <p v-if="phone.length" class="card-checkout-section__text-info-instructions">
                            <span class="card-checkout-section__text-info-instructions">
                                +{{ countryCodeSelected.country_code }}
                            </span>
                            {{ visiblePhone }}
                        </p>
                        <p v-if="email.length" class="card-checkout-section__text-info-instructions">
                            {{ email }}
                        </p>
                    </template>
                </aside>
            </template>
        </div>
        <button @click="showModal">
            {{ $t('cta.change') }}
        </button>
    </section>

    <BackgroundWithTransition :is-active="showInstructions" content-centered @on-bg-click="showInstructions = false">
        <TheModalContent class="modal-instructions">
            <template #header>
                <BaseHeader
                    :text="$t('txt.resume__instructions-delivery')"
                    :text-size="20"
                    with-default-close
                    @on-close="showInstructions = false"
                />
            </template>
            <section class="modal-instructions__content">
                <p class="delivery-instructions__subtitle">
                    {{ $t('txt.resume__delivery_title') }}
                </p>
                <TextAreaInstructions
                    v-model="localInstructions.text"
                    :max-length="200"
                    :placeholder="$t('txt.resume__delivery_placeholder')"
                />
                <p class="delivery-instructions__contact-additional">
                    {{ $t('txt.resume__delivery_contact_additional') }}
                </p>
                <span class="delivery-instructions__description"> {{ $t('txt.resume__delivery_description') }}</span>
                <InputPhoneWithLabel
                    v-model="localInstructions.phone"
                    :show-label="false"
                    @on-validate="(isValid: boolean) => (isValidNumber = isValid)"
                />
                <TiptiInputText
                    v-if="hasDeliveryPacking"
                    v-model="localInstructions.email"
                    class="email_instructions"
                    :placeholder="$t('txt.resume__delivery_description')"
                    label="email"
                    type="email"
                />
            </section>

            <template #footer>
                <footer class="modal-instructions-footer">
                    <BtnSolid
                        :is-disabled="btnSaveInstructionsIsDisabled"
                        :txt="$t('cta.save')"
                        @click="saveInstruction"
                    />
                </footer>
            </template>
        </TheModalContent>
    </BackgroundWithTransition>
</template>

<style lang="scss" scoped>
@import '../menu/menu-checkout.scss';

.card-checkout-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $margin-sm;

    &__instructions {
        display: flex;
        flex-direction: column;
        gap: $margin-sm;
    }

    &__text_instructions {
        @include headline5;
        color: $orange;
        font-weight: 600;
        font-size: 14px;
    }

    &__text_contact {
        @include headline5;
        font-size: 14px;

        &--red {
            color: $error;
        }
    }

    &__text-info-instructions {
        @include headline5;
        font-size: 14px;
        margin: $margin-none;
    }
}

.modal-instructions-footer {
    margin: 1rem;
    display: flex;
    justify-content: center;
}

.delivery-instructions {
    padding: $padding-xlg;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    &__header {
        @include caption;
        padding: $padding-none $padding-md;
    }

    &__subtitle {
        @include caption;
        color: $dark-grey;
        margin-bottom: $margin-lg;
    }

    &__contact-additional {
        @include caption;
        font-weight: 600;
    }

    &__description {
        @include caption;
    }
}

.modal-instructions {
    padding: $padding-md;

    &__content {
        display: flex;
        flex-direction: column;
        gap: $margin-sm;
        padding: $padding-lg;
    }
}

.email_instructions {
    margin-top: 0.5rem;
}

.no-instruction {
    background-color: $pink;
}
</style>
