import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_alert_pdf = _resolveComponent("alert-pdf")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "terms-and-conditions",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.openTermsAndConditions && $options.openTermsAndConditions(...args)))
    }, [
      _createElementVNode("p", {
        class: "terms-and-conditions__txt",
        style: _normalizeStyle({ color: $props.color })
      }, _toDisplayString(_ctx.$t("general['terms-and-conditions']")), 5),
      _createVNode(_component_icon_arrow, {
        color: $props.color,
        size: 0.5
      }, null, 8, ["color"])
    ]),
    ($data.showModal)
      ? (_openBlock(), _createBlock(_component_alert_pdf, {
          key: 0,
          title: _ctx.$t('general.terms-and-conditions'),
          onOnClose: $options.closeTermsAndConditions
        }, null, 8, ["title", "onOnClose"]))
      : _createCommentVNode("", true)
  ], 64))
}