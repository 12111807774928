<template>
    <carousel-tipti
        class="list-subcategories"
        :has-arrow-button="false"
        :is-loading="false"
        :length="subcategories?.length"
        :show-arrows="false"
        has-margin
        is-last-page
    >
        <nav
            v-for="subcategory in subcategories"
            :key="subcategory.id"
            class="list-subcategories-title"
            :class="{ 'list-subcategories-title--selected': subcategory.id === subcategoryIdSelected }"
            @click="onSelectSubcategory(subcategory)"
        >
            <h2>{{ subcategory.name }}</h2>
        </nav>
    </carousel-tipti>
</template>

<script lang="ts" setup>
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';
import { CategoryModel } from '@/models/product/CategoryModel';
import { PromotionsCategoriesModel } from '@/models/promotions/PromotionsCategoriesModel';
import { Identifiers } from '@/enums/identifiers';
import { PropType, ref } from 'vue';
import { useApp } from '@/composables/useApp';

defineProps({
    subcategories: {
        type: Object as PropType<Array<CategoryModel | PromotionsCategoriesModel>>,
        required: true,
    },
});
const { $store } = useApp();
const subcategoryIdSelected = ref(Identifiers.ALL);
const $emit = defineEmits<(e: 'on-select-subcategory', value: CategoryModel | PromotionsCategoriesModel) => void>();
const onSelectSubcategory = (subcategory: CategoryModel | PromotionsCategoriesModel): void => {
    subcategoryIdSelected.value = subcategory.id;
    $store.commit('categories/setSubcategorySelected', { subcategory, isFromSeeMoreBtn: false });
    $emit('on-select-subcategory', subcategory);
};
</script>

<style lang="scss" scoped>
@import 'list-subcategories-body';
</style>
