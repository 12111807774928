<template>
    <base-modal :has-close="false" :hasHeader="hasHeader" :title="title">
        <div class="model-loading">
            <triple-loading />
            <h1 v-if="title.length && !hasHeader" class="model-loading__title">{{ title }}</h1>
            <h2 v-if="body.length" :class="{ 'model-loading__text--light': dontClose }" class="model-loading__text">
                {{ body }}
            </h2>
            <p v-if="dontClose" class="model-loading__text">{{ $t('general.no_close_tab').toUpperCase() }}</p>
        </div>
    </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import TripleLoading from '@/components/loading/TripleLoading.vue';

export default {
    name: 'loading-modal',
    components: { BaseModal, TripleLoading },
    props: {
        title: {
            type: String,
            default: '',
        },
        body: {
            type: String,
            default: '',
        },
        hasHeader: {
            type: Boolean,
            default: false,
        },
        dontClose: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss" scoped>
@import './loding-modal.scss';
</style>
