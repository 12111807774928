import { createI18n } from 'vue-i18n';
import { messages } from './messages';
import store from '../store';

// type MessageSchema = typeof EN;
// export default createI18n<[MessageSchema], 'en' | 'es'>({
export default createI18n({
    locale: store.getters['user/user']?.['defaultLanguage']?.toLowerCase() ?? 'es',
    fallbackLocale: 'es',
    messages,
    legacy: false,
});
