import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-777fe9f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar sidebar__bar" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "sidebar-option__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "block" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.options, (option, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["sidebar-option cursor-pointer", {
                    'bar-option--active': $options.optionSelected === option.code,
                }]),
            onClick: ($event: any) => (_ctx.$router.push({ name: option.path }))
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(option.component), {
              class: _normalizeClass({ 'bar-option__active-logo': $options.optionSelected === option.code }),
              color: $options.optionSelected === option.code ? '#FFF' : '#C7C7C7',
              size: option.size
            }, null, 8, ["class", "color", "size"])),
            _createElementVNode("p", _hoisted_3, _toDisplayString(option.title), 1)
          ], 10, _hoisted_2))
        }), 128))
      ]),
      _: 1
    })
  ]))
}