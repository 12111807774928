<template>
    <base-icon
        :icon-name="$t('icon.credit_card')"
        :width="width"
        :height="height"
        viewBox="0 0 33 33"
        :iconColor="color"
    >
        <path
            d="M3.70613 7.8778L18.8998 0.24586C19.8167 -0.214574 20.9538 -0.0138451 21.6271 0.726922L34.6858 15.0998C35.4764 15.9702 35.4608 17.2502 34.6492 18.1036L20.5342 32.9414C19.166 34.246 16.8751 34.1122 15.6918 32.6587L2.01139 14.6076C0.296521 12.3446 1.09031 9.19166 3.70613 7.8778Z"
        />
        <g style="mix-blend-mode:multiply">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                fill="#C7C7C7"
                d="M0.893786 14.9164L10.7813 1.72304C11.3778 0.926999 12.4899 0.628682 13.4484 1.00792L32.0432 8.36164C33.1693 8.807 33.7728 9.9569 33.4596 11.0595L28.0116 30.2353C27.4191 31.9747 25.3085 32.8167 23.5508 32.0154L2.62652 21.6378C0.00332451 20.3366 -0.808325 17.1879 0.893786 14.9164Z"
            />
        </g>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="#606060"
            d="M7.65541 5.89403L15.6496 1.87848L26.6123 6.21391L32.8772 13.1093L30.0995 22.8863L22.0997 31.2958L10.1776 25.3829L2.01134 14.6077C1.85496 14.4013 1.71943 14.1875 1.60425 13.9684L7.65541 5.89403Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-credit-card',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 20 * this.size;
        },
        height(): number {
            return 20 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
