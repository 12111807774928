<template>
    <billing-form
        v-if="showBillingForm"
        :bill-to-edit="billToEdit"
        :country-id="countryId"
        :is-from-modal="showModal"
        @on-add-success="onAddNewBillData"
    />
    <bills-as-list
        v-else
        :is-from-modal="showModal"
        :show-check="showCheck"
        @open-bill-form="activateBillingForm"
        @on-edit-bill="onEditBill"
        @on-check="onCheck"
    />
</template>

<script lang="ts">
import BillsAsList from './components/billsAsList/BillsAsList.vue';
import BillingForm from './components/billingForm/BillingForm.vue';

export default {
    name: 'Bills',
    components: {
        BillsAsList,
        BillingForm,
    },
    props: {
        showCheck: {
            type: Boolean,
            default: false,
        },
        isFormShowing: {
            type: Boolean,
            default: false,
        },
        showModal: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-check', 'on-billing-form-showing'],
    data() {
        return {
            showBillingForm: false,
            billToEdit: undefined,
            countryId: undefined,
        };
    },
    watch: {
        isFormShowing(state) {
            this.showBillingForm = state;
        },
    },
    methods: {
        activateBillingForm(state: boolean) {
            this.billToEdit = undefined;
            this.countryId = undefined;
            this.showBillingForm = state;
            if (this.showBillingForm) this.$emit('on-billing-form-showing');
        },
        onEditBill(billSelected): void {
            const { bill } = billSelected;
            const { countryId } = billSelected;
            this.activateBillingForm(true);
            this.billToEdit = bill;
            this.countryId = countryId;
        },
        async onAddNewBillData(): Promise<void> {
            this.activateBillingForm(false);
        },
        async onCheck(billSelected): Promise<void> {
            const { bill } = billSelected;
            await this.$emit('on-check', bill);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.bills-container--bottom-sheet {
    height: 100%;
    width: 100%;
}
</style>
