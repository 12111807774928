class BenefitsAndPromotionsModel {
    readonly franchiseId: number;
    readonly franchiseName: string;
    readonly title: string;
    readonly description: string;
    readonly acceptButtonText: string;
    readonly image: string;
    readonly termsAndConditions: string;
    readonly stockItemId: number;
    readonly available: boolean;
    readonly discountId: number;
    readonly discountName: string;
    askPermission: boolean;

    constructor(
        askPermission: boolean,
        franchiseId: number,
        franchiseName: string,
        title: string,
        description: string,
        acceptButtonText: string,
        termsAndConditions: string,
        image: string,
        discountId: number,
        discountName: string,
    ) {
        this.askPermission = askPermission;
        this.franchiseId = franchiseId;
        this.franchiseName = franchiseName;
        this.title = title;
        this.description = description;
        this.acceptButtonText = acceptButtonText;
        this.image = image;
        this.termsAndConditions = termsAndConditions;
        this.discountId = discountId;
        this.discountName = discountName;
    }
}

const toBenefitsAndPromotionsModel = (data: object) => {
    return !Object.keys(data).length
        ? null
        : new BenefitsAndPromotionsModel(
              data['ask_permission'] ?? false,
              data['franchise_id'],
              data['franchise_name'] ?? '',
              data['title'] ?? '',
              data['description'] ?? '',
              data['accept_button_text'] ?? '',
              data['terms_and_conditions'] ?? '',
              data['image'] ?? '',
              data['discount']['id'],
              data['discount']['name'] ?? '',
          );
};

export { BenefitsAndPromotionsModel, toBenefitsAndPromotionsModel };
