interface ProductBlockInterface {
    data: object;
    block: string;
}

export const getProductBlock = ({ data, block }: ProductBlockInterface) => {
    if (!data || !Object.keys(data).length || !block?.length) return null;

    if (data['item'])
        return data['item']['product'][block] && data['item']['product'][block].length
            ? data['item']['product'][block]
            : null;
    if (data['original_unitary_price_with_tax']) return data[block] ? data[block] : null;
    if (data['stockitem'])
        return data['stockitem']['product'][block] && data['stockitem']['product'][block].length
            ? data['stockitem']['product'][block]
            : null;
    return data['product'][block] && !!data['product'][block] ? data['product'][block] : null;
};

export const getItemBlock = ({ data, block }: ProductBlockInterface) => {
    if (!data || !Object.keys(data).length || !block?.length) return null;
    if (data['item']) return data['item'][block] ?? null;
    if (data['original_unitary_price_with_tax']) return data[block] ?? null;
    if (data['stockitem']) return data['stockitem'][block] ?? null;
    return data[block] ?? null;
};
