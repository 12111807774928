import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_state_feedback = _resolveComponent("modal-state-feedback")!
  const _component_bottom_sheet_feedback = _resolveComponent("bottom-sheet-feedback")!

  return (!$options.isMobile)
    ? (_openBlock(), _createBlock(_component_modal_state_feedback, {
        key: 0,
        body: $props.body,
        "has-close": $props.hasClose,
        "has-header": $props.hasHeader,
        "is-failure": $props.isFailure,
        "is-loading": $props.isLoading,
        "is-success": $props.isSuccess,
        title: $props.title,
        "cta-text": $props.ctaText,
        "link-text": $props.linkText,
        "show-action": $props.showAction,
        onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close'))),
        onOnAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-action'))),
        onOnTextAction: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-text-action')))
      }, null, 8, ["body", "has-close", "has-header", "is-failure", "is-loading", "is-success", "title", "cta-text", "link-text", "show-action"]))
    : (_openBlock(), _createBlock(_component_bottom_sheet_feedback, {
        key: 1,
        "is-active": true,
        onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-close'))),
        onOnAction: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-action'))),
        onOnTextAction: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('on-text-action'))),
        "is-success": $props.isSuccess,
        "is-loading": $props.isLoading,
        "is-failure": $props.isFailure,
        "cta-text": $props.ctaText,
        "link-text": $props.linkText,
        "show-action": $props.showAction,
        title: $props.title,
        body: $props.body
      }, null, 8, ["is-success", "is-loading", "is-failure", "cta-text", "link-text", "show-action", "title", "body"]))
}