import { AddressModel } from '@/models/address/AddressModel';
import { parseString } from 'xml2js';
import { logger } from '@/utils/logger';
import { CityModel, toCityModel } from '@/models/location/CityModel';
import { toCountryAddresses } from '@/models/address/CountryAddressesModel';
import { toClientProfileModel } from '@/models/user/ClientProfileModel';
import { PrimeInformationModel } from '@/models/user/PrimeInformationModel';
import { getCountry, getCity } from '@/utils/locationUtils';
import { toUnavailableProductsByHomologationModel } from '@/models/product/UnavailableProductsByHomologationModel';

const axios = require('axios').default;

export default {
    updateAddresses({ commit, rootGetters, getters }, data): void {
        commit('countryAddresses', data?.map((country: object) => toCountryAddresses(country)) ?? []);
        const actualAddress: AddressModel = rootGetters['user/user']?.currentAddress ?? getters['addressDefault'];
        commit('user/userLocation', actualAddress, { root: true });
        commit('external/countrySelected', actualAddress?.city?.country, { root: true });
    },
    async getMapData({ dispatch }): Promise<void> {
        const { data } = await axios.get(process.env.VUE_APP_API_GOOGLE_MAPS_URL);
        await parseString(data, async (err, result) => {
            if (!err) await dispatch('parsedData', result);
            return;
        });
    },
    async getCitiesByCountry({ rootGetters, commit, getters }): Promise<void> {
        try {
            const url = `v2/countries/${rootGetters['external/countrySelected']?.id ?? 1}/sectors?group-by=cities`;
            const { data } = await rootGetters['network/axios'].get(url);
            commit(
                'cities',
                data
                    ?.map(city => toCityModel(city))
                    ?.map((city: CityModel) => {
                        const polygon = getters['polygons']?.find(polygon => polygon.cityID == city.id);
                        if (polygon) city['referenceCoordinate'] = polygon['coords'][0];
                        return city;
                    }) ?? [],
            );
        } catch (err) {
            logger(`GET_SECTORS_BY_COUNTRY ${err}`);
        }
    },
    async parsedData({ dispatch, commit, getters }, googleResult: object): Promise<void> {
        await dispatch('getCitiesByCountry');
        const paths = [];
        if (!googleResult) logger('NOT CONTENT OF GOOGLE MAPS');
        else {
            const realData = googleResult['kml']['Document'][0]['Folder'][0]['Placemark'];
            realData.forEach(city => {
                // Get polygons
                const coordinates = city.Polygon?.[0].outerBoundaryIs[0].LinearRing[0].coordinates[0].split(',0\n');
                const locationData = city.name?.[0].split('.');

                const parserCoordinates = coordinates?.reduce((acc, coordinate) => {
                    const points = coordinate.split(',');
                    if (points.length > 1) acc.push({ lng: parseFloat(points[0]), lat: parseFloat(points[1]) });
                    return acc;
                }, []);

                // TODO: ADD A MODEL FOR THIS DATA
                paths.push({
                    coords: parserCoordinates,
                    countryID: locationData[0],
                    cityID: locationData[1],
                    sectorID: locationData[2],
                });
            });
        }
        commit('polygons', paths);
        commit(
            'cities',
            getters['cities']?.map((city: CityModel) => {
                const polygon = getters['polygons']?.find(polygon => polygon.cityID == city.id);
                if (polygon) city['referenceCoordinate'] = polygon['coords'][0];
                return city;
            }),
        );
    },
    async updateDataWithDefaultAddress({ dispatch, commit }, address: AddressModel) {
        try {
            commit('external/countrySelected', address.city?.country, { root: true });
            await dispatch('cart/fetchQuickCart', null, { root: true });
            commit('user/userLocation', address, { root: true });
        } catch (err) {
            logger('UPDATE_DATA_WITH_DEFAULT_ADDRESS', err);
        }
    },
    async addNewAddress(
        { rootGetters, dispatch, getters },
        payload: { data: AddressModel; screen: string; section: string },
    ): Promise<void> {
        try {
            const url = 'v2/client_profile/address?group-by=country';
            const { data } = await rootGetters['network/axios'].post(url, payload.data.toMap());
            const updatedCountryAddress = data?.map((country: object) => toCountryAddresses(country));
            const updatedAllAddresses = [];
            updatedCountryAddress.forEach(element => {
                updatedAllAddresses.push(...element.addresses);
            });
            const createdAddress = updatedAllAddresses.filter(function(address) {
                return !getters['allAddresses'].some(function(address2) {
                    return address.id == address2.id;
                });
            })[0];

            dispatch(
                'segment/addressSaved',
                {
                    location: {
                        city: createdAddress?.city?.name ?? (await getCity()),
                        country: createdAddress?.city?.country?.name ?? (await getCountry()),
                        sector_id: createdAddress?.sector?.id ?? '',
                        sector: createdAddress?.sector?.name ?? '',
                    },
                    origin: {
                        screen: payload.screen,
                        section: payload.section,
                    },
                },
                { root: true },
            );

            if (!getters['countryAddresses'].length) {
                dispatch(
                    'segment/completeRegistration',
                    {
                        location: {
                            city: createdAddress?.city?.name ?? '',
                            country: createdAddress?.city?.country?.name ?? '',
                            sector_id: createdAddress?.sector?.id ?? '',
                            sector: createdAddress?.sector?.name ?? '',
                        },
                    },
                    { root: true },
                );
            }
            dispatch('updateAddresses', data);
        } catch (err) {
            throw Error(err);
        }
    },
    async editAddress({ rootGetters, dispatch, getters }, payload): Promise<void> {
        try {
            const url = `v2/client_profile/address/${payload.address.id}/?group-by=country`;
            const { data } = await rootGetters['network/axios'].put(url, payload.address.toMap());
            dispatch('updateAddresses', data);
            const addressUpdated = getters['allAddresses'].find(address => address.id === payload.address.id);
            dispatch(
                'segment/addressUpdated',
                {
                    location: {
                        city: addressUpdated?.city?.name ?? '',
                        country: addressUpdated?.city?.country?.name ?? '',
                        sector_id: addressUpdated?.sector?.id ?? '',
                        sector: addressUpdated?.sector?.name ?? '',
                    },
                    origin: {
                        screen: payload.screen,
                        section: payload.section,
                    },
                },
                { root: true },
            );
        } catch (err) {
            throw Error(err);
        }
    },
    async countryAddresses({ rootGetters, dispatch }): Promise<void> {
        try {
            const url = 'client_profile/address/all?group-by=country';
            const { data } = await rootGetters['network/axios'].get(url);
            dispatch('updateAddresses', data);
        } catch (err) {
            logger('COUNTRY_ADDRESSES', err);
        }
    },
    async deleteAddress({ rootGetters, dispatch }, id: number): Promise<void> {
        try {
            const url = `v2/client_profile/address/${id}/?group-by=country`;
            const { data } = await rootGetters['network/axios'].delete(url);
            dispatch('updateAddresses', data);
        } catch (err) {
            logger('DELETE_ADDRESS', err);
        }
    },
    async updateCurrentSector({ rootGetters, commit, dispatch }, payload): Promise<void> {
        try {
            logger('UPDATE_CURRENT_SECTOR', payload);
            if (!payload.sector) return;
            const url = 'client_profile/';
            const data = {
                user: {},
                current_address: payload.address.id,
            };
            const response = await rootGetters['network/axios'].put(url, data);
            const clientProfile = toClientProfileModel(response.data);
            let primeInformation: PrimeInformationModel;
            /// Update default payment types
            this.dispatch('payments/defaultPaymentsType');
            /// Get information of cambrella subscription
            this.dispatch('cambrellas/cambrellaMembership');
            if (clientProfile.isPrime) primeInformation = await dispatch('user/getPrimeData', null, { root: true });
            commit('user/user', toClientProfileModel(response.data, primeInformation), { root: true });
            dispatch('segment/detailsPopulated', null, { root: true });
            this.dispatch('segment/addressSelected', {
                location: {
                    city: payload.address?.city?.name ?? '',
                    country: payload.address?.city?.country?.name ?? '',
                    sector_id: payload.address?.sector?.id ?? '',
                    sector: payload.address?.sector?.name ?? '',
                },
                user: {
                    user_id: rootGetters['user/user']?.userId,
                    client_id: rootGetters['user/user']?.id,
                    email: rootGetters['user/user']?.email,
                    first_name: rootGetters['user/user']?.name,
                    last_name: rootGetters['user/user']?.lastname,
                },
                origin: {
                    screen: payload.screen,
                    section: payload.section,
                },
            });
        } catch (err) {
            throw Error(err);
        }
    },
    async getUnavailableProductsByLocation({ rootGetters, commit, getters }): Promise<boolean> {
        try {
            const _userLocation = rootGetters['user/userLocation'];
            const _url = `v2/products/new/retailer/sector/${_userLocation?.sector?.id}/not_available`;
            const response = await rootGetters['network/axios'].post(_url);
            if (!response?.data) commit('product/unavailableProductsByHomologation', [], { root: true });
            const _data = response?.data
                .map(element => {
                    return toUnavailableProductsByHomologationModel(element);
                })
                .filter(element => element?.items?.length);

            commit('product/unavailableProductsByHomologation', _data, { root: true });
            ///Return true if has product not available in new retailer
            return _data?.length > 0;
        } catch (err) {
            logger('GET_UNAVAILABLE_PRODUCT_BY_LOCATION', err);
            commit('product/unavailableProductsByHomologation', [], { root: true });
            return false;
        }
    },
};
