class PrimeInformationModel {
    available: boolean;
    isTrial: boolean;
    isCancelled: boolean;
    primeTypeBase: number;
    tiptiCardPrimeId: number;
    dateNextPayment: string;
    isExclusive: boolean;

    constructor(
        available: boolean,
        isTrial?: boolean,
        isCancelled?: boolean,
        primeTypeBase?: number,
        tiptiCardPrimeId?: number,
        dateNextPayment?: string,
        isExclusive?: boolean,
    ) {
        this.available = available;
        this.isTrial = isTrial;
        this.isCancelled = isCancelled;
        this.primeTypeBase = primeTypeBase;
        this.tiptiCardPrimeId = tiptiCardPrimeId;
        this.dateNextPayment = dateNextPayment;
        this.isExclusive = isExclusive;
    }
}
const toPrimeInformationModel = (data: object): PrimeInformationModel => {
    return data
        ? new PrimeInformationModel(
              data['available'] ?? false,
              data['is_trial'],
              data['is_cancelled'] ?? false,
              data['prime_type_base'],
              data['id'],
              data['date_next_payment'],
              data['is_exclusive'] ?? false,
          )
        : null;
};

export { PrimeInformationModel, toPrimeInformationModel };
