<template>
    <section>
        <div class="extra-item-order-state">
            <icon-circle class="order-state__circle" :color="'#CFDE00'" size="25px" />
            <h2 class="extra-item-order-state__number">
                {{ $t('txt.extra_items_current_order') }} #
                <span class="extra-item-order-state__number--id">
                    {{ currentOrder.id }}
                </span>
            </h2>
            <icon-and-text class="no_padding" :text="currentOrder?.orderInformation?.city">
                <template #icon>
                    <icon-location :size="0.5" color="#707070" />
                </template>
            </icon-and-text>
            <icon-and-text class="no_padding" :text="currentOrder?.retailer.name">
                <template #icon>
                    <icon-store :size="0.5" color="#707070" />
                </template>
            </icon-and-text>
            <icon-and-text class="no_padding" :text="deliveryText">
                <template #icon>
                    <icon-schedule :size="0.5" color="#707070" />
                </template>
            </icon-and-text>
            <btn-chat
                v-if="canChat"
                class="extra-item-order-state__chat"
                :messages-unread="totalUnreadMessages"
                @click="goToChat"
            />
            <div v-else></div>
        </div>
        <div class="extra-item-order-state--mobile">
            <div class="information_container">
                <h2 class="extra-item-order-state__number">
                    {{ $t('txt.extra_items_current_order') }} #
                    <span class="extra-item-order-state__number--id">
                        {{ currentOrder.id }}
                    </span>
                </h2>
                <div class="information_container__delivery_info">
                    <icon-and-text class="no_padding" :text="currentOrder?.orderInformation?.city">
                        <template #icon>
                            <icon-location :size="0.5" color="#707070" />
                        </template>
                    </icon-and-text>
                    <icon-and-text class="no_padding" :text="deliveryText">
                        <template #icon>
                            <icon-schedule :size="0.5" color="#707070" />
                        </template>
                    </icon-and-text>
                </div>
            </div>
            <btn-chat
                v-if="canChat"
                class="extra-item-order-state__chat"
                :messages-unread="totalUnreadMessages"
                @click="goToChat"
            />
            <div v-else></div>
        </div>
    </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { logger } from '@/utils/logger';
import IconLocation from '@/components/icons/IconLocation.vue';
import IconCircle from '@/components/icons/IconCircle.vue';
import IconStore from '@/components/icons/IconStore.vue';
import IconAndText from '@/components/iconAndText/IconAndText.vue';
import BtnChat from '@/components/buttons/BtnChat.vue';
import { OrderState } from '@/enums/orderState';
import IconSchedule from '@/components/icons/IconSchedule.vue';
import { useDays } from '@/composables/useDays';
import { OrderResumenModel } from '@/models/order/OrderResumen';
import { useChat } from '@/composables/useChat';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'TheExtraItemOrderState',

    components: {
        IconSchedule,
        IconAndText,
        IconLocation,
        IconCircle,
        IconStore,
        BtnChat,
    },
    setup() {
        const { toStringCalendar } = useDays();
        const { loadChannelList, isConnected: isConnectedToSendbird, groupChannels } = useChat();
        return {
            isConnectedToSendbird,
            toStringCalendar,
            loadChannelList,
            groupChannels,
        };
    },
    data() {
        return {
            isLoading: false,
            isDisable: false,
        };
    },
    watch: {
        isConnectedToSendbird: async function (_) {
            if (this.oneOrder) await this.loadChannelList([this.currentOrder.id]);
        },
    },
    computed: {
        ...mapGetters({
            baseConfiguration: 'user/baseConfiguration',
            order: 'extraItems/order',
        }),
        currentOrder(): OrderResumenModel {
            return this.order;
        },
        deliveryText(): string {
            return (
                this.$t("txt['retailer__next-delivery']") + ' ' + this.toStringCalendar(this.currentOrder?.deliveryTo)
            );
        },
        canChat(): boolean {
            if (!this.currentOrder?.shopper || !this.baseConfiguration.chatServiceEnabled) return false;
            return [
                OrderState.SHOPPER_ASSIGNED,
                OrderState.SHOPPER_BUYING,
                OrderState.SHOPPER_DELIVERING,
                OrderState.SHOPPER_IN_DESTINY,
                OrderState.ORDER_WAITING_REPLACED_CONFIRMATION,
                OrderState.PAYMENT_REJECTED,
                OrderState.EXTRA_ITEMS_PAYMENT_IN_PROCESS,
                OrderState.STORE_PURCHASE_COMPLETED,
            ].includes(this.currentOrder?.state);
        },
        time(): string {
            return this.currentOrder?.delivery?.toString();
        },
        totalUnreadMessages() {
            return (
                this.groupChannels?.find((channel) => channel?.url == this.currentOrder?.id.toString())
                    ?.unreadMessageCount ?? 0
            );
        },
    },
    created(): void {
        this.loadChannelList([this.currentOrder?.id]);
    },
    methods: {
        goToChat(): void {
            try {
                this.$store.commit('orders/isSelectedOrder', true);
                this.$router.push({
                    name: appRoutesMap.OrderMap.ChatTab,
                    params: {
                        orderId: this.currentOrder.id,
                    },
                });
            } catch (err) {
                logger('GO_TO_CHAT', err);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import './the-order-state.scss';

.extra-item-order-state {
    display: grid;
    grid-template-columns: auto 5fr repeat(2, 1.5fr) 2fr 1fr;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    gap: $margin-md;
    @include tablet {
        display: none;
    }

    &__number {
        @include body2;
        justify-self: flex-start;
        color: $orange;
        font-weight: 600;
        margin-right: $margin-md;

        &--id {
            color: $txt;
        }
    }

    &__chat {
        border-left: $border 1px solid;
        padding-left: $margin-md;
    }

    &--mobile {
        display: none;
        @include tablet {
            display: grid;
            max-width: 100vw;
            grid-template-columns: 5fr 1fr;
            padding: $padding-sm;
        }
    }
}

.information_container {
    display: flex;
    flex-direction: column;

    &__delivery_info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $margin-sm;
    }
}

.no_padding {
    padding: unset;
    justify-self: flex-start;
}
</style>
