<template>
    <base-card class="flex-column" padding="0">
        <section-header :title="categorySelected?.name" has-back @on-back="$router.go(-1)" />
        <search-category
            class="lists-search"
            :origin-section="originSection"
            @redirect-on-search="redirectFromSearch"
        />
        <div class="category-with-subcategory">
            <category-with-subcategories
                :section="originSection"
                :show-promotional-banner="false"
                @go-to-featured-products="goToFeaturedProducts"
                @go-to-subcategory="goToSubcategory"
            />
        </div>
    </base-card>
</template>
<script lang="ts">
import CategoryWithSubcategories from '@/components/category/categoryWithSubcategories/CategoryWithSubcategories.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import SearchCategory from '@/components/category/searchCategory/SearchCategory.vue';
import { Sections } from '@/enums/sectionsOrigin';
import { RoutesOrigin } from '@/enums/routesOrigin';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { mapGetters } from 'vuex';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'list-category-with-subcategory',
    components: {
        CategoryWithSubcategories,
        SectionHeader,
        BaseCard,
        SearchCategory,
    },
    computed: {
        ...mapGetters({
            categorySelected: 'categories/getCategorySelected',
        }),
        originSection(): Sections {
            return Sections.MY_LISTS;
        },
        categoryId(): string {
            return this.categorySelected?.id;
        },
    },
    methods: {
        goToFeaturedProducts(): void {
            this.$router.push({
                name: appRoutesMap.list.listCategoryFeaturedProducts,
                query: {
                    name: this.categoryName,
                    categoryId: this.categoryId,
                },
            });
        },
        goToSubcategory(): void {
            this.$router.push({ name: appRoutesMap.list.listCategorySeeMore });
        },
        redirectFromSearch(query: string): void {
            this.$router.push({
                path: RoutesOrigin.LISTS_CATEGORY_PRODUCTS,
                query: {
                    searchOn: query,
                    categoryId: this.categoryId,
                },
                replace: query?.length > 0 && !this.$route.path.includes(RoutesOrigin.LISTS_CATEGORY_PRODUCTS),
            });
        },
    },
    setup() {
        const { retailerSelected } = useRetailer();
        return {
            retailerSelected,
        };
    },
};
</script>

<style lang="scss" scoped>
@import './list-category-with-subcategory.scss';
</style>
