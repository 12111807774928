<template>
    <base-icon :icon-name="$t('icon.chat')" :width="width" :height="height" viewBox="0 0 23 21" :iconColor="color">
        <path
            d="M10.0759 17.2103L9.73555 17.166L9.47953 17.3947C8.70759 18.0841 7.44693 18.7351 6.29467 19.235C6.31172 19.2021 6.32846 19.1694 6.34488 19.137C6.68972 18.4556 6.93075 17.8117 7.00634 17.4395L7.13139 16.8236L6.54693 16.5927C3.04688 15.2098 0.75 12.3213 0.75 9.09008L0.75 9.08984C0.748566 4.61363 5.21161 0.75 11.0396 0.75C16.866 0.75 21.3308 4.61368 21.3308 9.09008C21.3308 13.598 17.3501 17.3131 11.561 17.3131C11.0079 17.3131 10.6177 17.2809 10.0759 17.2103Z"
            stroke="#606060"
            stroke-width="1"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-message',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 20 * this.size;
        },
        height(): number {
            return 25 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
