import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3872dbf3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form_header" }
const _hoisted_2 = { class: "button-go-back__text cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_TabMultiOption = _resolveComponent("TabMultiOption")!
  const _component_BaseForm = _resolveComponent("BaseForm")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_section_footer = _resolveComponent("section-footer")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($data.isLoadingSchema)
      ? (_openBlock(), _createBlock(_component_base_loading, { key: 0 }))
      : (_openBlock(), _createElementBlock("form", {
          key: 1,
          class: "billing-form",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.submitBill && $options.submitBill(...args)), ["prevent"]))
        }, [
          _createVNode(_component_TabMultiOption, {
            modelValue: $data.documentType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.documentType) = $event)),
            class: "bill-type-from",
            options: $data.dropdownDocumentOptions
          }, null, 8, ["modelValue", "options"]),
          _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t('txt.billing__required_data')), 1),
          _createElementVNode("section", {
            class: _normalizeClass(["billing-form-inputs", { 'billing-form-inputs--column': $props.isFromModal }])
          }, [
            _createVNode(_component_BaseForm, {
              errors: $data.errorMessageBill,
              required: $data.requiredFields,
              schema: $options.formFields,
              value: $data.formData,
              onFormValues: $options.updateValues
            }, null, 8, ["errors", "required", "schema", "value", "onFormValues"])
          ], 2)
        ], 32)),
    ($props.isFromModal)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "button-go-back",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.back && $options.back(...args)))
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('txt.go-back')), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_section_footer, {
      class: "billing-form__footer",
      "with-shadow": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_btn_solid, {
          "is-disabled": $options.btnIsDisable,
          "is-loading": $data.isLoading,
          txt: _ctx.$t('cta.save'),
          "size-text": "18px",
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($data.isLoading ? null : $options.submitBill()))
        }, null, 8, ["is-disabled", "is-loading", "txt"])
      ]),
      _: 1
    }),
    _createVNode(_component_snack_bar, {
      body: $data.modalMessage,
      "is-active": $data.showModal,
      "is-failure": !$data.snackbarSuccess,
      "is-success": $data.snackbarSuccess,
      onOnSnackbarClose: $options.closeSnackBar
    }, null, 8, ["body", "is-active", "is-failure", "is-success", "onOnSnackbarClose"])
  ], 64))
}