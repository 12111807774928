<template>
    <div class="update-password">
        <icon-password-recovery :size="0.85" />
        <p class="subtitle">
            {{ $t("txt['recovery__password-insert-new_password-start']") }}
            <span> {{ $t("txt['recovery__password-insert-new_password-middle']") }} </span>
        </p>
        <div class="form-container">
            <label v-if="invalidPassword" class="label-input" for="password">
                {{ $t("txt['login__enter-you-password']") }}
            </label>
            <TiptiInputPassword
                v-model="password"
                :label="$t('general.password')"
                :label-id="$t('general.password')"
                :placeholder="$t('general.password')"
            />
        </div>
        <btn-solid :is-loading="isLoading" :txt="$t('general.send')" @click="sendPassword" />
    </div>
</template>

<script lang="ts" setup>
import IconPasswordRecovery from '@/components/icons/IconPasswordRecovery.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import LoginModel from '@/models/user/LoginModel';
import { inject, ref } from 'vue';
import { notifierKey } from '@/modules/notification/useNotifier';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import TiptiInputPassword from '@/components/inputs/primary/normal/TiptiInputPassword.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

const props = defineProps({
    code: {
        type: String,
        required: true,
    },
    email: {
        type: String,
        required: true,
    },
});
const $store = useStore();
const $router = useRouter();
const { t } = useI18n();
const notifier = inject(notifierKey);
const password = ref('');
const isLoading = ref(false);
const invalidPassword = ref(false);

const sendPassword = async () => {
    try {
        if (isLoading.value) return;
        if (!password.value.length) {
            invalidPassword.value = true;
            return;
        }
        isLoading.value = true;
        await $store.dispatch('user/resetPassword', { code: props.code, password: password.value });
        await $store.dispatch('user/login', new LoginModel(props.email, password.value));
        $router.push({ name: appRoutesMap.home });
    } catch (_) {
        notifier({
            type: 'ERROR',
            body: t('txt.recovery__password-send-password-fail'),
        });
    } finally {
        isLoading.value = false;
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.update-password {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 80%;
    margin: $margin-md auto;
}

.icon-mail {
    margin: $margin-md;
}

.subtitle {
    @include body1;
    width: 80%;
    text-align: center;
    margin: $margin-md auto;
}

.subtitle span {
    color: $orange;
    text-transform: uppercase;
}

.form-container {
    margin: $margin-md auto;
}

.form-container label {
    @include body2;
    color: $error;
}
</style>
