<template>
    <div class="information-quantity-modifier">
        <aside class="quantity-modifier">
            <button @click.stop="quantity--">
                <icon-decrement :color="decreaseColorBtn" :size="1.5" />
            </button>
            <div class="actions-quantity__input">
                <input
                    v-if="showByWeight"
                    v-model.number="visualQuantityOnWeightInput"
                    :readonly="showByWeight"
                    type="number"
                />
                <input v-else v-model.number="quantity" type="number" />
                <p class="actions-quantity__unit">{{ unitShownOnInput }}</p>
            </div>
            <button @click.stop="incrementQuantity">
                <icon-increment :size="1.5" color="#606060" />
            </button>
        </aside>
        <btn-solid
            :bg-color="ctaColor"
            :is-disabled="isLoading"
            :is-loading="isLoading"
            :txt="ctaQuantity"
            @click="onClickMainAction"
        />
    </div>
    <alert-configurable-delivery-products
        v-if="showConfigurableDeliveryAlert"
        :product-name="product.name"
        @on-close="showConfigurableDeliveryAlert = false"
    />
</template>
<script lang="ts" setup>
import { ProductModel } from '@/models/product/ProductModel';
import { isMobileBrowser } from '@/utils/utils';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import IconDecrement from '@/components/icons/IconDecrement.vue';
import IconIncrement from '@/components/icons/IconIncrement.vue';
import AlertConfigurableDeliveryProducts from '@/components/alerts/alertConfigurableDeliveryProducts/AlertConfigurableDeliveryProducts.vue';
import { computed, PropType, ref } from 'vue';
import { useProductQuantity } from '@/composables/useProductQuantity';
import { useI18n } from 'vue-i18n';
import { useUser } from '@/composables/useUser';
import { useStockItemSchedule } from '@/composables/useStockItemSchedule';

const props = defineProps({
    product: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
    isLoading: {
        type: Boolean,
        required: true,
    },
    isForReplacement: {
        type: Boolean,
        required: true,
    },
    showByWeight: {
        type: Boolean,
        required: true,
    },
    modelValue: {
        type: Number,
        required: true,
    },
});
const { t: $t } = useI18n();
const { isAuth, requireAuth } = useUser();
const { canAddProduct } = useStockItemSchedule();

const showConfigurableDeliveryAlert = ref(false);

const $emit = defineEmits<{
    (e: 'update:modelValue', value: number): void;
    (e: 'update-cart');
}>();

const quantity = computed({
    get: () => props.modelValue,
    set: (value) => $emit('update:modelValue', value),
});

const incrementQuantity = () => {
    isAuth.value ? quantity.value++ : (requireAuth.value = true);
};

const { productQuantityInCart, alreadyInCart } = useProductQuantity('PRODUCT_DETAIL');

const visualQuantityOnWeightInput = computed(() => props.product.unitQuantity * quantity.value);

const decreaseColorBtn = computed(() => {
    if (alreadyInCart.value && quantity.value === 1) return '#606060';
    return quantity.value <= 1 ? '#DFDFDF' : '#606060';
});

const unitShownOnInput = computed(() =>
    props.showByWeight ? props.product.unit.slice(0, 2) : props.product.unitStock ? 'U' : '',
);
const ctaColor = computed(() => (alreadyInCart.value && +quantity.value === 0 ? '#F03F51' : '#FF9012'));

const isQuantityInTheCart = computed(() => alreadyInCart.value && productQuantityInCart.value === quantity.value);
const isMobile = computed(() => isMobileBrowser());
const ctaQuantity = computed(() => {
    if (props.isForReplacement && !isMobile.value) return $t("general['select']");
    if (props.isForReplacement) return $t("cta['confirm-replace']");
    if (isQuantityInTheCart.value) return $t('cta.already_in_cart');
    if (!alreadyInCart.value) return $t("cta['add_to_cart']");
    return +quantity.value === 0 ? $t("cta['delete']") : $t('cta.update_quantity');
});

const onClickMainAction = () => {
    if (!isAuth.value) return (requireAuth.value = true);
    if (props.isLoading) return;
    if (props.product.uniqueDelivery && !canAddProduct.value) {
        showConfigurableDeliveryAlert.value = true;
        return;
    }
    $emit('update-cart');
};
</script>
<style lang="scss" scoped>
@import './the-quantity-modifier.scss';

.header__close-icon-back {
    display: none;
    @include phone {
        display: grid;
        align-self: center;
    }
}

.product-price-header {
    &__tag {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $margin-md;

        &--margin {
            margin: $margin-sm 0;
        }
    }

    &__label {
        @include caption;
        font-weight: 400;
        @include phone {
            font-size: 14px;
        }

        &--normal {
            @include body2;
        }

        &--special {
            @include body2;
            color: $magenta;
            font-weight: 600;
            @include phone {
                font-size: 16px;
            }
        }

        &--crossed {
            @include body1;
            text-decoration: line-through;
            margin-left: $margin-sm;
            font-size: 1rem !important;
        }
    }

    &__special {
        @include headline1;
        font-weight: 600;
    }

    @include phone {
        display: none;
    }
}

.product-name {
    @include tablet {
        @include text-lines(3);
    }
}
</style>
