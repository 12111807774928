import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { MyCouponsModel, toMyCouponsModel } from '@/models/coupons/MyCouponsModel';
import { logger } from '@/utils/logger';
import { shallowRef } from 'vue';

const myCoupons = shallowRef<MyCouponsModel[]>([]);

export const useCoupons = () => {
    const getCoupons = async () => {
        try {
            const { data } = await axiosInstance().get('v2/client/marketing/promotion-codes');
            myCoupons.value = data ? data.map((coupon: MyCouponsModel) => toMyCouponsModel(coupon)) : [];
        } catch (_) {
            logger(_);
        }
    };

    const applyCode = async (code: string): Promise<string> => {
        try {
            const { data } = await axiosInstance().post('v2/marketing/promotion-code', {
                code,
            });
            return data['message'];
        } catch (err) {
            logger('REDEEM_CODE');
            throw err.response?.data;
        }
    };

    return {
        myCoupons,
        getCoupons,
        applyCode,
    };
};
