import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill", "stroke"]
const _hoisted_2 = ["fill", "stroke"]
const _hoisted_3 = ["fill", "stroke"]
const _hoisted_4 = ["fill", "stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.delete'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 12 13",
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M10.6396 2.59743H8.32613V2.21184C8.32613 1.55084 7.7753 1 7.11429 1H4.85586C4.19486 1 3.64402 1.55084 3.64402 2.21184V2.59743H1.3305C1.16525 2.59743 1 2.70759 1 2.92793C1 3.09318 1.16525 3.25843 1.3305 3.25843H1.88134V10.5295C1.88134 11.4659 2.59743 12.182 3.53385 12.182H8.4363C9.37272 12.182 10.0888 11.4659 10.0888 10.5295V3.20335H10.6396C10.8049 3.20335 10.9151 3.09318 10.9151 2.92793C10.9151 2.76268 10.8049 2.59743 10.6396 2.59743ZM4.24994 2.21184C4.24994 1.88134 4.52536 1.60592 4.85586 1.60592H7.05921C7.38971 1.60592 7.66513 1.88134 7.66513 2.21184V2.59743H4.24994V2.21184ZM9.48289 10.5295C9.48289 11.0803 9.04222 11.5761 8.4363 11.5761H3.53385C2.98301 11.5761 2.48726 11.1354 2.48726 10.5295V3.20335H9.48289V10.5295Z",
        fill: $props.color,
        stroke: $props.color,
        "stroke-width": "0.26925"
      }, null, 8, _hoisted_1),
      _createElementVNode("path", {
        d: "M5.95752 10.4744C6.12277 10.4744 6.28802 10.3091 6.28802 10.1439V4.63549C6.28802 4.47024 6.12277 4.30499 5.95752 4.30499C5.79227 4.30499 5.6821 4.47024 5.6821 4.63549V10.1439C5.6821 10.3091 5.79227 10.4744 5.95752 10.4744Z",
        fill: $props.color,
        stroke: $props.color,
        "stroke-width": "0.26925"
      }, null, 8, _hoisted_2),
      _createElementVNode("path", {
        d: "M3.97455 10.0888C4.1398 10.0888 4.30505 9.9235 4.30505 9.75825V4.96597C4.30505 4.80072 4.1398 4.63547 3.97455 4.63547C3.80929 4.63547 3.64404 4.80072 3.64404 4.96597V9.75825C3.64404 9.97859 3.80929 10.0888 3.97455 10.0888Z",
        fill: $props.color,
        stroke: $props.color,
        "stroke-width": "0.26925"
      }, null, 8, _hoisted_3),
      _createElementVNode("path", {
        d: "M7.9956 10.0888C8.16085 10.0888 8.3261 9.9235 8.3261 9.75825V4.96597C8.3261 4.80072 8.16085 4.63547 7.9956 4.63547C7.83035 4.63547 7.6651 4.80072 7.6651 4.96597V9.75825C7.72018 9.97859 7.83035 10.0888 7.9956 10.0888Z",
        fill: $props.color,
        stroke: $props.color,
        "stroke-width": "0.26925"
      }, null, 8, _hoisted_4)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "iconColor"]))
}