import { ProductModel, toProductModel } from '@/models/product/ProductModel';
import { logger } from '@/utils/logger';
import { ProductGroupedModel } from '@/models/product/ProductGroupedModel';
import { ProductWithAttributeModel } from '@/models/product/ProductWithAttributeModel';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { HourModel } from '@/models/retailers/HourModel';
import { ProductSuggested } from '@/models/product/ProductSuggestied';
import { CategoryModel, NewCategoryModel } from '@/models/product/CategoryModel';
import { CartTypes } from '@/modules/core/types/CartType';

interface GetProductModel {
    retailerId: number;
    productId: number;
}

export default {
    async categoriesPromotions({ rootGetters, commit }, retailerId: number): Promise<CategoryModel[]> {
        try {
            const url = `v2/client_profile/promotion_personalized/retailer/${retailerId}/categories`;
            const { data } = await rootGetters['network/axios'].get(url);
            const subcategories = data.map((category) => new NewCategoryModel(category));
            commit('categories/promotionSubcategories', subcategories, { root: true });
            return subcategories;
        } catch (err) {
            logger('PROMOTIONS_AND_DISCOUNT', err);
            commit('categories/promotionSubcategories', [], { root: true });
        }
    },

    async checkFavorite({ rootGetters }, productId: number): Promise<boolean> {
        try {
            const url = `client_profile/item/${productId}/check_favorite/`;
            const response = await rootGetters['network/axios'].get(url);
            return response.data != '';
        } catch (err) {
            logger('CHECK_FAVORITE', err);
        }
    },
    async setProductFavorite({ dispatch, rootGetters }, payload): Promise<boolean> {
        /// * PAYLOAD OPTIONS
        // id: number,
        // quantity: number,
        // isFavorite: boolean,
        try {
            if (payload.isFavorite) {
                await dispatch('lists/deleteProductFromFavorites', { productId: payload.id }, { root: true });
                return false;
            }
            const url = 'v2/client/shopping-list/favorites';
            await rootGetters['network/axios'].post(url, {
                stockitem_id: payload.id,
                quantity: payload.quantity,
            });
            return true;
        } catch (err) {
            logger('SET_PRODUCT_FAVORITE', err);
        }
    },
    async getProductFromProductId({ rootGetters }, payload: GetProductModel): Promise<ProductModel> {
        try {
            const url = `v2/retailer/${payload.retailerId}/product/${payload.productId}`;
            const response = await rootGetters['network/axios'].get(url);
            return toProductModel(response.data.stockitems[0]);
        } catch (err) {
            logger('ERROR FETCHING PRODUCTS TO GUESS', err);
        }
    },

    async suggestedReplacement(
        { rootGetters },
        payload: { stockitemId: number; size: number; page: number },
    ): Promise<Record<string, any>> {
        try {
            ///*** IF DOES NOT HAVE PAGE -MEAN REQUEST JUST ONE PRODUCT- REQUEST SIZE 2 TO AVOID GETING THE SAME PRODUCT FOR REPLACEMENT
            const payloadSize = !payload.page ? 2 : payload.size ?? 15;
            const url = `v2/recommended_stock_item_search?stock_item_id=${payload.stockitemId}&page=${
                payload.page ?? 1
            }&page_size=${payloadSize}`;
            const { data } = await rootGetters['network/axios'].get(url);
            const recommendations = data?.['stock_items']?.map((product) => toProductModel(product)) ?? [];
            const isLastPage = data?.['page'] === data?.['total_pages'] ?? true;
            return { products: recommendations, isLastPage: isLastPage };
        } catch (err) {
            logger('SUGGESTED_REPLACEMENT', err);
            return { products: [], isLastPage: true };
        }
    },
    async getGroupedItems({ rootGetters, commit }, group_id: number): Promise<ProductGroupedModel> {
        const { data } = await rootGetters['network/axios'].get(`v2/stockitems/groups/stock/${group_id}/items`);
        const modelResult = ProductGroupedModel.fromJson(data);
        commit('itemDefault', modelResult.item_selected);
        commit('typeAttributes', modelResult.type_of_attributes);
        return modelResult;
    },
    setCurrentAttributeProduct({ rootGetters, commit }, payload: { attribute: ProductWithAttributeModel }): void {
        let product: ProductModel;

        const products: Array<ProductModel> = rootGetters['cart/products'];

        const isExtraItemCart = rootGetters['app/cartType'] === CartTypes.extraItem;
        const extraItems = rootGetters['extraItems/products'];

        function setOldInformation() {
            const oldProduct = isExtraItemCart
                ? extraItems?.find((internalProduct: ProductModel) => {
                      return internalProduct.id === product.id;
                  })
                : products?.find((internalProduct: ProductModel) => {
                      return internalProduct.id === product.id;
                  });
            product.productQuantity = oldProduct?.productQuantity ?? 0;
            product.note = oldProduct?.note;
            product.noteToShopperPhotoFile = oldProduct?.noteToShopperPhotoFile;
            product.noteToShopperPhoto = oldProduct?.noteToShopperPhoto;

            commit('currentProduct', product);
        }

        if (payload.attribute?.attribute?.length) {
            commit('itemSelected', payload.attribute.attribute[0]);
            product = payload.attribute.attribute[0].item;
            if (product) setOldInformation();
            return;
        }
        product = payload.attribute?.item;
        if (product) setOldInformation();
    },
    async productSchedules(
        { rootGetters },
        { retailer_id, sector_id, stock_id }: { retailer_id: number; sector_id: number; stock_id: number },
    ): Promise<Array<HourModel>> {
        const url = `v2/api/delivery/scheduled/retailer/${retailer_id}/sector/${sector_id}?stock_id=${stock_id}`;
        const { data } = await rootGetters['network/axios'].get(url);
        return data;
    },
    async productFirstSchedules(
        { rootGetters, commit },
        {
            retailer_id,
            sector_id,
            stock_id,
            product_name,
        }: { retailer_id: number; sector_id: number; stock_id: number; product_name: string },
    ): Promise<void> {
        try {
            const url = `v2/api/first_delivery/scheduled/retailer/${retailer_id}/sector/${sector_id}?stock_id=${stock_id}`;
            const { data } = await rootGetters['network/axios'].get(url);

            if (!Object.keys(data)?.length) return;

            const lastSchedule = data['schedules']?.[data['schedules']?.length - 1];
            if (!lastSchedule) {
                logger('PRODUCT_SCHEDULE', 'EMPTY');
                return;
            }
            const schedule = new TimeDeliveryModel({
                retailerId: retailer_id,
                stockItemId: stock_id,
                productName: product_name,
                date: data['date'],
                deliveryType: data['type'],
                express: lastSchedule?.['express'],
                deliveryFirstDate: lastSchedule?.['first_hour'],
                deliverySecondDate: lastSchedule?.['second_hour'],
                shippingCost: data['unique_delivery_shipping_cost'],
                originalShippingCost: data['original_unique_delivery_shipping_cost'],
            });
            commit('checkout/stockItemTimeDelivery', schedule, { root: true });
        } catch (err) {
            logger('PRODUCT_SCHEDULE', err);
        }
    },
    async suggestionsForUnAvailableProducts({ rootGetters, commit }, product: ProductModel): Promise<void> {
        try {
            ///*** when product out of stock is from order
            /// stockItemId is set different thant id...
            /// then use this to get suggestion
            const keyRequest = product.stockItemId !== product.id ? product.stockItemId : product.id;
            const url = `products-suggested/${keyRequest}/`;
            const { data } = await rootGetters['network/axios'].get(url);
            const suggestions = data.map((product: object) => toProductModel(product));
            commit(
                'suggestionsForUnAvailableProducts',
                new ProductSuggested({
                    suggestions,
                    unAvailableProduct: product,
                }),
            );
        } catch (err) {
            logger('PRODUCT_SUGGESTED', err);
            commit(
                'suggestionsForUnAvailableProducts',
                new ProductSuggested({
                    suggestions: [],
                    unAvailableProduct: product,
                }),
            );
        }
    },
};
