<template>
    <base-icon
        :height="height"
        :icon-name="$t('icon.shared_cart')"
        :iconColor="color"
        :width="width"
        viewBox="0 0 23 17"
    >
        <path
            :fill="color"
            d="M9.99261 12.4014C8.93395 12.4014 8.08203 13.2533 8.08203 14.3119C8.08203 15.3706 8.93395 16.2225 9.99261 16.2225C11.0513 16.2225 11.9032 15.3706 11.9032 14.3119C11.9028 13.2533 11.0509 12.4014 9.99261 12.4014ZM9.99261 14.9829C9.60527 14.9829 9.29555 14.6732 9.29555 14.2858C9.29555 13.8985 9.60527 13.5888 9.99261 13.5888C10.3799 13.5888 10.6897 13.8989 10.6897 14.2858C10.6897 14.6728 10.3799 14.9829 9.99261 14.9829Z"
        />
        <path
            :fill="color"
            d="M17.2992 12.4014C16.2406 12.4014 15.3887 13.2533 15.3887 14.3119C15.3887 15.3706 16.2406 16.2225 17.2992 16.2225C18.3579 16.2225 19.2098 15.3706 19.2098 14.3119C19.2098 13.2533 18.3579 12.4014 17.2992 12.4014ZM17.2992 14.9829C16.9119 14.9829 16.6022 14.6732 16.6022 14.2858C16.6022 13.8985 16.9119 13.5888 17.2992 13.5888C17.6866 13.5888 17.9963 13.8985 17.9963 14.2858C17.9963 14.6732 17.6866 14.9829 17.2992 14.9829Z"
        />
        <path
            :fill="color"
            d="M22.307 3.64946C22.1782 3.41736 21.92 3.31399 21.6618 3.31399H5.55249H5.50099L4.75206 1.0422C4.5972 0.474255 4.08074 0.0869141 3.5128 0.0869141H1.52498C1.21526 0.0869141 0.957031 0.370888 0.957031 0.680609C0.957031 0.99033 1.21526 1.2743 1.52498 1.2743H3.48705C3.59042 1.2743 3.64191 1.3258 3.66804 1.42916L6.86937 10.9294C7.02423 11.4974 7.54068 11.8847 8.10863 11.8847H8.39261H19.3385C19.7001 11.8847 20.0355 11.4716 20.1389 11.11C20.7069 9.01884 22.4107 4.3204 22.4107 4.3204C22.4618 4.14017 22.4361 3.88194 22.307 3.64946ZM13.077 7.59935C13.077 7.76671 13.01 7.93368 12.9434 8.06734L14.5488 9.30471C14.7495 9.13735 14.9838 9.03702 15.2511 9.03702C15.8199 9.03702 16.2878 9.47206 16.2878 10.0737C16.2878 10.6424 15.8199 11.1104 15.2511 11.1104C14.6824 11.1104 14.2145 10.6424 14.2145 10.0737C14.2145 9.90635 14.2815 9.73938 14.3481 9.60572L12.7427 8.36835C12.542 8.53571 12.3077 8.63567 12.0403 8.63567C11.4716 8.63567 11.0037 8.16768 11.0037 7.59897C11.0037 7.03027 11.4716 6.56228 12.0403 6.56228C12.3077 6.56228 12.5757 6.66224 12.7427 6.82997L14.3481 5.5926C14.2481 5.45894 14.2145 5.29159 14.2145 5.12461C14.2145 4.55591 14.6824 4.08792 15.2511 4.08792C15.8199 4.08792 16.2878 4.55591 16.2878 5.12461C16.2878 5.69332 15.8199 6.16131 15.2511 6.16131C14.9835 6.16131 14.7158 6.06135 14.5488 5.89361L12.9434 7.13098C13.0433 7.26502 13.077 7.43199 13.077 7.59935Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-shared-cart',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 32 * this.size;
        },
        height(): number {
            return 28 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
