export default {
    showMainSearchBlurPredictions(state, show: boolean) {
        state.showMainSearchBlurPredictions = show;
    },
    searchOriginSection(state: { searchOriginSection: string }, event: string) {
        state.searchOriginSection = event;
    },
    query(state, query: string) {
        state.query = query;
    },
};
