<template>
    <div class="user">
        <lottie-animation class="user__img" :src="AnimationEnum.REGARDS_HAND" height="2rem" width="2rem" />
        <p class="user__name">
            {{ `${$t('general.hello')}, ${profile?.name}` }}
        </p>
    </div>
</template>
<script lang="ts" setup>
import LottieAnimation from '@/components/animations/LottieAnimation.vue';
import { AnimationEnum } from '@/enums/animationEnum';
import { useUser } from '@/composables/useUser';

const { profile } = useUser();
</script>
<style lang="scss" scoped>
@import '@/assets/scss';

.user {
    display: flex;
    align-items: center;

    &__img {
        @include box-shadow;
        height: 3rem;
        padding: $padding-xsm;
        border-radius: $radius-circular;
    }

    &__name {
        @include subtitle1;
        font-weight: 600;
        margin-left: $margin-md;
        @include phone {
            font-size: 12px;
        }
    }
}
</style>
