
const __default__ = {
    name: 'overlay',
    props: {
        bgColor: {
            type: String,
            default: '#FFFFFF',
        },
    },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "eb175b2e": (_ctx.bgColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__