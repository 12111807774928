<template>
    <template v-for="index in 2" :key="index">
        <div class="attribute-skeleton-title">
            <base-bone :isShow="true" />
        </div>
        <div class="attribute-skeleton">
            <base-card-flat
                v-for="slide in maxSlides"
                :key="slide.slides"
                padding="0"
                class="attribute-skeleton__item"
                border-color="transparent"
                :border-radius="index === 2 ? '7px' : '100%'"
            >
                <base-bone />
            </base-card-flat>
        </div>
    </template>
</template>

<script>
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import BaseBone from '@/components/skeletons/BaseBone.vue';

export default {
    name: 'skeleton-attributes',
    components: { BaseCardFlat, BaseBone },
    props: {
        maxSlides: {
            type: Number,
            default: 3,
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'skeleton-attribute';
</style>
