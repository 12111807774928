const translate = (value, locale) => {
    let title;
    try {
        const json = JSON.parse(value);
        title = json[locale.toUpperCase()] ?? json[locale];
    } catch (_) {
        title = value;
    }
    return title;
};

const getEnumValue = (instance: Array<any>, parentId?: string, locale?: string) => {
    return instance
        .map(value => {
            if (parentId) {
                const parent = value['enum'][0].split('-');
                if (parseInt(parent[1]) === parseInt(parentId)) {
                    return {
                        title: translate(value['title'], locale),
                        value: value['enum'][0],
                        type: value['type'],
                    };
                }
                return;
            }
            return {
                title: translate(value['title'], locale),
                value: value['enum'][0],
                type: value['type'],
            };
        })
        .filter(element => element)
        .sort(function(a, b) {
            if (a.value > b.value) {
                return 1;
            }
            if (a.value < b.value) {
                return -1;
            }
            return 0;
        });
};

export { getEnumValue };
