<script lang="ts" setup>
import BtnInformation from '@/components/buttons/BtnInformation.vue';
import CheckSquare from '@/components/checks/CheckSquare.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import GiftCard from '@/components/cards/GiftCard.vue';
import { computed, PropType } from 'vue';
import { GiftCardsAlertCheckoutModel } from '@/models/giftCardsAlert/giftCardsAlertCheckoutModel';
import { useDays } from '@/composables/useDays';

const props = defineProps({
    giftCard: {
        type: Object as PropType<GiftCardsAlertCheckoutModel>,
        required: true,
    },
    isSelected: {
        type: Boolean,
        required: true,
    },
});

const { doFormat } = useDays();

const card = computed(() => {
    return {
        id: props.giftCard.id,
        card_picture: props.giftCard.image_gift_card,
        color_text: props.giftCard.color_text,
        card_name: props.giftCard.name_gift_card,
        available_amount: props.giftCard.available_amount,
        expiration_date: props.giftCard.expiration_date,
        description: props.giftCard.additional_information,
    };
});

defineEmits<(e: 'on-change') => void>();
</script>

<template>
    <BaseCard class="modal-change-giftcard__list__card" padding="0rem">
        <GiftCard :card="card" />
        <section class="modal-change-giftcard__list__card__bottom">
            <div class="modal-change-giftcard__list__card__bottom__left">
                <p class="modal-change-giftcard__list__card__bottom__left__name">
                    <span>{{ card.card_name }}</span>
                    <BtnInformation v-if="card.description">
                        <p
                            v-translate="card.description"
                            class="modal-change-giftcard__list__card__bottom__left__tooltip"
                        />
                    </BtnInformation>
                </p>
                <p v-if="card.expiration_date" class="modal-change-giftcard__list__card__bottom__left__date">
                    {{ $t('txt.gift-card-valid-until') }} {{ card.expiration_date }}
                </p>
            </div>
            <check-square :is-checked="isSelected" @on-check="$emit('on-change')" />
        </section>
    </BaseCard>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index';

.modal-change-giftcard__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding: $padding-lg;
    max-width: 900px;
    justify-content: center;
    overflow-y: scroll;
    @include tablet {
        max-width: 700px;
    }
    @include phone {
        max-width: 400px;
    }

    &__card {
        position: relative;

        &__bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $white;
            border-bottom-left-radius: $radius-x-lg;
            border-bottom-right-radius: $radius-x-lg;
            display: flex;
            padding: $padding-sm $padding-md;

            &__left {
                flex-grow: 1;
                @include body2;

                &__name {
                    position: relative;
                    margin: $margin-none;
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;
                }

                &__tooltip {
                    @include caption;
                    margin: $margin-none;
                }

                &__date {
                    margin: $margin-none;
                    color: $orange;
                }
            }
        }
    }
}

.modal-change-giftcard-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $padding-lg 0;

    &__btn {
        width: 280px;
    }
}
</style>
