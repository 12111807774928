<template>
    <base-card class="profile-options" padding="0">
        <div class="profile-options__background" :class="getContainerStyle">
            <img
                v-if="profile.isPrime && !profile.isExclusive"
                class="banner-prime--img"
                alt="banner-prime-normal"
                src="@/assets/svg/prime_logo.svg"
            />
            <img
                v-if="!profile.isPrime"
                class="banner--img cursor-pointer"
                alt="se prime"
                src="@/assets/svg/ic_prime_badge.svg"
                @click="$router.push({ name: appRoutesMap.account.tiptiPrime })"
            />
            <div style="position: relative">
                <img
                    v-if="profile.profilePicture || imageUpdated"
                    ref="profileImg"
                    :alt="$t('general.profile-picture')"
                    :src="profile.profilePicture"
                />
                <img v-else :alt="$t('general.profile-picture-generic')" src="@/assets/svg/ic_avatar_tipti.svg" />
                <div class="camera-icon" @click="$refs.file.click()">
                    <input ref="file" style="display: none" type="file" @change="handleFile" />
                    <img alt="change-profile-picture" src="@/assets/svg/ic_camera.svg" />
                </div>
            </div>
        </div>
        <aside class="margin--horizontal">
            <base-card class="profile-information">
                <div class="information">
                    <p class="information__name">{{ profile.name }}</p>
                    <p class="information__email">
                        {{ getCorrectEmail() }}
                    </p>
                    <p class="information__ID">ID {{ profile.id }}</p>
                </div>
                <div
                    class="information"
                    :class="{ 'information-active': activeOption === statisticsRoute }"
                    @click="changeOption(statisticsRoute)"
                >
                    <p class="information__amount">{{ profile.totalOrders }}</p>
                    <p class="information__orders">
                        {{ $t("general['orders']", { count: profile.totalOrders }).toUpperCase() }}
                    </p>
                </div>
            </base-card>
            <the-btn-menu
                v-for="option in editProfileOptions"
                :key="option.route"
                :class="{ 'hide-profile-option-in-phone': option.route === 'bills-and-document' }"
                :link-img="option.icon"
                :selected="activeOption === option.route"
                :txt="option.title"
                @click="changeOption(option.route)"
            />
            <div class="prime-op__close-session">
                <p class="prime-op__close-session-txt" @click="showLogoutModal = true">{{ $t('cta.close-session') }}</p>
            </div>
        </aside>
    </base-card>
    <snack-bar
        :body="message"
        :is-active="showSnackBar"
        :is-failure="isFailure"
        :is-success="isSuccess"
        @on-snackbar-close="showSnackBar = false"
    />
    <close-session-modal v-if="showLogoutModal" @on-close="showLogoutModal = false" />
</template>

<script lang="ts">
import CloseSessionModal from '@/components/modal/closeSessionModal/CloseSessionModal.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import TheBtnMenu from '@/components/buttons/TheBtnMenu.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import { useUser } from '@/composables/useUser';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useApp } from '@/composables/useApp';

export default {
    components: {
        BaseCard,
        TheBtnMenu,
        CloseSessionModal,
        SnackBar,
    },
    setup() {
        const { isMobile } = useApp();

        return {
            isMobile,
            ...useUser(),
        };
    },
    data() {
        return {
            appRoutesMap,
            activeOption: appRoutesMap.account.statistics,
            showLogoutModal: false,
            imageUpdated: false,
            editProfileOptions: [
                {
                    title: this.$t("txt['profile-options__edit-profile']"),
                    route: appRoutesMap.account.editProfile,
                    icon: 'ic_profilemenu.svg',
                },
                {
                    title: this.$t("txt['profile-options__delivery-address']"),
                    route: appRoutesMap.account.addressesProfile,
                    icon: 'ic_location.svg',
                },
                {
                    title: this.$t("general['billing-data']"),
                    route: appRoutesMap.account.billsProfile,
                    icon: 'ic_billing.svg',
                },
                {
                    title: this.$t("txt['profile-options__card-register']"),
                    route: appRoutesMap.account.creditCardsProfile,
                    icon: 'ic_creditcard.svg',
                },
                {
                    title: this.$t("txt['profile-options__share-data']"),
                    route: appRoutesMap.account.shareData,
                    icon: 'ic_share.svg',
                },
                {
                    title: this.$t("general['bills-documents']"),
                    route: appRoutesMap.account.billsAndDocuments,
                    icon: 'ic_billing_document.svg',
                },
            ],
            statisticsRoute: appRoutesMap.account.statistics,
            isSuccess: false,
            isFailure: false,
            message: '',
            showSnackBar: false,
        };
    },
    computed: {
        getContainerStyle(): string {
            if (this.profile.isExclusive) return 'profile-options--background-exclusive';
            if (this.profile.isPrime) return 'profile-options--background-prime';
            return 'profile-options--background-normal';
        },
        /// Delete this when back implement endpoint to control.
        titleByCountry(): string {
            if (this.profile?.currentAddress?.city?.country?.id === 1)
                return this.$t('txt.profile-options__loyalty_ec');
            return this.$t('txt.profile-options__loyalty_pa');
        },
    },
    mounted() {
        if (this.isMobile) this.activeOption = '';
        else {
            this.activeOption = this.statisticsRoute;
        }
        if (this.baseConfiguration.showLoyaltyMenu)
            this.editProfileOptions.splice(4, 0, {
                title: this.titleByCountry,
                route: appRoutesMap.reward.programs,
                icon: 'ic_loyalty_and_rewards.svg',
            });
    },
    methods: {
        changeOption(route: string): void {
            this.activeOption = route;
            this.$router.push({ name: route });
        },
        getCorrectEmail(): string {
            return this.profile.email.length > 23 ? `${this.profile.email.slice(0, 23)}...` : this.profile.email;
        },
        handleFile(): void {
            const pictureReader = new FileReader();
            pictureReader.readAsDataURL(this.$refs.file.files[0]);
            pictureReader.onload = async (pictureReader) => {
                if (this.$refs?.profileImg) this.$refs.profileImg.src = pictureReader.target.result;
                if (this.profile?.profilePicture) this.imageUpdated = true;
                const isRequestComplete = await this.$store.dispatch(
                    'user/changeProfilePicture',
                    this.$refs.file.files[0],
                );
                this.message = isRequestComplete
                    ? this.$t("txt['profile-options__updated-success']")
                    : this.$t("txt['profile-options__there-is-error']");
                this.isSuccess = isRequestComplete;
                this.isFailure = !isRequestComplete;
                this.showSnackBar = true;
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import './profile-options.scss';
</style>
