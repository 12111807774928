import { LoyaltyAndRewardProgramsModel } from '@/models/loyaltyPrograms/LoyaltyAndRewardModel';
import { LoyaltyOrRewardModel } from '@/models/loyaltyPrograms/LoyaltyOrRewardsModel';

export default {
    programs(state, programs: LoyaltyAndRewardProgramsModel) {
        state.programs = programs;
    },
    program(state, program: LoyaltyOrRewardModel) {
        state.program = program;
    },
    registrationTempData(state, userData: object) {
        state.registrationTempData = userData;
    },
    reloadProgram(state, programId: number) {
        state.program = [...state.programs.rewards, ...state.programs.afiliations].find(
            (program: LoyaltyOrRewardModel) => program.id === programId,
        );
    },
};
