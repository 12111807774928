<template>
    <table class="bills-table">
        <thead class="bills-table__header" :class="{ 'bills-table__header--profile': isFromUserMenu }">
            <tr>
                <th
                    v-for="(option, index) in headerOptions"
                    :key="index"
                    class="bills-table__header-th"
                    :class="{
                        'bills-table__header-th--profile': isFromUserMenu,
                    }"
                >
                    {{ option.toUpperCase() }}
                </th>
            </tr>
        </thead>
        <tbody v-if="billsFile" class="bills-table__body">
            <tr v-for="(billFile, index) in billsFile" :key="index">
                <td>#{{ billFile.order_id }}</td>
                <td>
                    {{ billFile.issue_date }}
                </td>
                <td>
                    {{ billFile.client_document }}
                </td>
                <td>
                    {{ billFile.client_name }}
                </td>
                <td class="bills-table__document-type">
                    <p>{{ billFile.document_number }}</p>
                    <p>{{ billFile.document_type }}</p>
                </td>
                <td v-if="billFile.available_files" class="bills-files">
                    <button class="bills-files__file" @click="downloadFile(billFile?.link_pdf)">PDF</button>
                    <button class="bills-files__file" @click="downloadFile(billFile.link_xml)">XML</button>
                </td>
                <td v-else>
                    {{ $t('general.no-available') }}
                </td>
                <td>{{ getTotalBills(billFile?.total) }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { InvoiceDocument } from '@/models/BillModels';
import { useI18n } from 'vue-i18n';
import { useUser } from '@/composables/useUser';

defineProps({
    billsFile: {
        type: Array as PropType<InvoiceDocument[]>,
        required: true,
    },
    isFromUserMenu: {
        type: Boolean,
        default: false,
    },
});

const { t: $t } = useI18n();
const { currency } = useUser();

const headerOptions = [
    `# ${$t('general.order')}`,
    $t('general.issue-date'),
    'RUC/CI',
    $t('general.name'),
    $t('general.number-document'),
    `${$t('general.document')}S`,
    $t('general.total'),
];

const downloadFile = (urlXml: string): void => window.open(urlXml);

const getTotalBills = (total: string): string =>
    total ? `${currency.value}${(+total)?.toFixed(2)}` : $t('general.no-available');
</script>

<style lang="scss" scoped>
@import './bills-table.scss';
</style>
