import { CartModel } from '@/models/cart/CartModel';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import { ProductModel } from '@/models/product/ProductModel';
import { CartBarGoals } from '@/models/cart/CartBarGoals';
import { DiscountsTypes } from '@/enums/discounts';
import { BaseGoal } from '@/models/cart/CartBaseGoal';
import type { CartMedicalPrescription } from '@/models/cart/CartMedicalPrescription';

export default {
    showCart(state, show: boolean) {
        state.showCart = show;
    },
    toggleShowCart(state) {
        state.showCart = !state.showCart;
    },
    cart(state, cart: CartModel) {
        state.cart = cart;
        state.products = cart.retailers
            .map((retailer: CartRetailerModel) => {
                return retailer.products;
            })
            .flat();
        state.cartAmount = cart?.retailers?.length
            ? cart.retailers
                  .map((retailer) => {
                      return retailer?.products?.length ?? 0;
                  })
                  .reduce((quantity, accumulator) => quantity + accumulator)
            : 0;
    },
    personalCartId(state, cartId: number) {
        state.personalCartId = cartId;
    },
    products(state, product: ProductModel) {
        const localProductIndex: number = state.products.findIndex(
            (localProduct: ProductModel) => localProduct.id == product.id,
        );
        localProductIndex === -1 ? state.products.push(product) : (state.products[localProductIndex] = product);
    },
    removeProductFromProducts(state, product: ProductModel) {
        state.products = state.products.filter((actualProduct: ProductModel) => actualProduct.id !== product.id);
    },
    cartAmount(state, amount: number) {
        if (state.cart === 0) state.products = [];
        state.cartAmount = amount;
    },

    lastProductsAdded(state, product: ProductModel) {
        state.lastProductsAdded.push(product);
        setTimeout(function () {
            if (state.lastProductsAdded.length) state.lastProductsAdded.shift();
        }, 2000);
    },
    cleanLastProductAdded(state) {
        state.lastProductsAdded = [];
    },
    removeProductFromlastProductsAdded(state, id: number) {
        state.lastProductsAdded = state.lastProductsAdded.filter((product: ProductModel) => product.id != id);
    },
    cartBarGoal(state, value: CartBarGoals) {
        state.cartBarGoal = value;
        setTimeout(function () {
            if (state.cartBarGoal?.type === DiscountsTypes.CASHBACK) state.showCartBarAlert = true;
            state.cartBarGoal = undefined;
        }, 2000);
    },
    cardGoal(state, value: BaseGoal) {
        state.cardGoal = value;
    },
    showCartBarAlert(state, value: boolean) {
        state.showCartBarAlert = value;
    },
    productWithSpecialDelivery(state, products: Array<ProductModel>) {
        state.productWithSpecialDelivery = products;
    },
    listFilesPrescriptions(state, payload: { changedFiles: File[]; retailer_id: number }) {
        if (!payload?.changedFiles) return (state.listFilesPrescriptions = []);

        const retailerFiles = state.listFilesPrescriptions.find((obj) => obj.retailer_id === payload.retailer_id);
        if (retailerFiles) return (retailerFiles.listFiles = payload.changedFiles);

        state.listFilesPrescriptions.push({
            retailer_id: payload.retailer_id,
            listFiles: payload.changedFiles,
        });
    },
    itemsPrescription(state, itemsPrescription: CartMedicalPrescription): void {
        state.itemsPrescription = itemsPrescription;
    },
};
