<template>
    <section class="retailer-card__schedule-container">
        <img v-if="orderInfo.franchiseLogo" class="image" :src="orderInfo.franchiseLogo" alt="" />
        <div>
            <h2 class="retailer-card__schedule-detail">{{ $t('general.delivery-time') }}</h2>
            <div class="retailer-card__schedule-detail">
                <icon-schedule :size="isMobile ? 0.9 : 1" color="#FF9012"></icon-schedule>
                <div>
                    <h3 class="delivery_time">
                        {{ dayNameAndNumber(orderInfo.firstDate) }}
                    </h3>
                    <h3 class="hours">
                        {{ $t('general.from') }}
                        {{ hours(orderInfo.firstDate) }}
                        {{ $t("txt['purchase__retailer-schedule-to']") }}
                        {{ hours(orderInfo.secondDate) }}
                    </h3>
                </div>
            </div>
            <template v-if="orderInfo?.showAccumulatedPoints">
                <p class="alert-purchase__text-accumulated">{{ $t("txt['has-accumulated']") }}</p>
                <p class="alert-purchase__text-points">{{ $t("txt['points-text']") }}</p>
            </template>
        </div>
    </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { isMobileBrowser } from '@/utils/utils';
import { ResponseOrderModel } from '@/models/checkout/ResponseOrderModel';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import IconSchedule from '@/components/icons/IconSchedule.vue';

export default {
    name: 'CardRetailerPurchaseSchedule',
    components: { IconSchedule },
    props: {
        orderInfo: {
            required: true,
            type: ResponseOrderModel,
        },
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
        }),
        isMobile() {
            return isMobileBrowser();
        },
    },
    methods: {
        dayNameAndNumber(date: string): string {
            const dayName = dayjs(date).locale(this.user?.['defaultLanguage']?.toLowerCase()).format('dddd');
            const dayOfMonth = dayjs(date).locale(this.user?.['defaultLanguage']?.toLowerCase()).format('D');
            const Month = dayjs(date).locale(this.user?.['defaultLanguage']?.toLowerCase()).format('MMMM');
            const year = dayjs(date).locale(this.user?.['defaultLanguage']?.toLowerCase()).format('YYYY');
            return `${dayName.charAt(0).toUpperCase() + dayName.slice(1)}  ${dayOfMonth} ${Month} ${year}`;
        },
        hours(date: string): string {
            return `${dayjs(date)
                .locale(this.user?.['defaultLanguage']?.toLowerCase())
                .format('HH:mm')
                .replace(':', 'h')}`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './card-retailer-purchase-schedule.scss';
</style>
