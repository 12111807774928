<template>
    <div
        ref="animationContainer"
        :style="{
            width: width,
            height: height,
        }"
    ></div>
</template>

<script lang="ts" setup>
import Lottie from 'lottie-web';
import { onMounted, ref } from 'vue';

const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    loop: {
        type: Boolean,
        default: true,
    },
    autoplay: {
        type: Boolean,
        default: true,
    },
    width: {
        type: String,
        default: 'auto',
    },
    height: {
        type: String,
        default: 'auto',
    },
});

const animationContainer = ref<HTMLElement>();
const $emit = defineEmits<(e: 'on-loaded', value: boolean) => void>();

const initAnimation = (): void => {
    if (!animationContainer.value) return;
    Lottie.loadAnimation({
        container: animationContainer.value,
        path: props.src,
        loop: props.loop,
        autoplay: props.autoplay,
    }).addEventListener('data_ready', () => {
        $emit('on-loaded', true);
    });
};

onMounted(() => {
    initAnimation();
});
</script>
