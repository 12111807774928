import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bc121f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "badge-and-share" }
const _hoisted_2 = { class: "recipe-name-and-pax" }
const _hoisted_3 = { class: "recipe-name--rate" }
const _hoisted_4 = { class: "icon-and-pax" }
const _hoisted_5 = { class: "detail-recipe__data" }
const _hoisted_6 = { class: "detail-subsection" }
const _hoisted_7 = { class: "detail-subsection" }
const _hoisted_8 = { class: "detail-recipe__total" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "product-information" }
const _hoisted_11 = { class: "product-information__actions" }
const _hoisted_12 = { class: "product-information__price-column" }
const _hoisted_13 = {
  key: 0,
  class: "product-information__price-discount"
}
const _hoisted_14 = { class: "rate-recipe" }
const _hoisted_15 = {
  key: 0,
  class: "recipe-details"
}
const _hoisted_16 = { class: "recipe-details__title" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 1,
  class: "recipe-details"
}
const _hoisted_19 = { class: "recipe-details__title" }
const _hoisted_20 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_badge_name = _resolveComponent("badge-name")!
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_icon_share_list = _resolveComponent("icon-share-list")!
  const _component_rating = _resolveComponent("rating")!
  const _component_icon_person = _resolveComponent("icon-person")!
  const _component_btn_out_line = _resolveComponent("btn-out-line")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_icon_decrement = _resolveComponent("icon-decrement")!
  const _component_btn_circular = _resolveComponent("btn-circular")!
  const _component_icon_increment = _resolveComponent("icon-increment")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_horizontal_divider = _resolveComponent("horizontal-divider")!
  const _component_snack_bar = _resolveComponent("snack-bar")!
  const _component_share = _resolveComponent("share")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.recipeDetail)
      ? (_openBlock(), _createBlock(_component_base_card, {
          key: 0,
          class: "flex-column",
          padding: "0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_section_header, {
              hasBack: true,
              title: $options.recipeName,
              onOnBack: $options.backList
            }, null, 8, ["title", "onOnBack"]),
            _createElementVNode("header", {
              class: "recipe-information",
              style: _normalizeStyle({ 'background-image': `url(${_ctx.recipeDetail?.recipe?.picture})` })
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_badge_name, { name: $options.recipeGroup }, null, 8, ["name"]),
                ($data.isLoadingShareRecipe)
                  ? (_openBlock(), _createBlock(_component_base_loading, { key: 0 }))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "share-button",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.shareRecipe && $options.shareRecipe(...args)))
                    }, [
                      _createVNode(_component_icon_share_list, { size: 1.5 }),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('general.share')), 1)
                    ]))
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_rating, {
                    size: 0.5,
                    colorNotSelect: "transparent",
                    colorSelect: "#FFF",
                    stroke: "#FFF",
                    strokeSelect: "#FFF",
                    onStars: $options.changeScore
                  }, null, 8, ["onStars"]),
                  _createElementVNode("h2", null, _toDisplayString($options.recipeName), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_icon_person, { size: 0.2 }),
                  _createElementVNode("p", null, _toDisplayString(_ctx.recipeDetail['peopleNumber']) + " Pax.", 1),
                  _createVNode(_component_btn_out_line, {
                    isLoading: $data.isLoadingBuyProducts,
                    txt: _ctx.$t('cta.add-to-cart'),
                    bgColor: "transparent",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (!$data.isLoadingBuyProducts ? $options.buyProductsRecipe() : null))
                  }, null, 8, ["isLoading", "txt"])
                ])
              ])
            ], 4),
            _createVNode(_component_base_card, { class: "detail-recipe" }, {
              default: _withCtx(() => [
                _createElementVNode("aside", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.recipeDetail['products']?.length), 1),
                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("txt['recipe__ingredients-quantity']", { count: _ctx.recipeDetail['products']?.length ?? 0 })), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.recipeDetail['calories']), 1),
                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("general['calories']")), 1)
                  ])
                ]),
                _createElementVNode("p", _hoisted_8, _toDisplayString($options.currency) + " " + _toDisplayString($options.totalAmount), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getProducts, (product) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "recipe-products",
                key: product.id
              }, [
                (product?.image?.medium)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "recipe-products__img",
                      src: product.image.medium,
                      alt: ""
                    }, null, 8, _hoisted_9))
                  : _createCommentVNode("", true),
                _createElementVNode("header", _hoisted_10, [
                  _createElementVNode("h4", null, _toDisplayString(product.name), 1),
                  _createElementVNode("p", null, _toDisplayString(product.unitQuantity) + " " + _toDisplayString(product.unit), 1)
                ]),
                _createElementVNode("aside", _hoisted_11, [
                  _createVNode(_component_btn_circular, {
                    class: "actions-btn",
                    onClick: ($event: any) => ($options.updateQuantity(product, true))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_icon_decrement)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createElementVNode("p", null, _toDisplayString(product.productQuantity), 1),
                  _createVNode(_component_btn_circular, {
                    class: "actions-btn",
                    onClick: ($event: any) => ($options.updateQuantity(product))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_icon_increment)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  (product.hasDiscount)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString($options.currency) + " " + _toDisplayString(product.priceWithDiscount.toFixed(2)), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", {
                    class: _normalizeClass(["product-information__price", { 'product-information__price-crossed': product.hasDiscount }])
                  }, _toDisplayString($options.currency) + " " + _toDisplayString(product.price.toFixed(2)), 3)
                ])
              ]))
            }), 128)),
            _createElementVNode("aside", _hoisted_14, [
              _createElementVNode("h5", null, _toDisplayString(_ctx.$t("txt['recipe-rate']")), 1),
              _createVNode(_component_rating, {
                size: 0.75,
                onStars: $options.changeScore
              }, null, 8, ["onStars"])
            ]),
            _createVNode(_component_base_card, { class: "total-and-buy" }, {
              default: _withCtx(() => [
                _createElementVNode("h6", null, "TOTAL: " + _toDisplayString($options.currency) + " " + _toDisplayString($options.totalAmount), 1),
                _createVNode(_component_btn_solid, {
                  isLoading: $data.isLoadingBuyProducts,
                  txt: _ctx.$t('cta.add-to-cart'),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (!$data.isLoadingBuyProducts ? $options.buyProductsRecipe() : null))
                }, null, 8, ["isLoading", "txt"])
              ]),
              _: 1
            }),
            (_ctx.recipeDetail['ingredients'])
              ? (_openBlock(), _createElementBlock("aside", _hoisted_15, [
                  _createElementVNode("h6", _hoisted_16, _toDisplayString(_ctx.$t("txt['recipe__ingredients-quantity']", { count: 2 })), 1),
                  _createElementVNode("div", {
                    class: "recipe-details__data",
                    innerHTML: _ctx.recipeDetail['ingredients']
                  }, null, 8, _hoisted_17)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_horizontal_divider, { class: "divider" }),
            (_ctx.recipeDetail['preparation'])
              ? (_openBlock(), _createElementBlock("aside", _hoisted_18, [
                  _createElementVNode("h6", _hoisted_19, _toDisplayString(_ctx.$t("general['preparation']")), 1),
                  _createElementVNode("div", {
                    class: "recipe-details__data",
                    innerHTML: _ctx.recipeDetail['preparation']
                  }, null, 8, _hoisted_20)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_snack_bar, {
      body: $data.snackBarMessage,
      isActive: $data.showSnackBar,
      isFailure: $data.isFailure,
      isSuccess: $data.isSuccess,
      onOnSnackbarClose: $options.closeSnackBar
    }, null, 8, ["body", "isActive", "isFailure", "isSuccess", "onOnSnackbarClose"]),
    ($data.isShareRecipe)
      ? (_openBlock(), _createBlock(_component_share, {
          key: 1,
          link: $options.deeplink,
          messageToShare: $options.messageToShare,
          shareLink: true,
          title: _ctx.recipeDetail?.recipe?.name ?? '',
          onOnTrackEvent: $options.onTrackEvent,
          onOnClose: _cache[3] || (_cache[3] = ($event: any) => ($data.isShareRecipe = false))
        }, null, 8, ["link", "messageToShare", "title", "onOnTrackEvent"]))
      : _createCommentVNode("", true)
  ], 64))
}