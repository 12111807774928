import { AlertNewsDto } from '@/models/alertNews/AlertNewsModel';

export default {
    alertNewsSlide(state): AlertNewsDto {
        return state.alertNewsSlide;
    },
    closeNewsAlertInRetailers(state) {
        return state.closeNewsAlertInRetailers;
    },
    closeNewsAlertInCart(state) {
        return state.closeNewsAlertInCart;
    },
};
