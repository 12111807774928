import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.rate'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 36 34",
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M18.5169 1.53287L22.8296 11.7396L33.8703 12.6883C34.0407 12.703 34.2031 12.7675 34.337 12.8739C34.471 12.9803 34.5707 13.1239 34.6235 13.2866C34.6764 13.4493 34.6802 13.6239 34.6344 13.7888C34.5886 13.9536 34.4953 14.1013 34.366 14.2134L25.9909 21.4676L28.5001 32.2604C28.539 32.427 28.5279 32.6013 28.4682 32.7616C28.4084 32.9219 28.3027 33.061 28.1643 33.1615C28.0258 33.262 27.8608 33.3195 27.6899 33.3266C27.519 33.3338 27.3497 33.2904 27.2034 33.2018L17.7159 27.48L8.22842 33.2018C8.08206 33.2904 7.91283 33.3338 7.74191 33.3266C7.57098 33.3195 7.40597 33.262 7.26754 33.1615C7.1291 33.061 7.02339 32.9219 6.96365 32.7616C6.90391 32.6013 6.89278 32.427 6.93167 32.2604L9.44091 21.4676L1.06579 14.2109C0.936553 14.0988 0.843204 13.9512 0.797403 13.7863C0.751603 13.6215 0.755383 13.4468 0.808269 13.2841C0.861156 13.1214 0.960807 12.9779 1.09477 12.8715C1.22874 12.7651 1.39109 12.7005 1.56154 12.6859L12.6022 11.7371L16.9137 1.53287C16.98 1.37497 17.0914 1.24017 17.234 1.14537C17.3766 1.05057 17.5441 1 17.7153 1C17.8865 1 18.054 1.05057 18.1966 1.14537C18.3392 1.24017 18.4506 1.37497 18.5169 1.53287V1.53287Z",
        stroke: $props.stroke,
        "stroke-width": "1.22104"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "iconColor"]))
}