import { AddCreditCardModel } from '@/models/paymentMethods/AddCreditCard';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { WrapperResponse } from '@/modules/core/decorators/wrapperResponse';
import { ClientError, PaymentErrorOnAdd } from '@/modules/core/errors/AppErrors';
import { PaymentMethodModel, toPaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';

class PaymentMethodService {
    @WrapperResponse({ rethrowError: true })
    async addPaymentMethod(input: AddCreditCardModel): Promise<PaymentMethodModel> {
        try {
            const { data } = await axiosInstance().post('client_profile/payment_method', input.toAddCreditCardPayload);
            return toPaymentMethodModel(data);
        } catch (error) {
            if (!(error instanceof ClientError) || error.code !== 400) throw error;
            const { show_message_error } = error?.errorObject;
            throw new PaymentErrorOnAdd({
                code: `${error.code}-${input.toAddCreditCardPayload.payment_type}`,
                where: error.where,
                message: show_message_error ?? error.message,
            });
        }
    }

    @WrapperResponse()
    async findCardBrandByBin(input: string): Promise<{ image: string; brand: string }> {
        const { data } = await axiosInstance().get<{ image: string; brand: string }>(`payment_method_brand/${input}/`);
        return data;
    }

    @WrapperResponse()
    async deletedPromotions(): Promise<void> {
        await axiosInstance().delete('v2/client_profile/coupon/promotion/');
    }
}

export const paymentMethodService = new PaymentMethodService();
