<template>
    <base-card-flat class="tipti-prime__product" :borderColor="'#a2a2a2'">
        <icon-tipti :size="0.5" color="#FF9012" />
        <p class="tipti-prime__product-owner">{{ `${currentPrimeDeal.name}` }}</p>
        <img v-if="currentPrimeDeal?.logo" class="tipti-prime__product-logo" :src="currentPrimeDeal.logo" alt="" />
    </base-card-flat>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import IconTipti from '@/components/icons/IconTipti.vue';
import { TiptiCardDealsModel } from '@/models/tiptiCard/TiptiCardDealsModel';

export default {
    name: 'card-tipti-prime-product',
    components: { IconTipti, BaseCardFlat },
    computed: {
        ...mapGetters({
            creditCardDefault: 'payments/creditCardDefault',
            paymentsToShow: 'payments/tiptiPrimeToPay',
            cart: 'cart/cart',
            countrySelected: 'external/countrySelected',
            defaultPrime: 'tiptiCard/defaultPrime',
            primeSelected: 'tiptiCard/primeSelected',
        }),
        currentPrimeDeal(): TiptiCardDealsModel {
            return this.primeSelected ?? this.defaultPrime;
        },
        currency() {
            return this.countrySelected?.currencySymbol ?? '$';
        },
    },
    methods: {
        spaceDots(index: number): string {
            return index === 3 || index === 7 || index == 11 ? 'credit-card--space' : '';
        },
    },
};
</script>

<style lang="scss" scoped>
@import './card-tipt-prime-product';
</style>
