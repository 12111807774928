<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { ProductWithAttributeModel } from '@/models/product/ProductWithAttributeModel';
import { useTranslate } from '@/composables/UseTranslate';
import { useStore } from 'vuex';
import { ProductModel } from '@/models/product/ProductModel';

const props = defineProps({
    attribute: {
        type: Object as PropType<ProductWithAttributeModel>,
        required: true,
    },
});

const { exec } = useTranslate();
const $store = useStore();
defineEmits<{ (e: 'update-attribute', value: ProductWithAttributeModel) }>();

const nameAttribute = computed(() => exec(props.attribute.name));

const product = computed<ProductModel>(() => $store.getters['product/currentProduct']);

const isActiveAttribute = computed(() => product.value?.attributes.some((attr) => attr.id === props.attribute.id));
</script>

<template>
    <button
        class="attribute-btn cursor-pointer"
        :class="{
            'attribute-btn--rounded': attribute?.use_image,
            'attribute-btn--selected': isActiveAttribute,
        }"
        @click="$emit('update-attribute', attribute)"
    >
        <img v-if="attribute?.use_image" class="attribute-btn__image" :alt="nameAttribute" :src="attribute?.image" />
        <span v-else class="attribute-btn__text">
            {{ nameAttribute }}
        </span>
    </button>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.attribute-btn {
    margin: $margin-sm;
    background-color: $white;
    min-width: 3rem;
    min-height: 3rem;
    border: $border 1px solid;
    border-radius: $radius-md;

    &--selected {
        background-color: $orange;
        border: $orange 1px solid;
    }

    &--rounded {
        border-radius: $radius-circular;
        background-color: $white;
        min-width: 4rem;
        min-height: 4rem;
    }

    &__image {
        width: 100%;
        max-width: 4rem;
        border-radius: $radius-circular;
    }

    &__text {
        @include body2;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.attribute-btn--selected > .attribute-btn__text {
    color: $white;
}
</style>
