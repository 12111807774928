<template>
    <div class="retailer-img-circular">
        <img
            :alt="retailerSelected?.name ?? ''"
            :src="retailerSelected?.franchise?.icon ?? retailerSelected?.icon"
            loading="lazy"
        />
    </div>
</template>

<script lang="ts" setup>
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

const { retailerSelected } = useRetailer();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.retailer-img-circular {
    display: none;
    @include tablet {
        display: flex;
        justify-content: center;
        margin-bottom: $margin-md;
    }

    & img {
        min-height: 3rem;
        margin-top: $margin-lg;
        width: 100%;
        border-radius: 50%;
        max-width: 70px;
        text-align: center;
        justify-self: center;
    }
}
</style>
