<template>
    <aside class="tipti-prime-detail">
        <img v-if="prime?.logo" :src="prime.logo" alt="" />
        <icon-tipti v-else />
        <h2 class="tipti-prime-detail__title" v-if="prime?.benefits?.length">
            {{ $t('general.benefits') }} {{ prime?.name ?? '' }}
        </h2>
        <ul class="benefits">
            <li v-for="(benefit, index) in prime?.benefits" :key="index">
                <p>{{ benefit }}</p>
            </li>
        </ul>
    </aside>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';

import IconTipti from '@/components/icons/IconTipti.vue';
import { TiptiCardDealsModel } from '@/models/tiptiCard/TiptiCardDealsModel';

export default {
    name: 'tipti-prime-detail',
    components: { IconTipti },
    computed: {
        ...mapGetters({ defaultPrime: 'tiptiCard/defaultPrime', primeSelected: 'tiptiCard/primeSelected' }),
        prime(): TiptiCardDealsModel {
            return this.primeSelected ?? this.defaultPrime;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './tipti-prime-detail.scss';
</style>
