<template>
    <base-card-flat v-if="isMobile" border-radius="0" class="flex-column" padding="0" border-width="0" bg-color="#fff">
        <router-view></router-view>
    </base-card-flat>
    <base-card v-else class="flex-column" padding="0">
        <router-view></router-view>
    </base-card>
</template>

<script lang="ts">
import BaseCard from '@/components/cards/BaseCard.vue';
import { isMobileBrowser } from '@/utils/utils';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
export default {
    name: 'programs',
    components: { BaseCardFlat, BaseCard },
    computed: {
        isMobile(): boolean {
            return isMobileBrowser();
        },
    },
};
</script>
