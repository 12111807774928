<template>
    <general-alert
        :accent-header="true"
        :accept-button-txt="$t('txt.tipti-card__use-balance')"
        :decline-button-txt="$t('txt.tipti-card__accumulate-balance')"
        :message="$t('txt.tipti-card__available-balance')"
        :show-close-icon="true"
        :title="$t('txt.tipti-card__alert-title')"
        @on-accept="$emit('on-use-balance')"
        @on-decline="$emit('on-accumulate')"
        @on-close="$emit('on-close')"
    >
        <template #content>
            <h1 v-currency="tiptiCard?.availableAmount?.toFixed(2)"></h1>
            <p>{{ $t('txt.tipti-card__do-you-wish') }}</p>
        </template>
    </general-alert>
</template>

<script lang="ts">
import GeneralAlert from '../generalAlert/GeneralAlert.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'AlertTiptiCard',
    components: {
        GeneralAlert,
    },
    emits: ['on-close', 'on-accumulate', 'on-use-balance'],
    computed: {
        ...mapGetters({ tiptiCard: 'tiptiCard/tiptiCard', countrySelected: 'external/countrySelected' }),
        currency() {
            return this.countrySelected?.currencySymbol ?? '$';
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

h1 {
    @include headline1;
    color: $orange;
    font-size: 3rem;
}

p {
    @include caption;
    margin-top: $margin-md;
}
</style>
