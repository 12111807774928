<template>
    <label v-if="label?.length" class="radio-label">{{ label }}</label>
    <div v-for="(input, index) in inputs" :key="index" class="radio" :class="{ 'radio--vertical': isVertical }">
        <input
            :id="input.value"
            :checked="input?.value === valueSelect"
            :name="radioGroup"
            :value="modelValue"
            type="radio"
            @input="$emit('update:modelValue', input.value)"
        />
        <label class="radio__label" :for="input.value"> {{ input.name }} </label>
    </div>
</template>

<script>
/// *** inputs should be composed of {name: 'Nombre del label', value: '}
export default {
    name: 'Radio',
    props: {
        radioGroup: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [Number, String, Boolean],
        },
        label: {
            type: String,
        },
        inputs: {
            type: Array,
            required: true,
        },
        isVertical: {
            type: Boolean,
            default: false,
        },
        valueSelect: {
            type: [Number, String],
        },
    },
    emits: ['update:modelValue'],
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.radio-label {
    @include overline;
    color: $orange;
    margin-bottom: $margin-sm;
    @include tablet {
        @include caption;
        color: $orange;
    }
}
.radio {
    display: flex;
    align-items: center;
    position: relative;
    margin: $margin-sm $margin-md;
}
.radio input {
    margin: 0;
    margin-right: $margin-sm;
}

.radio input[type='radio']:checked,
.radio input[type='radio']:not(:checked) {
    position: absolute;
    left: 4px;
}
.radio input[type='radio']:checked + label,
.radio input[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    @include body2;
}
.radio input[type='radio']:checked + label:before,
.radio input[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: $radius-circular;
    background: $white;
}
.radio input[type='radio']:checked + label:after,
.radio input[type='radio']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $orange;
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    margin: auto;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.radio input[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.radio input[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.radio__label {
    @include body2;
}
.radio--vertical {
    flex-direction: column;
    margin-right: 0;
    margin-top: $margin-md;
}
</style>
