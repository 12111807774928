<template>
    <div
        class="toast-notification"
        :class="{
            'cursor-pointer': notification.canTouch,
            'toast-notification--success': notification.type === 'SUCCESS' || notification.type === 'CHAT',
            'toast-notification--error': notification.type === 'ERROR',
            'toast-notification--info': notification.type === 'INFO',
        }"
        @click.stop="$emit('on-click', notification)"
    >
        <img
            v-if="notification.image?.src"
            class="alert__img"
            :alt="notification.image.alt"
            :src="notification.image.src"
            @error="onErrorImg"
        />
        <div class="toast-notification__body">
            <h2 class="toast-notification__body__title-text">
                {{ notification.title }}
            </h2>

            <p class="toast-notification__body__msg">{{ notification.body }}</p>
        </div>

        <i
            v-if="notification.type === 'CHAT'"
            class="toast-notification__close cursor-pointer"
            @click.stop="$emit('on-close', notification)"
        >
            <IconCircularClose :size="0.8" />
        </i>
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import type { ToastContent } from './Notification';
import { useImage } from '@/composables/ImageComposable';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';

const props = defineProps({
    notification: {
        type: Object as PropType<ToastContent>,
        required: true,
    },
});

const { displayDefaultImg, displayDefaultUserImg } = useImage();
const onErrorImg = (event: Event) =>
    props.notification.type === 'CHAT' ? displayDefaultUserImg(event) : displayDefaultImg(event);
defineEmits<{
    (e: 'on-click', value: ToastContent): void;
    (e: 'on-close', value: ToastContent): void;
}>();
</script>

<style lang="scss" scoped>
@import '@/assets/scss';

.toast-notification {
    position: relative;
    display: flex;
    gap: $margin-sm;
    border-radius: $radius-lg 0 0 $radius-lg;
    min-height: 5rem;

    padding: $padding-sm;
    width: fit-content;
    max-width: 25rem;
    border-left: 1rem solid $orange !important;
    background-color: $white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

    & img {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: $radius-circular;
        align-self: center;
        object-fit: cover;
        border: 1px solid $placeholder;
        margin: $margin-sm;
        margin-right: $margin-md;
    }

    &--success {
        border-left: 1rem solid $green !important;
    }

    &--failure {
        border-left: 1rem solid $error !important;
    }

    @include phone {
        max-width: unset;
        width: 100%;
        box-sizing: border-box;
    }

    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: $margin-sm;

        text-align: center;
        align-self: center;

        &__title {
            display: grid;
            grid-template-columns: 2rem 1fr 2rem;
            gap: $margin-sm;

            &-text {
                @include subtitle2;
                color: $orange;
                font-size: 1.5rem;
            }
        }

        &__msg {
            @include caption;
        }
    }

    &__close {
        position: absolute;
        top: 3%;
        right: 3%;
    }
}

.toast-remaining {
    position: absolute;
    display: grid;
    place-content: center;
    z-index: 1;
    min-width: 1.5em;
    height: 1.5em;
    bottom: -15px;
}
</style>
