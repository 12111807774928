<template>
    <component :is="currentComponent" v-if="categorySelected" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import MainCategoryWithSubcategory from '@/modules/main-products/views/mainCategoryWithSubcategory/MainCategoryWithSubcategory.vue';
import { CategoryModel } from '@/models/product/CategoryModel';
import { CustomCategories } from '@/modules/core/errors/CustomCategories';
import MainCategoryProducts from '@/modules/main-products/views/mainCategoryProducts/MainCategoryProducts.vue';

export default defineComponent({
    name: 'CategorySwitcher',
    components: {
        MainCategoryProducts,
        MainCategoryWithSubcategory,
    },
    setup() {
        const $store = useStore();

        const categorySelected = computed<CategoryModel>(() => $store.getters['categories/getCategorySelected']);
        const customCategory = computed<{ name: CustomCategories; label: string }>(
            () => $store.getters['categories/customCategory'],
        );

        const currentComponent = computed(() => {
            if (categorySelected.value?.hasSubcategories || customCategory.value) return 'MainCategoryWithSubcategory';
            return 'MainCategoryProducts';
        });

        return {
            currentComponent,
            categorySelected,
        };
    },
});
</script>
