<template>
    <retailer-image-circular />
    <ListSubcategoriesBody
        v-if="!showListOfSubcategories"
        :subcategories="subCategories"
        @on-select-subcategory="onSelectSubcategory"
    />
    <subheader-products
        :has-background="false"
        has-return
        origin-section="MAIN_CATEGORIES"
        show-search
        @redirect-on-search="redirectOnSearch"
    />
    <router-view />
</template>

<script lang="ts" setup>
import RetailerImageCircular from '@/components/retailer/RetailerImageCircular.vue';
import SubheaderProducts from '@/components/subheaders/subheaderProducts/SubheaderProducts.vue';
import { useApp } from '@/composables/useApp';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRoutes } from '@/composables/useRoutes';
import { computed, ref } from 'vue';
import { Identifiers } from '@/enums/identifiers';
import { useProductFetcher } from '@/views/seeMore/useProductFetcher';
import { CategoryModel } from '@/models/product/CategoryModel';
import MainCategorySeeMoreVue from '@/modules/main-products/views/mainCategoriesSeeMore/MainCategorySeeMore.vue';
import ListSubcategoriesBody from '@/components/category/listSubcategories/ListSubcategoriesBody.vue';

const { $router, $t, $store, $route } = useApp();

const redirectOnSearch = (query: string): void => {
    $router.push({
        name: appRoutesMap.retail.indexCategoryProducts,
        query: {
            searchOn: query,
            page: 1,
        },
        replace: $route.path.includes(appRoutesMap.retail.indexCategoryProducts),
    });
};

const { isScreenMainCategorySeeMore } = useRoutes();
const subcategoryIdSelected = ref(Identifiers.ALL);
const { categorySelected, subcategoriesList } = useProductFetcher();

const onSelectSubcategory = (idSubcategory: CategoryModel): void => {
    subcategoryIdSelected.value = idSubcategory.id;
    if (idSubcategory.id === Identifiers.ALL) {
        $router.replace({
            name: appRoutesMap.retail.baseCategoryView,
            params: {
                category: categorySelected.value?.name ?? '',
            },
        });
        return;
    }
    $router.push({
        name: MainCategorySeeMoreVue.name,
        replace: true,
        params: {
            subcategory: idSubcategory.name,
        },
    });
};

const subCategories = computed(() => [
    new CategoryModel(Identifiers.ALL, $t('txt.subcategories__all_products'), null),
    ...subcategoriesList.value,
]);

const showListOfSubcategories = computed(
    () => isScreenMainCategorySeeMore.value && $store.getters['categories/isFromSeeMoreBtn'],
);
</script>
