import {
    OrderRejectedContextDto,
    PaymentRejectedContext,
    SuggestedPaymentMethod,
} from '@/models/order/OrderRejectedContextDto';

export class ResponseOrderModel extends OrderRejectedContextDto {
    readonly id: number;
    state: string;
    readonly retailerId: number;
    readonly retailerName: string;
    readonly firstDate: string;
    readonly secondDate: string;
    readonly franchiseLogo: string;
    readonly franchiseIcon: string;
    readonly showAccumulatedPoints: boolean;

    constructor({
        id,
        first_date,
        second_date,
        franchise_icon,
        franchise_logo,
        retailer_name,
        retailer_id,
        suggested_payment_method,
        payment_rejected_context,
        state,
        show_accumulated_points,
    }: ResponseOrderDto) {
        super({ payment_rejected_context, suggested_payment_method });
        this.id = id;
        this.state = state;
        this.retailerId = retailer_id;
        this.retailerName = retailer_name;
        this.firstDate = first_date;
        this.secondDate = second_date;
        this.franchiseLogo = franchise_logo;
        this.franchiseIcon = franchise_icon;
        this.showAccumulatedPoints = show_accumulated_points ?? false;
    }
}

interface ResponseOrderDto {
    readonly id: number;
    readonly state: string;
    readonly retailer_id: number;
    readonly retailer_name: string;
    readonly first_date: string;
    readonly second_date: string;
    readonly franchise_logo: string;
    readonly franchise_icon: string;
    readonly payment_rejected_context: PaymentRejectedContext;
    readonly suggested_payment_method: SuggestedPaymentMethod;
    readonly show_accumulated_points: boolean;
}
