import { logger } from '@/utils/logger';
import UuidDevice from 'device-uuid';

export default {
    async setDeviceNotifications({ rootGetters }) {
        try {
            const token = await rootGetters['notifications/tokenFirebase'];
            const _url = `client_profile/device/${UuidDevice.DeviceUUID().get()}/`;
            await rootGetters['network/axios'].post(_url, {
                available: true,
                platform: UuidDevice.DeviceUUID().parse().platform,
                version: UuidDevice.DeviceUUID().parse().os,
                uuid: UuidDevice.DeviceUUID().get(),
                model: '',
                manufacturer: '',
                isVirtual: true,
                serial: '',
                push_notification_token: token,
                token_type: 'GMS',
            });
        } catch (err) {
            logger('SET DEVICE NOTIFICATIONS', err);
        }
    },
};
