<template>
    <base-btn
        :isLoading="isLoading"
        :bgColor="bgColor"
        :isDisabled="isDisabled"
        :fullWidth="fullWidth"
        :loadingSize="loadingSize"
    >
        <div class="btn-solid-right-arrow">
            <p :style="stylesBtn">{{ txt }}</p>
            <icon-right-arrow color="#fff"></icon-right-arrow>
        </div>
    </base-btn>
</template>

<script lang="ts">
// USAGE OF SOLID BUTTON
// Possible options:
// * normal only text (default one)
// * special text and icon (left side)

// TO CONSIDER:
// - text will always appear;
// - To activate special one set [withIcon] to true and implement slot named icon;
// - [color] handle text and icon color;
// - [bgColor] handles background color;
// - [isLoading] is optional. Although by default it does not show up;

import IconRightArrow from '../icons/IconRightArrow.vue';
import BaseBtn from './BaseBtn.vue';

export default {
    name: 'btn-solid-right-arrow',
    components: {
        BaseBtn,
        IconRightArrow,
    },
    props: {
        txt: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: '#FFF',
        },
        bgColor: {
            type: String,
            default: '#FF9012',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        stretch: {
            type: Boolean,
            default: false,
        },
        loadingSize: {
            type: String,
            default: '.5rem',
        },
    },
    computed: {
        stylesBtn(): object {
            return {
                color: this.color,
                '--overflow': this.stretch ? 'unset' : 'hidden',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.btn-solid-right-arrow {
    display: flex;
    align-items: center;
}
.btn-solid-right-arrow p {
    @include button;
    border-radius: 2rem;
    text-align: center;
    transition: all 250ms ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: var(--overflow);
    margin: 0 auto;
    margin-right: $margin-sm;
}
</style>
