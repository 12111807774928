<template>
    <base-card class="menu-container" padding="0">
        <the-delivery-options />
        <address-card @on-change-selected="selectSection" />
        <ContactCheckout />
        <retailer-schedules />
        <schedules-stock-item-card />
        <bills-card />
        <payment-card @on-change-selected="selectSection(appRoutesMap.checkout.payments.index)" />
        <PaymentGiftCard v-if="paymentsToShowGiftCards.length" />
    </base-card>
</template>

<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import AddressCard from './components/addressCard/AddressCard.vue';
import PaymentCard from './components/paymentCard/PaymentCard.vue';
import BillsCard from './components/billsCard/BillsCard.vue';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import TheDeliveryOptions from '@/components/buttons/TheDeliveryOptions.vue';
import SchedulesStockItemCard from '@/views/checkout/components/menu/components/scheduleCard/ScheduleStokItemCard.vue';
import RetailerSchedules from '@/views/checkout/components/menu/components/scheduleCard/RetailerSchedules.vue';
import { storeToRefs } from 'pinia';
import { usePickupStore } from '@/store/usePickupStore';
import PaymentGiftCard from '@/views/checkout/components/menu/components/paymentGiftCardsToUse/PaymentGiftCard.vue';
import { useCart } from '@/composables/useCart';
import { useApp } from '@/composables/useApp';
import { computed } from 'vue';
import { useGiftCards } from '@/composables/useGiftCards';
import { appRoutesMap } from '@/router/appRoutesMap';
import ContactCheckout from '@/views/checkout/components/instructions/ContactCheckout.vue';

const { showPickUpMap, temporalRetailer } = storeToRefs(usePickupStore());
const { cart } = useCart();
const { notifier, $t, $store, $router } = useApp();
const { paymentsToShowGiftCards } = useGiftCards();

const isSelectedSchedule = computed<boolean>(() => $store.getters['checkout/isSelectedSchedules']);

const selectSection = (section: string) => {
    if (section === 'pickup') {
        const _retailer: CartRetailerModel = cart.value?.retailers?.[0];
        temporalRetailer.value = new RetailerModel(
            _retailer.id,
            _retailer.isPickupAvailable,
            undefined,
            undefined,
            _retailer.name,
            _retailer.logo,
            undefined,
            undefined,
            undefined,
            _retailer.primaryColor,
            _retailer.secondaryColor,
        );
        showPickUpMap.value = true;
        return;
    }
    if (section === 'payments-checkout' && !isSelectedSchedule.value) {
        return notifier({
            type: 'ERROR',
            title: $t('txt.checkout__menu-no-select-schedule'),
            body: $t('txt.checkout__menu-please-select-schedule'),
        });
    }
    $store.commit('payments/defaultPaymentSelected', null);
    $router.push({ name: section });
};
</script>

<style lang="scss" scoped>
@import './menu-checkout.scss';
</style>
