import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import { MaxiProgramModel } from '@/models/maxiDollars/MaxiProgram';
import { MaxiBalanceModel } from '@/models/maxiDollars/MaxiBalance';
import { TiptiCardHistoryExchangeModel } from '@/models/historyExchange/TiptiCardHistoryExchange';

export default {
    program(state): MaxiProgramModel {
        return state.program;
    },
    maxiCardSelected(state): PaymentMethodModel {
        return state.maxiCardSelected;
    },
    history(state): Array<TiptiCardHistoryExchangeModel> {
        return state.history;
    },
    balance(state): MaxiBalanceModel {
        return state.balance;
    },
};
