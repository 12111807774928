export const i18Nbreadcrumb = {
    ES: {
        breadcrumb: {
            account: 'Mi cuenta',
            statistics: 'Estadísticas',
            profile: 'Perfil',
            programs: 'Programas',
            address: 'Direcciones',
            bills: 'Facturas y Documentos',
            creditCards: 'Tarjetas de crédito',
            shareData: 'Compartir información',
            orders: 'Mis Ordenes',
            order: 'Pedido',
            prime: 'Tipti Prime',
            notifications: 'Notificaciones',
            referred: 'Referidos',
            subscriptions: 'Suscripciones',
            settings: 'Configuraciones',
            wallet: 'Tipti Wallet',
            tiptiCard: 'Tipti Card',
            history: 'Historial Tipti Card',
            gift: 'Gift Card',
            recharge: 'Recargas',
            myGifts: 'Mis gift cards',
            transfer: 'Transferir',
            maxiCard: 'MaxiCard',
            registeredCard: 'Tarjeta registrada',
            membershipCard: 'Tarjeta de afiliación',
            giftCards: 'Tipti Gift Cards',
            expiredGiftCards: 'Tarjetas expiradas',
        },
    },

    EN: {
        breadcrumb: {
            account: 'My account',
            statistics: 'Statistics',
            profile: 'Profile',
            programs: 'Programs',
            address: 'Addresses',
            bills: 'Bills and Documents',
            creditCards: 'Credit cards',
            shareData: 'Share data',
            orders: 'Orders',
            order: 'order',
            prime: 'Tipti Prime',
            notifications: 'Notifications',
            referred: 'Referred',
            subscriptions: 'Subscriptions',
            settings: 'Ssettings',
            wallet: 'Tipti Wallet',
            tiptiCard: 'Tipti Card',
            history: 'Tipti Card history',
            gift: 'Gift Card',
            recharge: 'Recharge',
            myGifts: 'My Gifts cards',
            transfer: 'Transfer',
            maxiCard: 'MaxiCard',
            registeredCard: 'Registered card',
            membershipCard: 'Membership card',
            giftCards: 'Tipti Gift Cards',
            expiredGiftCards: 'Expired cards',
        },
    },
};
