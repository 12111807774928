import { OrderModel } from '@/models/order/OrderModel';
import { OrderResumenModel, toOrderResumeModel } from '@/models/order/OrderResumen';
import { FiltersModel, toFiltersModel } from '@/models/order/FiltersModel';
import { OrderActiveHomeModel } from '@/models/order/OrderActiveHomeModel';
import { ProductModel } from '@/models/product/ProductModel';
import { logger } from '@/utils/logger';
import type { ResponsePaged } from '@/modules/core/models/ResponseModels';

export default {
    async addProductsToCart({ rootGetters, dispatch }, products: Array<ProductModel>): Promise<void> {
        await rootGetters['network/axios'].put('v2/client_profile/order/items/cart/', {
            order_items: products.map((product: ProductModel) => {
                return { id: product?.id, note: product?.note, quantity: product?.productQuantity };
            }),
        });
        await dispatch(
            'cart/fetchCart',
            {
                overrideDeactivatedProduct: false,
            },
            { root: true },
        );
    },
    async fetchOrders(
        { rootGetters, commit, getters, dispatch },
        payload: {
            isActiveOrders: boolean;
            page: number;
            retailerId: string;
            hideOrderToRate: boolean;
            findPaymentRejected: boolean;
            searchActiveOrder: boolean;
            setOrderForExtraItems: boolean;
        },
    ): Promise<ResponsePaged<OrderModel>> {
        const _hideOrderToRate = payload?.hideOrderToRate ? '&version=v2' : '';
        const url = `v2/client/orders?active_orders=${payload.isActiveOrders}${_hideOrderToRate}&page=${
            payload?.page ?? 1
        }&retailer_id=${payload?.retailerId ?? ''}`;
        const { data } = await rootGetters['network/axios'].get(url);
        if (!payload.page || payload.page === 1) commit('orders', []);
        const orders: OrderModel[] = [
            ...getters['orders'],
            ...(data['results'] ? data['results'].map((order) => new OrderModel(order)) : []),
        ];
        commit('orders', orders);
        if (payload.findPaymentRejected) {
            commit('payments/cleanPaymentMethods', undefined, { root: true });
            commit('payments/paymentsToShow', undefined, { root: true });
        }
        const ordersCanAddExtraItems = payload?.searchActiveOrder
            ? orders.filter((order) => order?.canAddExtraItemsFromActiveOrder)
            : [];

        if (
            payload.setOrderForExtraItems &&
            ordersCanAddExtraItems.length === 1 &&
            ordersCanAddExtraItems.at(0)?.retailer?.id === rootGetters['retailers/retailerSelected']?.id
        ) {
            commit('extraItems/order', ordersCanAddExtraItems[0], { root: true });
            dispatch('getOrderResume', ordersCanAddExtraItems[0]?.id);
        } else {
            commit('extraItems/order', null, { root: true });
        }

        return data;
    },
    async getOrderResume({ dispatch, commit }, orderId: number): Promise<OrderResumenModel> {
        try {
            const [activeOrder, historyOrder] = await Promise.allSettled([
                dispatch('getActiveOrderDetail', orderId),
                dispatch('getHistoryOrderDetail', orderId),
            ]);
            if (activeOrder.status === 'rejected' || historyOrder.status === 'rejected') throw Error('ERROR');
            const orderDetail: OrderResumenModel = toOrderResumeModel({ ...activeOrder.value, ...historyOrder.value });
            commit('orderDetailSelected', orderDetail);
            return orderDetail;
        } catch (err) {
            commit('orderDetailSelected', null);
            logger(`GET_ORDER_RESUME`, err);
            throw err.response;
        }
    },
    async getActiveOrderDetail({ rootGetters }, orderId: number): Promise<object> {
        try {
            const url = `v2/client/${orderId}/order`;
            const { data } = await rootGetters['network/axios'].get(url);
            return data;
        } catch (err) {
            throw Error(`GET_ACTIVE_ORDER_DETAIL ${err}`);
        }
    },
    async getHistoryOrderDetail({ rootGetters }, orderId: number): Promise<object> {
        try {
            const url = `v2/client-profile/order/${orderId}/detail?taxes_by_country=true`;
            const { data } = await rootGetters['network/axios'].get(url);
            return data;
        } catch (err) {
            throw Error(`GET_HISTORY_ORDER_DETAIL ${err}`);
        }
    },
    async getOrderFilters({ rootGetters }): Promise<FiltersModel> {
        try {
            const url = 'v2/client/order-filters';
            const response: object = await rootGetters['network/axios'].get(url);
            return toFiltersModel(response['data']);
        } catch (err) {
            throw Error(`GET_ORDER_FILTERS ${err}`);
        }
    },
    async getOrdersActiveFromHome(
        { rootGetters, commit, dispatch },
        { hideOrderToRate, setOrderForExtraItems }: { hideOrderToRate: boolean; setOrderForExtraItems: boolean },
    ): Promise<OrderActiveHomeModel> {
        try {
            const { data } = await rootGetters['network/axios'].get('v2/client/orders/active', {
                params: {
                    version: hideOrderToRate ? 'v2' : '',
                },
            });

            if (!data) return;
            if (!data?.order && data?.total_orders <= 0) return;
            const result = {
                total_orders: data.total_orders,
                order: data?.order ? new OrderModel(data?.order) : undefined,
            };
            if (
                result.total_orders === 1 &&
                setOrderForExtraItems &&
                rootGetters['retailers/retailerSelected']?.id === result.order?.retailer?.id &&
                result.order?.canAddExtraItemsFromActiveOrder
            ) {
                dispatch('getOrderResume', result.order?.id).then((order) =>
                    commit('extraItems/order', order, { root: true }),
                );
            }

            return result;
        } catch (err) {
            throw Error(`GET_ORDERS_ACTIVATE_FROM_HOME ${err}`);
        }
    },
    async getOrdersToRate({ rootGetters }): Promise<ResponsePaged<OrderModel>> {
        const { data } = await rootGetters['network/axios'].get('v2/client/orders/delivered');
        return {
            count: data['count'],
            next: data['next'],
            results: data['results'].map((order) => new OrderModel(order)),
            previous: data['previous'],
        };
    },
};
