<template>
    <section>
        <div class="body-credit-card-header">
            <p>{{ $t("general['credit-debit-card']") }}</p>
            <base-link :is-route="false" :txt-link="$t('txt.credit-card__accepted-cards')" @click="showModal = true" />
        </div>
        <profile-form v-if="showProfileModal" @on-accept="profileCompleted" @on-close="showProfileModal = false" />
        <dotted-rectangle
            v-if="!showAddCard"
            class="dotted-rectangle"
            :button-text="$t('txt.credit-card__new-card')"
            @click="canAddCard"
        />
        <body-add-credit-cards v-if="showAddCard" @on-new-credit-card="onNewCreditCardShowed" @on-return="onReturn" />
        <list-cards v-else @add-card="canAddCard" />
    </section>
    <base-modal
        v-if="showModal"
        :title="$t('txt.credit-card__accepted-cards')"
        :z-index="11"
        @on-close="showModal = false"
    >
        <p class="body-credit-card__alert">
            {{ $t("txt['credit-card__accepted-cards-to']", { cards: creditCardsAllowed }) }}
        </p>
    </base-modal>
    <validate-card
        v-if="showVerificationFlow"
        :force-validate="baseConfiguration?.cardValidationNewRegister"
        :payment-method-id="paymentMethodId"
        @on-close="fetchPaymentMethods"
    />
</template>
<script lang="ts">
import DottedRectangle from '@/components/dottedRectangle/DottedRectangle.vue';
import ListCards from '../components/listCards/ListCards.vue';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BaseLink from '@/components/links/BaseLink.vue';
import BodyAddCreditCards from '../bodyAddCreditCards/BodyAddCreditCards.vue';
import ValidateCard from '@/views/creditCards/components/ValidateCard/ValidateCard.vue';
import { mapActions, mapGetters } from 'vuex';
import { logger } from '@/utils/logger';
import ProfileForm from '@/views/cart/components/profileForm/ProfileForm.vue';
import { useUser } from '@/composables/useUser';

export default {
    name: 'BodyCreditCards',
    components: {
        ValidateCard,
        BaseModal,
        DottedRectangle,
        ListCards,
        BaseLink,
        BodyAddCreditCards,
        ProfileForm,
    },
    props: {
        needToBack: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['show-card-added'],
    setup() {
        const { checkCompletedProfile } = useUser();
        return {
            checkCompletedProfile,
        };
    },
    data() {
        return {
            showModal: false,
            showAddCard: false,
            paymentMethodId: undefined,
            showVerificationFlow: false,
            showProfileModal: false,
        };
    },
    computed: {
        ...mapGetters({
            countrySelected: 'external/countrySelected',
            baseConfiguration: 'user/baseConfiguration',
            user: 'user/user',
        }),
        creditCardsAllowed(): string {
            return this.$t(
                this.countrySelected?.alphaCode === 'PA'
                    ? 'Visa, Mastercard'
                    : 'Visa, Mastercard, American Express, Diners',
            );
        },
    },
    watch: {
        needToBack(newValue) {
            this.showAddCard = newValue;
        },
    },
    methods: {
        ...mapActions({
            fetchCreditCards: 'payments/creditCardList',
        }),
        profileCompleted() {
            this.showProfileModal = false;
            this.showAddCard = true;
        },
        canAddCard(): void {
            if (this.checkCompletedProfile) {
                this.showAddCard = true;
                return;
            }
            this.showProfileModal = true;
            this.$store.dispatch('segment/viewModalCompleteProfile', {
                event: 'View modal complete profile',
                origin: {
                    screen: 'payment_method',
                },
            });
        },
        onNewCreditCardShowed(): void {
            this.$emit('show-card-added', true);
        },
        async fetchPaymentMethods(): Promise<void> {
            try {
                this.isLoading = true;
                this.showVerificationFlow = false;
                this.creditCards = await this.fetchCreditCards(true);
            } catch (err) {
                logger('FETCH_CREDIT_CARDS', err);
            } finally {
                this.isLoading = false;
            }
        },
        onReturn(paymentMethodId: number): void {
            this.showAddCard = false;
            this.paymentMethodId = paymentMethodId;
            if (this.baseConfiguration?.cardValidationNewRegister) {
                this.showVerificationFlow = true;
            }
            this.$emit('show-card-added', false);
        },
    },
    created() {
        if (this.$route.query.add !== 'true') return;
        this.showAddCard = true;
    },
};
</script>
<style lang="scss" scoped>
@import './body-credit-cards.scss';
</style>
