<script lang="ts" setup>
import { useWallet } from '@/views/wallet/useWallet';
import { useRouter } from 'vue-router';
import { useTranslate } from '@/composables/UseTranslate';
import IconBack from '@/components/icons/IconBack.vue';
import GiftCard from '@/components/cards/GiftCard.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import GiftCardLatestTransactionsSection from './GiftCardTransactionsSection.vue';
import TheGiftCardDetatilRetailersSection from './TheGiftCardDetatilRetailersSection.vue';
import GiftCardTransactionsSkeleton from './GiftCardTransactionsSkeleton.vue';
import SkeletonSimple from '@/components/skeletons/skeletonSimple/SkeletonSimple.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'TheGiftCardDetail',
});

const $router = useRouter();
const { exec } = useTranslate();

const { giftCardSelected, giftCardTransactions } = useWallet();

const resetCards = () => {
    $router
        .replace({
            name: appRoutesMap.Wallet,
        })
        .then(() => {
            giftCardSelected.value = undefined;
            giftCardTransactions.value = undefined;
        });
};

if (!giftCardSelected.value)
    $router.replace({
        name: appRoutesMap.Wallet,
    });
</script>

<template>
    <button class="gift-card-detail__btn" @click="resetCards">
        <icon-back :size="0.8" color="#000000" />
        <span class="gift-card-detail__btn-text">{{ $t('txt.go-back') }}</span>
    </button>
    <section class="gift-card-detail__top">
        <BaseCard v-if="giftCardSelected" class="gift-card-detail__card" padding="2rem">
            <div class="gift-card-detail__card__container">
                <GiftCard class="gift-card-detail__card__container__card" :card="giftCardSelected" />
                <div class="gift-card-detail__card__container__exp-date">
                    {{ $t('txt.gift-card-valid-until') + giftCardSelected.expiration_date }}
                </div>
            </div>
            <h3 class="gift-card-detail__card__title">{{ giftCardSelected.card_name }}</h3>
            <p class="gift-card-detail__card__description">
                {{ exec(giftCardSelected.description) }}
            </p>
        </BaseCard>
        <Suspense>
            <GiftCardLatestTransactionsSection />
            <template #fallback>
                <GiftCardTransactionsSkeleton />
            </template>
        </Suspense>
    </section>
    <Suspense>
        <TheGiftCardDetatilRetailersSection />
        <template #fallback>
            <div class="gift-card-detail__retailers__skeleton">
                <SkeletonSimple class="gift-card-detail__retailers__skeleton__title" height="2rem" width="15rem" />
                <SkeletonSimple class="gift-card-detail__retailers__skeleton__title" height="2rem" width="45rem" />
                <SkeletonSimple class="gift-card-detail__retailers__skeleton__card" height="20rem" width="95%" />
            </div>
        </template>
    </Suspense>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.gift-card-detail {
    position: relative;

    &__top {
        display: flex;
        gap: 2rem;
        margin: 0 $margin-lg;
        @include tablet {
            flex-direction: column;
            margin: 0 $margin-md;
        }
    }

    &__retailers {
        &__skeleton {
            margin-top: $margin-xlg;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__title {
                margin-bottom: $margin-lg;
            }

            &__card {
                margin-bottom: $margin-xlg;
            }
        }
    }
}

.gift-card-detail__btn {
    all: unset;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin: 0 $margin-xlg;
    @include phone {
        margin: 0 $margin-md;
    }

    &-text {
        @include button;
        font-size: 1.8rem;
        font-weight: 600;
        text-transform: capitalize;
    }
}

.gift-card-detail__card {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    width: 100%;
    height: fit-content;
    @include tablet {
        width: auto;
    }

    &__container {
        align-self: center;
        position: relative;

        &__card {
            @include sm-phone {
                width: 16rem;
                height: 8rem;
            }
        }

        &__exp-date {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $white;
            @include caption;
            font-size: 10px;
            border-top-right-radius: 5px;
            padding: $padding-sm;
            color: $orange;
            font-weight: 600;
        }
    }

    &__title {
        @include subtitle2;
        color: $orange;
        margin-top: $margin-md;
    }

    &__description {
        @include body2;
    }
}
</style>
