<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
});
const width = computed<number>(() => 39 * props.size);
const height = computed<number>(() => 39 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0  38 39">
        <path
            :stroke="color"
            d="M2.31055 35.887L5.34935 28.8886C3.0245 25.5884 1.98395 21.5532 2.42297 17.5402C2.86199 13.5273 4.7504 9.81255 7.7338 7.09307C10.7172 4.37358 14.5905 2.83634 18.6268 2.76983C22.6632 2.70332 26.585 4.11211 29.6564 6.73182C32.7278 9.35153 34.7376 13.0021 35.3085 16.9983C35.8795 20.9946 34.9725 25.0619 32.7577 28.4369C30.5428 31.812 27.1724 34.2627 23.279 35.3292C19.3855 36.3957 15.2367 36.0047 11.6111 34.2295L2.31055 35.887Z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4.25581"
        />
        <path
            :stroke="color"
            d="M13.3604 15.6278C13.3604 15.872 13.4574 16.1062 13.6301 16.2789C13.8028 16.4516 14.037 16.5486 14.2812 16.5486C14.5254 16.5486 14.7596 16.4516 14.9323 16.2789C15.105 16.1062 15.202 15.872 15.202 15.6278V13.7861C15.202 13.5419 15.105 13.3076 14.9323 13.1349C14.7596 12.9623 14.5254 12.8652 14.2812 12.8652C14.037 12.8652 13.8028 12.9623 13.6301 13.1349C13.4574 13.3076 13.3604 13.5419 13.3604 13.7861V15.6278ZM13.3604 15.6278C13.3604 18.07 14.3305 20.4122 16.0575 22.1392C17.7844 23.8661 20.1266 24.8363 22.5688 24.8363M22.5688 24.8363H24.4105C24.6548 24.8363 24.889 24.7392 25.0617 24.5665C25.2344 24.3939 25.3314 24.1596 25.3314 23.9154C25.3314 23.6712 25.2344 23.437 25.0617 23.2643C24.889 23.0916 24.6548 22.9946 24.4105 22.9946H22.5688C22.3246 22.9946 22.0904 23.0916 21.9177 23.2643C21.745 23.437 21.648 23.6712 21.648 23.9154C21.648 24.1596 21.745 24.3939 21.9177 24.5665C22.0904 24.7392 22.3246 24.8363 22.5688 24.8363Z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4.25581"
        />
    </BaseIcon>
</template>
