<template>
    <section class="no-content">
        <slot></slot>
        <h2 v-if="title.length" class="no-content__title">{{ title }}</h2>
        <p class="no-content__description">{{ description }}</p>
        <btn-solid
            class="no-content__action"
            v-if="ctaText.length"
            @click="$emit('on-action')"
            :txt="ctaText"
            :bg-color="colorBtn"
            :is-loading="isLoading"
        />
    </section>
</template>

<script lang="ts">
import BtnSolid from '@/components/buttons/BtnSolid.vue';

export default {
    name: 'no-content',
    inheritAttrs: true,
    components: { BtnSolid },
    emits: ['on-action'],
    props: {
        title: {
            type: String,
            default: '',
        },
        colorTitle: {
            type: String,
            default: '#FF9012',
        },
        colorBtn: {
            type: String,
            default: '#FF9012',
        },
        description: {
            type: String,
            default: '',
        },
        ctaText: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.no-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $margin-md;
    &__btn {
        margin: $margin-lg;
    }
}
.no-content__icon {
    height: 30vh;
}
.no-content__title {
    @include subtitle2;
    text-align: center;
    margin-top: $margin-sm;
    color: v-bind(colorTitle);
}
.no-content__description {
    @include body2;
    text-align: center;
    margin-top: $margin-md;
    &--retailer {
        max-width: 30rem;
    }
}
.no-content__action {
    margin: $margin-md $margin-none;
}
</style>
