export interface RemoteLocaleDto {
    readonly EN: string;
    readonly ES: string;
}

export class RemoteLocaleModel implements RemoteLocaleDto {
    EN: string;
    ES: string;
    constructor(data: RemoteLocaleDto) {
        this.EN = data.EN;
        this.ES = data.ES;
    }

    static fromJson(json: object): RemoteLocaleModel {
        if (!json) return null;
        if (typeof json === 'string') return new RemoteLocaleModel(JSON.parse(json) as RemoteLocaleDto);
        return new RemoteLocaleModel(json as RemoteLocaleDto);
    }
}
