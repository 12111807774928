<template>
    <div
        class="program-header"
        :style="{
            'background-color': programSelected.mainColor,
        }"
    >
        <img class="program-header__img" :src="programSelected.logo" :alt="programSelected.name" />
    </div>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';

export default {
    name: 'header-program',
    computed: {
        ...mapGetters({
            programSelected: 'loyaltyAndRewards/program',
        }),
    },
};
</script>
<style lang="scss" scoped>
@import 'header-program';
</style>
