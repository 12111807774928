import { ListGroupModel, toListGroupModel } from '@/models/list/ListGroupModel';
import { ListModel, toListModel } from '@/models/list/ListModel';
import { DayModel } from '@/models/list/DayModel';
import { AdRecipeModel, toAdRecipeModel } from '@/models/ads/AdRecipeModel';
import { RecipeDetailModel, toRecipeDetailModel } from '@/models/recipes/RecipeDetailModel';
import { AdModel, toAdModel } from '@/models/ads/AdModel';
import { logger } from '@/utils/logger';
import { ListDetailItemModel, ListDetailModel, toListDetailModel } from '@/models/list/ListDetailModel';
import { ProductModel } from '@/models/product/ProductModel';
import { RecipeInstagram, toRecipeInstagram } from '@/models/recipes/RecipeInstagram';
import { toUnavailableProductsByHomologationModel } from '@/models/product/UnavailableProductsByHomologationModel';

interface DeleteProductToList {
    listIds: Array<number>;
    productId: number;
    quantity: number;
}

interface AddEditProductToList {
    listIds: Array<number>;
    listsNames: Array<string>;
    product: ProductModel;
    listItem: ListDetailItemModel;
    screen?: string;
    section?: string;
}

interface AddProductsToCart {
    listId: number;
    items: Array<ListDetailItemModel>;
}

interface ResultsAdsForRetailer {
    adRecipesForRetailer: Array<AdModel>;
    isLastPage: boolean;
}

export { ResultsAdsForRetailer };

export default {
    async tiptiRecipes(
        { rootGetters },
        page: number,
    ): Promise<{
        totalRecipes: number;
        isLastPage: boolean;
        recipes: Array<RecipeInstagram>;
    }> {
        try {
            const url = `v2/api/landing/instagram?page=${page}`;
            const { data } = await rootGetters['network/axios'].get(url);
            return {
                totalRecipes: data['count'],
                isLastPage: data['next'] === null,
                recipes: data['results']?.map((recipe) => toRecipeInstagram(recipe) ?? []),
            };
        } catch (err) {
            logger('TIPTI_RECIPES', err);
        }
    },

    async getListGroup({ rootGetters }): Promise<Array<ListGroupModel>> {
        try {
            const url = 'v2/api/tipti-ads/groups';
            const response = await rootGetters['network/axios'].get(url);
            return response.data.map((list: object) => toListGroupModel(list));
        } catch (_) {
            throw Error('GET_LIST_GROUP');
        }
    },
    async getMyList({ rootGetters }): Promise<Array<ListModel>> {
        try {
            const url = 'v2/client_profile/shopping_list/';
            const { data } = await rootGetters['network/axios'].get(url);
            return data?.map((list: object) => toListModel(list));
        } catch (_) {
            throw Error('GET_MY_LIST');
        }
    },
    async getList({ rootGetters, commit }, listId: number): Promise<ListDetailModel> {
        try {
            const url = `v2/client_profile/shopping_list/${listId}/items`;
            const { data } = await rootGetters['network/axios'].get(url);
            const list = toListDetailModel(data);
            commit('listDetail', list.items);
            return list;
        } catch (_) {
            throw Error('GET_LIST');
        }
    },
    async createList({ rootGetters }, name: string): Promise<ListModel> {
        try {
            const data = {
                name: name,
            };
            const url = 'v2/client_profile/list';
            const response = await rootGetters['network/axios'].post(url, data);
            return toListModel(response.data);
        } catch (err) {
            logger('CREATE_LIST');
            throw err.response;
        }
    },
    async deleteList({ rootGetters }, listId: number): Promise<void> {
        try {
            const url = `client_profile/list/${listId}/`;
            await rootGetters['network/axios'].delete(url);
        } catch (_) {
            throw Error('DELETE_LIST');
        }
    },
    async deleteProductList({ rootGetters }, productId: number): Promise<void> {
        try {
            const url = `client_profile/list/${productId}/items/`;
            await rootGetters['network/axios'].delete(url);
        } catch (_) {
            throw Error('DELETE_PRODUCT_LIST');
        }
    },

    async deleteProductToList({ rootGetters }, payload: DeleteProductToList): Promise<void> {
        try {
            const data = {
                data: {
                    list_id: payload.listIds,
                    stock_item_id: payload.productId,
                    stock_item_quantity: payload.quantity,
                },
            };
            const url = `v2/client-profile/shopping-list/items`;
            await rootGetters['network/axios'].delete(url, { data: data });
        } catch (_) {
            throw Error('DELETE_PRODUCT_TO_LIST');
        }
    },

    async addEditProductToList({ rootGetters, dispatch }, payload: AddEditProductToList): Promise<void> {
        try {
            const product = payload.product ?? payload.listItem;
            const stockitemQuantity = payload.product?.productQuantity
                ? +payload.product?.productQuantity === 0
                    ? 1
                    : payload.product?.productQuantity
                : payload.listItem?.quantity
                  ? +payload.listItem?.quantity === 0
                      ? 1
                      : payload.listItem?.quantity
                  : 1;
            const data = {
                data: {
                    list_id: payload.listIds,
                    stock_item_id: product.id,
                    stock_item_note: product?.note ?? '',
                    stock_item_quantity: stockitemQuantity,
                },
            };
            const url = `v2/client-profile/shopping-list/items`;
            await rootGetters['network/axios'].post(url, data);
            dispatch(
                'segment/listSaved',
                {
                    product: payload.product ?? payload.listItem.product,
                    list: {
                        list: payload.listsNames.toString().replace(/[[\]{}'"]/g, ''),
                        list_id: payload.listIds,
                        list_amount:
                            (!payload.product ? payload.listItem.price : payload.product.price) * stockitemQuantity,
                        products_number: stockitemQuantity,
                    },
                    retail: {
                        retail_id: rootGetters['retailers/retailerSelected']?.id,
                        retail: rootGetters['retailers/retailerSelected']?.name,
                        retail_category: rootGetters['retailers/retailerSelected'].retailerType,
                        retail_category_id: rootGetters['retailers/retailerSelected'].retailerType,
                    },
                    origin: {
                        screen: payload.screen,
                        section: payload.section,
                    },
                },
                {
                    root: true,
                },
            );
        } catch (_) {
            logger(_);
            throw Error('ADD_EDIT_PRODUCT_TO_LIST');
        }
    },
    async addCartToList(
        { rootGetters, dispatch },
        payload: {
            products: Array<ProductModel>;
            listNames: Array<string>;
            listIds: Array<number>;
            screen: string;
            section: string;
        },
    ): Promise<void> {
        try {
            const data = {
                list_ids: payload.listIds,
            };
            const url = `v2/client_profile/shopping_cart_copy/${rootGetters['cart/cart'].id}/`;
            await rootGetters['network/axios'].post(url, data);
            const amount = payload.products.reduce(
                (accumulator, currentValue) => accumulator + currentValue.price * currentValue.productQuantity,
                0,
            );

            Promise.allSettled(
                payload.products.map((product) => {
                    dispatch(
                        'segment/listSaved',
                        {
                            product: product,
                            list: {
                                list: payload.listNames,
                                list_id: payload.listIds,
                                list_amount: amount,
                                products_number: payload.products.length,
                            },
                            retail: {
                                retail_id: rootGetters['retailers/retailerSelected']?.id,
                                retail: rootGetters['retailers/retailerSelected']?.name,
                                retail_category: rootGetters['retailers/retailerSelected'].retailerType,
                                retail_category_id: rootGetters['retailers/retailerSelected'].retailerType,
                            },
                            origin: {
                                screen: payload.screen,
                                section: payload.section,
                            },
                        },
                        {
                            root: true,
                        },
                    );
                }),
            );
        } catch (_) {
            throw Error('ADD_CART_TO_LISTS');
        }
    },
    async deleteProductFromList({ rootGetters }, payload): Promise<void> {
        /// * PAYLOAD OPTIONS:
        /// listIds: Array<number>
        /// productId: number
        // / * PAYLOAD OPTIONS:
        // / listIds: Array<number>
        // / productId: number
        try {
            const url = 'v2/clD';
            const data = {
                stock_item_id: payload.productId,
                stock_item_quantity: 1,
                list_id: payload.listIds,
            };
            await rootGetters['network/axios'].delete(url, {
                data: {
                    data: data,
                },
            });
        } catch (err) {
            throw Error(err);
        }
    },

    async deleteProductFromFavorites({ rootGetters }, payload): Promise<void> {
        // / * PAYLOAD OPTIONS:
        // / productId: number
        try {
            const url = `v2/client/shopping-list/favorites/stock-items/${payload.productId}`;
            await rootGetters['network/axios'].delete(url);
        } catch (err) {
            throw Error('DELETE_PRODUCT_FROM_FAVORITES');
        }
    },
    async addProductsToCart({ rootGetters, commit }, payload: AddProductsToCart): Promise<void> {
        try {
            const data: object = payload['listId']
                ? {
                      list_id: payload['listId'],
                  }
                : {
                      listitems: payload['items'].map((item: ListDetailItemModel) => {
                          return {
                              id: item.listItemId,
                              quantity: item.quantity ?? 1,
                              note: item?.note ?? '',
                          };
                      }),
                  };
            const url = 'v2/client_profile/list/items/cart/v2/';
            const response = await rootGetters['network/axios'].put(url, data);
            // / Parse json to model
            if (!response?.data) commit('product/unavailableProductsByHomologation', [], { root: true });
            const _data = response?.data
                .map((element) => {
                    return toUnavailableProductsByHomologationModel(element);
                })
                .filter((element) => element?.items?.length);
            commit('product/unavailableProductsByHomologation', _data, { root: true });
        } catch (_) {
            commit('product/unavailableProductsByHomologation', [], { root: true });
            throw Error('ADD_PRODUCTS_TO_CART');
        }
    },

    async getDays({ rootGetters }): Promise<DayModel[]> {
        try {
            const { data } = await rootGetters['network/axios'].get('v2/client_profile/subscription/hours');
            return data;
        } catch (_) {
            throw Error('GET_DAYS');
        }
    },
    async scheduleList({ rootGetters }, payload): Promise<void> {
        try {
            const data = {
                delivery: payload.delivery,
                id_list: payload.idList,
                period: payload.period,
                period_type: payload.periodType,
            };
            const url = 'v2/client_profile/repetitive_list/id/';
            await rootGetters['network/axios'].post(url, data);
        } catch (_) {
            throw Error('SCHEDULE_LIST');
        }
    },
    async deleteScheduleList({ rootGetters }, id: number): Promise<void> {
        try {
            const url = `v2/client_profile/repetitive_list/${id}/`;
            await rootGetters['network/axios'].delete(url);
        } catch (_) {
            throw Error('DELETE_SCHEDULE_LIST');
        }
    },
    async getListRecipes({ rootGetters }, subgroupId: number): Promise<Array<AdRecipeModel>> {
        try {
            const url = `v2/api/tipti-ads/subgroup/${subgroupId}`;
            const response = await rootGetters['network/axios'].get(url);
            return response.data?.map((ads: object) => toAdRecipeModel(ads));
        } catch (_) {
            throw Error('GET_LIST_RECIPES');
        }
    },
    async getRecipeDetail({ rootGetters }, recipeId: number): Promise<RecipeDetailModel> {
        try {
            const url = `v2/api/tipti-ads/logging/recipe/${recipeId}/ingredients`;
            const response = await rootGetters['network/axios'].get(url);
            return toRecipeDetailModel(response.data);
        } catch (err) {
            logger('GET_RECIPE_DETAIL', err);
            throw Error('GET_RECIPE_DETAIL');
        }
    },
    async getRecipeFromAd({ rootGetters }, adId: number): Promise<AdModel> {
        try {
            const url = `v2/api/tipti-ads/ad/${adId}/detail`;
            const response = await rootGetters['network/axios'].get(url);
            return toAdModel(response.data);
        } catch (_) {
            throw Error('GET_ADD_RECIPE');
        }
    },
    async getRecipeAdsForRetailer({ rootGetters }, page: number): Promise<ResultsAdsForRetailer> {
        const { data } = await rootGetters['network/axios'].get(`v2/tipti-ads/recipes`, {
            params: {
                retailer_id: rootGetters['retailers/retailerSelected'].id,
                page: page ?? 1,
            },
        });
        const isLastPage = !data['next'];
        return {
            adRecipesForRetailer: data['results'].map((ad: object) => toAdModel(ad)),
            isLastPage: isLastPage,
        };
    },
    async recipeToCart(
        { rootGetters },
        payload: {
            id?: number;
            items: Array<{
                stock_item_id: number;
                quantity: number;
                note: string;
            }>;
        },
    ): Promise<void> {
        try {
            const data = {
                stock_item_list: payload.items,
            };
            if (payload.id) data['tipti_ad_id'] = payload.id;
            const url = 'v2/client_profile/multiple-items/shopping-cart';
            await rootGetters['network/axios'].put(url, data);
        } catch (e) {
            throw Error('RECIPE_TO_CART');
        }
    },
    async createLinkList({ rootGetters }, listId: number): Promise<string> {
        try {
            const { data } = await rootGetters['network/axios'].post('v2/deep-links/product-list', {
                product_list_id: listId,
            });
            return data['deep_link'] ?? '';
        } catch (_) {
            throw Error('CREATE_LINK_LIST');
        }
    },
    async addListToMyListsFromDeeplink({ rootGetters }, listId: number): Promise<void> {
        try {
            const url = 'v2/client-profile/copy-shopping-list';
            const data: object = {
                list_id: listId,
                accepted: true,
            };
            await rootGetters['network/axios'].put(url, data);
        } catch (err) {
            throw Error(`ADD_LIST_TO_FAVORITE_FROM_DEEPLINK: ${err}`);
        }
    },
};
