<template>
    <section class="banner-covid" :class="{ 'banner-covid--no-img': !showImage }">
        <img
            v-if="showImage"
            class="banner-covid__mobile-img"
            src="@/assets/img/shopper_how_works_ok.png"
            :alt="$t('txt.banner__covid-tipti-shopper-with')"
        />
        <div class="banner-covid__info">
            <icon-info color="#FFF"></icon-info>
            <p>
                {{ $t("general['covid-msg']") }}
                <span @click="openTermAndConditions">{{ $t("general['here']") }}</span>
            </p>
        </div>
    </section>
</template>

<script lang="ts">
import IconInfo from '@/components/icons/IconInfo.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'banner-covid',
    components: {
        IconInfo,
    },
    props: {
        showImage: { type: Boolean, default: true },
    },
    methods: {
        openTermAndConditions() {
            window.open(this.termsAndConditions, '_blank');
        },
    },
    computed: {
        ...mapGetters({ user: 'user/user', countrySelected: 'external/countrySelected' }),
        termsAndConditions(): string {
            return this.countrySelected?.termsAndConditions[this.user?.defaultLanguage];
        },
    },
};
</script>

<style lang="scss" scoped>
@import './banner-covid.scss';
</style>
