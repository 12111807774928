import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
    token: '',
    isAuth: false,
    user: undefined,
    userLanguage: 'es',
    baseConfiguration: undefined,
    grantedPermissions: [],
    selectedDocumentType: '',
    requireAuth: false,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
