<template>
    <label>
        <input @input="updateValue" v-model="status" type="checkbox" :checked="item.isSelected" />
        <span></span>
    </label>
</template>

<script>
/// *** Because this component is used in dependency from parent to child, this action needs to be performed, placing a custom event to receive the function that is emitted from parent to child.
/// TODO: WE WILL IMPROVE THIS LATER
/// *** Variables to considerer:
/// *** [modelValue] modelValue is used to change the state of the check from either on to off
/// *** [item] is used to obtain the data that has been selected

export default {
    name: 'check-circular',
    data() {
        return {
            status: this.initialValue,
        };
    },
    props: {
        modelValue: {
            type: Boolean,
        },
        item: {
            type: Object,
        },
        initialValue: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-check-selected', 'update:modelValue'],
    methods: {
        updateValue() {
            if (this.item) {
                this.$emit('on-check-selected', this.item);
            }
            this.$emit('update:modelValue', !this.status);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';
label {
    position: relative;
}
span {
    cursor: pointer;
    width: 1.25em;
    height: 1.25em;
    border: 1px solid #ccc;
    display: flex;
    border-radius: 50%;
    transition: all linear 0.3s;
    background-color: $white;
    justify-content: center;
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        width: 0.75em;
        border-radius: $radius-circular;
        background-color: $orange;
        height: 0.75em;
        visibility: hidden;
    }
}
input {
    display: none;
    &:checked ~ span {
        background: $white;
        &:after {
            visibility: visible;
        }
    }
}
</style>
