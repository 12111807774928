<script generic="T extends { id: number }" lang="ts" setup>
import { useCarousel } from '@/components/carousels/components/useCarousel';
import IconBackNoBorder from '@/components/icons/IconBackNoBorder.vue';
import { computed, PropType } from 'vue';
import { RequestState } from '@/modules/core/types/WorkingState';

const props = defineProps({
    carouselGap: {
        type: String,
        default: '0.5rem',
    },
    autoplay: {
        type: Boolean,
        default: false,
    },
    interval: {
        type: Number,
        default: undefined,
    },
    backgroundArrow: {
        type: String,
        default: '#dfdfdf',
    },
    colorArrow: {
        type: String,
        default: '#606060',
    },
    items: {
        type: Array<T>,
        required: true,
    },
    showLoadMore: {
        type: Boolean,
        default: false,
    },
    state: {
        type: String as PropType<RequestState>,
        default: 'NONE',
    },
    skeletons: {
        type: Number,
        default: 10,
    },
});

const emit = defineEmits<(e: 'reached-end') => void>();
const carousel = computed(() => props.carouselGap ?? '0.5rem');

const { showLeftArrow, slider, showRightArrow, move, autoPlay, clearTimer } = useCarousel(props, emit);
</script>

<template>
    <section class="carousel" @mouseout="autoPlay" @mouseover="clearTimer">
        <main ref="slider" class="carousel__items">
            <template v-if="items.length">
                <slot v-for="(item, index) in items" :key="item" :item="item" :position="index" name="item" />
            </template>
            <slot v-if="state !== 'LOADING'" name="more" />
            <template v-if="state === 'LOADING' || state === 'FIRST_LOAD'">
                <slot v-for="i in skeletons" :key="i" name="skeleton" />
            </template>
            <slot name="suggestion" />
        </main>
        
        <icon-back-no-border
            v-if="showLeftArrow"
            class="carousel__arrow-back cursor-pointer"
            :background-fill="backgroundArrow"
            :color="colorArrow"
            :icon-name="$t('icon.backward')"
            :size="0.8"
            @click="move(true)"
        />
        <icon-back-no-border
            v-if="showRightArrow"
            class="carousel__arrow-next cursor-pointer"
            :background-fill="backgroundArrow"
            :color="colorArrow"
            :icon-name="$t('icon.forward')"
            :size="0.8"
            @click="move(false)"
        />
    </section>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.carousel {
    position: relative;
    
    &__items {
        gap: v-bind(carousel);
        align-self: flex-start;
        scroll-snap-align: start;
        display: grid;
        overflow-y: scroll;
        grid-template-rows: 1fr;
        grid-auto-columns: max-content;
        grid-auto-flow: column;
        scroll-behavior: smooth;
        box-sizing: border-box;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding: style.$padding-sm style.$padding-none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.carousel__arrow-back {
    @include style.box-glow;
    position: absolute;
    left: style.$margin-sm;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
    border-radius: style.$radius-circular;
}

.carousel__arrow-next {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: style.$margin-sm;
    z-index: 0;
    transform: rotate(180deg);
    @include style.box-glow;
    border-radius: style.$radius-circular;
}
</style>
