import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d311a032"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-text" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("span", _mergeProps({
      class: ["cta-text", {
                'cta-text--no-underline': !$props.isUnderline,
                'cursor-pointer': $props.inLink,
                'cta-text--normal': !$props.isNormalWeight,
            }]
    }, _ctx.$attrs, {
      textContent: _toDisplayString($props.text)
    }), null, 16, _hoisted_2),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}