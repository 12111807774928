<script lang="ts" setup>
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';
import BrandArtworkCard from '@/views/newsPress/components/BrandArtworkCard.vue';
import type { BrandArtwork } from './brandArtwork';
import IconTipti from '@/components/icons/IconTipti.vue';
import IconTiptiTime from '@/components/icons/IconTiptiTime.vue';
import { useI18n } from 'vue-i18n';
import IconTiptiWithSlogan from '@/components/icons/IconTiptiWithSlogan.vue';

const { t: $t } = useI18n();

const typeFiles = {
    ZIP: 'zip',
    PDF: 'pdf',
};

const brandArtwork: BrandArtwork[] = [
    {
        icon: IconTiptiWithSlogan,
        title: $t("general['logo-with-description']"),
        description: $t("general['logo-text-brand']"),
        linkSvg: 'https://statics.tipti.market/press_release/logotipo_con_descripcion.svg',
        linkPng: 'https://statics.tipti.market/press_release/logotipo_con_descripcion.png',
    },
    {
        icon: IconTipti,
        title: $t("general['flat-logo']"),
        description: $t("general['logo-text-brand']"),
        linkSvg: 'https://statics.tipti.market/press_release/logotipo_plano.svg',
        linkPng: 'https://statics.tipti.market/press_release/logotipo_plano.png',
    },
    {
        icon: IconTiptiTime,
        title: $t("general['logo-with-slogan']"),
        description: $t("general['logo-text-brand']"),
        linkSvg: 'https://statics.tipti.market/press_release/logotipo_con_slogan.svg',
        linkPng: 'https://statics.tipti.market/press_release/logotipo_con_slogan.png',
    },
];

const downloadFile = (typeFile: string) => {
    const link = document.createElement('a');
    link.href =
        typeFile === typeFiles.ZIP
            ? 'https://www.tiptisemillerosdetalento.com/press_release/logos_tipti.zip'
            : 'https://statics.tipti.market/press_release/manual_marca_tipti_2023.pdf';
    link.download = `logotipo-tipti${typeFile}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
</script>

<template>
    <div class="brand-atr">
        <h4 class="brand-atr__title">Brand Artwork</h4>
        <p class="brand-atr__description">
            {{ $t("general['brand-text']") }}
            <a
                class="personal-data__form"
                download
                href="https://statics.tipti.market/press_release/manual_marca_tipti_2023.pdf"
                target="_blank"
            >
                <u class="brand-atr__manual"> {{ $t("general['brand-manual']") }} </u>
            </a>
        </p>

        <p class="brand-atr__file-format">{{ $t("general['file-formats']") }}</p>
        <p class="brand-atr__site-web">{{ $t("general['for-websites-and-applications']") }}</p>

        <CarouselTipti background-arrow="#ffc788" color-arrow="white">
            <BrandArtworkCard v-for="item in brandArtwork" :key="item" :item-brand="item" />
        </CarouselTipti>

        <div class="container_download">
            <button class="container_download__button cursor-pointer" @click="downloadFile(typeFiles.ZIP)">
                <span class="container_download__text"> {{ $t("general['download-complete-kit']") }} </span>
            </button>

            <button class="container_download__button cursor-pointer" @click="downloadFile(typeFiles.PDF)">
                <span class="container_download__text"> {{ $t("general['download-brand-manual']") }} </span>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.brand-atr {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    gap: style.$margin-md;

    &__title {
        @include style.headline6;
        font-size: 30px;
        color: style.$dark-grey;
        margin-bottom: style.$margin-md;
    }

    &__description {
        @include style.body1;
    }

    &__manual {
        @include style.body1;
        color: style.$orange;
    }

    &__file-format {
        @include style.body1;
        color: style.$dark-grey;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-top: style.$margin-md;
    }

    &__site-web {
        @include style.body1;
        color: style.$orange;
        font-size: 22px;
        margin-top: style.$margin-md;
    }
}

.container_download {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: style.$margin-md;

    @include style.phone {
        flex-direction: column;
    }

    &__button {
        border-radius: 5px;
        background: white;
        border: 1px solid style.$orange;
        padding: style.$margin-md;
    }

    &__text {
        color: style.$orange;
        font-size: 14px;
        padding: style.$margin-md;
        font-weight: 600;
    }
}
</style>
