import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60ecd0c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-products" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search = _resolveComponent("search")!
  const _component_autocomplete_your_products = _resolveComponent("autocomplete-your-products")!
  const _component_search_results = _resolveComponent("search-results")!
  const _component_autocomplete_retailers_global_search = _resolveComponent("autocomplete-retailers-global-search")!
  const _component_autocomplete_products_global_search = _resolveComponent("autocomplete-products-global-search")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_search, {
      "is-alternative-style": $props.searchCategories,
      "is-autocomplete": $props.hasAutocomplete && $data.showPredictions,
      "is-loading": $props.isLoading || $data.isLoadingResults,
      placeholder: $options.searchPlaceholder,
      onOnKeyup: $options.autocomplete,
      onOnKeyupEsc: $options.cleanPredictions,
      onOnBlur: $options.cleanPredictions,
      onOnSearch: _cache[0] || (_cache[0] = (value) => $options.onSearch(value, null, false)),
      onOnChangeValue: _cache[1] || (_cache[1] = (value) => ($data.query = value)),
      onOnFocus: _cache[2] || (_cache[2] = ($event: any) => ($data.focusedInput = true)),
      onOnCleanQuery: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-clean')))
    }, null, 8, ["is-alternative-style", "is-autocomplete", "is-loading", "placeholder", "onOnKeyup", "onOnKeyupEsc", "onOnBlur"]),
    ($options.isAutocompleteActive)
      ? _withDirectives((_openBlock(), _createElementBlock("aside", {
          key: 0,
          ref: "slide",
          class: "autocomplete",
          onKeydown: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => ($options.cleanYourProducts && $options.cleanYourProducts(...args)), ["esc"]))
        }, [
          ($data.yourProducts?.length && !$setup.isHomePage)
            ? (_openBlock(), _createBlock(_component_autocomplete_your_products, {
                key: 0,
                products: $data.yourProducts,
                "products-prediction-quantity": $setup.results.length,
                onOnSelectProduct: $options.onSearch
              }, null, 8, ["products", "products-prediction-quantity", "onOnSelectProduct"]))
            : _createCommentVNode("", true),
          (!$setup.isHomePage)
            ? (_openBlock(), _createBlock(_component_search_results, {
                key: 1,
                products: $setup.results,
                query: $data.query,
                onOnClick: $options.onSearch
              }, null, 8, ["products", "query", "onOnClick"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                ($setup.retailers.length && $data.retailersSearched.length)
                  ? (_openBlock(), _createBlock(_component_autocomplete_retailers_global_search, {
                      key: 0,
                      "retailers-global-search": $data.retailersSearched,
                      onOnSelectRetailer: $options.onSelectRetailer
                    }, null, 8, ["retailers-global-search", "onOnSelectRetailer"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_autocomplete_products_global_search, {
                  "products-global-search": $setup.results,
                  onOnSelectProduct: $options.onSearch
                }, null, 8, ["products-global-search", "onOnSelectProduct"])
              ], 64))
        ], 32)), [
          [_directive_click_outside, $options.cleanYourProducts]
        ])
      : _createCommentVNode("", true)
  ]))
}