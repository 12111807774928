class DeferredModel {
    code: string;
    name: string;
    translatedName: string;

    constructor(code: string, name: string, translatedName: string) {
        this.code = code;
        this.name = name;
        this.translatedName = translatedName;
    }
}
const toDeferredModel = (data: object) => {
    return data ? new DeferredModel(data['code'], data['name'] ?? '', data['translated_name'] ?? '') : null;
};
export { toDeferredModel, DeferredModel };
