<template>
    <div class="">
        <section-header
            :has-back="isMobile"
            :title="$t('txt.affiliates_and_rewards')"
            :with-borders="false"
            @on-back="onBack"
        />
        <program-skeleton v-if="isLoadingPrograms" />
        <no-content
            v-else-if="noContent"
            class="no-content"
            :cta-text="$t('cta.reload_page')"
            :description="$t('general.error_occurred')"
            @on-action="getPrograms"
        >
            <icon-afflicted-shop-bag />
        </no-content>
        <section v-if="showAfiliation" class="programs">
            <h3 class="program-title">
                {{ $t('txt.available_programs') }}
            </h3>
            <div class="program-container">
                <program-type
                    v-for="program in allProgram?.afiliations"
                    :key="program.id"
                    :program="program"
                    @click="onSelectProgram(program)"
                />
            </div>
        </section>
        <section v-if="showRewards" class="programs">
            <h3 class="program-title">
                {{ $t('txt.available_rewards') }}
            </h3>
            <div class="program-container">
                <program-type
                    v-for="program in programs?.rewards"
                    :key="program.id"
                    :program="program"
                    @click="onSelectProgram(program)"
                />
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import IconAfflictedShopBag from '@/components/icons/IconAfflictedShopBag.vue';
import ProgramType from '@/views/myAccount/views/profile/views/loyaltyPrograms/loyaltyAndRewards/ProgramType.vue';
import { mapActions, mapGetters } from 'vuex';
import { isMobileBrowser } from '@/utils/utils';
import { LoyaltyAndRewardProgramsModel } from '@/models/loyaltyPrograms/LoyaltyAndRewardModel';
import ProgramSkeleton from '@/components/skeletons/skeletonProgrom/SkeletonProgram.vue';
import { LoyaltyOrRewardModel } from '@/models/loyaltyPrograms/LoyaltyOrRewardsModel';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'loyalty-and-rewards',
    data: () => ({
        isLoadingPrograms: false,
        showNoContent: false,
    }),
    components: { ProgramSkeleton, SectionHeader, ProgramType, IconAfflictedShopBag, NoContent },
    computed: {
        ...mapGetters({
            userLanguage: 'user/userLanguage',
            programs: 'loyaltyAndRewards/programs',
        }),
        showAfiliation(): boolean {
            return this.allProgram?.afiliations?.length && !this.isLoadingPrograms;
        },
        showRewards(): boolean {
            return this.allProgram?.rewards?.length && !this.isLoadingPrograms;
        },
        allProgram(): LoyaltyAndRewardProgramsModel {
            return this.programs;
        },
        isMobile(): boolean {
            return isMobileBrowser();
        },
        hasBack(): boolean {
            const path = this.$route.fullPath.split('/');
            return this.isMobile || path;
        },
        noContent(): boolean {
            return this.showNoContent || (!this.allProgram?.afiliations.length && !this.allProgram?.rewards.length);
        },
    },
    methods: {
        ...mapActions({
            fetchPrograms: 'loyaltyAndRewards/programs',
            checkSubscription: 'loyaltyAndRewards/checkSubscription',
            registerSubscription: 'loyaltyAndRewards/registerSubscription',
        }),
        onBack(): void {
            this.$router.go(-1);
        },
        async getPrograms(): Promise<void> {
            try {
                this.isLoadingPrograms = true;
                await this.fetchPrograms();
            } catch (e) {
                this.showNoContent = true;
            } finally {
                this.isLoadingPrograms = false;
            }
        },
        onSelectProgram(program: LoyaltyOrRewardModel) {
            this.$store.commit('loyaltyAndRewards/program', program);
            if (program.subscription) {
                this.$router.push({
                    name: appRoutesMap.reward.benefitScreen,
                    params: {
                        id: program.id,
                    },
                });
            } else {
                this.$router.push({
                    name: appRoutesMap.reward.placeholderScreen,
                    params: {
                        id: program.id,
                    },
                });
            }
        },
    },
    created() {
        this.getPrograms();
    },
};
</script>

<style lang="scss" scoped>
@import 'loyalty-and-rewards';
</style>
