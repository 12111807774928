<template>
    <section ref="htmlRef" class="dropdown" :class="{ dropdown__bordered: isBordered }">
        <button
            class="dropdown-button"
            :class="{ 'dropdown-button__bordered': isBordered }"
            :style="{ color: txtColor, fontWeight: fontWeight }"
            @click="showOptions = !showOptions"
        >
            <img v-if="icon" class="dropdown-button__icon" :src="icon" alt="" />
            <span v-else class="dropdown-button__label">{{ labelShow }}</span>
            <icon-dropdown :color="btnColor" :size="0.75" />
        </button>
        <base-card
            v-if="showOptions"
            class="dropdown-options"
            :class="{ 'dropdown-option--align-left': !alignToRight, 'dropdown-options__bordered': isBordered }"
            :padding="isBordered ? '0' : '0.5rem'"
        >
            <slot></slot>
        </base-card>
    </section>
</template>
<script lang="ts" setup>
import IconDropdown from '../../icons/IconDropdown.vue';
import BaseCard from '../../cards/BaseCard.vue';
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
    isBordered: {
        type: Boolean,
        default: false,
    },
    labelShow: {
        type: String,
        default: '',
    },
    icon: {
        type: String,
    },
    btnColor: {
        type: String,
        default: '#FFF',
    },
    txtColor: {
        type: String,
        default: '#FFF',
    },
    alignToRight: {
        type: Boolean,
        default: true,
    },
    fontWeight: {
        type: String,
        default: '600',
    },
});

const showOptions = ref(false);
const htmlRef = ref<HTMLElement>();

onClickOutside(htmlRef, () => {
    showOptions.value = false;
});
</script>

<style lang="scss" scoped>
@import './dropdown-base.scss';
</style>
