import { GiftCardsAlertModel } from '@/models/giftCardsAlert/giftCardsAlertModel';
import { GiftCardsAlertCheckoutModel } from '@/models/giftCardsAlert/giftCardsAlertCheckoutModel';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';

export default {
    async getGifCardsAlerts({ rootGetters }): Promise<Array<GiftCardsAlertModel>> {
        const { data } = await rootGetters['network/axios'].get('v3/gift_cards/client/alert_notification');
        return data;
    },
    async getGifCardsAlertsCheckout(): Promise<Array<GiftCardsAlertCheckoutModel>> {
        const { data } = await axiosInstance().get('v3/gift_cards/client/checkout/validate', {
            cancelPreviousRequests: true,
        });
        return data;
    },
    async updateGifCardsAlerts({ rootGetters }, payload: { idGiftCard: number }): Promise<void> {
        await rootGetters['network/axios'].put(`v3/gift_cards/client/${payload.idGiftCard}/alert_notification`);
    },
};
