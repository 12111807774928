<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
});
const width = computed<number>(() => 27 * props.size);
const height = computed<number>(() => 28 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0 27 28">
        <path
            d="M15.258 10.4442H4.20534C3.30909 10.4442 2.58253 11.1708 2.58253 12.067V23.1197C2.58253 24.0159 3.30909 24.7425 4.20534 24.7425H15.258C16.1542 24.7425 16.8808 24.0159 16.8808 23.1197V12.067C16.8808 11.1708 16.1542 10.4442 15.258 10.4442Z"
            stroke="#FF9012"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.66667"
        />
        <path
            :fill="color"
            clip-rule="evenodd"
            d="M21.7928 3.9094C22.2232 3.9094 22.636 4.08038 22.9403 4.38471C23.2446 4.68905 23.4156 5.10181 23.4156 5.53221V16.5848C23.4156 17.0152 23.2446 17.428 22.9403 17.7323C22.636 18.0367 22.2232 18.2076 21.7928 18.2076H20.5647C20.1045 18.2076 19.7314 18.5807 19.7314 19.041C19.7314 19.5012 20.1045 19.8743 20.5647 19.8743H21.7928C22.6652 19.8743 23.5019 19.5277 24.1188 18.9108C24.7357 18.294 25.0823 17.4573 25.0823 16.5848V5.53221C25.0823 4.65979 24.7357 3.8231 24.1188 3.2062C23.5019 2.58931 22.6652 2.24274 21.7928 2.24274H10.7402C9.86775 2.24274 9.03106 2.58931 8.41416 3.2062C7.79727 3.8231 7.4507 4.65979 7.4507 5.53221V6.76028C7.4507 7.22052 7.82379 7.59361 8.28403 7.59361C8.74427 7.59361 9.11736 7.22052 9.11736 6.76028V5.53221C9.11736 5.10182 9.28834 4.68905 9.59267 4.38471C9.89701 4.08038 10.3098 3.9094 10.7402 3.9094H21.7928Z"
            fill-rule="evenodd"
        />
    </BaseIcon>
</template>
