<template>
    <base-modal
        :auto-size="!isMobile"
        :has-back-arrow="changeCreditCard"
        :title="modalTitle"
        :z-index="5"
        header-text-color="#ff9012"
        @on-close="onClose"
        @on-back-arrow="toggleShowModal"
    >
        <div class="prime-payment">
            <div v-if="!changeCreditCard" class="prime-payment__content">
                <coupon :full-width="true" section="TIPTI_PRIME" />
                <payment-method-only-credit-card-selection-tile @on-change-payment="changeCreditCard = true" />
                <card-tipti-prime-product class="cursor-pointer" @click="onClose" />
                <balance class="prime-payment__balance" :balance="prepareBalance" :origin-screen="'TIPTI_PRIME'" />
            </div>
            <select-credit-card v-else :amount="currentPrimeDeal.price" @on-close="changeCreditCard = false" />
        </div>
        <section-footer v-if="!changeCreditCard" class="prime-payment__footer" :has-border="true" :with-shadow="true">
            <btn-out-line
                class="prime-payment__footer-btn"
                :is-disabled="!paymentIdSelected"
                :is-loading="isLoading"
                :txt="$t('cta.subscribe')"
                @click="paymentIdSelected ? $emit('show-pdf-terms') : null"
            />
        </section-footer>
    </base-modal>
    <snack-bar
        :body="snackData?.msg ?? ''"
        :is-active="isActive && isFromError"
        :is-failure="snackData?.failed ?? false"
        :is-success="snackData?.success ?? false"
        @on-snackbar-close="isActive = false"
    />
</template>

<script lang="ts">
import Coupon from '@/components/inputs/redeem/Coupon.vue';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import SelectCreditCard from '@/views/myAccount/views/prime/components/selectCreditCard/SelectCreditCard.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import CardTiptiPrimeProduct from '@/components/cards/cardTiptiPrimeProduct/CardTiptiPrimeProduct.vue';
import { TotalDetailsModel } from '@/models/cart/TotalDetailsModel';
import { mapActions, mapGetters } from 'vuex';
import Balance from '@/views/cart/components/balance/Balance.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import { TiptiCardDealsModel } from '@/models/tiptiCard/TiptiCardDealsModel';
import { TaxModel } from '@/models/cart/TaxModel';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import { logger } from '@/utils/logger';
import { isMobileBrowser } from '@/utils/utils';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import PaymentMethodOnlyCreditCardSelectionTile from '@/modules/payment-methods/components/PaymentMethodOnlyCreditCardSelectionTile.vue';

export default {
    name: 'TiptiPrimePayment',
    components: {
        PaymentMethodOnlyCreditCardSelectionTile,
        SectionFooter,
        CardTiptiPrimeProduct,
        BaseModal,
        Coupon,
        SelectCreditCard,
        BtnOutLine,
        Balance,
        SnackBar,
    },
    props: {
        snackData: {
            type: Object,
            required: false,
        },
        isFromError: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['on-close', 'show-pdf-terms'],
    data() {
        return {
            changeCreditCard: false,
            termsAndConditions: undefined,
            showTermsAndConditions: false,
            isSuccess: false,
            isFailure: false,
            snackBarMessage: '',
            isLoading: false,
            isActive: this.snackData?.active ?? false,
        };
    },
    async mounted() {
        await this.verify();
    },
    computed: {
        ...mapGetters({
            countrySelected: 'external/countrySelected',
            defaultPrime: 'tiptiCard/defaultPrime',
            primeSelected: 'tiptiCard/primeSelected',
            creditCardDefault: 'payments/creditCardDefault',
            creditCardSelected: 'payments/creditCardSelected',
            paymentsToShow: 'payments/paymentsToShow',
            paymentIdSelected: 'payments/paymentIdSelected',
            baseConfiguration: 'user/baseConfiguration',
        }),
        isMobile() {
            return isMobileBrowser();
        },
        currentPrimeDeal(): TiptiCardDealsModel {
            return this.primeSelected ?? this.defaultPrime;
        },
        modalTitle(): string {
            if (this.changeCreditCard) return this.$t('txt.credit-card__cards-choosed');
            if (this.showTermsAndConditions) return this.$t('txt.prime__alert-terms');
            return this.currentPrimeDeal?.name;
        },
        prepareBalance() {
            const discount =
                this.currentPrimeDeal?.discountRate > 0
                    ? (this.currentPrimeDeal?.price * 100) / this.currentPrimeDeal?.discountRate -
                      this.currentPrimeDeal?.price
                    : 0;

            return new TotalDetailsModel(
                undefined,
                discount,
                undefined,
                this.currentPrimeDeal.price,
                undefined,
                undefined,
                this.countrySelected?.taxName,
                this.countrySelected?.taxes.filter((tax: TaxModel) => {
                    return tax.isServiceTax;
                }),
                0,
                [],
                this.currentPrimeDeal.price,
            );
        },
    },
    methods: {
        ...mapActions({
            fetchCreditCardList: 'payments/creditCardList',
        }),
        async verify() {
            try {
                this.isLoading = true;
                const creditCardPayment = this.paymentsToShow?.find(
                    (payment: PaymentToShowModel) =>
                        payment?.id == this.paymentIdSelected && payment?.name === 'payment-cards',
                );
                if (creditCardPayment) return;
                this.$store.commit('payments/cleanPaymentMethods');
                this.$store.commit('payments/paymentsToShow', []);

                await this.fetchCreditCardList(false);
                if (!this.creditCardDefault) return;
                if (this.baseConfiguration?.cardValidationOldRegister && !this.creditCardDefault.isValidate) return;
                this.$store.commit('payments/paymentIdSelected', this.creditCardDefault.id);
                this.$store.commit(
                    'payments/paymentsToShow',
                    new PaymentToShowModel({
                        name: 'payment-cards',
                        id: this.creditCardDefault.id,
                        idExpired: this.creditCardDefault.isExpired,
                        amount: undefined,
                        data: this.creditCardDefault.data?.cardDigits?.slice(1, 5),
                        analyticData: {
                            franchise: this.creditCardDefault.franchise,
                            bin: this.creditCardDefault.creditCardBrandData?.bin,
                        },
                        objectData: ['payment-cards', this.creditCardDefault?.id],
                    }),
                );
            } catch (e) {
                logger('FETCH_CREDIT_CARD_LIST', e);
            } finally {
                this.isLoading = false;
            }
        },
        async toggleShowModal() {
            await this.verify();
            this.changeCreditCard = false;
        },
        onClose() {
            this.$emit('on-close', false);
        },
        onShowSnackBar(properties) {
            this.isSuccess = properties.success;
            this.isFailure = properties.failed;
            this.snackBarMessage = properties.msg;
            this.showSnackBar = true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './tipta-prime-payment.scss';
</style>
