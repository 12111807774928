import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06bac9d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "no-content" }
const _hoisted_2 = {
  key: 0,
  class: "no-content__title"
}
const _hoisted_3 = { class: "no-content__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_solid = _resolveComponent("btn-solid")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    ($props.title.length)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString($props.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_3, _toDisplayString($props.description), 1),
    ($props.ctaText.length)
      ? (_openBlock(), _createBlock(_component_btn_solid, {
          key: 1,
          class: "no-content__action",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-action'))),
          txt: $props.ctaText,
          "bg-color": $props.colorBtn,
          "is-loading": $props.isLoading
        }, null, 8, ["txt", "bg-color", "is-loading"]))
      : _createCommentVNode("", true)
  ]))
}