import { logger } from '@/utils/logger';
import { MembershipType } from '@/modules/cambrella/models/MembershipType';
import { CambrellaMembershipModel } from '@/modules/cambrella/models/CambrellaMembership';

interface SubscriptionInputInterface {
    payment_method: number;
    membership_id: number;
}

export default {
    async cambrellaMembershipTypes({ rootGetters }): Promise<MembershipType[]> {
        const { data } = await rootGetters['network/axios'].get('typemembershipcambrellas');
        return data;
    },
    async cambrellaMembership({ rootGetters, commit }): Promise<void> {
        try {
            const _url = 'client_profile/membershipcambrellas';
            const { data } = await rootGetters['network/axios'].get(_url);
            const memberships = CambrellaMembershipModel.builderList(data);
            const cambrellaMembership = memberships.find((membership) => membership.enabled);
            commit('cambrellaMembership', cambrellaMembership);
        } catch (err) {
            commit('cambrellaMembership', null);
            logger('GET_MEMBERSHIPS', err);
        }
    },
    async subscribe({ rootGetters }, payload: SubscriptionInputInterface): Promise<CambrellaMembershipModel> {
        try {
            const url = 'v2/cambrella/membership/subscription';
            const { data } = await rootGetters['network/axios'].post(url, payload);
            return CambrellaMembershipModel.fromJSON(data);
        } catch (e) {
            logger('CAMBRELLAS_SUBSCRIPTION', e);
        }
    },
};
