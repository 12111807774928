import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3deb4a80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "categoryProducts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_subcategories_body = _resolveComponent("list-subcategories-body")!
  const _component_subheader_products = _resolveComponent("subheader-products")!
  const _component_product_not_found = _resolveComponent("product-not-found")!
  const _component_subheader_separator = _resolveComponent("subheader-separator")!
  const _component_products_grid = _resolveComponent("products-grid")!
  const _component_SkeletonGrid = _resolveComponent("SkeletonGrid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_list_subcategories_body, {
      class: "banner-categories-list",
      subcategories: $setup.categories,
      onOnSelectSubcategory: $options.onSelectCategory
    }, null, 8, ["subcategories", "onOnSelectSubcategory"]),
    _createVNode(_component_subheader_products, {
      class: "subheader",
      "display-name": $options.categoryName,
      "has-button": false,
      name: $options.categoryName,
      "has-return": ""
    }, null, 8, ["display-name", "name"]),
    _createElementVNode("div", {
      id: "productsBody",
      class: _normalizeClass(["products-body", { 'products-body--scroll': $props.localScroll }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (!$setup.productsByCategory.length && !$setup.isLoading)
          ? (_openBlock(), _createBlock(_component_product_not_found, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_subheader_separator, {
                name: $options.categoryName,
                "show-btn": false,
                "has-padding": ""
              }, null, 8, ["name"]),
              _createVNode(_component_products_grid, {
                class: "products-grid",
                products: $setup.productsByCategory
              }, null, 8, ["products"])
            ], 64)),
        ($setup.isLoading)
          ? (_openBlock(), _createBlock(_component_SkeletonGrid, { key: 2 }))
          : _createCommentVNode("", true)
      ], 512)
    ], 2)
  ], 64))
}