import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_deferred_option = _resolveComponent("deferred-option")!
  const _component_base_btn = _resolveComponent("base-btn")!
  const _component_general_alert = _resolveComponent("general-alert")!

  return (_openBlock(), _createBlock(_component_general_alert, {
    accentHeader: true,
    "accept-button-txt": _ctx.$t('cta.continue'),
    onlyConfirmationBtn: true,
    "show-buttons": false,
    showCloseIcon: true,
    title: $setup.textAlertDeferredAndMixedPayment,
    padding: "1rem",
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-continue'))),
    onOnAccept: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-continue')))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_deferred_option),
      _createVNode(_component_base_btn, {
        "border-radius": "5px",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-continue')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('cta.continue')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["accept-button-txt", "title"]))
}