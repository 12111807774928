<template>
    <div class="tipti-card__content">
        <p v-if="showConfirmButton" class="credit-card__amount">
            {{ $t('txt.tipti-prime__your-amount') }}
            <span v-currency="amount" />
        </p>
        <div v-if="showConfirmButton" class="alert-purchase__divider">
            <horizontal-divider />
        </div>
        <div class="credit-card__content">
            <body-credit-card @show-card-added="onShowAddNewCard" />
        </div>
        <section-footer class="footer__select-card" :with-shadow="false">
            <btn-out-line
                v-if="showConfirmButton"
                :is-disabled="!canSubscribe"
                :txt="$t('cta.confirm')"
                @click="canSubscribe ? onClose() : null"
            />
        </section-footer>
    </div>
</template>

<script lang="ts">
import BodyCreditCard from '@/views/creditCards/bodyCreditCards/BodyCreditCards.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import HorizontalDivider from '@/components/dividers/HorizontalDivider.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import { usePayments } from '@/composables/usePayments';

export default {
    name: 'SelectCreditCard',
    components: {
        SectionFooter,
        BodyCreditCard,
        BtnOutLine,
        HorizontalDivider,
    },
    props: {
        amount: {
            type: [Number, String],
            required: true,
        },
    },
    emits: ['on-close', 'on-select-card'],
    setup() {
        const { paymentIdSelected } = usePayments();
        return {
            paymentIdSelected,
        };
    },
    data: () => ({
        showConfirmButton: true,
    }),
    computed: {
        canSubscribe() {
            return this.paymentIdSelected;
        },
    },
    mounted() {
        this.$store.commit('payments/cleanPaymentMethods');
        this.$store.commit('payments/paymentIdSelected', undefined);
        this.$store.commit('payments/paymentsToShow', undefined);
    },
    methods: {
        onShowAddNewCard(onNewCardAdded) {
            this.showConfirmButton = !onNewCardAdded;
        },
        onClose() {
            this.$emit('on-close', false);
        },
    },
};
</script>

<style lang="scss" scoped>
@import './select-credit-card.scss';
</style>
