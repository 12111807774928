import { TiptiCardDealsModel } from '@/models/tiptiCard/TiptiCardDealsModel';
import { TiptiCardModel } from '@/models/tiptiCard/TiptiCardModel';
import { PrimeInformationModel } from '@/models/user/PrimeInformationModel';

export default {
    tiptiCard(state, tiptiCard: TiptiCardModel) {
        state.tiptiCard = tiptiCard;
    },
    primeSelected(state, prime: TiptiCardDealsModel) {
        state.primeSelected = prime;
    },
    totalToPay(state, amount: number) {
        state.totalToPay = amount;
    },
    isTiptiCardCheckout(state, isTiptiCardCheckout: boolean) {
        state.isTiptiCardCheckout = isTiptiCardCheckout;
    },
    primeDeals(state, deals: Array<PrimeInformationModel>) {
        state.primeDeals = deals;
    },
    defaultPrime(state, prime: PrimeInformationModel) {
        state.defaultPrime = prime;
    },
};
