<template>
    <base-modal :hasClose="hasClose" :hasHeader="hasHeader" :title="title" @on-close="$emit('on-close')">
        <div class="model-error">
            <icon-circular-close :iconName="$t('icon.error')" :size="1.5" color="#F03F51" />
            <h1 v-if="showOops" class="model-error__oops">Oops!</h1>
            <h1 v-if="title.length && !hasHeader" class="model-error__title">{{ title }}</h1>
            <h2 v-if="body.length" class="model-error__text">{{ body }}</h2>
            <btn-solid
                v-if="showAction && ctaText.length"
                :txt="ctaText"
                class="model-loading__action"
                @click="$emit('on-action')"
            />
            <btn-text
                v-if="showAction && linkText.length"
                :text="linkText"
                class="cta-text--normal"
                @click="$emit('on-text-action')"
            />
        </div>
    </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnText from '@/components/buttons/BtnText.vue';

export default {
    name: 'error-modal',
    components: { BtnText, BtnSolid, BaseModal, IconCircularClose },
    props: {
        title: {
            type: String,
            default: '',
        },
        body: {
            type: String,
            default: '',
        },
        ctaText: {
            type: String,
            default: '',
        },
        linkText: {
            type: String,
            default: '',
        },
        hasClose: {
            type: Boolean,
            default: false,
        },
        hasHeader: {
            type: Boolean,
            default: false,
        },
        showAction: {
            type: Boolean,
            default: false,
        },
        showOops: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-close', 'on-action', 'on-text-action'],
};
</script>
<style lang="scss" scoped>
@import 'error-modal';
</style>
