import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fec21c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-tipti" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_tipti = _resolveComponent("icon-tipti")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_icon_tipti, {
      color: $props.positiveContrast ? '#ff9012' : '#FF9012',
      size: $props.size
    }, null, 8, ["color", "size"]),
    _createElementVNode("div", {
      class: "container-tipti__slogan",
      style: _normalizeStyle($options.setPositiveContrast)
    }, [
      _createElementVNode("strong", {
        class: "container-tipti--orange container-tipti--bold",
        style: _normalizeStyle($options.setPositiveContrast)
      }, _toDisplayString(_ctx.$t("txt['slogan__online-and']")), 5),
      _createElementVNode("strong", {
        class: "container-tipti--orange container-tipti--bold",
        style: _normalizeStyle($options.setPositiveContrast)
      }, _toDisplayString(_ctx.$t("txt['slogan__supermarket-and']")), 5),
      _createElementVNode("p", {
        class: "container-tipti--orange",
        style: _normalizeStyle($options.setPositiveContrast)
      }, _toDisplayString(_ctx.$t("txt['slogan__home-essenials']")), 5)
    ], 4)
  ]))
}