<template>
    <base-modal
        v-if="activeModal && !getting"
        :color-iconclose="textHeaderColor"
        :has-close="true"
        :has-header="true"
        :header-text-color="textHeaderColor"
        :orange-header="false"
        :title="$t('general.available-balance')"
        @on-close="closeAlertGiftCard"
    >
        <div class="gift-card-alert-checkout">
            <p class="gift-card-alert-checkout__description">
                {{ $t('txt.gift-card-checkout-available-balance-modal-description') }}
            </p>

            <section v-if="tiptiCard?.availableAmount > 0" class="gift-card-alert-checkout__cards-list">
                <base-card-flat
                    class="gift-card-alert-checkout__img gift-card-alert-checkout__tipti-card"
                    :style="{
                        'background-image': `url(${tiptiCard?.image})`,
                        background:
                            ' radial-gradient(58.17% 58.8% at 61.95% 50.02%, #FAB735 0%, #FAB434 25.37%, #F8AA2E 52.7%, #F49827 80.85%, #F18820 100%)',
                        'box-sizing': 'border-box',
                    }"
                    border-radius="5px"
                    padding="0.5rem"
                >
                    <icon-tipti :size="0.3" color="white" />
                    <span class="gift-card-alert-checkout__tipti-card__text">Card</span>
                </base-card-flat>
                <p class="gift-card-alert-checkout__card-description">{{ $t('general.payment-tipti-card') }}</p>

                <p v-currency="tiptiCard?.availableAmount" class="gift-card-alert-checkout__label" />
                <check-square :is-checked="selectTiptiCard" @on-check="(value) => (selectTiptiCard = value)" />
            </section>
            <section v-for="(giftCard, index) in gifts" :key="index" class="gift-card-alert-checkout__cards-list">
                <TheGifCardTile :gift-card="giftCard" />
            </section>

            <section class="general-alert-btns-checkout">
                <btn-out-line
                    class="general-alert-btns--one-btn-checkout"
                    :txt="$t('txt.tipti-card__accumulate-balance')"
                    bg-color="#FFF"
                    color="#FF9012"
                    @click="closeAlertGiftCard"
                />

                <btn-solid
                    class="general-alert-btns--one-btn-checkout"
                    :is-loading="isLoading"
                    :txt="$t('txt.tipti-card__use-balance')"
                    border-radius="7px"
                    @click="useBalanceCards"
                />
            </section>
        </div>
    </base-modal>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { useGiftCards } from '@/composables/useGiftCards';

import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import CheckSquare from '@/components/checks/CheckSquare.vue';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import IconTipti from '@/components/icons/IconTipti.vue';
import TheGifCardTile from '@/components/modalsGiftCards/giftCardsCheckout/TheGifCardTile.vue';
import { watch } from 'vue';
import { useCart } from '@/composables/useCart';

const emits = defineEmits<(e: 'handle-tipti-card-balance', value: boolean) => void>();

const $store = useStore();

const { fetchCart } = useCart();
const { textHeaderColor, isLoading, getting, activeModal, selectTiptiCard, gifts, useGiftCard } = useGiftCards();

const { tiptiCard } = useTiptiCard();
const useBalanceCards = async () => {
    if (isLoading.value) return;
    isLoading.value = true;
    await fetchCart($store.getters['checkout/retailerIdsWithtimeDelivery']);
    emits('handle-tipti-card-balance', selectTiptiCard.value);
    isLoading.value = false;
    activeModal.value = false;
};

watch(
    getting,
    (value, oldValue) => {
        if (!oldValue) return;
        if (value) return;
        if (activeModal.value) return;
        emits('handle-tipti-card-balance', selectTiptiCard.value);
    },
    {
        immediate: true,
    },
);

const closeAlertGiftCard = async () => {
    if (isLoading.value) return;
    isLoading.value = true;
    useGiftCard([]);
    selectTiptiCard.value = false;
    await fetchCart($store.getters['checkout/retailerIdsWithtimeDelivery']);
    emits('handle-tipti-card-balance', selectTiptiCard.value);
    isLoading.value = false;
    activeModal.value = false;
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index';

.gift-card-alert-checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $padding-md;

    &__img {
        display: block;
        width: 5rem;
        height: 3rem;
        border-radius: $radius-md;
        object-fit: fill;
    }

    &__tipti-card {
        display: flex;
        align-items: center;

        &__text {
            @include caption;
            color: $white;
            font-size: 12px;
        }
    }

    &__description {
        @include body2;
        margin-bottom: $margin-lg;
        max-width: 22rem;
        text-align: center;
        @include phone {
            font-size: 14px;
        }
    }

    &__cards-list {
        display: flex;
        align-items: center;
        gap: 1rem;
        min-width: 26rem;
        width: 100%;
        margin-bottom: $margin-lg;
    }

    &__card-description {
        @include body1;
        flex-grow: 1;

        &__top {
            @include body1;
        }

        &__bottom {
            @include body2;
        }
    }

    &__label {
        @include overline;
        color: $orange;
    }
}

.general-alert-btns-checkout {
    display: grid;
    gap: $margin-md;
    margin: $margin-sm 0;
    width: 90%;
    @include phone {
        grid-template-columns: 1fr;
    }
}

.general-alert-btns--one-btn-checkout {
    grid-template-columns: 1fr;
}
</style>
