import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import { MaxiBalanceInterface } from '@/models/maxiDollars/MaxiBalance';
import { MaxiProgramInstance } from '@/models/maxiDollars/MaxiProgram';

interface MaxiDollarsStateInterface {
    program: MaxiProgramInstance | undefined;
    maxiCardSelected: PaymentMethodModel | undefined;
    history: [];
    balance: MaxiBalanceInterface | undefined;
}

const state: MaxiDollarsStateInterface = {
    program: undefined,
    maxiCardSelected: undefined,
    history: [],
    balance: undefined,
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
