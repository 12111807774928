<template>
    <base-card-flat class="cambrella_selection" :border-color="'#a2a2a2'" padding="0.5rem">
        <icon-cambrella class="cambrella_selection__icon" :size="1.5" color="#FF9012" />
        <p class="cambrella_selection__name">{{ $t('txt.cambrellas__subscription') }}</p>
        <img class="cambrella_selection__img" :src="membership.icon" alt="" @error="displayDefaultImg" />
    </base-card-flat>
</template>

<script lang="ts" setup>
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import { MembershipType } from '@/modules/cambrella/models/MembershipType';
import { useImage } from '@/composables/ImageComposable';
import IconCambrella from '@/components/icons/IconCambrella.vue';
import { PropType } from 'vue';

defineProps({
    membership: {
        type: Object as PropType<MembershipType>,
        required: true,
    },
});

const { displayDefaultImg } = useImage();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.cambrella_selection {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;

    &__icon {
        align-self: center;
        margin-left: $margin-md;
    }

    &__name {
        @include body2;
        font-size: 18px;
        color: $txt;
        margin: auto;
        width: 100%;
        padding-left: $padding-lg;
    }

    &__img {
        width: auto;
        height: 2rem;
        border: 1px solid $border;
        margin-right: $margin-xsm;
        padding: $padding-sm;
    }
}
</style>
