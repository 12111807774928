<template>
    <TheRetailerScheduleCard
        v-for="retailer in retailersAvailability"
        :key="retailer.retailer_id"
        :is-single="retailersAvailability.length === 1"
        :retailer="retailer"
    />
</template>

<script lang="ts" setup>
import TheRetailerScheduleCard from '@/views/checkout/components/menu/components/scheduleCard/TheRetailerScheduleCard.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

const { retailersAvailability } = useRetailer();
</script>

<style lang="scss" scoped>
@import '../../menu-checkout.scss';
@import './schedule-card.scss';
</style>
