import { CategoryModel, toCategoryModel } from './CategoryModel';
import { RetailerInProductModel, toRetailerInProductModel } from './RetailerInProductModel';
import { NutritionalColorModel, toNutritionalColorModel } from './NutritionalColorModel';
import { ProductImageModel, toProductImageModel } from './ProductImageModel';
import { ReplacementModel, toReplacementModel } from './ReplacementModel';
import { createUUID } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { ProductMediaModel } from '@/models/product/ProductMedia';
import { AttributeInProductModel } from '@/models/product/AttributeInProduct';
import { getItemBlock } from '@/utils/ProductBuilderUtil';
import { RemoteLocaleDto, RemoteLocaleModel } from '../internationalization/RemoteLocale';
import { DiscountCardDto } from '@/models/product/DiscountCardDto';

/// TODO: CHECK sections with undefined and verify if they need to be included;

/// *** SUB-STRUCTURES EXAMPLE 'TILL MARCH 2021
//
// if (data['item']) {
//      if(data['id']) return data; //* Used for lists
//     return data;
// } else if(data['original_unitary_price_with_tax']){
//  //* Tipti items
// }else if (data['stockitem']) {
//     if (!data['product']) {
//         if (data['price_with_discount']) {
//             return data;
//         } else if (!data['final_sale_unitary_price_with_tax']) {
//             return data;
//         } else {
//             return data;
//         }
//     } else {
//         return data;
//     }
// } else {
//     return data;
// }
//
/// ** data['item'] can be seen with v2/product/recommendations/category_v2/?retailer_id=2
/// ** data['final_sale_unitary_price_with_tax'] can be seen with v2/client-profile/order/318634/detail

export interface ProductAnalyticInterface {
    product_id: number;
    ean: string;
    category: string;
    category_id: number;
    name: string;
    price: number;
    price_with_discount: number;
    presentation_quantity: string | number;
    product_quantity: number;
    subcategory: string;
    image_url: string;
    indicators: RemoteLocaleDto[];
}

const getPriceWithDiscount = (price: number, rate: number): number => {
    if (price >= 0) return price - price * rate;
    logger('PRICE NOT SHOULD BE BELOW ZERO');
    return 0;
};

const buildId = (data: object): number => {
    if (data['item']) return data['item']['id'];
    if (data['original_unitary_price_with_tax']) return data['id'];
    if (data['stockitem']) {
        if (!data['product']) {
            if (!data['final_sale_unitary_price_with_tax']) {
                return data['stockitem']['id'];
            }
            return data['id'];
        }
        return data['stockitem']['id'];
    }
    return data['id'];
};

const buildProductId = (data: object): number => {
    if (data['item'] && data['item']['product']) {
        return data['item']['product']['id'];
    } else if (data['original_unitary_price_with_tax']) {
        return data['id'];
    } else if (data['stockitem']) {
        if (!data['product']) {
            if (!data['final_sale_unitary_price_with_tax'] && data['stockitem']['product']) {
                return data['stockitem']['product']['id'];
            }
            return data['id'];
        }
        if (data['stockitem'] && data['stockitem']['product']) {
            return data['stockitem']['product']['id'];
        }
        return null;
    }
    if (data['product']) {
        return data['product']['id'];
    }
    return null;
};

const buildProductQuantity = (data: object): number => {
    if (data['item']) {
        return data['quantity'] ?? 0;
    } else if (data['original_unitary_price_with_tax']) {
        return data['quantity'];
    } else if (data['initial_quantity']) {
        if (!data['initial_item']) return data['quantity'];
        return data['initial_quantity'];
    } else if (data['stockitem']) {
        return data['quantity'];
    }
    return 0;
};

const buildName = (data: object): string => {
    if (data['item']) {
        return data['item']['product']['name'];
    } else if (data['original_unitary_price_with_tax']) {
        return data['name'];
    } else if (data['stockitem']) {
        return data['stockitem']['product']['name'];
    }
    return data['product']['name'];
};
const buildEan = (data: object): string => {
    if (data['item']) {
        return data['item']['product']['ean'];
    } else if (data['original_unitary_price_with_tax']) {
        return data['ean'];
    } else if (data['stockitem']) {
        return data['stockitem']['product']['ean'];
    }
    return data['product']['ean'];
};
const buildIsSovi = (data: object): boolean => {
    /// ** data['is_sovi'] ?? false logic for search results
    /// altough this feature is not active 'till approval.
    return false;
};

const buildImage = (data: object): object => {
    if (data['item']) {
        return data['item']['product']['cover'] && data['item']['product']['cover'].length
            ? data['item']['product']['cover'][0]
            : null;
    } else if (data['original_unitary_price_with_tax']) {
        return data['photo']
            ? {
                  original: data['photo'],
                  medium: data['photo'],
                  small: data['photo'],
              }
            : null;
    } else if (data['stockitem']) {
        return data['stockitem']['product']['cover'] && data['stockitem']['product']['cover'].length
            ? data['stockitem']['product']['cover'][0]
            : null;
    }
    return data['product']['cover'] && data['product']['cover'].length ? data['product']['cover'][0] : null;
};

const buildUnit = (data: object): string => {
    if (data['item']) {
        return data['item']['product']['unit'];
    } else if (data['original_unitary_price_with_tax']) {
        return data['presentation_unit'];
    } else if (data['stockitem']) {
        if (
            data['final_sale_unitary_price_with_tax'] &&
            data['change_unit'] &&
            !data['initial_item'] &&
            data['new_unit'] &&
            !['ITEM_REPLACED', 'ITEM_NOT_FOUND'].includes(data['state'])
        )
            return data['new_unit'];
        return data['stockitem']['product']['unit'];
    }
    return data['product']['unit'];
};

const buildNote = (data: object): string => {
    if (data['item']) {
        return data['note'];
    } else if (data['original_unitary_price_with_tax']) {
        return data['note'];
    } else if (data['stockitem']) {
        if (!data['product']) {
            if (!data['final_sale_unitary_price_with_tax']) {
                return data['note'];
            }
            return data['note'];
        }
        return undefined;
    }
    return data['note'];
};

const buildNoteToShopperPhoto = (data: object): string => {
    return data['note_picture'];
};

const buildDescriptionProduct = (data: object): string => {
    if (data['item']) {
        return data['item']['product']['description']?.trim()?.length ? data['item']['product']['description'] : null;
    } else if (data['original_unitary_price_with_tax']) {
        return data['name'];
    } else if (data['stockitem']) {
        if (!data['product']) {
            if (!data['final_sale_unitary_price_with_tax']) {
                return data['stockitem']['product']['description']?.trim()?.length
                    ? data['stockitem']['product']['description']
                    : null;
            }
            return undefined;
        }
        return data['stockitem']['product']['description']?.trim()?.length
            ? data['stockitem']['product']['description']
            : null;
    }
    return data['product']['description']?.trim()?.length ? data['product']['description'] : null;
};

const buildDescriptionImage = (data: object): string => {
    if (data['item']) {
        return data['item']['description']?.trim()?.length ? data['item']['description'] : null;
    } else if (data['stockitem']) {
        if (!data['product']) {
            if (!data['final_sale_unitary_price_with_tax']) {
                return data['stockitem']['description']?.trim()?.length ? data['stockitem']['description'] : null;
            }
            return undefined;
        }
        return data['stockitem']['description']?.trim()?.length ? data['stockitem']['description'] : null;
    }
    return data['description']?.trim()?.length ? data['description'] : null;
};

const buildUnitQuanity = (data: object): number => {
    if (data['item']) {
        return data['item']['product']['quantity'] ? +data['item']['product']['quantity'] : null;
    } else if (data['original_unitary_price_with_tax']) {
        return data['presentation_quantity'];
    } else if (data['stockitem']) {
        if (
            data['final_sale_unitary_price_with_tax'] &&
            !data['initial_item'] &&
            data['change_unit_quantity'] &&
            !['ITEM_REPLACED', 'ITEM_NOT_FOUND'].includes(data['state'])
        )
            return +data['new_unit_quantity'];
        return data['stockitem']['product']['quantity'] ? +data['stockitem']['product']['quantity'] : null;
    }
    return data['product']['quantity'] ? +data['product']['quantity'] : null;
};

const buildPrice = (data: object): number => {
    if (data['item']) {
        return data['item']['price'] ? +data['item']['price'] : null;
    } else if (data['original_unitary_price_with_tax']) {
        return +data['original_unitary_price_with_tax'];
    } else if (data['stockitem']) {
        if (!data['product']) {
            if (!data['final_sale_unitary_price_with_tax']) {
                return data['stockitem']['price'] ? +data['stockitem']['price'] : null;
            }
            if (
                data['final_sale_unitary_price_with_tax'] &&
                data['initial_item'] &&
                (data['change_unit_quantity'] || data['change_unit'])
            )
                return data['initial_unitary_stock_item_sale_price']
                    ? +data['initial_unitary_stock_item_sale_price']
                    : null;
            if (
                ['ITEM_REPLACED', 'ITEM_NOT_FOUND'].includes(data['state']) &&
                data['initial_unitary_stock_item_sale_price']
            )
                return data['initial_unitary_stock_item_sale_price']
                    ? +data['initial_unitary_stock_item_sale_price']
                    : null;
            return data['unitary_stock_item_sale_price'] ? +data['unitary_stock_item_sale_price'] : null;
        }

        return data['stockitem']['price'] ? +data['stockitem']['price'] : null;
    }
    return data['price'] ? +data['price'] : null;
};

const buildPriceWithDiscount = (data: object): number => {
    ///* Verify undefined data with ternary operator
    // because undefined with unary operator returns NaN
    if (data['item']) {
        return getPriceWithDiscount(
            data['item']['price'] ? +data['item']['price'] : 0,
            data['rate'] ? +data['rate'] : 0,
        );
    } else if (data['original_unitary_price_with_tax']) {
        return +data['price'];
    } else if (data['stockitem'] && !data['price_with_discount']) {
        if (!data['product']) {
            if (!data['final_sale_unitary_price_with_tax']) {
                return getPriceWithDiscount(
                    data['stockitem']['price'] ? +data['stockitem']['price'] : 0,
                    data['discount'] ? +data['discount'] : 0,
                );
            }
            if (
                data['final_sale_unitary_price_with_tax'] &&
                data['initial_item'] &&
                (data['change_unit_quantity'] || data['change_unit'])
            )
                return data['initial_sale_unitary_price_with_tax']
                    ? +data['initial_sale_unitary_price_with_tax']
                    : null;
            if (
                ['ITEM_REPLACED', 'ITEM_NOT_FOUND'].includes(data['state']) &&
                data['initial_unitary_stock_item_sale_price']
            )
                return data['initial_sale_unitary_price_with_tax']
                    ? +data['initial_sale_unitary_price_with_tax']
                    : null;
            return data['final_sale_unitary_price_with_tax'];
        }
        return getPriceWithDiscount(
            data['stockitem']['price'] ? +data['stockitem']['price'] : 0,
            data['discount'] ? +data['discount'] : 0,
        );
    }
    return data['price_with_discount']
        ? +data['price_with_discount']
        : getPriceWithDiscount(data['price'] ? +data['price'] : 0, data['discount'] ? +data['discount'] : 0);
};

const buildCategory = (data: object): CategoryModel => {
    if (data['item']) {
        return toCategoryModel(
            data['item']['product']['category']?.length ? data['item']['product']['category'][0] : null,
        );
    } else if (data['original_unitary_price_with_tax']) {
        return undefined;
    } else if (data['stockitem']) {
        return toCategoryModel(
            data['stockitem']['product']['category']?.length ? data['stockitem']['product']['category'][0] : null,
        );
    }
    return toCategoryModel(data['product']['category']?.length ? data['product']['category'][0] : null);
};

const buildSubCategory = (data: object): CategoryModel => {
    if (data['item']) {
        return toCategoryModel(
            data['item']['product']['subcategory']?.length ? data['item']['product']['subcategory'][0] : null,
        );
    } else if (data['original_unitary_price_with_tax']) {
        return undefined;
    } else if (data['stockitem']) {
        return toCategoryModel(
            data['stockitem']['product']['subcategory']?.length ? data['stockitem']['product']['subcategory'][0] : null,
        );
    }
    return toCategoryModel(data['product']['subcategory']?.length ? data['product']['subcategory'][0] : null);
};

const buildBulk = (data: object): boolean => {
    if (data['item']) {
        return data['item']['product']['bulk'] ?? false;
    } else if (data['original_unitary_price_with_tax']) {
        return undefined;
    } else if (data['stockitem']) {
        return data['stockitem']['product']['bulk'] ?? false;
    }
    return data['product']['bulk'] ?? false;
};

const buildUnitStock = (data: object): boolean => {
    if (data['item']) {
        return data['item']['product']['unit_stock'] ?? false;
    } else if (data['original_unitary_price_with_tax']) {
        return undefined;
    } else if (data['stockitem']) {
        return data['stockitem']['product']['unit_stock'] ?? false;
    }
    return data['product']['unit_stock'] ?? false;
};

const buildHasDiscount = (data: object): boolean => {
    if (data['item']) {
        return data['rate'] ? +data['rate'] > 0 : false;
    } else if (data['original_unitary_price_with_tax']) {
        return +data['discount'] > 0;
    } else if (data['stockitem'] && !data['final_price_without_discount']) {
        return data['discount'] ? +data['discount'] > 0 : false;
    }
    return data['final_price_without_discount']
        ? (data['final_price_without_discount'] ? +data['final_price_without_discount'] : 0) >
              (data['final_price_with_discount'] ? +data['final_price_with_discount'] : 0)
        : data['discount_applied']
          ? (data['price'] ? +data['price'] : 0) !== (data['price_with_discount'] ? +data['price_with_discount'] : 0)
          : data['discount']
            ? +data['discount'] > 0
            : false;
};

const buildDiscount = (data: object): number => {
    if (data['item']) {
        return data['rate'] ? +data['rate'] : 0;
    } else if (data['original_unitary_price_with_tax']) {
        return data['discount'] ? +data['discount'] / 100 : 0;
    }
    return data['discount'] ? +data['discount'] : 0;
};

const buildRetailer = (data: object): RetailerInProductModel => {
    if (data['item']) {
        return toRetailerInProductModel(data['item']['retailer']);
    } else if (data['original_unitary_price_with_tax']) {
        return undefined;
    } else if (data['stockitem']) {
        return toRetailerInProductModel(data['stockitem']['retailer']);
    }
    return toRetailerInProductModel(data['retailer']);
};

const buildNutritionColors = (data: object): NutritionalColorModel => {
    if (data['item']) {
        return toNutritionalColorModel(
            data['item']['product']['nutrition_colors']?.length &&
                typeof data['item']['product']['nutrition_colors'][0] === 'object'
                ? data['item']['product']['nutrition_colors'][0]
                : null,
        );
    } else if (data['original_unitary_price_with_tax']) {
        return undefined;
    } else if (data['stockitem']) {
        if (!data['product']) {
            return undefined;
        }
        return toNutritionalColorModel(
            data['stockitem']['product']['nutrition_colors']?.length &&
                typeof data['stockitem']['product']['nutrition_colors'][0] === 'object'
                ? data['stockitem']['product']['nutrition_colors'][0]
                : null,
        );
    }
    return data['product']['nutrition_colors']?.length && typeof data['product']['nutrition_colors'][0] === 'object'
        ? data['product']['nutrition_colors'][0]
        : null;
};

const buildAvailable = (data: object): boolean => {
    if (data['item']) {
        return data['item']['available'] ?? false;
    } else if (data['original_unitary_price_with_tax']) {
        return true;
    } else if (data['stockitem']) {
        if (!data['product']) {
            return data['stockitem']['available'] ?? false;
        }
        return data['stockitem']['available'] ?? false;
    }
    return data['available'] ?? false;
};

const buildHighDemand = (data: object): boolean => {
    if (data['item']) {
        return data['item']['high_demand'] ?? false;
    } else if (data['original_unitary_price_with_tax']) {
        return false;
    } else if (data['stockitem']) {
        if (!data['product']) {
            return data['stockitem']['high_demand'] ?? false;
        }
        return data['stockitem']['high_demand'] ?? false;
    }
    return data['high_demand'] ?? false;
};

const buildIsSampling = (data: object): boolean => {
    if (data['item']) {
        return data['item']['is_sampling'] ?? false;
    } else if (data['original_unitary_price_with_tax']) {
        return false;
    } else if (data['stockitem']) {
        if (!data['product']) {
            return data['stockitem']['product']['is_sampling'] ?? false;
        }
        return data['stockitem']['is_sampling'] ?? false;
    }
    return data['is_sampling'] ?? false;
};

const buildReplacement = (data: object): ReplacementModel => {
    return !data['replace'] || Array.isArray(data['replace'])
        ? undefined
        : toReplacementModel({
              id: data['replace']['stockitem']['id'],
              name: data['replace']['stockitem']['product']['name'],
              quantity: data['replace']['quantity'] ?? 1,
              unit_quantity: +data['replace']['stockitem']['product']['quantity'],
              unit: data['replace']['stockitem']['product']['unit'],
              image: data['replace']['stockitem']['product']['cover'][0],
          });
};

const buildAffiliatedPrice = (data: object): boolean => {
    if (data['item']) {
        return data['item']['margin'] ? +data['item']['margin'] === 0 : false;
    } else if (data['original_unitary_price_with_tax']) {
        return data['margin'] ? +data['margin'] === 0 : false;
    } else if (data['stockitem']) {
        return data['stockitem']['margin'] ? +data['stockitem']['margin'] === 0 : false;
    }
    return data['margin'] ? +data['margin'] === 0 : false;
};

const buildDeepLink = (data: object): string => {
    if (data['item']) {
        return data['item']['product']['deep_link'];
    } else if (data['original_unitary_price_with_tax']) {
        return data['deep_link'];
    } else if (data['stockitem']) {
        return data['stockitem']['product']['deep_link'];
    } else if (data['replace']) {
        return data['replace']['stockitem']['product']['deep_link'];
    }
    return data['product']['deep_link'] ?? '';
};

const getGroupId = (data: object): number => {
    if (data['item']) {
        return data['item']['product']['group_id'];
    } else if (data['original_unitary_price_with_tax']) {
        return data['group_id'];
    } else if (data['stockitem']) {
        return data['stockitem']['product']['group_id'];
    } else if (data['replace']) {
        return data['replace']['stockitem']['product']['group_id'];
    }
    return data['product']['group_id'] ?? '';
};

const getIndicators = (data: object): RemoteLocaleDto[] => {
    if (data['product']) {
        return (
            data['product']['identifiers']?.map((identifier: RemoteLocaleDto) =>
                RemoteLocaleModel.fromJson(identifier),
            ) ?? []
        );
    }
    if (data['item']) {
        return (
            data['item']['product']['identifiers']?.map((identifier: RemoteLocaleDto) =>
                RemoteLocaleModel.fromJson(identifier),
            ) ?? []
        );
    }
    return [];
};

class ProductModel {
    /// ** [finalPriceWithDiscount] [finalPrice]
    /// Options related only for cart endpoint v2/client/shopping-cart
    /// TODO: maybe connect with endpoint in orders;

    readonly id: number;

    readonly productId: number;

    uuid: string; /// ** used to blur selection on product's card
    productQuantity: number; /// ** mostly local information editable;
    readonly name: string;
    readonly ean: string;
    readonly isSovi: boolean;
    readonly image: ProductImageModel;
    readonly unit: string;
    note: string;
    noteToShopperPhotoFile: File;
    noteToShopperPhoto: string;
    readonly descriptionProduct: string;
    readonly descriptionImage: string;
    readonly unitQuantity: number;
    readonly price: number;
    readonly priceWithDiscount: number;
    readonly category: CategoryModel;
    readonly subCategory: CategoryModel;
    readonly bulk: boolean;
    readonly unitStock: boolean;
    readonly hasDiscount: boolean;
    readonly discount: number;
    retailer: RetailerInProductModel;
    readonly nutritionColors: NutritionalColorModel;
    available: boolean;
    readonly highDemand: boolean;
    readonly isSampling: boolean;
    readonly shoppingBag: boolean;
    readonly mandatoryItem: boolean;
    finalPriceWithDiscount: number;
    finalPrice: number;
    replacement: ReplacementModel; /// ** mostly local information editable;
    affiliatedPrice: boolean;
    readonly productIdOnList: number; ///** only use for edit product in to list flow
    readonly deepLink: string; //* used for share deeplink of product
    trackItemSection: string; //** used locally for tracking data from product's section */
    trackResourceType: string; //** used locally for tracking data from product's section */
    trackId: string; //** used locally for tracking data from product's section */
    readonly temporalQuantity: number; ///*** Use to set items quantity in not available products in retailer when change sector
    readonly media: Array<ProductMediaModel>;
    groupId: number;
    attributes: Array<AttributeInProductModel>;
    readonly hoursToDelivery: number;
    readonly uniqueDelivery: boolean;
    readonly costOfDelivery: number;
    readonly conversionGramsAndKilogramsToPounds: string;
    readonly carry: number;
    readonly pay: number;
    readonly messageCarryPay: RemoteLocaleModel;
    readonly discountFiveByFive: boolean;
    deactivateProductIdReference: ProductModel;
    stockItemId: number;

    discountCard: DiscountCardDto;
    indicators: RemoteLocaleDto[];
    gm_recommendation: boolean;
    readonly needToFetchDetail: boolean;

    constructor(
        discountCard: DiscountCardDto,
        id: number,
        discountFiveByFive: boolean,
        productId: number,
        uuid: string,
        productQuantity: number,
        name: string,
        ean: string,
        isSovi: boolean,
        image: ProductImageModel,
        unit: string,
        note: string,
        noteToShopperPhotoFile: File,
        noteToShopperPhoto: string,
        descriptionProduct: string,
        descriptionImage: string,
        unitQuantity: number,
        price: number,
        priceWithDiscount: number,
        category: CategoryModel,
        subCategory: CategoryModel,
        bulk: boolean,
        unitStock: boolean,
        hasDiscount: boolean,
        discount: number,
        retailer: RetailerInProductModel,
        nutritionColors: NutritionalColorModel,
        available: boolean,
        highDemand: boolean,
        isSampling: boolean,
        deepLink: string,
        groupId: number,
        attributes: Array<AttributeInProductModel>,
        hoursToDelivery: number,
        uniqueDelivery: boolean,
        costOfDelivery: number,
        indicators: RemoteLocaleDto[],
        media?: Array<ProductMediaModel>,
        shoppingBag?: boolean,
        mandatoryItem?: boolean,
        finalPriceWithDiscount?: number,
        finalPrice?: number,
        replacement?: ReplacementModel,
        affiliatedPrice?: boolean,
        productIdOnList?: number,
        temporalQuantity?: number,
        carry?: number,
        pay?: number,
        messageCarryPay?: RemoteLocaleModel,
        gm_recommendation?: boolean,
        trackItemSection?: string,
        trackResourceType?: string,
        trackId?: string,
        needToFetchDetail?: boolean,
    ) {
        this.id = id;
        this.stockItemId = id;
        this.productId = productId;
        this.uuid = uuid;
        this.productQuantity = productQuantity;
        this.name = name;
        this.ean = ean;
        this.isSovi = isSovi;
        this.image = image;
        this.unit = unit;
        this.note = note;
        this.noteToShopperPhotoFile = noteToShopperPhotoFile;
        this.noteToShopperPhoto = noteToShopperPhoto;
        this.descriptionProduct = descriptionProduct;
        this.descriptionImage = descriptionImage;
        this.unitQuantity = unitQuantity;
        this.price = price;
        this.priceWithDiscount = priceWithDiscount;
        this.category = category;
        this.subCategory = subCategory;
        this.bulk = bulk;
        this.unitStock = unitStock;
        this.hasDiscount = hasDiscount;
        this.discount = discount;
        this.retailer = retailer;
        this.nutritionColors = nutritionColors;
        this.available = available;
        this.highDemand = highDemand;
        this.isSampling = isSampling;
        this.deepLink = deepLink;
        this.shoppingBag = shoppingBag;
        this.mandatoryItem = mandatoryItem;
        this.finalPriceWithDiscount = finalPriceWithDiscount;
        this.finalPrice = finalPrice;
        this.replacement = replacement;
        this.affiliatedPrice = affiliatedPrice;
        this.productIdOnList = productIdOnList;
        this.temporalQuantity = temporalQuantity;
        this.trackItemSection = trackItemSection;
        this.trackResourceType = trackResourceType;
        this.trackId = trackId;
        this.media = media;
        this.attributes = attributes;
        this.groupId = groupId;
        this.hoursToDelivery = hoursToDelivery;
        this.uniqueDelivery = uniqueDelivery;
        this.costOfDelivery = costOfDelivery;
        this.conversionGramsAndKilogramsToPounds = this._conversionGramsAndKilogramsToPounds();
        this.carry = carry;
        this.pay = pay;
        this.messageCarryPay = messageCarryPay;
        this.discountFiveByFive = discountFiveByFive ?? false;
        this.discountCard = discountCard;
        this.indicators = indicators;
        this.gm_recommendation = gm_recommendation;
        this.needToFetchDetail = needToFetchDetail;
    }

    private _gramsToPounds(): string {
        const productUnit = 'LB';
        const pound = 453.6;
        const productPounds = this.unitQuantity >= 0 ? (this.unitQuantity / pound).toFixed(2) : 0.0;
        const slashSymbol = '/ ';
        return slashSymbol + productPounds + ' ' + productUnit;
    }

    private _kilogramsToPounds(): string {
        const productUnit = 'LB';
        const pound = 2.205;
        const productPounds = this.unitQuantity >= 0 ? (this.unitQuantity * pound).toFixed(2) : 0.0;
        const slashSymbol = '/ ';
        return slashSymbol + productPounds + ' ' + productUnit;
    }

    private _conversionGramsAndKilogramsToPounds(): string {
        if (!this.bulk) return '';
        if (this.unit == 'G') return this._gramsToPounds();
        if (this.unit == 'KG') return this._kilogramsToPounds();
        return '';
    }
}

const toProductModel = (data: object): ProductModel => {
    return data
        ? new ProductModel(
              getItemBlock({
                  data: data,
                  block: 'discount_card',
              }),
              buildId(data),
              getItemBlock({
                  data: data,
                  block: 'discount_five_by_five',
              }),
              buildProductId(data),
              createUUID(buildId(data)),
              buildProductQuantity(data),
              buildName(data),
              buildEan(data),
              buildIsSovi(data),
              toProductImageModel(buildImage(data)),
              buildUnit(data),
              buildNote(data),
              undefined,
              buildNoteToShopperPhoto(data),
              buildDescriptionProduct(data),
              buildDescriptionImage(data),
              buildUnitQuanity(data),
              buildPrice(data),
              buildPriceWithDiscount(data),
              buildCategory(data),
              buildSubCategory(data),
              buildBulk(data),
              buildUnitStock(data),
              buildHasDiscount(data),
              buildDiscount(data),
              buildRetailer(data),
              buildNutritionColors(data),
              buildAvailable(data),
              buildHighDemand(data),
              buildIsSampling(data),
              buildDeepLink(data),
              getGroupId(data),
              AttributeInProductModel.builderList(data),
              +getItemBlock({
                  data: data,
                  block: 'hours_to_delivery',
              }),
              getItemBlock({
                  data: data,
                  block: 'unique_delivery',
              }),
              +(+getItemBlock({
                  data: data,
                  block: 'cost_of_delivery',
              })).toFixed(2),
              getIndicators(data),
              ProductMediaModel.builderList(data),
              data['shopping_bag'] ?? false,
              data['mandatory_item'] ?? false,
              data['final_price_with_discount'],
              data['final_price_without_discount'],
              buildReplacement(data),
              buildAffiliatedPrice(data),
              data['item'] && data['id'] ? data['id'] : undefined,
              data['temporal_quantity'],
              getItemBlock({
                  data: data,
                  block: 'carry',
              }),
              getItemBlock({
                  data: data,
                  block: 'pay',
              }),
              RemoteLocaleModel.fromJson(data['message_multiplier_discount']),
              data['gm_recommendation'] ?? (data['item'] && data['item']['gm_recommendation']) ?? false,
          )
        : null;
};

export { ProductModel, toProductModel };
