<template>
    <base-card class="flex-column" padding="0">
        <section-header :hasBack="true" :title="recipeGroup" @on-back="backList"></section-header>
        <container-scroller
            v-if="recipeAds"
            class="recipes"
            :isScroller="isScroller"
            @on-observer="onObserver"
            @on-observer-change="onObserverChange"
        >
            <template v-slot:content>
                <scroller-item
                    v-for="subgroup in recipeAds"
                    :key="subgroup.id"
                    :id="subgroup.id"
                    :name="subgroup.name"
                    :observer="refObserver"
                >
                    <template v-slot:content>
                        <base-card
                            class="flex-column cursor-pointer"
                            padding="0"
                            @click="isLoading ? null : getRecipeDetail(subgroup)"
                        >
                            <img class="recipe__img" :src="subgroup.picture" alt="" loading="lazy" />
                            <aside class="recipe-label">
                                <h3>{{ subgroup.name }}</h3>
                                <div class="recipe-items">
                                    <icon-cart :size="0.6" color="#707070"></icon-cart>
                                    <p>{{ subgroup.productsNumber }} {{ $t('general.products-general') }}</p>
                                </div>
                                <base-loading
                                    v-if="isLoading && getSubgrubId === subgroup.id"
                                    :isMarginAuto="false"
                                    heigth=".5rem"
                                    width=".5rem"
                                >
                                </base-loading>
                                <icon-arrow v-else :iconName="$t('icon.forward')" :size="0.75" />
                            </aside>
                        </base-card>
                    </template>
                </scroller-item>
            </template>
        </container-scroller>
    </base-card>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import { isMobileBrowser } from '@/utils/utils';
import { logger } from '@/utils/logger';

import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import IconCart from '@/components/icons/IconCart.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import ContainerScroller from '@/components/container/ContainerScroller.vue';
import ScrollerItem from '@/components/container/ScrollerItem.vue';
import { Sections } from '@/enums/sectionsOrigin';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'recipe-sub-group',
    data() {
        return {
            isLoading: false,
            getSubgrubId: 0,
            refObserver: null,
            isScroller: false,
        };
    },
    components: {
        SectionHeader,
        BaseCard,
        IconArrow,
        IconCart,
        BaseLoading,
        ContainerScroller,
        ScrollerItem,
    },
    computed: {
        recipeGroup(): string {
            return this.$route.query.group;
        },
        ...mapGetters({
            recipeAds: 'lists/recipeAds',
        }),
        isMobile() {
            return isMobileBrowser();
        },
    },
    methods: {
        onObserver(refObserver) {
            this.refObserver = refObserver;
        },
        async onObserverChange(entries) {
            this.isScroller = true;
            await this.$store.dispatch('segment/SubGroupsRecipeImpression', {
                origin: {
                    section: this.$route.query?.section ?? Sections.MY_LISTS,
                    screen: this.$route.path,
                },
                subgroups_recipe: Array.from(entries).map((row) => {
                    return {
                        subgroup_id: row['id'],
                        subgroup: row['name'],
                    };
                }),
            });
            this.isScroller = false;
        },
        async getRecipeDetail(recipe: object): Promise<void> {
            try {
                this.isLoading = true;
                this.getSubgrubId = recipe['id'];
                const response = await this.$store.dispatch('lists/getRecipeDetail', recipe['recipeId']);
                this.$store.commit('lists/recipeDetail', response);
                this.$router.push({
                    name: appRoutesMap.list.recipeDetail,
                    query: { group: this.recipeGroup },
                });
            } catch (err) {
                logger('GET_RECIPE_DETAIL', err);
            } finally {
                this.isLoading = false;
            }
        },
        backList(): void {
            this.$router.go(-1);
        },
    },
};
</script>
<style lang="scss" scoped>
@import './recipes-sub-group.scss';
</style>
