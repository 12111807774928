import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14a6d46e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banks" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.banks, (bank) => {
      return (_openBlock(), _createBlock(_component_base_card, {
        padding: "0",
        bgColor: $options.backgroundColor(bank),
        class: _normalizeClass(["card-bank cursor-pointer", { 'card-bank--disable': !bank?.enabled }]),
        key: bank,
        onClick: ($event: any) => ($options.onSelect(bank))
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: bank.icon,
            alt: ""
          }, null, 8, _hoisted_2)
        ]),
        _: 2
      }, 1032, ["bgColor", "class", "onClick"]))
    }), 128))
  ]))
}