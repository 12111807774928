<template>
    <base-card class="subscription-cambrellas" :padding="0">
        <section-header
            :has-back="isMobile"
            :title="$t('txt.cambrellas_membership')"
            color-title="#000"
            @on-back="$emit('return')"
        />
        <div class="subscription-detail">
            <h2 class="subscription-detail__header">
                {{ $t('txt.cambrellas__subscription_experience') }}
            </h2>
            <p class="subscription-detail__legend">{{ $t('txt.cambrellas__subscription_change') }}</p>
            <p class="subscription-detail__legend">
                {{ $t('txt.cambrellas__subscription_legend') }}
            </p>
            <section class="subscription-detail__content">
                <div>
                    <p class="benefit-cambrella__header">{{ $t('general.benefits') }}</p>
                    <div v-for="benefit in membership.benefits" :key="benefit" class="benefit-cambrella__item">
                        <icon-check-success-rounded class="benefit-cambrella__icon" :size="1" use-custom-fill-color />
                        <p class="benefit-cambrella__item-text">
                            {{ benefit }}
                        </p>
                    </div>
                    <p v-if="hasCambrellaSubscription" class="benefit-cambrella__date">
                        {{ $t('txt.cambrellas__expire') }}:
                        <span
                            v-date="{
                                date: cambrellaMembership.date_end_membership,
                                format: 'D MMM YYYY',
                            }"
                            class="benefit-cambrella__date--highlight"
                        />
                    </p>
                </div>
                <div>
                    <p v-if="hasCambrellaSubscription" class="benefit-cambrella__header">
                        {{ $t('txt.cambrellas__txt_subscription') }}
                    </p>
                    <base-card
                        v-if="hasCambrellaSubscription"
                        class="benefit-cambrella__progress"
                        :class="{ 'benefit-cambrella__progress--completed': hasCambrellasRemaining }"
                        :bg-color="backGroundColor"
                        padding="0.5rem"
                    >
                        <p class="benefit-cambrella__item-text benefit-cambrella__item-text--white">
                            {{ $t('txt.cambrellas__used') }}
                        </p>
                        <div class="benefit-cambrella__bags">
                            <div class="benefit-cambrella__progress-data">
                                <icon-one-bag />
                                <span
                                    class="benefit-cambrella__progress-number"
                                    :class="{
                                        'benefit-cambrella__progress-number--completed': hasCambrellasRemaining,
                                    }"
                                    >{{ cambrellaMembership.cambrellas_in_use }}</span
                                >
                            </div>
                            <div class="benefit-cambrella__progress-divider">/</div>
                            <div class="benefit-cambrella__progress-data">
                                <icon-one-bag />
                                <span
                                    class="benefit-cambrella__progress-number"
                                    :class="{
                                        'benefit-cambrella__progress-number--completed': hasCambrellasRemaining,
                                    }"
                                    >{{ cambrellaMembership.number_of_cambrellas }}</span
                                >
                            </div>
                        </div>
                    </base-card>
                    <cambrella-return :is-showed-in-modal="false" :membership="membership" />
                </div>
            </section>
        </div>

        <div class="subscription-footer">
            <p class="subscription-footer__text">
                {{ $t('general.terms_and_conditions__message') }}
                <span class="subscription-footer__text--highlight" @click="showTermsAndConditions = true">
                    {{ $t("general['terms-and-conditions']").toUpperCase() }}
                </span>
            </p>
            <btn-contact-advisor v-if="hasCambrellaSubscription" />
            <btn-solid v-else :txt="btnText" @click="showCambrellasModal = true" />
        </div>
    </base-card>
    <cambrella-get-subscription
        v-if="showCambrellasModal"
        :membership="membership"
        :use-snack-bar-on-success="false"
        @on-close="showCambrellasModal = false"
        @on-success="onSubscriptionSuccess"
    />
    <cambrella-with-subscription
        v-if="showCambrelaWithSubscription"
        :membership="cambrellaMembership"
        :show-header="false"
        @on-close="showCambrelaWithSubscription = false"
    />
    <alert-pdf
        v-if="showTermsAndConditions"
        :title="$t('general.terms-and-conditions')"
        :url="translate({ recordable: membership.link_terms_and_conditions_text })"
        @close="showTermsAndConditions = false"
    />
</template>
<script lang="ts">
import BaseCard from '@/components/cards/BaseCard.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import IconCheckSuccessRounded from '@/components/icons/IconCheckSuccessRounded.vue';
import IconOneBag from '@/components/icons/IconOneBag.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import CambrellaGetSubscription from '@/modules/cambrella/components/CambrellaGetSubcription.vue';
import { MembershipType } from '@/modules/cambrella/models/MembershipType';
import { isMobileBrowser } from '@/utils/utils';
import { useCambrellas } from '@/modules/cambrella/composables/useCambrellas';
import { useCurrency } from '@/composables/useCurrency';
import { useTranslate } from '@/composables/UseTranslate';
import { useDays } from '@/composables/useDays';
import BtnContactAdvisor from '@/components/buttons/BtnContactAdvisor.vue';
import AlertPdf from '@/components/alerts/alertPdf/AlertPdf.vue';
import CambrellaReturn from '@/modules/cambrella/components/CambrellaReturn.vue';
import CambrellaWithSubscription from '@/modules/cambrella/components/CambrellaWithSubcription.vue';
import { PropType } from 'vue';

export default {
    name: 'SubscriptionCambrellasBenefits',
    components: {
        CambrellaWithSubscription,
        CambrellaReturn,
        AlertPdf,
        BtnContactAdvisor,
        CambrellaGetSubscription,
        BtnSolid,
        IconOneBag,
        IconCheckSuccessRounded,
        BaseCard,
        SectionHeader,
    },
    props: {
        membership: {
            type: Object as PropType<MembershipType>,
            required: true,
        },
    },
    emits: ['return'],
    setup() {
        const { hasCambrellaSubscription, cambrellaMembership } = useCambrellas();
        const { doFormat } = useDays();
        const { convertToCurrency } = useCurrency();
        const { translate } = useTranslate();
        return {
            hasCambrellaSubscription,
            cambrellaMembership,
            doFormat,
            convertToCurrency,
            translate,
        };
    },
    data: () => ({
        showCambrellasModal: false,
        showTermsAndConditions: false,
        showCambrelaWithSubscription: false,
    }),
    computed: {
        isMobile(): boolean {
            return isMobileBrowser();
        },
        btnText(): string {
            return `Cambrellas Annual - ${this.convertToCurrency(this.membership.price)}`;
        },
        hasCambrellasRemaining(): boolean {
            return !this.cambrellaMembership.cambrellas_available;
        },
        backGroundColor(): string {
            return this.hasCambrellasRemaining ? '#c90808' : '#ff9012';
        },
    },
    methods: {
        onSubscriptionSuccess(): void {
            this.showCambrellasModal = false;
            this.showCambrelaWithSubscription = true;
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'subscriptions-cambrella-membership';
</style>
