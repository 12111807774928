import { RouteRecordRaw } from 'vue-router';
import { AddressModel } from '@/models/address/AddressModel';
import store from '@/store';
import { breadcrumbDefinition } from '@/components/breadcrumb/BreadcumbDefinition';
import TiptiWallet from '@/views/wallet/TiptiWallet.vue';
import TiptiCardHistory from '@/views/myAccount/views/tiptiCard/views/tiptiCardHistory/TiptiCardHistory.vue';
import TheWalletCardDetail from '@/views/wallet/components/TheWalletCardDetail.vue';
import TheGiftCardDetail from './components/GiftCard/TheGiftCardDetail.vue';
import TheExpiredGiftCards from './components/GiftCard/TheExpiredGiftCards.vue';
import TheExpiredGiftCardDetail from './components/GiftCard/TheExpiredGiftCardDetail.vue';
import TheGiftCardSelectedRetailer from './components/GiftCard/TheGiftCardSelectedRetailer.vue';
import TheGiftCardSelectedRetailerCategory from './components/GiftCard/TheGiftCardSelectedRetailerCategory.vue';
import TiptiCard from '@/views/myAccount/views/tiptiCard/TiptiCard.vue';
import { appRoutesMap } from '@/router/appRoutesMap';
import TheInstitutionalCardDetail from '@/views/wallet/components/TheInstitutionalCardDetail.vue';

export type WalletMetaTypes = keyof typeof appRoutesMap;

export const WalletRoutes: RouteRecordRaw = {
    name: appRoutesMap.Wallet,
    path: 'tipti-wallet',
    component: TiptiWallet,
    meta: {
        require_auth: true,
        breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.wallet],
    },
    children: [
        {
            path: 'card',
            name: appRoutesMap.WalletCard,
            component: TheWalletCardDetail,
            meta: {
                show_router_view: true,
                breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.wallet],
            },
        },
        {
            path: 'institution',
            name: appRoutesMap.walletInstitution,
            component: TheInstitutionalCardDetail,
            meta: {
                show_router_view: true,
                breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.wallet],
            },
        },
        {
            path: 'gift-card-detail',
            name: appRoutesMap.giftCardDetail,
            component: TheGiftCardDetail,
            meta: {
                show_router_view: true,
                breadcrumbs: [breadcrumbDefinition.account, breadcrumbDefinition.giftCards],
            },
        },
        {
            path: 'gift-card-selected-retailer',
            name: appRoutesMap.giftCardSelectedRetailer,
            component: TheGiftCardSelectedRetailer,
            meta: {
                show_router_view: true,
                breadcrumbs: [],
            },
        },
        {
            path: 'gift-card-selected-retailer-category',
            name: appRoutesMap.giftCardSelectedRetailerCategory,
            component: TheGiftCardSelectedRetailerCategory,
            meta: {
                show_router_view: true,
                breadcrumbs: [],
            },
        },
        {
            path: 'expired-gift-cards',
            name: appRoutesMap.expiredGiftCards,
            component: TheExpiredGiftCards,
            meta: {
                show_router_view: true,
                breadcrumbs: [
                    breadcrumbDefinition.account,
                    breadcrumbDefinition.giftCards,
                    breadcrumbDefinition.expiredGiftCards,
                ],
            },
        },
        {
            path: 'expired-gift-card-detail',
            name: appRoutesMap.expiredGiftCardDetail,
            meta: {
                show_router_view: true,
                breadcrumbs: [
                    breadcrumbDefinition.account,
                    breadcrumbDefinition.giftCards,
                    breadcrumbDefinition.expiredGiftCards,
                ],
            },
            component: TheExpiredGiftCardDetail,
        },
        {
            path: 'tipti-card',
            name: appRoutesMap.tiptiCard,
            component: TiptiCard,
            redirect: { name: appRoutesMap.tiptiCardRoutes.history },
            meta: {
                require_auth: true,
                show_router_view: true,
                breadcrumbs: [
                    breadcrumbDefinition.account,
                    breadcrumbDefinition.wallet,
                    breadcrumbDefinition.tiptiCard,
                ],
            },
            children: [
                {
                    path: 'tipti-card-history',
                    name: appRoutesMap.tiptiCardRoutes.history,
                    meta: {
                        breadcrumbs: [
                            breadcrumbDefinition.account,
                            breadcrumbDefinition.wallet,
                            breadcrumbDefinition.tiptiCard,
                            breadcrumbDefinition.history,
                        ],
                    },
                    component: TiptiCardHistory,
                },
                {
                    path: 'tipti-card-gifs',
                    name: appRoutesMap.tiptiCardRoutes.gift,
                    meta: {
                        send_gift: true,
                        show_router_view: true,
                        breadcrumbs: [
                            breadcrumbDefinition.account,
                            breadcrumbDefinition.wallet,
                            breadcrumbDefinition.tiptiCard,
                            breadcrumbDefinition.gift,
                        ],
                    },
                    component: () =>
                        import('@/views/myAccount/views/tiptiCard/views/tiptiCardRecharge/TiptiCardRecharge.vue'),
                },
                {
                    path: 'tipti-card-recharge',
                    name: appRoutesMap.tiptiCardRoutes.recharge,
                    meta: {
                        show_router_view: true,
                        breadcrumbs: [
                            breadcrumbDefinition.account,
                            breadcrumbDefinition.wallet,
                            breadcrumbDefinition.tiptiCard,
                            breadcrumbDefinition.recharge,
                        ],
                    },
                    component: () =>
                        import('@/views/myAccount/views/tiptiCard/views/tiptiCardRecharge/TiptiCardRecharge.vue'),
                },
                {
                    path: 'tipti-card-my-gift-cards',
                    name: appRoutesMap.tiptiCardRoutes.myGifts,
                    meta: {
                        show_router_view: true,
                        breadcrumbs: [
                            breadcrumbDefinition.account,
                            breadcrumbDefinition.wallet,
                            breadcrumbDefinition.tiptiCard,
                            breadcrumbDefinition.myGifts,
                        ],
                    },
                    component: () =>
                        import('@/views/myAccount/views/tiptiCard/views/tiptiCardMyGiftCards/TiptiCardMyGiftCards.vue'),
                },
                {
                    path: 'tipti-card-transfers',
                    name: appRoutesMap.tiptiCardRoutes.transfer,
                    meta: {
                        show_router_view: true,
                        breadcrumbs: [
                            breadcrumbDefinition.account,
                            breadcrumbDefinition.wallet,
                            breadcrumbDefinition.tiptiCard,
                            breadcrumbDefinition.transfer,
                        ],
                    },
                    component: () =>
                        import(
                            '@/views/myAccount/views/tiptiCard/views/tiptiCardTransferBalance/TiptiCardTransferBalance.vue'
                        ),
                },
                {
                    path: 'maxi-cards',
                    name: appRoutesMap.tiptiCardRoutes.maxiCard,
                    meta: {
                        show_router_view: true,
                        breadcrumbs: [
                            breadcrumbDefinition.account,
                            breadcrumbDefinition.wallet,
                            breadcrumbDefinition.tiptiCard,
                            breadcrumbDefinition.maxiCard,
                        ],
                    },
                    component: () => import('@/views/myAccount/views/tiptiCard/views/maxiDollars/MaxiCards.vue'),
                    beforeEnter: (_, __, next) => {
                        const countrySelected: AddressModel = store.getters['user/userLocation'];
                        if (countrySelected?.city?.country?.alphaCode === 'EC') return next();
                        return next({ name: appRoutesMap.account.profile });
                    },
                    redirect: { name: appRoutesMap.tiptiCardRoutes.maxiCardRoutes.maxiDollars },
                    children: [
                        {
                            path: `maxi-dollars`,
                            name: appRoutesMap.tiptiCardRoutes.maxiCardRoutes.maxiDollars,
                            meta: {
                                breadcrumbs: [
                                    breadcrumbDefinition.account,
                                    breadcrumbDefinition.wallet,
                                    breadcrumbDefinition.tiptiCard,
                                    breadcrumbDefinition.maxiCard,
                                ],
                            },
                            component: () =>
                                import('@/views/myAccount/views/tiptiCard/views/maxiDollars/MaxiDollars.vue'),
                        },
                        {
                            name: appRoutesMap.tiptiCardRoutes.maxiCardRoutes.maxiCard,
                            path: `maxi-card/:id`,
                            component: () => import('@/views/myAccount/views/tiptiCard/views/maxiDollars/MaxiCard.vue'),
                            meta: {
                                breadcrumbs: [
                                    breadcrumbDefinition.account,
                                    breadcrumbDefinition.wallet,
                                    breadcrumbDefinition.tiptiCard,
                                    breadcrumbDefinition.maxiCard,
                                ],
                            },
                        },
                    ],
                },
                {
                    path: 'payment',
                    name: appRoutesMap.tiptiCardRoutes.payments,
                    component: () =>
                        import('@/views/myAccount/views/tiptiCard/views/tiptiCardPayments/TiptiCardPayments.vue'),
                    beforeEnter: (to, from, next) => {
                        to.meta.send_gift = from.meta.send_gift;
                        next();
                    },
                    meta: {
                        show_router_view: true,
                    },
                    children: [
                        {
                            path: 'credit-cards',
                            component: () => import('@/views/creditCards/bodyCreditCards/BodyCreditCards.vue'),
                            name: appRoutesMap.tiptiCardRoutes.paymentsRoutes.creditCard,
                            beforeEnter: (to, from, next) => {
                                to.meta.send_gift = from.meta.send_gift;
                                next();
                            },
                            meta: {
                                breadcrumbs: [
                                    breadcrumbDefinition.account,
                                    breadcrumbDefinition.wallet,
                                    breadcrumbDefinition.tiptiCard,
                                ],
                            },
                        },
                        {
                            path: 'payphone',
                            component: () => import('@/views/payphone/Payphone.vue'),
                            name: appRoutesMap.tiptiCardRoutes.paymentsRoutes.payphone,
                            beforeEnter: (to, from, next) => {
                                to.meta.send_gift = from.meta.send_gift;
                                next();
                            },
                            meta: {
                                breadcrumbs: [
                                    breadcrumbDefinition.account,
                                    breadcrumbDefinition.wallet,
                                    breadcrumbDefinition.tiptiCard,
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
