<template>
    <label class="check-square">
        <input v-model="checked" type="checkbox" @input="onInput" class="check-square__input" />
        <span class="check-square__span"></span>
    </label>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps<{
    isChecked?: boolean;
}>();

const emit = defineEmits<{
    (e: 'on-check', value: boolean): void;
}>();

const checked = ref(props.isChecked ?? false);

const onInput = () => {
    checked.value = !checked.value;
    emit('on-check', checked.value);
};

watch(
    () => props.isChecked,
    (newVal) => {
        checked.value = newVal;
    },
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

.check-square {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &__input {
        display: none;
        &:checked ~ span {
            background: $white;
            &:after {
                visibility: visible;
            }
        }
    }
    &__span {
        cursor: pointer;
        width: 1.5em;
        height: 1.5em;
        border: 1px solid $orange;
        display: flex;
        border-radius: 4px;
        transition: all linear 0.3s;
        background-color: $white;
        justify-content: center;
        align-items: center;

        &:after {
            content: '';
            position: absolute;
            width: 1.25em;
            border-radius: 4px;
            background-color: $orange;
            height: 1.25em;
            align-self: center;
            visibility: hidden;
        }
    }
}
</style>
