import { PaymentMethodModel, PaymentsChannels, toPaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import { PaymentSurplusModel, toPaymentSurplusModel } from '@/models/notifications/PaymentSurplusModel';
import { toPayphoneModel } from '@/models/paymentMethods/PayphoneModel';
import { BanksModel, toBanksModel } from '@/models/paymentMethods/BanksModel';
import { DeferredModel, toDeferredModel } from '@/models/paymentMethods/DeferredModel';
import { logger } from '@/utils/logger';
import { InstitutionalModel, toInstitutionalModel } from '@/models/paymentMethods/InstitutionalModel';
import { DefaultPaymentTypeModel, toDefaultPaymentTypeModel } from '@/models/paymentMethods/DefaultPaymentTypeModel';
import { toDefaultPaymentModel } from '@/models/paymentMethods/DefaultPaymentModel';
import { PaymentChannelOptions } from '@/enums/paymentChannelOptions';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import type { OrderRejected } from '@/models/order/OrderRejectedModel';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';

interface DefaultPaymentInterface {
    paymentTypeId: number;
    institutionalId?: number;
    paymentId?: number;
}

export default {
    async institutions({ rootGetters }): Promise<InstitutionalModel> {
        try {
            const url = 'v2/client/institutional-payments';
            const { data } = await rootGetters['network/axios'].get(url);
            return data?.map((institution) => toInstitutionalModel(institution)) ?? [];
        } catch (err) {
            throw Error(err);
        }
    },
    async deferredOptions({ rootGetters, getters, commit }, orderRejected: OrderRejected): Promise<void> {
        try {
            const url = 'v2/api/credit_card/deferred';
            const body = {
                payment_method_id:
                    getters['paymentIdSelected'] ??
                    getters['creditCardDefault']?.id ??
                    rootGetters['payments/paymentsToShow']?.find(
                        (payment: PaymentToShowModel) => payment.name === 'payment-cards',
                    )?.id,
                order_id: orderRejected?.orderId,
            };
            const { data } = await rootGetters['network/axios'].post(url, body);
            const options: Array<DeferredModel> = data?.map((option: object) => toDeferredModel(option));
            commit('deferredOptions', options);
            if (!options?.length) return;
            commit('deferred', options.at(0));
        } catch (err) {
            commit('deferredOptions', []);
            commit('deferred', undefined);
            logger('DEFERRED_OPTIONS', err);
        }
    },
    async fetchSurplusPayments({ rootGetters, commit }): Promise<Array<PaymentSurplusModel>> {
        try {
            const url = 'v2/client_profile/surplus/payment_request';
            const { data } = await rootGetters['network/axios'].get(url);
            let pendingPayment: PaymentSurplusModel;
            if (data?.length >= 1) pendingPayment = toPaymentSurplusModel(data[0]);
            commit('notifications/pendingPayment', pendingPayment, { root: true });
            return data?.map((surplus: object) => toPaymentSurplusModel(surplus));
        } catch (err) {
            commit('notifications/pendingPayment', null, { root: true });
            logger('FETCH_PENDING_PAYMENTS', err);
        }
    },
    async setPendingPayment(
        { rootGetters },
        payload: { status: string; payment: PaymentSurplusModel },
    ): Promise<boolean> {
        try {
            const url = 'v2/client_profile/surplus/payment_request';
            const body = {
                status: payload.status,
                order_surplus_payment_request_id: payload.payment.id,
                pay_with_cash:
                    payload.status != 'REJECTED_BY_CLIENT' && payload.payment.cashPayment
                        ? payload.payment.cashPayment
                        : undefined,
                pay_with_tipti_card:
                    payload.status != 'REJECTED_BY_CLIENT' && payload.payment.tiptiCardPayment
                        ? payload.payment.tiptiCardPayment
                        : undefined,
                payment_method_id:
                    payload.status != 'REJECTED_BY_CLIENT' && payload.payment.paymentMethodToUse != null
                        ? payload.payment.paymentMethodToUse
                        : undefined,
                payphone_number:
                    payload.status != 'REJECTED_BY_CLIENT' && payload.payment?.payphonePayment != null
                        ? payload.payment.payphonePayment
                        : undefined,
            };
            const { data } = await rootGetters['network/axios'].post(url, body);
            return data?.data?.['sent'] ?? false;
        } catch (err) {
            throw Error(err);
        }
    },
    async creditCardList({ rootGetters, commit }, isNewVersion: boolean): Promise<Array<PaymentMethodModel>> {
        try {
            const url = `client_profile/payment_method/all${isNewVersion ? '?version=v2' : ''}`;
            const { data } = await rootGetters['network/axios'].get(url);
            const creditCards: Array<PaymentMethodModel> = data['results']
                .map((card) => toPaymentMethodModel(card))
                .filter(
                    (method: PaymentMethodModel) => method.paymentChannel === PaymentsChannels.CREDIT_AND_DEBIT_CARD,
                );
            const creditCardDefault: PaymentMethodModel = creditCards.find(
                (card: PaymentMethodModel) => card.resultDefault,
            );
            commit('creditCardDefault', creditCardDefault);
            return creditCards;
        } catch (err) {
            throw Error(err);
        }
    },
    async deleteCard({ rootGetters }, id: number): Promise<void> {
        try {
            const url = `client_profile/payment_method/${id}/`;
            await rootGetters['network/axios'].delete(url);
        } catch (err) {
            throw Error('DELETE_CREDIT_CARDS');
        }
    },

    async payphoneList({ rootGetters, commit }): Promise<void> {
        try {
            const url = 'client_profile/payment_method/all?version=v2';
            const { data } = await rootGetters['network/axios'].get(url);
            const filterPhones = data['results'].filter(
                (card) =>
                    card['name'] != 'paymentez_subscription' &&
                    card['name'] != 'pago_plux_subscription' &&
                    card['name'] != 'payphone_subscription',
            );
            const payphoneList = filterPhones.map((phone) => {
                return toPayphoneModel(phone);
            });
            commit('payphoneList', payphoneList);
        } catch (err) {
            logger('CREDIT_CARDS_LITS', err);
        }
    },
    async banks({ rootGetters }, onlineBankServices: boolean): Promise<Array<BanksModel>> {
        try {
            const { data } = await rootGetters['network/axios'].get(
                `v2/api/tipti-bank-accounts?online_banking_payment=${onlineBankServices ? 'True' : 'False'}`,
            );
            return data.map((bank: object) => toBanksModel(bank));
        } catch (err) {
            throw Error(err);
        }
    },
    async addPayphone({ rootGetters, dispatch }, phone): Promise<void> {
        try {
            const url = 'client_profile/payment_method';
            ///* Get payment ty by channel
            const _paymentType = await dispatch('getPaymentTypeByChannel', PaymentChannelOptions.PAYPHONE_APP);
            const data = {
                payment_type: _paymentType ?? 3,
                name: phone,
                default: false,
                data: {
                    phone: phone,
                },
                response_flag: true,
            };
            await rootGetters['network/axios'].post(url, data);
        } catch (err) {
            if (err.response?.status == 400) throw err.response;
            logger('ADD_PAYPHONE', err);
        }
    },
    async validatePayment({ rootGetters }, payload: { paymentMethodId: number; amount: number }): Promise<void> {
        try {
            const url = `client_profile/validate/payment_method/${payload.paymentMethodId}/`;
            const data = { amount: payload.amount };
            await rootGetters['network/axios'].post(url, data);
        } catch (err) {
            logger('VALIDATE_PAYMENT_METHOD', err);
            throw err.response;
        }
    },
    async generateVerification({ rootGetters }, paymentMethodId: number): Promise<void> {
        try {
            const url = `client_profile/create_request/payment_method/${paymentMethodId}/`;
            await rootGetters['network/axios'].post(url);
        } catch (err) {
            logger('GENERATE_VERIFICATION', err);
            throw err.response;
        }
    },
    async defaultPaymentsType({ rootGetters, commit }): Promise<void> {
        try {
            const _url = 'v2/customer/payment-methods/types/';
            const { data } = await rootGetters['network/axios'].get(_url);
            commit(
                'defaultPaymentsTypesAvailable',
                data.map((defaultPaymentType: object) => toDefaultPaymentTypeModel(defaultPaymentType)),
            );
        } catch (err) {
            logger('DEFAULT_PAYMENTS_TYPE', err);
            commit('defaultPaymentsTypesAvailable', []);
        }
    },
    async defaultPayment({ commit }): Promise<number> {
        try {
            const { data } = await axiosInstance().get('v2/customer/payment-methods/default/', {
                cancelPreviousRequests: true,
            });
            commit('defaultPaymentSelected', toDefaultPaymentModel(data));
            return data['id'];
        } catch (err) {
            logger('DEFAULT_PAYMENT', err);
        }
    },
    async setDefaultPayment({ rootGetters, commit }, defaultPaymentData: DefaultPaymentInterface): Promise<void> {
        try {
            const _url = 'v2/customer/payment-methods/default/';
            const _data = {
                payment_type_id: defaultPaymentData.paymentTypeId,
                payment_id: defaultPaymentData.paymentId,
                institutional_id: defaultPaymentData.institutionalId,
            };
            const { data } = await rootGetters['network/axios'].post(_url, _data);
            commit('defaultPaymentSelected', toDefaultPaymentModel(data));
        } catch (err) {
            logger('SET_DEFAULT_PAYMENT', err);
            throw err.response;
        }
    },
    async updateDefaultPayment({ rootGetters, commit }, defaultPaymentData: DefaultPaymentInterface): Promise<void> {
        try {
            const _url = 'v2/customer/payment-methods/default/update';
            const _data = {
                payment_type_id: defaultPaymentData.paymentTypeId,
                payment_id: defaultPaymentData.paymentId,
                institutional_id: defaultPaymentData.institutionalId,
            };
            const { data } = await rootGetters['network/axios'].put(_url, _data);
            commit('defaultPaymentSelected', toDefaultPaymentModel(data));
        } catch (err) {
            logger('SET_DEFAULT_PAYMENT', err);
            throw err.response;
        }
    },
    async deleteDefaultPayment({ rootGetters, commit }, idPaymentDefault: number): Promise<void> {
        try {
            const _url = `v2/customer/payment-methods/default/${idPaymentDefault}/delete`;
            await rootGetters['network/axios'].delete(_url);
            commit('defaultPaymentSelected', null);
        } catch (err) {
            logger('DELETE_DEFAULT_PAYMENT', err);
            throw err.response;
        }
    },
    async getPaymentTypeByChannel({ getters }, paymentChannel: PaymentChannelOptions): Promise<number> {
        if (!getters['defaultPaymentsTypesAvailable']?.length) {
            await this.defaultPaymentsType();
        }
        if (paymentChannel == null) return null;
        return getters['defaultPaymentsTypesAvailable'].find(
            (paymentType: DefaultPaymentTypeModel) => paymentType.channelPayment == paymentChannel,
        )?.id;
    },
    async getNequiPhoneNumbersList({ rootGetters, commit }): Promise<void> {
        try {
            const url = 'v2/client_profile/nequi_phone_list/';
            const { data } = await rootGetters['network/axios'].get(url);
            return data;
        } catch (err) {
            logger('NEQUI_NUMBERS_LIST', err);
        }
    },
};
