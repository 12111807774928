<template>
    <footer
        class="section-footer"
        :class="{ 'section-footer--border': hasBorder, 'section-footer--shadow': withShadow }"
        :style="{ padding: `${verticalPadding} 0` }"
    >
        <slot></slot>
    </footer>
</template>

<script>
export default {
    name: 'section-footer',
    props: {
        hasBorder: {
            type: Boolean,
            default: true,
        },
        withShadow: {
            type: Boolean,
            default: true,
        },
        verticalPadding: {
            type: String,
            default: '1rem',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.section-footer {
    display: flex;
    justify-content: center;
    background-color: $white;
    &--border {
        border-radius: 0 0 $radius-lg $radius-lg;
    }
    &--shadow {
        @include box-shadow;
    }
}
</style>
