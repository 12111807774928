<template>
    <base-icon :icon-name="$t('icon.enter_code')" :width="width" :height="height" viewBox="0 0 114 114">
        <path
            d="M3.17373 113.999H21.8853C22.5783 113.999 23.0403 113.421 23.0403 112.728V109.841C23.0403 109.148 22.4628 108.57 21.8853 108.57H3.17373C2.48071 108.57 2.0187 109.148 2.0187 109.841V112.728C1.90319 113.421 2.48071 113.999 3.17373 113.999Z"
            fill="#CFDE00"
        />
        <path
            d="M33.0886 113.999H51.8002C52.4932 113.999 52.9552 113.421 52.9552 112.728V109.841C52.9552 109.148 52.3777 108.57 51.8002 108.57H33.0886C32.3956 108.57 31.9336 109.148 31.9336 109.841V112.728C31.9336 113.421 32.3956 113.999 33.0886 113.999Z"
            fill="#CFDE00"
        />
        <path
            d="M63.123 113.999H81.8345C82.5275 113.999 82.9896 113.421 82.9896 112.728V109.841C82.9896 109.148 82.412 108.57 81.8345 108.57H63.123C62.4299 108.57 61.9679 109.148 61.9679 109.841V112.728C61.8524 113.421 62.4299 113.999 63.123 113.999Z"
            fill="#CFDE00"
        />
        <path
            d="M93.2675 113.886H111.979C112.672 113.886 113.134 113.308 113.134 112.615V109.728C113.134 109.035 112.557 108.457 111.979 108.457H93.2675C92.5745 108.457 92.1124 109.035 92.1124 109.728V112.615C91.9969 113.308 92.5745 113.886 93.2675 113.886Z"
            fill="#CFDE00"
        />
        <path
            d="M89.3391 63.1806C89.3391 72.9984 81.4848 80.8526 71.667 80.9681H52.0314C42.2136 80.9681 34.3594 72.9984 34.3594 63.1806V34.3047H89.2236L89.3391 63.1806Z"
            fill="#FF9012"
        />
        <path
            d="M30.7812 108.572V111.344C30.7812 112.037 31.3588 112.499 31.9363 112.499H50.6479C51.3409 112.499 51.8029 111.922 51.8029 111.344V108.572C51.8029 107.879 51.2254 107.417 50.6479 107.417H32.0518C31.3588 107.302 30.7812 107.879 30.7812 108.572Z"
            fill="#606060"
        />
        <path
            d="M83.3389 26.9123H77.6793V15.2465C77.6793 6.81471 70.8645 0 62.4328 0H50.4204C42.1042 0 35.2895 6.81471 35.1739 15.2465V26.9123H29.6298C28.0127 26.9123 26.7422 28.1829 26.7422 29.7999V57.7518C26.7422 69.3021 36.2135 78.7734 47.8793 78.7734H65.0894C76.7552 78.7734 86.2265 69.3021 86.2265 57.6363V29.6844C86.2265 28.1829 84.956 26.9123 83.3389 26.9123ZM40.9491 15.2465C40.9491 9.9333 45.2228 5.77518 50.4204 5.77518H62.4328C67.6304 5.77518 71.9041 10.0488 71.9041 15.2465V26.9123H40.9491V15.2465ZM80.4513 57.6363C80.4513 66.1835 73.5211 72.9982 65.0894 72.9982H47.8793C39.3321 72.9982 32.5174 66.068 32.5174 57.6363V32.572H80.5668L80.4513 57.6363Z"
            fill="#606161"
        />
        <path
            d="M53.5401 63.5274C53.5401 65.1444 54.8107 66.415 56.4277 66.415C58.0448 66.415 59.3153 65.1444 59.3153 63.5274V55.4421C59.6618 54.9801 59.8928 54.4026 59.8928 53.8251C59.8928 52.208 58.6223 50.9375 57.0052 50.9375H55.9657C54.3487 50.9375 53.0781 52.208 53.0781 53.8251C53.0781 54.4026 53.3091 54.9801 53.6556 55.4421V63.5274H53.5401Z"
            fill="#606161"
        />
        <path
            d="M18.9998 87.614C18.6533 86.8055 17.6137 86.5745 16.9207 87.0365L12.9936 89.3466V84.7264C12.8781 83.8024 12.1851 83.1094 11.3766 83.1094C10.568 83.1094 9.75951 83.8024 9.75951 84.7264V89.3466L5.83239 87.0365C5.02386 86.5745 4.09983 86.8055 3.63782 87.614C3.1758 88.307 3.40681 89.3466 4.21534 89.8086L8.25796 92.1187L4.21534 94.4287C3.40681 94.8907 3.1758 95.8148 3.63782 96.6233C4.09983 97.4318 5.02386 97.6628 5.83239 97.2008L9.75951 94.8907V99.5109C9.75951 100.435 10.4525 101.128 11.3766 101.128C12.3006 101.128 12.9936 100.435 12.9936 99.5109V94.8907L16.9207 97.2008C17.7292 97.6628 18.6533 97.4318 19.1153 96.6233C19.5773 95.8148 19.3463 94.8907 18.5378 94.4287L14.4952 92.1187L18.5378 89.8086C19.2308 89.3466 19.4618 88.4225 18.9998 87.614C19.1153 87.614 19.1153 87.614 18.9998 87.614Z"
            fill="#606060"
        />
        <path
            d="M33.6701 96.6255C34.1321 97.3185 35.0561 97.665 35.8646 97.203L39.7917 94.893V99.5131C39.7917 100.437 40.4848 101.13 41.4088 101.13C42.3328 101.13 43.0258 100.437 43.0258 99.5131V94.893L46.953 97.203C47.7615 97.665 48.6855 97.434 49.1475 96.6255C49.6095 95.817 49.3785 94.893 48.57 94.4309L44.5274 92.1209L48.57 89.8108C49.3785 89.3488 49.6095 88.4248 49.1475 87.6162C48.6855 86.8077 47.646 86.5767 46.953 86.9232L43.0258 89.2333V84.6131C43.0258 83.6891 42.3328 82.9961 41.4088 82.9961C40.4848 82.9961 39.7917 83.6891 39.7917 84.6131V89.2333L35.8646 86.9232C35.1716 86.4612 34.1321 86.6922 33.6701 87.3852C33.208 88.0783 33.439 89.1178 34.1321 89.5798C34.1321 89.5798 34.1321 89.5798 34.2476 89.5798L38.2902 91.8899L34.2476 94.1999C33.439 94.893 33.208 95.817 33.6701 96.6255Z"
            fill="#606060"
        />
        <path
            d="M60.9258 108.522V111.294C60.9258 111.987 61.5033 112.449 62.0808 112.449H80.7924C81.4854 112.449 81.9474 111.872 81.9474 111.294V108.522C81.9474 107.829 81.3699 107.367 80.7924 107.367H62.0808C61.3878 107.367 60.9258 107.945 60.9258 108.522Z"
            fill="#606060"
        />
        <path
            d="M63.7003 96.6255C64.1623 97.3185 65.0864 97.665 65.8949 97.203L69.822 94.893V99.5131C69.822 100.437 70.515 101.13 71.4391 101.13C72.3631 101.13 73.0561 100.437 73.0561 99.5131V94.893L76.9832 97.203C77.7917 97.665 78.7158 97.434 79.1778 96.6255C79.6398 95.817 79.4088 94.893 78.6003 94.4309L74.5577 92.1209L78.6003 89.8108C79.2933 89.3488 79.5243 88.3093 79.0623 87.6162C78.6003 86.8077 77.6762 86.5767 76.9832 86.9232L73.0561 89.2333V84.6131C73.0561 83.6891 72.3631 82.9961 71.4391 82.9961C70.515 82.9961 69.822 83.6891 69.822 84.6131V89.2333L65.8949 86.9232C65.0864 86.4612 64.1623 86.6922 63.7003 87.5007C63.2383 88.3093 63.4693 89.2333 64.2778 89.6953L68.3205 92.0054L64.2778 94.3154C63.5848 94.893 63.2383 95.817 63.7003 96.6255Z"
            fill="#606060"
        />
        <path
            d="M110.824 107.367H92.1121C91.419 107.367 90.957 107.945 90.957 108.522V111.294C90.957 111.987 91.5345 112.449 92.1121 112.449H110.824C111.517 112.449 111.979 111.872 111.979 111.294V108.522C112.094 107.945 111.517 107.367 110.824 107.367Z"
            fill="#606060"
        />
        <path
            d="M1.90504 112.565H20.6166C21.3096 112.565 21.7716 111.987 21.7716 111.41V108.522C21.7716 107.829 21.1941 107.367 20.6166 107.367H1.90504C1.21201 107.367 0.75 107.945 0.75 108.522V111.294C0.75 111.987 1.32752 112.565 1.90504 112.565Z"
            fill="#606060"
        />
        <path
            d="M93.7286 96.6233C94.1907 97.3163 95.1147 97.6628 95.9232 97.2008L99.8503 94.8907V99.5109C99.8503 100.435 100.543 101.128 101.467 101.128C102.391 101.128 103.084 100.435 103.084 99.5109V94.8907L107.012 97.2008C107.82 97.6628 108.744 97.4318 109.206 96.6233C109.668 95.8148 109.437 94.8907 108.629 94.4287L104.586 92.1187L108.629 89.8086C109.437 89.3466 109.668 88.4225 109.206 87.614C108.744 86.8055 107.82 86.5745 107.012 86.921L103.084 89.2311V84.6109C103.084 83.8024 102.391 83.1094 101.467 83.1094C100.543 83.1094 99.8503 83.8024 99.8503 84.7264V89.3466L95.9232 87.0365C95.2302 86.5745 94.1907 86.8055 93.7286 87.4985C93.2666 88.1915 93.4976 89.2311 94.1907 89.6931C94.1907 89.6931 94.1907 89.6931 94.3062 89.6931L98.3488 92.0032L94.3062 94.3132C93.6131 94.8907 93.3821 95.8148 93.7286 96.6233Z"
            fill="#606060"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-enter-code',
    props: {
        size: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        width(): number {
            return 114 * this.size;
        },
        height(): number {
            return 114 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
