import { ServerException } from '@/models/error/ServerException';

const handleError400 = (err: ServerException, defaultMessages: { [key: number]: string }) => {
    if (!err) return '';
    if (typeof err.data === 'string') return err.data?.length ? err.data : '';
    if (err.data?.show_message_error?.length) return err.data?.show_message_error;
    return defaultMessages['400'] ?? '';
};

const handleError429 = (err: ServerException, defaultMessages: { [key: number]: string }) => {
    return defaultMessages['429'] ?? '';
};

const handleError4Default = (err: ServerException, defaultMessages: { [key: number]: string }) => {
    return defaultMessages['1'];
};

/**
 * @param err ServerException type
 * @param defaultMessages {key (status): value (defaultMessage)} message to show when server error message is empty
 * @param self for use this.$t()
 */
export const HandlerServerError = (err: ServerException, defaultMessages: { [key: number]: string }, self) => {
    if (!err) return self.$t('general.try-again');
    const errorCodeMap = {
        400: handleError400(err, defaultMessages),
        429: handleError429(err, defaultMessages),
        1: handleError4Default(err, defaultMessages),
    };
    return err?.status in errorCodeMap
        ? errorCodeMap[err?.status]
        : errorCodeMap['1']
        ? errorCodeMap['1']
        : self.$t('general.try-again');
};
