<template>
    <base-modal
        v-if="!isMobile"
        :hasClose="hasClose"
        :title="title"
        :hasHeader="hasHeader"
        :header-text-color="headerTextColor"
        @on-close="$emit('on-close')"
    >
        <slot name="content" />
        <section
            v-if="showButtons"
            class="modal_manager__btns"
            :class="{
                'modal_manager__btns--one-btn': onlyConfirmationBtn,
            }"
            :style="style"
        >
            <btn-out-line
                v-if="!onlyConfirmationBtn"
                bgColor="#FFF"
                color="#FF9012"
                :txt="declineButtonText ?? $t('cta.reject')"
                @click="$emit('on-decline')"
            />
            <btn-solid
                :isDisabled="isDisabled"
                :isLoading="isLoading"
                :txt="acceptButtonText ?? $t('cta.accept')"
                @click="$emit('on-accept')"
            />
        </section>
    </base-modal>
    <base-bottom-sheet v-else bg-color="#fff" @on-dismiss="$emit('on-dismiss')" :cta-header-text="ctaHeaderText">
        <template v-slot:content>
            <h1 v-if="showBottomSheetTitle" class="bottom-sheet__title">
                {{ title }}
            </h1>
            <slot name="content" />
            <section
                v-if="showButtons"
                class="bottom-sheet__btns"
                :class="{
                    'bottom-sheet__btns--one-btn': onlyConfirmationBtn,
                }"
                :style="style"
            >
                <btn-out-line
                    v-if="!onlyConfirmationBtn"
                    bgColor="#FFF"
                    color="#FF9012"
                    :txt="declineButtonText ?? $t('cta.reject')"
                    @click="$emit('on-decline')"
                />
                <btn-solid
                    :isDisabled="isDisabled"
                    :isLoading="isLoading"
                    :txt="acceptButtonText ?? $t('cta.accept')"
                    @click="$emit('on-accept')"
                />
            </section>
        </template>
    </base-bottom-sheet>
</template>

<script lang="ts">
import { isMobileBrowser } from '@/utils/utils';
import BaseBottomSheet from '../../bottomSheet/BaseBottomSheet.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';

export default {
    name: 'modal-manager',
    emits: ['on-close', 'on-close-modal', 'on-accept', 'on-decline', 'on-dismiss'],
    props: {
        hasClose: {
            type: Boolean,
            default: false,
        },
        hasHeader: {
            type: Boolean,
            default: true,
        },
        isDisabled: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        acceptButtonText: {
            type: String,
        },
        declineButtonText: {
            type: String,
        },
        title: {
            type: String,
            default: '',
        },
        ctaHeaderText: {
            type: String,
            default: '',
        },
        onlyConfirmationBtn: {
            type: Boolean,
            default: false,
        },
        showButtons: {
            type: Boolean,
            default: false,
        },
        showBottomSheetTitle: {
            type: Boolean,
            default: true,
        },
        padding: {
            type: String,
        },
        btnXPadding: {
            type: Number,
            default: 0,
        },
        btnYPadding: {
            type: Number,
            default: 0,
        },
        headerTextColor: {
            type: String,
            default: '#707070',
        },
    },
    components: {
        BaseModal,
        BaseBottomSheet,
        BtnOutLine,
        BtnSolid,
    },
    computed: {
        isMobile() {
            return isMobileBrowser();
        },
        style(): object {
            return {
                padding: `${this.btnYPadding}rem ${this.btnXPadding}rem`,
            };
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'modal-manager';
</style>
