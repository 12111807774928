<template>
    <section class="dynamic_dropdown" v-click-outside="closeContent">
        <button class="dropdown-button" @click.prevent="toggleOption">
            <span class="dropdown-button__text" :class="{ 'dropdown-button__text--tag': !currentOption }">
                {{ currentOption ?? labelName }}
            </span>
            <div class="dropdown-button__arrow" :class="{ 'dropdown-button__arrow--disable': !options?.length }">
                <icon-dropdown color="#ffff" :size="0.75"></icon-dropdown>
            </div>
        </button>
        <p class="input-normal__required" v-if="isEmptyAndRequired">* {{ $t("general['required-fields']") }}</p>
        <base-card padding="0" v-if="showOptions" border-radius="0" :style="style" class="dropdown__options">
            <slot>
                <div :key="option.value" class="" v-for="option in options" @click.prevent="updateValue(option)">
                    <p class="dropdown-option">{{ option.title }}</p>
                    <horizontal-divider v-if="option !== options[options.length - 1]" />
                </div>
            </slot>
        </base-card>
    </section>
</template>

<script lang="ts">
import IconDropdown from '@/components/icons/IconDropdown.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import vClickOutside from '@/directives/click_outside';
import HorizontalDivider from '@/components/dividers/HorizontalDivider.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'dynamic-dropdown',
    components: {
        HorizontalDivider,
        IconDropdown,
        BaseCard,
    },
    directives: {
        clickOutside: vClickOutside,
    },
    props: {
        modelValue: {
            type: [String, Number],
        },
        options: {
            type: Array,
        },
        defaultOption: {
            type: String,
            default: '',
        },
        labelName: {
            type: String,
            default: '',
        },
        isEmptyAndRequired: {
            type: Boolean,
            default: false,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showOptions: false,
            currentOption: undefined,
        };
    },
    methods: {
        toggleOption(): void {
            if (!this.options?.length) return;
            this.showOptions = !this.showOptions;
        },
        updateValue(option: any): void {
            this.currentOption = option;
            this.$emit('update:modelValue', option?.value);
            this.closeContent();
        },
        closeContent(): void {
            this.showOptions = false;
        },
    },
    computed: {
        style(): object {
            return {
                'border-bottom-left-radius': '11px',
                'border-bottom-right-radius': '11px',
                'max-height': '10rem',
                'overflow-y': 'auto',
            };
        },
    },
    updated() {
        this.currentOption = this.options?.find(option => option.value === this.modelValue)?.title;
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
@import '../inputs/primary/input-primary';
.dropdown-option {
    @include overline;
    display: flex;
    align-items: center;
    padding: $padding-xsm $padding-sm;
    height: 20px;
    &:hover {
        font-weight: 600;
        cursor: pointer;
    }
    @include phone {
        @include caption;
    }
}
.dynamic_dropdown {
    position: relative;
    width: 100%;
}
.dropdown-button {
    width: 100%;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    border-radius: $radius-lg;
    border: 1px solid #c7c7c7;
    padding: unset;
    padding-left: 5px;
    &__text {
        @include overline;
        @include phone {
            @include caption;
        }
        &--tag {
            color: #c7c7c7;
        }
    }
    &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $orange;
        height: 100%;
        width: 2.5rem;
        &--disable {
            background-color: $disable;
        }
    }
}
.dropdown__options {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    height: fit-content;
    width: 100%;
}
</style>
