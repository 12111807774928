import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-101224ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "alert_legend" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_actions_quantity = _resolveComponent("actions-quantity")!
  const _component_the_add_buttons = _resolveComponent("the-add-buttons")!
  const _component_btn_circular = _resolveComponent("btn-circular")!
  const _component_alert_configurable_delivery_products = _resolveComponent("alert-configurable-delivery-products")!
  const _component_icon_add_cart = _resolveComponent("icon-add-cart")!
  const _component_tipti_alert = _resolveComponent("tipti-alert")!
  const _component_snack_bar = _resolveComponent("snack-bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["the-product-actions", { 'the-product-actions--rounded': _ctx.updateAmount > 0 && !$options.isLargeQuantity && !$options.canShowActions }]),
    onMouseout: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.onMouseOut && $options.onMouseOut(...args))),
    onMouseover: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => ($options.onMouseOver && $options.onMouseOver(...args)))
  }, [
    ($props.isForReplacement)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : ($options.canShowActions)
        ? (_openBlock(), _createBlock(_component_actions_quantity, {
            key: 1,
            class: "card-product-blur__actions",
            "actual-quantity": _ctx.updateAmount === 0 ? 1 : _ctx.updateAmount,
            "is-catalog-ficoa": $props.isCatalogFicoa,
            "is-miniature": $props.isMiniature,
            "must-fetch-cart": $props.fetchCart,
            "product-data": $props.productData,
            section: $props.section,
            onOnClose: $options.onClose,
            onOnUpdate: $options.onUpdate,
            onOnDelete: $options.onDelete
          }, null, 8, ["actual-quantity", "is-catalog-ficoa", "is-miniature", "must-fetch-cart", "product-data", "section", "onOnClose", "onOnUpdate", "onOnDelete"]))
        : (_ctx.updateAmount === 0)
          ? (_openBlock(), _createBlock(_component_the_add_buttons, {
              key: $data.buttonRemountKey,
              onClickOnce: _cache[0] || (_cache[0] = ($event: any) => ($options.onBtnClicked(true)))
            }))
          : (_openBlock(), _createBlock(_component_btn_circular, {
              key: 3,
              class: _normalizeClass(["btn-actions", { 'actions--mini': $props.isMiniature }]),
              "bg-color": "#ffffff",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.onBtnClicked(false)))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "card-product__quantity",
                  textContent: _toDisplayString(_ctx.updateAmount)
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            }, 8, ["class"])),
    ($data.showConfigurableDeliveryAlert)
      ? (_openBlock(), _createBlock(_component_alert_configurable_delivery_products, {
          key: 4,
          "product-name": $props.productData.name,
          onOnClose: $options.closeConfigurableDeliveryAlert
        }, null, 8, ["product-name", "onOnClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_tipti_alert, {
      "alert-information": $data.alertData,
      "show-alert": $data.showAskForExtraItemsModal,
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => ($options.onBtnClicked(true, true))),
      onOnMainAction: $options.addInOrder,
      onOnSecondaryAction: _cache[3] || (_cache[3] = ($event: any) => ($options.onBtnClicked(true, true)))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_icon_add_cart, {
          size: 2,
          color: "#ff9012"
        })
      ]),
      moreContent: _withCtx(() => [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('txt.extra_item_limit', { limit: _ctx.order.numberOfExtraItemsAllowed })), 1)
      ]),
      _: 1
    }, 8, ["alert-information", "show-alert", "onOnMainAction"]),
    _createVNode(_component_snack_bar, {
      body: $data.message5x5,
      "is-active": $data.show5x5Message && !$data.wasShowed5x5Message,
      "is-success": $data.isDiscountApplied,
      "no-background": "",
      onOnSnackbarClose: _cache[4] || (_cache[4] = ($event: any) => ($data.wasShowed5x5Message = true)),
      onOnSnackbarTap: _cache[5] || (_cache[5] = ($event: any) => ($data.wasShowed5x5Message = true))
    }, null, 8, ["body", "is-active", "is-success"])
  ], 34))
}