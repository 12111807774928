<template>
    <section v-if="!hideMainFaq" class="contain-faqs" :class="{ 'contain-faqs--profile': isPageAccountFaqs }">
        <h1 class="header-contact">{{ $t("txt['faqs__how-can-we-help-you']") }}</h1>
        <div class="faq-main">
            <template v-for="item in homeFaqCards" :key="item.titleCard">
                <CardFaq
                    :alt-icon="item.titleCard"
                    :content-card="item.contentCard"
                    :icon-component="item.iconComponent"
                    :title-card="item.titleCard"
                    @click="openCardFaq(item.nameComponent)"
                />
            </template>
        </div>
    </section>

    <template v-if="hideMainFaq">
        <ContentFaq :component-active="selectedCard" @back-faq="backFaq" />
    </template>

    <snack-bar
        :body="snackBarMessage"
        :is-active="showSnackBar"
        :is-failure="!isSuccess"
        :is-success="isSuccess"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import CardFaq from '@/views/faqs/components/CardFaq.vue';
import ContentFaq from '@/views/faqs/components/ContentFaq.vue';
import { useSeoMeta } from '@unhead/vue';
import { useRoutes } from '@/composables/useRoutes';

export default {
    name: 'faqs',
    components: {
        CardFaq,
        SnackBar,
        ContentFaq,
    },
    emits: ['back-faq'],
    setup() {
        const { t } = useI18n();
        const { isPageAccountFaqs } = useRoutes();
        useSeoMeta({
            title: t('seo.contact_us'),
            description: t('txt.help-support__faqs-description'),
        });

        return {
            isPageAccountFaqs,
        };
    },
    data() {
        return {
            showSnackBar: false,
            isLoading: false,
            isSuccess: false,
            showModal: false,
            snackBarMessage: '',

            hideMainFaq: false,
            selectedCard: '',
            homeFaqCards: [
                {
                    titleCard: 'faqs__what-is-tipti',
                    contentCard: 'faqs__what-is-tipti_content',
                    iconComponent: 'IconWhatIsTipti',
                    nameComponent: 'FaqWhatIsTipti',
                },
                {
                    titleCard: 'faqs__tipti-express',
                    contentCard: 'faqs__tipti-express_content',
                    iconComponent: 'IconTiptiExpress',
                    nameComponent: 'FaqTiptiExpress',
                },
                {
                    titleCard: 'faqs__order-in-process',
                    contentCard: 'faqs__order-in-process_content',
                    iconComponent: 'IconOrderInProcess',
                    nameComponent: 'FaqOrderInProcess',
                },
                {
                    titleCard: 'faqs__make-an-order',
                    contentCard: 'faqs__make-an-order_content',
                    iconComponent: 'IconMakeAnOrder',
                    nameComponent: 'FaqMakeAnOrder',
                },
                {
                    titleCard: 'faqs__my-tipti-card',
                    contentCard: 'faqs__my-tipti-card_content',
                    iconComponent: 'IconMyTiptiCard',
                    nameComponent: 'FaqMyTiptiCard',
                },
                {
                    titleCard: 'faqs__delivery-of-your-order',
                    contentCard: 'faqs__delivery-of-your-order_content',
                    iconComponent: 'IconDeliveryOfYourOrder',
                    nameComponent: 'FaqDeliveryYourOrder',
                },
                {
                    titleCard: 'faqs__promotions-discounts-rates',
                    contentCard: 'faqs__promotions-discounts-rates_content',
                    iconComponent: 'IconPromotionsDiscountsRates',
                    nameComponent: 'FaqPromotionsDiscounts',
                },
                {
                    titleCard: 'faqs__profile-and-settings',
                    contentCard: 'faqs__profile-and-settings_content',
                    iconComponent: 'IconProfileAndSettings',
                    nameComponent: 'FaqProfileSettings',
                },
                {
                    titleCard: 'faqs__contact_us',
                    contentCard: 'faqs__contact_us_content',
                    iconComponent: 'IconContactUs',
                    nameComponent: 'FaqContactUs',
                },
                {
                    titleCard: 'faqs__your-order-is-for-elderly-adult',
                    contentCard: 'faqs__your-order-is-for-elderly-adult_content',
                    iconComponent: 'IconOlderAdult',
                    nameComponent: 'FaqOrderSeniors',
                },
            ],
        };
    },
    methods: {
        openCardFaq(selectedCard) {
            this.selectedCard = selectedCard;
            this.hideMainFaq = true;
        },
        backFaq() {
            this.selectedCard = null;
            this.hideMainFaq = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './faqs.scss';
</style>
