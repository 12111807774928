class ReplacementModel {
    id: number;
    name: string;
    quantity: number;
    readonly unitQuantity: number;
    readonly unit: string;
    readonly image: string;

    constructor(id: number, name: string, quantity: number, unitQuantity: number, unit: string, image: string) {
        this.id = id;
        this.name = name;
        this.quantity = quantity;
        this.unitQuantity = unitQuantity;
        this.unit = unit;
        this.image = image;
    }
}
const toReplacementModel = (data: object): ReplacementModel => {
    return data
        ? new ReplacementModel(
              data['id'],
              data['name'],
              data['quantity'],
              data['unit_quantity'],
              data['unit'],
              data['image'],
          )
        : null;
};
export { ReplacementModel, toReplacementModel };
