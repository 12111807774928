import { useStore } from 'vuex';
import { computed } from 'vue';
import { RemoteLocaleDto } from '@/models/internationalization/RemoteLocale';

interface TranslateInterface {
    map?: object;
    recordable?: RemoteLocaleDto;
}

export const useTranslate = () => {
    const store = useStore();

    const userLanguage = computed(() => store.getters['user/userLanguage']);

    /**
     * @deprecated exec run function
     */
    const translate = ({ map, recordable }: TranslateInterface) => {
        if (!map && !recordable) return '';

        if (map) {
            return map[userLanguage.value] ?? map[userLanguage.value.toUpperCase()];
        }

        return userLanguage.value === 'en' ? recordable?.EN : recordable?.ES;
    };

    const isRemoteLocaleDto = (arg: any): arg is RemoteLocaleDto => {
        return arg.ES !== undefined && arg.EN !== undefined;
    };

    const exec = (input: RemoteLocaleDto | object) => {
        if (!input) return '';
        if (typeof input === 'string' || typeof input === 'number') return input;
        if (!Object.keys(input).length) return '';
        if (isRemoteLocaleDto(input)) return userLanguage.value === 'en' ? input.EN : input?.ES;
        return input[userLanguage.value] ?? input[userLanguage.value.toUpperCase()] ?? '';
    };

    return {
        translate,
        exec,
    };
};
