<template>
    <base-icon :height="height" :icon-color="color" :width="width" viewBox="0 0 24 17">
        <path
            d="M18.85 16.05H4.2C1.9 16.05 0 14.2 0 11.85V4.2C0 1.9 1.85 0 4.2 0H18.85C21.15 0 23.05 1.85 23.05 4.2V11.85C23.05 14.2 21.2 16.05 18.85 16.05Z"
            fill="#FFC400"
        />
        <path d="M4 0V16" opacity="0.1" stroke="#554201" stroke-width="0.5" />
        <path d="M19.5 0V16" opacity="0.1" stroke="#554201" stroke-width="0.5" />
        <path d="M0 3.5L23 3.5" opacity="0.1" stroke="#554201" stroke-width="0.5" />
        <path d="M0 12L23 12" opacity="0.1" stroke="#554201" stroke-width="0.5" />
        <path d="M15.5 0V16" opacity="0.1" stroke="#554201" stroke-width="0.5" />
        <path d="M11.5 0V16" opacity="0.1" stroke="#554201" stroke-width="0.5" />
        <path d="M7.5 0V16" opacity="0.1" stroke="#554201" stroke-width="0.5" />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconChipCard',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#DFDFDF',
        },
    },
    computed: {
        width(): number {
            return 24 * this.size;
        },
        height(): number {
            return 17 * this.size;
        },
    },
};
</script>
