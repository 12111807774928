<template>
    <teleport ref="slider" to="body">
        <transition @after-leave="afterLeave">
            <div v-if="showBtnSheet" class="bottom-sheet" draggable="true">
                <div
                    ref="theBottomSheet"
                    v-click-outside="dismissBottomSheet"
                    class="bottom-sheet__content"
                    :style="{ 'background-color': bgColor }"
                >
                    <slot ref="theBottomSheetHeader" name="bottom-sheet-header" />
                    <section class="bottom-sheet__header">
                        <div></div>
                        <div ref="theBottomSheetHeader" class="bottom-sheet__header--line"></div>
                        <div v-if="ctaHeaderText?.length" class="bottom_action" @click="$emit('on-cta-press')">
                            <p class="bottom_action__text">{{ ctaHeaderText }}</p>
                        </div>
                        <div v-else></div>
                    </section>
                    <slot name="content" />
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script lang="ts">
import vClickOutside from '@/directives/click_outside';

export default {
    name: 'BaseBottomSheet',
    directives: {
        clickOutside: vClickOutside,
    },
    props: {
        showBtnSheet: {
            type: Boolean,
            default: true,
        },
        ctaHeaderText: {
            type: String,
            default: '',
        },
        bgColor: {
            type: String,
            default: '#f1f1f1',
        },
        canDrag: {
            type: Boolean,
            default: true,
        },
    },
    ///***  SHOULD IMPLEMENT ON-DISMISS TO HANDLE EVENT FOR CLOSE WHEN SWIPE DOWN THE BOTTOM SHEET AND CLICK OUTSIDE
    emits: ['bottom-sheet-right', 'on-dismiss', 'on-cta-press'],
    mounted() {
        if (!this.canDrag) return;
        this.dragEvents();
    },
    methods: {
        dismissBottomSheet(): void {
            this.$emit('on-dismiss');
        },
        afterLeave(): void {
            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
        dragEvents() {
            if (!this.canDrag) return;
            const indicator = this.$refs.theBottomSheetHeader;
            const container = this.$refs.theBottomSheet;
            let mouseDown = false;
            let posFinal;
            let posInitial;
            let starting = 0;

            ///*** TOUCH EVENTS *///
            indicator.ontouchstart = async (e): Promise<void> => {
                mouseDown = true;
                e = e || window.event;
                e.preventDefault();
                const clientY = e.touches[0].clientY;
                posInitial = clientY;
                starting = container.offsetTop;
            };
            indicator.ontouchend = async (e): Promise<void> => {
                mouseDown = false;
                ///*** If slides until upper of half the sheet returns to the starting position
                // else dismiss the bottom sheet *///
                if (posInitial > starting + 200) this.$emit('on-dismiss');
                else {
                    posInitial = starting;
                    container.style.top = posInitial + 'px';
                }
            };

            indicator.ontouchmove = async (e): Promise<void> => {
                e = e || window.event;
                e.preventDefault();
                if (container.offsetTop <= 0) {
                    mouseDown = false;
                }
                if (!mouseDown) {
                    return;
                }
                const clientY = e.touches[0].clientY;
                posFinal = posInitial - clientY;
                ///*** Prevent to swipe up to the original starting position, only swipe down *///
                if (starting > posInitial) return;
                posInitial = clientY;
                container.style.top = container.offsetTop - posFinal + 'px';
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import './base-bottom-sheet.scss';
</style>
