<template>
    <general-alert
        :accentHeader="true"
        :accept-button-txt="$t('cta.continue')"
        :onlyConfirmationBtn="true"
        :show-buttons="false"
        :showCloseIcon="true"
        :title="textAlertDeferredAndMixedPayment"
        padding="1rem"
        @on-close="$emit('on-continue')"
        @on-accept="$emit('on-continue')"
    >
        <template v-slot:content>
            <deferred-option />
            <base-btn border-radius="5px" @click="$emit('on-continue')">
                {{ $t('cta.continue') }}
            </base-btn>
        </template>
    </general-alert>
</template>

<script lang="ts">
import GeneralAlert from '../generalAlert/GeneralAlert.vue';
import DeferredOption from '@/components/alerts/alertDeferred/DeferredOption.vue';
import BaseBtn from '@/components/buttons/BaseBtn.vue';
import { usePayments } from '@/composables/usePayments';

export default {
    name: 'alert-deferred',
    emits: ['on-continue'],
    components: { BaseBtn, DeferredOption, GeneralAlert },
    setup() {
        const { textAlertDeferredAndMixedPayment } = usePayments();
        return {
            textAlertDeferredAndMixedPayment,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
</style>
