<template>
    <base-icon
        :height="height"
        :icon-color="color"
        :icon-name="$t('icon.information')"
        :width="width"
        viewBox="0 0 23 20"
    >
        <path
            d="M22.8031 17.8594L12.7505 0.709431C12.1949 -0.236477 10.8057 -0.236477 10.25 0.709431L0.197435 17.8594C-0.358234 18.8053 0.32376 20.0001 1.43511 20.0001H21.5402C22.6766 20.0001 23.3586 18.8052 22.8029 17.8594H22.8031ZM11.1846 5.96146C11.7908 5.81214 12.3969 6.11078 12.6747 6.65841C12.7757 6.85757 12.8011 7.08154 12.7757 7.28069C12.7251 7.90297 12.6999 8.52525 12.6747 9.17238C12.6242 10.1431 12.5484 11.1387 12.4978 12.1094C12.4726 12.4081 12.4726 12.7069 12.4473 13.0304C12.4221 13.5532 12.0179 13.9513 11.4874 13.9513C10.9823 13.9513 10.5529 13.553 10.5276 13.0552C10.4518 11.5369 10.3507 10.0185 10.275 8.50014C10.2498 8.10184 10.2244 7.67873 10.1992 7.28043C10.1995 6.68331 10.6037 6.1109 11.1846 5.96155L11.1846 5.96146ZM11.513 17.3616C10.8058 17.3616 10.2501 16.7892 10.2501 16.0922C10.2501 15.3953 10.831 14.8228 11.5382 14.8228C12.2454 14.8228 12.8011 15.3953 12.8011 16.1172C12.7759 16.7889 12.195 17.3616 11.513 17.3616V17.3616Z"
            fill="#C90808"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconTriangleInfo',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C90808',
        },
    },
    computed: {
        width(): number {
            return 23 * this.size;
        },
        height(): number {
            return 20 * this.size;
        },
    },
};
</script>
