<template>
    <base-icon
        :height="dimensions.height"
        :icon-name="$t('icon.add_to_lists')"
        :icon-color="color"
        :width="dimensions.width"
        viewBox="0 0 150 138"
    >
        <path
            d="M143.75 12.5158H6.25C3.125 12.5158 0 9.38684 0 6.25789C0 3.12895 3.125 0 6.25 0H143.75C146.875 0 150 3.12895 150 6.25789C150 9.38684 146.875 12.5158 143.75 12.5158ZM143.75 43.8052H6.25C3.125 43.8052 0 40.6763 0 37.5473C0 34.4184 3.125 31.2894 6.25 31.2894H143.75C146.875 31.2894 150 34.4184 150 37.5473C149.219 40.6763 146.875 43.8052 143.75 43.8052ZM81.25 75.0947H6.25C3.125 75.0947 0 71.9657 0 68.8368C0 64.9256 3.125 62.5789 6.25 62.5789H81.25C84.375 62.5789 87.5 65.7078 87.5 68.8368C87.5 71.9657 84.375 75.0947 81.25 75.0947ZM50 106.384H6.25C3.125 106.384 0 103.255 0 100.126C0 96.9973 3.125 93.8683 6.25 93.8683H50C53.125 93.8683 56.25 96.9973 56.25 100.126C56.25 103.255 53.125 106.384 50 106.384ZM143.75 106.384H81.25C78.125 106.384 75 103.255 75 100.126C75 96.9973 78.125 93.8683 81.25 93.8683H143.75C146.875 93.8683 150 96.9973 150 100.126C149.219 103.255 146.875 105.602 143.75 106.384ZM112.5 137.674C109.375 137.674 106.25 134.545 106.25 131.416V68.8368C106.25 65.7078 109.375 62.5789 112.5 62.5789C115.625 62.5789 118.75 65.7078 118.75 68.8368V131.416C117.969 134.545 115.625 136.891 112.5 137.674Z"
        />
    </base-icon>
</template>

<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { reactive } from 'vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#707070',
    },
});

const dimensions = reactive({
    width: 18 * props.size,
    height: 18 * props.size,
});
</script>
