import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import { useTiptiCard } from '@/composables/useTiptiCard';
import { useUser } from '@/composables/useUser';
import { logger } from '@/utils/logger';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { GiftCardsAlertCheckoutModel } from '@/models/giftCardsAlert/giftCardsAlertCheckoutModel';

const activeModal = ref(false);
const selectTiptiCard = ref(false);
const getting = ref(false);

export const useGiftCards = () => {
    const store = useStore();
    const { countrySelected } = useUser();
    const { tiptiCard } = useTiptiCard();

    const dataGiftCard = ref([]);
    const idDataGiftCard = ref<number[]>([]);
    const textHeaderColor = '#ff9012';
    const isLoading = ref(false);
    const idGiftCards = computed<number[]>(() => store.getters['giftCardAlert/idgGiftCardsToUse']);
    const paymentsToShowGiftCards = computed<GiftCardsAlertCheckoutModel[]>(
        () => store.getters['giftCardAlert/paymentsToShowGiftCard'],
    );
    const timeDelivery = computed<TimeDeliveryModel[]>(() => store.getters['checkout/timeDelivery']);

    const gifts = computed<GiftCardsAlertCheckoutModel[]>(() => store.getters['giftCardAlert/toShowGiftCards']);

    /**
     * @return boolean true if user has tipti-card or gift cards
     */
    const getInfoAlertGiftCard = async () => {
        try {
            getting.value = true;
            activeModal.value = false;
            selectTiptiCard.value = false;
            if (!timeDelivery.value.length) return false;
            dataGiftCard.value = await store.dispatch('giftCardAlert/getGifCardsAlertsCheckout');
            store.commit('giftCardAlert/toShowGiftCards', dataGiftCard.value);
            store.commit('giftCardAlert/idgGiftCardsToUse', dataGiftCard.value?.map((card) => card.id) ?? []);
            if (!tiptiCard.value?.availableAmount && !dataGiftCard.value.length) return false;
            selectTiptiCard.value = Boolean(tiptiCard.value?.availableAmount);
            activeModal.value = true;
            return true;
        } catch (error) {
            logger('ERROR_GIFT_CARD', error);
        } finally {
            getting.value = false;
        }
    };

    const useGiftCard = (id: number | number[]) => {
        store.commit('giftCardAlert/idgGiftCardsToUse', id);
    };

    return {
        selectTiptiCard,
        timeDelivery,
        dataGiftCard,
        idDataGiftCard,
        textHeaderColor,
        activeModal,
        isLoading,
        tiptiCard,
        countrySelected,
        idGiftCards,
        gifts,
        useGiftCard,
        getInfoAlertGiftCard,
        getting,
        paymentsToShowGiftCards,
    };
};
