<template>
    <base-card v-if="recipeDetail" class="flex-column" padding="0">
        <section-header :hasBack="true" :title="recipeName" @on-back="backList"> </section-header>
        <header class="recipe-information" :style="{ 'background-image': `url(${recipeDetail?.recipe?.picture})` }">
            <div class="badge-and-share">
                <badge-name :name="recipeGroup"></badge-name>
                <base-loading v-if="isLoadingShareRecipe"></base-loading>
                <button v-else class="share-button" @click="shareRecipe">
                    <icon-share-list :size="1.5"></icon-share-list>
                    <p>{{ $t('general.share') }}</p>
                </button>
            </div>
            <div class="recipe-name-and-pax">
                <div class="recipe-name--rate">
                    <rating
                        :size="0.5"
                        colorNotSelect="transparent"
                        colorSelect="#FFF"
                        stroke="#FFF"
                        strokeSelect="#FFF"
                        @stars="changeScore"
                    ></rating>
                    <h2>{{ recipeName }}</h2>
                </div>
                <div class="icon-and-pax">
                    <icon-person :size="0.2"></icon-person>
                    <p>{{ recipeDetail['peopleNumber'] }} Pax.</p>
                    <btn-out-line
                        :isLoading="isLoadingBuyProducts"
                        :txt="$t('cta.add-to-cart')"
                        bgColor="transparent"
                        @click="!isLoadingBuyProducts ? buyProductsRecipe() : null"
                    ></btn-out-line>
                </div>
            </div>
        </header>
        <base-card class="detail-recipe">
            <aside class="detail-recipe__data">
                <div class="detail-subsection">
                    <p>{{ recipeDetail['products']?.length }}</p>
                    <h3>
                        {{
                            $t("txt['recipe__ingredients-quantity']", { count: recipeDetail['products']?.length ?? 0 })
                        }}
                    </h3>
                </div>
                <div class="detail-subsection">
                    <p>{{ recipeDetail['calories'] }}</p>
                    <h3>{{ $t("general['calories']") }}</h3>
                </div>
            </aside>
            <p class="detail-recipe__total">{{ currency }} {{ totalAmount }}</p>
        </base-card>
        <div v-for="product in getProducts" class="recipe-products" :key="product.id">
            <img v-if="product?.image?.medium" class="recipe-products__img" :src="product.image.medium" alt="" />
            <header class="product-information">
                <h4>{{ product.name }}</h4>
                <p>{{ product.unitQuantity }} {{ product.unit }}</p>
            </header>
            <aside class="product-information__actions">
                <btn-circular class="actions-btn" @click="updateQuantity(product, true)">
                    <icon-decrement></icon-decrement>
                </btn-circular>
                <p>{{ product.productQuantity }}</p>
                <btn-circular class="actions-btn" @click="updateQuantity(product)">
                    <icon-increment></icon-increment>
                </btn-circular>
            </aside>
            <div class="product-information__price-column">
                <p v-if="product.hasDiscount" class="product-information__price-discount">
                    {{ currency }} {{ product.priceWithDiscount.toFixed(2) }}
                </p>
                <p
                    class="product-information__price"
                    :class="{ 'product-information__price-crossed': product.hasDiscount }"
                >
                    {{ currency }} {{ product.price.toFixed(2) }}
                </p>
            </div>
        </div>
        <aside class="rate-recipe">
            <h5>{{ $t("txt['recipe-rate']") }}</h5>
            <rating :size="0.75" @stars="changeScore"></rating>
        </aside>
        <base-card class="total-and-buy">
            <h6>TOTAL: {{ currency }} {{ totalAmount }}</h6>
            <btn-solid
                :isLoading="isLoadingBuyProducts"
                :txt="$t('cta.add-to-cart')"
                @click="!isLoadingBuyProducts ? buyProductsRecipe() : null"
            ></btn-solid>
        </base-card>
        <aside v-if="recipeDetail['ingredients']" class="recipe-details">
            <h6 class="recipe-details__title">{{ $t("txt['recipe__ingredients-quantity']", { count: 2 }) }}</h6>
            <div class="recipe-details__data" v-html="recipeDetail['ingredients']"></div>
        </aside>
        <horizontal-divider class="divider"></horizontal-divider>
        <aside v-if="recipeDetail['preparation']" class="recipe-details">
            <h6 class="recipe-details__title">{{ $t("general['preparation']") }}</h6>
            <div class="recipe-details__data" v-html="recipeDetail['preparation']"></div>
        </aside>
    </base-card>
    <snack-bar
        :body="snackBarMessage"
        :isActive="showSnackBar"
        :isFailure="isFailure"
        :isSuccess="isSuccess"
        @on-snackbar-close="closeSnackBar"
    ></snack-bar>
    <share
        v-if="isShareRecipe"
        :link="deeplink"
        :messageToShare="messageToShare"
        :shareLink="true"
        :title="recipeDetail?.recipe?.name ?? ''"
        @on-track-event="onTrackEvent"
        @on-close="isShareRecipe = false"
    ></share>
</template>

<style lang="scss" scoped>
@import './recipe-detail.scss';
</style>

<script lang="ts">
import { mapGetters } from 'vuex';
import { ProductModel } from '@/models/product/ProductModel';
import { logger } from '@/utils/logger';

import BaseCard from '@/components/cards/BaseCard.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import BadgeName from '../../components/badgeName/BadgeName.vue';
import IconShareList from '@/components/icons/IconShareList.vue';
import IconPerson from '@/components/icons/IconPerson.vue';
import HorizontalDivider from '@/components/dividers/HorizontalDivider.vue';
import BtnCircular from '@/components/buttons/BtnCircular.vue';
import IconIncrement from '@/components/icons/IconIncrement.vue';
import IconDecrement from '@/components/icons/IconDecrement.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import Rating from '@/components/rating/Rating.vue';
import BtnOutLine from '@/components/buttons/BtnOutLine.vue';
import Share from '@/components/alerts/share/Share.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import { Sections } from '@/enums/sectionsOrigin';

export default {
    name: 'recipe-detail',
    data() {
        return {
            showSnackBar: false,
            isSuccess: false,
            isFailure: false,
            snackBarMessage: '',
            isLoadingBuyProducts: false,
            isShareRecipe: false,
            isLoadingShareRecipe: false,
            linkRecipe: '',
        };
    },
    components: {
        BaseCard,
        SectionHeader,
        BadgeName,
        IconShareList,
        IconPerson,
        HorizontalDivider,
        BtnCircular,
        IconIncrement,
        IconDecrement,
        BtnSolid,
        SnackBar,
        Rating,
        BtnOutLine,
        Share,
        BaseLoading,
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            recipeDetail: 'lists/recipeDetail',
            countrySelected: 'external/countrySelected',
        }),
        messageToShare(): string {
            return this.$t('txt.recipe__share', {
                user: this.user?.name,
                recipe_name: this.recipeDetail['name'],
                deeplink: this.deeplink,
            });
        },
        deeplink(): string {
            return this.recipeDetail?.recipe?.deeplink ?? '';
        },
        recipeName(): string {
            return this.recipeDetail?.name ?? '';
        },
        recipeGroup(): string {
            return this.$route.query.group;
        },
        recipeId(): string {
            return this.recipeDetail?.recipe?.id;
        },
        currency() {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        totalAmount(): string {
            let total = 0;
            this.getProducts.forEach((product: ProductModel) => {
                total += product.price * product.productQuantity;
            });
            return total.toFixed(2);
        },
        getProducts(): Array<ProductModel> {
            return this.recipeDetail['products'];
        },
    },
    methods: {
        updateQuantity(product: ProductModel, isDecrement: boolean): number {
            if (isDecrement) {
                if (product.productQuantity === 0) {
                    return (product.productQuantity = 0);
                }
                return (product.productQuantity = product.productQuantity - 1);
            }
            return (product.productQuantity = product.productQuantity + 1);
        },
        async buyProductsRecipe(): Promise<void> {
            try {
                if (this.isLoadingBuyProducts) return;
                this.isLoadingBuyProducts = true;
                const productsToCart = [];
                this.$store.dispatch('segment/addRecipeToCart', {
                    origin: {
                        section: this.$route.query?.section ?? Sections.MY_LISTS,
                        screen: this.$route.path,
                    },
                });
                this.getProducts.forEach((product: ProductModel) => {
                    productsToCart.push({
                        stock_item_id: product.id,
                        quantity: product.productQuantity,
                        note: product.note,
                    });
                });
                await this.$store.dispatch('lists/recipeToCart', {
                    items: productsToCart,
                    id: this.recipeId,
                });
                await this.$store.dispatch('cart/fetchQuickCart');
                this.snackBarInfo({ msg: this.$t("general['products-added-cart']"), success: true, failed: false });
            } catch (err) {
                this.snackBarInfo({ msg: this.$t("general['products-not-added-cart']"), success: false, failed: true });
                logger('BUY_PRODUCTS_RECIPE', err);
            } finally {
                this.isLoadingBuyProducts = false;
            }
        },
        snackBarInfo(data: object) {
            this.showSnackBar = true;
            this.isSuccess = data['success'];
            this.isFailure = data['failed'];
            this.snackBarMessage = data['msg'];
        },
        closeSnackBar(): void {
            this.showSnackBar = false;
        },
        backList(): void {
            this.$router.go(-1);
        },
        onTrackEvent(shareAction: string): void {
            this.$store.dispatch('segment/shareRecipe', {
                origin: {
                    section: this.$route.query?.section ?? Sections.MY_LISTS,
                    screen: this.$route.path,
                },
                share_media: shareAction,
            });
        },
        changeScore(value: number): void {
            this.$store.dispatch('segment/rateRecipe', {
                origin: {
                    section: this.$route.query?.section ?? Sections.MY_LISTS,
                    screen: this.$route.path,
                },
                recipe_rate: value,
            });
        },
        async shareRecipe(): Promise<void> {
            try {
                this.isLoadingShareRecipe = true;
                this.isShareRecipe = true;
                this.linkRecipe = await this.$store.dispatch('lists/createLinkList', this.recipeDetail['id']);
            } catch (err) {
                this.isShareRecipe = false;
                logger('SHARE_RECIPE', err);
            } finally {
                this.isLoadingShareRecipe = false;
            }
        },
    },
    mounted() {
        this.$store.dispatch('segment/openRecipe', {
            origin: {
                section: this.$route.query?.section ?? Sections.MY_LISTS,
                screen: this.$route.path,
            },
        });
    },
};
</script>
