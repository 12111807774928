<template>
    <section class="products-grid" v-bind="$attrs">
        <img v-if="picture && products.length" class="banner_img" :alt="title" :src="picture" loading="lazy" />
        <card-product
            v-for="product in products"
            :key="product.id"
            :product-data="product"
            :section="section"
            :track-item-section="trackItemSection"
            :track-resource-type="trackResourceType"
            @on-gm-items="getProductForHome"
        />
        <card-load-more-products
            v-if="showLoadMoreProductsCard"
            class="products-grid__load-more-products"
            :is-loading="false"
            @click="$emit('on-click-see-more')"
        />
    </section>
    <BackgroundWithTransition
        :is-active="!!productsRecommended.length"
        content-centered
        @on-bg-click="closeRecommendationsProducts"
    >
        <TheModalContent class="modal-recommendations-items">
            <template #header>
                <SubheaderSeparator
                    :name="$t('txt.you-have-everything-you-need')"
                    :show-btn="false"
                    has-padding
                    show-btn-close
                    @on-close="closeRecommendationsProducts"
                />
                <p class="modal-recommendations-items__header">{{ $t('txt.text-recommendations-items') }}</p>
            </template>
            <RecommendationsProducts :has-bg-color="false" :items="productsRecommended" :show-header="false" />
        </TheModalContent>
    </BackgroundWithTransition>
</template>
<script lang="ts" setup>
import { ProductModel } from '@/models/product/ProductModel';

import CardProduct from '@/components/cards/cardProduct/CardProduct.vue';
import CardLoadMoreProducts from '@/components/cards/cardLoadMoreProducts/CardLoadMoreProducts.vue';
import { useStore } from 'vuex';
import type { PropType } from 'vue';
import { computed } from 'vue';
import { BannerModel } from '@/models/promotions/banners/BannerModel';
import { StoriesResultModel } from '@/models/stories/StoriesResultModel';
import { useRecommendationsProducts } from '@/modules/main-products/composables/useRecommendationsProducts';
import RecommendationsProducts from '@/modules/main-products/components/RecommendationsProducts.vue';
import TheModalContent from '@/modules/core/components/layouts/TheModalContent.vue';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';

defineOptions({
    inheritAttrs: false,
});
defineProps({
    products: {
        type: Array as PropType<Array<ProductModel>>,
        required: true,
    },
    trackItemSection: {
        /// * Used for local tracking
        type: String,
        default: undefined,
    },
    trackResourceType: {
        /// * Used for local tracking
        type: String,
        default: undefined,
    },
    section: {
        type: String,
        default: undefined,
    },
    showLoadMoreProductsCard: {
        type: Boolean,
        default: false,
    },
});
const $store = useStore();
const bannerInfo = computed<BannerModel>(() => $store.getters['promotions/bannerSelected']);
const selectedStory = computed<StoriesResultModel>(() => $store.getters['stories/selectedStory']);
const picture = computed(() => selectedStory.value?.picture ?? bannerInfo.value?.picture);
const title = computed(() => selectedStory.value?.title ?? bannerInfo.value?.title);
const { productsRecommended, closeRecommendationsProducts, getProductForHome } = useRecommendationsProducts();

defineEmits<(e: 'on-click-see-more') => void>();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 12rem), 1fr));
    grid-column-gap: $margin-lg;
    grid-row-gap: $margin-xlg;
    @include desktop {
        grid-template-columns: repeat(auto-fill, minmax(min(100%, 11rem), 1fr));
    }
    @include tablet {
        grid-template-columns: repeat(auto-fill, minmax(min(100%, 10rem), 1fr));
    }
    @include phone {
        grid-template-columns: repeat(auto-fill, minmax(min(100%, 8rem), 1fr));
        gap: $margin-sm;
    }
}

.banner_img {
    max-height: 20rem;
    max-width: 100%;
    margin: auto;
    box-sizing: border-box;
    object-fit: contain;
    padding: 1em;
    border-radius: 11px;
    grid-column-start: 1;
    grid-column-end: 3;
    @include box-shadow;
}

.products-grid__load-more-products {
    @include tablet {
        height: 85%;
    }
}

.modal-recommendations-items {
    padding: $padding-md;
    display: flex;
    flex-direction: column;
    gap: $margin-sm;
    max-width: 90vw;

    &__header {
        @include caption;
        padding: $padding-none $padding-md;
    }
}

.margin-grid {
    margin: 0 $margin-md;
}
</style>
