import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_products = _resolveComponent("search-products")!

  return (_openBlock(), _createBlock(_component_search_products, {
    class: "subheader-products-search",
    "has-autocomplete": false,
    "is-loading": _ctx.isLoading,
    "origin-section": $props.originSection,
    placeholder: $options.searchBarPlaceholder,
    "search-categories": $props.searchCategories,
    onOnSearch: $options.onSearch,
    onOnClean: $options.onClean
  }, null, 8, ["is-loading", "origin-section", "placeholder", "search-categories", "onOnSearch", "onOnClean"]))
}