<template>
    <div>
        <section-header color-title="#000" :title="$t('general.subscriptions')" />
        <div class="subscription-empty">
            <icon-subscriptions :size="3" color="#c1c1c1" />
            <p class="subscription-empty__text">
                {{ $t('txt.subscription__not_selected') }}
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import IconSubscriptions from '@/components/icons/IconSubscription.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';

export default {
    name: 'subscription-not-selected',
    components: { SectionHeader, IconSubscriptions },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/index.scss';
.subscription-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    &__text {
        @include body1;
        color: $disable;
        max-width: 25rem;
        text-align: center;
        margin: $margin-md;
    }
}
</style>
