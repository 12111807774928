<template>
    <carousel-tipti class="months-container" :length="months.length">
        <base-card
            v-for="(month, index) in months"
            :key="index"
            class="months-card"
            :class="{
                'month-disable': index > date.month(),
                'month-selected': month.isSelected,
            }"
            border-radius="3rem"
            padding="0.5rem"
            @click="selectMonth(index)"
        >
            <button>{{ month.abv }}</button>
        </base-card>
    </carousel-tipti>
</template>

<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import CarouselTipti from '@/components/carousels/components/carouselTipti/CarouselTipti.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { ref } from 'vue';
import { useDays } from '@/composables/useDays';
import { useStatistics } from '@/views/myAccount/views/profile/views/statistics/useStatistics';

const { monthlyStatistics } = useStatistics();
const { doFormat } = useDays();
const isLoading = ref(false);
const date = dayjs();

const months = ref(
    Array.from(Array(12).keys()).map((element) => {
        return {
            abv: doFormat({
                date: dayjs().month(element),
                format: 'MMM',
            }),
            isSelected: element === date.month(),
        };
    }),
);

const selectMonth = async (index: number) => {
    if (isLoading.value) return;
    if (isLoading.value || index > date.month()) return;
    isLoading.value = true;
    months.value.map((month, idx) => {
        month.isSelected = idx === index;
    });
    await monthlyStatistics(date.year(), index + 1);
    isLoading.value = false;
};

selectMonth(date.month());
</script>

<style lang="scss" scoped>
@import './carousel-months.scss';
</style>
