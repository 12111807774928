import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dacccf98"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_star_rate = _resolveComponent("icon-star-rate")!

  return (_openBlock(), _createElementBlock("div", {
    class: "rating",
    style: _normalizeStyle($options.style)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.maxStars, (star) => {
      return (_openBlock(), _createElementBlock("div", {
        key: star,
        class: _normalizeClass(["star", { 'cursor-pointer': $props.showCursor }]),
        onClick: ($event: any) => ($props.isDissable ? '' : $options.rate(star))
      }, [
        _createVNode(_component_icon_star_rate, {
          color: star <= $data.stars ? $props.colorSelect : $props.colorNotSelect,
          size: $props.size,
          stroke: star <= $data.stars ? $props.strokeSelect : $props.stroke
        }, null, 8, ["color", "size", "stroke"])
      ], 10, _hoisted_1))
    }), 128))
  ], 4))
}