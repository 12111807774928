import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.forward'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 5 8",
    iconColor: "transparent"
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M1 1L4 4L1 7",
        stroke: $props.color,
        "stroke-width": "1.78658",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height"]))
}