<template>
    <base-modal :has-header="false" :z-index="5" cover-all-screen @on-close="$emit('on-close')">
        <div class="auth-container">
            <greetings class="left-column" @on-close="$emit('on-close')" />
            <div class="right-column">
                <login v-if="isLogin" @on-switch="isLogin = false" @on-close="$emit('on-close')" />
                <register v-else @on-switch="isLogin = true" @on-close="$emit('on-close')" />
            </div>
        </div>
    </base-modal>
</template>

<script lang="ts" setup>
import Login from './components/login/Login.vue';
import Register from './components/register/Register.vue';
import Greetings from './components/greetings/Greetings.vue';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

defineOptions({
    name: 'Auth',
});

const props = defineProps({
    isRegister: {
        type: Boolean,
        default: false,
    },
});
defineEmits<(e: 'on-close') => void>();
const $store = useStore();
const isLogin = ref(props.isRegister);

watch(
    () => isLogin.value,
    (value) => {
        if (value) {
            $store.dispatch('segment/openSignIn');
            $store.dispatch('segment/registerSegmentPage', { event: 'Sign In' });
            return;
        }
        $store.dispatch('segment/openSignUp');
        $store.dispatch('segment/registerSegmentPage', { event: 'Sign Up' });
    },
    {
        immediate: true,
    },
);
</script>

<style lang="scss" scoped>
@import './auth.scss';
</style>
