import { RemoteLocaleDto } from '@/models/internationalization/RemoteLocale';
import { ProductModel } from '@/models/product/ProductModel';
import { createUUID } from '@/utils/utils';
import { ProductImageModel } from '@/models/product/ProductImageModel';
import { CategoryModel } from '@/models/product/CategoryModel';
import { ProductMediaModel } from '@/models/product/ProductMedia';
import { AttributeInProductModel } from '@/models/product/AttributeInProduct';
import { NutritionalColorModel } from '@/models/product/NutritionalColorModel';

export interface AutocompleteDo {
    text: string;
    purchases: number;
    stockitem_id: number;
    available: boolean;
    small: string;
}

export interface GlobalSearchProductModel {
    retailer_id: number;
    retailer_name: string;
    retailer_logo: string;
    product_name: string;
    match_phrase: string;
    product_picture: string;
}

export interface TiptiItemWithRate {
    item: BaseTiptiItem;
    rate: number | string;
}

export interface BaseTiptiItem {
    id: number;
    product: TiptiProduct;
    price: number;
    hours_to_delivery: number;
    unique_delivery: boolean;
    discount_card: null;
    sku: string;
    available: boolean;
    discount_multiplier: boolean;
    discount_five_by_five: boolean;
    pay: number;
    carry: number;
    gm_recommendation: boolean;
    high_demand: boolean;
    //todo [v3/client_profile/promotion_personalized/retailer/${retailerId}]
    discount_applied: boolean;
    discount: number;
    position: number;
    tax_value: number;
    discounted_price: number;
    //todo [v3/client_profile/purchase_predictions_v3]
    only_discount_item: boolean;
    margin: string;
    cost_of_delivery: string;
    price_with_discount: number;
    shopping_bag: boolean;
    mandatory_item: boolean;
    is_sampling: boolean;
}

export interface TiptiProduct {
    id: number;
    name: string;
    quantity: number;
    unit: string;
    bulk: boolean;
    unit_stock: boolean;
    group_id: number;
    attributes: AttributeInProductModel[];
    identifiers: RemoteLocaleDto[];
    image_medium: string;
    category: string;
    media: ProductMediaModel[];
    description: string;
    deep_link: string;
    groupId: number;
    ean: string;
    nutrition_colors: NutritionalColorModel;
}

const getPriceWithDiscount = (price: number, rate: number): number => {
    if (!price) return 0;
    if (price >= 0) return price - price * rate;

    return 0;
};

export const toProductModelAdapter = (input: BaseTiptiItem | TiptiItemWithRate) => {
    const data = 'rate' in input ? input.item : input;
    const rate = 'rate' in input ? +input.rate : null;
    const priceDiscounted =
        'item' in input
            ? getPriceWithDiscount(data.price, rate)
            : data.price_with_discount ?? getPriceWithDiscount(data.price, data.discount);
    const hasDiscount =
        'item' in input
            ? !!rate
            : data.discount_applied
              ? data.price
                  ? data.price !== data.price_with_discount
                  : false
              : data.discount > 0;

    return data
        ? new ProductModel(
              data.discount_card,
              data.id,
              data.discount_five_by_five,
              data.product.id,
              createUUID(data.id),
              data.product.quantity,
              data.product.name,
              data.product.ean,
              false,
              new ProductImageModel(data.product.image_medium, data.product.image_medium, data.product.image_medium),
              data.product.unit,
              '',
              null,
              '',
              data.product.description,
              '',
              data.product.quantity,
              data.price,
              priceDiscounted,
              new CategoryModel(0, data.product.category, false),
              new CategoryModel(0, data.product.category, false),
              data.product.bulk,
              data.product.unit_stock,
              hasDiscount,
              rate ?? data.discount,
              null,
              data.product.nutrition_colors?.[0] ?? null,
              data.available,
              data.high_demand,
              data.is_sampling ?? false,
              data.product.deep_link,
              data.product.groupId ?? null,
              data.product.attributes,
              data.hours_to_delivery,
              data.unique_delivery,
              +data.cost_of_delivery,
              data.product.identifiers,
              data.product.media ?? [
                  {
                      product: data.product.id,
                      resource: data.product.image_medium,
                      alt_text: data.product.name,
                      is_cover: true,
                  },
              ],
              data.shopping_bag ?? false,
              data.mandatory_item ?? false,
              null,
              null,
              null,
              +data.margin === 0,
              data.id,
              0,
              data.carry,
              data.pay,
              null,
              data.gm_recommendation,
              '',
              '',
              '',
              true,
          )
        : null;
};

export const fromAutoCompleteToProductAdapter = (input: AutocompleteDo) => {
    return input
        ? new ProductModel(
              null,
              input.stockitem_id,
              null,
              null,
              null,
              0,
              input.text,
              null,
              false,
              new ProductImageModel(input.small, input.small, input.small),
              null,
              '',
              null,
              '',
              null,
              '',
              null,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              input.available,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              [
                  {
                      product: input.stockitem_id,
                      resource: input.small,
                      alt_text: input.text,
                      is_cover: true,
                  },
              ],
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              true,
          )
        : null;
};
