import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-765e7b8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "validate-card" }
const _hoisted_2 = { class: "validate-card__mobile-title" }
const _hoisted_3 = { class: "validate-card__txt" }
const _hoisted_4 = { class: "validate-card__reimbursed validate-card__txt" }
const _hoisted_5 = { class: "validate-card__reimbursed--highlight" }
const _hoisted_6 = { class: "validate-card__txt--close validate-card__txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_shield_check = _resolveComponent("icon-shield-check")!
  const _component_triple_loading = _resolveComponent("triple-loading")!
  const _component_alert_manager = _resolveComponent("alert-manager")!

  return (_openBlock(), _createBlock(_component_alert_manager, {
    "is-loading": false,
    "show-buttons": !$data.isVerifying,
    title: $data.title,
    "only-confirmation-btn": true,
    "accept-button-text": _ctx.$t('cta.verify'),
    "show-bottom-sheet-title": false,
    "has-padding-btn": true,
    "is-disabled": $data.isDisable,
    "btn-x-padding": 1,
    "btn-y-padding": 1,
    onOnAccept: $options.onAccept,
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close'))),
    onOnDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon_shield_check, {
          class: "validate-card__img",
          size: 2
        }),
        _createElementVNode("h2", _hoisted_2, _toDisplayString($data.title), 1),
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('txt.credit-card__discount-amount', {
                            currency: $options.currency,
                            amount: $options.debitAmount,
                        })), 1),
        _createElementVNode("h3", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t('txt.credit-card__amount')) + " ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('txt.credit-card__reimbursed')), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('txt.credit-card__automatically')), 1)
        ]),
        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('general.dont_close_app')), 1),
        (!$data.isVerifying)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "validate-card__txt--benefits validate-card__txt cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-show-benefits')))
            }, _toDisplayString(_ctx.$t('txt.credit-card__benefits')), 1))
          : (_openBlock(), _createBlock(_component_triple_loading, { key: 1 }))
      ])
    ]),
    _: 1
  }, 8, ["show-buttons", "title", "accept-button-text", "is-disabled", "onOnAccept"]))
}