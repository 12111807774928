import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f43a7c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "registration" }
const _hoisted_2 = { class: "registration__header" }
const _hoisted_3 = { class: "registration__text" }
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { class: "registration-form-inputs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_header_program = _resolveComponent("header-program")!
  const _component_dynamic_form = _resolveComponent("dynamic-form")!
  const _component_btn_text = _resolveComponent("btn-text")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_modal_loading_manager = _resolveComponent("modal-loading-manager")!
  const _component_alert_pdf = _resolveComponent("alert-pdf")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      _createVNode(_component_section_header, {
        "has-back": true,
        title: $setup.programSelected.name,
        "with-borders": false,
        onOnBack: $setup.onBack
      }, null, 8, ["title", "onOnBack"]),
      _createVNode(_component_header_program),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString($setup.pageLabel.description), 1),
          ($setup.pageLabel.image?.length)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "card-program__img",
                alt: $setup.programSelected.name,
                src: $setup.pageLabel.image
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_dynamic_form, {
            invalid: $setup.invalidFiled,
            required: $setup.requiredFields,
            schema: $setup.formFields,
            value: $setup.formData,
            onFormValues: $setup.updateValues
          }, null, 8, ["invalid", "required", "schema", "value", "onFormValues"])
        ]),
        _createVNode(_component_btn_text, {
          text: $setup.locale.t('general.terms-and-conditions'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showTermsAndConditions = true))
        }, null, 8, ["text"]),
        _createVNode(_component_btn_solid, {
          txt: $setup.pageLabel.callToActionSuccess,
          onClick: $setup.generateSubscription
        }, null, 8, ["txt", "onClick"])
      ])
    ]),
    ($setup.showModal)
      ? (_openBlock(), _createBlock(_component_modal_loading_manager, {
          key: 0,
          body: $setup.loadingModalBody,
          "cta-text": $setup.locale.t('general.keep_shopping'),
          "has-close": !$setup.isLoading,
          "has-header": true,
          "is-failure": $setup.isFailure,
          "is-loading": $setup.isLoading,
          "is-success": $setup.isSuccess,
          "show-action": $setup.isFailure,
          title: $setup.modalTitle,
          onOnAction: $setup.onAction,
          onOnClose: $setup.handleCloseModal
        }, null, 8, ["body", "cta-text", "has-close", "is-failure", "is-loading", "is-success", "show-action", "title", "onOnAction", "onOnClose"]))
      : _createCommentVNode("", true),
    ($setup.showTermsAndConditions)
      ? (_openBlock(), _createBlock(_component_alert_pdf, {
          key: 1,
          "accent-header": true,
          title: $setup.locale.t('general.terms-and-conditions'),
          url: $setup.programSelected?.termsAndConditions,
          onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showTermsAndConditions = false))
        }, null, 8, ["title", "url"]))
      : _createCommentVNode("", true)
  ], 64))
}