// Dependecies
import { RouteRecordRaw } from 'vue-router';
// Routing
import Routes from '@/router/routing';
// Pages
const LayoutPage = () => import('@/views/promotions/Promotions.vue');
const Categories = () => import('@/views/promotions/views/categories/Categories.vue');
const CategoryProducts = () => import('@/views/promotions/views/categoryProducts/CategoryProducts.vue');

const routes: Array<RouteRecordRaw> = [
    {
        ...Routes.Promotions.Index,
        redirect: { name: Routes.Promotions.Categories.name },
        component: LayoutPage,
        children: [
            {
                ...Routes.Promotions.Categories,
                component: Categories,
            },
            {
                ...Routes.Promotions.CategoryProducts,
                component: CategoryProducts,
            },
        ],
    },
];
export default routes;
