class SectorModel {
    id: number;
    name: string;
    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
}
const toSectorModel = (data: object) => (data ? new SectorModel(data['id'], data['name'] ?? '') : null);

export { toSectorModel, SectorModel };
