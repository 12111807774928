import { ProductModel } from '@/models/product/ProductModel';
import { ProductWithAttributeModel } from '@/models/product/ProductWithAttributeModel';
import { ProductAttributeModel } from '@/models/product/ProductAttributeModel';
import { UnavailableProductsByHomologationModel } from '@/models/product/UnavailableProductsByHomologationModel';
import { ProductSuggested } from '@/models/product/ProductSuggestied';

export default {
    isLastPage(state): boolean {
        return state.isLastPage;
    },
    productSelected(state): ProductModel {
        return state.productSelected;
    },
    /// proxy product to use with individual and grouped product
    currentProduct(state): ProductModel {
        return state.currentProduct;
    },
    productIdActiveBlur(state): string {
        return state.productIdActiveBlur;
    },
    fetchCart(state): boolean {
        return state.fetchCart;
    },
    tmpNote(state): string {
        return state.tmpNote;
    },
    tmpFile(state): File {
        return state.tmpFile;
    },
    showAlertNotSaveNote(state): boolean {
        return state.showAlertNotSaveNote;
    },
    itemSelected(state): ProductWithAttributeModel {
        return state.itemSelected;
    },
    itemDefault(state): ProductWithAttributeModel {
        return state.itemDefault;
    },
    typeAttributes(state): Array<ProductAttributeModel> {
        return state.type_attributes;
    },
    unavailableProductsByHomologation(state): Array<UnavailableProductsByHomologationModel> {
        return state.unavailableProductsByHomologation;
    },
    suggestionsForUnAvailableProducts(state): Array<ProductSuggested> {
        return state.suggestionsForUnAvailableProducts;
    },
    deactivatedProducts(state): Array<ProductModel> {
        return state.deactivatedProducts;
    },
};
