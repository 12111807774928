<script lang="ts" setup>
import IconBackNoBorder from '@/components/icons/IconBackNoBorder.vue';
import { computed, ref } from 'vue';

const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
    totalPages: {
        type: Number,
        required: true,
    },
});

const emits = defineEmits<(e: 'update:modelValue', value: number) => void>();

const hoverRight = ref(false);
const hoverLeft = ref(false);

const page = computed({
    get: () => props.modelValue,
    set: (value) => emits('update:modelValue', value),
});

const pagesBullets = computed(() => {
    const bulletsToShow = 5;
    const temporalArrayAvailablePages = Array.from(Array(props.totalPages > 0 ? props.totalPages + 1 : 1).keys()).slice(
        1,
    );
    if (props.totalPages <= bulletsToShow) return temporalArrayAvailablePages;
    if (props.modelValue <= 3) return temporalArrayAvailablePages.slice(0, bulletsToShow);
    if (props.modelValue >= props.totalPages - 3)
        return temporalArrayAvailablePages.slice(props.totalPages - bulletsToShow, props.totalPages);
    return temporalArrayAvailablePages.slice(props.modelValue - 2, props.modelValue + 2);
});

const showGoToFirstPage = computed(() => !pagesBullets.value.some((u) => u === 1));

const showGoToLastPage = computed(() => !pagesBullets.value.some((u) => u === props.totalPages));

const changePage = (value: number) => {
    page.value = value;
    hoverRight.value = false;
    hoverLeft.value = false;
};
</script>

<template>
    <section>
        <slot />
        <aside v-if="totalPages > 1" class="tipti-paginator">
            <icon-back-no-border
                :class="{ 'cursor-pointer': page !== 1 }"
                :background-fill="page === 1 ? '#ffffff' : !hoverLeft ? '#ffffff' : '#ff9012'"
                :color="page === 1 ? '#c1c1c1' : hoverLeft ? '#ffffff' : '#ff9012'"
                :icon-name="$t('icon.backward')"
                @mouseleave="page === 1 ? null : (hoverLeft = false)"
                @mouseover="page === 1 ? null : (hoverLeft = true)"
                @click.stop="page === 1 ? null : page--"
            />
            <button v-if="showGoToFirstPage" class="tipti-paginator__item" @click.stop="page = 1">1</button>
            <button
                v-for="p in pagesBullets"
                :key="p"
                class="tipti-paginator__item"
                :class="{ 'tipti-paginator__item--selected': page === p }"
                @click.stop="changePage(p)"
            >
                {{ p }}
            </button>
            <button v-if="showGoToLastPage" class="tipti-paginator__item" @click.stop="page = totalPages">
                {{ totalPages }}
            </button>
            <icon-back-no-border
                class="tipti-paginator--next cursor-pointer"
                :class="{ 'cursor-pointer': page !== totalPages }"
                :background-fill="page === totalPages ? '#ffffff' : !hoverRight ? '#ffffff' : '#ff9012'"
                :color="page === totalPages ? '#c1c1c1' : hoverRight ? '#ffffff' : '#ff9012'"
                :icon-name="$t('icon.forward')"
                @mouseleave="page === totalPages ? null : (hoverRight = false)"
                @mouseover="page === totalPages ? null : (hoverRight = true)"
                @click.stop="page === totalPages ? null : page++"
            />
        </aside>
    </section>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as colors;
@use '@/assets/scss/padding' as pd;
@use '@/assets/scss/type-system' as font;

.tipti-paginator {
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    bottom: 0;
    gap: pd.$padding-xsm;
    backdrop-filter: blur(5px);
    padding: pd.$padding-sm pd.$padding-sm pd.$padding-md;
    margin: 0 auto;
    border-radius: 2rem;

    &__item {
        all: unset;
        @include font.subtitle2;
        width: 40px;
        height: 40px;
        background-color: colors.$white;
        border-radius: 50%;
        text-align: center;
        vertical-align: center;

        &:hover {
            background-color: colors.$orange;
            color: colors.$white;
            cursor: pointer;
        }

        &--selected {
            background-color: colors.$orange;
            color: colors.$white;
        }
    }

    &--next {
        transform: rotate(180deg);
    }
}
</style>
