<template>
    <base-icon :icon-name="$t('icon.whatsapp')" :width="width" :height="height" viewBox="0 0 24 24" :iconColor="color">
        <path
            d="M19.4567 4.12723C18.4631 3.12799 17.2811 2.33572 15.9792 1.79626C14.6774 1.2568 13.2814 0.980858 11.8722 0.984409C10.0316 0.984466 8.22199 1.45792 6.61719 2.35926C5.01239 3.26061 3.66639 4.55955 2.70852 6.13127C1.75065 7.70299 1.21312 9.49465 1.14758 11.3341C1.08205 13.1735 1.4907 14.9989 2.33428 16.6348L0.530778 21.7873C0.479353 21.9367 0.464219 22.0961 0.486628 22.2525C0.509037 22.4089 0.568345 22.5576 0.659649 22.6865C0.750952 22.8154 0.871627 22.9207 1.01169 22.9937C1.15176 23.0668 1.3072 23.1054 1.46515 23.1064C1.57806 23.1062 1.69012 23.087 1.79661 23.0494L6.94916 21.2497C9.22372 22.4227 11.8426 22.7442 14.3334 22.156C16.8241 21.5679 19.0226 20.1089 20.5322 18.0423C22.0418 15.9757 22.7631 13.4377 22.5659 10.886C22.3687 8.33442 21.2659 5.93731 19.4567 4.12723ZM18.5388 18.3821C17.1103 19.8103 15.261 20.7424 13.2631 21.0411C11.2652 21.3399 9.22422 20.9895 7.44034 20.0416C7.31886 19.977 7.18535 19.9383 7.04819 19.9277C6.91102 19.9171 6.77315 19.9349 6.6432 19.9801L1.96383 21.6201L3.60236 16.9393C3.6474 16.8093 3.66512 16.6714 3.65441 16.5342C3.6437 16.397 3.60479 16.2635 3.54012 16.1421C2.77599 14.7045 2.39694 13.0936 2.43983 11.4662C2.48273 9.83868 2.94612 8.25 3.78492 6.85467C4.62372 5.45933 5.80938 4.30485 7.22656 3.50351C8.64374 2.70217 10.2442 2.28127 11.8722 2.28173C13.7372 2.28262 15.56 2.83634 17.1104 3.87292C18.6607 4.9095 19.869 6.38241 20.5825 8.1055C21.2959 9.82858 21.4826 11.7245 21.1188 13.5536C20.7551 15.3828 19.8573 17.063 18.5388 18.3821Z"
        />
        <path
            d="M15.8639 12.6728C15.6263 12.4358 15.3044 12.3027 14.9689 12.3027C14.6333 12.3027 14.3115 12.4358 14.0739 12.6728L13.5339 13.2127C12.1992 12.4879 11.1032 11.3918 10.3784 10.0571L10.9183 9.51722C11.1553 9.27964 11.2884 8.95777 11.2884 8.62221C11.2884 8.28665 11.1553 7.96478 10.9183 7.72721L9.4635 6.2724C9.22592 6.03542 8.90405 5.90234 8.56849 5.90234C8.23292 5.90234 7.91106 6.03542 7.67348 6.2724L6.50964 7.43625C5.84298 8.10291 5.80999 9.24875 6.41591 10.6608C7.80067 13.4967 10.0929 15.7889 12.9288 17.1737C13.4978 17.4371 14.1138 17.584 14.7405 17.6056C14.9997 17.6178 15.2586 17.5776 15.5019 17.4874C15.7452 17.3972 15.9678 17.2589 16.1563 17.0807L17.3202 15.9168C17.5575 15.6795 17.6909 15.3575 17.6909 15.0218C17.6909 14.6862 17.5575 14.3642 17.3202 14.1268L15.8639 12.6728ZM15.237 16.1643C15.0465 16.3548 14.4616 16.4215 13.4372 15.9828C10.9077 14.7296 8.85925 12.6811 7.606 10.1516C7.16731 9.12877 7.23104 8.54385 7.42452 8.35187L8.56587 7.21052L9.97568 8.62034L9.30076 9.29524C9.15202 9.44331 9.05415 9.6348 9.02127 9.84209C8.98838 10.0494 9.02217 10.2618 9.11779 10.4486C9.9973 12.1817 11.4056 13.59 13.1388 14.4695C13.3256 14.5652 13.538 14.599 13.7452 14.5661C13.9525 14.5332 14.144 14.4353 14.2921 14.2866L14.967 13.6117L16.3768 15.0215L15.237 16.1643Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-whats-app',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#128c7e',
        },
    },
    computed: {
        width(): number {
            return 23 * this.size;
        },
        height(): number {
            return 23 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
