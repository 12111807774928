<template>
    <base-icon :iconName="$t('icon.delivery')" :width="width" :height="height" viewBox="0 0 27 15" :iconColor="color">
        <path
            d="M12.8129 5.65735C12.0561 5.7866 12.1344 4.83543 12.3302 4.0798C12.5161 3.36725 12.6531 3.09548 12.6922 2.94303C12.764 2.67127 13.4914 2.79389 13.4099 3.10211C13.3512 3.32416 13.2174 3.69866 13.1489 3.9638C12.9793 4.62332 13.0021 5.01439 13.0478 5.23313C13.0478 5.23313 13.175 5.59769 12.8129 5.65735Z"
            fill="#FF9012"
        />
        <path
            d="M8.68335 5.66729C8.68335 5.66729 12.4705 7.66575 14.2581 4.81555C14.2581 4.81555 14.431 4.46424 14.7246 4.21899C15.1388 3.87432 15.6444 4.34493 15.3313 4.86195C14.5582 6.13791 12.0432 7.94082 8.68335 5.66729Z"
            fill="#FF9012"
        />
        <path
            d="M11.9845 2.98941C12.0661 2.64805 11.3941 2.57844 11.2702 2.88666C11.231 2.98609 11.0027 3.5727 10.9538 3.80138C10.6634 5.14031 11.0777 5.75012 11.4561 5.67721C11.8345 5.6043 11.3158 5.57447 11.6681 3.94058C11.7138 3.72184 11.965 3.06895 11.9845 2.98941Z"
            fill="#FF9012"
        />
        <path
            d="M20.4201 0.4441H18.6977C18.2639 0.4441 17.8692 0.609808 17.5658 0.878257V0H7.56454V8.92179C7.08177 9.11732 6.73926 9.59788 6.73926 10.1547V11.2483C6.73926 11.9808 7.32315 12.5773 8.04731 12.5773H9.18249C9.18249 12.5873 9.18249 12.5972 9.18249 12.6072C9.18249 13.9262 10.2394 15 11.5376 15C12.8359 15 13.8928 13.9262 13.8928 12.6072C13.8928 12.5972 13.8928 12.5873 13.8895 12.5773H20.0286C20.0286 12.5873 20.0253 12.5972 20.0253 12.6072C20.0253 13.9262 21.0822 15 22.3805 15C23.6788 15 24.7357 13.9262 24.7357 12.6072C24.7357 12.5044 24.7259 12.4017 24.7128 12.2989C25.6816 11.835 26.3536 10.8374 26.3536 9.68073V6.81728C26.3601 5.22315 21.9923 0.4441 20.4201 0.4441ZM8.14843 0.596554H16.9786V2.19068V8.82899H8.14843V0.596554ZM11.6812 14.4034C10.5917 14.4929 9.68483 13.5716 9.76964 12.4647C9.83814 11.593 10.5329 10.8871 11.3909 10.8175C12.4804 10.728 13.3872 11.6494 13.2991 12.7563C13.2306 13.6279 12.5358 14.3338 11.6812 14.4034ZM22.5273 14.4034C21.4378 14.4929 20.531 13.5716 20.619 12.4647C20.6875 11.593 21.3823 10.8871 22.2402 10.8175C23.3298 10.728 24.2366 11.6494 24.1485 12.7563C24.08 13.6279 23.3852 14.3338 22.5273 14.4034Z"
            fill="#FF8800"
        />
        <path
            d="M11.6811 11.4671C10.9406 11.371 10.3143 12.0106 10.4089 12.763C10.4741 13.2767 10.8819 13.6909 11.3875 13.7572C12.1312 13.8533 12.7575 13.217 12.6629 12.4614C12.5944 11.9444 12.1867 11.5301 11.6811 11.4671ZM11.6419 13.1607C11.2538 13.2369 10.921 12.8955 10.9928 12.5011C11.0352 12.2824 11.2081 12.1034 11.4234 12.0637C11.8116 11.9874 12.1443 12.3288 12.0693 12.7232C12.0334 12.9386 11.8572 13.1176 11.6419 13.1607Z"
            fill="#FF8800"
        />
        <path
            d="M22.5305 11.4671C21.7868 11.371 21.1605 12.0106 21.2551 12.763C21.3203 13.2767 21.7281 13.6909 22.2337 13.7572C22.9742 13.8533 23.6005 13.2137 23.5059 12.4614C23.4472 11.9477 23.0361 11.5334 22.5305 11.4671ZM22.4914 13.1607C22.1032 13.2369 21.7705 12.8955 21.8422 12.5045C21.8847 12.2857 22.0575 12.1068 22.2728 12.0637C22.661 11.9874 22.9937 12.3288 22.922 12.7232C22.8828 12.9419 22.7067 13.1176 22.4914 13.1607Z"
            fill="#FF8800"
        />
        <path
            d="M5.66596 8.14964H2.02884C1.91141 8.14964 1.82007 8.01376 1.82007 7.84142C1.82007 7.66909 1.91467 7.5332 2.02884 7.5332H5.66596C5.78339 7.5332 5.87472 7.66909 5.87472 7.84142C5.87472 8.01376 5.78013 8.14964 5.66596 8.14964Z"
            fill="#FF8800"
        />
        <path
            d="M4.75605 10.0287H1.11893C1.0015 10.0287 0.910156 9.89279 0.910156 9.72045C0.910156 9.55143 1.00476 9.41223 1.11893 9.41223H4.75605C4.87348 9.41223 4.96482 9.54812 4.96482 9.72045C4.96482 9.88948 4.87022 10.0287 4.75605 10.0287Z"
            fill="#FF8800"
        />
        <path
            d="M3.84588 11.9045H0.208762C0.0913306 11.9045 0 11.7686 0 11.5963C0 11.4273 0.0945926 11.2881 0.208762 11.2881H3.84588C3.96331 11.2881 4.05466 11.424 4.05466 11.5963C4.05466 11.7686 3.96331 11.9045 3.84588 11.9045Z"
            fill="#FF8800"
        />
        <path
            d="M19.2033 5.97215C18.7955 5.97215 18.4661 5.69044 18.4661 5.34245V2.93304C18.4661 2.58836 18.7988 2.30334 19.2033 2.30334H20.681C20.9354 2.30334 21.0854 2.31329 21.2975 2.4094C21.7966 2.63477 22.935 3.84113 23.3297 4.45094C23.483 4.68956 24.703 5.99203 23.6363 5.96883C23.6168 5.97215 19.2033 5.97215 19.2033 5.97215Z"
            fill="white"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-delivery',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 27 * this.size;
        },
        height(): number {
            return 15 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
