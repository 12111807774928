import { ref } from 'vue';
import { BillAddress } from '@/models/BillModels';
import { useBillStore } from '@/store/useBillStore';
import { storeToRefs } from 'pinia';

const showModalBill = ref<boolean>(false);
export const useBill = () => {
    const billSelected = ref<BillAddress>();
    const isAddingBillingInfo = ref(false);
    const $billsStore = useBillStore();
    const { defaultBill, selectedBill } = storeToRefs($billsStore);
    const select = (bill: BillAddress) => {
        billSelected.value = bill;
        selectedBill.value = billSelected.value;
    };

    const saveSelection = () => {
        selectedBill.value = billSelected.value ?? defaultBill.value;
        showModalBill.value = false;
    };

    return {
        select,
        saveSelection,
        isAddingBillingInfo,
        billSelected,
        defaultBill,
        selectedBill,
        showModalBill,
    };
};
