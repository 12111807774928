import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ea6cb68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-container" }
const _hoisted_2 = {
  key: 0,
  class: "alert-header__spacer"
}
const _hoisted_3 = {
  key: 1,
  class: "alert-header__title-text"
}
const _hoisted_4 = {
  key: 3,
  class: "alert-header__spacer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_circular_close = _resolveComponent("icon-circular-close")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "alert-bg",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close-modal')))
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_base_card, {
          class: "alert-container__content",
          padding: $props.showCloseIcon ? ($props.accentHeader ? '0' : '10px') : '0'
        }, {
          default: _withCtx(() => [
            _createElementVNode("header", {
              class: _normalizeClass(["alert-header", { 'alert-header__title': $props.title.length && $props.accentHeader }])
            }, [
              ($props.title.length && $props.accentHeader)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : _createCommentVNode("", true),
              ($props.title.length && $props.accentHeader)
                ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString($props.title), 1))
                : _createCommentVNode("", true),
              ($props.showCloseIcon)
                ? (_openBlock(), _createBlock(_component_icon_circular_close, {
                    key: 2,
                    class: "cursor-pointer",
                    color: "#606060",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close')))
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_4))
            ], 2),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["padding"])
      ])
    ])
  ]))
}