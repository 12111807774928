<template>
    <div class="payment-methods" :class="{ 'payment-methods--selected': paymentsToShow?.length }">
        <template v-if="!paymentsToShow?.length">
            <div class="payment-selector">
                <icon-add-payment />
                <p class="payment-selector__payment">{{ $t('general.add_new_card') }}</p>
                <button class="payment-selector__add" @click="$emit('show-add-payment')">{{ $t('cta.add') }}</button>
            </div>
        </template>
        <template v-for="payment in paymentsToShow" v-else :key="payment">
            <div class="payment-selector">
                <template v-if="payment.name === 'payment-cards'">
                    <icon-add-payment />
                    <div class="payment-selector__card_container">
                        <aside>
                            <p class="payment-selector__card">{{ payment.alias }}</p>
                            <creatit-card-identifier :number="payment.data" v-bind="payment" />
                        </aside>
                        <p v-if="!payment.deferredInformation" class="payment-selector__current">
                            {{ $t("general['payment-current']") }}
                        </p>
                    </div>
                    <tipti-checkbox-rounded
                        v-model="isCheckActive"
                        class="payment-selector__check"
                        label="payment-method"
                        prevent-update
                    />
                </template>
                <template v-if="payment.name === 'payment-tipti-card'">
                    <icon-tipti-card :size="1.5" color="#ff9012" />
                    <section>
                        <p class="payment-selector__payment">Tipti Card</p>
                        <p class="payment-selector__tipti-card">
                            Balance:
                            <span v-currency="payment.amount" class="payment-selector__tipti-card--amount" />
                        </p>
                    </section>
                    <tipti-checkbox-rounded
                        v-model="isCheckActive"
                        class="payment-selector__check"
                        label="payment-method"
                        prevent-update
                    />
                </template>
            </div>
            <deferred-option v-if="payment.name === 'payment-cards' && deferredOptions.length" />
        </template>
    </div>
</template>

<script lang="ts" setup>
import IconAddPayment from '@/components/icons/IconAddPayment.vue';
import { usePayments } from '@/composables/usePayments';
import TiptiCheckboxRounded from '@/modules/payment-methods/components/TiptiCheckboxRounded.vue';
import { ref, watch } from 'vue';
import IconTiptiCard from '@/components/icons/IconTiptiCard.vue';
import DeferredOption from '@/components/alerts/alertDeferred/DeferredOption.vue';
import CreatitCardIdentifier from '@/modules/payment-methods/components/CreatitCardIdentifier.vue';

const { paymentsToShow, checkDeferAvailability, deferredOptions } = usePayments();

defineEmits<(e: 'show-add-payment') => void>();
watch(
    () => [...paymentsToShow.value],
    (payments, old) => {
        if (!payments.length) return;
        const newPayment = payments?.find((payment) => payment.name === 'payment-cards');
        const oldPayment = old?.find((payment) => payment.name === 'payment-cards');
        if (oldPayment?.id === newPayment?.id) return;
        checkDeferAvailability();
    },
    {
        immediate: true,
    },
);

const isCheckActive = ref(true);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.payment-methods {
    width: 100%;
    border: 1px solid $border;
    border-radius: $radius-md;

    &--selected {
        border: 1px solid $orange;
        border-radius: $radius-md;
        background-color: lighten($orange, 40);
    }
}

.payment-selector {
    display: grid;
    grid-template-columns: 3rem 1fr 4rem;
    gap: $margin-sm;
    padding: $padding-md;
    align-items: center;

    &--empty {
        border: 1px $border solid;
    }

    &__add {
        all: unset;
        @include body2;
        color: $orange;
        text-decoration: underline;
        cursor: pointer;
    }

    &__payment {
        @include body2;
        color: $orange;
    }

    &__tipti-card {
        @include body2;

        &--amount {
            font-size: 18px;
            margin-left: $margin-sm;
            border: 1px solid $orange;
            padding: $padding-xsm;
            border-radius: $radius-md;
        }
    }

    &__check {
        justify-self: center;
    }

    &__card_container {
        display: flex;
        flex-direction: column;
        gap: $margin-xsm;
    }

    &__card {
        @include body2;
        color: $orange;
    }

    &__current {
        @include caption;
    }
}

label {
    position: relative;
}

.input-span {
    cursor: pointer;
    width: 1.25em;
    height: 1.25em;
    border: 1px solid $orange;
    display: flex;
    border-radius: 50%;
    transition: all linear 0.3s;
    background-color: $white;
    justify-content: center;
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        width: 0.75em;
        border-radius: $radius-circular;
        background-color: $orange;
        height: 0.75em;
        visibility: hidden;
    }
}

input {
    display: none;

    &:checked ~ span {
        background: $white;

        &:after {
            visibility: visible;
        }
    }
}
</style>
