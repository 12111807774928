<template>
    <div
        class="search"
        :class="{
            'search--min-radius': minRadius,
            'search--autocomplete': isAutocomplete,
            'search--secondary': isAlternativeStyle,
        }"
    >
        <input
            ref="generalSearch"
            v-model.trim="query"
            :placeholder="placeholder ?? ''"
            :type="searchType"
            @blur="$emit('on-blur', $event)"
            @focus="$emit('on-focus', $event)"
            @input="$emit('on-change-value', query)"
            @keyup="$emit('on-keyup', $event)"
            @keyup.enter="onSearch(query)"
            @keyup.esc="$emit('on-keyup-esc', $event)"
        />
        <base-loading v-if="isLoading" :is-margin-auto="false" heigth=".4em" width=".4em" />
        <div
            v-else
            class="cursor-pointer"
            :class="{ 'icon-section': hasIconAccentBackground }"
            @click="!query?.length ? onSearch(query) : onClean()"
        >
            <icon-search
                v-if="!query?.length"
                class="cursor-pointer"
                :color="hasIconAccentBackground ? '#fff' : '#606060'"
                :size="0.1"
            />
            <icon-search-close
                v-else
                class="cursor-pointer"
                :color="hasIconAccentBackground ? '#fff' : '#606060'"
                :size="0.9"
            />
        </div>
    </div>
</template>

<script lang="ts">
import BaseLoading from '@/components/loading/BaseLoading.vue';
import IconSearchClose from '@/components/icons/IconSearchClose.vue';
import IconSearch from '@/components/icons/IconSearch.vue';

export default {
    name: 'Search',
    components: { BaseLoading, IconSearchClose, IconSearch },
    props: {
        searchType: {
            type: String,
            default: 'text',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
        },
        isAutocomplete: {
            type: Boolean,
            default: false,
        },
        isAlternativeStyle: {
            type: Boolean,
            default: false,
        },
        hasIconAccentBackground: {
            type: Boolean,
            default: false,
        },
        minRadius: {
            type: Boolean,
            default: true,
        },
    },
    emits: [
        'on-search',
        'on-keydown',
        'on-blur',
        'on-keyup-esc',
        'on-change-value',
        'on-focus',
        'on-keyup',
        'on-clean-query',
    ],
    data() {
        return {
            query: '',
            results: [],
            showPredictions: false,
        };
    },
    watch: {
        $route(newValue) {
            if (!newValue.params.searchOn?.length) return;
            this.query = newValue.params.searchOn;
        },
    },
    methods: {
        async onSearch(query: string): Promise<void> {
            this.query = query;
            await this.$emit('on-search', query);
            this.$refs.generalSearch?.blur();
        },
        onClean(): void {
            this.query = '';
            this.$refs.generalSearch?.blur();
            this.$emit('on-clean-query');
        },
    },
};
</script>

<style lang="scss" scoped>
@import './search.scss';
.icon-section {
    padding: $padding-sm $padding-md;
    background-color: $orange;
    border-bottom-right-radius: $radius-md;
    border-top-right-radius: $radius-md;
}
</style>
