<template>
    <hr class="separator" :data-text="text" />
</template>

<script lang="ts">
export default {
    name: 'SeparatorText',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.separator {
    overflow: visible;
    padding: 0;
    border: none;
    border-top: solid 1px $border;
    text-align: center;
    width: 100%;
    height: 0;
    &:after {
        @include mobile-type;
        font-weight: 400;
        color: $border;
        content: attr(data-text);
        display: inline-block;
        position: relative;
        top: -0.7em;
        font-size: 1.5em;
        padding: 0 0.5em;
        background: white;
    }
}
</style>
