import firebase from 'firebase/app';
import 'firebase/messaging';
import store from '@/store';
import { logger } from '@/utils/logger';
import NotificationModel from '@/models/notifications/NotificationModel';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_FIREBASE_APIKEY,
    authDomain: process.env.VUE_APP_API_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_API_FIREBASE_DATABASEURL,
    projectId: process.env.VUE_APP_API_FIREBASE_PROJECTID,
    storageBucket: process.env.VUE_APP_API_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_API_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_API_FIREBASE_APPID,
    measurementId: process.env.VUE_APP_API_FIREBASE_MEASUREMENTID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
let firebaseMessaging;

const setNotifications = permission => {
    if (permission !== 'granted') return logger('NOTIFICATION_PERMISSION_DENIED');
    logger('NOTIFICATION_PERMISSION_GRANTED.');
    if (!firebase.messaging.isSupported()) return;
    navigator.serviceWorker
        .register('firebase-messaging-sw.js', { scope: 'firebase-cloud-messaging-push-scope' })
        .then(registration => {
            firebaseMessaging = firebase.messaging();
            firebaseMessaging
                .getToken({
                    vapidKey: process.env.VUE_APP_API_FIREBASE_VAPIDKEY,
                    ServiceWorkerRegistration: registration,
                })
                .then(currentToken => {
                    if (currentToken) return store.commit('notifications/tokenFirebase', currentToken);
                    logger('NO_REGISTRATION_TOKEN_AVAILABLE');
                })
                .catch(err => {
                    logger('ERROR_WHILE_RETRIVING_TOKEN', err);
                });
            firebaseMessaging.onMessage(payload => {
                store.commit(
                    'notifications/notifications',
                    new NotificationModel(payload['data']['title'], payload['data']['body']),
                );
                store.commit('notifications/message', payload);
            });
        })
        .catch(err => {
            logger('REGISTER SERVICE WORKER', err);
        });
};

try {
    if (typeof Notification !== 'undefined') Notification.requestPermission().then(setNotifications);
} catch (err) {
    // ** Safari doesn't return a promise for requestPermissions and it
    // ** throws a TypeError. It takes a callback as the first argument
    // ** instead.
    if (err instanceof TypeError) {
        Notification.requestPermission(setNotifications);
    } else {
        logger('Unable to get permission to notify.');
    }
}

export { firebaseApp, firebaseMessaging };
