export const enum ReplacementEventTypes {
    DataReplacementTracking = 'Data replacement tracking',
    SaveReplacement = 'Save replacement',
    ViewReplacementPopUp = 'View replacement pop up',
    ViewReplacementSuggestions = 'View replacement suggestions',
    ReplacementSelected = 'Replacement selected',
    SkipReplacement = 'Skip replacement',
}

export const enum ReplacementType {
    HighDemand = 'high demand',
    OutOfStock = 'out of stock',
}
