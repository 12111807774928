import { ListSubgroupModel } from '@/models/list/ListSubgroupModel';
import { AdModel } from '@/models/ads/AdModel';
import { RecipeDetailModel } from '@/models/recipes/RecipeDetailModel';
import { ListDetailItemModel } from '@/models/list/ListDetailModel';
import { ListModel } from '@/models/list/ListModel';
import { ListCartModel } from '@/models/list/ListCartModel';
export default {
    list(state): ListModel {
        return state.list;
    },
    subgroups(state): Array<ListSubgroupModel> {
        return state.subgroups;
    },
    listDetail(state): Array<ListDetailItemModel> {
        return state.listDetail;
    },
    recipeAds(state): Array<AdModel> {
        return state.recipeAds;
    },
    recipeDetail(state): Array<RecipeDetailModel> {
        return state.recipeDetail;
    },
    isCategoryFromList(state): boolean {
        return state.isCategoryFromList;
    },
    listIdToAddFromCategories(state): number {
        return state.listIdToAddFromCategories;
    },
    isFromDeeplink(state): boolean {
        return state.isFromDeeplink;
    },
    activeOption(state): boolean {
        return state.activeOption;
    },
    localCart(state): Array<ListCartModel> {
        return state.localCart;
    },

    disableButtonContinuetoCheckout(state) {
        return state.disableButtonContinuetoCheckout;
    },
};
