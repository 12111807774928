<template>
    <base-loading v-if="isLoading && !selectPayphoneId" />
    <div v-else-if="payphoneList.length" class="phones cursor-pointer">
        <div v-for="phone in payphoneList" :key="phone">
            <base-card-flat
                class="phone"
                :class="{ 'phone-selected': isSelected(phone) }"
                :border-color="isSelected(phone) ? '#FF9012' : '#C7C7C7'"
                @click="selectPhone(phone)"
            >
                <icon-payphone :color="isSelected(phone) ? '#FF9012' : '#606060'" />
                <p class="phone__number" :class="{ 'phone-selected__number': isSelected(phone) }">{{ phone.phone }}</p>
                <base-loading v-if="isLoading && selectPayphoneId === phone.id" />
                <btn-circular
                    v-else-if="deleteNumber"
                    class="actions"
                    bg-color="#C90808"
                    @click="deletePhone(phone.id)"
                >
                    <icon-close :icon-name="$t('icon.remove')" :size="0.4" color="#FFF" />
                </btn-circular>
                <icon-check v-if="isSelected(phone)" />
            </base-card-flat>
            <default-payment-selector
                v-if="isSelected(phone)"
                :is-default="phone.resultDefault"
                :payment-channel-selected="phone.paymentChannel"
                :payment-id="phone.id"
                :payment-type="phone.paymentType"
                @on-set-default="fetchPayphones"
            />
        </div>
    </div>
    <no-content v-else :title="$t('general.payment-payphone')">
        <icon-payphone :size="7" color="#C7C7C7" />
    </no-content>
    <snack-bar
        :body="modalMessage"
        :is-active="showSnackBar"
        :is-failure="isFailure"
        :is-success="isSuccess"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { PayphoneModel } from '@/models/paymentMethods/PayphoneModel';

import IconClose from '@/components/icons/IconClose.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import BtnCircular from '@/components/buttons/BtnCircular.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import IconPayphone from '@/components/icons/IconPayphone.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import payphoneMixin from '@/mixings/payphoneMixin';
import DefaultPaymentSelector from '@/components/defaultPaymentSelector/DefaultPaymentSelector.vue';
import IconCheck from '@/components/icons/IconCheck.vue';

export default {
    name: 'PayphonePhones',
    components: {
        IconClose,
        BtnCircular,
        SnackBar,
        BaseLoading,
        BaseCardFlat,
        IconPayphone,
        NoContent,
        DefaultPaymentSelector,
        IconCheck,
    },
    mixins: [payphoneMixin],
    props: {
        deleteNumber: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            selectPayphoneId: undefined,
            showSnackBar: false,
            modalMessage: '',
            isSuccess: false,
            isFailure: false,
        };
    },
    computed: {
        ...mapGetters({
            isMixInstitutionalPayment: 'payments/isMixInstitutionalPayment',
            isMixPaymentTiptiCard: 'payments/isMixPaymentTiptiCard',
            secondPayment: 'payments/secondPayment',
            totalToPay: 'payments/totalToPay',
            payphoneList: 'payments/payphoneList',
        }),
    },
    methods: {
        isSelected(payphone: PayphoneModel): boolean {
            return this.selectPayphoneId === payphone?.id;
        },
        async deletePhone(id: number): Promise<void> {
            try {
                this.isLoading = true;
                this.selectPayphoneId = id;
                await this.$store.dispatch('payments/deleteCard', id);
                await this.fetchPayphones();
                this.showSnackBar = true;
                this.isSuccess = true;
                this.modalMessage = this.$t("txt['payphone__delete-number']");
            } catch (err) {
                this.showSnackBar = true;
                this.isFailure = true;
                this.modalMessage = this.$t("txt['payphone__delete-number-failed']");
            } finally {
                this.isLoading = false;
            }
        },
        selectPhone(payphone: PayphoneModel): void {
            this.selectPayphoneId = payphone.id;
            this.$store.commit('payments/cleanPaymentMethods');
            this.$store.commit('payments/paymentIdSelected', payphone.id);
            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-payphone',
                    amount:
                        this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment
                            ? this.secondPayment
                            : this.totalToPay,
                    data: payphone.phone,
                    analyticData: {
                        selected_number: payphone.phone,
                    },
                    objectData: ['payment-payphone', +payphone.id],
                }),
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import './phones.scss';
</style>
