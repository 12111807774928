import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_afflicted_shop_bag = _resolveComponent("icon-afflicted-shop-bag")!
  const _component_no_content = _resolveComponent("no-content")!

  return (_openBlock(), _createBlock(_component_no_content, {
    description: _ctx.$t('txt.subcategories__no_content'),
    ctaText: _ctx.$t('txt.subcategories__reload_page'),
    showBtn: true,
    onOnAction: $options.onReloadPage
  }, {
    default: _withCtx(() => [
      _createVNode(_component_icon_afflicted_shop_bag, { size: 0.75 })
    ]),
    _: 1
  }, 8, ["description", "ctaText", "onOnAction"]))
}