import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21a610ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "special-instructions-section" }
const _hoisted_2 = { class: "special-instructions-section" }
const _hoisted_3 = { class: "instructions-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_elder = _resolveComponent("icon-elder")!
  const _component_icon_info = _resolveComponent("icon-info")!
  const _component_check_tipti = _resolveComponent("check-tipti")!
  const _component_icon_automatic_replacements = _resolveComponent("icon-automatic-replacements")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_general_alert = _resolveComponent("general-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_card, {
      class: "special-instructions",
      padding: 0
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_icon_elder),
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("txt['resume__are-you-elder-people']")), 1),
          _createVNode(_component_icon_info, {
            class: "icon-info",
            color: "#FF9012",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.showInformationElder = true))
          }),
          _createVNode(_component_check_tipti, {
            onOnCheck: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isElder ? $options.setElderCondition(false) : ($data.showTermsElder = true))),
            isChecked: _ctx.isElder
          }, null, 8, ["isChecked"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_icon_automatic_replacements),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("txt['resume__automatic-replacements-requested']")), 1)
          ]),
          _createVNode(_component_icon_info, {
            class: "icon-info",
            color: "#FF9012",
            size: 0.8,
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($data.showInformationAutomaticReplacements = true))
          }),
          _createVNode(_component_check_tipti, {
            onOnCheck: _cache[3] || (_cache[3] = ($event: any) => (
                    _ctx.isAutomaticReplacements
                        ? $options.setAutomaticReplacementsCondition(false)
                        : ($data.showTermsAutomaticReplacements = true)
                )),
            isChecked: _ctx.isAutomaticReplacements
          }, null, 8, ["isChecked"])
        ])
      ]),
      _: 1
    }),
    ($data.showTermsElder || $data.showInformationElder)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 0,
          accentHeader: true,
          showCloseIcon: true,
          title: 
            $data.showInformationElder ? `${_ctx.$t('cta.information')} ${_ctx.$t('general.elder-people')}` : _ctx.$t('general.elder-people')
        ,
          message: 
            $data.showInformationElder ? _ctx.$t('txt.resume__elder-information') : _ctx.$t('txt.resume__elder-terms-and-conditions')
        ,
          declineButtonTxt: _ctx.$t('cta.cancel'),
          onlyConfirmationBtn: _ctx.isElder,
          showButtons: $data.showTermsElder,
          onOnDecline: _cache[4] || (_cache[4] = ($event: any) => ($data.showTermsElder = false)),
          onOnClose: _cache[5] || (_cache[5] = ($event: any) => ($data.showInformationElder ? ($data.showInformationElder = false) : ($data.showTermsElder = false))),
          onOnAccept: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isElder ? ($data.showTermsElder = false) : $options.acceptElderTerms(true)))
        }, null, 8, ["title", "message", "declineButtonTxt", "onlyConfirmationBtn", "showButtons"]))
      : _createCommentVNode("", true),
    ($data.showTermsAutomaticReplacements || $data.showInformationAutomaticReplacements)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 1,
          accentHeader: true,
          showCloseIcon: true,
          title: 
            $data.showInformationAutomaticReplacements
                ? `${_ctx.$t('cta.information')} ${_ctx.$t('txt.resume__alert-replacements-title')}`
                : _ctx.$t('txt.resume__alert-replacements-title')
        ,
          message: 
            $data.showInformationAutomaticReplacements
                ? _ctx.$t('txt.resume__alert-replacements-information')
                : _ctx.$t('txt.resume__alert-replacements-description')
        ,
          declineButtonTxt: _ctx.$t('cta.cancel'),
          onlyConfirmationBtn: _ctx.isAutomaticReplacements,
          showButtons: $data.showTermsAutomaticReplacements,
          onOnDecline: _cache[7] || (_cache[7] = ($event: any) => ($data.showTermsAutomaticReplacements = false)),
          onOnClose: _cache[8] || (_cache[8] = ($event: any) => (
            $data.showInformationAutomaticReplacements
                ? ($data.showInformationAutomaticReplacements = false)
                : ($data.showTermsAutomaticReplacements = false)
        )),
          onOnAccept: _cache[9] || (_cache[9] = ($event: any) => (
            _ctx.isAutomaticReplacements ? ($data.showTermsAutomaticReplacements = false) : $options.acceptAutomaticReplacements(true)
        ))
        }, null, 8, ["title", "message", "declineButtonTxt", "onlyConfirmationBtn", "showButtons"]))
      : _createCommentVNode("", true)
  ], 64))
}