<template>
    <base-card class="stats" padding="0">
        <section-header
            :color-title="isMobile ? '#707070' : '#FF9012'"
            :has-back="isMobile"
            :title="title(isMobile)"
            @on-back="$router.back"
        />
        <section class="stats-container">
            <h2 v-if="isMobile" class="stats-container__title">{{ title(!isMobile) }}</h2>
            <carousel-months />
            <stats-graphics />
            <main-info v-if="!isMobile" />
            <store-stats />
        </section>
    </base-card>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs';
import BaseCard from '@/components/cards/BaseCard.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import CarouselMonths from './components/carouselMonths/CarouselMonths.vue';
import MainInfo from './components/mainInfo/MainInfo.vue';
import StatsGraphics from './components/statsGraphics/StatsGraphics.vue';
import { computed } from 'vue';
import { useApp } from '@/composables/useApp';
import StoreStats from '@/views/myAccount/views/profile/views/statistics/components/storeStats/StoreStats.vue';

const { isMobile, $t } = useApp();

const title = computed(() => (value: boolean) => {
    return value ? $t('txt.statistics__my-experience-tipti') : `${$t('general.statistics')} ${dayjs().year()}`;
});
</script>

<style lang="scss" scoped>
@import './statistics.scss';
</style>
