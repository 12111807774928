import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-965e7548"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "skeleton-grid",
  ref: "skeletonGrid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_subheader = _resolveComponent("skeleton-subheader")!
  const _component_base_bone = _resolveComponent("base-bone")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.showSubheader)
      ? (_openBlock(), _createBlock(_component_skeleton_subheader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (index) => {
        return (_openBlock(), _createBlock(_component_base_bone, {
          class: "cards",
          key: index
        }))
      }), 128))
    ], 512)
  ], 64))
}