<template>
    <section
        v-if="isVisible"
        class="default-payment-selector"
        :class="{ 'default-payment-selector__disable': isLoading }"
    >
        <base-loading v-if="isLoading" class="default-payment--loading" heigth="0.5rem" width="0.5rem" />
        <p class="default-payment-selector__text">{{ $t('txt.default_payment') }}</p>
        <toggle v-model="isActive" @on-changed="toggleAction" />
    </section>
</template>

<script lang="ts">
import { DefaultPaymentTypeModel } from '@/models/paymentMethods/DefaultPaymentTypeModel';
import { mapActions, mapGetters } from 'vuex';
import Toggle from '../toggle/Toggle.vue';
import { logger } from '@/utils/logger';
import BaseLoading from '../loading/BaseLoading.vue';

export default {
    name: 'DefaultPaymentSelector',
    components: { Toggle, BaseLoading },
    props: {
        isDefault: {
            default: false,
            type: Boolean,
        },
        paymentType: {
            required: true,
        },
        paymentId: {
            type: Number,
            default: undefined,
        },
        clientInstitutionId: {
            type: Number,
            default: undefined,
        },
        paymentChannelSelected: {
            required: true,
        },
    },
    emits: ['on-set-default'],
    data() {
        return {
            isVisible: false,
            isActive: false,
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            defaultPaymentTypes: 'payments/defaultPaymentsTypesAvailable',
            defaultPayment: 'payments/defaultPaymentSelected',
        }),
    },
    methods: {
        ...mapActions({
            fetchDefaultPayment: 'payments/defaultPayment',
        }),
        isPaymentAvailable(): boolean {
            const _isPaymentChannelAvailable = this.defaultPaymentTypes.some(
                (paymentType: DefaultPaymentTypeModel) => paymentType.channelPayment == this.paymentChannelSelected,
            );
            return _isPaymentChannelAvailable;
        },
        async selectPaymentAsDefault(): Promise<void> {
            try {
                this.isLoading = true;
                ///*Check defaultPayment status
                await this.fetchDefaultPayment();

                const _defaultPaymentType = await this.paymentType;
                const _defaultPaymentData = {
                    paymentTypeId: _defaultPaymentType,
                    institutionalId: this.clientInstitutionId,
                    paymentId: this.paymentId,
                };
                if (!this.defaultPayment) {
                    await this.$store.dispatch('payments/setDefaultPayment', _defaultPaymentData);
                } else {
                    await this.$store.dispatch('payments/updateDefaultPayment', _defaultPaymentData);
                }
                this.isActive = true;
                await this.$emit('on-set-default', true);
            } catch (err) {
                logger('SELECT_DEFAULT_PAYMENT', err);
                this.isActive = false;
            } finally {
                this.isLoading = false;
            }
        },
        async deleteDefaultPayment(): Promise<void> {
            try {
                this.isLoading = true;
                const _defaultPaymentSelectedId = await this.fetchDefaultPayment();
                await this.$store.dispatch('payments/deleteDefaultPayment', _defaultPaymentSelectedId);
                await this.$emit('on-set-default', false);
                this.isActive = false;
            } catch (err) {
                logger('DELETE_DEFAULT_PAYMENT', err);
            } finally {
                this.isLoading = false;
            }
        },
        async toggleAction(): Promise<void> {
            this.isActive ? await this.deleteDefaultPayment() : await this.selectPaymentAsDefault();
        },
    },
    mounted() {
        this.isVisible = this.isPaymentAvailable();
        this.isActive = this.isDefault;
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.default-payment-selector {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: $margin-sm;
    gap: $margin-sm;

    &__disable {
        pointer-events: none;
        cursor: pointer;
    }

    &__text {
        @include button;
        font-weight: 400;
    }
}

.default-payment--loading {
    margin: unset;
}
</style>
