<template>
    <section class="sub-section-gender card-check-gender" :class="{ 'card-check-select': valueSelected }">
        <label class="sub-section-gender__label">{{ option.name }}</label>
        <tipti-checkbox-rounded v-model="valueSelected" :label="option.name" bg-round="#F5F5F5" />
    </section>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed } from 'vue';
import TiptiCheckboxRounded from '@/modules/payment-methods/components/TiptiCheckboxRounded.vue';

const isCheck = defineModel({
    default: '',
    type: String,
});
const props = defineProps({
    option: {
        type: Object as PropType<{ value: string; name: string }>,
        required: true,
    },
});

const valueSelected = computed({
    get: () => isCheck.value === props.option.value,
    set: () => {
        isCheck.value = props.option.value;
    },
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;

.card-check-gender {
    @include style.box-shadow;
    gap: style.$margin-md;
    box-sizing: content-box;
    border-radius: 8px;
    height: 1.5rem;
}

.card-check-select {
    background: style.$degrade;
    border: 2px solid style.$orange;
}

.sub-section-gender {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: style.$margin-md;
    margin-left: style.$margin-md;
    padding: style.$padding-sm;
    @include style.tablet {
        margin: style.$margin-none;
    }

    &__label {
        @include style.body2;
        @include style.tablet {
            @include style.caption;
        }
    }
}
</style>
