import { useStore } from 'vuex';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';

import { computed } from 'vue';
import { MaxiProgramModel } from '@/models/maxiDollars/MaxiProgram';
import { MaxiBalanceModel } from '@/models/maxiDollars/MaxiBalance';
import { TiptiCardHistoryExchangeModel } from '@/models/historyExchange/TiptiCardHistoryExchange';
import { useTiptiCard } from '@/composables/useTiptiCard';

export const useMaxiDollars = () => {
    const $store = useStore();
    const { fetchTiptiCard } = useTiptiCard();
    const pageSize = 10;

    const maxiCardSelected = computed<PaymentMethodModel>(() => $store.getters['maxiDollars/maxiCardSelected']);
    const history = computed<Array<TiptiCardHistoryExchangeModel>>(() => $store.getters['maxiDollars/history']);
    const balance = computed<MaxiBalanceModel>(() => $store.getters['maxiDollars/balance']);
    const program = computed<MaxiProgramModel>(() => $store.getters['maxiDollars/program']);

    const initialMaxiConverter = (): void => {
        $store.dispatch('segment/maxiConversionInit', {
            initial_amount: balance.value?.current_balance,
        });
    };

    const resultMaxiConverter = (amount: number, isSuccess: boolean): void => {
        $store.dispatch('segment/maxiConversionResult', {
            amount_updated: amount,
            success: isSuccess,
        });
    };

    const fetchMaxiCard = async (): Promise<void> => {
        await $store.dispatch('maxiDollars/getMaxiCards');
    };

    const fetchHistory = async (page = 0): Promise<boolean> => {
        if (!page) $store.commit('maxiDollars/history', undefined);
        return $store.dispatch('maxiDollars/getHistory', { page, size: pageSize });
    };

    const fetchBalance = async (): Promise<void> => {
        $store.commit('maxiDollars/balance', undefined);
        await $store.dispatch('maxiDollars/getBalance');
    };

    const setMaxiCard = (card: PaymentMethodModel) => {
        $store.commit('maxiDollars/maxiCardSelected', card);
    };

    const sendConversion = async (amount: number): Promise<boolean> => {
        let isSuccess: boolean;
        try {
            isSuccess = await $store.dispatch('maxiDollars/sendConversion', amount);
            if (isSuccess) fetchTiptiCard();
        } catch (_) {
            isSuccess = false;
        } finally {
            resultMaxiConverter(amount, isSuccess);
        }
        return isSuccess;
    };

    return {
        maxiCardSelected,
        history,
        balance,
        program,
        initialMaxiConverter,
        resultMaxiConverter,
        fetchMaxiCard,
        setMaxiCard,
        fetchHistory,
        fetchBalance,
        sendConversion,
    };
};
