<template>
    <aside class="cart-prices">
        <p
            :class="[hasPriceWithDiscount ? 'cart-prices__discount' : 'cart-prices__price']"
            v-currency="finalPriceWithDiscount"
        />
        <p
            v-if="hasPriceWithDiscount"
            class="cart-prices__price cart-prices--crossed"
            v-currency="finalPriceWithoutDiscount"
        />
        <p class="cart-prices__unitary">
            {{ product.productQuantity }} x
            {{ unitaryPrice }}
        </p>
        <img
            v-if="!isExtraItemCart && product.discountCard"
            class="cart-card-discount"
            :src="product.discountCard?.icon"
            alt=""
        />
    </aside>
</template>
<script lang="ts">
import { ProductModel } from '@/models/product/ProductModel';
import { useCartType } from '@/modules/core/composable/useCartType';

export default {
    name: 'product-cart-price',
    props: {
        product: {
            type: [ProductModel, Object],
            required: true,
        },
    },
    setup() {
        const { isExtraItemCart } = useCartType();

        return {
            isExtraItemCart,
        };
    },
    computed: {
        hasPriceWithDiscount(): boolean {
            return this.isExtraItemCart
                ? this.product.hasDiscount
                : this.product.finalPriceWithDiscount < this.product.finalPrice;
        },
        finalPriceWithDiscount(): number {
            return this.isExtraItemCart
                ? +this.product.priceWithDiscount * +this.product.productQuantity
                : this.product.finalPriceWithDiscount;
        },
        finalPriceWithoutDiscount(): number {
            return this.isExtraItemCart ? +this.product.price * this.product.productQuantity : this.product.finalPrice;
        },
        unitaryPrice(): number {
            return this.product.hasDiscount
                ? this.product.priceWithDiscount.toFixed(2)
                : this.product.price?.toFixed(2);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.cart-prices {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-self: flex-start;
    &__discount {
        @include body2;
        font-weight: 600;
        color: $magenta;
    }
    &__price {
        @include body2;
    }
    &__unitary {
        @include overline;
    }
    &--crossed {
        text-decoration: line-through;
    }
}
.cart-card-discount {
    align-self: flex-end;
    max-height: 2rem;
    max-width: 2rem;
    object-fit: contain;
}
</style>
