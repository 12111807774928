import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50e3d1e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-main__detail" }
const _hoisted_2 = { class: "product-detail-data__name" }
const _hoisted_3 = {
  key: 0,
  class: "product-detail-data__unit"
}
const _hoisted_4 = {
  key: 1,
  class: "product-detail-data__unit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_note = _resolveComponent("icon-note")!
  const _component_cambrella = _resolveComponent("cambrella")!
  const _component_note_modal = _resolveComponent("note-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString($props.product.name), 1),
      ($props.product.groupId && _ctx.attributeName.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.attributeName) + ": " + _toDisplayString(_ctx.attributeValue), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($props.product.unitQuantity) + " " + _toDisplayString($props.product.unit), 1)),
      ($props.product.shoppingBag)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "product-detail-data__link",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.changeCambrellas && $options.changeCambrellas(...args)))
          }, _toDisplayString($options.cambrellaSubscriptionText), 1))
        : _createCommentVNode("", true),
      ($options.canShowNote)
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            class: "product-detail-data__action",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.showNoteModal && $options.showNoteModal(...args)), ["stop"]))
          }, [
            _createVNode(_component_icon_note, {
              color: $options.getColorNote,
              "icon-name": $options.getColorNote === '#707070' ? _ctx.$t('icon.save_note') : _ctx.$t('icon.show_note'),
              size: 0.8
            }, null, 8, ["color", "icon-name"]),
            _createElementVNode("span", {
              style: _normalizeStyle({ color: $options.getColorNote })
            }, _toDisplayString($options.hasNote ? _ctx.$t("txt['note__edit-note']") : _ctx.$t("cta['add-note']")), 5)
          ]))
        : _createCommentVNode("", true)
    ]),
    ($props.product.shoppingBag && $data.showCambrellasModal)
      ? (_openBlock(), _createBlock(_component_cambrella, {
          key: 0,
          onOnClose: $options.closeCambrellas
        }, null, 8, ["onOnClose"]))
      : _createCommentVNode("", true),
    ($data.showNote)
      ? (_openBlock(), _createBlock(_component_note_modal, {
          key: 1,
          "initial-product": $props.product,
          "shopper-note-placeholder": $props.retailer?.shopperNotePlaceholder,
          "shopper-note-title": $props.retailer?.shopperNoteTitle,
          onOnClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showNote = false))
        }, null, 8, ["initial-product", "shopper-note-placeholder", "shopper-note-title"]))
      : _createCommentVNode("", true)
  ], 64))
}