import { ref } from 'vue';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { logger } from '@/utils/logger';
import {
    OverallStatisticsModel,
    StatisticsCurrentMonthCategoryModel,
} from '@/models/statistics/OverallStatisticsModel';
import { RequestState } from '@/modules/core/types/WorkingState';

const overAllStatistics = ref<OverallStatisticsModel>();
const selectedMonth = ref<StatisticsCurrentMonthCategoryModel[]>();
const selectedMonthIndex = ref(0);
const stateForOverallStatistics = ref<RequestState>('NONE');
const stateForMonthlyStatistics = ref<RequestState>('NONE');
export const useStatistics = () => {
    const getOverallStatistics = async (): Promise<void> => {
        try {
            stateForOverallStatistics.value = 'LOADING';
            const { data } = await axiosInstance().get<{
                data: OverallStatisticsModel;
            }>('v2/client/statistics/overall');
            overAllStatistics.value = data?.data;
            stateForOverallStatistics.value = data?.data?.overall_effective_orders ? 'NONE' : 'EMPTY';
        } catch (err) {
            logger('OVERALL_STATISTICS', err);
            overAllStatistics.value = undefined;
            stateForOverallStatistics.value = 'EMPTY';
        }
    };

    const monthlyStatistics = async (year: number, month: number): Promise<void> => {
        try {
            stateForMonthlyStatistics.value = 'LOADING';
            selectedMonthIndex.value = month;
            const { data } = await axiosInstance().get(`v2/client/statistics/monthly`, {
                params: {
                    year,
                    month,
                },
            });
            selectedMonth.value = data.data ?? [];
        } catch (err) {
            selectedMonth.value = [];
            logger('FILTER_STATISTICS', err);
        } finally {
            stateForMonthlyStatistics.value = 'NONE';
        }
    };
    return {
        stateForOverallStatistics,
        getOverallStatistics,
        monthlyStatistics,
        selectedMonth,
        overAllStatistics,
        selectedMonthIndex,
        stateForMonthlyStatistics,
    };
};
