import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04ce9fb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "surplus-message" }
const _hoisted_2 = { class: "surplus-message--important" }
const _hoisted_3 = { class: "surplus-message--important" }
const _hoisted_4 = { class: "surplus-message--important" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_afflicted_shop_bag = _resolveComponent("icon-afflicted-shop-bag")!
  const _component_general_alert = _resolveComponent("general-alert")!
  const _component_snack_bar = _resolveComponent("snack-bar")!
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.isPageNotifications && _ctx.pendingPayment)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 0,
          "decline-button-txt": _ctx.$t('cta.reject'),
          "is-disabled": $data.isLoading,
          "is-loading": $data.isLoading,
          title: _ctx.$t('txt.notifications__have-pending-payments'),
          onOnClose: $options.closeAlert,
          onOnAccept: _cache[0] || (_cache[0] = ($event: any) => ($data.isLoading ? null : $options.setPayment('APPROVED'))),
          onOnDecline: _cache[1] || (_cache[1] = ($event: any) => ($data.isLoading ? null : $options.setPayment('REJECTED_BY_CLIENT')))
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_icon_afflicted_shop_bag, { size: 0.5 })
          ]),
          content: _withCtx(() => [
            _createElementVNode("p", _hoisted_1, [
              _createTextVNode(_toDisplayString(_ctx.$t('txt.surplus__your-shopper')) + " ", 1),
              _withDirectives(_createElementVNode("span", _hoisted_2, null, 512), [
                [_directive_currency, _ctx.pendingPayment?.amountReceivable]
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('txt.surplus__order-of')) + " ", 1),
              _createElementVNode("span", _hoisted_3, "#" + _toDisplayString(_ctx.pendingPayment?.order), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('txt.surplus__to-charge-from')) + " ", 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString($options.paymentMethod), 1)
            ])
          ]),
          _: 1
        }, 8, ["decline-button-txt", "is-disabled", "is-loading", "title", "onOnClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_snack_bar, {
      body: $data.snackBarMessage,
      "is-active": $data.showSnackBar,
      "is-failure": !$data.isSuccess,
      "is-success": $data.isSuccess,
      onOnSnackbarClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showSnackBar = false))
    }, null, 8, ["body", "is-active", "is-failure", "is-success"])
  ], 64))
}