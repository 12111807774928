import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21cd6314"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "benefit" }
const _hoisted_2 = { class: "benefit-header" }
const _hoisted_3 = { class: "benefit-header__name" }
const _hoisted_4 = { class: "product-benefit" }
const _hoisted_5 = { class: "benefit-detail" }
const _hoisted_6 = { class: "benefit-detail__special" }
const _hoisted_7 = { class: "benefit-detail__new-price" }
const _hoisted_8 = { class: "benefit-detail__before" }
const _hoisted_9 = { class: "benefit-detail__old-price" }
const _hoisted_10 = {
  key: 0,
  class: "benefit-footer"
}
const _hoisted_11 = { class: "benefit-footer__free-product" }
const _hoisted_12 = { class: "benefit-footer__btn-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_delete = _resolveComponent("icon-delete")!
  const _component_base_image = _resolveComponent("base-image")!
  const _component_icon_cart = _resolveComponent("icon-cart")!
  const _component_base_btn = _resolveComponent("base-btn")!
  const _component_modal_manager = _resolveComponent("modal-manager")!
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createBlock(_component_modal_manager, {
    "has-header": false,
    "has-padding-btn": true,
    "is-disabled": false,
    "is-loading": false,
    "is-modal": true,
    "show-bottom-sheet-title": false,
    "show-buttons": false,
    padding: ".5rem",
    onOnClose: $options.onClose,
    onOnDismiss: $options.onClose
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("div", {
            class: "benefit-header__helper",
            style: _normalizeStyle($options.style)
          }, null, 4),
          _createElementVNode("h2", _hoisted_3, _toDisplayString($props.goal.giveProduct.name), 1),
          _createVNode(_component_icon_delete, {
            class: "benefit-header__icon cursor-pointer",
            ref: "iconDelete",
            "icon-name": _ctx.$t('icon.close'),
            size: 0.6,
            color: "red",
            onClick: $options.onClose
          }, null, 8, ["icon-name", "onClick"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_base_image, {
            class: "product-benefit__img",
            "aspect-ratio": $options.isMobile ? 0.7 : 1,
            "has-padding": false,
            source: $props.goal.giveProduct.image,
            alternativeText: ""
          }, null, 8, ["aspect-ratio", "source"]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h3", _hoisted_6, _toDisplayString($options.isMobile ? _ctx.$t('general.special-price') : _ctx.$t('general.special-price').toUpperCase()), 1),
            _withDirectives(_createElementVNode("h4", _hoisted_7, null, 512), [
              [_directive_currency, $props.goal.giveProduct?.current_price]
            ]),
            _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('general.before')) + ":", 1),
            _withDirectives(_createElementVNode("h4", _hoisted_9, null, 512), [
              [_directive_currency, $props.goal.giveProduct?.original_price]
            ])
          ])
        ]),
        ($options.isBrandingProduct)
          ? (_openBlock(), _createElementBlock("footer", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('txt.goal__gift_product')), 1)
            ]))
          : (_openBlock(), _createElementBlock("footer", {
              key: 1,
              class: _normalizeClass(["benefit-footer benefit-footer__actions", { 'benefit-footer--mobile': $options.isMobile }])
            }, [
              _createVNode(_component_base_btn, {
                class: "benefit-footer__btn",
                "is-disabled": $data.isLoading,
                "is-loading": $data.isLoading,
                bgColor: "orange",
                color: "#FF9012",
                onClick: $options.onAccept
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_cart, {
                    size: 0.6,
                    color: "white"
                  }),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('cta.add_product')), 1)
                ]),
                _: 1
              }, 8, ["is-disabled", "is-loading", "onClick"]),
              _createElementVNode("span", {
                class: "benefit-footer__text cursor-pointer",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClose && $options.onClose(...args)))
              }, _toDisplayString(_ctx.$t('general.no-thanks')), 1)
            ], 2))
      ])
    ]),
    _: 1
  }, 8, ["onOnClose", "onOnDismiss"]))
}