<template>
    <section class="settings-tile">
        <div class="settings-tile__title" :class="{ 'settings-tile__title--no-border': removeBottomBorder }">
            <div class="settings-tile__title__wrapper">
                <slot name="title-icon"></slot>
                {{ title }}
            </div>
        </div>
        <div class="settings-tile__content">
            <slot name="content"></slot>
        </div>
    </section>
</template>

<script lang="ts">
export default {
    name: 'settings-tile',
    props: {
        title: {
            type: String,
            required: true,
        },
        removeBottomBorder: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import './settings-tile.scss';
</style>
