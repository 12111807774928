import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    informativeBanner: undefined,
    bannerSelected: undefined,
    bannerGoal: undefined,
    promotionsInRetailers: undefined,
    isLastPage: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
