interface CountryCodeModelInterface {
    name: string;
    alpha_code: string;
    flag: string;
    country_code: string;
    regex: string;
}

export class CountryCodeModel implements CountryCodeModelInterface {
    readonly name: string;
    readonly alpha_code: string;
    readonly flag: string;
    readonly country_code: string;
    readonly regex: string;

    constructor(data?: CountryCodeModelInterface) {
        if (data) {
            const keys = Object.keys(data);
            for (const property of keys) {
                if (Object.prototype.hasOwnProperty.call(data, property)) {
                    this[property] = data[property];
                }
            }
        }
    }

    static fromJson(data: object) {
        if (!data) return null;
        return new CountryCodeModel(data as CountryCodeModelInterface);
    }
}
