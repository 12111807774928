<template>
    <base-card-flat
        class="program-type cursor-pointer"
        :style="{
            'background-color': program.mainColor,
        }"
        border-width="0"
        :border-color="program.mainColor"
    >
        <h3 class="program-type__name">{{ program.description }}</h3>
        <div class="program-footer">
            <img class="program-footer__img" :src="program.logo" :alt="program.name" />
        </div>
        <div class="program-state">
            <div
                class="program-state__circle"
                :class="{
                    'program-state__circle--active': program.subscription !== null,
                    'program-state__circle--inactive': program.subscription === null,
                }"
            />
            <p
                class="program-state__text"
                :class="{
                    'program-state__text--active': program.subscription !== null,
                    'program-state__text--inactive': program.subscription === null,
                }"
            >
                {{ programState.toUpperCase() }}
            </p>
        </div>
    </base-card-flat>
</template>
<script lang="ts">
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';

export default {
    name: 'program-type',
    components: { BaseCardFlat },
    props: {
        program: {
            type: Object,
            required: true,
        },
    },
    computed: {
        programState(): string {
            return this.program.subscription
                ? this.program.subscription?.subscriberIdentifier
                : this.$t('general.inactive');
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'program-type';
</style>
