<template>
    <base-icon
        :icon-name="$t('icon.forward')"
        :width="width"
        :height="height"
        viewBox="0 0 5 8"
        iconColor="transparent"
    >
        <path d="M1 1L4 4L1 7" :stroke="color" stroke-width="1.78658" stroke-linecap="round" stroke-linejoin="round" />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-right-arrow',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
        iconName: {
            type: String,
        },
    },
    computed: {
        width(): number {
            return 5 * this.size;
        },
        height(): number {
            return 8 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
