import { computed, reactive, Ref, ref, watch } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useRoute } from 'vue-router';

export const useProductInformation = (quantity: Ref) => {
    const store = useStore();
    const i18n = useI18n();
    const route = useRoute();
    const { isExtraItemCart } = useCartType();

    const productSelected = computed<ProductModel>({
        get: () => store.getters['product/productSelected'],
        set: (value) => store.commit('product/productSelected', value),
    });

    const currentProduct = computed<ProductModel>({
        get: () => store.getters['product/currentProduct'],
        set: (value) => store.commit('product/currentProduct', value),
    });

    const product = computed(() => {
        if (productSelected.value?.groupId) return currentProduct.value;
        return productSelected.value;
    });

    const extraItems = computed(() => store.state.extraItems.products);
    const products = computed(() => store.state.cart.products);

    const sectionShowed = ref('MAIN');
    const showSnackBarForList = reactive({
        showSnackBar: false,
        isSuccess: false,
        isFailure: false,
        message: '',
    });
    const isFavorite = ref<boolean>(false);
    const hideImage = computed<boolean>(() =>
        ['ADD_NOTE', 'ADD_TO_LIST', 'NUTRITIONAL_INFO'].includes(sectionShowed.value),
    );

    const checkFavorite = async () => {
        isFavorite.value = await store.dispatch('product/checkFavorite', product.value.id);
    };

    const setAsFavorite = async (): Promise<void> => {
        isFavorite.value = await store.dispatch('product/setProductFavorite', {
            id: product.value.id,
            quantity: quantity.value,
            isFavorite: isFavorite.value,
        });
        store.dispatch('segment/productFavorite', {
            product: product.value,
            screen: route.path,
            state: isFavorite.value,
            isfromIcon: true,
        });
    };

    const onActionClick = (action: string): void => {
        if (action === 'SET_AS_FAVORITE') {
            void setAsFavorite();
            return;
        }
        const section = ['ADD_NOTE', 'ADD_TO_LIST', 'NUTRITIONAL_INFO', 'ZOOM'].includes(action);
        sectionShowed.value = section ? action : 'MAIN';
    };

    const onSuccess = async (listName?: string, listId?: string): Promise<void> => {
        showSnackBarForList.showSnackBar = true;
        showSnackBarForList.isSuccess = true;
        showSnackBarForList.isFailure = false;
        showSnackBarForList.message = listName
            ? i18n.t('txt.product_list__added', { name: listName })
            : i18n.t("txt['cart__list-added-successfully']");
        await checkFavorite();
        if (listName === 'Favoritos') {
            store.dispatch('segment/productFavorite', {
                product: currentProduct.value,
                screen: route.path,
                state: true,
                isfromIcon: false,
            });
        }
    };

    const onFailure = (): void => {
        showSnackBarForList.showSnackBar = true;
        showSnackBarForList.isSuccess = false;
        showSnackBarForList.isFailure = true;
        showSnackBarForList.message = i18n.t('alerts.list_product_error');
    };

    const productFromServer = computed(() =>
        isExtraItemCart.value
            ? extraItems.value?.find((internalProduct: ProductModel) => {
                  return internalProduct.id === product.value.id;
              })
            : products.value?.find((internalProduct: ProductModel) => {
                  return internalProduct.id === product.value.id;
              }),
    );

    const findProductNote = (): void => {
        product.value.note = productFromServer.value?.note ?? '';
        product.value.noteToShopperPhoto = productFromServer.value?.noteToShopperPhoto;
        product.value.noteToShopperPhotoFile = productFromServer.value?.noteToShopperPhotoFile;
    };

    watch(product, (newProduct, oldProduct) => {
        if (!newProduct) return;
        if (newProduct.id === oldProduct.id) return;
        isFavorite.value = false;
        findProductNote();
        checkFavorite();
    });

    return {
        sectionShowed,
        isFavorite,
        product,
        hideImage,
        onActionClick,
        checkFavorite,
        setAsFavorite,
        showSnackBarForList,
        findProductNote,
        onSuccess,
        onFailure,
        currentProduct,
        productSelected,
    };
};
