<template>
    <teleport to="body">
        <transition :duration="550" name="nested">
            <base-background v-if="localShow" :center="contentCentered" @click="$emit('on-bg-click')">
                <div class="inner" :aria-hidden="!localShow" :aria-modal="localShow" v-bind="$attrs" @click.stop>
                    <slot />
                </div>
            </base-background>
        </transition>
    </teleport>
</template>

<script lang="ts">
import BaseBackground from '@/modules/core/components/background/BaseBackground.vue';

export default {
    name: 'BackgroundWithTransition',
    components: { BaseBackground },
    inheritAttrs: false,
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        zIndex: {
            type: Number,
            default: 5,
        },
        contentCentered: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-bg-click'],
    data() {
        return {
            localShow: false,
        };
    },
    watch: {
        isActive: {
            handler: function (newValue: boolean): void {
                this.localShow = newValue;
            },
            immediate: true,
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.nested-enter-active,
.nested-leave-active {
    transition: opacity 0.3s ease-in-out;
}

/* delay leave of parent element */
.nested-leave-active {
    transition-delay: 0.3s;
}

.nested-enter-from,
.nested-leave-to {
    opacity: 0;
}

.nested-enter-active .inner,
.nested-leave-active .inner {
    transition: opacity 0.2s ease-in-out;
}

/* delay enter of nested element */
.nested-enter-active .inner {
    transition-delay: 0.2s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
    opacity: 0.01;
}

.full-tablet {
    @include tablet {
        width: 100%;
        height: 100%;
    }
}

.full-mobile {
    @include tablet {
        width: 100vw;
        height: 100vh;
    }
}
</style>
