<template>
    <base-card ref="buttonsCountryRef" class="phone-options" border-radius="0 0 5px 5px " padding="0">
        <div class="search-country">
            <input
                v-model="searchQuery"
                class="search-country__input"
                :placeholder="$t('general.searching_country')"
                name="search"
                type="text"
                @input.stop
            />
            <icon-search :size="0.1" />
        </div>
        <div class="phone-options__countries">
            <country-tile
                v-for="country in countryCodeFiltered"
                :key="country"
                :country="country"
                @click.prevent="setCountryCodeSelected(country)"
            />
        </div>
    </base-card>
</template>

<script lang="ts" setup>
import { CountryCodeModel } from '@/models/country/CountryCodeModel';
import CountryTile from '@/components/inputs/primary/phone/CountryTile.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import IconSearch from '@/components/icons/IconSearch.vue';
import { computed, ref } from 'vue';
import { useApp } from '@/composables/useApp';
import { onClickOutside } from '@vueuse/core';

const { $store } = useApp();
const $emit = defineEmits<(e: 'on-close') => void>();
const searchQuery = ref('');
const buttonsCountryRef = ref<HTMLElement>();

onClickOutside(buttonsCountryRef, () => {
    close();
});

const countryCodes = computed<CountryCodeModel[]>(() => $store.getters['external/countryCodes']);

const countryCodeFiltered = computed(() => {
    if (!searchQuery.value.length) return countryCodes.value;
    return (
        countryCodes.value?.filter((country) =>
            country.name.toUpperCase().startsWith(searchQuery.value.toUpperCase()),
        ) ?? []
    );
});

const setCountryCodeSelected = (selection: CountryCodeModel): void => {
    $store.commit('external/countryCodeSelected', selection);
    close();
};
const close = (): void => {
    $emit('on-close');
};
</script>
<style lang="scss" scoped>
@import '../input-primary.scss';

.phone-options {
    @include box-shadow();
    position: absolute;
    top: 45%;
    width: 80%;
    display: inline-flex;
    flex-direction: column;
    background-color: $white;
    z-index: 1;
    max-height: 15em;

    &__countries {
        overflow-y: auto;
    }
}

.search-country {
    display: flex;
    align-items: center;
    max-height: 2.5rem;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.5rem;
    background-color: $white;
    border: 1px solid lighten($txt, 50%);
    transition: all ease-in-out 200ms;

    &__input {
        width: 100%;
        border: none;
        background-color: transparent;
        resize: none;
        outline: none;

        &:focus {
            border-bottom: none;
        }
    }
}

::-webkit-input-placeholder {
    @include body2;
    color: $disable;
    opacity: 1;
    transition: inherit;
}
</style>
