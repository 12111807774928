<template>
    <div class="container-scroller">
        <slot name="content" />
    </div>
</template>
<script lang="ts">
export default {
    name: 'container-scroller',
    props: {
        isScroller: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            observer: null,
            items: new Set(),
        };
    },
    created() {
        this.observer = new IntersectionObserver(this.onElementObserved, {
            root: this.$el,
            rootMargin: '24px',
            threshold: [0.5, 1.0],
        });
        this.$emit('on-observer', this.observer);
    },
    methods: {
        onElementObserved(entries) {
            const items = new Set();
            if (this.isScroller) return;
            entries.forEach(({ target, isIntersecting, intersectionRatio }) => {
                if (!isIntersecting) return;
                if (isIntersecting && intersectionRatio == 1.0) {
                    const name = target.getAttribute('data-name');
                    const id = target.getAttribute('data-id');
                    items.add({
                        id: id,
                        name: name,
                    });
                }
            });
            if (items.size > 0 && !this.isScroller) {
                this.$emit('on-observer-change', items);
            }
        },
    },
    unmounted() {
        this.observer.unobserve(this.$el);
    },
};
</script>
