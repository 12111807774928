import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["stroke"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.close'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 12 12",
    iconColor: "transparent",
    fill: "none"
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M10.433 1.00317L5.43457 6.00158L10.433 11",
        stroke: $props.color,
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }, null, 8, _hoisted_1),
      _createElementVNode("path", {
        d: "M0.999637 10.9968L5.99805 5.99842L0.999637 1.00001",
        stroke: $props.color,
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height"]))
}