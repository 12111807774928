import NotificationModel from '@/models/notifications/NotificationModel';
import { PaymentSurplusModel } from '@/models/notifications/PaymentSurplusModel';

export default {
    notifications(state): Array<NotificationModel> {
        return state.notifications;
    },
    pendingPayment(state): PaymentSurplusModel {
        return state.pendingPayment;
    },
    tokenFirebase(state) {
        return state.tokenFirebase;
    },
    message(state) {
        return state.message;
    },
};
