import { renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cfe6d95"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-hidden", "aria-modal"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_background = _resolveComponent("base-background")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      duration: 550,
      name: "nested"
    }, {
      default: _withCtx(() => [
        ($data.localShow)
          ? (_openBlock(), _createBlock(_component_base_background, {
              key: 0,
              center: $props.contentCentered,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-bg-click')))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _mergeProps({
                  class: "inner",
                  "aria-hidden": !$data.localShow,
                  "aria-modal": $data.localShow
                }, _ctx.$attrs, {
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                }), [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ], 16, _hoisted_1)
              ]),
              _: 3
            }, 8, ["center"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}