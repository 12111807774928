<template>
    <BackgroundWithTransition :is-active="canShowThisAlert" content-centered @on-bg-click="closeAlert">
        <TheModalContent>
            <template #header>
                <BaseHeader
                    class="alert-purchase__header"
                    :text="alertTitle"
                    with-default-close
                    @on-close="closeAlert"
                />
            </template>
            <template #default>
                <div class="alert-purchase alert-purchase__rejected">
                    <h2 class="alert-purchase__title">{{ $t('alerts.payment_rejected') }}</h2>
                    <div class="banner-payment-method">
                        <img
                            class="banner-payment-method__img"
                            :alt="order?.payment_rejected_context?.type"
                            :src="order?.payment_rejected_context?.icon"
                        />
                        <CreatitCardIdentifier
                            v-if="order?.payment_rejected_context?.type === 'card'"
                            :dot-color="'magenta'"
                            :number="order?.payment_rejected_context?.payment_method_number"
                            :text-color="'magenta'"
                        />
                        <p v-else class="banner-payment-method__number">
                            {{ paymentIdentifier }}
                        </p>
                        <IconTriangleInfo />
                    </div>
                    <section class="alert-purchase__main-body">
                        <section class="alert-purchase__reason-body">
                            <span class="alert-purchase__reason">{{ $t('general.reason') }}:</span>
                            <p class="alert-purchase__legend">
                                {{ order?.messageToShow ?? '' }}
                            </p>
                        </section>
                        <p class="alert-purchase__payments message-option">
                            {{ $t('general.other_payment_option') }}
                        </p>
                        <PaymentMethodSelector @show-add-payment="redirectToResentPayment(true)" />
                        <SeparatorText class="option-separator" :text="$t('general.or')" />
                        <button class="more-payments" @click="redirectToResentPayment(false)">
                            <span class="more-payments__text">{{ $t('general.more_payments') }}</span>
                            <IconArrow :size="0.7" color="#FF9012" />
                        </button>
                        <div class="payment-rejected__btn">
                            <ButtonWithPrice
                                :is-disabled="isDisableButton"
                                :is-loading="isLoading"
                                :price="order?.final_cost"
                                :text="$t('general.pay_order')"
                                no-wrap-text
                                @click="resentPaymentMethod"
                            />
                        </div>
                    </section>
                </div>
            </template>
        </TheModalContent>
    </BackgroundWithTransition>
</template>

<script lang="ts" setup>
import { useTiptiTAC } from '@/modules/retailers/composables/useTiptiTAC';
import ButtonWithPrice from '@/modules/core/components/ButtonWithPrice.vue';
import PaymentMethodSelector from '@/modules/payment-methods/components/PaymentMethodSelector.vue';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';
import TheModalContent from '@/modules/core/components/layouts/TheModalContent.vue';
import BaseHeader from '@/modules/core/components/headers/HeaderWithAction.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import { usePayments } from '@/composables/usePayments';
import { useResentPayment } from '@/composables/useResentPayment';
import IconTriangleInfo from '@/components/icons/IconTriangleInfo.vue';
import SeparatorText from '@/components/alerts/alertPurchase/SeparatorText.vue';
import CreatitCardIdentifier from '@/modules/payment-methods/components/CreatitCardIdentifier.vue';
import { OrderState } from '@/enums/orderState';
import { useXSelling } from '@/modules/extraItems/composables/useXSelling';
import { ResponsePurchaseModel } from '@/models/checkout/ResponsePurchaseModel';
import { computed, ref, watch } from 'vue';
import { useRoutes } from '@/composables/useRoutes';
import type { RouteRecordName } from 'vue-router';
import { useOrder } from '@/composables/useOrder';
import { ResponseOrderModel } from '@/models/checkout/ResponseOrderModel';
import { useApp } from '@/composables/useApp';
import PaymentToShowModel, { PaymentToShowName } from '@/models/checkout/PaymentToShowModel';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { appRoutesMap } from '@/router/appRoutesMap';

const { $store: store, $t, $router: router, $route: route, notifier } = useApp();
const { resentPayment } = useResentPayment();
const { alertInfo, isFetching } = useTiptiTAC();
const { tiptiCard } = useTiptiCard();
const { canShowXSellingProducts, hasResponsePurchase } = useXSelling();
const { paymentsToShow } = usePayments();
const { isPageSummaryCheckout, isHomePage, isPageAllCategories, isPageMainProducts } = useRoutes();
const { orderRejected: order } = useOrder();
const isLoading = ref(false);
const retailerInOrder = ref(0);
const alertTitle = computed<string>(() => `${$t('general.order')} # ${order.value?.orderId}`);
const isMultipleRetailers = computed(() => retailerInOrder.value > 1);
const routeName = computed<RouteRecordName>(() => route.name);

const paymentIdentifier = computed(() => {
    if (order.value?.payment_rejected_context?.type === 'card')
        return '&#9679;'.repeat(3) + order.value?.payment_rejected_context?.payment_method_number;
    return order.value?.payment_rejected_context?.payment_method_number;
});

const isDisableButton = computed(() => !paymentsToShow.value?.length || isLoading.value);

const canShowThisAlert = computed<boolean>(() => {
    if (canShowXSellingProducts.value) return false;
    if (
        hasResponsePurchase.value?.ordersData?.every(
            (order: ResponseOrderModel) => order.state !== OrderState.PAYMENT_REJECTED,
        )
    )
        return false;
    if (isFetching.value) return false;
    return !alertInfo.value && !!order.value;
});

watch(
    () => routeName.value,
    (newPath) => {
        if (!newPath) return;
        if (!canShowThisAlert.value) return;
        if (isHomePage.value || isPageMainProducts.value || isPageAllCategories.value) return;
        order.value = null;
    },
);

watch(
    () => order.value,
    (newValue) => {
        if (!newValue) return;
        if (!newValue?.suggested_payment_method?.id) return store.commit('payments/paymentsToShow', undefined);

        const toPaymentToShowName = (): PaymentToShowName => {
            if (newValue?.suggested_payment_method?.name === 'tipti_card') return 'payment-tipti-card';
            return 'payment-cards';
        };
        store.commit(
            'payments/paymentsToShow',
            new PaymentToShowModel({
                name: toPaymentToShowName(),
                id: newValue?.suggested_payment_method?.id,
                alias: newValue?.suggested_payment_method?.alias ?? '',
                data:
                    newValue?.suggested_payment_method?.last_card_digits ?? newValue?.suggested_payment_method?.ammount,
                icon: newValue?.suggested_payment_method?.brand,
                amount: +newValue?.suggested_payment_method?.ammount ?? newValue?.payment_rejected_context?.amount,
                objectData:
                    toPaymentToShowName() === 'payment-tipti-card'
                        ? ['payment-tipti-card', +tiptiCard.value.id]
                        : ['payment-cards', newValue.suggested_payment_method?.id],
            }),
        );
    },
);

const closeAlert = () => {
    if (isLoading.value) return;
    order.value = null;
    if (isPageSummaryCheckout.value) {
        store.dispatch('cart/fetchCart');
        redirectToOrderStateTab();
        return;
    }
    clearData();
};
const resentPaymentMethod = async (): Promise<void> => {
    if (!paymentsToShow.value?.length || isLoading.value || isDisableButton.value) return;
    try {
        isLoading.value = true;
        await resentPayment();
        isLoading.value = false;
        if (!isPageSummaryCheckout.value || isMultipleRetailers.value) {
            closeAlert();
            await router.replace({ name: appRoutesMap.retail.home});
            return;
        }
        //*** show Order Accepted alert: required data
        const newResponsePurchase: ResponsePurchaseModel = hasResponsePurchase.value;
        newResponsePurchase.ordersData[0].state = OrderState.ASSIGNING_SHOPPER;
        clearData();
        store.commit('checkout/responsePurchase', newResponsePurchase);
        retailerInOrder.value = newResponsePurchase.ordersData?.length;

        await router.replace({ name: appRoutesMap.retail.home});
    } catch (e) {
        notifier({
            type: 'ERROR',
            body: e.message ?? $t('general.try-again'),
        });
        isLoading.value = false;
    }
};

const clearData = (): void => {
    store.commit('checkout/cleanTimeDelivery');
    store.commit('checkout/resetStockItemTimeDelivery');
    store.commit('checkout/responsePurchase', undefined);
    store.commit('payments/cleanPaymentMethods', undefined);
    order.value = null;
};

const redirectToOrderStateTab = (): void => {
    /// *** this method is called when the purchase failed with more than one retailer, the user is redirect to
    // decide which order wants to resent payment
    isLoading.value = true;
    store.commit('orders/isSelectedOrder', true);
    const orderId = order.value ? order.value?.orderId : hasResponsePurchase.value?.ordersData[0]?.id;
    if (!orderId) return;
    router
        .push({
            name: appRoutesMap.OrderMap.StateTab,
            params: {
                orderId,
            },
        })
        .finally(() => {
            clearData();
            isLoading.value = false;
        });
};

const redirectToResentPayment = async (showAddPayment = false): Promise<void> => {
    if (isLoading.value) return;
    isLoading.value = true;
    /// *** this method is called from retailers screen or main products
    await store.dispatch('orders/getOrderResume', order.value?.orderId);
    clearData();
    await router.push({ name: appRoutesMap.paymentResent.resend, query: { add: `${showAddPayment}` } });
    isLoading.value = false;
};
</script>

<style lang="scss" scoped>
@import './alert-purchase.scss';

.alert-purchase__main-body {
    display: flex;
    flex-direction: column;
    padding: $padding-md;
    gap: $margin-sm;
    align-items: center;
}

.payment-selector {
    width: 100%;
    display: grid;
    grid-template-columns: 3rem 1fr 3rem;
    gap: $margin-sm;
    padding: $padding-md;
    border: 1px $border solid;
    align-items: center;

    &__add {
        @include body2;
        color: $orange;
        text-decoration: underline;
        cursor: pointer;
    }

    &__add-payment {
        @include body2;
        color: $orange;
    }
}

.more-payments {
    all: unset;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    padding: $padding-sm;
    border-radius: $radius-md;
    border: 1px solid $orange;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
        @include body2;
        color: $orange;
    }
}

.payment-rejected__btn {
    margin: 0 auto;
}

.option-separator {
    margin: $margin-md 0;
}
</style>
