<template>
    <section class="settings-container">
        <settings-header />
        <div class="settings-container__content">
            <settings-language-tile />
            <settings-terms-and-conditions-tile />
        </div>
    </section>
</template>

<script lang="ts">
import SettingsHeader from './components/SettingsHeader.vue';
import SettingsLanguageTile from './components/SettingsLanguageTile.vue';
import SettingsTermsAndConditionsTile from './components/SettingsTermsAndConditionsTile.vue';

export default {
    name: 'settings',
    data() {
        return {
            showCloseSessionModal: false,
        };
    },
    components: {
        SettingsHeader,
        SettingsLanguageTile,
        SettingsTermsAndConditionsTile,
    },
    mounted() {
        this.$store.dispatch('segment/viewConfiguration', {
            origin: {
                screen: 'settings',
            },
        });
    },
};
</script>

<style lang="scss" scoped>
@import './settings.scss';
</style>
