<template>
    <base-card class="flex-column" padding="0">
        <section-header
            :has-back="isMobile || showBack"
            :title="$t('txt.profile-options__card-register')"
            @on-back="onBack"
        />
        <div class="credit-cards-profile__body">
            <body-credit-card :need-to-back="showBack" @show-card-added="handleShowAddForm" />
        </div>
    </base-card>
</template>
<script lang="ts">
import { isMobileBrowser } from '@/utils/utils';
import BaseCard from '@/components/cards/BaseCard.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import BodyCreditCard from '@/views/creditCards/bodyCreditCards/BodyCreditCards.vue';

export default {
    name: 'CreditCards',
    components: {
        BodyCreditCard,
        BaseCard,
        SectionHeader,
    },
    data() {
        return {
            showBack: false,
        };
    },
    computed: {
        isMobile() {
            return isMobileBrowser();
        },
    },
    methods: {
        onBack() {
            if (this.showBack) return (this.showBack = false);
            this.$router.go(-1);
        },
        handleShowAddForm(isAdding: boolean) {
            this.showBack = isAdding;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './credit-cards-profile.scss';
</style>
