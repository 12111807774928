interface AlertAttributes {
    name: string;
    value: string | number;
}

export interface BaseTiptiAlertModelInterface {
    name: string;
    title: string;
    icon: string;
    description: string;
    button_text: string;
    alert_type?: string;
    link_text?: string;
    secondary_button_text?: string;
    priority?: number;
    attributes?: Array<AlertAttributes>;
}

export class TiptiAlertModel implements BaseTiptiAlertModelInterface {
    readonly name: string;
    readonly description: string;
    readonly icon: string;
    readonly title: string;
    readonly button_text: string;
    readonly link_text: string;
    readonly secondary_button_text: string;
    readonly priority: number;
    readonly attributes: Array<AlertAttributes>;

    constructor(data: BaseTiptiAlertModelInterface) {
        this.name = data.name ?? data.alert_type;
        this.description = data.description ?? '';
        this.icon = data.icon ?? '';
        this.title = data.title ?? '';
        this.button_text = data.button_text ?? '';
        this.link_text = data.link_text ?? '';
        this.secondary_button_text = data.secondary_button_text ?? '';
        this.priority = data.priority ?? 0;
        this.attributes = data.attributes ?? [];
    }

    static fromJson(data: object): TiptiAlertModel {
        if (!data) return null;
        return new TiptiAlertModel(data as BaseTiptiAlertModelInterface);
    }
}
