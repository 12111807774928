import DeliveryAddressModel from '@/models/checkout/DeliveryAddressModel';
import { ResponsePurchaseModel } from '@/models/checkout/ResponsePurchaseModel';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';

export default {
    timeDelivery(state, timeDelivery: TimeDeliveryModel) {
        const updatedTimeDelivery = state.timeDelivery.filter(
            (time: TimeDeliveryModel) => time.retailerId != timeDelivery.retailerId,
        );
        state.timeDelivery = [...updatedTimeDelivery, timeDelivery];
    },
    removeTimeDelivery(state, retailerId: number) {
        state.timeDelivery = state.timeDelivery.filter((time: TimeDeliveryModel) => time.retailerId != retailerId);
    },
    cleanTimeDelivery(state) {
        state.timeDelivery = [];
    },
    deliveryAddress(state, timeDelivery: DeliveryAddressModel) {
        const updatedDeliveryAddress = state.deliveryAddress.filter(
            (address: DeliveryAddressModel) => address.addressId != timeDelivery.addressId,
        );
        state.deliveryAddress = [...updatedDeliveryAddress, timeDelivery];
    },
    isElder(state, elder: boolean) {
        state.isElder = elder;
    },
    isAutomaticReplacements(state, automaticReplacements: boolean) {
        state.isAutomaticReplacements = automaticReplacements;
    },
    instructions(state, instructions: string) {
        state.instructions = instructions;
    },
    phone(state, phone: string) {
        state.phone = phone;
    },
    email(state, email: string) {
        state.email = email;
    },
    responsePurchase(state, response: ResponsePurchaseModel) {
        state.responsePurchase = response;
    },
    stockItemTimeDelivery(state, productTimeDelivery: TimeDeliveryModel) {
        if (!productTimeDelivery) return;
        const updatedDeliveryAddress = state.stockItemTimeDelivery.filter(
            (time: TimeDeliveryModel) => time.stockItemId != productTimeDelivery.stockItemId,
        );
        state.stockItemTimeDelivery = [...updatedDeliveryAddress, productTimeDelivery];
    },
    resetStockItemTimeDelivery(state) {
        state.stockItemTimeDelivery = [];
    },
};
