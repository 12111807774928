<template>
    <section class="check-selector cursor-pointer" @click.stop="isCheck = !isCheck">
        <p class="check-selector__text">{{ labelName }}</p>
        <tipti-checkbox-rounded v-model="isCheck" :label="labelName" />
    </section>
</template>

<script lang="ts" setup>
import TiptiCheckboxRounded from '@/modules/payment-methods/components/TiptiCheckboxRounded.vue';

defineOptions({
    name: 'DynamicToggle',
});

const isCheck = defineModel({
    default: false,
    type: Boolean,
});

defineProps({
    labelName: {
        type: String,
        default: '',
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.check-selector {
    box-sizing: content-box;
    box-shadow: 0 1px 4px 0 #0000000d;
    border-radius: $radius-md;
    min-height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $padding-sm;
    @include tablet {
        margin: $margin-none;
    }

    &__text {
        @include body2;
        margin-left: $margin-xsm;
        @include tablet {
            @include caption;
        }
    }
}
</style>
