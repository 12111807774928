<template>
    <base-icon
        :height="dimensions.height"
        :icon-name="dimensions.label"
        :width="dimensions.width"
        icon-color="transparent"
        viewBox="0 0 12 21"
    >
        <path
            :stroke="color"
            d="M2 19L10 10.5L2 2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.84615"
        />
    </base-icon>
</template>

<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#606060',
    },
    iconName: {
        type: String,
        default: undefined,
    },
});

const { t: $t } = useI18n();

const dimensions = reactive({
    width: 12 * props.size,
    height: 21 * props.size,
    label: props.iconName ?? $t('icon.arrow'),
});
</script>
