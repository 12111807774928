<template>
    <base-icon
        :height="dimensions.height"
        :icon-color="color"
        :icon-name="$t('icon.payment_methods')"
        :width="dimensions.width"
        viewBox="0 0 35 37"
    >
        <path
            :fill="color"
            clip-rule="evenodd"
            d="M25.5559 0.000190955C25.2108 -0.0044234 24.8603 0.0745052 24.5352 0.246206L2.28999 11.9893C1.2491 12.5389 0.848338 13.8375 1.39869 14.8783L1.56626 15.1953H3.65512L28.049 2.31882L27.4256 1.13972C27.0472 0.423999 26.315 0.0106794 25.5558 0.000980546L25.5559 0.000190955ZM29.6721 5.38803L11.0746 15.205H27.6254C28.8028 15.205 30.2099 16.3814 30.2099 17.2848V17.3468L32.8605 15.994C33.9089 15.4588 34.3019 14.1458 33.7516 13.105L29.672 5.38815L29.6721 5.38803ZM2.1386 16.1904C0.961181 16.1904 0 17.1517 0 18.329V31.8614C0 33.0386 0.961243 34 2.1386 34H21.0703C20.3896 32.8233 20 31.4571 20 30C20 25.5817 23.5817 22 28 22C28.4899 22 28.9695 22.044 29.4351 22.1284V18.329C29.4351 17.1519 28.4739 16.1904 27.2965 16.1904H2.1386ZM2.52183 20.4067H6.5057C6.74394 20.4067 6.93677 20.5998 6.93677 20.8378V24.8214C6.93677 25.0594 6.7437 25.2525 6.5057 25.2525H2.52183C2.28359 25.2525 2.09051 25.0594 2.09051 24.8214V20.8378C2.09051 20.5998 2.28359 20.4067 2.52183 20.4067ZM2.5523 27.8338H14.5049C14.7453 27.8304 14.9423 28.0245 14.9423 28.2649C14.9423 28.5053 14.7456 28.6994 14.5049 28.696H2.5523C2.31186 28.6994 2.1149 28.5053 2.1149 28.2649C2.1149 28.0245 2.31162 27.8304 2.5523 27.8338ZM2.5523 29.8258H13.4243C13.6648 29.8224 13.8617 30.0164 13.8617 30.2568C13.8617 30.4973 13.665 30.6913 13.4243 30.6879H2.5523C2.31186 30.6913 2.1149 30.4973 2.1149 30.2568C2.1149 30.0164 2.31162 29.8224 2.5523 29.8258ZM35 30C35 33.866 31.866 37 28 37C24.134 37 21 33.866 21 30C21 26.134 24.134 23 28 23C31.866 23 35 26.134 35 30ZM32 28.9437H29.0756V26H26.9076V28.9437H24V31.0563H26.9076V34H29.0756V31.0563H32V28.9437Z"
            fill-rule="evenodd"
        />
    </base-icon>
</template>

<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { reactive } from 'vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
});

const dimensions = reactive({
    width: 35 * props.size,
    height: 37 * props.size,
});
</script>
