import { isMobileBrowser } from '@/utils/utils';
import { computed } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import { ProductMediaModel } from '@/models/product/ProductMedia';

export const useImage = (product?: ProductModel) => {
    const isMobile = computed(() => isMobileBrowser());

    const mouseZoomMove = (e) => {
        if (isMobile.value) return;
        const zoomer = e.currentTarget;
        const offsetX: number = e.offsetX;
        const offsetY: number = e.offsetY;
        const x = (offsetX / zoomer?.offsetWidth) * 100;
        const y = (offsetY / zoomer?.offsetHeight) * 100;
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
        zoomer.style.backgroundSize = '150%';
    };

    const mouseZoomMoveOut = (e) => {
        if (isMobile.value) return;
        const zoomer = e.currentTarget;
        zoomer.style.backgroundSize = '0%';
    };

    const displayDefaultImg = (event: Event) => {
        (event.target as HTMLImageElement).src = require('@/assets/svg/tipti_logo/ic_tipti_orange.svg');
    };

    const displayDefaultUserImg = (event: Event): void => {
        (event.target as HTMLImageElement).src = require('@/assets/svg/ic_avatar_tipti.svg');
    };

    const coverImage = computed<ProductMediaModel>(() =>
        product?.media?.length
            ? product.media.find((image) => image.is_cover) ?? product.media[0]
            : new ProductMediaModel({
                  resource: product.image?.medium ?? '',
                  is_cover: true,
                  product: product.productId,
                  alt_text: product.name,
              }),
    );

    return {
        isMobile,
        coverImage,
        displayDefaultImg,
        mouseZoomMove,
        mouseZoomMoveOut,
        displayDefaultUserImg,
    };
};
