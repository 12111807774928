<template>
    <subheader-products :has-button="false" :name="title" has-return />
    <section class="multiple-products">
        <aside v-if="showDiscountActivationProgressBar" class="banner-goal">
            <h3>{{ bannerGoal?.bannerMessage }}</h3>
            <progress-bar
                class="banner-goal__progress-bar"
                :minimum-amount="bannerGoal?.minimumAmount"
                :progress="progress ?? 0"
            />
            <p>{{ $t('txt.banner-goal__txt-footer') }}</p>
        </aside>
        <main class="promotion">
            <products-grid ref="multipleProductsScroll" :products="products" :track-resource-type="trackResourceType" />
            <triple-loading v-if="requestState === 'LOADING'" class="promotion__loading" />
            <div v-if="requestState === 'NONE'" ref="productsRef" />
        </main>
    </section>
    <snack-bar
        :body="bannerGoal?.pushMessage ?? ''"
        :is-active="
            bannerGoal?.pushMessage?.length && bannerGoal?.actualAmount >= bannerGoal?.minimumAmount && !showSnackbar
        "
        is-success
        no-background
        @on-snackbar-close="showSnackbar = true"
    />
</template>

<script lang="ts" setup>
import { logger } from '@/utils/logger';
import TripleLoading from '@/components/loading/TripleLoading.vue';
import ProductsGrid from '@/components/product/productsGrid/ProductsGrid.vue';
import ProgressBar from '@/components/progressBar/ProgressBar.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import SubheaderProducts from '@/components/subheaders/subheaderProducts/SubheaderProducts.vue';
import { useApp } from '@/composables/useApp';
import { computed, onUnmounted, ref, shallowReactive } from 'vue';
import { BannerModel } from '@/models/promotions/banners/BannerModel';
import { BannersGoalModel } from '@/models/promotions/banners/BannerGoalModel';
import { StoriesResultModel } from '@/models/stories/StoriesResultModel';
import { useProductQuantity } from '@/composables/useProductQuantity';
import { useIntersectionObserver } from '@vueuse/core';
import { RequestState } from '@/modules/core/types/WorkingState';
import { ProductModel } from '@/models/product/ProductModel';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

const { $route, $store } = useApp();

const props = defineProps({
    fetchString: {
        type: String,
        required: true,
    },
    trackResourceType: {
        type: String,
        default: undefined,
    },
});

const { fetchDiscountActivationProgressBar } = useProductQuantity(props.trackResourceType);
const { retailerSelected } = useRetailer();
const page = ref<number>(1);
const products = shallowReactive<ProductModel[]>([]);
const showSnackbar = ref<boolean>(false);
const requestState = ref<RequestState>('NONE');

const isBannerInSection = computed<boolean>(() => $store.getters['promotions/isBannerInSection']);
const bannerGoal = computed<BannersGoalModel>(() => $store.getters['promotions/bannerGoal']);
const selectedStory = computed<StoriesResultModel>(() => $store.getters['stories/selectedStory']);
const bannerInfo = computed<BannerModel>(() => $store.getters['promotions/bannerSelected']);
const id = computed<number>(() => selectedStory.value?.id ?? bannerInfo.value?.id);
const title = computed<string>(() => selectedStory.value?.title ?? bannerInfo.value?.title);
const showDiscountActivationProgressBar = computed<boolean>(
    () => $route.params.activationDiscountProgressBar === 'true',
);
const progress = computed<number>(() => {
    return bannerGoal.value?.actualAmount && bannerGoal.value?.minimumAmount
        ? bannerGoal.value.actualAmount >= bannerGoal.value.minimumAmount
            ? 100.0
            : Math.abs((bannerGoal.value?.actualAmount / bannerGoal.value?.minimumAmount) * 100)
        : 0.0;
});
const productsRef = ref<HTMLElement>();
const { stop } = useIntersectionObserver(productsRef, async ([{ isIntersecting }]) => {
    if (!isIntersecting) return;
    await fetchProducts();
});

const fetchProducts = async (): Promise<void> => {
    try {
        if (requestState.value === 'LOADING' || requestState.value === 'LOAD-ENDED' || requestState.value === 'ERROR')
            return;
        requestState.value = 'LOADING';
        const { results, next } = await $store.dispatch(props.fetchString, {
            id: id.value,
            isBannerInSection: isBannerInSection.value,
            page: page.value,
            retailerId: retailerSelected.value.id,
        });
        page.value++;
        requestState.value = next ? 'NONE' : 'LOAD-ENDED';
        products.push(...results);
    } catch (err) {
        logger('FETCH_PRODUCTS', err);
        requestState.value = 'ERROR';
    } finally {
        if (requestState.value === 'LOAD-ENDED' || requestState.value === 'ERROR') stop();
    }
};

const init = async () => {
    if (showDiscountActivationProgressBar.value) fetchDiscountActivationProgressBar();
    await fetchProducts();
    if ($route.meta.story_type) {
        $store.dispatch('segment/contentStoryViewed', {
            stories: {
                story_id: selectedStory.value.id,
                story_type: selectedStory.value.type,
                story: selectedStory.value.title,
            },
            products: products,
        });
        return;
    }

    $store.dispatch('segment/contentBannerViewed', {
        banners: {
            banner_id: id.value,
            banner_type: props.fetchString === 'promotions/bannersProducts' ? 'MULTIPLE_PRODUCTS' : 'DISCOUNT',
            banner: title.value,
        },
        products: products,
    });
};

init();

onUnmounted(() => {
    $store.commit('promotions/bannerGoal', undefined);
});
</script>

<style lang="scss" scoped>
@import './multiple-products.scss';
</style>
