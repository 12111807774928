import { AddressModel } from '@/models/address/AddressModel';
import { CountryAddressesModel } from '@/models/address/CountryAddressesModel';
import { CityModel } from '@/models/location/CityModel';

export default {
    polygons(state, polygons) {
        state.polygons = polygons ?? [];
    },
    cities(state, cities?: Array<CityModel>) {
        state.cities = cities ?? [];
    },
    addressToEdit(state, addressToEdit: AddressModel) {
        state.addressToEdit = addressToEdit ? addressToEdit : [];
    },
    locationSelected(state, location: AddressModel) {
        state.locationSelected = location;
    },
    countryAddresses(
        state: { countryAddresses: Array<CountryAddressesModel> },
        countryAddresses: Array<CountryAddressesModel>,
    ) {
        state.countryAddresses = countryAddresses;
    },
    showAddressModal(state, showAddressModal: boolean) {
        state.showAddressModal = showAddressModal;
    },
    setLocationSelectedAsDelivery(state) {
        const _location: AddressModel = { ...state.locationSelected };
        _location.isPickUpMode = false;
        _location.pickUpCenter = null;
        state.locationSelected = _location;
    },
};
