<template>
    <button
        class="base-btn"
        :disabled="isDisabled"
        :class="{
            'base-btn--full-width': fullWidth,
            'btn-disabled': isDisabled,
            'cursor-pointer': !isDisabled,
        }"
        :style="styles"
    >
        <base-loading
            v-if="isLoading"
            :color="borderColor"
            :isMarginAuto="false"
            :width="loadingSize"
            :heigth="loadingSize"
        ></base-loading>
        <slot v-else class="base-btn"></slot>
    </button>
</template>

<script lang="ts">
import BaseLoading from '@/components/loading/BaseLoading.vue';
export default {
    name: 'base-btn',
    inheritAttrs: true,
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        outline: {
            type: Boolean,
            required: false,
        },
        borderRadius: {
            type: String,
            default: '2rem',
        },
        borderColor: {
            type: String,
            default: '#FF9012',
        },
        bgColor: {
            type: String,
            default: '#FF9012',
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '#FFF',
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        loadingSize: {
            type: String,
            default: '.5rem',
        },
        padding: {
            type: String,
            default: '.5rem  1.5rem',
        },
    },
    components: {
        BaseLoading,
    },
    computed: {
        styles(): object {
            return {
                'background-color': this.bgColor,
                color: this.color,
                border: this.outline ? `1px solid ${this.borderColor}` : 'none',
                'border-radius': this.borderRadius,
                padding: this.padding,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.base-btn {
    @include button;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition: all 250ms ease-in-out;
    outline-style: none;
    box-shadow: 1px 2px 4px 1px rgba(50, 50, 0, 0.2);
    &--no-shadow {
        box-shadow: unset;
    }
}
.base-btn--full-width {
    width: 100%;
}
.btn-disabled {
    background-color: $disable !important;
}
</style>
