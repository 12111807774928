import { defineStore } from 'pinia';
import { toPickUpCenter } from '@/models/pickup/PickUpCenter';
import { useUser } from '@/composables/useUser';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';

export const usePickupStore = defineStore('pickup', {
    state: (): {
        showPickUpAlert: boolean;
        showPickUpMap: boolean;
        retailersWithPickUp: RetailerModel[];
        temporalRetailer: RetailerModel | undefined;
    } => ({
        showPickUpAlert: false,
        showPickUpMap: false,
        retailersWithPickUp: [],
        temporalRetailer: undefined,
    }),
    actions: {
        async pickUpCenters(retailerId: number): Promise<void> {
            try {
                const { userLocation } = useUser();
                const cityId = userLocation?.value.city?.id;
                if (!cityId) throw Error('NO_CITY_PROVIDED');
                const { data } = await axiosInstance().get(`v2/pickups/cities/${cityId}/retailers/${retailerId}/`);
                return data?.map((center: object) => toPickUpCenter(center)) ?? [];
            } catch (err) {
                throw Error(err);
            }
        },
        addRetailer(retailer: RetailerModel) {
            this.retailersWithPickUp = [
                ...this.retailersWithPickUp?.filter(
                    (actualRetailer: RetailerModel) => actualRetailer.id !== retailer.id,
                ),
                retailer,
            ];
        },
    },
});
