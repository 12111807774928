<template>
    <no-content
        :description="$t('txt.subcategories__no_content')"
        :ctaText="$t('txt.subcategories__reload_page')"
        :showBtn="true"
        @on-action="onReloadPage"
    >
        <icon-afflicted-shop-bag :size="0.75"></icon-afflicted-shop-bag>
    </no-content>
</template>

<script lang="ts">
import NoContent from '@/components/noContent/NoContent.vue';
import IconAfflictedShopBag from '@/components/icons/IconAfflictedShopBag.vue';
export default {
    name: 'no-content-category',
    components: { NoContent, IconAfflictedShopBag },
    methods: {
        onReloadPage(): void {
            this.$router.go();
        },
    },
};
</script>

<style scoped lang="scss"></style>
