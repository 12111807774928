import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { ProductModel } from '@/models/product/ProductModel';
import { CartRetailerModel } from '@/models/cart/CartRetailerModel';
import { CartModel } from '@/models/cart/CartModel';
import { OrderResumenModel } from '@/models/order/OrderResumen';

interface StateInterface {
    products: Array<ProductModel>;
    xSellingProducts: Array<ProductModel>;
    totalCostExtraItems: number;
    order: OrderResumenModel;
    retailer: CartRetailerModel;
    cart: CartModel;
    showLimitItemsByOrder: boolean;
}
const state: StateInterface = {
    products: [],
    xSellingProducts: [],
    totalCostExtraItems: 0,
    order: null,
    retailer: new CartRetailerModel(),
    cart: CartModel.getInstance(),
    showLimitItemsByOrder: false,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
