<template>
    <list-subcategories-body
        class="banner-categories-list"
        :subcategories="categories"
        @on-select-subcategory="onSelectCategory"
    />
    <subheader-products :has-button="false" :name="bannerInfo?.title ?? ''" has-return />
    <no-content-category v-if="!categories.length && requestState === 'LOAD-ENDED'" />
    <template v-for="(category, index) in categories" v-else :key="category.id">
        <section v-if="index === 0" :key="category?.id" class="picture-container__display-flex">
            <base-card v-if="bannerInfo?.picture" class="card__center">
                <img :alt="bannerInfo?.title" :src="bannerInfo.picture" loading="lazy" />
            </base-card>
            <section>
                <CarouselForProducts
                    :items="category?.products ?? []"
                    :section="`HOME_${category?.name.toUpperCase()}`"
                    :state="requestState"
                    :subheader-name="category.name"
                    has-subheader-padding
                    @on-click-subheader="goToSeeMore(category)"
                />
            </section>
        </section>
        <section v-else>
            <CarouselForProducts
                :items="category?.products ?? []"
                :section="`HOME_${category?.name.toUpperCase()}`"
                :state="requestState"
                :subheader-name="category.name"
                has-subheader-padding
                @on-click-subheader="goToSeeMore(category)"
            />
        </section>
    </template>
</template>

<script lang="ts" setup>
import { useBanners } from '@/modules/banners/composables/useBanners';
import SubheaderProducts from '@/components/subheaders/subheaderProducts/SubheaderProducts.vue';
import NoContentCategory from '@/components/category/noContentCategory/NoContentCategory.vue';
import { CategoryModel } from '@/models/product/CategoryModel';
import ListSubcategoriesBody from '@/components/category/listSubcategories/ListSubcategoriesBody.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useApp } from '@/composables/useApp';
import { computed, ref } from 'vue';
import CarouselForProducts from '@/components/carousels/CarouselForProducts.vue';
import { RequestState } from '@/modules/core/types/WorkingState';

const { fetchCategories, categories } = useBanners();
const { $router, $store, $route } = useApp();
const bannerInfo = computed(() => $store.getters['promotions/bannerSelected']);
const requestState = ref<RequestState>('FIRST_LOAD');

const init = async () => {
    await fetchCategories(bannerInfo.value?.id ?? $route.params.id, 1);
    requestState.value = 'LOAD-ENDED';
};

init();

const goToSeeMore = async (category: CategoryModel) => {
    if (!category) return;
    $store.commit('categories/setCategorySelected', category);
    $store.commit('categories/setSubcategorySelected', category);
    await $router.push({
        name: appRoutesMap.categoryProductsInBanner,
        params: {
            name: category.name,
        },
    });
};
const onSelectCategory = async (category: CategoryModel): Promise<void> => {
    if (!category) return;
    $store.commit('categories/setCategorySelected', category);
    await $router.push({
        name: appRoutesMap.categoryProductsInBanner,
        params: {
            name: category.name,
        },
    });
};
</script>

<style lang="scss" scoped>
@import './banners-with-subcategories.scss';
</style>
