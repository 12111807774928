<template>
    <base-card padding="0" class="promotion-layout">
        <div class="promotion-layout__header">
            <slot name="header"></slot>
        </div>
        <div class="promotion-layout__content">
            <slot name="content"></slot>
        </div>
    </base-card>
</template>

<script>
import BaseCard from '@/components/cards/BaseCard.vue';

export default {
    name: 'promotion-layout',
    components: {
        BaseCard,
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.promotion-layout {
    position: sticky;
    top: 8rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.promotion-layout__header {
    @include headline5;
    @include box-shadow;
    border-radius: $radius-sm $radius-sm 0 0;
    padding: $padding-sm 0;
    z-index: 1;
}
.promotion-layout__content {
    height: 100%;
    display: flex;
    overflow: auto;
    flex-direction: column;
    justify-content: space-between;
}
</style>
