<template>
    <div
        class="vertical"
        :style="{
            height,
            width,
        }"
    ></div>
</template>

<script>
export default {
    name: 'VerticalDivider',
    props: {
        height: {
            type: String,
            default: '40px',
        },
        width: {
            type: String,
            default: '2px',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_colors.scss';

.vertical {
    background-color: $grey;
}
</style>
