<template>
    <base-modal
        v-if="activeModal && currentGiftCard"
        :color-iconclose="textHeaderColor"
        :has-close="true"
        :has-header="true"
        :header-text-color="textHeaderColor"
        :orange-header="true"
        :title="exec(currentGiftCard?.title_alert)"
        @on-close="closeAlertGiftCardPage(currentGiftCard?.id)"
    >
        <div class="gift-card-alert">
            <GiftCard class="gift-card-alert__card" :card="giftCard" />
            <p class="gift-card-alert__description">
                {{ exec(currentGiftCard.description_alert) }}
            </p>

            <btn-solid
                class="gift-card-alert__btn"
                :txt="exec(currentGiftCard.text_button)"
                border-radius="7px"
                @click="closeAlertGiftCard(currentGiftCard.id)"
            />
        </div>
    </base-modal>
</template>

<script lang="ts" setup>
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import GiftCard from '@/components/cards/GiftCard.vue';
import { useStore } from 'vuex';
import { ref } from 'vue';
import { useTranslate } from '@/composables/UseTranslate';
import { logger } from '@/utils/logger';
import { useRouter } from 'vue-router';
import { useWallet } from '@/views/wallet/useWallet';
import { GiftCardsAlertModel } from '@/models/giftCardsAlert/giftCardsAlertModel';
import { GiftCardDTO } from '@/views/wallet/WalletModels';
import { appRoutesMap } from '@/router/appRoutesMap';

const store = useStore();

const router = useRouter();
const { exec } = useTranslate();
const dataGiftCard = ref<GiftCardsAlertModel[]>([]);

const textHeaderColor = ref('#fff');
const activeModal = ref(true);
const currentIndex = ref(0);
const currentGiftCard = ref<GiftCardsAlertModel>(null);
const giftCard = ref<GiftCardDTO>(null);

const { giftCardSelected, mainGiftCards, getMainGiftCards } = useWallet();
const changeCurrentGiftCard = () => {
    if (currentIndex.value >= dataGiftCard.value.length) return;
    currentGiftCard.value = dataGiftCard.value[currentIndex.value];
    activeModal.value = true;
    giftCard.value = {
        card_picture: currentGiftCard.value.image_gift_card,
        color_text: currentGiftCard.value.color_text,
        id: currentGiftCard.value.id,
        card_name: exec(currentGiftCard.value?.title_alert),
        available_amount: currentGiftCard.value.available_amount,
        description: exec(currentGiftCard.value.description_alert),
    };
    currentIndex.value++;
};
const getInfoAlertGiftCard = async () => {
    try {
        dataGiftCard.value = await store.dispatch('giftCardAlert/getGifCardsAlerts');
        changeCurrentGiftCard();
    } catch (error) {
        logger(error);
    }
};
const closeAlertGiftCard = async (idGiftCard: number) => {
    await store.dispatch('giftCardAlert/updateGifCardsAlerts', {
        idGiftCard: idGiftCard,
    });
    giftCardSelected.value = mainGiftCards.value.find((idGift) => idGift.id === idGiftCard);
    await router.push({
        name: appRoutesMap.giftCardDetail,
    });
};

const closeAlertGiftCardPage = async (idGiftCard: number) => {
    activeModal.value = false;
    await store.dispatch('giftCardAlert/updateGifCardsAlerts', {
        idGiftCard: idGiftCard,
    });
    changeCurrentGiftCard();
};

getInfoAlertGiftCard();
getMainGiftCards();
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index';

.gift-card-alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $padding-md;

    &__card {
        width: 18.75rem;
        height: 11.25rem;
    }

    &__description {
        @include caption;
        margin: $margin-md $margin-none;
        max-width: 20rem;
        text-align: center;

        @include phone {
            font-size: 1rem;
        }
    }
}
</style>
