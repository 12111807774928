<template>
    <component
        :is="field.component"
        v-for="field in schema"
        :key="field.name"
        v-model="formData[field.name]"
        :errors="errors[field.name]"
        :input-phone-disabled="false"
        :is-disabled="field.disabled"
        :is-invalid="localInvalid?.includes(field.name) || !!errors[field.name]?.length"
        :is-required="field.required"
        :label="field.name"
        :label-name="field.title"
        :placeholder="field.placeholder"
        v-bind="{
            ...field,
            ...$attrs,
        }"
        @input="updateForm(field.name, $event.target.value, field)"
        @update:model-value="(value) => updateForm(field.name, value, field)"
    />
</template>

<script lang="ts">
import BaseCard from '@/components/cards/BaseCard.vue';
import DynamicToggle from '@/components/forms/DynamicCheck.vue';
import DynamicDropdown from '@/components/forms/DynamicDropdown.vue';
import DynamicDate from '@/components/forms/DynamicDate.vue';
import InputPhoneWithLabel from '@/components/inputs/primary/phone/InputPhoneWithLabel.vue';
import { defineComponent } from 'vue';
import DynamicInput from '@/components/forms/DynamicInput.vue';

export default defineComponent({
    name: 'DynamicForm',
    components: {
        InputPhoneWithLabel,
        BaseCard,
        DynamicInput,
        DynamicDropdown,
        DynamicToggle,
        DynamicDate,
    },
    props: ['schema', 'value', 'required', 'placeholder', 'errors'],
    emits: ['form', 'formValues'],
    data() {
        return {
            formData: {},
            localRequired: [],
            localInvalid: [],
        };
    },
    created() {
        this.formData = this.value ?? {};
    },
    updated() {
        this.formData = this.value;
        this.localRequired = this.required;
    },
    methods: {
        updateForm(fieldName: string, value: string, field: object) {
            this.formData[fieldName] =
                value === 'true' || value === 'on' ? true : value === 'false' || value === 'off' ? false : value;
            if (!value.length && typeof value !== 'boolean') delete this.formData[fieldName];
            this.$emit('formValues', fieldName, this.formData, field);
        },
    },
});
</script>
