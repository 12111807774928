class DefaultPaymentTypeModel {
    readonly name: string;
    readonly description: string;
    readonly id: number;
    readonly channelPayment: string;

    constructor(name: string, description: string, id: number, channelPayment: string) {
        this.name = name;
        this.description = description;
        this.id = id;
        this.channelPayment = channelPayment;
    }
}
const toDefaultPaymentTypeModel = (data: object): DefaultPaymentTypeModel => {
    return data ? new DefaultPaymentTypeModel(data['name'], data['description'], data['id'], data['channel']) : null;
};
export { DefaultPaymentTypeModel, toDefaultPaymentTypeModel };
