import { TaxModel, toTaxModel } from '../cart/TaxModel';
import { ClientInformationModel, toClientInformationModel } from './ClientInformation';
import { DeliveryInformationModel, toDeliveryInformationModel } from './DeliveryInformation';
import { RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';

class OrderInformationModel {
    readonly clientInformation: ClientInformationModel;
    readonly deliveryInformation: DeliveryInformationModel;
    readonly city: string;
    readonly retailer: string;
    readonly retailerPrimaryColor: string;
    readonly retailerSecondaryColor: string;
    readonly amountReceivable: number;
    readonly discountApplied: Array<string>;
    readonly finalDiscountApplied: Array<string>;
    readonly orderCost: object;
    readonly taxName: string;
    readonly subtotalTaxes: Array<TaxModel>;
    readonly subtotalTaxesTotal: number;
    readonly taxes: Array<TaxModel>;
    readonly taxesTotal: number;
    readonly subtotalTaxesInitial: Array<TaxModel>;
    readonly subtotalTaxesInitialTotal: number;
    readonly taxesInitial: Array<TaxModel>;
    readonly taxesInitialTotal: number;
    readonly retailerCommissionName: RemoteLocaleModel;

    constructor(
        clientInformation: ClientInformationModel,
        deliveryInformation: DeliveryInformationModel,
        city: string,
        retailer: string,
        primaryColor: string,
        secondaryColor: string,
        amountReceivable: number,
        discountApplied: Array<string>,
        finalDiscountApplied: Array<string>,
        orderCost: object,
        taxName?: string,
        taxes?: Array<TaxModel>,
        taxesTotal?: number,
        subtotalTaxes?: Array<TaxModel>,
        subtotalTaxesTotal?: number,
        taxesInitial?: Array<TaxModel>,
        taxesInitialTotal?: number,
        subtotalTaxesInitial?: Array<TaxModel>,
        subtotalTaxesInitialTotal?: number,
        retailerCommissionName?: RemoteLocaleModel,
    ) {
        this.clientInformation = clientInformation;
        this.deliveryInformation = deliveryInformation;
        this.city = city;
        this.retailer = retailer;
        this.retailerPrimaryColor = primaryColor;
        this.retailerSecondaryColor = secondaryColor;
        this.amountReceivable = amountReceivable;
        this.discountApplied = discountApplied;
        this.finalDiscountApplied = finalDiscountApplied;
        this.orderCost = orderCost;
        this.taxName = taxName;
        this.taxes = taxes;
        this.taxesTotal = taxesTotal;
        this.subtotalTaxesTotal = subtotalTaxesTotal;
        this.subtotalTaxes = subtotalTaxes;
        this.taxesInitial = taxesInitial;
        this.taxesInitialTotal = taxesInitialTotal;
        this.subtotalTaxesInitialTotal = subtotalTaxesInitialTotal;
        this.subtotalTaxesInitial = subtotalTaxesInitial;
        this.retailerCommissionName = retailerCommissionName;
    }
}

const toOrderInformationModel = (data: object): OrderInformationModel => {
    return data
        ? new OrderInformationModel(
              toClientInformationModel(data['client_information']),
              toDeliveryInformationModel(data['delivery_information']),
              data['order_detail'] && data['order_detail']['city'] ? data['order_detail']['city'] : '',
              data['order_detail'] && data['order_detail']['retailer'] ? data['order_detail']['retailer'] : '',
              data['order_detail'] && data['order_detail']['retailer_primary_color']
                  ? data['order_detail']['retailer_primary_color']
                  : '',
              data['order_detail'] && data['order_detail']['retailer_secondary_color']
                  ? data['order_detail']['retailer_secondary_color']
                  : '',
              data['order_detail'] && data['order_detail']['amount_receivable']
                  ? data['order_detail']['amount_receivable']
                  : '',
              data['order_detail'] && data['order_detail']['discount_applied']
                  ? data['order_detail']['discount_applied'].map((discount: string) => {
                        return discount;
                    })
                  : [],
              data['order_detail'] && data['order_detail']['discount_applied_final']
                  ? data['order_detail']['discount_applied_final'].map((discount: string) => {
                        return discount;
                    })
                  : [],
              data['order_costs'],
              data['order_costs']['taxes']?.['taxes_values']?.length
                  ? data['order_costs']['taxes']['taxes_values'][0]['name'].split(' ')[0]
                  : '',
              data['order_costs']['taxes']?.['taxes_values']
                  ?.map(tax => toTaxModel(tax))
                  ?.filter((tax: TaxModel) => tax.percent > 0 && tax.value > 0) ?? [],
              data['order_costs']['taxes']?.['taxes_values']
                  ?.map(tax => tax['value'])
                  ?.reduce((acc, actual) => actual + acc),
              data['order_costs']['taxes']?.['sub_total_values']
                  ?.map(tax => toTaxModel(tax))
                  ?.filter((tax: TaxModel) => tax.value > 0) ?? [],
              data['order_costs']['taxes']?.['sub_total_values']
                  ?.map(tax => tax['value'])
                  ?.reduce((acc, actual) => actual + acc),
              data['order_costs']['taxes']?.['taxes_values_initial']
                  ?.map(tax => toTaxModel(tax))
                  ?.filter((tax: TaxModel) => tax.percent > 0 && tax.value > 0) ?? [],
              data['order_costs']['taxes']?.['taxes_values_initial']
                  ?.map(tax => tax['value'])
                  ?.reduce((acc, actual) => actual + acc),
              data['order_costs']['taxes']?.['sub_total_values_initial']
                  ?.map(tax => toTaxModel(tax))
                  ?.filter((tax: TaxModel) => tax.value > 0) ?? [],
              data['order_costs']['taxes']?.['sub_total_values_initial']
                  ?.map(tax => tax['value'])
                  ?.reduce((acc, actual) => actual + acc),
              RemoteLocaleModel.fromJson(data['order_detail']['retailer_commission_name']),
          )
        : null;
};

export { OrderInformationModel, toOrderInformationModel };
