import { AppSectionModel } from './AppSectionModel';

export abstract class PromoObjectModel {
    id: number;
    retailers: string;
    cities: string;
    appSection: AppSectionModel;
    type: string;
    title: string;
    titleColor: string;
    picture: string;
    startDate: Date;
    endDate: Date;
    file: string;
    showTermsAndConditions: boolean;
    showDiscountActivationProgressBar: boolean;

    protected constructor(
        id: number,
        retailers: string,
        cities: string,
        appSection: AppSectionModel,
        type: string,
        title: string,
        titleColor: string,
        picture: string,
        startDate: Date,
        endDate: Date,
        file: string,
        showTermsAndConditions: boolean,
        showDiscountActivationProgressBar: boolean,
    ) {
        this.id = id;
        this.retailers = retailers;
        this.cities = cities;
        this.appSection = appSection;
        this.type = type;
        this.title = title;
        this.titleColor = titleColor;
        this.picture = picture;
        this.startDate = startDate;
        this.endDate = endDate;
        this.file = file;
        this.showTermsAndConditions = showTermsAndConditions;
        this.showDiscountActivationProgressBar = showDiscountActivationProgressBar;
    }
}
