import { ProductAttributeInterface, ProductAttributeModel } from '@/models/product/ProductAttributeModel';
import { ProductWithAttributeInterface, ProductWithAttributeModel } from '@/models/product/ProductWithAttributeModel';

interface ProductGroupedInterface {
    type_of_attributes: Array<ProductAttributeInterface>;
    attribute: Array<ProductWithAttributeInterface>;
    item_selected: ProductWithAttributeInterface;
}

export class ProductGroupedModel implements ProductGroupedInterface {
    readonly type_of_attributes: Array<ProductAttributeModel>;
    readonly item_selected: ProductWithAttributeModel;
    readonly attribute: Array<ProductWithAttributeModel>;

    constructor(data: ProductGroupedInterface) {
        this.type_of_attributes = ProductAttributeModel.builderList(data.type_of_attributes);
        this.item_selected = ProductWithAttributeModel.fromJson(data.item_selected, true);
        this.attribute = ProductWithAttributeModel.builderList(data.attribute);
    }

    static fromJson(data: object): ProductGroupedModel {
        if (!data) return null;
        return new ProductGroupedModel(data as ProductGroupedInterface);
    }
}
