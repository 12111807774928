<template>
    <section
        class="card-menu"
        :class="{
            'card-menu__single-retailer': isSingle,
            'card-menu__no-data': !scheduleToRender,
        }"
    >
        <icon-schedule v-if="scheduleToRender" :size="0.8" color="#FF9012" />
        <section class="card-menu__content">
            <header class="retailer-schedule__header">
                <h2 :class="{ 'text-no-selected': !scheduleToRender }">
                    {{ deliveryModeText }}
                </h2>
                <h3 :class="{ 'text-no-selected': !scheduleToRender }">
                    {{ retailer.retailer_name.toUpperCase() }}
                </h3>
            </header>
            <p v-if="!retailer.delivery_data" class="text-no-selected">
                {{ $t("txt['schedules__this-retailer-dont']") }}
            </p>
            <p v-else-if="showMinimumAmountRequirement" class="text-no-selected">
                {{ $t('txt.minimum-amount__this-store-amount') }}
            </p>
            <p v-else-if="scheduleToRender" class="retailer-schedule--date">
                {{
                    doFormat({
                        format: 'dddd D',
                        date: scheduleToRender.date,
                        title: true,
                    })
                }}
                {{ $t("general['from']") }} {{ scheduleToRender.deliveryFirstDate }}
                {{ $t("general['to']") }}
                {{ scheduleToRender.deliverySecondDate }}
            </p>
            <p v-else class="text-no-selected">{{ $t("txt['schedule__select-delivery']") }}</p>
        </section>
        <button
            v-show="retailer.delivery_data && !showMinimumAmountRequirement"
            :class="{ 'text-no-selected': !scheduleToRender }"
            @click="showModalSchedule = true"
        >
            {{ $t(!scheduleToRender ? "general['select']" : "cta['change']") }}
        </button>
    </section>
    <BackgroundWithTransition :is-active="showModalSchedule" content-centered @on-bg-click="showModalSchedule = false">
        <CheckoutScheduleModal v-if="showModalSchedule" v-model="showModalSchedule" :retail="retailer" />
    </BackgroundWithTransition>
</template>

<script lang="ts" setup>
import IconSchedule from '@/components/icons/IconSchedule.vue';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { RetailerAvailabilityModel } from '@/modules/retailers/models/RetailerAvailabilityModel';
import { usePickUp } from '@/composables/usePickUp';
import { useDays } from '@/composables/useDays';
import { computed, PropType, ref } from 'vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { useApp } from '@/composables/useApp';
import CheckoutScheduleModal from '@/views/checkout/components/menu/components/scheduleCard/CheckoutScheduleModal.vue';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';

const props = defineProps({
    isSingle: {
        required: true,
        type: Boolean,
    },
    retailer: {
        type: Object as PropType<RetailerAvailabilityModel>,
        required: true,
    },
});

const showModalSchedule = ref(false);

const { $store } = useApp();
const { deliveryModeText } = usePickUp();
const { retailerInCart } = useRetailer(props.retailer.retailer_id);
const { doFormat } = useDays();

const scheduleToRender = computed<TimeDeliveryModel>(() => {
    return $store.getters['checkout/timeDelivery'].find(
        (time: TimeDeliveryModel) => time.retailerId == props.retailer.retailer_id,
    );
});
const showMinimumAmountRequirement = computed(() => {
    if (!retailerInCart.value) return false;
    const totalToPay = retailerInCart.value.products?.reduce(
        (accumulator, { finalPriceWithDiscount }) => accumulator + finalPriceWithDiscount,
        0,
    );
    return totalToPay <= retailerInCart.value?.minimumBuyPrice;
});
</script>

<style lang="scss" scoped>
@import '../../menu-checkout.scss';
@import './schedule-card.scss';
</style>
