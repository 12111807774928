<template>
    <base-icon :icon-name="$t('icon.order_cancelled')" :width="width" :height="height" viewBox="0 0 144 151">
        <path
            d="M84.7766 72.0106L96.7447 59.2447C98.3404 57.6489 98.3404 54.4574 96.7447 52.8617L90.3617 46.4787C88.766 44.883 85.5745 44.883 83.9787 46.4787L72.0106 59.2447L59.2447 46.4787C57.6489 44.883 54.4574 44.883 52.8617 46.4787L46.4787 52.8617C44.883 54.4574 44.883 57.6489 46.4787 59.2447L59.2447 72.0106L46.4787 84.7766C44.883 86.3723 44.883 89.5638 46.4787 91.1596L52.8617 96.7447C54.4574 98.3404 57.6489 98.3404 59.2447 96.7447L72.0106 83.9787L84.7766 96.7447C86.3723 98.3404 89.5638 98.3404 91.1596 96.7447L97.5426 90.3617C99.1383 88.766 99.1383 85.5745 97.5426 83.9787L84.7766 72.0106ZM72.0106 1C32.9149 1 1 32.9149 1 72.0106C1 111.106 32.9149 143.021 72.0106 143.021C111.106 143.021 143.021 111.106 143.021 72.0106C143.021 32.9149 111.106 1 72.0106 1ZM72.0106 129.457C40.0957 129.457 14.5638 103.926 14.5638 72.0106C14.5638 40.0957 40.0957 14.5638 72.0106 14.5638C103.926 14.5638 129.457 40.0957 129.457 72.0106C129.457 103.926 103.926 129.457 72.0106 129.457Z"
            fill="#F03F51"
            stroke="#E74051"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-order-cancelled',
    props: {
        size: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        width(): number {
            return 144 * this.size;
        },
        height(): number {
            return 151 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
