import { toRaw } from 'vue';
import { useStore } from 'vuex';
import { ProductModel } from '@/models/product/ProductModel';
import { ReplacementEventTypes, ReplacementType } from '@/modules/events/enums/ReplacementEventTypes';
import { useRoute } from 'vue-router';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export const useReplacementEvents = () => {
    const $store = useStore();
    const $route = useRoute();
    const { retailerSelected } = useRetailer();

    const addStoreName = (proxyProduct: ProductModel) => {
        if (!proxyProduct) return;
        const product = toRaw(proxyProduct);
        if (!retailerSelected.value || !product.retailer) return;
        product.retailer.store = retailerSelected.value?.store_name;
        return product;
    };
    const sendReplacementAnalytics = ({
        mainProduct,
        replacementProduct,
        replacementProducts,
        replacementType,
        event,
    }: {
        mainProduct?: ProductModel;
        replacementProduct?: ProductModel;
        replacementProducts?: ProductModel[];
        replacementType: ReplacementType;
        event: ReplacementEventTypes;
    }) => {
        const originalProduct = addStoreName(mainProduct);
        const replacement = addStoreName(replacementProduct);
        const replacements = replacementProducts ? replacementProducts.map((prod) => addStoreName(prod)) : null;
        $store.dispatch('segment/replacementEvents', {
            event,
            section: replacementType,
            originalProduct,
            replacement,
            replacements,
            screen: $route.name,
        });
    };
    return {
        sendReplacementAnalytics,
    };
};
