import { useStore } from 'vuex';
import { computed } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import { logger } from '@/utils/logger';
import { useUser } from '@/composables/useUser';
import { HourModel } from '@/models/retailers/HourModel';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { useCartType } from '@/modules/core/composable/useCartType';

export const useStockItemSchedule = () => {
    const $store = useStore();

    const { userLocation } = useUser();
    const { isExtraItemCart } = useCartType();

    const productWithSpecialDelivery = computed<Array<ProductModel>>(
        () => $store.getters['cart/productWithSpecialDelivery'],
    );
    const stockItemTimeDelivery = computed<Array<TimeDeliveryModel>>(
        () => $store.getters['checkout/stockItemTimeDelivery'],
    );

    const resetProductFirstSchedules = (): void => {
        $store.commit('checkout/resetStockItemTimeDelivery');
    };

    const isCategoryFromList = computed<boolean>(() => $store.getters['lists/isCategoryFromList']);

    const canAddProduct = computed<boolean>(() => !isExtraItemCart.value && !isCategoryFromList.value);
    const fetchProductFirstSchedules = async (): Promise<void> => {
        try {
            resetProductFirstSchedules();
            const productsToFetch = productWithSpecialDelivery.value?.map(product => {
                return {
                    stock_id: product.id,
                    retailer_id: product.retailer?.id,
                    sector_id: userLocation.value?.sector?.id,
                    product_name: product.name,
                };
            });

            if (!productsToFetch.length) return;
            const allFetch = productsToFetch.map(product => $store.dispatch('product/productFirstSchedules', product));
            await Promise.all(allFetch);
        } catch (err) {
            logger('FETCHING_PRODUCT_SCHEDULE_ERROR', err);
        }
    };

    const fetchProductSchedules = async ({
        retailer_id,
        stock_id,
    }: {
        retailer_id: number;
        stock_id: number;
    }): Promise<Array<HourModel>> => {
        try {
            const product = {
                retailer_id,
                stock_id,
                sector_id: userLocation.value?.sector?.id,
            };
            return await $store.dispatch('product/productSchedules', product);
        } catch (err) {
            logger('FETCHING_PRODUCT_SCHEDULE_ERROR', err);
            return [];
        }
    };

    return {
        fetchProductFirstSchedules,
        fetchProductSchedules,
        canAddProduct,
        stockItemTimeDelivery,
        productWithSpecialDelivery,
    };
};
