<template>
    <no-content
        v-if="!promotions.length"
        class="promotions--art-space"
        :description="$t('txt.promotions__no-exist-promotions')"
        :title="$t('txt.promotions__without-promotions')"
    >
        <icon-afflicted-shop-bag />
    </no-content>
    <section v-else class="promotions">
        <base-card class="promotions__options">
            <base-card
                v-for="promotion in promotions"
                :key="promotion.id"
                class="promotion-card"
                padding="0"
                @click="selectPromotion(promotion)"
            >
                <figure v-if="promotion.picture" class="promotion-card__figure">
                    <img class="promotion-card__img" :alt="promotion.title" :src="promotion.picture" />
                </figure>
                <div class="promotion-card__content">
                    <div>
                        <h3 class="promotion-card__title">{{ promotion.title }}</h3>
                        <p class="promotion-card__cities">
                            {{ citiesShowed(promotion?.cities) }}
                        </p>
                    </div>
                    <p class="promotion-card__date align-top">
                        {{ formatDatePromotion(promotion.startDate, promotion.endDate) }}
                    </p>
                    <icon-arrow :icon-name="$t('icon.show')" />
                </div>
            </base-card>
            <triple-loading v-if="isLoading" />
        </base-card>
        <informative class="promotions__detail" :promotion="promotionSelected" />
    </section>
    <div ref="promotionsRef"></div>
</template>

<script lang="ts" setup>
import { BannerModel } from '@/models/promotions/banners/BannerModel';
import { logger } from '@/utils/logger';
import BaseCard from '@/components/cards/BaseCard.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import IconAfflictedShopBag from '@/components/icons/IconAfflictedShopBag.vue';
import informative from './types/informative/Informative.vue';
import TripleLoading from '@/components/loading/TripleLoading.vue';
import { useI18n } from 'vue-i18n';
import { useSeoMeta } from '@unhead/vue';
import { usePromotion } from '@/composables/usePromotion';
import { inject, ref, shallowRef } from 'vue';
import { useApp } from '@/composables/useApp';
import { useStore } from 'vuex';
import { notifierKey } from '@/modules/notification/useNotifier';
import { useIntersectionObserver } from '@vueuse/core';

defineOptions({
    name: 'Promotions',
});
const { t } = useI18n();
const { goToPromoProductsScreen, formatDatePromotion, isBannerInSection } = usePromotion();
useSeoMeta({
    title: t('seo.promotions'),
});

const notifier = inject(notifierKey);
const $store = useStore();
const { isMobile } = useApp();
const promotions = shallowRef<BannerModel[]>([]);
const promotionSelected = ref<BannerModel>();
const page = ref(1);
const isLoading = ref(false);
const isLastPage = ref(false);
const promotionsRef = ref<HTMLElement>();
const selectPromotion = (promotion: BannerModel): void => {
    if (isMobile) {
        isBannerInSection.value = false;
        goToPromoProductsScreen(promotion);
        return;
    }
    promotionSelected.value = promotion;
};

const citiesShowed = (cities: string) =>
    cities?.split(',')?.length <= 3 ? cities : cities.split(',').slice(0, 3).join(',') + '...' ?? cities;

const { stop } = useIntersectionObserver(promotionsRef, ([{ isIntersecting }]) => {
    if (!isIntersecting) return;
    return fetchPromotions();
});
const fetchPromotions = async (): Promise<void> => {
    try {
        if (isLoading.value || isLastPage.value) return;
        isLoading.value = true;
        const promotionsResults: {
            banners: BannerModel[];
            isLastPage: boolean;
        } = await $store.dispatch('promotions/promotionalBanners', {
            page: page.value,
        });
        if (page.value === 1) {
            promotionSelected.value = promotionsResults.banners.at(0);
        }
        promotions.value = [...promotions.value, ...promotionsResults.banners];
        isLastPage.value = promotionsResults.isLastPage ?? false;
        page.value++;
    } catch (err) {
        isLastPage.value = true;
        notifier({
            type: 'ERROR',
            body: t('txt.promotions__txt-error-loading-promotions'),
        });
        logger('FETCH_PROMOTIONS', err);
    } finally {
        isLoading.value = false;
    }
    if (!isLastPage.value) return;
    stop();
};

await fetchPromotions();
</script>

<style lang="scss" scoped>
@import './promotions.scss';
</style>
