<template>
    <section class="products-related">
        <h4 class="products-related__label">{{ $t("txt['product-modal__complete-your-order']") }}</h4>
        <CarouselForProducts :items="products" :state="requestState" section="COMPLETE_YOUR_ORDER" />
    </section>
</template>

<script lang="ts" setup>
import { ProductModel } from '@/models/product/ProductModel';
import CarouselForProducts from '@/components/carousels/CarouselForProducts.vue';
import { ref, shallowRef } from 'vue';
import { RequestState } from '@/modules/core/types/WorkingState';
import { useProductStore } from '@/store/useProductStore';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { useProductInformation } from '@/composables/useProductInformation';
import { useProductQuantity } from '@/composables/useProductQuantity';

const { getRelatedProducts } = useProductStore();
const { retailerSelected } = useRetailer();
const requestState = ref<RequestState>('FIRST_LOAD');
const products = shallowRef<ProductModel[]>([]);
const { quantity } = useProductQuantity('COMPLETE_YOUR_ORDER');
const { productSelected } = useProductInformation(quantity);
const page = ref(1);

const getProducts = async () => {
    const { results } = await getRelatedProducts({
        item_id: productSelected.value.id,
        page: page.value,
        retailer_id: retailerSelected.value.id,
    });
    products.value = results ?? [];
    requestState.value = 'LOAD-ENDED';
};

getProducts();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.products-related {
    display: flex;
    flex-direction: column;
}

.products-related__label {
    @include body2;
    font-weight: 600;
    text-align: start;
    padding: $padding-sm $padding-md;
    border-top: 1px solid $disable;
    background-color: $white;
}

.products-related__carousel {
    @include tablet {
        padding-bottom: $margin-screen;
    }
}
</style>
