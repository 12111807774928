<template>
    <base-icon :icon-name="$t('icon.password_recovery')" :width="width" :height="height" viewBox="0 0 115 116">
        <path
            d="M47.5228 33.9805H74.3682C82.3493 33.9805 88.8792 40.5104 88.8792 48.4915V63.1235C88.8792 71.1045 82.3493 77.6345 74.3682 77.6345H47.5228C39.5417 77.6345 33.0117 71.1045 33.0117 63.1235V48.4915C33.0117 40.5104 39.5417 33.9805 47.5228 33.9805Z"
            fill="#FF9012"
        />
        <path
            d="M20.6785 79.3242H102.908C109.559 79.3242 115 84.7659 115 91.4168V103.026C115 109.676 109.559 115.118 102.908 115.118H20.6785C14.0276 115.118 8.58594 109.676 8.58594 103.026V91.4168C8.58594 84.7659 14.0276 79.3242 20.6785 79.3242Z"
            fill="#CFDE00"
        />
        <path
            d="M81.1406 92.0236C81.1406 93.4747 82.3499 94.5631 83.801 94.684C85.2521 94.684 86.3404 93.4747 86.4613 92.0236C86.4613 90.5725 85.2521 89.4842 83.801 89.3633C82.3499 89.3633 81.1406 90.5725 81.1406 92.0236Z"
            fill="#606060"
        />
        <path
            d="M44.7422 92.0236C44.7422 93.4747 45.9514 94.5631 47.4025 94.684C48.8537 94.684 49.942 93.4747 50.0629 92.0236C50.0629 90.5725 48.8537 89.4842 47.4025 89.3633C45.8305 89.3633 44.7422 90.5725 44.7422 92.0236Z"
            fill="#606060"
        />
        <path
            d="M37.9719 76.0636H74.8542C81.2632 76.0636 86.5839 70.8638 86.5839 64.3338V40.0278C86.5839 33.6188 81.3841 28.2981 74.8542 28.2981H72.1938V15.48C72.1938 11.3685 70.5009 7.37799 67.5987 4.5967C64.5755 1.57357 60.585 0.00154059 56.4735 0.00154059C47.8878 -0.119385 40.7532 6.89429 40.6323 15.48V28.2981H37.9719C31.5629 28.2981 26.2422 33.4979 26.2422 40.0278V64.4547C26.2422 70.8638 31.442 76.0636 37.9719 76.0636ZM45.8321 15.48C45.953 9.67557 50.6691 5.08041 56.4735 5.20133C62.2779 5.08041 66.994 9.67557 67.1149 15.48V28.2981H45.8321V15.48ZM31.442 40.0278C31.442 36.4001 34.3442 33.4979 37.9719 33.4979H74.9751C78.6029 33.4979 81.5051 36.4001 81.5051 40.0278V64.4547C81.5051 68.0825 78.6029 70.9847 74.9751 70.9847H37.9719C34.3442 70.9847 31.442 68.0825 31.442 64.4547V40.0278Z"
            fill="#606060"
        />
        <path
            d="M26.4844 92.0236C26.4844 93.4747 27.6936 94.5631 29.1447 94.684C30.5958 94.684 31.6842 93.4747 31.8051 92.0236C31.8051 90.5725 30.5958 89.4842 29.1447 89.3633C27.5727 89.3633 26.4844 90.5725 26.4844 92.0236Z"
            fill="#606060"
        />
        <path
            d="M103.391 70.9844H98.796V76.1842H103.391C105.689 76.1842 107.624 78.119 107.624 80.4165V103.392C107.624 105.69 105.689 107.625 103.391 107.625H9.43218C7.1346 107.625 5.19979 105.69 5.19979 103.392V80.4165C5.19979 78.119 7.1346 76.1842 9.43218 76.1842H16.0831V70.9844H9.43218C4.23239 70.9844 0 75.2168 0 80.4165V103.392C0 108.592 4.23239 112.825 9.43218 112.825H103.391C108.591 112.825 112.823 108.592 112.823 103.392V80.4165C112.823 75.2168 108.591 70.9844 103.391 70.9844Z"
            fill="#606060"
        />
        <path
            d="M62.8828 92.0236C62.8828 93.4747 64.0921 94.5631 65.5432 94.684C66.9943 94.684 68.0826 93.4747 68.2035 92.0236C68.2035 90.5725 66.9943 89.4842 65.5432 89.3633C64.0921 89.3633 62.8828 90.5725 62.8828 92.0236Z"
            fill="#606060"
        />
        <path
            d="M56.469 54.6606C57.9201 54.6606 59.0084 53.4513 59.1293 52.0002C59.1293 50.5491 57.9201 49.4608 56.469 49.3398C55.0178 49.3398 53.9295 50.5491 53.8086 52.0002C53.8086 53.5722 55.0178 54.6606 56.469 54.6606Z"
            fill="#606060"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-password-recovery',
    props: {
        size: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        width(): number {
            return 115 * this.size;
        },
        height(): number {
            return 116 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
