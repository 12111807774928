<template>
    <base-icon
        :icon-name="iconName ? iconName : $t('icon.success')"
        :width="width"
        :height="height"
        viewBox="0 0 20 20"
        :iconColor="color"
    >
        <circle cx="9.60243" cy="10.2169" r="9.60243" fill="#FF9012" />
        <path
            d="M14.6975 7.95755L8.95545 14.1082C8.72108 14.3592 8.48671 14.4847 8.13516 14.4847C7.90079 14.4847 7.54924 14.3592 7.31487 14.1082L4.61962 11.2212C4.15088 10.7191 4.15088 9.96589 4.61962 9.58932C5.08836 9.08724 5.79147 9.08724 6.14302 9.58932L8.01799 11.5977L12.9397 6.32578C13.4085 5.8237 14.1116 5.8237 14.4631 6.32578C15.0491 6.70235 15.0491 7.45547 14.6975 7.95755Z"
            fill="white"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-check-success-rounded',
    components: {
        BaseIcon,
    },
    props: {
        color: {
            type: String,
            default: '#FF9012',
        },
        iconName: {
            type: String,
        },
        size: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        height(): number {
            return 20 * this.size;
        },
        width(): number {
            return 20 * this.size;
        },
    },
};
</script>
