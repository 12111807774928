<template>
    <section v-if="selectedMonth?.length" class="stats-graphics__donut">
        <div class="stats-graphics-donut">
            <canvas ref="donutChartContainer" height="200" width="200" />
            <icon-bag class="stats-graphics-donut__img" :size="0.4" color="#FF9012" />
        </div>
        <aside class="stats-graphics__donut-categories">
            <div v-for="(category, index) in categories" :key="index" class="category-tile">
                <div class="category-tile__circle" :style="{ backgroundColor: category.color }"></div>
                <p>{{ category.category }}</p>
            </div>
        </aside>
    </section>
    <no-content
        v-else
        class="cart__no-content"
        :description="$t('txt.statistics__no-orders-detail')"
        :title="$t('general.orders', { count: 0 })"
    >
        <icon-afflicted-shop-bag :size="0.5" color="#C1C1C1" />
    </no-content>
</template>

<script lang="ts" setup>
import IconBag from '@/components/icons/IconBag.vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useApp } from '@/composables/useApp';
import { Chart } from 'chart.js/auto';
import NoContent from '@/components/noContent/NoContent.vue';
import IconAfflictedShopBag from '@/components/icons/IconAfflictedShopBag.vue';
import { useStatistics } from '@/views/myAccount/views/profile/views/statistics/useStatistics';

const { $t } = useApp();
const { selectedMonth, stateForMonthlyStatistics, selectedMonthIndex } = useStatistics();
const alpha = ref(1);
const donutChartContainer = ref<HTMLCanvasElement | null>(null);
const donutChart = ref<Chart<'doughnut'>>();

const categories = computed(() => {
    return selectedMonth.value?.map((category, index) => {
        const variation = alpha.value / selectedMonth.value?.length || 1;
        if (index === 0) {
            alpha.value = 1;
        } else {
            alpha.value = alpha.value - variation;
        }
        return {
            category: category.category_name,
            value: category.month_occurrences,
            color: `rgba(255,144,18,${alpha.value}`,
        };
    });
});

const buildDonutChart = () => {
    if (!selectedMonth.value?.length) return;
    if (!donutChartContainer.value) return;
    if (donutChart.value) donutChart.value.destroy();

    donutChart.value = new Chart(donutChartContainer.value, {
        type: 'doughnut',
        data: {
            datasets: [
                {
                    data: categories.value.map((element) => element.value),
                    backgroundColor: categories.value.map((element) => element.color),
                    hoverBackgroundColor: categories.value.map((element) => element.color),
                    spacing: 2,
                    borderWidth: 0,
                    animation: false,
                },
            ],
        },
        options: {
            plugins: { tooltip: { enabled: false } },
        },
    });
};

onMounted(() => {
    if (stateForMonthlyStatistics.value === 'NONE') buildDonutChart();
});

onUnmounted(() => {
    if (donutChart.value) donutChart.value.destroy();
});
</script>

<style lang="scss" scoped>
@import './stats-graphics.scss';
</style>
