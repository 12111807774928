<template>
    <category-products
        class="category-products"
        :origin-section="Sections.CATEGORIES"
        @go-to-featured-products="$router.push({ name: appRoutesMap.retail.indexCategoryFeaturedProducts })"
    />
</template>

<script lang="ts" setup>
import { Sections } from '@/enums/sectionsOrigin';
import { computed } from 'vue';
import CategoryProducts from '@/components/category/categoryProducts/CategoryProducts.vue';
import { CategoryModel } from '@/models/product/CategoryModel';
import { useSeoMeta } from '@unhead/vue';
import { useApp } from '@/composables/useApp';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'MainCategoryProducts',
});

const { $router, $store, $t } = useApp();

const categorySelected = computed<CategoryModel>(() => $store.getters['categories/getCategorySelected']);

useSeoMeta({
    title: $t('seo.category_name', {
        category: categorySelected.value?.name,
        retailer: $store.getters['retailers/retailerSelected']?.name ?? '',
    }),
});
</script>
<style lang="scss" scoped>
@import './main-category-products.scss';
</style>
