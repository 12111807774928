<template>
    <subheader-products has-return />
    <section ref="bodyResults" class="see-more">
        <subheader-separator
            :name="`${$t('txt.search__products-find-in')}: ${retailerSelected.name}`"
            :show-btn="false"
        />

        <ThePaginator v-model="page" class="see-more-body" :total-pages="totalPages">
            <Suspense>
                <ProductGridFetcher
                    :page="page"
                    :section="trackItemSection"
                    fetcher="SEE_MORE"
                    @pages="(value) => (totalPages = value)"
                />
                <template #fallback>
                    <skeleton-grid />
                </template>
            </Suspense>
        </ThePaginator>
    </section>
</template>

<script lang="ts" setup>
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';
import SubheaderProducts from '@/components/subheaders/subheaderProducts/SubheaderProducts.vue';
import { computed, watch } from 'vue';
import ThePaginator from '@/modules/core/components/paginatinos/ThePaginator.vue';
import ProductGridFetcher from '@/components/product/productsGrid/ProductGridFetcher.vue';
import SkeletonGrid from '@/components/skeletons/skeletonGrid/SkeletonGrid.vue';
import { useProductFetcher } from '@/views/seeMore/useProductFetcher';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { usePagination } from '@/modules/core/components/paginatinos/usePagination';
import { useApp } from '@/composables/useApp';

const { $route, $router } = useApp();
const { seeMoreSection } = useProductFetcher();
const { retailerSelected } = useRetailer();

const { page, totalPages } = usePagination();

page.value = +(($route.query.page as string) ?? '1');

watch(
    () => page.value,
    (value) => {
        $router.replace({
            query: {
                ...$route.query,
                page: `${value || 1}`,
            },
        });
    },
);

const trackItemSection = computed(() => {
    if (seeMoreSection.value.isNewProduct) return 'NEW_PRODUCTS';
    if (seeMoreSection.value.isYourProducts) return 'HOME_RECOMMENDATIONS';
    if (seeMoreSection.value.isPrediction) return 'HOME_PREDICTIONS';
    if (seeMoreSection.value.isRecommendSovi) return 'HOME_SOVI_RECOMMENDATIONS';
    return '';
});
</script>
<style lang="scss" scoped>
@import './see-more.scss';
</style>
