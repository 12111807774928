import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cb3e7c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["base-btn", {
            'base-btn--full-width': $props.fullWidth,
            'btn-disabled': $props.isDisabled,
            'cursor-pointer': !$props.isDisabled,
        }]),
    disabled: $props.isDisabled,
    style: _normalizeStyle($options.styles)
  }, [
    ($props.isLoading)
      ? (_openBlock(), _createBlock(_component_base_loading, {
          key: 0,
          color: $props.borderColor,
          isMarginAuto: false,
          width: $props.loadingSize,
          heigth: $props.loadingSize
        }, null, 8, ["color", "width", "heigth"]))
      : _renderSlot(_ctx.$slots, "default", {
          key: 1,
          class: "base-btn"
        }, undefined, true)
  ], 14, _hoisted_1))
}