<template>
    <router-view v-if="isScreenMainCategorySeeMore" :key="categorySelected?.id" />
    <CategoryWithSubcategories
        v-else
        section="MAIN_CATEGORIES"
        @go-to-featured-products="$router.push({ name: appRoutesMap.retail.indexCategoryFeaturedProducts })"
        @go-to-subcategory="goToSubcategory"
    />
</template>

<script lang="ts" setup>
import CategoryWithSubcategories from '@/components/category/categoryWithSubcategories/CategoryWithSubcategories.vue';
import { CategoryModel } from '@/models/product/CategoryModel';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { computed } from 'vue';
import { useSeoMeta } from '@unhead/vue';
import { useApp } from '@/composables/useApp';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRoutes } from '@/composables/useRoutes';

const { $store, $t, $router } = useApp();
const categorySelected = computed<CategoryModel>(() => $store.getters['categories/getCategorySelected']);
const subcategorySelected = computed<CategoryModel>(() => $store.getters['categories/getSubcategorySelected']);
const { retailerSelected } = useRetailer();
const { isScreenMainCategorySeeMore } = useRoutes();

useSeoMeta({
    title: $t('seo.category_name', {
        category: categorySelected.value?.name,
        retailer: retailerSelected.value?.name,
    }),
});

const goToSubcategory = (): void => {
    $router.push({
        name: appRoutesMap.retail.indexCategorySeeMore,
        params: {
            subcategory: subcategorySelected.value?.name,
        },
    });
};
</script>
