import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
    programs: undefined,
    program: undefined,
    programType: undefined,
    registrationTempData: undefined,
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
