<script lang="ts" setup>
import CardsWithAmount from '@/views/wallet/components/CardsWithAmount.vue';
import WalletCreditCards from '@/views/wallet/components/WalletCreditCards.vue';
import WalletCardSubscriptions from '@/views/wallet/components/WalletCardSubscriptions.vue';
import WalletGiftCards from '@/views/wallet/components/WalletGiftCards.vue';
import WalletSectionCards from '@/views/wallet/components/WalletSectionCards.vue';
import SkeletonCreditCard from '@/components/skeletons/skeletonCreditCards/SkeletonCreditCard.vue';
import { watch } from 'vue';
import { useWallet } from '@/views/wallet/useWallet';
import { useRoute } from 'vue-router';
import { useOrder } from '@/composables/useOrder';

const $route = useRoute();
const skeletonData = Array(3).fill(0);
const { $clean } = useWallet();

const { order, extraItemOrder } = useOrder();

order.value = undefined;
extraItemOrder.value = undefined;

watch(
    () => $route.meta.show_router_view,
    (value) => {
        if (value) return;
        $clean();
    },
    {
        immediate: true,
    },
);
</script>

<template>
    <div class="wallet-cards">
        <RouterView v-if="$route.meta.show_router_view" />
        <template v-else>
            <Suspense>
                <CardsWithAmount />
                <template #fallback>
                    <WalletSectionCards :items="skeletonData" :title="$t('txt.cards_with_balance')">
                        <SkeletonCreditCard />
                    </WalletSectionCards>
                </template>
            </Suspense>
            <Suspense>
                <WalletCreditCards />
                <template #fallback>
                    <WalletSectionCards :items="skeletonData" :title="$t('txt.credit__cards-registered-cards')">
                        <SkeletonCreditCard />
                    </WalletSectionCards>
                </template>
            </Suspense>
            <Suspense>
                <WalletGiftCards />
                <template #fallback>
                    <WalletSectionCards :items="skeletonData" :title="'Tipti Giftcards'">
                        <SkeletonCreditCard />
                    </WalletSectionCards>
                </template>
            </Suspense>
            <Suspense>
                <WalletCardSubscriptions />
                <template #fallback>
                    <WalletSectionCards :items="skeletonData" :title="$t('txt.membership_cards')">
                        <SkeletonCreditCard />
                    </WalletSectionCards>
                </template>
            </Suspense>
        </template>
    </div>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/type-system' as type;
@use '@/assets/scss/media-query' as mq;
@use '@/assets/scss/margin' as mg;
@use '@/assets/scss/padding' as pd;

.wallet-cards {
    display: flex;
    flex-direction: column;
    gap: mg.$margin-md;
    margin-bottom: 2rem;
    padding: pd.$padding-md;
    @include mq.tablet {
        margin-top: mg.$margin-md;
    }
}
</style>
