import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_payphone = _resolveComponent("add-payphone")!
  const _component_dotted_rectangle = _resolveComponent("dotted-rectangle")!
  const _component_subheader_toggle = _resolveComponent("subheader-toggle")!
  const _component_phones = _resolveComponent("phones")!

  return ($data.showAddNumber)
    ? (_openBlock(), _createBlock(_component_add_payphone, {
        key: 0,
        "total-to-pay": $options.totalToPay,
        onOnBack: _cache[0] || (_cache[0] = ($event: any) => ($data.showAddNumber = false))
      }, null, 8, ["total-to-pay"]))
    : (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_dotted_rectangle, {
          "button-text": _ctx.$t('general.new-number'),
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($data.showAddNumber = true))
        }, null, 8, ["button-text"]),
        _createVNode(_component_subheader_toggle, {
          "is-active": $data.deleteNumber,
          subtitle: _ctx.$t('txt.payphone__select-number'),
          onOnPressed: _cache[2] || (_cache[2] = ($event: any) => ($data.deleteNumber = !$data.deleteNumber))
        }, null, 8, ["is-active", "subtitle"]),
        _createVNode(_component_phones, {
          class: "phone",
          "delete-number": $data.deleteNumber
        }, null, 8, ["delete-number"])
      ]))
}