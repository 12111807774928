class BannerHomePage {
    imagen: string;
    name: string;
    type: string;
    title: string;
    position: number;
    deeplink: string;
    dateFrom: string;
    dateTo: string;
    isVisible: boolean;

    constructor(
        imagen: string,
        type: string,
        name: string,
        title: string,
        dateFrom: string,
        dateTo: string,
        isVisible: boolean,
        deeplink: string,
        position: number,
    ) {
        this.imagen = imagen;
        this.type = type;
        this.name = name;
        this.title = title;
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.isVisible = isVisible;
        this.deeplink = deeplink;
        this.position = position;
    }
}

const toBannerHomePage = (data: object) => {
    return data
        ? new BannerHomePage(
              data['imagen'],
              data['type'],
              data['name'],
              data['title'],
              data['date_from'],
              data['date_to'],
              data['is_visible'],
              data['deeplink'] ?? '',
              data['position'],
          )
        : null;
};
export { toBannerHomePage, BannerHomePage };
