<template>
    <button class="cursor-pointer country_phone">
        <img class="country_phone__img" :alt="countryCode?.name" :src="countryCode?.flag" />
        <span class="country_phone__code">{{ countryCode?.alpha_code }}</span>
        <i>
            <icon-arrow class="country_phone__arrow" :size="0.7" />
        </i>
    </button>
</template>

<script lang="ts" setup>
import IconArrow from '@/components/icons/IconArrow.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { CountryCodeModel } from '@/models/country/CountryCodeModel';

const state = useStore();

const countryCode = computed<CountryCodeModel>(() => state.state.external.countryCodeSelected);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

.country_phone {
    @include box-shadow();
    border: unset;
    display: grid;
    grid-template-columns: auto 1fr auto;
    place-items: center;
    gap: 0.5rem;
    border-radius: 5px;
    background-color: $white;
    padding: $padding-sm;
    @include phone {
        padding: $padding-xsm;
    }

    &__img {
        width: 2rem;
        object-fit: contain;
        border-radius: 50%;
    }

    &__code {
        @include body2;
        font-weight: 600;
    }

    &__arrow {
        transform: rotate(90deg);
    }
}
</style>
