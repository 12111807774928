import { computed, inject } from 'vue';
import MediaQuery from '@/assets/ts/media_query';
import { notifierKey, widthKey } from '@/modules/notification/useNotifier';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export const useApp = () => {
    const { t: $t } = useI18n();
    const notifier = inject(notifierKey);
    const width = inject(widthKey);

    const isOnlyPhone = computed(() => width.value <= MediaQuery.smallDeviceWidth);
    const isOnlyTablet = computed(
        () => width.value > MediaQuery.smallDeviceWidth && width.value <= MediaQuery.mediumDeviceWidth,
    );
    const isMobile = computed(() => width.value <= MediaQuery.mediumDeviceWidth);
    const isDesktop = computed(() => width.value > MediaQuery.mediumDeviceWidth);

    return {
        $store: useStore(),
        $router: useRouter(),
        $route: useRoute(),
        $t,
        notifier,
        isOnlyPhone,
        isMobile,
        isOnlyTablet,
        isDesktop,
        screenWidth: width,
    };
};
