import { CheckoutEventTypes } from '@/views/checkout/enums/CheckoutEventTypes';
import { assertUnreachable } from '@/modules/core/utils/assertioUnreachable';
import { CashPaymentModel, WireTransferPaymentModel } from '@/models/checkout/CashWirePaymentModel';
import InstitutionalPaymentModel from '@/models/checkout/InstitutionalPaymentModel';
import { OnlineBankServiceModel } from '@/models/paymentMethods/OnlineBankServiceModel';
import { DeferredModel } from '@/models/paymentMethods/DeferredModel';

export type PaymentToShowName =
    | 'payment-cards'
    | 'payment-wire-transfer'
    | 'payment-tipti-card'
    | 'payment-gift-card'
    | 'payment-payphone'
    | 'payment-institutional'
    | 'payment-cash'
    | 'payment-online-bank-services'
    | 'payment-nequi'
    | 'payment-yappy';

type PaymentObject =
    | ['payment-online-bank-services', OnlineBankServiceModel]
    | ['payment-institutional', InstitutionalPaymentModel]
    ///*** second value its amount or payment id //
    | ['payment-cards' | 'payment-payphone', number]
    | ['payment-tipti-card', number]
    | ['payment-cash', CashPaymentModel]
    | ['payment-wire-transfer', WireTransferPaymentModel];

interface PaymentToShowDto {
    name: PaymentToShowName;
    objectData?: PaymentObject;
    id: number;
    amount: number;
    data: string;
    alias: string;
    icon: string;
    backgroundColor: string;
    idExpired: boolean;
    analyticData: {
        selected_number?: string;
        selected_institutional_name?: string;
        selected_bank_name?: string;
        tipticard_current_available_amount?: number;
        franchise?: string;
        bin?: string;
    };
}
export default class PaymentToShowModel {
    name: PaymentToShowName;
    amount: number;
    data: string;
    icon: string;
    alias: string;
    id: number;
    backgroundColor?: string;
    idExpired: boolean;
    analyticData: {
        selected_number?: string;
        selected_institutional_name?: string;
        selected_bank_name?: string;
        tipticard_current_available_amount?: number;
        franchise?: string;
        bin?: string;
    };
    analyticCheckoutEvent: CheckoutEventTypes;
    deferredInformation: DeferredModel;
    readonly objectData?:
        | OnlineBankServiceModel
        | InstitutionalPaymentModel
        | CashPaymentModel
        | WireTransferPaymentModel
        | number;

    constructor({
        id,
        name,
        icon,
        amount,
        data,
        alias,
        analyticData,
        backgroundColor,
        idExpired,
        objectData,
    }: Partial<PaymentToShowDto>) {
        this.id = id;
        this.name = name ?? objectData[0];
        this.alias = alias;
        this.amount = amount;
        this.data = data;
        this.icon = icon;
        this.backgroundColor = backgroundColor;
        this.analyticData = analyticData;
        this.idExpired = idExpired ?? false;
        this.analyticCheckoutEvent = this.getPaymentType();
        if (!objectData?.length) return;
        this.name = objectData[0];
        this.objectData = objectData[1];
    }

    private getPaymentType(): CheckoutEventTypes {
        switch (this.name) {
            case 'payment-cards':
                return CheckoutEventTypes.SelectCreditCardAsPaymentMethod;
            case 'payment-cash':
                return CheckoutEventTypes.SelectCashAsPaymentMethod;
            case 'payment-institutional':
                return CheckoutEventTypes.SelectInstitutionalAsPaymentMethod;
            case 'payment-nequi':
                return CheckoutEventTypes.SelectNequiAsPaymentMethod;
            case 'payment-yappy':
                return CheckoutEventTypes.SelectYappiAsPaymentMethod;
            case 'payment-online-bank-services':
                return CheckoutEventTypes.SelectOnlineAsPaymentMethod;
            case 'payment-payphone':
                return CheckoutEventTypes.SelectPayphoneAsPaymentMethod;
            case 'payment-tipti-card':
                return CheckoutEventTypes.SelectTiptiCardAsPaymentMethod;
            case 'payment-gift-card':
                return CheckoutEventTypes.SelectTiptiCardAsPaymentMethod;
            case 'payment-wire-transfer':
                return CheckoutEventTypes.SelectWireTransferAsPaymentMethod;
            default:
                assertUnreachable(this.name);
        }
    }
}
