<template>
    <section class="background-canvas">
        <h1 class="search-header">{{ $t("txt['search__results-for']") }}: {{ searchOn }}</h1>
        <ThePaginator v-model="page" class="page-search" :total-pages="totalPages">
            <Suspense :key="paginatorKey">
                <ProductGridFetcher
                    :page="page"
                    fetcher="SEARCH"
                    section="MAIN_SEARCH"
                    @pages="(value) => (totalPages = value)"
                    @first-products="sendAnalytics"
                />
                <template #fallback>
                    <skeleton-grid />
                </template>
            </Suspense>
        </ThePaginator>
    </section>
</template>

<script lang="ts" setup>
import { SearchBar } from '@/enums/searchBar';
import { Sections } from '@/enums/sectionsOrigin';
import SkeletonGrid from '@/components/skeletons/skeletonGrid/SkeletonGrid.vue';
import ThePaginator from '@/modules/core/components/paginatinos/ThePaginator.vue';
import ProductGridFetcher from '@/components/product/productsGrid/ProductGridFetcher.vue';
import { computed, ref, watch } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import { usePagination } from '@/modules/core/components/paginatinos/usePagination';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useApp } from '@/composables/useApp';

const { $store, $t, $router, $route } = useApp();
const { page, totalPages, searchOn, paginatorKey } = usePagination();

const analyticsSent = ref(false);

watch(
    () => page.value,
    (value) => {
        $router.replace({ query: { page: `${value || 1}` } });
    },
);

const originSection = computed(() =>
    $route.name === appRoutesMap.home ? Sections.RETAILERS : Sections.HOMES_RETAILER,
);

const searchBar = computed(() => ($route.name === appRoutesMap.home ? SearchBar.GLOBAL : SearchBar.GLOBAL_BY_RETAILER));

const sendAnalytics = (products: ProductModel[]): void => {
    if (analyticsSent.value) return;
    analyticsSent.value = true;
    $store.dispatch('segment/search', {
        keyword: searchOn.value,
        search_bar: {
            search_bar: searchBar.value,
            search_bar_id: searchBar.value,
        },
        origin: {
            screen: $route.path,
            section: originSection.value,
        },
        products: products?.map((product: ProductModel) => product.name),
        total_results: totalPages.value,
    });
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.search-header {
    @include headline6;
    margin: $margin-lg;
    margin-bottom: unset;
}

.page-search {
    padding: $padding-lg;
    @include tablet {
        padding: $padding-md;
    }
}
</style>
