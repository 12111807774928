<script lang="ts" setup>
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import BaseCard from '@/components/cards/BaseCard.vue';
import { CreditCardExpensiveResume } from '@/views/wallet/WalletModels';
import dayjs, { Dayjs } from 'dayjs';
import IconArrow from '@/components/icons/IconArrow.vue';
import { useUser } from '@/composables/useUser';
import { useWallet } from '@/views/wallet/useWallet';
import TheCardMembership from '@/views/creditCards/components/listCards/TheCardMembership.vue';
import CreditCardWithActions from '@/views/creditCards/components/listCards/CreaditCardWithActions.vue';
import WalletCreditCardOrderList from '@/views/wallet/components/WalletCreditCardOrderList.vue';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import WalletBenefitDetail from '@/views/wallet/components/WalletBenefitDetail.vue';
import { logger } from '@/utils/logger';
import IconBack from '@/components/icons/IconBack.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

const $router = useRouter();
const { userLanguage } = useUser();
const { membershipSelected, creditCardSelected } = useWallet();

const date = ref<Dayjs>(dayjs());
const resumeMonth = reactive<CreditCardExpensiveResume>({} as CreditCardExpensiveResume);

const dateToFetch = computed({
    get: () =>
        dayjs(date.value)
            .locale(userLanguage.value ?? 'es')
            .format('YYYY-MM-DD'),
    set: (value) => {
        date.value = dayjs(value);
    },
});

const canFetchPreviousMonth = computed(
    () =>
        dayjs(resumeMonth.oldest_day).month() < dayjs(date.value).month() &&
        dayjs(resumeMonth.oldest_day).year() <= dayjs(date.value).year(),
);
const canFetchNextMonth = computed(
    () => dayjs().month() > dayjs(date.value).month() && dayjs().year() >= dayjs(date.value).year(),
);

const updateDate = async (addMont: boolean) => {
    if (addMont && !canFetchNextMonth.value) return;
    if (!addMont && !canFetchPreviousMonth.value) return;
    dateToFetch.value = addMont
        ? dayjs(date.value).add(1, 'months').toString()
        : dayjs(date.value).subtract(1, 'months').toString();
    await initCreditCard();
};

const initCreditCard = async () => {
    try {
        Object.assign(resumeMonth, {});

        const { data } = await axiosInstance().get<CreditCardExpensiveResume>(
            `v2/client_profile/payment_method/${creditCardSelected.value.id}/expense_summary`,
            {
                params: {
                    date: dateToFetch.value,
                    // used to disable browser cache
                    helper_param: dayjs(),
                },
            },
        );

        resumeMonth.oldest_day = data.oldest_day;
        resumeMonth.number_orders_monthly = data.number_orders_monthly;
        resumeMonth.number_transactions_monthly = data.number_transactions_monthly;
        resumeMonth.total_amount_general = data.total_amount_general;
        resumeMonth.total_amount_monthly = data.total_amount_monthly;
    } catch (e) {
        logger('TheWalletCardDetail', e);
    }
};

if (creditCardSelected.value) await initCreditCard();

const resetCards = () => {
    $router.replace({
        name: appRoutesMap.Wallet,
    });
    creditCardSelected.value = undefined;
    membershipSelected.value = undefined;
};

onBeforeMount(() => {
    if (creditCardSelected.value || membershipSelected.value) return;
    $router.replace({
        name: appRoutesMap.Wallet,
    });
});
</script>

<template>
    <button class="wallet-card-detail__btn" @click="resetCards">
        <icon-back :size="0.8" color="#000000" />
        <span class="wallet-card-detail__btn-text">{{ $t('txt.go-back') }}</span>
    </button>

    <div class="wallet-card-detail">
        <BaseCard class="wallet-card" padding="1rem">
            <CreditCardWithActions
                v-if="creditCardSelected"
                :card="creditCardSelected"
                is-from-tipti-wallet-detail
                @on-deleted="resetCards"
            />
            <TheCardMembership v-if="membershipSelected" :card="membershipSelected" is-detail />
        </BaseCard>
        <section v-if="creditCardSelected" class="membership-detail">
            <BaseCard class="membership-detail--cc-header" border-radius="3rem" padding="0.5rem">
                <i
                    class="membership-detail__arrow membership-detail__arrow--left cursor-pointer"
                    @click.stop="updateDate(false)"
                >
                    <IconArrow :color="canFetchPreviousMonth ? '#606060' : '#c1c1c1'" :size="0.7" />
                </i>
                <div class="membership-detail__transactions">
                    <p
                        v-date.title="{
                            date: date,
                            format: 'MMMM YYYY',
                        }"
                        class="membership-detail__transactions_date"
                    />
                    <span v-if="resumeMonth.number_transactions_monthly" class="membership-detail__transactions_number">
                        {{ resumeMonth.number_transactions_monthly }} {{ $t('txt.transactions') }}
                    </span>
                </div>
                <i class="membership-detail__arrow cursor-pointer" @click.stop="updateDate(true)">
                    <IconArrow :color="canFetchNextMonth ? '#606060' : '#c1c1c1'" :size="0.7" />
                </i>
            </BaseCard>
            <Suspense :key="date">
                <WalletCreditCardOrderList
                    :card-id="creditCardSelected.id"
                    :date-to-fetch="dateToFetch"
                    :resume-month="resumeMonth"
                />
                <template #fallback>
                    <div class="wallet-card-detail__skeleton">
                        <BaseBone class="wallet-card-detail__skeleton--header" />
                        <BaseBone v-for="i in 3" :key="i" class="wallet-card-detail__skeleton--body" />
                    </div>
                </template>
            </Suspense>
        </section>

        <Suspense v-if="membershipSelected">
            <div class="wallet-card-detail__cards">
                <WalletBenefitDetail />
            </div>
            <template #fallback>
                <div class="wallet-card-detail__cards">
                    <BaseBone v-for="item in 3" :key="item" class="benefit-detail__skeleton" />
                </div>
            </template>
        </Suspense>
    </div>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as colors;
@use '@/assets/scss/type-system' as type;
@use '@/assets/scss/margin' as mg;
@use '@/assets/scss/padding' as pd;

.wallet-card {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wallet-card-detail {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: mg.$margin-sm;

    &__btn {
        all: unset;
        display: flex;
        gap: mg.$margin-sm;

        &-txt {
            @include type.button;
            font-size: 2rem;
        }
    }

    @include type.phone {
        grid-template-columns: 1fr;
    }

    &__skeleton {
        display: flex;
        flex-direction: column;
        gap: mg.$margin-md;

        &--header {
            height: 4rem;
        }

        &--body {
            height: 6rem;
        }
    }

    &__cards {
        padding: pd.$padding-none pd.$padding-md;
        display: flex;
        flex-direction: column;
        gap: mg.$margin-md;
    }
}

.membership-detail {
    flex-direction: column;
    gap: mg.$margin-md;
    height: fit-content;

    &__transactions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &_date {
            @include type.body1;
            color: colors.$orange;
            font-weight: 600;
        }

        &_number {
            @include type.body2;
            color: colors.$grey;
        }
    }

    &__arrow {
        display: flex;
        margin: auto 0;
        padding: 1rem;

        &--left {
            transform: rotate(180deg);
        }
    }

    &--cc-header {
        justify-content: space-between;
        align-items: center;
    }

    &__date {
        flex-direction: column;
        border: 1px solid colors.$orange !important;
    }
}

.wallet-card__actions {
    display: flex;
    justify-content: space-evenly;
    gap: mg.$margin-sm;
    align-items: center;
    width: 100%;

    &__delete {
        all: unset;
        display: flex;
        gap: mg.$margin-sm;
        align-items: center;
        cursor: pointer;
    }

    &__text {
        @include type.button;
        color: colors.$magenta;
        font-weight: 400;
    }

    &__default {
        margin-top: unset !important;
    }
}

.benefit-detail__skeleton {
    height: 8rem;
}

.wallet-card-detail__btn {
    all: unset;
    display: flex;
    gap: mg.$margin-sm;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    &-text {
        @include type.button;
        font-size: 1.8rem;
        font-weight: 600;
        text-transform: capitalize;
    }
}
</style>
