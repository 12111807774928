import { toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a9a2e68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subheader-price" }
const _hoisted_2 = {
  key: 0,
  class: "subheader-price__txt"
}
const _hoisted_3 = { class: "subheader-price__amount" }
const _hoisted_4 = {
  key: 1,
  class: "subheader-price__mix"
}
const _hoisted_5 = { class: "subheader-price__txt" }
const _hoisted_6 = { class: "subheader-price__amount" }
const _hoisted_7 = { class: "subheader-price__txt" }
const _hoisted_8 = { class: "subheader-price__amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_currency = _resolveDirective("currency")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (!$props.totalPayed)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('general.total-order')) + ": ", 1),
          _withDirectives(_createElementVNode("span", _hoisted_3, null, 512), [
            [_directive_currency, $props.totalToPay]
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.$t('general.value_payed')) + ": ", 1),
            _withDirectives(_createElementVNode("span", _hoisted_6, null, 512), [
              [_directive_currency, $props.totalPayed]
            ])
          ]),
          _createElementVNode("p", _hoisted_7, [
            _createTextVNode(_toDisplayString(_ctx.$t('general.value_to_pay')) + ": ", 1),
            _withDirectives(_createElementVNode("span", _hoisted_8, null, 512), [
              [_directive_currency, $props.totalToPay]
            ])
          ])
        ]))
  ]))
}