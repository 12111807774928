<template>
    <section class="question">
        <header class="question__title" :style="{ color: textColor, backgroundColor: bgColor }">
            <h2>{{ selectedStory?.interactiveElement.text.toUpperCase() }}</h2>
        </header>

        <div class="question__options">
            <div
                v-for="(element, index) in selectedStory?.interactiveElement?.elements"
                :key="index"
                class="question__options-answer"
                :style="{
                    color: !hasAnswered
                        ? element.textColor ?? '#FFF'
                        : element.isCorrect || selectedIndex === index
                        ? '#FFF'
                        : element.textColor,
                    backgroundColor: !hasAnswered
                        ? element.textBackgroundColor ?? '#606060'
                        : selectedIndex === index
                        ? isCorrectAnswer
                            ? '#1CA757'
                            : '#f01728'
                        : element.isCorrect
                        ? '#1CA75780'
                        : element.textBackgroundColor,
                }"
                @click="hasAnswered ? null : onSelectOption(element.id, index)"
            >
                <div class="answer-item">{{ items[index] }}</div>
                <p class="answer-label">{{ element.text }}</p>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { logger } from '@/utils/logger';

export default {
    name: 'question',
    data() {
        return {
            items: ['A', 'B', 'C'],
            isCorrectAnswer: false,
            selectedIndex: 0,
        };
    },
    computed: {
        ...mapGetters({
            selectedStory: 'stories/selectedStory',
        }),
        hasAnswered() {
            return this.selectedStory?.interactiveElement?.elements?.some(element => element.choice);
        },
        textColor() {
            return this.selectedStory?.interactiveElement?.textColor ?? '#FFF';
        },
        bgColor() {
            return this.selectedStory?.interactiveElement?.textBackgroundColor ?? '#606060';
        },
    },
    watch: {
        selectedStory() {
            this.loadAnswer();
        },
    },
    mounted() {
        this.loadAnswer();
    },
    methods: {
        loadAnswer(): void {
            this.isCorrectAnswer =
                this.selectedStory?.interactiveElement?.elements?.find(element => element.choice === true)
                    ?.isCorrect === true;
        },
        async onSelectOption(elementId: number, selectedIndex: number): Promise<void> {
            try {
                this.selectedIndex = selectedIndex;
                this.selectedStory?.interactiveElement?.elements?.forEach((element, index) => {
                    if (index == selectedIndex) element.choice = true;
                });
                this.hasAnswered = true;
                this.isCorrectAnswer =
                    this.selectedStory?.interactiveElement?.elements[selectedIndex]?.isCorrect === true;
            } catch (err) {
                logger('QUESTION_TRACKING', err);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../../story-container.scss';
@import './question.scss';
</style>
