<template>
    <retailer-bussiness></retailer-bussiness>
    <banner-covid :show-image="false"></banner-covid>
    <retailer-logos></retailer-logos>
    <div class="retailer-form">
        <retailer></retailer>
    </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';

import Retailer from '@/components/forms/retailerForm/Retailer.vue';
import RetailerBussiness from './components/retailerBussiness/RetailerBussiness.vue';
import RetailerLogos from './components/retailerLogos/RetailerLogos.vue';
import BannerCovid from '@/components/banners/bannerCovid/BannerCovid.vue';
import { useSeoMeta } from '@unhead/vue';

export default {
    name: 'become-retailer',
    components: {
        Retailer,
        RetailerBussiness,
        RetailerLogos,
        BannerCovid,
    },
    setup() {
        const { t } = useI18n();
        useSeoMeta({
            title: t('seo.become_retailer'),
            description: `${t("txt['become-retailer__increase-customer-base']")},
                   ${t("txt['become-retailer__increase-purchase-loyalty']")},
                    ${t("txt['become-retailer__increase-purchase-frequency']")},
                    ${t("txt['become-retailer__average-ticket-increase']")},
                   ${t("txt['become-retailer__average-ticket-profitability']")},
                    ${t("txt['become-retailer__home-penetration']")}`,
        });
    },
};
</script>

<style lang="scss" scoped>
@import './become-retailer.scss';
</style>
