class TiptiCardModel {
    readonly id: number;
    readonly client: number;
    readonly availableAmount: number;
    readonly isPrime: boolean;
    readonly image: string;
    readonly availableAmountThatExpires: number;
    readonly availableAmountThatNotExpires: number;
    readonly details: object;
    readonly cardNumber: string;
    readonly logo: string;

    constructor(
        id: number,
        client: number,
        availableAmount: number,
        isPrime: boolean,
        image: string,
        availableAmountThatExpires: number,
        availableAmountThatNotExpires: number,
        details: object,
        cardNumber: string,
        logo?: string,
    ) {
        this.id = id;
        this.client = client;
        this.availableAmount = availableAmount;
        this.isPrime = isPrime;
        this.image = image;
        this.availableAmountThatExpires = availableAmountThatExpires;
        this.availableAmountThatNotExpires = availableAmountThatNotExpires;
        this.details = details;
        this.cardNumber = cardNumber;
        this.logo = logo;
    }
}
const toTiptiCardModel = (data: object): TiptiCardModel => {
    return data
        ? new TiptiCardModel(
              data['id'],
              data['client'],
              data['available_amount'] ? +data['available_amount'] : 0,
              data['is_prime'],
              data['image'],
              data['available_amount_that_not_expires'] ? +data['available_amount_that_not_expires'] : 0,
              data['available_amount_that_expires'] ? +data['available_amount_that_expires'] : 0,
              data['details'],
              data['card_number'],
              data['logo'],
          )
        : null;
};

export { TiptiCardModel, toTiptiCardModel };
