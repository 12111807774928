<template>
    <button class="btn-text">
        <span
            class="cta-text"
            :class="{
                'cta-text--no-underline': !isUnderline,
                'cursor-pointer': inLink,
                'cta-text--normal': !isNormalWeight,
            }"
            v-bind="$attrs"
            v-text="text"
        />
        <slot></slot>
    </button>
</template>

<script lang="ts">
export default {
    name: 'BtnText',
    props: {
        text: {
            type: String,
            required: true,
        },
        isUnderline: {
            type: Boolean,
            default: true,
        },
        inLink: {
            type: Boolean,
            default: true,
        },
        isNormalWeight: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.btn-text {
    all: unset;
    display: flex;
    align-items: baseline;
}

.cta-text {
    @include caption;
    color: $orange;
    text-decoration: underline;
    font-weight: 600;
    margin: $margin-md $margin-none;

    &--no-underline {
        text-decoration: none;
    }

    &--normal {
        font-weight: 400;
    }
}
</style>
