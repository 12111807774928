<template>
    <base-card-flat border-color="#fff" padding="0">
        <base-bone class="card-skeleton" :is-show="true" />
    </base-card-flat>
</template>
<script lang="ts">
import BaseBone from '@/components/skeletons/BaseBone.vue';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';

export default {
    name: 'skeleton-credit-card',
    components: { BaseBone, BaseCardFlat },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.card-skeleton {
    height: 13rem;
    width: 26rem;
    @include tablet {
        height: 10rem;
        width: 20rem;
    }
}
</style>
