import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9398fc10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "register-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_back = _resolveComponent("icon-back")!
  const _component_tipti_with_slogan = _resolveComponent("tipti-with-slogan")!
  const _component_verify_email = _resolveComponent("verify-email")!
  const _component_verify_code = _resolveComponent("verify-code")!
  const _component_update_password = _resolveComponent("update-password")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_icon_back, {
      onClick: $options.onBack,
      class: "container-back"
    }, null, 8, ["onClick"]),
    _createVNode(_component_tipti_with_slogan),
    ($data.isVerifyEmail)
      ? (_openBlock(), _createBlock(_component_verify_email, {
          key: 0,
          onOnMailSent: $options.goToVerifyCode
        }, null, 8, ["onOnMailSent"]))
      : ($data.isVerifyCode)
        ? (_openBlock(), _createBlock(_component_verify_code, {
            key: 1,
            onOnCodeValidated: $options.goToUpdatePassword
          }, null, 8, ["onOnCodeValidated"]))
        : (_openBlock(), _createBlock(_component_update_password, {
            key: 2,
            code: $data.code,
            email: $data.email
          }, null, 8, ["code", "email"]))
  ]))
}