<template>
    <general-alert
        :acceptButtonTxt="$t('cta.delete-and-continue')"
        :accentHeader="true"
        :showCloseIcon="true"
        :declineButtonTxt="$t('cta.no-delete')"
        :title="$t('txt.minimum-amount__are-you-sure', { amount: minimumAmount, currency: currency })"
        @on-close="$emit('on-close')"
        @on-accept="$emit('on-accept')"
        @on-decline="$emit('on-decline')"
        :isLoading="isLoading"
        :isDisabled="isLoading"
    >
        <template v-slot:content>
            <div class="minimum-amount-body">
                <h1>
                    {{ $t('txt.minimum-amount__there-is', { amount: minimumAmount, currency: currency }) }}
                </h1>
                <p>{{ $t('txt.minimum-amount__if-deleted') }}</p>
            </div>
        </template>
    </general-alert>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import GeneralAlert from '../generalAlert/GeneralAlert.vue';
export default {
    name: 'alert-minimum-amount-per-retailer',
    props: {
        minimumAmount: {
            type: Number,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    components: { GeneralAlert },
    emits: ['on-close', 'on-decline', 'on-accept'],
    computed: {
        ...mapGetters({
            countrySelected: 'external/countrySelected',
        }),
        currency() {
            return this.countrySelected?.currencySymbol ?? '$';
        },
    },
};
</script>

<style lang="scss" scoped>
@import './alert-minimum-amount-per-retailer.scss';
</style>
