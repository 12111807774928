import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    totalToPay: undefined,
    creditCardDefault: undefined,
    creditCardSelected: undefined,
    paymentIdSelected: undefined, /// ** work for credit and payphone payment
    institutionPayment: undefined,
    cashWirePayment: undefined,
    onlineBankServicePayment: undefined,
    nequiPayment: undefined,
    deferred: undefined,
    deferredOptions: [],
    isTiptiCardPayment: false,
    isMixPaymentTiptiCard: false,
    isMixInstitutionalPayment: false,
    isYappyPayment: false,
    secondPayment: 0,
    payphoneList: [],
    paymentsToShow: [], /// * It is used to show up the payments selected and can be used to verify if has already a payment selected
    defaultPaymentsTypesAvailable: [],
    defaultPaymentSelected: undefined,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
