import { ParticipantModel, toParticipantModel } from './ParticipantModel';

class SharedCartModel {
    id: number;
    cart: number;
    name: string;
    productsAmount: number;
    isOwner: boolean;
    owner: ParticipantModel;
    participants: Array<ParticipantModel>;
    picture: string;

    constructor(
        id: number,
        cart: number,
        name: string,
        productsAmount: number,
        isOwner: boolean,
        owner?: ParticipantModel,
        participants?: Array<ParticipantModel>,
        picture?: string,
    ) {
        this.id = id;
        this.cart = cart;
        this.name = name;
        this.productsAmount = productsAmount;
        this.isOwner = isOwner;
        this.owner = owner;
        this.participants = participants;
        this.picture = picture;
    }
}
const toSharedCartModel = (data: object): SharedCartModel => {
    return data
        ? new SharedCartModel(
              data['id'],
              data['cart'],
              data['name'] ?? '',
              data['num_items'] ?? 0,
              data['is_owner'] ?? false,
              toParticipantModel(data['owner']),
              data['participants']?.map(participant => toParticipantModel(participant)),
              data['picture'],
          )
        : null;
};
export { SharedCartModel, toSharedCartModel };
