<template>
    <div :style="styles">
        <div
            class="credit-card-option"
            :class="{
                'credit-card-option--selected':
                    (!isFromTiptiWalletDetail && isSelected) ||
                    ((isFromTiptiWalletDetail || isFromTiptiWalletCardList) && isDefaultCard),
                'cursor-pointer': !isFromTiptiWalletDetail,
                'credit-card-option--expired': card.isExpired,
            }"
            @click="onClickCard"
        >
            <the-credit-card :card="card" :is-default="isDefaultCard" :is-full-overlay="false">
                <template v-if="shouldValidateCard || card.isExpired" #overlay>
                    <overlay :class="{ 'credit-card-overlay--no-radius': card.isExpired }" :bg-color="bgColor">
                        <div class="credit-card__overlay-content">
                            <template v-if="shouldValidateCard">
                                <icon-shield-check :size="3" color="#fff" />
                                <h4 class="credit-card__validate-txt">
                                    {{ $t('txt.credit-card__should-validate') }}
                                </h4>
                            </template>
                            <template v-if="card.isExpired">
                                <icon-credit-card-info />
                                <h4 class="credit-card__validate-txt">
                                    {{ $t('txt.credit_card__expired') }}
                                </h4>
                            </template>
                        </div>
                    </overlay>
                </template>
            </the-credit-card>
            <deferred-option v-if="showAlertDeferredSelector && isMobile" :is-modal="false" has-top-margin />
        </div>
        <aside
            v-if="isSelected"
            class="credit-card-option__actions"
            :class="{ 'credit-card-option__actions--center': card.isExpired }"
        >
            <button class="credit-card-option__actions_delete" @click.stop="showDeleteAlert = true">
                <IconTrash :size="1.8" color="#f03f51" />
                <span class="credit-card-option__actions_delete-text">{{ $t('cta.delete_card') }}</span>
            </button>
            <default-payment-selector
                v-if="isSelected && !card.isExpired"
                class="credit-card-option__actions-default"
                :is-default="isDefaultCard"
                :payment-channel-selected="card.paymentChannel"
                :payment-id="card.id"
                :payment-type="card.paymentType"
                @on-set-default="onSetDefault"
            />
        </aside>
    </div>
    <validate-card v-if="showVerificationFlow" :payment-method-id="card.id" @on-close="validateModalClose" />
    <expired-card :show-alert="showAlertCardExpired" @on-accept="addNew" @on-cancel="closeAlertExpired" />
    <alert-deferred v-if="showAlertDeferredSelector && !isMobile" @on-continue="showAlertDeferred = false" />

    <BaseModal
        v-if="showDeleteAlert"
        :title="$t('cta.delete_card')"
        has-close
        has-header
        header-text-color="orange"
        is-centered
        @on-close="closeDeleteAlert"
    >
        <div class="deleted-card__content">
            <p class="deleted-card__text">{{ $t('txt.deleted_card_msg') }}</p>
            <section class="deleted-card__actions">
                <BtnOutline
                    v-if="!isWorking"
                    :txt="$t('cta.cancel')"
                    bg-color="white"
                    color="#ff9012"
                    @click.stop="showDeleteAlert = false"
                />
                <BtnSolid :is-loading="isWorking" :txt="$t('cta.delete')" @click.stop="deleteCard" />
            </section>
        </div>
    </BaseModal>
</template>

<script lang="ts" setup>
import TheCreditCard from '@/views/creditCards/components/listCards/TheCreditCard.vue';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import DeferredOption from '@/components/alerts/alertDeferred/DeferredOption.vue';
import { isMobileBrowser } from '@/utils/utils';
import Overlay from '@/views/creditCards/components/listCards/Overlay.vue';
import IconShieldCheck from '@/components/icons/IconShieldCheck.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import ExpiredCard from '@/views/creditCards/components/ExpiderdCard/ExpiredCard.vue';
import AlertDeferred from '@/components/alerts/alertDeferred/AlertDeferred.vue';
import { usePayments } from '@/composables/usePayments';
import ValidateCard from '@/views/creditCards/components/ValidateCard/ValidateCard.vue';
import { useUser } from '@/composables/useUser';
import DefaultPaymentSelector from '@/components/defaultPaymentSelector/DefaultPaymentSelector.vue';
import { useMaxiDollars } from '@/composables/useMaxiDollars';
import { useCartType } from '@/modules/core/composable/useCartType';
import IconCreditCardInfo from '@/components/icons/IconCreditCardInfo.vue';
import { computed, inject, onBeforeMount, PropType, ref } from 'vue';
import { notifierKey } from '@/modules/notification/useNotifier';
import IconTrash from '@/components/icons/IconTrash.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BtnOutline from '@/components/buttons/BtnOutLine.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

const props = defineProps({
    card: {
        type: Object as PropType<PaymentMethodModel>,
        required: true,
    },
    isDelete: {
        type: Boolean,
        default: false,
    },
    isMaxiCards: {
        type: Boolean,
        default: false,
    },
    isFromTiptiWalletDetail: {
        type: Boolean,
        default: false,
    },
    isFromTiptiWalletCardList: {
        type: Boolean,
        default: false,
    },
    styles: {
        type: Object,
        default: undefined,
    },
});

const { deferredOptions, paymentIdSelected, checkDeferAvailability } = usePayments();
const { isExtraItemCart } = useCartType();
const { baseConfiguration } = useUser();
const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const { setMaxiCard } = useMaxiDollars();
const notifier = inject(notifierKey);
const isWorking = ref(false);
const showAlertDeferred = ref(false);
const showVerificationFlow = ref(false);
const showAlertCardExpired = ref(false);
const isDefaultCard = ref(false);
const showDeleteAlert = ref(false);
const { t: $t } = useI18n();

onBeforeMount(() => {
    isDefaultCard.value = props.card.resultDefault;
});

const $emit = defineEmits<{
    (e: 'add-card');
    (e: 'fetch-list', value: boolean);
    (e: 'onClick');
    (e: 'on-deleted');
}>();

const isMobile = computed(() => isMobileBrowser());
const isMixPaymentTiptiCard = computed(() => $store.getters['payments/isMixPaymentTiptiCard']);
const isMixInstitutionalPayment = computed(() => $store.getters['payments/isMixInstitutionalPayment']);
const secondPayment = computed(() => $store.getters['payments/secondPayment']);
const totalToPay = computed(() => $store.getters['payments/totalToPay']);

const closeDeleteAlert = () => {
    if (isWorking.value) return;
    showDeleteAlert.value = false;
};

const bgColor = computed<string>(() => {
    if (shouldValidateCard.value) return '#000000B2';
    if (props.card?.isExpired) return 'rgba(201, 8, 8, 0.8)';
    return '';
});

const shouldValidateCard = computed<boolean>(() => {
    if (props.isFromTiptiWalletCardList || props.isFromTiptiWalletDetail) return false;
    if (props.isDelete) return false;
    if (props.card?.isRequiredValidation) return true;
    return baseConfiguration.value?.cardValidationOldRegister && !props.card?.isValidate;
});

const showAlertDeferredSelector = computed<boolean>(() => {
    if (!isSelected.value) return false;
    return deferredOptions.value?.length && showAlertDeferred.value;
});

const isSelected = computed<boolean>(() => {
    if (props.isFromTiptiWalletCardList) return false;
    if (props.isFromTiptiWalletDetail) return true;
    if (isFromAccountPath.value || props.isMaxiCards) return false;
    return paymentIdSelected.value === props.card.id && !props.isDelete;
});

const isFromAccountPath = computed<boolean>(() => {
    return $route.name === appRoutesMap.account.creditCardsProfile;
});

const addNew = (): void => {
    deleteCard();
    $emit('add-card');
};
const closeAlertExpired = (): void => {
    showAlertCardExpired.value = false;
    deleteCard();
};

const validateModalClose = (): void => {
    $emit('fetch-list', true);
    showVerificationFlow.value = false;
};

const onSetDefault = (isDefault: boolean) => {
    isDefaultCard.value = isDefault;
    $emit('fetch-list', true);
};

const deleteCard = async (): Promise<void> => {
    try {
        if (isWorking.value) return;
        isWorking.value = true;
        await $store.dispatch('payments/deleteCard', props.card.id);
        $store.commit('payments/removePayment', { id: props.card.id, name: 'payment-cards' });
        $emit('fetch-list', true);
        $emit('on-deleted');
        notifier({
            type: 'SUCCESS',
            body: $t("txt['list__delete-card-success']"),
        });
    } catch (err) {
        notifier({
            type: 'ERROR',
            body: $t("txt['list__delete-card']"),
        });
    } finally {
        isWorking.value = false;
    }
};

const onClickCard = async () => {
    if (props.isFromTiptiWalletCardList) {
        $emit('onClick');
        return;
    }
    if (props.isFromTiptiWalletDetail) return;
    onSelectCard();
};

const onSelectCard = (): void => {
    try {
        if (isWorking.value) return;
        if (isMobile.value && paymentIdSelected.value === props.card.id) return;
        if (props.card.isExpired && !isFromAccountPath.value) {
            showAlertCardExpired.value = true;
            return;
        }
        if (
            props.card.isRequiredValidation ||
            (baseConfiguration.value?.cardValidationOldRegister && !props.card.isValidate)
        ) {
            showVerificationFlow.value = true;
            return;
        }
        if (props.isMaxiCards) {
            setMaxiCard(props.card);
            isWorking.value = false;
            return;
        }
        if (isFromAccountPath.value) return;
        $store.commit('payments/deferredOptions', []);
        $store.commit('payments/cleanPaymentMethods');
        isWorking.value = true;

        $store.commit('payments/paymentIdSelected', props.card.id);
        $store.commit(
            'payments/paymentsToShow',
            new PaymentToShowModel({
                name: 'payment-cards',
                id: props.card.id,
                idExpired: props.card.isExpired,
                amount:
                    isMixPaymentTiptiCard.value || isMixInstitutionalPayment.value
                        ? secondPayment.value
                        : totalToPay.value,
                data: props.card.data?.cardDigits?.slice(1, 5),
                icon: props.card.creditCardBrandData?.franchiseLogo,
                backgroundColor: props.card.creditCardBrandData?.backgroundColor,
                analyticData: {
                    franchise: props.card?.franchise,
                    bin: `${props.card?.creditCardBrandData?.bin}`,
                },
                objectData: ['payment-cards', props.card.id],
            }),
        );
        if (isFromAccountPath.value || isExtraItemCart.value) {
            isWorking.value = false;
            return;
        }
        showAlertDeferred.value = true;
    } catch (_) {
        showAlertDeferred.value = false;
    } finally {
        isWorking.value = false;
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.credit-card-option {
    position: relative;
    width: 26rem;
    align-items: flex-end;
    border: 2px solid $white;
    border-radius: 12px;

    @include tablet {
        width: 100%;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        margin-top: $margin-md;

        &--center {
            justify-content: center;
        }

        &_delete {
            all: unset;
            display: flex;
            gap: 0.5rem;
            align-items: center;
            cursor: pointer;

            &-text {
                @include button;
                color: $magenta;
            }
        }

        &-default {
            margin-top: unset !important;
        }
    }

    &--selected {
        border-color: $orange;
    }

    &--expired {
        border-color: $error;
    }

    @include tablet {
        margin-bottom: $margin-sm;
    }
}

.credit-card {
    position: relative;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: top;
    background-size: cover;

    &__overlay-content {
        display: flex;
        flex-direction: column;
        justify-items: center;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__validate-txt {
        @include body1;
        padding: $padding-md;
        font-weight: 600;
        color: $white;
        text-align: center;
    }

    @include tablet {
        margin-bottom: $margin-sm;
    }
}

.deleted-card__content {
    max-width: 25rem;
    padding: $padding-md;
    padding-top: 0;
}

.deleted-card__text {
    @include body2;
    text-align: center;
    margin: $margin-sm;
}

.deleted-card__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $margin-sm;

    & button {
        width: 100%;
    }
}
</style>
