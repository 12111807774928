<template>
    <base-icon :icon-name="$t('icon.warning')" :width="width" :height="height" viewBox="0 0 29 29" :iconColor="color">
        <path
            d="M14.5 4.98449e-08C12.596 -0.000157809 10.7103 0.374639 8.9512 1.10313C7.19207 1.83161 5.59374 2.89959 4.24729 4.24582C2.90084 5.59205 1.83279 7.19013 1.10401 8.94915C0.375228 10.7082 0.000157845 12.5936 0 14.4977C0.000637545 18.3428 1.52863 22.03 4.24787 24.7486C6.96712 27.4672 10.6549 28.9944 14.5 28.9941C18.3451 28.9944 22.0329 27.4672 24.7521 24.7486C27.4714 22.03 28.9994 18.3428 29 14.4977C28.9998 12.5936 28.6245 10.7082 27.8957 8.94915C27.1669 7.19013 26.0989 5.59205 24.7524 4.24582C23.406 2.89959 21.8076 1.83161 20.0485 1.10313C18.2894 0.374639 16.404 -0.000157809 14.5 4.98449e-08V4.98449e-08ZM15.8535 19.9872C15.4863 20.3215 15.006 20.5045 14.5094 20.4993C14.0122 20.5048 13.5306 20.3263 13.1571 19.998C12.9556 19.8171 12.7964 19.5943 12.6905 19.3451C12.5846 19.0958 12.5345 18.8263 12.5441 18.5557C12.5416 18.3006 12.5907 18.0477 12.6882 17.812C12.7856 17.5764 12.9297 17.3627 13.1116 17.184C13.482 16.8245 13.9777 16.6235 14.4938 16.6235C15.01 16.6235 15.506 16.8245 15.8763 17.184C16.0613 17.3615 16.208 17.5752 16.3074 17.8114C16.4069 18.0477 16.4569 18.3017 16.4545 18.558C16.4637 18.8257 16.4147 19.0923 16.311 19.3392C16.2072 19.5861 16.0511 19.8076 15.8535 19.9883V19.9872ZM16.3881 9.20857L15.9843 13.8388C15.964 14.3417 15.8509 14.8366 15.6504 15.2982C15.5458 15.5269 15.3757 15.7195 15.1618 15.8517C14.9478 15.9839 14.6997 16.0497 14.4484 16.041C14.1982 16.0525 13.9504 15.988 13.7376 15.8561C13.5248 15.7241 13.3566 15.5309 13.2557 15.3017C13.0678 14.8305 12.9567 14.3321 12.9265 13.8256L12.6248 9.30365C12.5663 8.40774 12.5374 7.76655 12.5382 7.3803C12.5097 6.80001 12.7089 6.2317 13.0934 5.79618C13.2811 5.59757 13.5091 5.44114 13.7619 5.3375C14.0148 5.23387 14.2868 5.18541 14.5599 5.19517C14.8764 5.16507 15.1948 5.23082 15.4734 5.38387C15.7521 5.53692 15.9782 5.77002 16.1226 6.05326C16.3681 6.63782 16.4798 7.26995 16.4495 7.90325C16.4511 8.33917 16.4302 8.7748 16.387 9.20857H16.3881Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-warning',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 30 * this.size;
        },
        height(): number {
            return 30 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
