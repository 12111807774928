<template>
    <footer v-if="showFooter" class="fotter-mobile-bottom-nav">
        <div
            v-for="({ name, isActive, action }, index) in navItems"
            :key="index"
            class="nav-middle-bottom__nav"
            :class="{ 'nav-middle-bottom__nav--selected': isActive }"
        >
            <icon-home v-if="index == 0" :color="isActive ? '#ff9012' : '#707070'" :size="0.8" @click="action" />
            <icon-store v-else-if="index == 1" :color="isActive ? '#ff9012' : '#707070'" :size="0.85" @click="action" />
            <icon-my-lists v-else :color="isActive ? '#ff9012' : '#707070'" :size="0.8" @click="action" />
            <p>{{ name }}</p>
        </div>
    </footer>
</template>

<script lang="ts" setup>
import IconHome from '@/components/icons/IconHome.vue';
import IconStore from '@/components/icons/IconStore.vue';
import IconMyLists from '@/components/icons/IconMyLists.vue';
import { computed } from 'vue';
import { useUser } from '@/composables/useUser';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useApp } from '@/composables/useApp';
import { useRoutes } from '@/composables/useRoutes';
import { RETAILER_FILTER } from '@/modules/core/constants';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

const { $t, $router } = useApp();
const { isAuth } = useUser();
const { filterApplied } = useRetailer();
const { isPageSeeMoreSuggestions, isPageProductDetail, isHomePage, isInRetailer, isPageMyLists } = useRoutes();

const showFooter = computed(() => {
    if (!isAuth.value) return false;
    return !isPageSeeMoreSuggestions.value && !isPageProductDetail.value;
});

const navItems = computed(() => {
    return [
        {
            name: $t('cta.home'),
            isActive: isHomePage.value && !filterApplied.value,
            action: () => {
                filterApplied.value = undefined;
                if (isHomePage.value) return;

                $router.push({ name: appRoutesMap.home });
            },
        },
        {
            name: $t('general.retailers'),
            isActive: (isHomePage.value && filterApplied.value) || isInRetailer.value,
            action: () => {
                if (isHomePage.value) {
                    filterApplied.value = RETAILER_FILTER;
                    return;
                }

                $router.push({ name: appRoutesMap.home }).then(() => {
                    filterApplied.value = RETAILER_FILTER;
                });
            },
        },
        {
            name: $t('cta.my-lists'),
            isActive: isPageMyLists.value,
            action: () => {
                if (isPageMyLists.value) return;

                $router.push({ name: appRoutesMap.list.myLists });
            },
        },
    ];
});
</script>
<style lang="scss" scoped>
@import './footer-mobile.scss';
</style>
