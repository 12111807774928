<template>
    <section class="the-note">
        <header class="the-note-header">
            <icon-note />
            <h3 class="the-note-header__title">{{ $t("txt['product-modal__send-note']") }}</h3>
        </header>
        <p v-if="hasTitleAndPlaceholderOverride" class="the-note__title">{{ noteTitle }}</p>
        <text-area-note
            :placeholder="notePlaceholder"
            :product="product"
            :quantity="quantity"
            @on-back="$emit('on-close')"
        />
    </section>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import IconNote from '@/components/icons/IconNote.vue';
import TextAreaNote from '@/components/inputs/textArea/TextAreaNote.vue';
import { useTranslate } from '@/composables/UseTranslate';
import { useCartType } from '@/modules/core/composable/useCartType';
import { computed } from 'vue';

defineOptions({
    name: 'TheNote',
});

const props = defineProps({
    section: {
        type: String,
        required: true,
    },
    quantity: {
        type: Number,
        required: true,
    },
});

const $store = useStore();
defineEmits<(e: 'on-close') => void>();

const { exec } = useTranslate();
const { isExtraItemCart } = useCartType();
const product = computed(() => $store.getters['product/currentProduct']);
const retailerSelected = computed(() => $store.getters['retailers/retailerSelected']);

const noteTitle = computed(() => exec(retailerSelected.value?.franchise?.shopperNoteTitle));

const notePlaceholder = computed(() => exec(retailerSelected.value?.franchise?.shopperNotePlaceholder));

const hasTitleAndPlaceholderOverride = computed(() => noteTitle.value?.length && notePlaceholder.value?.length);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-note {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &__title {
        @include caption;
        color: $error;
        text-align: center;
    }
}
.the-note-header {
    display: flex;
    align-items: center;
    gap: $margin-sm;
    width: 100%;
    & > svg {
        align-self: center;
    }
}
.the-note-header__title {
    @include body2;
    text-align: center;
    font-weight: 600;
}
</style>
