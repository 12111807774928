import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37c80ea4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "minimum-amount"
}
const _hoisted_2 = { class: "minimum-amount__legend" }
const _hoisted_3 = { class: "minimum-amount__missing" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progress_bar = _resolveComponent("progress-bar")!

  return ($options.missingAmount > 0)
    ? (_openBlock(), _createElementBlock("aside", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('txt.minimum-amount__this-store')) + " " + _toDisplayString($options.currency) + " " + _toDisplayString($props.minimumAmount) + " " + _toDisplayString(_ctx.$t('txt.minimum-amount__add')) + " ", 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString($options.currency) + " " + _toDisplayString($options.missingAmount.toFixed(2)), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('txt.minimum-amount__to-process')), 1)
        ]),
        _createVNode(_component_progress_bar, {
          minimumAmount: $props.minimumAmount,
          progress: $options.progress
        }, null, 8, ["minimumAmount", "progress"])
      ]))
    : _createCommentVNode("", true)
}