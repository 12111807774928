import { logger } from '@/utils/logger';
import { toLoyaltyAndRewardProgramsModel } from '@/models/loyaltyPrograms/LoyaltyAndRewardModel';

export default {
    async programs({ rootGetters, commit }): Promise<void> {
        try {
            const _url = 'loyalty-and-reward/programs/';
            const { data } = await rootGetters['network/axios'].get(_url);
            commit('programs', toLoyaltyAndRewardProgramsModel(data));
        } catch (err) {
            logger('LOYALTY_AND_REWARDS_PROGRAMS', err);
            commit('programs');
            throw err.response.data;
        }
    },
    async checkSubscription(
        { rootGetters },
        payload: {
            programId: number;
            body: object;
        },
    ): Promise<any> {
        try {
            const _url = `loyalty-and-reward/programs/${payload.programId}/check-subscription/`;
            const { data } = await rootGetters['network/axios'].post(_url, payload.body);
            return data;
        } catch (err) {
            logger('LOYALTY_CHECK_SUBSCRIPTION', err);
            throw err.response.data;
        }
    },
    async registerSubscription(
        { rootGetters },
        payload: {
            programId: number;
            body: object;
        },
    ): Promise<any> {
        try {
            const _url = `loyalty-and-reward/programs/${payload.programId}/subscription/`;
            const { data } = await rootGetters['network/axios'].post(_url, payload.body);
            return data;
        } catch (err) {
            logger('LOYALTY_REGISTER_SUBSCRIPTION', err);
            throw err.response.data;
        }
    },
    async getRewards(
        { rootGetters },
        payload: {
            programId: number;
            subscriptionId: number;
        },
    ): Promise<any> {
        try {
            const _url = `loyalty-and-reward/programs/${payload.programId}/subscription/${payload.subscriptionId}/reward-balance`;
            const { data } = await rootGetters['network/axios'].get(_url);
            return data;
        } catch (err) {
            logger('LOYALTY_BALANCE', err);
            throw err.response.data;
        }
    },
    async getTransactions(
        { rootGetters },
        payload: {
            programId: number;
            subscriptionId: number;
            size: number;
            page: number;
            newUrl?: string;
        },
    ): Promise<any> {
        try {
            const _url = `loyalty-and-reward/programs/${payload.programId}/subscription/${
                payload.subscriptionId
            }/exchange-transactions/?size=${payload.size ?? 10}&page=${payload.page ?? 0}`;
            const { data } = await rootGetters['network/axios'].get(payload.newUrl ?? _url);
            return data;
        } catch (err) {
            logger('LOYALTY_REGISTER_SUBSCRIPTION', err);
            throw err.response.data;
        }
    },
    async deactivateSubscription(
        { rootGetters },
        payload: { programId: number; subscriptionId: number },
    ): Promise<any> {
        try {
            const _url = `loyalty-and-reward/programs/${payload.programId}/subscription/${payload.subscriptionId}/deactivate`;
            const { data } = await rootGetters['network/axios'].delete(_url);
            return data;
        } catch (err) {
            logger('LOYALTY_DEACTIVATE_SUBSCRIPTION', err);
            throw err.response.data;
        }
    },
    async exchangeTiptiCard(
        { rootGetters },
        payload: { programId: number; subscriptionId: number; body: { total_points: number } },
    ): Promise<any> {
        try {
            const _url = `loyalty-and-reward/programs/${payload.programId}/subscription/${payload.subscriptionId}/exchange/`;
            const { data } = await rootGetters['network/axios'].post(_url, payload.body);
            return data;
        } catch (err) {
            logger('LOYALTY_EXCHANGE_ERROR', err);
            throw err.response.data;
        }
    },
};
