/* eslint-disable @typescript-eslint/no-namespace */
class FiltersModel {
    static GeneralFilterModel = class {
        id: number;
        name: string;

        constructor(data: object) {
            this.id = data['id'];
            this.name = data['name'];
        }

        toJson(): object {
            return { name: this.name, id: this.id };
        }
    };

    readonly states: Array<string>;
    readonly cities: Array<FiltersModel.GeneralFilterModel>;
    readonly retailers: Array<FiltersModel.GeneralFilterModel>;

    constructor(
        states: Array<string>,
        cities: Array<FiltersModel.GeneralFilterModel>,
        retailers: Array<FiltersModel.GeneralFilterModel>,
    ) {
        this.states = states;
        this.cities = cities;
        this.retailers = retailers;
    }
}

// TO RECOGNIZE AS DATA TYPE THE INNER CLASS
namespace FiltersModel {
    export type GeneralFilterModel = typeof FiltersModel.GeneralFilterModel.prototype;
}

const toFiltersModel = (data: object) => {
    return data
        ? new FiltersModel(
              data['states'] ? data['states'].map((state: string) => state) : [],
              data['cities']
                  ? data['cities'].map((city: object) => {
                        return new FiltersModel.GeneralFilterModel(city);
                    })
                  : [],
              data['retailers']
                  ? data['retailers'].map((city: object) => {
                        return new FiltersModel.GeneralFilterModel(city);
                    })
                  : [],
          )
        : null;
};

export { FiltersModel, toFiltersModel };
