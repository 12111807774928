interface TimeDeliveryInterface {
    retailerId: number;
    stockItemId?: number;
    retailerLogo?: string;
    retailerName?: string;
    productName?: string;
    deliveryFirstDate: string;
    originalShippingCost: string;
    shippingCost: string;
    deliverySecondDate?: string;
    express?: boolean;
    deliveryType?: string;
    date?: string;
}

export default class TimeDeliveryModel implements TimeDeliveryInterface {
    retailerId: number;
    stockItemId: number;
    retailerLogo: string;
    retailerName: string;
    productName: string;
    deliveryFirstDate: string;
    deliverySecondDate: string;
    shippingCost: string;
    originalShippingCost: string;
    express: boolean;
    deliveryType: string;
    date: string;

    constructor(data: TimeDeliveryInterface) {
        this.retailerId = data.retailerId;
        this.retailerLogo = data.retailerLogo;
        this.retailerName = data.retailerName;
        this.productName = data.productName;
        this.deliveryFirstDate = data.deliveryFirstDate;
        this.deliverySecondDate = data.deliverySecondDate;
        this.shippingCost = data.shippingCost;
        this.originalShippingCost = data.originalShippingCost;
        this.express = data.express;
        this.deliveryType = data.deliveryType;
        this.date = data.date;
        this.stockItemId = data.stockItemId;
    }

    toJson = () => {
        return {
            retailer_id: this.retailerId,
            delivery_first_date: `${this.date}T${this.deliveryFirstDate}:00-05:00`,
            delivery_second_date: `${this.date}T${this.deliverySecondDate}:00-05:00`,
            shipping_cost: this.shippingCost,
            express: this.express,
            delivery_type: this.deliveryType,
        };
    };
}
