<template>
    <subheader-toggle
        :is-active="isRemovingCards"
        :subtitle="$t('txt.address-user__review-address')"
        @on-pressed="isRemovingCards = !isRemovingCards"
    />
    <base-loading v-if="isLoading" />
    <no-content
        v-else-if="!countryAddresses.length"
        :description="$t('txt.address__no-content-description')"
        :title="$t('txt.address__no-content-title')"
        color-title="#C1C1C1"
    >
        <icon-address :size="2" color="#C1C1C1" />
    </no-content>
    <div v-else>
        <div v-for="country in countryAddresses" :key="country?.id">
            <subheader-separator v-if="countryAddresses?.length > 1" :name="country?.name" :show-btn="false" />
            <div class="addresses">
                <base-card-flat
                    v-for="address in country?.addresses"
                    :key="address.id"
                    class="address cursor-pointer"
                    :class="{
                        'address--delete': isRemovingCards,
                        'address--selected': !isSelected(address) && !isRemovingCards,
                    }"
                    :border-color="isRemovingCards || isSelected(address) ? '#C7C7C7' : '#FF901230'"
                    @click="
                        !isRemovingCards && !showCheck ? openModal(address) : isRemovingCards ? null : onSelect(address)
                    "
                >
                    <div v-if="isRemovingCards" class="address__icons">
                        <div class="icons icons__delete">
                            <icon-trash :size="1.5" @click="deleteAddress(address.id)" />
                        </div>
                        <div class="icons icons__edit">
                            <icon-edit :size="0.8" @click="openModal(address)" />
                        </div>
                    </div>
                    <header class="address-header">
                        <icon-address
                            :color="!isSelected(address) && !isRemovingCards ? '#ff9012' : '#707070'"
                            :size="0.4"
                        />
                        <h2
                            :class="{
                                'address-header__title-orange': !isSelected(address) && !isRemovingCards,
                            }"
                        >
                            {{ address.comment }}
                        </h2>
                        <p v-if="address.isDefaultAddress">{{ $t("general['default']") }}</p>
                        <icon-check v-if="!isSelected(address) && !address.isDefaultAddress && !isRemovingCards" />
                    </header>
                    <p class="address__data">
                        {{ `${address?.addressLine1 ?? ''}, ${address?.addressLine2 ?? ''}` }}
                    </p>
                    <p class="address__data">{{ address?.city?.name ?? '' }}, {{ address?.sector?.name ?? '' }}</p>
                    <p class="address__reference">{{ address?.reference ?? '' }}</p>
                </base-card-flat>
            </div>
        </div>
    </div>
    <tipti-map-modal v-if="showAddressModal" :section="section" is-edit-address @on-close="showAddressModal = false" />
    <snack-bar
        :body="snackBarMessage"
        :is-active="showSnackBar"
        :is-failure="isFailure"
        :is-success="isSuccess"
        @on-snackbar-close="showSnackBar = false"
    />
</template>

<script lang="ts">
import { AddressModel } from '@/models/address/AddressModel';
import { mapActions, mapGetters } from 'vuex';
import { logger } from '@/utils/logger';
import { CountryAddressesModel } from '@/models/address/CountryAddressesModel';

import IconAddress from '@/components/icons/IconAddress.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';
import IconCheck from '@/components/icons/IconCheck.vue';
import SubheaderToggle from '@/components/subheaders/subheaderToggle/SubheaderToggle.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import TiptiMapModal from '@/components/modal/tiptiMapModal/TiptiMapModal.vue';
import IconTrash from '@/components/icons/IconTrash.vue';
import IconEdit from '@/components/icons/IconEdit.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'ListAddress',
    components: {
        BaseCardFlat,
        IconAddress,
        SnackBar,
        BaseLoading,
        SubheaderSeparator,
        IconCheck,
        SubheaderToggle,
        NoContent,
        TiptiMapModal,
        IconTrash,
        IconEdit,
    },
    props: {
        showCheck: {
            type: Boolean,
            default: false,
        },
        section: {
            type: String,
            required: false,
        },
    },
    emits: ['on-check'],
    data() {
        return {
            editAddress: false,
            showAddressModal: false,
            showSnackBar: false,
            isSuccess: false,
            isFailure: false,
            snackBarMessage: '',
            isLoading: true,
            addressSelectedId: null,
            isRemovingCards: false,
            selectedAddress: null,
        };
    },
    computed: {
        ...mapGetters({
            locationSelected: 'user/userLocation',
            countryAddresses: 'location/countryAddresses',
        }),
    },
    methods: {
        ...mapActions({ fetchAddress: 'location/countryAddresses', fetchProfile: 'user/getProfile' }),
        async deleteAddress(id: number): Promise<void> {
            try {
                if (
                    this.countryAddresses?.map((country: CountryAddressesModel) => country.addresses)?.flat()
                        ?.length === 1
                ) {
                    this.isFailure = true;
                    this.snackBarMessage = this.$t("txt['address__tipti-needs-at']");
                    return;
                }
                this.isLoading = true;
                this.addressSelectedId = id;
                await this.$store.dispatch('location/deleteAddress', id);
                this.showSnackBar = true;
                this.isSuccess = true;
                this.snackBarMessage = this.$t("txt['address__delete-address-success']");
                if (this.locationSelected?.id === this.addressSelectedId) {
                    this.$store.commit('user/userLocation', undefined);
                    if (this.$route.name !== appRoutesMap.home) this.$router.push({ name: appRoutesMap.home });
                }
                await this.fetchAddress();
                await this.fetchProfile();
            } catch (err) {
                this.isFailure = true;
                this.snackBarMessage = this.$t("txt['address__delete-address-failed']");
                logger('DELETE_ADDRESS', err);
            } finally {
                this.showSnackBar = true;
                this.isLoading = false;
            }
        },
        openModal(address: AddressModel): void {
            this.showAddressModal = !this.showAddressModal;
            this.$store.commit('location/addressToEdit', address);
        },
        onSelect(address: AddressModel): void {
            this.selectedAddress = address;
            this.$emit('on-check', address);
        },
        isSelected(address: AddressModel): boolean {
            return this.selectedAddress?.id != address.id;
        },
    },
    async mounted() {
        await this.fetchAddress();
        this.selectedAddress = this.locationSelected;
        this.isLoading = false;
    },
};
</script>
<style lang="scss" scoped>
@import './list-address';
</style>
