import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-677b2b1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "success-cart-bar__text" }
const _hoisted_2 = { class: "cart-bar__legend" }
const _hoisted_3 = { class: "cart-bar__amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_check_success = _resolveComponent("icon-check-success")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_progress_bar = _resolveComponent("progress-bar")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!
  const _component_general_alert = _resolveComponent("general-alert")!
  const _component_product_benefits = _resolveComponent("product-benefits")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$data.wasShowed)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          ($data.showSuccess)
            ? (_openBlock(), _createBlock(_component_base_card, {
                key: 0,
                class: "success-cart-bar",
                "bg-color": "#ff9012",
                "border-radius": "7px",
                padding: "0.1rem"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, _toDisplayString($options.getSuccessMessage), 1),
                  _createVNode(_component_icon_check_success, { class: "success-cart-bar__icon" })
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_base_card_flat, {
                key: 1,
                class: "cart-bar",
                "border-color": "#ff9012",
                "border-width": "2px",
                padding: "0.3rem"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_2, [
                    _createTextVNode(_toDisplayString($options.getMessage($data.localBenefit.message, 0)) + " ", 1),
                    _createElementVNode("span", _hoisted_3, _toDisplayString($options.getMessage($data.localBenefit.message, 1)), 1),
                    _createTextVNode(" " + _toDisplayString($options.getMessage($data.localBenefit.message, 2)), 1)
                  ]),
                  _createVNode(_component_progress_bar, {
                    "minimum-amount": $options.minimumAmount,
                    progress: $options.progress,
                    "background-color": "#dfdfdf"
                  }, null, 8, ["minimum-amount", "progress"])
                ]),
                _: 1
              }))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.showCartBarAlert)
      ? (_openBlock(), _createBlock(_component_general_alert, {
          key: 1,
          "accent-header": true,
          message: _ctx.$t('txt.cart-bar__cashback-content'),
          "only-confirmation-btn": true,
          "show-close-icon": true,
          title: _ctx.$t('txt.cart-bar__cashback-title'),
          onOnClose: $options.closeAlert,
          onOnAccept: $options.closeAlert
        }, null, 8, ["message", "title", "onOnClose", "onOnAccept"]))
      : _createCommentVNode("", true),
    ($data.product && $data.showSuccess)
      ? (_openBlock(), _createBlock(_component_product_benefits, {
          key: 2,
          goal: $data.localBenefit,
          "retailer-id": $props.retailerId,
          "retailer-name": $props.retailerName,
          onOnClose: _cache[0] || (_cache[0] = 
            () => {
                $data.localBenefit = undefined;
                $data.showSuccess = false;
                $data.product = undefined;
                $data.wasShowed = true;
            }
        )
        }, null, 8, ["goal", "retailer-id", "retailer-name"]))
      : _createCommentVNode("", true)
  ], 64))
}