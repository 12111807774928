<template>
    <alert-manager
        v-if="showAlert"
        :showButtons="true"
        :title="$t('txt.credit_card__card_expired')"
        :declineButtonText="$t('txt.notifications__change-method')"
        :acceptButtonText="$t('txt.credit-card__add-card')"
        :showBottomSheetTitle="false"
        :ctaHeaderText="$t('general.skip')"
        @on-dismiss="$emit('on-cancel')"
        @on-decline="$emit('on-cancel')"
        @on-close="$emit('on-cancel')"
        @on-close-modal="$emit('on-cancel')"
        @on-omit="$emit('on-cancel')"
        @on-accept="$emit('on-accept')"
        :isLoading="false"
        :showCloseIcon="true"
        :accentHeader="true"
        :isDisabled="false"
    >
        <template v-slot:content>
            <icon-circular-close :iconName="$t('icon.error')" class="expired-card-icon" :size="1" color="#F03F51" />
            <div class="expired-card-content">
                <p>{{ $t('txt.credit_card__card_expired_messaje') }}</p>
                <p class="expired-card-content__advisor">{{ $t('txt.credit_card__remember_expired') }}</p>
            </div>
            <div class="expired-card-mobile">
                <video class="expired-card-mobile__img" autoplay="true" loop="true">
                    <source src="@/assets/gif/video_error.mp4" type="video/mp4" />
                </video>
                <div class="expired-card-mobile-content">
                    <h3 class="expired-card-mobile-content__title">{{ $t('txt.credit_card__card_expired') }}</h3>
                    <p>{{ $t('txt.credit_card__card_expired_messaje') }}</p>
                    <p class="expired-card-mobile-content__advisor">{{ $t('txt.credit_card__remember_expired') }}</p>
                </div>
            </div>
        </template>
    </alert-manager>
</template>

<script lang="ts">
import AlertManager from '@/components/alerts/alertManager/AlertManager.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
export default {
    components: { AlertManager, IconCircularClose },
    name: 'expired-card',
    props: {
        showAlert: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['on-cancel', 'on-accept'],
};
</script>

<style lang="scss" scoped>
@import 'expired-card.scss';
</style>
