import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    idgGiftCardsToUse: [],
    paymentsToShowGiftCard: undefined,
    toShowGiftCards: undefined,
};
export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state,
};
