<template>
    <section ref="headerRef" class="skeleton-subheader">
        <div class="skeleton-subheader__title">
            <base-bone :is-show="true"></base-bone>
        </div>
        <div v-if="showSkeletonBtn" class="skeleton-subheader__button">
            <base-bone :is-show="true"></base-bone>
        </div>
    </section>
</template>

<script lang="ts" setup>
import BaseBone from '../BaseBone.vue';
import { ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

defineProps({
    showSkeletonBtn: {
        default: true,
        type: Boolean,
    },
});

const $emit = defineEmits<{
    (e: 'is-visible', value: boolean): void;
}>();
const headerRef = ref<HTMLElement>();

useIntersectionObserver(
    headerRef,
    ([{ isIntersecting }]) => {
        $emit('is-visible', isIntersecting);
    },
    {
        rootMargin: '200px',
        immediate: true,
    },
);
</script>

<style lang="scss" scoped>
@import './skeleton-subheader.scss';
</style>
