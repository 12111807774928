<template>
    <section class="tipti-card-options-items" :class="{ 'tipti-card-options-items--more-columns': showMaxiDollars }">
        <base-card
            v-for="(item, index) in tiptiCardItems"
            :key="index"
            class="tipti-card-options--cards tipti-card-options--base-card"
            padding="0.5rem"
            @click="changeOption(item)"
        >
            <div class="tipti-card-options__icons">
                <icon-tipti-card-send-gif-card v-if="item.id === 'send_gifcard'" :size="size" />
                <icon-tipti-card-buy-recharge v-if="item.id === 'buy_recharge'" :size="size" />
                <icon-tipti-card-my-gif-card v-if="item.id === 'my_giftcard'" :size="size" />
                <icon-tipti-card-transfer-amount v-if="item.id === 'transfer_amount'" :size="size" />
                <icon-exchange v-if="item.id === 'maxi_dollars'" :size="size" />
            </div>
            <p class="tipti-card-options--content">{{ item.title }}</p>
            <icon-arrow
                v-if="!isMobile"
                :color="activeOption === item.id ? 'orange' : '#606060'"
                :icon-name="$t('icon.forward')"
                :size="0.5"
            />
        </base-card>
    </section>
</template>

<script lang="ts" setup>
import { isMobileBrowser } from '@/utils/utils';
import BaseCard from '@/components/cards/BaseCard.vue';
import IconTiptiCardSendGifCard from '@/components/icons/IconTiptiCardSendGifCard.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import IconTiptiCardBuyRecharge from '@/components/icons/IconTiptiCardBuyRecharge.vue';
import IconTiptiCardMyGifCard from '@/components/icons/IconTiptiCardMyGifCard.vue';
import IconTiptiCardTransferAmount from '@/components/icons/IconTiptiCardTransferAmount.vue';
import { useUser } from '@/composables/useUser';
import IconExchange from '@/components/icons/IconExchange.vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { appRoutesMap } from '@/router/appRoutesMap';

const { userLocation } = useUser();
const $router = useRouter();
const isMobile = computed(() => isMobileBrowser());
const activeOption = ref('');
const { t: $t } = useI18n();

const tiptiCardItems = ref([
    {
        id: 'send_gifcard',
        title: $t("txt['tipti-card__send-gift-card']"),
        route: appRoutesMap.tiptiCardRoutes.gift,
    },
    {
        id: 'buy_recharge',
        title: $t("txt['tipti-card__buy-recharge']"),
        route: appRoutesMap.tiptiCardRoutes.recharge,
    },
    {
        id: 'my_giftcard',
        title: $t("txt['tipti-card__my-gift-card']"),
        route: appRoutesMap.tiptiCardRoutes.myGifts,
    },
    {
        id: 'transfer_amount',
        title: $t("txt['tipti-card__transfer-balance']"),
        route: appRoutesMap.tiptiCardRoutes.transfer,
    },
]);

const size = computed(() => (isMobile.value ? 0.4 : 0.7));

const showMaxiDollars = computed(() => userLocation.value?.city?.country?.alphaCode === 'EC');

const changeOption = (option: object) => {
    activeOption.value = option['id'];
    $router.push({ name: option['route'] });
};

if (showMaxiDollars.value)
    tiptiCardItems.value.push({
        id: 'maxi_dollars',
        title: $t('txt.tipti-card__convert-maxi'),
        route: appRoutesMap.tiptiCardRoutes.maxiCard,
    });
</script>

<style lang="scss" scoped>
@import './tipti-card-options.scss';
</style>
