<template>
    <div class="signin-container">
        <div class="header">
            <p class="header__text">{{ $t("txt['register__register-register']") }}</p>
            <icon-circular-close class="header__close" :size="0.8" @click="$emit('on-close')" />
        </div>
        <form class="register" @submit.prevent="register">
            <div class="register__inputs">
                <tipti-input-text
                    v-model="name"
                    :errors="notValidNameSurname ? `${$t('txt.register__enter-name-lastname')}` : ''"
                    :label="$t('general.name-and-surname')"
                    is-required
                    placeholder="Ingresa aquí tu nombre"
                />
                <tipti-input-text
                    v-model="email"
                    class="email--separator"
                    is-required
                    label="email"
                    placeholder="Ingresa aquí tu e-mail"
                />
                <TiptiInputPassword
                    v-model="password"
                    is-required
                    label="contraseña"
                    placeholder="Ingresa aquí la contraseña para tu cuenta"
                />
            </div>
            <div class="register__actions">
                <div class="register__actions__btn-container">
                    <btn-solid :txt="$t('txt.register__register-register')" />
                </div>
                <div class="register__actions__to-login">
                    <p>{{ $t("txt['register__already-have-account']") }}</p>
                    <base-link :txt-link="$t('cta.log-in')" @click="$emit('on-switch')" />
                </div>
                <separator-text class="option-separator" text="o" />
                <p class="social-header">{{ $t('general.general-register-with') }}</p>
                <div class="social-btn">
                    <the-google @on-submit="loginGoogle" />
                    <the-facebook :is-login="false" @click="loginFacebook" />
                </div>
            </div>
        </form>
    </div>
    <alert-pdf
        v-if="showTCPdf"
        :title="$t('general.terms-and-conditions')"
        :url="exec(countrySelected.termsAndConditions)"
        @close="showTCPdf = false"
    />
    <modal-acceptation
        v-if="showAcceptationModal"
        :is-loading="isLoading"
        :register-fields="{ name: name, email: email }"
        @on-accept="acceptTerms"
        @on-decline="declineTerms"
        @on-unmonted-modal="unmountedModal"
    />
</template>

<script lang="ts">
import { mapActions } from 'vuex';
import { logger } from '@/utils/logger';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import RegisterModel from '@/models/user/RegisterModel';
import TheFacebook from '../TheFacebook.vue';
import ModalAcceptation from '../modalAcceptation/ModalAcceptation.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import TheGoogle from '../TheGoogle.vue';
import AlertPdf from '@/components/alerts/alertPdf/AlertPdf.vue';
import BaseLink from '@/components/links/BaseLink.vue';
import SeparatorText from '@/components/alerts/alertPurchase/SeparatorText.vue';
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';
import { appRoutesMap } from '@/router/appRoutesMap';
import TiptiInputPassword from '@/components/inputs/primary/normal/TiptiInputPassword.vue';
import { useApp } from '@/composables/useApp';
import { useUser } from '@/composables/useUser';
import { useTranslate } from '@/composables/UseTranslate';

export default {
    components: {
        TiptiInputPassword,
        AlertPdf,
        BtnSolid,
        TheFacebook,
        ModalAcceptation,
        IconCircularClose,
        TheGoogle,
        TiptiInputText,
        BaseLink,
        SeparatorText,
    },
    emits: ['on-switch', 'on-close'],
    setup() {
        const { profile, isAuth, countrySelected } = useUser();
        const { notifier } = useApp();
        const { exec } = useTranslate();
        return {
            notifier,
            profile,
            countrySelected,
            isAuth,
            exec,
        };
    },
    data() {
        return {
            showTCPdf: false,
            name: '',
            email: '',
            password: '',
            googleToken: '',
            emailEmpty: false,
            nameEmpty: false,
            passwordEmpty: false,
            notValidNameSurname: false,
            userData: null,
            showAcceptationModal: false,
            isLoading: false,
            isFacebookRegister: false,
            isGoogleRegister: false,
        };
    },
    methods: {
        ...mapActions({
            fetchProfileData: 'user/getProfile',
        }),
        async fireRegisterEvent(type: string): Promise<void> {
            try {
                this.$store.dispatch('segment/identify', type);
                this.$store.dispatch('segment/signUp', {
                    source: {
                        source_id: type,
                        source: type,
                    },
                });
                this.$store.dispatch('segment/detailsPopulated', {
                    source: {
                        source_id: type,
                        source: type,
                    },
                });
            } catch (err) {
                logger('FIRE_REGISTER_EVENT', err);
            }
        },
        async loginFacebook(): Promise<void> {
            this.isFacebookRegister = true;
            this.isGoogleRegister = false;
            this.showAcceptationModal = true;
            logger('PRESS_FB', this.isFacebookRegister);
        },
        async loginGoogle(token: string): Promise<void> {
            this.isGoogleRegister = true;
            this.isFacebookRegister = false;
            this.showAcceptationModal = true;
            this.googleToken = token;
        },
        async register(): Promise<void> {
            if (this.isGoogleRegister || this.isFacebookRegister) return;
            this.nameEmpty = false;
            this.notValidNameSurname = false;
            if (!this.email?.trim()?.length) this.emailEmpty = true;
            if (!this.name?.trim()?.length) this.nameEmpty = true;
            if (!this.password?.trim()?.length) this.passwordEmpty = true;
            if (this.name.trim()?.split(' ')?.length === 1) this.notValidNameSurname = true;
            if (
                this.email?.trim()?.length &&
                this.name?.trim()?.length &&
                this.password?.trim()?.length &&
                this.name.trim()?.split(' ')?.length > 1
            ) {
                this.showAcceptationModal = true;
                this.userData = new RegisterModel(this.name, this.email, this.password);
            }
        },
        async acceptTerms() {
            try {
                if (this.isLoading) return;
                this.isLoading = true;
                if (this.isFacebookRegister) {
                    await this.$store.dispatch('user/loginFB');
                    await this.fireRegisterEvent('FACEBOOK');
                } else if (this.isGoogleRegister) {
                    await this.$store.dispatch('user/loginGoogle', this.googleToken);
                    this.$store.dispatch('segment/createNewUser', {
                        name: this.name,
                        email: this.email,
                        provider: 'GOOGLE',
                    });
                    await this.fireRegisterEvent('GOOGLE');
                } else {
                    await this.$store.dispatch('user/register', this.userData);
                    await this.fireRegisterEvent('REGULAR');
                }
                this.showAcceptationModal = false;
                this.$store.commit('user/requireAuth', false);
                this.$store.dispatch('location/updateAddresses', []);
                await this.$router.push({ name: appRoutesMap.home });
            } catch (err) {
                if (this.isFacebookRegister || this.isGoogleRegister)
                    return this.notifier({
                        type: 'ERROR',
                        body: this.$t('alerts.register_social_error'),
                    });
                if (err.data?.message?.toLowerCase() === 'a user with that email already exists')
                    return this.notifier({
                        type: 'ERROR',
                        body: this.$t('alerts.register_user_exists'),
                    });
                this.notifier({
                    type: 'ERROR',
                    body: this.$t('alerts.register_user_error'),
                });
            } finally {
                this.isLoading = false;
            }
        },
        declineTerms() {
            this.showAcceptationModal = false;
        },
        unmountedModal() {
            if (this.isFacebookRegister) {
                this.emailEmpty = false;
                this.nameEmpty = false;
                this.passwordEmpty = false;
                this.notValidNameSurname = false;
                this.isFacebookRegister = false;
            }
        },
    },
    mounted() {
        this.googleToken = '';
    },
};
</script>

<style lang="scss" scoped>
@import './register.scss';
</style>
