<template>
    <subheader-separator
        v-if="promotions?.length && !isBannerInSectionProp"
        class="promotions-sub-header"
        :name="$t('general.promotions')"
        :show-btn="false"
    />
    <CarouselBase :items="promotions" :skeletons="5" :state="state" autoplay @reached-end="fetchTheBanners">
        <template #item="{ item: promotion }">
            <BannerPromotion :banner="promotion" :is-banner-in-section-prop="isBannerInSectionProp" />
        </template>
        <template #skeleton>
            <BaseBone
                class="promotion promotion--skeleton"
                :class="{ 'promotion__mini promotion__mini--skeleton': isBannerInSectionProp }"
            />
        </template>
    </CarouselBase>
</template>

<script lang="ts" setup>
import { BannerModel } from '@/models/promotions/banners/BannerModel';
import { logger } from '@/utils/logger';
import SubheaderSeparator from '@/components/subheaders/subheaderSeparator/SubheaderSeparator.vue';
import CarouselBase from '@/components/carousels/components/CarouselBase.vue';
import { useCategories } from '@/modules/main-products/composables/useCategories';
import { reactive, ref } from 'vue';
import { RequestState } from '@/modules/core/types/WorkingState';
import { useApp } from '@/composables/useApp';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import BannerPromotion from '@/components/banners/thePromotionsBanner/BannerPromotion.vue';
import { useAffiliationAlert } from '@/modules/affiliation/composables/useAffiliationAlert';

const props = defineProps({
    isBannerInSectionProp: {
        type: Boolean,
        default: false,
    },
});
const $emit = defineEmits<(e: 'on-banners-fetch', value: number) => void>();
const { category } = useCategories();
const { $store, $route } = useApp();
const page = ref(0);
const state = ref<RequestState>('FIRST_LOAD');
const promotions = reactive<BannerModel[]>([]);
const { bannerUserAffiliateAlert, bannerSectionName } = useAffiliationAlert();

const fetchTheBanners = async (): Promise<void> => {
    try {
        if (
            state.value === 'LOAD-ENDED' ||
            state.value === 'LOADING' ||
            (state.value === 'FIRST_LOAD' && page.value === 1)
        )
            return;
        state.value = page.value === 1 ? 'FIRST_LOAD' : 'LOADING';
        page.value++;

        const response = await $store.dispatch('promotions/promotionalBanners', {
            isBannerInSection: props.isBannerInSectionProp,
            page: page.value,
            categoryId: category.value?.id,
        });
        /// Filter to prevent show in home banners from categories
        const banners = category.value?.id
            ? response['banners'].filter(
                  (banner) =>
                      banner.appSectionAppear?.name === '/inside_category' &&
                      banner.categoriesToShow?.includes(+category.value?.id),
              )
            : response['banners'].filter(
                  (banner) => !['/inside_category', bannerSectionName].includes(banner.appSectionAppear?.name),
              );

        if (props.isBannerInSectionProp && !category.value?.id)
            bannerUserAffiliateAlert.value = response?.banners?.find(
                (banner) => banner.appSectionAppear?.name === bannerSectionName,
            );

        promotions.push(...banners);
        $emit('on-banners-fetch', promotions.length);
        state.value = response['isLastPage'] ? 'LOAD-ENDED' : 'NONE';

        $store.dispatch('segment/bannerImpressions', {
            banner: banners.map((element) => {
                return {
                    banner_id: element.id,
                    banner_type: element.type,
                    banner: element.title,
                    banner_name: element.name,
                };
            }),
            origin: {
                screen: $route.path,
                section: props.isBannerInSectionProp ? 'HOME_BANNER_IN_SECTION' : 'HOME_BANNER',
            },
        });
    } catch (err) {
        $emit('on-banners-fetch', promotions.length);
        logger('FETCH_BANNERS', err);
        state.value = 'LOAD-ENDED';
    }
};

fetchTheBanners();
</script>
<style lang="scss" scoped>
@import './the-promotions-banner.scss';
</style>
<style lang="scss">
@import '@/assets/scss/index.scss';

.carousel__pagination-button {
    background-color: $disable;
    height: 10px;
    width: 10px;
    border-radius: $radius-circular;
    @include phone {
        margin: 1px;
    }
}

.carousel__pagination-button--active {
    background-color: $orange !important;
}

.carousel__pagination {
    margin: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
</style>
