<template>
    <div ref="shareRef" class="share-area">
        <base-card class="share-button" padding="0.75em" @click="showOptions = !showOptions">
            <icon-tipti-card color="#FF9012" />
            <p class="share-button__cta-text">{{ $t('txt.tipti-card__btn-share') }}</p>
        </base-card>
        <div v-if="showOptions" class="share-button__sharable-buttons">
            <share-buttons
                :link="tiptiCard.cardNumber.replace(/\s/g, '')"
                :mail-subject="$t('txt.tipti-card__txt-mail-subject')"
                :message-share="
                    $t('txt.tipti-card__txt-share', {
                        userName: profile.name ?? '',
                    })
                "
                :share-link="false"
            />
        </div>
        <div v-if="showOptions" class="share-button__arrow" />
    </div>
</template>

<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import IconTiptiCard from '@/components/icons/IconTiptiCard.vue';
import ShareButtons from '@/components/buttons/ShareButtons.vue';
import { ref } from 'vue';
import { useUser } from '@/composables/useUser';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { onClickOutside } from '@vueuse/core';

const { profile } = useUser();
const { tiptiCard } = useTiptiCard();

const showOptions = ref(false);
const shareRef = ref<HTMLElement>();

onClickOutside(shareRef, () => (showOptions.value = false));
</script>

<style lang="scss" scoped>
@import './share-button-tipti-card.scss';
</style>
