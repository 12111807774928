import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_terms_and_conditions = _resolveComponent("alert-terms-and-conditions")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return ($props.showTermsAndConditions)
    ? (_openBlock(), _createBlock(_component_base_modal, {
        key: 0,
        "has-back-arrow": false,
        "header-text-color": "#ff9012",
        title: _ctx.$t('txt.prime__alert-terms'),
        onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close')))
      }, {
        default: _withCtx(() => [
          ($props.showTermsAndConditions)
            ? (_openBlock(), _createBlock(_component_alert_terms_and_conditions, {
                key: 0,
                onSnackBarData: $options.snackData,
                onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close'))),
                onlyView: $props.onlyView
              }, null, 8, ["onSnackBarData", "onlyView"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}