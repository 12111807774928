import { useStore } from 'vuex';
import { computed } from 'vue';
import { TiptiCardModel } from '@/models/tiptiCard/TiptiCardModel';

export const useTiptiCard = () => {
    const $store = useStore();

    const tiptiCard = computed<TiptiCardModel>({
        get: () => $store.getters['tiptiCard/tiptiCard'],
        set: (value) => $store.commit('tiptiCard/tiptiCard', value),
    });
    const totalToPay = computed<number>({
        get: () => $store.getters['tiptiCard/totalToPay'],
        set: (value) => $store.commit('tiptiCard/totalToPay', value),
    });

    const fetchTiptiCard = async (): Promise<void> => $store.dispatch('tiptiCard/tiptiCard');

    return {
        tiptiCard,
        fetchTiptiCard,
        totalToPay,
        availableAmount: computed(() => tiptiCard.value?.availableAmount ?? 0),
    };
};
