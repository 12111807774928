<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#FF9012',
    },
});
const width = computed<number>(() => 34 * props.size);
const height = computed<number>(() => 34 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0 34 34">
        <path
            :fill="color"
            clip-rule="evenodd"
            d="M19.6314 0.930614C16.6241 1.52933 14.6355 3.25243 13.7508 6.02598C13.4263 7.04343 13.3391 7.96821 13.3386 10.3949L13.3381 12.6851L11.0666 12.6863C8.55114 12.6876 8.44403 12.7064 8.2789 13.1737C8.13978 13.5677 8.13978 18.6118 8.2789 19.0087C8.43344 19.4495 8.64708 19.4854 11.15 19.4906L13.3381 19.4952V26.4628V33.4304L13.5391 33.6579L13.7402 33.8854L16.7274 33.9105C19.9466 33.9375 20.2451 33.9089 20.4095 33.558C20.481 33.4055 20.5066 31.5131 20.5066 26.3855V19.4203L23.2961 19.3969C25.8439 19.3756 26.0986 19.361 26.2344 19.2288C26.3719 19.095 26.3831 18.8537 26.3831 16.049V13.014L26.1933 12.8293C26.0046 12.6456 25.9893 12.6444 23.2481 12.6212L20.4925 12.5977L20.5209 10.4322C20.5514 8.10377 20.5708 7.99464 21.0285 7.57218C21.3777 7.24992 21.5796 7.22219 23.9107 7.17638L26.1893 7.13171L26.3905 6.90423L26.5915 6.67674V3.92119V1.16556L26.3869 0.96661L26.1823 0.767578L23.2819 0.774388C20.8104 0.780225 20.2706 0.803331 19.6314 0.930614Z"
            fill-rule="evenodd"
        />
    </BaseIcon>
</template>
