import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-422e88aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "countdown" }
const _hoisted_2 = ["datetime"]
const _hoisted_3 = { class: "clock-section__digit" }
const _hoisted_4 = { class: "clock-section__digit" }
const _hoisted_5 = { class: "clock-section__digit" }
const _hoisted_6 = { class: "clock-section__digit" }
const _hoisted_7 = { class: "clock-section__digit" }
const _hoisted_8 = { class: "clock-section__digit" }
const _hoisted_9 = { class: "clock-period" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", {
      class: "countdown__title",
      style: _normalizeStyle({ color: $options.textColor, backgroundColor: $options.bgColor })
    }, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.selectedStory?.interactiveElement.text.toUpperCase()), 1)
    ], 4),
    _createElementVNode("div", {
      class: "countdown__clock",
      style: _normalizeStyle({ color: $options.textColor, backgroundColor: $options.bgColor })
    }, [
      _createElementVNode("time", {
        class: "clock-section",
        style: _normalizeStyle($options.styleDigits),
        datetime: _ctx.selectedStory?.interactiveElement?.elements[0]?.endDate
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString($options.getDigits($data.days)[0]), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString($options.getDigits($data.days)[1]), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString($options.getDigits($data.hours)[0]), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString($options.getDigits($data.hours)[1]), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString($options.getDigits($data.minutes)[0]), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString($options.getDigits($data.minutes)[1]), 1)
      ], 12, _hoisted_2),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("general['days']")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("general['hours']")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("general['minutes']")), 1)
      ])
    ], 4)
  ]))
}