<template>
    <section class="product-price">
        <div
            v-if="showLabels"
            class="product-price__tag"
            :class="{ 'product-price__tag--margin': !product.highDemand }"
        >
            <p
                v-if="showDiscountCard"
                v-translate.upper="product.discountCard?.title"
                class="product-price__label product-price__label--special"
            />
            <p
                v-else
                v-upper="priceLabel"
                class="product-price__label"
                :class="{
                    'product-price__label--special': product.hasDiscount || product.affiliatedPrice,
                    'product-price__label--normal': !product.hasDiscount && !product.affiliatedPrice,
                }"
            />
            <IndicatorHighDemandOutOfStock
                v-if="!product.available || product.highDemand"
                class="product-price__indicator-item"
                :product="product"
            />
        </div>

        <div class="price-values">
            <div v-if="showDeferredOption" class="max-deferred">
                <p class="max-deferred__month" :class="{ 'header-price-deferred': !showLabels }">
                    {{ deferredValues?.at(0)?.payments }}
                </p>
                <ThePriceValue :header-price-small="!showLabels" :value="deferredValues?.at(0)?.value" type="INLINE" />
            </div>
            <div v-if="showDeferredOption" class="price-values__separator"></div>
            <PriceAndDiscountCard v-if="showDiscountCard" :header-price-small="!showLabels" :product="product" />
            <template v-else>
                <div
                    v-if="product.hasDiscount"
                    class="price-discounted"
                    :class="{
                        'max-deferred__price-discounted--deferred': showDeferredOption,
                        'price-discounted__column': showDeferredOption,
                    }"
                >
                    <ThePriceValue
                        :header-price-small="!showLabels"
                        :value="product.priceWithDiscount"
                        type="DISCOUNTED"
                    />
                    <ThePriceValue :header-price-small="!showLabels" :value="product.price" type="OLD" />
                </div>
                <ThePriceValue v-else :header-price-small="!showLabels" :type="'REGULAR'" :value="product.price" />
            </template>
        </div>
    </section>
</template>
<script lang="ts" setup>
import { ProductModel } from '@/models/product/ProductModel';
import { useTranslate } from '@/composables/UseTranslate';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useXSelling } from '@/modules/extraItems/composables/useXSelling';
import { useImage } from '@/composables/ImageComposable';
import ThePriceValue from '@/components/modal/productModal/components/productModalPrice/ThePriceValue.vue';
import PriceAndDiscountCard from '@/components/modal/productModal/components/productModalPrice/PriceAndDiscountCard.vue';
import { useStore } from 'vuex';
import { useProductTags } from '@/composables/useProductTags';
import { computed, PropType } from 'vue';
import IndicatorHighDemandOutOfStock from '@/components/badges/productIndicators/IndicatorHighDemandOutOfStock.vue';

defineOptions({
    name: 'ProductPrice',
});
const props = defineProps({
    product: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
    showLabels: {
        type: Boolean,
        default: true,
    },
});
const { translate } = useTranslate();
const store = useStore();

const { isExtraItemCart } = useCartType();
const { products: xSellingProducts } = useXSelling();
const { displayDefaultImg } = useImage();
const { priceLabel, showDeferredOption, deferredValues, colorTag } = useProductTags();

const showDiscountCard = computed(() => {
    if (xSellingProducts.value?.length || isExtraItemCart.value) return false;
    return Boolean(props.product?.discountCard);
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.product-price {
    display: flex;
    flex-direction: column;

    &__tag {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $margin-md;

        &--margin {
            margin: $margin-sm 0;
        }
    }

    &__label {
        @include caption;
        color: v-bind(colorTag);
        font-weight: 400;
        @include phone {
            font-size: 14px;
        }

        &--normal {
            @include body2;
            color: v-bind(colorTag);
        }

        &--special {
            @include body2;
            color: v-bind(colorTag);
            font-weight: 600;
            @include phone {
                font-size: 16px;
            }
        }

        &--crossed {
            @include body1;
            text-decoration: line-through;
            margin-left: $margin-sm;
            font-size: 1rem !important;
        }
    }

    &__special {
        @include headline1;
        font-weight: 600;
    }

    &__indicator-item {
        margin-top: $margin-md !important;
    }
}

.price-discounted {
    display: flex;
    align-items: center;
    gap: $margin-sm;

    &--deferred {
        gap: unset;
        flex-direction: column;
        align-items: flex-start;
    }

    &__column {
        flex-direction: column;
    }
}

.max-deferred {
    display: flex;
    flex-direction: column;

    &__month {
        @include subtitle1;
        font-weight: 400;
    }

    &__header-price-deferred {
        font-size: 14px;
    }
}

.price-values {
    display: flex;
    align-items: center;
    gap: $margin-md;
    padding: 0.5rem;

    &__separator {
        align-self: stretch;
        border: 1px solid $border;
        border-radius: 1px;
    }
}

.stock-indicator {
    display: flex;
    align-items: center;
    @include body2;
    font-weight: 600;
    gap: $margin-sm;

    & span {
        color: $error;
        text-transform: capitalize;
    }
}
</style>
