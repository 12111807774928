<template>
    <base-icon :icon-name="label" :width="width" :height="height" viewBox="0 0 114 151" :iconColor="color">
        <path
            d="M99.4287 8.22223H93.3312V7.00118C93.3312 3.33803 90.2824 0.2854 87.2336 0.2854C83.5751 0.895926 81.136 3.33803 81.136 7.00118V7.6117H65.8921V7.00118C64.0629 3.33803 61.0141 0.2854 57.3555 0.2854C53.697 0.2854 51.8677 3.94855 51.8677 7.00118V7.6117H33.5751V7.00118C34.1848 3.94855 31.7458 0.895926 28.0873 0.2854C23.819 0.2854 20.7702 3.33803 20.7702 7.00118V7.6117H15.2824C6.74579 8.83275 -0.571286 16.1591 0.0384702 25.3169V133.38C-0.571286 141.927 6.13603 149.864 15.2824 150.475H98.819C107.356 149.864 114.063 142.538 113.453 133.38V25.3169C114.673 16.1591 107.965 8.83275 99.4287 8.22223ZM103.087 133.38C103.087 135.211 101.258 140.096 98.819 140.096H15.2824C12.2336 140.096 10.4043 136.433 10.4043 133.38V25.3169C10.4043 22.2643 12.2336 18.6012 15.2824 18.6012H20.7702V26.538C20.7702 30.2011 24.4287 31.4222 28.0873 31.4222C30.5263 31.4222 32.9653 29.5906 33.5751 27.1485C33.5751 26.538 33.5751 26.538 33.5751 25.9275V18.6012H51.8677V26.538C51.8677 28.9801 53.697 31.4222 56.136 32.0327C56.7458 32.0327 56.7458 32.0327 57.3555 32.0327C61.0141 32.0327 64.6726 30.2011 64.6726 27.1485V18.6012H79.9165V26.538C79.9165 30.2011 82.9653 31.4222 86.0141 31.4222C89.6726 31.4222 92.1116 29.5906 92.1116 26.538V18.6012H98.2092C101.868 18.6012 102.478 24.7064 102.478 25.3169L103.087 133.38Z"
        />
        <path
            d="M87.2336 44.2446H24.4287C21.9897 44.2446 19.5507 46.0747 18.9409 49.1249C18.9409 51.5651 20.7702 54.0052 23.2092 54.6152C23.819 54.6152 23.819 54.6152 24.4287 54.6152H87.2336C89.6726 54.6152 92.1116 52.7851 92.7214 50.345C92.7214 47.9048 90.8921 45.4647 88.4531 44.8547C88.4531 44.2446 87.8433 44.2446 87.2336 44.2446Z"
        />
        <path
            d="M87.2336 67.4431H24.4287C21.9897 67.4431 19.5507 69.2726 18.9409 71.7119C18.9409 74.1513 20.7702 76.5906 23.2092 77.2004C23.819 77.2004 23.819 77.2004 24.4287 77.2004H87.2336C89.6727 77.2004 92.1117 75.3709 92.7214 72.9316C92.7214 70.4923 90.8922 68.0529 88.4531 67.4431C88.4531 67.4431 87.8434 67.4431 87.2336 67.4431Z"
        />
        <path
            d="M87.2336 91.2534H24.4287C21.9897 91.2534 19.5507 93.0829 18.9409 95.5223C18.9409 97.9616 20.7702 100.401 23.2092 101.011C23.819 101.011 23.819 101.011 24.4287 101.011H87.2336C89.6727 101.011 92.1117 99.1812 92.7214 96.7419C92.7214 94.3026 90.8922 91.8633 88.4531 91.2534C88.4531 91.2534 87.8434 91.2534 87.2336 91.2534Z"
        />
        <path
            d="M56.1356 114.455H25.6478C22.599 114.455 20.7698 118.116 20.7698 120.557C20.7698 122.998 23.2088 126.66 25.6478 126.66H56.1356C59.1844 126.66 61.0137 122.998 61.0137 120.557C61.0137 118.116 59.1844 114.455 56.1356 114.455Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-note',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
        iconName: {
            type: String,
        },
    },
    computed: {
        width(): number {
            return 18 * this.size;
        },
        height(): number {
            return 18 * this.size;
        },
        label(): string {
            return this.iconName ?? this.$t('icon.note');
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
