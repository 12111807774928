<template>
    <base-card class="change-password" padding="0">
        <section-header :has-back="true" :title="$t('cta.change-password')" @on-back="returnEditProfile" />
        <icon-recovery-pass class="change-password__icon" :size="3" />
        <p class="change-password__description">
            {{ $t("txt['edit-profile__enter-your-new']") }}
            <span class="txt--orange">{{ $t("general['password']").toUpperCase() }}</span
            >, {{ $t("txt['edit-profile__remember-that-need']") }}<br />
            {{ $t("txt['edit-profile__between-eight-sixteen']") }}
        </p>
        <form class="change-password__form-container">
            <TiptiInputPassword
                id="old_password"
                v-model="oldPassword"
                :label="$t('txt.edit-profile__enter-old-password')"
                is-required
                placeholder="***************"
            />
            <TiptiInputPassword
                id="new_password"
                v-model="newPassword"
                :label="$t('txt.edit-profile__enter-new-password')"
                is-required
            />
            <TiptiInputPassword
                id="password_confirm"
                v-model="newPasswordConfirm"
                :label="$t('txt.edit-profile__confirm-new-password')"
                is-required
            />
        </form>
        <section-footer>
            <btn-solid :is-loading="isLoading" :txt="btnTxt" @click="saveChanges" />
        </section-footer>
    </base-card>
    <snack-bar
        :body="modalMessage"
        :is-active="showModal"
        :is-failure="isFailure"
        :is-success="isSuccess"
        @on-snackbar-close="closeSnackBar"
    ></snack-bar>
</template>

<script lang="ts">
import BaseCard from '@/components/cards/BaseCard.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import IconRecoveryPass from '@/components/icons/IconRecoveryPass.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import TiptiInputPassword from '@/components/inputs/primary/normal/TiptiInputPassword.vue';

export default {
    name: 'ChangePassword',
    components: {
        TiptiInputPassword,
        BaseCard,
        BtnSolid,
        SnackBar,
        IconRecoveryPass,
        SectionHeader,
        SectionFooter,
    },
    emits: ['on-select-option'],
    data() {
        return {
            btnTxt: this.$t("cta['change-password']"),
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
            modalMessage: '',
            showModal: false,
            isSuccess: false,
            isFailure: false,
            isRequestComplete: undefined,
            isLoading: false,
        };
    },
    methods: {
        async saveChanges(): Promise<void> {
            this.isLoading = true;
            if (this.newPassword === this.newPasswordConfirm) {
                this.isRequestComplete = await this.$store.dispatch('user/setPassword', {
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword,
                });
                this.modalMessage = this.isRequestComplete
                    ? this.$t('txt["edit-profile__password-success-change"]')
                    : this.$t('txt["edit-profile__password-error-change"]');
                this.showModal = true;
                this.isSuccess = this.isRequestComplete;
                this.isFailure = !this.isRequestComplete;
            } else {
                this.modalMessage = this.$t('txt["edit-profile__password-no-match"]');
                this.showModal = true;
            }
            this.isLoading = false;
        },
        closeSnackBar(): void {
            this.showModal = false;
            if (this.isRequestComplete) this.returnEditProfile();
        },
        returnEditProfile(): void {
            this.$emit('on-select-option', 'edit_profile');
        },
    },
};
</script>

<style lang="scss" scoped>
@import './change-password.scss';
</style>
