import { useApp } from '@/composables/useApp';
import { computed } from 'vue';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { useUser } from '@/composables/useUser';

export const useDeeplink = () => {
    const { $store, $t } = useApp();

    const { tiptiCard } = useTiptiCard();
    const { currency, countrySelected } = useUser();
    const showModal = computed<boolean>({
        get: () => $store.getters['deeplink/showModal'],
        set: (value) => $store.commit('deeplink/showModal', value),
    });

    const showRetailerNotFoundModal = computed<boolean>({
        get: () => $store.getters['deeplink/showRetailerNotFoundModal'],
        set: (value) => $store.commit('deeplink/showRetailerNotFoundModal', value),
    });

    const successModal = computed<boolean>(() => $store.getters['deeplink/successModal']);
    const messageModal = computed(() => $store.getters['deeplink/messageModal']);
    const messageFromShowModal = computed(() => {
        switch (messageModal.value) {
            case 'retailers':
                return $t('alerts.retail_out_coverage');
            case 'retailers-not-found':
                return $t('alerts.retail_out_coverage');
            case 'product':
                return $t('alerts.product_not_found');
            case 'tiptiCard':
                return successModal.value
                    ? $t("txt['deeplink-modal__active-tipticard']", {
                          balance: tiptiCard.value?.availableAmount ?? 0,
                          currency: currency.value,
                      })
                    : $t("general['error-to']", {
                          message: $t("general['tipticard-error']"),
                      });
            case 'category':
                return $t('alerts.category_not_found');

            case 'screens':
                return $t("general['error-to']", {
                    message: $t("general['open-screen']"),
                });
            case 'only_recipe':
            case 'recipe':
                return $t('alerts.recipe_not_found');
            case 'recipe_wrong_sector':
                return $t('txt.recipe__no-by-sector');
            case 'codes':
                return successModal.value
                    ? $t("txt['deeplink-modal__active-code-success']")
                    : $t("txt['deeplink-modal__active-code-error']");
            case 'referredCode':
                return successModal.value
                    ? $t("txt['deeplink-modal__active-referred-success']")
                    : $t("txt['deeplink-modal__active-referred-error']");
            case 'banners':
            case 'bannersInSections':
            case 'promotions':
            case 'stories':
                return $t('alerts.retail_not_found');
            case 'NO_AVAILABLE':
                return $t('alerts.link_unavailable', {
                    number: countrySelected.value.mainPhone ?? '',
                });
            default:
                return $t("general['deeplink-modal__general-error']");
        }
    });

    return {
        showModal,
        showRetailerNotFoundModal,
        messageFromShowModal,
        successModal,
    };
};
