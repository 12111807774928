<template>
    <MaintenanceView v-if="currentWork?.image?.length" />
    <template v-else>
        <BoundaryError>
            <div :key="keyRef" style="position: relative">
                <Suspense v-if="!$route.meta?.use_custom_header">
                    <HeaderMain @loaded="showRouterView = true" />
                </Suspense>
                <router-view v-if="showRouterView || $route.meta?.use_custom_header" :key="userLocation?.sector?.id" />
            </div>
            <the-carts />
            <div :class="{ 'predictions-blur': showMainSearchBlurPredictions }" />
            <FooterMain v-if="!$route.meta?.use_custom_footer && showRouterView" />
            <the-product-modal-handler />
            <alert-product-not-found />
            <OrderRejectedAlert v-if="!isScreenResendPayment" />
            <product-snack-bar />
            <notification-box />
            <shipping-limit-alert />
            <alert-unavailable-products-by-location />
            <extra-item-x-selling />
            <NotifierComponent />
            <TheModalProductReplacements v-if="isMainCart" />
        </BoundaryError>
        <Auth v-if="requireAuth" is-register @on-close="requireAuth = false" />
    </template>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { logger } from './utils/logger';
import appboy from '@braze/web-sdk';
import { computed, defineAsyncComponent, onBeforeMount, onMounted, provide, ref, watch, watchEffect } from 'vue';
import { useEventListener, useWindowSize } from '@vueuse/core';
import { useTiptiTAC } from '@/modules/retailers/composables/useTiptiTAC';
import { useRoute } from 'vue-router';
import HeaderMain from '@/components/headers/headerMain/HeaderMain.vue';
import FooterMain from '@/components/footers/footerMain/FooterMain.vue';
import { useUser } from '@/composables/useUser';
import { notifierKey, useNotifier, widthKey } from '@/modules/notification/useNotifier';
import { isFicoaDomain } from '@/utils/utils';
import BoundaryError from '@/modules/core/components/BoundaryError.vue';
import { useSeo } from '@/composables/useSeo';
import OrderRejectedAlert from '@/components/alerts/alertPurchase/OrderRejectedAlert.vue';
import { useRoutes } from '@/composables/useRoutes';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useMaintenance } from '@/composables/useMaintenance';

const { isMainCart } = useCartType();
const TheProductModalHandler = defineAsyncComponent(
    () => import('@/components/modal/productModal/TheProductModalHandler.vue'),
);
const MaintenanceView = defineAsyncComponent(() => import('@/modules/core/components/MaintenanceView.vue'));

const TheCarts = defineAsyncComponent(() => import('@/modules/core/components/cart/TheCarts.vue'));
const AlertUnavailableProductsByLocation = defineAsyncComponent(
    () => import('@/components/alerts/alertUnavailableProductsByLocation/AlertUnavailableProductsByLocation.vue'),
);

const ExtraItemXSelling = defineAsyncComponent(() => import('@/modules/extraItems/components/ExtraItemXSelling.vue'));
const ShippingLimitAlert = defineAsyncComponent(
    () => import('@/components/alerts/shippingLimit/ShippingLimitAlert.vue'),
);
const ProductSnackBar = defineAsyncComponent(() => import('@/components/snackbar/productSnackBar/ProductSnackBar.vue'));
const NotificationBox = defineAsyncComponent(() => import('./components/alerts/notifications/NotificationBox.vue'));

const TheModalProductReplacements = defineAsyncComponent(
    () => import('@/modules/highDemandAndOutOfStock/TheModalProductReplacements.vue'),
);
const AlertProductNotFound = defineAsyncComponent(
    () => import('@/components/alerts/alertProductNotFound/AlertProductNotFound.vue'),
);
const Auth = defineAsyncComponent(() => import('@/views/auth/Auth.vue'));

const $store = useStore();
const { isAuth } = useUser();
const { metaTageHome } = useSeo();
const showMainSearchBlurPredictions = computed(() => $store.getters['search/showMainSearchBlurPredictions']);
const countryCodes = computed(() => $store.getters['external/countryCodes']);
const askForMaintenance = computed({
    get: () => $store.getters['network/askForMaintenance'],
    set: (value: boolean) => $store.commit('network/askForMaintenance', value),
});

const $route = useRoute();
const { fetchData: fetT$C } = useTiptiTAC();
const keyRef = ref(0);
const { NotifierComponent, notify } = useNotifier();
const { width } = useWindowSize();
const { isScreenResendPayment } = useRoutes();
const { currentWork, getPendingWorks } = useMaintenance();
const { userLocation } = useUser();
provide(notifierKey, notify);
provide(widthKey, width);

watch(isAuth, () => {
    keyRef.value++;
    onBeforeMountLogic();
    onMountedLogic();
});

const showRouterView = ref(false);

const onBeforeMountLogic = () => {
    if ($route.meta.fetch_tipti_tc) fetT$C();
    $store.dispatch('location/getMapData');
    $store.commit('alertNews/closeNewsAlertInRetailers', false);
    $store.commit('alertNews/closeNewsAlertInCart', false);
    $store.commit('cart/showCart', false);
    $store.commit('product/deactivatedProducts', null);
    $store.commit('product/suggestionsForUnAvailableProducts', null);
    $store.commit('cart/cleanLastProductAdded');
    $store.commit('user/requireAuth', false);
    $store.commit('orders/orderRateSelected');
};

onBeforeMount(() => {
    onBeforeMountLogic();
});

const requireAuth = computed({
    get: () => $store.getters['user/requireAuth'],
    set: (value) => $store.commit('user/requireAuth', value),
});

const onMountedLogic = () => {
    navigator.permissions
        .query({ name: 'geolocation' })
        .then((result) => {
            $store.commit('network/geoLocalizationState', result.state === 'granted');
        })
        .catch(() => $store.commit('network/geoLocalizationState', false));
    setBraze();

    $store.dispatch('external/updateCareTeamChatByCountry');
    $store.commit('network/connectionStatus', window.navigator.onLine);

    if (!isAuth.value) return;
    if (isFicoaDomain()) return;
    /// * update polygons data;
    if (countryCodes.value?.length) return;
    $store.dispatch('external/getCountryPhones');
};

onMounted(() => {
    metaTageHome();
    onMountedLogic();
});

watchEffect(() => {
    if (askForMaintenance.value) {
        getPendingWorks();
        askForMaintenance.value = false;
    }
});

caches.keys().then(function (keyList) {
    return Promise.all(
        keyList.map(function (key) {
            return caches.delete(key);
        }),
    );
});
useEventListener(document, 'offline', () => $store.commit('network/connectionStatus', false));
useEventListener(document, 'online', () => $store.commit('network/connectionStatus', true));

const setBraze = async () => {
    try {
        /// * Handle braze only in STG and PROD enviroments;
        if (process.env.VUE_APP_API_ENV === 'DEV') return;
        if (!isAuth.value) return;
        const brazeApiKey =
            process.env.VUE_APP_API_ENV === 'PROD'
                ? process.env.VUE_APP_BRAZE_API_KEY_PROD
                : process.env.VUE_APP_BRAZE_API_KEY_DEV;
        const safariPushId =
            process.env.VUE_APP_API_ENV === 'PROD'
                ? process.env.VUE_APP_BRAZE_SAFARI_PROD
                : process.env.VUE_APP_BRAZE_SAFARI_DEV;

        // *** initialize the SDK
        appboy.initialize(brazeApiKey, {
            baseUrl: process.env.VUE_APP_BRAZE_ENDPOINT,
            enableLogging: process.env.VUE_APP_API_ENV !== 'PROD',
            safariWebsitePushId: safariPushId,
        });
        // *** show all In-App Messages without custom handling
        appboy.display.automaticallyShowNewInAppMessages();
        appboy.openSession();
        appboy.registerAppboyPushMessages();
    } catch (err) {
        logger('SET_BRAZE', err);
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/index.scss';

* *::after *::before {
    box-sizing: border-box;
}

p {
    line-height: 1.3;
}

.separator-sm {
    height: $margin-sm;
}

.separator {
    height: $margin-md;
}

.separator-lg {
    height: $margin-lg;
}

.predictions-blur {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all ease-in-out 300ms;
}

a {
    text-decoration: none;
}

.label-error {
    @include overline;
    color: $error;
}

.pac-icon {
    background-image: url('./assets/svg/ic_tipti_location.svg') !important;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-position: center center;
    grid-row: 1 / span 2;
    grid-column: 1;
}

.pac-item {
    @include overline;
    color: $disable;
    display: grid;
    padding: $margin-sm $margin-none;
    column-gap: $margin-xsm;
    grid-template-columns: 10% 1fr;
}

.pac-logo:after {
    display: none;
}

.pac-item-selected .pac-icon-marker {
    height: 80%;
    background-position: center center;
    transition: all 250ms ease-in-out;
}

.pac-matched {
    color: $orange;
    font-weight: 400;
}

.pac-item-query {
    @include caption;
    grid-column: 2;
    grid-row: 1;
}

.pac-container {
    background-color: $canvas;
    @include phone {
        max-height: 11rem;
        overflow: scroll;
    }
}

.pac-container span {
    margin: $margin-none;
    padding: $padding-none;
    line-height: 1rem;
}

.background-canvas {
    background-color: #fff;
}

.gmnoprint {
    top: 1vh;
    right: 10px;
}

.gm-style-cc {
    display: none;
}

.not-valid-input {
    @include overline;
    color: $error;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-help {
    cursor: help;
}

.cursor-default {
    cursor: default;
}

.input-error {
    @include overline;
    color: $error;
}

.flex-column {
    flex-direction: column;
}

.nested-enter-active,
.nested-leave-active {
    transition: opacity 0.3s ease-in-out;
}

/* delay leave of parent element */
.nested-leave-active {
    transition-delay: 0.3s;
}

.nested-enter-from,
.nested-leave-to {
    opacity: 0;
}

.nested-enter-active .inner,
.nested-leave-active .inner {
    transition: opacity 0.2s ease-in-out;
}

/* delay enter of nested element */
.nested-enter-active .inner {
    transition-delay: 0.2s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
    opacity: 0.01;
}
</style>
