import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
    type: "tel",
    placeholder: $options.placeholderPhone,
    autocomplete: "tel",
    onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.isNumericKey && $options.isNumericKey(...args)))
  }, null, 40, _hoisted_1))
}