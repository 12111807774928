<script lang="ts" setup>
import { useWallet } from '@/views/wallet/useWallet';

import GiftCard from '@/components/cards/GiftCard.vue';
import CardProduct from '@/components/cards/cardProduct/CardProduct.vue';

defineOptions({
    name: 'TheGiftCardSelctedRetailerWithoutCategories',
});

const { giftCardSelected, giftCardCategoryProducts, giftCardRetailerCategorySelected, getGiftCardCategoryProducts } =
    useWallet();

await getGiftCardCategoryProducts();
</script>

<template>
    <div class="the-gift-card-selected-retailer-without-categories">
        <GiftCard
            v-if="giftCardRetailerCategorySelected == null"
            class="the-gift-card-selected-retailer-without-categories__card"
            :card="giftCardSelected"
        />
        <CardProduct
            v-for="item in giftCardCategoryProducts"
            :key="item.id"
            class="the-gift-card-selected-retailer-without-categories__product"
            :product-data="item"
        />
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-gift-card-selected-retailer-without-categories {
    display: flex;
    flex-wrap: wrap;

    &__card {
        margin: auto $margin-lg;
    }

    &__product {
        width: 14rem;
        height: 100%;
        @include tablet {
            width: 10rem;
        }
        @include phone {
            width: 8rem;
        }
    }
}
</style>
