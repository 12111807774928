<template>
    <div v-if="!isRecoveryPassword" class="login-container">
        <div class="header">
            <p class="header__text">{{ $t('cta.log-in') }}</p>
            <icon-circular-close class="header__close" :size="0.8" @click="$emit('on-close')"></icon-circular-close>
        </div>
        <form class="login" @submit.prevent="login">
            <div class="login-inputs">
                <tipti-input-text
                    v-model="email"
                    is-required
                    label="email"
                    label-id="email"
                    placeholder="Ingresa aquí tu e-mail"
                />
                <TiptiInputPassword
                    v-model="password"
                    class="password--sepator"
                    :errors="errorLogin ? `${$t('txt.login__user-password-incorrect')}` : ''"
                    is-required
                    label="contraseña"
                    label-id="password"
                    placeholder="Ingresa aquí la contraseña para tu cuenta"
                    type="password"
                />
                <div class="login__forgot-password">
                    <a @click="isRecoveryPassword = true">
                        {{ $t("txt['login__forget-your-password']") }}
                    </a>
                </div>
            </div>
            <div class="login__actions">
                <div class="login__actions__btn-container">
                    <btn-solid :is-loading="isLoading" :txt="$t('cta.log-in')"></btn-solid>
                </div>
                <div class="login__actions__to-register">
                    <p>{{ $t("txt['login__still-not-account']") }}</p>
                    <base-link :txt-link="$t('txt.login__enter-here')" @click.prevent="switchToRegiter"></base-link>
                </div>
                <separator-text class="option-separator" text="o" />
                <p class="social-header">{{ $t('general.general-login-with') }}</p>
                <div class="social-btn">
                    <the-google @on-submit="loginGoogle" />
                    <the-facebook @click="loginFacebook"></the-facebook>
                </div>
            </div>
        </form>
    </div>
    <recovery-password v-else @on-back="onBack"></recovery-password>
    <snack-bar
        :body="snackBarMessage"
        :is-active="showSnackBar"
        :is-failure="isFailure"
        :is-success="isSuccess"
        @on-snackbar-close="showSnackBar = false"
    ></snack-bar>
</template>

<script lang="ts">
import { mapActions } from 'vuex';
import { logger } from '@/utils/logger';

import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import BaseLink from '@/components/links/BaseLink.vue';
import LoginModel from '@/models/user/LoginModel';
import TheFacebook from '../TheFacebook.vue';
import RecoveryPassword from '../recoveryPassword/RecoveryPassword.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import TheGoogle from '../TheGoogle.vue';
import SeparatorText from '@/components/alerts/alertPurchase/SeparatorText.vue';
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';
import TiptiInputPassword from '@/components/inputs/primary/normal/TiptiInputPassword.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'Login',
    components: {
        TiptiInputPassword,
        BtnSolid,
        BaseLink,
        SnackBar,
        TheFacebook,
        RecoveryPassword,
        IconCircularClose,
        TheGoogle,
        TiptiInputText,
        SeparatorText,
    },
    emits: ['on-switch', 'on-close'],
    data() {
        return {
            isLoading: false,
            showSnackBar: false,
            email: '',
            password: '',
            emailEmpty: false,
            passwordEmpty: false,
            errorLogin: false,
            isRecoveryPassword: false,
            snackBarMessage: '',
            isSuccess: false,
            isFailure: false,
            isLoginFacebook: false,
        };
    },
    methods: {
        ...mapActions({
            fetchProfileData: 'user/getProfile',
        }),
        onBack(): void {
            this.isRecoveryPassword = false;
        },
        async fireLoginEvent(type: string): Promise<void> {
            try {
                await this.fetchProfileData();
                this.$store.dispatch('segment/identify', type);
                this.$store.dispatch('segment/signIn', {
                    source: {
                        source_id: type,
                        source: type,
                    },
                });
                this.$store.dispatch('segment/detailsPopulated', {
                    source: {
                        source_id: type,
                        source: type,
                    },
                });
            } catch (err) {
                logger('FIRE_LOGIN_EVENT', err);
            }
        },
        async loginFacebook(): Promise<void> {
            try {
                this.isLoginFacebook = true;
                await this.$store.dispatch('user/loginFB');
                await this.fireLoginEvent('FACEBOOK');
                this.$emit('on-close');
                this.$router.push({ name: appRoutesMap.home });
            } catch (err) {
                this.manageError(err);
            }
        },
        async loginGoogle(token: string): Promise<void> {
            try {
                this.isLoginGoogle = true;
                await this.$store.dispatch('user/loginGoogle', token);
                await this.fireLoginEvent('GOOGLE');
                this.$emit('on-close');
                this.$router.push({ name: appRoutesMap.home });
            } catch (err) {
                this.manageError(err);
            }
        },
        switchToRegiter(): void {
            this.$emit('on-switch');
        },
        async login(): Promise<void> {
            try {
                if (this.isLoginFacebook || this.isLoginGoogle) {
                    this.isLoginFacebook = false;
                    this.isLoginGoogle = false;
                    return;
                }
                this.passwordEmpty = !this.password.trim().length;
                this.emailEmpty = !this.email.trim().length;
                if (!this.email.trim().length || !this.password.trim().length) return;
                this.isLoading = true;
                const loginData = new LoginModel(this.email, this.password);
                await this.$store.dispatch('user/login', loginData);
                await this.fireLoginEvent('REGULAR');
                this.$emit('on-close');
                this.$router.push({ name: appRoutesMap.home });
            } catch (err) {
                this.manageError(err);
            } finally {
                this.isLoading = false;
            }
        },
        manageError(err) {
            const _window = window as any;
            this.snackBarInfo({
                msg: this.$t('general.error-to', { message: this.$t('txt.login__try-login') }),
                success: false,
                failed: true,
            });
            this.passwordEmpty = false;
            this.errorLogin = true;
            _window.google.accounts.id.disableAutoSelect();
            const acDeactivated = err.data?.non_field_errors?.find((element) => element === 'USER_DEACTIVATED');
            if (acDeactivated?.length || err.data?.is_user_active === false) {
                this.errorLogin = false;
                this.snackBarInfo({
                    msg: this.$t('txt.login__account-deactivated'),
                    success: false,
                    failed: true,
                });
            }
            this.showSnackBar = true;
        },
        snackBarInfo(data): void {
            this.isSuccess = data.success;
            this.isFailure = data.failed;
            this.snackBarMessage = data.msg;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './login.scss';
</style>
