<template>
    <base-card class="become-prime" padding="0">
        <section-header color-title="#000" has-back title="Tipti Prime" @on-back="$router.go(-1)" />
        <base-loading v-if="isLoading" />
        <section v-else-if="primeDeals" class="primes">
            <div class="primes__content">
                <tipti-prime-detail />
                <tipti-prime-options />
            </div>
        </section>
        <no-content v-else class="cart__no-content" :title="$t('txt.prime__no_content')">
            <icon-afflicted-shop-bag :size="0.5" color="#C1C1C1" />
        </no-content>
        <div>
            <p class="primes__terms-and-conditions">
                {{ $t("txt['prime__accept-terms']") }}
                <span class="primes__terms-and-conditions--highlight cursor-pointer" @click="view">
                    {{ $t("general['terms-and-conditions']") }}
                </span>
                {{ $t("txt['prime__accept-terms-final']") }}
            </p>
            <section-footer class="prime-footer">
                <btn-solid
                    :is-disabled="isLoading"
                    :is-loading="isLoading"
                    :txt="$t('cta.subscribe')"
                    @click="showPayment"
                />
            </section-footer>
        </div>
    </base-card>
    <view-terms-and-contions
        :only-view="onlyView"
        :show-terms-and-conditions="showTermsAndConditions"
        @snack-bar-data="snackData"
        @on-close="showTermsAndConditions = false"
    />
    <tipti-prime-payment
        v-if="showTiptiPayment"
        :is-from-error="isError"
        :snack-data="snackInfo"
        @show-pdf-terms="accept"
        @on-close="showTiptiPayment = false"
    />
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import BaseCard from '@/components/cards/BaseCard.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import TiptiPrimePayment from '@/views/myAccount/views/prime/components/tiptiPrimePayment/TiptiPrimePayment.vue';
import TiptiPrimeDetail from '@/views/myAccount/views/prime/components/tiptiPrimeDetail/TiptiPrimeDetail.vue';
import TiptiPrimeOptions from '@/views/myAccount/views/prime/components/tiptiPrimeOptions/TiptiPrimeOptions.vue';
import ViewTermsAndContions from '@/views/myAccount/views/prime/components/viewTermsAndConditions/ViewTermsAndCondition.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import { logger } from '@/utils/logger';
import IconAfflictedShopBag from '@/components/icons/IconAfflictedShopBag.vue';
import { isMobileBrowser } from '@/utils/utils';

export default {
    name: 'Prime',
    components: {
        TiptiPrimeDetail,
        BaseCard,
        BaseLoading,
        TiptiPrimePayment,
        TiptiPrimeOptions,
        SectionFooter,
        BtnSolid,
        SectionHeader,
        NoContent,
        IconAfflictedShopBag,
        ViewTermsAndContions,
    },
    data() {
        return {
            showTermsAndConditions: false,
            showSnackBar: false,
            isSuccess: false,
            isFailure: false,
            isTrial: false,
            snackBarMessage: '',
            isLoading: false,
            showTiptiPayment: false,
            onlyView: false,
            snackInfo: {
                success: false,
                failed: false,
                msg: '',
                active: false,
            },
            isError: false,
        };
    },
    computed: {
        ...mapGetters({
            primeDeals: 'tiptiCard/primeDeals',
        }),
        isMobile() {
            return isMobileBrowser();
        },
    },
    methods: {
        ...mapActions({ fetchTiptiPrimeDeals: 'tiptiCard/tiptiPrimeDeals' }),
        showPayment() {
            if (this.isLoading) return;
            this.showTiptiPayment = true;
            this.isError = false;
        },
        accept() {
            this.showTiptiPayment = false;
            this.onlyView = false;
            this.showTermsAndConditions = true;
        },
        view() {
            this.showTiptiPayment = false;
            this.onlyView = true;
            this.showTermsAndConditions = true;
        },
        snackData(data) {
            data.active = true;
            this.snackInfo = data;
            this.isError = true;
            this.showTiptiPayment = true;
        },
    },
    async created() {
        try {
            this.isLoading = true;
            await this.fetchTiptiPrimeDeals();
        } catch (error) {
            logger(error);
        } finally {
            this.isLoading = false;
        }
    },
};
</script>

<style lang="scss" scoped>
@import './prime.scss';
</style>
