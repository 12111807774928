import { CartTypes } from '@/modules/core/types/CartType';

export default {
    cartType(state): CartTypes {
        return state.cartType;
    },
    showCart(state): boolean {
        return state.showCart;
    },
    currentRoutePath(state): boolean {
        return state.currentRoutePath;
    },
};
