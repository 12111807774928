<template>
    <teleport to=".header-product">
        <IconBack class="header__close-icon-back" @click="closePageProductDetail" />
        <img
            class="image-item-header"
            :src="product?.image?.low"
            :alt="product.name"
            loading="lazy"
            @error="displayDefaultImg"
        />
        <p class="product-name">{{ product?.name }}</p>
        <ProductPrice class="product-price-header" :product="product" :show-labels="false" />
        <slot name="action" />
    </teleport>
</template>
<script lang="ts" setup>
import { ProductModel } from '@/models/product/ProductModel';
import { computed, PropType, ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import ProductPrice from '@/components/modal/productModal/components/productModalPrice/ProductPrice.vue';
import IconBack from '@/components/icons/IconBack.vue';
import { useImage } from '@/composables/ImageComposable';
import { useClosePageProductDetail } from '@/composables/closeProductDetail';
import { useUser } from '@/composables/useUser';

const { displayDefaultImg } = useImage();
const { closePageProductDetail } = useClosePageProductDetail();
const { isAuth } = useUser();

const props = defineProps({
    product: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
});

const mainHeaderHeight = computed(() => {
    const header = document.querySelector('.header-container');
    return (header?.clientHeight ?? 0) + 75;
});

const observer = ref<HTMLElement>();
const showInHeader = ref(false);
useIntersectionObserver(
    observer,
    ([{ isIntersecting }]) => {
        if (!window.scrollY) {
            showInHeader.value = false;
            return;
        }
        showInHeader.value = !isIntersecting;
    },
    {
        rootMargin: `-${mainHeaderHeight.value}px 0px 0px 0px`,
    },
);
</script>
<style lang="scss" scoped>
@import './the-quantity-modifier.scss';

.header__close-icon-back {
    display: none;
    @include phone {
        display: grid;
        align-self: center;
    }
}

.product-price-header {
    @include phone {
        display: none;
    }
}

.product-name {
    @include tablet {
        @include text-lines(3);
    }
}
</style>
