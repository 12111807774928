<template>
    <div class="payment-methods">
        <base-card-flat
            v-for="option in payments"
            :key="option.code"
            class="payment-method cursor-pointer"
            :class="{ 'payment-method__selected': isTileSelected(option) }"
            :border-color="isTileSelected(option) ? '#FF9012' : '#C7C7C7'"
            @click="isSetMixedPayment ? onShowRestartPaymentAlert(option) : onOptionClick(option)"
        >
            <icon-payphone v-if="option.code === 'payphone'" :color="getTileColor(option.code)" :size="1.1" />
            <icon-cash v-else-if="option.code === 'cash'" :color="getTileColor(option.code)" :size="0.8" />
            <icon-wire-transfer
                v-else-if="option.code === 'wire_transfer'"
                :color="getTileColor(option.code)"
                :size="0.6"
            />
            <icon-institutional
                v-else-if="option.code === 'institutional'"
                :color="getTileColor(option.code)"
                :size="0.75"
            />
            <icon-online-bank-services
                v-else-if="option.code === 'online-bank-services'"
                :color="getTileColor(option.code)"
                :size="0.75"
            />
            <icon-nequi v-else-if="option.code === 'nequi'" :color="getTileColor(option.code)" :size="1.1" />
            <icon-yappy v-else-if="option.code === 'yappy'" :color="getTileColor(option.code)" :size="0.7" />
            <icon-tipti-card
                v-else-if="option.code === 'tipti_card'"
                :color="option.code === paymentSelected || isMixPaymentTiptiCard ? '#FF9012' : '#606060'"
                :size="1.1"
            />
            <icon-credit-card v-else :color="getTileColor(option.code)" :size="1" />
            <div class="title-otp">
                <h2
                    class="payment__label"
                    :class="{
                        'payment__label--orange': isTileSelected(option),
                        'payment__label--disable': showDisableColor(option.code),
                    }"
                >
                    {{ option.title }}
                </h2>
                <p
                    v-if="option.code === 'tipti_card'"
                    v-currency="availableAmount"
                    class="tipticard-amount"
                    :class="{
                        'tipticard-amount--orange': isTileSelected(option),
                    }"
                />
            </div>
            <icon-arrow
                class="content-icon-arrow"
                :color="showDisableColor(option.code) ? '#C1C1C1' : isTileSelected(option) ? '#FF9012' : '#606060'"
                :icon-name="$t('icon.forward')"
                :size="0.75"
            />
        </base-card-flat>
        <snack-bar
            :body="alertMessage"
            :is-active="showAlert"
            :title="alertTitle"
            is-failure
            @on-snackbar-close="showAlert = false"
        />
        <general-alert
            v-if="showRestartPaymentsAlert"
            :message="$t('txt.payment-methods__change-payment-description')"
            :title="$t('txt.payment-methods__change-payment')"
            accent-header
            show-close-icon
            @on-decline="showRestartPaymentsAlert = false"
            @on-accept="onAcceptRestartPayment"
            @on-close="showRestartPaymentsAlert = false"
        />
    </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import IconPayphone from '@/components/icons/IconPayphone.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import IconCreditCard from '@/components/icons/IconCreditCard.vue';
import IconCash from '@/components/icons/IconCash.vue';
import BaseCardFlat from '../cards/BaseCardFlat.vue';
import IconWireTransfer from '@/components/icons/IconWireTransfer.vue';
import IconInstitutional from '../icons/IconInstitutional.vue';
import IconTiptiCard from '../icons/IconTiptiCard.vue';
import IconNequi from '../icons/IconNequi.vue';
import IconYappy from '../icons/IconYappy.vue';
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import IconOnlineBankServices from '../icons/IconOnlineBankServices.vue';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import { useCheckoutEvents } from '@/views/checkout/composables/useCheckoutEvents';
import { PaymentToShowName } from '@/models/checkout/PaymentToShowModel';
import { useApp } from '@/composables/useApp';
import { useUser } from '@/composables/useUser';
import { usePickUp } from '@/composables/usePickUp';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { useCart } from '@/composables/useCart';

export default {
    name: 'PaymentMethods',
    components: {
        SnackBar,
        IconPayphone,
        IconWireTransfer,
        IconArrow,
        IconCreditCard,
        IconCash,
        BaseCardFlat,
        IconInstitutional,
        IconTiptiCard,
        GeneralAlert,
        IconOnlineBankServices,
        IconNequi,
        IconYappy,
    },
    props: {
        totalToPay: {
            type: [String, Number],
            required: true,
        },
        payments: {
            type: [PaymentMethodModel, Array],
            required: true,
        },
        optionSelected: {
            type: String,
            default: '',
        },
        skipRouter: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { selectPaymentsMethod } = useCheckoutEvents();
        const { isMobile } = useApp();
        const { profile, currency, baseConfiguration } = useUser();
        const { isPickup } = usePickUp();
        const { availableAmount } = useTiptiCard();
        const { cart } = useCart();
        return {
            selectPaymentsMethod,
            isMobile,
            profile,
            currency,
            baseConfiguration,
            isPickUp: isPickup,
            availableAmount,
            cart,
        };
    },
    data() {
        return {
            paymentSelected: this.optionSelected,
            showAlert: false,
            alertTitle: '',
            alertMessage: '',
            showRestartPaymentsAlert: false,
            optionToShow: undefined,
        };
    },
    watch: {
        paymentSelected(newValue) {
            let eventType: PaymentToShowName;
            if (newValue === 'payphone') eventType = 'payment-payphone';
            if (newValue === 'credit_cards') eventType = 'payment-cards';
            if (newValue === 'nequi') eventType = 'payment-nequi';
            if (newValue === 'yappy') eventType = 'payment-yappy';
            if (newValue === 'cash') eventType = 'payment-cash';
            if (newValue === 'institutional') eventType = 'payment-institutional';
            if (newValue === 'online_bank_services') eventType = 'payment-online-bank-services';
            if (newValue === 'tipti_card') eventType = 'payment-tipti-card';
            if (newValue === 'wire_transfer') eventType = 'payment-wire-transfer';
            if (!eventType) return;
            this.selectPaymentsMethod({
                screen: eventType,
            });
        },
    },
    computed: {
        ...mapGetters({
            selectPrime: 'tiptiCard/selectPrime',
            isMixPaymentTiptiCard: 'payments/isMixPaymentTiptiCard',
            isMixInstitutionalPayment: 'payments/isMixInstitutionalPayment',
            secondPayment: 'payments/secondPayment',
            paymentsToShow: 'payments/paymentsToShow',
        }),
        isCashAvailable(): boolean {
            if (this.isPickUp) return false;
            return this.totalForPayment <= this.baseConfiguration?.limitCashAmount;
        },
        isCreditCardAvailable(): boolean {
            const isInsideLimit: boolean = this.totalForPayment <= this.baseConfiguration?.globalPaymentLimit;
            return this.totalForPayment >= 1 && isInsideLimit;
        },
        totalForPayment(): boolean {
            return this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment ? this.secondPayment : +this.totalToPay;
        },
        isInstitutionalAvailable(): boolean {
            return this.profile.isInsitutionalPayment;
        },
        isOnlineBankServicesAvailable(): boolean {
            return this.baseConfiguration.onlineBankPaymentsEnabled;
        },
        isSetMixedPayment(): boolean {
            return (this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment) && this.paymentsToShow.length > 1;
        },
    },
    methods: {
        ...mapActions({ fetchTiptiCard: 'tiptiCard/tiptiCard', fetchBaseConfiguration: 'user/baseConfiguration' }),
        onAcceptRestartPayment() {
            this.showRestartPaymentsAlert = false;
            this.$store.commit('payments/isMixPaymentTiptiCard', false);
            this.$store.commit('payments/isMixInstitutionalPayment', false);
            this.$store.commit('payments/cleanPaymentMethods');
            this.onOptionClick(this.optionToShow);
        },
        onShowRestartPaymentAlert(option: object) {
            this.showRestartPaymentsAlert = true;
            this.optionToShow = option;
        },
        getTileColor(optionCode: string): string {
            if (
                ((optionCode === 'payphone' ||
                    optionCode === 'credit_cards' ||
                    optionCode === 'nequi' ||
                    optionCode === 'yappy') &&
                    !this.isCreditCardAvailable) ||
                (optionCode === 'cash' && !this.isCashAvailable) ||
                (optionCode === 'institutional' && !this.isInstitutionalAvailable) ||
                (optionCode === 'online_bank_services' && this.isMixInstitutionalPayment) ||
                (optionCode === 'nequi' && this.isMixInstitutionalPayment)
            ) {
                return '#C1C1C1';
            }
            return optionCode === this.paymentSelected ||
                this.isSecondPayment(optionCode) ||
                (optionCode === this.paymentSelected && optionCode === 'tipti_card' && this.isMixPaymentTiptiCard) ||
                (optionCode === this.paymentSelected &&
                    optionCode === 'institutional' &&
                    this.isMixInstitutionalPayment)
                ? '#FF9012'
                : '#606060';
        },
        showDisableColor(method: string): boolean {
            if (method === 'cash' && !this.isCashAvailable) return true;
            else if (method === 'credit_cards' && !this.isCreditCardAvailable) return true;
            else if (method === 'payphone' && !this.isCreditCardAvailable) return true;
            else if (method === 'nequi' && !this.isCreditCardAvailable) return true;
            else if (method === 'institutional' && !this.isInstitutionalAvailable) return true;
            else if (method === 'online_bank_services' && this.isMixInstitutionalPayment) return true;
            else if (method === 'nequi' && this.isMixInstitutionalPayment) return true;
            return false;
        },
        showCashAlert(method: string): boolean {
            if (method !== 'cash') return false;
            if (this.isCashAvailable) return false;
            this.showAlert = true;
            this.alertTitle = this.$t('txt.cash__not-allowed');
            this.alertMessage = this.isPickUp
                ? this.$t('txt.pickup__not_avalilable_cash')
                : this.$t('txt.cash__over-limit');
            return true;
        },
        showCreditCardAlert(method: string): boolean {
            if (method !== 'credit_cards') return false;
            if (this.isCreditCardAvailable) return false;
            if (this.totalForPayment < 1) {
                this.showAlert = true;
                this.alertTitle = this.$t('txt.credit-card__not-allowed');
                this.alertMessage = this.$t('txt.credit-card__under-limit', { currency: this.currency });
                return true;
            }
            if (this.totalForPayment > this.baseConfiguration?.globalPaymentLimit) {
                this.showAlert = true;
                this.alertTitle = this.$t('txt.credit-card__not-allowed');
                this.alertMessage = this.$t('txt.credit-card__over-limit', {
                    currency: this.currency,
                    amount: this.baseConfiguration?.globalPaymentLimit,
                });
                return true;
            }
        },
        showPayphoneAlert(method: string): boolean {
            if (method !== 'payphone') return false;
            if (this.isCreditCardAvailable) return false;
            if (this.totalForPayment < 1) {
                this.showAlert = true;
                this.alertTitle = this.$t('txt.payphone__not-allowed');
                this.alertMessage = this.$t('txt.payphone__under-limit', { currency: this.currency });
                return true;
            }
            if (this.totalForPayment > this.baseConfiguration?.globalPaymentLimit) {
                this.showAlert = true;
                this.alertTitle = this.$t('txt.payphone__not-allowed');
                this.alertMessage = this.$t('txt.payphone__over-limit', {
                    currency: this.currency,
                    amount: this.baseConfiguration?.globalPaymentLimit,
                });
                return true;
            }
        },
        showInstitutionalAlert(method: string): boolean {
            if (method !== 'institutional') return false;
            if (this.isInstitutionalAvailable) return false;
            this.showAlert = true;
            this.alertTitle = this.$t('txt.institutional__not-allowed');
            this.alertMessage = this.$t('txt.institutional__not-available');
            return true;
        },
        showOnlineBankServicesAlert(method: string): boolean {
            if (method !== 'online_bank_services') return false;
            if (!this.isMixInstitutionalPayment) return false;
            this.showAlert = true;
            this.alertTitle = this.$t('txt.online-bank-services__not-allowed');
            this.alertMessage = this.$t('txt.online-bank-services__not-available');
            return true;
        },
        showNequiAlert(method: string): boolean {
            if (method !== 'nequi') return false;

            if (this.isCreditCardAvailable) return false;
            if (this.totalForPayment < 1) {
                this.showAlert = true;
                this.alertTitle = this.$t('txt.nequi__not-available-title');
                this.alertMessage = this.$t('txt.nequi__under-limit', { currency: this.currency });
                return true;
            }
            if (this.totalForPayment > this.baseConfiguration?.globalPaymentLimit) {
                this.showAlert = true;
                this.alertTitle = this.$t('txt.nequi__not-available-title');
                this.alertMessage = this.$t('txt.nequi__over-limit', {
                    currency: this.currency,
                    amount: this.baseConfiguration?.globalPaymentLimit,
                });
                return true;
            }
            if (this.isMixInstitutionalPayment) {
                this.showAlert = true;
                this.alertTitle = this.$t('txt.nequi__not-available-title');
                this.alertMessage = this.$t('txt.nequi__not-available-message');
                return true;
            }
        },
        onOptionClick(option: object): void {
            this.showAlert = false;
            if (this.showCashAlert(option['code'])) return;
            if (this.showCreditCardAlert(option['code'])) return;
            if (this.showPayphoneAlert(option['code'])) return;
            if (this.showInstitutionalAlert(option['code'])) return;
            if (this.showOnlineBankServicesAlert(option['code'])) return;
            if (this.showNequiAlert(option['code'])) return;
            this.paymentSelected = option['code'];
            if (this.skipRouter) return this.$emit('selection', option);
            this.$router.replace({ name: option['route'], query: { totalToPay: this.totalToPay } });
        },
        firtsPush(): void {
            const firstPaymentOption = this.payments.find((payment) => {
                if (payment['code'] === 'cash' && this.isCashAvailable) return true;
                else if (payment['code'] === 'credit_cards' && this.isCreditCardAvailable) return true;
                else if (payment['code'] === 'payphone' && this.isCreditCardAvailable) return true;
                else if (payment['code'] === 'nequi' && this.isCreditCardAvailable) return true;
                else if (payment['code'] === 'institutional' && this.isInstitutionalAvailable) return true;
                else if (payment['code'] === 'online_bank_services' && this.isOnlineBankServicesAvailable) return true;
                return false;
            });
            if (!firstPaymentOption) return;
            if (this.skipRouter) return;
            const { query } = this.$route;
            this.$router.replace({ name: firstPaymentOption['route'], query });
            this.paymentSelected = firstPaymentOption['code'];
        },
        isTileSelected(option): boolean {
            return (
                option.code == this.paymentSelected ||
                (this.isMixPaymentTiptiCard && option.code === 'tipti_card') ||
                (this.isMixInstitutionalPayment && option.code === 'institutional') ||
                this.isSecondPayment(option.code)
            );
        },
        isSecondPayment(code: string) {
            if (!this.isSetMixedPayment) return false;
            return (
                (code === 'cash' && this.paymentsToShow[1].name === 'payment-cash') ||
                (code === 'credit_cards' && this.paymentsToShow[1].name === 'payment-cards') ||
                (code === 'payphone' && this.paymentsToShow[1].name === 'payment-payphone') ||
                (code === 'wire_transfer' && this.paymentsToShow[1].name === 'payment-wire-transfer') ||
                (code === 'institutional' && this.paymentsToShow[1].name === 'payment-institutional') ||
                (code === 'online_bank_services' && this.paymentsToShow[1].name === 'payment-online-bank-services') ||
                (code === 'nequi' && this.paymentsToShow[1].name === 'payment-nequi') ||
                (code === 'yappy' && this.paymentsToShow[1].name === 'payment-yappy')
            );
        },
    },
    async mounted() {
        await Promise.allSettled([this.fetchBaseConfiguration(), this.fetchTiptiCard()]);
        /// *** SET the amount of next purchase from parent relation
        /// [totalToPay] is used to make mix payments calculations;
        this.$store.commit('payments/totalToPay', this.totalToPay);
        if (!this.isMobile) this.firtsPush();
        this.showCreditCardAlert(this.optionSelected);
    },
    emits: ['selection'],
};
</script>

<style lang="scss" scoped>
@import './payment-methods.scss';
</style>
