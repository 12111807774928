import { AppSectionModel, toAppSectionModel } from '../AppSectionModel';
import { PromoObjectModel } from '../PromoObjectModel';
import { isMobileBrowser } from '@/utils/utils';

class BannerModel extends PromoObjectModel {
    readonly detail: string;
    readonly detailColor: string;
    readonly appSectionAppear: AppSectionModel;
    readonly categoriesToShow: Array<number>;
    readonly retailerIds: Array<number>;
    name: string;
    readonly isCategorizeProducts: boolean;
    readonly link: string;
    readonly deeplink: string;
    readonly images: {
        picture_for_web: string;
        picture_for_mobile_web: string;
    };

    constructor(
        id: number,
        retailers: string,
        retailerIds: Array<number>,
        cities: string,
        appSection: AppSectionModel,
        appSectionAppear: AppSectionModel,
        categoriesToShow: Array<number>,
        bannerType: string,
        title: string,
        titleColor: string,
        picture: string,
        detail: string,
        detailColor: string,
        startDate: Date,
        endDate: Date,
        file: string,
        showTermsAndConditions: boolean,
        showDiscountActivationProgressBar: boolean,
        name: string,
        picture_for_mobile_web,
        picture_for_web,
        isCategorizeProducts: boolean,
        link: string,
        deeplink: string,
    ) {
        super(
            id,
            retailers,
            cities,
            appSection,
            bannerType,
            title,
            titleColor,
            picture,
            startDate,
            endDate,
            file,
            showTermsAndConditions,
            showDiscountActivationProgressBar,
        );
        this.detail = detail;
        this.retailerIds = retailerIds;
        this.detailColor = detailColor;
        this.appSectionAppear = appSectionAppear;
        this.categoriesToShow = categoriesToShow;
        this.name = name;
        this.images = {
            picture_for_mobile_web: picture_for_mobile_web ?? '',
            picture_for_web: picture_for_web ?? '',
        };
        this.isCategorizeProducts = isCategorizeProducts;
        this.link = link;
        this.deeplink = deeplink;
    }
}

const picture = (data: object) => (isMobileBrowser() ? data['picture_for_mobile_web'] : data['picture_for_web']);

const toBannerModel = (data: object) => {
    return data
        ? new BannerModel(
              data['id'],
              data['retailers'] ?? '',
              data['retailer_ids'] ?? [],
              data['citys'] ?? '',
              toAppSectionModel(data['app_sections']),
              toAppSectionModel(data['app_sections_appear']),
              data['categories_to_show']?.map((category: object) => category['id']),
              data['banner_type'],
              data['title'] ?? '',
              data['title_color'],
              picture(data),
              data['detail'],
              data['detail_color'],
              data['start_date'],
              data['end_date'],
              data['terms_and_conditions_file'],
              data['show_terms_and_conditions'] ?? false,
              data['show_discount_activation_progress_bar'] ?? false,
              data['name'] ?? '',
              data['picture_for_mobile_web'] ?? '',
              data['picture_for_web'] ?? '',
              data['is_categorize_products'] ?? false,
              data['link'] ?? '',
              data['deeplink'] ?? '',
          )
        : null;
};

export { BannerModel, toBannerModel };
