<template>
    <section class="stats-graphics__chart">
        <h2>{{ $t('txt.statistics__my-orders-with-tipti') }}</h2>
        <canvas ref="lineChartContainer" height="200" width="300"></canvas>
        <aside v-if="isHighValue" class="chart-orders">
            <div class="chart-orders__title">
                <h2>{{ $t('txt.statistics__accumulated-orders') }}</h2>
                <p>{{ $t("general['year']") }} {{ actualYear }}</p>
            </div>
            <div class="chart-orders__value">
                <h3>{{ overAllStatistics.overall_effective_orders }}</h3>
                <p>
                    {{
                        $t("general['orders']", {
                            count: overAllStatistics?.overall_effective_orders ?? 0,
                        }).toUpperCase()
                    }}
                </p>
            </div>
        </aside>
    </section>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useApp } from '@/composables/useApp';
import dayjs from 'dayjs';
import { Chart } from 'chart.js/auto';
import { useDays } from '@/composables/useDays';
import { useStatistics } from '@/views/myAccount/views/profile/views/statistics/useStatistics';

const { isMobile, $t } = useApp();
const { doFormat } = useDays();
const actualYear = dayjs().year();
const { overAllStatistics, getOverallStatistics } = useStatistics();
const lineChartContainer = ref<HTMLCanvasElement>();
const lineChart = ref<Chart<'line'>>();

const isHighValue = computed(() => (isMobile.value && overAllStatistics.value?.overall_savings) >= 1000);

const buildLineChart = () => {
    if (lineChart.value) lineChart.value.destroy();
    const options = [...overAllStatistics.value.current_year_monthly_statistics].sort(
        (prev, curr) => prev.month - curr.month,
    );

    const labels = options.map((element) =>
        doFormat({
            date: dayjs().set('month', element.month - 1),
            format: 'MMM',
        }),
    );

    const maxOrders = Math.max(...options.map((element) => element.month_effective_orders));
    let maxYValue: number;
    let stepSize: number;
    const remaining = maxOrders % 5;
    if (remaining != 0) {
        maxYValue = Math.abs(remaining - 5) + maxOrders;
    } else maxYValue = maxOrders;
    stepSize = maxOrders > 9 ? Math.ceil(maxOrders / 5) : 1;

    lineChart.value = new Chart(lineChartContainer.value, {
        type: 'line',
        data: {
            labels,
            datasets: [
                {
                    data: options.map((element) => element.month_effective_orders),
                    borderColor: 'rgb(82, 10, 118)',
                    tension: 0.1,
                    pointBackgroundColor: 'rgb(82, 10, 118)',
                },
            ],
        },
        options: {
            plugins: {
                legend: {
                    display: false,
                },
            },
            elements: {
                point: {
                    radius: 0,
                },
            },
            scales: {
                y: {
                    max: maxYValue,
                    ticks: {
                        stepSize,
                        precision: 1,
                    },
                },
                x: {
                    grid: {
                        display: false,
                    },
                },
            },
        },
    });
};

const init = () => {
    getOverallStatistics().then(() => {
        if (!overAllStatistics.value) return;
        buildLineChart();
    });
};
init();
</script>

<style lang="scss" scoped>
@import './stats-graphics.scss';
</style>
