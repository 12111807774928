<template>
    <base-icon :icon-name="$t('icon.hidden')" :width="width" :height="height" viewBox="0 0 25 9" :iconColor="color">
        <g clip-path="url(#clip0)">
            <path
                d="M22.9197 1.47951L23.0997 1.35783C23.4397 1.11447 23.5197 0.648031 23.2797 0.30327C23.0397 -0.0414908 22.5797 -0.122611 22.2397 0.12075C10.6596 8.35445 2.19952 0.546631 1.85952 0.20187C1.55952 -0.0820509 1.07951 -0.0617708 0.79951 0.24243C0.519508 0.546631 0.539508 1.01307 0.83951 1.29699C0.83951 1.31727 1.11951 1.56063 1.59952 1.92568L0.0995045 4.39984C-0.120497 4.7446 -0.000496274 5.21105 0.339506 5.43413C0.459507 5.51525 0.599509 5.53553 0.719509 5.53553C0.959511 5.53553 1.21951 5.41385 1.35951 5.17049L2.81953 2.73688C3.63953 3.24388 4.67954 3.79144 5.91955 4.25788L5.19955 6.69149C5.07954 7.09709 5.29955 7.50269 5.69955 7.62437L5.91955 7.66493C6.23955 7.66493 6.53956 7.46213 6.61956 7.13765L7.31956 4.7446C8.45957 5.06908 9.75958 5.27189 11.1396 5.33273V7.74605C11.1396 8.17193 11.4796 8.49642 11.8796 8.49642C12.2996 8.49642 12.6196 8.15165 12.6196 7.74605V5.31245C13.8396 5.25161 15.1196 5.02852 16.4596 4.6432L17.3996 7.09709C17.5196 7.40129 17.7996 7.58381 18.0996 7.58381L18.3597 7.54325C18.7397 7.40129 18.9397 6.95513 18.7997 6.56981L17.9196 4.19704C19.1397 3.7306 20.3997 3.14248 21.6997 2.33128L22.8997 3.95368C23.0397 4.15648 23.2597 4.25788 23.4997 4.25788C23.6597 4.25788 23.8197 4.21732 23.9397 4.11592C24.2597 3.87256 24.3397 3.38584 24.0997 3.06136L22.9197 1.47951Z"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="24.2202" height="8.47707" />
            </clipPath>
        </defs>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-eye-close',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 25 * this.size;
        },
        height(): number {
            return 9 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
