<template>
    <promotion-layout v-if="promotion">
        <template #header>
            <div :class="{ 'informative-header': !showInformativeBanner }">
                <icon-back
                    v-if="!showInformativeBanner"
                    class="informative-header__back cursor-pointer"
                    :size="0.7"
                    @click="showInformativeBanner = true"
                />
                <h2 class="informative-header__title">{{ promotion?.title ?? '' }}</h2>
            </div>
        </template>
        <template #content>
            <section v-if="showInformativeBanner">
                <figure class="promotion-card__figure">
                    <img class="informative__img" :src="promotion.picture" :alt="promotion.title" />
                </figure>
                <div class="informative__data">
                    <p class="informative__description">{{ promotion.detail }}</p>
                    <div class="informative-detail">
                        <strong class="informative-detail__title">{{ $t("general['promotion-date']") }}</strong>
                        <p class="informative-detail__content">
                            {{ formatDatePromotion(promotion.startDate, promotion.endDate) }}
                        </p>
                    </div>
                    <div v-if="promotion.cities?.length > 0" class="informative-detail">
                        <strong class="informative-detail__title">{{ $t("general['participant-cities']") }}</strong>
                        <p class="informative-detail__content">{{ promotion.cities }}</p>
                    </div>
                    <div class="informative-detail">
                        <strong v-if="promotion.retailers?.length > 0" class="informative-detail__title">
                            {{ $t("general['participant-retailers']") }}
                        </strong>
                        <p class="informative-detail__content">{{ promotion.retailers }}</p>
                    </div>
                    <txt-action-terms-and-conditions
                        v-if="promotion.termsAndConditionsFile && promotion.showTermsAndConditions"
                        :document="promotion.termsAndConditionsFile"
                    />
                </div>
                <footer v-if="promotion?.type != 'INFORMATIVE'" class="informative__footer">
                    <btn-solid :txt="$t('cta.continue')" @click="goToProducts" />
                </footer>
            </section>
            <router-view v-else />
        </template>
    </promotion-layout>
</template>

<script lang="ts" setup>
import { BannerModel } from '@/models/promotions/banners/BannerModel';
import TxtActionTermsAndConditions from '@/components/termAndConditions/TxtActionTermsAndConditions.vue';
import PromotionLayout from '../PromotionLayout.vue';
import IconBack from '@/components/icons/IconBack.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { usePromotion } from '@/composables/usePromotion';
import { PropType, ref, watch } from 'vue';
import { useStore } from 'vuex';

defineOptions({
    name: 'Informative',
});
const props = defineProps({
    promotion: {
        type: Object as PropType<BannerModel>,
        required: true,
    },
});

const $store = useStore();
const { goToPromoProductsScreen, isBannerInSection, formatDatePromotion } = usePromotion();
const showInformativeBanner = ref(false);

const goToProducts = async () => {
    showInformativeBanner.value = false;
    isBannerInSection.value = true;
    await goToPromoProductsScreen(props.promotion, '/coupons-and-promotions/promotions');
};

watch(
    () => props.promotion,
    (newValue, oldValue) => {
        showInformativeBanner.value = newValue.id != oldValue?.id;
    },
    {
        immediate: true,
    },
);
</script>

<style lang="scss" scoped>
@import './informative.scss';
</style>
