<template>
    <base-icon :icon-name="$t('icon.instagram')" :width="width" :height="height" viewBox="0 0 23 23" :iconColor="color">
        <path
            d="M16.3707 0H6.22382C4.63611 0.00257793 3.11417 0.634434 1.99149 1.75711C0.868809 2.87979 0.236953 4.40173 0.234375 5.98944L0.234375 16.1363C0.237944 17.7234 0.870238 19.2444 1.99281 20.3662C3.11538 21.4881 4.63676 22.1194 6.22382 22.122H16.3707C17.9571 22.1184 19.4775 21.4867 20.5993 20.3649C21.721 19.2431 22.3528 17.7227 22.3564 16.1363V5.98944C22.3538 4.40238 21.7225 2.88101 20.6006 1.75844C19.4787 0.635863 17.9578 0.0035688 16.3707 0V0ZM21.0628 16.1363C21.0614 17.3803 20.5666 18.573 19.687 19.4526C18.8073 20.3323 17.6147 20.827 16.3707 20.8284H6.22382C4.97982 20.827 3.78716 20.3323 2.90752 19.4526C2.02788 18.573 1.53309 17.3803 1.5317 16.1363V5.98944C1.53309 4.74544 2.02788 3.55279 2.90752 2.67315C3.78716 1.79351 4.97982 1.29871 6.22382 1.29732H16.3707C17.6147 1.29871 18.8073 1.79351 19.687 2.67315C20.5666 3.55279 21.0614 4.74544 21.0628 5.98944V16.1363Z"
        />
        <path
            d="M11.3002 5.00977C10.1036 5.00977 8.93383 5.3646 7.93888 6.02941C6.94394 6.69421 6.16847 7.63912 5.71054 8.74464C5.25262 9.85017 5.13281 11.0667 5.36626 12.2403C5.5997 13.4139 6.17593 14.4919 7.02206 15.3381C7.86819 16.1842 8.94623 16.7604 10.1198 16.9939C11.2935 17.2273 12.51 17.1075 13.6155 16.6496C14.721 16.1917 15.6659 15.4162 16.3307 14.4212C16.9955 13.4263 17.3504 12.2566 17.3504 11.0599C17.3486 9.45589 16.7106 7.91804 15.5763 6.7838C14.4421 5.64955 12.9042 5.01155 11.3002 5.00977ZM11.3002 15.8121C10.3602 15.8121 9.44124 15.5333 8.65964 15.0111C7.87803 14.4888 7.26885 13.7465 6.90912 12.878C6.54938 12.0096 6.45526 11.0539 6.63865 10.132C6.82204 9.21 7.2747 8.36312 7.9394 7.69842C8.6041 7.03372 9.45098 6.58106 10.3729 6.39767C11.2949 6.21428 12.2505 6.3084 13.119 6.66813C13.9875 7.02786 14.7298 7.63705 15.252 8.41865C15.7743 9.20026 16.053 10.1192 16.053 11.0592C16.0524 12.3198 15.5516 13.5287 14.6605 14.4204C13.7694 15.3121 12.5608 15.8137 11.3002 15.8151V15.8121Z"
        />
        <path
            d="M17.4909 2.86523C17.1373 2.86523 16.7916 2.97008 16.4977 3.16652C16.2037 3.36296 15.9745 3.64217 15.8392 3.96885C15.7039 4.29552 15.6685 4.65497 15.7375 5.00176C15.8065 5.34856 15.9767 5.6671 16.2267 5.91713C16.4768 6.16715 16.7953 6.33742 17.1421 6.4064C17.4889 6.47538 17.8483 6.43997 18.175 6.30466C18.5017 6.16935 18.7809 5.94021 18.9773 5.64621C19.1738 5.35222 19.2786 5.00657 19.2786 4.65299C19.2782 4.17897 19.0898 3.72448 18.7546 3.38929C18.4194 3.05411 17.9649 2.86563 17.4909 2.86523ZM17.4909 5.14342C17.3939 5.14342 17.299 5.11466 17.2184 5.06077C17.1377 5.00688 17.0749 4.93028 17.0378 4.84067C17.0006 4.75105 16.9909 4.65245 17.0099 4.55731C17.0288 4.46218 17.0755 4.37479 17.1441 4.3062C17.2127 4.23761 17.3001 4.1909 17.3952 4.17198C17.4903 4.15306 17.5889 4.16277 17.6785 4.19989C17.7682 4.23701 17.8448 4.29987 17.8987 4.38052C17.9525 4.46117 17.9813 4.55599 17.9813 4.65299C17.9811 4.783 17.9294 4.90763 17.8374 4.99956C17.7455 5.09149 17.6209 5.14322 17.4909 5.14342Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-instagram',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 23 * this.size;
        },
        height(): number {
            return 23 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
