<template>
    <article class="product-information">
        <section>
            <ProductImages
                v-if="product"
                :product="product"
                :zoom="sectionShowed === 'ZOOM'"
                @close-zoom="(value) => (sectionShowed = value ? 'ZOOM' : 'MAIN')"
            />
            <the-nutritional-information :key="product.id" class="the-nutritional-information" :product="product" />
        </section>

        <section class="product-information__main">
            <h2 class="product-information__name">{{ product.name }}</h2>
            <p v-if="product.hoursToDelivery && product.uniqueDelivery" class="indicator-delivery">
                {{
                    $t('txt.unique_delivery_info_message', {
                        count: product.hoursToDelivery,
                    })
                }}
            </p>
            <product-indicators :indicators="product.indicators" />
            <p v-if="product.carry && product.pay" class="indicator-item">{{ getMultiplierTag(product) }}</p>

            <p class="product-information__unit-information">
                {{ product.unitQuantity }} {{ product.unit }}
                {{ product.conversionGramsAndKilogramsToPounds }}
            </p>

            <div class="product-information__detail">
                <ProductPrice :product="product" />
                <TheQuantityModifier
                    :key="product.id"
                    :is-loading="isLoading"
                    :product="product"
                    @on-main-action="handleActionProduct"
                    @on-changed-quantity="onChangedQuantity"
                />

                <template v-if="productSelected.groupId">
                    <skeleton-attributes v-if="!attributes?.attribute" />
                    <product-attributes v-else :attribute-order="1" :attribute-values="attributes?.attribute" />
                </template>
                <SecondaryProductActions
                    v-if="sectionShowed !== 'ADD_TO_LIST'"
                    :is-favorite="isFavorite"
                    :product="product"
                    @on-click="onActionClick"
                />
            </div>
            <template v-if="false">
                <section v-if="showDeferredOption && sectionShowed !== 'ADD_TO_LIST'">
                    <div class="deferred-price__header">
                        <IconPaymentPlanes />
                        <p class="deferred-price__title">{{ t('txt.payment_facilities') }}</p>
                    </div>
                    <section class="deferred-price">
                        <article v-for="option in deferredValues" :key="option.value" class="deferred-price__option">
                            <p class="deferred-price__month">{{ option.months }}</p>
                            <p v-currency.noSpaced="option.value" class="deferred-price__price" type="INLINE" />
                        </article>
                    </section>
                    <p class="deferred-price__reference">* {{ t('txt.reference_fee') }}</p>
                </section>
            </template>

            <the-note v-if="sectionShowed === 'ADD_NOTE'" :key="product.id" :quantity="quantity" :section="section" />
            <the-my-lists
                v-if="sectionShowed === 'ADD_TO_LIST'"
                section="PRODUCT_DETAIL"
                @on-failure="onFailure"
                @on-success="onSuccess"
                @on-back="sectionShowed = 'ADD_NOTE'"
            />

            <the-nutritional-information
                class="the-nutritional-information the-nutritional-information--mobile"
                :product="product"
            />
        </section>
    </article>
    <snack-bar
        :body="showSnackBarInfo.message"
        :is-active="showSnackBarInfo.showSnackBar"
        :is-failure="showSnackBarInfo.isFailure"
        :is-success="showSnackBarInfo.isSuccess"
        @on-snackbar-close="closeSnackBar"
    />
    <tipti-alert
        :alert-information="alertData"
        :show-alert="showAskForExtraItemsModal"
        @on-close="showAskForExtraItemsModal = false"
        @on-main-action="addInOrder(true)"
        @on-secondary-action="addInOrder(false)"
    >
        <template #icon>
            <icon-add-cart :size="2" color="#ff9012" />
        </template>
        <template #moreContent>
            <p class="alert_legend">{{ t('txt.extra_item_limit', { limit: order.numberOfExtraItemsAllowed }) }}</p>
        </template>
    </tipti-alert>
</template>

<script lang="ts">
import { computed } from 'vue';
import TheNote from '../../sections/theNote/TheNote.vue';
import TheMyLists from '../../sections/theMyLists/TheMyLists.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import TheNutritionalInformation from '../../sections/theNutritionalInformation/TheNutritionalInformation.vue';
import fetchDiscountActivationProgressBar from '@/utils/discountActivationProgressBarUtils';
import TheQuantityModifier from './components/theQuantityModifier/TheQuantityModifier.vue';
import ProductImages from '@/components/modal/productModal/components/productImage/ProductImages.vue';
import { useImage } from '@/composables/ImageComposable';
import { useTranslate } from '@/composables/UseTranslate';
import { useProductInformation } from '@/composables/useProductInformation';
import { useProductQuantity } from '@/composables/useProductQuantity';
import { BaseTiptiAlertModelInterface } from '@/models/alerts/AlertOpenRetailer';
import { mapGetters } from 'vuex';
import { useCartType } from '@/modules/core/composable/useCartType';
import TiptiAlert from '@/components/alerts/alertTiptiAlert/TiptiAlert.vue';
import IconAddCart from '@/components/icons/IconAddCart.vue';
import SecondaryProductActions from '@/components/modal/productModal/components/theProductInformation/components/theActionsProductInformation/SecondaryProductActions.vue';
import ProductIndicators from '@/components/badges/productIndicators/ProductIndicators.vue';
import { useProductTags } from '@/composables/useProductTags';
import ProductPrice from '@/components/modal/productModal/components/productModalPrice/ProductPrice.vue';
import IconPaymentPlanes from '@/components/icons/IconPaymentPlanes.vue';
import SkeletonAttributes from '@/components/skeletons/skeletonAttributes/SkeletonCarousel.vue';
import ProductAttributes from '@/components/modal/productModal/components/productAttributes/ProductAttributes.vue';
import { useRoutes } from '@/composables/useRoutes';
import { useApp } from '@/composables/useApp';
import { useProductStore } from '@/store/useProductStore';
import { toProductModel } from '@/models/product/ProductModel';
import { useUser } from '@/composables/useUser';

export default {
    name: 'TheProductInformation',
    components: {
        ProductAttributes,
        SkeletonAttributes,
        IconPaymentPlanes,
        ProductPrice,
        ProductIndicators,
        SecondaryProductActions,
        IconAddCart,
        TiptiAlert,
        ProductImages,
        TheNote,
        TheNutritionalInformation,
        TheMyLists,
        SnackBar,
        TheQuantityModifier,
    },
    mixins: [fetchDiscountActivationProgressBar],
    props: {
        section: {
            type: String,
            required: true,
        },
    },
    emits: ['activate-replacement'],
    setup(props) {
        const { $store, $t: t, isMobile } = useApp();
        const { isAuth } = useUser();
        const { getDetailProduct } = useProductStore();
        const { displayDefaultImg } = useImage();
        const { isMainCart, changeToExtraItemsCart } = useCartType();
        const { onChangedQuantity, quantityAction, removeReplacement, isLoading, quantity } = useProductQuantity(
            props.section,
        );
        const productInformationHelper = useProductInformation(quantity);
        const { exec } = useTranslate();
        const { isScreenMyAccount } = useRoutes();

        const showSnackBarInfo = computed(() => {
            let isSuccess = false;
            let isFailure = false;
            let message = '';

            if (productInformationHelper.showSnackBarForList.showSnackBar) {
                isSuccess = productInformationHelper.showSnackBarForList.isSuccess;
                isFailure = productInformationHelper.showSnackBarForList.isFailure;
                message = productInformationHelper.showSnackBarForList.message;
            }
            return {
                showSnackBar: productInformationHelper.showSnackBarForList.showSnackBar,
                isSuccess,
                isFailure,
                message,
            };
        });

        const closeSnackBar = () => {
            productInformationHelper.showSnackBarForList.showSnackBar = false;
        };

        return {
            t,
            closeSnackBar,
            showSnackBarInfo,
            quantity,
            isLoading,
            onChangedQuantity,
            quantityAction,
            changeToExtraItemsCart,
            removeReplacement,
            displayDefaultImg,
            ...productInformationHelper,
            ...useProductTags(),
            isMainCart,
            exec,
            isScreenMyAccount,
            isMobile,
            getDetailProduct,
            isAuth,
        };
    },
    data() {
        return {
            showAskForExtraItemsModal: false,
            isBoughtByWeight: false,
            isAfterAskForExtraItemsModal: false,
            alertData: undefined,
            attributes: undefined,
        };
    },
    watch: {
        product() {
            this.initProductState();
        },
    },
    mounted() {
        this.alertData = {
            title: this.t('txt.extra_items__active_order'),
            description: this.t('txt.active_order__message'),
            button_text: this.t('txt.add_in_order'),
            secondary_button_text: this.t('txt.create_new_cart'),
        } as BaseTiptiAlertModelInterface;
        this.initProductState();
        this.sectionShowed = 'ADD_NOTE';
    },
    async created(): Promise<void> {
        if (this.productSelected?.needToFetchDetail)
            this.getDetailProduct(+this.productSelected.id).then((data) => {
                const temp = toProductModel(data);
                this.productSelected.descriptionProduct = temp.descriptionProduct;
                this.productSelected.image = temp.image;
                this.productSelected.media = temp.media;
                this.productSelected.category = temp.category;
                this.productSelected.subCategory = temp.subCategory;
                this.productSelected.retailer = temp.retailer;
                this.productSelected.nutritionColors = temp.nutritionColors;
                this.productSelected.deepLink = temp.deepLink;
                this.productSelected = structuredClone(this.productSelected);
            });
        if (!this.productSelected?.groupId) return;
        this.$store
            .dispatch('product/getGroupedItems', this.productSelected?.stockItemId)
            .then((attr) => {
                this.attributes = attr;
            })
            .catch(() => (this.productSelected.groupId = undefined));
    },
    computed: {
        ...mapGetters({
            order: 'extraItems/order',
        }),
        validateForShow(): boolean {
            return this.order && this.isMainCart && !this.isScreenMyAccount;
        },
    },
    methods: {
        initProductState(): void {
            if (!this.product) return;
            this.$store.commit('product/tmpFile');
            this.$store.commit('product/tmpNote');
            this.quantity = +this.product.productQuantity === 0 ? 1 : this.product.productQuantity;
            if (!this.isAuth) return;
            this.checkFavorite();
        },
        handleActionProduct(isBoughtByWeight: boolean): void {
            this.isBoughtByWeight = isBoughtByWeight;
            if (this.validateForShow && !this.isAfterAskForExtraItemsModal) {
                this.showAskForExtraItemsModal = true;
                return;
            }
            if (this.isAfterAskForExtraItemsModal) this.$store.commit('extraItems/order', undefined);
            this.showAskForExtraItemsModal = false;
            this.quantityAction(isBoughtByWeight);
        },
        addInOrder(add: boolean): void {
            if (add) {
                this.changeToExtraItemsCart();
                return this.quantityAction(this.isBoughtByWeight);
            }
            this.isAfterAskForExtraItemsModal = true;
            this.showAskForExtraItemsModal = false;
            this.handleActionProduct(this.isBoughtByWeight);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
@import './the-product-information.scss';
@import '../common-product-modal.scss';

.alert_legend {
    @include caption;
    margin: $margin-sm;
}
</style>
