<template>
    <section class="countdown">
        <header class="countdown__title" :style="{ color: textColor, backgroundColor: bgColor }">
            <h2>{{ selectedStory?.interactiveElement.text.toUpperCase() }}</h2>
        </header>

        <div class="countdown__clock" :style="{ color: textColor, backgroundColor: bgColor }">
            <time
                class="clock-section"
                :style="styleDigits"
                :datetime="selectedStory?.interactiveElement?.elements[0]?.endDate"
            >
                <div class="clock-section__digit">{{ getDigits(days)[0] }}</div>
                <div class="clock-section__digit">{{ getDigits(days)[1] }}</div>
                <div class="clock-section__digit">{{ getDigits(hours)[0] }}</div>
                <div class="clock-section__digit">{{ getDigits(hours)[1] }}</div>
                <div class="clock-section__digit">{{ getDigits(minutes)[0] }}</div>
                <div class="clock-section__digit">{{ getDigits(minutes)[1] }}</div>
            </time>
            <div class="clock-period">
                <p>{{ $t("general['days']") }}</p>
                <p>{{ $t("general['hours']") }}</p>
                <p>{{ $t("general['minutes']") }}</p>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

export default {
    name: 'countdown',
    data() {
        return {
            total: 0,
            minutes: 0,
            hours: 0,
            days: 0,
        };
    },
    computed: {
        ...mapGetters({
            selectedStory: 'stories/selectedStory',
        }),
        textColor() {
            return this.selectedStory?.interactiveElement?.textColor ?? '#FFF';
        },
        bgColor() {
            return this.selectedStory?.interactiveElement?.textBackgroundColor ?? '#606060';
        },
        styleDigits() {
            return {
                '--bg-digit': this.selectedStory?.interactiveElement?.elements[0]?.textBackgroundColor ?? '#FFF',
                '--text-digit': this.selectedStory?.interactiveElement?.elements[0]?.textColor ?? '#606060',
            };
        },
    },
    mounted() {
        this.getTimeRemaining(this.selectedStory?.interactiveElement?.elements[0]?.endDate);
    },
    methods: {
        getTimeRemaining(endtime: string): void {
            this.total = Date.parse(endtime) - Date.now();
            this.minutes = Math.floor((this.total / 1000 / 60) % 60);
            this.hours = Math.floor((this.total / (1000 * 60 * 60)) % 24);
            this.days = Math.floor(this.total / (1000 * 60 * 60 * 24));
        },

        getDigits(digits: number): object {
            return { 0: Math.floor(digits / 10), 1: digits - Math.floor(digits / 10) * 10 };
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../../story-container.scss';
@import './countdown.scss';
</style>
