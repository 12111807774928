import { logger } from '@/utils/logger';
import { AlertBuilderModel } from '@/models/alerts/AlertBuilderModel';
import { AlertNames } from '@/enums/alertNames';

export default {
    async fetchAlerts({ rootGetters, commit }): Promise<void> {
        try {
            commit('alerts', []);
            const _url = `v2/franchises/${rootGetters['retailers/retailerSelected']?.franchise?.id}/alerts`;
            const { data } = await rootGetters['network/axios'].get(_url);
            commit('alerts', AlertBuilderModel.build(data));
        } catch (error) {
            commit('alerts', []);
            logger('FETCH_ALERTS_ERROR', error);
        }
    },
    async updateAlertFirstTime({ rootGetters, commit }): Promise<void> {
        try {
            const url = `v2/franchises/${rootGetters['retailers/retailerSelected']?.franchise?.id}/alerts/first_time/`;
            await rootGetters['network/axios'].post(url);
        } catch (err) {
            logger('UPDATE_ALERT_FIRST_TIME_ERROR', err);
            throw err.response;
        } finally {
            commit('removeAlert', AlertNames.firstTime);
        }
    },
};
