import { CountriesModel } from '@/models/external/CountriesModel';
import { CountryCodeModel } from '@/models/country/CountryCodeModel';

export default {
    countries(state: { countries: Array<CountriesModel> }, countries: Array<CountriesModel>): void {
        state.countries = countries;
    },
    countrySelected(state: { countrySelected: CountriesModel }, country: CountriesModel): void {
        state.countrySelected = country;
    },
    countryCodes(state, countries: Array<CountryCodeModel>) {
        state.countryCodes = countries;
    },
    countryCodeSelected(state, country: CountryCodeModel) {
        state.countryCodeSelected = country;
    },
};
