<template>
    <button
        v-if="!profile?.isPrime"
        class="prime-btn cursor-pointer"
        @click="$router.push({ name: appRoutesMap.account.tiptiPrime })"
    >
        <lottie-animation class="alert-purchase__img" :src="AnimationEnum.BECOME_TIPTI_PRIME" />
    </button>
</template>

<script lang="ts" setup>
import { useUser } from '@/composables/useUser';
import { AnimationEnum } from '@/enums/animationEnum';
import LottieAnimation from '@/components/animations/LottieAnimation.vue';
import { useRouter } from 'vue-router';
import { appRoutesMap } from '@/router/appRoutesMap';

const { profile } = useUser();
const $router = useRouter();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.prime-btn {
    width: 100%;
    background-color: $black;
    margin-bottom: $margin-sm;

    &__img {
        max-height: 3rem;
        width: 100%;
        object-fit: contain;
    }
}
</style>
