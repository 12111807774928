import { RemoteLocaleDto, RemoteLocaleModel } from '@/models/internationalization/RemoteLocale';

export interface ProductAttributeValueInterface {
    id: number;
    name: RemoteLocaleDto;
    image?: string;
}

export class ProductAttributeValueModel implements ProductAttributeValueInterface {
    readonly id: number;
    readonly name: RemoteLocaleModel;
    readonly image: string;

    constructor(data: ProductAttributeValueInterface) {
        this.id = data.id;
        this.name = RemoteLocaleModel.fromJson(data.name);
        this.image = data.image;
    }

    static fromJson(data: object): ProductAttributeValueModel {
        if (!data || !Object.keys(data).length) return null;
        return new ProductAttributeValueModel(data as ProductAttributeValueInterface);
    }

    static builderList(data: object): Array<ProductAttributeValueModel> {
        if (!data) return null;
        return (data as []).map(this.fromJson).filter(attribute => !!attribute);
    }
}
