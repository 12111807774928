import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a941f3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header__retailer-column" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "retailer-column__data"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "products-resume-column__indicator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_products = _resolveComponent("products")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_LoadPrescriptionItem = _resolveComponent("LoadPrescriptionItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_card, {
      class: "dropdown-retailer-products __outlined",
      "bg-color": $options.bgColor,
      "border-color": $options.borderColor,
      padding: 0
    }, {
      default: _withCtx(() => [
        _createElementVNode("header", {
          class: _normalizeClass(["retailer-products__header", { 'retailer-products__header--cursor-default': !$options.isRetailerAvailable }]),
          style: _normalizeStyle({ 'background-color': $options.headerBackground }),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.onHeaderPressed && $options.onHeaderPressed(...args)))
        }, [
          _createElementVNode("aside", _hoisted_1, [
            ($props.retailer.logo?.length)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: $props.retailer.logo,
                  alt: "",
                  onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.displayDefaultImg && $setup.displayDefaultImg(...args)))
                }, null, 40, _hoisted_2))
              : _createCommentVNode("", true),
            ($options.isScheduleSelected)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("h1", {
                    style: _normalizeStyle({ color: $options.headerFontColor })
                  }, _toDisplayString($props.retailer.name), 5)
                ]))
              : (!$options.isRetailerAvailable)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("txt['schedules__this-retailer-dont']")), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("txt['schedule__select-delivery-placeholder']")), 1))
          ]),
          ($data.showProducts)
            ? (_openBlock(), _createBlock(_component_icon_arrow, {
                key: 0,
                class: "header__arrow",
                color: $options.headerFontColor,
                "icon-name": _ctx.$t('icon.hide'),
                size: 0.75
              }, null, 8, ["color", "icon-name"]))
            : (_openBlock(), _createElementBlock("aside", {
                key: 1,
                class: _normalizeClass(["header__products-resume-column", { 'half-opacity': !$options.isScheduleSelected }])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.peekProducts, (product) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: product.id
                  }, [
                    (product.image?.medium)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: product.image.medium,
                          alt: "",
                          onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.displayDefaultImg && $setup.displayDefaultImg(...args)))
                        }, null, 40, _hoisted_6))
                      : _createCommentVNode("", true)
                  ]))
                }), 128)),
                ($props.retailer.products.length > $options.peekProducts.length)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_7, _toDisplayString(`+${$props.retailer.products.length - $options.peekProducts.length}`), 1))
                  : _createCommentVNode("", true)
              ], 2))
        ], 6),
        ($data.showProducts)
          ? (_openBlock(), _createBlock(_component_products, {
              key: 0,
              "is-disabled-editing": true,
              retailers: [$props.retailer],
              "show-delete": false,
              "show-header": false
            }, null, 8, ["retailers"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["bg-color", "border-color"]),
    ($options.hasItemsPrescription && $options.isRetailerAvailable)
      ? (_openBlock(), _createBlock(_component_LoadPrescriptionItem, {
          key: 0,
          "items-prescription": $props.itemsPrescription,
          retailer: $props.retailer,
          onOnPrescription: _cache[3] || (_cache[3] = (val) => _ctx.$emit('on-prescription', val)),
          onOnRemovePrescription: _cache[4] || (_cache[4] = (val) => _ctx.$emit('on-remove-prescription', val))
        }, null, 8, ["items-prescription", "retailer"]))
      : _createCommentVNode("", true)
  ], 64))
}