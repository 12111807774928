<template>
    <alert-manager
        :is-disabled="false"
        :is-loading="false"
        :show-bottom-sheet-title="false"
        :show-buttons="true"
        :only-confirmation-btn="true"
        :title="title"
        :accept-button-text="$t('cta.exit')"
        :btn-x-padding="1"
        :btn-y-padding="1"
        @on-close="$emit('on-close')"
        @on-accept="$emit('on-close')"
        @on-dismiss="$emit('on-close')"
        @on-close-modal="$emit('on-close')"
    >
        <template v-slot:content>
            <div class="validate-card">
                <icon-shield-check :size="2" class="validate-card__img" />
                <h2 class="validate-card__mobile-title">
                    {{ title }}
                </h2>
                <p class="validate-card__benefits">
                    {{ $t('txt.credit-card__benefit-message') }}
                </p>
            </div>
        </template>
    </alert-manager>
</template>

<script lang="ts">
import IconShieldCheck from '@/components/icons/IconShieldCheck.vue';
import AlertManager from '@/components/alerts/alertManager/AlertManager.vue';

export default {
    name: 'benefits-validation',
    components: {
        AlertManager,
        IconShieldCheck,
    },
    emits: ['on-close'],
    data() {
        return {
            title: this.$t('txt.credit-card__validate-benefits'),
        };
    },
};
</script>

<style scoped lang="scss">
@import 'benefits-validate';
</style>
