<template>
    <section class="lateral-menu" :class="{ 'lateral-menu--mobile': mobile }">
        <retailer-information />
        <div class="mobile-header">
            <button @click="$emit('close-menu')">
                <icon-circular-close :size="0.8" />
            </button>
            <div class="mobile-header__title">
                <icon-category :size="0.6" color="#606060" />
                <h3>{{ $t("cta['categories']") }}</h3>
            </div>
        </div>
        <div class="options">
            <div
                v-for="(nav, index) in navItems"
                :key="index"
                class="options__item"
                @click="
                    $router.push({
                        name: nav.path,
                    })
                "
            >
                <div class="options__item__icon-container">
                    <component :is="nav.component" :size="0.8" color="#606060" />
                </div>
                <h3>{{ nav.name }}</h3>
            </div>
        </div>
        <h2 class="categories-title">{{ $t('cta.categories') }}</h2>
        <div class="categories">
            <template v-if="categoriesLoading">
                <div v-for="index in 6" :key="index" class="categories__item categories__item--skeleton">
                    <base-bone />
                </div>
            </template>
            <transition-vertical-fade>
                <div v-if="!categoriesLoading">
                    <div
                        v-for="category in categories"
                        :key="category.id"
                        class="categories__item cursor-pointer"
                        @click="selectCategory(category)"
                    >
                        <p
                            class="categories__item__title"
                            :class="{ 'categories__item--selected': category.id == getCategorySelected?.id }"
                        >
                            {{ category.name }}
                        </p>
                        <div
                            v-if="getCategorySelected?.id == category.id && category.hasSubcategories"
                            class="subcategories"
                        >
                            <div
                                v-for="subcategory in subcategoriesList"
                                :key="subcategory.id"
                                class="subcategories__item"
                                @click.stop="selectSubcategory(subcategory)"
                            >
                                <p
                                    class="subcategories__item__text"
                                    :class="{
                                        'subcategories__item__text--selected':
                                            subcategory.id == getSubcategorySelected?.id,
                                    }"
                                >
                                    {{ subcategory.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-vertical-fade>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { logger } from '@/utils/logger';
import { CategoryModel, NewCategoryModel } from '@/models/product/CategoryModel';
import RetailerInformation from '../RetailerInformation.vue';
import IconMyLists from '@/components/icons/IconMyLists.vue';
import IconCoupons from '@/components/icons/IconCoupons.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import IconCategory from '@/components/icons/IconCategory.vue';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import TransitionVerticalFade from '@/modules/core/components/transition/TransitionFade.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { useUser } from '@/composables/useUser';
import { useHeader } from '@/components/headers/headerMain/useHeader';
import { computed, ref } from 'vue';
import { useApp } from '@/composables/useApp';
import { useCategories } from '@/modules/main-products/composables/useCategories';
import { appRoutesMap } from '@/router/appRoutesMap';

defineProps({
    mobile: {
        type: Boolean,

        default: false,
    },
});

const { retailerSelected } = useRetailer();
const { isAuth } = useUser();
const { height: headerHeight } = useHeader();
const { $store, $router, $t } = useApp();
const { fetchCategoriesData } = useCategories();

const categoriesLoading = ref<boolean>(true);
const categories = ref<CategoryModel[]>([]);
const navItems = [
    {
        name: $t("cta['coupons']"),
        path: appRoutesMap.coupons.couponsAndPromotions,
        component: IconCoupons,
    },
    {
        name: $t("cta['lists-and-recipes']"),
        path: 'personal-lists',
        component: IconMyLists,
    },
];

const subcategoriesList = computed(() => $store.getters['categories/subcategoriesList']);
const getCategorySelected = computed(() => $store.getters['categories/getCategorySelected']);
const getSubcategorySelected = computed(() => $store.getters['categories/getSubcategorySelected']);
const allSubCategories = computed(() => $store.getters['categories/allSubCategories']);
const fetchCategories = async (): Promise<void> => {
    try {
        const localCategories = [
            $store.dispatch('categories/categories', retailerSelected.value.id),
            $store.dispatch('product/categoriesPromotions', retailerSelected.value.id),
        ];
        const [normalCategories, discountCategories] = await Promise.allSettled<[CategoryModel[]]>(localCategories);
        const categoriesResult = normalCategories.status !== 'rejected' ? normalCategories.value : [];

        //*** Update route name with deeplink
        if (getCategorySelected.value?.isDeeplink) {
            const currentCategoryFromDeeplink = categoriesResult.find(
                (category) => category.id == getCategorySelected.value.id,
            );
            currentCategoryFromDeeplink.isDeeplink = true;
            $store.commit('categories/setCategorySelected', currentCategoryFromDeeplink);
            $router.push({
                name: appRoutesMap.retail.baseCategoryView,
                params: {
                    retailer: retailerSelected.value?.name ?? '',
                    category: currentCategoryFromDeeplink?.name ?? 'deeplink',
                },
            });
        }
        if (discountCategories.status === 'rejected' || !discountCategories.value?.length) {
            categories.value = categoriesResult as CategoryModel[];
            fetchCategoriesData();
            return;
        }
        categoriesResult.splice(
            0,
            0,
            new NewCategoryModel({
                id: 0,
                name: $t('txt.main-products__discount-products'),
                request_subcategories: true,
                isDiscount: true,
            }),
        );
        categories.value = categoriesResult as CategoryModel[];
        fetchCategoriesData();
    } catch (err) {
        logger('FETCH_CATEGORIES', err);
    } finally {
        categoriesLoading.value = false;
    }
};

fetchCategories();

const selectCategory = (category: CategoryModel): void => {
    if (getCategorySelected.value?.id == category.id) return;
    $store.commit('categories/setCategorySelected', category);

    const setSubCategory = allSubCategories.value.find((cat) => cat.categoryId === category.id);
    $store.commit('categories/subcategoriesList', setSubCategory?.subcategories ?? []);

    $router.push({
        name: appRoutesMap.retail.baseCategoryView,
        params: {
            category: category.name,
        },
    });
};

const selectSubcategory = (subcategory: CategoryModel): void => {
    if (getSubcategorySelected.value?.id == subcategory.id) return;
    $store.commit('categories/setSubcategorySelected', { subcategory });
    $router.push({
        name: appRoutesMap.retail.indexCategorySeeMore,
        params: {
            subcategory: subcategory.name,
        },
    });
};

defineEmits<(e: 'close-menu') => void>();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.lateral-menu {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: v-bind(headerHeight);
    height: calc(100svh - v-bind(headerHeight));
    background-color: $white;
    @include tablet {
        display: none;
    }

    &--mobile {
        display: none;
        @include tablet {
            position: relative;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            height: 100%;
            width: 250px;
        }
    }
}

.retailer-info {
    @include tablet {
        display: none;
    }
}

.mobile-header {
    display: none;
    margin: $margin-lg;
    border-bottom: 2px solid $bright-grey;
    @include tablet {
        display: block;
    }

    button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        margin-bottom: $margin-lg;
    }

    &__title {
        margin-bottom: $margin-lg;
        display: flex;
        align-items: center;

        h3 {
            @include subtitle2;
            margin-left: $margin-sm;
        }
    }
}

.options {
    display: flex;
    flex-direction: column;
    border-top: 2px solid $bright-grey;
    border-bottom: 2px solid $bright-grey;
    padding: $padding-md $padding-none;
    @include tablet {
        display: none;
    }

    &__item {
        display: flex;
        align-items: center;
        margin: $margin-sm $margin-md;
        cursor: pointer;

        h3 {
            @include subtitle2;
            font-size: 16px;
        }

        &__icon-container {
            width: 3.5rem;
            display: flex;
            justify-content: center;
        }
    }
}

.categories-title {
    @include subtitle2;
    font-size: 16px;
    margin: $margin-md $margin-lg;
    @include tablet {
        display: none;
    }
}

.categories {
    flex-grow: 1;
    overflow-y: auto;
    padding-left: $padding-lg;
    padding-right: $padding-md;
    padding-bottom: $padding-md;

    &__item {
        margin: $margin-sm $margin-none;

        &--skeleton {
            width: 100%;
            height: 1.5rem;
        }

        &__title {
            @include body1;
            padding-left: $padding-md;
            border-radius: $radius-sm;
            font-size: 16px;
            transition: all 0.5s ease;

            &:hover {
                color: $orange;
                background-color: $canvas;
            }
        }

        &--selected {
            color: $orange;
            background-color: $canvas;
        }
    }
}

.subcategories {
    padding-left: $padding-md;

    &__item {
        padding-left: $padding-sm;
        margin: $margin-sm $margin-none;

        &__text {
            @include body2;
            font-size: 14px;

            &:hover,
            &--selected {
                font-weight: 600;
            }
        }
    }
}
</style>
