import { ProductModel } from '../product/ProductModel';

class ListCartModel {
    readonly listIds: Array<number>;
    readonly product: ProductModel;

    constructor(listIds: Array<number>, product: ProductModel) {
        this.listIds = listIds;
        this.product = product;
    }
}

export { ListCartModel };
