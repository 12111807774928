<template>
    <base-icon
        :height="height"
        :icon-color="color"
        :icon-name="$t('icon.tipti_card')"
        :width="width"
        viewBox="0 0 29 26"
    >
        <path
            :fill="color"
            d="M28.5968 13.1727V18.6469C28.5968 19.1984 28.1474 19.6477 27.5959 19.6477H23.3473C21.3558 19.6477 19.7422 18.0341 19.7422 16.0426V15.777C19.7422 13.7855 21.3558 12.1719 23.3473 12.1719H27.5959C28.1474 12.1719 28.5968 12.6212 28.5968 13.1727Z"
        />
        <path
            :fill="color"
            d="M24.7013 1.36853V4.28737H3.67596C2.49126 4.28737 1.53125 3.32736 1.53125 2.14267C1.53125 0.960012 2.49024 0 3.67392 0H23.3327C24.0885 0 24.7013 0.612774 24.7013 1.36853Z"
        />
        <path
            :fill="color"
            d="M23.3467 10.6403H27.2991V7.18839C27.2991 6.43263 26.6863 5.81986 25.9306 5.81986H2.91068C1.30725 5.81986 0 4.52282 0 2.90918V22.4465C0 24.4073 1.59321 26.0005 3.55409 26.0005H24.3884C25.9918 26.0005 27.2991 24.6933 27.2991 23.0899V21.1801H23.3467C20.5147 21.1801 18.2096 18.876 18.2096 16.043V15.7774C18.2096 12.9444 20.5147 10.6403 23.3467 10.6403ZM9.19876 11.2705H4.39359C3.88601 11.2705 3.47443 10.8589 3.47443 10.3513C3.47443 9.84374 3.88601 9.43216 4.39359 9.43216H9.19876C9.70634 9.43216 10.1179 9.84374 10.1179 10.3513C10.1179 10.8589 9.70634 11.2705 9.19876 11.2705Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconTiptiCard',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C7C7C7',
        },
    },
    computed: {
        width(): number {
            return 29 * this.size;
        },
        height(): number {
            return 26 * this.size;
        },
    },
};
</script>
