export const breadcrumbDefinition = Object.freeze({
    account: 'breadcrumb.account',
    statistics: 'breadcrumb.statistics',
    profile: 'breadcrumb.profile',
    programs: 'breadcrumb.loyalty',
    address: 'breadcrumb.address',
    bills: 'breadcrumb.bills',
    creditCards: 'breadcrumb.creditCards',
    shareData: 'breadcrumb.shareData',
    orders: 'breadcrumb.orders',
    order: 'breadcrumb.order',
    prime: 'breadcrumb.prime',
    notifications: 'breadcrumb.notifications',
    referred: 'breadcrumb.referred',
    subscriptions: 'breadcrumb.subscriptions',
    settings: 'breadcrumb.settings',
    wallet: 'breadcrumb.wallet',
    tiptiCard: 'breadcrumb.tiptiCard',
    history: 'breadcrumb.history',
    gift: 'breadcrumb.gift',
    recharge: 'breadcrumb.recharge',
    myGifts: 'breadcrumb.myGifts',
    transfer: 'breadcrumb.transfer',
    maxiCard: 'breadcrumb.maxiCard',
    registeredCard: 'breadcrumb.registeredCard',
    membershipCard: 'breadcrumb.membershipCard',
    giftCards: 'breadcrumb.giftCards',
    expiredGiftCards: 'breadcrumb.expiredGiftCards',
});
