<template>
    <section>
        <h2 class="institutional-title">{{ $t("general['institutional-assigned']") }}</h2>
        <base-loading v-if="isLoading" />
        <div v-else class="institutions">
            <div v-for="institutional in institutions" :key="institutional?.institution?.id">
                <base-card-flat class="institution">
                    <div v-if="!isSelectionActive(institutional)" class="institution__not-enough">
                        <p>
                            {{ $t("txt['institutional__not-enough']") }}
                        </p>
                    </div>
                    <img
                        v-if="institutional?.institution?.logo"
                        :alt="institutional?.institution?.name"
                        :src="institutional.institution.logo"
                    />
                    <div class="institution-data">
                        <h2>{{ institutional?.institution?.name }}</h2>
                        <p
                            v-if="!institutional?.unlimitedBuys && +institutional?.amountAvailable > 0"
                            class="institution-data__balance"
                        >
                            {{ $t("general['available-balance']") }}: {{ currency }}
                            {{ institutional?.amountAvailable }}
                        </p>
                    </div>
                    <radio-normal
                        v-if="isSelectionActive(institutional)"
                        :is-selected="institutionalSelected?.institution?.id === institutional?.institution?.id"
                        :value="institutional"
                        radio-group="institutionalPayment"
                        @on-check="selectInstitution"
                    />
                </base-card-flat>
                <default-payment-selector
                    v-if="institutionalSelected?.institution?.id === institutional?.institution?.id"
                    :client-institution-id="institutionalSelected.clientInstitutitonId"
                    :is-default="institutionalSelected.resultDefault"
                    :payment-channel-selected="paymentChannel"
                    :payment-type="paymentType"
                    @on-set-default="fetchInstitutions"
                />
            </div>
        </div>
    </section>
    <snack-bar
        :body="$t('txt.institutional__not-meet')"
        :is-active="showAlertNoMeets"
        @on-snackbar-close="showAlertNoMeets = false"
        @on-snackbar-tap="showAlertNoMeets = false"
    />
    <general-alert
        v-if="showAlertMixPayment"
        :message="
            $t('txt.mix-payment__are-you-sure', {
                method: `${$t('general.payment-institutional')} ${institutionalSelected?.institution?.name}`,
                amount: institutionalSelected?.amountAvailable,
                difference: difference,
                currency: currency,
            })
        "
        @on-accept="acceptMixPayment"
        @on-decline="declineMixPayment"
        @on-close="declineMixPayment"
    >
        <template #icon>
            <icon-institutional :size="4" color="#FF9012" />
        </template>
    </general-alert>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import { InstitutionalModel } from '@/models/paymentMethods/InstitutionalModel';
import { isMobileBrowser } from '@/utils/utils';
import { PaymentChannelOptions } from '@/enums/paymentChannelOptions';
import RadioNormal from '@/components/radio/RadioNormal.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import InstitutionalPaymentModel from '@/models/checkout/InstitutionalPaymentModel';
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import IconInstitutional from '@/components/icons/IconInstitutional.vue';
import SnackBar from '@/components/alerts/snackBar/SnackBar.vue';
import DefaultPaymentSelector from '@/components/defaultPaymentSelector/DefaultPaymentSelector.vue';
import { DefaultPaymentTypeModel } from '@/models/paymentMethods/DefaultPaymentTypeModel';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRoutes } from '@/composables/useRoutes';

export default {
    name: 'InstitutionalPayment',
    components: {
        RadioNormal,
        BaseCardFlat,
        BaseLoading,
        GeneralAlert,
        IconInstitutional,
        SnackBar,
        DefaultPaymentSelector,
    },
    setup() {
        const { isScreenExtraItemsPayment, isScreenInstitutional } = useRoutes();
        return {
            isScreenExtraItemsPayment,
            isScreenInstitutional,
        };
    },
    data() {
        return {
            isLoading: false,
            showAlertMixPayment: false,
            institutionalSelected: undefined,
            institutions: [],
            showAlertNoMeets: false,
            paymentType: 0,
        };
    },

    computed: {
        ...mapGetters({
            isMixInstitutionalPayment: 'payments/isMixInstitutionalPayment',
            isMixPaymentTiptiCard: 'payments/isMixPaymentTiptiCard',
            secondPayment: 'payments/secondPayment',
            totalToPay: 'payments/totalToPay',
            countrySelected: 'external/countrySelected',
            order: 'orders/orderDetailSelected',
            defaultPaymentTypes: 'payments/defaultPaymentsTypesAvailable',
        }),

        currency() {
            return this.countrySelected?.currencySymbol ?? '$';
        },
        isMobile(): boolean {
            return isMobileBrowser();
        },
        difference(): number {
            return +(this.totalToPay - +this.institutionalSelected?.amountAvailable).toFixed(2);
        },
        isMixPaymentUnavailableBySection(): boolean {
            return this.isScreenInstitutional || this.isScreenExtraItemsPayment;
        },
        paymentChannel(): string {
            return PaymentChannelOptions.INSTITUTIONAL;
        },
    },
    methods: {
        ...mapActions({
            fetchInstitutions: 'payments/institutions',
            paymentTypeByChannel: 'payments/getPaymentTypeByChannel',
        }),
        async isPaymentIdType() {
            this.paymentType = this.defaultPaymentTypes.find(
                (paymentType: DefaultPaymentTypeModel) =>
                    paymentType.channelPayment == PaymentChannelOptions.INSTITUTIONAL,
            )?.id;
            return this.paymentType;
        },

        isSelectionActive(institutional: InstitutionalModel): boolean {
            if (
                +institutional?.amountAvailable < this.order?.totalPayed &&
                this.$route.path.includes(appRoutesMap.paymentResent.resend)
            )
                return false;
            if (institutional.unlimitedBuys) return true;
            if (
                !this.isMixPaymentTiptiCard &&
                +institutional?.amountAvailable > 0 &&
                !this.isMixPaymentUnavailableBySection
            )
                return true;
            if (
                !institutional.unlimitedBuys &&
                this.totalToPayInstitutional(+institutional.amountAvailable) <= +institutional?.amountAvailable
            )
                return true;
            return (
                this.isMixPaymentTiptiCard &&
                +institutional?.amountAvailable > 0 &&
                this.totalToPayInstitutional(+institutional.amountAvailable) <= +institutional?.amountAvailable
            );
        },
        totalToPayInstitutional(amountAvailable: number): number {
            return this.isMixPaymentTiptiCard
                ? this.secondPayment
                : this.isMixInstitutionalPayment && amountAvailable < this.totalToPay
                  ? amountAvailable
                  : this.totalToPay;
        },
        selectInstitution(institutional: InstitutionalModel): void {
            this.isPaymentIdType();
            this.institutionalSelected = institutional;
            if (this.isMixPaymentTiptiCard && institutional.unlimitedBuys) {
                this.$store.commit('payments/isMixPaymentTiptiCard', false);
                this.showAlertNoMeets = true;
            }

            this.$store.commit('payments/cleanPaymentMethods');
            if (
                !this.isMixPaymentTiptiCard &&
                !institutional?.unlimitedBuys &&
                +institutional?.amountAvailable < this.totalToPay
            ) {
                this.showAlertMixPayment = true;
                this.$store.commit('payments/isMixInstitutionalPayment', true);
                this.$store.commit('payments/secondPayment', this.difference);
            }

            this.$store.commit(
                'payments/institutionPayment',
                new InstitutionalPaymentModel(
                    institutional?.institution?.id,
                    institutional?.unlimitedBuys,
                    /// * always set amount since on main checkout the only place to send institutional_amount
                    /// * the verification is if [unlimitedBuys] false send institutional_amount
                    this.totalToPayInstitutional(+institutional?.amountAvailable),
                ),
            );

            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-institutional',
                    amount: this.totalToPayInstitutional(+institutional?.amountAvailable),
                    data: institutional?.institution?.name,
                    analyticData: {
                        selected_institutional_name: institutional?.institution?.name,
                    },
                    objectData: [
                        'payment-institutional',
                        new InstitutionalPaymentModel(
                            institutional?.institution?.id,
                            institutional?.unlimitedBuys,
                            /// * always set amount since on main checkout the only place to send institutional_amount
                            /// * the verification is if [unlimitedBuys] false send institutional_amount
                            this.totalToPayInstitutional(+institutional?.amountAvailable),
                        ),
                    ],
                }),
            );
        },
        acceptMixPayment() {
            this.showAlertMixPayment = false;
            if (this.isMobile) this.$router.replace({ name: appRoutesMap.checkout.payments.index });
        },
        declineMixPayment() {
            this.institutionalSelected = undefined;
            this.showAlertMixPayment = false;
            this.$store.commit('payments/isMixInstitutionalPayment', false);
            this.$store.commit('payments/cleanPaymentMethods');
        },
    },
    async mounted() {
        this.isLoading = true;
        this.institutions = await this.fetchInstitutions();
        this.isLoading = false;
    },
};
</script>

<style lang="scss" scoped>
@import './institutional-payment.scss';
</style>
