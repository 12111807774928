<template>
    <!--* Manage search for categories in different secctions [main-categories] [extra-items-categories] [my-list-categories]
Works with store and the component category-products depends for this
You can add this component in othes sections of categories and ever redirect to principal screen related to [redirecToSubcategory] route
-->
    <search-products
        class="subheader-products-search"
        :has-autocomplete="false"
        :is-loading="isLoading"
        :origin-section="originSection"
        :placeholder="searchBarPlaceholder"
        :search-categories="searchCategories"
        @on-search="onSearch"
        @on-clean="onClean"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { SearchBar } from '@/enums/searchBar';
import { ProductModel } from '@/models/product/ProductModel';
import SearchProducts from '@/components/inputs/searchProducts/SearchProducts.vue';
import { PropType } from 'vue';
import { Sections } from '@/enums/sectionsOrigin';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export default {
    components: { SearchProducts },
    props: {
        originSection: {
            type: String as PropType<keyof typeof Sections>,
        },
        searchCategories: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const { retailerSelected } = useRetailer();

        return {
            retailerSelected,
        };
    },
    computed: {
        ...mapGetters({
            isLoading: 'categories/isSearchCategoryProducts',
            categorySelected: 'categories/getCategorySelected',
            subcategorySelected: 'categories/getSubcategorySelected',
        }),
        isDiscount(): boolean {
            return this.categorySelected?.isDiscount;
        },
        searchBarPlaceholder(): string {
            return `${this.$t('general.search-on')} ${this.sectionName ?? this.retailerSelected?.name}`;
        },
        searchOn(): string {
            return this.$route.query.searchOn;
        },
        sectionName(): string {
            return this.categorySelected?.name;
        },
    },
    mounted() {
        this.$store.commit('categories/isSearchCategoryProducts', false);
    },
    methods: {
        async onClean(): Promise<void> {
            if (!this.searchOn?.length) return;
            await this.$router.go(-1);
        },
        async onSearch(query: string): Promise<void> {
            this.$emit('redirect-on-search', query);
            this.$store.dispatch('segment/search', {
                keyword: query ?? this.searchOn ?? '',
                search_bar: {
                    search_bar: SearchBar.CATEGORY,
                    search_bar_id: SearchBar.CATEGORY,
                },
                origin: {
                    screen: this.$route.path,
                    section: this.originSection,
                },
                products: this.products?.map((product: ProductModel) => product.name),
            });
        },
    },
    emits: ['redirect-on-search'],
};
</script>
