<template>
    <add-payphone v-if="showAddNumber" :total-to-pay="totalToPay" @on-back="showAddNumber = false" />
    <section v-else>
        <dotted-rectangle :button-text="$t('general.new-number')" @click="showAddNumber = true" />
        <subheader-toggle
            :is-active="deleteNumber"
            :subtitle="$t('txt.payphone__select-number')"
            @on-pressed="deleteNumber = !deleteNumber"
        />
        <phones class="phone" :delete-number="deleteNumber" />
    </section>
</template>

<script lang="ts">
import DottedRectangle from '@/components/dottedRectangle/DottedRectangle.vue';
import SubheaderToggle from '@/components/subheaders/subheaderToggle/SubheaderToggle.vue';
import Phones from './components/phones/Phones.vue';
import AddPayphone from './components/addPayphone/AddPayphone.vue';
import { PayphoneModel } from '@/models/paymentMethods/PayphoneModel';
import payphoneMixin from '@/mixings/payphoneMixin';

export default {
    name: 'Payphone',
    components: {
        DottedRectangle,
        SubheaderToggle,
        Phones,
        AddPayphone,
    },
    mixins: [payphoneMixin],
    data() {
        return {
            deleteNumber: false,
            showAddNumber: false,
            payphones: [PayphoneModel],
        };
    },
    computed: {
        totalToPay(): string {
            return this.$route.query?.totalToPay ?? this.cart?.totalDetails?.totalCost;
        },
    },
    mounted() {
        this.fetchPayphones();
    },
};
</script>
