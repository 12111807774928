import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b535e74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "download__stores" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.stores, (store) => {
      return (_openBlock(), _createElementBlock("button", {
        key: store,
        class: "cursor-pointer store-app",
        onClick: ($event: any) => ($options.clickStore(store))
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(store.icon), _normalizeProps(_guardReactiveProps(store)), null, 16))
      ], 8, _hoisted_2))
    }), 128))
  ]))
}