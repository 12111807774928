/* eslint-disable @typescript-eslint/camelcase */
import { StoriesResultModel, toStoriesResultModel } from '@/models/stories/StoriesResultModel';
import { PromotionsCategoriesModel, toPromotionsCategoriesModel } from '@/models/promotions/PromotionsCategoriesModel';
import { ProductModel, toProductModel } from '@/models/product/ProductModel';
import { toAdModel } from '@/models/ads/AdModel';
import { toRecipeDetailModel } from '@/models/recipes/RecipeDetailModel';
import { logger } from '@/utils/logger';
import router from '@/router';
import { appRoutesMap } from '@/router/appRoutesMap';
import { ResponsePaged } from '@/modules/core/models/ResponseModels';

interface IdPage {
    id: number;
    page: number;
    retailerId: number;
}

export default {
    async stories({ rootGetters, commit, getters }, page: number): Promise<boolean> {
        try {
            const url = `v2/retailer/${rootGetters['retailers/retailerSelected']?.id}/histories/?page=${page ?? 1}`;
            const { data } = await rootGetters['network/axios'].get(url);
            const allStories = data['results']?.map((story: StoriesResultModel) => toStoriesResultModel(story));
            const isLastPage = data['next']?.length ? false : true;
            allStories?.sort(function (x, y) {
                return x === y ? 0 : x ? 1 : -1;
            });
            allStories?.forEach((story) => {
                let storyViewed = true;
                story.elements.forEach((element) => {
                    if (!element.viewed) storyViewed = false;
                });
                story.viewed = storyViewed;
            });
            const regularStories = allStories?.filter((element) => !element.featured);
            const featuredStory = allStories?.find((story) => story.featured);
            const stories = page == 1 ? [] : getters['stories'];
            stories?.push(...regularStories);
            commit('stories', stories);
            commit('featuredStory', featuredStory);
            return isLastPage;
        } catch (err) {
            logger('STORIES', err);
        }
    },

    async productsWithDiscount({ rootGetters }, payload: IdPage): Promise<object> {
        try {
            const url = `v2/retailer/${rootGetters['retailers/retailerSelected'].id}/histories/${
                payload.id
            }/discount_detail?page=${payload.page ?? 1}`;
            const { data } = await rootGetters['network/axios'].get(url);
            const products = data['results'].map((product: object) => toProductModel(product));
            return {
                isLastPage: data['next'] === null || data['next'] === undefined,
                products: products,
            };
        } catch (err) {
            logger('PRODUCTS_FROM_STORY', err);
        }
    },

    async productsFromStory({ rootGetters }, payload: IdPage): Promise<ResponsePaged<ProductModel[]>> {
        try {
            const { data } = await rootGetters['network/axios'].get(
                `v2/retailer/${payload.retailerId}/histories/${payload.id}/detail`,
                {
                    params: {
                        page: payload.page,
                    },
                },
            );

            return {
                ...data,
                results: data?.results?.map((product: ProductModel) => toProductModel(product)),
            };
        } catch (err) {
            logger('PRODUCTS_FROM_STORY', err);
        }
    },

    async recipe({ rootGetters, commit }, id: number): Promise<void> {
        try {
            const url = `v2/retailer/${rootGetters['retailers/retailerSelected'].id}/histories/${id}/detail`;
            const response = await rootGetters['network/axios'].get(url);
            const adRecipe = toAdModel(response.data['results'][0]);
            const recipeDetail = toRecipeDetailModel(response.data['results'][0]['recipe']);
            commit('lists/recipeAds', adRecipe, { root: true });
            commit('lists/recipeDetail', recipeDetail, { root: true });
            router.push({
                name: appRoutesMap.list.recipeDetail,
                query: { group: 'Banner', name: recipeDetail.name, id: recipeDetail.id },
            });
        } catch (err) {
            logger('STORIES_RECIPE', err);
        }
    },

    async storiesSubcategories({ rootGetters }, payload: IdPage): Promise<ResponsePaged<PromotionsCategoriesModel[]>> {
        try {
            const { data } = await rootGetters['network/axios'].get(
                `v2/retailer/${payload.retailerId}/histories/${payload.id}/detail`,
                {
                    params: {
                        page: payload.page,
                    },
                },
            );

            return {
                ...data,
                results:
                    data?.results?.map((category: PromotionsCategoriesModel) =>
                        toPromotionsCategoriesModel(category),
                    ) ?? [],
            };
        } catch (err) {
            logger('STORIES_SUBCATEGORIES', err);
        }
    },

    async setAsViewed({ rootGetters }, elementId: number): Promise<void> {
        try {
            const url = `v2/histories/${elementId}/activity`;
            await rootGetters['network/axios'].post(url);
        } catch (err) {
            logger('SET AS VIEWED', err);
        }
    },
};
