import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-281073d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions-quantity" }
const _hoisted_2 = { class: "input__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_trash = _resolveComponent("icon-trash")!
  const _component_btn_circular = _resolveComponent("btn-circular")!
  const _component_icon_decrement = _resolveComponent("icon-decrement")!
  const _component_icon_increment = _resolveComponent("icon-increment")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    ($options.remove)
      ? (_openBlock(), _createBlock(_component_btn_circular, {
          key: 0,
          class: _normalizeClass({
                'actions-quantity--small': $props.isFromAutocomplete,
            }),
          "is-loading": $data.isLoadingDelete,
          "bg-color": "#FFF",
          "loading-size": ".5rem",
          padding: "0 .5rem",
          onClick: $options.deleteProduct
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_trash, {
              class: "actions_svg",
              color: $props.isMiniature ? '#FFF' : '#707070',
              size: 1.5
            }, null, 8, ["color"])
          ]),
          _: 1
        }, 8, ["class", "is-loading", "onClick"]))
      : (_openBlock(), _createBlock(_component_btn_circular, {
          key: 1,
          class: _normalizeClass(["actions-quantity--shadow", {
                'actions-quantity--small': $props.isFromAutocomplete,
            }]),
          "bg-color": "#FFF",
          padding: "0 .5rem",
          onClick: $options.decrement
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_decrement, {
              class: "actions_svg",
              size: 1,
              color: "#707070"
            })
          ]),
          _: 1
        }, 8, ["class", "onClick"])),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.quantity) = $event)),
        class: "actions-quantity__input",
        type: "number",
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onEnter && $options.onEnter(...args))),
        onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => ($options.onEnter && $options.onEnter(...args)), ["enter"]))
      }, null, 544), [
        [
          _vModelText,
          $data.quantity,
          void 0,
          { number: true }
        ]
      ])
    ]),
    _createVNode(_component_btn_circular, {
      class: _normalizeClass(["actions-quantity--shadow", {
                'actions-quantity--small': $props.isFromAutocomplete,
            }]),
      "bg-color": "#FFF",
      padding: "0 .5rem",
      onClick: $options.increment
    }, {
      default: _withCtx(() => [
        _createVNode(_component_icon_increment, {
          class: "actions_svg",
          size: 1,
          color: "#707070"
        })
      ]),
      _: 1
    }, 8, ["class", "onClick"])
  ]))
}