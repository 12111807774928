<template>
    <base-icon
        :icon-name="$t('icon.plus_circle')"
        :width="width"
        :height="height"
        viewBox="0 0 20 20"
        :iconColor="color"
    >
        <circle cx="9.56522" cy="9.56522" r="9.56522" />
        <path
            d="M8.42417 15.6524V3.47852H10.7068V15.6524H8.42417ZM3.47852 10.6772V8.48149H15.6524V10.6772H3.47852Z"
            fill="white"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-plus-circle',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 20 * this.size;
        },
        height(): number {
            return 20 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
