class ShopperModel {
    readonly id: number;
    readonly name: string;
    readonly picture: string;
    readonly phone: string;
    readonly document: string;
    readonly rating: number;
    readonly email: string;
    readonly shopperRole: string;
    readonly shopperRoleText: string;

    constructor(
        id: number,
        name: string,
        picture: string,
        phone: string,
        document: string,
        rating: number,
        email: string,
        shopperRole?: string,
        shopperRoleText?: string,
    ) {
        this.id = id;
        this.name = name;
        this.picture = picture;
        this.phone = phone;
        this.document = document;
        this.rating = rating;
        this.email = email;
        this.shopperRole = shopperRole;
        this.shopperRoleText = shopperRoleText;
    }
}

const toShopperModel = (data: object): ShopperModel => {
    return data
        ? new ShopperModel(
              data['id'],
              data['name'] ?? '',
              data['picture'],
              data['phone'],
              data['document_id'],
              +data['rating'] === 0 ? 5 : +data['rating'],
              data['user']?.['email'] ?? '',
              data['shopper_role'],
              data['shopper_role_text'],
          )
        : null;
};
export { ShopperModel, toShopperModel };
