<template>
    <div class="greetings">
        <div class="title-container">
            <h2 class="greetings__title">BIENVENIDO A</h2>
            <tipti-with-slogan class="greetings__logo" :size="tiptiLogoSize" />
        </div>
        <div class="carousel-container">
            <div
                v-for="(item, index) in contentArray"
                :key="index"
                class="carousel"
                :class="{ 'hide-carousel': index !== selectedIndex }"
            >
                <Transition name="slide-fade">
                    <div v-if="selectedIndex == index" class="carousel__item">
                        <h3 class="carousel__item__title">
                            {{ item.title }}
                        </h3>
                        <img class="carousel__item__img" :src="require(`@/assets/svg/auth/${item.imgSrc}`)" alt="" />
                        <p class="carousel__item__description">{{ item.description }}</p>
                    </div>
                </Transition>
            </div>
            <div class="indicator">
                <div
                    v-for="index in 4"
                    :key="index"
                    class="indicator__circle"
                    :class="{ 'filled-circle': index - 1 === selectedIndex }"
                    @click="selectCarouselIndex(index - 1)"
                />
            </div>
        </div>
        <icon-circular-close class="close-btn" :size="0.8" @click="$emit('on-close')" />
    </div>
</template>
<script lang="ts">
import TiptiWithSlogan from '@/components/logos/TiptiWithSlogan.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import MediaQueryValues from '@/assets/ts/media_query';

export default {
    components: {
        TiptiWithSlogan,
        IconCircularClose,
    },
    emits: ['on-close'],
    data() {
        return {
            selectedIndex: 0,
            contentArray: [
                {
                    title: 'Asistente de compras',
                    imgSrc: 'greetings_step_1.svg',
                    description: 'Elegimos los mejores productos del supermercado para ti.',
                },
                {
                    title: 'Rápido y conveniente',
                    imgSrc: 'greetings_step_2.svg',
                    description: 'Aseguramos calidad para que los mejores productos lleguen a tu hogar.',
                },
                {
                    title: 'Supermercado a domicilio',
                    imgSrc: 'greetings_step_3.svg',
                    description: 'Los mejores shoppers de delivery que entregan tu pedido a tiempo.',
                },
                {
                    title: 'Tiempo para ti',
                    imgSrc: 'greetings_step_4.svg',
                    description: 'Con Tipti ahorras tiempo para hacer lo que más te gusta.',
                },
            ],
            interval: null,
            windowWidth: window.innerWidth,
            tiptiLogoSize: 1.6,
        };
    },
    mounted() {
        this.startInterval();
        this.setLogoSize();
    },
    updated() {
        this.onResize();
    },
    unmounted() {
        this.stopInterval();
    },
    methods: {
        changeSelectedIndex() {
            if (this.selectedIndex >= 3) {
                this.selectedIndex = 0;
                return;
            }
            this.selectedIndex += 1;
        },
        startInterval() {
            this.interval = setInterval(this.changeSelectedIndex, 4000);
        },
        selectCarouselIndex(index) {
            this.stopInterval();
            this.selectedIndex = index;
            this.startInterval();
        },
        stopInterval() {
            clearInterval(this.interval);
        },
        onResize(): void {
            this.windowWidth = window.innerWidth;
            this.setLogoSize();
        },
        setLogoSize(): void {
            if (this.windowWidth <= MediaQueryValues.extraSmallDeviceWidth) {
                this.tiptiLogoSize = 0.4;
                return;
            }
            if (this.windowWidth <= MediaQueryValues.mediumDeviceWidth) {
                this.tiptiLogoSize = 1;
                return;
            }
            this.tiptiLogoSize = 1.6;
        },
    },
};
</script>
<style lang="scss" scoped>
@import './greetings.scss';
</style>
