<template>
    <tipti-input-text v-model="modelValue" v-bind="$props" />
</template>

<script lang="ts" setup>
import TiptiInputText from '@/components/inputs/primary/normal/TiptiInputText.vue';

const modelValue = defineModel({
    type: String,
    default: '',
});

defineProps({
    label: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        default: 'text',
    },
    maxlength: {
        type: [String, Number],
        default: null,
    },
    minLength: {
        type: [String, Number],
        default: null,
    },
    pattern: {
        type: String,
        default: null,
    },
    autocomplete: {
        type: String,
        default: undefined,
    },
    placeholder: {
        type: String,
        default: '',
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    errors: {
        type: [String, Array<string>],
        default: undefined,
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.input-normal {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.field {
    display: flex;
    flex-flow: column-reverse;
}

input {
    @include overline;
    border: 0;
    border-bottom: 1px solid $canvas;
    -webkit-appearance: none;
    border-radius: 0;
    padding-top: $padding-xsm;
    @include phone {
        @include caption;
        width: 100%;
    }
}

input:focus {
    outline: 0;
    border-bottom: 1px solid $border;
}

::-webkit-input-placeholder {
    @include overline;
    color: $disable;
    @include phone {
        @include caption;
        color: $disable;
    }
}

::-moz-placeholder {
    @include overline;
    color: $disable;
    @include phone {
        @include caption;
        color: $disable;
    }
}

.input-label {
    @include overline;
    @include phone {
        @include caption;
    }
}

.input__required {
    margin: unset;
    color: $orange;
}

.input-normal__required {
    @include overline;
    color: $error;
    margin: $margin-sm;
    @include phone {
        @include caption;
        color: $error;
    }
}
</style>
