<template>
    <general-alert
        :message="$t('txt.product-note__save-alert-content')"
        :acceptButtonTxt="$t('cta.btn-yes-save')"
        :declineButtonTxt="$t('cta.btn-not-discard')"
        @on-accept="$emit('on-accept')"
        @on-decline="$emit('on-discard')"
        @on-close="$emit('on-close')"
        :accentHeader="true"
        :title="$t('txt.product-note__save-alert-title')"
    >
    </general-alert>
</template>

<script lang="ts">
import GeneralAlert from '../generalAlert/GeneralAlert.vue';
export default {
    components: { GeneralAlert },
    name: 'alert-note-not-save',
    emits: ['on-accept', 'on-discard', 'on-close'],
};
</script>
