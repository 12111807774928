import { CreditCardModel } from './CreditCard';

class PaymentMethodDataModel {
    readonly token: string;
    readonly status: string;
    readonly message: string;
    readonly transactionReference: string;
    readonly phone: string;
    readonly card: CreditCardModel;
    readonly date: string;
    readonly cardName: string;
    readonly cardType: string;
    readonly cardOwner: string;
    readonly cardDigits: string;

    constructor(
        token: string,
        status: string,
        message: string,
        transactionReference: string,
        phone: string,
        card: CreditCardModel,
        date: string,
        cardName: string,
        cardType: string,
        cardOwner: string,
        cardDigits: string,
    ) {
        this.token = token;
        this.status = status;
        this.message = message;
        this.transactionReference = transactionReference;
        this.phone = phone;
        this.card = card;
        this.date = date;
        this.cardName = cardName;
        this.cardType = cardType;
        this.cardOwner = cardOwner;
        this.cardDigits = cardDigits;
    }
}
const toPaymentMethodDataModel = (data: object): PaymentMethodDataModel => {
    return data
        ? new PaymentMethodDataModel(
              data['token'],
              data['status'],
              data['message'],
              data['transaction_reference'],
              data['phone'],
              new CreditCardModel(data['card']),
              data['date'],
              data['card_name'],
              data['card_type'],
              data['card_owner'],
              data['card_digits'],
          )
        : null;
};
export { PaymentMethodDataModel, toPaymentMethodDataModel };
