<template>
    <header v-if="showTabs" class="delivery-tabs">
        <button
            class="delivery-tabs__label"
            :class="{ 'delivery-tabs--selected': !isPickUp }"
            @click="updateDeliveryMode(false)"
        >
            {{ $t('general.delivery') }}
        </button>
        <button
            class="delivery-tabs__label"
            :class="{ 'delivery-tabs--selected': isPickUp }"
            @click="updateDeliveryMode(true)"
        >
            {{ $t('general.pick-up') }}
        </button>
    </header>
    <section v-if="showButtons" class="delivery__options">
        <button
            class="delivery__option"
            :class="{ 'delivery__option--selected': !isPickUp }"
            @click="updateDeliveryMode(false)"
        >
            <icon-delivery />
            {{ $t('general.delivery') }}
        </button>
        <button
            class="delivery__option"
            :class="{ 'delivery__option--selected': isPickUp }"
            @click="updateDeliveryMode(true)"
        >
            <icon-pickup />
            {{ $t('general.pick-up') }}
        </button>
    </section>
    <alert-pick-up />
</template>
<script lang="ts">
import { logger } from '@/utils/logger';
import { mapActions, mapGetters } from 'vuex';
import { AddressModel } from '@/models/address/AddressModel';
import AlertPickUp from '@/components/alerts/alertPickUp/AlertPickUp.vue';
import IconDelivery from '@/components/icons/IconDelivery.vue';
import IconPickup from '@/components/icons/IconPickup.vue';
import { usePickUp } from '@/composables/usePickUp';
import { storeToRefs } from 'pinia';
import { usePickupStore } from '@/store/usePickupStore';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

export default {
    name: 'TheDeliveryOptions',
    components: {
        IconPickup,
        IconDelivery,
        AlertPickUp,
    },
    props: {
        showButtonOption: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { showPickUpAlert, temporalRetailer } = storeToRefs(usePickupStore());
        const { fetchFirstSchedulePerRetailer, fetchFirstSchedulePerRetailerPickUp } = useRetailer();
        return {
            showPickUpAlert,
            temporalRetailer,
            ...usePickUp({
                showButtonOption: props.showButtonOption,
            }),
            fetchFirstSchedulePerRetailer,
            fetchFirstSchedulePerRetailerPickUp,
        };
    },
    computed: {
        ...mapGetters({
            locationSelected: 'user/userLocation',
            retailerIdsWithtimeDelivery: 'checkout/retailerIdsWithtimeDelivery',
            productWithSpecialDelivery: 'cart/productWithSpecialDelivery',
            isAuth: 'user/isAuth',
        }),
        isPickUp(): boolean {
            return this.locationSelected?.isPickUpMode ?? false;
        },
        showTabs(): boolean {
            if (this.showButtonOption) return false;
            ///* This use for:
            /// Clean and off pickup mode in cart when in checkoutcontains a product with unique delivery
            if (this.productWithSpecialDelivery.length) {
                const _location: AddressModel = { ...this.locationSelected };
                _location.isPickUpMode = false;
                _location.pickUpCenter = null;
                this.$store.commit('user/userLocation', _location);
                return false;
            }
            return this.canShowDeliveryOptions;
        },
        showButtons(): boolean {
            if (!this.showButtonOption) return false;
            return this.canShowDeliveryOptions;
        },
    },
    methods: {
        ...mapActions({
            fetchCart: 'cart/fetchCart',
        }),
        async updateDeliveryMode(isPickUp: boolean): Promise<void> {
            if (isPickUp && !this.isAuth) return this.$store.commit('user/requireAuth', true);
            this.showPickUpAlert = isPickUp;
            this.temporalRetailer = this.retailerPickup;
            const _location: AddressModel = { ...this.locationSelected };
            _location.isPickUpMode = this.showButtonOption ? false : isPickUp;
            _location.pickUpCenter = null;
            this.$store.commit('user/userLocation', _location);
            this.fetchFirstSchedule();
            if (!isPickUp)
                this.fetchCart({ retailerIds: this.retailerIdsWithtimeDelivery }).catch((err) =>
                    logger('FETCH_CART', err),
                );
            if (!isPickUp) {
                this.$store.dispatch('segment/initialModeDeliveryAndPickup', {
                    origin: {
                        screen: this.$route.path,
                    },
                    mode: { initial_mode: 'PICKUP', new_mode: 'DELIVERY' },
                });
            }
        },
        async fetchFirstSchedule(): Promise<void> {
            try {
                if (!this.showButtonOption) if (this.cart?.retailers?.length !== 1) return;
                if (this.isPickUp) await this.fetchFirstSchedulePerRetailerPickUp();
                else await this.fetchFirstSchedulePerRetailer();
            } catch (err) {
                logger('FETCH_FIRST_SCHEDULE', err);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import './the-delivery-options.scss';
</style>
