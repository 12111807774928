<script lang="ts" setup>
import { useWallet } from '@/views/wallet/useWallet';
import { useRouter } from 'vue-router';
import IconBack from '@/components/icons/IconBack.vue';
import GiftCard from '@/components/cards/GiftCard.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import GiftCardLatestTransactionsSection from './GiftCardTransactionsSection.vue';
import GiftCardTransactionsSkeleton from './GiftCardTransactionsSkeleton.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'TheExpiredGiftCardDetail',
});

const $router = useRouter();

const { giftCardSelected } = useWallet();

const resetCards = () => {
    $router.replace({
        name: appRoutesMap.expiredGiftCards,
    });
    giftCardSelected.value = undefined;
};

if (!giftCardSelected.value)
    $router.replace({
        name: appRoutesMap.Wallet,
    });
</script>

<template>
    <button class="the-expired-gift-card-detail__btn" @click="resetCards">
        <icon-back :size="0.8" color="#000000" />
        <span class="the-expired-gift-card-detail__btn-text">{{ $t('txt.go-back') }}</span>
    </button>
    <section class="the-expired-gift-card-detail__top">
        <BaseCard class="the-expired-gift-card-detail__card" padding="2rem">
            <div class="the-expired-gift-card-detail__card__container">
                <GiftCard :card="giftCardSelected" is-expired />
            </div>
            <h3 class="the-expired-gift-card-detail__card__title">{{ giftCardSelected.card_name }}</h3>
            <p class="the-expired-gift-card-detail__card__description" v-translate="giftCardSelected.description"></p>
        </BaseCard>
        <Suspense>
            <GiftCardLatestTransactionsSection />
            <template #fallback>
                <GiftCardTransactionsSkeleton />
            </template>
        </Suspense>
    </section>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-expired-gift-card-detail {
    &__top {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin: 0 $margin-xlg;
        @include tablet {
            flex-direction: column;
        }
    }
}

.the-expired-gift-card-detail__btn {
    all: unset;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin: 0 $margin-xlg;

    &-text {
        @include button;
        font-size: 1.8rem;
        font-weight: 600;
        text-transform: capitalize;
    }
}

.the-expired-gift-card-detail__card {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    width: 100%;
    height: fit-content;
    @include tablet {
        width: auto;
    }

    &__container {
        align-self: center;
        position: relative;
    }

    &__title {
        @include subtitle2;
        color: $orange;
        margin-top: $margin-md;
    }

    &__description {
        @include body2;
    }
}
</style>
