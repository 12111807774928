<template>
    <base-icon :icon-name="$t('icon.cambrella')" :width="width" :height="height" viewBox="0 0 19 25" :iconColor="color">
        <path
            d="M13.5657 24.0001H5.3947C2.96648 24.0001 1 22.0336 1 19.6054V6.61096H17.9604V19.6054C17.9604 22.0295 15.9898 24.0001 13.5657 24.0001Z"
            fill="#FF9012"
            stroke="#FF9012"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linejoin="round"
        />
        <path
            d="M6.42578 9.65746V4.05072C6.42578 2.36458 7.79448 1 9.4765 1C11.1626 1 12.5272 2.3687 12.5272 4.05072V9.65746"
            stroke="#FF9012"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
        />
        <path
            d="M11.0268 16.9007C9.74467 17.4449 9.48494 15.7587 9.5138 14.3694C9.54266 13.0502 9.66634 12.5225 9.67459 12.238C9.69108 11.7392 11.0227 11.6403 11.0062 12.2092C10.9979 12.6214 10.9196 13.3264 10.9114 13.8129C10.8866 15.029 11.0969 15.701 11.27 16.0597C11.2659 16.0597 11.6411 16.641 11.0268 16.9007Z"
            fill="white"
        />
        <path
            d="M15.1326 14.4478C14.3081 16.9873 10.6349 21.18 3.7666 18.6776C4.12115 18.76 11.3151 20.3843 13.2197 14.823C13.2197 14.823 13.3723 14.1386 13.7928 13.5862C14.3782 12.8152 15.4665 13.4089 15.1326 14.4478Z"
            fill="white"
        />
        <path
            d="M8.63956 17.5109C8.0088 17.7994 7.01937 16.9213 6.9699 14.4766C6.96166 13.8871 6.99876 13.2357 7.04823 12.572C7.07709 12.201 7.97582 12.2958 8.20257 12.4607C8.29326 12.5266 8.38808 12.5967 8.37571 12.7122C8.31387 13.3594 8.27264 13.9572 8.28501 14.4107C8.35097 17.3954 9.27857 17.2264 8.63956 17.5109Z"
            fill="white"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-cambrella',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 18 * this.size;
        },
        height(): number {
            return 25 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
