import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheRetailerScreen = _resolveComponent("TheRetailerScreen")!
  const _component_t_c_modal_handler = _resolveComponent("t-c-modal-handler")!
  const _component_ModalGiftCardAccreditation = _resolveComponent("ModalGiftCardAccreditation")!
  const _component_alert_news = _resolveComponent("alert-news")!
  const _component_story_page = _resolveComponent("story-page")!
  const _component_OrderAcceptedAlert = _resolveComponent("OrderAcceptedAlert")!
  const _component_notification_rate = _resolveComponent("notification-rate")!
  const _component_RetailersModal = _resolveComponent("RetailersModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", {
      class: _normalizeClass({ 'main-content': true, 'main-content--empty': !$data.hasRetailers })
    }, [
      _createVNode(_component_TheRetailerScreen, {
        onHasRetailers: _cache[0] || (_cache[0] = (value) => ($data.hasRetailers = value))
      })
    ], 2),
    _createVNode(_component_t_c_modal_handler),
    _createVNode(_component_ModalGiftCardAccreditation),
    ($options.showAlertNews)
      ? (_openBlock(), _createBlock(_component_alert_news, {
          key: 0,
          onCloseAlert: $options.closeAlert
        }, null, 8, ["onCloseAlert"]))
      : _createCommentVNode("", true),
    (_ctx.showStoryFromDeeplink)
      ? (_openBlock(), _createBlock(_component_story_page, {
          key: 1,
          onOnClose: $options.closeStoryPage
        }, null, 8, ["onOnClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_OrderAcceptedAlert),
    ($data.hasRetailers)
      ? (_openBlock(), _createBlock(_component_notification_rate, { key: 2 }))
      : _createCommentVNode("", true),
    ($setup.productsFlow)
      ? (_openBlock(), _createBlock(_component_RetailersModal, { key: 3 }))
      : _createCommentVNode("", true)
  ], 64))
}