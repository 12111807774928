import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d0394ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "model-loading" }
const _hoisted_2 = {
  key: 0,
  class: "model-loading__title"
}
const _hoisted_3 = {
  key: 2,
  class: "model-loading__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_triple_loading = _resolveComponent("triple-loading")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "has-close": false,
    hasHeader: $props.hasHeader,
    title: $props.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_triple_loading),
        ($props.title.length && !$props.hasHeader)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString($props.title), 1))
          : _createCommentVNode("", true),
        ($props.body.length)
          ? (_openBlock(), _createElementBlock("h2", {
              key: 1,
              class: _normalizeClass([{ 'model-loading__text--light': $props.dontClose }, "model-loading__text"])
            }, _toDisplayString($props.body), 3))
          : _createCommentVNode("", true),
        ($props.dontClose)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('general.no_close_tab').toUpperCase()), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["hasHeader", "title"]))
}