import { LoyaltyProgramsTypes } from '@/enums/loyaltyProgramsTypes';
import { LoyaltyOrRewardModel, toLoyaltyOrRewardModel } from '@/models/loyaltyPrograms/LoyaltyOrRewardsModel';

class LoyaltyAndRewardProgramsModel {
    constructor(public rewards: Array<LoyaltyOrRewardModel>, public afiliations: Array<LoyaltyOrRewardModel>) {
        this.rewards = rewards;
        this.afiliations = afiliations;
    }
}

const toLoyaltyAndRewardProgramsModel = (data: object) => {
    const rewards = !data[LoyaltyProgramsTypes.REWARD]
        ? []
        : data[LoyaltyProgramsTypes.REWARD].map(program =>
              toLoyaltyOrRewardModel(program, LoyaltyProgramsTypes.REWARD),
          );
    const afiliations = !data[LoyaltyProgramsTypes.AFFILIATION]
        ? []
        : data[LoyaltyProgramsTypes.AFFILIATION].map(program =>
              toLoyaltyOrRewardModel(program, LoyaltyProgramsTypes.AFFILIATION),
          );
    return new LoyaltyAndRewardProgramsModel(rewards, afiliations);
};

export { LoyaltyAndRewardProgramsModel, toLoyaltyAndRewardProgramsModel };
