<template>
    <base-icon :icon-name="$t('icon.edit')" :width="width" :height="height" viewBox="0 0 13 12" :iconColor="color">
        <path
            d="M8.2385 2.13232L1.72057 8.65072C1.68778 8.68361 1.6641 8.72514 1.65283 8.76977L0.930377 11.6695C0.908766 11.7568 0.934417 11.8497 0.998121 11.9134C1.04632 11.9616 1.112 11.9882 1.17918 11.9882C1.19976 11.9882 1.2208 11.9857 1.24129 11.9805L4.14105 11.258C4.18624 11.2467 4.2273 11.2231 4.26009 11.1903L10.7786 4.67241L8.2385 2.13232Z"
        />
        <path
            d="M12.3936 1.24336L11.668 0.517812C11.1831 0.0328902 10.338 0.03336 9.8536 0.517812L8.96484 1.40657L11.5048 3.94656L12.3936 3.0578C12.6358 2.81567 12.7692 2.49339 12.7692 2.15063C12.7692 1.80787 12.6358 1.48559 12.3936 1.24336Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-edit',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 13 * this.size;
        },
        height(): number {
            return 12 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
