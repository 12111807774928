export class BaseGoal {
    discountId: number;
    discountName: string;
    discountIcon: string;
    message: string;
    type: string;

    constructor(data: BaseGoalDto) {
        this.discountId = data?.discount_id;
        this.discountName = data?.discount_name ?? '';
        this.message = data?.message ?? '';
        this.type = data?.type ?? '';
        this.discountIcon = data?.discount_icon ?? '';
    }
}

export interface BaseGoalDto {
    discount_id: number;
    discount_name: string;
    discount_icon?: string;
    message: string;
    type: string;
}
