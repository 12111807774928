<template>
    <article class="retailers-in-tipti">
        <h1 class="retailers-in-tipti__title">{{ $t("general['retailers']") }}</h1>
        <section class="retailers">
            <base-card v-for="store in listRetailers" :key="store.id" class="retailer cursor-pointer" padding="0">
                <img
                    v-if="store?.retailer?.logo"
                    class="retailer__img"
                    :alt="store.retailer.name"
                    :src="store.retailer.logo"
                />
                <div class="card-overlay">
                    <p class="card-overlay__txt">{{ $t("general['coverage-sector']") }}</p>
                    <btn-solid :txt="$t('general.see')" @click="openSectorsOfCoverage"></btn-solid>
                </div>
            </base-card>
        </section>
    </article>
    <auth v-if="showLogin" @on-close="showLogin = false"></auth>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { logger } from '@/utils/logger';
import { useI18n } from 'vue-i18n';
import BaseCard from '@/components/cards/BaseCard.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import Auth from '@/views/auth/Auth.vue';
import { useSeoMeta } from '@unhead/vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'RetailersInTipti',
    components: { BaseCard, BtnSolid, Auth },
    setup() {
        const { t } = useI18n();
        useSeoMeta({
            title: t('seo.retailers'),
            description: t('seo.retailers__description'),
        });
    },
    data() {
        return {
            listRetailers: [],
            showLogin: false,
        };
    },
    mounted() {
        this.getListRetailers();
    },
    computed: { ...mapGetters({ isAuth: 'user/isAuth' }) },
    methods: {
        openSectorsOfCoverage() {
            if (this.isAuth) this.$router.push({ name: appRoutesMap.home });
            else {
                this.$store.dispatch('segment/openSignIn');
                this.showLogin = true;
            }
        },
        async getListRetailers(): Promise<void> {
            try {
                this.listRetailers = await this.$store.dispatch('external/retailersShowRoom');
            } catch (err) {
                logger('GET_LIST_RETAILERS', err);
            }
        },
        closeModal(): void {
            this.showModal = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './retailers-in-tipti.scss';
</style>
