<template>
    <base-card-flat :style="style" border-color="#fff" padding="0">
        <base-bone />
    </base-card-flat>
</template>
<script lang="ts">
import BaseBone from '@/components/skeletons/BaseBone.vue';
import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';

export default {
    name: 'skeleton-simple',
    components: { BaseBone, BaseCardFlat },
    props: {
        height: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            required: true,
        },
    },
    computed: {
        style(): object {
            return {
                height: this.height,
                width: this.width,
            };
        },
    },
};
</script>
