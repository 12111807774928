<template>
    <input
        @input="$emit('update:modelValue', $event.target.value)"
        type="tel"
        :placeholder="placeholderPhone"
        autocomplete="tel"
        @keypress="isNumericKey"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
export default {
    name: 'input-phone',
    emits: ['update:modelValue'],
    computed: {
        ...mapGetters({ countrySelected: 'external/countrySelected' }),
        placeholderPhone(): string {
            return this.countrySelected?.cellphone;
        },
    },
    methods: {
        isNumericKey(evt) {
            evt = evt ? evt : window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            // 48-57 are numbers
            // 45 is character "-"
            if ((charCode >= 48 && charCode <= 57) || charCode === 45) return true;
            evt.preventDefault();
        },
    },
};
</script>
