<template>
    <base-icon :icon-name="$t('icon.coupons')" :width="width" :height="height" viewBox="0 0 150 82" :iconColor="color">
        <path
            d="M146.375 0.639893H43.5045L37.1601 6.99229L31.2689 0.639893H3.17221C1.35952 0.639893 0 2.00112 0 3.81609V78.6837C0 80.4986 1.35952 81.8599 3.17221 81.8599H31.2689L37.6133 75.5075L43.9577 81.8599H146.828C148.64 81.8599 150 80.4986 150 78.6837V3.81609C149.547 2.00112 148.187 0.639893 146.375 0.639893ZM143.202 75.5075H46.2236L41.6918 70.97L37.1601 66.4326L32.6284 70.97L28.0967 75.5075H6.34441V6.99229H28.5499L33.0816 11.5297L37.6133 16.0672L42.145 11.5297L46.6767 6.99229H143.656L143.202 75.5075Z"
            fill="#606060"
        />
        <path
            d="M80.7645 16.2351C73.9669 16.2351 68.5288 21.6797 68.5288 28.4854C68.5288 35.291 73.9669 40.7356 80.7645 40.7356C87.562 40.7356 93.0001 35.291 93.0001 28.4854C93.4533 21.6797 88.0152 16.2351 80.7645 16.2351ZM80.7645 34.8373C77.1391 34.8373 74.42 32.1151 74.42 28.4854C74.42 24.8556 77.1391 22.1334 80.7645 22.1334C84.3898 22.1334 87.1089 24.8556 87.1089 28.4854C87.1089 32.1151 84.3898 34.8373 80.7645 34.8373Z"
            fill="#F78F1F"
        />
        <path
            d="M105.68 41.1821C98.8825 41.1821 92.9912 46.6272 92.9912 53.4335C92.9912 60.2399 98.4293 66.1387 105.227 66.1387C112.024 66.1387 117.916 60.6936 117.916 53.8873C118.369 46.6272 112.931 41.1821 105.68 41.1821ZM105.68 59.7861C102.055 59.7861 99.3356 57.0636 99.3356 53.4335C99.3356 49.8035 102.055 47.0809 105.68 47.0809C109.305 47.0809 112.024 49.8035 112.024 53.4335C112.024 57.0636 109.305 59.7861 105.68 59.7861Z"
            fill="#F78F1F"
        />
        <path
            d="M115.205 16.2351C114.299 16.2351 113.392 16.6889 112.939 17.1426L69.4347 60.7021C68.0752 62.0634 68.0752 63.8783 69.4347 65.2396C70.7942 66.6008 72.6069 66.6008 73.9664 65.2396L117.471 21.68C118.83 20.3188 118.83 18.5038 117.471 17.1426C117.018 16.6889 116.111 16.2351 115.205 16.2351Z"
            fill="#FF9012"
        />
        <path
            d="M37.4369 19.3518C35.6242 19.3518 34.2646 20.7113 34.2646 22.524C34.2646 24.3367 35.6242 25.6962 37.4369 25.6962C39.2495 25.6962 40.6091 24.3367 40.6091 22.524C40.6091 20.7113 39.2495 19.3518 37.4369 19.3518Z"
            fill="#FF9012"
        />
        <path
            d="M37.4369 31.8254C35.6242 31.8254 34.2646 33.185 34.2646 34.9976C34.2646 36.8103 35.6242 38.1699 37.4369 38.1699C39.2495 38.1699 40.6091 36.8103 40.6091 34.9976C40.6091 33.185 39.2495 31.8254 37.4369 31.8254Z"
            fill="#FF9012"
        />
        <path
            d="M37.4369 44.3035C35.6242 44.3035 34.2646 45.663 34.2646 47.4757C34.2646 49.2884 35.6242 50.6479 37.4369 50.6479C39.2495 50.6479 40.6091 49.2884 40.6091 47.4757C40.6091 45.663 39.2495 44.3035 37.4369 44.3035Z"
            fill="#FF9012"
        />
        <path
            d="M37.4369 56.7817C35.6242 56.7817 34.2646 58.1413 34.2646 59.9539C34.2646 61.7666 35.6242 63.1261 37.4369 63.1261C39.2495 63.1261 40.6091 61.7666 40.6091 59.9539C40.6091 58.1413 39.2495 56.7817 37.4369 56.7817Z"
            fill="#FF9012"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-coupons-mobile',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 25 * this.size;
        },
        height(): number {
            return 13 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
