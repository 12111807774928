import { createUUID } from './utils';

const blobToFile = (theBlob: Blob, fileName?: string): File => {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName ?? createUUID(theBlob.size);
    return new File([theBlob], b.name, { type: 'image/png' });
};

const fileToBlob = (file: File) => new Blob([file], { type: file.type });

const toBase64 = (file: File) =>
    file
        ? new Promise<string | ArrayBuffer>((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(fileToBlob(file));
              reader.onload = () => resolve(reader.result);
              reader.onerror = _ => reject(null);
          })
        : null;

const compressFile = (file: File) =>
    new Promise<File>((resolve, reject) => {
        const blobURL = URL.createObjectURL(file);
        const img = new Image();
        img.src = blobURL;
        img.onload = _ => {
            URL.revokeObjectURL(blobURL);
            const canvas = document.createElement('canvas');
            const size = 1000;
            const heightAuto = size * (img.height / img.width);
            canvas.width = size;
            canvas.height = heightAuto;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, size, heightAuto);
            canvas.toBlob(blob => resolve(blobToFile(blob, file.name)), 'image/png', 0.8);
        };
        img.onerror = _ => reject(null);
    });

const urlToFile = (url: string, fileName?: string) =>
    new Promise<File>((resolve, reject) => {
        fetch(url, {
            mode: 'no-cors',
        })
            .then(async data => {
                const theBlob = await data.blob();
                resolve(blobToFile(theBlob, fileName));
            })
            .catch(_ => reject(null));
    });

export { toBase64, blobToFile, compressFile, urlToFile };
