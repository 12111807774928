import { useStore } from 'vuex';
import { ProductModel } from '@/models/product/ProductModel';
import { useCartType } from '@/modules/core/composable/useCartType';

export const useExtraItemHandleEditProduct = () => {
    const $store = useStore();
    const { changeToExtraItemsCart } = useCartType();

    const deletedProduct = (product: ProductModel) => {
        $store.commit('extraItems/removeProduct', product);
    };

    const isAlreadyInTheCart = (product: ProductModel) => {
        const products: ProductModel[] = $store.getters['extraItems/products'];
        return Boolean(products.find(p => p.id === product.id));
    };

    const addProduct = (product: ProductModel) => {
        changeToExtraItemsCart();
        $store.commit('extraItems/products', product);
    };

    return {
        isAlreadyInTheCart,
        deletedProduct,
        addProduct,
    };
};
