class InstitutionalModel {
    clientInstitutitonId: number;
    institution: InstitutionModel;
    amountAllocated: string;
    amountAvailable: string;
    unlimitedBuys: boolean;
    resultDefault: boolean;
    constructor(
        clientInstitutitonId: number,
        institution: InstitutionModel,
        amountAllocated: string,
        amountAvailable: string,
        unlimitedBuys: boolean,
        resultDefault: boolean,
    ) {
        this.clientInstitutitonId = clientInstitutitonId;
        this.institution = institution;
        this.amountAllocated = amountAllocated;
        this.amountAvailable = amountAvailable;
        this.unlimitedBuys = unlimitedBuys;
        this.resultDefault = resultDefault;
    }
}

class InstitutionModel {
    id: number;
    name: string;
    logo: string;

    constructor(id: number, name: string, logo: string) {
        this.id = id;
        this.name = name;
        this.logo = logo;
    }
}

const toInsitutionModel = (data: object) => {
    return data ? new InstitutionModel(data['id'], data['business_name'], data['logo']) : null;
};
const toInstitutionalModel = (data: object) => {
    return data
        ? new InstitutionalModel(
              data['id'],
              toInsitutionModel(data['institution']),
              data['amount_allocated'],
              data['amount_allocated_available'],
              data['unlimited_buys'] ?? false,
              data['default'] ?? false,
          )
        : null;
};

export { InstitutionalModel, toInstitutionalModel };
