import { LoyaltyAndRewardProgramsModel } from '@/models/loyaltyPrograms/LoyaltyAndRewardModel';
import { LoyaltyOrRewardModel } from '@/models/loyaltyPrograms/LoyaltyOrRewardsModel';

export default {
    programs(state): LoyaltyAndRewardProgramsModel {
        return state.programs;
    },
    program(state): LoyaltyOrRewardModel {
        return state.program;
    },
    registrationTempData(state): object {
        return state.registrationTempData;
    },
};
