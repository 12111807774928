import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54b1b08a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "head" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_back = _resolveComponent("icon-back")!
  const _component_icon_circular_close = _resolveComponent("icon-circular-close")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _renderSlot(_ctx.$slots, "icon-left", {}, () => [
      ($props.withDefaultIconBack)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "header-icon header-icon--left",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('on-back')), ["stop"]))
          }, [
            _createVNode(_component_icon_back)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2))
    ], true),
    _renderSlot(_ctx.$slots, "text", {}, () => [
      _createElementVNode("h1", _hoisted_3, _toDisplayString($props.text), 1)
    ], true),
    _renderSlot(_ctx.$slots, "icon-right", {}, () => [
      ($props.withDefaultClose)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "header-icon header-icon--right",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close')))
          }, [
            _createVNode(_component_icon_circular_close, { size: $props.sizeIcon }, null, 8, ["size"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4))
    ], true)
  ]))
}