import { defineStore } from 'pinia';
import {
    AutocompleteDo,
    BaseTiptiItem,
    fromAutoCompleteToProductAdapter,
    GlobalSearchProductModel,
    toProductModelAdapter,
} from '@/models/product/ProductModelAdapters.ts';
import { logger } from '@/utils/logger';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { ResponsePaged } from '@/modules/core/models/ResponseModels';
import { ProductModel, toProductModel } from '@/models/product/ProductModel';
import { ProductSuggestion } from '@/models/product/ProductOnDemandModel';

interface storeState {
    itemHighDemand: ProductSuggestion[];
    itemOutOfStock: ProductSuggestion[];
    lastItemAdded: ProductModel;
    showModalProductsReplacements: boolean;
    itemAddedFromSuggestion: boolean;
}

const errorResponse = {
    previous: '',
    results: [],
    count: 0,
    next: '',
    total_pages: 0,
};

const page_size = 12;
export const useProductStore = defineStore('Products', {
    state: (): storeState => ({
        itemHighDemand: [],
        itemOutOfStock: [],
        lastItemAdded: undefined,
        showModalProductsReplacements: false,
        itemAddedFromSuggestion: false,
    }),
    getters: {
        isHighDemand(state): boolean {
            return !!state.itemHighDemand?.[0]?.productAdded;
        },
        isOutOfStock(state): boolean {
            return !!state.itemOutOfStock?.[0]?.productAdded;
        },
    },
    actions: {
        async promotionPersonalized({
            page,
            retailerId,
            search,
            category,
        }: {
            page: number;
            retailerId: number;
            category?: number;
            search?: string;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get<ResponsePaged<BaseTiptiItem>>(
                    `v3/client_profile/promotion_personalized/retailer/${retailerId}`,
                    {
                        params: {
                            page_size,
                            page,
                            category,
                            search,
                        },
                    },
                );
                return {
                    ...data,
                    results: data.results?.map(toProductModelAdapter) ?? [],
                    total_pages: Math.ceil(data['count'] / page_size),
                };
            } catch (err) {
                logger('PROMOTIONS_AND_DISCOUNT', err);
                return errorResponse;
            }
        },
        async predictions({
            page,
            retailerId,
        }: {
            page: number;
            retailerId: number;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get<ResponsePaged<BaseTiptiItem>>(
                    `v3/client_profile/purchase_predictions_v3`,
                    {
                        params: {
                            limit: 50,
                            page,
                            retailer_id: retailerId,
                            check_purchases: true,
                            page_size,
                        },
                    },
                );
                return {
                    ...data,
                    results: data.results?.map(toProductModelAdapter) ?? [],
                    total_pages: Math.ceil(data['count'] / page_size),
                };
            } catch (err) {
                logger('PREDICTIONS', err);
                return errorResponse;
            }
        },
        async newProducts({
            page,
            retailerId,
        }: {
            page: number;
            retailerId: number;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get<ResponsePaged<BaseTiptiItem>>(
                    `v3/product/new/retailers/v3/${retailerId}`,
                    {
                        params: {
                            page,
                            page_size,
                        },
                    },
                );
                return {
                    ...data,
                    results: data.results?.map(toProductModelAdapter) ?? [],
                    total_pages: Math.ceil(data['count'] / page_size),
                };
            } catch (err) {
                logger('NEW_PRODUCTS', err);
                return errorResponse;
            }
        },
        async yourProducts({
            page,
            retailerId,
        }: {
            page: number;
            retailerId: number;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get<ResponsePaged<BaseTiptiItem>>(
                    `v3/product/recommendations_v4`,
                    {
                        params: {
                            page,
                            page_size,
                            retailer_id: retailerId,
                            check_purchases: true,
                        },
                    },
                );
                return {
                    ...data,
                    results: data.results?.map(toProductModelAdapter) ?? [],
                    total_pages: Math.ceil(data['count'] / page_size),
                };
            } catch (err) {
                logger('YOUR_PRODUCTS_HOME', err);
                return errorResponse;
            }
        },
        async getDetailProduct(stock_item_id: number): Promise<ProductModel> {
            try {
                const { data } = await axiosInstance().get(`v3/product_detail_api/`, {
                    params: {
                        stock_item_id,
                        not_show_discount: true, // reduce request time => avoid discounts
                    },
                });
                return data;
            } catch (err) {
                logger('DETAIL_PRODUCT', err);
            }
        },
        async stockItemWithDiscount({
            query,
            retailer_id,
            subcategory_id,
            category_id,
            page,
        }: {
            page: number;
            retailer_id: number;
        } & Partial<{
            subcategory_id: number;
            category_id: number;
            query: string;
        }>): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get<ResponsePaged<ProductModel>>(
                    // TODO to remove ternary and endpoint stockitems-with-discount
                    //  ask to back if stockitems-with-discount_v2 accept query search keyword
                    query?.length ? `stockitems-with-discount` : `v3/stockitems-with-discount_v2`,
                    {
                        params: {
                            retailer_id,
                            page,
                            page_size,
                            category_id,
                            subcategory_id,
                            query,
                        },
                    },
                );
                return {
                    ...data,
                    results: data.results.map(toProductModel),
                    total_pages: Math.ceil(data['count'] / page_size),
                };
            } catch (err) {
                logger('STOCK_ITEMS_WITH_DISCOUNT', err);
                return errorResponse;
            }
        },
        async featuredProducts({
            retailer_id,
            category_id,
            size,
        }: {
            retailer_id: number;
            category_id: number;
            size?: number;
        }): Promise<ProductModel[]> {
            try {
                const { data } = await axiosInstance().get(
                    `v3/retailers/${retailer_id}/categories/${category_id}/featured-products/`,
                    {
                        params: {
                            size: size ?? 24,
                        },
                    },
                );
                return data[0]['stockitems'].map(toProductModel);
            } catch (err) {
                logger('RECOMMENDATION_SOVI', err);
                return [];
            }
        },
        async recommendationsSovi({
            retailerId,
            page,
        }: {
            retailerId: number;
            page: number;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get(`v3/product/recommendations_sovi_v2/`, {
                    params: {
                        retailer_id: retailerId,
                        page,
                    },
                });
                return {
                    ...data,
                    results: data.results?.map(toProductModelAdapter),
                };
            } catch (err) {
                logger('RECOMMENDATION_SOVI', err);
                return errorResponse;
            }
        },
        async searchDiscounts({
            page,
            category_id,
            subcategory_id,
            retailer_id,
        }: {
            page: number;
            retailer_id: number;
            category_id?: number;
            subcategory_id?: number;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get('search_discount/', {
                    params: {
                        page,
                        retailer_id,
                        subcategory_id,
                        category_id,
                    },
                    cancelPreviousRequests: true,
                });
                return {
                    ...data,
                    results: data['results'].map(toProductModel),
                    total_pages: Math.ceil(data['count'] / page_size),
                };
            } catch (err) {
                logger('SEARCH_DISCOUNTS', err);
                return errorResponse;
            }
        },
        async recommendationsByCategory({
            page,
            category_id,
            retailer_id,
        }: {
            page: number;
            retailer_id: number;
            category_id?: number;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get('v3/product/recommendations/category_v3/', {
                    params: {
                        page,
                        retailer_id,
                        category_id,
                        limit: 50,
                        page_size,
                    },
                });
                return {
                    ...data,
                    results: data.results?.map(toProductModel) ?? [],
                    total_pages: data.total_pages ?? Math.ceil(data['count'] / page_size),
                };
            } catch (err) {
                return errorResponse;
            }
        },
        async recommendationsAutocomplete({
            userId,
            query,
            retailerId,
        }: {
            query: string;
            userId: number;
            retailerId: number;
        }): Promise<ProductModel[]> {
            try {
                const { data } = await axiosInstance().get<{
                    hits: AutocompleteDo[];
                }>('v2/client-recommendations-autocomplete-search', {
                    params: {
                        client: userId,
                        query,
                        retailer: retailerId,
                    },
                    cancelPreviousRequests: true,
                });
                return data.hits?.map(fromAutoCompleteToProductAdapter) ?? [];
            } catch (err) {
                return [];
            }
        },
        async autocompleteSearch({
            query,
            retailerId,
        }: {
            query: string;
            retailerId: number;
        }): Promise<ProductModel[]> {
            try {
                const { data } = await axiosInstance().get<{
                    hits: AutocompleteDo[];
                }>('v2/autocomplete-search', {
                    params: {
                        retailer: retailerId,
                        query,
                    },
                    cancelPreviousRequests: true,
                });
                return data.hits?.map(fromAutoCompleteToProductAdapter) ?? [];
            } catch (err) {
                return [];
            }
        },
        async autocompleteGlobalSearch(query: string): Promise<GlobalSearchProductModel[]> {
            try {
                const { data } = await axiosInstance().get('v2/autocomplete_global_search', {
                    params: {
                        query,
                    },
                    cancelPreviousRequests: true,
                });
                return data ?? [];
            } catch (err) {
                return [];
            }
        },
        async stockItemsSearch({
            page,
            query,
            retailerId,
        }: {
            query: string;
            page: number;
            retailerId: number;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                if (!query.length) return;
                const { data } = await axiosInstance().get('v2/stock-items-search', {
                    params: {
                        query,
                        page_size: 15,
                        retailer: retailerId,
                        page,
                    },
                });
                return {
                    ...data,
                    results: data?.['stock_items']?.map(toProductModel) ?? [],
                };
            } catch (e) {
                return errorResponse;
            }
        },

        async getRelatedProducts({
            page,
            retailer_id,
            item_id,
        }: {
            page: number;
            retailer_id: number;
            item_id: number;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get('v2/product/recommendations/items/category/', {
                    params: {
                        page_size,
                        page,
                        retailer_id,
                        item_id,
                    },
                });
                return {
                    ...data,
                    results: data?.results?.map(toProductModel) ?? [],
                };
            } catch (e) {
                return errorResponse;
            }
        },

        async getProductForYourHome({
            page,
            item_id,
        }: {
            page: number;
            item_id: number;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get('v2/product/recommendations/items/for_home/', {
                    params: {
                        page_size,
                        page,
                        item_id,
                    },
                });
                return {
                    ...data,
                    results: data?.results?.map(toProductModel) ?? [],
                };
            } catch (e) {
                return errorResponse;
            }
        },

        async suggestionsForUnAvailableProducts(payload: {
            stockItemId: number;
            size?: number;
        }): Promise<ResponsePaged<ProductModel>> {
            try {
                const { data } = await axiosInstance().get(`products-suggested/${payload.stockItemId}/`, {
                    params: {
                        size: payload?.size ?? page_size,
                    },
                });
                return {
                    ...data,
                    results: data?.map(toProductModel) ?? [],
                };
            } catch (err) {
                return errorResponse;
            }
        },
        updateItemList(list: ProductSuggestion[], payload: ProductSuggestion) {
            if (!list?.length && !payload?.productAdded)
                return list.push({
                    productAdded: payload.productAdded,
                    replacementSuggestion: payload.replacementSuggestion,
                });

            const existingItemIndex = list.findIndex(
                (item) => item.productAdded.stockItemId === payload.productAdded.stockItemId,
            );
            if (existingItemIndex === -1)
                return list.push({
                    productAdded: payload.productAdded,
                    replacementSuggestion: payload.replacementSuggestion,
                });
            return list.splice(existingItemIndex, 1);
        },
        setItemHighDemand(payload: ProductSuggestion) {
            return this.updateItemList(this.itemHighDemand, payload);
        },
        setItemOutOfStock(payload: ProductSuggestion) {
            return this.updateItemList(this.itemOutOfStock, payload);
        },
    },
});
