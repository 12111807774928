<template>
    <base-icon :icon-name="$t('icon.settings')" :width="width" :height="height" viewBox="0 0 38 39" :iconColor="color">
        <path
            d="M4.51058 23.1868C4.81299 24.3979 5.2666 25.4577 5.87142 26.5175L4.20817 28.637C3.60335 29.394 3.75456 30.3023 4.35938 30.9079L7.23226 33.7844C7.83708 34.39 8.89551 34.39 9.50033 33.9358L11.6172 32.2704C12.6756 32.876 13.8853 33.3302 15.0949 33.7844L15.3973 36.5095C15.5485 37.4179 16.3045 38.0234 17.0606 38.0234H21.1431C22.0503 38.0234 22.8063 37.4179 22.8063 36.5095L23.1087 33.9358C24.4696 33.633 25.6792 33.0274 26.7376 32.4218L28.7033 34.0872C29.4593 34.6928 30.3665 34.5414 30.9714 33.9358L33.8442 31.0593C34.4491 30.4537 34.4491 29.394 33.9954 28.7884L32.4834 26.8202C33.0882 25.6091 33.693 24.3979 33.9954 23.1868L36.4147 22.884C37.3219 22.7326 37.9268 21.9756 37.9268 21.2187V17.131C37.9268 16.2226 37.3219 15.4657 36.4147 15.4657L33.9954 15.1629C33.693 13.9517 33.2394 12.7406 32.4834 11.5294L33.9954 9.71268C34.6003 8.95571 34.4491 8.04734 33.8442 7.44176L30.9714 4.56527C30.3665 3.95969 29.3081 3.95969 28.7033 4.41388L26.8888 5.92782C25.6792 5.17085 24.4696 4.71666 23.1087 4.41388V1.53738C22.9575 0.629015 22.2015 0.0234375 21.4455 0.0234375H17.363C16.4557 0.0234375 15.6997 0.629015 15.6997 1.53738L15.2461 3.95969C13.8853 4.26248 12.6756 4.86806 11.466 5.62503L9.65153 4.11109C8.89551 3.50551 7.83708 3.50551 7.23226 4.26248L4.35938 7.13897C3.75456 7.74455 3.60335 8.65292 4.20817 9.40989L5.72022 11.378C5.1154 12.5892 4.66178 13.8003 4.35938 15.0115L1.7889 15.3143C0.881673 15.4657 0.276855 16.2226 0.276855 16.9796V21.2187C0.276855 22.127 0.881673 22.884 1.7889 22.884L4.51058 23.1868ZM19.3286 12.135C23.1087 12.135 26.1328 15.1629 26.1328 18.9477C26.1328 22.7326 23.1087 25.7605 19.3286 25.7605C15.5485 25.7605 12.5244 22.7326 12.5244 18.9477C12.5244 15.1629 15.5485 12.135 19.3286 12.135Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-config',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#C7C7C7',
        },
    },
    computed: {
        width(): number {
            return 38 * this.size;
        },
        height(): number {
            return 39 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
