<template>
    <section class="card-menu payment-method-expired">
        <div>
            <icon-credit-card-info :size="0.7" />
        </div>
        <p class="payment-method-expired__txt">{{ $t('txt.credit_card__expired_info') }}</p>
        <button class="payment-method-expired__btn" @click="$emit('on-change-selected')">
            {{ $t('cta.change') }}
        </button>
    </section>
</template>

<script lang="ts">
import IconCreditCardInfo from '@/components/icons/IconCreditCardInfo.vue';

export default {
    name: 'PaymentMethodExpired',
    components: { IconCreditCardInfo },
    emits: ['on-change-selected'],
};
</script>

<style lang="scss" scoped>
@import '../../menu-checkout.scss';
.payment-method-expired {
    background-color: rgba(201, 8, 8, 0.8);
    position: absolute;
    inset: 0;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
    min-height: unset;
    &__txt {
        @include body2;
        color: $white !important;
        align-self: center !important;
        justify-self: flex-start !important;
    }
    &__btn {
        align-self: flex-end !important;
        justify-self: flex-end !important;
        color: $white !important;
    }
}
</style>
