<template>
    <base-icon
        :height="dimensions.height"
        :icon-name="$t('icon.add_to_cart')"
        :icon-color="color"
        :width="dimensions.width"
        viewBox="0 0 30 26"
    >
        <path
            class="st0"
            d="M22.7,0.4c-3.9,0-7,3.1-7,7c0,0.1,0,0.2,0,0.3h-9C6.3,7.7,5.9,7.8,5.6,8L4.9,5.6C4.9,5.4,4.8,5.2,4.6,5.1
	C4.4,5,4.3,4.9,4.1,4.9H1C0.8,4.9,0.6,5,0.5,5.1S0.3,5.4,0.3,5.6v0.1c0,0.2,0.1,0.4,0.2,0.5C0.6,6.3,0.8,6.4,1,6.4h2.6L5,11.1
	l1.8,6.5c-0.6,0.1-1,0.5-1.4,0.9s-0.5,1-0.4,1.6c0.1,0.6,0.3,1.1,0.7,1.5c0.4,0.4,1,0.6,1.5,0.7c-0.1,0.3-0.2,0.7-0.1,1
	c0,0.3,0.1,0.7,0.3,1s0.4,0.6,0.7,0.7s0.6,0.3,0.9,0.4c0.3,0.1,0.7,0,1,0c0.3-0.1,0.6-0.3,0.9-0.5c0.3-0.2,0.5-0.5,0.6-0.8
	c0.1-0.3,0.2-0.7,0.2-1c0-0.3,0-0.5-0.1-0.8h2.8c-0.2,0.5-0.2,1,0,1.5c0.1,0.5,0.4,0.9,0.8,1.2c0.4,0.3,0.9,0.5,1.4,0.5
	c0.5,0,1-0.2,1.4-0.4c0.4-0.3,0.7-0.7,0.9-1.2c0.2-0.5,0.2-1,0-1.5c-0.2-0.5-0.5-0.9-0.9-1.2s-0.9-0.4-1.4-0.4H7.4
	c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.4-0.3-0.6c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.3s0.2-0.1,0.3-0.2s0.2-0.1,0.3-0.1h11
	c0.5,0,0.9-0.2,1.3-0.4c0.4-0.3,0.6-0.7,0.7-1.1l0.8-3.4c0.5,0.1,1,0.2,1.5,0.2c3.9,0,7-3.1,7-7S26.6,0.4,22.7,0.4z M16.1,22.7
	c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0,0.4c0,0.2-0.1,0.3-0.2,0.4
	c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3,0-0.4,0c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4C15.9,23,16,22.8,16.1,22.7z
	 M8.9,22.7c0.1-0.1,0.3-0.2,0.5-0.2h0c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0,0.4s-0.1,0.3-0.2,0.4
	c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3,0-0.4,0c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4C8.7,23,8.8,22.8,8.9,22.7z
	 M6.6,11.1L6.6,11.1L6.3,9.9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0h1.5l0.4,1.8H6.6z
	 M7.1,12.7h1.9l0.4,1.7H7.5L7.1,12.7z M8.8,17.6c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2L8,16h1.7l0.4,1.6H8.8z M12.7,17.6
	h-1.1L11.3,16h1.5V17.6z M12.7,14.4h-1.8l-0.4-1.7h2.2V14.4z M12.7,11.1h-2.5L9.8,9.3h2.9L12.7,11.1z M15.6,17.6h-1.2V16h1.6
	L15.6,17.6z M16.3,14.4h-1.9v-1.7h2.3L16.3,14.4z M14.3,11.1V9.3H16c0.2,0.7,0.5,1.3,0.8,1.8L14.3,11.1z M18.9,17.3
	c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-1.2l0.3-1.6h1.6L18.9,17.3z M19.6,14.4h-1.7l0.3-1.6c0.5,0.4,1,0.7,1.5,1
	L19.6,14.4z M26,7.9h-2.8v2.8c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5V7.9h-2.8c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h2.8
	V4.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v2.8H26c0.3,0,0.5,0.2,0.5,0.5S26.3,7.9,26,7.9z"
        />
    </base-icon>
</template>

<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue';
import { reactive } from 'vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: '#606060',
    },
});

const dimensions = reactive({
    width: 30 * props.size,
    height: 26 * props.size,
});
</script>
