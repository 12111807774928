import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e10f890"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "verification" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "registration-content" }
const _hoisted_4 = { class: "registration-content__text" }
const _hoisted_5 = { class: "validation-form-inputs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_header_program = _resolveComponent("header-program")!
  const _component_dynamic_form = _resolveComponent("dynamic-form")!
  const _component_btn_text = _resolveComponent("btn-text")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_modal_loading_manager = _resolveComponent("modal-loading-manager")!
  const _component_alert_pdf = _resolveComponent("alert-pdf")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      _createVNode(_component_section_header, {
        "has-back": true,
        title: _ctx.programSelected.name,
        "with-borders": false,
        onOnBack: _ctx.onBack
      }, null, 8, ["title", "onOnBack"]),
      _createVNode(_component_header_program),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "card-program__img",
          src: _ctx.pageLabel.image,
          alt: ""
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.pageLabel.description), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_dynamic_form, {
              invalid: _ctx.invalidFiled,
              required: _ctx.requiredFields,
              schema: _ctx.formFields,
              value: _ctx.formData,
              onFormValues: _ctx.updateValues
            }, null, 8, ["invalid", "required", "schema", "value", "onFormValues"])
          ]),
          _createVNode(_component_btn_text, {
            class: "cta-text--normal",
            text: _ctx.$t('general.terms-and-conditions'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTermsAndConditions = true))
          }, null, 8, ["text"]),
          _createVNode(_component_btn_solid, {
            txt: _ctx.pageLabel.callToActionSuccess,
            onClick: _ctx.verifySubscription
          }, null, 8, ["txt", "onClick"]),
          _createVNode(_component_btn_text, {
            text: _ctx.pageLabel.callToActionFailure,
            onClick: _ctx.onBack
          }, null, 8, ["text", "onClick"])
        ])
      ])
    ]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_modal_loading_manager, {
          key: 0,
          body: _ctx.loadingModalBody,
          "has-close": !_ctx.isLoading,
          "has-header": true,
          "is-failure": _ctx.isFailure,
          "is-loading": _ctx.isLoading,
          "is-success": _ctx.isSuccess,
          title: _ctx.modalTitle,
          onOnAction: _ctx.onAction,
          onOnClose: _ctx.handleCloseModal
        }, null, 8, ["body", "has-close", "is-failure", "is-loading", "is-success", "title", "onOnAction", "onOnClose"]))
      : _createCommentVNode("", true),
    (_ctx.showTermsAndConditions)
      ? (_openBlock(), _createBlock(_component_alert_pdf, {
          key: 1,
          "accent-header": true,
          title: _ctx.$t('general.terms-and-conditions'),
          url: _ctx.programSelected?.termsAndConditions,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTermsAndConditions = false))
        }, null, 8, ["title", "url"]))
      : _createCommentVNode("", true)
  ], 64))
}