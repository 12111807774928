<template>
    <header class="subheader-products" :class="{ 'subheader-products--no-background': !hasBackground }">
        <div v-if="hasReturn" class="header">
            <icon-back class="cursor-pointer" background-fill="transparent" @click="goBack" />
            <h1 class="header__title">{{ nameToShow }}</h1>
        </div>
        <p v-else class="header__title header__title--orange">
            <icon-back class="cursor-pointer" :size="0.8" background-fill="transparent" @click="goBack" />
            {{ $t("txt['search__results-for']") }}:
            <span class="header__title--black">{{ nameToShow }}</span>
        </p>
        <search-category v-if="showSearch" :origin-section="originSection" @redirect-on-search="redirectOnSearch" />
        <router-link
            v-if="hasButton"
            class="subheader-products__not-found"
            :to="{ name: appRoutesMap.productNotFound }"
        >
            <btn-solid-right-arrow :txt="$t('cta.not-find-product')" full-width />
        </router-link>
    </header>
</template>

<script lang="ts" setup>
import IconBack from '@/components/icons/IconBack.vue';
import BtnSolidRightArrow from '@/components/buttons/BtnSolidRightArrow.vue';
import SearchCategory from '@/components/category/searchCategory/SearchCategory.vue';
import { Sections } from '@/enums/sectionsOrigin';
import type { PropType } from 'vue';
import { computed } from 'vue';
import { useApp } from '@/composables/useApp';
import { appRoutesMap } from '@/router/appRoutesMap';

defineProps({
    hasReturn: {
        type: Boolean,
        default: false,
    },
    hasButton: {
        type: Boolean,
        default: true,
    },
    hasBackground: {
        type: Boolean,
        default: true,
    },
    originSection: {
        type: String as PropType<keyof typeof Sections>,
        default: undefined,
    },
    showSearch: {
        type: Boolean,
        default: false,
    },
});

const { $router, $route, $store } = useApp();
const $emit = defineEmits<(e: 'redirect-on-search', value: string) => void>();

const goBack = () => {
    $router.back();
    $store.commit('categories/setSubcategorySelected');
};

const redirectOnSearch = (query: string) => {
    $emit('redirect-on-search', query);
};

const nameToShow = computed(() => {
    const categorySelected = $store.getters['categories/getCategorySelected'];
    const customCategory = $store.getters['categories/customCategory'];
    const bannerInfo = $store.getters['promotions/bannerSelected'];
    const selectedStory = $store.getters['stories/selectedStory'];

    return (
        $route.query?.name ??
        bannerInfo?.title ??
        selectedStory?.title ??
        customCategory?.label ??
        categorySelected?.name
    );
});
</script>
<style lang="scss" scoped>
@import './subheader-products.scss';
</style>
