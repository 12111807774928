import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8acf16c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "online-bank-services" }
const _hoisted_2 = { class: "online-bank-services__title" }
const _hoisted_3 = { class: "online-bank-services__content" }
const _hoisted_4 = {
  key: 1,
  class: "online-bank-services__no-info"
}
const _hoisted_5 = { class: "bank-content" }
const _hoisted_6 = { class: "bank-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_icon_bank = _resolveComponent("icon-bank")!
  const _component_no_content = _resolveComponent("no-content")!
  const _component_banks = _resolveComponent("banks")!
  const _component_horizontal_divider = _resolveComponent("horizontal-divider")!
  const _component_lottie_animation = _resolveComponent("lottie-animation")!
  const _component_base_card_flat = _resolveComponent("base-card-flat")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("txt['online-bank-sevices__avalible-platform']")), 1),
    _createElementVNode("div", _hoisted_3, [
      ($data.isLoading)
        ? (_openBlock(), _createBlock(_component_base_loading, { key: 0 }))
        : (!$data.banks.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_no_content, {
                description: _ctx.$t('txt.online-bank-services__not-loading')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_bank, { size: "1" })
                ]),
                _: 1
              }, 8, ["description"]),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.fetchBanks && $options.fetchBanks(...args)))
              }, _toDisplayString(_ctx.$t("txt['online-bank-services__reloading']")), 1)
            ]))
          : _createCommentVNode("", true),
      _createVNode(_component_banks, {
        "bank-selected": $data.bankSelected,
        banks: $data.banks,
        onOnSelectedBank: $options.onSelectBank
      }, null, 8, ["bank-selected", "banks", "onOnSelectedBank"]),
      ($data.bankSelected?.id)
        ? (_openBlock(), _createBlock(_component_base_card_flat, {
            key: 2,
            class: "flex-column"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h3", {
                class: "bank-name",
                style: _normalizeStyle({ color: $data.bankSelected['color'] })
              }, _toDisplayString(_ctx.$t('txt.online_bank__service_payments')) + " " + _toDisplayString($data.bankSelected['name']), 5),
              _createVNode(_component_horizontal_divider, { height: "0.5rem" }),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("txt['online_bank__steps']")), 1),
              _createVNode(_component_lottie_animation, {
                src: $data.animation,
                height: "20rem"
              }, null, 8, ["src"]),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("txt['online-bank-sevices__modify-schedule-msj']")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}