import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export const useRoutes = () => {
    const route = useRoute();

    return {
        isPageSummaryCheckout: computed(() => route.name === appRoutesMap.checkout.summary),
        isHomePage: computed(() => route.name === appRoutesMap.home),
        isPageMainProducts: computed(() => route.name === appRoutesMap.retail.home),
        isPageCategorySwitcher: computed(() => route.name === appRoutesMap.retail.categorySwitcher),
        isPageAllCategories: computed(() => route.name === appRoutesMap.retail.allCategories),
        isInRetailer: computed(() => route.meta.require_retailer),
        isPageMyLists: computed(() => route.meta.subsection === 'list'),
        isPageAccountFaqs: computed(() => route.name === appRoutesMap.account.faqs),
        isPageStatisticsProfile: computed(() => route.name === appRoutesMap.account.statistics),
        isPageNotifications: computed(() => route.name === appRoutesMap.account.notifications),
        isScreenCheckout: computed(() => route.path.includes(appRoutesMap.checkout.index)),
        isScreenResendPayment: computed(() => route.path.includes(appRoutesMap.paymentResent.resend)),
        isScreenInstitutional: computed(() => route.name === appRoutesMap.paymentResent.institutional),
        isScreenMyAccount: computed(() => route.name === appRoutesMap.account.profile),
        isScreenSearch: computed(() => route.name === appRoutesMap.retail.search),
        isScreenSeeMore: computed(() => route.name === appRoutesMap.retail.seeMore),
        isScreenTiptiWallet: computed(() => route.name === appRoutesMap.Wallet),
        isScreenNotifications: computed(() => route.name === appRoutesMap.account.notifications),
        isScreenReferred: computed(() => route.name === appRoutesMap.account.referred),
        isScreenSettings: computed(() => route.name === appRoutesMap.account.settings),
        isScreenFaqs: computed(() => route.name === appRoutesMap.account.faqs),
        isScreenSubscription: computed(() => route.name === appRoutesMap.account.subscriptions),
        isScreenOrders: computed(() => route.name === appRoutesMap.OrderMap.PlaceHolderPage),
        isScreenExtraItemsPayment: computed(() =>
            route.path.includes(appRoutesMap.checkout.payments.extraItemsPayment),
        ),
        isScreenMainCategorySeeMore: computed(() => route.name === appRoutesMap.retail.indexCategorySeeMore),
        isPageSeeMoreSuggestions: computed(() => route.name === appRoutesMap.retail.seeMoreSuggestions),
        isInOrderPage: computed(() => route.meta.isInOrderPage),
        isPageProductDetail: computed(() => route.name === appRoutesMap.product),
    };
};
