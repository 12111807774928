<template>
    <section class="resent-container">
        <base-card class="flex-column" border-radius="7px" padding="0">
            <section-header
                :bg-color="bgColor"
                :btn-back-color="colorTitle"
                :color-title="colorTitle"
                :title="$t('txt.resent_payment_title', { order: order?.id })"
                :with-borders="!isMobile"
                has-back
                @on-back="$router.go(-1)"
            />
            <section class="payment-sections">
                <aside class="payment-sections__options">
                    <subheader-price
                        class="payment-sections__price"
                        :total-payed="order?.totalPayed"
                        :total-to-pay="order?.totalToPayInResentPayment"
                    />
                    <div v-if="isMobile" class="payment_select">
                        <p class="payment_select__title">{{ $t('general.select_method') }}</p>
                        <p class="payment_select__description">{{ $t('general.select_method_description') }}</p>
                    </div>
                    <payment-methods
                        :option-selected="optionSelected"
                        :payments="options"
                        :total-to-pay="order?.totalToPayInResentPayment"
                    />
                </aside>
                <div class="payment-sections__divider"></div>
                <div class="payment-sections__methods">
                    <router-view />
                </div>
            </section>
        </base-card>
        <section-footer class="resend-payment__footer" :with-shadow="false" has-border>
            <btn-solid
                :is-disabled="!paymentsToShow?.length"
                :is-loading="sendingPayment"
                :txt="$t('cta.confirm')"
                @click="changePaymentMethod"
            />
        </section-footer>
    </section>
    <modal-state-feedback
        v-if="showModal"
        :body="modalBody"
        :is-failure="!isSuccess"
        :is-loading="sendingPayment"
        :is-success="isSuccess"
        :title="modalTitle"
        @on-close="onClose"
    />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import BaseCard from '@/components/cards/BaseCard.vue';
import PaymentMethods from '@/components/paymentMethods/PaymentMethods.vue';
import SubheaderPrice from '@/components/subheaders/subheaderPrice/SubheaderPrice.vue';
import SectionFooter from '@/components/footers/sectionFooter/SectionFooter.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import ModalStateFeedback from '@/components/modal/modalStateFeedback/ModalStateFeddback.vue';
import { isMobileBrowser } from '@/utils/utils';
import { useResentPayment } from '@/composables/useResentPayment';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { computed, watch } from 'vue';
import { useOrder } from '@/composables/useOrder';
import { usePayments } from '@/composables/usePayments';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'ResendPayment',
    components: {
        ModalStateFeedback,
        SectionHeader,
        BaseCard,
        PaymentMethods,
        SubheaderPrice,
        SectionFooter,
        BtnSolid,
    },
    setup() {
        const { orderDetailAsOrderRejected, orderRejected } = useOrder();
        const { resentPayment } = useResentPayment();
        const { fetchTiptiCard, tiptiCard } = useTiptiCard();
        const { paymentsToShow, checkDeferAvailability } = usePayments();
        orderDetailAsOrderRejected();

        watch(
            () => [...paymentsToShow.value],
            (newPayments) => {
                if (newPayments.every((payment) => payment?.name !== 'payment-cards')) return;
                checkDeferAvailability();
            },
        );

        return {
            resentPayment,
            fetchTiptiCard,
            orderRejected,
            tiptiCard: computed(() => tiptiCard.value),
        };
    },
    data() {
        return {
            showModal: false,
            sendingPayment: false,
            isSuccess: false,
            isFailure: false,
            options: [
                {
                    title: this.$t("general['payment-cards']"),
                    code: 'credit_cards',
                    route: 'BodyCreditCards-resent',
                },
                {
                    title: 'Payphone',
                    code: 'payphone',
                    route: 'Payphone-resent',
                },
                {
                    title: this.$t("general['payment-cash']"),
                    code: 'cash',
                    route: 'CashPayment-resent',
                },
                {
                    title: this.$t("general['payment-wire-transfer']"),
                    code: 'wire_transfer',
                    route: 'CashWireTransfer-resent',
                },
                {
                    title: this.$t("general['payment-institutional']"),
                    code: 'institutional',
                    route: 'InstitutionalPayment-resent',
                },
            ],
            optionSelected: 'credit_cards',
        };
    },
    computed: {
        ...mapGetters({ order: 'orders/orderDetailSelected', paymentsToShow: 'payments/paymentsToShow' }),
        isMobile(): boolean {
            return isMobileBrowser();
        },
        bgColor(): string {
            return '#FFF';
        },
        colorTitle(): string {
            return '#707070';
        },
        modalTitle(): string {
            if (this.sendingPayment) return this.$t('alerts.validating_payment');
            if (!this.isSuccess) return this.$t('general.ups');
            return this.$t('alerts.payment_success');
        },
        modalBody(): string {
            if (this.sendingPayment) return '';
            return !this.isSuccess ? this.$t('general.error_occurred') : '';
        },
        canUseTiptiCard(): boolean {
            return this.tiptiCard?.availableAmount >= this.order?.totalToPayInResentPayment;
        },
    },
    async created() {
        this.showModal = false;
        this.$store.commit('checkout/responsePurchase', undefined);
        this.$store.commit('orders/orderWithPaymentRejected', undefined);
        this.$store.commit('payments/cleanPaymentMethods');
        this.$store.commit('payments/defaultPaymentSelected', undefined);
        await this.fetchTiptiCard();
        if (!this.canUseTiptiCard) return;
        this.options.unshift({
            title: 'Tipti Card',
            code: 'tipti_card',
            route: 'TiptiCardPayment-resent',
        });
    },
    methods: {
        async changePaymentMethod(): Promise<void> {
            try {
                if (!this.paymentsToShow.length || this.sendingPayment) return;
                this.sendingPayment = true;
                this.showModal = true;
                await this.resentPayment();
                this.isSuccess = true;
            } catch (e) {
                this.isSuccess = false;
            } finally {
                //*** showModal isn't inside the block finally because the user have to close the modal
                // to know what happen with the payment, it's handle inside the onClose method
                this.sendingPayment = false;
            }
        },
        onClose() {
            if (this.sendingPayment) return;
            //*** user close the modal manually, if the payment was done then redirect to retailers screen
            this.showModal = false;
            if (!this.isSuccess) return;
            this.$router.replace({ name: appRoutesMap.home });
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('orders/orderDetailSelected');
        this.orderRejected = null;
        next();
    },
};
</script>

<style lang="scss" scoped>
@import './resend-payment';
</style>
