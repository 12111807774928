import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-999a4cca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "promotions__title" }
const _hoisted_2 = { class: "promotions-body" }
const _hoisted_3 = { class: "promotions-body__description" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "notification-data" }
const _hoisted_7 = ["datetime"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggle = _resolveComponent("toggle")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_surplus_card = _resolveComponent("surplus-card")!
  const _component_icon_warning = _resolveComponent("icon-warning")!
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_icon_bell = _resolveComponent("icon-bell")!
  const _component_no_content = _resolveComponent("no-content")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_base_card, { class: "promotions" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("general['promotions']")), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("txt['setting__want-receive-notifications']")), 1),
          _createVNode(_component_toggle)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_base_card, {
      class: "notifications",
      padding: "0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_surplus_card),
        (_ctx.notifications?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(["notification", { 'notification--unseen': !notification.isSeen, 'cursor-pointer': !notification.isSeen }]),
                  onClick: ($event: any) => (notification.isSeen ? null : $options.markAsSeen(notification))
                }, [
                  _createVNode(_component_icon_warning),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("time", {
                      datetime: notification.date
                    }, _toDisplayString($options.notificationDate(notification.date)), 9, _hoisted_7),
                    _createElementVNode("h5", null, _toDisplayString(notification?.title ?? ''), 1),
                    _createElementVNode("p", null, _toDisplayString(notification?.body ?? ''), 1)
                  ])
                ], 10, _hoisted_5))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        ($data.isLoading)
          ? (_openBlock(), _createBlock(_component_base_loading, { key: 1 }))
          : (!_ctx.notifications?.length && !_ctx.pendingPayment)
            ? (_openBlock(), _createBlock(_component_no_content, {
                key: 2,
                colorTitle: "#A2A2A2",
                class: "notifications__no-content",
                title: _ctx.$t('txt.notifications__no-content-title'),
                description: _ctx.$t('txt.notifications__no-content-description')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_bell, {
                    color: "#C1C1C1",
                    size: 1.5
                  })
                ]),
                _: 1
              }, 8, ["title", "description"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}