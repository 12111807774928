<template>
    <div class="input-map">
        <input
            :placeholder="placeholder"
            :value="modelValue"
            type="text"
            @input="$emit('update:modelValue', $event.target.value)"
        />
    </div>
</template>

<script lang="ts">
export default {
    name: 'input-map',
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        modelValue: {
            type: String,
        },
    },
    emits: ['update:modelValue'],
};
</script>
<style lang="scss" scoped>
@import './input-map.scss';
</style>
