<template>
    <base-icon :icon-name="$t('icon.person')" :width="width" :height="height" viewBox="0 0 115 151" :iconColor="color">
        <path
            d="M58.3333 74.0741C74.9999 74.0741 88.8893 57.4074 88.8893 37.037C88.8893 16.6667 84.2592 0 58.3333 0C32.4073 0 27.7778 16.6667 27.7778 37.037C27.7778 57.4074 41.6666 74.0741 58.3333 74.0741Z"
        />
        <path
            d="M114.815 128.705C113.889 93.5198 109.259 83.336 74.0741 76.8545C69.4444 81.4841 63.889 83.3355 57.4075 83.3355C50.926 83.3355 45.3706 81.4841 40.741 76.8545C5.55578 83.336 0.925926 93.5212 0 127.78C0 130.558 0 130.556 0 130.556C0 131.482 0 132.41 0 134.261C0 134.261 8.33344 150.929 57.4075 150.929C106.482 150.929 114.815 134.261 114.815 134.261C114.815 133.336 114.815 132.409 114.815 131.483C114.815 129.631 114.815 128.705 114.815 128.705Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-person',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 115 * this.size;
        },
        height(): number {
            return 151 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
