<script lang="ts" setup>
import { computed, defineAsyncComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTranslate } from '@/composables/UseTranslate';
import { useTiptiTAC } from '@/modules/retailers/composables/useTiptiTAC';

const BackgroundWithTransition = defineAsyncComponent(
    () => import('@/modules/core/components/background/BackgroundWithTransition.vue'),
);
const PdfFrame = defineAsyncComponent(() => import('@/modules/core/components/pdf/PdfFrame.vue'));
const TheModalContent = defineAsyncComponent(() => import('@/modules/core/components/layouts/TheModalContent.vue'));
const BaseHeader = defineAsyncComponent(() => import('@/modules/core/components/headers/HeaderWithAction.vue'));
const BtnSolid = defineAsyncComponent(() => import('@/components/buttons/BtnSolid.vue'));
const BtnText = defineAsyncComponent(() => import('@/components/buttons/BtnText.vue'));
const IconCircularClose = defineAsyncComponent(() => import('@/components/icons/IconCircularClose.vue'));

const { t } = useI18n();
const { exec } = useTranslate();
const { alertInfo, closeAlert, isLoading } = useTiptiTAC();

const showPdf = ref(false);

const title = computed(() => (showPdf.value ? t("general['terms-and-conditions']") : exec(alertInfo.value.title)));
</script>

<template>
    <background-with-transition :class="{ 'full-mobile': showPdf }" :is-active="!!alertInfo" content-centered>
        <the-modal-content class="fit" :class="{ tipti_tc: showPdf }">
            <template #header>
                <base-header :text="title" :text-color="showPdf ? 'orange' : 'text'">
                    <template v-if="showPdf" #icon-right>
                        <icon-circular-close class="cursor-pointer header__close-icon" @click="showPdf = false" />
                    </template>
                </base-header>
            </template>
            <template #default>
                <pdf-frame
                    v-if="showPdf"
                    class="pdf-tipti-terms-and-conditions"
                    :src="exec(alertInfo.link_pdf)"
                    :title="title"
                />
                <p v-else v-translate="alertInfo.description" class="pdf-tipti-terms-and-conditions__message" />
            </template>
            <template v-if="!showPdf" #footer>
                <footer class="tc_modal_footer">
                    <btn-text :text="$t('general.terms-and-conditions')" in-link is-underline @click="showPdf = true" />
                    <btn-solid :is-disabled="isLoading" :txt="exec(alertInfo.button_text)" @click="closeAlert" />
                </footer>
            </template>
        </the-modal-content>
    </background-with-transition>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.tipti_tc {
    min-width: 50vw;
    transition: all 3s ease;
}
.tc_modal_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pdf-tipti-terms-and-conditions {
    height: 75vh;
    @include tablet {
        height: 90vh;
    }
    &__message {
        @include body1;
        align-self: center;
        padding: $padding-sm;
        max-width: 30rem;
        text-align: center;
    }
}
</style>
