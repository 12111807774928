<script lang="ts" setup>
import WalletSectionCards from '@/views/wallet/components/WalletSectionCards.vue';
import { useRouter } from 'vue-router';
import { useWallet } from '@/views/wallet/useWallet';
import CreaditCardWithActions from '@/views/creditCards/components/listCards/CreaditCardWithActions.vue';
import { PaymentMethodModel } from '@/models/paymentMethods/PaymentMethod';
import { appRoutesMap } from '@/router/appRoutesMap';

defineOptions({
    name: 'CardsWithAmount',
});

const $router = useRouter();

const { creditCards, getCreditCard, creditCardSelected } = useWallet();

await getCreditCard();

const goToRoute = (item: PaymentMethodModel) => {
    creditCardSelected.value = item;
    return $router.push({
        name: appRoutesMap.WalletCard,
    });
};
</script>

<template>
    <WalletSectionCards :items="creditCards" :title="$t('txt.credit__cards-registered-cards')">
        <template #default="{ item, style }">
            <CreaditCardWithActions
                :card="item"
                :styles="style"
                is-from-tipti-wallet-card-list
                @on-click="goToRoute(item)"
            />
        </template>
    </WalletSectionCards>
</template>
