import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14951930"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tipti-prime-detail" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "tipti-prime-detail__title"
}
const _hoisted_4 = { class: "benefits" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_tipti = _resolveComponent("icon-tipti")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    ($options.prime?.logo)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: $options.prime.logo,
          alt: ""
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createBlock(_component_icon_tipti, { key: 1 })),
    ($options.prime?.benefits?.length)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.$t('general.benefits')) + " " + _toDisplayString($options.prime?.name ?? ''), 1))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.prime?.benefits, (benefit, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("p", null, _toDisplayString(benefit), 1)
        ]))
      }), 128))
    ])
  ]))
}