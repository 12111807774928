import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e0a1f9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "signin-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header__text" }
const _hoisted_4 = { class: "register__inputs" }
const _hoisted_5 = { class: "register__actions" }
const _hoisted_6 = { class: "register__actions__btn-container" }
const _hoisted_7 = { class: "register__actions__to-login" }
const _hoisted_8 = { class: "social-header" }
const _hoisted_9 = { class: "social-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_circular_close = _resolveComponent("icon-circular-close")!
  const _component_tipti_input_text = _resolveComponent("tipti-input-text")!
  const _component_TiptiInputPassword = _resolveComponent("TiptiInputPassword")!
  const _component_btn_solid = _resolveComponent("btn-solid")!
  const _component_base_link = _resolveComponent("base-link")!
  const _component_separator_text = _resolveComponent("separator-text")!
  const _component_the_google = _resolveComponent("the-google")!
  const _component_the_facebook = _resolveComponent("the-facebook")!
  const _component_alert_pdf = _resolveComponent("alert-pdf")!
  const _component_modal_acceptation = _resolveComponent("modal-acceptation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("txt['register__register-register']")), 1),
        _createVNode(_component_icon_circular_close, {
          class: "header__close",
          size: 0.8,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close')))
        })
      ]),
      _createElementVNode("form", {
        class: "register",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => ($options.register && $options.register(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_tipti_input_text, {
            modelValue: $data.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.name) = $event)),
            errors: $data.notValidNameSurname ? `${_ctx.$t('txt.register__enter-name-lastname')}` : '',
            label: _ctx.$t('general.name-and-surname'),
            "is-required": "",
            placeholder: "Ingresa aquí tu nombre"
          }, null, 8, ["modelValue", "errors", "label"]),
          _createVNode(_component_tipti_input_text, {
            modelValue: $data.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.email) = $event)),
            class: "email--separator",
            "is-required": "",
            label: "email",
            placeholder: "Ingresa aquí tu e-mail"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_TiptiInputPassword, {
            modelValue: $data.password,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.password) = $event)),
            "is-required": "",
            label: "contraseña",
            placeholder: "Ingresa aquí la contraseña para tu cuenta"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_btn_solid, {
              txt: _ctx.$t('txt.register__register-register')
            }, null, 8, ["txt"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("txt['register__already-have-account']")), 1),
            _createVNode(_component_base_link, {
              "txt-link": _ctx.$t('cta.log-in'),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-switch')))
            }, null, 8, ["txt-link"])
          ]),
          _createVNode(_component_separator_text, {
            class: "option-separator",
            text: "o"
          }),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('general.general-register-with')), 1),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_the_google, { onOnSubmit: $options.loginGoogle }, null, 8, ["onOnSubmit"]),
            _createVNode(_component_the_facebook, {
              "is-login": false,
              onClick: $options.loginFacebook
            }, null, 8, ["onClick"])
          ])
        ])
      ], 32)
    ]),
    ($data.showTCPdf)
      ? (_openBlock(), _createBlock(_component_alert_pdf, {
          key: 0,
          title: _ctx.$t('general.terms-and-conditions'),
          url: $setup.exec($setup.countrySelected.termsAndConditions),
          onClose: _cache[6] || (_cache[6] = ($event: any) => ($data.showTCPdf = false))
        }, null, 8, ["title", "url"]))
      : _createCommentVNode("", true),
    ($data.showAcceptationModal)
      ? (_openBlock(), _createBlock(_component_modal_acceptation, {
          key: 1,
          "is-loading": $data.isLoading,
          "register-fields": { name: $data.name, email: $data.email },
          onOnAccept: $options.acceptTerms,
          onOnDecline: $options.declineTerms,
          onOnUnmontedModal: $options.unmountedModal
        }, null, 8, ["is-loading", "register-fields", "onOnAccept", "onOnDecline", "onOnUnmontedModal"]))
      : _createCommentVNode("", true)
  ], 64))
}