<template>
    <div
        v-if="!paymentsToShowGiftCard.length"
        class="card-menu card-payment-menu"
        :class="{
            'card-menu__no-data card-payment-menu__no-data': !paymentsToShow?.length,
            'card-payment-menu__expired': hasPaymentMethodsExpired,
        }"
    >
        <icon-payment-methods
            v-if="paymentsToShow?.length"
            :class="{ payment_top_icon: deferredOptions?.length }"
            :size="0.4"
            color="#FF9012"
        />
        <section v-if="paymentsToShow.length" class="card-menu__content">
            <template v-for="(payment, index) in paymentsToShow" :key="payment">
                <payment-to-show-tile
                    v-if="payment"
                    :payment="payment"
                    :show-button="index === 0"
                    @on-change-selected="$emit('on-change-selected')"
                />
                <h4 v-if="showMissingPayment" class="card-payment__warning">
                    {{ $t("txt['payment-methods__pending']") }}
                </h4>
            </template>
        </section>
        <div v-else class="card-menu__content">
            <p class="text-no-selected">{{ $t("general['payment-methods']") }}</p>
            <p class="text-no-selected">{{ $t("txt['checkout__enter-payment-method']") }}</p>
        </div>
        <button
            v-if="!paymentsToShow?.length"
            class="payment__card-button text-no-selected"
            @click="$emit('on-change-selected')"
        >
            {{ $t("general['select']") }}
        </button>
        <payment-method-expired v-if="hasPaymentMethodsExpired" @on-change-selected="$emit('on-change-selected')" />
    </div>
    <div
        v-else
        class="card-menu"
        :class="{
            'card-payment-menu': paymentsToShow.length,
            'card-menu__no-data card-payment-menu__no-data':
                !!cart.totalDetails.totalCost && !paymentsToShow.length && !defaultPayment,
            'card-payment-menu__expired': hasPaymentMethodsExpired,
        }"
    >
        <template v-if="idGiftCards.length">
            <template v-if="cart.totalDetails.totalCost">
                <template v-if="!paymentsToShow.length">
                    <div v-if="!defaultPayment" class="card-menu__content">
                        <p class="text-no-selected">{{ $t("general['payment-methods']") }}</p>
                        <p class="text-no-selected">{{ $t("txt['checkout__enter-payment-method']") }}</p>
                    </div>
                    <template v-else>
                        <div class="payment-card-selected__icon">
                            <icon-payment-methods :size="0.4" color="#FF9012" />
                        </div>
                        <h2 class="payment-card-selected__text">{{ $t("general['payment-methods']") }}</h2>
                    </template>
                </template>
                <template v-else>
                    <icon-payment-methods v-if="paymentsToShow?.length" :size="0.4" color="#FF9012" />
                    <section v-if="paymentsToShow.length" class="card-menu__content">
                        <template v-for="(payment, index) in paymentsToShow" :key="payment">
                            <payment-to-show-tile
                                v-if="payment"
                                :payment="payment"
                                :show-button="index === 0"
                                @on-change-selected="$emit('on-change-selected')"
                            />
                            <h4 v-if="showMissingPayment" class="card-payment__warning">
                                {{ $t("txt['payment-methods__pending']") }}
                            </h4>
                        </template>
                    </section>
                </template>
            </template>
            <template v-else>
                <template v-if="!paymentsToShow.length">
                    <div class="payment-card-selected__icon">
                        <icon-payment-methods :size="0.4" color="#FF9012" />
                    </div>
                    <h2 class="payment-card-selected__text">{{ $t("general['payment-methods']") }}</h2>
                </template>
                <template v-else>
                    <icon-payment-methods :size="0.4" color="#FF9012" />
                    <section class="card-menu__content">
                        <template v-for="(payment, index) in paymentsToShow" :key="payment">
                            <payment-to-show-tile
                                v-if="payment"
                                :payment="payment"
                                :show-button="index === 0"
                                @on-change-selected="$emit('on-change-selected')"
                            />
                            <h4 v-if="showMissingPayment" class="card-payment__warning">
                                {{ $t("txt['payment-methods__pending']") }}
                            </h4>
                        </template>
                    </section>
                </template>
            </template>
        </template>
        <div v-else-if="!paymentsToShow.length" class="card-menu__content">
            <p class="text-no-selected">{{ $t("general['payment-methods']") }}</p>
            <p class="text-no-selected">{{ $t("txt['checkout__enter-payment-method']") }}</p>
        </div>
        <template v-else>
            <icon-payment-methods v-if="paymentsToShow?.length" :size="0.4" color="#FF9012" />
            <section v-if="paymentsToShow.length" class="card-menu__content">
                <template v-for="(payment, index) in paymentsToShow" :key="payment">
                    <payment-to-show-tile
                        v-if="payment"
                        :payment="payment"
                        :show-button="index === 0"
                        @on-change-selected="$emit('on-change-selected')"
                    />
                    <h4 v-if="showMissingPayment" class="card-payment__warning">
                        {{ $t("txt['payment-methods__pending']") }}
                    </h4>
                </template>
            </section>
        </template>
        <button
            v-if="!paymentsToShow?.length"
            class="payment__card-button text-no-selected"
            :class="{ 'text-orange': cart.totalDetails.totalCost <= 0.01 && idGiftCards.length }"
            @click="resetPayments"
        >
            {{ $t("general['select']") }}
        </button>
        <payment-method-expired v-if="hasPaymentMethodsExpired" @on-change-selected="$emit('on-change-selected')" />
    </div>
</template>

<script lang="ts">
import IconPaymentMethods from '@/components/icons/IconPaymentMethods.vue';
import PaymentToShowTile from '@/views/checkout/components/menu/components/paymentCard/PaymentToShowTile.vue';
import { usePayments } from '@/composables/usePayments';
import PaymentMethodExpired from '@/views/checkout/components/menu/components/expired-card/PaymentMethodExpired.vue';
import { useGiftCards } from '@/composables/useGiftCards';

export default {
    name: 'PaymentCard',
    components: {
        PaymentMethodExpired,
        PaymentToShowTile,
        IconPaymentMethods,
    },
    emits: ['on-change-selected'],
    setup() {
        return {
            ...usePayments(),
            ...useGiftCards(),
        };
    },
    data() {
        return {
            showDeferredAlert: false,
            originalCashAmount: undefined,
        };
    },
    watch: {
        cart: function (cart) {
            this.tipticardCoverAllValueValidation(cart);
            this.mixedPaymentValidation(cart);
            this.cashPaymentValidation(cart);
            this.$store.commit('payments/secondPayment', this.differenceToPay);
        },
    },
    methods: {
        resetPayments() {
            if (this.cart.totalDetails.totalCost === 0) {
                this.$store.commit('giftCardAlert/idgGiftCardsToUse', []);
                this.$store.commit('payments/paymentsToShow', []);
                this.$store.commit('giftCardAlert/paymentsToShowGiftCard', []);
            }

            this.$store.commit('payments/cleanPaymentMethods');
            this.$emit('on-change-selected');
        },
        cashPaymentValidation(cart) {
            if (this.paymentsToShow.some((payment) => payment.name === 'payment-cash')) {
                const cashUpdated = this.cashWirePayment;
                cashUpdated.cash.cashToPay =
                    this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment
                        ? this.differenceToPay
                        : +cart?.totalDetails?.totalCost;
                if (cashUpdated.cash.cashToPay > cashUpdated.cash.cashAmount && !this.originalCashAmount) {
                    this.originalCashAmount = cashUpdated.cash.cashAmount;
                }
                cashUpdated.cash.cashAmount = this.getCashUpdated(
                    cashUpdated.cash.cashToPay,
                    cashUpdated.cash.cashAmount,
                );
                this.$store.commit('payments/cashWirePayment', cashUpdated);
            }
        },

        getCashUpdated(cashToPay: number, cashAmount: number): number {
            if (cashToPay === cashAmount || !this.originalCashAmount) return cashAmount;
            if (cashToPay > cashAmount) return cashToPay;
            return cashToPay > this.originalCashAmount ? cashToPay : this.originalCashAmount;
        },
        mixedPaymentValidation(cart) {
            if (
                (+cart?.totalDetails?.totalCost <= +this.tiptiCard?.availableAmount && this.isMixPaymentTiptiCard) ||
                (+cart?.totalDetails?.totalCost <= +this.institutionSelected?.amount && this.isMixInstitutionalPayment)
            ) {
                const mixedTiptiCard = this.isMixPaymentTiptiCard;
                const mixedInstitutional = this.institutionSelected;
                const paymentsUpdated = this.paymentsToShow[0];
                paymentsUpdated.amount = +cart?.totalDetails?.totalCost;
                this.$store.commit('payments/cleanPaymentMethods');
                this.$store.commit('payments/isMixPaymentTiptiCard', false);
                this.$store.commit('payments/isMixInstitutionalPayment', false);
                this.$store.commit('payments/paymentsToShow', paymentsUpdated);
                if (mixedTiptiCard) this.$store.commit('payments/isTiptiCardPayment', true);
                else this.$store.commit('payments/institutionPayment', mixedInstitutional);
            }
        },
        tipticardCoverAllValueValidation(cart) {
            if (
                this.paymentsToShow.length === 1 &&
                +cart?.totalDetails?.totalCost > this.paymentsToShow[0].amount &&
                this.paymentsToShow.some(
                    (payment) => payment.name === 'payment-tipti-card' || payment.name === 'payment-institutional',
                )
            ) {
                if (this.paymentsToShow[0].name === 'payment-tipti-card')
                    this.$store.commit('payments/isMixPaymentTiptiCard', true);
                else this.$store.commit('payments/isMixInstitutionalPayment', true);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../menu-checkout.scss';
@import './payment-card.scss';

.card-payment-menu__expired {
    position: relative;
}

.payment_top_icon-gift {
    margin-left: 2rem;
    margin-top: 3rem;
}

.payment-card-selected {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    gap: 1rem;
    width: 100%;
    @include tablet {
        grid-template-columns: 2rem auto;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__text {
        width: 100%;
    }
}

.card-payment-menu {
    grid-template-columns: 3rem 1fr !important;
    @include phone {
        grid-template-columns: 1.5rem 1fr !important;
    }
    @include tablet {
        grid-template-columns: 2rem 1fr !important;
    }

    &__no-data {
        grid-template-columns: 1fr 10% !important;
    }
}
</style>
