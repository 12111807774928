import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-131f0cd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category-with-subcategory" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_search_category = _resolveComponent("search-category")!
  const _component_category_with_subcategories = _resolveComponent("category-with-subcategories")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    class: "flex-column",
    padding: "0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_section_header, {
        title: _ctx.categorySelected?.name,
        "has-back": "",
        onOnBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
      }, null, 8, ["title"]),
      _createVNode(_component_search_category, {
        class: "lists-search",
        "origin-section": $options.originSection,
        onRedirectOnSearch: $options.redirectFromSearch
      }, null, 8, ["origin-section", "onRedirectOnSearch"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_category_with_subcategories, {
          section: $options.originSection,
          "show-promotional-banner": false,
          onGoToFeaturedProducts: $options.goToFeaturedProducts,
          onGoToSubcategory: $options.goToSubcategory
        }, null, 8, ["section", "onGoToFeaturedProducts", "onGoToSubcategory"])
      ])
    ]),
    _: 1
  }))
}