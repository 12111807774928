<template>
    <base-icon :icon-name="$t('icon.menu')" :width="width" :height="height" viewBox="0 0 150 119" :iconColor="color">
        <path
            d="M150 106.822C150 113.152 145.962 118.691 141.346 118.691H8.65385C4.03846 118.691 0 113.152 0 106.822C0 100.491 4.03846 94.9521 8.65385 94.9521H141.346C145.962 94.9521 150 100.491 150 106.822Z"
            :fill="color"
        />
        <path
            d="M126.923 59.3451C126.923 65.6753 122.885 71.2142 118.269 71.2142H8.65385C4.03846 71.2142 0 65.6753 0 59.3451C0 53.015 4.03846 47.4761 8.65385 47.4761H118.269C122.885 47.4761 126.923 53.015 126.923 59.3451Z"
            :fill="color"
        />
        <path
            d="M150 11.8691C150 18.1992 145.962 23.7381 141.346 23.7381H8.65385C4.03846 23.7381 0 18.1992 0 11.8691C0 5.5389 4.03846 0 8.65385 0H141.346C145.962 0 150 5.5389 150 11.8691Z"
            :fill="color"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-menu-hamburger',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 150 * this.size;
        },
        height(): number {
            return 119 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
