<template>
    <section-header :with-borders="false" :has-back="true" @on-back="$router.go(-1)" :title="programSelected.name" />
    <header-program />
    <div class="affiliation-card">
        <h3 class="title-virtual-affiliation">{{ $t('txt.show_card__affiliates') }}</h3>
        <div class="container-card">
            <img class="affiliation-card__img" :src="programSelected.cardImage" :alt="programSelected.name" />
            <div class="name-card">{{ programSelected.subscription.subscriberName }}</div>
            <div class="identify-card">{{ programSelected.subscription.subscriberIdentifier }}</div>
        </div>
        <vue-barcode
            :value="programSelected.subscription.subscriberIdentifier"
            :options="{ displayValue: true, format: formatCode }"
        />
    </div>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import HeaderProgram from '@/views/myAccount/views/profile/views/loyaltyPrograms/HeaderProgram.vue';
import VueBarcode from '@chenfengyuan/vue-barcode';
export default {
    name: 'show-card',
    components: { HeaderProgram, SectionHeader, VueBarcode },
    computed: {
        ...mapGetters({
            programSelected: 'loyaltyAndRewards/program',
        }),
        formatCode(): string {
            return this.programSelected.subscription.subscriberIdentifier?.length !== 13 ? null : 'EAN13';
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'show-card';
</style>
