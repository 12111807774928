<template>
    <base-card class="amount-description" padding="1rem">
        <p class="amount-description__label">{{ $t("general['available-balance']") }}</p>
        <p v-currency="tiptiCard?.availableAmount" class="amount-description__avalible-amount" />
    </base-card>
</template>

<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import { useTiptiCard } from '@/composables/useTiptiCard';

const { tiptiCard } = useTiptiCard();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.amount-description {
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    &__label {
        @include body1;
        color: $orange;
        font-weight: normal;
    }

    &__avalible-amount {
        @include body1;
        color: $orange;
        font-weight: normal;
        border-left: 1px solid $orange;
        padding-left: $padding-sm;
    }
}
</style>
