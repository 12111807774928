import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd82a7ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body-credit-card-header" }
const _hoisted_2 = { class: "body-credit-card__alert" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_link = _resolveComponent("base-link")!
  const _component_profile_form = _resolveComponent("profile-form")!
  const _component_dotted_rectangle = _resolveComponent("dotted-rectangle")!
  const _component_body_add_credit_cards = _resolveComponent("body-add-credit-cards")!
  const _component_list_cards = _resolveComponent("list-cards")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_validate_card = _resolveComponent("validate-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("general['credit-debit-card']")), 1),
        _createVNode(_component_base_link, {
          "is-route": false,
          "txt-link": _ctx.$t('txt.credit-card__accepted-cards'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.showModal = true))
        }, null, 8, ["txt-link"])
      ]),
      ($data.showProfileModal)
        ? (_openBlock(), _createBlock(_component_profile_form, {
            key: 0,
            onOnAccept: $options.profileCompleted,
            onOnClose: _cache[1] || (_cache[1] = ($event: any) => ($data.showProfileModal = false))
          }, null, 8, ["onOnAccept"]))
        : _createCommentVNode("", true),
      (!$data.showAddCard)
        ? (_openBlock(), _createBlock(_component_dotted_rectangle, {
            key: 1,
            class: "dotted-rectangle",
            "button-text": _ctx.$t('txt.credit-card__new-card'),
            onClick: $options.canAddCard
          }, null, 8, ["button-text", "onClick"]))
        : _createCommentVNode("", true),
      ($data.showAddCard)
        ? (_openBlock(), _createBlock(_component_body_add_credit_cards, {
            key: 2,
            onOnNewCreditCard: $options.onNewCreditCardShowed,
            onOnReturn: $options.onReturn
          }, null, 8, ["onOnNewCreditCard", "onOnReturn"]))
        : (_openBlock(), _createBlock(_component_list_cards, {
            key: 3,
            onAddCard: $options.canAddCard
          }, null, 8, ["onAddCard"]))
    ]),
    ($data.showModal)
      ? (_openBlock(), _createBlock(_component_base_modal, {
          key: 0,
          title: _ctx.$t('txt.credit-card__accepted-cards'),
          "z-index": 11,
          onOnClose: _cache[2] || (_cache[2] = ($event: any) => ($data.showModal = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("txt['credit-card__accepted-cards-to']", { cards: $options.creditCardsAllowed })), 1)
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    ($data.showVerificationFlow)
      ? (_openBlock(), _createBlock(_component_validate_card, {
          key: 1,
          "force-validate": _ctx.baseConfiguration?.cardValidationNewRegister,
          "payment-method-id": $data.paymentMethodId,
          onOnClose: $options.fetchPaymentMethods
        }, null, 8, ["force-validate", "payment-method-id", "onOnClose"]))
      : _createCommentVNode("", true)
  ], 64))
}