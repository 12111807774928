<template>
    <base-icon
        :icon-name="iconName ? iconName : $t('icon.success')"
        :width="width"
        :height="height"
        viewBox="0 0 64 63"
        :iconColor="color"
    >
        <path
            d="M35.8937 46.7835H27.2402C24.6686 46.7835 22.5859 44.7009 22.5859 42.1293V28.3674H40.548V42.1293C40.548 44.6965 38.461 46.7835 35.8937 46.7835Z"
            fill="#FF9012"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M31.5636 22.7402C29.956 22.7402 28.6478 24.0446 28.6478 25.656V31.5939C28.6478 31.7679 28.5067 31.909 28.3327 31.909C28.1587 31.909 28.0176 31.7679 28.0176 31.5939V25.656C28.0176 23.696 29.6085 22.11 31.5636 22.11C33.5236 22.11 35.1096 23.7009 35.1096 25.656V31.5939C35.1096 31.7679 34.9685 31.909 34.7945 31.909C34.6205 31.909 34.4794 31.7679 34.4794 31.5939V25.656C34.4794 24.0484 33.175 22.7402 31.5636 22.7402Z"
            fill="#FF9012"
        />
        <path
            d="M33.2022 39.2644C31.8444 39.8407 31.5693 38.055 31.5999 36.5836C31.6304 35.1865 31.7614 34.6276 31.7701 34.3263C31.7876 33.7981 33.1978 33.6933 33.1804 34.2958C33.1716 34.7324 33.0887 35.479 33.08 35.9942C33.0538 37.2822 33.2764 37.9938 33.4598 38.3737C33.4554 38.3737 33.8528 38.9893 33.2022 39.2644Z"
            fill="white"
        />
        <path
            d="M37.5509 36.6665C36.6777 39.356 32.7876 43.7963 25.5137 41.1461C25.8892 41.2334 33.508 42.9537 35.5251 37.0638C35.5251 37.0638 35.6866 36.3391 36.132 35.754C36.752 34.9375 37.9046 35.5663 37.5509 36.6665Z"
            fill="white"
        />
        <path
            d="M30.6757 39.9099C30.0077 40.2155 28.9599 39.2855 28.9075 36.6964C28.8987 36.0721 28.938 35.3823 28.9904 34.6793C29.021 34.2864 29.9728 34.3868 30.2129 34.5614C30.309 34.6313 30.4094 34.7055 30.3963 34.8278C30.3308 35.5132 30.2871 36.1463 30.3002 36.6266C30.3701 39.7876 31.3525 39.6086 30.6757 39.9099Z"
            fill="white"
        />
        <path
            d="M27.7944 2.34406C26.2379 2.08176 24.4482 2.83785 22.3357 4.64422C21.4649 5.49713 20.6975 6.47653 20.0636 7.57504L14.407 17.3724L12.2539 16.129L13.1971 26.2904L22.4682 22.0275L20.3584 20.8095L26.0156 11.0121C27.7279 8.04614 30.5679 7.72603 31.7085 7.72603C32.1411 7.72603 32.8177 7.77309 33.5847 7.99159C31.7388 5.00345 29.9664 2.70537 27.7945 2.34414L27.7944 2.34406Z"
            fill="#FF9012"
        />
        <path
            d="M48.8847 17.1506L43.3562 7.57449C40.925 3.36407 36.5713 0.85083 31.7103 0.85083C30.9401 0.85083 30.1845 0.921139 29.4453 1.04391C32.274 2.28892 34.3205 5.76592 36.639 9.71245L37.3984 11.0013C37.4005 11.0045 37.4027 11.0078 37.4043 11.011L42.9339 20.5871L48.8847 17.1506Z"
            fill="#FF9012"
        />
        <path
            d="M24.7807 53.4917H13.4679C10.0417 53.4906 8.34565 51.1921 7.77508 50.2045C7.55874 49.8302 7.26074 49.2207 7.06657 48.4478C5.4029 51.5397 4.29913 54.2234 5.07199 56.285C5.62689 57.7658 7.17208 58.9352 9.79248 59.8616C10.9666 60.1893 12.1998 60.3646 13.4686 60.3646H24.7814V62.8508L33.1082 56.9534L24.7814 51.0561L24.7807 53.4917Z"
            fill="#FF9012"
        />
        <path
            d="M7.03322 44.9441L7.76984 43.6412C7.77146 43.638 7.77309 43.6347 7.77471 43.632L13.3043 34.0559L7.35187 30.6189L1.82335 40.195C-0.607784 44.4054 -0.607784 49.4332 1.82335 53.643C2.20898 54.3104 2.64759 54.9297 3.12353 55.5084C2.78658 52.4354 4.77474 48.9253 7.03323 44.9442L7.03322 44.9441Z"
            fill="#FF9012"
        />
        <path
            d="M48.8202 25.0396L47.877 35.2004L49.9867 33.9824L55.6424 43.7798C57.3546 46.7452 56.2129 49.365 55.6424 50.3536C55.426 50.7284 55.0474 51.2909 54.4747 51.8457C57.9842 51.7397 60.8604 51.3541 62.2596 49.6543C63.2645 48.4336 63.5052 46.5109 62.9978 43.7796C62.6944 42.5979 62.2298 41.4426 61.5949 40.3437L55.9383 30.5463L58.0914 29.3029L48.8202 25.0396Z"
            fill="#FF9012"
        />
        <path
            d="M49.9622 53.643V53.6424C49.9585 53.6424 49.9552 53.643 49.9514 53.643L38.8945 53.6424V60.5155L49.9514 60.516C54.813 60.516 59.1663 58.0017 61.5973 53.7918C61.9824 53.1244 62.2999 52.4354 62.5627 51.7334C60.07 53.5614 56.0369 53.5944 51.4599 53.6295L49.9622 53.643Z"
            fill="#FF9012"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-recycle',
    components: {
        BaseIcon,
    },
    props: {
        color: {
            type: String,
            default: '#FF9012',
        },
        iconName: {
            type: String,
        },
        size: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        height(): number {
            return 64 * this.size;
        },
        width(): number {
            return 63 * this.size;
        },
    },
};
</script>
