<template>
    <base-card padding="0" class="flex-column">
        <section-header
            :hasBack="isMobile"
            @on-back="onBack"
            :title="$t('txt.address-user__delivery-sector')"
        ></section-header>
        <addresses :showCheck="false" section="PROFILE"></addresses>
    </base-card>
</template>
<script>
import { isMobileBrowser } from '@/utils/utils';

import BaseCard from '@/components/cards/BaseCard.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import Addresses from '@/views/addresses/Addresses.vue';

export default {
    name: 'addresses-profile',
    components: {
        BaseCard,
        Addresses,
        SectionHeader,
    },
    computed: {
        isMobile() {
            return isMobileBrowser();
        },
    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
    },
};
</script>
