import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b98b24f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-alert__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_share_buttons = _resolveComponent("share-buttons")!
  const _component_base_alert = _resolveComponent("base-alert")!

  return (_openBlock(), _createBlock(_component_base_alert, {
    onOnClose: $options.onCloseAlert,
    class: "content-alert"
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($props.title), 1),
      _createVNode(_component_share_buttons, {
        messageShare: $props.messageToShare,
        "snack-bar-message": $options.messageToShowOnCopy,
        link: $props.link,
        "share-link": $props.shareLink,
        onOnTrackEvent: $options.onTrackEvent,
        mailSubject: $props.title
      }, null, 8, ["messageShare", "snack-bar-message", "link", "share-link", "onOnTrackEvent", "mailSubject"])
    ]),
    _: 1
  }, 8, ["onOnClose"]))
}