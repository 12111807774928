import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": $options.label,
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 39 39"
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M30.94 15.8214C30.7895 15.3556 30.3764 15.0249 29.8877 14.9808L23.2493 14.378L20.6242 8.23394C20.4307 7.78366 19.9899 7.49219 19.5001 7.49219C19.0104 7.49219 18.5695 7.78366 18.376 8.235L15.751 14.378L9.11149 14.9808C8.62366 15.0259 8.21163 15.3556 8.06019 15.8214C7.90875 16.2871 8.04861 16.7979 8.41764 17.1199L13.4355 21.5206L11.9559 28.0385C11.8476 28.5177 12.0336 29.0131 12.4312 29.3006C12.645 29.455 12.895 29.5336 13.1472 29.5336C13.3646 29.5336 13.5803 29.475 13.7738 29.3592L19.5001 25.9368L25.2243 29.3592C25.6432 29.6112 26.1712 29.5882 26.5679 29.3006C26.9658 29.0122 27.1516 28.5167 27.0433 28.0385L25.5637 21.5206L30.5815 17.1208C30.9506 16.7979 31.0915 16.288 30.94 15.8214Z",
        fill: $props.color
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height"]))
}