import { ProductAnalyticInterface, ProductModel } from '@/models/product/ProductModel';
import { logger } from '@/utils/logger';
import { getLocation } from '@/utils/locationUtils';
import { CambrellaMembershipModel } from '@/modules/cambrella/models/CambrellaMembership';
import { MembershipType } from '@/modules/cambrella/models/MembershipType';
import dayjs from 'dayjs';
import { CartModel } from '@/models/cart/CartModel';
import { CheckoutEventTypes } from '@/views/checkout/enums/CheckoutEventTypes';
import { ReplacementEventTypes, ReplacementType } from '@/modules/events/enums/ReplacementEventTypes';
import { ShareActions } from '@/enums/shareActions';
import { isBoughtByWeigth } from '@/utils/productUtils';

declare const analytics: any;

interface IdentifyPayload {
    user_id: string;
    client_id: string;
    signup_type: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    user_type: string;
    register_date: string;
    birthday: string;
    gender: string;
}

interface ProductPayload {
    product_id: string;
    ean: string;
    category: string;
    name: string;
    price: number;
    price_with_discount: number;
    percentage_discount?: number;
    presentation_quantity: string;
    product_quantity: number;
    subcategory?: string;
    image_url: string;
}

interface UserPayload {
    user_id: string;
    client_id: string;
    email: string;
    first_name: string;
    last_name: string;
    user_type: string;
    birthday: string;
    gender: string;
}

interface SourcePayload {
    source_id: string;
    source: string;
}

interface LocationPayload {
    sector_id?: string;
    sector?: string;
    city: string;
    country: string;
}

interface OriginPayload {
    screen: string;
    section: string;
}

interface ListPayload {
    list_id: number;
    list: string;
    list_amount: number;
    product_numbers: number;
}

interface RetailPayload {
    retail: string;
    retail_id: number;
    retail_category: string;
    retail_category_id: string;
    delivery_mode: string;
    store_name: string;
}

interface RetailersPayload {
    name_category: string;
    name_retailers: Array<string>;
}

interface IsPickUpModePayload {
    initial_mode: string;
    new_mode: string;
}

interface CartPayload {
    cart_id: number;
    cart: string;
}

interface SearchBarPayload {
    search_bar: string;
    search_bar_id: string;
}

interface KeywordPayload {
    tag: string;
}

interface CategoryPayload {
    category: string;
    category_id: number;
}

interface SubcategoryPayload {
    subcategory: string;
    subcategory_id: number;
}

interface OrderPayload {
    order_id: string;
    order_amount: number;
    order_discount: number;
    applied_discount: Array<string>;
    service_fee: number;
    delivery_fee: number;
    products_number: number;
    tax_amount?: number;
    older_adult?: string;
    currency?: string;
    payment_methods?: Array<string>;
    affiliation?: string;
}

interface CodePayload {
    code: string;
    status: string;
}

interface SchedulePayload {
    date: string;
    hour_range: string;
}

interface BillPayload {
    identifier_number: string;
    phone: string;
    email: string;
    first_name: string;
    last_name: string;
    address: string;
}

interface BannerPayload {
    banner_id: number;
    banner_type: string;
    banner: string;
    banner_name: string;
}

interface StoryPayload {
    story_id: number;
    story_type: string;
    story: string;
}

interface TermsConditionPayload {
    client_accepted: boolean;
    terms_type: string;
    terms_information: string;
}

interface RecipePayload {
    recipe: string;
    recipe_id: number;
    recipe_people_amount?: number;
    recipe_difficulty?: number;
    recipe_items_amount?: number;
    products?: Array<ProductPayload>;
}

interface GroupRecipePayload {
    group: string;
    group_id: number;
}

interface SubGroupRecipePayload {
    subgroup: string;
    subgroup_id: number;
}

interface BenefitPayload {
    isAccepted?: boolean;
    productBenefit: {
        id: number;
        name: string;
        finalPrice: number;
        productQuantity: number;
    };
    eventName: string;
    retail: {
        retail: string;
        retail_id: number;
    };
}

const buildProduct = (product: ProductModel, setBulk = undefined) => {
    if (!product) return;
    return {
        product_id: product.id,
        stockitem_id: product.stockItemId,
        ean: product.ean ?? '',
        category: product.category?.name ?? '',
        name: product.name,
        price: product.price,
        percentage_discount: product.discount * 100,
        price_with_discount: product.priceWithDiscount ?? 0,
        presentation_quantity: product.unitQuantity ?? '',
        product_quantity: product.productQuantity ?? 0,
        subcategory: product.subCategory?.name ?? '',
        image_url: product.image?.medium ?? '',
        bulk: setBulk ? isBoughtByWeigth(product) : undefined,
        attachments: {
            note: !!product.note,
            picture: !!(product.noteToShopperPhoto || product.noteToShopperPhotoFile),
        },
        tag: {
            multiplayer: {
                carry: product.carry,
                pay: product.pay,
            },
            indicators: product.indicators,
            delivery: {
                time: product.hoursToDelivery,
            },
        },
    };
};
export default {
    retailer({ rootGetters }): RetailPayload {
        if (!rootGetters['retailers/retailerSelected']) return null;
        return {
            retail: rootGetters['retailers/retailerSelected'].name,
            retail_id: rootGetters['retailers/retailerSelected'].id,
            retail_category: rootGetters['retailers/retailerSelected'].retailerType,
            retail_category_id: rootGetters['retailers/retailerSelected'].retailerType,
            store_name: rootGetters['retailers/retailerSelected'].store_name,
            delivery_mode: rootGetters['retailers/retailerSelected'].isPickUpModeUsersSelection
                ? 'PICK_UP'
                : 'DELIVERY',
        };
    },
    user({ rootGetters }): UserPayload {
        if (!rootGetters['user/user']) return null;
        return {
            user_id: rootGetters['user/user'].userId,
            client_id: rootGetters['user/user'].id,
            email: rootGetters['user/user'].email,
            first_name: rootGetters['user/user'].name,
            last_name: rootGetters['user/user'].lastname,
            user_type: rootGetters['user/user'].userType ?? 'REGULAR',
            birthday: dayjs(rootGetters['user/user'].birthday).format('DD/MM/YYYY'),
            gender: rootGetters['user/user'].genre,
        };
    },
    identifyUser({ rootGetters }, type?: string): IdentifyPayload {
        if (!rootGetters['user/user']) return null;
        return {
            user_id: rootGetters['user/user'].userId,
            email: rootGetters['user/user'].email,
            first_name: rootGetters['user/user'].name,
            last_name: rootGetters['user/user'].lastname,
            user_type: rootGetters['user/user'].userType ?? 'REGULAR',
            phone: rootGetters['user/user'].phone,
            client_id: rootGetters['user/user'].id,
            signup_type: type ?? '',
            register_date: rootGetters['user/user'].registerDate,
            birthday: dayjs(rootGetters['user/user'].birthday).format('DD/MM/YYYY'),
            gender: rootGetters['user/user'].genre,
        };
    },
    location({ rootGetters }): LocationPayload {
        return {
            city: rootGetters['user/userLocation']?.city?.name ?? '',
            country: rootGetters['user/userLocation']?.city?.country?.name ?? '',
            sector_id: rootGetters['user/userLocation']?.sector?.id ?? '',
            sector: rootGetters['user/userLocation']?.sector?.name ?? '',
        };
    },
    /// TODO add enum fot this options
    extraItemPayment({ rootGetters }): object {
        return {
            payment_method_id: rootGetters['payments/paymentIdSelected'] ? 'CREDIT_OR_DEBIT_CARD' : null,
            cash_amount: rootGetters['payments/cashWirePayment']?.cash?.cashAmount ? 'CASH' : null,
            tipti_card_amount: rootGetters['payments/isTiptiCardPayment'] ? 'TIPTI_CARD' : null,
            institution_id: rootGetters['payments/institutionPayment']?.id ? 'INSTITUTIONAL' : null,
        };
    },
    async _productPixel({ rootGetters }, product: ProductModel): Promise<object> {
        /// ** data set to handle in PIXEL by SEGMENT
        return {
            id: product.id,
            name: product.name,
            currency: rootGetters['external/currencyType'] ?? 'USD',
            quantity: product.unitQuantity ?? '',
            price: product.priceWithDiscount ?? 0,
            value: product.priceWithDiscount ?? 0,
            category: product.category?.name ?? '',
        };
    },
    recipe({ rootGetters }): RecipePayload {
        if (!rootGetters['lists/recipeDetail']) return null;
        const products = rootGetters['lists/recipeDetail']?.products.filter(
            (product: ProductModel) => product.productQuantity > 0,
        );
        return {
            recipe: rootGetters['lists/recipeDetail']?.name,
            recipe_id: rootGetters['lists/recipeDetail']?.id,
            recipe_people_amount: rootGetters['lists/recipeDetail']?.peopleNumber,
            recipe_difficulty: rootGetters['lists/recipeDetail']?.difficulty,
            recipe_items_amount: rootGetters['lists/recipeDetail']?.products.length,
            products: products.map((product: ProductModel) => {
                return {
                    product_id: product.id,
                    ean: product.ean,
                    category: product.category.name,
                    name: product.name,
                    price: product.price,
                    price_with_discount: product.priceWithDiscount,
                    percentage_discount: product.discount,
                    presentation_quantity: product.unitQuantity,
                    product_quantity: product.productQuantity,
                    subcategory: product?.subCategory?.name ?? null,
                    image_url: product?.image?.medium ?? null,
                };
            }),
        };
    },
    async registerSegmentTrack(_, payload): Promise<void> {
        try {
            analytics.track(payload.event, payload.parameters);
        } catch (err) {
            logger('REGISTER_SEGMENT_TRACK', err);
        }
    },
    registerSegmentPage(_, payload): void {
        try {
            analytics.page(payload.event, payload.parameters);
        } catch (err) {
            logger('REGISTER_SEGMENT_PAGE', err);
        }
    },
    async identify({ rootGetters, dispatch }, signType: string): Promise<void> {
        try {
            const location = await getLocation();
            const networkInfo = window?.navigator['connection'];
            const traits = await dispatch('identifyUser', signType);
            analytics.identify(rootGetters['user/user']?.userId, traits, {
                context: {
                    token: rootGetters['notifications/tokenFirebase'] ?? '',
                    location: {
                        latitude: location.lat,
                        longitude: location.lng,
                    },
                    screen: {
                        density: window.devicePixelRatio,
                        height: window.screen.height,
                        width: window.screen.width,
                    },
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    network: {
                        cellular: networkInfo ? networkInfo.type === 'cellular' : '',
                        wifi: networkInfo ? networkInfo.type === 'wifi' : '',
                    },
                },
            });
        } catch (err) {
            logger('IDENTIFY', err);
        }
    },
    openSignIn({ dispatch, getters }): void {
        dispatch('registerSegmentTrack', {
            event: 'Open Sign In',
            parameters: {
                browser: getters['browser'],
            },
        });
    },
    openSignUp({ dispatch, getters }): void {
        dispatch('registerSegmentTrack', {
            event: 'Open Sign Up',
            parameters: {
                browser: getters['browser'],
            },
        });
    },
    async signIn({ dispatch, getters }, payload: { source?: SourcePayload }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Sign In',
            parameters: {
                browser: getters['browser'],
                location: await dispatch('location'),
                user: await dispatch('user'),
                source: payload?.source,
            },
        });
    },
    async signUp({ dispatch, getters }, payload: { source?: SourcePayload }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Sign Up',
            parameters: {
                browser: getters['browser'],
                location: await dispatch('location'),
                user: await dispatch('user'),
                source: payload?.source,
            },
        });
    },
    async viewModalCompleteProfile({ dispatch }, payload: { origin: OriginPayload }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'View modal complete profile',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                origin: payload.origin,
            },
        });
    },
    async viewConfiguration({ dispatch }, payload: { origin: OriginPayload }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'View configuration',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                origin: payload.origin,
            },
        });
    },
    async viewTermsAndConditions(
        { dispatch },
        payload: { origin: OriginPayload; terms_and_conditions: TermsConditionPayload },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'View T&C',
            parameters: {
                user: await dispatch('identifyUser'),
                terms_and_conditions: payload.terms_and_conditions,
                origin: payload.origin,
            },
        });
    },
    async completeRegistration({ dispatch }, payload: { location: LocationPayload }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Complete Registration',
            parameters: {
                location: payload.location,
                user: await dispatch('user'),
            },
        });
    },
    async addressSaved({ dispatch }, payload: { location: LocationPayload; origin: OriginPayload }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Address Saved',
            parameters: {
                location: payload.location,
                user: await dispatch('user'),
                origin: payload.origin,
            },
        });
    },
    async addressUpdated({ dispatch }, payload: { location: LocationPayload; origin: OriginPayload }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Address Updated',
            parameters: {
                location: payload.location,
                user: await dispatch('user'),
                origin: payload.origin,
            },
        });
    },
    async addressDefault(
        { dispatch },
        payload: { location: LocationPayload; user: UserPayload; origin: OriginPayload },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Address Default',
            parameters: {
                location: payload.location,
                user: await dispatch('user'),
                origin: payload.origin,
            },
        });
    },
    async addressSelected(
        { dispatch },
        payload: { location: LocationPayload; user: UserPayload; origin: OriginPayload },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Address Selected',
            parameters: {
                location: payload.location,
                user: await dispatch('user'),
                origin: payload.origin,
            },
        });
    },
    async search(
        { dispatch },
        payload: {
            origin: OriginPayload;
            search_bar: SearchBarPayload;
            keyword: string;
            total_results: number;
            products: Array<string>;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Search',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                origin: payload.origin,
                search_bar: payload.search_bar,
                retail: await dispatch('retailer'),
                keyword: {
                    tag: payload.keyword,
                } as KeywordPayload,
                first_page_results: payload.products ?? [],
                total_results: payload.total_results,
                /// ** data set to handle in PIXEL by SEGMENT
                query: payload.keyword,
            },
        });
    },
    async searchAutocompleteSelected(
        { dispatch },
        payload: {
            origin: OriginPayload;
            search_bar: SearchBarPayload;
            keyword: KeywordPayload;
            options: Array<string>;
            optionSelected: string;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Search Autocomplete Selected',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                origin: payload.origin,
                search_bar: payload.search_bar,
                retail: await dispatch('retailer'),
                keyword: payload.keyword,
                option_selected: payload.optionSelected,
                options: payload.options,
            },
        });
    },
    async addCartToListEvent({ dispatch }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Add cart to list',
            parameters: {
                user: await dispatch('user'),
                location: await dispatch('location'),
                origin: {
                    screen: window.location.pathname,
                },
                retailers: await dispatch('retailer'),
            },
        });
    },

    async emptyCartEvent({ dispatch }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Empty Cart',
            parameters: {
                user: await dispatch('user'),
                location: await dispatch('location'),
                origin: {
                    screen: window.location.pathname,
                },
                retailers: await dispatch('retailer'),
            },
        });
    },
    async openSharedCartEvent(
        { dispatch },
        payload: {
            numberCarts: number;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Open Shared Cart',
            parameters: {
                user: await dispatch('user'),
                location: await dispatch('location'),
                origin: {
                    screen: window.location.pathname,
                },
                numberCarts: payload.numberCarts,
            },
        });
    },
    async selectSharedCart(
        { dispatch },
        payload: {
            itIsSharedCart: boolean;
            cartName: string;
            products: Array<ProductPayload>;
            members: [];
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Select Shared Cart Viewed',
            parameters: {
                user: await dispatch('user'),
                location: await dispatch('location'),
                origin: {
                    screen: window.location.pathname,
                },
                it_is_shared_cart: payload.itIsSharedCart,
                cart_name: payload.cartName,
                products: payload.products,
                members: payload.members,
            },
        });
    },
    async viewedLanding(
        { dispatch },
        payload: {
            origin: OriginPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentPage', {
            event: 'Viewed landing Page',
            parameters: {
                origin: payload.origin,
            },
        });
    },

    async cartViewed(
        { dispatch, rootGetters },
        payload: {
            products: Array<ProductPayload>;
            cart: CartPayload;
            retailers: Array<RetailPayload>;
        },
    ): Promise<void> {
        const cambrellaMembership: CambrellaMembershipModel = await rootGetters['cambrellas/cambrellaMembership'];
        dispatch('registerSegmentPage', {
            event: 'Cart Viewed',
            parameters: {
                user: await dispatch('user'),
                products: payload.products,
                cart: payload.cart,
                location: await dispatch('location'),
                has_cambrella_subscription: !!cambrellaMembership?.id,
                retailers: payload.retailers,
                origin: {
                    screen: window.location.pathname,
                },
            },
        });
    },
    async retailSelected({ dispatch }, origin: OriginPayload): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Retail Selected',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                retail: await dispatch('retailer'),
                origin: origin,
            },
        });
    },
    async selectRetailCategory(
        { dispatch },
        {
            origin,
            name_category,
            name_retailers,
        }: {
            origin: OriginPayload;
            name_category: string;
            name_retailers: string[];
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Select retail category',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                origin,
                name_category,
                name_retailers,
            },
        });
    },

    async initialModeDeliveryAndPickup({ dispatch }, payload: { origin: OriginPayload }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Select delivery mode',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                retail: await dispatch('retailer'),
                ...payload,
            },
        });
    },
    async categoryViewed(
        { dispatch },
        payload: {
            category: CategoryPayload;
            origin: OriginPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentPage', {
            event: 'Category Viewed',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                category: payload.category,
                retail: await dispatch('retailer'),
                origin: payload.origin,
            },
        });
    },
    async subcategoryViewed(
        { dispatch },
        payload: {
            location: LocationPayload;
            user: UserPayload;
            category: CategoryPayload;
            subcategory: SubcategoryPayload;
            retail: RetailPayload;
            origin: OriginPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentPage', {
            event: 'Subcategory Viewed',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                category: payload.category,
                subcategory: payload.subcategory,
                retail: payload.retail,
                origin: payload.origin,
            },
        });
    },

    async listViewed(
        { dispatch },
        payload: {
            location: LocationPayload;
            user: UserPayload;
            origin: OriginPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentPage', {
            event: 'List Viewed',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                origin: payload.origin,
            },
        });
    },
    async listSaved(
        { dispatch },
        payload: {
            product: ProductModel;
            list: ListPayload;
            retail: RetailPayload;
            origin: OriginPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'List Saved',
            parameters: {
                user: await dispatch('user'),
                product: { ...buildProduct(payload.product) },
                list: {
                    ...payload.list,
                    list: payload.list.list.toString().replace(/[[\]{}'"]/g, ''),
                },
                retail: payload.retail,
                origin: payload.origin,
            },
        });
    },
    async orderViewed(
        { dispatch },
        payload: {
            products: Array<ProductPayload>;
            order: OrderPayload;
            retail: RetailPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentPage', {
            event: 'Order Viewed',
            parameters: {
                user: await dispatch('user'),
                products: payload.products,
                order: payload.order,
                retail: payload.retail,
            },
        });
    },
    addCode(
        { dispatch },
        payload: {
            code: CodePayload;
            origin: OriginPayload;
        },
    ): void {
        dispatch('registerSegmentTrack', {
            event: 'Add Code',
            parameters: {
                code: payload.code,
                origin: payload.origin,
            },
        });
    },
    async productViewed(
        { dispatch, rootGetters },
        payload: {
            product: ProductModel;
            origin: string;
            section: string;
            screen?: string;
            searchedPhrase?: string;
        },
    ): Promise<void> {
        if (!payload?.product) return;
        dispatch('registerSegmentTrack', {
            event: 'Product Viewed',
            parameters: {
                product: { ...buildProduct(payload.product) },
                location: await dispatch('location'),
                retail: await dispatch('retailer'),
                origin: {
                    screen: payload.origin ?? payload.screen,
                    section: payload.searchedPhrase ? rootGetters['search/searchOriginSection'] : payload.section,
                    searched_phrase: payload.searchedPhrase?.trim(),
                },
                ...(await dispatch('_productPixel', payload.product)),
            },
        });
    },
    async productAdded(
        { dispatch, rootGetters },
        payload: {
            product: ProductModel;
            origin: string;
            section: string;
            screen?: string;
            searchedPhrase?: string;
        },
    ): Promise<void> {
        if (!payload?.product) return;
        dispatch('registerSegmentTrack', {
            event: 'Product Added',
            parameters: {
                product: { ...buildProduct(payload.product, true) },
                location: await dispatch('location'),
                retail: await dispatch('retailer'),
                origin: {
                    section: payload.section,
                    screen: payload.searchedPhrase ? rootGetters['search/searchOriginSection'] : payload.screen,
                    searched_phrase: payload.searchedPhrase?.trim(),
                },
                ...(await dispatch('_productPixel', payload.product)),
            },
        });
    },
    async productUpdated(
        { dispatch, rootGetters },
        payload: {
            product: ProductModel;
            origin: string;
            section: string;
            screen?: string;
            searchedPhrase?: string;
        },
    ): Promise<void> {
        if (!payload?.product) return;
        dispatch('registerSegmentTrack', {
            event: 'Product Updated',
            parameters: {
                location: await dispatch('location'),
                product: {
                    ...buildProduct(payload.product, true),
                },
                retail: await dispatch('retailer'),
                origin: {
                    screen: payload.origin ?? payload.screen,
                    section: payload.searchedPhrase ? rootGetters['search/searchOriginSection'] : payload.section,
                    searched_phrase: payload.searchedPhrase?.trim(),
                },
            },
        });
    },
    productRemoved(
        { dispatch },
        payload: {
            location: LocationPayload;
            product: ProductPayload;
            retail: RetailPayload;
            origin: OriginPayload;
        },
    ): void {
        dispatch('registerSegmentTrack', {
            event: 'Product Removed',
            parameters: {
                location: payload.location,
                product: payload.product,
                retail: payload.retail,
                origin: payload.origin,
            },
        });
    },
    async productFavorite(
        { dispatch, rootGetters },
        payload: {
            product: ProductModel;
            products?: Array<ProductModel>;
            section?: string;
            state: boolean;
            screen?: string;
            searchedPhrase?: string;
            isfromIcon: boolean;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Product Favorite',
            parameters: {
                location: await dispatch('location'),
                product: {
                    ...buildProduct(payload.product, true),
                },
                products: payload.products?.map((product) => buildProduct(product, true)),
                new_state: payload.state,
                retail: await dispatch('retailer'),
                is_from_icon: payload.isfromIcon,
                origin: {
                    screen: payload.screen,
                    section: payload.searchedPhrase ? rootGetters['search/searchOriginSection'] : payload.section,
                    searched_phrase: payload.searchedPhrase?.trim(),
                },
            },
        });
    },
    async productShare(
        { dispatch, rootGetters },
        payload: {
            section?: string;
            media: ShareActions;
            screen?: string;
            searchedPhrase?: string;
        },
    ): Promise<void> {
        const product: ProductModel = rootGetters['product/productSelected'];
        dispatch('registerSegmentTrack', {
            event: 'Product Share',
            parameters: {
                location: await dispatch('location'),
                product: {
                    ...buildProduct(product),
                },
                media: payload.media,
                retail: await dispatch('retailer'),
                origin: {
                    screen: payload.screen,
                    section: payload.searchedPhrase ? rootGetters['search/searchOriginSection'] : payload.section,
                    searched_phrase: payload.searchedPhrase?.trim(),
                },
            },
        });
    },
    async viewProductDetail(
        { dispatch, rootGetters },
        payload: {
            section?: string;
            screen?: string;
            searchedPhrase?: string;
        },
    ): Promise<void> {
        const product: ProductModel = rootGetters['product/currentProduct'];
        if (!product) return;
        dispatch('registerSegmentTrack', {
            event: 'View Product Detail',
            parameters: {
                location: await dispatch('location'),
                product: {
                    ...buildProduct(product, true),
                },
                retail: await dispatch('retailer'),
                origin: {
                    screen: payload.screen,
                    section: payload.searchedPhrase ? rootGetters['search/searchOriginSection'] : payload.section,
                    searched_phrase: payload.searchedPhrase?.trim(),
                },
            },
        });
    },
    async checkoutStarted(
        { dispatch, rootGetters },
        payload: {
            product: Array<ProductPayload>;
            schedule: SchedulePayload;
            bill: BillPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Checkout Started',
            parameters: {
                products: payload.product,
                cart: { cart: rootGetters['cart/cart']?.name, cart_id: rootGetters['cart/cart'].id } as CartPayload,
                location: await dispatch('location'),
                user: await dispatch('user'),
                schedule: payload.schedule,
                bill: payload.bill,
                value: rootGetters['cart/cart']['totalDetails']['totalCost'],
                currency: rootGetters['external/countrySelected']?.currencySymbol ?? '$',
            },
        });
    },
    async bannerImpressions(
        { dispatch },
        payload: {
            banner: BannerPayload;
            origin: OriginPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Banner Impressions',
            parameters: {
                banner: payload.banner,
                user: await dispatch('user'),
                retail: await dispatch('retailer'),
                origin: payload.origin,
            },
        });
    },
    async storiesImpressions(
        { dispatch },
        payload: {
            stories: StoryPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Stories Impressions',
            parameters: {
                stories: payload.stories,
                user: await dispatch('user'),
                retail: await dispatch('retailer'),
            },
        });
    },
    async storiesOpened(
        { dispatch },
        payload: {
            stories: StoryPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Stories Opened',
            parameters: {
                stories: payload.stories,
                user: await dispatch('user'),
                retail: await dispatch('retailer'),
            },
        });
    },
    async contentStoryViewed(
        { dispatch },
        payload: {
            stories: StoryPayload;
            products: ProductModel[];
        },
    ): Promise<void> {
        dispatch('registerSegmentPage', {
            event: 'Content Story Viewed',
            parameters: {
                stories: payload.stories,
                user: await dispatch('user'),
                retail: await dispatch('retailer'),
                products: payload.products.map((prod) => buildProduct(prod, true)),
            },
        });
    },
    async contentBannerViewed(
        { dispatch },
        payload: {
            banners: StoryPayload;
            products: ProductModel[];
        },
    ): Promise<void> {
        dispatch('registerSegmentPage', {
            event: 'Content Banner Viewed',
            parameters: {
                banners: payload.banners,
                user: await dispatch('user'),
                retail: await dispatch('retailer'),
                products: payload.products.map((prod) => buildProduct(prod, true)),
            },
        });
    },
    async detailsPopulated(
        { dispatch },
        payload: {
            source?: SourcePayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Details Populated',
            parameters: {
                parameters: {
                    location: await dispatch('location'),
                    user: await dispatch('user'),
                    source: payload?.source,
                },
            },
        });
    },
    async tcAccepted(
        { dispatch },
        payload: {
            user: UserPayload;
            terms_and_conditions: TermsConditionPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'T&C Accepted',
            parameters: {
                user: await dispatch('user'),
                terms_and_conditions: payload.terms_and_conditions,
            },
        });
    },
    async addRecipeToCart(
        { dispatch },
        payload: {
            origin: OriginPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Add Recipe To Cart',
            parameters: {
                retail: await dispatch('retailer'),
                origin: payload.origin,
                recipe: await dispatch('recipe'),
            },
        });
    },
    async shareRecipe(
        { dispatch },
        payload: {
            origin: OriginPayload;
            share_media: string;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Share Recipe',
            parameters: {
                retail: await dispatch('retailer'),
                origin: payload.origin,
                recipe: await dispatch('recipe'),
                share_media: payload.share_media,
            },
        });
    },
    async rateRecipe(
        { dispatch },
        payload: {
            origin: OriginPayload;
            recipe_rate: number;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Rate Recipe',
            parameters: {
                retail: await dispatch('retailer'),
                origin: payload.origin,
                recipe: await dispatch('recipe'),
                recipe_rate: payload.recipe_rate,
            },
        });
    },
    async openRecipe(
        { dispatch },
        payload: {
            origin: OriginPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Open Recipe',
            parameters: {
                retail: await dispatch('retailer'),
                origin: payload.origin,
                recipe: await dispatch('recipe'),
            },
        });
    },
    async openSubGroupsRecipe(
        { dispatch },
        payload: {
            origin: OriginPayload;
            recipes: Array<RecipePayload>;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Open Subgroups Recipe',
            parameters: {
                retail: await dispatch('retailer'),
                origin: payload.origin,
                recipes: payload.recipes,
            },
        });
    },
    async openGroupRecipe(
        { dispatch },
        payload: {
            origin: OriginPayload;
            subgroups_recipe: Array<SubGroupRecipePayload>;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Open Group Recipe',
            parameters: {
                retail: await dispatch('retailer'),
                origin: payload.origin,
                subgroups_recipe: payload.subgroups_recipe,
            },
        });
    },
    async GroupsRecipeImpression(
        { dispatch },
        payload: {
            origin: OriginPayload;
            groups_recipe: Array<GroupRecipePayload>;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Groups Recipe Impression',
            parameters: {
                retail: await dispatch('retailer'),
                origin: payload.origin,
                groups_recipe: payload.groups_recipe,
            },
        });
    },
    async SubGroupsRecipeImpression(
        { dispatch },
        payload: {
            origin: OriginPayload;
            subgroups_recipe: Array<SubGroupRecipePayload>;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Subgroups Recipe Impression',
            parameters: {
                retail: await dispatch('retailer'),
                origin: payload.origin,
                subgroups_recipe: payload.subgroups_recipe,
            },
        });
    },
    async RecipesImpression(
        { dispatch },
        payload: {
            origin: OriginPayload;
            recipes: Array<RecipePayload>;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Recipes Impression',
            parameters: {
                retail: await dispatch('retailer'),
                origin: payload.origin,
                recipes: payload.recipes,
            },
        });
    },
    giveBenefit(
        { dispatch },
        payload: {
            benefit: BenefitPayload;
            origin: OriginPayload;
        },
    ): void {
        const parameters = {
            product: payload.benefit.productBenefit,
            retail: payload.benefit.retail,
            origin: payload.origin,
        };
        if (payload.benefit.isAccepted !== undefined) parameters['benefit_accepted'] = payload.benefit.isAccepted;
        dispatch('registerSegmentTrack', {
            event: `${payload.benefit.eventName}`,
            parameters,
        });
    },
    async maxiConversionInit(
        { dispatch },
        payload: {
            initial_amount: number;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Initial Transfer Mxd',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                initial_amount: payload.initial_amount,
            },
        });
    },
    async maxiConversionResult(
        { dispatch },
        payload: {
            amount_updated: number;
            success: boolean;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Transfer Mxd',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                amount_updated: payload.amount_updated,
                success: payload.success,
            },
        });
    },
    async viewInformationServiceFee({ dispatch }, payload: { code_type: string }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'View Information Service Fee',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
                type: payload?.code_type,
            },
        });
    },
    async viewInformationServiceCost({ dispatch }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'View Information Service Cost',
            parameters: {
                location: await dispatch('location'),
                user: await dispatch('user'),
            },
        });
    },

    ///Cambrellas Analytics
    async openCambrellasSubscription(
        { dispatch },
        payload: {
            products: Array<ProductPayload>;
            cart: CartPayload;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Open cambrellas subscription',
            parameters: {
                user: await dispatch('user'),
                products: payload.products,
                cart: payload.cart,
                location: await dispatch('location'),
                has_cambrella_subscription: false,
            },
        });
    },
    async initialCambrellasSubscription(
        { dispatch },
        {
            cambrellas,
        }: {
            cambrellas: MembershipType;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Initial cambrella subscription',
            parameters: {
                user: await dispatch('user'),
                cambrellas: {
                    id: cambrellas.id,
                    name: cambrellas.name,
                    total: cambrellas.number_of_cambrellas,
                    available: cambrellas.number_of_cambrellas,
                    price: cambrellas.price,
                },
            },
        });
    },
    async cambrellasSubscription(
        { dispatch },
        {
            cambrellas,
        }: {
            cambrellas: MembershipType;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Cambrella subscription',
            parameters: {
                user: await dispatch('user'),
                cambrellas: {
                    id: cambrellas.id,
                    name: cambrellas.name,
                    total: cambrellas.number_of_cambrellas,
                    available: cambrellas.number_of_cambrellas,
                    price: cambrellas.price,
                },
            },
        });
    },
    async cambrellaStatus(
        { dispatch },
        payload: {
            cambrellas: CambrellaMembershipModel;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Cambrella status',
            parameters: {
                user: await dispatch('user'),
                cambrellas: CambrellaMembershipModel.toJSON(payload.cambrellas),
            },
        });
    },
    //** X-Selling
    async xSellingOrderComplete(
        { dispatch },
        payload: {
            products: Array<ProductAnalyticInterface>;
            payment_method: object;
            retailer: object;
            total_xselling_cost: number;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Xselling order completed',
            parameters: {
                user: await dispatch('user'),
                location: await dispatch('location'),
                products: payload.products,
                payment_method: payload.payment_method,
                retailer: payload.retailer,
                total_xselling_cost: payload.total_xselling_cost,
            },
        });
    },
    async viewOrderCompleteModal(
        { dispatch },
        payload: {
            amount_saved: number;
            time_saved: number;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'View order complete modal',
            parameters: {
                user: await dispatch('user'),
                location: await dispatch('location'),
                amount_saved: payload.amount_saved,
                time_saved: payload.time_saved,
            },
        });
    },
    async viewXSellingProducts({ dispatch }, products: ProductModel[]): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'View Xselling screen',
            parameters: {
                products: products.map((product) => buildProduct(product, true)),
            },
        });
    },
    async viewXSellingOrder(
        { dispatch },
        payload: {
            products: ProductModel[];
            total_xselling_cost: number;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'View Xselling order',
            parameters: {
                products: payload.products.map((product) => buildProduct(product, true)),
                total_xselling_cost: payload.total_xselling_cost,
                payment_method: await dispatch('extraItemPayment'),
            },
        });
    },
    async xSellingClose({ dispatch }, payload: { isSkip: boolean; cart: CartModel }): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: payload.isSkip ? 'Xselling skip' : 'Xselling back',
            parameters: {
                user: await dispatch('user'),
                location: await dispatch('location'),
                retailer: payload.cart?.retailers[0]?.toAnalytics,
            },
        });
    },
    async replacementEvents(
        { dispatch },
        {
            event,
            originalProduct,
            replacement,
            replacements,
            section,
            screen,
        }: {
            event: ReplacementEventTypes;
            originalProduct: ProductModel;
            replacement: ProductModel;
            replacements: ProductModel[];
            section: ReplacementType;
            screen: string;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event,
            parameters: {
                user: await dispatch('user'),
                location: await dispatch('location'),
                retailer: originalProduct?.retailer?.toAnalytics,
                original_product: buildProduct(originalProduct, true),
                replacement:
                    event === ReplacementEventTypes.ViewReplacementSuggestions || replacements?.length
                        ? undefined
                        : buildProduct(replacement, true),
                replacements: replacements?.length
                    ? replacements?.map((product) => buildProduct(product, true))
                    : undefined,
                origin: {
                    section,
                    screen,
                } as OriginPayload,
            },
        });
    },
    async checkoutEvents(
        { dispatch },
        {
            event,
            parameters,
        }: {
            event: CheckoutEventTypes;
            parameters?: object;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event,
            parameters: {
                user: await dispatch('user'),
                location: await dispatch('location'),
                ...parameters,
            },
        });
    },
    async outOfDeliveryZone(
        { dispatch },
        {
            lat,
            lon,
            origin_screen,
        }: {
            lat: number;
            lon: number;
            origin_screen: string;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Out of delivery zone',
            parameters: {
                user: await dispatch('user'),
                location: await dispatch('location'),
                lon,
                lat,
                origin_screen,
            },
        });
    },
    async createNewUser(
        { dispatch },
        {
            mail,
            name,
            provider,
        }: {
            mail: string;
            name: string;
            provider: string;
        },
    ): Promise<void> {
        dispatch('registerSegmentTrack', {
            event: 'Create new user',
            parameters: {
                mail,
                name,
                provider,
            },
        });
    },
};
