export interface PaymentRejectedContext {
    type: string;
    payment_method_number: string;
    icon: string;
    amount: number;
}

export interface SuggestedPaymentMethod {
    name: string;
    id: number;
    ammount: string /*only tipti card*/;
    alias: string;
    brand: string;
    last_card_digits: string;
}

export class OrderRejectedContextDto {
    readonly payment_rejected_context: PaymentRejectedContext;
    readonly suggested_payment_method: SuggestedPaymentMethod;

    constructor({
        payment_rejected_context,
        suggested_payment_method,
    }: {
        payment_rejected_context: PaymentRejectedContext;
        suggested_payment_method: SuggestedPaymentMethod;
    }) {
        this.payment_rejected_context = payment_rejected_context;
        this.suggested_payment_method =
            suggested_payment_method && Object.keys(suggested_payment_method)?.length
                ? suggested_payment_method
                : undefined;
    }
}
