<template>
    <section class="the-note">
        <div class="the-note-area">
            <text-area v-model.trim="updateNote" class="the-note-area__area" :placeholder="thePlaceholder" :rows="4" />
            <p class="the-note-area__counter" :class="{ 'the-note-area__counter--limit': tmpNote?.length >= 250 }">
                {{ tmpNote?.length }}/250
            </p>
        </div>
        <section class="the-note__actions">
            <button class="the-note-area__add-photo" @click.stop="isDeleteFile ? onClean() : onAddFile()">
                <icon-gallery v-if="isDeleteFile" :size="0.6" />
                <icon-filled-circular-close
                    v-if="isDeleteFile"
                    class="cursor-pointer"
                    :size="0.4"
                    color="#f03f51"
                    @click="onClean"
                />
                <the-btn-picture v-else @on-file="onFileChange" />
                <p>{{ isDeleteFile ? tmpFile?.name : $t('cta.add-image') }}</p>
            </button>
            <btn-solid
                :is-disabled="!isNoteChanged || isLoadingExternal || isLoading || !tmpNote?.length"
                :is-loading="isLoadingExternal || isLoading"
                :txt="$t('cta.modified-note')"
                @click.stop="addNote"
            />
        </section>
    </section>
    <alert-note-not-save
        v-if="showAlertNotSaveNote"
        @on-accept="addNote(true)"
        @on-discard="cleanAndBack"
        @on-close="cleanAndBack"
    />
    <general-alert
        v-if="showAlertFileRequirements"
        :accent-header="true"
        :message="$t('txt.note__add-photo-as-note')"
        :only-confirmation-btn="true"
        :title="$t('txt.product-modal__send-note')"
        @on-accept="showAlertFileRequirements = false"
        @on-close="showAlertFileRequirements = false"
    />
</template>

<script lang="ts">
import { ProductModel } from '@/models/product/ProductModel';
import { toBase64, urlToFile } from '@/utils/picturesUtils';
import { mapGetters } from 'vuex';
import { cleanNote } from '@/utils/productUtils';

import IconGallery from '@/components/icons/IconGallery.vue';
import IconFilledCircularClose from '@/components/icons/IconFilledCircularClose.vue';
import TextArea from '@/components/inputs/textArea/TextArea.vue';
import TheBtnPicture from '@/components/buttons/TheBtnPicture.vue';
import GeneralAlert from '@/components/alerts/generalAlert/GeneralAlert.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { logger } from '@/utils/logger';
import AlertNoteNotSave from '@/components/alerts/alertNoteNotSave/AlertNoteNotSave.vue';
import type { PropType } from 'vue';

export default {
    name: 'TextAreaNotes',
    props: {
        product: {
            type: Object as PropType<ProductModel>,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        isLoadingExternal: {
            type: Boolean,
            default: false,
        },
        isFromModal: {
            type: Boolean,
            default: false,
        },
        quantity: {
            type: Number,
            default: undefined,
        },
    },
    emits: ['on-close', 'on-back', 'on-save'],
    data() {
        return {
            isLoading: false,
            showAlertFileRequirements: false,
            localFile: undefined,
            serverFile: undefined,
        };
    },
    computed: {
        ...mapGetters({
            tmpFile: 'product/tmpFile',
            showAlertNotSaveNote: 'product/showAlertNotSaveNote',
            isCategoryFromList: 'lists/isCategoryFromList',
            listIdToAddFromCategories: 'lists/listIdToAddFromCategories',
            tmpNote: 'product/tmpNote',
            isAuth: 'user/isAuth',
        }),

        isDeleteFile(): boolean {
            return this.tmpFile;
        },
        thePlaceholder(): string {
            return this.placeholder.length ? this.placeholder : this.$t('txt.note__instructions-shopper');
        },
        isTextChanged(): boolean {
            return this.updateNote !== cleanNote(this.product?.note);
        },
        isFileChanged(): boolean {
            return (this.serverFile as File)?.name !== this.localFile?.name;
        },
        isNoteChanged(): boolean {
            return this.isFileChanged || this.isTextChanged;
        },
        updateNote: {
            get() {
                return this.tmpNote ?? '';
            },
            set(note: string) {
                if (this.isLoading || this.isLoadingExternal) return;
                this.$store.commit('product/tmpNote', note);
            },
        },
    },
    components: {
        AlertNoteNotSave,
        BtnSolid,
        IconGallery,
        IconFilledCircularClose,
        TextArea,
        TheBtnPicture,
        GeneralAlert,
    },
    watch: {
        product() {
            this.initNote();
        },
    },
    created() {
        this.initNote();
    },
    methods: {
        async initNote() {
            this.localFile = null;
            this.serverFile = null;
            this.$store.commit('product/showAlertNotSaveNote', false);
            this.$store.commit('product/tmpNote', cleanNote(this.product?.note));
            if (!this.product?.noteToShopperPhoto?.length && !this.product?.noteToShopperPhotoFile) return;

            const file = await urlToFile(this.product.noteToShopperPhoto, this.$t('cta.replacement'));
            this.$store.commit('product/tmpFile', this.product?.noteToShopperPhotoFile ?? file);
            this.localFile = file;
            this.serverFile = file;
        },
        closeAlert(): void {
            this.$store.commit('product/showAlertNotSaveNote', false);
        },
        cleanAndBack(): void {
            Promise.resolve(() => {
                this.$router.back();
            }).then(() => {
                this.$store.commit('product/productSelected');
            });
        },
        onFileChange(file: File) {
            this.localFile = file;
            this.$store.commit('product/tmpFile', file);
            if (!this.tmpNote?.length && file) this.showAlertFileRequirements = true;
        },
        onClean(): void {
            this.$store.commit('product/tmpFile', null);
        },
        onAddFile(): void {
            if (!this.isAuth) return this.$store.commit('user/requireAuth', true);
            const fileInput = document.getElementById('file-upload');
            if (!fileInput) return;
            fileInput.click();
        },

        async addNote(isFromAlertNoteUnsaved = false): Promise<void> {
            if (!this.isAuth) return this.$store.commit('user/requireAuth', true);
            try {
                if (!this.isNoteChanged) return;
                if (this.isLoadingExternal || this.isLoading || !this.tmpNote?.length) return;
                if (!this.tmpNote.length) return;
                if (this.isFromModal) return this.$emit('on-save');
                this.isLoading = true;
                const product: ProductModel = { ...this.product };
                product.note = this.tmpNote;
                product.noteToShopperPhotoFile = this.tmpFile;
                product.noteToShopperPhoto = this.tmpFile ? ((await toBase64(this.tmpFile)) as string) : null;
                product.productQuantity = this.quantity || 1;
                this.$store.commit('product/productSelected', product);

                if (this.isExtraItemCart) {
                    this.addProduct(product);
                } else if (this.isCategoryFromList) {
                    this.$store.commit('lists/localCart', {
                        listIds: [this.listIdToAddFromCategories],
                        product: product,
                    });
                } else {
                    await this.$store.dispatch('cart/addEditProduct', {
                        product: product,
                        screen: this.$route.path,
                        section: this.section,
                    });
                    this.$store.commit('cart/products', product);
                }

                if (typeof isFromAlertNoteUnsaved === 'boolean' && isFromAlertNoteUnsaved) {
                    this.$store.commit('product/showAlertNotSaveNote', false);
                    this.cleanAndBack();
                }
            } catch (err) {
                logger('ADD_NOTE', err);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.the-note {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__actions {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $margin-md;
    }
}

.the-note-area {
    position: relative;
    margin: $margin-sm 0;
    box-sizing: border-box;

    &__area {
        background-color: $bright-canvas;
    }

    &__add-photo {
        all: unset;
        display: flex;
        align-items: center;
        gap: $margin-sm;
        border-radius: $radius-lg;
        padding: $padding-sm;
        cursor: pointer;
        box-shadow: 0 1px 2px 0 #00000029;

        & p {
            @include caption;
            color: $orange;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__counter {
        position: absolute;
        bottom: $margin-sm;
        right: $margin-sm;
        @include caption;
    }

    &__counter--limit {
        color: $error;
    }
}
</style>
