class RetailerInProductModel {
    readonly id: number;
    readonly name: string;
    readonly primaryColor: string;
    readonly secondaryColor: string;
    readonly icon: string;
    store: string;

    constructor(id: number, name?: string, primaryColor?: string, secondaryColor?: string, icon?: string, store = '') {
        this.id = id;
        this.name = name;
        this.primaryColor = primaryColor;
        this.secondaryColor = secondaryColor;
        this.icon = icon;
        this.store = store;
    }

    get toAnalytics() {
        return {
            retailer: this.name,
            id: this.id,
            store: this.store,
        };
    }
}

const toRetailerInProductModel = (data: object): RetailerInProductModel => {
    return data
        ? new RetailerInProductModel(
              data['id'],
              data['name'],
              data['primary_color'],
              data['secondary_color'],
              data['icon'],
          )
        : null;
};

export { RetailerInProductModel, toRetailerInProductModel };
