<template>
    <SkeletonGrid v-if="state === 'LOADING'" />
    <template v-else>
        <ProductsGrid v-if="products?.length" :products="products" :section="originSection" />
        <no-content-category v-else />
    </template>
</template>

<script lang="ts" setup>
import NoContentCategory from '@/components/category/noContentCategory/NoContentCategory.vue';
import { logger } from '@/utils/logger';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import ProductsGrid from '@/components/product/productsGrid/ProductsGrid.vue';
import { useProductFetcher } from '@/views/seeMore/useProductFetcher';
import { ref, shallowRef } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import SkeletonGrid from '@/components/skeletons/skeletonGrid/SkeletonGrid.vue';
import { RequestState } from '@/modules/core/types/WorkingState';
import { useProductStore } from '@/store/useProductStore';

defineProps({
    originSection: {
        type: String,
        required: true,
    },
});
const { featuredProducts } = useProductStore();
const { retailerSelected } = useRetailer();
const { categorySelected } = useProductFetcher();
const products = shallowRef<ProductModel[]>([]);
const state = ref<RequestState>('LOADING');

const getProducts = async (): Promise<void> => {
    try {
        products.value = await featuredProducts({
            retailer_id: retailerSelected.value.id,
            category_id: categorySelected.value?.id,
            size: 50,
        });
    } catch (err) {
        logger('FETCH_ALL_FEATURED_PRODUCTS_LISTS', err);
    } finally {
        state.value = 'NONE';
    }
};

getProducts();
</script>
