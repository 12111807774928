<template>
    <teleport to="body">
        <div :class="{ 'only-desktop': onlyDesktop }">
            <div :class="{ 'modal-bg': hasBackground }" :style="style" @click="$emit('on-close')"></div>
            <section
                class="base-modal"
                :class="{
                    'auto-size': autoSize,
                    'auto-size--full-screen': coverAllScreen,
                    'full-modal': isFullModal,
                    'full-modal--full-screen': coverAllScreen,
                    'base-modal--full-screen': coverAllScreen,
                }"
                :style="style"
            >
                <header v-if="hasHeader" class="modal-header" :class="{ 'modal-header--orange': orangeHeader }">
                    <icon-back
                        v-if="hasBackArrow"
                        class="cursor-pointer"
                        background-fill="#fff"
                        color="#606060"
                        @click="$emit('on-back-arrow')"
                    />
                    <div v-else></div>
                    <h1 class="modal-header__title" :style="{ color: headerTextColor }">
                        {{ title }}
                    </h1>
                    <icon-circular-close
                        v-if="hasClose"
                        class="cursor-pointer modal-header__close"
                        :alt="$t('cta.close')"
                        :color="colorIconclose"
                        @click="$emit('on-close')"
                    />
                    <div v-else></div>
                </header>
                <div
                    class="base-modal__body"
                    :class="{
                        'no-scroll': noScroll,
                        'base-modal__body--center': isCentered,
                        'base-modal__body--full-screen': coverAllScreen,
                    }"
                >
                    <slot />
                </div>
            </section>
        </div>
    </teleport>
</template>

<script lang="ts">
import IconBack from '@/components/icons/IconBack.vue';
import IconCircularClose from '@/components/icons/IconCircularClose.vue';

export default {
    name: 'BaseModal',
    components: {
        IconBack,
        IconCircularClose,
    },
    props: {
        onlyDesktop: {
            type: Boolean,
            default: false,
        },
        orangeHeader: {
            type: Boolean,
            default: false,
        },
        headerTextColor: {
            type: String,
            default: '#707070',
        },
        title: {
            type: String,
            default: '',
        },
        autoSize: {
            type: Boolean,
            default: true,
        },
        hasHeader: {
            type: Boolean,
            default: true,
        },
        hasBackArrow: {
            type: Boolean,
            default: false,
        },
        isCentered: {
            type: Boolean,
            default: false,
        },
        hasClose: {
            type: Boolean,
            default: true,
        },
        hasBackground: {
            type: Boolean,
            default: true,
        },
        isFullModal: {
            type: Boolean,
            default: false,
        },
        noScroll: {
            type: Boolean,
            default: false,
        },
        zIndex: {
            type: Number,
            default: 9,
        },
        coverAllScreen: {
            type: Boolean,
            default: false,
        },
        colorIconclose: {
            type: String,
            default: '#606060',
        },
    },
    emits: ['on-close', 'on-back-arrow'],
    computed: {
        style(): object {
            return {
                'z-index': this.zIndex,
            };
        },
    },
    mounted() {
        document.body.style.overflowY = 'hidden';
    },
    unmounted() {
        document.body.style.overflowY = 'auto';
    },
};
</script>

<style lang="scss" scoped>
@import './base-modal.scss';
</style>
