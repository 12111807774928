import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { TiptiAlertModel } from '@/models/alerts/AlertOpenRetailer';

interface AlertModuleInterface {
    alerts: Array<TiptiAlertModel>;
}

const state: AlertModuleInterface = {
    alerts: [],
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
