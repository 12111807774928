import { ref } from 'vue';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';

const currentWork = ref<{
    image: string;
}>();
const isLoading = ref(false);
export const useMaintenance = () => {
    const getPendingWorks = () => {
        if (isLoading.value) return;
        const url = ['DEV', 'STG'].includes(process.env.VUE_APP_API_ENV)
            ? 'https://mantenimientodev.tipti.market/'
            : 'https://mantenimiento.tipti.market/';

        isLoading.value = true;
        axiosInstance(false)
            .get<{
                image: string;
            }>(url)
            .then(({ data }) => {
                currentWork.value = data;
            })
            .catch(() => {
                currentWork.value = undefined;
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        currentWork,
        getPendingWorks,
    };
};
