<template>
    <div class="mobile-header">
        <retailer-information />
        <div class="mobile-header__nav">
            <div
                v-for="(nav, index) in navItems"
                :key="index"
                class="mobile-header__item"
                @click.stop="selectNav(nav?.path)"
            >
                <div class="mobile-header__item__icon-container">
                    <component :is="nav.component" :size="nav.size" color="#606060"></component>
                </div>
                <h3>{{ nav.name }}</h3>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import RetailerInformation from './RetailerInformation.vue';
import IconCategory from '@/components/icons/IconCategory.vue';
import IconMyLists from '@/components/icons/IconMyLists.vue';
import IconCoupons from '@/components/icons/IconCoupons.vue';
import { mapGetters } from 'vuex';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'all-categories-mobile-header',
    components: {
        RetailerInformation,
        IconCategory,
        IconMyLists,
        IconCoupons,
    },
    computed: {
        ...mapGetters({
            isAuth: 'user/isAuth',
        }),
    },
    data() {
        return {
            navItems: [
                {
                    name: this.$t("cta['categories']"),
                    component: IconCategory.name,
                    size: 0.5,
                },
                {
                    name: this.$t("cta['coupons']"),
                    path: appRoutesMap.coupons.index,
                    component: IconCoupons.name,
                    size: 0.8,
                },
                {
                    name: this.$t("cta['lists-and-recipes']"),
                    path: appRoutesMap.list.personalLists,
                    component: IconMyLists.name,
                    size: 0.6,
                },
            ],
        };
    },
    methods: {
        selectNav(route: string | null): void {
            if (!route) return this.$store.commit('categories/isMobileCategoryMenuOpen', true);
            if (!this.isAuth) return this.$store.commit('user/requireAuth', true);
            this.$router.push({ name: route });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.mobile-header {
    display: none;
    padding-bottom: $padding-md;
    @include box-glow-new;
    @include tablet {
        display: block;
    }

    &__nav {
        display: flex;
        justify-content: space-around;
    }

    &__item {
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        align-items: center;

        h3 {
            @include subtitle2;
            @include tablet {
                font-size: 14px;
            }
        }

        &__icon-container {
            width: 2.5rem;
            display: flex;
            justify-content: center;
        }
    }
}
</style>
