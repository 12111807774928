import { CartBarGoals, CartBarGoalsDto } from '@/models/cart/CartBarGoals';
import { BaseGoal, BaseGoalDto } from '@/models/cart/CartBaseGoal';

export class CartGoals {
    productGoals: CartBarGoals[];
    cardGoal: BaseGoal;

    constructor(data: CartGoalsDto[]) {
        const allGoals = data?.length
            ? data
                  .map(goal => {
                      return {
                          productGoal:
                              goal.goals?.map(
                                  internalGoal =>
                                      new CartBarGoals({
                                          ...internalGoal,
                                          retailer_id: goal.retailer_id,
                                      }),
                              ) ?? [],
                          cardGoal: goal?.card_goals
                              ? new BaseGoal({
                                    ...goal.card_goals,
                                })
                              : null,
                      };
                  })
                  .flat()
            : [];
        this.productGoals = allGoals?.map(goal => goal.productGoal)?.flat() ?? [];
        this.cardGoal =
            allGoals
                ?.map(goal => goal.cardGoal)
                ?.flat()
                ?.find(goal => Boolean(goal)) ?? null;
    }
}

interface CartGoalsDto {
    retailer_id: number;
    goals: Omit<CartBarGoalsDto, 'retailer_id'>[];
    card_goals: BaseGoalDto;
}
