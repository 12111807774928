<template>
    <base-icon :icon-name="label" :width="width" :height="height" viewBox="0 0 38 38" :iconColor="color">
        <path
            d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38Z"
            :fill="backgroundFill"
        />
        <path
            d="M21.0032 27.9508C20.5714 27.9508 20.1396 27.7781 19.7941 27.4326L12.4532 20.0917C11.7623 19.4008 11.7623 18.3645 12.4532 17.6735L19.7941 10.3326C20.485 9.64173 21.5214 9.64173 22.2123 10.3326C22.9032 11.0235 22.9032 12.0599 22.2123 12.7508L16.0805 18.8826L22.2123 25.0145C22.9032 25.7054 22.9032 26.7417 22.2123 27.4326C21.8668 27.7781 21.435 27.9508 21.0032 27.9508Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-back-no-border',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
        backgroundFill: {
            type: String,
            default: '#FFF',
        },
        iconName: {
            type: String,
        },
    },
    computed: {
        width(): number {
            return 30 * this.size;
        },
        height(): number {
            return 30 * this.size;
        },
        label(): string {
            return this.iconName ?? this.$t('icon.back');
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
