<template>
    <base-icon
        :icon-name="$t('icon.password_recovery')"
        :width="width"
        :height="height"
        viewBox="0 0 151 151"
        :iconColor="color"
    >
        <g clip-path="url(#clip0)">
            <path
                d="M62.6125 44.4609H97.6283C108.038 44.4609 116.556 52.9783 116.556 63.3884V82.4736C116.556 92.8837 108.038 101.401 97.6283 101.401H62.6125C52.2024 101.401 43.6851 92.8837 43.6851 82.4736V63.3884C43.6851 52.9783 52.2024 44.4609 62.6125 44.4609Z"
                fill="#FF9012"
            />
            <path
                d="M27.5966 103.609H134.852C143.527 103.609 150.625 110.707 150.625 119.382V134.524C150.625 143.199 143.527 150.297 134.852 150.297H27.5966C18.9215 150.297 11.8237 143.199 11.8237 134.524V119.382C11.8237 110.707 18.9215 103.609 27.5966 103.609Z"
                fill="#CFDE00"
            />
            <path
                d="M106.461 120.169C106.461 122.062 108.038 123.482 109.931 123.639C111.824 123.639 113.243 122.062 113.401 120.169C113.401 118.277 111.824 116.857 109.931 116.699C108.038 116.699 106.461 118.277 106.461 120.169Z"
                fill="#606060"
            />
            <path
                d="M58.9849 120.169C58.9849 122.062 60.5622 123.482 62.4549 123.639C64.3476 123.639 65.7672 122.062 65.9249 120.169C65.9249 118.277 64.3476 116.857 62.4549 116.699C60.4044 116.699 58.9849 118.277 58.9849 120.169Z"
                fill="#606060"
            />
            <path
                d="M50.1517 99.3501H98.259C106.619 99.3501 113.559 92.5677 113.559 84.0504V52.3469C113.559 43.9873 106.776 37.0472 98.259 37.0472H94.789V20.328C94.789 14.9652 92.5808 9.76018 88.7953 6.13242C84.8521 2.1892 79.647 0.138728 74.2842 0.138728C63.0855 -0.0190005 53.7795 9.12926 53.6218 20.328V37.0472H50.1517C41.7921 37.0472 34.8521 43.8296 34.8521 52.3469V84.2081C34.8521 92.5677 41.6344 99.3501 50.1517 99.3501ZM60.4041 20.328C60.5618 12.757 66.7132 6.76333 74.2842 6.92106C81.8552 6.76333 88.0066 12.757 88.1644 20.328V37.0472H60.4041V20.328ZM41.6344 52.3469C41.6344 47.6151 45.4199 43.8296 50.1517 43.8296H98.4167C103.149 43.8296 106.934 47.6151 106.934 52.3469V84.2081C106.934 88.94 103.149 92.7255 98.4167 92.7255H50.1517C45.4199 92.7255 41.6344 88.94 41.6344 84.2081V52.3469Z"
                fill="#606060"
            />
            <path
                d="M35.1675 120.169C35.1675 122.062 36.7448 123.482 38.6375 123.639C40.5303 123.639 41.9498 122.062 42.1075 120.169C42.1075 118.277 40.5303 116.857 38.6375 116.699C36.587 116.699 35.1675 118.277 35.1675 120.169Z"
                fill="#606060"
            />
            <path
                d="M135.483 92.7266H129.489V99.5089H135.483C138.48 99.5089 141.004 102.033 141.004 105.029V134.998C141.004 137.995 138.48 140.518 135.483 140.518H12.9278C9.93099 140.518 7.40733 137.995 7.40733 134.998V105.029C7.40733 102.033 9.93099 99.5089 12.9278 99.5089H21.6029V92.7266H12.9278C6.1455 92.7266 0.625 98.2471 0.625 105.029V134.998C0.625 141.78 6.1455 147.301 12.9278 147.301H135.483C142.265 147.301 147.786 141.78 147.786 134.998V105.029C147.786 98.2471 142.265 92.7266 135.483 92.7266Z"
                fill="#606060"
            />
            <path
                d="M82.644 120.169C82.644 122.062 84.2213 123.482 86.1141 123.639C88.0068 123.639 89.4264 122.062 89.5841 120.169C89.5841 118.277 88.0068 116.857 86.1141 116.699C84.2213 116.699 82.644 118.277 82.644 120.169Z"
                fill="#606060"
            />
            <path
                d="M74.2845 71.4323C76.1772 71.4323 77.5968 69.855 77.7545 67.9622C77.7545 66.0695 76.1772 64.6499 74.2845 64.4922C72.3917 64.4922 70.9722 66.0695 70.8145 67.9622C70.8145 70.0127 72.3917 71.4323 74.2845 71.4323Z"
                fill="#606060"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="150" height="150.158" fill="white" transform="translate(0.625 0.140625)" />
            </clipPath>
        </defs>
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'icon-recovery-pass',
    components: {
        BaseIcon,
    },
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
    },
    computed: {
        width(): number {
            return 77 * this.size;
        },
        height(): number {
            return 49 * this.size;
        },
    },
};
</script>
