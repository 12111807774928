import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31f98d8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "validate-card" }
const _hoisted_2 = { class: "validate-card__mobile-title" }
const _hoisted_3 = { class: "validate-card__benefits" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_shield_check = _resolveComponent("icon-shield-check")!
  const _component_alert_manager = _resolveComponent("alert-manager")!

  return (_openBlock(), _createBlock(_component_alert_manager, {
    "is-disabled": false,
    "is-loading": false,
    "show-bottom-sheet-title": false,
    "show-buttons": true,
    "only-confirmation-btn": true,
    title: $data.title,
    "accept-button-text": _ctx.$t('cta.exit'),
    "btn-x-padding": 1,
    "btn-y-padding": 1,
    onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close'))),
    onOnAccept: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close'))),
    onOnDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close'))),
    onOnCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-close')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon_shield_check, {
          size: 2,
          class: "validate-card__img"
        }),
        _createElementVNode("h2", _hoisted_2, _toDisplayString($data.title), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('txt.credit-card__benefit-message')), 1)
      ])
    ]),
    _: 1
  }, 8, ["title", "accept-button-text"]))
}