<template>
    <transition-horizontal-fade>
        <base-card
            v-if="showSnackRate && order"
            class="notification-rate"
            padding="0"
            trail-color="#ff9012"
            @click="showModalRate = true"
        >
            <div class="notification-rate__content">
                <figure-profile-rate />
                <aside class="notification-rate__info">
                    <h2 class="notification-rate__title">
                        {{ $t('txt.rate-shopper__notification-rate-your-experience') }}
                    </h2>
                    <div class="notification-rate__detail">
                        <h3 class="notification-detail__subtitle">
                            {{ $t('general.order') }} <span>#{{ order.id }}</span>
                        </h3>
                        <h3 class="notification-detail__subtitle">
                            {{ $t('general.date') }}:
                            <span
                                v-date="{
                                    date: order.delivery,
                                    format: 'DD MMM YYYY',
                                }"
                            />
                        </h3>
                    </div>
                </aside>
            </div>
            <button class="btn-skip" @click.stop="skipRatingOrder" v-text="$t('cta.skip-rate-shopper')" />
        </base-card>
    </transition-horizontal-fade>
</template>
<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import FigureProfileRate from '../components/figureProfileRate/FigureProfileRate.vue';
import TransitionHorizontalFade from '@/modules/core/components/transition/TransitionHorizontalFade.vue';
import { useApp } from '@/composables/useApp';
import { useOrder } from '@/composables/useOrder';
import { ref, shallowRef, watch } from 'vue';
import { OrderModel } from '@/models/order/OrderModel';
import { logger } from '@/utils/logger';

const { notifier, $t } = useApp();
const { skipRatingOrder: skipRate, showModalRate, showSnackRate, orderToRate: order, getOrderToRate } = useOrder();
const orders = shallowRef<OrderModel[]>([]);
const isSendingRate = ref(false);

watch(
    () => order.value?.id,
    (value) => {
        if (value) return;
        getOrdersToRate();
    },
);
const getOrdersToRate = async () => {
    try {
        const result = await getOrderToRate();
        orders.value = result.results ?? [];
        if (!orders.value.length) return;
        setTimeout(() => {
            order.value = result.results?.at(0);
            showSnackRate.value = !!order.value;
        }, 250);
    } catch (e) {
        logger('get rate order snack', e);
    }
};

const skipRatingOrder = async () => {
    try {
        if (isSendingRate.value) return;
        isSendingRate.value = true;
        await skipRate();
        order.value = undefined;
        getOrdersToRate();
        notifier({
            type: 'INFO',
            body: $t('alerts.shopper_skip_success'),
        });
    } catch (e) {
        order.value = undefined;

        notifier({
            type: 'ERROR',
            body: $t('alerts.shopper_rate_error'),
            title: $t('alerts.error'),
        });
    } finally {
        isSendingRate.value = false;
    }
};

getOrdersToRate();
</script>
<style lang="scss" scoped>
@import './notification-rate';
</style>
