import { RetailerFilter } from '@/modules/retailers/models/RetailerFilterModel';

export const CF_DOMAIN = 'https://www.supermaxi.com';
export const CF_AFFILIATION_CHANEL = 'CF_NOTIFICATION';
export const CF_AFFILIATION_ACTION = 'BTN_UPDATE_CLICKED';
export const RETAILER_FILTER = {
    id: 100001,
    retailer_type: 'retailer_filter_all_store',
    name: {
        ES: 'retailer_filter_all_store',
        EN: 'retailer_filter_all_store',
    },
    active_icon: '',
    inactive_icon: '',
    header_image: '',
    isAllRetailer: true,
} satisfies RetailerFilter;
