<template>
    <div class="product-main__detail">
        <h3 class="product-detail-data__name">
            {{ product.name }}
        </h3>
        <p v-if="product.groupId && attributeName.length" class="product-detail-data__unit">
            {{ attributeName }}: {{ attributeValue }}
        </p>
        <p v-else class="product-detail-data__unit">{{ product.unitQuantity }} {{ product.unit }}</p>
        <button v-if="product.shoppingBag" class="product-detail-data__link" @click="changeCambrellas">
            {{ cambrellaSubscriptionText }}
        </button>
        <template v-if="canShowNote">
            <button class="product-detail-data__action" @click.stop="showNoteModal">
                <icon-note
                    :color="getColorNote"
                    :icon-name="getColorNote === '#707070' ? $t('icon.save_note') : $t('icon.show_note')"
                    :size="0.8"
                />
                <span :style="{ color: getColorNote }">
                    {{ hasNote ? $t("txt['note__edit-note']") : $t("cta['add-note']") }}
                </span>
            </button>
        </template>
    </div>
    <cambrella v-if="product.shoppingBag && showCambrellasModal" @on-close="closeCambrellas" />
    <note-modal
        v-if="showNote"
        :initial-product="product"
        :shopper-note-placeholder="retailer?.shopperNotePlaceholder"
        :shopper-note-title="retailer?.shopperNoteTitle"
        @on-close="showNote = false"
    />
</template>
<script lang="ts">
import IconNote from '@/components/icons/IconNote.vue';
import { cleanNote } from '@/utils/productUtils';
import NoteModal from '@/components/modal/noteModal/NoteModal.vue';
import { ProductModel } from '@/models/product/ProductModel';
import { mapGetters } from 'vuex';
import { RetailerModel } from '@/modules/retailers/models/RetailerModel';
import { useProductAttribute } from '@/composables/ProductAttributesComposable';
import Cambrella from '@/modules/cambrella/components/Cambrella.vue';
import { useCambrellas } from '@/modules/cambrella/composables/useCambrellas';

export default {
    name: 'ProductCartDetail',
    components: { Cambrella, NoteModal, IconNote },
    props: {
        product: {
            type: [ProductModel, Object],
            required: true,
        },
        retailer: {
            type: [RetailerModel, Object],
            required: true,
        },
    },
    setup(props) {
        const { hasCambrellaSubscription, cambrellaMembership } = useCambrellas();

        return {
            ...useProductAttribute(props.product as ProductModel),
            hasCambrellaSubscription,
            cambrellaMembership,
        };
    },
    data() {
        return {
            showCambrellasModal: false,
            showNote: false,
        };
    },
    computed: {
        ...mapGetters({
            userLanguage: 'user/userLanguage',
            productsForSegment: 'cart/productsForSegment',
            cart: 'cart/cart',
        }),
        canShowNote(): boolean {
            if (this.product?.isSampling) return false;
            return !this.product?.mandatoryItem;
        },
        getColorNote(): string {
            return this.hasNote ? '#F03F51' : '#707070';
        },
        hasNote(): boolean {
            return !!cleanNote(this.product?.note)?.length;
        },
        cambrellaSubscriptionText(): string {
            if (!this.hasCambrellaSubscription) return this.$t('txt.cambrellas__txt_no_subscription');
            return this.$t('txt.cambrellas__txt_subscription');
        },
    },
    methods: {
        changeCambrellas() {
            this.showCambrellasModal = true;
            if (this.hasCambrellaSubscription) {
                this.$store.dispatch('cambrellas/cambrellaMembership');
            }
            this.sendCambrellasAnalyticss();
        },
        showNoteModal() {
            this.$store.commit('product/tmpFile');
            this.$store.commit('product/tmpNote');
            this.showNote = true;
        },
        closeCambrellas() {
            this.showCambrellasModal = false;
        },
        sendCambrellasAnalyticss(): void {
            if (!this.hasCambrellaSubscription) {
                this.$store.dispatch('segment/openCambrellasSubscription', {
                    products: this.productsForSegment,
                    cart: {
                        cart: this.cart?.name,
                        cart_id: this.cart?.id?.toString(),
                    },
                });
                return;
            }
            this.$store.dispatch('segment/cambrellaStatus', {
                cambrellas: this.cambrellaMembership,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.product-main__detail {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.product-detail-data {
    width: 100%;
    &__name {
        @include caption;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    &__unit {
        @include overline;
        margin: $margin-xsm $margin-none;
    }
    &__link {
        all: unset;
        @include overline;
        cursor: pointer;
        color: $orange;
        text-decoration: underline;
    }
    &__action {
        all: unset;
        cursor: pointer;
        display: flex;
        align-items: center;
        & span {
            @include overline;
            margin-left: $margin-sm;
        }
    }
}
</style>
