import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-788b1098"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subscription-empty" }
const _hoisted_2 = { class: "subscription-empty__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_icon_subscriptions = _resolveComponent("icon-subscriptions")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_section_header, {
      "color-title": "#000",
      title: _ctx.$t('general.subscriptions')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_icon_subscriptions, {
        size: 3,
        color: "#c1c1c1"
      }),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('txt.subscription__not_selected')), 1)
    ])
  ]))
}