class BaseOrderState {
    name: string;
    primaryColor: string;
    secondaryColor: string;
    description: string;
    txtColor: string;

    constructor(name: string, primaryColor: string, secondaryColor: string, description: string, txtColor: string) {
        this.name = name;
        this.primaryColor = primaryColor;
        this.secondaryColor = secondaryColor;
        this.description = description;
        this.txtColor = txtColor;
    }
}

export { BaseOrderState };
