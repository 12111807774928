import { createApp } from 'vue';
import router from './router';
import store from './store';
import i18 from './vi18/i18';
import './registerServiceWorker';
import GoogleAuth from 'vue3-google-oauth2';
import { createHead } from '@unhead/vue';
import App from './App.vue';
import dayjs from 'dayjs';
import { logger } from '@/utils/logger';
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';

const app = createApp(App);

app.use(store);
app.use(
    createPinia().use(
        createPersistedState({
            auto: true,
            storage: localStorage,
        }),
    ),
);
app.use(router);
app.use(i18);
app.use(createHead());
app.use(GoogleAuth, {
    clientId: process.env.VUE_APP_GOOGLE_AUTH,
});

app.directive('date', (el, binding) => {
    // *** usage
    // <p v-date="{ date: scheduleToRender.date, format: 'dddd D' } />
    // <p v-date.upper="{ date: scheduleToRender.date, format: 'dddd D' } />
    // <p v-date="date" />
    // <p v-date.upper="date" />
    const profile = store.getters['user/user']?.defaultLanguage ?? 'es';
    const value = typeof binding.value === 'string' ? binding.value : binding.value['date'];
    const format = typeof binding.value === 'string' ? 'DD/MM/YYYY' : binding.value['format'];
    const upper = !!binding.modifiers['upper'];
    const title = !!binding.modifiers['title'];
    const date = dayjs(value).locale(profile).format(format);
    el.innerHTML = upper ? date.toUpperCase() : title ? date.at(0).toUpperCase().concat(date.substring(1)) : date;
});
app.directive('fixed', (el, binding) => {
    // *** usage
    // <div v-fixed:2="value"></div>
    if (binding.value === binding.oldValue) return;
    const fixed = binding.arg ?? 2;
    el.innerHTML = binding.value.toFixed(+fixed);
});
app.directive('currency', (el, binding) => {
    /// *** usage
    /// <p v-currency="availableAmount" />
    const currency = store.getters['external/countrySelected']?.currencySymbol ?? '$';
    if (binding.value === binding.oldValue) return;
    const fixed = !binding.modifiers['noFix'];
    const sup = !!binding.modifiers['sup'];
    const spaced = !binding.modifiers['noSpaced'];
    const value = isNaN(+binding?.value) ? 0 : +binding?.value;
    const finalValue = value.toFixed(fixed ? 2 : 0);

    if (!sup) {
        el.innerHTML = spaced ? `${currency} ${finalValue}` : `${currency}${finalValue}`;
        return;
    }
    const [integers, decimals] = finalValue.split('.');
    const supper = `<sup>${decimals}</sup>`;

    el.innerHTML = spaced ? `${currency} ${integers}.${supper}` : `${currency}${integers}.${supper}`;
});
app.directive('translate', (el, { value, modifiers }) => {
    const language = store.getters['user/userLanguage'] ?? 'es';
    if (!value) return;
    if (typeof value === 'string') {
        el.innerHTML = value;
        return;
    }
    let finalValue = '';
    if (value[language]) finalValue = value[language];
    if (!finalValue.length) {
        if (language === 'es') finalValue = value.ES;
        if (language === 'en') finalValue = value.EN;
    }
    el.innerHTML = !finalValue ? '' : finalValue;
});
app.directive('upper', (el, { value }) => {
    el.innerHTML = typeof value === 'string' ? value?.toUpperCase() ?? '' : value;
});
app.config.performance = true;
app.config.errorHandler = (err, instance, info) => {
    logger('errorHandler', { err, info, instance });
};
router.isReady().then(() => app.mount('#app'));
