<template>
    <section class="promotion-categories">
        <section-header class="promotion-categories__header" :title="title" has-back @on-back="$router.back()" />
        <list-subcategories-body
            v-if="listSubcategories"
            class="promotion-categories-list"
            :subcategories="listSubcategories"
            @on-select-subcategory="openSubcategory"
        />
        <main class="categories-body">
            <h1 :style="{ color: titleColor ?? '#FF9012' }">
                {{ title }}
            </h1>
            <base-loading v-if="requestState === 'LOADING'" />
            <div v-for="(subcategory, index) in subcategories" :key="subcategory.id">
                <section v-if="index === 0" :key="subcategory?.id" class="picture-category__display-flex">
                    <base-card v-if="picture" class="card__center-category">
                        <img :alt="title" :src="picture" loading="lazy" />
                    </base-card>
                    <section>
                        <CarouselForProducts
                            :items="subcategory.stockitems"
                            :subheader-name="subcategory.name"
                            section="HOME_RECOMMENDATIONS"
                            state="LOAD-ENDED"
                            @on-click-subheader="openSubcategory(subcategory)"
                        />
                    </section>
                </section>
                <section v-else>
                    <CarouselForProducts
                        :items="subcategory.stockitems"
                        :subheader-name="subcategory.name"
                        section="HOME_RECOMMENDATIONS"
                        state="LOAD-ENDED"
                        @on-click-subheader="openSubcategory(subcategory)"
                    />
                </section>
            </div>
            <div v-if="requestState === 'NONE'" ref="productsRef" />
        </main>
    </section>
</template>

<script lang="ts" setup>
import { PromotionsCategoriesModel } from '@/models/promotions/PromotionsCategoriesModel';
import { logger } from '@/utils/logger';
import ListSubcategoriesBody from '@/components/category/listSubcategories/ListSubcategoriesBody.vue';
import { Identifiers } from '@/enums/identifiers';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import BaseCard from '@/components/cards/BaseCard.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useApp } from '@/composables/useApp';
import { computed, ref } from 'vue';
import CarouselForProducts from '@/components/carousels/CarouselForProducts.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import { useIntersectionObserver } from '@vueuse/core';
import { RequestState } from '@/modules/core/types/WorkingState';

const props = defineProps({
    fetchString: {
        type: String,
        required: true,
    },
});

const { retailerSelected } = useRetailer();
const { $router, $store, $route, $t } = useApp();

const page = ref(1);
const subcategories = ref<PromotionsCategoriesModel[]>([]);
const listSubcategories = ref<PromotionsCategoriesModel[]>([]);
const requestState = ref<RequestState>('NONE');
const isBannerInSection = computed<boolean>(() => $store.getters['promotions/isBannerInSection']);
const title = computed<string>(() => `${$route.params?.title}`);
const titleColor = computed<string>(() => `#${$route.query?.titleColor}`);
const picture = computed<string>(() => `${$route.query?.picture}`);
const productsRef = ref<HTMLElement>();

const { stop } = useIntersectionObserver(productsRef, async ([{ isIntersecting }]) => {
    if (!isIntersecting) return;
    await fetchSubcategories();
});

const openSubcategory = (subcategory: PromotionsCategoriesModel): void => {
    if (!subcategory) return;
    $router.push({
        name: appRoutesMap.retail.seeMore,
        params: {
            retailer: retailerSelected.value?.name,
        },
        query: {
            name: subcategory.name,
            categoryId: subcategory.categoryId,
            subCategoryId: subcategory.id,
        },
    });
};

const fetchSubcategories = async (): Promise<void> => {
    if (
        !$route.params?.id ||
        requestState.value === 'LOADING' ||
        requestState.value === 'LOAD-ENDED' ||
        requestState.value === 'ERROR'
    )
        return;
    requestState.value = 'LOADING';
    try {
        const { results, next } = await $store.dispatch(props.fetchString, {
            id: $route.params?.id,
            isBannerInSection: isBannerInSection.value,
            page: page.value,
            retailerId: retailerSelected.value.id,
        });

        if (!listSubcategories.value?.length) {
            listSubcategories.value = [
                new PromotionsCategoriesModel(Identifiers.ALL, $t('txt.subcategories__all_products'), null, null, null),
            ];
        }
        page.value++;
        requestState.value = next ? 'NONE' : 'LOAD-ENDED';
        subcategories.value = [...subcategories.value, ...results];
        listSubcategories.value = [...listSubcategories.value, ...results];
    } catch (err) {
        logger('FETCH_SUBCATEGORIES', err);
        requestState.value = 'ERROR';
    } finally {
        if (requestState.value === 'LOAD-ENDED' || requestState.value === 'ERROR') stop();
    }
};

fetchSubcategories();
</script>

<style lang="scss" scoped>
@import './promotion-categories.scss';
</style>
