<template>
    <base-icon
        :icon-name="$t('icon.validated_card')"
        :width="width"
        :height="height"
        viewBox="0 0 15 16"
        :iconColor="color"
    >
        <path
            d="M5.9278 6.53385C5.61431 6.21619 5.10133 6.21619 4.78785 6.53385C4.47436 6.85151 4.47436 7.37132 4.78785 7.68897L6.18429 9.104C6.32678 9.24839 6.55477 9.33503 6.75426 9.33503C6.95375 9.33503 7.18174 9.24839 7.32423 9.104L10.0031 6.38946C10.3166 6.0718 10.3166 5.552 10.0031 5.23434C9.68963 4.91668 9.17665 4.91668 8.86317 5.23434L6.75426 7.37132L5.9278 6.53385Z"
            :fill="color"
        />
        <path
            d="M7.83491 1.0175L7.835 1.0174L7.83044 1.01367C7.60416 0.828775 7.27583 0.828775 7.04956 1.01367L7.04947 1.01357L7.04509 1.0175C7.04509 1.0175 7.04501 1.01756 7.04483 1.0177C7.02211 1.03523 5.47405 2.22928 1.25428 3.7466C1.02705 3.82172 0.875 4.02877 0.875 4.24776C0.875 11.8646 6.88262 14.9307 7.15934 15.0551C7.22929 15.0865 7.33096 15.125 7.44 15.125C7.5413 15.125 7.62669 15.1057 7.72612 15.0525C8.01888 14.911 14.005 11.8404 14.005 4.24776C14.005 4.02873 13.8529 3.82164 13.6256 3.74656C9.4203 2.2432 7.85667 1.03433 7.83513 1.01768L7.83491 1.0175ZM12.8187 4.62889C12.6519 10.4119 8.57108 13.2591 7.44001 13.9412C6.30983 13.2593 2.24398 10.4121 2.06152 4.62879C5.13457 3.50061 6.75443 2.55019 7.43893 2.11394C8.12472 2.56352 9.74544 3.50088 12.8187 4.62889Z"
            :fill="color"
            :stroke="color"
            stroke-width="0.25"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-shield-check',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#CFDE00',
        },
    },
    computed: {
        width(): number {
            return 18 * this.size;
        },
        height(): number {
            return 18 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
