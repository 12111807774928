<template>
    <section class="nequi-payment">
        <h2 class="nequi-payment__title">{{ $t('txt.payment-nequi__title') }}</h2>
        <p class="nequi-payment__description">{{ $t('txt.payment-nequi__description') }}</p>
        <div class="nequi-payment__content">
            <div class="number-box" type="text">{{ phoneCode }}</div>
            <input
                v-model="inputNumber"
                class="nequi-payment__input number-box"
                :placeholder="inputPlaceholder"
                type="text"
                @keypress="onKeyPress"
            />
        </div>
        <div v-show="isPhoneNumberListNotEmpty" class="nequi-payment__phone-numbers-list">
            <p>{{ $t('txt.payment-nequi__phone-numbers-list') }}</p>
            <base-card-flat
                v-for="phoneNumber in phoneNumbersArray"
                :key="phoneNumber"
                class="nequi-payment__list-item"
                :border-color="phoneNumber == selectedNumber ? '#FF9012' : '#C7C7C7'"
                @click="onOptionSelected(phoneNumber)"
            >
                <icon-nequi
                    class="nequi-payment__icon"
                    :color="phoneNumber == selectedNumber ? '#FF9012' : '#606060'"
                    :size="1.1"
                ></icon-nequi>
                {{ phoneNumber }}
            </base-card-flat>
        </div>
    </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import BaseCardFlat from '@/components/cards/BaseCardFlat.vue';
import IconNequi from '@/components/icons/IconNequi.vue';

import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import NequiPaymentModel from '@/models/checkout/NequiPaymentModel';

const INPUT_MAX_LENGTH = 50;

export default {
    name: 'NequiPayment',
    components: {
        BaseCardFlat,
        IconNequi,
    },
    data() {
        return {
            phoneCode: '',
            isLoading: false,
            inputNumber: '',
            phoneNumbersArray: [],
            selectedNumber: '',
        };
    },
    computed: {
        ...mapGetters({
            isMixInstitutionalPayment: 'payments/isMixInstitutionalPayment',
            isMixPaymentTiptiCard: 'payments/isMixPaymentTiptiCard',
            secondPayment: 'payments/secondPayment',
            totalToPay: 'payments/totalToPay',
            userProfile: 'user/user',
        }),
        inputPlaceholder(): string {
            return this.$t('txt.payment-nequi__input-placeholder');
        },
        isPhoneNumberListNotEmpty() {
            return this.phoneNumbersArray?.length > 0;
        },
    },
    watch: {
        inputNumber(newValue, _) {
            if (newValue === '') {
                this.$store.commit('payments/cleanPaymentMethods');
                return;
            }
            this.setNequiPhoneNumberAsPayment(this.addCountryCodeToNumber(newValue));
            this.selectedNumber = '';
        },
    },
    async created() {
        this.$store.commit('payments/cleanPaymentMethods');
        this.phoneNumbersArray = await this.$store.dispatch('payments/getNequiPhoneNumbersList');
        this.phoneCode = '+' + this.userProfile.currentAddress.city.country.code;
    },
    methods: {
        onKeyPress(event: KeyboardEvent) {
            const pressedKey = event.key;
            const isPressedKeyANumber = /[0-9]/.test(pressedKey);
            const hasInputReachedMaxLength = this.inputNumber.length >= INPUT_MAX_LENGTH;
            if (isPressedKeyANumber && !hasInputReachedMaxLength) {
                return;
            }
            event.preventDefault();
        },
        totalToPayNequi(): number {
            return this.isMixPaymentTiptiCard || this.isMixInstitutionalPayment
                ? +this.secondPayment
                : +this.totalToPay;
        },
        setNequiPhoneNumberAsPayment(phoneNumber) {
            this.setPaymentToShow(phoneNumber);
            const nequiObject = new NequiPaymentModel(phoneNumber);
            this.$store.commit('payments/nequiPayment', nequiObject);
        },
        setPaymentToShow(phoneNumber) {
            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-nequi',
                    amount: this.totalToPayNequi(),
                    data: phoneNumber,
                    analyticData: {
                        selected_number: phoneNumber,
                    },
                }),
            );
        },
        onOptionSelected(phoneNumber: object): void {
            this.selectedNumber = phoneNumber;
            this.setNequiPhoneNumberAsPayment(phoneNumber);
        },
        addCountryCodeToNumber(number: number | string) {
            return this.phoneCode + number;
        },
        isTileSelected(phoneNumber): boolean {
            return phoneNumber == this.selectedNumber;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './nequi-payment.scss';

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $bright-grey;
    opacity: 1; /* Firefox */
}
</style>
