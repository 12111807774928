import { InteractiveModel, toInteractiveModel } from './InteractiveModel';

class InteractiveElementModel {
    id: number;
    elements: Array<InteractiveModel>;
    available: boolean;
    text: string;
    textColor: string;
    textBackgroundColor: string;
    interactionType: string;

    constructor(
        id: number,
        elements: Array<InteractiveModel>,
        available: boolean,
        text: string,
        textColor: string,
        textBackgroundColor: string,
        interactionType: string,
    ) {
        this.id = id;
        this.elements = elements;
        this.available = available;
        this.text = text;
        this.textColor = textColor;
        this.textBackgroundColor = textBackgroundColor;
        this.interactionType = interactionType;
    }
}

const toInteractiveElementModel = (data: object) => {
    return data
        ? new InteractiveElementModel(
              data['id'],
              data['elements'] ? data['elements'].map((element: InteractiveModel) => toInteractiveModel(element)) : [],
              data['available'] ?? false,
              data['text'] ?? '',
              data['text_color'] ?? '',
              data['text_background_color'] ?? '',
              data['interaction_type'] ?? '',
          )
        : null;
};

export { InteractiveElementModel, toInteractiveElementModel };
