<template>
    <aside class="minimum-amount" v-if="missingAmount > 0">
        <p class="minimum-amount__legend">
            {{ $t('txt.minimum-amount__this-store') }} {{ currency }} {{ minimumAmount }}
            {{ $t('txt.minimum-amount__add') }}
            <span class="minimum-amount__missing">{{ currency }} {{ missingAmount.toFixed(2) }}</span>
            {{ $t('txt.minimum-amount__to-process') }}
        </p>
        <progress-bar :minimumAmount="minimumAmount" :progress="progress"></progress-bar>
    </aside>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import ProgressBar from '@/components/progressBar/ProgressBar.vue';
export default {
    name: 'minimum-amount',
    components: { ProgressBar },
    props: {
        minimumAmount: {
            type: Number,
            default: 0,
        },
        totalToPay: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        missingAmount(): number {
            return this.totalToPay > this.minimumAmount ? 0 : this.minimumAmount - this.totalToPay;
        },
        progress(): number {
            return Math.abs((this.missingAmount * 100) / this.minimumAmount - 100);
        },
        ...mapGetters({
            countrySelected: 'external/countrySelected',
        }),
        currency() {
            return this.countrySelected?.currencySymbol ?? '$';
        },
    },
};
</script>

<style lang="scss" scoped>
@import './minimum-amount.scss';
</style>
