<template>
    <section>
        <section-header :has-back="true" :title="programSelected.name" @on-back="$router.go(-1)" />
        <header-program />
        <div class="program-placeholder">
            <img class="program-placeholder__img" :src="pageLabel.image" alt="" />
            <h3 class="program-placeholder__text">
                {{ pageLabel.description }}
            </h3>
            <btn-solid :txt="pageLabel.callToActionSuccess" @click="changeToVerificationScreen" />
        </div>
    </section>
</template>
<script lang="ts">
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { mapGetters } from 'vuex';
import HeaderProgram from '@/views/myAccount/views/profile/views/loyaltyPrograms/HeaderProgram.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import { appRoutesMap } from '@/router/appRoutesMap';

export default {
    name: 'placeholder-screen',
    components: { SectionHeader, HeaderProgram, BtnSolid },

    computed: {
        ...mapGetters({
            programSelected: 'loyaltyAndRewards/program',
        }),
        pageLabel() {
            return this.programSelected.customLabelStructure.find(
                (labels) => labels.pageName === 'place_holder_screen',
            );
        },
    },
    methods: {
        changeToVerificationScreen() {
            this.$router.push({
                name: appRoutesMap.reward.validationScreen,
                params: {
                    id: this.programSelected.id,
                },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'placeholder-screen';
</style>
