import { computed } from 'vue';

import { useStore } from 'vuex';
import { ProductModel } from '@/models/product/ProductModel';
import { ProductSuggested } from '@/models/product/ProductSuggestied';
import { useCart } from '@/composables/useCart';
import { useRoute } from 'vue-router';
import { appRoutesMap } from '@/router/appRoutesMap';

export const deactivateProductKey = 'DEACTIVATED_PRODUCT_KEY';
export const deactivateProductKeyDefaultVValue = null;

export const useDeactivatedProduct = () => {
    const $store = useStore();
    const $route = useRoute();
    const { fetchCart } = useCart();
    const deactivatedProducts = computed<Array<ProductModel>>(() => $store.getters['product/deactivatedProducts']);
    const suggestions = computed<Array<ProductSuggested>>(
        () => $store.getters['product/suggestionsForUnAvailableProducts'],
    );

    const deleteDeactivatedProducts = async () => {
        const isFromOrder = $route.path.includes(appRoutesMap.OrderMap.SummaryPage);
        if (isFromOrder) return $store.commit('product/deactivatedProducts', null);

        const tempApiCalls = deactivatedProducts.value.map((product) =>
            $store.dispatch('cart/deleteProduct', { deactivatedProductId: product.id }),
        );
        await Promise.allSettled(tempApiCalls);
        $store.commit('product/deactivatedProducts', null);
        return fetchCart();
    };

    const clearSuggestions = (): void => {
        $store.commit('product/suggestionsForUnAvailableProducts');
    };

    const oneProduct = (product: ProductModel): Promise<void> => {
        return $store.dispatch('product/suggestionsForUnAvailableProducts', product);
    };

    const cleanDeactivatedProducts = () => {
        clearSuggestions();
        if (deactivatedProducts.value.length) return deleteDeactivatedProducts();
    };

    const removeOne = (productId: number) => {
        return $store.commit('product/removeOneDeactivatedProduct', productId);
    };
    return {
        suggestions,
        removeOne,
        cleanDeactivatedProducts,
        oneProduct,
        clearSuggestions,
    };
};
