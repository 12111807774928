<template>
    <section-header
        class="show-header"
        :has-back="true"
        :title="titleHeader"
        :with-borders="false"
        color-title="#707070"
        @on-back="onBack"
    />
    <skeleton-checkout v-if="isLoading" />
    <section v-else class="checkout__section">
        <router-view />
    </section>
    <alert-change-schedule
        v-if="showChangeScheduleModal"
        :retailers="retailersToAskSchedule"
        @on-close="onCloseScheduleModal"
        @on-select="onSelectedNewSchedule"
    />
    <ModalGiftCardsCheckout @handle-tipti-card-balance="handleTiptiCardBalance" />
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import { logger } from '@/utils/logger';

import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import PaymentToShowModel from '@/models/checkout/PaymentToShowModel';
import AlertChangeSchedule from '@/views/checkout/components/schedules/AlertChangeSchedule.vue';
import SkeletonCheckout from '@/components/skeletons/skeletonCheckout/SkeletonCheckout.vue';
import { AddressModel } from '@/models/address/AddressModel';
import { PaymentChannelOptions } from '@/enums/paymentChannelOptions';
import InstitutionalPaymentModel from '@/models/checkout/InstitutionalPaymentModel';
import { usePayments } from '@/composables/usePayments';
import { useCart } from '@/composables/useCart';
import { useStockItemSchedule } from '@/composables/useStockItemSchedule';
import { computed, watch } from 'vue';
import { paymentMethodService } from '@/modules/payment-methods/infraestructure/PaymentMethodService';
import ModalGiftCardsCheckout from '@/components/modalsGiftCards/giftCardsCheckout/ModalGiftCardsCheckout.vue';
import { CashPaymentModel, CashWirePaymentModel } from '@/models/checkout/CashWirePaymentModel';
import { useTiptiCard } from '@/composables/useTiptiCard';
import { useBillStore } from '@/store/useBillStore';
import { useOrder } from '@/composables/useOrder';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { usePickUp } from '@/composables/usePickUp';
import { useUser } from '@/composables/useUser';
import { useApp } from '@/composables/useApp';
import { useCheckOut } from '@/composables/useCheckOut';

export default {
    components: {
        ModalGiftCardsCheckout,
        SectionHeader,
        AlertChangeSchedule,
        SkeletonCheckout,
    },
    setup() {
        const {
            checkDeferAvailability,
            difference,
            tiptiCard,
            paymentsToShow,
            paymentIdSelected,
            deferred,
            resetPaymentMethodsAndGiftCards,
        } = usePayments();
        const { $route, $t, $store } = useApp();
        const { userLocation, baseConfiguration } = useUser();
        const { orderRejected, order } = useOrder();
        const { cart, retailersWithNormalProducts, fetchCart } = useCart();
        const { fetchTiptiCard } = useTiptiCard();
        const { retailerIds } = useCheckOut();
        const { fetchProductFirstSchedules } = useStockItemSchedule();
        const { paymentsToShowGiftCard } = usePayments();
        const { fetchRetailersSchedules, fetchFirstSchedulePerRetailer, fetchFirstSchedulePerRetailerPickUp } =
            useRetailer();
        orderRejected.value = null;
        order.value = null;

        watch(
            () => [...paymentsToShow.value],
            (newPayments, oldPayments) => {
                paymentMethodService.deletedPromotions();
                if (newPayments.every((payment) => payment?.name !== 'payment-cards'))
                    return fetchCart(retailerIds.value?.join(','));
                checkDeferAvailability();
                $store.dispatch('promotions/setCreditCardPromotion').then(() => {
                    fetchCart(retailerIds.value?.join(','));
                });
            },
        );

        const $billsStore = useBillStore();
        const { isPickup } = usePickUp();

        const titleHeader = computed(() => {
            if ($route.meta.subsection === 'address') return $t("txt['address-user__delivery-sector']");
            if ($route.meta.subsection === 'payment') return $t("general['payment-methods']");
            return $t("general['resume']");
        });
        return {
            titleHeader,
            fetchCart,
            checkDeferAvailability,
            difference,
            paymentIdSelected,
            paymentsToShow,
            deferred,
            tiptiCard,
            cart,
            retailersWithNormalProducts,
            resetPaymentMethodsAndGiftCards,
            fetchProductFirstSchedules,
            fetchTiptiCard,
            paymentsToShowGiftCard,
            resetBillStore: () => $billsStore.$reset(),
            billUsed: computed(() => $billsStore.billUsed),
            fetchRetailersSchedules,
            fetchFirstSchedulePerRetailer,
            fetchFirstSchedulePerRetailerPickUp,
            isPickup,
            locationSelected: userLocation,
            baseConfiguration,
        };
    },
    data() {
        return {
            showChangeScheduleModal: false,
            retailersToAskSchedule: [],
            isLoading: false,
            timer: undefined,
            alertTiptiCardWasShowed: false,
            showTiptiCardAlert: false,
        };
    },
    computed: {
        ...mapGetters({
            retailerIdsWithtimeDelivery: 'checkout/retailerIdsWithtimeDelivery',
            timeDelivery: 'checkout/timeDelivery',
            secondPayment: 'payments/secondPayment',
            productsForSegment: 'cart/productsForSegment',
            defaultPayment: 'payments/defaultPaymentSelected',
        }),
    },
    watch: {
        locationSelected: function (newVal: AddressModel, oldVal: AddressModel) {
            if (!this.timeDelivery?.length) return;
            if (!newVal?.isPickUpMode && oldVal?.isPickUpMode) this.fetchDefaultDeliveryPayment();
        },
        timeDelivery: function (newVal, oldVal) {
            if (newVal?.length && !oldVal?.length)
                setTimeout(() => this.fetchCart(this.retailerIdsWithtimeDelivery), 200);
            if ((newVal?.length ?? 0) != (oldVal?.length ?? 0))
                this.resetPaymentMethodsAndGiftCards().then((result: boolean | undefined) => {
                    if (result || result === undefined) return;
                    this.fetchDefaultDeliveryPayment();
                });

            if (this.timer) clearTimeout(this.timer);
            const schedulesToRender = [];
            newVal?.forEach((retailer) => {
                if (!oldVal.includes(retailer)) schedulesToRender.push(retailer);
            });
            this.startTimer(schedulesToRender);
        },
    },
    async created() {
        /// TODO: add a cleander commit in chekcout
        this.$store.commit('tiptiCard/tiptiCard', null);
        this.$store.commit('checkout/instructions', '');
        this.$store.commit('checkout/phone', '');
        this.$store.commit('orders/orderDetailSelected');
        this.$store.commit('giftCardAlert/toShowGiftCards', []);
        this.$store.commit('giftCardAlert/idgGiftCardsToUse', []);
        this.$store.commit('giftCardAlert/paymentsToShowGiftCard', []);
        this.resetBillStore();
        await this.initiateCheckout();
        this.fireCheckoutEvent();
    },
    methods: {
        checkInstitutionalDefaultPayment(difference: number): void {
            if (this.defaultPayment?.channelPayment != PaymentChannelOptions.INSTITUTIONAL) return;
            this.$store.commit('payments/cleanPaymentMethods');
            if (this.defaultPayment?.data?.unlimitedBuys) return;
            this.setInstitutionalAsDefault(difference);
        },

        //Difference is use when choise tipticard
        async fetchDefaultDeliveryPayment(difference?: number): Promise<void> {
            if (!this.timeDelivery?.length) return;

            //// TODO ADD LOGIC TO SET NEW DEFAULT PAYMENT
            await this.fetchDefaultPayment();

            this.$store.commit('payments/cleanPaymentMethods');

            if (this.defaultPayment?.channelPayment === PaymentChannelOptions.PAYPHONE_APP)
                return this.setPayphoneAsDefault(difference);
            if (this.defaultPayment?.channelPayment === PaymentChannelOptions.CREDIT_AND_DEBIT_CARD)
                return this.setCreditCardAsDefault(difference);
            if (this.defaultPayment?.channelPayment !== PaymentChannelOptions.INSTITUTIONAL) return;
            this.setInstitutionalAsDefault(difference);
        },
        fireCheckoutEvent(): void {
            const schedulesDay = this.timeDelivery.map((element) => {
                return element.date;
            });
            const schedulesHours = this.timeDelivery.map((element) => {
                return `${element.deliveryFirstDate}-${element.deliverySecondDate}`;
            });
            const billSegment = this.billUsed;
            const billSegmentUser = billSegment?.name?.split(' ');
            this.$store.dispatch('segment/checkoutStarted', {
                product: this.productsForSegment,
                schedule: {
                    date: schedulesDay,
                    hour_range: schedulesHours,
                },
                bill: {
                    identifier_number: billSegment?.identifier,
                    phone: billSegment?.phone,
                    email: billSegment?.email,
                    first_name: billSegmentUser?.length > 0 ? billSegmentUser[0] : billSegmentUser?.join(' '),
                    last_name: billSegmentUser?.length >= 2 ? billSegmentUser[1] : billSegmentUser?.join(' '),
                    address: billSegment?.address,
                },
            });
        },
        async handleTiptiCardBalance(useTiptiCardBalance: boolean): Promise<void> {
            this.$store.commit('payments/cleanPaymentMethods');
            if (this.cart.totalDetails.totalCost <= 0) return;
            if (!useTiptiCardBalance) return this.fetchDefaultDeliveryPayment();
            if (+this.tiptiCard?.availableAmount >= +this.cart?.totalDetails?.totalCost) {
                this.$store.commit('payments/isTiptiCardPayment', true);
                this.$store.commit(
                    'payments/paymentsToShow',
                    new PaymentToShowModel({
                        name: 'payment-tipti-card',
                        amount: +this.cart?.totalDetails?.totalCost,
                        data: this.tiptiCard.id,
                        objectData: ['payment-tipti-card', +this.tiptiCard?.id],
                    }),
                );
                return;
            }

            const difference: number = this.difference(
                +this.cart?.totalDetails?.totalCost,
                +this.tiptiCard?.availableAmount,
            );

            if (difference >= 1 || !this.paymentsToShowGiftCard?.length)
                this.checkInstitutionalDefaultPayment(difference);

            this.$store.commit('payments/isMixPaymentTiptiCard', true);
            this.$store.commit('payments/secondPayment', difference);

            this.$store.commit('payments/isTiptiCardPayment', true);

            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-tipti-card',
                    amount: difference > 0 ? +this.tiptiCard?.availableAmount : +this.cart?.totalDetails?.totalCost,
                    data: this.tiptiCard.id,
                    objectData: ['payment-tipti-card', +this.tiptiCard?.id],
                }),
            );

            if (difference >= 1) return this.fetchDefaultDeliveryPayment(difference);

            if (!this.paymentsToShowGiftCard?.length) return;
            this.$store.commit(
                'payments/cashWirePayment',
                new CashWirePaymentModel(new CashPaymentModel(difference, difference, true), null),
            );
            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-cash',
                    amount: difference,
                }),
            );
        },
        async initiateCheckout(): Promise<void> {
            try {
                this.isLoading = true;
                /// * Clean delivery mode from multiple retailers
                if (this.cart?.retailers?.length !== 1 || !this.cart?.retailers[0]?.isPickupAvailable) {
                    const _location: AddressModel = { ...this.locationSelected };
                    _location.isPickUpMode = false;
                    this.$store.commit('user/userLocation', _location);
                }
                this.$store.commit('payments/isMixPaymentTiptiCard', false);
                this.$store.commit('payments/defaultPaymentSelected');
                this.$store.commit('payments/isMixInstitutionalPayment', false);
                this.$store.commit('payments/cleanPaymentMethods');
                this.$store.commit('checkout/phone', '');
                this.$store.commit('checkout/email', '');
                this.$store.commit('checkout/cleanTimeDelivery');
                this.$store.commit('checkout/resetStockItemTimeDelivery');
                this.$store.commit('checkout/isAutomaticReplacements', false);
                this.$store.commit('checkout/isElder', false);
                this.$store.commit('checkout/instructions', '');
                this.$store.commit('cart/listFilesPrescriptions', null);
                this.$store.commit('cart/itemsPrescription', []);

                await Promise.allSettled([this.fetchRetailersSchedules(), this.fetchTiptiCard()]);

                /// ** handle pickup or not pickup schedule;
                const schedulesToFetchList = [this.fetchProductFirstSchedules()];
                if (!this.isPickUp) schedulesToFetchList.push(this.fetchFirstSchedulePerRetailer());
                if (this.isPickUp && this.retailersWithNormalProducts.length === 1)
                    schedulesToFetchList.push(this.fetchFirstSchedulePerRetailerPickUp());
                await Promise.allSettled([...schedulesToFetchList, this.getPrescriptionItems()]);
            } catch (err) {
                logger('INITIATE_CHECKOUT', err);
            } finally {
                this.isLoading = false;
            }
        },
        onBack(): void {
            this.$router.go(-1);
        },
        ...mapActions({
            fetchDefaultPayment: 'payments/defaultPayment',
        }),
        onCloseScheduleModal(): void {
            this.showChangeScheduleModal = false;
            this.retailersToAskSchedule.forEach((time) => {
                this.$store.commit('checkout/removeTimeDelivery', time.retailerId);
            });
            this.retailersToAskSchedule = [];
        },

        onSelectedNewSchedule(retailerRemoved): void {
            this.showChangeScheduleModal = false;
            this.retailersToAskSchedule = this.retailersToAskSchedule.filter((retailer) => retailer != retailerRemoved);
        },
        setCreditCardAsDefault(difference?: number) {
            if (
                (difference ?? this.cart?.totalDetails?.totalCost) < 1 ||
                (difference ?? this.cart?.totalDetails?.totalCost) > this.baseConfiguration?.globalPaymentLimit
            )
                return;
            this.$store.commit('payments/paymentIdSelected', this.defaultPayment.paymentId);
            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-cards',
                    id: this.defaultPayment.paymentId,
                    idExpired: this.defaultPayment?.data?.isExpired,
                    amount: difference ?? this.cart?.totalDetails?.totalCost,
                    data: this.defaultPayment?.data?.identifier,
                    icon: this.defaultPayment?.data?.logo,
                    analyticData: {
                        franchise: this.defaultPayment?.franchise,
                        bin: this.defaultPayment?.creditCardBrandData?.bin,
                    },
                    objectData: ['payment-cards', this.defaultPayment?.paymentId],
                }),
            );
        },
        setInstitutionalAsDefault(difference?: number) {
            if (
                !this.defaultPayment?.data?.unlimitedBuys &&
                this.defaultPayment?.data?.balance < (difference ?? this.cart?.totalDetails?.totalCost)
            )
                return;
            this.$store.commit(
                'payments/institutionPayment',
                new InstitutionalPaymentModel(
                    this.defaultPayment?.data?.institutionId,
                    this.defaultPayment?.data?.unlimitedBuys,
                    difference ?? +this.defaultPayment?.data?.balance,
                ),
            );

            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-institutional',
                    amount: difference ?? +this.defaultPayment?.data?.balance,
                    data: this.defaultPayment?.data?.alias,
                }),
            );
        },
        setPayphoneAsDefault(difference?: number) {
            if (
                (difference ?? this.cart?.totalDetails?.totalCost) <= 1 ||
                (difference ?? this.cart?.totalDetails?.totalCost) > this.baseConfiguration?.globalPaymentLimit
            )
                return;
            this.$store.commit('payments/paymentIdSelected', this.defaultPayment.paymentId);
            this.$store.commit(
                'payments/paymentsToShow',
                new PaymentToShowModel({
                    name: 'payment-payphone',
                    amount: difference ?? this.cart?.totalDetails?.totalCost,
                    data: this.defaultPayment?.data?.alias,
                    analyticData: {
                        selected_number: this.defaultPayment?.data?.alias,
                    },
                    objectData: ['payment-payphone', this.defaultPayment?.paymentId],
                }),
            );
        },
        startTimer(retailers): void {
            if (!retailers.length) return;
            const retailer = retailers[0];
            this.retailersToAskSchedule = this.retailersToAskSchedule.filter(
                (schedule) => schedule.retailerId != retailer.retailerId,
            );
            if (!this.retailersToAskSchedule.includes(retailer)) this.retailersToAskSchedule.push(retailer);
            this.timer = setTimeout(() => {
                if (this.retailersToAskSchedule.length) this.showChangeScheduleModal = true;
            }, 600000); ///***10 minutes
        },

        async getPrescriptionItems(): Promise<void> {
            try {
                await this.$store.dispatch('cart/getPrescriptionItems');
            } catch (err) {
                logger('GET_PRESCRIPTION_ITEMS', err);
            }
        },
    },
    unmounted() {
        this.$store.commit('giftCardAlert/toShowGiftCards', []);
        this.$store.commit('giftCardAlert/idgGiftCardsToUse', []);
        this.$store.commit('giftCardAlert/paymentsToShowGiftCard', []);
        if (this.timer) clearTimeout(this.timer);
    },
};
</script>

<style lang="scss" scoped>
@import './checkout.scss';
</style>
