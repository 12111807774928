import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0e847b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "converter" }
const _hoisted_2 = { class: "converter__content" }
const _hoisted_3 = { class: "converter__content" }
const _hoisted_4 = {
  key: 1,
  class: "converter__text"
}
const _hoisted_5 = { class: "converter__text converter__text--equivalence" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_manager = _resolveComponent("modal-manager")!

  return (_openBlock(), _createBlock(_component_modal_manager, {
    "has-close": true,
    "is-loading": false,
    title: $options.modalTitle,
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close'))),
    onOnDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close'))),
    "is-disabled": $options.disableBtn,
    "show-bottom-sheet-title": $props.showBottomSheetTitle,
    "show-buttons": true,
    "has-header": true,
    "only-confirmation-btn": true,
    "accept-button-text": _ctx.$t('txt.transfer_to_tipti'),
    onOnAccept: $options.converter
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "header", {}, undefined, true),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "field-names", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.amount) = $event)),
            type: "number",
            class: _normalizeClass(["input_amount", { 'input_amount--error': $options.hasError }])
          }, null, 2), [
            [
              _vModelText,
              $data.amount,
              void 0,
              { number: true }
            ]
          ]),
          ($props.useIcon)
            ? _renderSlot(_ctx.$slots, "equal-symbol", { key: 0 }, undefined, true)
            : (_openBlock(), _createElementBlock("span", _hoisted_4, " = ")),
          _createElementVNode("p", _hoisted_5, _toDisplayString($setup.currency) + " " + _toDisplayString($options.convertValue), 1)
        ]),
        ($options.hasError)
          ? _renderSlot(_ctx.$slots, "error", { key: 0 }, undefined, true)
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["title", "is-disabled", "show-bottom-sheet-title", "accept-button-text", "onOnAccept"]))
}