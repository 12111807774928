<template>
    <dropdown-base :btn-color="btnColor" :icon="countrySelected?.icon" :label-show="countrySelected?.name ?? 'Ecuador'">
        <aside v-for="country in countries" :key="country.id">
            <div class="dropdown-country__options">
                <p>{{ country?.name }}</p>
                <div class="dropdown-country__selector">
                    <img class="dropdown-option__icon" :src="country?.icon" alt="" />
                    <radio-normal
                        :is-selected="countrySelected?.alphaCode === country.alphaCode"
                        :radio-group="country.name"
                        :value="country"
                        @on-check="(value) => (countrySelected = value)"
                    />
                </div>
            </div>
            <horizontal-divider v-if="country != countries[countries.length - 1]" />
        </aside>
    </dropdown-base>
</template>

<script lang="ts" setup>
import DropdownBase from '@/components/dropdown/dropdownBase/DropdownBase.vue';
import RadioNormal from '@/components/radio/RadioNormal.vue';
import HorizontalDivider from '@/components/dividers/HorizontalDivider.vue';
import { useUser } from '@/composables/useUser';
import { useApp } from '@/composables/useApp';
import { computed } from 'vue';
import { CountriesModel } from '@/models/external/CountriesModel';

defineProps({
    btnColor: {
        type: String,
        default: '#FFF',
    },
});
const { $store } = useApp();
const { countrySelected } = useUser();

const countries = computed<CountriesModel[]>(() => $store.getters['external/countries']);
</script>

<style lang="scss" scoped>
@import './dropdown-countries.scss';
</style>
