<template>
    <div class="carousel" :class="{ __margin: hasMargin }">
        <div ref="slider" class="carousel-items">
            <slot></slot>
        </div>
        <aside v-if="showArrows">
            <icon-back-no-border
                v-show="showLeftArrow"
                class="carousel__arrow-back cursor-pointer"
                :background-fill="hasOrangeArrows ? '#ff9012' : '#dfdfdf'"
                :color="hasOrangeArrows ? '#FFF' : '#606060'"
                :icon-name="$t('icon.backward')"
                :size="0.8"
                @click="goBack()"
            />
            <icon-back-no-border
                v-show="showRightArrow"
                class="carousel__arrow-next cursor-pointer"
                :background-fill="hasOrangeArrows ? '#ff9012' : '#dfdfdf'"
                :color="hasOrangeArrows ? '#FFF' : '#606060'"
                :icon-name="$t('icon.forward')"
                :size="0.8"
                @click="goForward()"
            />
        </aside>
    </div>
</template>

<script lang="ts">
import IconBackNoBorder from '@/components/icons/IconBackNoBorder.vue';

export default {
    name: 'CarouselBasic',
    components: {
        IconBackNoBorder,
    },
    props: {
        hasMargin: {
            type: Boolean,
            default: true,
        },
        showArrows: {
            type: Boolean,
            default: true,
        },
        maxWith: {
            type: String,
            default: '40vw',
        },
        hasOrangeArrows: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['reached-end', 'reached-before-end'],
    data() {
        return {
            showLeftArrow: false,
            showRightArrow: false,
            sliderRef: null,
        };
    },
    watch: {
        sliderRef(newVal, oldVal) {
            if (newVal === oldVal || !newVal) return;
            this.calculate();
        },
    },
    mounted() {
        this.calculate();
    },
    methods: {
        goBack(): void {
            this.sliderRef.scrollBy({
                left: -this.sliderRef.clientWidth,
                behaviour: 'smooth',
            });
        },
        goForward(): void {
            this.sliderRef.scrollBy({
                left: this.sliderRef.clientWidth,
                behaviour: 'smooth',
            });
        },
        onScroll() {
            this.showRightArrow =
                Math.ceil(this.sliderRef.scrollLeft) + this.sliderRef.clientWidth < this.sliderRef.scrollWidth;
            this.showLeftArrow = this.sliderRef.scrollLeft > 0;
        },
        calculate() {
            if (null != (this.sliderRef = this.$refs.slider)) this.sliderRef.onscroll = this.onScroll;
            if (this.sliderRef.clientWidth != this.sliderRef.scrollWidth) this.showRightArrow = true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './carousel-basic.scss';
</style>
