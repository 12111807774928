import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e58677a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "balance"
}
const _hoisted_2 = { class: "collapsable-item collapsable-item--prime" }
const _hoisted_3 = { class: "collapsable-text" }
const _hoisted_4 = { class: "collapsable-text--bold" }
const _hoisted_5 = { class: "collapsable-info" }
const _hoisted_6 = { class: "collapsable-info collapsable-info--bold" }
const _hoisted_7 = { class: "collapsable-item" }
const _hoisted_8 = { class: "collapsable-text" }
const _hoisted_9 = { class: "collapsable-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_collapsable = _resolveComponent("collapsable")!
  const _component_btn_information = _resolveComponent("btn-information")!

  return ($props.balance || $options.onlyTotal)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        ($props.balance?.subtotalTaxesTotal)
          ? (_openBlock(), _createBlock(_component_collapsable, {
              key: 0,
              amount: $props.balance?.subtotalTaxesTotal,
              children: $options.subtotalTaxesChildren,
              "header-text": _ctx.$t('txt.cart__subtotal')
            }, null, 8, ["amount", "children", "header-text"]))
          : _createCommentVNode("", true),
        ($props.showShippingCost)
          ? (_openBlock(), _createBlock(_component_collapsable, {
              key: 1,
              amount: $options.valueShoppingCost,
              children: $options.shoppingCostChildren
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("p", _hoisted_3, [
                    _createTextVNode(_toDisplayString(_ctx.$t('txt.cart__shipping-cost')) + " ", 1),
                    (!$setup.profile.isPrime)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "collapsable-text--accent cursor-pointer",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.becomePrime && $options.becomePrime(...args)))
                        }, [
                          _createTextVNode(" (" + _toDisplayString($options.shippingWant[0]) + " ", 1),
                          _createElementVNode("span", _hoisted_4, _toDisplayString($options.shippingWant[1]), 1),
                          _createTextVNode("?) ")
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_btn_information, { onOnView: $options.viewInformationServiceCost }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", _hoisted_5, [
                        _createTextVNode(_toDisplayString($options.shippingInfo[0]) + " ", 1),
                        _createElementVNode("span", _hoisted_6, _toDisplayString($options.shippingInfo[1]), 1),
                        _createTextVNode(" " + _toDisplayString($options.shippingInfo[2]), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["onOnView"])
                ])
              ]),
              _: 1
            }, 8, ["amount", "children"]))
          : _createCommentVNode("", true),
        ($options.showServiceFee)
          ? (_openBlock(), _createBlock(_component_collapsable, {
              key: 2,
              amount: $props.balance?.retailersPercentageTotal,
              children: $options.retailersCommission
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('txt.cart__service-fee')), 1),
                  ($props.balance?.retailersPercentageTotal === 0)
                    ? (_openBlock(), _createBlock(_component_btn_information, {
                        key: 0,
                        onOnView: $options.viewInformationServiceFee
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_9, _toDisplayString($options.commissionFeeTextAlert), 1)
                        ]),
                        _: 1
                      }, 8, ["onOnView"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["amount", "children"]))
          : _createCommentVNode("", true),
        ($props.balance?.taxesTotal)
          ? (_openBlock(), _createBlock(_component_collapsable, {
              key: 3,
              amount: $options.amountTotalTaxes,
              children: $options.taxesChildren,
              "header-text": $props.balance?.taxName
            }, null, 8, ["amount", "children", "header-text"]))
          : _createCommentVNode("", true),
        ($props.balance?.totalDiscount)
          ? (_openBlock(), _createBlock(_component_collapsable, {
              key: 4,
              amount: $props.balance?.totalDiscount,
              children: $options.discountsChildren,
              "header-text": _ctx.$t('txt.cart__savings-total'),
              bold: "",
              "error-header": ""
            }, null, 8, ["amount", "children", "header-text"]))
          : _createCommentVNode("", true),
        _createVNode(_component_collapsable, {
          amount: $props.balance?.orderCost ?? _ctx.totalCostExtraItems,
          "header-text": _ctx.$t('general.total'),
          bold: "",
          "has-extra-height": ""
        }, null, 8, ["amount", "header-text"])
      ]))
    : _createCommentVNode("", true)
}