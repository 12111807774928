export default {
    Promotions: {
        Index: {
            path: '/promotions/:title/:banner_id',
            name: 'promotions.categories',
            meta: {
                pageTitle: 'page-title__banner-categories',
            },
        },
        Categories: {
            path: '',
            name: 'promotions.categories.show',
        },
        CategoryProducts: {
            path: ':category',
            name: 'promotions.categories.category',
            meta: {
                pageTitle: 'page-title__banner-categories-show-category',
            },
        },
    },
};
