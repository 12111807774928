import { onMounted, onUnmounted, ref } from 'vue';
import MediaQuery from '@/assets/ts/media_query';

export const useResize = () => {
    const isMobile = ref(false);
    const isTablet = ref(false);

    const calculateDevice = () => {
        isMobile.value = window.innerWidth < MediaQuery.smallDeviceWidth;
        isTablet.value = window.innerWidth < MediaQuery.mediumDeviceWidth;
    };

    onMounted(() => window.addEventListener('resize', calculateDevice));
    onUnmounted(() => window.removeEventListener('resize', calculateDevice));

    return {
        isMobile,
        isTablet,
    };
};
