<template>
    <base-icon
        :icon-name="$t('icon.send_email')"
        :width="width"
        :height="height"
        viewBox="0 0 24 20"
        :iconColor="color"
    >
        <path
            d="M19.1786 0H4.82143C1.66071 0 0 2.56983 0 5.02793V15.0279C0 18.2682 2.46429 20.0559 4.82143 20.0559H19.2321C21.5357 20.0559 24.0536 18.324 24.0536 15.0279V5.02793C24 2.56983 22.3393 0 19.1786 0ZM21.5893 14.7486C21.5893 17.2626 19.1786 17.2626 19.1786 17.2626V5.97765L12 11.0056L4.82143 5.97765V17.2626C4.82143 17.2626 2.41071 17.2626 2.41071 14.7486V4.69274C2.41071 4.69274 2.41071 2.17877 4.82143 2.17877L12 7.82123L19.1786 2.23464C21.5893 2.23464 21.5893 4.7486 21.5893 4.7486V14.7486Z"
            :fill="color"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-gmail',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FFF',
        },
    },
    computed: {
        width(): number {
            return 24 * this.size;
        },
        height(): number {
            return 20 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
