import { AlertNewsDto } from '@/models/alertNews/AlertNewsModel';

export default {
    alertNewsSlide(state, alertNewsSlide: AlertNewsDto) {
        state.alertNewsSlide = alertNewsSlide;
    },
    closeNewsAlertInRetailers(state, value: boolean) {
        state.closeNewsAlertInRetailers = value;
    },
    closeNewsAlertInCart(state, value: boolean) {
        state.closeNewsAlertInCart = value;
    },
};
