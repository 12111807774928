<script lang="ts" setup>
import { ref, shallowRef } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import CarouselForProducts from '@/components/carousels/CarouselForProducts.vue';
import { useProductStore } from '@/store/useProductStore';
import { useProductQuantity } from '@/composables/useProductQuantity';
import { useProductInformation } from '@/composables/useProductInformation';

const products = shallowRef<ProductModel[]>([]);
const { getProductForYourHome } = useProductStore();
const { quantity } = useProductQuantity('COMPLETE_YOUR_ORDER');
const { productSelected } = useProductInformation(quantity);
const page = ref(1);

const getProductForHome = async () => {
    const { results } = await getProductForYourHome({
        item_id: productSelected.value.id,
        page: page.value,
    });
    products.value = results ?? [];
};
getProductForHome();
</script>

<template>
    <template v-if="products?.length">
        <h4 class="products-for-home__label">{{ $t("txt['for-your-home']") }}</h4>
        <CarouselForProducts :items="products" section="PRODUCTS_FOR_HOME" state="LOAD-ENDED" />
    </template>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as styles;

.products-for-home {
    &__label {
        @include styles.body2;
        font-weight: 600;
        text-align: start;
        padding: styles.$padding-sm styles.$padding-md;
        border-top: 1px solid styles.$disable;
        background-color: styles.$white;
    }

    &__carousel {
        @include styles.tablet {
            padding-bottom: styles.$margin-screen;
        }
    }
}
</style>
