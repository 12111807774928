<template>
    <div class="card-product__price">
        <template v-if="showDiscountCard">
            <div class="card-product__price__card-discount">
                <img class="card-product__price__card-discount__img" :src="productData?.discountCard?.icon" alt="" />
                <p v-currency="productData?.discountCard?.product_price" class="prices__special" />
                <div class="discount-cart__container">
                    <BtnInformation>
                        <template #default>
                            <p
                                v-translate="productData.discountCard.message"
                                class="card-product__price__card-discount__info"
                            />
                        </template>
                    </BtnInformation>
                </div>
            </div>
            <p
                v-currency="productData.hasDiscount ? productData?.priceWithDiscount : productData.price"
                class="prices__regular prices--cross-discount"
            />
        </template>
        <template v-else>
            <p v-if="productData.hasDiscount" v-currency="productData?.priceWithDiscount" class="prices__special" />
            <p
                v-currency="productData.price"
                class="prices__regular"
                :class="{ 'prices--cross-discount': productData.hasDiscount }"
            />
        </template>
        <p v-if="productData.groupId && attributeValue.length" class="prices__units">{{ attributeValue }}</p>
        <p v-else class="prices__units">
            {{ productData.unitQuantity }} {{ productData.unit }}
            {{ productData.conversionGramsAndKilogramsToPounds }}
        </p>
        <p
            v-if="productData.carry && productData.pay"
            class="card-product__special-info card-product__special-info--purple"
        >
            {{ getMultiplierTag(productData) }}
        </p>
        <p v-else-if="showUniqueDelivery" class="card-product__special-info">
            {{
                $t('txt.unique_delivery_info_message', {
                    count: productData.hoursToDelivery,
                })
            }}
        </p>
        <ProductIndicators v-else-if="showProductIndicators" :indicators="productData.indicators" :size="0.6" />
        <IndicatorHighDemandOutOfStock v-if="!productData.available || productData.highDemand" :product="productData" />
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed } from 'vue';
import type { ProductModel } from '@/models/product/ProductModel';
import { useProductAttribute } from '@/composables/ProductAttributesComposable';
import { useProductTags } from '@/composables/useProductTags';
import BtnInformation from '@/components/buttons/BtnInformation.vue';
import { useCartType } from '@/modules/core/composable/useCartType';
import { useXSelling } from '@/modules/extraItems/composables/useXSelling';
import ProductIndicators from '@/components/badges/productIndicators/ProductIndicators.vue';
import IndicatorHighDemandOutOfStock from '@/components/badges/productIndicators/IndicatorHighDemandOutOfStock.vue';

defineOptions({
    name: 'CardProductPrice',
});

const props = defineProps({
    productData: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
});

const { getMultiplierTag } = useProductTags();
const { isExtraItemCart: isExtraItems } = useCartType();
const { products: xSellingProducts } = useXSelling();
const { attributeValue } = useProductAttribute(props.productData);

const showUniqueDelivery = computed(() => {
    return props.productData.uniqueDelivery && props.productData.hoursToDelivery;
});

const showDiscountCard = computed<boolean>(() => {
    if (xSellingProducts.value?.length || isExtraItems.value) return false;
    return Boolean(props.productData.discountCard);
});

const showProductIndicators = computed<boolean>(() => {
    return !(props.productData.carry && props.productData.pay) && !showUniqueDelivery.value;
});
</script>

<style lang="scss" scoped>
@import './card-product.scss';
</style>
