<template>
    <section class="subheader-price">
        <p class="subheader-price__txt" v-if="!totalPayed">
            {{ $t('general.total-order') }}:
            <span class="subheader-price__amount" v-currency="totalToPay" />
        </p>
        <div class="subheader-price__mix" v-else>
            <p class="subheader-price__txt">
                {{ $t('general.value_payed') }}:
                <span class="subheader-price__amount" v-currency="totalPayed" />
            </p>
            <p class="subheader-price__txt">
                {{ $t('general.value_to_pay') }}:
                <span class="subheader-price__amount" v-currency="totalToPay" />
            </p>
        </div>
    </section>
</template>
<script lang="ts">
export default {
    name: 'subheader-price',
    props: {
        totalToPay: { type: Number, default: 0 },
        totalPayed: { type: Number, default: 0 },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.subheader-price {
    display: flex;
    flex-direction: column;
    &__mix {
        display: flex;
        justify-content: space-between;
    }
    &__txt {
        @include body1;
        text-align: center;
    }
    &__amount {
        color: $orange;
    }
}
</style>
