import { AffiliationAlertDto } from '@/modules/affiliation/models/AffiliationAlertDto';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { WrapperResponse } from '@/modules/core/decorators/wrapperResponse';

class TiptiTermsAndConditionService {
    @WrapperResponse({ logResult: false, rethrowError: false })
    async get(): Promise<AffiliationAlertDto> {
        const url = 'v2/alert-setting-term-conditions';
        const { data } = await axiosInstance().get<AffiliationAlertDto>(url);
        return data;
    }

    @WrapperResponse({ logResult: false, rethrowError: false })
    async post(): Promise<void> {
        const url = 'v2/alert-setting-term-conditions';
        await axiosInstance().post(url);
    }
}

export const tiptiTermsAndConditionService = new TiptiTermsAndConditionService();
