class PayphoneModel {
    readonly paymentType: number;
    readonly phone: string;
    readonly resultDefault: boolean;
    readonly id: number;
    readonly isShared: boolean;
    readonly isExtraItemsPayment: boolean;
    readonly isPaymentMethod: boolean;
    readonly isTransaction: boolean;
    readonly paymentChannel: string;

    constructor(
        paymentType: number,
        phone: string,
        resultDefault: boolean,
        id: number,
        isShared: boolean,
        isExtraItemsPayment: boolean,
        isPaymentMethod: boolean,
        isTransaction: boolean,
        paymentChannel: string,
    ) {
        this.paymentType = paymentType;
        this.phone = phone;
        this.resultDefault = resultDefault;
        this.id = id;
        this.isShared = isShared;
        this.isExtraItemsPayment = isExtraItemsPayment;
        this.isPaymentMethod = isPaymentMethod;
        this.isTransaction = isTransaction;
        this.paymentChannel = paymentChannel;
    }
}
const toPayphoneModel = (data: object): PayphoneModel => {
    return data
        ? new PayphoneModel(
              data['payment_type'],
              data['data']['phone'],
              data['default'],
              data['id'],
              data['is_shared'],
              data['extra_items_payment'],
              data['is_payment_method'],
              data['is_transaction'],
              data['channel'],
          )
        : null;
};

export { PayphoneModel, toPayphoneModel };
