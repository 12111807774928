<template>
    <base-icon :icon-name="label" :width="width" :height="height" viewBox="0 0 10 10" :iconColor="color">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.20574 10.2892C8.39063 10.4909 8.62175 10.5918 8.85287 10.5918C9.08399 10.5918 9.31511 10.4909 9.5 10.2892C9.86979 9.88568 9.86979 9.28043 9.5 8.87694L6.21812 5.2959L9.5 1.71486C9.86979 1.31137 9.86979 0.70612 9.5 0.302623C9.13021 -0.100874 8.57553 -0.100874 8.20574 0.302623L4.88778 3.92303L1.57161 0.304575C1.38671 0.102826 1.15559 0.00195181 0.924474 0.0019518C0.693356 0.0019518 0.462237 0.102826 0.277342 0.304575C-0.0924473 0.708072 -0.0924473 1.31332 0.277342 1.71681L3.55922 5.29785L0.277342 8.87889C-0.0924474 9.28238 -0.0924474 9.88763 0.277342 10.2911C0.647132 10.6946 1.20182 10.6946 1.57161 10.2911L4.88957 6.67072L8.20574 10.2892Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-close',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#FF9012',
        },
        iconName: {
            type: String,
        },
    },
    computed: {
        width(): number {
            return 15 * this.size;
        },
        height(): number {
            return 15 * this.size;
        },
        label(): string {
            return this.iconName ?? this.$t('icon.close');
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
