import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7546ebe5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "program-type__name" }
const _hoisted_2 = { class: "program-footer" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "program-state" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_card_flat = _resolveComponent("base-card-flat")!

  return (_openBlock(), _createBlock(_component_base_card_flat, {
    class: "program-type cursor-pointer",
    style: _normalizeStyle({
            'background-color': $props.program.mainColor,
        }),
    "border-width": "0",
    "border-color": $props.program.mainColor
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($props.program.description), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          class: "program-footer__img",
          src: $props.program.logo,
          alt: $props.program.name
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["program-state__circle", {
                    'program-state__circle--active': $props.program.subscription !== null,
                    'program-state__circle--inactive': $props.program.subscription === null,
                }])
        }, null, 2),
        _createElementVNode("p", {
          class: _normalizeClass(["program-state__text", {
                    'program-state__text--active': $props.program.subscription !== null,
                    'program-state__text--inactive': $props.program.subscription === null,
                }])
        }, _toDisplayString($options.programState.toUpperCase()), 3)
      ])
    ]),
    _: 1
  }, 8, ["style", "border-color"]))
}