<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import { useTranslate } from '@/composables/UseTranslate';
import { useWallet } from '@/views/wallet/useWallet';

const { benefits, getMembershipCardById } = useWallet();

const { exec } = useTranslate();

await getMembershipCardById();
</script>

<template>
    <h3 v-if="exec(benefits.title_screen)" v-translate="benefits?.title_screen" class="benefit-detail__title"></h3>
    <BaseCard v-for="(benefit, index) in benefits.benefits_list" :key="index" class="benefit-detail">
        <img v-if="benefit?.icon" class="benefit-detail__icon" :alt="benefit?.name?.EN" :src="benefit.icon" />
        <div class="benefit-detail__content-description">
            <h3 v-translate="benefit?.name" class="benefit-detail__title-list"></h3>
            <p v-translate="benefit?.description" class="benefit-detail__text"></p>
        </div>
    </BaseCard>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as colors;
@use '@/assets/scss/type-system' as type;
@use '@/assets/scss/margin' as mg;

.benefit-detail {
    gap: mg.$margin-md;
    height: fit-content;
    align-items: center;

    &__title {
        @include type.body1;
        color: colors.$orange;
        font-weight: 600;
    }

    &__title-list {
        @include type.body1;
        color: colors.$dark-grey;
        font-weight: 600;
    }

    &__content-description {
        display: flex;
        flex-direction: column;
        gap: mg.$margin-sm;
    }

    &__name {
        @include type.body1;
        color: colors.$dark-grey;
    }

    &__text {
        @include type.body2;
        color: colors.$dark-grey;
        font-size: 14px;
    }

    &__icon {
        height: 4rem;
    }
}
</style>
