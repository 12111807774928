<template>
    <base-card class="share-data" padding="0">
        <section-header
            class="share-data__header-mobile"
            :title="$t('txt.profile-options__share-data')"
            color-title="#707070"
            has-back
            @on-back="$router.back"
        />
        <header class="share-data__header">
            <h1>{{ $t('txt.profile_shared_data__share_id_with') }}</h1>
            <div class="share-data__question-icon" @click="showInformation = true">?</div>
        </header>
        <base-loading v-if="isLoading" />
        <section v-else-if="permissions.length" class="share-data__body">
            <base-card
                v-for="permission in permissions"
                :key="permission.retailer_id"
                class="share-data__permission-card"
            >
                <aside class="permission-card__data">
                    <img
                        v-if="getRetailerIcon(permission.retailer_id).length"
                        :src="getRetailerIcon(permission.retailer_id)"
                        alt=""
                    />
                    <h2>{{ permission.franchise_name }}</h2>
                </aside>
                <toggle
                    :model-value="permission.permission_status === 'APPROVED'"
                    @on-changed="setPermission(permission.retailer_id, permission.permission_status !== 'APPROVED')"
                />
            </base-card>
        </section>
        <no-content
            v-else
            class="shared-data__no-content"
            :title="$t('txt.profile_shared_data__no_content')"
            color-title="#C1C1C1"
        >
            <icon-document :size="1.5" color="#C1C1C1"></icon-document>
        </no-content>
    </base-card>
    <base-modal
        v-if="showInformation"
        :title="$t('txt.profile_shared_data__affiliate_modal_title')"
        header-text-color="#ff9012"
        @on-close="showInformation = false"
    >
        <div class="share-data__modal-content-container">
            <p class="share-data__modal-body">{{ $t('txt.profile_shared_data__affiliate_modal_paragraph_1') }}</p>
            <p v-if="alphaCode === 'PA'" class="share-data__modal-body">
                {{ $t('txt.profile_shared_data__affiliate_modal_paragraph_2') }}
            </p>
            <span
                v-for="item in termsAndConditionsData"
                :key="item.id"
                v-translate="item.text"
                @click="onSelectTermAndCondition(item)"
            />
            <br />
            <div class="share-data__button-container">
                <btn-solid :txt="$t('cta.accept')" full-width @click="showInformation = false" />
            </div>
        </div>
    </base-modal>
    <alert-pdf
        v-if="selectedTermsAndConditions"
        :title="exec(selectedTermsAndConditions.text)"
        :url="exec(selectedTermsAndConditions.document)"
        @on-close="selectedTermsAndConditions = undefined"
    />
</template>

<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import BaseLoading from '@/components/loading/BaseLoading.vue';
import NoContent from '@/components/noContent/NoContent.vue';
import IconDocument from '@/components/icons/IconDocument.vue';
import Toggle from '@/components/toggle/Toggle.vue';
import SectionHeader from '@/components/headers/sectionHeader/SectionHeader.vue';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import BtnSolid from '@/components/buttons/BtnSolid.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { useUser } from '@/composables/useUser';
import { computed, reactive, ref } from 'vue';
import { ShareDataRetailPermissionDto } from '@/models/shareData/ShareDataRetailPermissionDto';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { useApp } from '@/composables/useApp';
import { useTranslate } from '@/composables/UseTranslate';
import AlertPdf from '@/components/alerts/alertPdf/AlertPdf.vue';
import { useTermsAndConditions } from '@/composables/useTermsAndConditions';
import { TermsAndConditionsDto } from '@/models/termsAndConditions/TermsAndConditionsDto';

const { notifier, $t, $store } = useApp();
const { alphaCode } = useUser();
const { allRetailers } = useRetailer();
const { exec } = useTranslate();
const { fetchTermsAndConditions, termsAndConditionsData, selectTermsAndConditions, selectedTermsAndConditions } =
    useTermsAndConditions();
const showInformation = ref(false);
const isLoading = ref(true);
const permissions = reactive<ShareDataRetailPermissionDto[]>([]);

const onSelectTermAndCondition = (item: TermsAndConditionsDto) => {
    showInformation.value = false;
    selectTermsAndConditions(item);
};
const getRetailerIcon = computed(() => (retailerId: number) => {
    const retail = allRetailers.value.find((retailer) => retailer.id == retailerId);
    return retail?.icon ?? retail?.image ?? '';
});

const setPermission = async (retailerId: number, status: boolean): Promise<void> => {
    try {
        await axiosInstance().post(`v2/customer/permission/${retailerId}`, {
            status: status ? 'APPROVED' : 'DENIED',
        });
    } catch (err) {
        const per = permissions.find((ret) => ret.retailer_id === retailerId);
        if (per) per.permission_status = !status ? 'APPROVED' : 'DENIED';
        notifier({
            type: 'ERROR',
            body: $t('general.try-again'),
        });
    }
};

const getAllStatusPermissionToShareData = async () => {
    const { data } = await axiosInstance().get<ShareDataRetailPermissionDto[]>('v2/customer/permission/');
    permissions.push(...data);
};

const init = () => {
    isLoading.value = true;
    Promise.allSettled([fetchTermsAndConditions(), getAllStatusPermissionToShareData()]).then(() => {
        isLoading.value = false;
    });
};

init();
</script>

<style lang="scss" scoped>
@import './share-data.scss';
</style>
