import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    orderDetailSelected: undefined,
    productsToCart: [],
    orders: [],
    orderRateSelected: undefined,
    orderWithPaymentRejected: undefined,
    selectedRetailer: '',
    isHistoryOrders: false,
    productsForSegment: [],
    isSelectedOrder: false,
    resentPaymentAccept: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
