export default {
    showMainSearchBlurPredictions(state): boolean {
        return state.showMainSearchBlurPredictions;
    },
    searchOriginSection(state: { searchOriginSection: string }): string {
        return state.searchOriginSection;
    },
    query(state): string {
        return state.query;
    },
};
