<template>
    <section v-if="items.length" class="the-tipti-news">
        <TheCardNews v-for="item in items" :key="item.id" :news="item" />
    </section>
    <u v-if="showSeeMore" class="the-tipti-news__see-more cursor-pointer" @click="getListReleases">
        {{ $t("general['see-more-newsletters']") }}
    </u>
    <section v-if="isLoading" class="the-tipti-news">
        <SkeletonNews v-for="i in 6" :key="i" />
    </section>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import type { PressReleases } from '@/models/external/PressReleases';
import TheCardNews from '@/views/newsPress/components/TheCardNews.vue';
import SkeletonNews from '@/views/newsPress/components/SkeletonNews.vue';

const $store = useStore();
const isLoading = ref(false);
const page = ref(1);
const items = ref<PressReleases[]>([]);
const isLastPage = ref(false);

const showSeeMore = computed(() => !isLastPage.value && items.value.length);

const getListReleases = async () => {
    if (isLoading.value || isLastPage.value) return;
    isLoading.value = true;
    const { results, next } = await $store.dispatch('external/getPressReleases', { page: page.value });
    isLoading.value = false;
    if (!results) return;
    page.value = page.value + 1;
    items.value = [...items.value, ...results];
    isLastPage.value = !next;
};

await getListReleases();
</script>

<style lang="scss" scoped>
@use '@/assets/scss/index.scss' as style;
@import './tipti-news.scss';

.the-tipti-news {
    display: grid;
    grid-template-columns: repeat(auto-fill, 20rem);
    gap: 0.5rem;
    place-content: center;

    &__see-more {
        display: flex;
        justify-content: center;
        @include style.subtitle1;
        color: style.$orange;
        font-size: 20px;
        font-weight: 600;
    }
}
</style>
