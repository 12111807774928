<script lang="ts" setup>
import { computed } from 'vue';

import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    size: {
        type: Number,
        default: 1,
    },
    color: {
        type: String,
        default: 'white',
    },
});
const width = computed<number>(() => 76 * props.size);
const height = computed<number>(() => 76 * props.size);
</script>

<template>
    <BaseIcon :height="height" :width="width" icon-color="transparent" viewBox="0 0 76 76">
        <rect :width="15" fill="none" height="13" />
        <path
            :fill="color"
            d="M38 0C17.0109 0 0 17.0109 0 38C0 58.9891 17.0109 76 38 76C58.9891 76 76 58.9891 76 38C76 17.0109 58.9891 0 38 0ZM55.1891 31.4391L36.1891 50.4391C35.3727 51.2555 34.3187 51.6562 33.25 51.6562C32.1813 51.6562 31.1214 51.2527 30.3095 50.4408L20.8095 40.9408C19.19 39.3169 19.19 36.6822 20.8095 35.0583C22.4334 33.4344 25.0681 33.4344 26.692 35.0583L33.25 41.6219L49.3109 25.5609C50.9348 23.937 53.5696 23.937 55.1935 25.5609C56.807 27.1789 56.807 29.8211 55.1891 31.4391Z"
        />
    </BaseIcon>
</template>
