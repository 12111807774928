class DescriptionModel {
    express: string;
    delivery: string;
    pickup: string;
    detail: string;
    deliveryDay: string;
    schedule: string;

    constructor(
        express: string,
        delivery: string,
        pickup: string,
        detail: string,
        deliveryDay: string,
        schedule: string,
    ) {
        this.express = express;
        this.delivery = delivery;
        this.pickup = pickup;
        this.detail = detail;
        this.deliveryDay = deliveryDay;
        this.schedule = schedule;
    }
}
const toDescriptionModel = (data: object): DescriptionModel => {
    return data
        ? new DescriptionModel(
              data['express_detail'] ?? '',
              data['delivery_detail'] ?? '',
              data['pickup_detail'] ?? '',
              data['detail'] ?? '',
              data['delivery_day_detail'] ?? '',
              data['schedule_detail'] ?? '',
          )
        : null;
};

export { DescriptionModel, toDescriptionModel };
