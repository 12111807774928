import { PaymentMethodDataModel, toPaymentMethodDataModel } from './PaymentMethodData';
import { CreditCardBrandModel } from './CreditCardBrand';

enum PaymentsChannels {
    CASH = 'CASH',
    PAYPHONE_APP = 'PAYPHONE_APP',
    INSTITUTIONAL = 'INSTITUTIONAL',
    TIPTI_CARD = 'TIPTI_CARD',
    CREDIT_AND_DEBIT_CARD = 'CREDIT_AND_DEBIT_CARD',
}

class PaymentMethodModel {
    readonly paymentType: number;
    readonly data: PaymentMethodDataModel;
    readonly name: string;
    readonly resultDefault: boolean;
    readonly id: number;
    readonly isShared: boolean;
    readonly isExtraItemsPayment: boolean;
    readonly isPaymentMethod: boolean;
    readonly isTransaction: boolean;
    readonly creditCardBrandData: CreditCardBrandModel;
    readonly isValidate: boolean;
    readonly isRequiredValidation: boolean;
    readonly isExpired: boolean;
    readonly paymentChannel: string;
    readonly franchise: string;

    constructor(
        paymentType: number,
        data: PaymentMethodDataModel,
        name: string,
        resultDefault: boolean,
        id: number,
        isShared: boolean,
        isExtraItemsPayment: boolean,
        isPaymentMethod: boolean,
        isTransaction: boolean,
        creditCardBrandData: CreditCardBrandModel,
        isValidate: boolean,
        isRequiredValidation: boolean,
        isExpired: boolean,
        paymentChannel: PaymentsChannels,
        franchise?: string,
    ) {
        this.paymentType = paymentType;
        this.data = data;
        this.name = name;
        this.resultDefault = resultDefault;
        this.id = id;
        this.isShared = isShared;
        this.isExtraItemsPayment = isExtraItemsPayment;
        this.isPaymentMethod = isPaymentMethod;
        this.isTransaction = isTransaction;
        this.creditCardBrandData = creditCardBrandData;
        this.isValidate = isValidate;
        this.isRequiredValidation = isRequiredValidation;
        this.isExpired = isExpired;
        this.paymentChannel = paymentChannel;
        this.franchise = franchise;
    }
}

const toPaymentMethodModel = (data: object): PaymentMethodModel => {
    return data
        ? new PaymentMethodModel(
              data['payment_type'],
              toPaymentMethodDataModel(data['data']),
              data['name'],
              data['default'],
              data['id'],
              data['is_shared'],
              data['extra_items_payment'],
              data['is_payment_method'],
              data['is_transaction'],
              new CreditCardBrandModel(data['credit_card_brand_data']),
              data['is_validated'] ?? false,
              data['is_required_validation'] ?? false,
              data['is_expired'] ?? false,
              data['channel'],
              data['franchise'],
          )
        : null;
};
export { PaymentMethodModel, toPaymentMethodModel, PaymentsChannels };
