<template>
    <TheScheduleStockItemCard
        v-for="product in products"
        :key="product.id"
        :is-single="products.length === 1"
        :product="product"
    />
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { computed } from 'vue';
import TheScheduleStockItemCard from '@/views/checkout/components/menu/components/scheduleCard/TheScheduleStokItemCard.vue';

defineOptions({
    name: 'SchedulesStockItemCard',
});

const $state = useStore();
const products = computed(() => $state.getters['cart/productWithSpecialDelivery']);
</script>

<style lang="scss" scoped>
@import '../../menu-checkout.scss';
@import './schedule-card.scss';
</style>
