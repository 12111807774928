<template>
    <base-icon :icon-name="$t('icon.recipes')" :width="width" :height="height" viewBox="0 0 18 19" :iconColor="color">
        <path
            d="M4.90909 9.06817C4.90909 8.82948 4.72611 8.63634 4.49999 8.63634C4.27386 8.63634 4.09088 8.82948 4.09088 9.06817C4.09088 10.2021 5.27581 11.759 5.41085 11.9332C5.49197 12.037 5.6094 12.0909 5.72764 12.0909C5.81873 12.0909 5.91062 12.0589 5.9869 11.9931C6.16148 11.8417 6.18664 11.5697 6.04363 11.385C5.60503 10.8208 4.90909 9.69311 4.90909 9.06817Z"
        />
        <path
            d="M13.5001 8.63634C13.2739 8.63634 13.091 8.82948 13.091 9.06817C13.091 9.691 12.395 10.8199 11.9564 11.3854C11.8134 11.5697 11.8389 11.8417 12.0135 11.9931C12.0894 12.0589 12.1813 12.0909 12.2728 12.0909C12.391 12.0909 12.5081 12.037 12.5892 11.9332C12.7242 11.759 13.9092 10.2021 13.9092 9.06817C13.9092 8.82948 13.7262 8.63634 13.5001 8.63634Z"
        />
        <path
            d="M9.00005 8.63634C8.77392 8.63634 8.59094 8.82948 8.59094 9.06817V11.6591C8.59094 11.8978 8.77392 12.0909 9.00005 12.0909C9.22617 12.0909 9.40915 11.8978 9.40915 11.6591V9.06817C9.40911 8.82948 9.22613 8.63634 9.00005 8.63634Z"
        />
        <path
            d="M13.5 1.72729C13.2521 1.72729 12.993 1.7619 12.724 1.81465C11.823 0.705983 10.4892 0 9.00002 0C7.51084 0 6.17702 0.705983 5.27605 1.81461C5.00697 1.7619 4.7479 1.72725 4.50003 1.72725C2.01867 1.72729 0 3.85811 0 6.47728C0 8.27201 0.958807 9.90355 2.46414 10.7103L2.45616 17.236C2.45577 17.7071 2.62913 18.1499 2.94434 18.483C3.25995 18.8166 3.67902 19 4.12529 19H13.8764C14.7968 19 15.5455 18.2097 15.5455 17.2381V10.7039C17.0452 9.89513 18 8.2661 18 6.47728C18 3.85811 15.9813 1.72729 13.5 1.72729ZM14.7273 17.2382C14.7273 17.7332 14.3454 18.1364 13.8763 18.1364H4.12525C3.89794 18.1364 3.6842 18.0428 3.5232 17.8729C3.36258 17.7029 3.27391 17.4773 3.27429 17.237L3.27644 15.5455H14.7273V17.2382ZM14.9806 10.0318C14.959 10.041 14.9386 10.052 14.9194 10.0651C14.8679 10.0988 14.8255 10.1435 14.794 10.195C14.762 10.246 14.7404 10.305 14.7317 10.3683C14.7281 10.3923 14.7269 10.4164 14.7272 10.4408V14.6818H3.27747L3.28273 10.4467C3.28311 10.4231 3.28192 10.399 3.27874 10.3758C3.26996 10.3104 3.24717 10.2497 3.214 10.197C3.18282 10.1477 3.14209 10.1047 3.09254 10.0718C3.07217 10.0583 3.05058 10.0465 3.02822 10.0367C1.68551 9.41695 0.81817 8.0211 0.81817 6.47728C0.81817 4.33419 2.46973 2.59091 4.49999 2.59091C4.57934 2.59091 4.6631 2.60103 4.74529 2.60787C4.33139 3.36717 4.09089 4.2446 4.09089 5.18182C4.09089 5.42051 4.27387 5.61365 4.49999 5.61365C4.72612 5.61365 4.90909 5.42051 4.90909 5.18182C4.90909 2.8009 6.74441 0.863623 9.00002 0.863623C11.2556 0.863623 13.0909 2.8009 13.0909 5.18182C13.0909 5.42051 13.2739 5.61365 13.5 5.61365C13.7261 5.61365 13.9091 5.42051 13.9091 5.18182C13.9091 4.2446 13.6686 3.36721 13.2547 2.60787C13.3369 2.60103 13.4207 2.59091 13.5 2.59091C15.5303 2.59091 17.1818 4.33423 17.1818 6.47728C17.1818 8.01563 16.3181 9.40974 14.9806 10.0318Z"
        />
    </base-icon>
</template>

<script lang="ts">
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'icon-chef-hat',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: '#606060',
        },
    },
    computed: {
        width(): number {
            return 25 * this.size;
        },
        height(): number {
            return 25 * this.size;
        },
    },
    components: {
        BaseIcon,
    },
};
</script>
