import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["fill"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createBlock(_component_base_icon, {
    "icon-name": _ctx.$t('icon.rewards'),
    width: $options.width,
    height: $options.height,
    viewBox: "0 0 46 24",
    iconColor: $props.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        d: "M35.6705 14.0809C34.8716 14.7614 34.1636 15.699 33.5835 16.8253C34.0685 18.5196 34.372 20.3476 34.465 22.2374C34.4832 22.6096 34.4319 22.9779 34.3266 23.3296H45.5927C45.394 19.2838 43.9354 15.8351 41.875 14.0803C42.7585 13.2519 43.3122 12.0751 43.3122 10.7675C43.3122 8.26073 41.2794 6.22852 38.7723 6.22852C36.2655 6.22852 34.2333 8.26064 34.2333 10.7675C34.2339 12.0751 34.7864 13.2526 35.6702 14.081L35.6705 14.0809Z",
        fill: $props.color
      }, null, 8, _hoisted_1),
      _createElementVNode("path", {
        d: "M0.837158 23.331H12.1042C11.9978 22.9789 11.9472 22.6106 11.9657 22.2387C12.0588 20.3486 12.3619 18.521 12.8479 16.8267C12.2679 15.6997 11.5595 14.7631 10.76 14.0823C11.6435 13.2539 12.1966 12.077 12.1966 10.7695C12.1966 8.26268 10.1645 6.23047 7.65736 6.23047C5.15057 6.23047 3.11836 8.2626 3.11836 10.7695C3.11836 12.0767 3.67147 13.2539 4.55498 14.0823C2.49529 15.8366 1.0366 19.2855 0.837241 23.3307L0.837158 23.331Z",
        fill: $props.color
      }, null, 8, _hoisted_2),
      _createElementVNode("path", {
        d: "M23.2161 2.3121C26.1609 2.3121 28.5484 4.69897 28.5484 7.64374C28.5484 9.17921 27.8987 10.5616 26.8596 11.5346C29.2804 13.5963 30.9929 17.6467 31.227 22.3991H15.205C15.4391 17.6474 17.1519 13.5963 19.5718 11.5346C18.5337 10.5623 17.884 9.17921 17.884 7.64374C17.8843 4.69897 20.2712 2.3121 23.2159 2.3121H23.2161ZM23.2159 0.762695C19.4223 0.762695 16.3355 3.84948 16.3355 7.64374C16.3355 8.99893 16.7421 10.3211 17.4793 11.439C15.2767 13.9792 13.8747 17.9213 13.6579 22.3225C13.6371 22.746 13.7911 23.1587 14.0836 23.4663C14.376 23.773 14.7819 23.9478 15.2057 23.9478H31.2276C31.652 23.9478 32.0567 23.7734 32.3495 23.4663C32.6419 23.1587 32.7962 22.7459 32.7751 22.3225C32.5592 17.9211 31.156 13.9787 28.9547 11.439C29.6922 10.3208 30.0985 8.99902 30.0985 7.64374C30.0972 3.84948 27.011 0.762695 23.2161 0.762695H23.2159Z",
        fill: $props.color
      }, null, 8, _hoisted_3),
      _createElementVNode("path", {
        d: "M20.5193 14.0206C21.5321 13.604 22.7917 14.1202 23.2167 15.1262C23.6567 14.0841 24.8335 13.5767 25.9119 14.0225C26.9669 14.4615 27.4694 15.6747 27.0337 16.7287C26.6424 17.6686 23.7342 20.4243 23.4032 20.7353L23.2135 20.9149L23.0239 20.7353C22.6938 20.4234 19.7879 17.6672 19.3978 16.7248C18.9627 15.6708 19.4653 14.4569 20.5193 14.0205L20.5193 14.0206Z",
        fill: $props.color
      }, null, 8, _hoisted_4)
    ]),
    _: 1
  }, 8, ["icon-name", "width", "height", "iconColor"]))
}