<template>
    <div class="card" :style="styles">
        <slot></slot>
    </div>
</template>

<script lang="ts">
export default {
    name: 'base-card-flat',
    props: {
        padding: {
            type: String,
            default: '1em',
        },
        borderColor: {
            type: String,
        },
        borderWidth: {
            type: String,
            default: '1px',
        },
        bgColor: {
            type: String,
            default: '#FFF',
        },
        borderRadius: {
            type: String,
            default: '7px',
        },
    },
    computed: {
        styles(): object {
            return {
                padding: this.padding,
                border: this?.borderColor
                    ? `${this.borderWidth} solid ${this.borderColor}`
                    : `${this.borderWidth} solid #C7C7C7`,
                'background-color': this.bgColor,
                'border-radius': this.borderRadius,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
.card {
    display: flex;
    padding: $padding-sm;
}
</style>
