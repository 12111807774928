import { CategoryModel } from './CategoryModel';
import { OrderInformationModel, toOrderInformationModel } from './OrderInformation';
import { HistoryModel, toHistoryModel } from './HistoryModel';
import { RetailerModel, toRetailerModel } from '@/modules/retailers/models/RetailerModel';
import { PaymentMethodElementModel, toPaymentMethodElementModel } from './PaymentMethodElementModel';
import { baseOrderState } from '@/utils/orderUtils';
import { BaseOrderState } from './BaseOrderState';
import { ShopperModel, toShopperModel } from './ShopperModel';
import { ProductModel, toProductModel } from '../product/ProductModel';
import { ShopperInfoDto } from './ShopperInfoDto';
import { BillAddress } from '@/models/BillModels';
import type { PaymentRejectedContext } from '@/models/order/OrderRejectedContextDto';
import { SuggestedPaymentMethod } from '@/models/order/OrderRejectedContextDto';

class OrderResumenModel {
    readonly id: number;
    readonly actualShopperAssigned: ShopperModel;
    readonly shopperInfo: ShopperInfoDto;
    readonly base: BaseOrderState;
    state: string;
    readonly retailer: RetailerModel;
    readonly initialOrderItems?: Array<CategoryModel>;
    readonly initialOrderExtraItems?: Array<CategoryModel>;
    readonly finalOrderBoughtItems?: Array<CategoryModel>;
    readonly finalOrderReplacedItems?: Array<CategoryModel>;
    readonly finalOrderNotFoundItems?: Array<CategoryModel>;
    readonly tiptiItems?: Array<ProductModel>;
    readonly orderInformation: OrderInformationModel;
    readonly date: Date;
    readonly delivery: Date;
    readonly deliveryTo: Date;
    readonly billingInfo: BillAddress;
    readonly history: Array<HistoryModel>;
    readonly express: boolean;
    readonly isReschedule: boolean;
    readonly isAddExtraItemEnabled: boolean;
    readonly rate: number;
    readonly numberOfExtraItemsAllowed: number;
    readonly paymentMethods: Array<PaymentMethodElementModel>;
    readonly isPickUp: boolean;
    readonly commissions: Array<object>;
    readonly totalToPayInResentPayment: number;
    readonly totalPayed: number;
    readonly payment_attempt_message?: string;
    readonly payment_rejected_context: PaymentRejectedContext;
    readonly suggested_payment_method: SuggestedPaymentMethod;

    constructor(
        id: number,
        actualShopperAssigned: ShopperModel,
        shopperInfo: ShopperInfoDto,
        base: BaseOrderState,
        state: string,
        retailer: RetailerModel,
        initialOrderItems: Array<CategoryModel>,
        initialOrderExtraItems: Array<CategoryModel>,
        finalOrderBoughtItems: Array<CategoryModel>,
        finalOrderReplacedItems: Array<CategoryModel>,
        finalOrderNotFoundItems: Array<CategoryModel>,
        tiptiItems: Array<ProductModel>,
        orderInformation: OrderInformationModel,
        date: Date,
        delivery: Date,
        deliveryTo: Date,
        billingInfo: BillAddress,
        history: Array<HistoryModel>,
        express: boolean,
        isReschedule: boolean,
        isAddExtraItemEnabled: boolean,
        rate: number,
        numberOfExtraItemsAllowed: number,
        paymentMethods: Array<PaymentMethodElementModel>,
        isPickUp: boolean,
        commissions: Array<object>,
        payment_attempt_message: string,
        payment_rejected_context: PaymentRejectedContext,
        suggested_payment_method: SuggestedPaymentMethod,
    ) {
        this.id = id;
        this.actualShopperAssigned = actualShopperAssigned;
        this.shopperInfo = shopperInfo;
        this.base = base;
        this.state = state;
        this.retailer = retailer;
        this.initialOrderItems = initialOrderItems;
        this.initialOrderExtraItems = initialOrderExtraItems;
        this.finalOrderBoughtItems = finalOrderBoughtItems;
        this.finalOrderReplacedItems = finalOrderReplacedItems;
        this.finalOrderNotFoundItems = finalOrderNotFoundItems;
        this.tiptiItems = tiptiItems;
        this.orderInformation = orderInformation;
        this.date = date;
        this.delivery = delivery;
        this.deliveryTo = deliveryTo;
        this.billingInfo = billingInfo;
        this.history = history;
        this.express = express;
        this.isReschedule = isReschedule;
        this.isAddExtraItemEnabled = isAddExtraItemEnabled;
        this.rate = rate;
        this.numberOfExtraItemsAllowed = numberOfExtraItemsAllowed;
        this.paymentMethods = paymentMethods;
        this.isPickUp = isPickUp;
        this.commissions = commissions;
        this.totalPayed = this.getTotalPayed();
        this.totalToPayInResentPayment = this.getTotalToPay();
        this.payment_attempt_message = payment_attempt_message;
        this.payment_rejected_context = payment_rejected_context;
        this.suggested_payment_method = suggested_payment_method;
    }

    private getTotalPayed(): number {
        const totalPayed =
            this.paymentMethods?.map((paymentMethod) => paymentMethod.amount)?.reduce((a, b) => a + b, 0) ?? 0;
        return Number(totalPayed.toFixed(2));
    }

    private getTotalToPay(): number {
        return Number((this.orderInformation?.orderCost['final_cost'] - this.totalPayed).toFixed(2));
    }
}

const toOrderResumeModel = (data: object): OrderResumenModel => {
    return data
        ? new OrderResumenModel(
              data['id'],
              toShopperModel(data['shopper']),
              data['order_information']['order_detail']['shopper_info'],
              baseOrderState(data['state']),
              data['state'],
              data['get_retailers']?.length ? toRetailerModel(data['get_retailers'][0], data['retailer']) : null,
              data['initial_order_items']['initials_items']['categories']?.map(
                  (category) => new CategoryModel(category),
              ) ?? [],
              data['initial_order_items']['extra_items']['categories']?.map(
                  (category) => new CategoryModel(category),
              ) ?? [],
              data['final_order_items']['item_bought']['categories']?.map((category) => new CategoryModel(category)) ??
                  [],
              data['final_order_items']['item_replaced']['categories']?.map(
                  (category) => new CategoryModel(category),
              ) ?? [],
              data['final_order_items']['item_not_found']['categories']?.map(
                  (category) => new CategoryModel(category),
              ) ?? [],
              data['tipti_items']?.map((tiptiItem: object) => toProductModel(tiptiItem)),
              toOrderInformationModel(data['order_information']),
              new Date(data['date']),
              new Date(data['delivery']),
              new Date(data['delivery_to']),
              data['billing_info'],
              data['history']?.reverse()?.map((history: object) => toHistoryModel(history)) ?? [],
              data['express'] ?? false,
              data['is_reschedule'] ?? false,
              data['add_extra_items_enabled'] ?? false,
              +data['rate'],
              data['number_of_items_extra_allowed'] ?? false,
              data['payment_methods']?.map((paymentMethod: object) => toPaymentMethodElementModel(paymentMethod)),
              data['is_pickup'] ?? false,
              data['order_information']['order_detail']['commissions']?.map((commission: object) => commission) ?? [],
              data['payment_attempt_message'],
              data['payment_rejected_context'],
              data['suggested_payment_method'],
          )
        : null;
};

export { OrderResumenModel, toOrderResumeModel };
