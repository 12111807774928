<template>
    <product-not-found v-if="!products.length" />
    <products-grid v-else :products="products" :section="section" />
</template>

<script lang="ts" setup>
import { ProductModel } from '@/models/product/ProductModel';
import ProductsGrid from '@/components/product/productsGrid/ProductsGrid.vue';
import { PropType, shallowRef } from 'vue';
import { ResponsePaged } from '@/modules/core/models/ResponseModels';
import ProductNotFound from '@/views/productNotFound/ProductNotFound.vue';
import { useProductFetcher } from '@/views/seeMore/useProductFetcher';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { usePagination } from '@/modules/core/components/paginatinos/usePagination';
import { useProductStore } from '@/store/useProductStore';

const props = defineProps({
    page: {
        type: Number,
        required: true,
    },
    section: {
        type: String,
        default: undefined,
    },
    fetcher: {
        type: String as PropType<'SEARCH' | 'SEE_MORE' | 'CATEGORIES'>,
        required: true,
    },
});
const {
    stockItemWithDiscount,
    newProducts,
    predictions,
    yourProducts,
    promotionPersonalized,
    recommendationsSovi,
    searchDiscounts,
    stockItemsSearch,
} = useProductStore();
const products = shallowRef<ProductModel[]>([]);
const { retailerSelected } = useRetailer();
const { searchOn } = usePagination();
const { seeMoreSection, categorySelected, subcategorySelected } = useProductFetcher();

const $emit = defineEmits<{
    (e: 'pages', value: number);
    (e: 'first-products', value: ProductModel[]);
}>();

const formatResponse = (response: ResponsePaged<ProductModel>) => {
    products.value = response.results;
    $emit('pages', response.total_pages);
    if (props.page !== 1) return;
    $emit('first-products', products.value);
};

const fetchSeeMoreProducts = async (): Promise<ResponsePaged<ProductModel>> => {
    if (seeMoreSection.value.isNewProduct)
        return newProducts({
            page: props.page,
            retailerId: retailerSelected.value?.id,
        });

    if (seeMoreSection.value.isPrediction)
        return predictions({
            page: props.page,
            retailerId: retailerSelected.value?.id,
        });

    if (seeMoreSection.value.isYourProducts)
        return yourProducts({
            page: props.page,
            retailerId: retailerSelected.value?.id,
        });

    if (seeMoreSection.value.isPromotion)
        return promotionPersonalized({
            page: props.page,
            retailerId: retailerSelected.value?.id,
        });

    if (seeMoreSection.value.isRecommendSovi)
        return recommendationsSovi({
            page: props.page,
            retailerId: retailerSelected.value?.id,
        });

    return searchDiscounts({
        page: props.page,
        category_id: +seeMoreSection.value.categoryId,
        subcategory_id: +seeMoreSection.value.subCategoryId,
        retailer_id: retailerSelected.value?.id,
    });
};

const fetchCategoryProducts = async (): Promise<ResponsePaged<ProductModel>> => {
    if (seeMoreSection.value.isDiscount)
        return promotionPersonalized({
            search: searchOn.value,
            retailerId: retailerSelected.value?.id,
            category: subcategorySelected.value?.id,
            page: props.page,
        });

    return stockItemWithDiscount({
        query: searchOn.value,
        retailer_id: retailerSelected.value?.id,
        category_id: categorySelected.value?.id,
        subcategory_id: subcategorySelected.value?.id,
        page: props.page,
    });
};
const init = async () => {
    if (props.fetcher === 'SEARCH')
        return formatResponse(
            await stockItemsSearch({
                query: searchOn.value,
                page: props.page,
                retailerId: retailerSelected.value?.id,
            }),
        );
    if (props.fetcher === 'SEE_MORE') return formatResponse(await fetchSeeMoreProducts());
    if (props.fetcher === 'CATEGORIES') return formatResponse(await fetchCategoryProducts());
};

await init();
</script>
