<template>
    <alert-manager
        :is-loading="false"
        :is-disabled="false"
        :show-buttons="true"
        :only-confirmation-btn="true"
        :show-bottom-sheet-title="false"
        :has-padding-btn="true"
        :title="title"
        :btn-x-padding="1"
        :btn-y-padding="1"
        :accept-button-text="btnTxt"
        @on-accept="$emit('on-accept')"
        @on-close="$emit('on-close')"
        @on-dismiss="$emit('on-close')"
    >
        <template v-slot:content>
            <div class="validate-error">
                <icon-circular-close :iconName="$t('icon.error')" :size="1" color="#F03F51" />
                <h2 class="validate-error__mobile-title">
                    {{ title }}
                </h2>
                <h3 v-if="isRequestError" class="validate-error__general">
                    {{ $t('txt.credit-card__verification-error') }}
                </h3>
                <div v-else-if="isLastAttempt" class="validate-card__error">
                    <p class="validate-error__txt--highlight">
                        {{ $t('txt.credit-card__last-attempt') }}
                    </p>
                    <p class="validate-error__txt">
                        {{ $t('txt.credit-card__additionally') }}
                        <span class="validate-error__txt--highlight">
                            {{ $t('general.tipti_team') }}
                        </span>
                        {{ $t('txt.credit-card__contact-you') }}
                    </p>
                </div>
                <div v-else class="validate-card__error">
                    <p class="validate-error__txt--highlight">{{ $t('general.value_incorrect') }}</p>
                    <p class="validate-error__txt--highlight">{{ $t('general.attempts_remaining') }} {{ attempts }}</p>
                </div>
            </div>
        </template>
    </alert-manager>
</template>

<script lang="ts">
import IconCircularClose from '@/components/icons/IconCircularClose.vue';
import AlertManager from '@/components/alerts/alertManager/AlertManager.vue';

export default {
    name: 'validation-error',
    components: {
        IconCircularClose,
        AlertManager,
    },
    emits: ['on-close', 'on-accept'],
    props: {
        isRequestError: {
            type: Boolean,
            default: false,
        },
        isLastAttempt: {
            type: Boolean,
            default: false,
        },
        attempts: {
            type: Number,
            required: true,
        },
    },
    computed: {
        title(): string {
            return this.isRequestError ? this.$t('general.ups') : this.$t('general.verification_failed');
        },
        btnTxt(): string {
            return this.isRequestError ? this.$t('cta.continue') : this.$t('cta.try_again');
        },
    },
};
</script>

<style scoped lang="scss">
@import 'validation-error';
</style>
