<template>
    <BackgroundWithTransition content-centered is-active @on-bg-click="$emit('on-close')">
        <TransitionFade>
            <CheckoutScheduleModal
                v-if="showHours"
                v-model="showHours"
                :retail="retail"
                is-from-alert
                @selected="onSelectSchedule"
            />
            <TheModalContent v-else>
                <template #header>
                    <BaseHeader
                        :text="$t('txt.schedule__change-schedule')"
                        :text-size="20"
                        text-color="orange"
                        with-default-close
                        @on-close="$emit('on-close')"
                    />
                </template>
                <template #default>
                    <section class="change-schedule">
                        <p>{{ $t('txt.schedule__change-schedule-alert') }}</p>
                        <div v-for="time in retailers" :key="time.retailerId">
                            <base-card class="change-schedule__tile">
                                <img :src="time.retailerLogo ?? ''" alt="" @error="displayDefaultImg" />
                                <aside class="change-schedule__tile-data">
                                    <h1>{{ time.retailerName }}</h1>
                                    <p>{{ time.date }}</p>
                                    <p>{{ time.deliveryFirstDate }} - {{ time.deliverySecondDate }}</p>
                                </aside>
                                <RadioNormal
                                    :is-selected="retailerSelected?.retailerId == time.retailerId"
                                    :value="time"
                                    radio-group="changeSchedules"
                                    @on-check="retailerSelected = time"
                                />
                            </base-card>
                        </div>
                    </section>
                </template>
                <template #footer>
                    <footer class="change-schedule-footer">
                        <btn-solid
                            class="change-schedule__btn"
                            :is-disabled="!retailerSelected"
                            :txt="$t('cta.accept')"
                            @click="!retailerSelected ? null : (showHours = true)"
                        />
                    </footer>
                </template>
            </TheModalContent>
        </TransitionFade>
    </BackgroundWithTransition>
</template>

<script lang="ts" setup>
import BaseCard from '@/components/cards/BaseCard.vue';
import BtnSolid from '@/components/buttons/BtnOutLine.vue';
import type { PropType } from 'vue';
import { computed, ref, watch } from 'vue';
import { useImage } from '@/composables/ImageComposable';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import CheckoutScheduleModal from '@/views/checkout/components/menu/components/scheduleCard/CheckoutScheduleModal.vue';
import BackgroundWithTransition from '@/modules/core/components/background/BackgroundWithTransition.vue';
import TheModalContent from '@/modules/core/components/layouts/TheModalContent.vue';
import BaseHeader from '@/modules/core/components/headers/HeaderWithAction.vue';
import TransitionFade from '@/modules/core/components/transition/TransitionVerticalFade.vue';
import RadioNormal from '@/components/radio/RadioNormal.vue';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';

defineProps({
    retailers: {
        type: Array as PropType<TimeDeliveryModel[]>,
        required: true,
    },
});
const $emits = defineEmits<{
    (e: 'on-close'): void;
    (e: 'on-select', value: TimeDeliveryModel): void;
}>();

const { displayDefaultImg } = useImage();
const retailerSelected = ref<TimeDeliveryModel>();
const showHours = ref(false);

watch(showHours, (value, oldValue) => {
    if (!oldValue) return;
    if (value) return;
    $emits('on-close');
});

const { retailersAvailability } = useRetailer();

const retail = computed(
    () => retailersAvailability.value?.find((ret) => ret.retailer_id === retailerSelected.value?.retailerId),
);

const onSelectSchedule = () => {
    $emits('on-select', retailerSelected.value);
};
</script>
<style lang="scss" scoped>
@import 'alert-change-schedule';
</style>
