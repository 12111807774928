import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13db0bfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-header" }
const _hoisted_2 = { class: "settings-header__user-info" }
const _hoisted_3 = { class: "settings-header__user-info__name" }
const _hoisted_4 = { class: "settings-header__user-info__details" }
const _hoisted_5 = { class: "settings-header__user-info__details" }
const _hoisted_6 = { class: "settings-header__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.profile.name), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.profile.email), 1),
      _createElementVNode("p", _hoisted_5, "ID: " + _toDisplayString(_ctx.profile.id), 1)
    ]),
    _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('txt.setting__title')), 1)
  ]))
}