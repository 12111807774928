import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40a108cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "checkout__section"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_skeleton_checkout = _resolveComponent("skeleton-checkout")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_alert_change_schedule = _resolveComponent("alert-change-schedule")!
  const _component_ModalGiftCardsCheckout = _resolveComponent("ModalGiftCardsCheckout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_section_header, {
      class: "show-header",
      "has-back": true,
      title: $setup.titleHeader,
      "with-borders": false,
      "color-title": "#707070",
      onOnBack: $options.onBack
    }, null, 8, ["title", "onOnBack"]),
    ($data.isLoading)
      ? (_openBlock(), _createBlock(_component_skeleton_checkout, { key: 0 }))
      : (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createVNode(_component_router_view)
        ])),
    ($data.showChangeScheduleModal)
      ? (_openBlock(), _createBlock(_component_alert_change_schedule, {
          key: 2,
          retailers: $data.retailersToAskSchedule,
          onOnClose: $options.onCloseScheduleModal,
          onOnSelect: $options.onSelectedNewSchedule
        }, null, 8, ["retailers", "onOnClose", "onOnSelect"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ModalGiftCardsCheckout, { onHandleTiptiCardBalance: $options.handleTiptiCardBalance }, null, 8, ["onHandleTiptiCardBalance"])
  ], 64))
}