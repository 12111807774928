import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5dc3b6d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dynamic_dropdown" }
const _hoisted_2 = {
  key: 0,
  class: "input-normal__required"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "dropdown-option" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_dropdown = _resolveComponent("icon-dropdown")!
  const _component_horizontal_divider = _resolveComponent("horizontal-divider")!
  const _component_base_card = _resolveComponent("base-card")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("button", {
      class: "dropdown-button",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($options.toggleOption && $options.toggleOption(...args)), ["prevent"]))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["dropdown-button__text", { 'dropdown-button__text--tag': !$data.currentOption }])
      }, _toDisplayString($data.currentOption ?? $props.labelName), 3),
      _createElementVNode("div", {
        class: _normalizeClass(["dropdown-button__arrow", { 'dropdown-button__arrow--disable': !$props.options?.length }])
      }, [
        _createVNode(_component_icon_dropdown, {
          color: "#ffff",
          size: 0.75
        })
      ], 2)
    ]),
    ($props.isEmptyAndRequired)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "* " + _toDisplayString(_ctx.$t("general['required-fields']")), 1))
      : _createCommentVNode("", true),
    ($data.showOptions)
      ? (_openBlock(), _createBlock(_component_base_card, {
          key: 1,
          padding: "0",
          "border-radius": "0",
          style: _normalizeStyle($options.style),
          class: "dropdown__options"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: option.value,
                  class: "",
                  onClick: _withModifiers(($event: any) => ($options.updateValue(option)), ["prevent"])
                }, [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(option.title), 1),
                  (option !== $props.options[$props.options.length - 1])
                    ? (_openBlock(), _createBlock(_component_horizontal_divider, { key: 0 }))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_3))
              }), 128))
            ], true)
          ]),
          _: 3
        }, 8, ["style"]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, $options.closeContent]
  ])
}