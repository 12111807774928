<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed, ref, watch } from 'vue';
import { ProductModel } from '@/models/product/ProductModel';
import { useImage } from '@/composables/ImageComposable';
import { ProductMediaModel } from '@/models/product/ProductMedia';
import BaseModal from '@/components/modal/baseModal/BaseModal.vue';
import PercentageDiscount from '@/components/cards/cardProduct/PercentageDiscount.vue';

const props = defineProps({
    product: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
    zoom: {
        type: Boolean,
        default: false,
    },
});
const currentImage = ref<ProductMediaModel>();
const emits = defineEmits<(e: 'close-zoom', value: boolean) => void>();
const isZoomActive = computed({
    get: () => props.zoom,
    set: (value: boolean) => emits('close-zoom', value),
});
const { displayDefaultImg } = useImage();

watch(
    () => props.product,
    (newProduct) => {
        currentImage.value = newProduct?.media?.find((image) => image.is_cover) ?? newProduct?.media?.[0];
    },
    {
        immediate: true,
    },
);
const bgPosition = ref();
const bgSize = ref();
const applyZoom = (e: MouseEvent) => {
    const zoom = e.target as HTMLElement;

    const offsetX: number = e.offsetX;
    const offsetY: number = e.offsetY;
    const x = (offsetX / zoom?.offsetWidth) * 100;
    const y = (offsetY / zoom?.offsetHeight) * 100;
    bgSize.value = '200%';
    bgPosition.value = x + '% ' + y + '%';
};

const removeZoom = () => {
    showZoomer.value = false;
    if (!isVideo.value) return;
    bgSize.value = '0%';
};

const showZoomer = ref(false);
const isVideo = computed(() => /mp4$/gi.test(currentImage.value?.resource));
const hasImages = computed(() => props.product?.media?.length > 1);
</script>

<template>
    <section class="product-images" :class="{ 'product-images--one': !hasImages }">
        <section v-if="hasImages" class="product-images__list">
            <img
                v-for="media in product?.media"
                :key="media?.resource"
                class="image-item cursor-pointer"
                :class="{ 'image-item--selected': media?.resource === currentImage?.resource }"
                :alt="product?.name"
                :src="media?.resource"
                @click="currentImage = media"
                @error="displayDefaultImg"
            />
        </section>
        <section class="product-images__content">
            <section
                class="product-information__image"
                @mouseleave="showZoomer = false"
                @mouseover="showZoomer = !isVideo"
            >
                <figure class="product-information__img" :class="{ 'cursor-zoom-in': !isVideo }">
                    <video v-if="isVideo" :src="currentImage?.resource" controls>
                        Tu navegador no admite el elemento <code>video</code>.
                    </video>
                    <img
                        v-else
                        :alt="product.name"
                        :src="currentImage?.resource"
                        loading="lazy"
                        @error="displayDefaultImg"
                        @click.stop="isZoomActive = true"
                    />
                </figure>
                <transition name="scale-fade">
                    <div v-if="showZoomer" class="zoomer">
                        <figure
                            class="product-images__zoom"
                            :style="{
                                'background-image': `url(${currentImage?.resource})`,
                                'background-position': bgPosition,
                                'background-size': bgSize,
                            }"
                            @mousemove="applyZoom"
                        >
                            <img
                                class="product-images__main--zoom"
                                :alt="product.name"
                                :src="currentImage?.resource"
                                loading="lazy"
                                @click="isZoomActive = true"
                                @error="displayDefaultImg"
                            />
                        </figure>
                    </div>
                </transition>
            </section>
            <PercentageDiscount :product="product" where-to-show="DetailProduct" />
        </section>
    </section>
    <base-modal v-if="isZoomActive" :has-header="false" @on-close="isZoomActive = false">
        <img
            v-if="currentImage?.resource"
            class="img-zoom"
            :alt="product.name"
            :src="currentImage?.resource"
            @click="isZoomActive = false"
        />
    </base-modal>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/margin' as margin;
@import './product-images.scss';

.product-images {
    position: relative;
    align-self: center;

    &__zoom {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        object-fit: cover;
        height: 100%;
        width: 100%;
        cursor: zoom-in;
    }

    &__main {
        &:hover {
            opacity: 0;
        }

        transition: opacity 0.5s;
        display: block;
        width: 100%;
        height: 100%;
    }

    &__main--zoom {
        &:hover {
            opacity: 0;
        }

        transition: opacity 0.5s;
        width: 100%;
        height: 100%;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: margin.$margin-sm;

        & img,
        video {
            padding: 3px;
            width: 100%;
            aspect-ratio: 2/2;
        }
    }

    &__content {
        display: grid;
        gap: margin.$margin-sm;
    }
}

.image-zoom {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 15em 0 0 0;
    display: grid;
    place-content: flex-end;

    @include tablet {
        display: none;
    }
}

.zoomer {
    position: absolute;
    inset: -10%;
    z-index: 1;
    background-color: $white;
    display: grid;
    place-content: center;
    @include tablet {
        display: none;
    }
}

.scale-fade-enter-active,
.scale-fade-leave-active {
    transition: all 0.5s ease;
}

.scale-fade-enter-from,
.scale-fade-leave-to {
    opacity: 0;
    transform: scale(1.1);
}
</style>
