import { CategoryModel } from '@/models/product/CategoryModel';
import { ProductModel } from '@/models/product/ProductModel';
import { bannerService } from '@/modules/banners/infrastructure/BannersServices';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { ref } from 'vue';

export const useBanners = () => {
    const categories = ref<CategoryModel[]>([]);
    const { retailerSelected } = useRetailer();
    const productsByCategory = ref<ProductModel[]>([]);
    const isLoading = ref(true);
    const fetchCategories = async (bannerId: number, page: number): Promise<void> => {
        categories.value = await bannerService.getCategoriesInBanner({
            retailerId: retailerSelected.value.id,
            bannerId,
        });
        const products = categories.value?.map((category: CategoryModel) => {
            return bannerService.getProductsInBanner({
                retailerId: retailerSelected.value.id,
                bannerId,
                categoryId: category.id,
                page,
            });
        });
        await Promise.allSettled(products).then((result) => {
            result.forEach((productResult) => {
                if (productResult.status !== 'fulfilled') return;
                if (!productResult.value.products.length) {
                    categories.value = categories.value.filter(
                        (category) => category?.id != productResult.value?.categoryId,
                    );
                    return;
                }
                const targetCategory = categories.value?.find(
                    (category) => category?.id == productResult.value?.categoryId,
                );
                targetCategory.products = productResult.value?.products;
            });
        });
    };

    const fetchProductsFromCategoryId = async (bannerId, categoryId, page): Promise<void> => {
        isLoading.value = true;
        const { products } = await bannerService.getProductsInBanner({
            retailerId: retailerSelected.value.id,
            bannerId,
            categoryId,
            page,
        });
        productsByCategory.value = products;
        isLoading.value = false;
    };

    return {
        isLoading,
        fetchCategories,
        fetchProductsFromCategoryId,
        categories,
        productsByCategory,
    };
};
