import { OrderModel } from '@/models/order/OrderModel';
import { OrderResumenModel } from '@/models/order/OrderResumen';
import { ProductModel } from '@/models/product/ProductModel';
import type { OrderRejected } from '@/models/order/OrderRejectedModel';

export default {
    orderDetailSelected(state, orderDetail: OrderResumenModel) {
        state.orderDetailSelected = orderDetail;
    },
    productsToCart(state, product: ProductModel) {
        const index = state.productsToCart.indexOf(product);
        if (index !== -1) state.productsToCart.splice(index, 1);
        else state.productsToCart.push(product);
    },
    clearProducts(state) {
        state.productsToCart = [];
    },
    orders(state, orders: Array<OrderModel>) {
        state.orders = orders;
    },
    orderRateSelected(state, order) {
        state.orderRateSelected = order;
    },
    selectedRetailer(state, retailerId: string) {
        state.selectedRetailer = retailerId;
    },
    isHistoryOrders(state, isHistory: boolean) {
        state.isHistoryOrders = isHistory;
    },
    productsForSegment(state, products: Array<object>) {
        state.productsForSegment = products;
    },
    clearOrderRateSelected(state) {
        state.orderRateSelected = null;
    },
    isSelectedOrder(state, value: boolean) {
        state.isSelectedOrder = value;
    },
    orderIdSelected(state, value) {
        state.orderIdSelected = value;
    },
    orderWithPaymentRejected(state, order: OrderRejected) {
        state.orderWithPaymentRejected = order;
    },
    resentPaymentAccept(state, value: boolean) {
        state.resentPaymentAccept = value;
    },
};
