<template>
    <ProductHeaderMutation v-if="showInHeader" :product="product">
        <template #action>
            <section class="information-quantity">
                <QuantityModifierButtons
                    v-model="quantity"
                    :is-loading="isLoading"
                    :is-for-replacement="isForReplacement"
                    :show-by-weight="showByWeight"
                    :product="product"
                    @update-cart="$emit('on-main-action', showByWeight)"
                />
            </section>
        </template>
    </ProductHeaderMutation>
    <section ref="observer" class="information-quantity">
        <BulkOptionSelector v-if="product?.unitStock && product?.bulk" v-model="showByWeight" />
        <QuantityModifierButtons
            v-model="quantity"
            :is-loading="isLoading"
            :is-for-replacement="isForReplacement"
            :show-by-weight="showByWeight"
            :product="product"
            @update-cart="$emit('on-main-action', showByWeight)"
        />
    </section>
</template>
<script lang="ts" setup>
import { ProductModel } from '@/models/product/ProductModel';
import { isBoughtByWeigth } from '@/utils/productUtils';
import { computed, onMounted, PropType, ref, watch } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import { useImage } from '@/composables/ImageComposable';
import { useUser } from '@/composables/useUser';
import BulkOptionSelector from '@/components/modal/productModal/components/theProductInformation/components/theQuantityModifier/BulkOptionSelector.vue';
import ProductHeaderMutation from '@/components/modal/productModal/components/theProductInformation/components/theQuantityModifier/ProductHeaderMutation.vue';
import QuantityModifierButtons from '@/components/modal/productModal/components/theProductInformation/components/theQuantityModifier/QuantityModifierButtons.vue';

const props = defineProps({
    product: {
        type: Object as PropType<ProductModel>,
        required: true,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    isForReplacement: {
        type: Boolean,
        default: false,
    },
});

const quantity = ref(1);
const showByWeight = ref(false);
const { displayDefaultImg } = useImage();
const { isAuth } = useUser();

onMounted(() => {
    quantity.value = +props.product.productQuantity === 0 ? 1 : props.product.productQuantity;
    showByWeight.value = isBoughtByWeigth(props.product);
});

const $emit = defineEmits<{
    (e: 'on-changed-quantity', value: number): void;
    (e: 'on-main-action', value: boolean): void;
}>();

watch(
    () => quantity.value,
    (value) => {
        $emit('on-changed-quantity', value);
    },
);

const mainHeaderHeight = computed(() => {
    const header = document.querySelector('.header-container');
    return (header?.clientHeight ?? 0) + 75;
});

const observer = ref<HTMLElement>();
const showInHeader = ref(false);
useIntersectionObserver(
    observer,
    ([{ isIntersecting }]) => {
        if (!window.scrollY) {
            showInHeader.value = false;
            return;
        }
        showInHeader.value = !isIntersecting;
    },
    {
        rootMargin: `-${mainHeaderHeight.value}px 0px 0px 0px`,
    },
);
</script>
<style lang="scss" scoped>
@import './the-quantity-modifier.scss';

.header__close-icon-back {
    display: none;
    @include phone {
        display: grid;
        align-self: center;
    }
}

.product-price-header {
    &__tag {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $margin-md;

        &--margin {
            margin: $margin-sm 0;
        }
    }

    &__label {
        @include caption;
        font-weight: 400;
        @include phone {
            font-size: 14px;
        }

        &--normal {
            @include body2;
        }

        &--special {
            @include body2;
            color: $magenta;
            font-weight: 600;
            @include phone {
                font-size: 16px;
            }
        }

        &--crossed {
            @include body1;
            text-decoration: line-through;
            margin-left: $margin-sm;
            font-size: 1rem !important;
        }
    }

    &__special {
        @include headline1;
        font-weight: 600;
    }

    @include phone {
        display: none;
    }
}

.product-name {
    @include tablet {
        @include text-lines(3);
    }
}
</style>
