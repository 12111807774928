import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1db0512e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "extra-item-order-state" }
const _hoisted_2 = { class: "extra-item-order-state__number" }
const _hoisted_3 = { class: "extra-item-order-state__number--id" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "extra-item-order-state--mobile" }
const _hoisted_6 = { class: "information_container" }
const _hoisted_7 = { class: "extra-item-order-state__number" }
const _hoisted_8 = { class: "extra-item-order-state__number--id" }
const _hoisted_9 = { class: "information_container__delivery_info" }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_circle = _resolveComponent("icon-circle")!
  const _component_icon_location = _resolveComponent("icon-location")!
  const _component_icon_and_text = _resolveComponent("icon-and-text")!
  const _component_icon_store = _resolveComponent("icon-store")!
  const _component_icon_schedule = _resolveComponent("icon-schedule")!
  const _component_btn_chat = _resolveComponent("btn-chat")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_icon_circle, {
        class: "order-state__circle",
        color: '#CFDE00',
        size: "25px"
      }),
      _createElementVNode("h2", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t('txt.extra_items_current_order')) + " # ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString($options.currentOrder.id), 1)
      ]),
      _createVNode(_component_icon_and_text, {
        class: "no_padding",
        text: $options.currentOrder?.orderInformation?.city
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_icon_location, {
            size: 0.5,
            color: "#707070"
          })
        ]),
        _: 1
      }, 8, ["text"]),
      _createVNode(_component_icon_and_text, {
        class: "no_padding",
        text: $options.currentOrder?.retailer.name
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_icon_store, {
            size: 0.5,
            color: "#707070"
          })
        ]),
        _: 1
      }, 8, ["text"]),
      _createVNode(_component_icon_and_text, {
        class: "no_padding",
        text: $options.deliveryText
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_icon_schedule, {
            size: 0.5,
            color: "#707070"
          })
        ]),
        _: 1
      }, 8, ["text"]),
      ($options.canChat)
        ? (_openBlock(), _createBlock(_component_btn_chat, {
            key: 0,
            class: "extra-item-order-state__chat",
            "messages-unread": $options.totalUnreadMessages,
            onClick: $options.goToChat
          }, null, 8, ["messages-unread", "onClick"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h2", _hoisted_7, [
          _createTextVNode(_toDisplayString(_ctx.$t('txt.extra_items_current_order')) + " # ", 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString($options.currentOrder.id), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_icon_and_text, {
            class: "no_padding",
            text: $options.currentOrder?.orderInformation?.city
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_icon_location, {
                size: 0.5,
                color: "#707070"
              })
            ]),
            _: 1
          }, 8, ["text"]),
          _createVNode(_component_icon_and_text, {
            class: "no_padding",
            text: $options.deliveryText
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_icon_schedule, {
                size: 0.5,
                color: "#707070"
              })
            ]),
            _: 1
          }, 8, ["text"])
        ])
      ]),
      ($options.canChat)
        ? (_openBlock(), _createBlock(_component_btn_chat, {
            key: 0,
            class: "extra-item-order-state__chat",
            "messages-unread": $options.totalUnreadMessages,
            onClick: $options.goToChat
          }, null, 8, ["messages-unread", "onClick"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_10))
    ])
  ]))
}