import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22074406"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "retailer-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_retailer_bussiness = _resolveComponent("retailer-bussiness")!
  const _component_banner_covid = _resolveComponent("banner-covid")!
  const _component_retailer_logos = _resolveComponent("retailer-logos")!
  const _component_retailer = _resolveComponent("retailer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_retailer_bussiness),
    _createVNode(_component_banner_covid, { "show-image": false }),
    _createVNode(_component_retailer_logos),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_retailer)
    ])
  ], 64))
}